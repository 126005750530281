import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../api";
import { ReactComponent as SearchImage } from "../../assets/images/search/search.svg";
import { ReactComponent as KickIcon } from "../../assets/images/daoOverview/kick.svg";
import { ReactComponent as BanIcon } from "../../assets/images/daoOverview/ban.svg";
import { ReactComponent as EmptyBan } from "../../assets/images/daoOverview/emptyban.svg";
import bannPic from "../../assets/images/daoOverview/banPic.gif";
import remove from "../../assets/images/daoOverview/remove.gif";
import InviteIcon from "../../assets/images/profile/Vector.svg";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import { StyledDropdownMenu } from "../../components/SideMenu/SortableChannelItem";
import GreyVerticalDot from "../../assets/images/daoOverview/tripledotVertical.svg";
import GreyHorizontalDot from "../../images/grey-horizontal-dot.svg";
import { Button, Dropdown, Menu, Skeleton, Input, Row } from "antd";
import { getTrunckedString } from "../../components/Proposal/ProposalCard";
import GoBackButton from "../../components/InternalComponents/GoBackButton";
import { XMarkIcon } from "@heroicons/react/24/solid";
import EmptySearchComponent from "../../components/Search/EmptySearchComponent";
import { Modal as AntModal } from "antd";
import medal_1 from "../../assets/iconSvg/medal_1.svg";
import medal_2 from "../../assets/iconSvg/medal_2.svg";
import medal_3 from "../../assets/iconSvg/medal_3.svg";
import useWindowSize from "../../hooks/useWindowSize";
import MobilePageTopNavbar from "../../components/Common/MobilePageTopNavbar";
import MembersIcon from "../../images/members-icon.svg";
import { useDaoContext } from "../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { UserContext } from "../../layouts/app/UserContext";
import { SpaceBreadcrumb } from "../../components/Spaces/BreadcrumbDesktopContainer";
import ProfileCardPopup from "../../components/Common/ProfileCard";

const medals = { 0: medal_1, 1: medal_2, 2: medal_3 };
export const Modal = styled(AntModal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 1rem;
  }
  & .ant-modal-header {
    border-bottom: 0;
  }
  & .ant-modal-title {
    width: fit-content;
    background: #f4f2f2;
    border-radius: 0.6rem;
    font-size: small;
    /* margin-left: 8px; */
    background: #fff;
  }
  & .ant-modal-content {
    width: ${(props) => props.width || "600px"};
  }
  & .ant-modal-body {
    display: flex;

    flex-direction: column;
    padding: 0;
  }
`;
export const ModalBody = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  & .steps {
    height: 15px;
    font-weight: 600;
    font-size: 12px;
    color: #64748b;
  }

  & .title {
    width: 371px;
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    margin-top: 4px;
  }

  & .image {
    align-self: center;
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-top: 28px;
    border-radius: 4rem;
  }

  & .select {
    .ant-select-selector {
      border-radius: 12px !important;
    }
    margin-top: 16px;
  }

  & .text-button {
    align-self: start;
    color: #64748b;
    padding: 0;
    margin-top: 12px;
  }

  & .continue-button {
    height: 36px;
    border-radius: 12px;
    margin-top: 24px;

    &:disabled {
      color: #fff;
      background: #b1cdfb;
    }
  }
`;

const StyledDiv = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font: normal 500 14px/20px Inter;
  padding-left: 20px;
  margin-bottom: 8px;
  padding-right: 20px;
  gap: 8px;
  border-radius: 12px;
  &:hover {
    background: #f1f5f9;
    color: #3f83f8;
    .extra-menu {
      opacity: 1;
    }
  }
  .extra-menu {
    margin-left: auto;
    cursor: pointer;
    font: normal 600 12px/16px Inter;
    color: #f05252;
  }
`;

const Member = () => {
  const [daousers, setDaoJoinedByUsers] = useState([]);
  const { daoId, isSpace } = useDaoContext();

  const { userDetails } = useContext(UserContext);
  const [canEdit, setCanEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [update, setUpdate] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useDaoLensNavigate();
  const [loadingCount, setLoadingCount] = useState(0);
  const [stage, setStage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [timeFilter, setTimeFilter] = useState("ALL");
  const { isMobile } = useWindowSize();

  const removeUser = async (action, userId) => {
    await customAxios.post("/dao/leave", {
      daoId,
      type: action,
      userId,
    });
    setUpdate((prev) => !prev);
  };

  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`settings/canview`, { daoId: daoId })
      .then((res) => {
        setCanEdit(res.data.canView ? true : false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]); // ! Local handlers

  const menu = (user) => (
    <StyledDropdownMenu>
      <Menu.Item
        onClick={() => {
          setSelectedUser({ action: "KICK", user });
        }}
        key="edit"
        style={{ borderRadius: "8px" }}
      >
        <div
          style={{
            font: "normal 500 14px/20px Inter",
            color: "#64748B",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {" "}
          <KickIcon /> Kick {getTrunckedString(user.userName, 10)}
        </div>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setSelectedUser({ action: "BAN", user });
        }}
        key="delete"
        style={{ borderRadius: "8px" }}
      >
        <div
          style={{
            font: "normal 500 14px/20px Inter",
            color: "#64748B",
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <BanIcon /> Ban {getTrunckedString(user.userName, 10)}
        </div>
      </Menu.Item>
    </StyledDropdownMenu>
  );
  useEffect(() => {
    setLoadingCount((prev) => prev + 1);
    customAxios
      .get(`/users/daousers`, {
        params: { daoId, showDisableUser: stage === 2, timeFilter },
      })
      .then((res) => {
        setDaoJoinedByUsers(
          res?.data?.daoUser?.filter(
            (user) =>
              user?.userName
                ?.toLowerCase()
                ?.includes(searchTerm?.toLowerCase()) &&
              (stage === 2 ? user?.disableType === "BAN" : true) &&
              user?.userName !== "everyone"
          )
        );
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoadingCount((prev) => prev - 1);
      });
  }, [update, searchTerm, stage, timeFilter, daoId]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "0.75rem",
        width: isMobile ? "100vw" : "auto",
        paddingLeft: isMobile ? "15px" : "0",
      }}
    >
      {isMobile ? (
        <div>
          <MobilePageTopNavbar />
        </div>
      ) : (
        <div
          style={{
            paddingLeft: "20px",
            height: "54px",
            display: "flex",
            background: "white",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1.4rem",
            border: "1px solid #f4f2f2",
            boxShadow: "0px 4px 16px rgba(132, 148, 160, 0.12)",
            borderRadius: "16px",
            position: "relative",
          }}
        >
          {isSpace && (
            <div className="mr-1">
              <SpaceBreadcrumb />
            </div>
          )}
          <img
            src={MembersIcon}
            alt="Members"
            style={{ objectFit: "contain", width: "20px" }}
          />
          <h2
            style={{ marginBottom: "0", fontSize: "16px", marginLeft: "4px" }}
          >
            Members
          </h2>
        </div>
      )}
      <div
        style={{
          background: "white",
          borderRadius: "12px",
        }}
      >
        <Modal
          centered
          destroyOnClose
          title={null}
          visible={selectedUser ? true : false}
          onCancel={() => {
            setSelectedUser(undefined);
          }}
          footer={null}
          width="460px"
          maskStyle={{
            background: "rgba(0, 0, 0, 0.5",
            backdropFilter: "blur(20px)",
          }}
          height="345px"
        >
          <ModalBody>
            <div style={{ font: "normal 600 16px/24px Inter" }}>
              {selectedUser?.action === "BAN" ? "Ban" : "Remove"}{" "}
              {selectedUser?.user?.userName} from the community?
            </div>
            <img
              style={{ borderRadius: "8px", marginTop: "12px" }}
              src={selectedUser?.action === "KICK" ? remove : bannPic}
              alt="add_people"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "4px",
              }}
            >
              <Button
                type="text"
                style={{
                  width: "80px",
                  display: "inline-block",
                  border: "1px solid #64748B",
                }}
                className="continue-button"
                onClick={() => {
                  setSelectedUser(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                type="danger"
                disabled={submitting}
                style={{
                  width: "80px",
                  display: "inline-block",
                  cursor: submitting ? "wait" : "default",
                }}
                className="continue-button"
                onClick={() => {
                  setSubmitting(true);
                  removeUser(selectedUser.action, selectedUser.user.id)
                    .then(() => {
                      toast.success(
                        `User has been ${
                          selectedUser.action === "BAN" ? "banned" : "removed"
                        } successfully`
                      );
                      setSelectedUser(undefined);
                    })
                    .catch(() => {
                      console.log("error");
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
              >
                Remove
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            font: "normal 600 16px/24px Inter",
            padding: "20px 20px 12px 20px",
          }}
        >
          {stage !== 0 && (
            <GoBackButton
              showIcon
              clickHandler={() => {
                setSearchTerm("");
                setStage(0);
              }}
            />
          )}
          {stage === 1 ? (
            <>
              <Input
                style={{ fontSize: "14px" }}
                autoFocus={true}
                placeholder="Search for Members"
                className="ip-address"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                bordered={false}
              />
              {searchTerm && (
                <XMarkIcon
                  onClick={() => {
                    setSearchTerm("");
                  }}
                  width={18}
                  color="#64748B"
                  style={{ marginRight: "16px" }}
                  cursor="pointer"
                />
              )}
            </>
          ) : stage === 2 ? (
            <>
              Banned Users
              <div
                style={{
                  font: "normal 500 14px/20px Inter",
                  color: "#64748B",
                  margin: "0px 12px",
                }}
              >
                {loadingCount ? 0 : daousers.length}
              </div>
            </>
          ) : (
            <>
              Members
              <div
                style={{
                  font: "normal 500 14px/20px Inter",
                  color: "#64748B",
                  margin: "0px 12px",
                }}
              >
                {loadingCount ? 0 : daousers.length}
              </div>
              <SearchImage
                width="18px"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setStage(1);
                }}
              />
              <div style={{ marginLeft: "auto", display: "inline-flex" }}>
                {canEdit && (
                  <div className="extra-menu" style={{ cursor: "pointer" }}>
                    <Dropdown
                      overlay={
                        <StyledDropdownMenu>
                          <Menu.Item
                            onClick={() => {
                              setStage(2);
                            }}
                            key="delete"
                            style={{ borderRadius: "8px" }}
                          >
                            <div
                              style={{
                                font: "normal 500 14px/20px Inter",
                                color: "#64748B",
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <BanIcon /> View banned
                            </div>
                          </Menu.Item>
                        </StyledDropdownMenu>
                      }
                      trigger={["click"]}
                    >
                      <img
                        src={GreyHorizontalDot}
                        alt="dot icon"
                        onClick={(e) => {
                          //   e.stopPropagation();
                        }}
                      />
                    </Dropdown>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {loadingCount ? (
          <Skeleton active />
        ) : (
          <>
            {stage === 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px 20px",
                  backgroundColor: "#F9FAFB",
                }}
              >
                <span
                  onClick={() => {
                    setTimeFilter("ALL");
                  }}
                  style={{
                    color: timeFilter === "ALL" ? "#000000" : "#9CA3AF",
                    cursor: "pointer",
                  }}
                >
                  All time
                </span>
                <span
                  style={{
                    display: "inline-block",
                    height: "5px",
                    width: "5px",
                    borderRadius: "5rem",
                    backgroundColor: "#9CA3AF",
                    margin: "0 10px",
                  }}
                ></span>
                <span
                  onClick={() => {
                    setTimeFilter("1M");
                  }}
                  style={{
                    color: timeFilter === "1M" ? "#000000" : "#9CA3AF",
                    cursor: "pointer",
                  }}
                >
                  Monthly
                </span>
                <span
                  style={{
                    display: "inline-block",
                    height: "5px",
                    width: "5px",
                    borderRadius: "5rem",
                    backgroundColor: "#9CA3AF",
                    margin: "0 10px",
                  }}
                ></span>
                <span
                  onClick={() => {
                    setTimeFilter("1W");
                  }}
                  style={{
                    color: timeFilter === "1W" ? "#000000" : "#9CA3AF",
                    cursor: "pointer",
                  }}
                >
                  Weekly
                </span>
              </div>
            )}
            <div style={{ maxHeight: "600px", overflowY: "auto" }}>
              {daousers?.length > 0 ? (
                daousers?.map((user, index) => {
                  console.log("single user", user);
                  return (
                    <StyledDiv key={user?.id}>
                      <ProfileCardPopup userId={user?.id}>
                        <Row
                          style={{ alignItems: "center" }}
                          onClick={() => navigate(`/app/profile/${user?.id}`)}
                        >
                          {index < 3 ? (
                            <img
                              style={{
                                marginRight: isMobile ? "4px" : "10px",
                                width: isMobile ? "20px" : "30px",
                              }}
                              src={medals[index]}
                              alt="medal"
                            />
                          ) : (
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: isMobile ? "" : "1px solid #777777",
                                borderRadius: "5rem",
                                width: isMobile ? "20px" : "30px",
                                height: isMobile ? "20px" : "30px",
                                color: "#777777",
                                marginRight: isMobile ? "4px" : "10px",
                              }}
                            >
                              {index + 1 < 10 ? `0${index + 1}` : index + 1}
                            </span>
                          )}
                          <img
                            style={{
                              width: isMobile ? "24px" : "36px",
                              height: isMobile ? "24px" : "36px",
                              borderRadius: "50%",
                              marginRight: isMobile ? "4px" : "8px",
                            }}
                            src={
                              user.profilePicture
                                ? user.profilePicture
                                : dummyUser
                            }
                            alt={user?.userName}
                          />
                          <div style={{ rowGap: "8px" }}>
                            <div>
                              {user.profileName}
                              {user.userRoles &&
                              user.userRoles.includes("ADMIN") ? (
                                <span
                                  title="Admin"
                                  style={{ marginLeft: "4px" }}
                                >
                                  👑
                                </span>
                              ) : null}
                            </div>
                            <span
                              style={{
                                color: "#64748B",
                                fontSize: "12px",
                                fontWeight: "400",
                              }}
                            >
                              @
                              {getTrunckedString(
                                user.userName,
                                isMobile ? 15 : 30
                              )}{" "}
                            </span>
                          </div>
                        </Row>
                      </ProfileCardPopup>
                      <div>
                        <span style={{ color: "#64748B", fontSize: "12px" }}>
                          🔥 Lvl {user.level || 1}
                        </span>
                        <span style={{ color: "#E2E8F0", fontSize: "12px" }}>
                          {" "}
                          |{" "}
                        </span>
                        <span style={{ color: "#64748B", fontSize: "12px" }}>
                          ⭐️ {user.taskPoint || 0} XP
                        </span>

                        {canEdit && user?.id !== userDetails?.id && (
                          <Dropdown overlay={menu(user)} trigger={["click"]}>
                            <img
                              src={GreyVerticalDot}
                              alt="dot icon"
                              onClick={(e) => {
                                //   e.stopPropagation();
                              }}
                              style={{ marginLeft: "10px" }}
                            />
                          </Dropdown>
                        )}
                      </div>

                      {/* {canEdit && userDetails.id !== user.id && (
                      <div className="extra-menu">
                        {stage === 2 ? (
                          <div
                            onClick={() => {
                              console.log(user);
                              customAxios
                                .post("/dao/unban/users", {
                                  daoId,
                                  userId: user.id,
                                })
                                .then(() => {
                                  toast.success(
                                    "User has been unbanned successfully"
                                  );
                                  setUpdate((prev) => !prev);
                                })
                                .catch((error) => {
                                  console.log("error");
                                });
                            }}
                          >
                            Revoke Ban
                          </div>
                        ) : (
                          <div
                            style={{ alignItems: "center", columnGap: "12px" }}
                          >
                            <span
                              style={{
                                marginBottom: "0px",
                                fontSize: "12px",
                                color: "#64748B",
                                fontWeight: "400",
                              }}
                            >
                              Joined{" "}
                              {moment.utc(user.joinedOn).format("DD MMM YY`")}
                            </span>
                            <Dropdown overlay={menu(user)} trigger={["click"]}>
                              <img
                                src={GreyVerticalDot}
                                alt="dot icon"
                                onClick={(e) => {
                                  //   e.stopPropagation();
                                }}
                              />
                            </Dropdown>
                          </div>
                        )}
                      </div>
                    )} */}
                    </StyledDiv>
                  );
                })
              ) : stage === 2 ? (
                <EmptySearchComponent
                  text="You haven’t banned anyone yet"
                  component={<EmptyBan />}
                />
              ) : (
                <EmptySearchComponent
                  text="No search result found"
                  subtext="Try using different keyword"
                  component={<EmptyBan />}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Member;

import { useNavigate, useParams } from "react-router";
import { StyledCard } from "../BountyFeed";
import dummyUser from "../../assets/iconSvg/dummy_user_icons/4.svg";
import Card, { Title } from "../Common/Card";
import { useContext, useState } from "react";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { UserContext } from "../../layouts/app/UserContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
const DaoJoined = ({ daoJoined, editable }) => {
  const [loading, setLoading] = useState(false);
  const { reload } = useContext(UserContext);

  const navigate = useDaoLensNavigate();

  return (
    <Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        <div style={{ font: "normal 600 14px/20px Inter" }}>
          Daos Joined ({daoJoined.length})
        </div>

        {editable && (
          <div
            style={{
              color: "#64748B",
              font: "normal 500 12px/16px Inter",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              border: "0px",
            }}
            onClick={async () => {
              if (loading) {
                return;
              }
              setLoading(true);
              customAxios
                .get("daoroles/user/sync")
                .catch(() => {
                  console.log("error");
                })
                .finally(() => {
                  setLoading(false);
                  reload();
                });
            }}
          >
            <svg
              style={{
                marginRight: "4px",
                animation: loading ? "rotation 2s infinite linear" : "",
              }}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.66675 2.66654V5.99987H3.05475M13.2921 7.3332C13.1446 6.16178 12.6127 5.07238 11.7797 4.23572C10.9466 3.39907 9.85951 2.86245 8.68873 2.70996C7.51795 2.55748 6.32967 2.79775 5.3101 3.39311C4.29054 3.98848 3.49731 4.90529 3.05475 5.99987M3.05475 5.99987H6.00008M13.3334 13.3332V9.99987H12.9461M12.9461 9.99987C12.5029 11.0938 11.7094 12.0099 10.69 12.6047C9.67051 13.1996 8.48257 13.4395 7.31215 13.2871C6.14172 13.1347 5.05488 12.5985 4.22177 11.7624C3.38866 10.9263 2.85632 9.8375 2.70808 8.66654M12.9461 9.99987H10.0001"
                stroke="#64748B"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            {loading ? "Syncing" : "Sync roles"}
          </div>
        )}
      </div>

      <div style={{ maxHeight: "264px", overflowY: "auto" }}>
        {daoJoined?.map((dao) => (
          <StyledCard
            style={{
              width: "100%",
              textAlign: "left",
              marginBottom: "16px",
              borderRadius: "14px",
            }}
            onClick={() => {
              navigate(`/app/dao/${dao?.name}/${dao.dao_id}/overview`);
            }}
          >
            <div
              style={{
                fontStyle: "normal",
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "15px",
                display: "flex",
                gap: "4px",
                alignItems: "center",
              }}
            >
              {
                <img
                  src={dao.profile_picture ? dao.profile_picture : dummyUser}
                  style={{ height: "18px", width: "18px", borderRadius: "50%" }}
                />
              }
              {dao.name}
            </div>
            {dao.user_roles?.map((skill) => (
              <div
                key={skill}
                style={{
                  marginTop: "8px",
                  padding: "2px 8px",
                  marginRight: "4px",
                  fontStyle: "normal",
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "12px",
                  lineHeight: "16px",
                  border: "1px solid #E2E8F0",
                  background: "#F4F2F2",
                  borderRadius: "8px",
                  height: "20px",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                {skill}
              </div>
            ))}
          </StyledCard>
        ))}
      </div>
    </Card>
  );
};

export default DaoJoined;

import React from "react";
import "../../ComponentStyles/BountyDetails.scss";
import { Collapse, Skeleton, Typography } from "antd";
import dummyAvatar from "../../../assets/images/login/defaultUser.jpeg";
import pdfIcon from "../../../assets/iconSvg/pdf.svg";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { useEffect } from "react";
import downloadIcon from "../../../assets/iconSvg/downloadIcon.svg";
import ReactHtmlParser from "react-html-parser";
import { Document, Page } from "react-pdf";
import customAxios from "../../../api";
import GoBackButton from "../../InternalComponents/GoBackButton";
import SmallUserDetails from "../../InternalComponents/SmallUserDetails";
import TextButton from "../../InternalComponents/TextButton";
import StatusPill from "./StatusPill";
import { TaskPillWrapper } from "./TaskCard";
import { ChevronRightIcon, ClockIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../../Common/ProfileCard";
import { getCurrentDateFromNow } from "../../../utils";
const { Panel } = Collapse;
const { Link } = Typography;
function TaskDetailsPage() {
  const navigation = useDaoLensNavigate();
  // ! Local states
  const [accordionState, setAccordionState] = useState([]);
  const [task, setTask] = useState(null);
  const [subTask, setSubTask] = useState(null);
  const [showAttachmentLink, setShowAttachmentLink] = useState(false);

  const { taskId, subTaskId, projectId } = useParams();

  const fetchTask = async () => {
    try {
      const response = await customAxios(
        `/tasks/fetch/details?taskId=${taskId}`
      );
      const parentTask = response.data.res?.[0];
      setTask(parentTask);
      setSubTask(
        parentTask?.subTask?.find((eachSubTask) => eachSubTask.id === subTaskId)
      );
    } catch (error) {
      toast.error("Failed to fetch task.");
      console.error(error);
    }
  };

  const updateStatus = async (status) => {
    const prevStatus = subTask.status;
    setSubTask((prev) => ({ ...prev, status }));

    try {
      const formData = new FormData();
      formData.append("taskId", taskId);
      formData.append("projectId", projectId);
      const editSubTask = [{ subTaskId: subTaskId, status }];
      formData.append("editSubTask", JSON.stringify(editSubTask));
      await customAxios.post("/tasks/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Status updated");
    } catch (error) {
      setSubTask((prev) => ({ ...prev, status: prevStatus }));
      console.error(error);
    }
  };

  // ! Effects
  useEffect(() => {
    // ! Fetch comments
    fetchTask();
  }, []);

  const dateData = {
    label: moment(subTask?.submissionDate)?.isSame(new Date(), "day")
      ? "Today"
      : moment(subTask?.submissionDate)?.isSame(moment().add(1, "day"), "day")
      ? "Tomorrow"
      : moment(subTask?.submissionDate)?.format("D MMM"),
    color:
      moment(subTask?.submissionDate)?.isSame(new Date(), "day") ||
      moment(subTask?.submissionDate)?.isBefore(new Date())
        ? "#F05252"
        : moment(subTask?.submissionDate)?.isSame(moment().add(1, "day"), "day")
        ? "#E3A008"
        : "#64748B",
  };

  if (!subTask)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            background: "#fff",
            borderRadius: "16px",
            fontSize: "14px",
            gap: "10px",
            padding: "16px 20px 20px",
          }}
        >
          <Skeleton active />
        </div>
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          background: "#fff",
          borderRadius: "16px",
          fontSize: "14px",
          gap: "10px",
          padding: "16px 20px 20px",
        }}
      >
        <p
          style={{ fontWeight: "600", padding: 0, margin: 0 }}
          className="truncate"
        >
          {task.projectTitle}
        </p>
        <ChevronRightIcon width={12} />
        <p
          style={{ fontWeight: "600", padding: 0, margin: 0 }}
          className="truncate"
        >
          {task.title}
        </p>
        <ChevronRightIcon width={12} />
        <p style={{ fontWeight: "600", padding: 0, margin: 0 }}>
          Sub-task details
        </p>
      </div>
      <div className="bounty-details__wrapper">
        <GoBackButton
          showIcon
          buttonText={"Go Back"}
          clickHandler={() => {
            navigation(-1);
          }}
        />
        <div className="bounty-details__meta">
          <div className="bounty-details__meta--user-details">
            <div>
              <SmallUserDetails
                avatar={task?.creatorProfilePicture || dummyAvatar}
                name={task.creatorUserName}
                userId={task.creatorUserId}
                url=""
                showSeparator
                postSeparator={
                  <span className="bounty-details__meta--user-details-time">
                    {moment(subTask.taskCreatedAt).format("hh:mm A")}
                  </span>
                }
              />
            </div>
          </div>
          <h3 className="bounty-details__meta--header">{subTask.title}</h3>
          <div style={{ display: "flex", gap: "8px" }}>
            <StatusPill
              status={subTask?.status}
              updateStatus={updateStatus}
              disabled={!task.canUpdateStatus}
            />
            {subTask?.submissionDate && (
              <TaskPillWrapper>
                <ClockIcon width={16} color={dateData.color} />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: dateData.color,
                  }}
                >
                  {getCurrentDateFromNow(task?.submissionDate)}
                </span>
              </TaskPillWrapper>
            )}
            {subTask?.assignee?.length > 0 && (
              <TaskPillWrapper>
                <span
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#64748B",
                  }}
                >
                  Assigned to
                </span>
                {subTask?.assignee?.map((eachAssignee) => (
                  <ProfileCardPopup userId={eachAssignee?.id}>
                    <img
                      onClick={() =>
                        navigation(`/app/profile/${eachAssignee?.id}`)
                      }
                      src={
                        eachAssignee.profilePicture
                          ? eachAssignee.profilePicture
                          : dummyAvatar
                      }
                      alt={eachAssignee?.userName}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "999px",
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                    />
                  </ProfileCardPopup>
                ))}
              </TaskPillWrapper>
            )}
          </div>
        </div>
        <Collapse
          ghost
          activeKey={accordionState}
          className="bounty-details-accordion"
        >
          <Panel
            header={
              <TextButton
                clickHandler={() =>
                  accordionState[0] === "1"
                    ? setAccordionState([""])
                    : setAccordionState(["1"])
                }
                isActive={accordionState[0] === "1"}
                buttonText="Sub-task Details"
              />
            }
            key="1"
            showArrow={false}
          >
            <div>{ReactHtmlParser(subTask.description)}</div>
            {subTask.attachments?.length > 0 && <p>Attachments :</p>}
            <div className="view-bounty-submission-modal__attachment--container">
              {subTask.attachments?.map((singleAttachment, index) => {
                return (
                  <>
                    {showAttachmentLink ? (
                      <Link href={singleAttachment} target="_blank">
                        <img
                          src={singleAttachment}
                          alt="attachment"
                          style={{
                            width: "130px",
                            height: "130px",
                            marginBottom: "12px",

                            borderRadius: "12px",
                            marginRight: "10px",
                          }}
                        />
                      </Link>
                    ) : (
                      <Document
                        file={singleAttachment}
                        onLoadSuccess={() => {}}
                        loading={<span>Loading file</span>}
                        onLoadError={() => {
                          setShowAttachmentLink(true);
                        }}
                        className="view-bounty-submission-modal__attachment"
                      >
                        <Page pageNumber={1} />
                        <div
                          className="view-bounty-submission-modal__attachment--title"
                          onClick={() => {
                            window.open(singleAttachment);
                          }}
                        >
                          <img src={pdfIcon} alt="" />
                          <span>File {index + 1}</span>
                          <img src={downloadIcon} alt="" />
                        </div>
                      </Document>
                    )}
                  </>
                );
              })}
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default TaskDetailsPage;

import { Button, Input, Modal as AntModal, Skeleton } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import customAxios from "../../api";
import { StyledModalWrapper } from "../CreateBountyModal";
import EmptySearchComponent from "./EmptySearchComponent";
import SearchItem from "./SearchItem";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ReactComponent as SearchImage } from "../../assets/images/search/search.svg";
import { useParams } from "react-router";
import useWindowSize from "../../hooks/useWindowSize";
import no_search from "../../assets/iconSvg/no_search_results.svg";
import useInfiniteScroll from "react-infinite-scroll-hook";
export const SEARCH_PAGINATION_LIMIT = 7;

const Modal = styled(AntModal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 1rem;
  }
  & .ant-modal-header {
    padding: ${(props) => props.headerPadding || "16px 11px 0px 11px;"};
    border-bottom: 0;
  }
  & .ant-modal-title {
    width: fit-content;
    padding: 2px 0px;
    background: #f4f2f2;
    border-radius: 0.6rem;
    font-size: small;
    /* margin-left: 8px; */
    background: #fff;
  }
  & .ant-modal-content {
    width: ${(props) => props.width || "600px"};
  }
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: ${(props) => props.width || "600px"};
  }
`;

let LINK_TYPES = [
  { label: "All", value: "ALL" },
  { label: "Bounties", value: "BOUNTY" },
  { label: "Posts", value: "POST" },
  { label: "Proposals", value: "PROPOSALS" },
  { label: "Projects", value: "PROJECTS" },
  { label: "Announcements", value: "ANNOUNCEMENT" },
];

const getSearchResultsDefault = async (searchTerm, type, offset) => {
  const { data } = await customAxios.post("/search", {
    searchContent: searchTerm,
    type,
    limit: SEARCH_PAGINATION_LIMIT,
    offset,
  });
  return { data: data.data, searchTerm, nextOffset: data.offset };
};
export const HoverCard = styled.div`
  ${({ disabled = false }) =>
    disabled
      ? ``
      : `&:hover {
    background: #f4f2f2;
    cursor: pointer;
  }`}
`;
const SearchModal = ({
  isModalVisible,
  closeModal,
  isForUpdateLinking,
  linkedItems = [],
  onClick,
  getSearchResults = getSearchResultsDefault,
  isForLinking = false,
  handleDeleteLinkedItem,
  handleSubmit,
  newLinkedItems = [],
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedType, setSelectedType] = useState("ALL");
  const [searchItems, setSearchItems] = useState([]);
  const [loadingCount, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loaderRef] = useInfiniteScroll({
    loading: loadingCount,
    hasNextPage: hasNextPage,
    onLoadMore: () => {
      setUpdate(prev => !prev)
    },
    disabled: false,
    rootMargin: "0px 0px 400px 0px",
  });
  const handleClose = () => {
    setSearchTerm("");
    closeModal();
  };
  const { isMobile } = useWindowSize();
  const getTypesList = (isForLinking) => {
    if (isForLinking || isForUpdateLinking) return LINK_TYPES;
    else return [...LINK_TYPES, { label: "Members", value: "USER_PROFILE" }];
  };
  useEffect(() => {
    setSearchItems([]);
    setOffset(0);
    setHasNextPage(true);
    setUpdate(prev => !prev);
  }, [searchTerm, selectedType]);
  useEffect(() => {
    if (searchTerm) {
      setLoading(true);
      getSearchResults(searchTerm, selectedType, offset)
        .then(({ data, searchTerm: search, nextOffset }) => {
          console.log(nextOffset)
          setSearchTerm((prev) => {
            if (search === searchTerm) {
              setOffset(nextOffset);
              if (data.length === 0) {
                setHasNextPage(false);
              }
              setSearchItems(prev => [...prev, ...data]);
              setLoading(false);
            }
            return prev;
          });
        })
        .catch(() => {
          setHasNextPage(false)
          setLoading(false);
        });
    } else {
      setSearchItems([]);
      setSelectedType("ALL");
    }
  }, [update]);
  return (
    <StyledModalWrapper>
      <Modal
        width={isMobile ? "auto" : "600px"}
        destroyOnClose
        title=""
        visible={isModalVisible}
        onCancel={handleClose}
        footer={null}
        closable={false}
        style={{
          overflow: "hidden",
          padding: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "56px",
            paddingLeft: "20px",
          }}
        >
          <SearchImage />

          <Input
            style={{ fontSize: "16px", padding: "4px 12px" }}
            autoFocus={true}
            placeholder="Search for keywords"
            className="ip-address"
            value={searchTerm}
            onChange={(e) => {
              var regEx = /^[\w\-\s]*$/;
              if (e.target.value.match(regEx)) setSearchTerm(e.target.value);
            }}
            bordered={false}
          />
          {searchTerm ? (
            <XMarkIcon
              onClick={() => {
                setSearchTerm("");
              }}
              width={24}
              color="#64748B"
              style={{ marginRight: "16px" }}
              cursor="pointer"
            />
          ) : null}
        </div>
        {searchTerm ? (
          <>
            <div
              style={{
                display: "flex",
                gap: "4px",
                padding: "12px",
                overflowX: "auto",
                borderTop: "1px solid #E2E8F0",
              }}
            >
              {getTypesList(isForLinking).map(({ label, value }) => (
                <HoverCard
                  onClick={() => {
                    setSelectedType(value);
                  }}
                  style={{
                    padding: "4px 12px",
                    background: selectedType === value ? "#3F83F8" : "",
                    color: selectedType === value ? "white" : "#64748B",
                    borderRadius: "14px",
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {label}
                </HoverCard>
              ))}
            </div>
            <div
              style={{
                marginBottom: "12px",
                maxHeight: "440px",
                overflowY: "auto",
              }}
            >
              {" "}
              {isForLinking
                ? linkedItems.map((data) => (
                  <SearchItem
                    handleDeleteLinkedItem={handleDeleteLinkedItem}
                    data={data}
                    onClick={onClick}
                    handleClose={handleClose}
                    searchTerm={searchTerm}
                  />
                ))
                : null}
              {isForUpdateLinking
                ? newLinkedItems.map((data) => (
                  <SearchItem
                    handleDeleteLinkedItem={handleDeleteLinkedItem}
                    data={data}
                    onClick={onClick}
                    handleClose={handleClose}
                    searchTerm={searchTerm}
                  />
                ))
                : null}

              {!loadingCount && searchItems?.length === 0 && linkedItems?.length === 0 && (
                <EmptySearchComponent
                  text="No search result found"
                  subtext="Try using different keyword"
                  component={
                    <img src={no_search} style={{ width: "200px" }} />
                  }
                />
              )}
              {
                [...searchItems]?.map((data) =>
                  newLinkedItems?.find((ele) => ele.id === data.id) ||
                    linkedItems?.find((ele) => ele.id === data.id) ? null : (
                    <SearchItem
                      data={data}
                      onClick={onClick}
                      handleClose={handleClose}
                      searchTerm={searchTerm}
                    />
                  )
                )
              }
              {(loadingCount || hasNextPage) &&
                <div ref={loaderRef} style={{ width: "100%", padding: "12px" }}>
                  <Skeleton
                    paragraph={{
                      rows: 2,
                      width: "100%",
                    }}
                    title={false}
                    size="large"
                    active
                  />
                </div>}
            </div>
            {isForLinking || isForUpdateLinking ? (
              <div
                style={{
                  borderTop: "1px solid #f2f2f2",
                  margin: "4px 0px",
                  justifyContent: "flex-end",
                  display: "flex",
                  padding: "8px 12px",
                }}
              >
                <Button
                  type="primary"
                  style={{ borderRadius: "8px" }}
                  onClick={() => {
                    if (handleSubmit) handleSubmit();
                    else handleClose();
                  }}
                >
                  Done
                </Button>
              </div>
            ) : null}
          </>
        ) : null}
      </Modal>
    </StyledModalWrapper>
  );
};

export default SearchModal;

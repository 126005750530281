import React from "react";
import "../ComponentStyles/ViewBountySubmissionModal.scss";
import { Button, Modal } from "antd";
import GoBackButton from "../InternalComponents/GoBackButton";
import SmallUserDetails from "../InternalComponents/SmallUserDetails";
import SecondaryButtonOutlined from "../InternalComponents/SecondaryButtonOutlined";
import PrimaryButtonFilled from "../InternalComponents/PrimaryButtonFilled";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { useState } from "react";
import pdf from "../../assets/samplepdf.pdf";
import { toast } from "react-toastify";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import pdfIcon from "../../assets/iconSvg/pdf.svg";
import downloadIcon from "../../assets/iconSvg/downloadIcon.svg";
import RedFlagIcon from "../../images/red-flag.svg";
import BlueFlagIcon from "../../images/blue-flag.svg";
import yellowlagIcon from "../../images/yellow-flag.svg";
import GreyFlagIcon from "../../images/grey-flag.svg";
import subtaskIcon from "../../assets/iconSvg/subtaskIcon.svg";
import calendarIcon from "../../assets/iconSvg/calendarIcon.svg";
import starIcon from "../../assets/iconSvg/starIcon.svg";
import moment from "moment/moment";
import HtmlParser from "react-html-parser";
const flags = {
  URGENT_PRIORITY: RedFlagIcon,
  HIGH_PRIORITY: BlueFlagIcon,
  MEDIUM_PRIORITY: yellowlagIcon,
  LOW_PRIORITY: GreyFlagIcon,
};

function ViewBountySubtaskModal({
  openModalHandler,
  closeModalHandler,
  isModalOpen,
  bountyDetails,
  canShowNext,
  canShowPrevious,
  setcurrentBountySubmissionId,
  currentBountySubmissionId,
}) {
  const [viewDocument, setViewDocument] = useState(false);
  const [showAttachmentLink, setShowAttachmentLink] = useState(false);
  return (
    <Modal
      open={isModalOpen}
      visible={isModalOpen}
      title={
        <div>
          <GoBackButton
            buttonText={"Bounty Subtask"}
            clickHandler={closeModalHandler}
            showIcon
          />
        </div>
      }
      onOk={closeModalHandler}
      onCancel={closeModalHandler}
      footer={[
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            {canShowPrevious && (
              <SecondaryButtonOutlined
                buttonText="Previous"
                clickHandler={() => {
                  setcurrentBountySubmissionId(currentBountySubmissionId - 1);
                }}
              />
            )}
          </div>
          <div>
            {canShowNext && (
              <SecondaryButtonOutlined
                clickHandler={() => {
                  setcurrentBountySubmissionId(currentBountySubmissionId + 1);
                }}
                buttonText="Next"
              />
            )}
            <span style={{ display: "inline-block", width: "10px" }}></span>
            {/* <PrimaryButtonFilled
              buttonText="Approve & Initiate Payment"
              fillColor="#22C55E"
              clickHandler={() => {
                setViewDocument(true);
              }}
            /> */}
          </div>
        </div>,
      ]}
      className="view-bounty-submission-modal"
    >
      <SmallUserDetails
        name={bountyDetails.userName}
        userId={bountyDetails.userId}
        avatar={bountyDetails.icon || dummyUser}
        url={bountyDetails.url || ""}
        showSeparator
        postSeparator={
          <span className="bounty-details__meta--user-details-time">
            {moment(bountyDetails.bountyCreatedAt).format("hh:mm A")}
          </span>
        }
      />
      <h3 className="view-bounty-submission-modal__title">
        {bountyDetails.bountyTitle}
      </h3>
      <p className="view-bounty-submission-modal__description">
        {bountyDetails.bountyDescription
          ? HtmlParser(bountyDetails.bountyDescription)
          : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam dignissimos repellat corporis hic id? Repudiandae non sed facilis explicabo similique, eveniet alias consequatur harum molestias quasi impedit nam molestiae iure amet saepe laboriosam porro fuga repellendus hic laborum maiores autem."}
      </p>
      <SinglePanelHeader singleSubtask={bountyDetails} onClick={() => {}} />
      {bountyDetails.bountyAttachments?.length > 0 && <p>Attachments :</p>}
      <div className="view-bounty-submission-modal__attachment--container">
        {bountyDetails.bountyAttachments?.map((singleAttachment, index) => {
          return (
            <>
              {showAttachmentLink ? (
                <a href={singleAttachment} target="_blank">
                  {singleAttachment.split("/").pop()}
                </a>
              ) : (
                <Document
                  file={singleAttachment}
                  onLoadSuccess={() => {}}
                  loading={<span>Loading file</span>}
                  onLoadError={() => {
                    setShowAttachmentLink(true);
                  }}
                  className="view-bounty-submission-modal__attachment"
                >
                  <Page pageNumber={1} />
                  <div
                    className="view-bounty-submission-modal__attachment--title"
                    onClick={() => {
                      window.open(singleAttachment);
                    }}
                  >
                    <img src={pdfIcon} alt="" />
                    <span>File {index + 1}</span>
                    <img src={downloadIcon} alt="" />
                  </div>
                </Document>
              )}
            </>
          );
        })}
      </div>
    </Modal>
  );
}
function SinglePanelHeader({ singleSubtask, onClick }) {
  return (
    <div
      className="bounty-subtask__wrapper bounty-subtask__no-border"
      onClick={onClick}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={subtaskIcon} alt="" className="bounty-subtask__icon" />
        <GreyBackgroundComponent>
          <img src={calendarIcon} alt="" />
          <span>{moment(singleSubtask.bountyCreatedAt).format("DD MMM")}</span>
          <span>{moment(singleSubtask.bountyCreatedAt).format("hh:mm A")}</span>
        </GreyBackgroundComponent>
        {singleSubtask.bountyPriority && (
          <GreyBackgroundComponent>
            <img src={flags[singleSubtask.bountyPriority]} alt="" />
          </GreyBackgroundComponent>
        )}
        <GreyBackgroundComponent>
          <img src={starIcon} alt="" />
          <span>{singleSubtask.bountyId}</span>
          <span>Pts</span>
        </GreyBackgroundComponent>
        <img
          src={dummyUser}
          alt=""
          className="bounty-subtask__icon bounty-subtask__icon--rounded"
        />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}></div>
    </div>
  );
}
function GreyBackgroundComponent({ children }) {
  return <div className="bounty-subtask__grey-background">{children}</div>;
}
export default ViewBountySubtaskModal;

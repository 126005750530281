import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  Space,
  Select,
  Upload,
  Modal as AntModal,
  Radio,
  Tag,
  Col,
  Row,
  Checkbox,
  Card,
  Tooltip,
  DatePicker,
} from "antd";
import IconWrapper from "./IconWrapper";
import {
  CalendarIcon,
  MagnifyingGlassIcon,
  TagIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import QuillEditor from "./QuillEditor";
import Tags from "./Tags";
import { Modal, Footer } from "../styles/ModalStyles";
import TaskPoints from "./TaskPoints";
import Flag from "./Flag";
import { toast } from "react-toastify";
import AddNoteSubmission from "./AddNoteSubmission";
import styled from "styled-components";
import customAxios from "../api";
import { ReactComponent as StackIcon } from "./../images/rectangle-stack-icon.svg";
import { fetchBounty } from "../features/bountypage/bountyPageSlice";

import { useParams } from "react-router";
import dummyUser from "./../assets/images/login/defaultUser.jpeg";

import ConditionedTitle from "../images/git-fork.png";

import { Bin } from "../assets/iconSvg/Bin";

import DueDate from "./DueDate";
import moment from "moment";
// import { useMemo } from "react";
import userIconGreen from "./../assets/iconSvg/userIconGreen.svg";
import bountyBased from "../images/bountybased.png";
import applicationBased from "../images/application_based.png";
import userBased from "../images/user-add.png";
import withCondition from "../images/git-fork.png";
import anyMember from "../images/globe.png";
import GenericTagSelect from "./Common/GenericTagSelect";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import ReviewerSelector from "./BountyComponents/ReviewerSelector";
import CreateSubtaskInput from "./BountyComponents/CreateSubtaskInput";
import SubtaskCardView from "./BountyComponents/SubtaskCardView";
import AddBounty from "./BountyComponents/AddBounty";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { PROJECT_TASK_STATUSES, tokenOptions } from "../constants";
import { Feild } from "../styles/Feild";
import styles from "./Course/course.module.css";
import useExitPrompt from "../hooks/useExitPrompt";
import { getTrunckedString } from "./Proposal/ProposalCard";
import { checkFileSize, postMixpanel } from "../utils";
import LinkEntities from "./Common/LinkEntities";
import { PaperClipIcon } from "./Icones";
import { useDaoContext } from "../layouts/app/DaoContext";
import { confirmOperation } from "../utils/common";
const { Option } = Select;
export const cancelText = "Continue editing";
export const confirmText = "Discard changes";
const StyledConditionedModalTitle = styled.div`
  display: flex;
  margin-left: 10px;
  gap: 8px;

  .conditioned-title {
    width: 20px;
    height: 20px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
  }
`;

const StyledBin = styled.div`
  .bin {
    position: absolute;
    right: 0.6rem;
    top: 0.6rem;
    z-index: 10;
    opacity: 0.5;
  }
  .bin + .bin {
    position: absolute;
    inset: 38% 0% 0% 94%;
    z-index: 10;
    opacity: 0.5;
  }
`;

const StyledRowWrapper = styled.div`
  .ant-radio-button-wrapper:first-of-type {
    border-radius: 12px 0px 0px 12px;
  }

  .ant-radio-button-wrapper:last-of-type {
    border-radius: 0px 12px 12px 0px;
  }
`;

const StyledAntBodyWrapper = styled.div`
  .ant-row:first-of-type {
    padding-top: 0 !important;
  }
`;

const StyledDiv = styled.div`
  padding: 0px 51px 16px 20px;

  .bountyType {
    width: 1rem;
    height: 1rem;
  }

  display: flex;
  .ant-select-selector,
  .application-based {
    background-color: #f4f2f2 !important;
    border: none !important;
    border-radius: 8px !important;
  }
  .application-based {
    padding: 0.35rem 1rem;
  }
  .btn-addBounty {
    border: 1px dashed #94a3b8;
    background: transparent;
    padding: 5px 12px;
    border-radius: 8px;
    color: #64748b;
    font-size: 12px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    align-self: center;
    span {
      white-space: nowrap;
    }
    &:hover {
      border: 1px dashed #1890ff;
      cursor: pointer;
    }
  }
  .with-reward-of {
    margin: 0 5px;
    color: #0f172a;
    white-space: nowrap;
    opacity: 0.6;
  }
  .for {
    margin: 0 5px;
    color: #0f172a;
    opacity: 0.6;
  }
  .direct-assignment-ip {
    width: 8rem;
  }
  .diamond-image {
    width: 1rem;
    aspect-ratio: 1;
  }
  .direct-assignment-ip {
    background-color: #f4f2f2 !important;
    border: none !important;
    border-radius: 8px !important;
  }
  .bountyOption .gatedValue {
    margin-left: 6px;
    margin-top: 5px;
  }

  .selected-token-amt {
    padding: 5px 12px 3px;
    height: 32px;
    background: rgba(30, 192, 57, 0.1);
    border-radius: 8px;
    border: none;
    color: #22c55e;
    font-weight: 600;
    font-size: 12px;
  }
`;

const StyledBountyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-left: 10px;
  .diamond-image {
  }

  .ant-select-selector {
    background: ${(props) =>
      props.status === "TODO"
        ? "#F2F5FF !important"
        : props.status === "IN PROGRESS"
        ? "#8DA2FB !important"
        : props.status === "IN REVIEW"
        ? "#6C71E3 !important"
        : props.status === "COMPLETED"
        ? "#31C48D !important"
        : ""};
    border-radius: 8px 0px 0px 8px !important;
    margin-right: 5px !important;
    border: none !important;
  }

  .arrow-right {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    height: 32px;
    background-color: ${(props) =>
      props.status === "TODO"
        ? "#F2F5FF"
        : props.status === "IN PROGRESS"
        ? "#8DA2FB"
        : props.status === "IN REVIEW"
        ? "#6C71E3"
        : props.status === "COMPLETED"
        ? "#22C55E"
        : ""};
  }
`;
const AddSearchTagWrapper = styled.div`
  display: inline-block;
  position: relative;
  & .ant-card-body {
    padding: 10px;
  }
  & .tag-card {
    width: 178px;
    position: absolute;
    top: 120%;
    transition: 300ms all;
    border-radius: 1rem;
    z-index: 1;
    box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
  }
  & .search-input {
    border-radius: 0.6rem;
  }
  .padding-zero {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .padding-zero > span {
    margin: 0;
  }
`;

const StyledAttachment = styled.div`
  padding: 0px 20px;
  .attachment-title {
    // font-weight: 600;
    // font-size: 12px;
    margin: 5px 4px;
  }
`;

const StyledGatingCondition = styled.div`
  position: relative;
  margin: 14px 0px;
  .gating-type {
    width: 10rem !important;
    position: absolute;
    top: -1rem;
    margin-left: 0.5rem;
  }
  .ant-select-selector {
    border-radius: 8px !important;
  }
  .ip-address {
    border-radius: 12px;
  }
`;

export const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;
const steps = [
  {
    target: ".bounty-title",
    title: "Setup Bounty",
    disableBeacon: true,

    content:
      "Provide a title for your bounty and define who can attempt it and the compensation they would receive.",
  },
  {
    target: ".bounty-description",
    title: "Define bounty in detail",
    content:
      "Define your bounty in detail and add checklist for it so users can gain clarity about checkpoints.",
  },
  {
    target: ".footer-items",
    title: "Add Submission details & Reviewer",
    content:
      "Add a due date to the bounty and assign someone to review the submission to ascertain winners.",
  },
];
const BountyCreation = ({
  handleGatingCondition,
  handleBountyType,
  bountyType,
  setState,
  state,
  gatedValue,
  assigneeList,
  selectedTokenId,
  selectedTokenAmt,
  setSelectedTokenId,
  setSelectedTokenAmt,
  conditionDetails,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterAssigneeList, setFilterAssigneeList] = useState([]);
  useEffect(() => {
    if (!searchInput) setFilterAssigneeList(assigneeList.slice());
    else
      setFilterAssigneeList(
        assigneeList
          .slice()
          .filter((ele) => ele.userName?.toLowerCase().includes(searchInput))
      );
  }, [searchInput, assigneeList]);
  const ref = useRef();
  useOnClickOutside(ref, () => setShowDropdown(false));
  return (
    <StyledDiv
      style={{ alignItems: "center", columnGap: "8PX" }}
      className="fw-500"
    >
      <img src={userIconGreen} width="20px" height="20px" />
      <span className="bountyOption">Bounty </span>
      {gatedValue === "ANY_MEMBER" ? (
        <span style={{ fontWeight: "400" }}>for</span>
      ) : null}
      {bountyType === "BOUNTY_SUBMISSION" && (
        <Select
          // onChange={handleGatingCondition}
          onSelect={handleGatingCondition}
          defaultValue="ANY_MEMBER"
          showArrow={false}
          value={gatedValue}
        >
          <Option value="ANY_MEMBER">
            <img
              src={anyMember}
              height="16px"
              width="16px"
              style={{ marginRight: "4px" }}
            />
            <span className="gatedValue">Any Member</span>{" "}
          </Option>
          <Option value="WITH_CONDITION">
            <img
              src={withCondition}
              height="16px"
              width="16px"
              style={{ marginRight: "4px" }}
            />
            <span className="gatedValue">
              {/* {conditionDetails.conditions.length
                ? `With ${conditionDetails.conditions.length} Conditions`
                : "With Condition"} */}
              With Condition
            </span>
          </Option>
        </Select>
      )}
      {bountyType === "DIRECT_ASSIGNMENT" && (
        <AddSearchTagWrapper>
          {!state?.selectedAssignee ? (
            <Button
              className="padding-zero"
              type="secondary"
              size="small"
              style={{
                border: "1px dashed #94A3B8",
                borderRadius: "8px",
                minHeight: "32px",
                color: "#64748B",
              }}
              onClick={() => setShowDropdown(true)}
            >
              <IconWrapper>
                <i
                  class="fas fa-user"
                  style={{ fontSize: "16px", marginRight: "2px" }}
                ></i>
              </IconWrapper>{" "}
              +Add Assignee
            </Button>
          ) : state?.selectedAssignee?.userName?.length > 10 ? (
            <Tooltip
              title={state?.selectedAssignee?.userName}
              key={state?.selectedAssignee?.userName}
            >
              <div
                onClick={() => setShowDropdown(true)}
                style={{
                  background: "#F4F2F2",
                  width: "140px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  minHeight: "32px",
                  color: "#64748B",
                  display: "flex",

                  alignItems: "center",
                  padding: "0.4rem 0.8rem",
                }}
              >
                <img
                  style={{
                    width: "22px",
                    borderRadius: "50%",
                    height: "22px",
                    objectFit: "cover",
                    marginRight: "8px",
                  }}
                  src={
                    state?.selectedAssignee?.profilePicture
                      ? state?.selectedAssignee?.profilePicture
                      : dummyUser
                  }
                  alt="icon"
                />{" "}
                {state?.selectedAssignee.userName.slice(0, 9)}...
              </div>
            </Tooltip>
          ) : (
            <div
              onClick={() => setShowDropdown(true)}
              style={{
                background: "#F4F2F2",
                width: "140px",
                borderRadius: "8px",
                cursor: "pointer",
                minHeight: "32px",
                color: "#64748B",
                display: "flex",

                alignItems: "center",
                padding: "0.4rem 0.8rem",
              }}
            >
              <img
                style={{
                  width: "22px",
                  borderRadius: "50%",
                  height: "22px",
                  objectFit: "cover",
                  marginRight: "8px",
                }}
                src={
                  state?.selectedAssignee?.profilePicture
                    ? state?.selectedAssignee?.profilePicture
                    : dummyUser
                }
                alt="icon"
              />{" "}
              {state?.selectedAssignee.userName}
            </div>
          )}
          {showDropdown && (
            <Card className="tag-card" ref={ref} style={{ minWidth: "240px" }}>
              <Input
                className="search-input"
                placeholder="Search members..."
                value={searchInput}
                prefix={
                  <IconWrapper>
                    <MagnifyingGlassIcon />
                  </IconWrapper>
                }
                style={{ margin: "4px 0px" }}
                autoFocus={true}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <Space
                direction="vertical"
                style={{
                  minHeight: "250px",
                  width: "100%",
                  maxHeight: "250px",
                  overflowY: "auto",
                }}
              >
                {!filterAssigneeList.length ? (
                  <h4 style={{ textAlign: "center" }}>No users found</h4>
                ) : null}
                {filterAssigneeList.map((user, index) => (
                  <Feild
                    key={user}
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        selectedAssignee: user,
                      }));
                      setShowDropdown(false);
                    }}
                  >
                    <img
                      style={{
                        width: "22px",
                        borderRadius: "50%",
                        height: "22px",
                        objectFit: "cover",
                      }}
                      src={
                        user?.profilePicture ? user?.profilePicture : dummyUser
                      }
                      alt="icon"
                    />{" "}
                    <span>
                      {user?.userName?.slice(0, 16)}
                      {user?.userName?.length > 16 ? "..." : null}
                    </span>
                  </Feild>
                ))}
              </Space>
            </Card>
          )}
        </AddSearchTagWrapper>
      )}
      {bountyType === "APPLICATION_BASED" && (
        <span className="application-based">Any member</span>
      )}
      <span style={{ fontWeight: "400" }}>with reward of </span>
      <AddBounty
        selectedTokenId={selectedTokenId}
        selectedTokenAmt={selectedTokenAmt}
        setSelectedTokenId={setSelectedTokenId}
        setSelectedTokenAmt={setSelectedTokenAmt}
      />
    </StyledDiv>
  );
};

export const CreateBountyTitle = ({
  status,
  setStatus,
  statuses = PROJECT_TASK_STATUSES,
}) => {
  return (
    <StyledBountyTitle status={status}>
      <Select
        defaultValue="TODO"
        style={{ width: "140px" }}
        onChange={(e) => setStatus(e)}
        value={status}
      >
        {statuses.map((status) => (
          <Option value={status}>{status}</Option>
        ))}
      </Select>
    </StyledBountyTitle>
  );
};

export const BountyFilesAttachment = ({ files, setFiles }) => {
  const [localFiles, setLocalFiles] = useState(files);
  useEffect(() => {
    setLocalFiles(files);
  }, [files.length]);
  const handleFiles = (id) => {
    setFiles((prev) => prev.filter((file) => file.lastModified !== id));
  };
  return (
    <StyledAttachment>
      <h4 className="attachment-title">Attachments:</h4>

      <div style={{ maxHeight: "70px", overflowY: "auto" }}>
        {localFiles.map((file, idx) => (
          <Tag
            style={{
              border: "1px solid #E2E8F0",
              background: "transparent",
              padding: "0.25rem 1rem",
              borderRadius: "1rem",
              marginBottom: "8px",
              display: "inline-flex",
              gap: "4px",
              alignItems: "center",
            }}
            onClose={() => handleFiles(idx)}
            key={idx}
          >
            <IconWrapper width="12px" style={{ marginRight: "5px" }}>
              <DocumentIcon />
            </IconWrapper>

            {getTrunckedString(file.name, 15)}
            <XMarkIcon
              style={{ width: "12px", cursor: "pointer" }}
              onClick={() => {
                handleFiles(file.lastModified);
              }}
            />
          </Tag>
        ))}
      </div>
    </StyledAttachment>
  );
};

const CreateBountyModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  canEdit,
}) => {
  const [gatingCondition, setGatingCondition] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [selectedTokenId, setSelectedTokenId] = useState("");
  const [selectedTokenAmt, setSelectedTokenAmt] = useState("");
  const [loading, setLoading] = useState(false);
  const { daoDetails, daoId } = useDaoContext();

  const getSkillList = () => {
    customAxios
      .get("bounty/skills")
      .then((res) => {
        setSkillsList(res.data.skills);
      })
      .catch((err) => {
        console.log(err);
        if (window.location.host.includes("localhost")) console.log("error");

        console.log("err", err);
      });
  };
  const getTagsList = () => {
    customAxios
      .get(`tags?daoId=${daoId}`)
      .then((res) => {
        setTagsList(
          res.data.tagsList.map((ele, idx) => ({ name: ele, id: idx }))
        );
      })
      .catch((err) => {
        console.log(err);
        if (window.location.host.includes("localhost")) console.log("error");

        console.log("err", err);
      });
  };
  const getAssigneeList = (daoId) => {
    customAxios
      .get(`bounty/directassignmentusers?daoId=${daoId}`)
      .then((res) => {
        setAssigneeList(res.data.directAssignmentUser);
      })
      .catch((err) => {
        console.log(err);
        if (window.location.host.includes("localhost")) console.log("error");

        console.log("err", err);
      });
  };

  const defaultState = {
    assignmentType: "ANY_MEMBER",
    subTasks: [],
    status: "todo",
    daoId: "8",
    type: "Bounty Submission",
    description: "",
  };

  const [status, setStatus] = useState("TODO");

  const [state, setState] = useState(() => defaultState);

  const [description, setDescription] = useState("");

  const [isGatingModalVisible, setGatingModalVisible] = useState(false);

  const [gatedValue, setGatedValue] = useState("ANY_MEMBER");
  const [linkedItems, setLinkedItems] = useState([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [reviewer, setReviewer] = useState(null);
  const [allSubtasks, setAllSubtasks] = useState([]);
  const [mentionedUsers, setMentionedUsers] = useState([]);
  // const [subtaskFiles, setSubtaskFiles] = useState({});
  const updateSubtasks = (subtaskBody, indexToUpdate = allSubtasks.length) => {
    let copyOfSubtasks = allSubtasks;
    let foundSubtask = copyOfSubtasks[indexToUpdate]
      ? copyOfSubtasks[indexToUpdate]
      : {};
    if (foundSubtask) {
      // ! If subtask already exists then update
      let subtaskToInsert = { ...foundSubtask, ...subtaskBody };
      copyOfSubtasks[indexToUpdate] = subtaskToInsert;
      setAllSubtasks([...copyOfSubtasks]);
    } else {
      // ! If subtask doesn't exist then create
      copyOfSubtasks.push(subtaskBody);
      setAllSubtasks([...copyOfSubtasks]);
    }
  };
  const deleteSubtasks = (subtaskBody) => {
    const modifiedSubtasks = allSubtasks.filter(
      (singleSubtask) => !(singleSubtask.title === subtaskBody.title)
    );
    setAllSubtasks(modifiedSubtasks);
  };
  useEffect(() => {
    getSkillList();
    getAssigneeList(daoId);
    getTagsList();
  }, [daoId]);
  const createBounty = (state) => {
    const formData = new FormData();
    let flag = 1;
    formData.append("type", bountyType);
    formData.append("status", status.split(" ").join(""));
    formData.append("daoId", daoId);
    if (bountyType === "DIRECT_ASSIGNMENT") {
      formData.append(
        "assignmentId",
        JSON.stringify([state.selectedAssignee.userId])
      );
    }
    if (bountyType === "DIRECT_ASSIGNMENT") {
      formData.append("assignmentType", "USER");
    } else formData.append("assignmentType", gatedValue);

    let selectedTags = tagsList
      .filter((ele) => ele.checked && !ele.isNew)
      .map((ele) => ele.name);
    let selectedNewTags = tagsList
      .filter((ele) => ele.checked && ele.isNew)
      .map((ele) => ele.name);

    let selectedSkills = skillsList
      .filter((ele) => ele.checked)
      .map((ele) => ele.name);
    if (selectedTags.length > 0) {
      formData.append("tags", JSON.stringify(selectedTags));
    }
    if (selectedNewTags.length > 0) {
      formData.append("newTags", JSON.stringify(selectedNewTags));
    }
    if (selectedSkills.length > 0) {
      formData.append("skills", JSON.stringify(selectedSkills));
    }
    if (allSubtasks) {
      let currentSubtasks = allSubtasks.map((item, index) => {
        item.type = bountyType;
        item.daoId = daoId;
        if (bountyType === "DIRECT_ASSIGNMENT") {
          item.assignmentType = "USER";
        } else item.assignmentType = gatedValue;

        item.reviewers = reviewer ? [reviewer?.userId] : null;
        if (!item.status) {
          item.status = "TODO";
        } else {
          item.status = item.status.split(" ").join("");
        }
        if (!item.reviewers || !item.reviewers[0]) delete item.reviewers;
        if (!item.priority) delete item.priority;
        if (item.files) {
          item.files.forEach((file) => {
            var blob = file.slice(0, file.size, file.type);
            let newFileName = file.name
              .split(".")
              .join(`_subtask${index + 1}.`);
            let newFile = new File([blob], newFileName, { type: file.type });
            return formData.append("files", newFile);
          });
          delete item.files;
        }
        return item;
      });
      formData.append("subTasks", JSON.stringify(currentSubtasks));
    }
    if (state.title) formData.append("title", state?.title);
    else {
      flag = 0;
      toast.error("Please enter the title for bounty");
      return;
    }
    if (reviewer)
      formData.append("reviewers", JSON.stringify([reviewer?.userId]));
    else {
      flag = 0;
      toast.error("Please choose a reviewer");
      return;
    }
    if (description) formData.append("description", description);
    else {
      flag = 0;
      toast.error("Please provide bounty description");
    }

    if (state?.submissionNote)
      formData.append("submissionNote", state?.submissionNote);
    if (state?.submissionDate)
      formData.append("submissionDate", state?.submissionDate);

    files.map((file) => {
      var blob = file.slice(0, file.size, file.type);
      let newFileName = file.name.split(".").join(`_main.`);
      let newFile = new File([blob], newFileName, { type: file.type });
      return formData.append("files", newFile);
    });

    if (gatedValue === "WITH_CONDITION") {
      formData.append("gatingCondition", JSON.stringify(conditionDetails));
    }
    if (selectedTokenAmt) formData.append("amount", selectedTokenAmt);
    if (selectedTokenId) formData.append("bountyTokenId", selectedTokenId);
    if (linkedItems.length) {
      formData.append(
        "linkedEntities",
        JSON.stringify(
          linkedItems.map((ele) => ({ linkedType: ele.type, linkedId: ele.id }))
        )
      );
    }
    if (mentionedUsers)
      formData.append("userTagged", JSON.stringify(mentionedUsers));
    if (flag) {
      setLoading(true);
      customAxios
        .post("/bounty/create", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setLoading(false);

          if (res.data.success) {
            toast.success("Bounty created successfully");
            postMixpanel("track", "created_bounty", {
              dao_id: daoId,
              bounty_title: state.title,
            });
            handleCancel();
            onSubmit();
            setState(defaultState);
            if (window.location.host.includes("localhost"))
              console.log("error");
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const count = useRef(1);

  const [bountyType, setBountyType] = useState("BOUNTY_SUBMISSION");
  const handleGatingCondition = (e) => {
    if (e === "ANY_MEMBER") {
      setGatingCondition(false);
    } else if (e === "WITH_CONDITION") {
      setGatingCondition(true);
      setGatingModalVisible(true);
    }
    setGatedValue(e);
  };

  const handleConditionedModal = () => {
    setGatingModalVisible(false);
  };
  const getDefaultCondition = (type) => {
    switch (type) {
      case "NFT_GATING":
        return {
          type: type,
          contractAddress: "",
          amount: "",
          contractType: "POLYGON",
        };
      case "TOKEN_GATING":
        return {
          type: type,
          contractType: "POLYGON",
          contractAddress: "",
          amount: "",
        };
      default:
        return {};
    }
  };

  const defaultConditionDetails = {
    operator: "AND",
    conditions: [
      {
        type: "TOKEN_GATING",
        contractType: "POLYGON",
        contractAddress: "",
        amount: "",
      },
    ],
  };
  const [conditionDetails, setConditionDetails] = useState(
    () => defaultConditionDetails
  );

  const [directAssignne, setDirectAssignee] = useState("");

  const [files, setFiles] = useState([]);
  const handleDirectAssignee = (e) => {
    setDirectAssignee(e.target.value);
  };
  const handleBountyType = (e) => {
    setBountyType(e);
  };
  const handleConditionDetailChange = (key, value, idx) => {
    const currList = conditionDetails?.conditions?.slice();
    currList[idx][key] = value;
    setConditionDetails((prev) => ({
      ...prev,
      conditions: currList.slice(),
    }));
  };
  const fileUploadRef = useRef(null);

  const tagDeleteHandler = (name) => {
    customAxios
      .delete(`tags/delete?daoId=${daoId}&tagName=${name}`)
      .then((res) => {
        toast.success("tag deleted successfully");
        getTagsList();
      })
      .catch((err) => {
        console.log("error");
      });
  };

  return (
    <StyledModalWrapper>
      {/* <WalkmeTour steps={steps} run={true} otherProps={{ continuous: true }} /> */}
      <Modal
        centered={true}
        width="840px"
        bodyStyle={{
          minHeight: "450px",
          maxHeight: "720px",
          overflowY: "scroll",
        }}
        destroyOnClose
        visible={isModalVisible}
        onCancel={confirmOperation({
          onOk: () => isModalVisible,
          cancelText: "Discard Changes",
          confirmText: "Continue Editing",
          message: " You will lose the progress if you close this pop-up?",
          title: "Confirm",
          onCancel: () => alert("Cnacel"),
        })}
        footer={[
          <Footer key="modal-footer" justifyContent="space-between">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Space className="footer-items">
                <input
                  hidden
                  ref={fileUploadRef}
                  type="file"
                  accept="image/*"
                  style={{ width: "0", height: "0" }}
                  onClick={() => {
                    fileUploadRef.current.value = null;
                  }}
                  onChange={(e) => {
                    const newFile = e.target.files[0];
                    checkFileSize(newFile.size);
                    setFiles((prev) => [...prev, newFile]);
                  }}
                />
                <Button
                  className="borderless-button"
                  shape="circle"
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                >
                  <IconWrapper width="18px">
                    <PaperClipIcon />
                  </IconWrapper>
                </Button>
                {/* <Button
                  type="secondary"
                  icon={
                    <PaperClipIcon
                      width="18px"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0",
                      }}
                    />
                  }
                  style={{ borderRadius: "12px", border: "0" }}
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                ></Button> */}
                <Flag
                  handleChange={(value) => {
                    setState((prev) => ({ ...prev, priority: value }));
                  }}
                />
                <DueDate
                  defaultValue={state?.submissionDate}
                  onChange={(date, time) => {
                    console.log(date, time);
                    if (date) {
                      const dateTime = moment(
                        date.format("YYYY-MM-DD") + ` ${time ? time : "23:59"}`
                      ).format("YYYY-MM-DD HH:mmZ");
                      setState((prev) => ({
                        ...prev,
                        submissionDate: dateTime,
                      }));
                    } else
                      setState((prev) => ({ ...prev, submissionDate: null }));
                  }}
                />
                {/* <TaskPoints /> */}
                {/* <Assignee
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              /> */}
                <ReviewerSelector
                  reviewer={reviewer}
                  setReviewer={setReviewer}
                />
                <LinkEntities
                  isModalVisible={linkModalVisible}
                  handleClose={() => {
                    setLinkModalVisible(false);
                  }}
                  linkedItems={linkedItems}
                  setLinkedItems={setLinkedItems}
                ></LinkEntities>
                <Tooltip title="Link the discussions, proposals or any entity that led to this bounty">
                  <Button
                    className="borderless-button"
                    shape="circle"
                    onClick={() => {
                      setLinkModalVisible(true);
                    }}
                  >
                    <IconWrapper>
                      <StackIcon />{" "}
                    </IconWrapper>
                    <span style={{ color: "#64748B", fontSize: "12px" }}>
                      {linkedItems.length ? `(${linkedItems.length})` : null}
                    </span>
                  </Button>
                </Tooltip>
              </Space>

              <Button
                className="rounded-corner submit-bounty-button"
                type="primary"
                disabled={loading}
                style={{ marginLeft: "50px" }}
                onClick={() => createBounty(state)}
              >
                Create
              </Button>
            </Row>
          </Footer>,
        ]}
      >
        <Input.TextArea
          autoFocus={true}
          style={{
            paddingLeft: "20px",
            fontSize: "21px",
            paddingRight: "35px",
            fontWeight: "600",
            margin: "10px 0",
            marginTop: "24px",
          }}
          autoSize={{ minRows: "1", maxRows: "3" }}
          placeholder="Add the title of your bounty here"
          // size="large"
          maxLength={70}
          className="ip-address bounty-title"
          value={state?.title}
          onChange={(e) => {
            setState((prev) => ({ ...prev, title: e.target.value }));
          }}
          bordered={false}
        />
        <BountyCreation
          state={state}
          setState={setState}
          assigneeList={assigneeList}
          setGatingCondition={setGatingCondition}
          helperText={
            <Button onClick={() => setGatingModalVisible(true)}>
              Review condition
            </Button>
          }
          conditionDetails={conditionDetails}
          gatingCondition={gatingCondition}
          handleGatingCondition={handleGatingCondition}
          count={count.current}
          handleBountyType={handleBountyType}
          bountyType={bountyType}
          handleDirectAssignee={handleDirectAssignee}
          gatedValue={gatedValue}
          selectedTokenId={selectedTokenId}
          selectedTokenAmt={selectedTokenAmt}
          setSelectedTokenId={setSelectedTokenId}
          setSelectedTokenAmt={setSelectedTokenAmt}
        />

        <Modal
          destroyOnClose
          title={
            <StyledConditionedModalTitle>
              <img src={ConditionedTitle} className="conditioned-title" />
              <p>With Condition</p>
            </StyledConditionedModalTitle>
          }
          centered={true}
          width="600px"
          bodyStyle={{ minHeight: "auto" }}
          visible={isGatingModalVisible}
          onCancel={() => {
            setGatingModalVisible(false);

            setGatingCondition(false);
            setGatedValue("ANY_MEMBER");
            setConditionDetails(defaultConditionDetails);
          }}
          footer={[
            <Footer key="modal-footer" justifyContent="right">
              <Button
                className="rounded-corner"
                onClick={() => {
                  setGatingModalVisible(false);

                  setGatingCondition(false);
                  setGatedValue("ANY_MEMBER");
                  setConditionDetails(defaultConditionDetails);
                }}
              >
                Cancel
              </Button>
              <Button
                className="rounded-corner"
                type="primary"
                onClick={handleConditionedModal}
              >
                Save
              </Button>
            </Footer>,
          ]}
        >
          <StyledAntBodyWrapper>
            <Row xs={24} style={{ padding: "0px 10px" }}>
              {conditionDetails.conditions.map((ele, idx) => {
                return (
                  <Col
                    xs={24}
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {idx ? (
                      <StyledRowWrapper>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                          className="radio-btn"
                        >
                          <Radio.Group
                            options={[
                              { label: "And", value: "AND" },
                              { value: "OR", label: "Or" },
                            ]}
                            buttonStyle="solid"
                            onChange={(e) => {
                              const currCondition = { ...conditionDetails };
                              currCondition["operator"] = e.target.value;
                              setConditionDetails({ ...currCondition });
                            }}
                            value={conditionDetails.operator}
                            optionType="button"
                            style={{ borderRadius: "0px 12px 12px 0px" }}
                            className="radio-btn"
                          />
                        </Row>
                      </StyledRowWrapper>
                    ) : null}

                    <StyledGatingCondition>
                      <Row>
                        {conditionDetails.conditions.length > 1 ? (
                          <StyledBin
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              const currCondition = { ...conditionDetails };
                              currCondition.conditions.splice(idx, 1);
                              setConditionDetails({ ...currCondition });
                            }}
                          >
                            <Bin className="bin" />
                          </StyledBin>
                        ) : null}
                        <Select
                          className="gating-type"
                          style={{ width: "100%" }}
                          value={ele.type}
                          onChange={(e) => {
                            const currList =
                              conditionDetails?.conditions?.slice();
                            currList[idx] = getDefaultCondition(e);

                            setConditionDetails((prev) => ({
                              ...prev,
                              conditions: currList.slice(),
                            }));
                          }}
                        >
                          <Option value="TOKEN_GATING"> Token Gating</Option>
                          <Option value="NFT_GATING">NFT Gating</Option>
                        </Select>{" "}
                      </Row>
                      {ele.type === "NFT_GATING" ? (
                        <Row
                          style={{
                            flexFlow: "nowrap",
                            padding: "2rem 12px 15px 12px",
                            borderRadius: "12px",
                            background: "#eeeeee",
                            gap: "8px",
                          }}
                        >
                          <Select
                            style={{ minWidth: "10rem" }}
                            value={ele.contractType}
                            defaultValue="POLYGON"
                            onChange={(e) => {
                              handleConditionDetailChange(
                                "contractType",
                                e,
                                idx
                              );
                            }}
                          >
                            {tokenOptions.map((ele) => (
                              <Option value={ele.value}>{ele.label}</Option>
                            ))}
                          </Select>
                          <Input
                            value={ele.contractAddress}
                            placeholder={
                              ele.contractType === "SOLANA"
                                ? "Enter mint address"
                                : "Enter nft address"
                            }
                            className="ip-address"
                            onChange={(e) => {
                              handleConditionDetailChange(
                                "contractAddress",
                                e.target.value,
                                idx
                              );
                            }}
                          ></Input>
                        </Row>
                      ) : (
                        <Row
                          style={{
                            columnGap: "1rem",
                            flexFlow: "nowrap",
                            padding: "2rem 12px 15px 12px",
                            borderRadius: "12px",
                            background: "#eeeeee",
                          }}
                        >
                          <Col>
                            <Select
                              value={ele.contractType}
                              style={{ minWidth: "10rem" }}
                              defaultValue="POLYGON"
                              onChange={(e) => {
                                handleConditionDetailChange(
                                  "contractType",
                                  e,
                                  idx
                                );
                              }}
                            >
                              {tokenOptions.map((ele) => (
                                <Option value={ele.value}>{ele.label}</Option>
                              ))}{" "}
                            </Select>
                          </Col>
                          <Col>
                            <Input
                              value={ele.contractAddress}
                              placeholder="Enter token address"
                              className="ip-address"
                              onChange={(e) => {
                                handleConditionDetailChange(
                                  "contractAddress",
                                  e.target.value,
                                  idx
                                );
                              }}
                            ></Input>{" "}
                          </Col>
                          <Col>
                            <Input
                              value={ele.amount}
                              placeholder="Enter no. of tokens"
                              className="ip-address"
                              onChange={(e) => {
                                handleConditionDetailChange(
                                  "amount",
                                  e.target.value,
                                  idx
                                );
                              }}
                            ></Input>{" "}
                          </Col>
                        </Row>
                      )}
                    </StyledGatingCondition>
                  </Col>
                );
              })}

              {conditionDetails?.conditions?.length < 4 ? (
                <Row style={{ margin: "0px auto", marginBottom: "10px" }}>
                  <Button
                    type="primary"
                    style={{
                      background: "white",
                      border: "none",
                      color: "#1890ff",
                      margin: "0 auto",
                      textShadow: "none",
                      boxShadow: "none",
                    }}
                    icon={
                      <i
                        className="fas fa-plus"
                        style={{ marginRight: "0.5rem" }}
                      />
                    }
                    onClick={() => {
                      const currCondition = conditionDetails.conditions.slice();
                      currCondition.splice(
                        currCondition.length,
                        0,
                        getDefaultCondition("TOKEN_GATING")
                      );
                      setConditionDetails((prev) => ({
                        ...prev,
                        conditions: currCondition,
                      }));
                    }}
                  >
                    Add another condition
                  </Button>
                </Row>
              ) : null}
            </Row>
          </StyledAntBodyWrapper>
        </Modal>
        <Row
          xs={24}
          style={{
            rowGap: "4px",
            // flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GenericTagSelect
            key={1}
            canEdit={canEdit}
            showRemoveTagIcon={true}
            onTagDelete={(name) => tagDeleteHandler(name)}
            skillsList={tagsList}
            setSkillsList={setTagsList}
            selectedTagColor="#64748B"
            className="bounty-description"
            // title={
            //   <h4 style={{ color: "#64748B", display: 'inline-block', marginRight: '4px' }}>
            //     <TagIcon
            //       height="16px"
            //       width="16px"
            //       style={{ marginRight: "4px" }}
            //     />
            //     Tags:
            //   </h4>
            // }
            addButtonText={"Add tags"}
            searchText="Search tags..."
            addNewButtonText={"Add new tag"}
            AddIcon={() => <TagIcon height="16px" width="16px" />}
          />
          <GenericTagSelect
            key={2}
            // selectedTagColor="red"
            selectedTagColor="#64748B"
            // title={
            //   <h4 style={{ color: "#64748B", display: 'inline-block', marginRight: '4px' }}>
            //     <i
            //       class="fas fa-medal"
            //       style={{ fontSize: "16px", marginRight: "4px" }}
            //     ></i>
            //     Skills:
            //   </h4>
            // }
            skillsList={skillsList}
            disableAddNewButton={true}
            setSkillsList={setSkillsList}
          />{" "}
        </Row>
        {/* <StyledQuillEditior> */}
        <QuillEditor
          value={description}
          // className="bounty-description"
          onChange={(e) => setDescription(e)}
          height="200px"
          style={{
            padding: "0px 0px 4px 0px",
            resize: "none",
            overflowY: "auto",
          }}
          isBordered={true}
          placeholder="Add details of your bounty here"
          setMentionedUsers={setMentionedUsers}
        />
        {/* </StyledQuillEditior> */}
        {files.length > 0 && (
          <BountyFilesAttachment files={files} setFiles={setFiles} />
        )}

        {allSubtasks.length ? (
          <h4 style={{ margin: "0px 8px 2px 8px", padding: "6px 14px" }}>
            Checklist:
          </h4>
        ) : null}
        {allSubtasks.map((subtask, order) => (
          <SubtaskCardView
            subtask={subtask}
            updateSubtasks={updateSubtasks}
            deleteSubtasks={deleteSubtasks}
            order={order}
          />
        ))}

        <CreateSubtaskInput
          addSubtaskHandler={updateSubtasks}
          allSubtasks={allSubtasks}
        />
      </Modal>
    </StyledModalWrapper>
  );
};

export default CreateBountyModal;

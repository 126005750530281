import React from "react";
import { useNavigate } from "react-router";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import { CollectionType } from "../../../utils/Documents";
import { HoverCard } from "../../Course/CourseContent/CourseContentCard";

type Props = {
  collection: CollectionType;
};

const CollectionCard = ({ collection }: Props) => {
  const navigate = useDaoLensNavigate();
  const { daoDetails, daoId, daoName } = useDaoContext();

  return (
    <HoverCard
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px",
        border: "1px solid #E2E8F0",
        borderRadius: "12px",
        cursor: "pointer",
      }}
      onClick={() =>
        navigate(`/app/dao/${daoName}/${daoId}/library/${collection.id}`)
      }
    >
      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        <div
          style={{
            font: "normal 500 10px/11px Inter",
            width: "20px",
            height: "20px",
            background: collection.background,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
          }}
        >
          {collection.icon}
        </div>
        <p style={{ margin: 0 }}>{collection.title}</p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ margin: "0" }}>📕 {collection.totalDocuments}</p>
      </div>
    </HoverCard>
  );
};

export default CollectionCard;

import axios from "axios";
// import { config } from '../views/helper';
// import { store } from "../redux/index";
// import { logout } from "../redux/actions/auth.actions";
// const axios = require('axios');

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.

const PRODUCTION_BASE_URL = "https://b677-182-77-51-193.in.ngrok.io/";
const DEVELOPMENT_BASE_URL = "https://stagingbackend.daolens.com/";
const customAxios = axios.create({
  baseURL:
    process.env.REACT_APP_ENDPOINT === "local"
      ? "http://localhost:3001/"
      : process.env.REACT_APP_ENDPOINT === "development"
      ? "https://contribution-backend-dev-5dzspnb6za-el.a.run.app/"
      : process.env.REACT_APP_ENDPOINT === "staging"
      ? "https://backend-contribution-staging-5dzspnb6za-el.a.run.app/"
      : "https://contribution-backend-5dzspnb6za-uc.a.run.app/",
});

// Step-2: Create request, response & error handler
const requestHandler = (request) => {
  const token = localStorage.getItem("access_token");

  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  if (token) {
    const config = {
      Authorization: "Bearer " + token,
      // domain_url: `https://${window.location.host}`,
      // "ngrok-skip-browser-warning": true,
    };

    request.headers = config;
  }

  return request;
};

const errorHandler = (error) => {};

// // Step-3: Configure/make use of request & response interceptors from Axios
// // Note: You can create one method say configureInterceptors, add below in that,
// // export and call it in an init function of the application/page.
customAxios.interceptors.request.use((request) => requestHandler(request));
export default customAxios;

import {
  MagnifyingGlassIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Button, Card, Input, Space, Tooltip } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { PopupCard } from "../../styles/PopupCard";
import { Feild } from "../../styles/Feild";
import IconWrapper from "../IconWrapper";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import { LabelIcon } from "../../styles/LabelIcon";
import { CloseCircleFilled } from "@ant-design/icons";
import customAxios from "../../api";
import { useParams } from "react-router";
import { useDaoContext } from "../../layouts/app/DaoContext";

const MultipleAssigneeSelector = ({
  assignees,
  setAssignees,
  setUpdatedFieldsNames = () => {},
  pillBg = "#fff",
  isCardOpen,
  setIsCardOpen,
}) => {
  const [showCard, setShowCard] = React.useState(false);
  const ref = React.useRef();
  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [query, setQuery] = useState("");
  useEffect(() => {
    setFilterUsers(
      users.filter((ele) => ele.userName?.toLowerCase().includes(query))
    );
  }, [query, users]);
  // Call hook passing in the ref and a function to call on outside click
  useEffect(() => {
    setShowCard(isCardOpen);
  }, [isCardOpen]);

  useOnClickOutside(ref, () => {
    setShowCard(false);
    setIsCardOpen(false);
  });
  const { daoDetails, daoId } = useDaoContext();

  const fetchUsers = async () => {
    try {
      const res = await customAxios.get(`/users/daousers?daoId=${daoId}`);

      const usersFromDb = await res?.data?.daoUser;
      setUsers([...usersFromDb]);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const toggleUser = (user) => {
    setAssignees((prev) => {
      if (!prev) return [user];
      return prev?.find((stateUser) => stateUser?.id === user.id)
        ? prev?.filter((stateUser) => stateUser.id !== user?.id)
        : [...prev, user];
    });
  };

  const handleUserClick = (user) => {
    setUpdatedFieldsNames();
    toggleUser(user);
    setShowCard(false);
  };

  return (
    <PopupCard width="240px">
      {showCard && (
        <Card className="card" ref={ref}>
          <Input
            placeholder="Search"
            value={query}
            autoFocus={true}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            style={{
              minHeight: "32px",
              borderRadius: "12px",
              margin: "4px 0px",
            }}
            prefix={
              <IconWrapper>
                <MagnifyingGlassIcon />
              </IconWrapper>
            }
          />{" "}
          <Space
            direction="vertical"
            style={{
              gap: "4px",
              rowGap: "4px",
              columnGap: "4px",
              maxHeight: "250px",
              overflowY: "auto",
              minHeight: "250px",
              width: "100%",
            }}
          >
            {!filterUsers.length ? (
              <h4 style={{ textAlign: "center" }}>No users found</h4>
            ) : null}
            {filterUsers.map((user) => (
              <Feild
                key={user}
                onClick={() => {
                  handleUserClick(user);
                  setIsCardOpen(false);
                }}
              >
                <img
                  style={{
                    width: "22px",
                    borderRadius: "50%",
                    height: "22px",
                    objectFit: "cover",
                  }}
                  src={user?.profilePicture ? user?.profilePicture : dummyUser}
                  alt="icon"
                />{" "}
                <span>
                  {user?.userName?.slice(0, 16)}
                  {user?.userName?.length > 16 ? "..." : null}
                </span>
              </Feild>
            ))}
          </Space>
        </Card>
      )}
      {assignees.length > 0 ? (
        <div style={{ display: "flex", gap: "6px" }}>
          {assignees?.map((assignee) => (
            <LabelIcon
              style={{ background: pillBg, borderRadius: "0px" }}
              onClick={() => {
                setShowCard(true);
              }}
              height={"24px"}
              padding="0px"
            >
              <Fragment key={assignee.id}>
                <Tooltip title={assignee?.userName} placement="left">
                  <img
                    src={
                      assignee.profilePicture
                        ? assignee.profilePicture
                        : dummyUser
                    }
                    alt="icon"
                    height={"20px"}
                    style={{
                      width: "22px",
                      borderRadius: "50%",
                      height: "22px",
                      objectFit: "cover",
                    }}
                  />
                </Tooltip>

                <Button
                  className="borderless-button"
                  shape="circle"
                  size="small"
                  icon={<CloseCircleFilled />}
                  onClick={() => {
                    setUpdatedFieldsNames();
                    toggleUser(assignee);
                  }}
                />
              </Fragment>
            </LabelIcon>
          ))}
        </div>
      ) : (
        <Button
          onClick={() => {
            setShowCard(true);
          }}
          className="borderless-button"
          style={{
            margin: "auto 0",
            display: "inline-flex",
            alignItems: "center",
            height: "100%",
            minWidth: "0px",
          }}
          shape="circle"
        >
          <UserCircleIcon width={18} style={{ margin: "0" }} />
        </Button>
      )}
    </PopupCard>
  );
};

export default MultipleAssigneeSelector;

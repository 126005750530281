import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import BountyDetails from "../../components/BountyComponents/BountyDetails";
import customAxios from "../../api";
import SubtaskDetails from "../../components/BountyComponents/SubtaskDetails";
import { useDaoContext } from "../../layouts/app/DaoContext";

const SubtaskDetailsPage = () => {
  const [bountyDetails, setBountyDetails] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [rerender, setRerender] = useState(0);
  const { bountyId, subtaskId } = useParams();

  const { daoDetails, daoId } = useDaoContext();
  

  useEffect(() => {
    customAxios
      .get(`/bounty/fetch/details?bountyId=${bountyId}`)
      .then((res) => {
        setCanSubmit(res.data.bountyDetail[0]?.canSubmit);
      })
      .catch((err) => console.log(err));
    customAxios
      .get(`/bounty/subtaskList?bountyId=${bountyId}`)
      .then((res) => {
        // console.log("subtasks", res.data);
        let foundSubtask = res.data.subTaskList.find(
          (singleSubtask) => singleSubtask.bountyId == subtaskId
        );
        setBountyDetails(foundSubtask);
      })
      .catch((err) => console.log(err));
  }, [rerender]);

  return (
    <div>
      <div style={{ height: 20 }} />
      {bountyDetails && (
        <SubtaskDetails
          bountyDetails={bountyDetails}
          bountyId={bountyId}
          daoId={daoId}
          subtaskId={subtaskId}
          canSubmit={canSubmit}
          setRerender={setRerender}
        />
      )}
    </div>
  );
};

export default SubtaskDetailsPage;

import React from "react";
import "./InternalComponentStyles/SecondaryButtonOutlined.scss";
function SecondaryButtonOutlined({ buttonText, clickHandler, style, icon }) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        clickHandler();
      }}
      className="secondary-button-outlined"
      style={style}
    >
      {icon && <img src={icon} />}
      <span className="secondary-button-outlined__text">{buttonText}</span>
    </div>
  );
}

export default SecondaryButtonOutlined;

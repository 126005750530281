import styled from "styled-components";

export const LabelIcon = styled.div`
  height: ${(props) => props.height || "32px"};
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font: normal 500 14px/20px Inter;
  color: #64748b;
  padding: ${(props) => props.padding || "5px 12px"};
  background: ${(props) => props.background || "rgb(241, 245, 249)"};
  border-radius: 100px;
  gap: 8px;
  position: relative;
  cursor: pointer;

  button {
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
  }

  // &:hover button {
  //   display: block;
  // }
`;

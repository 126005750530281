import {
  Button,
  Card,
  Checkbox,
  Input,
  message,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import IconWrapper from "./IconWrapper";
import { PlusIcon, TagIcon } from "@heroicons/react/24/outline";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { v4 as uuidv4 } from "uuid";
import customAxios from "../api";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  padding-left: 20px;
  & .padding-zero {
    padding: 0;
    font-size: 12px;
    font-weight: 600;
  }
  > span {
    border: none;
    border-radius: 0.5rem;
  }
`;

const AddSearchTagWrapper = styled.div`
  display: inline-block;
  position: relative;
  & .ant-card-body {
    padding: 10px;
  }
  & .tag-card {
    width: 218px;
    position: absolute;
    top: 120%;
    transition: 300ms all;
    border-radius: 1rem;
    z-index: 1;
    box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
  }
  & .search-input {
    border-radius: 0.6rem;
  }
  .padding-zero {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .padding-zero > span {
    margin: 0;
  }
`;

const newTag = () => {};

const Tags = ({ selectedAllTags, setSelectedAllTags }) => {
  const [tags, setTags] = useState([
    { id: 1, color: "red", value: "One" },
    { id: 2, color: "blue", value: "Two" },
    { id: 3, color: "green", value: "Three" },
    { id: 4, color: "orange", value: "Four" },
    { id: 5, color: "purple", value: "Five" },
  ]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddNewTagInput, setShowAddNewTagInput] = useState(false);
  const [addNewTagInput, setAddnewTagInput] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [tagsFromDB, setTagsFromDB] = useState([]);

  const ref = useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setShowDropdown(false));

  const addNewTagHandler = () => {
    setShowAddNewTagInput(false);
    setTags([
      ...tags,
      { id: tags.length + 1, color: "Grey", value: addNewTagInput },
    ]);
    setAddnewTagInput("");
  };

  const tagSelectionHandler = (e, tag) => {
    // console.log(e, tag);
    e.target.checked
      ? setSelectedTags([...selectedTags, tag])
      : setSelectedTags(selectedTags.filter((oldTag) => oldTag !== tag));
  };

  const tagSearchHandler = (searchText) =>
    searchText === ""
      ? tagsFromDB
      : tagsFromDB.filter((tag) => tag.value.indexOf(searchInput) !== -1);

  const handleNewTags = (e, newTag) => {
    const uuid = uuidv4();
    setSelectedAllTags([...selectedAllTags, ...selectedTags, newTag]);
  };

  // console.log(tagsFromDB);
  const handleAddTags = async () => {
    setShowDropdown(!showDropdown);

    customAxios
      .get("tags")
      .then((res) => {
        setTagsFromDB(res.data.tagsList);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };

  return (
    <Wrapper>
      {selectedTags.map((tag) => {
        const isLongTag = tag.length > 20;
        const tagElem = (
          <Tag color="purple">{isLongTag ? `${tag.slice(0, 20)}...` : tag}</Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      <AddSearchTagWrapper>
        <Button
          className="padding-zero"
          type="link"
          size="small"
          onClick={handleAddTags}
        >
          <IconWrapper>
            <TagIcon height="16px" width="16px" />
          </IconWrapper>{" "}
          Add Tags
        </Button>
        {showDropdown && (
          <Card className="tag-card" ref={ref}>
            <Space direction="vertical">
              <Input
                className="search-input"
                placeholder="Search tags..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              {tagsFromDB &&
                tagsFromDB?.map((tag) => (
                  <Checkbox onChange={(e) => tagSelectionHandler(e, tag)}>
                    {tag}
                  </Checkbox>
                ))}
              {showAddNewTagInput && (
                <Space>
                  <Checkbox
                    onChange={(e) => handleNewTags(e, addNewTagInput)}
                  />
                  <Input
                    placeholder="Enter tag name"
                    bordered={false}
                    autoFocus={true}
                    className="padding-zero"
                    value={addNewTagInput}
                    onChange={(e) => setAddnewTagInput(e.target.value)}
                    onPressEnter={addNewTagHandler}
                  />
                </Space>
              )}
              <Button
                className="padding-zero"
                type="link"
                icon={
                  <IconWrapper>
                    <PlusIcon />
                  </IconWrapper>
                }
                onClick={() => setShowAddNewTagInput(true)}
              >
                Add new tag
              </Button>
            </Space>
          </Card>
        )}
      </AddSearchTagWrapper>
    </Wrapper>
  );
};

export default Tags;

import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Menu, Row, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import customAxios from "../../../api";
import { DaoContext } from "../../../layouts/app/DaoContext";
import { getContentType, HoverCard } from "../CourseContent/CourseContentCard";
import CreateDocumentModal, { Stylediv } from "./CreateDocumentModal";
import DocumentCard from "./DocumentCard";
import useWindowSize from "../../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../../constants";
import { useNavigate } from "react-router";
import EmptyComponent from "../EmptyComponent";
import { StyledInput } from "../CoursePage";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";

let sections = {
  ALL: "All",
  COLLECTION: "Folder",
  SINGLE_DOCUMENT: "File",
};
const DocumentFeed = () => {
  const [query, setQuery] = useState("");
  const [currentSection, setCurrentSection] = useState("ALL");
  const { isAdmin, daoDetails } = useContext(DaoContext);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [collection, setCollections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    setLoading(true);
    customAxios
      .get(
        `/documents/fetch/?type=${currentSection}&title=${query}&daoId=${daoDetails?.daoId}`
      )
      .then((res) => {
        setDocuments(res.data.document);
        setCollections(res.data.collection);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentSection, query, update, daoDetails?.daoId]);
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const navigate = useDaoLensNavigate();

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: isMobile ? "12px" : "0px",
        }}
      >
        <StyledInput
          placeholder="Search documents"
          className="search-courses"
          allowClear
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          prefix={
            <SearchOutlined
              style={{ color: "#94A3B8" }}
              className="site-form-item-icon"
            />
          }
          style={{
            padding: "8px 18px",
            borderRadius: "12px",
            borderColor: "#f0f0f0",
            fontWeight: "600",
          }}
        />
        {isAdmin ? (
          <Button
            className="create-new-course"
            type="primary"
            style={{
              padding: "18px 21px",
              borderRadius: "12px",
              background: "#3B82F6",
              fontWeight: 600,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "12px",
            }}
            onClick={() => {
              setCreateModalVisible(true);
            }}
          >
            Add document
          </Button>
        ) : null}
        <CreateDocumentModal
          onSubmit={() => {
            setUpdate((prev) => !prev);
          }}
          isModalVisible={createModalVisible}
          handleCancel={() => setCreateModalVisible(false)}
        />
      </div>

      <div
        style={{
          font: "normal 600 12px/16px Inter",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            font: "normal 500 14px/20px Inter",
            flexWrap: "wrap",
            color: "#64748B",
            marginBottom: "12px",
          }}
        >
          {Object.entries(sections).map(([section, label]) => (
            <Stylediv
              key={section}
              onClick={() => {
                setCurrentSection(section);
              }}
              style={{
                padding: "4px 12px",
                border: `1px solid ${
                  currentSection === section ? "#76A9FA" : "#E5E7EB"
                }`,
                borderRadius: "40px",
                cursor: "pointer",
                color: currentSection === section ? "#3F83F8" : "",
                background: currentSection === section ? "#E1EFFE" : "",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {label}
            </Stylediv>
          ))}
        </div>
        {loading && <Skeleton active />}
        {!loading &&
          collection.length === 0 &&
          documents.length === 0 &&
          currentSection === "ALL" && (
            <EmptyComponent text="No document added yet" />
          )}
        {!loading &&
          documents.length === 0 &&
          currentSection === "SINGLE_DOCUMENT" && (
            <EmptyComponent text="No file added yet" />
          )}
        {!loading &&
          collection.length === 0 &&
          currentSection === "COLLECTION" && (
            <EmptyComponent text="No folder added yet" />
          )}

        {currentSection !== "SINGLE_DOCUMENT" &&
          !loading &&
          collection.length > 0 && (
            <>
              {currentSection === "ALL" ? "Folder" : ""}

              <Row gutter={[16, 16]}>
                {collection.map((collection) => (
                  <Col xs={isMobile ? 24 : 12}>
                    <HoverCard
                      style={{
                        background: "white",
                        padding: "20px",
                        borderRadius: "12px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        navigate(
                          `/app/dao/${daoDetails.daoName}/${daoDetails?.daoId}/library/${collection.id}`
                        );
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          marginBottom: "12px",
                        }}
                      >
                        <div
                          style={{
                            font: "normal 500 28px/32px Inter",
                            width: "56px",
                            height: "56px",
                            background: collection.background,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "8px",
                          }}
                        >
                          {collection.icon}
                        </div>
                        <span
                          style={{
                            display: "inline-flex",
                            fontSize: "12px",
                            alignItems: "center",
                            padding: "5px 10px",
                            border: "1px solid #E2E8F0",
                            borderRadius: "30px",
                            gap: "4px",
                          }}
                        >
                          📕 {collection.totalDocuments}
                        </span>
                      </div>
                      <div
                        style={{
                          font: "normal 500 16px/24px Inter",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {collection.title}
                      </div>
                    </HoverCard>
                  </Col>
                ))}
              </Row>
            </>
          )}
        {currentSection !== "COLLECTION" &&
          !loading &&
          documents.length > 0 && (
            <>
              {currentSection === "ALL" ? "File" : ""}
              {documents.map((document) => (
                <DocumentCard
                  document={document}
                  reload={() => {
                    setUpdate((prev) => !prev);
                  }}
                />
              ))}
            </>
          )}
      </div>
    </div>
  );
};

export default DocumentFeed;

import { Button, Select as AntSelect } from "antd";
import { Footer, Modal } from "../../styles/ModalStyles";
import { StyledModalWrapper } from "../CreateBountyModal";
import background from "../../assets/images/profile/cover.png";
import InputField, { labeStyling } from "./InputField";
import { useEffect, useState, useRef, useContext } from "react";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import styled from "styled-components";
import PencilIcon from "../../assets/images/profile/pencil.svg";
import { isValidHttpUrl } from "../Settings/DaoProfile/EditProfileModal";
import { ReactComponent as Twitter } from "../../assets/images/profile/twitter.svg";
import { ReactComponent as Github } from "../../assets/images/profile/github.svg";
import { ReactComponent as Website } from "../../assets/images/profile/website.svg";
import { UserContext } from "../../layouts/app/UserContext";
import {
  checkFileSize,
  checkImageType,
  getUsernameFromUrl,
  getWebsiteVal,
} from "../../utils";
import useWindowSize from "../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../constants";

const Select = styled(AntSelect)`
  .ant-select-selector {
    border-radius: 8px !important;
    display: flex !important;
    align-items: center !important;
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    max-height: 90px !important;
    overflow-y: auto;
  }
`;

const EditProfileModal = ({ isModalVisible, handleClose }) => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;

  const [form, setForm] = useState({});
  const [skills, setSkills] = useState([]);
  const [userName, setUserName] = useState("");
  const [coverUpdated, setCoverUpdated] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [coverFocus, setCoverFocus] = useState(false);
  const [profileFocus, setProfileFocus] = useState(false);
  const [isUserNameAvailable, setUserNameAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const { userDetails, reload } = useContext(UserContext);
  const coverref = useRef(null);
  const profileref = useRef(null);
  const updateProfile = () => {
    const formData = new FormData();
    if (form.name.includes(" ")) {
      return toast.error("Username cannot contain space");
    }
    if (form.name && form.name !== userName)
      formData.append("userName", form.name);
    if (form.profileName) formData.append("profileName", form.profileName);
    if (!form.description || !form.name || !form.profileName) {
      return toast.error("Please fill the mandatory fields");
    }

    if (!isUserNameAvailable) {
      return toast.error("Username is already taken");
    }

    if (form.description) formData.append("description", form.description);
    if (form.website && !isValidHttpUrl(`https://${form.website}`)) {
      toast.error("invalid website url");
      return;
    }

    if (form.twitter)
      formData.append("twitter", `https://twitter.com/${form.twitter}`);
    if (form.github)
      formData.append("github", `https://github.com/${form.github}`);
    if (form.website) formData.append("website", `https://${form.website}`);
    formData.append("skills", JSON.stringify(form.skills));
    if (coverUpdated) {
      let coverFile = new File(
        [form.cover.slice(0, form.cover.size, form.cover.type)],
        form.cover.name.split(".").join(`_background.`),
        { type: form.cover.type }
      );
      formData.append("files", coverFile);
    }
    if (profileUpdated) {
      let profileImage = new File(
        [form.profile.slice(0, form.profile.size, form.profile.type)],
        form.profile.name.split(".").join(`_profile.`),
        { type: form.profile.type }
      );
      formData.append("files", profileImage);
    }
    customAxios
      .put("/users/profile/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("User profile updated successfully");
          reload();
          handleClose();
        } else console.log("error");
      })
      .catch((err) => toast.error("Some error occured"));
    handleClose();
  };
  useEffect(() => {
    if (isModalVisible) {
      setForm({
        name: userDetails.userName,
        profileName: userDetails.profileName,
        description: userDetails.description,
        github: userDetails.github?.split("https://github.com/")[1],
        twitter: userDetails.twitter?.split("https://twitter.com/")[1],
        website: userDetails.website?.split("https://")[1],
        cover: userDetails.backgroundImage,
        profile: userDetails.profilePicture,
        skills: userDetails.skills ? userDetails.skills : [],
      });
      setUserName(userDetails.userName);
    }

    (async () => {
      const {
        data: { skills },
      } = await customAxios.get(`/bounty/skills`);
      setSkills(skills);
    })();
  }, [isModalVisible]);
  useEffect(() => {
    if (form.name && form.name !== userName) {
      setLoading(true);
      customAxios
        .get(`users/username/available`, { params: { userName: form.name } })
        .then(({ data }) => {
          setUserNameAvailable(data.isUserNameAvailable);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [form.name]);

  return (
    <StyledModalWrapper style={{ borderRadius: "12px" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          console.log(e);
        }}
      >
        <Modal
          width="600px"
          destroyOnClose
          title={
            <div
              style={{
                marginLeft: "8px",
                marginBottom: "16px",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "24px",
              }}
            >
              Edit Profile
            </div>
          }
          isMobile={isMobile}
          visible={isModalVisible}
          onCancel={handleClose}
          footer={[
            <Footer
              key="modal-footer"
              justifyContent={isMobile ? "left" : "right"}
            >
              <Button
                style={{ border: "1px solid #DDDDDD" }}
                className="rounded-corner"
                type="text"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                className="rounded-corner"
                type="primary"
                disabled={loading}
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  updateProfile();
                }}
              >
                Save
              </Button>
            </Footer>,
          ]}
        >
          <div
            style={{
              overflowY: "auto",
              maxHeight: "500px",
              position: "relative",
              borderTop: "1px solid #f0f0f0",
            }}
          >
            <img
              src={`${
                form.cover
                  ? coverUpdated
                    ? URL.createObjectURL(form.cover)
                    : form.cover
                  : background
              }`}
              style={{
                width: "100%",
                position: "absolute",
                objectFit: "cover",
                left: 0,
                background: "white",
                height: "112px",
              }}
              onMouseEnter={() => {
                setCoverFocus(true);
              }}
            />
            {coverFocus && (
              <div
                style={{
                  height: "112px",
                  background: "black",
                  opacity: 0.5,
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  display: "flex",
                }}
                onClick={() => coverref.current.click()}
                onMouseLeave={() => {
                  setCoverFocus(false);
                }}
              >
                {" "}
                <img src={PencilIcon} alt="edit" />
              </div>
            )}
            {form.profile ? (
              <img
                src={`${
                  form.profile
                    ? profileUpdated
                      ? URL.createObjectURL(form.profile)
                      : form.profile
                    : background
                }`}
                style={{
                  objectFit: "cover",
                  width: "110px",
                  height: "110px",
                  position: "absolute",
                  left: "24px",
                  top: "78px",
                  borderRadius: "50%",
                  border: "2px solid white",
                  background: "white",
                  padding: "-1px",
                  filter:
                    "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.05))",
                }}
                onMouseEnter={() => {
                  setProfileFocus(true);
                }}
              />
            ) : (
              <UserCircleIcon
                style={{
                  width: "110px",
                  height: "110px",
                  position: "absolute",
                  left: "24px",
                  top: "78px",
                  borderRadius: "50%",
                  border: "2px solid white",
                  background: "#F4F2F2",
                  color: "#64748B",
                }}
                onMouseEnter={() => {
                  setProfileFocus(true);
                }}
              />
            )}
            {profileFocus && (
              <div
                style={{
                  width: "110px",
                  height: "110px",
                  background: "black",
                  opacity: 0.5,
                  position: "absolute",
                  left: "24px",
                  top: "78px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => profileref.current.click()}
                onMouseLeave={() => {
                  setProfileFocus(false);
                }}
              >
                <img src={PencilIcon} alt="edit" />
              </div>
            )}
            <div style={{ margin: "24px" }}>
              <input
                ref={coverref}
                onClick={() => {
                  coverref.current.value = null;
                }}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const newFile = e.target.files[0];
                  checkImageType(newFile.type);
                  checkFileSize(newFile.size);
                  setForm((prev) => ({ ...prev, cover: newFile }));
                  setCoverUpdated(true);
                }}
              />
              <input
                ref={profileref}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onClick={() => {
                  profileref.current.value = null;
                }}
                onChange={(e) => {
                  const newFile = e.target.files[0];
                  checkImageType(newFile.type);
                  checkFileSize(newFile.size);
                  setForm((prev) => ({ ...prev, profile: newFile }));
                  setProfileUpdated(true);
                }}
              />
              <div style={{ marginTop: "200px" }}>
                <InputField
                  value={form.profileName}
                  onChange={(e) => {
                    if (e.target.value.length <= 24)
                      setForm((prev) => ({
                        ...prev,
                        profileName: e.target.value,
                      }));
                  }}
                  placeholder="Enter Profile Name"
                  label={
                    <>
                      Profile Name (
                      {form.profileName ? form.profileName?.length : 0}/24){" "}
                      <span style={{ color: "#F05252" }}>*</span>
                    </>
                  }
                />
                <InputField
                  inputStyle={{
                    border: isUserNameAvailable ? "" : "1px solid #F05252",
                  }}
                  value={form.name}
                  onChange={(e) => {
                    if (e.target.value.length <= 24)
                      setForm((prev) => ({ ...prev, name: e.target.value }));
                  }}
                  placeholder="Enter username"
                  label={
                    <>
                      Username ({form.name ? form.name?.length : 0}/24){" "}
                      <span style={{ color: "#F05252" }}>*</span>
                    </>
                  }
                />
                {!isUserNameAvailable && (
                  <div
                    style={{
                      marginTop: "-12px",
                      weight: 600,
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#F05252",
                      marginBottom: "20px",
                    }}
                  >
                    Username already taken
                  </div>
                )}
                <InputField
                  value={form.description ? form.description : ""}
                  onChange={(e) => {
                    if (e.target.value.length <= 320)
                      setForm((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }));
                  }}
                  inputStyle={{
                    height: "90px",
                    borderRadius: "8px",
                    margin: "4px 0px",
                  }}
                  type="textarea"
                  placeholder="Tell more about yourself"
                  label={
                    <>
                      About({form.description ? form.description?.length : 0}
                      /320) <span style={{ color: "#F05252" }}>*</span>
                    </>
                  }
                />
                <div
                  style={{
                    ...labeStyling,
                    marginBottom: "20px",
                  }}
                >
                  Skills
                  <Select
                    showArrow
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", overflowY: "auto" }}
                    value={form.skills}
                    placeholder="Select Skills"
                    onChange={(e) => {
                      setForm((prev) => ({ ...prev, skills: e }));
                    }}
                  >
                    {skills.map(({ name }) => (
                      <Select.Option value={name} key={name}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div style={{ ...labeStyling }}>
                  Links
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        columnGap: "12px",
                      }}
                      className="social-links"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "12px",
                          width: "100%",
                        }}
                      >
                        <Twitter />
                        <InputField
                          value={getUsernameFromUrl(form.twitter)}
                          type="url"
                          prefix={
                            <span
                              style={{
                                color: "#97a6ba",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              https://twitter.com/
                            </span>
                          }
                          onChange={(e) => {
                            setForm((prev) => ({
                              ...prev,
                              twitter: e.target.value,
                            }));
                          }}
                          style={{
                            width: isMobile ? "100%" : "520px",
                            margin: 0,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "12px",
                          width: "100%",
                        }}
                      >
                        <Github />
                        <InputField
                          value={getUsernameFromUrl(form.github)}
                          type="url"
                          onChange={(e) => {
                            setForm((prev) => ({
                              ...prev,
                              github: e.target.value,
                            }));
                          }}
                          prefix={
                            <span
                              style={{
                                color: "#97a6ba",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              https://github.com/
                            </span>
                          }
                          style={{
                            width: isMobile ? "100%" : "520px",
                            margin: 0,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "12px",
                          width: "100%",
                        }}
                      >
                        <Website />
                        <InputField
                          value={getWebsiteVal(form.website)}
                          prefix={
                            <span
                              style={{
                                color: "#97a6ba",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              https://
                            </span>
                          }
                          type="url"
                          onChange={(e) => {
                            setForm((prev) => ({
                              ...prev,
                              website: e.target.value,
                            }));
                          }}
                          style={{
                            width: isMobile ? "100%" : "520px",
                            margin: 0,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </form>
    </StyledModalWrapper>
  );
};

export default EditProfileModal;

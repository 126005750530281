import axios from "axios";
const unauthenticatedAxios = axios.create({
  baseURL:
    process.env.REACT_APP_ENDPOINT === "local"
      ? "http://localhost:3001/"
      : process.env.REACT_APP_ENDPOINT === "development"
        ? "https://contribution-backend-dev-5dzspnb6za-el.a.run.app/"
        : process.env.REACT_APP_ENDPOINT === "staging"
          ? "https://backend-contribution-staging-5dzspnb6za-el.a.run.app/"
          : "https://contribution-backend-5dzspnb6za-uc.a.run.app/",
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
  const token = localStorage.getItem("access_token");

  if (token) {
    const config = {
      domain_url: `https://${window.location.host}`,
    };

    request.headers = config;
  }
  return request;
};
unauthenticatedAxios.interceptors.request.use((request) =>
  requestHandler(request)
);
export default unauthenticatedAxios;

import { Button, Input, Progress, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AboutCommunity from "./AboutCommunity";
import Details from "./Details";
import styled from "styled-components";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { postMixpanel, updateWalkmeStatus } from "../../utils";
import { UserContext } from "../../layouts/app/UserContext";
import { LoadingOutlined } from "@ant-design/icons";
import useWindowSize from "../../hooks/useWindowSize";
import { COMMUNITY_TYPES, MD_SCREEN_BREAKPOINT } from "../../constants";
import { ReactComponent as Daolens } from "../../assets/images/daoCreation/daolens.svg";
import FirstView from "./FirstView.js";
import Visibility from "./Visiblity";
import { ArrowRight } from "../Icones";
import IconWrapper from "../IconWrapper";
import VisiblityView from "./VisiblityView";
import { ArrowLeftIcon } from "@heroicons/react/24/solid";
import IntegrationView from "./IntegrationView";
import Integrations from "./Integration";
import DaoCreateModal from "../DaoCreateModal";
import { Crisp } from "crisp-sdk-web";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "#1890ff",
      // marginLeft: "12px",
    }}
    spin
  />
);
let transition = "0.5s ease-in-out";

const StyledDiv = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;
const STEP = {
  aboutCommunity: 0,
  details: 1,
  success: 2,
  channelCreation: 3,
};

const StyledView = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 742px;
  transform: ${(props) =>
    props.currentStep === 2
      ? "translateY(-100vh)"
      : props.currentStep === 3
      ? "translateY(-200vh)"
      : ""};
  transition: transform 0.5s ease-in-out;
`;

const Onboarding = () => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const { reload } = useContext(UserContext);
  const [communityName, setCommunityName] = useState("");
  const [aboutCommunity, setAboutCommunity] = useState("");
  const [communityType, setCommunityType] = useState([]);
  const [isImportedFromDiscord, setIsImportedFromDiscord] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [twitter, setTwitter] = useState("");
  const [website, setWebsite] = useState("");
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState([]);

  const [daoId, setDaoId] = useState(null);
  const [searchParams] = useSearchParams();
  const [profile, setProfile] = useState();
  const [daoDetails, setDaoDetails] = useState({});
  const navigate = useDaoLensNavigate();
  const { userDetails, showLoginModal } = useContext(UserContext);
  const history = useLocation();

  useEffect(() => {
    if (!userDetails) {
      showLoginModal();
    }
    setCommunityType(
      COMMUNITY_TYPES.filter(
        (communityType) => communityType.id === history.state?.communityType
      ).map((community) => community.id)
    );

    try {
      Crisp.chat.hide();
    } catch (err) {
      Crisp.session.onLoaded(() => {
        Crisp.chat.hide();
      });
    }
    return () => {
      Crisp.chat.show();
    };
  }, []);
  useEffect(() => {
    if (searchParams.get("fromDiscord")) {
      postMixpanel("track", "integrated_discord_through_onboarding_flow", {
        dao_id: daoId,
      });
      setDaoId(searchParams.get("daoId"));
      if (searchParams.get("type") === "import") {
        setIsImportedFromDiscord(true);
        setCommunityName(searchParams.get("daoName"));
      } else {
        setCurrentStep(3);
      }
    } else setCurrentStep(1);
  }, []);

  const saveDetails = async () => {
    const formData = new FormData();
    if (daoId) formData.append("daoId", daoId);
    formData.append(daoId ? "daoName" : "name", communityName);
    formData.append("about", aboutCommunity);
    if (profile) {
      let profileImage = new File(
        [profile.slice(0, profile.size, profile.type)],
        profile.name.split(".").join(`_profile.`),
        { type: profile.type }
      );
      formData.append("files", profileImage);
    }
    if (twitter)
      formData.append("twitter", `https://twitter.com/${twitter.trim()}`);
    if (website) formData.append("website", "https://" + website);
    formData.append("communityTypes", JSON.stringify(communityType));
    setLoading(true);
    customAxios
      .post(`dao/${daoId ? "edit" : "create"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(async (res) => {
        if (res.data.success) {
          postMixpanel(
            "track",
            "added_community_details_through_onboarding_flow",
            {
              dao_id: res.data.daoId,
              dao_name: communityName,
              about_community: aboutCommunity,
              webiste_added: !!website,
              twitter_added: !!twitter,
              profile_image_added: !!profile,
              cumminty_types: communityType,
            }
          );
          setCurrentStep(2);
          console.log(daoId, res);
          if (daoId) {
            daoReload();
          } else setDaoId(res.data.daoId);
          updateWalkmeStatus({ personaDaoFlow: true }, reload);
        } else console.log("error");
      })
      .catch((err) => {
        console.log("error");
        setLoading(false);
      });
  };
  const [update, setUpdate] = useState(false);
  const updateVisiblity = () => {
    let params = { daoId };
    entities.forEach((entity) => {
      params[entity.name] = entity.value;
    });
    setLoading(true);
    customAxios
      .post("/settings/visibility/update", params)
      .then(() => {
        postMixpanel(
          "track",
          "module_visiblity_updated_through_onboarding_flow",
          params
        );
        reload();
        setCurrentStep(3);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    (async () => {
      if (!daoId) {
        return;
      }
      setLoading(true);
      try {
        const daoRes = await customAxios.get(`/dao/fetch?daoId=${daoId}`);

        setDaoDetails(daoRes.data.daoDetails[0]);
      } finally {
        setLoading(false);
      }
    })();
  }, [daoId, update]);
  const daoReload = () => {
    setUpdate((prev) => !prev);
  };
  console.log(loading);

  return (
    <StyledDiv>
      {currentStep === 1 && (
        <DaoCreateModal
          closable={false}
          setCurrentStep={setCurrentStep}
          handleNewDao={() => {
            setCurrentStep(-1);
          }}
        />
      )}
      {!isMobile && (
        <div
          style={{
            height: "100%",
            background: "#4A42A7",
          }}
        >
          <Daolens
            style={{
              position: "absolute",
              top: "40px",
              left: "40px",
            }}
          />
          <StyledView currentStep={currentStep}>
            <FirstView />
          </StyledView>
          <StyledView currentStep={currentStep}>
            <VisiblityView entities={entities} daoDetails={daoDetails} />
          </StyledView>
          <StyledView currentStep={currentStep}>
            <IntegrationView daoDetails={daoDetails} />
          </StyledView>
        </div>
      )}
      <div
        style={{
          width: "100%",
          transform:
            currentStep === 2
              ? "translateY(-100vh)"
              : currentStep === 3
              ? "translateY(-200vh)"
              : "",
          transition,
        }}
      >
        <div style={{ height: "100vh" }}>
          <div
            style={{
              display: "flex",
              gap: "32px",
              padding: "40px 48px 0px 48px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#3B82F6",
                  background: "#EFF6FF",
                  width: "32px",
                  aspectRatio: "1",
                  font: "normal 500 15px/18px Inter",
                  border: "1px dashed #BFDBFE",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                1
              </div>
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #93C5FD 0%, rgba(147, 197, 253, 0) 98.44%)",
                  width: "1px",
                  margin: "8px",
                  flexGrow: 1,
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "40px",
                overflowY: "auto",
                height: "calc(100vh - 95px)",
              }}
            >
              <div
                style={{
                  font: "normal 500 15px/18px Inter",
                  color: "#7E7E7E",
                }}
              >
                STEP 1/3
                <div
                  style={{
                    font: "normal 800 40px/40px Roboto Slab",
                    color: "black",
                    margin: "12px 0px 16px",
                  }}
                >
                  Basic Details
                </div>
                Help your members know more about your community
              </div>
              <AboutCommunity
                aboutCommunity={aboutCommunity}
                communityName={communityName}
                isImportedFromDiscord={isImportedFromDiscord}
                setAboutCommunity={setAboutCommunity}
                setCommunityName={setCommunityName}
                profile={profile}
                setProfile={setProfile}
              />
              <Details
                twitter={twitter}
                communityType={communityType}
                setCommunityType={setCommunityType}
                setTwitter={setTwitter}
                setWebsite={setWebsite}
                website={website}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px 20px",
              columnGap: "10px",
              background:
                "linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 51.56%, rgba(255, 255, 255, 0) 100%)",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                saveDetails();
              }}
              type="primary"
              style={{
                borderRadius: "12px",
                cursor: loading
                  ? "wait"
                  : loading ||
                    !aboutCommunity ||
                    !communityName ||
                    !communityType.length
                  ? "default"
                  : "pointer",
              }}
              disabled={
                loading ||
                !aboutCommunity ||
                !communityName ||
                !communityType.length
              }
            >
              Next
              <IconWrapper>
                <ArrowRight width="16px" />
              </IconWrapper>
            </Button>
          </div>
        </div>
        <div style={{ height: "100vh" }}>
          <div style={{ display: "flex", gap: "32px", padding: "40px 48px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#3B82F6",
                  background: "#EFF6FF",
                  width: "32px",
                  aspectRatio: "1",
                  font: "normal 500 15px/18px Inter",
                  border: "1px dashed #BFDBFE",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                2
              </div>
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #93C5FD 0%, rgba(147, 197, 253, 0) 98.44%)",
                  width: "1px",
                  margin: "8px",
                  flexGrow: 1,
                }}
              ></div>
            </div>
            <div
              style={{
                width: "100%",
                overflowY: "auto",
                height: "calc(100vh - 135px)",
              }}
            >
              <div
                style={{
                  font: "normal 500 15px/18px Inter",
                  color: "#7E7E7E",
                  marginBottom: "24px",
                }}
              >
                STEP 2/3
                <div
                  style={{
                    font: "normal 800 40px/40px Roboto Slab",
                    color: "black",
                    margin: "12px 0px 16px",
                  }}
                >
                  Choose modules
                </div>
                Build the perfect platform for your needs.
              </div>
              {communityType.length && (
                <div
                  style={{
                    color: "#064E3B",
                    font: "normal 400 14px/24px Inter",
                    padding: "12px 16px",
                    background: "#ECFDF5",
                    borderRadius: "8px",
                    marginBottom: "12px",
                  }}
                >
                  ✅ Recommendation based on your community type
                  {COMMUNITY_TYPES.filter((type) =>
                    daoDetails.communityType?.includes(type.id)
                  )
                    .map((item) => item.name)
                    .join(", ")}
                </div>
              )}

              <Visibility
                daoDetails={daoDetails}
                entities={entities}
                setEntities={setEntities}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px 20px",
              columnGap: "10px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={loading}
              onClick={() => {
                updateVisiblity();
              }}
              type="primary"
              style={{
                borderRadius: "12px",
                cursor: loading ? "wait" : "default",
              }}
            >
              Next
              <IconWrapper>
                <ArrowRight width="16px" />
              </IconWrapper>
            </Button>
          </div>
        </div>
        <div>
          <div style={{ display: "flex", gap: "32px", padding: "40px 48px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  color: "#3B82F6",
                  background: "#EFF6FF",
                  width: "32px",
                  aspectRatio: "1",
                  font: "normal 500 15px/18px Inter",
                  border: "1px dashed #BFDBFE",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                3
              </div>
              <div
                style={{
                  background:
                    "linear-gradient(180deg, #93C5FD 0%, rgba(147, 197, 253, 0) 98.44%)",
                  width: "1px",
                  margin: "8px",
                  flexGrow: 1,
                }}
              ></div>
            </div>
            <div
              style={{
                width: "100%",
                overflowY: "auto",
                height: "calc(100vh - 135px)",
              }}
            >
              <div
                style={{
                  font: "normal 500 15px/18px Inter",
                  color: "#7E7E7E",
                  marginBottom: "24px",
                }}
              >
                STEP 3/3
                <div
                  style={{
                    font: "normal 800 40px/40px Roboto Slab",
                    color: "black",
                    margin: "12px 0px 16px",
                  }}
                >
                  Integration
                </div>
                Setup everything you need for the best experience{" "}
              </div>

              <Integrations
                daoDetails={daoDetails}
                reload={daoReload}
                loading={loading}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              padding: "10px 20px",
              justifyContent: "space-between",
            }}
          >
            <Button
              type="secondary"
              style={{
                borderRadius: "12px",
              }}
              onClick={() => setCurrentStep(2)}
            >
              <IconWrapper>
                <ArrowLeftIcon width="16px" />
              </IconWrapper>
              Previous
            </Button>

            <Button
              onClick={() => {
                navigate(`/app/dao/${daoDetails.daoName}/${daoDetails.daoId}`, {
                  state: { newCommunity: true },
                });
              }}
              type="primary"
              style={{
                borderRadius: "12px",
              }}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

export default Onboarding;

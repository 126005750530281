import { Select, Row, Col, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import customAxios from "../../api";
import SyncDiscordRoles from "./SyncDiscordRoles";
import { DaoContext, useDaoContext } from "../../layouts/app/DaoContext";
import ConnectDiscord from "./ConnectDiscord";

const { Option } = Select;
const MemberComponent = ({
  ele,
  idx,
  handleConditionDetailChange,
  isSpaceGating = false,
}) => {
  const [usersList, setUsersList] = useState([]);
  const { daoId } = useDaoContext();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!daoId) return;
    setLoading(true);
    customAxios
      .get(`users/daousers?daoId=${daoId}`)
      .then((res) => {
        setUsersList(
          res.data.daoUser
            .filter((ele) =>
              ele?.userRoles !== null && ele.userRoles?.includes("ADMIN")
                ? false
                : true
            )
            .map((ele) => ({ value: ele.id, label: ele.userName }))
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [daoId]);

  return (
    <Row
      gutter={["8"]}
      style={{
        columnGap: "1rem",
        flexFlow: "nowrap",
        padding: "2rem 12px 15px 12px",
        borderRadius: "12px",
        background: "#eeeeee",
        position: "relative",
      }}
    >
      {" "}
      {loading ? (
        <Col xs={24}>
          <Skeleton.Button active block />
        </Col>
      ) : (
        <Select
          mode="multiple"
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: "100%" }}
          value={ele?.user}
          options={usersList}
          placeholder="Select user name"
          onChange={(e) => {
            handleConditionDetailChange("user", e, idx);
          }}
        />
      )}
    </Row>
  );
};

export default MemberComponent;

import { Bars3Icon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Input } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Logo from "../../../assets/images/discovery/daolens-logo.svg";
import BgBanner from "../../../assets/images/discovery/bg-banner.png";
import { FILTERS } from "../../../constants/DiscoverPage";
import useWindowSize from "../../../hooks/useWindowSize";
import { UserContext } from "../../../layouts/app/UserContext";
import { CommunityFilterType } from "../../../types/DiscoverPage";
import "../../ComponentStyles/discover_page.css";
import { COMMUNITY_TYPES } from "../../../constants";
import { HideScrollDiv } from "../../Settings/Permissions";
type Props = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  setActiveFilter: Dispatch<SetStateAction<CommunityFilterType>>;
  activeFilter: CommunityFilterType;
};

function isHeaderInViewpost(element: HTMLDivElement) {
  const rect = element.getBoundingClientRect();
  return rect.bottom >= 0;
}

const Header = ({ activeFilter, query, setActiveFilter, setQuery }: Props) => {
  const { isMobile } = useWindowSize();
  const headerRef = useRef<HTMLDivElement>(null);
  const [isCompactHeaderVisible, setIsCompactHeaderVisible] = useState(false);

  const userContext = useContext(UserContext);
  const openSidebar = () => {
    setTimeout(() => {
      userContext.setMobileSidebarOpen(true);
    }, 100);
    return;
  };

  useEffect(() => {
    const handleCompactHeaderVisibility = () => {
      if (!headerRef?.current) return;
      if (isHeaderInViewpost(headerRef?.current))
        setIsCompactHeaderVisible(false);
      else setIsCompactHeaderVisible(true);
    };
    window.addEventListener("scroll", handleCompactHeaderVisibility);
    return () => {
      window.removeEventListener("scroll", handleCompactHeaderVisibility);
    };
  }, []);

  const searchInput = (
    <Input
      style={{
        borderRadius: "43px",
        boxShadow: isCompactHeaderVisible
          ? undefined
          : "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        border: isCompactHeaderVisible ? "1px solid #E5E7EB" : "none",
        padding: "6px 6px 6px 25px",
        fontSize: "14px",
        zIndex: 10,
      }}
      value={query}
      onChange={(e) => setQuery(e.currentTarget.value)}
      placeholder="Search community and happenings"
      suffix={
        <div
          style={{
            background: "#7C3AED",
            borderRadius: "100%",
            height: "32px",
            width: "32px",
            position: "relative",
          }}
        >
          <MagnifyingGlassIcon
            style={{
              color: "white",
              width: "13px",
              position: "absolute",
              top: "50%",
              left: "50%",
              strokeWidth: 2,
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
      }
    />
  );
  let defaultFilters = [
    { name: "🙌🏻 All", id: "" },
    { name: "️💜 Your", id: "YOURS" },
  ];

  const communityTypeList = (
    <div className="relative">
    <div 
      className="absolute h-full w-12 left-0"
      style={{
        background: 'linear-gradient(to left, transparent, white)',
      }}/>
    
      <div 
      className="absolute h-full w-12 right-0"
      style={{
        background: 'linear-gradient(to right, transparent, white)',
      }}/>
      <HideScrollDiv
      style={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        padding: "16px",
        overflow: "auto",
      }}
    >
   
      {[...defaultFilters, ...COMMUNITY_TYPES].map((filter) => (
        <button
          className="homepage_tabs_hover_state"
          onClick={() => setActiveFilter(filter.id)}
          style={{
            background: filter.id === activeFilter ? "#7C3AED" : "white",
            color: filter.id === activeFilter ? "white" : "#1F2937",
            whiteSpace: "nowrap",
            border:
              filter.id === activeFilter
                ? "1px solid #7C3AED"
                : "1px solid #E5E7EB",
            borderRadius: "38px",
            fontSize: "14px",
            cursor: "pointer",
            padding: "9px 13px",
          }}
        >
          {filter.name}
        </button>
      ))}
       
    </HideScrollDiv>


    </div>
   
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          width: isMobile ? "100%" : "1000px",
        }}
        ref={headerRef}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
            padding: isMobile ? "24px 16px" : "36px 60px",
            alignItems: "center",
          }}
        >
          {isMobile && (
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                zIndex: 10,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                marginBottom: "26px",
              }}
            >
              <button
                onClick={openSidebar}
                style={{
                  background: "none",
                  border: "none",
                  position: "absolute",
                  left: 0,
                  cursor: "pointer",
                }}
              >
                <Bars3Icon width={20} color={"white"} strokeWidth={2} />
              </button>
              <img src={Logo} alt="daolens" style={{ alignSelf: "center" }} />
            </div>
          )}
          <h1
            style={{
              fontSize: isMobile ? "16px" : "24px",
              fontWeight: "700",
              color: "white",
              zIndex: 10,
            }}
          >
            Find the community, where you belong
          </h1>
          {searchInput}

          <div
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <img
              src={BgBanner}
              alt="Gradient plain banner"
              style={{
                height: "100%",
                width: "100%",
                zIndex: 0,
                objectFit: "cover",
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
              }}
            />
          </div>
        </div>
        {communityTypeList}
      </div>
      {isCompactHeaderVisible && (
        <div
          style={{
            background: "white",
            padding: isMobile ? "0" : "24px 28px 8px",
            position: "fixed",
            zIndex: "100",
            width: isMobile ? "100%" : "1000px",
            boxShadow:
              "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
            display: "flex",
            flexDirection: "column",
            borderRadius: "0px 0px 24px 24px",
          }}
        >
          <div
            style={{
              margin: isMobile ? "16px 16px 0 16px" : "",
              display: "flex",
              alignItems: "center",
            }}
          >
            <button
              onClick={openSidebar}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              <Bars3Icon width={20} color={"#333333"} strokeWidth={2} />
            </button>
            {searchInput}
          </div>
          {communityTypeList}
        </div>
      )}
    </>
  );
};

export default Header;

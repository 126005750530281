import { useEffect, useState } from "react";
import alldao from "../../assets/images/profile/alldao.svg";
import Card, { Title } from "../Common/Card";
import { Avatar, Skeleton, Typography } from "antd";
import customAxios from "../../api";
import { StyledCard } from "../BountyFeed";
import { useNavigate, useParams } from "react-router";

import ReactHtmlParser from "react-html-parser";
import EmptyComponent from "../Common/EmptyComponent";
import styled from "styled-components";
import { getPostTime } from "../Feed";
import PostCard from "../Common/PostCard";
import useWindowSize from "../../hooks/useWindowSize";
import no_post from "../../assets/iconSvg/no_posts.svg";
import no_bounty from "../../assets/iconSvg/no_bounties.svg";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { StyledParagraph } from "../PostDetails";
import ProfileCardPopup from "../Common/ProfileCard";
import { getDefaultDaoImage, getTruncatedString } from "../../utils";
const { Text: AntText } = Typography;
export const Text = styled(AntText)`
  color: #64748b;
  font-weight: 500 !important;
  fontsize: 12px !important;
  &:hover {
    color: #3b82f6;
  }
`;

export const getCompletedBounties = async (userId) => {
  let res = userId ? `?userId=${userId}` : "";
  const { data } = await customAxios.get("/bounty/fetch/completed/user" + res);
  return data.data;
};
export const getPosts = async (userId) => {
  let res = userId ? `?userId=${userId}` : "";
  const { data } = await customAxios.get("/post/fetch/user" + res);
  return data.post;
};

const DaoSection = ({ updater }) => {
  const { isMobile } = useWindowSize();
  const [bountyList, setBountyList] = useState([]);
  const [posts, setPosts] = useState([]);
  const { userId } = useParams();
  let tabs = [
    { title: "Contributions", count: bountyList.length },
    { title: "Posts", count: posts.length },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0].title);
  const [hoverTab, setHoverTab] = useState(undefined);
  const [bountyLoading, setBountyLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  useEffect(() => {
    setBountyLoading(true);
    getCompletedBounties(userId)
      .then((res) => {
        setBountyList(res);
      })
      .finally(() => {
        setBountyLoading(false);
      });
    setPostLoading(true);
    getPosts(userId)
      .then((res) => {
        setPosts(res);
      })
      .finally(() => {
        setPostLoading(false);
      });
  }, [updater, userId]);

  let navigate = useDaoLensNavigate();
  return (
    <section
      style={{
        display: "inline-flex",
        marginLeft: !isMobile && "12px",
        width: isMobile ? "100%" : "636px",
      }}
    >
      <Card>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          {" "}
          <img src={alldao} alt="all dao" />
          <Title title="All Activities" style={{ marginBottom: "0px" }} />
        </div>

        <div style={{ display: "flex", borderBottom: "1px solid #F1F5F9" }}>
          {tabs.map((tab) => (
            <div
              onClick={() => {
                setSelectedTab(tab.title);
              }}
              onMouseEnter={() => {
                setHoverTab(tab.title);
              }}
              onMouseLeave={() => {
                setHoverTab(undefined);
              }}
              style={{
                height: "46px",
                borderBottom:
                  selectedTab === tab.title
                    ? "solid 2px #3B82F6"
                    : "solid 2px transparent",
                display: "flex",
                alignItems: "center",
                padding: "0px 12px",
                color:
                  selectedTab === tab.title || hoverTab === tab.title
                    ? "#3B82F6"
                    : "#475569",
                fontWeight: "600px",
                fontFamily: "Inter",
                fontStyle: "normal",
                gap: "8px",
                cursor: "pointer",
              }}
            >
              <div
                className="text-xs font-semibold px-2 py-1"
                style={{
                  background: selectedTab === tab.title ? "#3B82F6" : "#F1F5F9",
                  color: selectedTab === tab.title ? "white" : "#475569",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "24px",
                }}
              >
                {tab.count}
              </div>
              {tab.title}
            </div>
          ))}
        </div>
        <div style={{ paddingTop: "12px" }}>
          {selectedTab === "Posts" &&
            posts.map((post) => (
              <StyledCard
                style={{
                  width: "100%",
                  textAlign: "left",
                  marginBottom: "16px",
                  borderRadius: "14px",
                }}
                onClick={() => {
                  navigate(
                    `/app/dao/${post.daoName}/${post.daoId}/channel/${post.channelId}/post/${post.postId}`
                  );
                }}
                key={post.postId}
              >
                <div
                  style={{
                    color: "#64748B",
                    marginBottom: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px",
                  }}
                >
                  <div className="flex items-center">
                    <div
                      className="inline-flex items-center"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/app/dao/${post.daoName}/${post.daoId}`);
                      }}
                    >
                      {post?.profilePicture && (
                        <Avatar
                          size={16}
                          src={post.profilePicture}
                          style={{ marginRight: "6px" }}
                        />
                      )}
                      {!post?.profilePicture && post?.daoName && (
                        <div className="inline-block">
                          {getDefaultDaoImage(
                            post?.daoName,
                            {
                              alignSelf: "center",
                              width: "16px",
                              height: "16px",
                              cursor: "pointer",
                              marginRight: "6px",
                              borderRadius: "12px",
                            },
                            { fontSize: "10px" }
                          )}
                        </div>
                      )}
                      {post?.daoName && (
                        <>
                          <Text style={{ fontSize: "12px" }}>
                            {post.daoName}
                          </Text>
                          <span style={{ margin: "0 4px" }}>&#8226;</span>
                        </>
                      )}
                    </div>
                    <div className="inline-flex items-center">
                      <ProfileCardPopup userId={post.userId}>
                        {post?.userProfilePicture &&
                          post.userProfilePicture !== null && (
                            <Avatar
                              size={16}
                              src={post.userProfilePicture}
                              style={{ marginRight: "2px" }}
                            />
                          )}
                        {post?.userName && (
                          <>
                            <Text>{getTruncatedString(post.userName, 24)}</Text>
                            <span
                              style={{
                                margin: "0 4px",
                                display: isMobile && "none",
                              }}
                            >
                              &#8226;
                            </span>
                          </>
                        )}
                      </ProfileCardPopup>
                    </div>

                    <Text
                      style={{
                        color: "#64748B",
                        fontWeight: 600,
                        fontSize: "12px",
                        display: isMobile && "none",
                      }}
                    >
                      {getPostTime(post?.createdAt)}
                    </Text>
                  </div>
                </div>

                <Typography.Title
                  style={{ marginBottom: "0px", fontSize: "18px" }}
                >
                  {post?.postName}
                </Typography.Title>

                {post?.postTags && post?.postTags.length > 0 && (
                  <div>
                    {post.postTags.map((tag) => (
                      <div
                        style={{
                          padding: "2px 16px",
                          background: "#F2F5FF",
                          borderRadius: "8px",
                          color: "#3B82F6",
                          marginRight: "4px",
                          fontSize: "12px",
                          display: "inline-block",
                          marginBottom: "4px",
                          marginTop: "4px",
                        }}
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                )}

                {post?.postDescription ? (
                  <Typography
                    style={{
                      width: isMobile && "70vw",
                      fontSize: "14px",
                    }}
                  >
                    <StyledParagraph>
                      {ReactHtmlParser(post?.postDescription)}
                    </StyledParagraph>
                  </Typography>
                ) : null}
              </StyledCard>
            ))}
          {selectedTab === "Posts" && !postLoading && posts.length === 0 && (
            <EmptyComponent
              imageSource={no_post}
              text="No posts availabale yet"
              subtext="Be sure to check in a while"
            />
          )}
          {selectedTab === "Posts" && postLoading && <Skeleton active />}
          {selectedTab === "Contributions" &&
            !bountyLoading &&
            bountyList.length === 0 && (
              <EmptyComponent
                imageSource={no_bounty}
                text="No bounties available yet"
                subtext="Be sure to check in a while"
              />
            )}
          {selectedTab === "Contributions" && bountyLoading && (
            <Skeleton active />
          )}

          {selectedTab === "Contributions" &&
            bountyList.map((bountyPost) => (
              <PostCard
                bountyPost={bountyPost}
                daoName={bountyPost.daoName}
                daoId={bountyPost.daoId}
                selectedTab="Completed"
              />
            ))}
        </div>
      </Card>
    </section>
  );
};

export default DaoSection;

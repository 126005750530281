import { HoverCard } from "../../Search/SearchModal";

const Weighted = ({ choices, selectedChoice, setSelectedChoice }) => {
  const totalVote = selectedChoice
    ? Object.values(selectedChoice).reduce((prev, curr) => prev + curr, 0)
    : 0;
  return (
    <>
      {choices.map((choice, index) => {
        let idx = index + 1;
        return (
          <div
            style={{
              border: "1px solid",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "12px",
              font: "normal 500 12px/167% Inter",
              color: "#1A202E",
              height: "36px",
              borderColor: "#F4F2F2",
              position: "relative",
              paddingLeft: "12px",
            }}
          >
            {choice}
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <HoverCard
                style={{
                  height: "36px",
                  width: "36px",
                  display: "inline-flex",
                  alignItems: "center",
                  border: "1px solid #E2E8F0",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setSelectedChoice((prev) => {
                    if (!(idx in prev)) {
                      return prev;
                    }
                    if (prev[idx] > 1) {
                      return { ...prev, [idx]: prev[idx] - 1 };
                    }
                    delete prev[idx];
                    console.log(prev);
                    return { ...prev };
                  });
                }}
              >
                -
              </HoverCard>
              {selectedChoice && idx in selectedChoice
                ? selectedChoice[idx]
                : 0}
              <HoverCard
                style={{
                  height: "36px",
                  width: "36px",
                  display: "inline-flex",

                  alignItems: "center",
                  border: "1px solid #E2E8F0",
                  justifyContent: "center",
                }}
                onClick={() => {
                  setSelectedChoice((prev) => ({
                    ...prev,
                    [idx]: idx in prev ? prev[idx] + 1 : 1,
                  }));
                }}
              >
                +
              </HoverCard>
              <div style={{ width: "50px", marginLeft: "auto" }}>
                {
                  +(
                    ((selectedChoice && idx in selectedChoice
                      ? selectedChoice[idx]
                      : 0) *
                      100) /
                    (totalVote ? totalVote : 1)
                  ).toFixed(2)
                }
                %
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Weighted;

import React from "react";
import "../ComponentStyles/BountyWriteComment.scss";
import dummyAvatar from "../../assets/images/login/defaultUser.jpeg";
import crossIcon from "../../assets/iconSvg/crossIcon.svg";
import { Input } from "antd";
import PrimaryButtonFilled from "../InternalComponents/PrimaryButtonFilled";
import { useState } from "react";
import moment from "moment/moment";
const { TextArea } = Input;
function BountyWriteComment({ setAccordionState, addComment, userName }) {
  const [comment, setComment] = useState("");
  return (
    <div className="bounty-write-comment__wrapper">
      <div className="bounty-write-comment__user-details">
        <img
          src={dummyAvatar}
          alt=""
          className="bounty-write-comment__user-details--icon"
        />
        <div className="bounty-write-comment__user-details--content">
          <span className="bounty-write-comment__user-details--content-time">
            {moment().format("hh:mm A")}
          </span>
          <span className="bounty-write-comment__user-details--content-name">
            {userName}
          </span>
        </div>
        <img
          src={crossIcon}
          alt=""
          onClick={() => setAccordionState(["5"])}
          className="bounty-write-comment__user-details--cross"
        />
      </div>
      <TextArea
        className="bounty-write-comment__text-area"
        rows={4}
        placeholder="Write your comment here ..."
        value={comment}
        onChange={(ev) => setComment(ev.target.value)}
      />
      <PrimaryButtonFilled
        buttonText="Add comment"
        fillColor="#3B82F6"
        style={{
          padding: "8px 12px",
          alignSelf: "flex-end",
          marginTop: "12px",
        }}
        clickHandler={() => {
          addComment(comment);
          setComment("");
          setAccordionState(["5"]);
        }}
      />
    </div>
  );
}

export default BountyWriteComment;

import { useState, useEffect } from "react";
import { MD_SCREEN_BREAKPOINT } from "../constants";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  const handleWindowSize = () =>
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    window.addEventListener("resize", handleWindowSize);
    handleWindowSize();
    return () => window.removeEventListener("resize", handleWindowSize);
  }, []);
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;

  return { windowSize, isMobile };
};

export default useWindowSize;

import { Modal } from "antd";
import customAxios from "../api";
import styles from "../components/Course/course.module.css";
import React from "react";
import moment, { Moment } from "moment";

type ConfirmOperationOptions = {
  confirmText: string;
  cancelText?: string;
  message: string;
  onOk?: () => void;
  title?: string;
  onCancel?: () => void;
};

export const confirmOperation = ({
  confirmText,
  cancelText = undefined,
  message,
  title = "Confirm",
  onOk,
  onCancel,
}: ConfirmOperationOptions) => {
  Modal.confirm({
    title: (
      <span style={{ color: "#000000", fontWeight: "600", fontSize: "16px" }}>
        {title}
      </span>
    ),
    icon: null,
    content: (
      <span style={{ color: "#64748B", fontWeight: "500", fontSize: "14px" }}>
        {message}
      </span>
    ),
    okText: confirmText,
    cancelText: cancelText,
    closable: true,
    onOk,
    onCancel,
    bodyStyle: {
      paddingTop: "16px",
      paddingBottom: "12px",
      paddingLeft: "24px",
      paddingRight: "24px",
      borderRadius: "16px",
    },
    okButtonProps: {
      style: {
        background: "#3F83F8",
        fontWeight: "500",
        borderRadius: "12px",
      },
    },
    modalRender: (children) => (
      <div className={styles.antModalContainer}>{children}</div>
    ),
    cancelButtonProps: {
      style: {
        color: "#64748B",
        fontWeight: "500",
        borderRadius: "12px",
        border: "1px solid #64748B",
        marginTop: "5px",
      },
    },
  });
};

export const syncDiscordRoles = async (
  daoId: string,
  discordGuildId: string,
  postSyncRollCallback: () => any | undefined
) => {
  await customAxios.post("/settings/integration/discord", {
    daoId,
    discordGuildId,
  });
  postSyncRollCallback?.();
};

export function removeHash() {
  window.history.pushState(
    "",
    document.title,
    window.location.pathname + window.location.search
  );
}

export const checkIsInViewport = (element: Element | null) => {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getAdminInviteLink = async (daoId) => {
  if (!daoId) return "";
  const response = await customAxios.get(
    `/dao/generate/link?type=ADMIN&&daoId=${daoId}&isInterface=true`
  );

  return response?.data?.link;
};

export const sendEmailInvite = async (
  emails: string[],
  daoId: string,
  daoName: string,
  type: "MEMBER" | "ADMIN" = "ADMIN"
) => {
  await customAxios.post("/dao/invite", {
    emailId: emails,
    daoId,
    daoName,
    type,
  });
};

export const roundUpTimeToGivenMinuteMultiple = (
  time: Moment,
  minuteMultiple = 15
) => {
  const remainder = minuteMultiple - (time.minute() % minuteMultiple);

  const newTime = moment(time).add(remainder, "minutes").toISOString();
  return newTime;
};

export const respondToVisibility = (
  element: HTMLElement,
  callback: (visible: boolean) => void
) => {
  const options = {
    root: document.documentElement,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      console.log({ entry });
      callback(entry.intersectionRatio > 0);
    });
  }, options);

  observer.observe(element);
};

import styled from "styled-components";
import { HoverCard } from "./SearchModal";

const SearchCard = (props) => {
  return (
    <HoverCard
      style={{
        background: props.isLinkedItem ? "#F7F7F7" : "",
        border: props.isLinkedItem ? "1px solid #E2E8F0" : "none",
        display: "flex",
        alignItems: "center",
        padding: "12px 16px",
        height: "64px",
        alignItms: "center",
        gap: "12px",
        overflow: "hidden",
        position: "relative",
      }}
      {...props}
    />
  );
};

export default SearchCard;

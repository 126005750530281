import React, { useRef, useState } from "react";
import { Button, Input, Space, Row } from "antd";
import IconWrapper from "../IconWrapper";
import { EyeIcon } from "@heroicons/react/24/outline";
import QuillEditor from "../QuillEditor";
import { Modal, Footer } from "../../styles/ModalStyles";
import { toast } from "react-toastify";
import customAxios from "../../api";

import { useParams } from "react-router";

import DueDate from "../DueDate";
import moment from "moment";
import ReviewerSelector from "../BountyComponents/ReviewerSelector";
import {
  BountyFilesAttachment,
  StyledModalWrapper,
} from "../CreateBountyModal";
import CreateSubtaskInput from "../BountyComponents/CreateSubtaskInput";
import SubtaskCardView from "./SubtaskCardView";
import MultipleAssigneeSelector from "../BountyComponents/MultipleAssigneeSelector";
import StatusPill from "./Task/StatusPill";
import Attachments from "../Common/Attachments";
import { PaperClipIcon } from "../Icones";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { confirmOperation } from "../../utils/common";

const EditTaskModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  defaultData,
}) => {
  const [loading, setLoading] = useState(false);
  const { projectId } = useParams();
  const { daoDetails, daoId } = useDaoContext();

  const defaultState = {
    title: defaultData.title,
    reviewers: defaultData.reviewers,
    assignee: defaultData.assignee,
    description: defaultData.description,
    subTask: defaultData.subTask,
  };

  const defaultReviewersData = defaultData.reviewers.filter(
    (reviewer) => reviewer.id !== defaultData.creatorUserId
  );

  const [status, setStatus] = useState(defaultData.status);
  const [state, setState] = useState(() => defaultState);
  const [description, setDescription] = useState(defaultData.description);
  const [files, setFiles] = useState([]);
  const [assignees, setAssignees] = useState(defaultData.assignee || []);
  const [reviewer, setReviewer] = useState(
    defaultReviewersData?.[0]
      ? defaultReviewersData?.[0]
      : defaultData?.reviewers?.[0] || null
  );
  const [allSubtasks, setAllSubtasks] = useState(defaultData.subTask);
  const [attachmentsToBeDeleted, setAttachmentsToBeDeleted] = useState([]);
  const [mentionedUsers, setMentionedUsers] = useState([]);

  const defaultAttachments = defaultData?.attachments?.filter(
    (attachment) => !attachmentsToBeDeleted.includes(attachment)
  );

  // const [subtaskFiles, setSubtaskFiles] = useState({});
  const updateSubtasks = (subtaskBody) => {
    console.log({ subtaskBody });
    let copyOfSubtasks = allSubtasks;
    let indexToUpdate = null;
    let foundSubtask = copyOfSubtasks.find((singleSubtask, index) => {
      if (
        (subtaskBody.id && singleSubtask.id === subtaskBody.id) ||
        (subtaskBody.tmpId && singleSubtask.tmpId === subtaskBody.tmpId) ||
        singleSubtask.title === subtaskBody.title
      ) {
        indexToUpdate = index;
        return true;
      }
      return false;
    });
    if (foundSubtask) {
      // ! If subtask already exists then update
      let subtaskToInsert = { ...foundSubtask, ...subtaskBody };
      copyOfSubtasks[indexToUpdate] = subtaskToInsert;
      setAllSubtasks([...copyOfSubtasks]);
    } else {
      // ! If subtask doesn't exist then create
      copyOfSubtasks.push(subtaskBody);
      setAllSubtasks([...copyOfSubtasks]);
    }
  };

  const updateTask = (state) => {
    const formData = new FormData();
    let flag = 1;
    formData.append("status", status.split(" ").join(""));
    formData.append("taskId", defaultData.id);
    formData.append("projectId", projectId);

    if (allSubtasks) {
      const editSubTask = [],
        newSubTask = [];
      console.log({ allSubtasks });
      allSubtasks.forEach((item, index) => {
        item.reviewers = reviewer ? [reviewer?.userId] : null;
        item.assignee = item?.assignee
          ? item?.assignee.map((user) => user.id)
          : null;
        if (!item.status) {
          item.status = "TODO";
        } else {
          item.status = item.status.split(" ").join("");
        }
        if (!item.reviewers || !item.reviewers[0]) delete item.reviewers;
        if (!item.assignee || !item.assignee[0]) delete item.assignee;
        if (item.files) {
          item.files.forEach((file) => {
            if (!file) return;
            var blob = file.slice(0, file.size, file.type);
            let newFileName = file.name
              .split(".")
              .join(`_subtask${index + 1}.`);
            let newFile = new File([blob], newFileName, { type: file.type });
            return formData.append("files", newFile);
          });
          delete item.files;
        }
        if (item.isNew) {
          item.daoId = daoId;
          item.projectId = projectId;
          newSubTask.push(item);
        } else {
          item.subTaskId = item.id;
          editSubTask.push(item);
        }
      });
      console.log({ editSubTask, newSubTask });
      formData.append("editSubTask", JSON.stringify(editSubTask));
      formData.append("newSubTask", JSON.stringify(newSubTask));
    }
    if (state.title) formData.append("title", state?.title);
    else {
      flag = 0;
      toast.error("Please enter the title for the task");
    }
    if (reviewer)
      formData.append(
        "reviewers",
        JSON.stringify([reviewer?.userId || reviewer?.id])
      );
    else {
      flag = 0;
      toast.error("Please choose a reviewer");
    }
    if (assignees.length > 0)
      formData.append(
        "assignee",
        JSON.stringify(
          assignees?.map((assignee) => assignee.userId || assignee.id)
        )
      );
    else {
      flag = 0;
      toast.error("Please choose an assignee");
    }

    formData.append("description", description);
    if (state?.submissionDate)
      formData.append("submissionDate", state?.submissionDate);

    files.map((file) => {
      var blob = file.slice(0, file.size, file.type);
      let newFileName = file.name.split(".").join(`_main.`);
      let newFile = new File([blob], newFileName, { type: file.type });
      return formData.append("files", newFile);
    });

    if (attachmentsToBeDeleted?.length > 0)
      formData.append(
        "deletedAttachments",
        JSON.stringify(attachmentsToBeDeleted)
      );

    if (mentionedUsers)
      formData.append("userTagged", JSON.stringify(mentionedUsers));
    if (flag) {
      setLoading(true);
      customAxios
        .post("/tasks/edit", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setLoading(false);

          if (res.data.success) {
            toast.success("Task updated successfully");
            handleCancel();
            onSubmit();
            setState(defaultState);
            // dispatch(fetchBounty({ daoId: daoId }));
          } else console.log("error");
        })
        .catch((err) => {
          console.log("error");
          setLoading(false);
        });
    }
  };

  const fileUploadRef = useRef(null);

  return (
    <StyledModalWrapper>
      <Modal
        centered={true}
        width="840px"
        bodyStyle={{
          minHeight: "450px",
          maxHeight: "720px",
          overflowY: "scroll",
        }}
        destroyOnClose
        visible={isModalVisible}
        onCancel={() =>
          confirmOperation({
            cancelText: "Discard Changes",
            confirmText: "Continue Editing",
            message: " You will lose the progress if you close this pop-up?",
            title: "Confirm",
            onCancel: () => handleCancel(),
          })
        }
        footer={[
          <Footer key="modal-footer" justifyContent="space-between">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Space>
                <input
                  hidden
                  ref={fileUploadRef}
                  type="file"
                  accept="image/*"
                  style={{ width: "0", height: "0" }}
                  onChange={(e) => {
                    const newFile = e.target.files[0];

                    // console.log("kk", newFile);
                    setFiles((prev) => [...prev, newFile]);
                  }}
                />
                <Button
                  className="borderless-button"
                  shape="circle"
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                >
                  <IconWrapper width="18px">
                    <PaperClipIcon />
                  </IconWrapper>
                </Button>
                <DueDate
                  defaultValue={defaultData.submissionDate}
                  onChange={(date, time) => {
                    if (date) {
                      const dateTime = moment(
                        date.format("YYYY-MM-DD") + ` ${time ? time : "23:59"}`
                      ).format("YYYY-MM-DD HH:mmZ");
                      setState((prev) => ({
                        ...prev,
                        submissionDate: dateTime,
                      }));
                    } else
                      setState((prev) => ({ ...prev, submissionDate: null }));
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    background: "#F4F2F2",
                    borderRadius: "20px",
                    padding: "4px 14px",
                    gap: "10px",
                    height: "32px",
                  }}
                >
                  <EyeIcon width={16} />
                  <ReviewerSelector
                    pillBg="#F4F2F2"
                    reviewer={reviewer}
                    setReviewer={setReviewer}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    background: "#F4F2F2",
                    borderRadius: "20px",
                    padding: "4px 14px",
                    height: "32px",
                    gap: "4px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      color: "#64748B",
                      margin: "auto 0",
                    }}
                  >
                    Assignees
                  </span>
                  <MultipleAssigneeSelector
                    pillBg="#F4F2F2"
                    assignees={assignees}
                    setAssignees={setAssignees}
                  />
                </div>
              </Space>
              <Button
                className="rounded-corner"
                type="primary"
                disabled={loading}
                style={{ marginLeft: "50px" }}
                onClick={() => updateTask(state)}
              >
                Save
              </Button>
            </Row>
          </Footer>,
        ]}
      >
        <div style={{ display: "flex", margin: "24px 0 10px 20px" }}>
          <StatusPill
            status={status}
            updateStatus={setStatus}
            disabled={false}
          />
        </div>
        <Input.TextArea
          autoFocus={true}
          style={{
            paddingLeft: "20px",
            fontSize: "21px",
            paddingRight: "35px",
            fontWeight: "600",
            margin: "10px 0",
          }}
          autoSize={{ minRows: "1", maxRows: "3" }}
          placeholder="Add task name"
          // size="large"
          className="ip-address"
          value={state?.title}
          onChange={(e) => {
            setState((prev) => ({ ...prev, title: e.target.value }));
          }}
          bordered={false}
        />
        <QuillEditor
          value={description}
          onChange={(e) => setDescription(e)}
          paddingLeft="8px"
          placeholder="Add details of your task below"
          setMentionedUsers={setMentionedUsers}
        />
        <div style={{ padding: "4px 20px" }}>
          <Attachments
            attachments={defaultAttachments}
            onDelete={(attachment) => {
              setAttachmentsToBeDeleted((prev) => [...prev, attachment]);
            }}
          />
        </div>
        {files.length > 0 && (
          <BountyFilesAttachment files={files} setFiles={setFiles} />
        )}
        {allSubtasks.length ? (
          <h4 style={{ margin: "0px 8px 2px 8px", padding: "6px 14px" }}>
            Subtasks:
          </h4>
        ) : null}
        {allSubtasks.map((subtask) => (
          <SubtaskCardView
            subtask={subtask}
            updateSubtasks={updateSubtasks}
            key={subtask.uuid}
            isEdit
          />
        ))}

        <CreateSubtaskInput
          addSubtaskHandler={updateSubtasks}
          allSubtasks={allSubtasks}
          isEdit
        />
      </Modal>
    </StyledModalWrapper>
  );
};

export default EditTaskModal;

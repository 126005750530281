import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Switch } from "antd";
import React from "react";
import {
  ENTITIES,
  EntityKeyType,
  EntityType,
} from "../../lib/constants/common";

type VisibilityButtonProps = {
  entity: EntityType;
  onClick: () => void;
  isEnabled: boolean;
};

const VisibilityButton = ({
  entity,
  isEnabled,
  onClick,
}: VisibilityButtonProps) => (
  <div className="flex gap-3 border border-solid rounded-xl border-gray-200 p-4 w-full">
    <div
      className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 text-xl"
      style={{ background: entity.background }}
    >
      {entity.icon}
    </div>
    <div className="flex flex-col gap-1">
      <p className="m-0 text-gray-800 font-semibold">{entity.name}</p>
      <p className="m-0 text-gray-400 text-xs">{entity.description}</p>
    </div>
    <div className="self-center flex-1 flex justify-end">
      <Switch
        checked={isEnabled}
        className=""
        onChange={onClick}
        disabled={entity.switchDisabled}
      />
    </div>
  </div>
);

type Props = {
  visibilityData: Record<EntityKeyType, boolean>;
  onToggleVisibility: (key: EntityKeyType) => void;
};

const CreateModalVisibility = ({
  onToggleVisibility,
  visibilityData,
}: Props) => {
  return (
    <div className="flex flex-col gap-6 px-4 lg:px-6 py-7 relative">
      <div className="flex flex-col gap-1">
        <h2 className="m-0 font-semibold lg:font-bold text-2xl">
          Modules for your Space
        </h2>
        <p className="m-0 text-gray-500">
          Choose the modules you want to show in your space
        </p>
      </div>
      <div className="flex flex-col gap-4">
        {ENTITIES.map((entity) => (
          <VisibilityButton
            entity={entity}
            isEnabled={!visibilityData[entity.key]}
            onClick={() => onToggleVisibility(entity.key)}
          />
        ))}
        <div className="hidden lg:flex gap-3 items-center text-blue-500 bg-blue-50 w-full py-3 px-5 rounded-lg">
          <InformationCircleIcon className="w-4" />
          <p className="m-0">You can always change this from the settings page</p>
        </div>
      </div>
    </div>
  );
};

export default CreateModalVisibility;

import React from "react";
import { CommunityType } from "../../types/DiscoverPage";
import twitterIcon from "../../images/twitter.svg";
import discordIcon from "../../assets/images/bountyPage/discord.svg";
import { useNavigate } from "react-router";
import { HorizontalScrollItem } from "./HorizontalScrollContainer";
import defaultBg from "./../../assets/images/profile/cover.png";
import { getDefaultDaoImage } from "../../utils";
import { COMMUNITY_TYPES } from "../../constants";
import "../ComponentStyles/discover_page.css";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
const CommunityCard = ({
  bountyCount,
  communityType,
  coverImage,
  id,
  communityTypes,
  memberCount,
  title,
  profileImage,
  discord,
  twitter,
  width = "33.3%",
}: CommunityType & { width?: string }) => {
  const navigation = useDaoLensNavigate();
  return (
    <HorizontalScrollItem width={width}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "12px",
          background: "white",
          cursor: "pointer",
          height: "100%",
          border: "1px solid #E2E8F0",
        }}
        onClick={() => navigation(`/app/dao/${title}/${id}/overview`)}
        className="community_card_hover_state"
      >
        <img
          src={coverImage || defaultBg}
          alt={`${title} cover`}
          style={{
            objectFit: "cover",
            height: "106px",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            width: "100%",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "12px",
            gap: "12px",
            flex: "1",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                columnGap: "8px",
                alignItems: "center",
              }}
            >
              {!profileImage &&
                getDefaultDaoImage(
                  title,
                  {
                    height: "26px",
                    marginRight: "4px",
                    width: "26px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  },
                  { fontSize: "px" }
                )}
              {profileImage && (
                <img
                  src={profileImage}
                  alt={title}
                  style={{
                    borderRadius: "100%",
                    width: "27px",
                    height: "27px",
                    objectFit: "cover",
                  }}
                />
              )}
              <p style={{ fontWeight: "600", fontSize: "14px", margin: 0 }}>
                {title}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                columnGap: "8px",
                alignItems: "center",
              }}
            >
              {twitter && (
                <a
                  href={twitter}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={twitterIcon}
                    alt="Twitter logo"
                    style={{ width: "16px" }}
                  />
                </a>
              )}
              {discord && (
                <a
                  href={discord}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <img
                    src={discordIcon}
                    alt="Twitter logo"
                    style={{ width: "16px" }}
                  />
                </a>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              fontSize: "12px",
              color: "#64748B",
              marginTop: "auto",
            }}
          >
            <p
              style={{
                background: "#F7F7F7",
                borderRadius: "6px",
                padding: "4px 10px",
              }}
            >
              👥 : {memberCount}
            </p>
            <p
              style={{
                background: "#F7F7F7",
                borderRadius: "6px",
                padding: "4px 10px",
              }}
            >
              💰 : {bountyCount}
            </p>
            {communityTypes && (
              <p
                style={{
                  background: "#F7F7F7",
                  borderRadius: "6px",
                  padding: "4px 10px",
                }}
              >
                {
                  COMMUNITY_TYPES.find((type) => communityTypes[0] === type.id)
                    ?.name
                }
                {communityTypes.length > 1 && "+1"}
              </p>
            )}
          </div>
        </div>
      </div>
    </HorizontalScrollItem>
  );
};

export default CommunityCard;

import React, { Dispatch, SetStateAction, useState } from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import { Modal } from "../../../../styles/ModalStyles";
import TwitterIcon from "../../../../images/twitter.svg";
import { Button } from "antd";
import { toast } from "react-toastify";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onTweetAndVerify: (tweetMsg) => void;
  defaultTweetMsg: string;
  userTwitterDetails: {
    name: string;
    username: string;
    profilePicture: string;
  };
  tweetUrl?: string;
  reloadBountyDetails: () => void;
};

const TweetModal = ({
  isOpen,
  onTweetAndVerify,
  setIsOpen,
  defaultTweetMsg,
  userTwitterDetails,
  tweetUrl,
  reloadBountyDetails,
}: Props) => {
  const { isMobile } = useWindowSize();
  const [isTweetReqSent, setIsTweetReqSent] = useState(false);

  const handleSubmit = () => {
    onTweetAndVerify(defaultTweetMsg);
    setIsTweetReqSent(true);
  };

  const handleVerify = () => {
    reloadBountyDetails();
    toast.success("Task verified successfully");
    setIsOpen(false);
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      // @ts-expect-error
      isMobile={isMobile}
      height="auto"
    >
      <div className="flex flex-col p-5">
        <div className="flex items-center gap-2">
          <h3 className="m-0 text-gray-800 font-semibold text-base">
            Preview Tweet
          </h3>
        </div>
        <p className="m-0 font-normal text-xs text-gray-500 mt-1">
          Below is the preview of the Tweet which would be posted from your
          Twitter account.
        </p>
        <div className="p-5 border border-solid border-gray-200 rounded-2xl mt-4 flex flex-col gap-2 h-fit">
          <div className="flex gap-2 items-start">
            {userTwitterDetails?.profilePicture && (
              <div className="relative">
                <img
                  src={userTwitterDetails?.profilePicture}
                  alt={userTwitterDetails?.username}
                  className="rounded-full w-11 h-11"
                />
                <div className="bg-white w-5 h-5 border border-solid border-gray-100 rounded-full absolute bottom-0 right-0 flex items-center justify-center">
                  <img src={TwitterIcon} className="w-3" alt="Twitter" />
                </div>
              </div>
            )}
            <div className="flex flex-col">
              {userTwitterDetails?.name && (
                <div className="flex gap-2 items-center flex-wrap">
                  <p className="m-0 text-base text-neutral-900 font-semibold">
                    {userTwitterDetails?.name}
                  </p>
                  <p className="m-0 text-gray-500 font-normal">
                    @{userTwitterDetails?.username}
                  </p>
                </div>
              )}
              <p className="border-none outline-none m-0">{defaultTweetMsg}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center w-full mt-4">
          {tweetUrl ? (
            <>
              <p className="m-0 text-gray-500 text-xs">
                Above Tweet has been successfully posted 🎉
              </p>
              <div className="flex ml-auto gap-3">
                <Button
                  style={{ borderRadius: "8px" }}
                  onClick={() => {
                    window.open(tweetUrl);
                    handleVerify();
                  }}
                >
                  View Tweet
                </Button>
                <Button
                  type="primary"
                  style={{ borderRadius: "8px" }}
                  onClick={handleVerify}
                >
                  Verify Submission
                </Button>
              </div>
            </>
          ) : (
            <div className="flex ml-auto gap-3">
              <Button
                style={{ borderRadius: "8px" }}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ borderRadius: "8px" }}
                onClick={handleSubmit}
                loading={isTweetReqSent && !tweetUrl}
                disabled={isTweetReqSent || !!tweetUrl}
              >
                Tweet
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TweetModal;

import { Input } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import QuillEditor from "../QuillEditor";
import AddDisplayPicture from "./AddDisplayPicture";

type Props = {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  profilePicture: string | null;
  setProfilePicture: Dispatch<SetStateAction<string | null>>;
  emojiProfilePicture: {
    emoji: string;
    background: string;
  } | null;
  setEmojiProfilePicture: Dispatch<
    SetStateAction<{
      emoji: string;
      background: string;
    } | null>
  >;
  isUploadCompUpdated: boolean;
  setIsUploadCompUpdated: Dispatch<SetStateAction<boolean>>;
};

const CreateModalDetails = ({
  description,
  emojiProfilePicture,
  name,
  profilePicture,
  setDescription,
  setEmojiProfilePicture,
  setName,
  isUploadCompUpdated,
  setProfilePicture,
  setIsUploadCompUpdated,
}: Props) => {
  return (
    <div className="flex flex-col gap-6 lg:gap-8 px-4 lg:px-6 py-7">
      <div className="flex flex-col gap-1">
        <h2 className="m-0 font-semibold text-2xl">Space details</h2>
        <p className="m-0 text-gray-500">
          Help your members learn about your private space
        </p>
      </div>
      <div className="flex flex-col lg:flex-row gap-3 lg:items-center">
        <div className="flex flex-col gap-2">
          <label className="text-gray-500 lg:hidden">
            Add icon/image for your space*
          </label>
          <AddDisplayPicture
            emojiProfilePicture={emojiProfilePicture}
            isUploadCompUpdated={isUploadCompUpdated}
            profilePicture={profilePicture}
            setEmojiProfilePicture={setEmojiProfilePicture}
            setIsUploadCompUpdated={setIsUploadCompUpdated}
            setProfilePicture={setProfilePicture}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <label className="text-gray-500">Name your space*</label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Eg. Developers Space"
            maxLength={75}
            style={{
              borderRadius: "12px",
              padding: "12px 16px",
              height: "40px",
              marginBottom: "8px",
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <label className="text-gray-500">
          What’s the goal of your private space?*
        </label>
        <QuillEditor
          value={description}
          height="175px"
          isBordered
          onChange={(e: string) => setDescription(e)}
          placeholder="Add description to let the members know more about the DAO"
        />
      </div>
    </div>
  );
};

export default CreateModalDetails;

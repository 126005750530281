import { Select } from "antd";
import moment from "moment-timezone";
import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import styled from "styled-components";

type Props = {
  timezone: string;
  setTimezone: Dispatch<SetStateAction<string>>;
};
const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 12px !important;
  }
`;
const TimezoneDropdwon = ({
  setTimezone,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
}: Props) => {
  const allTimezones = useMemo(() => moment?.tz?.names(), []);

  useEffect(() => {
    return () => {
      moment.tz.setDefault(moment.tz.guess());
    };
  }, []);
  return (
    <div className="flex gap-3 w-full items-center daolens-select-container">
      <label style={{ fontSize: "12px", fontWeight: "600", color: "#64748B" }}>
        Time Zone:
      </label>
      <StyledSelect
        value={timezone}
        style={{ flexGrow: "1" }}
        dropdownStyle={{ borderRadius: "8px" }}
        onChange={(e) => {
          moment.tz.setDefault(e);
          setTimezone(e);
        }}
        options={allTimezones?.map((tz) => ({ value: tz, label: tz }))}
      />
    </div>
  );
};

export default TimezoneDropdwon;

import customAxios from "../api";

export type CollectionType = {
  id: string;
  createdAt: string;
  updatedAt: string;
  icon: string;
  title: string;
  description: string;
  daoId: string;
  background: string;
  gatingCondition: any;
  pinOverview: boolean;
  totalDocuments: number;
};

type DocumentType = {
  id: string;
  collectionId: any;
  link: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  daoId: string;
  pinOverview: boolean;
};

type PinToOverviewOptions = {
  pin: boolean;
  documentId?: string;
  collectionId?: string;
};
export const pinDocumentToOverview = async ({
  documentId,
  pin,
  collectionId,
}: PinToOverviewOptions) => {
  const body: Record<string, string | boolean> = { pinOverview: pin };
  if (documentId) body.documentId = documentId;
  if (collectionId) body.collectionId = collectionId;
  await customAxios.post("/documents/pin/overview", body);
};

export const fetchDocsInOview = async (daoId: string) => {
  if (!daoId)
    return {
      collection: [],
      document: [],
    };
  const response = await customAxios.get("/documents/fetch/overview", {
    params: { daoId },
  });

  return response?.data as {
    collection: CollectionType[];
    document: DocumentType[];
  };
};

import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { Modal, Footer } from "../../../styles/ModalStyles";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../../api";
import { ReactComponent as EnterEmoji } from "../../../assets/images/project/enter_emoji.svg";

import { getContentType } from "../CourseContent/CourseContentCard";
import { tabs } from "../CreateCourseContentModal";
import { BACKGROUND_COLORS } from "../../Project.js/CreateProjectModal";
import EmojiPicker from "emoji-picker-react";
import { postMixpanel } from "../../../utils";
import useWindowSize from "../../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../../constants";
import GatingModal, { defaultConditionDetails } from "../../Gating/GatingModal";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import useGating from "../../../hooks/useGating";
import { confirmOperation } from "../../../utils/common";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";

export const Stylediv = styled.div`
  background: white;
  &:hover {
    background: rgba(226, 232, 240, 0.2);
  }
`;

export const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;

const CreateDocumentModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  edit = false,
  document = null,
  collection = null,
  isOverviewPinned = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { daoId,daoName } = useDaoContext();
  const navigate = useDaoLensNavigate();

  const defaultState = {
    tab: "UPLOAD",
  };
  const [state, setState] = useState(() => defaultState);

  const [background, setBackground] = useState(
    BACKGROUND_COLORS[Math.floor(Math.random() * BACKGROUND_COLORS.length)]
  );
  const [showEmojiModal, setEmojiModal] = useState(false);
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const [type, setType] = useState("SINGLE_DOCUMENT");
 const resetFields = () =>{
    
    setState((prev) => ({
      ...prev,
      description:"",
      title:""
    }));


 }

  useEffect(() => {
    if (edit) {
      setState((prev) => ({
        ...prev,
        title: document ? document?.title : collection?.title,
        tab: document ? getContentType(document) : "",
        description: document ? document?.description : collection?.description,
        gatingCondition: collection?.gatingCondition,
        ...tabs
          .find(({ value }) =>
            document ? value === getContentType(document) : false
          )
          ?.initialState(document),
      }));
      if (collection) {
        setState((prev) => ({
          ...prev,
          icon: collection.icon,
          background: collection.background,
        }));
      }
      setType(collection ? "COLLECTION" : "SINGLE_DOCUMENT");
      gatingState.setGatingState(
        collection?.gatingConditionId,
        collection?.gatingCondition
      );
    } else {
      setType("SINGLE_DOCUMENT");
      setState(defaultState);
    }
    setEmojiModal(false);
  }, [isModalVisible]);
 

  const createDocument = () => {
    let res = {};
    if (type === "SINGLE_DOCUMENT") {
      tabs.find(({ value }) => value === state.tab)?.validation(state, res);
    } else {
      res.background = background;
      res.icon = state.icon ? state.icon : "🗂";
    }
    if (!state.title) {
      toast.error("Please provide document title");
      throw new Error();
    } else {
      res.title = state.title;
    }
    if (type === "COLLECTION") {
      gatingState.createResponseObject(res);
    }

    if (state.description) {
      res.description = state.description;
    }

    if (isOverviewPinned) {
      res.pinOverview = isOverviewPinned;
    }
    res.daoId = daoId;

    setLoading(true);
    if (collection) res.collectionId = collection.id;
    if (type === "SINGLE_DOCUMENT") {
      const formData = new FormData();
      Object.entries(res)?.forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (edit) {
        formData.append("documentId", document.id);

        customAxios
          .patch(`/documents/update`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            postMixpanel("track", "edit_document", {
              dao_id: daoId,
              collection_id: collection?.id,
              document_title: state.title,
            });
            toast.success(`Document updated successfully`);
            handleCancel();
            onSubmit();
            setState(defaultState);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        customAxios
          .post(`/documents/upload`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            postMixpanel("track", "create_document", {
              dao_id: daoId,
              collection_id: collection?.id,
              document_title: state.title,
            });
            toast.success(`Document added successfully`);
            handleCancel();
            onSubmit();
            setState(defaultState);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (edit) {
      customAxios
        .patch(`/documents/collection/update`, res)
        .then((res) => {
          toast.success(`Collection updated successfully`);
          postMixpanel("track", "edit_collection", {
            dao_id: daoId,
            collection_id: collection?.id,
            collection_title: state.title,
          });
          handleCancel();
          onSubmit();
          setState(defaultState);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      customAxios
        .post(`/documents/collection/create`, res)
        .then((res) => {
          toast.success(`Collection added successfully`);
          postMixpanel("track", "create_collection", {
            dao_id: daoId,
            collection_title: state.title,
          });
          handleCancel();
          onSubmit();
          setState(defaultState);
          navigate(`/app/dao/${daoName}/${daoId}/library/${res.data.collectionId}`)


        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const gatingState = useGating();
  return (
    <>
      <StyledModalWrapper>
        <Modal
          centered
          isMobile={isMobile}
          width="640px"
          height="auto"
          destroyOnClose
          className="create-quiz"
          title={<></>}
          visible={isModalVisible}
          onCancel={() => {
            if (
              state.icon ||
              state.title ||
              state.description ||
              state.link ||
              state.file
            )
              confirmOperation({
                cancelText: "Discard Changes",
                confirmText: "Continue Editing",
                message:
                  " You will lose the progress if you close this pop-up?",
                title: "Confirm",
                onCancel: () => handleCancel(),
              });
            else {
              handleCancel();
            }
          }}
          footer={
            state?.tab === "ADD_LINK" &&
            !(
              state.link &&
              !state.link?.includes("https://storage.googleapis.com")
            ) && type==="SINGLE_DOCUMENT"
              ? null
              : [
                  <Footer key="modal-footer" justifyContent="flex-end">
                    <div style={{ display: "flex", gap: "8px" }}>
                      {type === "COLLECTION" ? (
                        <GatingModal finalGatingState={gatingState} />
                      ) : null}
                      <Button
                        disabled={loading}
                        className="rounded-corner"
                        type="primary"
                        onClick={() => {
                          createDocument();
                        }}
                        style={{ cursor: loading ? "wait" : "pointer" }}
                      >
                        {edit ? "Update" : "Add"} {type === "COLLECTION"? "collection": "document"}
                      </Button>
                    </div>
                  </Footer>,
                ]
          }
        >
          <div
            style={{
              padding: "0px 20px",
              font: "normal 600 20px/28px Inter",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginBottom: "20px",
            }}
            onClick={() => {
              setEmojiModal(false);
            }}
          >
            {edit ? "Update Content" : "Add Content"}

            {!collection && !edit && (
              <div
                className="bounties-state-filter__container discover-courses"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  onClick={() => {
                    setType("SINGLE_DOCUMENT");
                    resetFields();
                  }}
                  className={
                    type === "SINGLE_DOCUMENT"
                      ? "bounties-state-filter__single-filter selected"
                      : "bounties-state-filter__single-filter"
                  }
                >
                  Single document
                </span>
                <span
                  onClick={() => {
                    setType("COLLECTION");
                    resetFields();
                  }}
                  className={
                    type === "COLLECTION"
                      ? "bounties-state-filter__single-filter selected"
                      : "bounties-state-filter__single-filter"
                  }
                >
                  Collection
                </span>
              </div>
            )}
            {type === "SINGLE_DOCUMENT" && (
              <>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    font: "normal 500 14px/20px Inter",
                    flexWrap: "wrap",
                    color: "#64748B",
                  }}
                >
                  {[tabs[0], tabs[1]].map(({ label, value, icon }) => (
                    <Stylediv
                      key={value}
                      onClick={() => {
                        setState((prev) => {
                          return { ...prev, tab: value };
                        });
                      }}
                      style={{
                        padding: "8px 16px",
                        border: `1px solid ${
                          state.tab === value ? "#76A9FA" : "#E5E7EB"
                        }`,
                        borderRadius: "40px",
                        cursor: "pointer",
                        color: state.tab === value ? "#3F83F8" : "",
                        background: state.tab === value ? "#E1EFFE" : "",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {icon(state.tab === value)}
                      {label}
                    </Stylediv>
                  ))}
                </div>
                {tabs
                  .find(({ value }) => value === state.tab)
                  ?.component(state, setState)}
              </>
            )}
            {type === "COLLECTION" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  width: "202px",
                  font: "normal 600 12px/16px Inter",
                  width: "100%",
                  color: "#64748B",
                }}
              >
                <div>
                  {state.icon ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setEmojiModal(true);
                      }}
                      style={{
                        font: "normal 500 30px/40px Inter",
                        width: "60px",
                        height: "60px",
                        background,
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border: "1.5px solid #FFFFFF",
                        borderRadius: "12px",
                        boxShadow: " 0px 0px 3px rgba(25, 38, 72, 0.2)",
                        cursor: "pointer",
                        marginBottom: "8px",
                      }}
                    >
                      {state.icon}
                    </div>
                  ) : (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setEmojiModal(true);
                      }}
                      style={{
                        padding: "16px",
                        background: "rgba(226, 232, 240, 0.2)",
                        borderRadius: "8px",
                        display: "inline-flex",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "4px",
                        font: "normal 500 12px/16px Inter",
                        color: "#64748B",
                        border: "1px dashed #CFD8E3",
                        borderRadius: "16px",
                        cursor: "pointer",
                        marginBottom: "8px",
                      }}
                    >
                      <EnterEmoji
                        width="28px"
                        height="28px"
                        // fill="#97A6BA"
                        color="pink"
                      ></EnterEmoji>
                      Add icon
                    </div>
                  )}
                </div>
                {showEmojiModal && (
                  <div
                    style={{ position: "fixed", zIndex: 10 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <EmojiPicker
                      onEmojiClick={(emojiData) => {
                        setState((prev) => ({
                          ...prev,
                          icon: emojiData.emoji,
                        }));
                        if (!edit)
                          setBackground(
                            BACKGROUND_COLORS[
                              Math.floor(
                                Math.random() * BACKGROUND_COLORS.length
                              )
                            ]
                          );
                        setEmojiModal(false);
                      }}
                      autoFocusSearch={false}
                    />
                  </div>
                )}
                Title of the collection
                <Input
                  value={state.title}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }));
                  }}
                  placeholder="Add a suitable title for your collection"
                  maxLength={75}
                  style={{
                    borderRadius: "12px",
                    padding: "12px 16px",
                    height: "40px",
                    marginBottom: "8px",
                  }}
                />
                Description
                <Input.TextArea
                  value={state.description}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  placeholder="Describe what the collection of documents is about"
                  style={{
                    borderRadius: "16px",
                    height: "120px",
                    resize: "none",
                    padding: "12px 16px",
                    marginBottom: "8px",
                  }}
                />
              </div>
            )}
          </div>
        </Modal>
      </StyledModalWrapper>
    </>
  );
};

export default CreateDocumentModal;

import { IssuesCloseOutlined } from "@ant-design/icons";
import { getOrdinal } from "../../../Proposal/api";
import { ChoiceCard } from "../../../Proposal/Vote";
import GatingConditionPopover from "../../GatingConditionPopover";

const Ranked = ({
  choices,
  selectedChoice,
  setSelectedChoice,
  canVote,
  gatingConditions,
}) => {
  return (
    <>
      {choices.map((choice) => {
        let isSelected = selectedChoice?.includes(choice.id);
        let currentChoiceRank = selectedChoice.indexOf(choice.id);
        return (
          <ChoiceCard
            isSelected={isSelected}
            onClick={() => {
              if (canVote)
                setSelectedChoice((prev) => {
                  if (isSelected) {
                    prev.splice(prev.indexOf(choice.id), 1);
                    return [...prev];
                  } else return [...prev, choice.id];
                });
            }}
            style={{ justifyContent: "space-between", padding: "0px 12px" }}
          >
            {choice.name}
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              {!canVote ? (
                <GatingConditionPopover
                  gatingConditions={gatingConditions}
                ></GatingConditionPopover>
              ) : null}
              {isSelected ? (
                <div style={{}}>({getOrdinal(currentChoiceRank + 1)})</div>
              ) : null}
              {isSelected && (
                <svg
                  style={{}}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.2773 3.08403C13.5071 3.2372 13.5692 3.54764 13.416 3.7774L7.41602 12.7774C7.33293 12.902 7.19834 12.9829 7.04927 12.9976C6.9002 13.0124 6.75237 12.9595 6.64645 12.8536L2.64645 8.85361C2.45118 8.65834 2.45118 8.34176 2.64645 8.1465C2.84171 7.95124 3.15829 7.95124 3.35355 8.1465L6.92229 11.7152L12.584 3.2227C12.7372 2.99294 13.0476 2.93085 13.2773 3.08403Z"
                    fill="#3F83F8"
                  />
                </svg>
              )}
            </div>
          </ChoiceCard>
        );
      })}
    </>
  );
};

export default Ranked;

import { Button, Col, Row, Skeleton } from "antd";
import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import snapShotIcon from "../../../images/snapshot.png";
import discourseIcon from "../../../images/discourse_icon.png";
import metamaskIcon from "../../../assets/iconSvg/metamask.png";
import customAxios from "../../../api";
import { DaoContext } from "../../../layouts/app/DaoContext";
import SnapshotIntegrationModal from "./SnapshotIntegrationModal";
import { getAddress } from "ethers/lib/utils";
import { Modal } from "../../../styles/ModalStyles";
import { Modal as AntModal } from "antd";
import { labeStyling } from "../DaoProfile/InputField";
import { useWalletConnect } from "../../../layouts/app/WalletConnectContext";
import walletConnectIcon from "../../../assets/iconSvg/wallet-connect.svg";
import styled from "styled-components";
import { useEthers } from "@usedapp/core";
import DiscourseIntegrationModal from "./DiscourseIntegrationModal";
import SecondaryButtonOutlined from "../../InternalComponents/PostSecondaryButtonOutlined";
import useWindowSize from "../../../hooks/useWindowSize";
import useDisourse from "../../../hooks/useDiscourse";
import {
  getTruncatedAddress,
  getTruncatedString,
  postMixpanel,
} from "../../../utils";

const MetamaskConnectButton = styled(Button)`
  &:hover {
    background: #f4f2f2 !important;
  }
`;

const WalletConnectButton = styled(Button)`
  &:hover {
    background: #f4f2f2 !important;
  }
`;

function Integrations() {
  const [loading, setLoading] = useState(false);
  const [isIntegrationSnapshotModalOpen, setIsIntegrationSnapshotModalOpen] =
    useState(false);

  const [isIntegrationDiscourseModalOpen, setIsIntegrationDiscourseModalOpen] =
    useState(false);
  const {
    daoDetails,
    reload,
    loading: daoDataLoading,
    daoId,
  } = useContext(DaoContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSnapshotIntegrated = !!daoDetails?.snapshotEnsName;
  const isMetamaskIntegrated = !!daoDetails?.walletAddress;
  const isDiscordConnected = !!daoDetails?.discordGuildId;

  const isDiscourseImported = !!daoDetails?.communityUrl;
  const discordServerName = daoDetails?.discordName;
  const daoName = daoDetails?.daoName;
  const { onClickActivate } = useWalletConnect();
  const { status, refetchStatus } = useDisourse(daoId);

  const { isMobile } = useWindowSize();

  const { account } = useEthers();
  useEffect(() => {
    if (daoDetails?.snapshotEnsName)
      postMixpanel("register_once", "integrated_discord_through_settings", {
        dao_id: daoId,
      });
  }, [daoDetails?.snapshotEnsName]);
  useEffect(() => {
    if (isDiscordConnected)
      postMixpanel("register_once", "integrated_discord_through_settings", {
        dao_id: daoId,
      });
  }, [isDiscordConnected]);
  useEffect(() => {
    if (status === "IMPORTED")
      postMixpanel("register_once", "integrated_discourse_through_settings", {
        dao_id: daoId,
      });
  }, [status]);
  useEffect(() => {
    setLoading(true);
    if (!!account) {
      const formData = new FormData();
      formData.append("daoId", daoId);
      formData.append("walletAddress", account);
      customAxios
        .post("dao/edit", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          postMixpanel(
            "resgister_once",
            "added_community_wallet_through_settings",
            {
              dao_id: daoId,
            }
          );
          toast.success("Wallet connected successfully");
          setIsModalOpen(false);
          reload();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [account, daoId]);

  const diconnectDiscord = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("daoId", daoId);
    formData.append("discordGuildId", null);
    customAxios
      .post("dao/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        toast.success("Discord disconnected");
        reload();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setLoading(false);
      });
  };

  const connectMetamask = () => {
    if (window.ethereum) {
      setLoading(true);
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          const formData = new FormData();
          formData.append("daoId", daoId);
          formData.append("walletAddress", getAddress(res[0]));
          customAxios
            .post("dao/edit", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(() => {
              toast.success("Metamask connected successfully");
              setIsModalOpen(false);
              reload();
            });
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Install metamask extension!!");
    }
  };

  const handleWalletDisconnect = () => {
    setLoading(true);
    if (isMetamaskIntegrated) {
      const formData = new FormData();
      formData.append("daoId", daoId);
      formData.append("walletAddress", null);
      customAxios
        .post("dao/edit", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          toast.success("Wallet disconnected");
          reload();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const ConnectMetaMask = () => {
    return (
      <MetamaskConnectButton
        style={{
          ...labeStyling,
          color: "#014737",
          padding: "22px 8px",
          gap: "8px",
          display: "inline-flex",
          border: `1px solid ${isMetamaskIntegrated ? "#0E9F6E" : "#E2E8F0"}`,
          alignItems: "center",
          borderRadius: "8px",
          background: isMetamaskIntegrated ? "#F3FAF7" : "",
          marginBottom: "12px",
          cursor:
            isMetamaskIntegrated && !isDiscordConnected ? "not-allowed" : "",
        }}
        onClick={() => {
          if (!isMetamaskIntegrated) {
            connectMetamask();
          }
        }}
      >
        {" "}
        <img src={metamaskIcon} alt="icon" height="16" />
        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: "500" }}>
          Metamask
        </p>
      </MetamaskConnectButton>
    );
  };

  const WalletConnect = () => {
    const handleConnectWallet = () => {
      onClickActivate();
    };

    return (
      <WalletConnectButton
        style={{
          ...labeStyling,
          color: "#014737",
          padding: "22px 8px",
          gap: "8px",
          display: "inline-flex",
          border: `1px solid ${isMetamaskIntegrated ? "#0E9F6E" : "#E2E8F0"}`,
          alignItems: "center",
          borderRadius: "8px",
          background: isMetamaskIntegrated ? "#F3FAF7" : "",
          marginBottom: "12px",
          cursor:
            isMetamaskIntegrated && !isDiscordConnected ? "not-allowed" : "",
        }}
        onClick={handleConnectWallet}
      >
        <img src={walletConnectIcon} alt="icon" height="16" />
        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: "500" }}>
          Wallet Connect
        </p>
      </WalletConnectButton>
    );
  };

  const handleSnapShotDisconnect = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("daoId", daoId);
      formData.append("snapshotEnsName", null);
      await customAxios.post("dao/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      reload();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Modal
        title={
          <h2
            style={{
              paddingLeft: "13px",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            Connect a wallet
          </h2>
        }
        visible={isModalOpen && !isMetamaskIntegrated}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setIsModalOpen(false)}
        width="420px"
      >
        <div
          style={{ padding: "20px", display: "flex", flexDirection: "column" }}
        >
          <ConnectMetaMask />
          <WalletConnect />
        </div>
      </Modal>
      <Col xs={24} style={{ padding: "0px", marginTop: "20px" }}>
        {isMobile ? null : (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #F4F2F2",
              borderRadius: "16px",
              padding: "12px 20px",
              background: "white",
              marginBottom: "12px",
              minHeight: "58px",
            }}
          >
            <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>
              Integrations
            </h4>
          </Row>
        )}
        {daoDataLoading ? (
          <Row
            style={{
              background: "white",
              borderRadius: "12px",
              padding: "20px",
            }}
          >
            <Skeleton />
          </Row>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            <Row
              xs={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "white",
                borderRadius: "12px",
                padding: "20px",
              }}
            >
              <Row style={{ alignItems: "center", gap: "8px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <i
                    class="fab fa-discord"
                    style={{
                      color: "#5165F6",
                      fontSize: "19px",
                      marginRight: "0.2rem",
                    }}
                  ></i>
                  <h3 style={{ marginBottom: "0px" }}>Discord</h3>
                </div>
                {isDiscordConnected ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "#9CA3AF" }}>&#x2022;</span>
                    <span style={{ color: "#9CA3AF" }}>
                      {getTruncatedString(daoDetails.discordName, 20)}
                    </span>
                  </div>
                ) : null}
              </Row>
              {isDiscordConnected ? (
                <Button
                  // disabled={true}
                  type="secondary"
                  disabled={loading}
                  style={{
                    borderRadius: "12px",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                    color: "#9CA3AF",
                  }}
                  onClick={() => diconnectDiscord()}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    window.open(
                      `https://discord.com/api/oauth2/authorize?client_id=${
                        process.env.REACT_APP_ENDPOINT === "development"
                          ? "1046753505026785401"
                          : "1014817083139301417"
                      }&redirect_uri=${
                        encodeURIComponent(window.location.origin) +
                        "/app/redirect/discordIntgeration"
                      }&response_type=code&state=${btoa(
                        JSON.stringify({ daoId, from: "settings" })
                      )}&scope=identify%20guilds`,
                      "_self"
                    )
                  }
                  type="primary"
                  style={{
                    borderRadius: "12px",
                    background: "#3B82F6",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Connect
                </Button>
              )}{" "}
            </Row>
            <Row
              xs={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "white",
                borderRadius: "12px",
                padding: "20px",
              }}
            >
              <Row style={{ alignItems: "center", gap: "8PX" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={metamaskIcon} alt="icon" height="20" />
                  <h3 style={{ marginBottom: "0px", marginLeft: "4px" }}>
                    Connect wallet
                  </h3>
                </div>
                {isMetamaskIntegrated ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "#9CA3AF" }}>&#x2022;</span>
                    <span style={{ color: "#9CA3AF" }}>
                      {getTruncatedAddress(daoDetails.walletAddress)}
                    </span>
                  </div>
                ) : null}
              </Row>
              {isMetamaskIntegrated ? (
                <Button
                  disabled={loading}
                  type="secondary"
                  style={{
                    borderRadius: "12px",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                    color: "#9CA3AF",
                  }}
                  onClick={handleWalletDisconnect}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  onClick={() => setIsModalOpen(true)}
                  type="primary"
                  style={{
                    borderRadius: "12px",
                    background: "#3B82F6",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Connect
                </Button>
              )}{" "}
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "white",
                borderRadius: "12px",
                padding: "20px",
              }}
            >
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <img
                  src={snapShotIcon}
                  alt="snapshot"
                  style={{ width: "19px", height: "23px" }}
                />
                <h3 style={{ marginBottom: "0px" }}>Snapshot</h3>
                {isSnapshotIntegrated ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "#9CA3AF" }}>&#x2022;</span>
                    <span style={{ color: "#9CA3AF" }}>
                      {daoDetails?.snapshotEnsName}
                    </span>
                  </div>
                ) : null}
              </div>
              {isSnapshotIntegrated ? (
                <Button
                  type="secondary"
                  disabled={loading}
                  style={{
                    borderRadius: "12px",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                    color: "#9CA3AF",
                  }}
                  onClick={handleSnapShotDisconnect}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  onClick={() => setIsIntegrationSnapshotModalOpen(true)}
                  type="primary"
                  style={{
                    borderRadius: "12px",
                    background: "#3B82F6",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Connect
                </Button>
              )}{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "white",
                borderRadius: "12px",
                padding: "20px",
              }}
            >
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8PX" }}
                >
                  <img
                    src={discourseIcon}
                    alt="snapshot"
                    style={{ width: "20 px", height: "20px" }}
                  />
                  <h3 style={{ marginBottom: "0PX" }}>Import from Discourse</h3>
                </div>

                {isDiscourseImported ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "#9CA3AF" }}>&#x2022;</span>
                    <span style={{ color: "#9CA3AF" }}>
                      {getTruncatedString(daoDetails?.communityUrl, 20)}
                    </span>
                  </div>
                ) : null}
              </div>
              {status === "IMPORT" ? (
                <Button
                  onClick={() => setIsIntegrationDiscourseModalOpen(true)}
                  type="primary"
                  style={{
                    borderRadius: "12px",
                    background: "#3B82F6",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Import
                </Button>
              ) : (
                <SecondaryButtonOutlined
                  buttonText={
                    status === "IMPORTING" ? (
                      <div className="flex items-center">
                        <svg
                          style={{
                            marginRight: "4px",
                            animation:
                              "anti-clock-wise-rotation 2s infinite linear",
                          }}
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.66675 2.66654V5.99987H3.05475M13.2921 7.3332C13.1446 6.16178 12.6127 5.07238 11.7797 4.23572C10.9466 3.39907 9.85951 2.86245 8.68873 2.70996C7.51795 2.55748 6.32967 2.79775 5.3101 3.39311C4.29054 3.98848 3.49731 4.90529 3.05475 5.99987M3.05475 5.99987H6.00008M13.3334 13.3332V9.99987H12.9461M12.9461 9.99987C12.5029 11.0938 11.7094 12.0099 10.69 12.6047C9.67051 13.1996 8.48257 13.4395 7.31215 13.2871C6.14172 13.1347 5.05488 12.5985 4.22177 11.7624C3.38866 10.9263 2.85632 9.8375 2.70808 8.66654M12.9461 9.99987H10.0001"
                            stroke="#64748B"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Importing
                      </div>
                    ) : status === "IMPORTED" ? (
                      "Imported"
                    ) : status === "LOADING" ? (
                      "Loading"
                    ) : (
                      <div className="text-red-500">Failed.Try Again.</div>
                    )
                  }
                  style={{
                    cursor: status === "IMPORTED" ? "not-allowed" : "pointer",
                  }}
                  clickHandler={() => {
                    if (status !== "IMPORTED")
                      setIsIntegrationDiscourseModalOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        )}
        <DiscourseIntegrationModal
          status={status}
          daoId={daoId}
          reload={() => {
            reload();
            refetchStatus();
          }}
          daoDetails={daoDetails}
          isOpen={isIntegrationDiscourseModalOpen}
          setIsOpen={setIsIntegrationDiscourseModalOpen}
        />
        <AntModal
          visible={isIntegrationSnapshotModalOpen}
          footer={null}
          onCancel={() => setIsIntegrationSnapshotModalOpen(false)}
          className="snapshot-modal"
        >
          <SnapshotIntegrationModal
            daoId={daoId}
            reload={reload}
            isOpen={isIntegrationSnapshotModalOpen}
            onSubmit={() => {
              setIsIntegrationSnapshotModalOpen(false);
            }}
          />
        </AntModal>
      </Col>
    </Fragment>
  );
}

export default Integrations;

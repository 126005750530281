import { Select, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import customAxios from "../../api";
import SyncDiscordRoles from "./SyncDiscordRoles";
import { DaoContext } from "../../layouts/app/DaoContext";
import ConnectDiscord from "./ConnectDiscord";

const { Option } = Select;
const DiscordComponent = ({
  ele,
  idx,
  handleConditionDetailChange,
  isSpaceGating = false,
}) => {
  const [discordRoleList, setDiscordRoleList] = useState([]);
  const [updater, setUpdater] = useState(false);
  const { daoDetails, daoId, isSpace, parentDaoDetails } =
    useContext(DaoContext);
  const isDiscordConnected = isSpace
    ? !!parentDaoDetails.discordGuildId
    : !!daoDetails.discordGuildId;
  useEffect(() => {
    if (!daoId) return;
    if (isSpace && !daoDetails?.parentDaoId) return;
    customAxios
      .get(`daoroles/fetch?daoId=${isSpace ? daoDetails?.parentDaoId : daoId}`)
      .then((res) => {
        setDiscordRoleList([...new Set(res.data.discordRoles)]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [updater, daoId, daoDetails?.parentDaoId, isSpace]);

  return (
    <Row
      gutter={["8"]}
      style={{
        columnGap: "1rem",
        flexFlow: "nowrap",
        padding: "2rem 12px 15px 12px",
        borderRadius: "12px",
        background: "#eeeeee",
        position: "relative",
      }}
    >
      {!isDiscordConnected ? (
        <ConnectDiscord />
      ) : (
        <>
          <div style={{ position: "absolute", top: "5px", right: "12px" }}>
            <SyncDiscordRoles
              postRoleSyncCallback={() => setUpdater((prev) => !prev)}
            />
          </div>
          <Select
            mode="multiple"
            placeholder="Select Discord Role"
            allowClear
            value={ele?.discordRole}
            style={{ minWidth: "10rem", width: "100%" }}
            onChange={(e) => {
              handleConditionDetailChange("discordRole", e, idx);
            }}
          >
            {discordRoleList?.map((ele) => {
              return <Option value={ele}>{ele}</Option>;
            })}
          </Select>
        </>
      )}
    </Row>
  );
};

export default DiscordComponent;

import { Button, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { PrivateRoute } from "../../App";
import Profile from "../../components/Profile/Profile";
import DiscordIntegrationModal from "../../components/Settings/Integrations/DiscordIntegrationModal";
import SidePannel from "../../components/SidePannel";
import DaoHomePage from "../../pages/DaoHomePage";
import { useContext } from "react";
import {
  MD_SCREEN_BREAKPOINT,
  ROUTES,
  UNAUTH_JOIN_COMMUNITY_DETAILS,
} from "../../constants";
import Onboarding from "../../components/Onboarding";
import { UserContext } from "./UserContext";
import DaoDiscovery from "../../pages/DaoDiscovery";
import mixpanel from "mixpanel-browser";
import { Crisp } from "crisp-sdk-web";
import { getCurrentPath, postMixpanel } from "../../utils";
import DaoContextProvider from "./DaoContext";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import search from "./../../assets/images/sidebar/search.png";
import defaultUser from "./../../assets/images/login/defaultUser.jpeg";
import SearchModal from "../../components/Search/SearchModal";
import NotificationPanel from "../../components/InternalComponents/NotificationPanel";
import daolensHome from "../../assets/iconSvg/daolensLogoBlue.svg";
import { ReactComponent as LoginIcon } from "../../assets/images/login/login.svg";
import IconWrapper from "./../../components/IconWrapper.js";
import TwitterBountyRedirection from "../../pages/TwitterBountyRedirection";
import minimatch from "minimatch";
import { hotjar } from "react-hotjar";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";

const StyledMenuItem = styled.div`
  color: #64748b;
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;
  border-radius: 12px;
  &:hover {
    background: #f4f2f2;
    cursor: pointer;
  }
`;
function AppLayout() {
  const location = useLocation();
  const navigate = useDaoLensNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const { userDetails, showLoginModal, setMobileSidebarOpen } =
    useContext(UserContext);

  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [searchModal, setSearchModal] = useState(false);
  const [showNotificationPanel, setShowNotificationPanel] = useState(false);
  Crisp.chat.onChatOpened(() => {
    Crisp?.session?.setData({
      source_page: getCurrentPath(),
    });
  });

  useEffect(() => {
    if (userDetails?.id && userDetails?.userName) {
      // window?.hj("identify");
      mixpanel.identify(userDetails.id);

      if (hotjar.initialized()) {
        hotjar.identify(userDetails?.id, {
          userName: userDetails?.userName,
          profileName: userDetails?.profileName,
          email: userDetails?.emailId,
        });
      }
      mixpanel.people.set({
        id: userDetails.id,
        name: userDetails.userName,
        full_name: userDetails.profileName,
        emailId: userDetails?.emailId,
      });
      mixpanel.register({
        user_id: userDetails.id,
        user_name: userDetails.userName,
        full_name: userDetails.profileName,
        emailId: userDetails?.emailId,
      });
      if (userDetails.emailId) {
        Crisp?.user?.setEmail(userDetails?.emailId);
      }
      if (userDetails?.userName)
        Crisp?.user?.setNickname(userDetails?.userName);
      Crisp?.session?.setData({
        user_id: userDetails.id,
        full_name: userDetails.profileName
          ? userDetails.profileName
          : "test name",
        user_name: userDetails.userName,
      });
      Crisp.setTokenId(userDetails.id);
    }
  }, [userDetails?.id]);

  return (
    <div>
      {/* {location.pathname !== ROUTES.appOnboarding &&
        !(isMobile && location.pathname === ROUTES.appDiscovery) && (
        )} */}
      {location.pathname !== ROUTES.appOnboarding &&
        !(isMobile && location.pathname === ROUTES.appProfile) &&
        !(isMobile && minimatch(location.pathname, "/app/profile/**")) && (
          <SidePannel
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        )}
      {isMobile && !location.pathname.includes("onboarding") && (
        <>
          <SearchModal
            isModalVisible={searchModal}
            closeModal={() => {
              setSearchModal(false);
            }}
          />
          {showNotificationPanel && (
            <NotificationPanel closePanel={setShowNotificationPanel} />
          )}
          <div
            className="sidebarActions_responsive"
            style={{
              display: "flex",
              rowGap: "1rem",
              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
              height: "72px",
              position: "fixed",
              width: "100vw",
              bottom: "0",
              backgroundColor: "#ffffff",
              borderTop: "1px solid #F4F2F2",
              zIndex: "20",
            }}
          >
            <Tooltip placement="right" title={"Discovery"}>
              <Button
                type="secondary"
                className="global-search"
                size="large"
                style={{
                  borderRadius: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  border: "none",
                }}
                icon={
                  <img
                    src={daolensHome}
                    style={{ width: "18px", cursor: "pointer" }}
                  />
                }
                onClick={() => {
                  navigate(`/app/discovery`);
                  setMobileSidebarOpen(false);
                }}
              ></Button>
            </Tooltip>
            {userDetails ? (
              <Tooltip placement="right" title={"Search"}>
                <Button
                  type="secondary"
                  className="global-search"
                  size="large"
                  style={{
                    borderRadius: "12px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                  icon={
                    <img
                      src={search}
                      style={{ width: "18px", cursor: "pointer" }}
                    />
                  }
                  onClick={() => {
                    setSearchModal(true);
                  }}
                ></Button>
              </Tooltip>
            ) : null}
            {userDetails ? (
              <Tooltip placement="right" title={"Notifications"}>
                <Button
                  type="secondary"
                  size="large"
                  className="notification"
                  style={{ borderRadius: "12px" }}
                  icon={
                    <i
                      class="fa-solid fa-bell "
                      style={{ position: "relative", color: "#64748B" }}
                    >
                      {unreadNotificationCount !== 0 && (
                        <span
                          style={{
                            position: "absolute",
                            display: "inline-flex",

                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#f3f3f3",
                            color: "#333",
                            height: "20px",
                            width: "20px",
                            borderRadius: "1rem",
                            fontSize: "10px",
                            fontWeight: "600",
                            top: "-15px",
                            right: "-18px",
                          }}
                        >
                          {unreadNotificationCount}
                        </span>
                      )}
                    </i>
                  }
                  onClick={() => {
                    //  ! Open notification sidebar
                    setShowNotificationPanel(!showNotificationPanel);
                  }}
                ></Button>
              </Tooltip>
            ) : null}
            {userDetails ? (
              <Tooltip
                placement="right"
                color="white"
                className="user-profile"
                title={
                  <>
                    <StyledMenuItem
                      onClick={() => {
                        navigate("/app/profile");
                      }}
                      style={{ padding: "10px 24px 4px 10px" }}
                    >
                      <svg
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.99984 5.19981C6.63636 5.19981 7.24681 4.94695 7.6969 4.49687C8.14699 4.04678 8.39984 3.43633 8.39984 2.79981C8.39984 2.16329 8.14699 1.55284 7.6969 1.10275C7.24681 0.652667 6.63636 0.399811 5.99984 0.399811C5.36332 0.399811 4.75287 0.652667 4.30279 1.10275C3.8527 1.55284 3.59984 2.16329 3.59984 2.79981C3.59984 3.43633 3.8527 4.04678 4.30279 4.49687C4.75287 4.94695 5.36332 5.19981 5.99984 5.19981V5.19981ZM0.399841 12.3998C0.399841 11.6644 0.54469 10.9362 0.826116 10.2568C1.10754 9.57736 1.52004 8.96002 2.04004 8.44001C2.56005 7.92001 3.17739 7.50751 3.85681 7.22609C4.53624 6.94466 5.26444 6.79981 5.99984 6.79981C6.73524 6.79981 7.46345 6.94466 8.14287 7.22609C8.82229 7.50751 9.43963 7.92001 9.95964 8.44001C10.4796 8.96002 10.8921 9.57736 11.1736 10.2568C11.455 10.9362 11.5998 11.6644 11.5998 12.3998H0.399841Z"
                          fill="#64748B"
                        />
                      </svg>
                      My Profile
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={() => {
                        postMixpanel("track", "logged_out");
                        localStorage.removeItem("access_token");
                        localStorage.removeItem(UNAUTH_JOIN_COMMUNITY_DETAILS);
                        mixpanel.reset();
                        Crisp?.session?.reset();
                        // if (isMobile) Crisp.chat.hide();
                        navigate("/app/discovery");
                        window.location.reload();
                      }}
                      style={{ padding: "4px 24px 10px 10px" }}
                    >
                      <svg
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.3333 8.66681L13 6.00014M13 6.00014L10.3333 3.33348M13 6.00014H3.66667M7.66667 8.66681V9.33348C7.66667 9.86391 7.45595 10.3726 7.08088 10.7477C6.70581 11.1228 6.1971 11.3335 5.66667 11.3335H3C2.46957 11.3335 1.96086 11.1228 1.58579 10.7477C1.21071 10.3726 1 9.86391 1 9.33348V2.66681C1 2.13638 1.21071 1.62767 1.58579 1.2526C1.96086 0.877523 2.46957 0.666809 3 0.666809H5.66667C6.1971 0.666809 6.70581 0.877523 7.08088 1.2526C7.45595 1.62767 7.66667 2.13638 7.66667 2.66681V3.33348"
                          stroke="#F05252"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Logout
                    </StyledMenuItem>
                  </>
                }
                overlayInnerStyle={{ padding: "0px", borderRadius: "12px" }}
              >
                <Button
                  style={{ height: "auto" }}
                  icon={
                    <img
                      src={
                        userDetails?.profilePicture
                          ? userDetails?.profilePicture
                          : defaultUser
                      }
                      className="profile-img"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        aspectRatio: "1",
                        borderRadius: "50%",
                      }}
                    />
                  }
                  type="text"
                ></Button>
              </Tooltip>
            ) : null}
            {!userDetails ? (
              <Tooltip placement="right" title={"Login"}>
                <Button
                  type="secondary"
                  size="large"
                  style={{
                    borderRadius: "12px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    border: "none",
                    backgroundColor: "#3b82f6",
                    padding: "0 8px",
                  }}
                  // icon={
                  //   <IconWrapper width="24px">
                  //     <svg
                  //       xmlns="http://www.w3.org/2000/svg"
                  //       fill="none"
                  //       viewBox="0 0 24 24"
                  //       strokeWidth={1.5}
                  //       stroke="#ffffff"
                  //       className="w-6 h-6"
                  //     >
                  //       <path
                  //         strokeLinecap="round"
                  //         strokeLinejoin="round"
                  //         d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                  //       />
                  //     </svg>
                  //   </IconWrapper>
                  // }
                  onClick={() => {
                    showLoginModal(false);
                    setMobileSidebarOpen(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#ffffff"
                    className="w-6 h-6"
                    style={{ height: "24px", width: "24px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                    />
                  </svg>
                </Button>
              </Tooltip>
            ) : null}{" "}
          </div>
        </>
      )}
      <Routes>
        <Route
          path="twitter-bounty-redirect"
          element={<TwitterBountyRedirection />}
        />
        <Route
          path="discovery"
          element={<DaoDiscovery setCurrentStep={setCurrentStep} />}
        />
        <Route path="onboarding" element={<Onboarding />} />
        <Route
          path="home"
          element={
            <div
              style={{
                background: "#F6F5FF",
                width: "100%",
                height: "100vh",
              }}
            >
              <Row
                justify="center"
                gutter={[16, 16]}
                style={{ background: "#F6F5FF" }}
              >
                <h1>Homepage</h1>
              </Row>
            </div>
          }
        />
        <Route
          path="profile"
          element={
            <div
              style={{
                background: "#F6F5FF",
                width: "100%",
                height: "100vh",
              }}
            >
              <Row
                justify="center"
                gutter={!isMobile && [16, 16]}
                style={{ background: "#F6F5FF", width: "100%" }}
              >
                <Profile />
              </Row>
            </div>
          }
        />
        <Route
          path="profile/:userId"
          element={
            <div
              style={{
                background: "#F6F5FF",
                width: "100%",
                height: "100vh",
              }}
            >
              <Row
                justify="center"
                gutter={!isMobile && [16, 16]}
                style={{ background: "#F6F5FF" }}
              >
                <Profile />
              </Row>
            </div>
          }
        />
        <Route
          path="redirect/discordIntgeration"
          element={<DiscordIntegrationModal />}
        />
        <Route
          path={`dao/:daoId/*`}
          element={
            <DaoContextProvider>
              <DaoHomePage deets={1} />
            </DaoContextProvider>
          }
        />
        <Route
          path={`dao/:daoName/:daoId/*`}
          element={
            <DaoContextProvider>
              <DaoHomePage />
            </DaoContextProvider>
          }
        />

        <Route
          path="*"
          element={
            <PrivateRoute>
              {window.location.host === "uniflux.daolens.com" ? (
                <Navigate to="/app/dao/uniflux/172" />
              ) : (
                <Navigate to="/app/discovery" />
              )}
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default AppLayout;

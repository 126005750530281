import { toast } from "react-toastify";
import { Card, InputField } from "./Common";

const Invite = ({ state, setState }) => {
  return (
    <div
      style={{
        padding: "20px",
        font: "normal 500 12px/16px Inter",
        color: "#64748B",
      }}
    >
      <Card>
        <InputField
          label="Number of invites"
          placeholder="Number of invites"
          type="number"
          description="Invited user needs to complete 1 quest for invite to count"
          value={state.invite}
          onChange={(e) => {
            setState((prev) => ({ ...prev, invite: e.target.value }));
          }}
        />
      </Card>
    </div>
  );
};

export default Invite;

export const inviteValidation = (state, res) => {
  if (!state.invite) {
    toast.error("Please provide number of invites");
    throw new Error();
  } else {
    res.invite = Number(state.invite);
  }
};

export const inviteInitialState = (bountyDetails) => {
  return { invite: bountyDetails?.link };
};

import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Input,
  Card,
  Typography,
  Avatar,
  Button,
  Col,
  Spin,
  Skeleton,
  Tooltip,
  Row,
} from "antd";
import PostCard from "./Common/PostCard";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import PropTypes from "prop-types";
import "./ComponentStyles/BountyFeed.scss";
import DotIcon from "../images/dot.svg";

import ViewBountyModal from "./ViewBountyModal";
import BountySubmit from "./BountySubmit";
import customAxios from "../api";
import BountyFilters from "./BountyFilters";
import SortFilter from "./SortFilter";
import no_bounty from "../assets/iconSvg/no_bounties.svg";
import { useInterval } from "../hooks/useInterval";
import { MD_SCREEN_BREAKPOINT, POLLING_REFRESH_INTERVAL } from "../constants";
import useExitPrompt from "../hooks/useExitPrompt";
import OnboardingModal from "./Onboarding/OnboardingModal";
import { UserContext } from "../layouts/app/UserContext";
import { postMixpanel, updateWalkmeStatus } from "../utils";
import CreateBountyModal from "./BountyComponents/CreateBounty/CreateBountyModal";
import { DaoContext, useDaoContext } from "../layouts/app/DaoContext";
import MobilePageTopNavbar from "./Common/MobilePageTopNavbar";
import useWindowSize from "../hooks/useWindowSize";
export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: ${(props) => (props.padding ? props.padding : "16px 20px")};
    border-radius: 16px;
    transition: 0.2s;
    height: ${(props) => props.height};
  }

  .ant-card-body:hover {
    box-shadow: 0px 10px 15px -4px rgba(0, 0, 0, 0.06),
      0px 4px 6px -4px rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`;

export const StyledProfileDetails = styled.div`
  display: flex;
  align-items: center;
  color: #64748b;
  &:hover {
    color: #3b82f6 !important;
  }
`;

export const StyledLabel = styled.div`
  padding: 4px 16px;
  border-radius: 50px;
  border: 1px solid;
  border-color: #e2e8f0;
`;
const steps = [
  {
    target: ".filter-menu",
    disableBeacon: true,
    disableScrolling: true,
    title: "Discover Bounties",
    content:
      "Use the board to find existing bounties. You can search, filter and sort bounties as per your requirements. ",
  },
  {
    target: ".create-bounty-button",
    title: "Create New Bounty",

    content: "Click on the button to start creating a new bounty.",
    disableScrolling: true,
  },
  {
    target: ".bounty-title",
    title: "Setup Bounty",
    disableScrolling: true,
    disableBeacon: true,

    content:
      "Provide a title for your bounty and define who can attempt it and the compensation they would receive.",
  },
  {
    target: ".bounty-description",
    title: "Define bounty in detail",
    disableScrolling: true,

    content:
      "Define your bounty in detail and add checklist for it so users can gain clarity about checkpoints.",
  },
  {
    target: ".footer-items",
    title: "Add Submission details & Reviewer",
    disableScrolling: true,

    content:
      "Add a due date to the bounty and assign someone to review the submission to ascertain winners.",
  },
  {
    target: ".submit-bounty-button",
    title: "Create and view bounty details",
    disableScrolling: true,

    content:
      "Click on create button to publish the bounty for public and make it live.",
  },
];
const { Title, Text } = Typography;

const StyledInput = styled(Input)`
  .ant-input {
    font-weight: 500;
  }
`;

const initialSubFilterState = {
  skills: {},
  amount: {},
  dueDate: {},
  creator: {},
  reviewer: {},
  assignees: {},
};

const filterOptions = [
  { value: "skills", displayValue: "Skills" },
  { value: "amount", displayValue: "Amount" },
  { value: "dueDate", displayValue: "Due date" },
  { value: "creator", displayValue: "Creator" },
  { value: "reviewer", displayValue: "Reviewer" },
  { value: "assignees", displayValue: "Assignees" },
];

const onlyTruthy = (object) => {
  for (var key in object) {
    if (!object[key]) {
      delete object[key];
    }
  }
  return object;
};

const BountyFeed = () => {
  const { walkMeStatus, reload } = useContext(UserContext);
  // ! Local states
  const [showEndingModal, setShowEndingModal] = useState(false);
  const [startTour, setStartTour] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [bountyList, setBountyList] = useState([]);
  const [updater, setUpdater] = useState(false);
  const [isBountyDataFetched, setIsBountyDataFetched] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [selectedTab, setSelectedTab] = useState("Available");
  const [numberOfFilters, setNumberOfFilters] = useState(0);
  const [query, setQuery] = useState("");
  const [selectedSortFilter, setSelectedSortFilter] = React.useState({
    value: "creationDate",
    displayValue: "Creation date",
  });
  const [sortOrder, setSortOrder] = React.useState(true);
  const [selectedSubFilter, setSelectedSubFilter] = React.useState(
    initialSubFilterState
  );
  const { windowSize, isMobile } = useWindowSize();
  const { daoId, daoName, isSpace } = useDaoContext();
  const [applyFilter, setApplyFilter] = useState(false);
  const [onboardingModalStep, setOnboardingmodalStep] =
    useState("bounty-start");
  const [bountyDetails, setBountyDetails] = useState(null);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [bountyModalId, setBountyModalId] = useState("");
  const [canCreateBounty, setCanCreateBounty] = useState(false);
  const [filteredBountyList, setFilteredBountyList] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const { isAdmin } = useContext(DaoContext);
  const [viewBountyModalVisibility, setViewBountyModalVisibility] =
    useState(false);
  const [submitBountyModalVisible, setSubmitMountyModalVisible] =
    useState(false);
  useEffect(() => {
    if (query)
      setFilteredBountyList(
        bountyList.filter((ele) =>
          ele.bountyTitle.toLowerCase().includes(query.toLowerCase())
        )
      );
    else setFilteredBountyList(bountyList.slice());
  }, [bountyList, query]);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  useEffect(() => {
    customAxios
      .get(`post/permission?daoId=${daoId}`)
      .then((res) => {
        setCanEdit(res.data.postPermission.includes("MANAGE"));

        if (
          res.data.postPermission.includes("MANAGE") &&
          walkMeStatus &&
          walkMeStatus?.bountyCreate === false
        ) {
          setShowOnboardingModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId, walkMeStatus]);

  useEffect(() => {
    if (createModalVisible) setShowExitPrompt(true);
    else setShowExitPrompt(false);
  }, [createModalVisible]);
  useEffect(() => {
    if (window.location.search.includes("c=t")) setCreateModalVisible(true);
  }, []);

  const fetchBountyDetails = () => {
    (async () => {
      const {
        data: { daoUser },
      } = await customAxios.get(`/users/daousers?daoId=${daoId}`);
      const {
        data: { skills },
      } = await customAxios.get(`/bounty/skills?daoId=${daoId}`);
      const {
        data: { reviewers },
      } = await customAxios.get(`/bounty/reviewers?daoId=${daoId}`);
      setFilterData({ daoUser, skills, reviewers });
    })();
    const parsedFilterData = filterOptions.reduce((pv, cv) => {
      const data = onlyTruthy(selectedSubFilter[cv.value]);
      const keys = Object.keys(data);
      return keys.length === 0 ? { ...pv } : { ...pv, [cv.value]: keys };
    }, {});
    if (parsedFilterData.amount) {
      parsedFilterData.amount = parsedFilterData.amount[0];
    }
    if (parsedFilterData.dueDate) {
      parsedFilterData.dueDate = parsedFilterData.dueDate[0];
    }
    customAxios
      .post(`bounty/fetch/${selectedTab.toLowerCase()}`, {
        daoId: daoId,
        sortBy: selectedSortFilter.value,
        ...parsedFilterData,
        sortOrder: !sortOrder ? "ascending" : "desending",
      })
      .then((res) => {
        setBountyList(res.data.bountyList);
        setIsBountyDataFetched(true);
      })
      .catch((err) => {
        if (window.location.host.includes("localhost")) console.log("error");

        console.log("err", err);
        setIsBountyDataFetched(true);
      });
  };
  // ! Effects

  useEffect(() => {
    customAxios
      .post(`bounty/cancreate`, { daoId: daoId })
      .then((res) => {
        setCanCreateBounty(res.data.canView ? true : false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]);
  useEffect(() => {
    fetchBountyDetails();
  }, [
    daoId,
    updater,
    selectedTab,
    sortOrder,
    selectedSubFilter,
    selectedSortFilter.value,
  ]);
  useInterval(fetchBountyDetails, POLLING_REFRESH_INTERVAL);

  // useEffect(() => {

  //   (async () => {
  //     const { data } = await customAxios.post(
  //       `bounty/fetch/${selectedTab.toLowerCase()}`,
  //       {
  //         daoId: daoId,
  //         ...parsedFilterData,
  //         sortBy: selectedSortFilter.value,
  //         sortOrder: !sortOrder ? "ascending" : "desending",
  //       }
  //     );
  //     setBountyList(data.bountyList);
  //   })();
  // }, [selectedSubFilter, selectedTab, selectedSortFilter.value, sortOrder]);
  // ! Local handlers
  const createNewButtonHandler = () => {
    postMixpanel("track", "clicked_create_new_bounty_button");

    // setViewBountyModalVisibility(true);
    setCreateModalVisible(true);
  };
  return (
    <div
      style={{
        paddingLeft: isMobile ? "15px" : "0",
      }}
    >
      {(windowSize.width < MD_SCREEN_BREAKPOINT || isSpace) && (
        <MobilePageTopNavbar />
      )}
      {createModalVisible ? (
        <CreateBountyModal
          onSubmit={() => {
            setUpdater((prev) => !prev);
            setQuery("");
          }}
          isModalVisible={createModalVisible}
          handleCancel={() => setCreateModalVisible(false)}
          canEdit={canEdit}
        />
      ) : null}
      {showOnboardingModal ? (
        <OnboardingModal
          step={"bounty-start"}
          showModal={showOnboardingModal}
          onClick={() => {
            updateWalkmeStatus({ bountyCreate: true }, reload);
            postMixpanel("track", "started_walk_me_tour_for_create_bounty");
            setShowOnboardingModal(false);
            setStartTour(true);
          }}
          onClose={() => {
            setShowOnboardingModal(false);
          }}
        />
      ) : null}
      {showEndingModal ? (
        <OnboardingModal
          step={"bounty-end"}
          showModal={showEndingModal}
          onClick={() => {
            postMixpanel("track", "finished_walk_me_tour_for_create_bounty");

            setShowEndingModal(false);
          }}
          onClose={() => {
            setShowEndingModal(false);
          }}
        />
      ) : null}

      {/* {startTour ? (
        <WalkmeTour
          run={startTour}
          steps={steps}
          otherProps={{
            disableScrollParentFix: true,
            disableScrolling: true,
            continuous: true,
            disableBeacon: true,
            callback: (step) => {
              console.log(step);
              if (
                step.step.target === ".create-bounty-button" &&
                step.action === "update"
              ) {
                setTimeout(() => {
                  setCreateModalVisible(true);
                }, 1000);
              }
              if (step.status === "finished") {
                setShowEndingModal(true);
                setCreateModalVisible(false);
              }
            },
          }}
        />
      ) : null} */}
      {viewBountyModalVisibility ? (
        <ViewBountyModal
          isModalVisible={viewBountyModalVisibility}
          handleCancel={() => setViewBountyModalVisibility(false)}
          bountyDetails={bountyDetails}
          bountyId={parseInt(bountyModalId)}
          onSubmit={() => {
            setViewBountyModalVisibility(false);
            setSubmitMountyModalVisible(true);
          }}
        />
      ) : null}
      {submitBountyModalVisible ? (
        <BountySubmit
          visible={submitBountyModalVisible}
          handleCancel={() => setSubmitMountyModalVisible(false)}
        />
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
          marginTop: isMobile ? "1rem" : "0px",
        }}
      >
        <StyledInput
          placeholder="Search Bounties"
          className="search-bounties"
          value={query}
          allowClear
          onChange={(e) => setQuery(e.target.value)}
          prefix={
            <SearchOutlined
              style={{ color: "#94A3B8" }}
              className="site-form-item-icon"
            />
          }
          style={{
            padding: "8px 20px",
            borderRadius: "12px",
            borderColor: "#E2E8F0",
          }}
        />

        {canCreateBounty || isAdmin ? (
          <Button
            type="primary"
            className="create-bounty-button"
            style={{
              padding: "16px 16px",
              borderRadius: "12px",
              background: "#3B82F6",
              fontWeight: 600,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "12px",
            }}
            onClick={createNewButtonHandler}
          >
            Create New
          </Button>
        ) : null}
      </div>

      <div
        className="bounties-state-filter__container filter-menu "
        style={{
          marginBottom: "16px",
          display: "flex",
          flexDirection: isMobile ? "column-reverse" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "initial" : "center",
        }}
      >
        <div className="bounties-state-filter">
          <span
            onClick={() => {
              setIsBountyDataFetched(false);
              setBountyList([]);
              setSelectedTab("Available");
            }}
            className={
              selectedTab === "Available"
                ? "bounties-state-filter__single-filter selected"
                : "bounties-state-filter__single-filter"
            }
          >
            🏆 Available
          </span>
          <span
            onClick={() => {
              setIsBountyDataFetched(false);
              setBountyList([]);
              setSelectedTab("Completed");
            }}
            className={
              selectedTab === "Completed"
                ? "bounties-state-filter__single-filter selected"
                : "bounties-state-filter__single-filter"
            }
          >
            ✅ Completed
          </span>
          <span
            onClick={() => {
              setIsBountyDataFetched(false);
              setBountyList([]);
              setSelectedTab("Missed");
            }}
            className={
              selectedTab === "Missed"
                ? "bounties-state-filter__single-filter selected"
                : "bounties-state-filter__single-filter"
            }
          >
            🚫 Missed
          </span>
        </div>
        <div style={{ marginBottom: "4px" }}>
          <BountyFilters
            filterOptions={filterOptions}
            numberOfFilters={numberOfFilters}
            setNumberOfFilters={setNumberOfFilters}
            initialSubFilterState={initialSubFilterState}
            filterData={filterData}
            selectedSubFilter={selectedSubFilter}
            setSelectedSubFilter={setSelectedSubFilter}
            setApplyFilter={setApplyFilter}
          />
          <SortFilter
            selectedSortFilter={selectedSortFilter}
            setSelectedSortFilter={setSelectedSortFilter}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setApplyFilter={setApplyFilter}
          />
        </div>
      </div>
      {isBountyDataFetched === true && !(filteredBountyList.length > 0) ? (
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
            alignItems: "center",
            background: "#FFFFFF",
            /* Shadow / Low */

            boxShadow: "0px 4px 24px rgba(180, 195, 205, 0.1)",
            borderRadius: "12px",
            padding: "2.5rem",
          }}
        >
          <img
            src={no_bounty}
            alt="emptyDao"
            style={{ objectFit: "contain", width: "200px" }}
          />
          <Col>
            <h1
              level={4}
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              No Bounties{" "}
              {query
                ? "matched your search"
                : numberOfFilters
                  ? "available for applied filters"
                  : "available yet"}
            </h1>
            <h1
              level={5}
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              {query || numberOfFilters ? (
                <span>
                  Please{" "}
                  {query ? (
                    "clear the search "
                  ) : (
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#5E54ED",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        setSelectedSubFilter(initialSubFilterState);
                      }}
                    >
                      Remove all filters{" "}
                    </span>
                  )}
                  and try again
                </span>
              ) : (
                " Be sure to check here again in a while"
              )}{" "}
            </h1>
          </Col>
        </div>
      ) : null}

      {isBountyDataFetched === false && (
        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            // height: "500px",
          }}
        >
          {/* <Spin indicator={antIcon} /> */}
          <Skeleton active />
        </div>
      )}

      {filteredBountyList?.length > 0 &&
        filteredBountyList.map((bountyPost) => (
          <PostCard
            bountyPost={bountyPost}
            daoId={daoId}
            daoName={daoName}
            selectedTab={selectedTab}
            canCreateBounty={canCreateBounty}
            isAdmin={isAdmin}
          />
        ))}
    </div>
  );
};

BountyFeed.propTypes = {
  bountyList: PropTypes.array,
  bountyDetails: PropTypes.array,
  fetchBountyDetails: PropTypes.func,
};

export default BountyFeed;

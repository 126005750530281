import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import customAxios from "../../../api";
import {
  PROJECT_STATUSES,
  PROJECT_TASK_STATUSES,
  PROJECT_TABS,
} from "../../../constants";
import TaskCard from "./TaskCard";
import EmptyStateImage from "../../../images/projects/project-empty-state.png";
import { Button, Skeleton } from "antd";
import { toast } from "react-toastify";
import { DaoContext } from "../../../layouts/app/DaoContext";

const TaskFeed = ({
  setCreateModalVisible,
  rerender,
  searchTerm,
  feedStatusUpdateOnCreate,
}) => {
  const { projectId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [activeStatus, setActiveStatus] = useState(PROJECT_STATUSES.todo);
  const [loading, setLoading] = useState(true);
  const { isAdmin } = useContext(DaoContext);
  const fetchTasks = async (currentActiveStatus) => {
    setLoading(true);
    try {
      const response = await customAxios(
        `/tasks/fetch?projectId=${projectId}&type=${currentActiveStatus}`
      );
      return { data: response.data, currentActiveStatus };
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch tasks.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks(activeStatus).then(({ data, currentActiveStatus }) => {
      setActiveStatus((prev) => {
        if (prev === currentActiveStatus) {
          setTasks(data.res);
          setLoading(false);
        }
        return prev;
      });
    });
  }, [activeStatus, rerender]);

  const updateStatusByTaskId = async (taskId, status) => {
    const index = tasks.findIndex((task) => task.id === taskId);
    const newTasks = [...tasks];
    const prevTasks = [...tasks];
    newTasks[index].status = status;
    setTasks(newTasks);

    try {
      const formData = new FormData();
      formData.append("status", status);
      formData.append("taskId", taskId);
      formData.append("projectId", projectId);
      await customAxios.post("/tasks/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Status updated");
    } catch (error) {
      setTasks(prevTasks);
      console.error(error);
    }
  };

  const filteredTasks = tasks?.filter((task) =>
    task.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    setActiveStatus(feedStatusUpdateOnCreate);
  }, [feedStatusUpdateOnCreate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "10px 0",
        gap: "13px",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #D9D9D9",
          display: "flex",
          justifyContent: "space-between",
          justifyItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          {PROJECT_TASK_STATUSES.map((status) => (
            <button
              key={status}
              onClick={() => setActiveStatus(status)}
              style={{
                display: "flex",
                borderTop: "2px solid #3F83F800",
                borderLeft: "2px solid #3F83F800",
                borderRight: "2px solid #3F83F800",
                background: "#ffffff00",
                cursor: "pointer",
                borderBottom:
                  activeStatus === status
                    ? "2px solid #3F83F8"
                    : "2px solid #3F83F800",
                fontSize: "14px",
                justifyItems: "center",
                justifyContent: "center",
                gap: "10px",
                color: activeStatus === status ? "#3F83F8" : "#64748B",
                padding: "10px",
              }}
            >
              <span
                style={{
                  background: PROJECT_TABS[status].color,
                  padding: "4px",
                  borderRadius: "999px",
                  margin: "auto 0",
                }}
              />
              {PROJECT_TABS[status]?.label}
            </button>
          ))}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {tasks?.filter((task) => task.status === activeStatus).length ===
            0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  background: "#fff",
                  borderRadius: "16px",
                  padding: "32px 20px",
                  cursor: "pointer",
                  width: "100%",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={EmptyStateImage}
                  alt="empty wrting pads"
                  style={{ width: "120px", alignSelf: "center" }}
                />
                <h3 style={{ alignSelf: "center" }}>No tasks added yet</h3>
                {isAdmin && (
                  <Button
                    className="rounded-corner"
                    type="primary"
                    style={{ borderRadius: "12px", alignSelf: "center" }}
                    onClick={() => {
                      setCreateModalVisible(true);
                    }}
                  >
                    Add task
                  </Button>
                )}
              </div>
            ) : (
              <>
                {filteredTasks
                  ?.filter((task) => task.status === activeStatus)
                  .map((task) => (
                    <TaskCard
                      task={task}
                      key={task?.id}
                      updateStatus={(status) =>
                        updateStatusByTaskId(task.id, status)
                      }
                    />
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TaskFeed;

import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { Button } from "antd";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import useGating from "../../hooks/useGating";
import useWindowSize from "../../hooks/useWindowSize";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { UserContext } from "../../layouts/app/UserContext";
import { EntityKeyType } from "../../lib/constants/common";
import {
  createNewSpace,
  CreateNewSpaceDataType,
  validateCreateSpacesStep1,
} from "../../lib/utils/spaces";
import { Modal } from "../../styles/ModalStyles";
import GatingModal from "../Gating/GatingModal";
import Gating, { defaultConditionDetails } from "../Gating/GatingModal";
import CreateModalDetails from "./CreateModalDetails";
import CreateModalVisibility from "./CreateModalVisibility";

enum ActiveStep {
  details,
  visibility,
}

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const CreateSpaceModal = ({ isOpen, setIsOpen }: Props) => {
  const navigate = useNavigate();
  const { daoId, daoName } = useDaoContext();
  const { reload } = useContext(UserContext);
  const { isMobile } = useWindowSize();
  const [activeStep, setActiveStep] = useState<ActiveStep>(ActiveStep.details);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const [emojiProfilePicture, setEmojiProfilePicture] = useState<{
    emoji: string;
    background: string;
  } | null>(null);

  const [isUploadCompUpdated, setIsUploadCompUpdated] = useState(false);
  const [visibilityData, setVisibilityData] = useState<
    Record<EntityKeyType, boolean>
  >({
    discussion: false,
    courses: false,
    projects: false,
    bounty: false,
    proposals: true,
    events: true,
  });

  const { isLoading, mutate: create } = useMutation({
    mutationFn: (data: CreateNewSpaceDataType) =>
      createNewSpace(data, gatingState),
    onError: (error) => {
      console.error(error);
      toast.error("Failed to create space. Try again later.");
    },
    onSuccess: (spaceId) => {
      setIsOpen(false);
      navigate(`/app/dao/${daoName}/${daoId}/spaces/${name}/${spaceId}`);
      reload();
    },
  });
  const gatingState = useGating();

  const handleCtaClick = () => {
    if (activeStep === ActiveStep.details) {
      const areDetailsValid = validateCreateSpacesStep1({
        description,
        name,
        emojiProfilePicture: emojiProfilePicture || undefined,
        profilePicture: profilePicture || undefined,
      });
      if (areDetailsValid) setActiveStep(ActiveStep.visibility);
      return;
    }

    create({
      details: {
        description,
        name,
        emojiProfilePicture: emojiProfilePicture || undefined,
        profilePicture: profilePicture || undefined,
      },
      parentDaoId: daoId || "",
      visibilityData,
    });
  };

  const onToggleVisibility = (entity: EntityKeyType) => {
    setVisibilityData((prev) => ({ ...prev, [entity]: !prev[entity] }));
  };
  return (
    <>
      <Modal
        visible={isOpen}
        destroyOnClose
        // @ts-expect-error
        isMobile={isMobile}
        onCancel={() => setIsOpen(false)}
        centered={true}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.5",
          backdropFilter: "blur(20px)",
        }}
        width="700px"
        footer={
          <div className="flex flex-col">
            {activeStep === ActiveStep.visibility && (
              <div className="lg:hidden flex gap-3 items-center justify-center text-blue-500 bg-blue-50 w-screen -ml-4 -mt-3 text-xs py-3 mb-3">
                <InformationCircleIcon className="w-4" />
                <p className="m-0">
                  You can always change this from the settings page
                </p>
              </div>
            )}
            <div className="flex items-center justify-between">
              <GatingModal finalGatingState={gatingState} />
              <Button
                style={{
                  fontSize: "14px",
                  padding: "6px 16px",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: "8px",
                }}
                type="primary"
                color="#3B82F6"
                disabled={isLoading}
                onClick={handleCtaClick}
              >
                {activeStep === ActiveStep.details ? "Next" : "Create"}
              </Button>
            </div>
          </div>
        }
      >
        <div className="-mt-3">
          {activeStep === ActiveStep.details && (
            <CreateModalDetails
              description={description}
              emojiProfilePicture={emojiProfilePicture}
              name={name}
              profilePicture={profilePicture}
              setDescription={setDescription}
              setEmojiProfilePicture={setEmojiProfilePicture}
              setName={setName}
              setProfilePicture={setProfilePicture}
              isUploadCompUpdated={isUploadCompUpdated}
              setIsUploadCompUpdated={setIsUploadCompUpdated}
            />
          )}
          {activeStep === ActiveStep.visibility && (
            <CreateModalVisibility
              onToggleVisibility={onToggleVisibility}
              visibilityData={visibilityData}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default CreateSpaceModal;

import React from "react";
import "../../ComponentStyles/BountySubmissionsAccordionStyles.scss";
import dummyUser from "../../../assets/images/login/defaultUser.jpeg";

function SinglePanelHeader({ singleSubtask, onClick }) {
  let description =
    singleSubtask.title.length > 30
      ? singleSubtask.title.split(" ").slice(0, 4).join(" ") + " ..."
      : singleSubtask.title;
  return (
    <div className="bounty-subtask__wrapper" onClick={onClick}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p className="bounty-subtask__description">{description}</p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {singleSubtask?.assignee?.map((assignee) => (
          <div
            className="bounty-subtask__icon bounty-subtask__icon--container"
            key={assignee.id}
          >
            <img
              src={
                assignee?.profilePicture ? assignee.profilePicture : dummyUser
              }
              alt=""
              style={{ objectFit: "cover", width: "20px", height: "20px" }}
              className="bounty-subtask__icon bounty-subtask__icon--rounded"
            />
            <span className="bounty-subtask__icon--tooltip">
              {assignee.userName}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
function SubtasksAccordion({
  subTasks,
  navigate,
  daoId,
  projectId,
  taskId,
  daoName,
}) {
  return (
    <div className="bounty-submission-container">
      {subTasks.map((eachSubtask, index) => {
        return (
          <SinglePanelHeader
            key={Math.random()}
            singleSubtask={eachSubtask}
            onClick={() => {
              navigate(
                `/app/dao/${daoName}/${daoId}/project/${projectId}/task/${taskId}/sub-task/${eachSubtask.id}`
              );
            }}
          />
        );
      })}
    </div>
  );
}

export default SubtasksAccordion;

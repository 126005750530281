import { Button, Modal } from "antd";
import styled from "styled-components";
import { Footer } from "../../../styles/ModalStyles";
import { StyledModalWrapper } from "../../CreateBountyModal";
import background from "../../../assets/images/profile/cover.png";
import DiscordIcon from "../../../assets/images/profile/Subtract.svg";
import WalletIcon from "../../../assets/images/profile/Rectangle 237.svg";
import PencilIcon from "../../../assets/images/profile/pencil.svg";
import InputField, { labeStyling } from "./InputField";
import { useEffect, useState, useRef, useContext } from "react";
import { iconStyling } from "./DaoProfile";
import customAxios from "../../../api";
import { toast } from "react-toastify";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { DaoContext } from "../../../layouts/app/DaoContext";
import QuillEditor from "../../QuillEditor";
import {
  checkFileSize,
  getUsernameFromUrl,
  isValidGithubUrl,
  isValidTwitterUrl,
  getWebsiteVal,
  postMixpanel,
  getDefaultDaoImage,
} from "../../../utils";
import useWindowSize from "../../../hooks/useWindowSize";

const StyledModal = styled(Modal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-title {
    font-weight: 600;
    color: #000000;
    line-height: 24px;
  }
`;

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
    console.log(url);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

const EditProfileModal = ({ isModalVisible, handleClose, onSubmit }) => {
  const [form, setForm] = useState({});
  const { isMobile } = useWindowSize();
  const [coverUpdated, setCoverUpdated] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [coverFocus, setCoverFocus] = useState(false);
  const [profileFocus, setProfileFocus] = useState(false);
  const { reload, daoDetails, update } = useContext(DaoContext);
  const [description, setDescription] = useState("");
  const coverref = useRef(null);
  const profileref = useRef(null);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      name: daoDetails?.daoName,
      profile: daoDetails?.profilePicture,
      cover: daoDetails?.backgroundImage,
      about: daoDetails?.description,
      twitter: getUsernameFromUrl(daoDetails?.twitterHandle),
      discord: getUsernameFromUrl(daoDetails?.discordUrl),
      website: getWebsiteVal(daoDetails?.website),
      github: getUsernameFromUrl(daoDetails?.github),
    }));
  }, [update, isModalVisible]);

  const updateProfile = () => {
    const formData = new FormData();
    formData.append("daoId", daoDetails?.daoId);
    if (form.name) formData.append("daoName", form.name);
    if (form.about) formData.append("about", form.about);
    formData.append(
      "twitter",
      form.twitter ? "https://twitter.com/" + form.twitter : ""
    );
    formData.append(
      "discordUrl",
      form.discord ? "https://discord.gg/" + form.discord : ""
    );
    formData.append(
      "github",
      form.github ? "https://github.com/" + form.github : ""
    );
    formData.append("website", form.website ? "https://" + form.website : "");
    if (coverUpdated) {
      let coverFile = new File(
        [form.cover.slice(0, form.cover.size, form.cover.type)],
        form.cover.name.split(".").join(`_background.`),
        { type: form.cover.type }
      );
      formData.append("files", coverFile);
    }
    if (profileUpdated) {
      const profileImage = new File(
        [form.profile.slice(0, form.profile.size, form.profile.type)],
        form.profile.name.split(".").join(`_profile.`),
        { type: form.profile.type }
      );
      formData.append("files", profileImage);
    }
    customAxios
      .post("dao/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          postMixpanel("track", "edited_dao_profile", {
            daoId: daoDetails.daoId,
          });
          if (coverUpdated) {
            postMixpanel("track", "edited_dao_cover_image", {
              daoId: daoDetails.daoId,
            });
          }
          if (profileUpdated) {
            postMixpanel("track", "edited_dao_profile_image", {
              daoId: daoDetails.daoId,
            });
          }
          toast.success("Comunity profile updated successfully");
          reload();
          onSubmit();
          handleClose();
        } else console.log("error");
      })
      .catch((err) => console.log("err"));
    handleClose();
  };
  return (
    <StyledModalWrapper style={{ borderRadius: "12px" }}>
      <StyledModal
        width="600px"
        destroyOnClose
        title="Edit Dao Details"
        visible={isModalVisible}
        isMobile={isMobile}
        onCancel={handleClose}
        bodyStyle={{ padding: "0px" }}
        footer={[
          <Footer key="modal-footer" justifyContent="right">
            <Button
              style={{ border: "1px solid #DDDDDD" }}
              className="rounded-corner"
              type="text"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="rounded-corner"
              type="primary"
              style={{ marginLeft: "5px" }}
              onClick={() => {
                updateProfile();
              }}
            >
              Save
            </Button>
          </Footer>,
        ]}
      >
        <div
          className="relative overflow-y-scroll"
          style={{ maxHeight: "500px" }}
        >
          <img
            alt="cover"
            src={`${
              form.cover
                ? coverUpdated
                  ? URL.createObjectURL(form.cover)
                  : form.cover
                : background
            }`}
            style={{
              width: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              background: "white",
              height: "182px",
              objectFit: "cover",
            }}
            onClick={() => coverref.current.click()}
            onMouseEnter={() => {
              setCoverFocus(true);
            }}
          />
          {coverFocus && (
            <div
              style={{
                height: "112px",
                background: "black",
                opacity: 0.5,
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                display: "flex",
                cursor: "pointer",
                height: "182px",
              }}
              onClick={() => coverref.current.click()}
              onMouseLeave={() => {
                setCoverFocus(false);
              }}
            >
              {" "}
              <img src={PencilIcon} alt="edit" />
            </div>
          )}
          {form.profile ? (
            <img
              src={`${
                form.profile
                  ? profileUpdated
                    ? URL.createObjectURL(form.profile)
                    : form.profile
                  : background
              }`}
              alt="profile"
              style={{
                width: "110px",
                height: "110px",
                position: "absolute",
                left: "20px",
                top: "130px",
                borderRadius: "50%",
                border: "2px solid white",
                background: "white",
              }}
              onClick={() => profileref.current.click()}
              onMouseEnter={() => {
                setProfileFocus(true);
              }}
            />
          ) : (
            <div
              onMouseEnter={() => {
                setProfileFocus(true);
              }}
            >
              {getDefaultDaoImage(
                daoDetails?.daoName,
                {
                  alignSelf: "center",
                  width: "110px",
                  height: "110px",
                  filter:
                    "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow( 0px 4px 6px rgba(0, 0, 0, 0.05))",
                  position: "absolute",
                  left: "20px",
                  top: "130px",

                  borderRadius: "50%",
                  border: "2px solid white",
                },
                { fontSize: "28px" }
              )}
            </div>
          )}
          {profileFocus && (
            <div
              style={{
                width: "110px",
                height: "110px",
                background: "black",
                opacity: 0.5,
                position: "absolute",
                left: "20px",
                top: "130px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => profileref.current.click()}
              onMouseLeave={() => {
                setProfileFocus(false);
              }}
            >
              <img src={PencilIcon} alt="edit" />
            </div>
          )}

          <input
            ref={coverref}
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={(e) => {
              const newFile = e.target.files[0];
              checkFileSize(newFile.size);
              setForm((prev) => ({ ...prev, cover: newFile }));
              setCoverUpdated(true);
            }}
          />
          <input
            ref={profileref}
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            onChange={(e) => {
              const newFile = e.target.files[0];
              checkFileSize(newFile.size);
              setForm((prev) => ({ ...prev, profile: newFile }));
              setProfileUpdated(true);
            }}
          />
          <div style={{ marginTop: "250px" }} className="p-4">
            <InputField
              inputProps={{ maxLength: 15 }}
              value={form.name}
              onChange={(e) => {
                setForm((prev) => ({ ...prev, name: e.target.value }));
              }}
              placeholder="Enter Dao name"
              label="Name*"
              textWeight="500"
            />
            <div
              style={{
                ...labeStyling,
                marginBottom: "16px",
              }}
            >
              About*
            </div>
            <QuillEditor
              value={form.about}
              // className="bounty-description"
              onChange={(e) => setForm((prev) => ({ ...prev, about: e }))}
              height="120px"
              style={{
                padding: "0px 0px 4px 0px",
                resize: "none",
                overflowY: "auto",
                marginBottom: "16px",
              }}
              isBordered={true}
              placeholder="Add details of your bounty here"
            />
            <div style={{ ...labeStyling }}>
              Links
              <div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    columnGap: "12px",
                  }}
                  className="social-links"
                >
                  <i
                    class="fa-brands fa-twitter fa-regular"
                    style={{ ...iconStyling, background: "#629DFF" }}
                  ></i>
                  <InputField
                    value={getUsernameFromUrl(form.twitter)}
                    prefix={
                      <span
                        style={{
                          color: "#97a6ba",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        https://twitter.com/
                      </span>
                    }
                    onChange={(e) => {
                      setForm((prev) => ({
                        ...prev,
                        twitter: getUsernameFromUrl(e.target.value),
                      }));
                    }}
                    style={{ width: "520px", margin: 0, marginBottom: "4px" }}
                    textColor="#97A6BA"
                    textSize="12px"
                    placeholder={"daolens"}
                    textWeight="500"
                  />
                  <img
                    src={DiscordIcon}
                    alt="discord icon"
                    style={{ ...iconStyling }}
                  />
                  <InputField
                    value={getUsernameFromUrl(form.discord)}
                    placeholder={"daolens"}
                    prefix={
                      <span
                        style={{
                          color: "#97a6ba",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        https://discord.gg/
                      </span>
                    }
                    onChange={(e) => {
                      setForm((prev) => ({
                        ...prev,
                        discord: getUsernameFromUrl(e.target.value),
                      }));
                    }}
                    style={{ width: "520px", margin: 0, marginBottom: "4px" }}
                    textColor="#97A6BA"
                    textSize="12px"
                    textWeight="500"
                  />
                  <i
                    class="fa-brands fa-github fa-regular"
                    style={{ ...iconStyling, background: "black" }}
                  ></i>
                  <InputField
                    value={getUsernameFromUrl(form.github)}
                    placeholder={"daolens"}
                    prefix={
                      <span
                        style={{
                          color: "#97a6ba",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        https://github.com/
                      </span>
                    }
                    onChange={(e) => {
                      setForm((prev) => ({
                        ...prev,
                        github: getUsernameFromUrl(e.target.value),
                      }));
                    }}
                    style={{ width: "520px", margin: 0, marginBottom: "4px" }}
                    textColor="#97A6BA"
                    textSize="12px"
                    textWeight="500"
                  />
                  <i
                    class=" fa-globe fa-solid"
                    style={{ ...iconStyling, background: "red" }}
                  ></i>
                  <InputField
                    placeholder={"daolens"}
                    value={getWebsiteVal(form.website)}
                    prefix={
                      <span
                        style={{
                          color: "#97a6ba",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        https://
                      </span>
                    }
                    onChange={(e) => {
                      setForm((prev) => ({
                        ...prev,
                        website: getWebsiteVal(e.target.value),
                      }));
                    }}
                    style={{ width: "520px", margin: 0 }}
                    textColor="#97A6BA"
                    textSize="12px"
                    textWeight="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledModal>
    </StyledModalWrapper>
  );
};

export default EditProfileModal;

import { toast } from "react-toastify";
import customAxios from "../../api";
import { defaultConditionDetails } from "../../components/Gating/GatingModal";
import { EntityKeyType } from "../constants/common";
import { DaoType } from "../types/common";
import TokenImage from "../../assets/iconSvg/tokenCoin.png";
import DiscordImage from "../../assets/images/bountyPage/discord.svg";
import { ReactComponent as NFT } from "../../assets/images/gating/nft.svg";
import { ReactComponent as Discord } from "../../assets/images/gating/discord.svg";
import { ReactComponent as Crown } from "../../assets/images/gating/crown.svg";

import React, { useEffect, useState } from "react";
import {
  getCurrentDateFromNow,
  getTruncatedAddress,
  postMixpanel,
} from "../../utils";
import minimatch from "minimatch";
import {
  CircleStackIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  StarIcon,
  ShieldCheckIcon,
  UsersIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import { UserGatingHook } from "../../hooks/useGating";

type CreateSpaceStep1Data = {
  name: string;
  description: string;
  emojiProfilePicture?: {
    emoji: string;
    background: string;
  };
  profilePicture?: any;
};

export const validateCreateSpacesStep1 = (data: CreateSpaceStep1Data) => {
  if (!data.name) {
    toast.error("Please enter name");
    return false;
  }
  if (!data.description) {
    toast.error("Please enter description");
    return false;
  }
  return true;
};

export type CreateNewSpaceDataType = {
  details: CreateSpaceStep1Data;
  visibilityData: Record<EntityKeyType, boolean>;
  parentDaoId: string;
};

export const createNewSpace = async (
  { details, visibilityData, parentDaoId }: CreateNewSpaceDataType,
  gatingState: UserGatingHook
) => {
  const formData = new FormData();
  gatingState.createFormData(
    formData,
    "subDaoGatingCondition",
    "subDaoGatingConditionId"
  );

  if (!validateCreateSpacesStep1(details)) {
    throw new Error("Details arent valid");
  }

  if (!parentDaoId) {
    throw new Error("Parent dao ID is missing");
  }

  formData.append("name", details.name);
  formData.append("about", details.description);
  formData.append("parentDaoId", parentDaoId);

  if (details.profilePicture) {
    formData.append("files", details.profilePicture);
  }

  if (details.emojiProfilePicture) {
    formData.append("iconEmoji", details?.emojiProfilePicture?.emoji);
    formData.append("iconBackground", details?.emojiProfilePicture?.background);
  }

  const response = await customAxios.post("/dao/create", formData);

  const spaceId = response.data.daoId as string;

  if (!spaceId) throw new Error("Did not receive spaceId(daoId)");

  await customAxios.post("/settings/visibility/update", {
    ...visibilityData,
    daoId: spaceId,
  });

  postMixpanel("track", "created_space", {
    space_id: spaceId,
    space_name: details?.name,
  });

  return spaceId;
};

export const updateSpace = async (
  {
    details,
    daoId,
  }: Omit<CreateNewSpaceDataType, "visibilityData" | "parentDaoId"> & {
    daoId: string;
  },
  gatingState: UserGatingHook
) => {
  const formData = new FormData();
  if (!daoId) {
    throw new Error("DaoId is missing");
  }
  gatingState.createFormData(
    formData,
    "subDaoGatingCondition",
    "subDaoGatingConditionId"
  );

  if (!validateCreateSpacesStep1(details)) {
    throw new Error("Details arent valid");
  }

  formData.append("daoName", details.name);
  formData.append("about", details.description);
  formData.append("daoId", daoId);
  if (details.profilePicture) {
    let profileImage = new File(
      [
        details.profilePicture.slice(
          0,
          details.profilePicture.size,
          details.profilePicture.type
        ),
      ],
      details.profilePicture.name.split(".").join(`_profile.`),
      { type: details.profilePicture.type }
    );
    formData.append("files", profileImage);
  }

  formData.append("iconEmoji", details?.emojiProfilePicture?.emoji || "");
  formData.append(
    "iconBackground",
    details?.emojiProfilePicture?.background || ""
  );

  await customAxios.post("dao/edit", formData);
};

type SpacesResponseType = {
  joinedSpaces: { daoDetails: DaoType[] }[];
  otherSpaces: { daoDetails: DaoType[] }[];
};

export const fetchSpaces = async (daoId: string) => {
  const response = await customAxios.get("/dao/fetchspaces", {
    params: {
      daoId,
    },
  });

  return response.data as SpacesResponseType;
};

export const checkCanJoinSpace = async (spaceId: string) => {
  if (!spaceId) return false;
  const response = await customAxios.post("/dao/can/join/space", {
    daoId: spaceId,
  });

  return response.data.canJoin as boolean;
};
export const GatingItem = ({ icon, content }) => {
  return (
    <div className="flex gap-1.5 items-start ">
      <div>
        <div className="h-5 w-5 rounded-full bg-blue-100 flex justify-center items-center">
          {icon}
        </div>
      </div>
      <div className="whitespace-nowrap text-ellipsis overflow-hidden">
        {content}
      </div>
    </div>
  );
};

export const getContractDetails = async (
  contractAddress: string,
  network: string
) => {
  const res = await customAxios.get(
    `bountypayments/fetchcontractdetails?contractAddress=${contractAddress}&network=${network}`
  );
  return res.data;
};
const getContractUrl = {
  POLYGON: (contractaddress) =>
    `https://opensea.io/assets/matic/${contractaddress}`,
  ETHEREUM: (contractaddress) =>
    `https://opensea.io/assets/ethereum/${contractaddress}`,
  SOLANA: (contractaddress) =>
    `https://opensea.io/assets/solana/${contractaddress}`,
  ARBITRUM: (contractaddress) =>
    `https://opensea.io/assets/arbitrum-nova/${contractaddress}`,
};

const Token = ({ condition }) => {
  const [token, setToken] = useState("");
  useEffect(() => {
    getContractDetails(condition.contractAddress, condition.contractType).then(
      (data) => {
        if (data.contractMetadata.name) setToken(data.contractMetadata.name);
      }
    );
  }, [condition.contractAddress, condition.contractType]);
  return (
    <GatingItem
      icon={<CircleStackIcon className="text-blue-500 h-3 w-3 stroke-2" />}
      content={
        <>
          {condition?.amount}{" "}
          {token ? token : getTruncatedAddress(condition?.contractAddress)}
        </>
      }
    />
  );
};
export const getGatingList = (
  gatingConditions,
  bountyList,
  navigate,
  daoDetails
) => {
  const conditions = gatingConditions?.conditions;

  const gatingList: JSX.Element[] = [];
  if (!conditions) {
    gatingList.push(
      <GatingItem
        icon={
          <Crown
            style={{ width: "18px" }}
            className=" text-blue-500 h-3 w-3 stroke-2"
          />
        }
        content={<>Be an admin of the community</>}
      />
    );
  }

  conditions?.forEach((condition) => {
    if (condition.type === "TOKEN_GATING") {
      gatingList.push(<Token condition={condition} />);
    } else if (condition.type === "SPECIFIC_ADDRESS") {
      gatingList.push(
        <GatingItem
          icon={<WalletIcon className="text-blue-500 h-3 w-3 stroke-2" />}
          content={<>Selected Wallet Addresses</>}
        />
      );
    } else if (condition.type === "MEMBER_PROFILE") {
      if (condition.isPublic) {
        gatingList.push(
          <GatingItem
            icon={<GlobeAltIcon className="text-blue-500 h-3 w-3 stroke-2" />}
            content={<>Visible to everyone</>}
          />
        );
      } else if (condition.allMembers) {
        gatingList.push(
          <GatingItem
            icon={
              <ShieldCheckIcon className="text-blue-500 h-3 w-3 stroke-2" />
            }
            content={<>Be a part of the community</>}
          />
        );
      } else {
        gatingList.push(
          <GatingItem
            icon={<UsersIcon className="text-blue-500 h-3 w-3 stroke-2" />}
            content={<>Selected Members</>}
          />
        );
      }
    } else if (condition.type === "BOUNTY") {
      let bounty = bountyList?.find(
        (bounty) => bounty.id === condition?.bountyId
      );
      let isExpired = getCurrentDateFromNow(bounty?.submissionDate)
        .toLowerCase()
        .includes("expired");
      let isValid = !isExpired && !!bounty;
      gatingList.push(
        <GatingItem
          icon={
            <CurrencyDollarIcon className="text-blue-500 h-3 w-3 stroke-2" />
          }
          content={
            isValid ? (
              <>
                Bounty:{" "}
                <span
                  className="underline text-gray-800 font-medium cursor-pointer"
                  onClick={() => {
                    console.log(
                      `/app/dao/${daoDetails?.daoName}/${daoDetails?.daoId}/bounty/${bounty.id}`
                    );
                    navigate(
                      `/app/dao/${daoDetails?.daoName}/${daoDetails.daoId}/bounty/${bounty.id}`
                    );
                  }}
                >
                  {bounty?.title}
                </span>
              </>
            ) : (
              <span className="text-gray-400">Delete/Expired Bounty</span>
            )
          }
        />
      );
    } else if (condition.type === "NFT_GATING") {
      console.log(condition);
      gatingList.push(
        <GatingItem
          icon={
            <NFT
              style={{ width: "18px" }}
              className=" text-blue-500 h-3 w-3 stroke-2"
            />
          }
          content={
            <>
              {condition?.amount && <span>{condition?.amount}</span>} NFT{" "}
              <span
                className="underline text-gray-800 font-medium cursor-pointer"
                onClick={() => {
                  let url = getContractUrl[condition?.contractType]?.(
                    condition?.contractAddress
                  );
                  if (url) window.open(url);
                }}
              >
                {getTruncatedAddress(condition?.contractAddress)}
              </span>
            </>
          }
        />
      );
    } else if (condition.type === "DISCORD_ROLES") {
      gatingList.push(
        <GatingItem
          icon={<Discord className=" h-3 w-3 " />}
          content={
            <div className="flex items-start">
              Discord role:
              <div className="flex gap-1.5 flex-wrap ml-2 mt-px">
                {condition?.discordRole?.map((role, index) => (
                  <div
                    className="py-0.5 px-3 rounded-lg text-slate-500 text-xs font-medium"
                    style={{ background: "#EFEFEF" }}
                  >
                    {role.toUpperCase()}
                  </div>
                ))}
              </div>
            </div>
          }
        />
      );
    } else {
      gatingList.push(
        <GatingItem
          icon={<StarIcon className="text-blue-500 h-3 w-3 stroke-2" />}
          content={
            <>
              Level {condition?.level}{" "}
              {(() => {
                switch (condition?.levelConditionType) {
                  case "GREATER_THAN_EQUAL_TO":
                    return "or above";
                  case "GREATER_THAN":
                    return "above";
                  case "LESS_THAN":
                    return "below";
                  case "LESS_THAN_EQUAL_TO":
                    return "or below";
                  default:
                    return "";
                }
              })()}
            </>
          }
        />
      );
    }
  });

  return gatingList;
};
export const isGatingValid = (gatingConditions, bountyList) => {
  let isValid = true;
  gatingConditions.conditions?.forEach((condition) => {
    if (condition.type === "BOUNTY") {
      let bounty = bountyList?.find(
        (bounty) => bounty.id === condition?.bountyId
      );
      let isExpired = getCurrentDateFromNow(bounty?.submissionDate)
        .toLowerCase()
        .includes("expired");
      if (isValid) isValid = !isExpired && !!bounty;
    }
  });

  return isValid;
};

export const joinSpace = async (spaceId: string) => {
  await customAxios.post(`dao/join`, {
    daoId: spaceId,
  });
};

export const convertDaoToSpaceUrl = (
  url: string,
  parentDaoId: string,
  parentDaoName: string
) => {
  if (!minimatch(url, "/app/dao/**")) {
    return url;
  }
  const terms = url.split("/");
  terms.splice(3, 0, "spaces");
  terms.splice(3, 0, parentDaoId);
  terms.splice(3, 0, parentDaoName);

  const newUrl = terms?.join("/");

  return newUrl;
};

type DaoListItem = {
  daoId: string;
  daoName: string;
  parentDaoId: string | null;
  profilePicture: string;
  userRoles: string[];
};

export const processDaoList = (daoList: DaoListItem[]) => {
  const spaceList = daoList.filter((dao) => dao.parentDaoId);
  const daoListWithoutSpacesList: (DaoListItem & { spaces?: DaoListItem[] })[] =
    daoList
      .filter((dao) => !dao.parentDaoId)
      ?.map((dao) => ({ ...dao, spaces: [] }));

  spaceList.forEach((space) => {
    const parentIndex = daoListWithoutSpacesList?.findIndex(
      (dao) => dao.daoId === space.parentDaoId
    );
    if (parentIndex === -1) return;
    daoListWithoutSpacesList?.[parentIndex]?.spaces?.push(space);
  });

  return daoListWithoutSpacesList;
};

import { toast } from "react-toastify";
import { Card, InputField } from "./Common";

const VoteSnapshot = ({ state, setState }) => {
  return (
    <div
      style={{
        padding: "20px",
        font: "normal 500 12px/16px Inter",
        color: "#64748B",
      }}
    >
      <Card>
        <InputField
          label="Snapshot proposal link"
          placeholder="https://snapshot.org/#/daocity.eth/proposal/"
          value={state.link}
          onChange={(e) => {
            setState((prev) => ({ ...prev, link: e.target.value }));
          }}
        />
      </Card>
    </div>
  );
};
export default VoteSnapshot;

export const snapshotValidation = (state, res) => {
  if (!state.link) {
    toast.error("Please provide snapshot proposal link");
    throw new Error();
  } else {
    res.link = state.link;
  }
};

export const snapshotInitialState = (bountyDetails) => {
  return { link: bountyDetails?.link };
};

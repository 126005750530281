import { Skeleton } from "antd";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import {
  fetchAnnouncementForCommunity,
  fetchOldAnnouncementForCommunity,
} from "../../../../utils/Announcements";
import { respondToVisibility } from "../../../../utils/common";
import AnnouncementList from "./AnnouncementList";
import OldAnnouncementButton from "./OldAnnoucementButton";

type Props = {
  canEdit: boolean;
  setIsOnlyAboutSection: Dispatch<SetStateAction<boolean>>;
};

const Announcement = ({ canEdit, setIsOnlyAboutSection }: Props) => {
  const { daoId } = useDaoContext();
  const createAnnouncmentButtonRef = useRef<HTMLButtonElement>(null);
  const [isCreateAnnouncementButtonShown, setIsCreateAnnouncementButtonShown] =
    useState(false);

  const { data, isLoading } = useQuery(["announcements", daoId], {
    queryFn: () => fetchAnnouncementForCommunity(daoId || ""),
    onError: (error) => {
      console.error(error);
      toast.error("Something went wrong while fetching announcements.");
    },
    onSuccess: (data) => {
      if (data?.length !== 0) setIsOnlyAboutSection(false);
    },
  });

  const { data: oldAnnouncements, isLoading: isOldAnnoucementLoading } =
    useQuery(["old-announcements", daoId], {
      queryFn: () => fetchOldAnnouncementForCommunity(daoId || ""),
      onError: (error) => {
        console.error(error);
        toast.error("Something went wrong while fetching old announcements");
      },
    });

  if (!canEdit && !isLoading && data?.length === 0) return null;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "24px 20px 4px 20px",
        background: "white",
        borderRadius: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0, fontSize: "16px", fontWeight: "600" }}>
          📣 Announcement
        </h3>
        <div className="flex items-center gap-3 h-6">
          {canEdit && oldAnnouncements?.length !== 0 && (
            <OldAnnouncementButton
              data={oldAnnouncements}
              isLoading={isOldAnnoucementLoading}
              isMinimal={isCreateAnnouncementButtonShown}
            />
          )}
          {canEdit && isCreateAnnouncementButtonShown && (
            <button
              className="text-xl font-normal flex items-center justify-center cursor-pointer bg-blue-500 hover:bg-blue-600 active:bg-blue-700 border-none text-white rounded-lg p-0 m-0 w-6 h-6"
              onClick={() => createAnnouncmentButtonRef.current?.click()}
            >
              +
            </button>
          )}
        </div>
      </div>
      {isLoading && <Skeleton paragraph={{ rows: 4 }} />}
      {!isLoading && (
        <AnnouncementList
          announcements={data}
          canEdit={canEdit}
          setIsCreateButtonShown={setIsCreateAnnouncementButtonShown}
          createAnnouncmentButtonRef={createAnnouncmentButtonRef}
        />
      )}
    </div>
  );
};

export default Announcement;

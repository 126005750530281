import React from "react";
import "./InternalComponentStyles/CommentReplyEditor.scss";
import dummyAvatar from "../../assets/images/login/defaultUser.jpeg";
import { useState } from "react";
import { useEffect } from "react";
import { getProfileDetails } from "../Profile/Profile";
function PostCommentReplyEditor({
  replyEditorRef,
  commentRef,
  handleReplyLineHeights,
  replyHandler,
  addReply,
  repliesLength,
}) {
  const [currentReply, setCurrentReply] = useState("");
  // ! Effects
  useEffect(() => {
    // console.log(commentRef.current, replyEditorRef.current);
    handleReplyLineHeights([replyEditorRef.current.y - commentRef.current.y]);
  }, [repliesLength]);
  // console.log("rerender");
  const [userProfilePicture, setUserProfilePicture] = useState(dummyAvatar);

  useEffect(() => {
    getProfileDetails().then((data) => {
      if (data.profilePicture) setUserProfilePicture(data.profilePicture);
    });
  }, [])
  return (
    <div className="comment-reply-editor">
      <div className="comment-reply-editor__edit">
        <img
          src={userProfilePicture}
          alt=""
          className="comment-reply-editor__edit--icon"
          ref={replyEditorRef}
        />
        <input
          className="comment-reply-editor__edit--input"
          type="text"
          name=""
          value={currentReply}
          onChange={(ev) => {
            ev.stopPropagation();
            setCurrentReply(ev.target.value);
          }}
          placeholder="Reply to"
          id=""
        />
      </div>
      <div className="comment-reply-editor__actions">
        <span
          className="comment-reply-editor__actions--cancel"
          onClick={(e) => {
            e.stopPropagation();
            replyHandler(false);
          }}
        >
          Cancel
        </span>
        <span
          className="comment-reply-editor__actions--post"
          onClick={(e) => {
            e.stopPropagation();
            addReply(currentReply, () => {
              console.log("running callback");
              handleReplyLineHeights([
                replyEditorRef.current.y - commentRef.current.y,
              ]);
            });
            setCurrentReply("");
          }}
        >
          Post
        </span>
      </div>
    </div>
  );
}

export default PostCommentReplyEditor;

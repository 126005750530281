import { TokenResponse } from "@react-oauth/google";
import customAxios from "../../api";

export const connectGoogle = async (
  data: Omit<TokenResponse, "error" | "error_description" | "error_uri"> & {
    daoId: string;
  }
) => {
  const res = await customAxios.post("/auth/connect/google", {
    idToken: data.access_token,
    daoId: data.daoId,
  });

  return res?.data;
};

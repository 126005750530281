import { web3 } from "@project-serum/anchor";
import {
  clusterApiUrl,
  Connection,
  PublicKey,
  PublicKeyInitData,
  Transaction,
} from "@solana/web3.js";
import { SignerWalletAdapterProps } from "@solana/wallet-adapter-base";

import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAssociatedTokenAddress,
} from "@solana/spl-token";
import { toast } from "react-toastify";
declare global {
  interface Window {
    phantom: any;
  }
}
interface Wallet {
  id: string;
  address: string;
  network: "SOLANA" | "SOLANA_DEVNET";
}

export const startSolanaPayment = async (
  wallet: Wallet,
  bountyAmount: number,
  recipientAddress: string,
  tokenMintAddress: string
) => {
  if (!recipientAddress) {
    toast.error("User has not connected any solana wallets");
    throw new Error("User has not connected any solana wallets");
  }

  const connection = new Connection(
    clusterApiUrl(wallet.network === "SOLANA" ? "mainnet-beta" : "devnet")
  );

  if (!("phantom" in window)) {
    toast.error("Please Install Phontom Wallet extension");
    throw new Error("Please Install Phontom Wallet extension");
  }
  const provider = window.phantom?.solana as any;
  const resp = await provider.connect();
  let senderPublicKey = resp.publicKey;

  if (senderPublicKey.toString() !== wallet.address) {
    toast.error("Please choose correct phantom wallet account");
    throw new Error("Please choose correct phantom wallet account");
  }

  const signature = await handlePayment(
    senderPublicKey,
    recipientAddress,
    tokenMintAddress,
    connection,
    bountyAmount,
    provider.signTransaction
  );
  toast.success("Transaction Completed Successfully");
  return signature;
};
export const handlePayment = async (
  fromPublicKey: PublicKey,
  recipientAddress: PublicKeyInitData,
  tokenMintAddress: PublicKeyInitData,
  connection: Connection,
  amount: number,
  signTransaction: SignerWalletAdapterProps["signTransaction"]
) => {
  const mintPublicKey = new web3.PublicKey(tokenMintAddress);
  const toPublicKey = new web3.PublicKey(recipientAddress);

  const transactionInstructions = [];
  const associatedTokenFrom = await getAssociatedTokenAddress(
    mintPublicKey,
    fromPublicKey
  );

  const fromAccount = await connection.getAccountInfo(associatedTokenFrom);

  if (!fromAccount) {
    toast.error("Token Account Not Found");
    return;
  }

  const associatedTokenTo = await getAssociatedTokenAddress(
    mintPublicKey,
    toPublicKey
  );
  const toAccount = await connection.getAccountInfo(associatedTokenTo);
  if (!toAccount) {
    transactionInstructions.push(
      createAssociatedTokenAccountInstruction(
        fromPublicKey,
        associatedTokenTo,
        toPublicKey,
        mintPublicKey
      )
    );
  }

  transactionInstructions.push(
    createTransferInstruction(
      associatedTokenFrom,
      associatedTokenTo,
      fromPublicKey,
      amount
    )
  );

  const transaction = new Transaction().add(...transactionInstructions);
  const signature = await configureAndSendCurrentTransaction(
    transaction,
    connection,
    fromPublicKey,
    signTransaction
  );
  return signature;
};
export const configureAndSendCurrentTransaction = async (
  transaction: Transaction,
  connection: Connection,
  feePayer: PublicKey,
  signTransaction: SignerWalletAdapterProps["signTransaction"]
) => {
  const blockHash = await connection.getLatestBlockhash();
  transaction.feePayer = feePayer;
  transaction.recentBlockhash = blockHash.blockhash;
  const signed = await signTransaction(transaction);
  const signature = await connection.sendRawTransaction(signed.serialize());
  await connection.confirmTransaction({
    blockhash: blockHash.blockhash,
    lastValidBlockHeight: blockHash.lastValidBlockHeight,
    signature,
  });
  return signature;
};

import { CloseCircleFilled } from "@ant-design/icons";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button, Card, Col, Input, Space, Tooltip } from "antd";
import moment from "moment";
import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { Feild } from "../../../styles/Feild";
import { LabelIcon } from "../../../styles/LabelIcon";
import { PopupCard } from "../../../styles/PopupCard";
import { checkFileSize } from "../../../utils";
import DueDate from "../../DueDate";
import Flag from "../../Flag";
import { PaperClipIcon } from "../../Icones";
import IconWrapper from "../../IconWrapper";
import { ReactComponent as CrossIcon } from "./../../../assets/iconSvg/crossIcon.svg";
import emptyUser from "./../../../assets/iconSvg/emptyUser.svg";
import dummyUser from "./../../../assets/images/login/defaultUser.jpeg";
const SearchBar = styled(Input)`
  border-radius: 0.6rem;
  margin: 0px 2% 4%;
  width: 96%;
`;
const StyledCard = styled(Card)`
  width: 178px;
  position: absolute;
  transition: 300ms all;
  border-radius: 1rem;
  z-index: 1;
  box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
  padding: 0px;
  & .search-input {
    border-radius: 0.6rem;
  }
  & .borderless-button {
    border: none;
    padding: 0;
    background: none;
    line-height: initial;
  }
`;
const Circle = () => (
  <div
    style={{
      width: "16px",
      height: "16px",
      marginRight: "4px",
      /* Colors/bg/white */
      borderRadius: "50%",
      background: "#FFFFFF",
      /* Colors/text/disabled-cool gray-400 */

      border: "1px solid #97A6BA",
    }}
  ></div>
);
function EditableChecklistItem({
  ele,
  idx,
  checklist,
  setChecklist,
  focusIndex,
  setFocusIndex,
  uploadFiles,
  addNewChecklistItem,
  BountyFilesAttachment,
  assigneeList,
  setUpdatedFieldsNames,
}) {
  const [searchInput, setSearchInput] = useState("");
  const [filterAssigneeList, setFilterAssigneeList] = useState([]);
  const [showCard, setShowCard] = useState(false);
  const cardRef = useRef();
  useEffect(() => {
    if (!searchInput) setFilterAssigneeList(assigneeList.slice());
    else
      setFilterAssigneeList(
        assigneeList
          .slice()
          .filter((ele) =>
            ele.userName?.toLowerCase().includes(searchInput.toLowerCase())
          )
      );
  }, [searchInput, assigneeList]);
  useOnClickOutside(cardRef, () => setShowCard(false));
  const inputRef = useRef();
  console.log(ele);
  return (
    <Col
      xs={24}
      //   onMouseEnter={() => setFocusIndex(idx)}
      //   onMouseLeave={() => setFocusIndex(-1)}
      style={{
        background: "#FFFFFF",
        minHeight: "50px",
        /* Colors/divider/dark */
        // margin: "12px 16px",
        marginTop: idx === 0 ? "12px" : "0px",
        border: focusIndex === idx ? "1px solid #E2E8F0" : "0px",
        borderBottom:
          focusIndex === idx
            ? "1px solid #E2E8F0"
            : focusIndex - 1 === idx
            ? "0px"
            : "1px solid #F1F5FF",

        /* shadow-sm */
        padding: "8px 12px",

        boxShadow:
          focusIndex === idx ? "0px 1px 2px rgba(0, 0, 0, 0.05)" : "none",
        borderRadius: focusIndex === idx ? "8px" : "0px",

        position: "relative",
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          columnGap: "8px",
          display: "flex",
        }}
      >
        {focusIndex === idx && idx ? (
          <div
            onClick={() => {
              const currList = checklist.slice();
              currList.splice(idx, 1);
              setChecklist(currList.slice());
            }}
            style={{
              cursor: "pointer",
              height: "20px",
              width: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: "-10px",
              top: "-10px",
              // background: "#f4f4f4",
              background: "#f4f4f4",
              borderRadius: "50%",
              border: "1px solid #f4f4f4",
            }}
          >
            <CrossIcon color="#000" width={"8px"} />
          </div>
        ) : null}
        <Input
          placeholder="Add checklist item"
          value={ele.title}
          multiple={true}
          onFocus={() => setFocusIndex(idx)}
          onChange={(e) => {
            const currList = checklist.slice();
            currList[idx]["title"] = e.target.value;
            setChecklist(currList.slice());
            setUpdatedFieldsNames();
          }}
          autoFocus={focusIndex === idx}
          prefix={<Circle />}
          onKeyDown={(e) => {
            if (e.key === "Enter") addNewChecklistItem();
          }}
          bordered={false}
          style={{ padding: "0px" }}
        ></Input>

        <Space>
          <Button
            className="borderless-button"
            shape="circle"
            style={{
              padding: "0px",
              border: "none",
              background: "none",
            }}
            onClick={() => inputRef.current.click()}
          >
            <IconWrapper width="18px">
              <PaperClipIcon />
            </IconWrapper>
          </Button>
          <input
            ref={inputRef}
            onClick={() => {
              inputRef.current.value = null;
            }}
            style={{ display: "none" }}
            hidden
            type="file"
            name="Attachment"
            accept="image/*"
            multiple
            onChange={(e) => {
              if (e.target.files.length) {
                const newFile = e.target.files[0];
                checkFileSize(newFile.size);
                setUpdatedFieldsNames();
                const insertPublicUrl = (file) => {
                  setChecklist((prev) => {
                    const currList = prev.slice();
                    const currAttachments = currList[idx]?.["attachments"]
                      ?.length
                      ? currList[idx]?.["attachments"].slice()
                      : [];
                    currAttachments.push(file);
                    currList[idx].attachments = currAttachments.slice();
                    return currList.slice();
                  });
                };
                uploadFiles(newFile, insertPublicUrl);
              }
            }}
          />
          <Flag
            style={{
              border: "none",
              boxShadow: "none",
            }}
            defaultValue={ele?.priority}
            handleChange={(value) => {
              setUpdatedFieldsNames();
              const currList = checklist.slice();
              currList[idx]["priority"] = value;
              setChecklist(currList.slice());
            }}
          />
          <DueDate
            buttonStyle={{ border: "none", boxShadow: "none" }}
            cardProps={{ top: "38px" }}
            onChange={(date, time) => {
              const currList = checklist.slice();
              if (date) {
                setUpdatedFieldsNames();
                const dateTime = moment(
                  date.format("YYYY-MM-DD") + ` ${time ? time : "00:00 AM"}`
                ).format("YYYY-MM-DD HH:mmZ");
                currList[idx]["dueDate"] = dateTime;
              } else currList[idx]["dueDate"] = null;
              setChecklist(currList.slice());
            }}
            defaultValue={
              ele?.dueDate
                ? moment(ele?.dueDate).format("YYYY-MM-DD HH:mmZ")
                : ""
            }
          />
          <LabelIcon
            padding="0px"
            onClick={() => {
              setShowCard(true);
            }}
            style={{ background: "#fff", borderRadius: "0px" }}
            height="24px"
          >
            {ele?.assigneeId ? (
              <>
                <Tooltip title={ele?.assigneeId?.userName}>
                  <img
                    src={
                      ele.assigneeId.profilePicture
                        ? ele.assigneeId.profilePicture
                        : dummyUser
                    }
                    alt="icon"
                    style={{
                      width: "20px",
                      borderRadius: "50%",
                      height: "20px",
                      objectFit: "cover",
                    }} // style={{ borderRadius: "10rem" }}
                  />
                </Tooltip>
                <Button
                  className="borderless-button"
                  shape="circle"
                  size="small"
                  style={{ padding: "0px", border: "none", background: "none" }}
                  icon={<CloseCircleFilled />}
                  onClick={() => {
                    setUpdatedFieldsNames();

                    setChecklist((prev) => {
                      const currList = prev.slice();
                      currList[idx]["assigneeId"] = null;
                      return currList.slice();
                    });
                    setShowCard(false);
                  }}
                />
              </>
            ) : (
              <img
                src={emptyUser}
                alt="icon"
                height={"20px"}
                style={{ borderRadius: "10rem" }}
              />
            )}
          </LabelIcon>
          {showCard && (
            <StyledCard
              bodyStyle={{ padding: "10px 12px" }}
              className="tag-card"
              ref={cardRef}
              style={{ minWidth: "240px" }}
            >
              <Input
                className="search-input"
                placeholder="Search members..."
                value={searchInput}
                prefix={
                  <IconWrapper>
                    <MagnifyingGlassIcon />
                  </IconWrapper>
                }
                style={{ margin: "4px 0px" }}
                autoFocus={true}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <Space
                direction="vertical"
                style={{
                  minHeight: "250px",
                  width: "100%",
                  maxHeight: "250px",
                  overflowY: "auto",
                }}
              >
                {!filterAssigneeList.length ? (
                  <h4 style={{ textAlign: "center" }}>No users found</h4>
                ) : null}
                {filterAssigneeList.map((user, index) => (
                  <Feild
                    key={user}
                    onClick={() => {
                      setUpdatedFieldsNames();

                      setChecklist((prev) => {
                        const currList = prev.slice();
                        currList[idx]["assigneeId"] = user;
                        return currList.slice();
                      });
                      setShowCard(false);
                    }}
                  >
                    <img
                      style={{
                        width: "22px",
                        borderRadius: "50%",
                        height: "22px",
                        objectFit: "cover",
                      }}
                      src={
                        user?.profilePicture ? user?.profilePicture : dummyUser
                      }
                      alt="icon"
                    />{" "}
                    <span>
                      {user?.userName?.slice(0, 16)}
                      {user?.userName?.length > 16 ? "..." : null}
                    </span>
                  </Feild>
                ))}
              </Space>
            </StyledCard>
          )}
        </Space>
      </div>

      {ele?.attachments?.length ? (
        <Col xs={24}>
          <BountyFilesAttachment
            showHeader={false}
            files={ele.attachments?.map((attach) => {
              if (typeof attach === "string") {
                return { name: attach.split("/").pop() };
              }
              return attach;
            })}
            onDelete={(id) => {
              setUpdatedFieldsNames();
              setChecklist((prev) => {
                const currList = prev.slice();

                currList[idx]["attachments"] = currList[idx][
                  "attachments"
                ].filter((ele) => ele.publicUrl !== id);
                return currList.slice();
              });
            }}
          />
        </Col>
      ) : null}
    </Col>
  );
}

export default EditableChecklistItem;

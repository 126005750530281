import { CheckCircleIcon, ClockIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router";
import StatusPill from "./StatusPill";
import dummyUser from "../../../assets/images/login/defaultUser.jpeg";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../../Common/ProfileCard";
import { getCurrentDateFromNow } from "../../../utils";

export const TaskPillWrapper = ({ children }) => (
  <div
    style={{
      border: "1px solid #E2E8F0",
      borderRadius: "20px",
      padding: "8px 16px",
      display: "flex",
      gap: "6px",
      alignItems: "center",
    }}
  >
    {children}
  </div>
);

const TaskCard = ({ task, updateStatus }) => {
  const navigation = useDaoLensNavigate();
  const { projectId } = useParams();
  const { daoDetails, daoId, daoName } = useDaoContext();

  const dateData = {
    label: moment(task?.submissionDate)?.isSame(new Date(), "day")
      ? "Today"
      : moment(task?.submissionDate)?.isSame(moment().add(1, "day"), "day")
      ? "Tomorrow"
      : moment(task?.submissionDate)?.format("D MMM"),
    color:
      moment(task?.submissionDate)?.isSame(new Date(), "day") ||
      moment(task?.submissionDate)?.isBefore(new Date())
        ? "#F05252"
        : moment(task?.submissionDate)?.isSame(moment().add(1, "day"), "day")
        ? "#E3A008"
        : "#64748B",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        borderRadius: "16px",
        padding: "16px 20px",
        cursor: "pointer",
      }}
      onClick={() =>
        navigation(
          `/app/dao/${daoName}/${daoId}/project/${projectId}/task/${task.id}`
        )
      }
    >
      <h3 style={{ fontSize: "16px", fontWeight: "600" }}>{task?.title}</h3>
      <div style={{ display: "flex", gap: "8px" }}>
        <StatusPill
          status={task?.status}
          updateStatus={updateStatus}
          disabled={!task.canUpdateStatus}
        />
        {task?.totalTasks > 0 && (
          <TaskPillWrapper>
            <CheckCircleIcon width={16} color="#64748B" />
            <span
              style={{ fontSize: "12px", fontWeight: "500", color: "#64748B" }}
            >
              {task?.completedTasks}/{task?.totalTasks}
            </span>
          </TaskPillWrapper>
        )}
        {task?.submissionDate && (
          <TaskPillWrapper>
            <ClockIcon width={16} color={dateData.color} />
            <span
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: dateData.color,
              }}
            >
              {getCurrentDateFromNow(task?.submissionDate)}
            </span>
          </TaskPillWrapper>
        )}
        {task?.assignee?.length > 0 && (
          <TaskPillWrapper>
            <span
              style={{
                padding: 0,
                margin: 0,
                fontSize: "12px",
                fontWeight: "500",
                color: "#64748B",
              }}
            >
              Assigned to
            </span>
            {task?.assignee?.map((eachAssignee) => (
              <ProfileCardPopup userId={eachAssignee?.id}>
                <img
                  onClick={() => navigation(`/app/profile/${eachAssignee?.id}`)}
                  src={
                    eachAssignee?.profilePicture
                      ? eachAssignee?.profilePicture
                      : dummyUser
                  }
                  alt={eachAssignee?.userName}
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "999px",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                />
              </ProfileCardPopup>
            ))}
          </TaskPillWrapper>
        )}
      </div>
    </div>
  );
};

export default TaskCard;

import React, { useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Mention from "quill-mention";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/dist/quill.mention.css";
import styled from "styled-components";
import { useEffect } from "react";
import customAxios from "../api";
import { useParams } from "react-router";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import MagicUrl from "quill-magic-url";

import { useDaoContext } from "../layouts/app/DaoContext";
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData("text/plain");
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, "silent");
    this.quill.setSelection(index, length, "silent");
    this.quill.scrollIntoView();
  }
}
export var formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "image",
  "mention",
  "video",
];
const StyledQuill = styled(ReactQuill)`
  padding: 4px;
  overflow: ${(props) => (props.overflowVisible ? "visible" : "auto")};
  & .ql-container {
    border: ${(props) => (props.isBordered ? "" : "none !important")};
    border-radius: ${(props) => (props.isBordered ? "0 0 8px 8px" : "8px")};
    height: ${(props) => (props.isDynamicHeight ? "auto !important" : "")};
  }
  .ql-tooltip {
    z-index: 20;
  }
  p {
    margin: 0;
    color: #1f2937;
  }
  .ql-stroke,
  .ql-snow {
    stroke: #64748b;
    color: black;
  }
  & .ql-toolbar {
    & .ql-formats {
      margin-right: 8px;
    }
    background: white;
    width: 100%;
    border: ${(props) => (props.isBordered ? "" : "none")};
    position: ${(props) => (props.isBordered ? "" : "absolute")};
    top: 0px;
    display: ${(props) => (props.disableToolbar ? "none" : "block")};
    z-index: 1;
    // background: white;
    padding: ${(props) => (props.isBordered ? "" : "0px 4px 4px 4px")};
    padding-left: 8px;
    border-radius: ${(props) =>
      props.isBordered ? "12px 12px 0px 0px" : "8px"};
  }
  .ql-editor.ql-blank::before {
    left: 12px;
  }
  & .ql-editor {
    overflow: auto;
    margin-bottom: ${(props) => (props.isBordered ? "" : "1.5rem")};
    font-size: 14px;
    height: ${(props) =>
      props.isDynamicHeight
        ? "auto !important"
        : props.height
        ? props.height
        : "100%"};
    min-height: ${(props) =>
      props.height
        ? `${props.height} !important`
        : props.disableToolbar
        ? "50px"
        : "96px"};
    word-break: break-word;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "500px")};
    padding: 8px 15px;

    padding-top: ${(props) =>
      props.disableToolbar || props.isBordered ? "" : "28px"};
    ${(props) => {
      if (props.bordered)
        return `         
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        &:hover{
           border-color: #40a9ff;
        }
        &:focus {
             border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
        }
        `;
      else return "";
    }}
  }
  & .ql-mention-list-container {
    list-style: none;
    padding: 10px;
    border-radius: 12px;
    z-index: 20;
  }
  & .ql-mention-list {
    list-style-type: none;
    margin: 0;
    max-height: 240px;
    overflow: auto;
  }
  & .ql-mention-list-item {
    margin: 0;
    border-radius: 12px;
    padding: 0px 8px;
    background: white;
    & .selected {
      background: #d3e1eb !important;
      &:hover {
        background: #d3e1eb;
      }
    }
    &:hover {
      background: #f3f3f3;
    }
  }

  .ql-mention-list-item.selected {
    background: #d3e1eb !important;
  }

  & .ql-blank {
    &::before {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #bfbfbf;
      font-style: normal;
    }
  }

  .mention {
    background: none;
    color: #3b82f6;
    margin: 0;
    & span {
      margin: 0;
    }
  }
  .ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "Enter Url:" !important;
  }
`;
var values = [];
var addUser = (userId) => {};
const modules = {
  magicUrl: true,
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    // [{ header: [0, 1, 2] }],
    ["bold", "italic", "underline", "strike"],

    // [{ color: [] }, { background: [] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    ["image"],
    ["video"],
    ["code"],
  ],

  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö0-9]*$/,
    mentionDenotationChars: ["@", "#"],
    onSelect: function (item, insertItem) {
      addUser(item.id);
      insertItem(
        { denotationChar: item.denotationChar, id: item.id, value: item.value },
        true
      );
    },
    source: function (searchTerm, renderList) {
      if (searchTerm.length === 0) {
        renderList(values.slice(0, 5), searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (
            ~values[i].value?.toLowerCase().indexOf(searchTerm?.toLowerCase())
          )
            matches.push(values[i]);
        renderList(matches.slice(0, 5), searchTerm);
      }
    },
  },

  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
  imageCompress: {
    quality: 0.7, // default
    maxWidth: 1000, // default
    maxHeight: 1000, // default
    imageType: "image/jpeg", // default
    debug: true, // default
  },
};
Quill.register("modules/magicUrl", MagicUrl);
Quill.register("module/mention", Mention);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);
Quill.register("modules/clipboard", PlainClipboard, true);

const QuillEditor = (props) => {
  const initialTextRef = useRef(props?.value);
  const { daoDetails, daoId } = useDaoContext();

  const quillContainerRef = useRef(null);
  useOnClickOutside(quillContainerRef, props?.onBlur);
  const [mentionedUsers, setMentionedUsers] = useState(
    props.initialUserTagged || []
  );
  const [wasMentionMenuOpen, setWasMentionMenuOpen] = useState(false);
  addUser = (userId) => {
    if (!(userId in mentionedUsers)) {
      setMentionedUsers([...mentionedUsers, userId]);
      props.setMentionedUsers([...mentionedUsers, userId]);
    }
  };

  useEffect(() => {
    if (!daoId && !props.daoId) return;
    customAxios
      .get(`users/daousers?daoId=${daoId ? daoId : props.daoId}`)
      .then((res) => {
        values = res.data.daoUser.map((singleUser) => {
          return {
            id: singleUser.id,
            value: singleUser.userName,
            // link: `/app/profile/${singleUser?.id}`,
          };
        });
      })
      .catch((err) => console.error(err));
  }, [daoId, props.daoId]);
  useEffect(() => {
    if (!props.autoFocus) return;
    document.querySelector("div.ql-editor")?.focus();
  }, [props.autoFocus]);
  return (
    <div
      className="quillBound"
      style={{ position: "relative" }}
      ref={quillContainerRef}
    >
      <StyledQuill
        disableToolbar={props.disableToolbar}
        isBordered={props.isBordered}
        maxHeight={props.maxHeight}
        overflowVisible={props.overflowVisible}
        style={{
          border: props.bordered ? "1px solid #d9d9d9" : "",
          borderRadius: "12px",
          ...props.style,
        }}
        ref={(ref) => {
          const quill = ref?.getEditor();
          if (initialTextRef.current) {
            quill?.setSelection(quill?.getLength(), 0);
            initialTextRef.current = "";
          }
        }}
        bounds=".quillBound"
        theme="snow"
        height={props?.height}
        isDynamicHeight={props?.isDynamicHeight}
        formats={formats}
        className={props.className}
        placeholder={props.placeholder}
        modules={modules}
        value={props.value}
        onChange={(e) => {
          if (props.setUpdatedFieldsNames)
            props.setUpdatedFieldsNames((prev) =>
              prev.includes("description") ? prev : [...prev, "description"]
            );
          props.onChange(e === "<p><br></p>" ? "" : e);
        }}
        {...props.otherProps}
        onKeyDown={(e) => {
          // At some places we submit on Enter key down
          // This prevents firing such events when mention menu is open
          // We can not use `element` directly here as when enter is presses the
          // menu does not exist in the DOM
          // So we check whether it was existing in the previous key down event
          const element = document.querySelector(".ql-mention-list-container");
          if (wasMentionMenuOpen && e.keyCode === 13) {
            setWasMentionMenuOpen(!!element);
            return;
          }
          setWasMentionMenuOpen(!!element);
          console.log({ element, e });
          props?.otherProps?.onKeyDown?.(e);
        }}
      />
    </div>
  );
};

export default QuillEditor;

import { Col } from "antd";
const EmptySearchComponent = ({ text, subtext, component }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "12px",
        padding: "2.5rem",
      }}
    >
      {component ? (
        component
      ) : (
        <svg
          width="152"
          height="120"
          viewBox="0 0 152 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1168_19479)">
            <path
              d="M137.99 0.0785522H0.0588379V88.6633H137.99V0.0785522Z"
              fill="#E6E6E6"
            />
            <path
              d="M134.046 11.1891H4.00305V83.4225H134.046V11.1891Z"
              fill="white"
            />
            <path d="M137.932 0H0V5.87624H137.932V0Z" fill="#6C63FF" />
            <path
              d="M4.35463 4.09333C4.95445 4.09333 5.44071 3.60572 5.44071 3.00422C5.44071 2.40271 4.95445 1.9151 4.35463 1.9151C3.75481 1.9151 3.26855 2.40271 3.26855 3.00422C3.26855 3.60572 3.75481 4.09333 4.35463 4.09333Z"
              fill="white"
            />
            <path
              d="M8.47695 4.09333C9.07677 4.09333 9.56302 3.60572 9.56302 3.00422C9.56302 2.40271 9.07677 1.9151 8.47695 1.9151C7.87712 1.9151 7.39087 2.40271 7.39087 3.00422C7.39087 3.60572 7.87712 4.09333 8.47695 4.09333Z"
              fill="white"
            />
            <path
              d="M12.5996 4.09333C13.1994 4.09333 13.6857 3.60572 13.6857 3.00422C13.6857 2.40271 13.1994 1.9151 12.5996 1.9151C11.9998 1.9151 11.5135 2.40271 11.5135 3.00422C11.5135 3.60572 11.9998 4.09333 12.5996 4.09333Z"
              fill="white"
            />
            <path
              d="M49.2342 18.6839H15.8192V75.9277H49.2342V18.6839Z"
              fill="#E6E6E6"
            />
            <path
              d="M82.848 18.6839H55.0022V34.4409H82.848V18.6839Z"
              fill="#E6E6E6"
            />
            <path
              d="M82.848 38.829H55.0022V55.7827H82.848V38.829Z"
              fill="#E6E6E6"
            />
            <path
              d="M82.848 60.1707H55.0022V75.9277H82.848V60.1707Z"
              fill="#E6E6E6"
            />
            <path
              d="M122.23 18.6839H88.8152V43.6304H122.23V18.6839Z"
              fill="#E6E6E6"
            />
            <path
              d="M122.23 50.9812H88.8152V75.9277H122.23V50.9812Z"
              fill="#E6E6E6"
            />
            <path
              d="M109.358 45.4226V40.052C109.358 38.1433 110.114 36.3128 111.46 34.9632C112.805 33.6136 114.631 32.8553 116.534 32.8553C118.437 32.8553 120.263 33.6136 121.609 34.9632C122.955 36.3128 123.711 38.1433 123.711 40.052V45.4226C123.71 45.6789 123.609 45.9246 123.428 46.1059C123.247 46.2871 123.002 46.389 122.747 46.3893H110.322C110.066 46.389 109.821 46.2871 109.64 46.1059C109.459 45.9246 109.358 45.6789 109.358 45.4226Z"
              fill="#2F2E41"
            />
            <path
              d="M127.146 115.315L124.615 116.018L120.708 106.561L124.444 105.525L127.146 115.315Z"
              fill="#FFB8B8"
            />
            <path
              d="M122.606 115.794L127.488 114.439L128.339 117.522L120.383 119.729C120.272 119.325 120.241 118.902 120.292 118.485C120.343 118.068 120.476 117.666 120.682 117.3C120.889 116.935 121.165 116.614 121.495 116.355C121.825 116.097 122.203 115.906 122.606 115.794V115.794Z"
              fill="#2F2E41"
            />
            <path
              d="M100.756 117.268H98.1298L96.8802 107.109H100.757L100.756 117.268Z"
              fill="#FFB8B8"
            />
            <path
              d="M96.2537 116.515H101.319V119.713H93.0646C93.0646 119.293 93.1471 118.877 93.3073 118.489C93.4676 118.101 93.7025 117.749 93.9987 117.452C94.2948 117.155 94.6464 116.919 95.0333 116.759C95.4202 116.598 95.8349 116.515 96.2537 116.515V116.515Z"
              fill="#2F2E41"
            />
            <path
              d="M97.5893 60.4943C97.7768 60.7481 98.0174 60.9576 98.2944 61.108C98.5713 61.2584 98.8777 61.346 99.1921 61.3648C99.5065 61.3836 99.8211 61.3331 100.114 61.2167C100.407 61.1004 100.67 60.9211 100.887 60.6914L107.868 63.8403L107.233 59.9023L100.701 57.5157C100.292 57.1771 99.7735 57.0006 99.2436 57.0196C98.7137 57.0387 98.2092 57.252 97.8257 57.6192C97.4422 57.9863 97.2063 58.4818 97.1627 59.0117C97.1192 59.5416 97.2709 60.0692 97.5893 60.4943H97.5893Z"
              fill="#FFB8B8"
            />
            <path
              d="M112.095 84.1763C109.902 84.1763 107.631 83.8486 105.723 82.8741C104.709 82.367 103.811 81.6556 103.085 80.7846C102.358 79.9135 101.819 78.9014 101.5 77.8117C100.506 74.6539 101.76 71.5516 102.972 68.5516C103.724 66.6926 104.434 64.9367 104.618 63.206L104.682 62.5915C104.969 59.8315 105.217 57.4479 106.588 56.5538C107.299 56.0904 108.255 56.064 109.51 56.4729L121.302 60.3142L120.869 82.7613L120.797 82.7854C120.735 82.8062 116.578 84.1763 112.095 84.1763Z"
              fill="#2F2E41"
            />
            <path
              d="M109.304 49.4846C109.304 49.4846 115.088 47.766 119.587 48.4105C119.587 48.4105 117.016 62.5889 117.873 67.3151C118.73 72.0412 102.984 69.1411 106.198 64.6298L107.269 59.2591C107.269 59.2591 105.127 57.1109 107.055 54.533L109.304 49.4846Z"
              fill="#6C63FF"
            />
            <path
              d="M106.459 83.7507L99.3303 82.4103L101.819 63.2035C101.986 62.6673 105.842 50.4981 107.593 49.8397C108.864 49.3907 110.164 49.0285 111.485 48.7555L111.738 48.7046L110.309 50.8547L104.618 64.5293L106.459 83.7507Z"
              fill="#2F2E41"
            />
            <path
              d="M102.745 114.914L93.5341 113.375L98.7029 92.2092L106.394 63.2642L106.47 63.7782C106.476 63.8165 107.195 67.5978 117.856 65.92L117.95 65.9053L117.976 65.9968L130.862 111.655L120.367 113.409L110.795 80.0971L102.745 114.914Z"
              fill="#2F2E41"
            />
            <path
              d="M115.511 87.345L115.517 87.1825C115.523 87.0021 116.157 69.0572 115.945 61.8402C115.733 54.5986 118.071 48.3275 118.094 48.265L118.113 48.2146L118.165 48.1997C121.19 47.3332 123.814 49.9195 123.84 49.9457L123.877 49.9826L123.015 57.1097L126.704 83.0266L115.511 87.345Z"
              fill="#2F2E41"
            />
            <path
              d="M115.059 46.2643C117.965 46.2643 120.321 43.902 120.321 40.9879C120.321 38.0739 117.965 35.7116 115.059 35.7116C112.153 35.7116 109.798 38.0739 109.798 40.9879C109.798 43.902 112.153 46.2643 115.059 46.2643Z"
              fill="#FFB8B8"
            />
            <path
              d="M107.66 40.4816C107.661 38.9723 108.26 37.5253 109.324 36.458C110.389 35.3908 111.832 34.7905 113.337 34.7888H114.408C115.913 34.7905 117.356 35.3908 118.42 36.458C119.485 37.5253 120.083 38.9723 120.085 40.4816V40.589H117.821L117.049 38.4211L116.895 40.589H115.725L115.335 39.4952L115.257 40.589H107.66V40.4816Z"
              fill="#2F2E41"
            />
            <path
              d="M114.542 46.9933C114.435 46.849 114.372 46.6775 114.358 46.4986C114.345 46.3197 114.382 46.1407 114.466 45.9821C115.601 43.8171 117.19 39.8166 115.081 37.3495L114.929 37.1722H121.053V46.3925L115.49 47.3768C115.434 47.3868 115.377 47.3918 115.32 47.3919C115.168 47.3919 115.018 47.3558 114.883 47.2865C114.747 47.2172 114.631 47.1167 114.542 46.9933Z"
              fill="#2F2E41"
            />
            <path
              d="M89.8847 8.92525C85.5993 5.29907 80.3553 3.00012 74.7908 2.30815C69.2263 1.61617 63.5811 2.56101 58.5423 5.02769C53.5035 7.49436 49.2883 11.3765 46.4095 16.2018C43.5308 21.027 42.1127 26.5873 42.3278 32.206C42.5429 37.8247 44.3819 43.2596 47.6211 47.8493C50.8602 52.439 55.3599 55.9857 60.5724 58.0577C65.785 60.1297 71.4858 60.6378 76.981 59.52C82.4763 58.4023 87.5291 55.7069 91.5246 51.762L127.004 81.7833C127.544 82.241 128.243 82.4649 128.948 82.4057C129.652 82.3465 130.304 82.0092 130.761 81.4678C131.217 80.9265 131.44 80.2255 131.381 79.5191C131.322 78.8127 130.986 78.1587 130.446 77.701L130.442 77.6977L94.9624 47.6764C99.084 41.7444 100.848 34.4828 99.9088 27.3145C98.9695 20.1462 95.3946 13.5881 89.8847 8.92525V8.92525ZM87.5975 44.7855C84.8335 48.0704 81.1601 50.4596 77.0421 51.6509C72.924 52.8423 68.5461 52.7823 64.462 51.4786C60.3779 50.1749 56.771 47.686 54.0975 44.3267C51.4239 40.9673 49.8038 36.8884 49.442 32.6057C49.0802 28.3231 49.9929 24.029 52.0648 20.2665C54.1366 16.504 57.2746 13.442 61.0817 11.4679C64.8889 9.49381 69.1944 8.69614 73.4536 9.17579C77.7129 9.65545 81.7347 11.3909 85.0104 14.1626H85.0104C87.1854 16.003 88.9776 18.255 90.2847 20.7899C91.5918 23.3249 92.3882 26.0932 92.6284 28.9368C92.8687 31.7804 92.548 34.6436 91.6848 37.3629C90.8216 40.0822 89.4327 42.6044 87.5975 44.7855V44.7855Z"
              fill="#3F3D56"
            />
            <path
              opacity="0.3"
              d="M57.0602 47.3798C52.9029 43.8618 50.217 38.9046 49.5357 33.4926C48.8545 28.0806 50.2278 22.6096 53.3829 18.166C52.9685 18.5844 52.568 19.0226 52.1812 19.4807C50.3459 21.6617 48.9571 24.1839 48.0938 26.9033C47.2306 29.6226 46.91 32.4858 47.1502 35.3294C47.3905 38.173 48.1869 40.9413 49.494 43.4763C50.8011 46.0112 52.5933 48.2632 54.7683 50.1035C56.9433 51.9439 59.4584 53.3367 62.1702 54.2023C64.8819 55.0679 67.7371 55.3895 70.5728 55.1486C73.4084 54.9077 76.169 54.109 78.6969 52.7983C81.2248 51.4875 83.4704 49.6903 85.3057 47.5092C85.6916 47.0506 86.055 46.5809 86.3959 46.1002C82.5586 49.9666 77.4116 52.2444 71.9771 52.4815C66.5425 52.7185 61.2178 50.8975 57.0602 47.3798V47.3798Z"
              fill="black"
            />
            <path
              d="M116.749 69.7185C117.055 69.7935 117.373 69.8003 117.682 69.7385C117.991 69.6767 118.283 69.5477 118.536 69.3606C118.79 69.1736 119 68.933 119.151 68.6559C119.302 68.3787 119.39 68.0718 119.41 67.7566L126.787 65.7034L123.788 63.0818L117.243 65.4334C116.713 65.435 116.202 65.6316 115.806 65.9859C115.411 66.3402 115.159 66.8276 115.098 67.3558C115.037 67.884 115.171 68.4162 115.476 68.8517C115.78 69.2871 116.233 69.5956 116.749 69.7185V69.7185Z"
              fill="#FFB8B8"
            />
            <path
              d="M119.815 68.6317L119.366 64.3554L125.527 60.9463L121.51 56.0697L122.165 50.5918L123.798 49.8903L123.849 49.9557C124.618 50.9482 131.369 59.6832 131.369 60.7629C131.369 61.872 130.078 65.1289 128.311 66.0148C126.606 66.8694 120.206 68.5303 119.935 68.6007L119.815 68.6317Z"
              fill="#2F2E41"
            />
            <path
              d="M151.786 120H70.1659C70.1091 120 70.0546 119.978 70.0144 119.937C69.9742 119.897 69.9517 119.842 69.9517 119.785C69.9517 119.728 69.9742 119.674 70.0144 119.633C70.0546 119.593 70.1091 119.57 70.1659 119.57H151.786C151.843 119.57 151.897 119.593 151.937 119.633C151.977 119.674 152 119.728 152 119.785C152 119.842 151.977 119.897 151.937 119.937C151.897 119.978 151.843 120 151.786 120Z"
              fill="#CCCCCC"
            />
          </g>
          <defs>
            <clipPath id="clip0_1168_19479">
              <rect width="152" height="120" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}

      <div
        style={{
          fontWeight: "600",
          fontSize: "14px",
          lineHeight: "20px",
          marginTop: "10px",
          marginBottom: "4px",
        }}
      >
        {text}
      </div>
      <div
        style={{
          color: "#64748B",
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "16px",
          color: "#64748B",
        }}
      >
        {subtext}
      </div>
    </div>
  );
};

export default EmptySearchComponent;

import React, { Dispatch, SetStateAction } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { updateComment, UpdateCommentOptions } from "../../utils/comments";
import CommentReplyInput from "./CommentReplyInput";

type Props = UpdateCommentOptions & {
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  isNestedReply?: boolean;
  minimumCharLimit?: number;
};

const EditComment = ({
  commentId,
  entity,
  content,
  userTagged,
  setIsEditing,
  onSuccess,
  isNestedReply,
  minimumCharLimit = 0,
}: Props) => {
  const { isLoading, mutate: handleCommentUpdate } = useMutation({
    mutationFn: (data: UpdateCommentOptions) => updateComment(data),
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update comment. Try again later.");
    },
    onSuccess,
  });

  const handlePostComment = async (
    commentText: string,
    mentionedUsers: string[],
    commentId: string
  ) => {
    handleCommentUpdate({
      commentId,
      content: commentText,
      userTagged: mentionedUsers,
      entity,
    });
  };

  return (
    <CommentReplyInput
      isNestedReply={null}
      isSmallProfilePicture={!!isNestedReply}
      parentId={commentId}
      initialCommentText={content}
      isFocused
      initialUserTagged={userTagged}
      parentLoader={isLoading}
      postComment={handlePostComment}
      onCancel={() => setIsEditing(false)}
      onSubmit={() => {}}
      minimumCharLimit={minimumCharLimit}
    />
  );
};

export default EditComment;

import { Avatar, Dropdown, Menu, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { StyledProfileDetails } from "../BountyFeed";
import dummyUser from "../../assets/iconSvg/dummy_user_icons/3.svg";
import { menu } from "./CreateBounty/data";
import useWindowSize from "../../hooks/useWindowSize";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { togglePinBounty } from "../../utils/pin";
import { PinIcon } from "../Icones";
import { useDaoContext } from "../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../Common/ProfileCard";
import { getPostTime } from "../Feed";

const { Text } = Typography;

function BountyFeedCardContent({
  data,
  style,
  showDao,
  canCreateBounty,
  isAdmin,
  isTripleDotMenuVisible = false,
}) {
  const { isMobile } = useWindowSize();
  const navigate = useDaoLensNavigate();
  const { daoDetails, daoId } = useDaoContext();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "12px",
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            fontSize: "12px",
          }}
        >
          {menu[data.bountyType]?.icon}
          {menu[data.bountyType]?.label}
        </div>

        <div
          style={{
            display: isMobile ? "none" : "flex",
            alignItems: "center",
            gap: "4px",
            fontSize: "12px",
            color: "#64748b",
          }}
        >
          &#x2022; Posted by{" "}
          <ProfileCardPopup userId={data?.userId}>
            <StyledProfileDetails
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/app/profile/${data.userId}`)}
            >
              <Text style={{ color: "inherit", fontWeight: 500, fontSize: 12 }}>
                {data.userName}
              </Text>
            </StyledProfileDetails>
          </ProfileCardPopup>
          {showDao && (
            <>
              {" "}
              &#x2022;
              <StyledProfileDetails
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/app/dao/${data.daoId}`);
                }}
              >
                <Avatar
                  size={16}
                  src={data.profilePicture ? data.profilePicture : dummyUser}
                  style={{ marginRight: "6px" }}
                />
                <Text
                  style={{ color: "inherit", fontWeight: 500, fontSize: 12 }}
                >
                  {data.daoName}
                </Text>
              </StyledProfileDetails>
            </>
          )}
          &#x2022;
          <Text style={{ color: "#64748B", fontWeight: 500, fontSize: 12 }}>
            {getPostTime(data.bountyCreatedAt)}
          </Text>
        </div>
        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          {data.pinned && <PinIcon style={{ color: "#6366F1" }} />}
          {isTripleDotMenuVisible && (canCreateBounty || isAdmin) ? (
            <Dropdown
              overlay={
                <Menu style={{ borderRadius: "8px" }}>
                  <Menu.Item
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      togglePinBounty(daoId, data.bountyId, !data.pinned);
                    }}
                  >
                    <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
                    {data.pinned ? "Unpin" : "Pin"}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <EllipsisVerticalIcon
                width={"20px"}
                style={{ cursor: "pointer" }}
              />
            </Dropdown>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default BountyFeedCardContent;

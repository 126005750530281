import { Button, Col, Modal, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import Sidemenu from "../../components/Sidemenu";
import CoursePage from "../../components/Course/CoursePage";
import CourseDetailsPage from "../../components/Course/CourseDetailsPage";
import BountyDetailsPage from "../../features/bountypage/BountyDetailsPage";
import PostDetailsPage from "../../components/PostDetailsPage";
import Bountypage from "../../features/bountypage/Bountypage";
import DaoChannel from "../DaoChannel";
import DaoOverview from "../DaoOverview";
import SubtaskDetailsPage from "../../features/bountypage/SubtaskDetailsPage";
import Settings from "../Settings";
import Proposal from "../../components/Proposal/Proposal";
import ProposalDetailsPage from "../../components/Proposal/ProposalDetails";
import { DaoContext } from "../../layouts/app/DaoContext";
import ProjectPage from "../../components/Project.js/ProjectPage";
import ProjectDetailsPage from "../../components/Project.js/ProjectDetailsPage";
import TaskDetailsPage from "../../components/Project.js/Task/TaskDetailsPage";
import SubTaskDetailsPage from "../../components/Project.js/Task/SubTaskDetailsPage";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import useWindowSize from "../../hooks/useWindowSize";
import {
  COMMUNITY_TYPES,
  MD_SCREEN_BREAKPOINT,
  UNAUTH_JOIN_COMMUNITY_DETAILS,
} from "../../constants";
import CollectionDetailsPage from "../../components/Course/Document/CollectionDetailsPage";
import DaoEvents from "../DaoEvents";
import EventDetailsPage from "../../components/EventComponents/EventDetailsPage";
import confettiAnimation from "./../../assets/animations/confetti2.json";
import GenericAnimation from "../../components/Common/GenericAnimation";
import DetailsPage from "../../components/Overview/Profile/Announcement/DetailsPage";
import JoinIcon from "../../assets/images/profile/Vector1.svg";
import daoDefaultBg from "../../assets/images/daoSideBar/daoDefaultBg.png";
import styled from "styled-components";
import { getDefaultDaoImage, postMixpanel } from "../../utils";
import { getTrunckedString } from "../../components/Proposal/ProposalCard";
import { UserContext } from "../../layouts/app/UserContext";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import Member from "../DaoOverview/Members";
import DaoSpaces from "../DaoSpaces";
import minimatch from "minimatch";
import JoinSpaceModal from "../../components/Spaces/JoinSpaceModal";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import DaoCourse from "../DaoCourse";
import DaoLibrary from "../DaoLibrary";
import DiscourseAccountCreationModal from "../../components/Common/Dao/DiscourseAccountCreationModal";

const StyledCapsule = styled.div`
  color: #64748b;
  width: fit-content;
  padding: 4px 8px;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 6.62787px;
`;
const StyledModal = styled(Modal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-body {
    padding: 0 !important;
  }
  & .ant-modal-close-x {
    color: #f3f3f3;
  }
`;

function DaoHomePage({ isSpace = false }) {
  const navigate = useDaoLensNavigate();
  const { windowSize, isMobile } = useWindowSize();
  const {
    reload,
    showLoginModal,
    userDetails,
    loading: userLoading,
  } = useContext(UserContext);
  const [startAnimation, setStartAnimation] = useState(false);
  const [showJoinDaoModal, setShowJoinDaoModal] = useState(false);
  const [canJoinDao, setCanJoinDao] = useState(true);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [canShowJoinButton, setCanShowJoinButton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const {
    daoDetails,
    isCurrentDaoJoined,
    reload: daoReload,
    loading,
    daoId,
    isInviteModalOpen,
    isConnectDiscourseModalOpen,
    setIsConnectDiscourseModalOpen,
  } = useContext(DaoContext);
  useEffect(() => {
    if (daoDetails.daoName) {
      let pathname = window.location.pathname.split("/");
      if (pathname[1] === "app" && pathname[2] === "dao") {
        if (isNaN(pathname[4])) {
          navigate(
            `/app/dao/${daoDetails.daoName}/${pathname[3]}/${
              window.location.pathname.split(pathname[3] + "/")[1]
            }${window.location.search}`
          );
        }
      }
    }
  }, [daoDetails, location.pathname]);

  useEffect(() => {}, [searchParams, isCurrentDaoJoined]);
  useEffect(() => {
    if (location?.state?.newCommunity) {
      setStartAnimation(true);
      setTimeout(() => {
        setStartAnimation(false);
      }, 2500);
    }
  }, []);
  useTrackPageView(daoId);
  useEffect(() => {
    let unauthDetails = window.localStorage.getItem(
      UNAUTH_JOIN_COMMUNITY_DETAILS
    );
    if (isCurrentDaoJoined !== null && !unauthDetails)
      if (searchParams.get("invite") && !isCurrentDaoJoined) {
        setDirectJoinDetails();
        setShowJoinDaoModal(true);
      }

    if (unauthDetails && userDetails) {
      unauthDetails = JSON.parse(unauthDetails);

      if (unauthDetails?.directJoin) {
        unauthDetails.type
          ? joinDaoHandler({ type: unauthDetails.type })
          : joinDaoHandler();
      }
    } else if (!userLoading && !loading) {
      setCanShowJoinButton(true);
    }
  }, [userDetails, userLoading, loading, searchParams, isCurrentDaoJoined]);

  const isSpaceInPathname = minimatch(
    location.pathname,
    "/app/dao/*/*/spaces/**"
  );

  const isSpaceParentComponent = isSpaceInPathname && !isSpace;

  let discussion =
    daoDetails?.discussionDisable !== undefined &&
    !daoDetails.discussionDisable;
  let bounty =
    daoDetails?.bountyDisable !== undefined && !daoDetails.bountyDisable;
  let proposal =
    daoDetails?.proposalsDisable !== undefined && !daoDetails.proposalsDisable;
  let events =
    daoDetails?.eventsDisable !== undefined && !daoDetails.eventsDisable;
  let project =
    daoDetails?.projectsDisable !== undefined && !daoDetails.projectsDisable;
  let course =
    daoDetails?.coursesDisable !== undefined && !daoDetails.coursesDisable;
  let documents =
    daoDetails?.documentsDisable !== undefined && !daoDetails.documentsDisable;
  let spaces =
    isSpaceParentComponent ||
    (daoDetails?.spacesDisable !== undefined && !daoDetails.spacesDisable);

  const setDirectJoinDetails = () => {
    if (!userDetails)
      window.localStorage.setItem(
        UNAUTH_JOIN_COMMUNITY_DETAILS,
        JSON.stringify({
          directJoin: true,
          invite: searchParams.get("invite") === "true" ? true : false,
          type: searchParams.get("type"),
        })
      );
  };
  const removeDirectJoinDetails = () => {
    window.localStorage.removeItem(UNAUTH_JOIN_COMMUNITY_DETAILS);
  };
  const joinDaoHandler = async (details = {}) => {
    const type = details.type || searchParams.get("type");
    if (!canJoinDao) return;
    setCanJoinDao(false);
    try {
      await customAxios.post(`dao/join`, {
        daoId: daoId,
        type: type ? `Bearer ${type}` : undefined,
      });
      postMixpanel("track", "joined_dao", {
        dao_id: daoId,
        dao_name: daoDetails?.name,
        type: type ? "adminInvite" : "memberInvite",
      });
      toast.success("Dao joined successfully");
      setShowJoinDaoModal(false);
      setCanJoinDao(true);
      setSearchParams({});
      removeDirectJoinDetails();
      setTimeout(() => setInviteModalOpen(true), 10000);
      window.location.reload();
      // windowSize.width < MD_SCREEN_BREAKPOINT && window.location.reload();
    } catch (error) {
      setCanJoinDao(true);
      console.error(error);
    }
  };

  return (
    <>
      {startAnimation ? (
        <GenericAnimation
          style={{
            zIndex: "100",
            position: "absolute",
            overflowY: "hidden",
            top: 0,
            left: 0,
          }}
          isStopped={!startAnimation}
          animationData={confettiAnimation}
          otherProps={{ loop: true }}
        />
      ) : null}{" "}
      <Routes>
        <Route
          path={"settings/*"}
          element={
            <div
              style={{
                background: "#F6F5FF",
                width: "100%",

                height:
                  windowSize.width < MD_SCREEN_BREAKPOINT
                    ? "calc(100vh - 75px)"
                    : "100vh",
              }}
            >
              <Row
                justify="center"
                // gutter={[16, 16]}
                style={{
                  background: "#F6F5FF",
                  width: "100%",

                  display: "flex",
                  flexDirection: "row-reverse",
                  padding:
                    windowSize.width < MD_SCREEN_BREAKPOINT
                      ? "10px 0 75px 0"
                      : "0",
                  zIndex: windowSize.width < MD_SCREEN_BREAKPOINT ? 5 : 1,
                  position:
                    windowSize.width < MD_SCREEN_BREAKPOINT
                      ? "absolute"
                      : "static",
                  // marginBottom:
                  //   windowSize.width < MD_SCREEN_BREAKPOINT ? "100px" : "0",
                }}
              >
                <Settings key={daoId} />
              </Row>
            </div>
          }
        />
        <Route
          path={"*"}
          element={
            <div
              style={{
                background: "#F6F5FF",
                width: "100%",

                height:
                  windowSize.width < MD_SCREEN_BREAKPOINT
                    ? "calc(100vh - 72px)"
                    : "100vh",
              }}
            >
              <Row
                justify="center"
                gutter={isSpaceParentComponent ? undefined : [16, 16]}
                style={{
                  background: "#F6F5FF",
                  width: "100%",

                  display: "flex",
                  flexDirection: "row-reverse",
                  padding:
                    windowSize.width < MD_SCREEN_BREAKPOINT
                      ? "10px 0 75px 0"
                      : "0",
                  zIndex: windowSize.width < MD_SCREEN_BREAKPOINT ? 5 : 1,
                  position:
                    windowSize.width < MD_SCREEN_BREAKPOINT
                      ? "absolute"
                      : "static",
                  // marginBottom:
                  //   windowSize.width < MD_SCREEN_BREAKPOINT ? "100px" : "0",
                }}
              >
                <Col
                  span={
                    windowSize.width < MD_SCREEN_BREAKPOINT ||
                    isSpaceParentComponent
                      ? 24
                      : 12
                  }
                  style={{ padding: 0, position: "relative" }}
                >
                  {" "}
                  {!isSpace && !isSpaceParentComponent && showJoinDaoModal && (
                    <StyledModal
                      visible={showJoinDaoModal}
                      onCancel={() => {
                        removeDirectJoinDetails();
                        setShowJoinDaoModal(false);
                      }}
                      width={458}
                      footer={null}
                      centered
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            daoDetails?.backgroundImage
                              ? daoDetails.backgroundImage
                              : daoDefaultBg
                          }
                          alt="logo"
                          style={{
                            borderTopRightRadius: "12px",
                            borderTopLeftRadius: "12px",
                            objectFit: "cover",
                            width: "100%",
                            height: "120px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "72%",
                            left: "41%",
                          }}
                        >
                          {daoDetails?.profilePicture ? (
                            <img
                              src={daoDetails?.profilePicture}
                              alt="dao_logo"
                              style={{
                                borderRadius: "100%",
                                width: "88px",
                                height: "88px",
                                objectFit: "cover",
                                border: "2px solid #ffffff",
                                boxShadow:
                                  "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
                              }}
                            />
                          ) : (
                            getDefaultDaoImage(
                              daoDetails?.daoName,
                              {
                                alignSelf: "center",
                                width: "88px",
                                height: "88px",
                                cursor: "pointer",

                                borderRadius: "50%",
                              },
                              { fontSize: "28px" }
                            )
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "64px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#1A202E",
                            lineHeight: "28px",
                          }}
                        >
                          {getTrunckedString(daoDetails?.daoName, 15)}
                        </div>
                        <Row
                          style={{
                            columnGap: "8px",
                            margin: "12px 0px 8px 0px",
                          }}
                        >
                          <StyledCapsule>
                            👥 : {daoDetails.contributors}
                          </StyledCapsule>
                          <StyledCapsule>
                            💰 : {daoDetails.bounty}
                          </StyledCapsule>
                          {daoDetails.communityType ? (
                            <StyledCapsule>
                              {
                                COMMUNITY_TYPES.find(
                                  (type) => daoDetails.communityType === type.id
                                )?.name
                              }
                            </StyledCapsule>
                          ) : null}
                        </Row>
                        <div
                          style={{
                            color: "#64748B",
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "400",
                            marginTop: "4px",
                          }}
                        >
                          Join the community space to engage on the platform
                        </div>
                        <Button
                          type="primary"
                          style={{
                            marginTop: "24px",
                            marginBottom: "24px",
                            width: "80%",
                            height: "40px",
                            borderRadius: "12px",
                            fontWeight: "500",
                          }}
                          onClick={() => {
                            if (userDetails) {
                              joinDaoHandler();
                            } else {
                              showLoginModal();
                            }
                          }}
                        >
                          Join 🤝
                        </Button>
                      </div>
                    </StyledModal>
                  )}
                  {isSpace && (
                    <JoinSpaceModal
                      isOpen={showJoinDaoModal}
                      setIsOpen={setShowJoinDaoModal}
                      daoDetails={daoDetails}
                    />
                  )}
                  {!loading &&
                    !isCurrentDaoJoined &&
                    !showJoinDaoModal &&
                    canShowJoinButton &&
                    !isInviteModalOpen && (
                      <div
                        style={{
                          borderRadius: "40px",
                          background: "rgba(255, 255, 255, 0.1)",
                          boxShadow:
                            "inset 9.03333px -9.03333px 9.03333px rgba(194, 194, 194, 0.1), inset -9.03333px 9.03333px 9.03333px rgba(255, 255, 255, 0.1)",
                          backdropFilter: "blur(9.03333px)",
                          position: "fixed",
                          bottom: isMobile ? "85px" : "48px",
                          left: isMobile ? "50%" : "60%",
                          transform: "translateX(-50%)",
                          cursor: "pointer",
                          zIndex: "1000",
                        }}
                      >
                        <div
                          style={{
                            borderRadius: "40px",
                            background: "rgba(255, 255, 255, 0.1)",
                            boxShadow:
                              "inset 9.03333px -9.03333px 9.03333px rgba(194, 194, 194, 0.1), inset -9.03333px 9.03333px 9.03333px rgba(255, 255, 255, 0.1)",
                            backdropFilter: "blur(9.03333px)",
                            position: "fixed",
                            bottom: isMobile ? "85px" : "48px",
                            left: isMobile ? "50%" : "60%",
                            transform: "translateX(-50%)",
                            cursor: "pointer",
                            zIndex: "10000000",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "rgb(63, 131, 248)",
                              borderRadius: "40px",
                              padding: "16px 20px",
                              display: "inline-flex",
                              color: "#ffffff",
                              width: "200px",
                              alignItems: "center",
                              justifyContent: "center",

                              fontWeight: "600",
                              margin: "10px",
                            }}
                            onClick={() => {
                              setDirectJoinDetails();
                              if (userDetails) {
                                joinDaoHandler();
                              } else {
                                showLoginModal();
                              }
                            }}
                          >
                            <span>Join the community</span>
                            <img
                              src={JoinIcon}
                              alt="invite"
                              style={{ marginLeft: "8px" }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  <Routes>
                    <Route
                      path="/overview/announcements/:announcementId"
                      element={<DetailsPage />}
                    />
                    <Route
                      path="/overview"
                      element={<DaoOverview key={daoId} />}
                    />
                    <Route path="/members" element={<Member key={daoId} />} />
                    {discussion && (
                      <Route
                        path="/channel/:channelId"
                        element={<DaoChannel key={daoId} />}
                      />
                    )}
                    {bounty && (
                      <Route
                        path="/bounty"
                        element={<Bountypage key={daoId} />}
                      />
                    )}
                    {bounty && (
                      <Route
                        path="/bounty/:bountyId"
                        element={<BountyDetailsPage key={daoId} />}
                      />
                    )}
                    {bounty && (
                      <Route
                        path="/bounty/:bountyId/subtask/:subtaskId"
                        element={<SubtaskDetailsPage key={daoId} />}
                      />
                    )}
                    {proposal && (
                      <Route path="/proposal" element={<Proposal />} />
                    )}
                    {proposal && (
                      <Route
                        path="/proposal/:proposalId"
                        element={<ProposalDetailsPage key={daoId} />}
                      />
                    )}
                    {project && (
                      <Route path="/project" element={<ProjectPage />} />
                    )}

                    {project && (
                      <Route
                        path="/project/:projectId"
                        element={<ProjectDetailsPage key={daoId} />}
                      />
                    )}
                    {project && (
                      <Route
                        path="/project/:projectId/task/:taskId"
                        element={<TaskDetailsPage key={daoId} />}
                      />
                    )}
                    {project && (
                      <Route
                        path="/project/:projectId/task/:taskId/sub-task/:subTaskId"
                        element={<SubTaskDetailsPage key={daoId} />}
                      />
                    )}
                    {discussion && (
                      <Route
                        path="channel/:channelId/post/:postId"
                        element={<PostDetailsPage key={daoId} />}
                      />
                    )}
                    {course && (
                      <Route
                        path="/course"
                        element={<DaoCourse key={daoId} />}
                      />
                    )}
                    {documents && (
                      <Route
                        path="/library"
                        element={<DaoLibrary key={daoId} />}
                      />
                    )}
                    {course && (
                      <Route
                        path="/course/:courseId"
                        element={<CourseDetailsPage key={daoId} />}
                      />
                    )}
                    {documents && (
                      <Route
                        path="/library/:documentId"
                        element={<CollectionDetailsPage key={daoId} />}
                      />
                    )}
                    {events && (
                      <Route
                        path="/events"
                        element={<DaoEvents key={daoId} />}
                      />
                    )}
                    {events && (
                      <Route
                        path="/events/:eventId"
                        element={<EventDetailsPage key={daoId} />}
                      />
                    )}
                    {spaces && (
                      <>
                        <Route
                          path="/spaces"
                          element={<DaoSpaces key={daoId} />}
                        />
                        <Route
                          path="/spaces/:spaceName/:spaceId/*"
                          element={<DaoHomePage isSpace />}
                        />
                      </>
                    )}

                    <Route path="*" element={<DaoOverview key={daoId} />} />

                    <Route path="*" element={<Navigate to="overview" />} />
                  </Routes>
                </Col>

                {windowSize.width > MD_SCREEN_BREAKPOINT &&
                  !isSpaceParentComponent && (
                    <Col
                      span={4}
                      style={{ maxWidth: "fit-content", paddingRight: "16px" }}
                    >
                      <Sidemenu key={daoId} />
                    </Col>
                  )}
                <DiscourseAccountCreationModal
                  isOpen={isConnectDiscourseModalOpen}
                  setIsOpen={setIsConnectDiscourseModalOpen}
                />
              </Row>
            </div>
          }
        />
      </Routes>
    </>
  );
}

export default DaoHomePage;

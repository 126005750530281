import { useState } from "react";
import { toast } from "react-toastify";
import CourseVerificationAdminModal from "../../Course/CourseVerificationAdminModal";
import { parseQuizDetails } from "../../Course/CreateCourseModal";

const Quiz = ({ state, setState }) => {
  return (
    <div style={{ padding: "20px" }}>
      <CourseVerificationAdminModal
        quizDetails={state.quiz}
        setQuizDetails={(data) => {
          setState((prev) => ({ ...prev, quiz: data }));
        }}
      />
    </div>
  );
};

export default Quiz;

export const quizValidation = ({ quiz }, res) => {
  if (!quiz || quiz.length === 0) {
    toast.error("Please add atleast one question");
    throw new Error();
  }
  let oneOptionSelected = quiz.map((item) => false);
  quiz.forEach((singleQuiz, index) => {
    // ! If it is initially true, only then get into the loop
    if (!singleQuiz.question) {
      toast.error("Question cannot be empty");
      throw new Error();
    }
    singleQuiz.options.forEach((option) => {
      if (!option.text) {
        toast.error("Option cannot be empty");
        throw new Error();
      }
      if (!oneOptionSelected[index] && option.isCorrect) {
        oneOptionSelected[index] = true;
        return;
      }
    });

    //Check duplicate options
    let duplicate = null;
    duplicate = singleQuiz.options.find((element, index) => {
      return singleQuiz.options.some(
        (opt, idx) => opt.text === element.text && index !== idx
      );
    });
    if (duplicate) {
      toast.error("Option cannot be same");
      throw new Error();
    }
  });
  let optionsSelectedForAll = oneOptionSelected.reduce((a, b) => a && b, true);
  if (!optionsSelectedForAll) {
    toast.error("Please choose correct answer");
    throw new Error();
  }
  res.quiz = JSON.stringify(parseQuizDetails(quiz));
};

export const quizInitialState = (bountyDetails) => {
  let modifiedQuizDetails = bountyDetails?.quiz?.map((singleQuiz) => ({
    ...singleQuiz,
    question: singleQuiz.questionName,
    options: singleQuiz.options.map((option) => ({
      text: option,
      isCorrect: singleQuiz?.correctAns
        ? singleQuiz?.correctAns?.includes(option)
        : false,
    })),
    correctAns: singleQuiz?.correctAns,
  }));
  return { quiz: modifiedQuizDetails };
};

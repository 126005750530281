import { Web3Provider } from "@ethersproject/providers";
import { getAddress } from "@ethersproject/address";
import { toast } from "react-toastify";
import snapshot from "@snapshot-labs/snapshot.js";
import { getProfileDetails } from "../Profile/Profile";
import { useWalletConnect } from "../../layouts/app/WalletConnectContext";
import Web3 from "web3";
import { walletConnect } from "../../utils/connectors";

const hub = "https://hub.snapshot.org"; // or https://testnet.snapshot.org for testnet
const client = new snapshot.Client712(hub);

const getCreatorAddress = async (isWalletConnected) => {
  const profile = await getProfileDetails();

  if (!profile?.walletAddress) {
    toast.error("Please connect your metamask wallet");
    return;
  }

  if (!window.ethereum) {
    toast.error("Please install metamask extension");
    return;
  }
  let checkAccount;
  const web3 = new Web3(walletConnect);
  if (isWalletConnected) {
    [checkAccount] = await web3.eth.getAccounts();
  } else {
    const [account] = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    checkAccount = account;
  }
  if (profile.walletAddress.toUpperCase() !== checkAccount.toUpperCase()) {
    throw Error("Please choose correct metamask wallet");
  }

  const creatorAddress = getAddress(checkAccount);
  console.log(creatorAddress);
  return creatorAddress;
};

export const createProposal = async ({ data }, isWalletConnected) => {
  const creatorAddress = await getCreatorAddress(isWalletConnected);
  const provider = new Web3Provider(
    isWalletConnected ? walletConnect : window.ethereum
  );
  let blockNumer = await snapshot.utils.getBlockNumber(provider);
  const receipt = await client.proposal(provider, creatorAddress, {
    ...data,
    snapshot: blockNumer,
    metadata: "{}",
    network: "1",
    plugins: "{}",
  });

  return { receipt, creatorAddress };
};

export const voteProposal = async ({ data }, isWalletConnected) => {
  console.log("first", data, isWalletConnected);

  const voterAddress = await getCreatorAddress(isWalletConnected);

  const provider = new Web3Provider(
    isWalletConnected ? walletConnect : window.ethereum
  );
  console.log("gg", provider.getNetwork());
  const receipt = await client
    .vote(provider, voterAddress, {
      ...data,
    })
    .catch((err) => {
      console.log("errs", err);
      toast.error(`Error in voting. ${err.error_description}`);
      throw new Error();
    });
  return { receipt, voterAddress };
};

export function getOrdinal(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

import { Button, Col, Row, Skeleton, Switch } from "antd";
import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import snapShotIcon from "../../images/snapshot.png";
import discourseIcon from "../../images/discourse_icon.png";
import metamaskIcon from "../../assets/iconSvg/metamask.png";
import customAxios from "../../api";
import { DaoContext } from "../../layouts/app/DaoContext";
import { getAddress } from "ethers/lib/utils";
import { Modal } from "../../styles/ModalStyles";
import { Modal as AntModal } from "antd";
import { useWalletConnect } from "../../layouts/app/WalletConnectContext";
import walletConnectIcon from "../../assets/iconSvg/wallet-connect.svg";
import styled from "styled-components";
import { useEthers } from "@usedapp/core";
import SecondaryButtonOutlined from "../InternalComponents/PostSecondaryButtonOutlined";
import useWindowSize from "../../hooks/useWindowSize";
import { labeStyling } from "../Settings/DaoProfile/InputField";
import DiscourseIntegrationModal from "../Settings/Integrations/DiscourseIntegrationModal";
import SnapshotIntegrationModal from "../Settings/Integrations/SnapshotIntegrationModal";
import { BellIcon, TicketIcon } from "@heroicons/react/24/solid";
import { CheckCircleFilled } from "@ant-design/icons";
import useDisourse from "../../hooks/useDiscourse";
import { postMixpanel } from "../../utils";

const MetamaskConnectButton = styled(Button)`
  &:hover {
    background: #f4f2f2 !important;
  }
`;

const WalletConnectButton = styled(Button)`
  &:hover {
    background: #f4f2f2 !important;
  }
`;
const CircleIntegration = styled.div`
  width: ${(props) => (props.view ? "64px" : "40px")};
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Discord = ({ view }) => (
  <CircleIntegration
    view={view}
    style={{
      background: "#EFF6FF",
    }}
  >
    <i
      class="fab fa-discord"
      style={{
        color: "#5165F6",
        fontSize: view ? "32px" : "20px",
      }}
    ></i>
  </CircleIntegration>
);

export const CommunityWallet = ({ view }) => (
  <CircleIntegration
    view={view}
    style={{
      background: "#F0FDFA",
      fontSize: view ? "32px" : "20px",
    }}
  >
    💲
  </CircleIntegration>
);

export const Snapshot = ({ view }) => (
  <CircleIntegration
    view={view}
    style={{
      background: "#FFFBEB",
      fontSize: view ? "32px" : "20px",
    }}
  >
    ⚡️
  </CircleIntegration>
);

export const Discourse = ({ view }) => (
  <CircleIntegration
    view={view}
    style={{
      background: "#F5F3FF",
    }}
  >
    <img
      src={discourseIcon}
      alt="snapshot"
      style={{ width: view ? "32px" : "20px", aspectRatio: 1 }}
    />
  </CircleIntegration>
);

export const BellIconPallet = ({ view }) => (
  <CircleIntegration view={false} className="bg-orange-50">
    <BellIcon className="text-orange-400 w-5" />
  </CircleIntegration>
);

function Integrations({ daoDetails, reload, loading: daoDataLoading }) {
  const [loading, setLoading] = useState(false);
  const [isIntegrationSnapshotModalOpen, setIsIntegrationSnapshotModalOpen] =
    useState(false);
  const [isIntegrationDiscourseModalOpen, setIsIntegrationDiscourseModalOpen] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const daoId = daoDetails.daoId;
  const { status, refetchStatus } = useDisourse(daoId);
  useEffect(() => {
    if (status === "IMPORTED")
      postMixpanel("track", "integrated_discourse_through_onboarding_flow", {
        dao_id: daoId,
      });
  }, [status]);
  const isSnapshotIntegrated = !!daoDetails?.snapshotEnsName;
  useEffect(() => {
    if (daoDetails?.snapshotEnsName)
      postMixpanel("track", "integrated_snapshot_through_onboarding_flow", {
        dao_id: daoId,
      });
  }, [daoDetails?.snapshotEnsName]);
  const isMetamaskIntegrated = !!daoDetails?.walletAddress;
  const isDiscordConnected = !!daoDetails?.discordGuildId;
  const { onClickActivate } = useWalletConnect();
  const isDiscordNotificationsEnabled = daoDetails?.discordNotificationsEnabled;
  const toggleDiscordNotifications = (flag) => {
    const formData = new FormData();
    formData.append("daoId", daoId);
    formData.append("discordNotificationsEnabled", flag);
    customAxios
      .post("dao/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          if (flag)
            postMixpanel(
              "track",
              "enabled_discord_notifications_through_onboarding_flow",
              {
                dao_id: daoId,
              }
            );
          reload();
        }
      })
      .catch((err) => {
        toast.error("Some error occured");
        console.log(err);
      });
  };

  const { account } = useEthers();

  useEffect(() => {
    setLoading(true);
    if (!!account && !!daoId) {
      const formData = new FormData();
      formData.append("daoId", daoId);
      formData.append("walletAddress", account);
      customAxios
        .post("dao/edit", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          postMixpanel(
            "track",
            "added_community_wallet_through_onboarding_flow",
            {
              dao_id: daoId,
            }
          );
          toast.success("Wallet connected successfully");
          setIsModalOpen(false);
          reload();
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [account, daoId]);
  const connectMetamask = () => {
    if (window.ethereum) {
      setLoading(true);
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          const formData = new FormData();
          formData.append("daoId", daoId);
          formData.append("walletAddress", getAddress(res[0]));
          customAxios
            .post("dao/edit", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then(() => {
              toast.success("Metamask connected successfully");
              setIsModalOpen(false);
              reload();
            });
        })
        .catch((err) => toast.error(err.message))
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error("Install metamask extension!!");
    }
  };

  const ConnectMetaMask = () => {
    return (
      <MetamaskConnectButton
        style={{
          ...labeStyling,
          color: "#014737",
          padding: "22px 8px",
          gap: "8px",
          display: "inline-flex",
          border: `1px solid ${isMetamaskIntegrated ? "#0E9F6E" : "#E2E8F0"}`,
          alignItems: "center",
          borderRadius: "8px",
          background: isMetamaskIntegrated ? "#F3FAF7" : "",
          marginBottom: "12px",
          cursor:
            isMetamaskIntegrated && !isDiscordConnected ? "not-allowed" : "",
        }}
        onClick={() => {
          if (!isMetamaskIntegrated) {
            connectMetamask();
          }
        }}
      >
        {" "}
        <img src={metamaskIcon} alt="icon" height="16" />
        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: "500" }}>
          Metamask
        </p>
      </MetamaskConnectButton>
    );
  };

  const WalletConnect = () => {
    const handleConnectWallet = () => {
      onClickActivate();
    };

    return (
      <WalletConnectButton
        style={{
          ...labeStyling,
          color: "#014737",
          padding: "22px 8px",
          gap: "8px",
          display: "inline-flex",
          border: `1px solid ${isMetamaskIntegrated ? "#0E9F6E" : "#E2E8F0"}`,
          alignItems: "center",
          borderRadius: "8px",
          background: isMetamaskIntegrated ? "#F3FAF7" : "",
          marginBottom: "12px",
          cursor:
            isMetamaskIntegrated && !isDiscordConnected ? "not-allowed" : "",
        }}
        onClick={handleConnectWallet}
      >
        <img src={walletConnectIcon} alt="icon" height="16" />
        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: "500" }}>
          Wallet Connect
        </p>
      </WalletConnectButton>
    );
  };

  return (
    <Fragment>
      <Modal
        title={
          <h2
            style={{
              paddingLeft: "13px",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            Connect a wallet
          </h2>
        }
        visible={isModalOpen && !isMetamaskIntegrated}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setIsModalOpen(false)}
        width="420px"
      >
        <div
          style={{ padding: "20px", display: "flex", flexDirection: "column" }}
        >
          <ConnectMetaMask />
          <WalletConnect />
        </div>
      </Modal>
      <Col xs={24} style={{ padding: "0px", marginTop: "20px" }}>
        {daoDataLoading ? (
          <Row
            style={{
              background: "white",
              borderRadius: "12px",
              padding: "20px",
            }}
          >
            <Skeleton />
          </Row>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            <div
              style={{
                border: "1px solid #E5E7EB",
                borderRadius: "12px",
                display: "flex",
                padding: "16px",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Discord />
              <div
                style={{ color: "#1F2937", font: "normal 600 14px/20px Inter" }}
              >
                Discord
                <div
                  style={{
                    marginTop: "4px",
                    color: "#9CA3AF",
                    font: "normal 500 12px/16px Inter",
                  }}
                >
                  Import roles and create discord based bounties
                </div>
              </div>

              {isDiscordConnected ? (
                <CheckCircleFilled
                  style={{
                    color: "#10B981",
                    fontSize: "24px",
                    stroke: "4px",
                    marginLeft: "auto",
                  }}
                />
              ) : (
                <Button
                  onClick={() =>
                    window.open(
                      `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_ENDPOINT === "development"
                        ? "1046753505026785401"
                        : "1014817083139301417"
                      }&redirect_uri=${encodeURIComponent(window.location.origin) +
                      "/app/redirect/discordIntgeration"
                      }&response_type=code&state=${btoa(
                        JSON.stringify({ daoId, from: "onboarding" })
                      )}&from=${btoa("onboarding")}&scope=identify%20guilds`,
                      "_self"
                    )
                  }
                  type="text"
                  style={{
                    color: "#3B82F6",
                    font: "normal 500 14px/20px Inter",
                    marginLeft: "auto",
                    borderRadius: "12px",
                  }}
                >
                  Connect
                </Button>
              )}
            </div>
            <div
              style={{
                border: "1px solid #E5E7EB",
                borderRadius: "12px",
                display: "flex",
                padding: "16px",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <CommunityWallet />
              <div
                style={{ color: "#1F2937", font: "normal 600 14px/20px Inter" }}
              >
                Connect community wallet
                <div
                  style={{
                    marginTop: "4px",
                    color: "#9CA3AF",
                    font: "normal 500 12px/16px Inter",
                  }}
                >
                  Make payments for bounties and tasks
                </div>
              </div>

              {isMetamaskIntegrated ? (
                <CheckCircleFilled
                  style={{
                    color: "#10B981",
                    fontSize: "24px",
                    stroke: "4px",
                    marginLeft: "auto",
                  }}
                />
              ) : (
                <Button
                  onClick={() => setIsModalOpen(true)}
                  type="text"
                  style={{
                    color: "#3B82F6",
                    font: "normal 500 14px/20px Inter",
                    marginLeft: "auto",
                    borderRadius: "12px",
                  }}
                >
                  Connect
                </Button>
              )}
            </div>
            <div
              style={{
                border: "1px solid #E5E7EB",
                borderRadius: "12px",
                display: "flex",
                padding: "16px",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Snapshot />
              <div
                style={{ color: "#1F2937", font: "normal 600 14px/20px Inter" }}
              >
                Snapshot
                <div
                  style={{
                    marginTop: "4px",
                    color: "#9CA3AF",
                    font: "normal 500 12px/16px Inter",
                  }}
                >
                  Import your snapshot workspace and create proposals
                </div>
              </div>

              {isSnapshotIntegrated ? (
                <CheckCircleFilled
                  style={{
                    color: "#10B981",
                    fontSize: "24px",
                    stroke: "4px",
                    marginLeft: "auto",
                  }}
                />
              ) : (
                <Button
                  onClick={() => setIsIntegrationSnapshotModalOpen(true)}
                  type="text"
                  style={{
                    color: "#3B82F6",
                    font: "normal 500 14px/20px Inter",
                    marginLeft: "auto",
                    borderRadius: "12px",
                  }}
                >
                  Import
                </Button>
              )}
            </div>
            <div
              // className="justify-between"
              style={{
                border: "1px solid #E5E7EB",
                borderRadius: "12px",
                display: "flex",
                padding: "16px",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <Discourse />
              <div
                className="mr-auto"
                style={{ color: "#1F2937", font: "normal 600 14px/20px Inter" }}
              >
                Discourse
                <span
                  style={{
                    background: "#ECFDF5",
                    color: "#059669",
                    marginLeft: "4px",
                    font: "normal 500 10px/16px Inter",
                    padding: "4px 8px",
                    borderRadius: "8px",
                  }}
                >
                  NEW
                </span>
                <div
                  style={{
                    marginTop: "4px",
                    color: "#9CA3AF",
                    font: "normal 500 12px/16px Inter",
                  }}
                >
                  Import all your community discussions seamlessly{" "}
                </div>
              </div>

              {status === "IMPORT" ? (
                <Button
                  onClick={() => setIsIntegrationDiscourseModalOpen(true)}
                  type="text"
                  style={{
                    color: "#3B82F6",
                    font: "normal 500 14px/20px Inter",
                    marginLeft: "auto",
                    borderRadius: "12px",
                  }}
                >
                  Import
                </Button>
              ) : (
                <SecondaryButtonOutlined
                  buttonText={
                    status === "IMPORTING" ? (
                      <div className="flex items-center">
                        <svg
                          style={{
                            marginRight: "4px",
                            animation:
                              "anti-clock-wise-rotation 2s infinite linear",
                          }}
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.66675 2.66654V5.99987H3.05475M13.2921 7.3332C13.1446 6.16178 12.6127 5.07238 11.7797 4.23572C10.9466 3.39907 9.85951 2.86245 8.68873 2.70996C7.51795 2.55748 6.32967 2.79775 5.3101 3.39311C4.29054 3.98848 3.49731 4.90529 3.05475 5.99987M3.05475 5.99987H6.00008M13.3334 13.3332V9.99987H12.9461M12.9461 9.99987C12.5029 11.0938 11.7094 12.0099 10.69 12.6047C9.67051 13.1996 8.48257 13.4395 7.31215 13.2871C6.14172 13.1347 5.05488 12.5985 4.22177 11.7624C3.38866 10.9263 2.85632 9.8375 2.70808 8.66654M12.9461 9.99987H10.0001"
                            stroke="#64748B"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Importing
                      </div>
                    ) : status === "IMPORTED" ? (
                      "Imported"
                    ) : (
                      <div className="text-red-500">Failed.Try Again.</div>
                    )
                  }
                  style={{
                    cursor: status === "IMPORTED" ? "not-allowed" : "pointer",
                  }}
                  clickHandler={() => {
                    if (status !== "IMPORTED")
                      setIsIntegrationDiscourseModalOpen(true);
                  }}
                />
              )}
            </div>
            <div className="text-xs font-semibold text-gray-600 mt-5">
              NOTIFICATIONS
            </div>
            <div
              style={{
                border: "1px solid #E5E7EB",
                borderRadius: "12px",
                display: "flex",
                padding: "16px",
                gap: "12px",
                alignItems: "center",
              }}
            >
              <BellIconPallet />
              <div
                className="mr-auto"
                style={{ color: "#1F2937", font: "normal 600 14px/20px Inter" }}
              >
                Discord Notifications
                <div
                  style={{
                    marginTop: "4px",
                    color: "#9CA3AF",
                    font: "normal 500 12px/16px Inter",
                  }}
                >
                  Get notified on a dedicated channel for your activities here
                </div>
              </div>

              {isDiscordConnected ? (
                <Switch
                  disabled={daoDataLoading || !isDiscordConnected}
                  checked={isDiscordNotificationsEnabled}
                  onChange={(flag) => {
                    toggleDiscordNotifications(flag);
                  }}
                  style={{ marginLeft: "auto" }}
                />
              ) : (
                <Button
                  onClick={() =>
                    window.open(
                      `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_ENDPOINT === "development"
                        ? "1046753505026785401"
                        : "1014817083139301417"
                      }&redirect_uri=${encodeURIComponent(window.location.origin) +
                      "/app/redirect/discordIntgeration"
                      }&response_type=code&state=${btoa(
                        JSON.stringify({ daoId, from: "onboarding" })
                      )}&from=${btoa("onboarding")}&scope=identify%20guilds`,
                      "_self"
                    )
                  }
                  type="text"
                  style={{
                    color: "#3B82F6",
                    font: "normal 500 14px/20px Inter",
                    marginLeft: "auto",
                    borderRadius: "12px",
                  }}
                >
                  Connect
                </Button>
              )}
            </div>
          </div>
        )}
        <DiscourseIntegrationModal
          status={status}
          daoId={daoId}
          reload={() => {
            reload();
            refetchStatus();
          }}
          daoDetails={daoDetails}
          isOpen={isIntegrationDiscourseModalOpen}
          setIsOpen={setIsIntegrationDiscourseModalOpen}
        />
        <AntModal
          visible={isIntegrationSnapshotModalOpen}
          footer={null}
          onCancel={() => setIsIntegrationSnapshotModalOpen(false)}
          className="snapshot-modal"
        >
          <SnapshotIntegrationModal
            daoId={daoId}
            reload={reload}
            isOpen={isIntegrationSnapshotModalOpen}
            onSubmit={() => {
              setIsIntegrationSnapshotModalOpen(false);
            }}
          />
        </AntModal>
      </Col>
    </Fragment>
  );
}

export default Integrations;

import {
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

type Props = {
  onLike: () => Promise<any>;
  onDislike: () => Promise<any>;
  onUndoLike: () => Promise<any>;
  onUndoDislike: () => Promise<any>;
  likeCount: number;
  dislikeCount: number;
  currentUserVote: "LIKE" | "DISLIKE" | undefined;
};

const LikeDislike = ({
  dislikeCount,
  likeCount,
  onDislike,
  onLike,
  onUndoDislike,
  onUndoLike,
  currentUserVote,
}: Props) => {
  const [currentUserLiked, setCurrentUserLiked] = useState(
    currentUserVote === "LIKE"
  );
  const [currentUserDisliked, setCurrentUserDisliked] = useState(
    currentUserVote === "DISLIKE"
  );

  const { mutate: handleLike } = useMutation({
    mutationFn: () => {
      setCurrentUserLiked(true);
      return onLike();
    },
    onError: (error) => {
      setCurrentUserLiked((prev) => prev && false);
      console.error(error);
      toast.error("Failed to like");
    },
  });

  const { mutate: handleDislike } = useMutation({
    mutationFn: () => {
      setCurrentUserDisliked(true);
      return onDislike();
    },
    onError: (error) => {
      setCurrentUserDisliked((prev) => prev && false);
      console.error(error);
      toast.error("Failed to dislike");
    },
  });

  const { mutate: handleUndoLike } = useMutation({
    mutationFn: () => {
      setCurrentUserLiked(false);
      return onUndoLike();
    },
    onError: (error) => {
      setCurrentUserLiked((prev) => !prev && true);
      console.error(error);
      toast.error("Failed to unlike");
    },
  });

  const { mutate: handleUndoDislike } = useMutation({
    mutationFn: () => {
      setCurrentUserDisliked(false);
      return onUndoDislike();
    },
    onError: (error) => {
      setCurrentUserDisliked((prev) => !prev && true);
      console.error(error);
      toast.error("Failed to undo dislike");
    },
  });

  useEffect(() => {
    if (currentUserVote) {
      setCurrentUserLiked(currentUserVote === "LIKE");
      setCurrentUserDisliked(currentUserVote === "DISLIKE");
    } else {
      setCurrentUserLiked(false);
      setCurrentUserDisliked(false);
    }
  }, [currentUserVote]);

  const handleLikeBtnClick = () => {
    if (currentUserDisliked) handleUndoDislike();
    if (!currentUserLiked) handleLike();
    if (currentUserLiked) handleUndoLike();
  };

  const handleDislikeBtnClick = () => {
    if (currentUserLiked) handleUndoLike();
    if (!currentUserDisliked) handleDislike();
    if (currentUserDisliked) handleUndoDislike();
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        alignItems: "center",
        border: "1px solid lightgrey",
        borderRadius: "32px",
        padding: "8px 12px",
      }}
    >
      <button
        onClick={handleLikeBtnClick}
        style={{
          display: "flex",
          alignItems: "center",
          background: "none",
          border: "none",
          gap: "4px",
          color: currentUserLiked ? "green" : "#6B7280",
          fontSize: "14px",
          cursor: "pointer",
        }}
      >
        <HandThumbUpIcon width={16} strokeWidth={2} />
        {likeCount +
          (currentUserLiked ? 1 : 0) -
          (currentUserVote === "LIKE" ? 1 : 0)}
      </button>
      <button
        onClick={handleDislikeBtnClick}
        style={{
          display: "flex",
          alignItems: "center",
          background: "none",
          border: "none",
          gap: "4px",
          color: currentUserDisliked ? "red" : "#6B7280",
          fontSize: "14px",
          cursor: "pointer",
        }}
      >
        <HandThumbDownIcon width={16} strokeWidth={2} />
        {dislikeCount +
          (currentUserDisliked ? 1 : 0) -
          (currentUserVote === "DISLIKE" ? 1 : 0)}
      </button>
    </div>
  );
};

export default LikeDislike;

import { StyledCard } from "../BountyFeed";
import { Text } from "../Profile/DaoSection";
import { Avatar, Dropdown, Menu } from "antd";
import moment from "moment";
import { useNavigate } from "react-router";
import dummyAvatar from ".././../assets/images/login/defaultUser.jpeg";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { togglePinProposal } from "../../utils/pin";
import { useDaoContext } from "../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { PinIcon } from "../Icones";
import ProfileCardPopup from "../Common/ProfileCard";
import { getTruncatedAddress } from "../../utils";

export const getTrunckedString = (text, length) => {
  const res = text ? text.substring(0, length) : "";
  const append = text?.length > length ? "..." : "";
  return res + append;
};
const ProposalCard = ({ proposal, canEdit = false, height = "" }) => {
  let navigate = useDaoLensNavigate();
  const { daoDetails, daoId } = useDaoContext();
  console.log(proposal);

  return (
    <StyledCard
      style={{ borderRadius: "16px", height }}
      height={height}
      onClick={() => {
        navigate(
          `/app/dao/${proposal?.daoName}/${proposal.daoId}/proposal/${proposal.id}`
        );
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ProfileCardPopup
          userId={proposal?.userDetails ? proposal?.userDetails.id : null}
        >
          <div
            onClick={() => navigate(`/app/profile/${proposal?.userDetails.id}`)}
          >
            <Avatar
              size={16}
              src={
                proposal?.userDetails
                  ? proposal?.userDetails?.profilePicture
                  : dummyAvatar
              }
              style={{ marginRight: "4px" }}
            />
           {proposal?.creatorAddress?  <Text style={{ font: "normal 500 12px/20px Inter" }}>
              {getTruncatedAddress(proposal?.creatorAddress)}
            </Text>:null}
          </div>
        </ProfileCardPopup>
        <div
          style={{
            font: "normal 500 12px/20px Inter",
            color: "#64748B",
            display: "flex",
            alignItems: "center",
            columnGap: "12px",
          }}
        >
          {proposal?.status === "active" && (
            <>{moment(proposal?.endDate).fromNow(true)} Left</>
          )}
          {proposal?.status === "pending" && (
            <>{moment(proposal?.startDate).fromNow(true)} Left</>
          )}
          <div
            style={{
              display: "inline-block",
              background: proposal?.status === "active" ? "#22C55E" : "#6875F5",
              color: "white",
              padding: "4px 12px",
              borderRadius: "18px",
            }}
          >
            {proposal?.status === "active"
              ? "Active"
              : proposal?.status === "pending"
              ? "Pending"
              : "Closed"}
          </div>
          {/* {proposal.pinned && <PinIcon />} */}
          {canEdit && (
            <Dropdown
              overlay={
                <Menu style={{ borderRadius: "8px" }}>
                  <Menu.Item
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      togglePinProposal(daoId, proposal.id, !proposal.pinned);
                    }}
                  >
                    <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
                    {proposal.pinned ? "Unpin" : "Pin"}
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <EllipsisHorizontalIcon
                width={"20px"}
                style={{ cursor: "pointer" }}
              />
            </Dropdown>
          )}
        </div>
      </div>
      <div
        style={{
          font: "normal 600 16px/24px Inter",
          color: "#1A202E",
          marginTop: "12px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          whiteSpace: "pre-wrap",
          overflowWrap: "break-word",
        }}
      >
        {getTrunckedString(proposal?.title, 150)}
        <div
          style={{
            font: "normal 400 14px/24px Inter",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
        >
          {getTrunckedString(proposal?.description, 150)}
        </div>
      </div>
    </StyledCard>
  );
};

export default ProposalCard;

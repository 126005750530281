import React from "react";
import { MANAGER_TUTORIALS } from "../../constants/DiscoverPage";
import useWindowSize from "../../hooks/useWindowSize";
import { postMixpanel } from "../../utils";
import styled from "styled-components";
import HorizontalScrollContainer, {
  HorizontalScrollItem,
} from "./HorizontalScrollContainer";
const StyledDiv = styled.div`
  height: 100%;
  transition: 0.2s;

  &:hover {
    box-shadow: 0px 10px 15px -4px rgba(0, 0, 0, 0.06),
      0px 4px 6px -4px rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`;
const Tutorials = () => {
  const { isMobile } = useWindowSize();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        position: "relative",
        background: "white",
        padding: "24px",
        borderRadius: "24px",
      }}
    >
      <h2
        style={{
          fontSize: "20px",
          color: "#1A202E",
          margin: 0,
          fontWeight: "600",
        }}
      >
        Learn how to go around swiftly! 💨
      </h2>
      <HorizontalScrollContainer>
        {MANAGER_TUTORIALS.map((tutorial, index) => (
          <HorizontalScrollItem
            key={tutorial.link}
            width={isMobile ? "80%" : "33.3%"}
            onClick={() =>
              postMixpanel("track", "discover_tutorial_card_click")
            }
          >
            <StyledDiv
              style={{
                borderRadius: "12px",
                border: "1px solid #e5e7eb",
                background: "white",
              }}
            >
              <a
                href={tutorial.link}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "flex",
                  flexDirection: "column",

                  height: "100%",
                }}
              >
                <img
                  src={tutorial.image}
                  alt={tutorial.title}
                  style={{
                    objectFit: "cover",
                    objectPosition: "50% 0%",
                    height: "120px",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    width: "100%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    gap: "12px",
                  }}
                >
                  <h3
                    style={{ margin: 0, color: "#111827", fontWeight: "600" }}
                  >
                    {tutorial.title}
                  </h3>
                  <p
                    style={{
                      margin: 0,
                      color: "#6B7280",
                      fontSize: "14px",
                      lineHeight: "20px",
                    }}
                  >
                    {tutorial.description}
                  </p>
                </div>
              </a>
            </StyledDiv>
          </HorizontalScrollItem>
        ))}
      </HorizontalScrollContainer>
    </div>
  );
};

export default Tutorials;

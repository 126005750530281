import React, { useContext } from "react";
import { Button, Col, Select, Typography } from "antd";
import {
  ArrowDownIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import styled from "styled-components";
import PropTypes from "prop-types";
import IconWrapper from "./IconWrapper";
import { UserContext } from "../layouts/app/UserContext";
import { DaoContext } from "../layouts/app/DaoContext";
import { StyledSelect } from "./CommentsComponents/CommentSection";
const { Text } = Typography;
const { Option } = Select;
const WrapperDiv = styled.div`
  padding: 10px 20px;
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  // border: 1px solid #f4f2f2;
  box-shadow: 0px 4px 16px rgba(132, 148, 160, 0.12);
  -webkit-box-shadow: 0px 4px 16px rgba(132, 148, 160, 0.12);
  -moz-box-shadow: 0px 4px 16px rgba(132, 148, 160, 0.12);
  border-radius: 16px 16px 0px 0px;

  & .hash {
    color: #bcc5d3;
    font-size: 16px;
  }
  > button {
    border-radius: 0.6rem;
  }
`;

const NewPostLabel = ({
  content,
  onNewPostHandler,
  canEdit,
  sortBy,
  setSortBy,
  sortingTypeList,
}) => {
  const { isAdmin } = useContext(DaoContext);
  return (
    <>
      <WrapperDiv>
        <Text strong>
          <span className="hash">#</span> {content}
        </Text>
        {canEdit || isAdmin ? (
          <Button
            className="create-post-button"
            type="primary"
            icon={
              <IconWrapper>
                <PlusIcon />
              </IconWrapper>
            }
            onClick={onNewPostHandler}
          >
            New Post
          </Button>
        ) : null}
      </WrapperDiv>
      <div
        style={{
          background: "#F9FAFB",
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          borderRadius: " 0px 0px 16px 16px",
        }}
      >
        <span style={{ color: "#6B7280", fontSize: "12px", fontWeight: "500" }}>
          Sort by:
        </span>
        <StyledSelect
          suffixIcon={
            <IconWrapper style={{ color: "#6b7280" }}>
              <ChevronDownIcon width={"12px"} strokeWidth={"3px"} />
            </IconWrapper>
          }
          style={{ minWidth: "75px" }}
          dropdownStyle={{ borderRadius: "10px", padding: "0px" }}
          listItemHeight={"32px"}
          bordered={false}
          value={sortBy}
          onChange={(value) => {
            setSortBy(value);
          }}
        >
          {Object.keys(sortingTypeList).map((ele) => {
            return <Option value={ele}>{ele}</Option>;
          })}
        </StyledSelect>
      </div>
    </>
  );
};

NewPostLabel.proptype = {
  content: PropTypes.string.isRequired,
  onNewPostHandler: PropTypes.func.isRequired,
};

export default NewPostLabel;

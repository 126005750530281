import { Typography } from "antd";
import pdfIcon from "../../assets/iconSvg/pdf.svg";
import downloadIcon from "../../assets/iconSvg/downloadIcon.svg";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { XCircleIcon } from "@heroicons/react/24/solid";

const Attachments = ({ attachments, onDelete = null }) => {
  const [showAttachmentLink, setShowAttachmentLink] = useState(false);
  return (
    <div className="view-bounty-submission-modal__attachment--container">
      {attachments?.map((singleAttachment, index) => {
        return (
          <div style={{ position: "relative" }} key={index}>
            {onDelete && (
              <button
                onClick={() => onDelete(singleAttachment)}
                style={{
                  position: "absolute",
                  top: 5,
                  left: 5,
                  cursor: "pointer",
                  borderRadius: "999px",
                  display: "flex",
                  justifyItems: "center",
                  justifyContent: "center",
                  border: "none",
                  padding: 0,
                }}
              >
                <XCircleIcon width={20} />
              </button>
            )}
            {showAttachmentLink ? (
              <Typography.Link href={singleAttachment} target="_blank">
                <img
                  src={singleAttachment}
                  alt="attachment"
                  style={{
                    width: "130px",
                    height: "130px",
                    marginBottom: "12px",
                    objectFit: "cover",
                    borderRadius: "12px",
                    marginRight: "10px",
                  }}
                />
              </Typography.Link>
            ) : (
              <Document
                file={singleAttachment}
                onLoadSuccess={() => {}}
                loading={<span>Loading file</span>}
                onLoadError={() => {
                  setShowAttachmentLink(true);
                }}
                className="view-bounty-submission-modal__attachment"
              >
                <Page pageNumber={1} />
                <div
                  className="view-bounty-submission-modal__attachment--title"
                  onClick={() => {
                    window.open(singleAttachment);
                  }}
                >
                  <img src={pdfIcon} alt="" />
                  <span>File {index + 1}</span>
                  <img src={downloadIcon} alt="" />
                </div>
              </Document>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Attachments;

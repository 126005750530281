import { Button } from "antd";
import { useNavigate } from "react-router";
import moment from "moment";
import { ReactComponent as ProposalIcon } from "../../../assets/images/search/proposal.svg";
import SearchCard from "../SearchCard";
import IconWrapper from "../../IconWrapper";
import { CloseCircleOutlined } from "@ant-design/icons";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
const Proposal = ({ data, handleClose, onClick, handleDeleteLinkedItem }) => {
  const navigate = useDaoLensNavigate();
  const { id } = data;
  const bountyTitle = data?.title.substring(0, 56);

  return (
    <SearchCard
      isLinkedItem={!!handleDeleteLinkedItem}
      onClick={() => {
        if (onClick) {
          if (handleDeleteLinkedItem) {
            return;
          } else onClick({ data });
        } else {
          navigate(`/app/dao/${data.daoName}/${data.daoId}/proposal/${id}`);
          handleClose();
        }
      }}
    >
      {" "}
      {handleDeleteLinkedItem ? (
        <Button
          className="borderless-button"
          type="text"
          shape="circle"
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
          icon={
            <IconWrapper width={"initial"}>
              <CloseCircleOutlined />
            </IconWrapper>
          }
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteLinkedItem(data.id);
          }}
        ></Button>
      ) : null}
      <ProposalIcon />
      <div
        style={{
          fontWeight: 500,
          lineHeight: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "#64748B",
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            marginBottom: "4px",
          }}
        >
          <div
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            Proposal
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            <div
              style={{
                width: "8px",
                height: "8px",
                background: data.status === "active" ? "#0E9F6E" : "#F05252",
                display: "inline-block",
                marginRight: "8px",
              }}
            ></div>
            {data.status}
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            <div
              style={{ display: "inline-block" }}
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
                navigate(`/app/dao/${data.dao_id}`);
              }}
            >
              {data?.parentDao ? `${data?.parentDao?.name}/` : ""}
              {data.daoName}
            </div>
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            <div
              style={{ display: "inline-block" }}
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
                navigate(`/app/profile/${data?.user_id}`);
              }}
            >
              {data?.userName}
            </div>
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            {moment(data?.createdAt).format("DD MMM")}
          </div>
        </div>

        {bountyTitle}
        {data?.title.length > 56 ? "..." : ""}

        <div>
          {data?.amount && (
            <div
              style={{
                marginTop: "4px",
                color: "#6F7E93",
                fontSize: "12px",
                padding: "0px 10px",
                fontWeight: 600,
              }}
            >
              {data?.amount}
            </div>
          )}
        </div>
      </div>
    </SearchCard>
  );
};

export default Proposal;

import { TwitterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Progress, Radio, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import customAxios from "../../api";
import { Modal } from "../../styles/ModalStyles";
function CourseCompletionVerifyModal({
  isModalOpen = false,
  questions,
  content,
  handleModalClose,
  courseDetails,
  fetchCourseDetailsService
}) {
  function areEqual(array1, array2) {
    if (array1.length === array2.length) {
      return array1.every((element) => {
        if (array2.includes(element)) {
          return true;
        }

        return false;
      });
    }

    return false;
  }

  const handleTwitterShare = () => {
    let url = "";

    url = window.location.href;

    const anchor = document.createElement("a");
    anchor.href = `http://twitter.com/share?text=Gm folks⚡️%0AJust completed an amazing course ${courseDetails.name} on Dao Manager. You can check it out here: ${url}`;
    anchor.target = "_blank";
    anchor.click();
  };

  const handleSubmitQuiz = (state) => {
    const finalArr = questions.map((ele, questionIndex) => {
      return {
        questionName: ele.questionName,
        answer: state[questionIndex],
        isCorrect: areEqual(ele.correctAns, state[questionIndex]),
      };
    });

    // customAxios
    //   .post("/courses/submit/quiz", { courseId: courseId, quiz: finalArr })
    //   .then((res) => {
    //     if (res.data.success) {

    //     }
    //   })
    //   .catch((err) => {
    //     toast.error("Some error occured. Please try after sometime.");
    //   });
    setMarksPercent(prev => {
      let res = (
        (finalArr.filter((ele) => ele.isCorrect).length /
          questions.length) *
        100
      ).toFixed(0)
      if (res > 75) {
        customAxios.post("/courses/detail/mark", { courseDetailsId: content.id, courseId: courseDetails.id }).then(() => {
          fetchCourseDetailsService()
        });
      }
      return res;
    }
    );
    setCurrQuestionIndex((prev) => prev + 1);
  };
  const [marksPercent, setMarksPercent] = useState(null);
  const [state, setState] = useState([]);
  useEffect(() => {
    setState(questions.map((ele, questionIndex) => []));
  }, [questions]);
  const [currQuestionIndex, setCurrQuestionIndex] = useState(0);
  return (
    <Modal
      width={marksPercent ? "520px" : "720px"}
      bodyStyle={{
        padding: "16px",
        minHeight: marksPercent ? "320px" : "500px",
      }}
      open={isModalOpen}
      visible={isModalOpen}
      centered
      destroyOnClose
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={
        !marksPercent
          ? [
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {currQuestionIndex < questions?.length - 1 ? (
                <div>
                  <Button
                    type="primary"
                    disabled={!state[currQuestionIndex]?.length}
                    style={{ background: "#3B82F6", borderRadius: "12px" }}
                    onClick={() => setCurrQuestionIndex((prev) => prev + 1)}
                  >
                    Next
                  </Button>
                </div>
              ) : (
                <Button
                  type="primary"
                  disabled={!state[currQuestionIndex]?.length}
                  style={{ background: "#3B82F6", borderRadius: "12px" }}
                  onClick={() => handleSubmitQuiz(state)}
                >
                  Submit Quiz
                </Button>
              )}
            </div>,
          ]
          : null
      }
    >
      {!marksPercent ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h2>Pass the quiz to test your learnings from the course</h2>
          <h4
            style={{
              background: "#F4F2F2",
              borderRadius: "8px",
              display: "flex",
              width: "fit-content",
              padding: "4px 8px",
              color: "#64748B",
            }}
          >
            Passing Criteria: 75%
          </h4>

          {questions.map((singleQuiz, questionIndex) => {
            if (questionIndex === currQuestionIndex)
              return (
                <div
                  className="course-verification-admin-modal__single-quiz"
                  style={{ marginTop: "20px" }}
                >
                  <div className="course-verification-admin-modal__single-quiz--top">
                    <div
                      style={{
                        color: "black",
                        display: "flex",
                        alignItems: "center",
                        columnGap: "4px",
                      }}
                    >
                      <h3 style={{ marginBottom: "0px" }}>
                        {questionIndex + 1} .{" "}
                      </h3>
                      <h3 style={{ marginBottom: "0px" }}>
                        {singleQuiz.questionName}
                      </h3>
                    </div>
                  </div>
                  {singleQuiz.type === "SINGLE_CHOICE" ? (
                    <Radio.Group
                      onChange={(ev) =>
                        setState((prev) => {
                          const currAnswers = prev.slice();
                          currAnswers[questionIndex] = [ev.target.value];
                          return currAnswers;
                        })
                      }
                      className="course-verification__option-group"
                    >
                      <Space direction="vertical">
                        {singleQuiz.options.map((singleOption, optionIndex) => {
                          return (
                            <Radio
                              value={singleOption}
                              disabled={!Boolean(singleOption)}
                              style={{ width: "100%", position: "relative" }}
                            >
                              <h4>{singleOption}</h4>
                              <span
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  color: "#64748B",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                {/* {singleOption.isCorrect ? "Correct" : ""} */}
                              </span>
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  ) : (
                    <Checkbox.Group
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                      onChange={(ev) => {
                        setState((prev) => {
                          const currAnswers = prev.slice();
                          currAnswers[questionIndex] = ev;
                          return currAnswers;
                        });
                      }}
                      className="course-verification__option-group"
                    >
                      {singleQuiz.options.map((singleOption, optionIndex) => {
                        return (
                          <Checkbox
                            value={singleOption}
                            disabled={!Boolean(singleOption)}
                            style={{ width: "100%", position: "relative" }}
                          >
                            <h4>{singleOption}</h4>
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  )}
                </div>
              );
            else return null;
          })}
          <Row style={{ marginTop: "20px" }}>
            <h4 style={{ marginBottom: "0px", color: "#64748B" }}>{`Question ${currQuestionIndex + 1
              }/${questions.length}`}</h4>
            <Progress
              status={"active"}
              strokeColor={
                "linear-gradient(0deg, #5850EC 0%, rgba(86, 77, 255, 0.23) 145.83%)"
              }
              showInfo={false}
              percent={Number(
                (currQuestionIndex + 1 / questions.length) * 100
              ).toFixed(0)}
            ></Progress>
          </Row>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <h1 style={{ fontSize: "75px", height: "100%" }}>
            {marksPercent > 75 ? "🥳" : "😕"}
          </h1>
          {marksPercent > 75 ? (
            <h2>Congratulations! You have completed the course</h2>
          ) : (
            <h2>
              Sorry, You didn't pass the test
              <br />
              Please try again
            </h2>
          )}
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <h4
              style={{
                background: "#F4F2F2",
                borderRadius: "8px",
                display: "flex",
                width: "fit-content",
                padding: "4px 8px",
                color: "#64748B",
              }}
            >
              Your score: {marksPercent}%
            </h4>
          </div>
          {marksPercent < 75 ? (
            <Row
              style={{
                justifyContent: "flex-end",
                columnGap: "8px",
                marginTop: "32px",
              }}
            >
              <Button
                style={{ borderRadius: "12px", height: "40px" }}
                onClick={() => handleModalClose()}
              >
                Cancel
              </Button>{" "}
              <Button
                type="primary"
                style={{
                  background: "#3B82F6",
                  borderRadius: "12px",
                  height: "40px",
                }}
                onClick={() => {
                  setCurrQuestionIndex(0);
                  setMarksPercent(null);
                }}
              >
                Retake Quiz
              </Button>
            </Row>
          ) : (
            <Row
              style={{
                justifyContent: "flex-end",
                columnGap: "8px",
                marginTop: "32px",
              }}
            >
              <Button
                icon={<TwitterOutlined />}
                onClick={() => {
                  handleTwitterShare();
                }}
                type="primary"
                style={{
                  height: "40px",
                  display: "flex",
                  borderRadius: "12px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#3B82F6",
                }}
              >
                Share on Twitter
              </Button>
            </Row>
          )}
        </div>
      )}
    </Modal>
  );
}

export default CourseCompletionVerifyModal;

import { Switch } from "antd";

import { useEffect } from "react";
import ProposalsIcon from "../../images/proposal-icon.svg";
import ProjectsIcon from "../../images/project-icon.svg";
import DiscussionsIcon from "../../images/discussion-icon.svg";
import DaoBounty from "../../images/bounty-icon.svg";
import CourseIcon from "../../images/course-icon.svg";
import EventsIcon from "../../assets/images/events/events.svg";
import DocumentIcon from "../../assets/images/documents/document.svg";
const Visibility = ({
  daoDetails: {
    discussionDisable,
    bountyDisable,
    proposalsDisable,
    projectsDisable,
    coursesDisable,
    eventsDisable,
    documentsDisable,
    spacesDisable,
  },
  entities,
  setEntities,
}) => {
  useEffect(() => {
    setEntities([
      {
        label: "Discussions",
        icon: "💬",
        viewicon: DiscussionsIcon,
        background: "#EFF6FF",
        name: "discussion",
        value: discussionDisable,
        initialValue: discussionDisable,
        description:
          "For topic specific communication amongst your community members",
      },
      {
        label: "Bounties",
        icon: "💰",
        viewicon: DaoBounty,
        background: "#F0FDFA",
        name: "bounty",
        value: bountyDisable,
        initialValue: bountyDisable,
        description:
          "Create a bounty board for contributors, evaluate and pay ",
      },

      {
        label: "Projects",
        background: "#FFFBEB",
        viewicon: ProjectsIcon,
        icon: "👨‍💻",
        name: "projects",
        value: projectsDisable,
        initialValue: projectsDisable,
        description: "A one stop shop for all your project management needs",
      },
      {
        label: "Courses",
        background: "#F5F3FF",
        viewicon: CourseIcon,
        icon: "📚",
        name: "courses",
        value: coursesDisable,
        initialValue: coursesDisable,
        description: "Create lessons and quizzes for your community",
      },
      {
        label: "Documents",
        background: "#F5F3FF",
        viewicon: DocumentIcon,
        icon: "📄",
        name: "documents",
        value: documentsDisable,
        initialValue: documentsDisable,
        description: "A resource repository including relevant documents",
      },
      {
        label: "Proposals",
        icon: "👩🏻‍⚖️",
        viewicon: ProposalsIcon,
        background: "#EEF2FF",
        name: "proposals",
        value: proposalsDisable,
        initialValue: proposalsDisable,
        description:
          "Create, discuss, and vote on proposals with in-app Snapshot integration",
      },
      {
        label: "Events",
        icon: "🎪",
        viewicon: EventsIcon,
        background: "#FFF1F2",
        name: "events",
        value: eventsDisable,
        initialValue: eventsDisable,
        description: "Hub for tracking organising all your events",
      },
      {
        label: "Spaces",
        icon: "🫧",
        viewicon: EventsIcon,
        background: "#F5F3FF",
        name: "events",
        value: spacesDisable,
        initialValue: spacesDisable,
        description: "Exchange ideas on various topics in dedicated channels",
      },
    ]);
  }, [
    discussionDisable,
    bountyDisable,
    proposalsDisable,
    projectsDisable,
    coursesDisable,
    eventsDisable,
    documentsDisable,
    spacesDisable,
  ]);
  const updateVisiblity = (name, flag) => {
    setEntities((prev) => {
      return prev.map((entity) =>
        entity.name === name ? { ...entity, value: flag } : entity
      );
    });
    // setLocalLoading(true);
    // customAxios
    //   .post("/settings/visibility/update", { daoId, [name]: flag })
    //   .then(() => {
    //     setEntities((prev) => {
    //       return prev.map((entity) =>
    //         entity.name === name ? { ...entity, value: flag } : entity
    //       );
    //     });
    //     toast.success("Visibility updated Successfully");
    //   })
    //   .finally(() => {
    //     setLocalLoading(false);
    //   });
  };
  return (
    <div
      style={{
        gap: "12px",
        display: "flex",
        flexDirection: "column",
        height: "600px",
      }}
    >
      {entities
        .filter((ele) => !ele.initialValue)
        .map((item, index) => (
          <div
            style={{
              borderBottom:
                index !== entities.filter((ele) => !ele.value).length - 1
                  ? "1px solid #E5E7EB"
                  : "",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
              font: "normal 600 14px/20px Inter",
              color: "#1F2937",
            }}
          >
            <div
              style={{
                background: item.background,
                width: "40px",
                aspectRatio: 1,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                justifyContent: "center",
              }}
            >
              {item.icon}
            </div>
            <div>
              {item.label}
              <div
                style={{
                  font: "normal 500 12px/16px Inter",
                  color: "#9CA3AF",
                  marginTop: "4px",
                }}
              >
                {item.description}
              </div>
            </div>
            <Switch
              checked={!item.value}
              style={{ marginLeft: "auto" }}
              onChange={(flag) => {
                updateVisiblity(item.name, !flag);
              }}
            />
          </div>
        ))}
      <div
        style={{
          color: "#6B7280",
          font: "normal 500 14px/20px Inter",
          padding: "8px 16px",
          background: "#F9FAFB",
          borderRadius: "8px",
          marginTop: "4px",
        }}
      >
        Other modules
      </div>
      {entities
        .filter((ele) => ele.initialValue)

        .map((item, index) => (
          <div
            style={{
              borderBottom:
                index !== entities.filter((ele) => ele.value).length - 1
                  ? "1px solid #E5E7EB"
                  : "",
              padding: "16px",
              display: "flex",
              alignItems: "center",
              gap: "12px",
              font: "normal 600 14px/20px Inter",
              color: "#1F2937",
            }}
          >
            <div
              style={{
                background: item.background,
                width: "40px",
                aspectRatio: 1,
                borderRadius: "50%",
                display: "flex",
                fontSize: "18px",

                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {item.icon}
            </div>
            <div>
              {item.label}
              <div
                style={{
                  font: "normal 500 12px/16px Inter",
                  color: "#9CA3AF",
                  marginTop: "4px",
                }}
              >
                {item.description}
              </div>
            </div>
            <Switch
              checked={!item.value}
              style={{ marginLeft: "auto" }}
              onChange={(flag) => {
                updateVisiblity(item.name, !flag);
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default Visibility;

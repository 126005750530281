import { Button, Checkbox, Progress, Radio, Row, Space } from "antd";
import React, { useState } from "react";
import { Modal } from "../../../styles/ModalStyles";

function VerifyQuiz({
  isModalOpen,
  handleModalClose,
  questions,
  handleSubmitQuiz,
}) {
  const [currQuestionIndex, setCurrQuestionIndex] = useState(0);
  const [state, setState] = useState([]);
  return (
    <Modal
      width={"720px"}
      bodyStyle={{
        padding: "16px",
        minHeight: "500px",
      }}
      open={isModalOpen}
      visible={isModalOpen}
      centered
      destroyOnClose
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={[
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {currQuestionIndex < questions?.length - 1 ? (
            <div>
              <Button
                type="primary"
                disabled={!state[currQuestionIndex]?.length}
                style={{ background: "#3B82F6", borderRadius: "12px" }}
                onClick={() => setCurrQuestionIndex((prev) => prev + 1)}
              >
                Next
              </Button>
            </div>
          ) : (
            <Button
              type="primary"
              disabled={!state[currQuestionIndex]?.length}
              style={{ background: "#3B82F6", borderRadius: "12px" }}
              onClick={() => handleSubmitQuiz(state)}
            >
              Submit Quiz
            </Button>
          )}
        </div>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2>Pass this quiz to claim the reward </h2>
        <h4
          style={{
            background: "#F4F2F2",
            borderRadius: "8px",
            display: "flex",
            width: "fit-content",
            padding: "4px 8px",
            color: "#64748B",
          }}
        >
          Passing Criteria: 100%
        </h4>

        {questions.map((singleQuiz, questionIndex) => {
          if (questionIndex === currQuestionIndex)
            return (
              <div
                className="course-verification-admin-modal__single-quiz"
                style={{ marginTop: "20px" }}
              >
                <div className="c">
                  <div
                    style={{
                      color: "black",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "4px",
                    }}
                  >
                    <h3 style={{ marginBottom: "0px" }}>
                      {questionIndex + 1} .{" "}
                    </h3>
                    <h3 style={{ marginBottom: "0px" }}>
                      {singleQuiz.questionName}
                    </h3>
                  </div>
                </div>
                {singleQuiz.type === "SINGLE_CHOICE" ? (
                  <Radio.Group
                    onChange={(ev) =>
                      setState((prev) => {
                        const currAnswers = prev.slice();
                        currAnswers[questionIndex] = [ev.target.value];
                        return currAnswers;
                      })
                    }
                    className="course-verification__option-group"
                  >
                    <Space direction="vertical">
                      {singleQuiz.options.map((singleOption, optionIndex) => {
                        return (
                          <Radio
                            value={singleOption}
                            disabled={!Boolean(singleOption)}
                            style={{ width: "100%", position: "relative" }}
                          >
                            <h4>{singleOption}</h4>
                            <span
                              style={{
                                position: "absolute",
                                right: 0,
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: "#64748B",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              {/* {singleOption.isCorrect ? "Correct" : ""} */}
                            </span>
                          </Radio>
                        );
                      })}
                    </Space>
                  </Radio.Group>
                ) : (
                  <Checkbox.Group
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                    }}
                    onChange={(ev) => {
                      setState((prev) => {
                        const currAnswers = prev.slice();
                        currAnswers[questionIndex] = ev;
                        return currAnswers;
                      });
                    }}
                    className="course-verification__option-group"
                  >
                    {singleQuiz.options.map((singleOption, optionIndex) => {
                      return (
                        <Checkbox
                          value={singleOption}
                          disabled={!Boolean(singleOption)}
                          style={{ width: "100%", position: "relative" }}
                        >
                          <h4>{singleOption}</h4>
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                )}
              </div>
            );
          else return null;
        })}
        <Row style={{ marginTop: "20px" }}>
          <h4 style={{ marginBottom: "0px", color: "#64748B" }}>{`Question ${currQuestionIndex + 1
            }/${questions.length}`}</h4>
          <Progress
            status={"active"}
            strokeColor={
              "linear-gradient(0deg, #5850EC 0%, rgba(86, 77, 255, 0.23) 145.83%)"
            }
            showInfo={false}
            percent={Number(
              (currQuestionIndex + 1 / questions.length) * 100
            ).toFixed(0)}
          ></Progress>
        </Row>
      </div>
    </Modal>
  );
}

export default VerifyQuiz;

import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Dropdown,
  Input,
  Menu,
  Skeleton,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import customAxios from "../../api";
import { DaoContext, useDaoContext } from "../../layouts/app/DaoContext";
import { postMixpanel, updateWalkmeStatus } from "../../utils";
import EmptyComponent from "../Common/EmptyComponent";
import OnboardingModal from "../Onboarding/OnboardingModal";
import CreateProjectModal from "./CreateProjectModal";
import ReactHtmlParser from "react-html-parser";

import { UserContext } from "../../layouts/app/UserContext";
import { PinIcon } from "../Icones";
import { togglePinProject } from "../../utils/pin";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import MobilePageTopNavbar from "../Common/MobilePageTopNavbar";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
const steps = [
  {
    target: ".discover-projects",
    disableBeacon: true,
    disableScrolling: true,
    title: "Discover Projects",
    content:
      "Use the board to find existing projects. You can search, filter and sort projects as per your requirements.",
  },
  {
    target: ".create-new-project",
    disableBeacon: true,
    disableScrolling: true,
    title: "Create New Project",
    content: "Click on button to start creating a new project.",
  },
  {
    target: ".setup-project",
    disableBeacon: true,
    disableScrolling: true,
    title: "Setup Project",
    content: "Provide Title and description to your project.",
  },
];

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 16px 20px;
    border-radius: 16px;
    transition: 0.2s;
    height: 100%;
  }

  .ant-card-body:hover {
    box-shadow: 0px 10px 15px -4px rgba(0, 0, 0, 0.06),
      0px 4px 6px -4px rgba(0, 0, 0, 0.03);
    cursor: pointer;
  }
`;

const ProjectPage = () => {
  const { walkMeStatus, reload } = useContext(UserContext);
  const [query, setQuery] = useState("");
  const [updater, setUpdater] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [startTour, setStartTour] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showEndingModal, setShowEndingModal] = useState(false);
  const { isAdmin } = useContext(DaoContext);
  const { isMobile } = useWindowSize();
  const { daoId, daoName, isSpace } = useDaoContext();

  useEffect(() => {
    if (!daoId) return;
    setLoading(true);
    customAxios
      .get(`/projects/fetch?daoId=${daoId}`)
      .then(({ data }) => {
        setProjects(data.res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [daoId, updater]);

  const filteredProjects = projects?.filter((project) =>
    project.title.toLowerCase().includes(query.toLocaleLowerCase())
  );
  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`settings/canview`, { daoId: daoId })
      .then((res) => {
        // setCanManageCourse(res.data.canView ? true : false);
        if (
          res.data.canView &&
          walkMeStatus &&
          walkMeStatus?.projectCreate === false
        )
          setShowOnboardingModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]);
  return (
    <div style={{ marginTop: 20 }}>
      {showOnboardingModal ? (
        <OnboardingModal
          step={"project-start"}
          showModal={showOnboardingModal}
          onClick={() => {
            updateWalkmeStatus({ projectCreate: true }, reload);
            postMixpanel("track", "started_walk_me_tour_for_create_project");

            setShowOnboardingModal(false);
            setStartTour(true);
          }}
          onClose={() => {
            setShowOnboardingModal(false);
          }}
        />
      ) : null}
      {showEndingModal ? (
        <OnboardingModal
          step={"project-end"}
          showModal={showEndingModal}
          onClick={() => {
            postMixpanel("track", "finished_walk_me_tour_for_create_project");

            setShowEndingModal(false);
          }}
          onClose={() => {
            setShowEndingModal(false);
          }}
        />
      ) : null}
      {/* {startTour ? (
        <WalkmeTour
          run={startTour}
          steps={steps}
          otherProps={{
            disableScrollParentFix: true,
            disableScrolling: true,
            continuous: true,
            disableBeacon: true,
            callback: (step) => {
              console.log({ step, target: step.step.target });
              if (
                step.step.target === ".create-new-project"
                // &&
                // step.action === "update"
              ) {
                setTimeout(() => {
                  setCreateModalVisible(true);
                }, 1000);
              }
              if (
                step.step.target === ".setup-project"
                // && step.lifecycle === "complete"
              ) {
                // setShowCourseVerificationAdminModal(true);
                // setTimeout(() => {
                // }, 1000);
              }
              if (step.status === "finished") {
                setShowEndingModal(true);
                setCreateModalVisible(false);
              }
            },
          }}
        />
      ) : null} */}
      {createModalVisible ? (
        <CreateProjectModal
          onSubmit={() => {
            setUpdater((prev) => !prev);
          }}
          isModalVisible={createModalVisible}
          handleCancel={() => setCreateModalVisible(false)}
        />
      ) : null}
      {(isMobile || isSpace) && <MobilePageTopNavbar />}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
          marginTop: isMobile ? "10px" : "0",
        }}
        className="discover-projects"
      >
        <Input
          allowClear
          placeholder="Search Projects"
          className="search-courses"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          prefix={
            <SearchOutlined
              style={{ color: "#94A3B8" }}
              className="site-form-item-icon"
            />
          }
          style={{
            padding: "8px 18px",
            borderRadius: "12px",
            borderColor: "#f0f0f0",
            fontWeight: "600",
          }}
        />
        {isAdmin && (
          <Button
            type="primary"
            className="create-new-project"
            style={{
              padding: "18px 21px",
              borderRadius: "12px",
              background: "#3B82F6",
              fontWeight: 600,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "12px",
            }}
            onClick={() => {
              postMixpanel("track", "clicked_create_new_project_button");

              setCreateModalVisible(true);
            }}
          >
            Create Project
          </Button>
        )}
      </div>
      {loading && <Skeleton active />}

      {!loading && filteredProjects.length === 0 && (
        <div style={{ background: "white", borderRadius: "16px" }}>
          <EmptyComponent
            text="No Projects available yet"
            subtext="Be sure to check here again in a while"
          />
        </div>
      )}
      {!loading &&
        filteredProjects.map((project) => (
          <ProjectCard
            daoId={daoId}
            daoName={daoName}
            isAdmin={isAdmin}
            project={project}
          />
        ))}
    </div>
  );
};

export const ProjectCard = ({ daoId, project, isAdmin, height = "" }) => {
  const navigate = useDaoLensNavigate();
  return (
    <StyledCard
      onClick={() => {
        navigate(`${project.id}`);
      }}
      style={{
        borderRadius: "16px",
        color: "#0F172A",
        marginBottom: "12px",
        height,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          style={{
            font: "normal 600 16px/24px Inter",
            marginBottom: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              font: "normal 500 12px/16px Inter",
              width: "24px",
              height: "24px",
              background: project.background,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1.5px solid #FFFFFF",
              borderRadius: "4px",
              boxShadow: " 0px 0px 3px rgba(25, 38, 72, 0.2)",
              marginRight: "8px",
            }}
          >
            {project.icon ? project.icon : "🗂"}
          </div>
          {project.title}
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              columnGap: "10px",
            }}
          >
            {project?.pinned && <PinIcon style={{ color: "#6366F1" }} />}
            {isAdmin ? (
              <Dropdown
                overlay={
                  <Menu style={{ borderRadius: "8px" }}>
                    <Menu.Item
                      onClick={(e) => {
                        e.domEvent.stopPropagation();
                        togglePinProject(daoId, project?.id, !project?.pinned);
                      }}
                    >
                      <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
                      {project?.pinned ? "Unpin" : "Pin"}
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <EllipsisHorizontalIcon
                  width={"20px"}
                  style={{ cursor: "pointer" }}
                />
              </Dropdown>
            ) : null}
          </div>
        </div>

        <Typography.Paragraph
          ellipsis={{
            rows: 2,
          }}
        >
          {ReactHtmlParser(project.description)}
        </Typography.Paragraph>
        <div
          style={{
            font: "normal 500 12px/16px Inter",
            color: "#64748B",
            marginTop: "auto",
          }}
        >
          {project.completedTasks} of {project.totalTasks} Tasks Done
          <div
            style={{
              height: "4px",
              background: "#E2E8F0",
              marginTop: "4px",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                width: `${
                  (project.completedTasks * 100) /
                  (project.totalTasks ? project.totalTasks : 1)
                }%`,
                height: "100%",
                background: "#3B82F6",
              }}
            ></div>
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default ProjectPage;

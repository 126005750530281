import { DatePicker, TimePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment-timezone";
import React, { Dispatch, SetStateAction } from "react";
import { placeTimePickerToInitialPosition } from "../../lib/utils/common";

type Props = {
  dateTime?: moment.Moment;
  setDateTime?: Dispatch<SetStateAction<moment.Moment>>;
  startAfterDate: moment.Moment;
  onChange?: (date: moment.Moment) => void;
  isTimeHidden?: boolean;
  timezone: string;
};

const DateTimePicker = ({
  dateTime,
  setDateTime,
  startAfterDate,
  onChange: onChangeExternal,
  isTimeHidden = false,
  timezone,
}: Props) => {
  const onChange = (
    _time: moment.Moment | undefined | null,
    _date: moment.Moment | undefined | null
  ) => {
    const timeIso = _time?.format();
    const dateIso = _date?.format();
    const newTime = `${dateIso?.split("T")[0]}T${timeIso?.split("T")[1]}`;

    setDateTime?.(moment(newTime));
    onChangeExternal?.(moment(newTime));
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    if (!startAfterDate) return false;
    return current && current < moment(startAfterDate).endOf("day");
  };

  return (
    <div style={{ display: "flex", gap: "12px", flexGrow: "1" }}>
      <DatePicker
        value={dateTime}
        defaultValue={dateTime}
        onChange={(e) => {
          onChange(dateTime, e?.tz(timezone));
        }}
        style={{ borderRadius: "12px", flexGrow: "1" }}
        disabledDate={disabledDate}
        allowClear={false}
      />
      {!isTimeHidden && (
        <TimePicker
          value={dateTime}
          defaultValue={dateTime}
          onChange={(e) => {
            onChange(e?.tz(timezone), dateTime);
          }}
          style={{ borderRadius: "12px", flexGrow: "1" }}
          use12Hours
          minuteStep={15}
          showSecond={false}
          format={"h:mm A"}
          allowClear={false}
          onPanelChange={() => {
            placeTimePickerToInitialPosition(225);
          }}
          onFocus={() => placeTimePickerToInitialPosition(225)}
          onOpenChange={() => placeTimePickerToInitialPosition(225)}
        />
      )}
    </div>
  );
};

export default DateTimePicker;

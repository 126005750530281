import { ReactComponent as SubmissionIcon } from "../../../assets/images/bountyPage/submission.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/images/bountyPage/twitter.svg";
import { ReactComponent as DiscordIcon } from "../../../assets/images/bountyPage/discord.svg";
import { ReactComponent as TelegramIcon } from "../../../assets/images/bountyPage/telegram.svg";

import Claim, { claimInitialState, claimValidation } from "./Claim";
import Invite, { inviteInitialState, inviteValidation } from "./Invite";
import JoinDiscord, {
  discordInitialState,
  discordValidation,
} from "./JoinDiscord";
import JoinTelegram, {
  telegramInitialState,
  telegramValidation,
} from "./JoinTelegram";
import Submission, {
  submissionInitialState,
  submissionValidation,
} from "./Submission";
import TwitterTask, {
  twitterInitialState,
  twitterValidation,
} from "./TwitterTask";
import VoteSnapshot, {
  snapshotInitialState,
  snapshotValidation,
} from "./VoteSnapshot";
import Quiz, { quizInitialState, quizValidation } from "./Quiz";
export const menu = {
  BOUNTY_SUBMISSION: {
    label: "Submission",
    icon: <SubmissionIcon />,
    component: (state, setState) => {
      return <Submission state={state} setState={setState} />;
    },
    validation: submissionValidation,
    initialState: submissionInitialState,
  },
  TWITTER: {
    label: "Twitter task",
    icon: <TwitterIcon />,
    component: (state, setState) => {
      return <TwitterTask state={state} setState={setState} />;
    },
    validation: twitterValidation,
    initialState: twitterInitialState,
  },
  DISCORD: {
    label: "Join discord",
    icon: <DiscordIcon />,
    component: (state, setState) => {
      return <JoinDiscord state={state} setState={setState} />;
    },
    validation: discordValidation,
    initialState: discordInitialState,
  },
  TELEGRAM: {
    label: "Join telegram",
    icon: <TelegramIcon />,
    component: (state, setState) => {
      return <JoinTelegram state={state} setState={setState} />;
    },
    validation: telegramValidation,
    initialState: telegramInitialState,
  },
  SNAPSHOT: {
    label: " Vote on snapshot",
    icon: "⚡️",
    component: (state, setState) => {
      return <VoteSnapshot state={state} setState={setState} />;
    },
    validation: snapshotValidation,
    initialState: snapshotInitialState,
  },

  QUIZ: {
    label: " Quiz",
    icon: "🙋‍♀️",
    component: (state, setState) => {
      return <Quiz state={state} setState={setState} />;
    },
    validation: quizValidation,
    initialState: quizInitialState,
  },
  INVITE: {
    label: " Invite",
    icon: "👥",
    component: (state, setState) => {
      return <Invite state={state} setState={setState} />;
    },
    validation: inviteValidation,
    initialState: inviteInitialState,
  },
  CLAIM: {
    label: " Free claim",
    icon: "🆓",
    component: (state, setState) => {
      return <Claim state={state} setState={setState} />;
    },
    validation: claimValidation,
    initialState: claimInitialState,
  },
};

import { Button, Skeleton } from "antd";
import React, { useContext } from "react";
import OverviewIcon from "./../../images/overview-icon.svg";
import cornerArtwork from "../../assets/iconSvg/cornerArtwork.svg";
import greenCheck from "../../assets/iconSvg/greenCheck.svg";
import "../pagesStyles/DaoOverview.scss";
import PrimaryButtonFilled from "../../components/InternalComponents/PrimaryButtonFilled";
import { useState } from "react";
import customAxios from "../../api";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QuillEditor from "../../components/QuillEditor";
import { StyledModalWrapper } from "../../components/Course/CreateCourseModal";
import { Footer, Modal } from "../../styles/ModalStyles";
import { DaoContext } from "../../layouts/app/DaoContext";
import { getDefaultDaoImage, postMixpanel } from "../../utils";
import MobilePageTopNavbar from "../../components/Common/MobilePageTopNavbar";
import useWindowSize from "../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import DaoIntroCard from "../../components/Common/DaoIntroCard";
import Profile from "../../components/Overview/Profile/Index";

import { UserPlusIcon } from "@heroicons/react/24/solid";
import { CopyOutlined } from "@ant-design/icons";
import daoDefaultBg from "../../assets/images/daoSideBar/daoDefaultBg.png";
import styled from "styled-components";
import SidebarHeader from "../../components/Spaces/SidebarHeader";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { SpaceBreadcrumb } from "../../components/Spaces/BreadcrumbDesktopContainer";
import InviteModal from "../../components/Spaces/InviteModal";
const StyledModal = styled(Modal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-body {
    padding: 0 !important;
  }
  & .ant-modal-close-x {
    color: #f3f3f3;
  }
`;
function DaoOverview() {
  const navigate = useDaoLensNavigate();
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const [showAboutEditor, setShowAboutEditor] = useState(false);
  const [aboutInput, setAboutInput] = useState("");
  const [localAbout, setLocalAbout] = useState("");
  const [canAccessPermissions, setCanAccessPermissions] = useState(null);
  const [canAccessOnboarding, setCanAccessOnboarding] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [showOverviewCard, setShowOverviewCard] = useState(null);
  const {
    currentTab,
    setCurrentTab,
    isCurrentDaoJoined,
    reload,
    isSpace,
    daoId,
    daoDetails,
  } = useContext(DaoContext);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  // useEffect(() => {
  //   setRewardsLoading(true);
  //   customAxios
  //     .get(
  //       `/rewards/dao/fetch?daoId=${daoId}&type=${
  //         selectedRewardsTab === "1" ? "COLLECTED" : "ONGOING"
  //       }`
  //     )
  //     .then((res) => {
  //       let requests = res.data.res.map((ele) => {
  //         return getMetadataFromIpfsHash(ele.ipfsHash.replace("://", "/"));
  //         // const imageUrl = data.image.replace("ipfs://", "ipfs/");
  //       });
  //       console.log(requests);
  //       Promise.all(requests).then((response) => {
  //         setRewardsData(() =>
  //           res.data.res.map((ele, index) => ({
  //             ...ele,
  //             imageUrl: response[index].image.replace("ipfs://", "ipfs/"),
  //           }))
  //         );
  //         setRewardsLoading(false);
  //       });
  //     })
  //     .catch((err) => {
  //       setRewardsLoading(false);

  //       console.log(err);
  //     });
  // }, [daoId, selectedRewardsTab]);
  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`settings/canview`, { daoId: daoId })
      .then((res) => {
        console.log("HERE", { res });
        setCanEdit(res.data.canView ? true : false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]); // ! Local handlers

  const saveAbout = (text) => {
    customAxios
      .post("/dao/edit", { about: text, daoId: daoId })
      .then((res) => {
        postMixpanel("track", "edited_community_about", { dao_id: daoId });
        setShowAboutEditor(false);
        fetchDaoDetails(daoId);
        setLocalAbout(text);
        setAboutInput("");
        window.location.reload();
      })
      .catch((err) => console.log("save about err", err));
  };

  const fetchDaoDetails = (daoId) => {
    customAxios
      .get(`/dao/fetch?daoId=${daoId}`)
      .then((res) => {
        console.log({ res });
        setLocalAbout(res.data.daoDetails[0]?.description);
        setCanAccessOnboarding(
          res.data.daoDetails[0]?.description &&
          !res.data?.daoDetails[0]?.clickedOnboarding
        );
        setCanAccessPermissions(
          res.data?.daoDetails[0]?.clickedOnboarding &&
          !res.data.daoDetails[0]?.clickedPermission
        );
        if (
          res.data.daoDetails[0]?.description &&
          res.data.daoDetails[0]?.clickedPermission &&
          res.data?.daoDetails[0]?.clickedOnboarding
        )
          setShowOverviewCard(false);
        else {
          if (res.data.daoDetails[0]?.hasOwnProperty("showOverviewCard"))
            setShowOverviewCard(res.data.daoDetails[0]?.showOverviewCard);
          else setShowOverviewCard(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!daoId) return;
    fetchDaoDetails(daoId);
  }, [daoId]);

  const clickedPermission = () => {
    customAxios
      .post("/dao/edit", { clickedPermission: true, daoId: daoId })
      .then((res) => {
        postMixpanel("track", "clicked_on_permissions_from_overview_card", {
          dao_id: daoId,
          dao_name: daoDetails.name,
        });
        setShowAboutEditor(false);
      })
      .catch((err) => console.log("clickedPermission err", err));
  };
  const clickedOnboarding = () => {
    customAxios
      .post("/dao/edit", { clickedOnboarding: true, daoId: daoId })

      .then((res) => {
        postMixpanel("track", "clicked_on_invite_members_from_overview_card", {
          dao_id: daoId,
          dao_name: daoDetails.name,
        });
        setCanAccessOnboarding(false);
        setShowAboutEditor(false);
        setCanAccessPermissions(true);
      })
      .catch((err) => console.log("clickedOnboarding err", err));
  };
  const closeOverviewCard = () => {
    customAxios
      .post("/dao/edit", { showOverViewCard: false, daoId: daoId })
      .then((res) => {
        postMixpanel("track", "closed_overview_card", {
          dao_id: daoId,
          dao_name: daoDetails.name,
        });
        // setShowAboutEditor(false);
        setShowOverviewCard(false);
      })
      .catch((err) => console.log("showOverViewCard err", err));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "16px",
        width: windowSize.width < MD_SCREEN_BREAKPOINT ? "100vw" : "auto",
        paddingLeft: windowSize.width < MD_SCREEN_BREAKPOINT ? "15px" : "0",
      }}
    >
      {isInviteModalOpen ? (
        <InviteModal
          isOpen={isInviteModalOpen}
          setIsOpen={setIsInviteModalOpen}
        />
      ) : null}
      {windowSize.width < MD_SCREEN_BREAKPOINT ? (
        <div>
          <MobilePageTopNavbar
            showInvite={!isSpace && isCurrentDaoJoined}
            setInviteModalOpen={() => setIsInviteModalOpen(true)}
          />
          {isSpace ? (
            <SidebarHeader
              isCurrentDaoJoined={isCurrentDaoJoined}
              onInvite={() => setIsInviteModalOpen(true)}
            />
          ) : (
            <DaoIntroCard />
          )}
        </div>
      ) : (
        <div
          style={{
            paddingLeft: "20px",
            height: "54px",
            display: "flex",
            background: "white",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1.4rem",
            border: "1px solid #f4f2f2",
            boxShadow: "0px 4px 16px rgba(132, 148, 160, 0.12)",
            borderRadius: "16px",
            position: "relative",
          }}
        >
          {isSpace && (
            <div className="mr-1">
              <SpaceBreadcrumb />
            </div>
          )}
          <img
            src={OverviewIcon}
            alt="overviewIcon"
            style={{ objectFit: "contain", width: "20px" }}
          />
          <h2
            style={{ marginBottom: "0", fontSize: "16px", marginLeft: "4px" }}
          >
            Overview
          </h2>
          {!isSpace && isCurrentDaoJoined && (
            <span
              style={{
                color: "#3B82F6",
                backgroundColor: "#3B82F61F",
                display: "inline-flex",
                alignItems: "center",
                padding: "7px 12px",
                borderRadius: "12px",
                cursor: "pointer",
                position: "absolute",
                right: "7px",
                fontWeight: "600",
              }}
              onClick={() => {
                setIsInviteModalOpen(true);
              }}
            >
              <UserPlusIcon
                style={{
                  width: "16px",
                  cursor: "pointer",
                  color: "#3B82F6",
                  marginRight: "10px",
                }}
              />
              Invite members
            </span>
          )}
        </div>
      )}

      {!daoDetails && (
        <div className="paragraph-skeleton">
          <Skeleton active paragraph={{ rows: 4 }} />
        </div>
      )}
      {showAboutEditor && (
        <StyledModalWrapper>
          <Modal
            visible={showAboutEditor}
            footer={
              <Footer key="modal-footer" justifyContent="flex-end">
                <Button
                  className="rounded-corner"
                  type="outline"
                  style={{ color: "#64748B" }}
                  onClick={() => {
                    setShowAboutEditor(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="rounded-corner"
                  type="primary"
                  onClick={() => {
                    saveAbout(aboutInput);
                  }}
                >
                  Save
                </Button>
              </Footer>
            }
            destroyOnClose
            onCancel={() => {
              setShowAboutEditor(false);
            }}
            width={isMobile ? "auto" : "600px"}
          >
            <div
              style={{
                paddingTop: "20px",
                paddingLeft: "20px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "600",
                color: "black",
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              About
            </div>

            <QuillEditor
              value={aboutInput}
              height="400px"
              style={{
                padding: "0px 0px 4px 0px",
                resize: "none",
                overflowY: "auto",
                margin: "10px 12px",
              }}
              isBordered={true}
              onChange={(e) => setAboutInput(e)}
              placeholder="Share overview on goals/services of your DAO"
            />
          </Modal>
        </StyledModalWrapper>
      )}

      {/* {canEdit && showOverviewCard && !isSpace && (
        <>
          {" "}
          <div className="dao-overview-steps">
            <div className="corner-artwork">
              <img
                src={cornerArtwork}
                alt=""
                style={{ borderRadius: "0 12px 0 0 " }}
              />
            </div>
            {localAbout && (
              <div className="close-button" onClick={closeOverviewCard}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.775589 0.775712C1.0099 0.541397 1.3898 0.541397 1.62412 0.775712L5.99985 5.15145L10.3756 0.775712C10.6099 0.541398 10.9898 0.541398 11.2241 0.775712C11.4584 1.01003 11.4584 1.38993 11.2241 1.62424L6.84838 5.99998L11.2241 10.3757C11.4584 10.61 11.4584 10.9899 11.2241 11.2242C10.9898 11.4586 10.6099 11.4586 10.3756 11.2242L5.99985 6.8485L1.62412 11.2242C1.3898 11.4586 1.0099 11.4586 0.77559 11.2242C0.541275 10.9899 0.541275 10.61 0.77559 10.3757L5.15133 5.99998L0.775589 1.62424C0.541275 1.38993 0.541275 1.01003 0.775589 0.775712Z"
                    fill="#64748B"
                  />
                </svg>
              </div>
            )}
            <div className="dao-overview-steps__single-step">
              {localAbout ? (
                <img src={greenCheck} alt="check mark" />
              ) : (
                <span>1</span>
              )}
              <div className="dao-overview-steps__single-step--details">
                <p className="title">Tell us more about your DAO</p>
                <p className="sub-title">
                  Share overview on goals/services of the DAO
                </p>
                <PrimaryButtonFilled
                  buttonText={localAbout ? "Done" : "Add About"}
                  fillColor={localAbout ? "#E2E8F0" : "#3F83F8"}
                  clickHandler={() => {
                    setShowAboutEditor(true);
                    if (localAbout) setAboutInput(localAbout);
                  }}
                />
              </div>
            </div>
            <div className="dao-overview-steps__single-step">
              {canAccessOnboarding === false ? (
                <img src={greenCheck} alt="check mark" />
              ) : (
                <span>2</span>
              )}{" "}
              <div className="dao-overview-steps__single-step--details">
                <p className="title">Invite members to your community</p>
                <p className="sub-title">
                  Bring in your community to experience what you created and
                  collaborate together for community success ✨
                </p>
                <PrimaryButtonFilled
                  buttonText={
                    canAccessOnboarding === false ? "Done" : "Invite Members"
                  }
                  fillColor={canAccessOnboarding ? "#3F83F8" : "#E2E8F0"}
                  disabled={!canAccessOnboarding}
                  clickHandler={() => {
                    clickedOnboarding();
                    let url = "";
                    url = window.location.href.split(daoId);
                    navigator.clipboard
                      .writeText(`${url[0]}${daoId}/overview?invite=true`)
                      .then(
                        () => {
                          postMixpanel(
                            "track",
                            "copied_community_invite_link_through_overview",
                            {
                              dao_id: daoId,
                              dao_name: daoDetails?.name,
                            }
                          );

                          toast.success("Invite link copied successfully");
                        },
                        () => {
                          toast.error("Link not copied");
                        }
                      )
                      .catch((err) => {
                        console.log("error", err);
                      });
                    // history.push("https://app.daolens.com/onboarding");
                  }}
                />
              </div>
            </div>
            <div className="dao-overview-steps__single-step">
              <span>3</span>

              <div className="dao-overview-steps__single-step--details">
                <p className="title">Review permissions</p>
                <p className="sub-title">
                  Check who can manage and interact on Daolens from your DAO
                </p>
                <PrimaryButtonFilled
                  buttonText={"Go to permissions"}
                  fillColor={canAccessPermissions ? "#3F83F8" : "#E2E8F0"}
                  disabled={
                    !(
                      localAbout &&
                      !canAccessOnboarding &&
                      canAccessPermissions
                    )
                  }
                  clickHandler={() => {
                    if (localAbout && canAccessPermissions) {
                      clickedPermission();
                      navigate(`/app/dao/${daoId}/settings/permissions`);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )} */}
      <Profile
        canEdit={canEdit}
        setShowAboutEditor={(e) => {
          setShowAboutEditor(e);
          setAboutInput(localAbout);
        }}
      />
    </div>
  );
}

export default DaoOverview;

import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import diamondIconGreen from "../../assets/iconSvg/diamondIconGreen.svg";
import { StyledCard, StyledLabel } from "../BountyFeed";
import BountyFeedCardContent from "../BountyComponents/BountyFeedCardContent";
import useWindowSize from "../../hooks/useWindowSize";
import { getCurrentDateFromNow, getUrlForIPFSHash } from "../../utils";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import IconWrapper from "../IconWrapper";
import axios from "axios";

const { Text } = Typography;

const PostCard = ({
  bountyPost,
  daoId,
  selectedTab,
  showDao,
  daoName,
  canCreateBounty,
  isAdmin,
  isMarginBottomRemoved = false,
}) => {
  let navigate = useDaoLensNavigate();
  const { isMobile } = useWindowSize();
  return (
    <StyledCard
      style={{
        width: "100%",
        textAlign: "left",
        // marginBottom: "16px",
        borderRadius: "16px",
        cursor: "pointer",
        marginBottom: isMarginBottomRemoved ? "" : "12px",
      }}
      onClick={() => {
        navigate(`/app/dao/${daoName}/${daoId}/bounty/${bountyPost.bountyId}`);
        // setViewBountyModalVisibility(true);
        // setBountyDetails(bountyPost);
        // setBountyModalId(bountyPost.bountyId);
      }}
      setOrder
    >
      <BountyFeedCardContent
        data={bountyPost}
        style={{ marginBottom: "12px" }}
        showDao={showDao}
        canCreateBounty={canCreateBounty}
        isAdmin={isAdmin}
        isTripleDotMenuVisible
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "12px",
          flexWrap: "wrap",
        }}
      >
        <div style={{ paddingRight: "0px" }}>
          <h4
            className="bounty-details__meta--header"
            style={{ marginBottom: "0px" }}
          >
            {bountyPost.bountyTitle}
          </h4>
        </div>
        <div>
          {/* <div
        style={{
          padding: "4px 20px",
          background: "#22C55E",
          borderRadius: "50px",
          color: "white",
        }}
      >
        Ξ 0.09
      </div> */}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile && "column",
          rowGap: isMobile && "8px",
          alignItems: isMobile && "start",
        }}
      >
        <div
          className="flex-wrap"
          style={{
            display: "flex",
            columnGap: "8px",
            rowGap: "8px",
            alignItems: isMobile && "start",
          }}
        >
          {selectedTab === "Available" && (
            <>
              {bountyPost.bountyLastSubmissionDate ? (
                <StyledLabel>
                  <Text
                    style={{
                      color: "#0F172A",
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "16px",
                    }}
                  >
                    🗓 Due on
                    {moment(bountyPost.bountyLastSubmissionDate).format(
                      " D MMMM YYYY"
                    )}
                  </Text>
                </StyledLabel>
              ) : null}

              {bountyPost.taskPoint ? (
                <StyledLabel>
                  <Text
                    style={{
                      color: "#0F172A",
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "16px",
                    }}
                  >{`⭐️${bountyPost.taskPoint}XP`}</Text>
                </StyledLabel>
              ) : null}
              {bountyPost.skills?.map((tag) => (
                <StyledLabel>
                  <Text
                    style={{
                      color: "#2A3142",
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "16px",
                      bacgkround: "white",
                    }}
                  >
                    {tag}
                  </Text>
                </StyledLabel>
              ))}
              {bountyPost.nftRewardMetadata ? (
                <StyledLabel
                  style={{ alignItems: "center", display: "flex", gap: "4px" }}
                >
                  <Text
                    style={{
                      color: "#0F172A",
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "16px",
                    }}
                  >{`🖼️ NFT`}</Text>
                  <IconWrapper>
                    <i
                      class="fas fa-external-link"
                      style={{
                        fontSize: "10px",
                        color: "#374151",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        axios
                          .get(
                            getUrlForIPFSHash(
                              bountyPost.nftRewardMetadata.split("ipfs://")[1]
                            )
                          )
                          .then((res) => {
                            if (res.data.image)
                              window.open(
                                getUrlForIPFSHash(
                                  res.data.image.split("ipfs://")[1]
                                )
                              );
                          });
                        e.stopPropagation();
                      }}
                    />
                  </IconWrapper>
                </StyledLabel>
              ) : null}

              <StyledLabel>
                <Text
                  style={{
                    color: "#0F172A",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "16px",
                  }}
                >
                  📜 <span>{bountyPost.noOfSubmissions}</span> Submissions
                </Text>
              </StyledLabel>
              {bountyPost?.bountyType === "CLAIM" &&
              bountyPost?.userSubmission?.length &&
              bountyPost?.userSubmission?.[0]?.completedSubtasks ? (
                <>
                  <StyledLabel style={{ marginRight: "8px" }}>
                    <Text
                      style={{
                        color: "#0F172A",
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: "16px",
                      }}
                    >
                      ✅ Claimed{" "}
                      {bountyPost?.userSubmission[0]?.completedSubtasks} times
                    </Text>
                  </StyledLabel>
                </>
              ) : null}
              {/* {[
                ...(bountyPost?.bountySkills ? bountyPost?.bountySkills : []),
              ].map((tag) => (
                <StyledLabel>
                  <Text
                    style={{
                      color: "#2A3142",
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "16px",
                      bacgkround: "white",
                    }}
                  >
                    {tag}
                  </Text>
                </StyledLabel>
              ))} */}

              {/* {bountyPost.bountyAmount &&
              bountyPost.bountyType === "BOUNTY_SUBMISSION" ? (
                <StyledLabel>
                  <Text
                    style={{
                      color: "#0F172A",
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "16px",
                    }}
                  >
                    <img
                      src={diamondIconGreen}
                      alt="diamond-icon"
                      className="diamond-image"
                    />{" "}
                    {bountyPost?.bountyAmount} {bountyPost?.symbol}
                  </Text>
                </StyledLabel>
              ) : null} */}
            </>
          )}
          {selectedTab === "Completed" && (
            <>
              <StyledLabel style={{ marginRight: !isMobile && "8px" }}>
                <Text
                  style={{
                    color: "#0F172A",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "16px",
                  }}
                >
                  ✅ {"  "}Submitted on{" "}
                  {moment(bountyPost.bountySubmittedDate).format(
                    "DD MMMM YYYY"
                  )}
                </Text>
              </StyledLabel>

              <StyledLabel style={{ marginRight: !isMobile && "8px" }}>
                <Text
                  style={{
                    color: "#0F172A",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "16px",
                  }}
                >
                  📜
                  <span style={{ marginLeft: "4px" }}>
                    {bountyPost.noOfSubmissions}
                  </span>{" "}
                  Submissions
                </Text>
              </StyledLabel>
              {bountyPost.bountyAmount ? (
                <StyledLabel>
                  <Text
                    style={{
                      color: "#0F172A",
                      fontWeight: 500,
                      fontSize: 12,
                      lineHeight: "16px",
                    }}
                  >
                    <img
                      src={diamondIconGreen}
                      alt="diamond-icon"
                      className="diamond-image"
                    />{" "}
                    {bountyPost?.bountyAmount} {bountyPost?.symbol}
                  </Text>
                </StyledLabel>
              ) : null}
            </>
          )}
          {selectedTab === "Missed" && (
            <>
              <StyledLabel
                style={{ backgroundColor: "#EF44441A", border: "none" }}
              >
                <Text
                  style={{
                    color: "#EF4444",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "16px",
                  }}
                >
                  {getCurrentDateFromNow(bountyPost.bountyLastSubmissionDate)}
                </Text>
              </StyledLabel>
            </>
          )}
          {bountyPost.bountyAmount && (
            <StyledLabel>
              <Text
                style={{
                  color: "#0F172A",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "16px",
                }}
              >
                <img
                  src={diamondIconGreen}
                  alt="diamond-icon"
                  className="diamond-image"
                />{" "}
                {bountyPost?.bountyAmount} {bountyPost?.symbol}
              </Text>
            </StyledLabel>
          )}
        </div>
        {bountyPost.gatekeeping && (
          <StyledLabel>
            <Text
              style={{
                color: "#2A3142",
                fontWeight: 500,
                fontSize: 12,
                lineHeight: "16px",
              }}
            >
              {bountyPost.gatekeeping}
            </Text>
          </StyledLabel>
        )}
      </div>
    </StyledCard>
  );
};

export default PostCard;

import React from "react";
import "../ComponentStyles/CourseVerificationAdminModal.scss";
import {
  Modal,
  Typography,
  Radio,
  Space,
  Checkbox,
  Dropdown,
  Menu,
  Button,
  Row,
  Col,
} from "antd";
import { useState } from "react";
import bin from "../../assets/iconSvg/bin.svg";
import { DownOutlined } from "@ant-design/icons";
import useWindowSize from "../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import { TrashIcon } from "@heroicons/react/24/solid";
import IconWrapper from "../IconWrapper";
const { Link } = Typography;

function CourseVerificationAdminModal({
  quizDetails,
  setQuizDetails,
  setUpdatedFieldsNames = () => {},
}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const menu = (
    <Menu>
      <Menu.Item
        key={"SINGLE_CHOICE"}
        onClick={(ev) => {
          setUpdatedFieldsNames();
          setQuestionType(ev.key, currentQuestionIndex);
        }}
      >
        Single Choice
      </Menu.Item>
      <Menu.Item
        key={"MULTIPLE_CHOICE"}
        onClick={(ev) => {
          setUpdatedFieldsNames();
          setQuestionType(ev.key, currentQuestionIndex);
        }}
      >
        Multi Choice
      </Menu.Item>
    </Menu>
  );

  const addQuestion = () => {
    setQuizDetails([
      ...quizDetails,
      {
        question: "",
        type: "SINGLE_CHOICE",
        options: [
          { text: "", isCorrect: true },
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      },
    ]);
  };
  const addOption = (questionIndex) => {
    let localQuizDetails = quizDetails[questionIndex];
    localQuizDetails.options.push({ text: "", isCorrect: false });
    quizDetails[questionIndex] = localQuizDetails;
    setQuizDetails([...quizDetails]);
  };
  const setQuestionText = (text, questionIndex) => {
    let localQuizDetails = quizDetails[questionIndex];
    localQuizDetails.question = text;
    quizDetails[questionIndex] = localQuizDetails;
    setQuizDetails([...quizDetails]);
  };
  const setQuestionType = (type, questionIndex) => {
    let localQuizDetails = quizDetails[questionIndex];
    localQuizDetails.type = type;
    localQuizDetails.options.forEach((option) => (option.isCorrect = false));
    quizDetails[questionIndex] = localQuizDetails;
    setQuizDetails([...quizDetails]);
  };
  const setOptionText = (text, questionIndex, optionIndex) => {
    let localQuizDetails = quizDetails[questionIndex];
    localQuizDetails.options[optionIndex].text = text;
    quizDetails[questionIndex] = localQuizDetails;
    setQuizDetails([...quizDetails]);
  };
  const setMultiCorrect = (checkedValues, questionIndex) => {
    let localQuizDetails = quizDetails[questionIndex];
    let newLocalOptions = localQuizDetails.options.map((singleOption) => {
      if (
        checkedValues.find((value) => {
          return value == singleOption.text;
        })
      ) {
        singleOption.isCorrect = true;
      } else {
        singleOption.isCorrect = false;
      }
      return singleOption;
    });
    localQuizDetails.options = newLocalOptions;
    quizDetails[questionIndex] = localQuizDetails;
    setQuizDetails([...quizDetails]);
  };
  const setSingleCorrect = (event, questionIndex) => {
    let localQuizDetails = quizDetails[questionIndex];
    localQuizDetails.options.forEach((singleOption) => {
      if (event.target.value == singleOption.text) {
        singleOption.isCorrect = true;
      } else {
        singleOption.isCorrect = false;
      }
      return singleOption;
    });
    quizDetails[questionIndex] = localQuizDetails;
    setQuizDetails([...quizDetails]);
  };
  const deleteOption = (questionIndex, optionIndex) => {
    let localQuizDetails = quizDetails[questionIndex];
    localQuizDetails.options.splice(optionIndex, 1);
    quizDetails[questionIndex] = localQuizDetails;
    setQuizDetails([...quizDetails]);
  };
  const deleteQuestion = (questionIndex) => {
    quizDetails.splice(questionIndex, 1);
    setQuizDetails([...quizDetails]);
  };
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;

  return (
    <div className="create-quiz">
      {quizDetails &&
        quizDetails.map((singleQuiz, questionIndex) => {
          return (
            <div className="course-verification-admin-modal__single-quiz">
              <div className="course-verification-admin-modal__single-quiz--top-left">
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    whiteSpace: "nowrap",
                    alignItems: "flex-end",
                    flexWrap: isMobile ? "wrap" : "",
                    overflow: "hidden",
                    gap: "8px",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    {questionIndex + 1} .
                    <input
                      type="text"
                      placeholder="Type question"
                      style={{ width: "100%", minWidth: "300px" }}
                      value={singleQuiz.question}
                      onChange={(ev) => {
                        setUpdatedFieldsNames();
                        setQuestionText(ev.target.value, questionIndex);
                      }}
                    />
                  </div>
                  <IconWrapper style={{ cursor: "pointer" }}>
                    <TrashIcon
                      fill="#6B7280"
                      width={16}
                      onClick={() => {
                        setUpdatedFieldsNames();
                        deleteQuestion(questionIndex);
                      }}
                    />
                  </IconWrapper>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Space
                      onClick={() => {
                        setCurrentQuestionIndex(questionIndex);
                      }}
                    >
                      <div className="course-verification__question-type">
                        <span style={{ whiteSpace: "nowrap" }}>
                          {singleQuiz.type === "SINGLE_CHOICE"
                            ? "Single Choice"
                            : "Multi Choice"}
                        </span>
                        <DownOutlined />
                      </div>
                    </Space>
                  </Dropdown>
                </div>
              </div>
              {singleQuiz.type === "SINGLE_CHOICE" ? (
                <Radio.Group
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    rowGap: "8px",
                  }}
                  value={
                    singleQuiz?.options.find((option) => option.isCorrect)?.text
                  }
                  onChange={(ev) => {
                    setUpdatedFieldsNames();
                    setSingleCorrect(ev, questionIndex);
                  }}
                  className="course-verification__option-group"
                >
                  {singleQuiz.options.map((singleOption, optionIndex) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                        className="checkbox-item-container"
                      >
                        <Radio
                          value={singleOption.text}
                          disabled={!Boolean(singleOption.text)}
                          style={{ position: "relative" }}
                        ></Radio>
                        <input
                          type="text"
                          value={singleOption.text}
                          style={{ width: "100%" }}
                          placeholder={`Option ${optionIndex + 1}`}
                          onChange={(ev) => {
                            setUpdatedFieldsNames();
                            setOptionText(
                              ev.target.value,
                              questionIndex,
                              optionIndex
                            );
                          }}
                        />

                        {singleOption.isCorrect && (
                          <span
                            style={{
                              right: "30px",
                              color: "#0E9F6E",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            Correct
                          </span>
                        )}
                        <IconWrapper>
                          <TrashIcon
                            fill="#6B7280"
                            width={16}
                            onClick={() => {
                              setUpdatedFieldsNames();
                              deleteOption(questionIndex, optionIndex);
                            }}
                            cursor="pointer"
                          />
                        </IconWrapper>
                      </div>
                    );
                  })}
                </Radio.Group>
              ) : (
                <Checkbox.Group
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    rowGap: "8px",
                  }}
                  value={singleQuiz?.options
                    .filter((option) => option.isCorrect)
                    .map((option) => option?.text)}
                  onChange={(checkedValues) => {
                    setUpdatedFieldsNames();
                    setMultiCorrect(checkedValues, questionIndex);
                  }}
                  className="course-verification__option-group"
                >
                  {singleQuiz.options.map((singleOption, optionIndex) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          columnGap: "8px",
                          alignItems: "center",
                        }}
                        className="checkbox-item-container"
                      >
                        <Checkbox
                          value={singleOption.text}
                          disabled={!Boolean(singleOption.text)}
                          style={{ position: "relative" }}
                        ></Checkbox>
                        <input
                          type="text"
                          value={singleOption.text}
                          placeholder={`Option ${optionIndex + 1}`}
                          style={{ width: "100%" }}
                          onChange={(ev) => {
                            setUpdatedFieldsNames();
                            setOptionText(
                              ev.target.value,
                              questionIndex,
                              optionIndex
                            );
                          }}
                        />
                        {singleOption.isCorrect && (
                          <span
                            style={{
                              right: "30px",
                              color: "#0E9F6E",
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            Correct
                          </span>
                        )}
                        <IconWrapper>
                          <TrashIcon
                            fill="#6B7280"
                            width={16}
                            onClick={() => {
                              setUpdatedFieldsNames();
                              deleteOption(questionIndex, optionIndex);
                            }}
                            cursor="pointer"
                          />
                        </IconWrapper>
                      </div>
                    );
                  })}
                </Checkbox.Group>
              )}
              {singleQuiz.options.length < 5 ? (
                <span
                  style={{
                    marginTop: "10px",
                    display: "inline-block",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#94A3B8",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUpdatedFieldsNames();
                    addOption(questionIndex);
                  }}
                >
                  + Add option
                </span>
              ) : null}
            </div>
          );
        })}

      <span
        style={{
          margin: "10px auto",
          display: "inline-block",
          fontSize: "14px",
          fontWeight: "500",
          color: "#94A3B8",
          cursor: "pointer",
          padding: "10px 20px",
          border: "1px solid #E2E8F0",
          borderRadius: "10rem",
        }}
        onClick={() => {
          setUpdatedFieldsNames();
          addQuestion();
        }}
      >
        + Add question
      </span>
    </div>
  );
}

export default CourseVerificationAdminModal;

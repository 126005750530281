import { PhotoIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useLayer } from "react-laag";
import classNames from "classnames";
import EmojiPicker from "emoji-picker-react";
import { BACKGROUND_COLORS } from "../Project.js/CreateProjectModal";
import Upload from "../Common/form/Upload";
import { checkFileSize, checkImageType } from "../../utils";
import { PencilIcon } from "@heroicons/react/24/solid";

type Props = {
  profilePicture: string | null;
  setProfilePicture: Dispatch<SetStateAction<string | null>>;
  emojiProfilePicture: {
    emoji: string;
    background: string;
  } | null;
  setEmojiProfilePicture: Dispatch<
    SetStateAction<{
      emoji: string;
      background: string;
    } | null>
  >;
  isUploadCompUpdated: boolean;
  setIsUploadCompUpdated: Dispatch<SetStateAction<boolean>>;
};

const AddDisplayPicture = ({
  emojiProfilePicture,
  profilePicture,
  setEmojiProfilePicture,
  setProfilePicture,
  isUploadCompUpdated,
  setIsUploadCompUpdated,
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<"emoji" | "upload">(
    profilePicture ? "upload" : "emoji"
  );

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    arrowOffset: 4,
    placement: "bottom-start",
    triggerOffset: 12,
    onOutsideClick: () => setOpen(false),
  });

  return (
    <>
      <button
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
        className="border-none bg-white w-fit h-fit relative group"
      >
        {!profilePicture && !emojiProfilePicture && (
          <button className="bg-gray-50 border-dashed border border-gray-300 rounded-full w-16 lg:w-20 h-16 lg:h-20 flex items-center justify-center">
            <PhotoIcon width={18} strokeWidth={2} />
          </button>
        )}
        {profilePicture ? (
          <img
            src={`${
              isUploadCompUpdated
                ? URL?.createObjectURL(profilePicture as any)
                : profilePicture
            }`}
            className="w-16 lg:w-20 h-16 lg:h-20 rounded-full flex items-center justify-center object-cover"
            alt="Profile"
          />
        ) : emojiProfilePicture?.emoji ? (
          <div
            style={{ background: emojiProfilePicture.background }}
            className="text-3xl w-16 lg:w-20 h-16 lg:h-20 rounded-full flex items-center justify-center"
          >
            {emojiProfilePicture.emoji}
          </div>
        ) : null}
        {(profilePicture || emojiProfilePicture?.emoji) && (
          <span className="absolute w-16 lg:w-20 h-16 lg:h-20 rounded-full top-0 left-0 right-0 mx-auto hidden group-hover:flex bg-black bg-opacity-30 items-center justify-center cursor-pointer">
            <PencilIcon className="w-8 text-black" />
          </span>
        )}
      </button>
      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{ ...layerProps.style, zIndex: 100000 }}
            className={classNames(
              "flex flex-col lg:w-auto rounded-2xl shadow border border-solid border-gray-100 bg-white",
              activeTab === "upload" ? "w-[90%]" : "w-auto"
            )}
          >
            <div className="flex border-b border-0 border-solid border-gray-200">
              <button
                onClick={() => setActiveTab("emoji")}
                className={classNames(
                  "text-gray-500 bg-white py-3 rounded-t-2xl px-2",
                  activeTab === "emoji"
                    ? "border-0 border-b-2 border-solid border-blue-500"
                    : "border-0 border-b-2 border-white border-solid"
                )}
              >
                Icons
              </button>
              <button
                onClick={() => setActiveTab("upload")}
                className={classNames(
                  "text-gray-500 bg-white py-3 rounded-t-2xl px-2",
                  activeTab === "upload"
                    ? "border-0 border-b-2 border-solid border-blue-500"
                    : "border-0 border-b-2 border-white border-solid"
                )}
              >
                Custom Image
              </button>
            </div>
            <>
              {activeTab === "emoji" && (
                <div className="overflow-auto">
                  <EmojiPicker
                    onEmojiClick={(emojiData) => {
                      // TODO: while editing it should not change background color
                      setEmojiProfilePicture({
                        emoji: emojiData.emoji,
                        background:
                          BACKGROUND_COLORS[
                            Math.floor(Math.random() * BACKGROUND_COLORS.length)
                          ],
                      });
                      setProfilePicture(null);
                      setOpen(false);
                    }}
                    height={350}
                  />
                </div>
              )}
              {activeTab === "upload" && (
                <>
                  <div className="px-3 py-5">
                    <Upload
                      setIsUpdated={setIsUploadCompUpdated}
                      // suggestedSize="1920 * 1080"
                      isUpdated={isUploadCompUpdated}
                      label=""
                      data={profilePicture}
                      onChange={(newFile: any) => {
                        checkImageType(newFile.type);
                        checkFileSize(newFile.size);
                        setEmojiProfilePicture(null);
                        setProfilePicture(newFile);
                      }}
                      width={"100%"}
                      height={!profilePicture ? "84px" : "200px"}
                      // @ts-expect-error
                      emptyStateComponent={
                        <div className="flex flex-col p-4 gap-3 bg-gray-50 rounded-xl border border-solid border-gray-100 items-center">
                          <div className="w-20 h-20 flex justify-center items-center rounded-full bg-gray-100 border border-dashed border-gray-200">
                            <PhotoIcon className="w-5 text-gray-500" />
                          </div>
                          <p className="m-0 text-gray-400 font-semibold">
                            Drag and drop or upload from your device
                          </p>
                        </div>
                      }
                    />
                  </div>
                  <div className="flex justify-end w-full p-3 border-0 border-t border-solid border-gray-200">
                    <button
                      onClick={() => setOpen(false)}
                      className="bg-blue-500 px-4 py-2 rounded-xl border-none text-white"
                    >
                      Done
                    </button>
                  </div>
                </>
              )}
            </>
          </div>
        )}
    </>
  );
};

export default AddDisplayPicture;

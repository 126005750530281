import { Typography } from "antd";
import React from "react";
import Approval from "./Approval";
import Ranked from "./Ranked";
import SingleChoice from "./SingleChoice";
import Weighted from "./Weighted";
function VotingTypeCard({
  choices,
  selectedChoice,
  setSelectedChoice,
  pollType,
  isExpired,
  canVote,
  gatingConditions,
}) {
  console.log("selected", selectedChoice);
  const getVotingTypeCard = () => {
    switch (pollType) {
      case "basic": {
        return (
          <SingleChoice
            choices={choices}
            selectedChoice={selectedChoice}
            gatingConditions={gatingConditions}
            isExpired={isExpired}
            canVote={canVote}
            setSelectedChoice={setSelectedChoice}
          />
        );
      }
      case "single-choice": {
        return (
          <SingleChoice
            choices={choices}
            canVote={canVote}
            gatingConditions={gatingConditions}
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
          />
        );
      }
      case "approval": {
        return (
          <Approval
            choices={choices}
            gatingConditions={gatingConditions}
            canVote={canVote}
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
          />
        );
      }
      case "ranked-choice": {
        return (
          <Ranked
            choices={choices}
            canVote={canVote}
            gatingConditions={gatingConditions}
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
          />
        );
      }
      case "quadratic": {
        return (
          <Weighted
            choices={choices}
            canVote={canVote}
            gatingConditions={gatingConditions}
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
          />
        );
      }
      case "weighted": {
        return (
          <Weighted
            canVote={canVote}
            choices={choices}
            gatingConditions={gatingConditions}
            selectedChoice={selectedChoice}
            setSelectedChoice={setSelectedChoice}
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {getVotingTypeCard(pollType)}
    </div>
  );
}

export default VotingTypeCard;

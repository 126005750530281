import { Row } from "antd";
import React, { useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import Tutorials from "../../components/DIscoverPage/Tutorials";
import { ENTITIES } from "../../constants/DiscoverPage";
import { CommunityFilterType, EntityValueType } from "../../types/DiscoverPage";
import Communities from "../../components/DIscoverPage/Entities/Communities";
import OtherEntities from "../../components/DIscoverPage/Entities/OtherEntities";
import Header from "../../components/DIscoverPage/Header";
import ErrorBoundary from "antd/lib/alert/ErrorBoundary";

function DaoDiscovery() {
  const [query, setQuery] = useState("");

  const [activeFilter, setActiveFilter] = useState<CommunityFilterType>("");
  const [activeEntity, setActiveEntity] = useState<EntityValueType>("");

  const { isMobile } = useWindowSize();

  return (
    <div
      style={{
        background: `#F5F3FF`,
        width: "100%",
        height: "100vh",
        paddingLeft: isMobile ? "" : "40px",
        overflowY: "auto",
      }}
    >
      <Row
        justify={isMobile ? "start" : "center"}
        style={{
          alignItems: "center",
          // paddingTop: "10",
          position: "absolute",
          zIndex: 10,
          width: isMobile ? "100vw" : "calc(100vw - 40px)",
          background: `#F5F3FF`,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: isMobile ? "100%" : "1000px",
            margin: isMobile ? "0 0 90px 0" : "0 auto 24px auto",
            display: "grid",
            gap: "16px",
            position: "relative",
          }}
        >
          <Header
            activeFilter={activeFilter}
            query={query}
            setActiveFilter={setActiveFilter}
            setQuery={setQuery}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "22px",
                background: "white",
                borderTopLeftRadius: "24px",
                borderTopRightRadius: "24px",
                padding: "12px 24px 0",
                borderBottom: "1px solid #F3F4F6",
              }}
            >
              <div
                style={{
                  display: "flex",
                  maxWidth: isMobile ? "calc(100vw - 48px)" : "",
                  overflow: "auto",
                }}
              >
                {ENTITIES.map((entity) => (
                  <button
                    onClick={() => setActiveEntity(entity.value)}
                    style={{
                      border: "none",
                      borderBottom:
                        entity.value === activeEntity
                          ? "1px solid #4F46E5"
                          : "1px solid white",
                      color:
                        entity.value === activeEntity ? "#4F46E5" : "#6B7280",
                      whiteSpace: "nowrap",
                      background: "white",
                      padding: "10px 10px",
                      cursor: "pointer",
                    }}
                  >
                    {entity.label}
                  </button>
                ))}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: "white",
                padding: "22px 24px",
                borderBottomLeftRadius: "24px",
                borderBottomRightRadius: "24px",
                gap: "12px",
              }}
            >
              {activeEntity === "" ? (
                <Communities communityType={activeFilter} searchQuery={query} />
              ) : (
                <ErrorBoundary
                  message="Something went wrong"
                  description="Please refresh the page and try again."
                >
                  <OtherEntities
                    communityType={activeFilter}
                    activeEntity={activeEntity}
                    searchQuery={query}
                  />
                </ErrorBoundary>
              )}
            </div>
          </div>
          <Tutorials />
        </div>
      </Row>
    </div>
  );
}

export default DaoDiscovery;

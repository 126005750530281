import {
  ArrowLeftOutlined,
  CloseCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Button, Card, Divider, Input, Select, Space, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { PopupCard } from "../../styles/PopupCard";
import customAxios from "../../api/index";
import { toast } from "react-toastify";
import DiamondImage from "../../images/diamond.png";
import diamonIconGreen from "./../../assets/iconSvg/diamondIconGreen.svg";
import { LabelIcon } from "../../styles/LabelIcon";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";

const AddBountyBtnWrapper = styled.div`
  & .btn-addBounty {
    border: 1px dashed #94a3b8;
    background: transparent;
    padding: 5px 12px;
    border-radius: 8px;
    color: #64748b;
    font-size: 12px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    align-self: center;
    span {
      white-space: nowrap;
    }
    &:hover {
      border: 1px dashed #1890ff;
      cursor: pointer;
      color: #1890ff;
    }
  }

  & .diamond-image {
    width: 1rem;
    aspect-ratio: 1;
  }
`;

const CardBody = styled.div`
  width: 100%;
  padding: 16px;

  & .label {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 4px;
  }

  & .select {
    width: 100%;
    .ant-select-selector {
      background-color: #fff !important;
      border: 1px solid #d9d9d9 !important;
      border-radius: 12px !important;
    }
  }

  & .input {
    position: relative;
    border-radius: 12px;
  }

  & .symbol {
    background: #f4f2f2;
    border: 1px solid #40a9ff;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    color: #64748b;
    position: absolute;
    right: -2px;
    padding: 4px 12px;
  }

  & .read-only-capsule {
    background: #f7f7f7;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;

    height: 32px;
    display: flex;
    align-items: center;
    color: #64748b;
    padding: 6px 0px 7px 16px;
  }

  & .grid-wrapper {
    width: 186px;
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
  }
`;

const CardFooter = styled.div`
  text-align: end;
  padding: 8px 16px;
  border-top: 1px solid #e2e8f0;
`;

const AddBounty = ({
  selectedTokenId,
  selectedTokenAmt,
  setSelectedTokenId,
  setSelectedTokenAmt,
  isSubtask,

  defaultNetwork,
  defaultTokenName,

  setUpdatedFieldsNames = () => {},
}) => {
  const [addTokenStep, setAddTokenStep] = useState(false);
  const [lookUpStep, setLookUpStep] = useState(false);
  const [contractAddress, setContractAddress] = useState("");
  const [tokenList, setTokenList] = useState([]);
  const [newTokenMetaData, setNewTokenMetaData] = useState([]);
  const [newSelectedTokenId, setNewSelectedTokenId] = useState("");
  const [contractDetails, setContractDetails] = useState({});
  const [showCard, setShowCard] = React.useState(false);
  const cardRef = useRef();
  const [tokenId, setTokenId] = useState(selectedTokenId);
  const [tokenAmount, setTokenAmount] = useState(selectedTokenAmt);

  useEffect(() => {
    getTokenMetaData();
  }, []);

  useEffect(() => {
    if (!showCard) {
      clearStatesHandler();
    }
  }, [showCard]);

  const clearStatesHandler = () => {
    setAddTokenStep(false);
    setLookUpStep(false);
    setContractAddress("");
    setNewTokenMetaData([]);
    setNewSelectedTokenId("");
    setContractDetails({});
  };

  const getTokenMetaData = () => {
    customAxios
      .get(`bountypayments/tokenmetadata`)
      .then(({ data }) => {
        if (
          defaultTokenName &&
          defaultNetwork &&
          defaultTokenName !== "" &&
          defaultNetwork !== ""
        ) {
          let tokenId = "";
          tokenId = data.tokenMetaData.filter(
            (token) =>
              token.tokenName === defaultTokenName &&
              token.network === defaultNetwork
          )[0]?.id;
          setSelectedTokenId(tokenId);
        }
        setTokenList(data.tokenMetaData);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };

  const getNewTokeMetaDataHandler = () => {
    setAddTokenStep(true);
    customAxios
      .get(`bountypayments/newtokemetadata`)
      .then(({ data }) => {
        setNewTokenMetaData(data.newTokenMetadata);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };

  const getContractDetailsHandler = () => {
    customAxios
      .get(
        `bountypayments/fetchcontractdetails?contractAddress=${contractAddress}&network=${
          newTokenMetaData.filter((token) => token.id === newSelectedTokenId)[0]
            .network
        }`
      )
      .then(({ data }) => {
        setContractDetails(data.contractMetadata);
        setLookUpStep(true);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
        toast.error("Contract not found");
      });
  };

  const addNewTokenHandler = () => {
    const selectedData = newTokenMetaData.filter(
      (token) => token.id === newSelectedTokenId
    )[0];
    customAxios
      .post(`bountypayments/addnewtoken`, {
        network: selectedData.network,
        tokenType: selectedData.tokenType,
        chainId: selectedData?.chainId,
        contractAddress,
        tokenName: contractDetails.name,
        symbol: contractDetails.symbol,
        decimals: contractDetails.decimals.toString(),
      })
      .then((res) => {
        getTokenMetaData();
        clearStatesHandler();
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };

  return (
    <PopupCard
      cardPadding="0px"
      bottom="unset"
      margin="2px 50px"
      width={"280px"}
    >
      {selectedTokenAmt !== "" ? (
        <LabelIcon
          onClick={() => setShowCard((prev) => !prev)}
          style={{ borderRadius: "8px" }}
        >
          <span
            style={{
              color: "#22c55e",
              fontweight: "600",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "32px",
              gap: "8px",
            }}
          >
            <img
              src={diamonIconGreen}
              alt="diamond-icon"
              className="diamond-image"
            />{" "}
            {selectedTokenAmt}
            {` ${
              tokenList?.filter((token) => token?.id === selectedTokenId)[0]
                ?.symbol
            }`}
          </span>
          <Button
            style={{ border: "none", background: "none" }}
            shape="circle"
            size="small"
            icon={<CloseCircleFilled />}
            onClick={(e) => {
              setSelectedTokenId("");
              setSelectedTokenAmt("");
              setUpdatedFieldsNames();
              e.stopPropagation();
            }}
          />
        </LabelIcon>
      ) : (
        <AddBountyBtnWrapper>
          <button
            className="btn-addBounty"
            onClick={() => setShowCard((prev) => !prev)}
          >
            {/* <img
              src={DiamondImage}
              alt="diamond-icon"
              className="diamond-image"
            /> */}
            <span style={{ fontSize: "14px" }}>+ Token</span>
          </button>
        </AddBountyBtnWrapper>
      )}
      {showCard && (
        <Card
          className="card"
          style={{ left: isSubtask ? "unset" : "-25%" }}
          ref={cardRef}
        >
          <CardBody>
            {!addTokenStep ? (
              <>
                <Typography className="label">Token</Typography>
                <Select
                  className="select"
                  style={{ marginBottom: "16px" }}
                  value={tokenId}
                  showSearch
                  placeholder="Select a token"
                  optionFilterProp="children"
                  onSelect={(value) => {
                    setTokenId(value);

                    setUpdatedFieldsNames();
                  }}
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "4px 0",
                        }}
                      />
                      <Button
                        style={{
                          margin: "0px",
                          padding: "0px 12px",
                          color: "#64748B",
                          fontWeight: "500",
                        }}
                        type="text"
                        icon={<PlusCircleFilled />}
                        onClick={getNewTokeMetaDataHandler}
                      >
                        Add Token
                      </Button>
                    </>
                  )}
                >
                  filterOption=
                  {(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  {tokenList?.map((token) => (
                    <Select.Option value={token.id}>
                      {token.tokenName}{" "}
                      <span style={{ textTransform: "capitalize" }}>
                        ({token.network.toLowerCase()})
                      </span>
                    </Select.Option>
                  ))}
                </Select>
                <Typography className="label">Amount</Typography>
                <Input
                  type="number"
                  className="input"
                  placeholder="Number of tokens"
                  value={tokenAmount}
                  onChange={(e) => {
                    setTokenAmount(e.target.value);
                    setUpdatedFieldsNames();
                  }}
                  suffix={
                    selectedTokenId !== "" ? (
                      <div className="symbol">
                        {
                          tokenList.filter((token) => token.id === tokenId)[0]
                            .symbol
                        }
                      </div>
                    ) : (
                      false
                    )
                  }
                />
              </>
            ) : (
              <>
                <Space
                  style={{
                    marginBottom: "16px",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  <Button type="text" style={{ padding: "0" }}>
                    <ArrowLeftOutlined onClick={clearStatesHandler} />
                  </Button>
                  <Typography>Add Token</Typography>
                </Space>
                <Typography className="label">Network</Typography>
                <Select
                  className="select"
                  style={{ marginBottom: "12px" }}
                  placeholder="Choose network"
                  onSelect={(value) => setNewSelectedTokenId(value)}
                >
                  {newTokenMetaData?.map((token) => (
                    <Select.Option value={token.id}>
                      {token.network}
                    </Select.Option>
                  ))}
                </Select>
                {newTokenMetaData?.find(
                  (token) => token?.id === newSelectedTokenId
                )?.tokenType?.length > 0 && (
                  <>
                    <Typography className="label">Token type</Typography>
                    <Select
                      className="select"
                      style={{ marginBottom: "12px" }}
                      placeholder="Choose type"
                      defaultValue={"ERC-20"}
                      disabled={newSelectedTokenId === ""}
                    >
                      {newSelectedTokenId &&
                        newTokenMetaData
                          .filter((token) => token.id === newSelectedTokenId)[0]
                          .tokenType?.map((tokenType) => (
                            <Select.Option value={tokenType}>
                              {tokenType}
                            </Select.Option>
                          ))}
                    </Select>
                  </>
                )}
                <Typography className="label">
                  {newTokenMetaData?.find(
                    (token) => token?.id === newSelectedTokenId
                  )?.network === "APTOS"
                    ? "Coin Type"
                    : "Contract address"}
                </Typography>
                <Space
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Input
                    type="text"
                    className="input"
                    placeholder={
                      newTokenMetaData?.find(
                        (token) => token?.id === newSelectedTokenId
                      )?.network === "APTOS"
                        ? "Enter coin type..."
                        : "Enter contract address..."
                    }
                    value={contractAddress}
                    onChange={(e) => setContractAddress(e.target.value)}
                  />
                  <Button
                    style={{ borderRadius: "12px" }}
                    disabled={contractAddress.trim() === ""}
                    onClick={getContractDetailsHandler}
                  >
                    Look up
                  </Button>
                </Space>
                {lookUpStep && (
                  <>
                    <Typography className="label" style={{ marginTop: "12px" }}>
                      Token name
                    </Typography>
                    <div className="read-only-capsule">
                      {contractDetails?.name}
                    </div>
                    <div className="grid-wrapper">
                      <Typography className="label">Symbol</Typography>
                      <Typography className="label">Decimals</Typography>
                      <span className="read-only-capsule">
                        {contractDetails?.symbol}
                      </span>
                      <span className="read-only-capsule">
                        {contractDetails?.decimals}
                      </span>
                    </div>
                  </>
                )}
              </>
            )}
          </CardBody>
          <CardFooter>
            <Button
              style={{ borderRadius: "8px", marginRight: "8px" }}
              onClick={() => setShowCard(false)}
            >
              Cancel
            </Button>
            {!addTokenStep ? (
              <Button
                type="primary"
                style={{ borderRadius: "8px" }}
                onClick={() => {
                  setSelectedTokenAmt(tokenAmount);
                  setSelectedTokenId(tokenId);
                  setShowCard(false);
                }}
                disabled={tokenId === "" || tokenAmount == 0}
              >
                Done
              </Button>
            ) : (
              <Button
                type="primary"
                style={{ borderRadius: "8px" }}
                onClick={addNewTokenHandler}
                disabled={!contractDetails?.name}
              >
                Add token
              </Button>
            )}
          </CardFooter>
        </Card>
      )}
    </PopupCard>
  );
};

export default AddBounty;

import React, { useRef, useState } from "react";
import { Button, Input, Space, Row } from "antd";
import QuillEditor from "../QuillEditor";
import { Modal, Footer } from "../../styles/ModalStyles";
import styled from "styled-components";
import DueDate from "../DueDate";
import moment from "moment";
import IconWrapper from "../IconWrapper";
import { BountyFilesAttachment } from "../CreateBountyModal";
import MultipleAssigneeSelector from "../BountyComponents/MultipleAssigneeSelector";
import StatusPill from "./Task/StatusPill";
import Attachments from "../Common/Attachments";
import { PaperClipIcon } from "../Icones";

const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;

const CreateSubtaskModal = ({
  isModalVisible,
  handleCancel,
  title,
  defaultData,
  updateSubtasks,
  isEdit = false,
  setUpdatedFieldsNames = () => {},
}) => {
  const [status, setStatus] = useState(defaultData?.status || "TODO");
  const [description, setDescription] = useState(
    defaultData?.description || ""
  );
  const [submissionDate, setSubmissionDate] = useState(
    defaultData?.submissionDate || ""
  );
  const [localTitle, setLocalTitle] = useState(title);
  const [assignees, setAssignees] = useState(
    defaultData?.assignee ? defaultData?.assignee : []
  );

  const [files, setFiles] = useState(defaultData?.files || []);

  const fileUploadRef = useRef(null);

  const [attachmentsToBeDeleted, setAttachmentsToBeDeleted] = useState(
    defaultData?.deletedAttachments || []
  );

  const isOldSubTask = isEdit && !defaultData.isNew;

  const defaultAttachments =
    isOldSubTask &&
    defaultData?.attachments?.filter(
      (attachment) => !attachmentsToBeDeleted.includes(attachment)
    );

  return (
    <StyledModalWrapper>
      <Modal
        centered
        width="840px"
        destroyOnClose
        // title={<Cre  ateBountyTitle setStatus={setStatus} status={status} />}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Footer key="modal-footer">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Space>
                <input
                  hidden
                  ref={fileUploadRef}
                  type="file"
                  style={{ width: "0", height: "0" }}
                  onChange={(e) => {
                    const newFile = e.target.files[0];
                    setFiles((prev) => [...prev, newFile]);
                    setUpdatedFieldsNames();
                  }}
                />
                <Button
                  className="borderless-button"
                  shape="circle"
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                >
                  <IconWrapper width="18px">
                    <PaperClipIcon />
                  </IconWrapper>
                </Button>

                <DueDate
                  defaultValue={defaultData.submissionDate}
                  onChange={(date, time) => {
                    if (date) {
                      setUpdatedFieldsNames();
                      const dateTime = moment(
                        date.format("YYYY-MM-DD") + ` ${time ? time : "23:59"}`
                      ).format("YYYY-MM-DD HH:mmZ");
                      console.log(date, time, dateTime);
                      setSubmissionDate(dateTime);
                    } else setSubmissionDate(null);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    background: "#F4F2F2",
                    borderRadius: "16px",
                    padding: "4px 14px",
                    gap: "10px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 500,
                      color: "#64748B",
                      margin: "auto 0",
                    }}
                  >
                    Assignees
                  </span>
                  <MultipleAssigneeSelector
                    pillBg="#F4F2F2"
                    assignees={assignees}
                    setAssignees={setAssignees}
                  />
                </div>
              </Space>
              <Button
                className="rounded-corner"
                type="primary"
                style={{ marginLeft: "50px" }}
                onClick={() => {
                  const modified = {
                    description,
                    status,
                    title: localTitle,
                    submissionDate,
                    files,
                    reviewers: [defaultData?.reviewers?.[0]],
                    assignee: assignees,
                    tmpId: defaultData.tmpId,
                    id: defaultData.id,
                  };
                  if (attachmentsToBeDeleted.length > 0)
                    modified.deletedAttachments = attachmentsToBeDeleted;

                  console.log(modified);
                  updateSubtasks(modified);
                  handleCancel();
                }}
              >
                Save
              </Button>
            </Row>
          </Footer>,
        ]}
      >
        <div style={{ display: "flex", margin: "24px 0 10px 20px" }}>
          <StatusPill
            status={status}
            updateStatus={setStatus}
            disabled={false}
          />
        </div>
        <Input
          style={{
            paddingLeft: "20px",
            fontSize: "21px",
            fontWeight: "600",
          }}
          placeholder="Add the title of your subtask here"
          size="large"
          className="ip-address"
          value={localTitle}
          onChange={(e) => setLocalTitle(e.currentTarget.value)}
          bordered={false}
        />

        <QuillEditor
          value={description}
          isBordered={true}
          onChange={(e) => {
            setUpdatedFieldsNames();
            setDescription(e);
          }}
          placeholder="Add details of your subtask here"
          height="200px"
          style={{
            padding: "0px 0px 4px 0px",
            resize: "none",
            overflowY: "auto",
            margin: "10px 20px",
          }}
        />

        {isOldSubTask && (
          <div style={{ padding: "4px 20px" }}>
            <Attachments
              attachments={defaultAttachments}
              onDelete={(attachment) => {
                setAttachmentsToBeDeleted((prev) => [...prev, attachment]);
              }}
            />
          </div>
        )}
        {/* </StyledQuillEditior> */}
        {files.length > 0 && (
          <BountyFilesAttachment files={files} setFiles={setFiles} />
        )}
      </Modal>
    </StyledModalWrapper>
  );
};

export default CreateSubtaskModal;

import { labeStyling } from "./InputField";
import Web3 from "web3";
import { toast } from "react-toastify";
import customAxios from "../../api";
import { Button, Col, Row, Spin, Tooltip } from "antd";
import metamaskIcon from "../../assets/iconSvg/metamask.png";
import Card, { Title } from "../Common/Card";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MagnifyingGlassIcon, XMarkIcon, PlusIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import petraIcon from "./../../assets//images/common/petra.jpg";
import walletIcon from "./../../assets//images/common/wallet.png";
import lensIcon from "./../../assets//images/common/lens.svg";

import { DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import { ReactComponent as SolanaIcon } from "../../assets/images/login/solana-sol-logo.svg";
import neraIcon from "../../assets/images/logos/Near.png";

import {
  CopyOutlined,
  ExclamationCircleFilled,
  GithubFilled,
  GoogleOutlined,
  InfoCircleFilled,
  LoadingOutlined,
  TwitterOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
// import { Modal } from "antd";
import { Modal } from "../../styles/ModalStyles";
import walletConnectIcon from "../../assets/iconSvg/wallet-connect.svg";
import googleIcon from "./../../assets/iconSvg/google-icon.svg";
import { UserContext } from "../../layouts/app/UserContext";
import { useEthers } from "@usedapp/core";
import { getAddress } from "ethers/lib/utils";
import { getDiscordUri } from "../../features/login/Components/LoginModal";

import { Fragment } from "react";
import { useWalletConnect } from "../../layouts/app/WalletConnectContext";
import { walletConnect } from "../../utils/connectors";
import {
  connectTwitter,
  copyTextToClipboard,
  generateSignature,
} from "../../utils";
import useSolana from "../../hooks/useSolana";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useGoogleLogin } from "@react-oauth/google";
import IconWrapper from "../IconWrapper";
import InputField from "../Settings/DaoProfile/InputField";
import useWindowSize from "../../hooks/useWindowSize";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import useNear from "../../hooks/useNear";
import TwitterAlreadyConnectedModal from "./TwitterAlreadyConnectedModal";
import useLens from "../../hooks/useLens";

export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 20,
      marginTop: "3px",
    }}
    spin
  />
);
const DiscordConnectButton = styled(Button)`
  &:hover {
    background: #1e429f !important;
  }
`;
const MetamaskConnectButton = styled(Button)`
  &:hover {
    background: #f4f2f2 !important;
  }
`;

const WalletConnectButton = styled(Button)`
  &:hover {
    background: #f4f2f2 !important;
  }
`;
const StyledButton = styled(Button)`
 .cross{
  display:none;
 }
 .tick{
  display:block;
 }
  :hover{
    .cross {
    display:block;
  }
  .tick{
  display:none;
 }
  }
`;
const StyledWalletMultiButton = styled(WalletMultiButton)`
 .cross{
  display:none;
 }
 .tick{
  display:block;
 }
  :hover{
    .cross {
    display:block;
  }
  .tick{
  display:none;
 }
  }
`;




const ConnectedAccounts = ({
  isDiscordConnected,
  isPetraConnected,
  isMetamaskConnected,
  noOfConnectedAccounts,
  isGoogleConnected,
  isTwitterConnected,
  isPhantomConnected,
  isGithubConnected,
  publicKey,
  isNearConnected,
  isLensConnected
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keysData, setKeysData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useWindowSize();
  const [githubModalOpen, setGithubModalOpen] = useState(false);
  const [githubModalActiveStep, setGithubModalActiveStep] = useState(0);
  const navigate = useDaoLensNavigate();
  const { onLoginClick } = useLens((access_token) => {
    localStorage.setItem("access_token", access_token);
    setLoading(false);
    reload();
  },
    () => {
      setLoading(true);
    },
    () => {
      setLoading(false);
    })
  const [
    isTwitterAccountAlreadyConnectedModalOpen,
    setIsTwitterAccountAlreadyConnectedModalOpen,
  ] = useState(false);
  const [existingTwitterUserDetails, setExistingTwitterUserDetails] =
    useState(undefined);

  const [isInConnectWalletFlow, setIsInConnectWalletFlow] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { onClickActivate, isWalletConnected, setWalletConnected } =
    useWalletConnect();
  const { wallet, disconnect } = useSolana(
    (access_token) => {
      localStorage.setItem("access_token", access_token);
      setLoading(false);
      reload();
    },
    () => {
      setLoading(true);
    },
    () => {
      setLoading(false);
      toast.error("Solana Account is already connected with another account");
    },
    "connect"
  );
  const { modal, signOut } = useNear(
    (access_token) => {
      localStorage.setItem("access_token", access_token);
      setLoading(false);
      reload();
    },
    () => {
      setLoading(true);
    },
    () => {
      setLoading(false);
      toast.error("Near Account is already connected with another account");
    },
    isNearConnected
  );

  const { account } = useEthers();

  useEffect(() => {
    if (searchParams.get("oauth_token") && searchParams.get("oauth_verifier")) {
      customAxios
        .post("/auth/connect/twitter", {
          oauthToken: searchParams.get("oauth_token"),
          oauthVerifier: searchParams.get("oauth_verifier"),
        })
        .then((res) => {
          if (res.data.access_token) {
            localStorage.setItem("access_token", res.data.access_token);
            reload();
          } else if (res.data.userAlreadyExist) {
            setExistingTwitterUserDetails(res.data.userAlreadyExist);
            setIsTwitterAccountAlreadyConnectedModalOpen(true);
          } else throw new Error("Something went wrong");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [searchParams]);
  useEffect(() => {
    const web3 = new Web3(walletConnect);
    if (
      isWalletConnected &&
      // !web3.currentProvider.connected &&
      isInConnectWalletFlow
    ) {
      const authWalletConnect = async () => {
        try {
          setWalletConnected(true);
          customAxios
            .post("auth/metamask", {
              publicAddress: account,
            })
            .then((response) => {
              handleSignMessage({
                publicAddress: account,
                ...response.data,
                isWalletConnect: true,
              });
            })
            .catch((err) => {
              console.log(err);
              toast.error("Some error occured");
            });
        } catch (error) {
          console.error(error);
        }
      };
      authWalletConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isInConnectWalletFlow]);
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleGoogle(tokenResponse);
    },
    flow: "implicit",
  });

  const { reload } = useContext(UserContext);
  const disconnectAccount = async (type) => {
    setLoading(true);
    try {
      await customAxios.post("auth/disconnect", { type });
      reload();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleSignMessage = ({
    publicAddress,
    message,
    token,
    isWalletConnect = false,
  }) => {
    let web3;
    if (!isWalletConnect) {
      web3 = new Web3(Web3.givenPovider || window.ethereum);
    } else {
      web3 = new Web3(walletConnect);
    }

    web3.eth.personal
      .sign(
        web3.utils.fromUtf8(`I am signing my one-time nonce: ${message}`),
        publicAddress
      )
      .then((res) => {
        customAxios
          .post("auth/connect/metamask/login", {
            token: "Bearer " + token,
            signature: res,
            isWalletConnect: isWalletConnect,
          })
          .then((response) => {
            localStorage.setItem("access_token", response.data.access_token);
            setLoading(false);
            reload();
          })

          .catch((err) => {
            toast.error(
              "Metamask Account is already connected with another account"
            );
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleGenerateSignature = (secretKey, message) => {
    const { toBePasted, signature } = generateSignature(
      secretKey,
      "https://github.com/" + message
    );
    setKeysData((prev) => ({
      ...prev,
      text: toBePasted,
      signature: signature,
    }));
    setGithubModalActiveStep(1);
  };
  const handleVerifySignature = (message, signature) => {
    customAxios
      .post("/auth/verify/signature", {
        message: "https://github.com/" + message,
        signature,
      })
      .then((res) => {
        console.log("verify", res.data);
        if (res.data.isVerified && res.data.doesSignatureExist) {
          setGithubModalOpen(false);
          toast.success("Verified successfully. Github connected.");
          reload();
        } else if (res.data.isVerified) {
          toast.error(
            "Please check your username and make you sure you've pasted the signature on readme.md"
          );
        } else {
          toast.error("Private key doesn't match.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const connectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          // Return the address of the wallet
          customAxios
            .post("auth/connect/metamask", {
              publicAddress: getAddress(res[0]),
            })
            .then((response) => {
              handleSignMessage({
                publicAddress: getAddress(res[0]),
                ...response.data,
                isWalletConnect: false,
              });
            })
            .catch((err) => {
              console.log("error");
              setLoading(false);
            });
        })
        .catch((err) => toast.error(err.message));
    } else {
      toast.error("Install metamask extension!!");
    }
  };
  useEffect(() => {
    const code = searchParams.get("code");
    if (code)
      customAxios
        .get(`auth/connect/discord/redirect?code=${code}`)
        .then((res) => {
          localStorage.setItem("access_token", res.data.access_token);
          navigate("/app/profile");
          setLoading(false);
          reload();
        })
        .catch((error) => {
          console.log(error);
          setSearchParams({});
          toast.error(
            "Discord server is already connected with another account"
          );
          setLoading(false);
        });
  }, []);

  const ConnectMetaMask = () => {
    return (
      <MetamaskConnectButton
        style={{
          ...labeStyling,
          color: "#014737",
          padding: "22px 8px",
          gap: "8px",
          display: "inline-flex",
          border: `1px solid ${isMetamaskConnected ? "#0E9F6E" : "#E2E8F0"}`,
          alignItems: "center",
          borderRadius: "8px",
          background: isMetamaskConnected ? "#F3FAF7" : "",
          marginBottom: "12px",
          cursor:
            isMetamaskConnected && !isDiscordConnected ? "not-allowed" : "",
        }}
        onClick={() => {
          if (!isMetamaskConnected) {
            setLoading(true);
            connectMetamask();
          }
        }}
      >
        {" "}
        <img src={metamaskIcon} alt="icon" height="16" />
        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: "500" }}>
          Metamask
        </p>
      </MetamaskConnectButton>
    );
  };

  const WalletConnect = ({ setIsInConnectWalletFlow }) => {
    const handleConnectWallet = () => {
      onClickActivate();
      setIsInConnectWalletFlow?.(true);
    };

    return (
      <WalletConnectButton
        style={{
          ...labeStyling,
          color: "#014737",
          padding: "22px 8px",
          gap: "8px",
          display: "inline-flex",
          border: `1px solid ${isMetamaskConnected ? "#0E9F6E" : "#E2E8F0"}`,
          alignItems: "center",
          borderRadius: "8px",
          background: isMetamaskConnected ? "#F3FAF7" : "",
          marginBottom: "12px",
          cursor:
            isMetamaskConnected && !isDiscordConnected ? "not-allowed" : "",
        }}
        onClick={handleConnectWallet}
      >
        <img src={walletConnectIcon} alt="icon" height="16" />
        <p style={{ marginBottom: "0px", fontSize: "14px", fontWeight: "500" }}>
          Wallet Connect
        </p>
      </WalletConnectButton>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleGoogle = async (data) => {
    customAxios
      .post("/auth/connect/google", {
        idToken: data.access_token,
      })
      .then((res) => {
        localStorage.setItem("access_token", res.data.access_token);
        setLoading(false);
        reload();
      })
      .catch((err) => {
        toast.error(
          "Google account is  already connected with another account"
        );
        setLoading(false);
      });
  };
  const connectPetra = () => {
    if (window.aptos) {
      window.aptos
        .connect()
        .then((res) => {
          // Return the address of the wallet
          console.log("tt", res);
          const publicAddress = res.address;
          customAxios
            .post("auth/connect/aptos", {
              publicAddress: publicAddress,
            })
            .then((response) => {
              handleSignMessageForPetra({
                publicAddress: publicAddress,
                ...response.data,
                publicKey: res.publicKey,
              });
            })
            .catch((err) => {
              console.log("error", err);
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.error("Install Petra wallet extension!!");
    }
  };
  const handleSignMessageForPetra = ({ message, token }) => {
    window.aptos
      .signMessage({
        message: "I am signing my one-time nonce:",
        nonce: message,
      })
      .then((res) => {
        window.aptos.account().then(({ publicKey }) => {
          customAxios
            .post("auth/connect/aptos/login", {
              token: "Bearer " + token,
              signature: res.signature,
              publicKey: publicKey,
            })
            .then((response) => {
              localStorage.setItem(
                "access_token",
                response?.data?.access_token
              );
              reload();
            })
            .catch((err) => {
              toast.error(
                "Petra wallet already connected with another account"
              );
            });
        });
      });
  };
  let accounts = [{
    flag: isMetamaskConnected,
    iconUrl: walletIcon,
    onConnect: async () => {
      setIsModalOpen(true)
    },
    onDisconnect: () => {
      disconnectAccount("METAMASK");
      setIsModalOpen(false);
    }

  },
  {
    flag: isLensConnected,
    iconUrl: lensIcon,
    onConnect: async () => {
      onLoginClick();
    },
    onDisconnect: () => {
      disconnectAccount("LENS");
    }

  },
  {

    flag: isNearConnected,
    iconUrl: neraIcon,
    onConnect: async () => {
      modal.show();

    },
    onDisconnect: async () => {
      await signOut();
      await disconnectAccount("NEAR");
    }

  },
  {

    flag: isPetraConnected,
    iconUrl: petraIcon,
    onConnect: async () => {
      connectPetra();

    },
    onDisconnect: async () => {
      disconnectAccount("APTOS");
    }

  },


  {
    flag: isTwitterConnected,
    icon: <TwitterOutlined
      style={{
        color: "#3F83F8",
        fontSize: "16px",
        marginLeft: "-2px",
      }}
    />,
    onConnect: async () => {
      connectTwitter({});

    },
    onDisconnect: async () => {
      disconnectAccount("TWITTER");
    },
    isOptional: true,
  },
  {
    flag: isDiscordConnected,
    icon: <i
      class="fa-brands fa-discord"
      style={{
        color: "rgb(59, 130, 246)",
        marginLeft: "3px",
        fontSize: "14px",
      }}
    ></i>,
    onConnect: async () => {
      setLoading(true);
      window.open(getDiscordUri(), "_self");

    },
    onDisconnect: async () => {
      disconnectAccount("DISCORD");
    },
    isOptional: true,
  },

  {
    flag: isGoogleConnected,
    iconUrl: googleIcon,
    onConnect: async () => {
      googleLogin();
    },
    onDisconnect: async () => {
      disconnectAccount("GOOGLE");
    },
  },
  {
    flag: isGithubConnected,
    icon: <IconWrapper>
      <GithubFilled style={{ fontSize: "16px" }} />
    </IconWrapper>,
    onConnect: async () => {
      if (publicKey) {
        setGithubModalActiveStep(0);
        setGithubModalOpen(true);
      } else {
        toast.info("Please generate your keys to connect to github.");
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
    onDisconnect: async () => {
    },
    isOptional: true,
    isParmanent: true
  },
  ]
  return (
    <Fragment>
      <Modal
        isMobile={isMobile}
        title={
          <h2
            style={{
              paddingLeft: "13px",
              fontSize: "16px",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            Connect a wallet
          </h2>
        }
        visible={isModalOpen && !isMetamaskConnected}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setIsModalOpen(false)}
        width="420px"
      >
        <div
          style={{ padding: "20px", display: "flex", flexDirection: "column" }}
        >
          <ConnectMetaMask />
          <WalletConnect setIsInConnectWalletFlow={setIsInConnectWalletFlow} />
        </div>
      </Modal>
      <TwitterAlreadyConnectedModal
        isOpen={isTwitterAccountAlreadyConnectedModalOpen}
        setIsOpen={setIsTwitterAccountAlreadyConnectedModalOpen}
        userAlreadyExist={existingTwitterUserDetails}
      />
      <Card>
        <Title
          title={
            <>
              Connected accounts{" "}
              {noOfConnectedAccounts
                ? `(${+noOfConnectedAccounts + (isTwitterConnected ? 1 : 0)})`
                : ""}
            </>
          }
          style={{ marginBottom: "12px" }}
        />
        <div
          className="flex flex-wrap gap-4"

        >
          {accounts.map((account, index) => {
            return <StyledButton
              key={index}
              icon={account.icon ? account.icon :
                <IconWrapper>
                  <img
                    src={account.iconUrl}
                    alt="Wallet"
                    style={{
                      width: "20px",
                    }}
                  />
                </IconWrapper>
              }
              style={{
                ...labeStyling,
                color: "#014737",
                padding: "8px 12px",
                gap: "6px",
                display: "inline-flex",
                border: "0px",
                width: "56px",
                height: "56px",
                position: "relative",
                borderRadius: "8px",
                justifyContent: "center",
                alignItems: "center",
                background: account.flag ? "#F3FAF7" : "#F4F7F9",
              }}
              onClick={() => {
                if (!account.flag) {
                  account.onConnect()
                }
              }}
            >
              <div className="absolute" style={{ top: "-4px", right: "-4px" }}>
                {loading ? <Spin indicator={antIcon} /> :
                  <>
                    {!account.flag ? <CheckCircleIcon
                      className={`text-gray-400`}
                      width="15px"
                    /> : (!account.isParmanent && (noOfConnectedAccounts > 1 || account.isOptional)) ?
                      <>
                        <XCircleIcon
                          className="text-red-400 cross"
                          onClick={account.onDisconnect}
                          width="18px"
                        />
                        <CheckCircleIcon
                          className={`text-green-400 tick`}
                          width="18px"
                        />
                      </> : <CheckCircleIcon
                        className={`text-green-400`}
                        width="18px"
                      />}
                  </>

                }
              </div>
            </StyledButton>

          })}



          {isPhantomConnected ? (
            <StyledButton
              block={true}
              icon={
                <SolanaIcon style={{ width: "18px" }} />
              }
              style={{
                ...labeStyling,
                color: "#014737",
                padding: "8px 12px",
                gap: "6px",
                display: "inline-flex",
                border: "0px",
                width: "56px",
                height: "56px",
                position: "relative",
                borderRadius: "8px",
                justifyContent: "center",
                alignItems: "center",
                background: "#F4F7F9",
              }}
            >
              <div className="absolute" style={{ top: "-4px", right: "-4px" }}>
                {loading ? <Spin indicator={antIcon} /> : noOfConnectedAccounts > 1 ?
                  <>
                    <XCircleIcon
                      className="text-red-400 cross"
                      onClick={() => {
                        disconnectAccount("PHANTOM");
                        disconnect();
                      }}
                      width="18px"
                    />
                    <CheckCircleIcon
                      className={`text-green-400 tick`}
                      width="18px"
                    />
                  </> : <CheckCircleIcon
                    className={`text-green-400`}
                    width="18px"
                  />}
              </div>
            </StyledButton>
          ) : (
            <StyledWalletMultiButton
              style={{
                ...labeStyling,
                color: "#014737",
                padding: "8px 12px",
                gap: "6px",
                display: "inline-flex",
                border: "0px",
                width: "56px",
                height: "56px",
                position: "relative",
                borderRadius: "8px",
                justifyContent: "center",
                alignItems: "center",
                background: "#F4F7F9",
              }}
              className="connect-with-solana-btn"
            >
              {!wallet && (
                <SolanaIcon style={{ width: "18px" }} />
              )}
              <div className="absolute" style={{ top: "-4px", right: "-4px" }}>

                {loading ? <Spin indicator={antIcon} /> :
                  <CheckCircleIcon
                    className={`text-gray-400`}
                    width="15px"
                  />

                }
              </div>
            </StyledWalletMultiButton>
          )}
        </div>
      </Card>
      {
        githubModalOpen ? (
          <Modal
            width={"600px"}
            isMobile={isMobile}
            title={<h3 style={{ paddingLeft: "12px" }}>Connect github</h3>}
            style={{ maxHeight: "fit-content" }}
            visible={githubModalOpen}
            onCancel={() => {
              setGithubModalActiveStep(0);
              setKeysData(null);

              setGithubModalOpen(false);
            }}
            destroyOnClose={true}
            footer={
              githubModalActiveStep ? (
                <Button
                  type="primary"
                  onClick={() =>
                    handleVerifySignature(
                      keysData?.githubUrl,
                      keysData?.signature
                    )
                  }
                  style={{ borderRadius: "8px" }}
                >
                  Verify
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() =>
                    handleGenerateSignature(
                      keysData?.privateKey,
                      keysData.githubUrl
                    )
                  }
                  style={{ borderRadius: "8px" }}
                >
                  Generate Signature
                </Button>
              )
            }
          >
            <Row style={{ padding: "20px 24px", borderTop: "1px solid #E2E8F0" }}>
              <Col xs={24}>
                <InputField
                  prefix={"https://github.com/"}
                  inputStyle={{ color: "gray", fontWeight: "400" }}
                  onChange={(e) => {
                    if (e.target.value.includes("https://"))
                      setKeysData((prev) => ({
                        ...prev,
                        githubUrl: e.target.value.split("https://github.com/")[1],
                      }));
                    else
                      setKeysData((prev) => ({
                        ...prev,
                        githubUrl: e.target.value,
                      }));
                  }}
                  placeholder="alexJames1"
                  value={keysData?.githubUrl}
                  label="Your Github username"
                ></InputField>
              </Col>
              <Col xs={24}>
                <InputField
                  value={keysData?.privateKey}
                  type="password"
                  onChange={(e) => {
                    setKeysData((prev) => ({
                      ...prev,
                      privateKey: e?.target.value,
                    }));
                  }}
                  placeholder="Paste your private key here"
                  label="Your Private Key"
                ></InputField>
              </Col>
              {githubModalActiveStep ? (
                <Col xs={24}>
                  <InputField
                    inputProps={{
                      readOnly: true,
                      suffix: (
                        <Button
                          type="text"
                          style={{ color: "#9CA3AF", margin: 0, padding: 0 }}
                          onClick={() => {
                            copyTextToClipboard(keysData?.text).then((res) => {
                              toast.success("Signature copied");
                            });
                          }}
                        >
                          <IconWrapper>
                            <DocumentDuplicateIcon />
                          </IconWrapper>
                        </Button>
                      ),
                    }}
                    label="Signature (Paste this on top of your Github profile’s README)"
                    value={keysData?.text}
                  ></InputField>
                </Col>
              ) : null}
              {githubModalActiveStep ? (
                <Col xs={24}>
                  <div
                    style={{
                      padding: "12px 16px",
                      background: "#FFFBEB",
                      borderRadius: "8px",
                      marginBottom: "12px",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "8px",
                      border: "1px solid #F59E0B",
                    }}
                  >
                    {/* <IconWrapper>
                <ExclamationCircleFilled
                  style={{ fontSize: "18px", color: "#F59E0B" }}
                />
              </IconWrapper> */}
                    <h5
                      style={{
                        color: "#F59E0B",
                        lineHeight: "16px",
                        marginBottom: "0px",
                      }}
                    >
                      Paste the signature on your Github profile’s ‘README’. See
                      how to make a readme.md{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "#1890ff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(
                            "https://docs.github.com/en/account-and-profile/setting-up-and-managing-your-github-profile/customizing-your-profile/managing-your-profile-readme",
                            "_blank"
                          );
                        }}
                      >
                        here
                      </span>
                    </h5>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Modal>
        ) : null
      }
    </Fragment >
  );
};

export default ConnectedAccounts;

import PropTypes from "prop-types";

const IconWrapper = ({ children, width = "", style={} }) => (
  <span
    role="img"
    className="anticon"
    style={{ width: width || "15px", ...style }}
  >
    {children}
  </span>
);

IconWrapper.proptype = {
  children: PropTypes.element,
  width: PropTypes.string,
};

export default IconWrapper;

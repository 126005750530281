import { Select, Col, Row, Skeleton } from "antd";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/common/chevrondown.svg";
import { useEffect, useState } from "react";
import customAxios from "../../api";
import { ReactComponent as BountyIcon } from "../../assets/images/search/bounty.svg";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";

const { Option } = Select;
const Bounty = ({ ele, idx, handleConditionDetailChange }) => {
  const [bountyList, setBountyList] = useState([]);
  const { daoDetails, isSpace } = useDaoContext();

  const [loading, setLoading] = useState(false);
  let daoId =
    isSpace && window.location.pathname.includes("settings/profile")
      ? daoDetails.parentDaoId
      : daoDetails.daoId;
  useEffect(() => {
    if (!daoId) return;
    setLoading(true);
    customAxios
      .post(`bounty/fetch`, { daoId })
      .then(({ data }) => {
        setBountyList(data.res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [daoId]);

  return (
    <Row
      gutter={["8"]}
      style={{
        padding: "2rem 12px 15px 12px",
        borderRadius: "12px",
        background: "#eeeeee",
        rowGap: "8px",
      }}
    >
      {loading ? (
        <>
          <Col xs={24} sm={6}>
            <Skeleton.Button active block />
          </Col>
          <Col xs={24} sm={12}>
            <Skeleton.Button active block />
          </Col>
          <Col xs={24} sm={6}>
            <Skeleton.Button active block />
          </Col>
        </>
      ) : (
        <>
          <Col xs={24} sm={6}>
            <div
              style={{
                border: "1px solid #E2E8F0",
                background: "#FFFFFF",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 12px",
                gap: "4px",
                height: "32px",
                marginRight: "4px",
              }}
            >
              <CurrencyDollarIcon className="text-teal-500 h-5 w-5 stroke-2" />
              If Bounty
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <Select
              placeholder="Select the Quest"
              suffixIcon={<ChevronDownIcon />}
              value={ele?.bountyId}
              style={{ width: "100%" }}
              onChange={(e) => {
                handleConditionDetailChange("bountyId", e, idx);
              }}
            >
              {bountyList.map((ele) => {
                return <Option value={ele.id}>{ele.title}</Option>;
              })}
            </Select>
          </Col>
          <Col xs={24} sm={6}>
            <div
              style={{
                border: "1px solid #E2E8F0",
                background: "#FFFFFF",
                borderRadius: "8px",
                padding: "0px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                height: "32px",
              }}
            >
              is Completed
            </div>
          </Col>
        </>
      )}
    </Row>
  );
};

export default Bounty;

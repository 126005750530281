import React, { useContext, useEffect, useMemo, useState } from "react";
import { Collapse, Button, Row, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import "../ComponentStyles/CourseDetails.scss";
import EmptyComponent from "./EmptyComponent";

import GoBackButton from "../InternalComponents/GoBackButton";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { DaoContext } from "../../layouts/app/DaoContext";
import CommentSection from "../CommentsComponents/CommentSection";
import { postMixpanel } from "../../utils";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import DeleteEntityModal from "../Common/DeleteEntityModal";
import CourseCard from "./CourseCard";
import CreateCourseContentModal from "./CreateCourseContentModal";
import CourseContentCard from "./CourseContent/CourseContentCard";
import greenCheck from "../../assets/iconSvg/greenCheckmark.svg";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import IconWrapper from "../IconWrapper";
import { PencilIcon } from "@heroicons/react/24/solid";
import SlidingContextSidebar from "../SlidingContextSidebar";
import Share from "../BountyComponents/Share";
import {
  DEFAULT_SORTING_TYPE_LIST,
  MD_SCREEN_BREAKPOINT,
} from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../Common/ProfileCard";
const StyledCollapse = styled(Collapse)`
  & .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 10px;
    padding-top: 0 !important;
    padding-bottom: 6px !important;
  }
`;
const { Panel } = Collapse;

const CourseDetails = ({
  courseId,
  courseDetails,
  fetchCourseDetailsService,
}) => {
  const { windowSize } = useWindowSize();
  const [sortBy, setSortBy] = useState("Latest");

  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const navigate = useDaoLensNavigate();
  const [edit, setEdit] = useState(false);
  const {
    isAdmin: canManageCourse,
    isCurrentDaoJoined,
    daoId,
    daoName,
  } = useContext(DaoContext);
  const [comments, setComments] = useState([]);
  const [items, setItems] = useState([]);

  function handleDragEnd(event) {
    const { active, over } = event;
    const itemIds = items.map((ele) => ele.id);
    const oldIndex = itemIds.indexOf(active.id);
    const newIndex = itemIds.indexOf(over.id);

    if (active.id !== over.id) {
      setItems((prev) => {
        let res = arrayMove(prev, oldIndex, newIndex);
        return res;
      });
    }
    customAxios
      .post("/courses/reorder/details", {
        courseDetailId: active.id,
        order: newIndex + 1,
        type: newIndex > oldIndex ? "DOWN" : "UP",
        daoId: daoId,
        courseId: courseDetails[0]?.id,
      })
      .catch((err) => {
        console.log("error");
        toast.error("Some error occured");
        fetchCourseDetailsService();
      });
  }
  useTrackPageView(courseId);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const deleteCourseHandler = () => {
    customAxios
      .delete(`courses/delete?coursesId=${courseId}`)
      .then((res) => {
        toast.success("Course deleted successfully");
        navigate(-1);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log("error");
      });
  };

  const fetchComments = () => {
    customAxios
      .post("/coursecomments/fetch", {
        courseId: courseId,
        parentId: "0",
        sortBy: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortBy,
        sortOrder: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortOrder,
      })
      .then((res) => {
        setComments([...res.data.courseComments.reverse()]);
      })
      .catch((err) => console.log(err));
  };

  const postComment = async (
    commentText,
    mentions,
    parentId,
    level2ParentId = "0"
  ) => {
    await customAxios.post("/coursecomments/create", {
      courseId: courseId,
      parentId,
      description: commentText,
      userTagged: mentions,
      level2ParentId,
    });
    fetchComments();
  };

  const enrolNowButtonHandler = () => {
    customAxios
      .post("/courses/join", {
        courseId: courseId,
      })
      .then((res) => {
        postMixpanel("track", "enrolled_in_course", {
          dao_id: daoId,
          course_title: courseDetails[0]?.name,
          course_id: courseDetails[0]?.id,
        });
        fetchCourseDetailsService();
        toast.success("Successfully enrolled in this course");
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };
  useEffect(() => {
    // ! Fetch comments
    fetchComments();
    if (courseDetails?.length > 0) {
      setItems(
        courseDetails[0].contentDetails.sort(
          (a, b) => parseInt(a.order) - parseInt(b.order)
        )
      );
      customAxios
        .get(`courses/completed?courseId=${courseDetails[0].id}`)
        .then((res) => {
          setSubmissions(res.data.res);
        });
    }
  }, [courseDetails?.length]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState("2");
  const [submissions, setSubmissions] = useState([]);

  return (
    <div>
      {courseDetails?.length > 0 && (
        <GoBackButton
          showIcon
          buttonText={"Go Back"}
          clickHandler={() => {
            navigate(-1);
          }}
        />
      )}
      <DeleteEntityModal
        note="This course will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          deleteCourseHandler();
        }}
      />
      {createModalVisible ? (
        <CreateCourseContentModal
          onSubmit={() => {
            fetchCourseDetailsService();
          }}
          isModalVisible={createModalVisible}
          handleCancel={() => setCreateModalVisible(false)}
        />
      ) : null}
      {courseDetails?.length > 0 &&
        courseDetails?.map((course) => (
          <div
            key={course.id}
            style={{
              background: "white",
              borderRadius: "16px",
              marginTop: "12px",
            }}
          >
            <CourseCard
              daoName={daoName}
              daoId={daoId}
              course={course}
              canManageCourse={canManageCourse}
              fetchCourseDetailsService={fetchCourseDetailsService}
              descriptionExpanded
            />

            <div
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              {items.length === 0 && (
                <EmptyComponent
                  text="No course content yet"
                  subtext="Start by adding your first document or link to this course."
                />
              )}
              {canManageCourse &&
                (edit || course?.contentDetails?.length === 0) && (
                  <div
                    style={{
                      background: "#EBF5FF",
                      border: "1px dashed #76A9FA",
                      borderRadius: "12px",
                      font: "normal 500 14px/20px Inter",
                      color: "#3B82F6",
                      height: "44px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCreateModalVisible(true);
                    }}
                  >
                    + Add new content
                  </div>
                )}

              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={items}
                  strategy={verticalListSortingStrategy}
                >
                  <div
                    style={{
                      maxHeight: "270px",
                      overflowY: "auto",
                      paddingBottom: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: "1px",
                          background: "#E2E8F0",
                          position: "absolute",
                          top: 0,
                          left: "28px",
                          height: "100%",
                        }}
                      ></div>
                      {items
                        ?.filter((content) =>
                          course?.isEnrolled?.isCompleted
                            ? content.isCompleted
                            : true
                        )
                        .map((content, idx) => (
                          <CourseContentCard
                            key={content?.id}
                            content={content}
                            index={idx}
                            canManageCourse={canManageCourse}
                            fetchCourseDetailsService={
                              fetchCourseDetailsService
                            }
                            edit={edit}
                            isEnrolled={course.isEnrolled}
                            course={course}
                          />
                        ))}
                    </div>
                  </div>
                </SortableContext>
              </DndContext>
              {items?.filter(
                (content) =>
                  course?.isEnrolled?.isCompleted && !content.isCompleted
              ).length > 0 && (
                <>
                  {" "}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        background: "#E2E8F0",
                      }}
                    ></div>
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        position: "absolute",
                        background: "white",
                        font: "500 12px/16px Inter",
                        color: "#64748B",
                        padding: "8px",
                      }}
                    >
                      NEW CONTENT
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        width: "1px",
                        background: "#E2E8F0",
                        position: "absolute",
                        top: 0,
                        left: "28px",
                        height: "100%",
                      }}
                    ></div>
                    {items
                      ?.filter(
                        (content) =>
                          course?.isEnrolled?.isCompleted &&
                          !content.isCompleted
                      )
                      .map((content, idx) => (
                        <CourseContentCard
                          key={idx}
                          content={content}
                          index={idx}
                          canManageCourse={canManageCourse}
                          fetchCourseDetailsService={fetchCourseDetailsService}
                          edit={edit}
                          isEnrolled={course.isEnrolled}
                          course={course}
                        />
                      ))}
                  </div>
                </>
              )}

              {canManageCourse &&
                !edit &&
                course?.contentDetails?.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      color: "#64748B",
                      justifyContent: "space-between",
                      flexDirection: isMobile ? "column" : "row",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <InformationCircleIcon height={20} /> Admin’s cannot
                      enroll
                    </div>
                    <Button
                      type="primary"
                      onClick={() => {
                        setEdit(true);
                      }}
                      style={{
                        background: "#EFF6FF",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "12px",
                        border: "none",
                        color: "#1D4ED8",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      <IconWrapper style={{ display: "flex" }}>
                        <PencilIcon fill="#3B82F6" />
                      </IconWrapper>
                      Add or Edit Content
                    </Button>
                  </div>
                )}

              {canManageCourse && edit && (
                <Button
                  style={{
                    background: "#3B82F6",
                    borderRadius: "12px",
                    display: "inline-block",
                    color: "white",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    setEdit(false);
                  }}
                >
                  Publish
                </Button>
              )}
              {course?.isEnrolled?.isCompleted && (
                <div
                  style={{
                    display: "flex",
                    height: "40px",
                    background: "#ECFDF5",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "12px",
                    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
                    borderRadius: "12px",
                    font: "normal 500 14px/20px Inter",
                    color: "#047857",
                  }}
                >
                  <img src={greenCheck} alt="" style={{ minWidth: "24px" }} />
                  Course Completed
                </div>
              )}

              {course?.canEnroll &&
                !course?.isEnrolled &&
                isCurrentDaoJoined &&
                course?.contentDetails?.length > 0 && (
                  <Button
                    type="primary"
                    style={{
                      background: "#3B82F6",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "12px",
                      marginLeft: "auto",
                    }}
                    onClick={enrolNowButtonHandler}
                  >
                    Enroll Now
                  </Button>
                )}
              {isMobile && !edit && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    columnGap: "8px",
                  }}
                >
                  <Share entity="course" />
                  <SlidingContextSidebar
                    entityId={courseId}
                    entityType="COURSES"
                    canEdit={canManageCourse}
                  />
                </div>
              )}
              {(course.isEnrolled || canManageCourse) && !edit && (
                <Tabs
                  onChange={(key) => setSelectedTab(key)}
                  activeKey={selectedTab}
                  style={{ width: "100%" }}
                  tabBarExtraContent={{
                    right: !isMobile && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "4px",
                        }}
                      >
                        <Share entity="course" style={{ marginRight: "4px" }} />
                        <SlidingContextSidebar
                          entityId={courseId}
                          entityType="COURSES"
                          canEdit={canManageCourse}
                        />
                      </div>
                    ),
                  }}
                >
                  <Tabs.TabPane
                    tab={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          marginTop: "16px",
                        }}
                      >
                        Completed
                        <div
                          style={{
                            background:
                              selectedTab === "1" ? "#DBEAFE" : "#F3F4F6",
                            padding: "2px 10px",
                            borderRadius: "16px",
                          }}
                        >
                          {submissions.length}
                        </div>
                      </div>
                    }
                    key="1"
                  >
                    {submissions.length === 0 ? (
                      <p>No submissions yet</p>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          rowGap: "8px",
                          flexDirection: "column",
                        }}
                      >
                        {submissions.map((ele) => {
                          return (
                            <ProfileCardPopup userId={ele.userId}>
                              <div
                                onClick={() =>
                                  navigate(`/app/profile/${ele.userId}`)
                                }
                                style={{
                                  columnGap: "8px",
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "8px 12px",
                                  border: "1px solid #f3f3f3",
                                  borderRadius: "12px",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={
                                    ele.profilePicture
                                      ? ele.profilePicture
                                      : dummyUser
                                  }
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                  }}
                                />
                                <span>{ele.userName}</span>{" "}
                              </div>
                            </ProfileCardPopup>
                          );
                        })}
                      </div>
                    )}
                  </Tabs.TabPane>

                  <Tabs.TabPane
                    tab={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                          marginTop: "16px",
                        }}
                      >
                        Discussions
                        <div
                          style={{
                            background:
                              selectedTab === "2" ? "#DBEAFE" : "#F3F4F6",
                            padding: "2px 10px",
                            borderRadius: "16px",
                          }}
                        >
                          {comments.length}
                        </div>
                      </div>
                    }
                    key="2"
                  >
                    <CommentSection
                      entity="course"
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      sortingTypeList={DEFAULT_SORTING_TYPE_LIST}
                      allComments={comments}
                      postComment={postComment}
                      fetchComments={fetchComments}
                    />
                  </Tabs.TabPane>
                </Tabs>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

CourseDetails.propTypes = {
  daoId: PropTypes.string,
  courseId: PropTypes.string,
  courseDetails: PropTypes.array,
  fetchCourseDetailsService: PropTypes.func,
};

export default CourseDetails;

import { CopyOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../../api";
import { DaoContext } from "../../../layouts/app/DaoContext";
import { copyTextToClipboard, validateEmail } from "../../../utils";
import IconWrapper from "../../IconWrapper";
import { InfoCircleFilled } from "@ant-design/icons";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const StyledSelect = styled(Select)`
  .ant-select-selector {
    overflow-y: auto;
    border-radius: 8px !important;
    max-height: 120px;
  }
  .ant-select-selection-item {
    border-radius: 8px;
    background: lavender;
  }
`;

const StyledModal = styled(Modal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-body {
  }
  //   & .ant-modal-close-x {
  //     color: #f3f3f3;
  //   }
`;
function InviteModal({ modalOpen, onClose }) {
  const { daoDetails } = useContext(DaoContext);

  const [emailList, setEmailList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adminLink, setAdminLink] = useState("");
  useEffect(() => {
    if (daoDetails.daoId)
      customAxios
        .get(
          `/dao/generate/link?type=ADMIN&&daoId=${daoDetails.daoId}&isInterface=true`
        )
        .then((res) => {
          setAdminLink(res.data.link);
        });
  }, [daoDetails]);
  const handleSendEmailInvite = (list) => {
    setLoading(true);
    customAxios
      .post("/dao/invite", {
        emailId: list,
        daoId: daoDetails.daoId,
        daoName: daoDetails.daoName,
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);

          toast.success("Invite link sent succesfully.");
          onClose();
        }
      })
      .catch((Err) => {
        setLoading(false);

        console.log("err", Err);
      });
  };
  return (
    <StyledModal
      visible={modalOpen}
      destroyOnClose
      footer={null}
      onCancel={onClose}
      centered={true}
      width="450px"
      bodyStyle={{ padding: "16px 20px" }}
      title={null}
    >
      <h4 style={{ marginBottom: "20px" }}>Invite</h4>
      <div
        style={{
          marginBottom: "16px",
          color: "#64748B",
          fontSize: "12px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "16px",
        }}
      >
        <div>Admin Invite</div>
        {adminLink && <div onClick={() => {
          toast.success("Link copied");
          copyTextToClipboard(adminLink);
        }} className="mt-1 text-sm bg-gray-100 rounded-lg p-3 text-slate-500 flex gap-2 justify-between items-center cursor-pointer">
          {adminLink}
          <button
            className="bg-gray-100 border-none cursor-pointer flex-shrink-0 p-0 flex"
          >
            <DocumentDuplicateIcon className="w-5 stroke-2" />
          </button>
        </div>}

      </div>
      {/* <InputField
        label={<div>Admin Invite</div>}
        value={adminLink}
        style={{ fontWeight: "400" }}
        inputStyle={{
          background: "#ffffff",
          textOverflow: "ellipsis",
        }}
        inputProps={{
          readOnly: true,
          disabled: true,

          suffix: (
            <Button
              icon={<CopyOutlined />}
              style={{
                borderRadius: "8PX",
                background: "#5865F2",
                padding: "8px 12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="primary"
              onClick={() => {
                copyTextToClipboard(adminLink).then(() => {
                  toast.success("Link copied successfully");
                });
              }}
            >
              Copy
            </Button>
          ),
        }}
      /> */}
      <div
        style={{
          padding: "12px 16px",
          background: "#EFF6FF",
          borderRadius: "8px",
          marginBottom: "12px",
          display: "flex",
          alignItems: "center",
          columnGap: "12px",
          border: "1px solid #3B82F6",
        }}
      >
        <IconWrapper>
          <InfoCircleFilled
            style={{ fontSize: "16px", color: "#3B82F6" }}
          />
        </IconWrapper>
        <h5
          style={{
            color: "#3B82F6",
            lineHeight: "16px",
            marginBottom: "0px",
          }}
        >
          Please note while sharing, as anyone with the link can become admin
        </h5>
      </div>
      <Col xs={24}>
        <div
          style={{
            width: "100%",
            borderTop: "1px dashed gray",
            position: "relative",
            margin: "16px 0px",
          }}
        >
          <h5
            style={{
              position: "absolute",
              left: "50%",
              top: "-0.6rem",
              color: "gray",
              zIndex: "120",
              background: "white",
            }}
          >
            OR
          </h5>
        </div>
      </Col>
      <Row>
        <Col xs={24}>
          <h5 style={{ fontWeight: "400", color: "#64748B" }}>
            Send Admin Invite to
          </h5>
        </Col>
        <Col xs={24}>
          <StyledSelect
            style={{ width: "100%", borderRadius: "12px" }}
            value={emailList}
            onChange={(value) => {
              if (value.length) {
                if (validateEmail(value[value.length - 1])) setEmailList(value);
                else toast.warn("Please enter a valid email address");
              } else setEmailList(value);
            }}
            options={[]}
            open={false}
            placeholder="Add mail address"
            mode="tags"
          />
        </Col>
      </Row>
      <Row
        style={{ width: "100%", justifyContent: "flex-end", marginTop: "16px" }}
      >
        <Button
          type="primary"
          style={{ borderRadius: "12px" }}
          disabled={!emailList.length || loading}
          onClick={() => handleSendEmailInvite(emailList)}
        >
          Send Invite
        </Button>
      </Row>
    </StyledModal>
  );
}

export default InviteModal;

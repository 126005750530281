import { UserCircleIcon } from "@heroicons/react/24/solid";
import { Avatar, Menu, Typography } from "antd";
import { useNavigate } from "react-router";
import SearchCard from "../SearchCard";
import { ReactComponent as ProfileImage } from "../../../assets/images/search/userprofile.svg";
import styled from "styled-components";
import { useState } from "react";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";

const UserProfile = ({ data, handleClose, searchTerm }) => {
  const navigate = useDaoLensNavigate();
  return (
    <SearchCard
      onClick={() => {
        handleClose();
        navigate(`/app/profile/${data.id}`);
      }}
    >
      <ProfileImage />

      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        {" "}
        {data.userProfilePicture ? (
          <img
            src={data.userProfilePicture}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              background: "#F4F2F2",
            }}
          />
        ) : (
          <UserCircleIcon
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              background: "#F4F2F2",
              color: "#64748B",
            }}
          />
        )}
        {data?.userName}
      </div>
    </SearchCard>
  );
};

export default UserProfile;

import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import typingGIF from "../../assets/images/gpt/typing.gif";
import { Secondary } from "./GPT";
import usePlaceholder from "./usePlaceholder";

interface LoadingProps {
  stopLoading: () => void;
}
const placeholders = [
  "Robin is typing",
  "Robin is putting on their thinking cape!",
  "Robin's brain is revving up like the Batmobile!",
  "Robin is analyzing the situation like a true superhero!",
  "Robin's super-senses are tuning in!",
  "Robin's virtual muscles are flexing!",
];
const Loading = ({ stopLoading }: LoadingProps) => {
  const placeholder = usePlaceholder(placeholders);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          color: "#9CA3AF",
          wordBreak: "break-all",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        {placeholder}
        <img
          src={typingGIF}
          style={{
            width: "12px",
            objectFit: "contain",
            marginLeft: "10px",
          }}
        />
      </div>

      <Secondary
        type="text"
        style={{ marginLeft: "auto" }}
        onClick={() => {
          stopLoading();
        }}
      >
        <XMarkIcon width="16px" />
        Stop
      </Secondary>
    </div>
  );
};
export default Loading;

import React from "react";
import MobilePageTopNavbar from "../../components/Common/MobilePageTopNavbar";
import DocumentFeed from "../../components/Course/Document/DocumentFeed";
import useWindowSize from "../../hooks/useWindowSize";

function DaoLibrary() {
  const { isMobile } = useWindowSize();
  return (
    <div
      style={{
        marginTop: isMobile ? "0" : 20,
        paddingLeft: isMobile ? "16px" : "0px",
      }}
    >
      {isMobile && <MobilePageTopNavbar />}
      <DocumentFeed />
    </div>
  );
}
export default DaoLibrary;

import snapShotIcon from "../../../images/snapshot.png";
import React, { useContext, useState } from "react";
import customAxios from "../../../api";
import { toast } from "react-toastify";
import { Button, Modal } from "antd";

const SnapshotIntegrationModal = ({ onSubmit, daoId, reload }) => {
  const [ensAddress, setEnsAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const updateSnaphot = async () => {
    if (loading) return;
    if (!ensAddress) {
      toast.error("Enter ENS address.");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("daoId", daoId);
      formData.append("snapshotEnsName", ensAddress);
      await customAxios.post("dao/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      reload();
      onSubmit();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        justifyContent: "center",
        gap: "20px",
      }}
    >
      <img
        src={snapShotIcon}
        alt="snapshot"
        width="61.88"
        height="74.25"
        style={{ alignSelf: "center" }}
      />
      <h3 style={{ textAlign: "center", margin: "0" }}>
        Import your snapshot workspace
      </h3>
      <p
        style={{
          color: "#64748B",
          fontWeight: "500",
          textAlign: "center",
          margin: "0",
        }}
      >
        Make sure to use the same ENS you used while setting up your community
        space on snapshot
      </p>
      <input
        placeholder="Type your community ENS address"
        value={ensAddress}
        onChange={(e) => setEnsAddress(e.currentTarget.value)}
        style={{
          border: "1px solid #E2E8F0",
          padding: "12px",
          borderRadius: "8px",
        }}
      />
      <Button
        onClick={updateSnaphot}
        disabled={loading}
        type="primary"
        style={{
          borderRadius: "8px",
          padding: "11px 16px",
          display: "flex",
          justifyContent: "center",
          border: "none",
          font: "normal 500 14px/20px Inter",
          height: "auto",
          boxShadow: " 0px 1px 2px rgba(0, 0, 0, 0.05)",
        }}
      >
        Import workspace
      </Button>
      <div
        style={{
          color: "#6B7280",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "1px",
            background: "#D9D9D9",
            position: "absolute",
          }}
        ></div>
        <div
          style={{
            background: "rgb(246, 245, 255)",
            zIndex: "2",
            padding: "0px 8px",
          }}
        >
          OR
        </div>
      </div>
      <Button
        onClick={() => {
          window.open("https://snapshot.org/#/setup?step=0");
        }}
        disabled={loading}
        type="text"
        style={{
          borderRadius: "6px",
          padding: "11px 16px",
          display: "flex",
          justifyContent: "center",
          height: "auto",
          font: "normal 500 14px/20px Inter",
          border: "1px solid #D1D5DB",
          boxShadow: " 0px 1px 2px rgba(0, 0, 0, 0.05)",
          background: "white",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <img src={snapShotIcon} style={{ width: "14px" }} /> Create your
        Snapshot workspace
      </Button>
      <a
        style={{ textAlign: "center", color: "#3B82F6" }}
        href="https://www.youtube.com/watch?v=oa6WCvnWLBc"
        target="_blank"
      >
        Confused? Learn how to create a workspace
      </a>
    </div>
  );
};

export default SnapshotIntegrationModal;

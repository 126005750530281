import React from "react";
import { useLocation, useNavigate } from "react-router";
import NotFoundImage from "../assets/images/common/404.svg";

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex items-center justify-center h-screen flex-col bg-purple-50 p-5">
      <img src={NotFoundImage} alt="404" className="w-full md:w-auto" />
      <h1 className="font-medium m-0 text-gray-700 text-2xl text-center mt-14">
        The page you are looking for was not found!
      </h1>
      <button
        onClick={() =>
          location.key !== "default" ? navigate(-1) : navigate("/")
        }
        className="mt-7 border-none bg-blue-500 text-white font-semibold rounded-xl flex items-center gap-2 py-3 px-7 hover:bg-blue-600 active:bg-blue-700"
      >
        Go Back
      </button>
    </div>
  );
};

export default NotFound;

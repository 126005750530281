import { Input, Select, Col, Row } from "antd";
import { tokenOptions } from "../../constants";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/common/chevrondown.svg";

const { Option } = Select;
const TokenComponent = ({ ele, idx, handleConditionDetailChange }) => {
  return (
    <Row
      gutter={["8"]}
      style={{
        padding: "2rem 12px 15px 12px",
        borderRadius: "12px",
        background: "#eeeeee",
        rowGap: "12px",
      }}
    >
      <Col xs={24} sm={8}>
        <Select
          suffixIcon={<ChevronDownIcon />}
          value={ele.contractType}
          style={{ width: "100%" }}
          defaultValue="POLYGON"
          onChange={(e) => {
            handleConditionDetailChange("contractType", e, idx);
          }}
        >
          {tokenOptions.map((ele) => (
            <Option value={ele.value}>{ele.label}</Option>
          ))}{" "}
        </Select>
      </Col>
      <Col xs={24} sm={8}>
        <Input
          style={{ width: "100%", borderRadius: "8px" }}
          value={ele.contractAddress}
          placeholder="Enter token address"
          className="ip-address"
          onChange={(e) => {
            handleConditionDetailChange("contractAddress", e.target.value, idx);
          }}
        ></Input>{" "}
      </Col>
      <Col xs={24} sm={8}>
        <Input
          value={ele.amount}
          style={{ width: "100%", borderRadius: "8px" }}
          placeholder="Enter no. of tokens"
          className="ip-address"
          onChange={(e) => {
            handleConditionDetailChange("amount", e.target.value, idx);
          }}
        ></Input>{" "}
      </Col>
    </Row>
  );
};

export default TokenComponent;

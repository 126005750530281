import sam from "../../assets/images/daoCreation/sam_holding.png";
import styled from "styled-components";

export const Bubble = styled.div`
  animation: top-down 8s ease-out forwards infinite;
  position: absolute;
  aspect-ratio: 1;
  width: 158px;
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(7.2px);
  border-radius: 50%;
  box-shadow: inset 7.2px -7.2px 7.2px rgba(165, 165, 165, 0.1),
    inset -7.2px 7.2px 7.2px rgba(255, 255, 255, 0.1);
  @keyframes top-down {
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;
const FirstView = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "562px",
          background: "white",
          gap: "20px",
          borderRadius: "16px",
          textAlign: "center",
          padding: "36px",
          font: "normal 500 14px/20px Inter",
          color: "#1F2937",
          zIndex: 1,
        }}
      >
        <img src={sam} style={{ width: "100px" }} />
        DAOs use a whole load of tools (both web2 and web3). Today I learned
        about DaoLens, which feels like a super effective way to onboard new
        members to your DAOs. I highly recommend checking it out if you are a
        DAO admin or considering launching a DAO.
        <div
          style={{
            font: "normal 600 14px/20px Inter",
          }}
        >
          Sam Holding
          <div
            style={{
              font: "normal 500 12px/16px Inter",
              color: "#6B7280",
              marginTop: "4px",
            }}
          >
            Founding Member, Quarius
          </div>
        </div>
      </div>
      <Bubble
        style={{
          top: "240px",
          left: "212pxs",
          width: "108px",
        }}
      />
      <Bubble
        style={{
          top: "216px",
          left: "590px",
          width: "108px",
        }}
      />
      <Bubble
        style={{
          top: "538px",
          left: "572px",
          width: "108px",
        }}
      />
      <Bubble
        style={{
          left: "21px",
          top: "548px",
          zIndex: 2,
        }}
      />
    </>
  );
};

export default FirstView;

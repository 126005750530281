const Label = ({ children }) => {
    return <label
        style={{
            paddingLeft: "12px",
            color: "#64748B",
            fontWeight: "600",
            fontFamily: "Inter",
            fontSize: "12px",
            lineHeight: "16px",
            fontStyle: "normal"
        }}>{children}</label>
}

export default Label;



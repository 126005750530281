import React, { useContext } from "react";
import { UserContext } from "../../layouts/app/UserContext";
import hamburgerIcon from "../../assets/iconSvg/hamburger.svg";
import OverviewIcon from "../../images/overview-icon.svg";
import ProposalsIcon from "../../images/proposal-icon.svg";
import ProjectsIcon from "../../images/project-icon.svg";
import DiscussionsIcon from "../../images/discussion-icon.svg";
import CourseIcon from "../../images/course-icon.svg";
import MembersIcon from "../../images/members-icon.svg";
import EventsIcon from "./../../assets/images/events/events.svg";
import SpacesIcon from "./../../images/spaces-icon.svg";

import DaoBounty from "../../images/bounty-icon.svg";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { SpaceBreadcrumb } from "../Spaces/BreadcrumbDesktopContainer";
import useWindowSize from "../../hooks/useWindowSize";
function MobilePageTopNavbar({
  showInvite = false,
  setInviteModalOpen = null,
  rightElement = <></>,
}) {
  const userContext = useContext(UserContext);
  const { isMobile } = useWindowSize();
  const { isSpace } = useDaoContext();
  const openSidebar = () => {
    setTimeout(() => {
      userContext.setMobileSidebarOpen(true);
    }, 100);
    return;
  };

  const getIcon = (page) => {
    switch (page) {
      case "overview":
        return OverviewIcon;
      case "bounty":
        return DaoBounty;
      case "proposal":
        return ProposalsIcon;
      case "project":
        return ProjectsIcon;
      case "members":
        return MembersIcon;
      case "channel":
        return DiscussionsIcon;
      case "library":
        return CourseIcon;
      case "course":
        return CourseIcon;
      case "document":
        return CourseIcon;
      case "events":
        return EventsIcon;
      case "spaces":
        return SpacesIcon;

      default:
        return DiscussionsIcon;
    }
  };
  const getSectionName = (path) => {
    if (path.includes("overview")) return "Overview";
    if (path.includes("bounty")) return "Bounty";
    if (path.includes("proposal")) return "Proposal";
    if (path.includes("project")) return "Project";
    if (path.includes("channel")) return "Discussion Channel";
    if (path.includes("library")) return "Library";
    if (path.includes("course")) return "Course";
    if (path.includes("document")) return "Document";
    if (path.includes("events")) return "Events";
    if (path.includes("spaces")) return "Spaces";
    if (path.includes("members")) return "Members";
  };
  return (
    <div className="mobileNavbar w-full rounded-lg lg:rounded-2xl bg-white opacity-80 flex items-center justify-between p-4 lg:pl-0 lg:mb-4">
      <div
        className="mobileNavbar__left"
        style={{ display: "flex", alignItems: "center" }}
      >
        <img
          src={hamburgerIcon}
          alt="menu"
          onClick={openSidebar}
          style={{ cursor: "pointer", display: !isMobile ? "none" : "" }}
        />
        {isSpace && (
          <div className="ml-3 mr-1">
            <SpaceBreadcrumb />
          </div>
        )}
        <img
          src={getIcon(window?.location?.pathname.split("/").slice(-1)[0])}
          alt="icon"
          style={{ display: "inline-block", margin: "0 5px" }}
        />

        <span>{getSectionName(window?.location?.pathname.toLowerCase())}</span>
      </div>
      <div className="mobileNavbar__right">
        {/* <img src={GreyHorizontalDot} alt="options" /> */}
        {showInvite && (
          <span
            style={{
              color: "#3B82F6",
              backgroundColor: "#3B82F61F",
              display: "inline-flex",
              alignItems: "center",
              padding: "10px 16px",
              borderRadius: "12px",
              // position: "absolute",
              // right: "5px",
              cursor: "pointer",
              fontWeight: "600",
            }}
            onClick={() => {
              setInviteModalOpen?.();
              // inviteDaoHandler({ popup: true });
            }}
          >
            <UserPlusIcon
              style={{
                width: "16px",
                cursor: "pointer",
                color: "#3B82F6",
                marginRight: "10px",
              }}
            />
            Invite members
          </span>
        )}
        {rightElement}
      </div>
    </div>
  );
}

export default MobilePageTopNavbar;

import { Button, Col, Input, Row, Spin, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "../../../styles/ModalStyles";
import Web3 from "web3";
import customAxios from "../../../api";
import discordIcon from "./../../../assets/iconSvg/discord.svg";
import discovery from "./../../../assets/images/discovery/discovery.png";

import walletConnectIcon from "./../../../assets/iconSvg/wallet-connect.svg";
import neraIcon from "./../../../assets/images/logos/Near.png";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import defaultUser from "./../../../assets/images/login/defaultUser.png";
import { toast } from "react-toastify";
import { SET_ACCESS_TOKEN } from "../../../redux/actionTypes/authActionType";
import metaMaskIcon from "../../../assets/iconSvg/metaMaskIcon.svg";
import googleIcon from "../../../assets/iconSvg/google-icon.svg";
import pixelMockup from "./../../../assets/images/login/pixelMockup.png";
import {
  BackwardOutlined,
  CloseOutlined,
  GoogleCircleFilled,
  GoogleOutlined,
  LoadingOutlined,
  StepBackwardFilled,
} from "@ant-design/icons";
import { getAddress } from "ethers/lib/utils";
import {
  checkFileSize,
  getRandomItemFromArray,
  postMixpanel,
  validateEmail,
} from "../../../utils";
import styled from "styled-components";
import { useEthers } from "@usedapp/core";
import { walletConnect } from "../../../utils/connectors";
import { useWalletConnect } from "../../../layouts/app/WalletConnectContext";
import { ReactComponent as SolanaIcon } from "../../../assets/images/login/solana-sol-logo.svg";

import jwtDecode from "jwt-decode";
import mixpanel from "mixpanel-browser";
import unauthenticatedAxios from "../../../api/unauthenticatedaxios";
import { MD_SCREEN_BREAKPOINT } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
import IconWrapper from "../../../components/IconWrapper";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import useSolana from "../../../hooks/useSolana";
import axios from "axios";
import petraIcon from "./../../../assets/images/common/petra.jpg";
import lensIcon from "./../../../assets/images/common/lens.svg";

import { DiscordIcon } from "../../../components/Icones";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import {
  NearWalletSelectorContext,
  useWalletSelector,
} from "../../../layouts/app/NearWalletSelectorContext";
import useNear from "../../../hooks/useNear";
import { randomUUID } from "crypto";
import useLens from "../../../hooks/useLens";
const { Title } = Typography;
const StyledColWallet = styled(Col)`
  .wallet-adapter-button {
    border: 1px solid #d9d9d9;

    color: black;
    &:hover {
      border: 1px solid #1890ff;
      cursor: pointer;
      color: #1890ff;
    }
  }
`;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);
const StyledLoginTitle = styled(Title)`
  font-family: Roboto Slab, serif !important;
  font-weight: 800 !important;
`;
const StyledGradientText = styled.h2`
  background: linear-gradient(
    180deg,
    #831843 -38.19%,
    rgba(131, 24, 67, 0) 276.39%
  );
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const getDiscordUri = (historyStateFrom) => {
  return `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_ENDPOINT === "development"
    ? "1046753505026785401"
    : "1014817083139301417"
    }&redirect_uri=${encodeURIComponent(
      window.location.href
    )}&response_type=code${`&state=${btoa(
      historyStateFrom
    )}`}&scope=identify%20guilds`;
};

const StyledImg = styled.img`
  &:hover {
    border: 1px solid #3b82f6;
  }
`;
const StyledCol = styled(Col)`
  border-radius: 8px;
  color: #94a3b8;
  cursor: pointer;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  &:hover {
    border-color: #40a9ff;
  }
  ${(props) =>
    props.selected &&
    `
    border:2px solid #3F83F8;
    color:#3F83F8;
 &:hover {
    border-color:#3F83F8;
  }
    `}
`;

const LoginModal = (props) => {
  const defaultUserImageList = [
    "https://storage.googleapis.com/contribution_post_attachments/Citrus Peel_main-1671190000617.png",
    "https://storage.googleapis.com/contribution_post_attachments/Cool Sky_main-1671190036110.png",
    "https://storage.googleapis.com/contribution_post_attachments/Piggy Pink_main-1671190036504.png",
    "https://storage.googleapis.com/contribution_post_attachments/Summer Dog_main-1671190074038.png",
    "https://storage.googleapis.com/contribution_post_attachments/Suzy (1)_main-1671190074457.png",
    "https://storage.googleapis.com/contribution_post_attachments/Suzy_main-1671190074890.png",
  ];

  const navigate = useDaoLensNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [isUserNameAvailable, setUserNameAvailable] = useState(true);
  const [isEmailAvailable, setEmailAvailable] = useState(true);
  const [userNameContainsSpace, setUsernameContainsSpace] = useState(false);

  const [selectedRole, setSelectedRole] = useState(0);
  const history = useLocation();
  const [isWalletConnectionLoading, setWalletConnectionLoading] =
    useState(false);
  const [readOnlyEmail, setReadOnlyEmail] = useState(false);
  const [modalTop, setModalTop] = useState("auto");

  const { account } = useEthers();
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;

  const { onClickActivate, isWalletConnected, setWalletConnected } =
    useWalletConnect();
  useEffect(() => {
    if (isWalletConnected) {
      const authWalletConnect = async () => {
        try {
          setWalletConnected(true);
          customAxios
            .post("auth/metamask", {
              publicAddress: account,
            })
            .then((response) => {
              handleSignMessage({
                publicAddress: account,
                ...response.data,
                isWalletConnect: true,
              });
            })
            .catch((err) => {
              console.log(err);
              toast.error("Some error occured");
            });
        } catch (error) {
          console.error(error);
        }
      };
      authWalletConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const fileUploadRef = useRef(null);
  useEffect(() => {
    const code = searchParams.get("code");

    const previousRoute = atob(searchParams.get("state"));

    if (activeStep === 0)
      if (code) {
        setActiveStep(-1);
        customAxios
          .get(`auth/discord/redirect?code=${code}`)
          .then((res) => {
            localStorage.setItem("access_token", res.data.access_token);
            mixpanel.identify(jwtDecode(res.data.access_token).id);

            localStorage.setItem("new_user", res.data.newUser);
            // dispatch(setAccessToken(res.data.access_token));

            if (res.data.newUser) setActiveStep(1);
            else {
              postMixpanel("track", "logged_in_using_discord", {
                invite_link: previousRoute?.includes("invite")
                  ? previousRoute
                  : "",
              });
              if (previousRoute !== "undefined") {
                navigate(previousRoute);
              } else if (window.location.host === "uniflux.daolens.com")
                navigate("/app/dao/uniflux/172");
              else handleRedirect();
            }

            //  setTimeout(window.close());
          })
          .catch((err) => {
            console.log(err);
            setActiveStep(0);
            console.log("error");
          });
      }
  }, []);
  const onLensLoginSuccess = (access_token, newUser) => {
    localStorage.setItem("access_token", access_token);
    mixpanel.identify(jwtDecode(access_token).id);
    localStorage.setItem("new_user", newUser);
    setLoading(false);

    if (newUser) setActiveStep(1);
    else {
      postMixpanel("track", "logged_in_using_lens", {
        invite_link: history?.state?.from?.includes("invite")
          ? history?.state?.from
          : "",
      });
      if (history?.state?.from) navigate(history?.state?.from);
      else {
        if (window.location.host === "uniflux.daolens.com")
          navigate("/app/dao/uniflux/172");
        else {
          handleRedirect();
        }
      }
    }
  };
  const { onLoginClick } = useLens(
    onLensLoginSuccess,
    () => {
      setActiveStep(-1);
    },
    () => {
      setActiveStep(0);
    }
  );

  useEffect(() => {
    setLoading(true);
    if (username)
      customAxios
        .get(`users/username/available?userName=${username}`)
        .then(({ data }) => {
          setUserNameAvailable(data.isUserNameAvailable);
        })
        .finally(() => {
          setLoading(false);
        });
  }, [username]);
  useEffect(() => {
    setLoading(true);
    if (emailId) {
      if (validateEmail(emailId))
        customAxios
          .get(`users/emailid/available`,{
            params:{emailId}
          })
          .then(({ data }) => {
            setEmailAvailable(data.isEmailIdAvailable);
          })
          .finally(() => {
            setLoading(false);
          });
      else {
        setLoading(false);
        // toast.error("Please enter a valid email id");
      }
    }
  }, [emailId]);

  useEffect(() => {
    const height = document.querySelector(".ant-modal")?.clientHeight;
    if (height) setModalTop(`calc(100vh - ${height + 20}px)`);
  });

  const getActiveStep = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <Row style={{ width: "100%", height: "100%", position: "relative" }}>
            <Button
              type="text"
              style={{
                position: "absolute",
                right: "0.5rem",
                top: "0.5rem",
                color: "#9CA3AF",
                cursor: "pointer",
                zIndex: "12",
                display: "flex",
                padding: "0px",
                alignItems: "center",
                justifyContent: "center",
              }}
              icon={
                <IconWrapper style={{}}>
                  <CloseOutlined />
                </IconWrapper>
              }
              onClick={() => {
                navigate(
                  history.state?.from &&
                    history.state?.from !== "/app/onboarding"
                    ? history.state.from
                    : "/app/discovery"
                );
              }}
            ></Button>

            {isMobile ? null : (
              <Col
                xs={12}
                style={{
                  position: "relative",
                  background: "#FEF2F2",
                  borderRadius: "12px",
                }}
              >
                <div style={{ padding: "24px 44px" }}>
                  <div
                    style={{
                      display: "flex",
                      columnGap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        borderRadius: "50%",
                        backgroundColor: "#FB7185",
                      }}
                    ></div>
                    <h4 style={{ color: "#FB7185", marginBottom: "0px" }}>
                      We are live on mobile
                    </h4>
                  </div>
                  <h1 style={{ fontSize: "35px" }} className="flex items-end gap-1.5">
                    <StyledGradientText style={{ color: "#FB7185",marginBottom:"8px" }}>
                      Now use DAO Manager on the go
                    </StyledGradientText>
                    <span
                      style={{
                        background: "none",
                        WebkitTextFillColor: "none",
                        WebkitBackgroundClip: "none",
                      }}
                    >
                      🚀
                    </span>
                  </h1>
                </div>
                <img
                  style={{
                    objectFit: "contain",
                    width: "450px",
                    position: "absolute",
                    bottom: "0px",
                  }}
                  src={pixelMockup}
                />
              </Col>
            )}
            <Col
              xs={isMobile ? 24 : 12}
              style={{
                padding: "24px 44px",
              }}
            >
              <StyledLoginTitle
                level={3}
                style={{
                  marginBottom: "0",
                }}
              >
                Login to unleash your superpowers
              </StyledLoginTitle>
              <Col xs={24} style={{ marginTop: "40px" }}>
                <Button
                  style={{
                    borderRadius: "12px",
                    minHeight: "2.5rem",
                    fontWeight: "500",
                  }}
                  type="default"
                  block={true}
                  icon={
                    <img
                      src={googleIcon}
                      style={{ width: "16px", marginRight: "8px" }}
                    ></img>
                  }
                  onClick={() => googleLogin()}
                >
                  Sign in with Google
                </Button>
              </Col>

              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid #E5E7EB",
                  position: "relative",
                  margin: "24px 0px",
                  borderRadius: "12px",
                }}
              >
                <h5
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "0rem",
                    color: "#64748B",
                    zIndex: "120",
                    fontWeight: "400",
                    background: "white",
                    fontSize: "14px",

                    transform: "translate(-50%, -50%)",
                  }}
                >
                  Or
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "16px",
                }}
              >
                {!isMobile && (
                  <Col xs={24}>
                    <Button
                      style={{
                        borderRadius: "12px",
                        minHeight: "2.5rem",
                        fontWeight: "500",
                      }}
                      type="default"
                      block={true}
                      icon={
                        <img
                          src={metaMaskIcon}
                          alt="metasmaskicon"
                          style={{ width: "20px", marginRight: "0.5rem" }}
                        />
                      }
                      onClick={() => connectMetamask()}
                    >
                      MetaMask
                    </Button>
                  </Col>
                )}
                <StyledColWallet xs={24}>
                  <WalletMultiButton
                    className="wallet-adapter-button"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      background: "white",
                      borderRadius: "12px",

                      fontWeight: "500",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      lineHeight: "10px",
                      height: "40px",
                    }}
                  >
                    {!wallet && (
                      <SolanaIcon
                        style={{ width: "20px", marginRight: "8px" }}
                      />
                    )}{" "}
                    Solana Wallet
                  </WalletMultiButton>
                </StyledColWallet>
                <Col xs={24}>
                  <Button
                    style={{
                      borderRadius: "12px",
                      minHeight: "2.5rem",
                      fontWeight: "500",
                    }}
                    type="default"
                    block={true}
                    icon={
                      <img
                        src={lensIcon}
                        alt="Lens"
                        style={{
                          width: "20px",
                          marginRight: "0.5rem",
                          borderRadius: "12px",
                        }}
                      />
                    }
                    onClick={onLoginClick}
                  >
                    Lens
                  </Button>
                </Col>
                {!isMobile && (
                  <Col xs={24}>
                    <Button
                      style={{
                        borderRadius: "12px",
                        minHeight: "2.5rem",
                        fontWeight: "500",
                      }}
                      type="default"
                      block={true}
                      icon={
                        <img
                          src={petraIcon}
                          alt="PETRA"
                          style={{
                            width: "20px",
                            marginRight: "0.5rem",
                            borderRadius: "12px",
                          }}
                        />
                      }
                      onClick={() => connectPetra()}
                    >
                      Petra
                    </Button>
                  </Col>
                )}
                <Col xs={24}>
                  <Button
                    style={{
                      borderRadius: "12px",
                      minHeight: "2.5rem",
                      fontWeight: "500",
                    }}
                    type="default"
                    block={true}
                    icon={
                      <img
                        src={neraIcon}
                        alt="wallectconnect"
                        style={{
                          width: "20px",
                          marginRight: "0.5rem",
                        }}
                      />
                    }
                    onClick={() => {
                      modal.show();
                    }}
                  >
                    NEAR Wallet
                  </Button>
                </Col>
                <Col xs={24}>
                  <Button
                    style={{
                      borderRadius: "12px",
                      minHeight: "2.5rem",
                      fontWeight: "500",
                    }}
                    type="default"
                    block={true}
                    icon={
                      <img
                        src={walletConnectIcon}
                        alt="wallectconnect"
                        style={{ width: "20px", marginRight: "0.5rem" }}
                      />
                    }
                    onClick={() => onClickActivate()}
                  >
                    {isWalletConnectionLoading ? "Loading" : "Wallet Connect"}
                  </Button>
                </Col>
                <Col xs={24}>
                  <Button
                    style={{
                      borderRadius: "12px",
                      minHeight: "2.5rem",
                      fontWeight: "500",
                    }}
                    icon={
                      <IconWrapper style={{ marginRight: "0.5rem" }}>
                        <i
                          class="fab fa-discord"
                          style={{
                            fontSize: "16px",
                            color: "rgb(59, 130, 246)",
                          }}
                        ></i>
                      </IconWrapper>
                    }
                    block={true}
                    type="default"
                    onClick={() => {
                      window.open(getDiscordUri(history?.state?.from), "_self");
                    }}
                  >
                    Discord
                  </Button>
                </Col>
                {/* <Col xs={24} style={{ marginTop: "16px" }}>
                  <StyledLoginDiv id="loginDiv"></StyledLoginDiv>
                </Col> */}
              </div>
            </Col>
          </Row>
        );
      case 1:
        return (
          <Col xs={24} style={{ padding: "1.5rem 2rem" }}>
            <Row style={{ gap: "1.5rem" }}>
              <Title level={4}>What do we call you?</Title>
              <Col xs={24} style={{ textAlign: "center" }}>
                <StyledImg
                  src={profilePicture ? profilePicture : defaultUser}
                  alt="PROFILE"
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
                <input
                  type="file"
                  hidden
                  accept="image/png, image/jpeg"
                  ref={fileUploadRef}
                  onClick={() => {
                    fileUploadRef.current.value = null;
                  }}
                  onChange={(e) => {
                    if (e.target.files.length) {
                      let formData = new FormData();
                      const newFile = e.target.files[0];
                      checkFileSize(newFile.size);
                      formData.append("file", newFile);
                      setUploading(true);

                      customAxios
                        .post("users/uploadpicture", formData, {
                          headers: { "Content-Type": "multipart/form-data" },
                        })
                        .then((res) => {
                          setProfilePicture(res.data.url.publicUrl);
                          //profile picture
                        })
                        .catch((err) => {
                          console.log("error");
                          console.log(err);
                        })
                        .finally(() => {
                          setUploading(false);
                        });
                    }
                    e.target.files = null;
                  }}
                />
              </Col>
              <Input
                style={{
                  borderRadius: "12px",
                  minHeight: "42px",
                }}
                placeholder="Enter your full name"
                value={profileName}
                autoFocus={true}
                maxLength="24"
                onChange={(e) => setProfileName(e.target.value)}
              />
              <Input
                style={{
                  borderRadius: "12px",
                  minHeight: "42px",
                  border:
                    isUserNameAvailable && !userNameContainsSpace
                      ? ""
                      : "1px solid #F05252",
                }}
                placeholder="Enter your username"
                value={username}
                maxLength="24"
                onChange={(e) => {
                  setUsername(e.target.value);
                  setUsernameContainsSpace(e.target.value.includes(" "));
                }}
              />
              {!isUserNameAvailable && (
                <div
                  style={{
                    marginTop: "-12px",
                    weight: 600,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#F05252",
                  }}
                >
                  Username already taken
                </div>
              )}
              {userNameContainsSpace && (
                <div
                  style={{
                    marginTop: "-12px",
                    weight: 600,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#F05252",
                  }}
                >
                  Username cannot contain space
                </div>
              )}

              <Input
                style={{
                  borderRadius: "12px",
                  minHeight: "42px",
                  border: isEmailAvailable ? "" : "1px solid #F05252",
                }}
                placeholder="Enter your email"
                value={emailId}
                maxLength="32"
                onChange={(e) => setEmailId(e.target.value)}
                readOnly={readOnlyEmail}
              />
              {!isEmailAvailable && (
                <div
                  style={{
                    marginTop: "-12px",
                    weight: 600,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#F05252",
                    marginBottom: "20px",
                  }}
                >
                  Email id is already taken
                </div>
              )}
              {window.location.host === "uniflux.daolens.com" ||
                history?.state?.from?.includes("invite") ? null : (
                <Row
                  style={{
                    width: "100%",
                    columnGap: "12px",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledCol
                    selected={selectedRole === 0}
                    onClick={() => setSelectedRole(0)}
                    style={{
                      width: !isMobile && "48%",
                      fontSize: isMobile && "13px",
                    }}
                  >
                    ‍🧑‍💻 I am a contributor
                  </StyledCol>
                  <StyledCol
                    selected={selectedRole === 1}
                    onClick={() => setSelectedRole(1)}
                    style={{
                      width: !isMobile && "48%",
                      fontSize: isMobile && "13px",
                    }}
                  >
                    🤵 I am an admin
                  </StyledCol>
                </Row>
              )}
              <Button
                block={true}
                type="primary"
                disabled={
                  !isUserNameAvailable ||
                  userNameContainsSpace ||
                  loading ||
                  uploading ||
                  !profileName.trim() ||
                  username.length === 0 ||
                  !isEmailAvailable
                }
                style={{ borderRadius: "12px", minHeight: "36px" }}
                onClick={() => {
                  if (!isUserNameAvailable) {
                    return toast.error("Username is already taken");
                  }
                  if (!emailId) return toast.error("Please enter an email id");
                  if (emailId && !validateEmail(emailId)) {
                    return toast.error("Please enter a valid email id");
                  }
                  if (!isEmailAvailable) {
                    return toast.error("Email is already taken");
                  }
                  if (username) {
                    customAxios
                      .put("/users/profile/update", {
                        userName: username,
                        emailId: emailId,
                        profileName: profileName.trim(),
                        profilePicture: profilePicture
                          ? profilePicture
                          : getRandomItemFromArray(defaultUserImageList),
                        newUser: true,
                      })
                      .then((res) => {
                        toast.success("Profile created succesfully");
                        let previousRoute = "undefined";
                        if (window.location.href.includes("?"))
                          previousRoute = atob(searchParams.get("state"));

                        localStorage.setItem("new_user", false);
                        //tracking-conditions-for-sginup
                        if (readOnlyEmail) {
                          postMixpanel(
                            "track",

                            "signed_up_using_google",
                            {
                              invite_link: history?.state?.from.includes(
                                "invite"
                              )
                                ? history?.state?.from
                                : "",
                            }
                          );
                        } else if (searchParams.get("code")) {
                          postMixpanel(
                            "track",

                            "signed_up_using_discord",
                            {
                              invite_link: previousRoute?.includes("invite")
                                ? previousRoute
                                : "",
                            }
                          );
                        } else {
                          postMixpanel(
                            "track",
                            isWalletConnected
                              ? "signed_up_using_wallet_connect"
                              : "signed_up_using_metamask",
                            {
                              invite_link: history?.state?.from.includes(
                                "invite"
                              )
                                ? history?.state?.from
                                : "",
                            }
                          );
                        }
                        //redirecting-based-on-params
                        if (selectedRole === 1) {
                          navigate("/app/onboarding");
                        } else {
                          if (history?.state?.from) {
                            navigate(history?.state?.from);
                          } else if (previousRoute !== "undefined") {
                            navigate(previousRoute);
                          } else {
                            if (window.location.host === "uniflux.daolens.com")
                              navigate("/app/dao/uniflux/172");
                            else {
                              navigate("/app/discovery");
                            }
                          }
                        }
                      })
                      .catch((err) => toast.error("Some error occured"));
                  } else {
                    toast.error("Please enter a username");
                  }
                }}
              >
                Continue
              </Button>
            </Row>
          </Col>
        );
      default:
        return (
          <Spin
            size="large"
            indicator={antIcon}
            style={{
              position: "fixed",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        );
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleGoogle(tokenResponse);
    },
    flow: "implicit",
  });

  const handleRedirect = () => {
    setActiveStep(-1);
    customAxios
      .get("dao/daojoinedbyuser")
      .then((res) => {
        if (res?.data?.daoList?.length) {
          navigate(
            `/app/dao/${res?.data?.daoList[0].daoName}/${res?.data?.daoList[0].daoId}/overview`
          );
        } else {
          navigate("/app/discovery");
        }
      })
      .catch((err) => {
        navigate("/app/discovery");
      });
  };

  const connectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          // Return the address of the wallet
          const publicAddress = getAddress(res[0]);
          console.log("first", publicAddress);
          unauthenticatedAxios
            .post("auth/metamask", {
              publicAddress: publicAddress,
            })
            .then((response) => {
              handleSignMessage({
                publicAddress: publicAddress,
                ...response.data,
              });
            })
            .catch((err) => {
              console.log("error");
            });
        })
        .catch((err) => toast.error(err.message));
    } else {
      toast.error("Install metamask extension!!");
    }
  };
  const connectPetra = () => {
    if (window.aptos) {
      window.aptos
        .connect()
        .then((res) => {
          // Return the address of the wallet
          console.log("tt", res);
          const publicAddress = res.address;
          unauthenticatedAxios
            .post("auth/aptos", {
              publicAddress: publicAddress,
            })
            .then((response) => {
              handleSignMessageForPetra({
                publicAddress: publicAddress,
                ...response.data,
                publicKey: res.publicKey,
              });
            })
            .catch((err) => {
              console.log("error", err);
            });
        })
        .catch((err) => {
          console.log(err, "ert");
          toast.error(err.message);
        });
    } else {
      toast.error("Install Petra wallet extension!!");
    }
  };
  const handleSignMessageForPetra = ({ message, token }) => {
    window.aptos
      .signMessage({
        message: "I am signing my one-time nonce:",
        nonce: message,
      })
      .then((res) => {
        window.aptos.account().then(({ publicKey }) => {
          customAxios
            .post("auth/aptos/login", {
              token: "Bearer " + token,
              signature: res.signature,
              publicKey: publicKey,
            })
            .then((response) => {
              localStorage.setItem("access_token", response.data.access_token);
              mixpanel.identify(jwtDecode(response.data.access_token).id);
              localStorage.setItem("new_user", response.data.newUser);
              // dispatch(setAccessToken(res.data.access_token));

              if (response.data.newUser) setActiveStep(1);
              else {
                postMixpanel(
                  "track",

                  "logged_in_using_petra_wallet",
                  {
                    invite_link: history?.state?.from?.includes("invite")
                      ? history?.state?.from
                      : "",
                  }
                );
                if (history?.state?.from) navigate(history?.state?.from);
                else if (window.location.host === "uniflux.daolens.com")
                  navigate("/app/dao/172");
                else {
                  handleRedirect();
                }
              }
            });
        });
      });
  };

  const handleSignMessage = ({
    publicAddress,
    message,
    token,
    isWalletConnect = false,
  }) => {
    let web3;
    if (!isWalletConnected) {
      web3 = new Web3(Web3.givenPovider || window.ethereum);
      setWalletConnectionLoading(true);
    } else {
      web3 = new Web3(walletConnect);
    }

    web3.eth.personal
      .sign(
        web3.utils.fromUtf8(`I am signing my one-time nonce: ${message}`),
        publicAddress
      )
      .then((res) => {
        setActiveStep(-1);

        if (!token) throw new Error("Failed to login, token is missing");

        customAxios
          .post("auth/metamask/login", {
            token: "Bearer " + token,
            signature: res,
            isWalletConnect: isWalletConnect,
          })
          .then((response) => {
            localStorage.setItem("access_token", response.data.access_token);
            mixpanel.identify(jwtDecode(response.data.access_token).id);
            localStorage.setItem("new_user", response.data.newUser);
            // dispatch(setAccessToken(res.data.access_token));

            if (response.data.newUser) setActiveStep(1);
            else {
              postMixpanel(
                "track",
                isWalletConnected
                  ? "logged_in_using_wallet_connect"
                  : "logged_in_using_metamask",
                {
                  invite_link: history?.state?.from?.includes("invite")
                    ? history?.state?.from
                    : "",
                }
              );
              if (history?.state?.from) navigate(history?.state?.from);
              else if (window.location.host === "uniflux.daolens.com")
                navigate("/app/dao/uniflux/172");
              else {
                handleRedirect();
              }
            }
          })
          .catch((err) => {
            setActiveStep(0);
            setWalletConnectionLoading(false);
            console.error(err);
          });
        setWalletConnectionLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        if (err.message === "Unauthorized") {
          toast.error(
            "Please remove DaoManager from your wallet's connected apps and refresh the page."
          );
        } else {
          window.location.reload();
        }
        setWalletConnectionLoading(false);

        // window.location.reload();
      });
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const onSolanaLoginSuccess = (access_token, newUser) => {
    localStorage.setItem("access_token", access_token);
    mixpanel.identify(jwtDecode(access_token).id);
    localStorage.setItem("new_user", newUser);
    setLoading(false);

    if (newUser) setActiveStep(1);
    else {
      postMixpanel("track", "logged_in_using_solana", {
        invite_link: history?.state?.from?.includes("invite")
          ? history?.state?.from
          : "",
      });
      if (history?.state?.from) navigate(history?.state?.from);
      else if (window.location.host === "uniflux.daolens.com")
        navigate("/app/dao/uniflux/172");
      else {
        handleRedirect();
      }
    }
  };
  const { wallet } = useSolana(
    onSolanaLoginSuccess,
    () => {
      setActiveStep(-1);
    },
    () => {
      setActiveStep(0);
    },
    "login"
  );
  const onNearLoginSuccess = (access_token, newUser) => {
    localStorage.setItem("access_token", access_token);
    mixpanel.identify(jwtDecode(access_token).id);
    localStorage.setItem("new_user", newUser);
    setLoading(false);

    if (newUser) setActiveStep(1);
    else {
      postMixpanel("track", "logged_in_using_near", {
        invite_link: history?.state?.from?.includes("invite")
          ? history?.state?.from
          : "",
      });
      if (history?.state?.from) navigate(history?.state?.from);
      else {
        if (window.location.host === "uniflux.daolens.com")
          navigate("/app/dao/uniflux/172");
        else {
          handleRedirect();
        }
      }
    }
  };

  const { modal } = useNear(
    onNearLoginSuccess,
    () => {
      setActiveStep(-1);
    },
    () => {
      setActiveStep(0);
    }
  );
  const handleGoogle = async (data) => {
    setActiveStep(-1);
    if (!data?.access_token) {
      setActiveStep(0);
      toast.error("Failed to login. Please try again later.");
    }
    customAxios
      .post("/auth/google/login", {
        idToken: data.access_token,
      })
      .then((res) => {
        window.localStorage.setItem("access_token", res.data.access_token);
        window.localStorage.setItem("new_user", res.data.newUser);

        mixpanel.identify(jwtDecode(res.data.access_token).id);
        if (res.data.newUser) {
          setActiveStep(1);
          setEmailId(res.data.userObj.emailId);
          setProfileName(res.data.userObj.profileName);
          setReadOnlyEmail(!!res.data.userObj.emailId);
        } else {
          postMixpanel("track", "logged_in_using_google", {
            invite_link: history?.state?.from?.includes("invite")
              ? history?.state?.from
              : "",
          });
          if (history?.state?.from) navigate(history?.state?.from);
          else if (window.location.host === "uniflux.daolens.com")
            navigate("/app/dao/uniflux/172");
          else handleRedirect();
        }
      })
      .catch((err) => {
        console.error(err);
        setActiveStep(0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
          background: `url(${discovery}) `,
          backgroundSize: "cover",
        }}
      ></div>
      {activeStep === -1 ? getActiveStep(-1) : null}
      {activeStep > -1 ? (
        <Modal
          title=""
          closable={false}
          width={isMobile ? "auto" : activeStep !== 0 ? "540px" : "900px"}
          visible={true}
          onOk={handleOk}
          footer=""
          centered={!isMobile}
          bodyStyle={{ height: "auto" }}
          style={{
            top: isMobile ? modalTop : undefined,
          }}
          maskStyle={{ background: "transparent" }}
        >
          {getActiveStep(activeStep)}
        </Modal>
      ) : null}
    </div>
  );
};

export default LoginModal;

const BackIcon = ({ props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
      />
    </svg>
  );
};

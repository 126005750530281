import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { Modal } from "../../styles/ModalStyles";
function DeleteEntityModal(props) {
  const {
    isModalVisible,
    onClose,
    title = "Confirm",
    note = "",
    onSubmit,
    cta = "Delete",
  } = props;
  return (
    <Modal
      width={"450px"}
      height="auto"
      visible={isModalVisible}
      centered
      onCancel={onClose}
      footer={null}
    >
      <div className="p-5">
        <div className="text-base font-semibold">{title}</div>
        {note && (
          <div className="text-sm font-normal text-gray-500 mt-3">{note}</div>
        )}
        <div className="mt-5 flex justify-end gap-2">
          <Button
            onClick={() => onSubmit()}
            style={{ borderRadius: "12PX", color: "#6B7280" }}
          >
            {cta}
          </Button>
          <Button
            className="rounded-corner"
            type="primary"
            style={{ borderRadius: "12PX" }}
            onClick={() => onClose()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default DeleteEntityModal;

import React from "react";
import { ReactComponent as PostIcon } from "./../../../assets/iconSvg/notificationCommentIcon.svg";
function EntityTypeChip({ type }) {
  const typeList = [
    {
      label: "Discussions",
      key: "POST",
      icon: PostIcon,
      backgroundColor: "#EFF6FF",
      textColor: "#3B82F6",
    },
    {
      label: "Project",
      key: "PROJECTS",
      icon: PostIcon,
      backgroundColor: "#ECFEFF",
      textColor: "#06B6D4",
    },
    {
      label: "Bounty",
      key: "BOUNTY",
      icon: PostIcon,
      backgroundColor: "#ECFDF5",
      textColor: "#10B981",
    },
    {
      label: "Proposal",
      key: "PROPOSALS",
      icon: PostIcon,
      backgroundColor: "#FDF2F8",
      textColor: "#E74694",
    },
    {
      label: "Course",
      key: "COURSES",
      icon: PostIcon,
      backgroundColor: "#EFF6FF",
      textColor: "#3B82F6",
    },
    {
      label: "Announcement",
      key: "ANNOUNCEMENT",
      icon: PostIcon,
      backgroundColor: "#EFF6FF",
      textColor: "#3B82F6",
    },
  ];
  const getTypeChip = (type) => {
    return typeList.find((ele) => ele?.key === type);
  };
  return (
    <div
      style={{
        width: "fit-content",
        display: "flex",
        backgroundColor: getTypeChip(type).backgroundColor,
        color: getTypeChip(type).textColor,
        padding: "4px 8px",
        borderRadius: "8px",
      }}
    >
      {/* <span>{getTypeChip(type).icon}</span> */}
      <span style={{ fontSize: "12px" }}>{getTypeChip(type).label}</span>
    </div>
  );
}

export default EntityTypeChip;

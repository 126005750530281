import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Col, Row, Switch } from "antd";
import React, { useContext, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import customAxios from "../../../api";
import useWindowSize from "../../../hooks/useWindowSize";
import { DaoContext } from "../../../layouts/app/DaoContext";
import IconWrapper from "../../IconWrapper";

function DaoNotifications() {
  const toggleDiscordNotifications = (flag) => {
    const formData = new FormData();
    formData.append("daoId", daoId);
    formData.append("discordNotificationsEnabled", flag);
    customAxios
      .post("dao/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          reload();
        }
      })
      .catch((err) => {
        toast.error("Some error occured");
        console.log(err);
      });
  };

  const {
    daoDetails,
    reload,
    loading: daoDataLoading,
    daoId,
  } = useContext(DaoContext);

  const isDiscordConnected = !!daoDetails?.discordGuildId;
  const isDiscordNotificationsEnabled = daoDetails?.discordNotificationsEnabled;
  const { isMobile } = useWindowSize();

  return (
    <>
      <Col xs={24} style={{ padding: "0px", marginTop: "20px" }}>
        {isMobile ? null : (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #F4F2F2",
              borderRadius: "16px",
              padding: "12px 20px",
              background: "white",
              marginBottom: "12px",
              minHeight: "58px",
            }}
          >
            <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>
              Integrations
            </h4>
          </Row>
        )}
        <Row
          xs={24}
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "center",
            background: "white",
            borderRadius: "12px",
            padding: "12px 20px",
            rowGap: "8px",
          }}
        >
          <Row
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              rowGap: "8px",
            }}
          >
            <Col
              md={18}
              xs={24}
              style={{ alignItems: "center", columnGap: "4px" }}
            >
              <h4 style={{ marginBottom: "0px" }}>Discord Notifications</h4>
              <span
                style={{
                  fontSize: "12px",
                  color: "#64748B",
                  lineHeight: "12px",
                  marginBottom: "0px",
                }}
              >
                This will send notifications to the community users in a
                dedicated Discord Channel
              </span>
            </Col>
            {isDiscordConnected ? (
              <Col>
                <Switch
                  disabled={daoDataLoading}
                  checked={isDiscordNotificationsEnabled}
                  onChange={(flag) => {
                    toggleDiscordNotifications(flag);
                  }}
                  style={{ marginLeft: "auto" }}
                />
              </Col>
            ) : (
              <Col xs={24} md={4}>
                <Button
                  onClick={() =>
                    window.open(
                      `https://discord.com/api/oauth2/authorize?client_id=${
                        process.env.REACT_APP_ENDPOINT === "development"
                          ? "1046753505026785401"
                          : "1014817083139301417"
                      }&redirect_uri=${
                        encodeURIComponent(window.location.origin) +
                        "/app/redirect/discordIntgeration"
                      }&response_type=code&state=${btoa(
                        JSON.stringify({
                          daoId,
                          from: "settings/notifications",
                        })
                      )}&scope=identify%20guilds`,
                      "_self"
                    )
                  }
                  type="primary"
                  style={{
                    borderRadius: "12px",
                    background: "#3B82F6",
                    padding: "6px 16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Add bot{" "}
                </Button>
              </Col>
            )}{" "}
          </Row>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <div
              style={{
                borderTop: "1px solid #F3F4F6",
                margin: "8px 0px",
                height: "1px",
                width: "100%",
              }}
            ></div>
          </Row>
          <Col xs={24}>
            <div
              style={{
                padding: "12px 16px",
                background: "#EFF6FF",
                borderRadius: "8px",
                marginBottom: "12px",
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
                border: "1px solid #3B82F6",
              }}
            >
              <IconWrapper>
                <InfoCircleFilled
                  style={{ fontSize: "16px", color: "#3B82F6" }}
                />
              </IconWrapper>
              <h5
                style={{
                  color: "#3B82F6",
                  lineHeight: "16px",
                  marginBottom: "0px",
                }}
              >
                Once you add the bot, we’ll be creating a channel in your
                discord server, where you’ll receive notifications based on your
                community activities on the DAO manager
              </h5>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default DaoNotifications;

import cover from "../../assets/images/profile/cover.png";
import { ReactComponent as Task } from "../../assets/images/profile/task.svg";
import { ReactComponent as Earn } from "../../assets/images/profile/dollar.svg";
import {
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
  PencilIcon,
  WalletIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as Twitter } from "../../assets/images/profile/twitter.svg";
import { ReactComponent as Github } from "../../assets/images/profile/github.svg";
import { ReactComponent as Website } from "../../assets/images/profile/website.svg";
import Card, { Title } from "../Common/Card";
import DaoSection from "./DaoSection";
import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  Row,
  Skeleton,
} from "antd";
import { useContext, useEffect, useState } from "react";
import EditProfileModal from "./EditProfileModal";
import customAxios from "../../api";
import HorizontalDot from "./../../assets/iconSvg/horizontalthreedot.svg";
import ConnectedAccounts from "./ConnectedAccounts";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../layouts/app/UserContext";
import DaoJoined from "./DaoJoined";
import EmptyComponent from "../Common/EmptyComponent";
import DeleteModal from "../Common/DeleteModal";
import styled from "styled-components";
import { toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize";
import {
  CopyOutlined,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { copyTextToClipboard, getTruncatedAddress } from "../../utils";
import { ethers } from "ethers";
import { Modal } from "../../styles/ModalStyles";
import IconWrapper from "../IconWrapper";
import InputField from "../Settings/DaoProfile/InputField";
import elliptic from "elliptic";
import crypto from "crypto";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import LensProfile from "./LensProfile";
const StyledDotIconContainer = styled.div`
  & img:hover {
    cursor: pointer;
  }
`;

const StyledDropdownMenu = styled(Menu)`
  border-radius: 8px;
`;
export const iconStyling = {
  borderRadius: "2px",
  color: "white",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const getProfileDetails = async (userId) => {
  let res = userId ? `?userId=${userId}` : "";
  const { data } = await customAxios.get("users/profile/fetch" + res);
  return data.userDetails[0];
};

const Profile = () => {
  const { isMobile } = useWindowSize();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useDaoLensNavigate();
  const [editProfile, setEditProfile] = useState(false);
  const [userDetails, setUserDetails] = useState(undefined);
  const [updater, setUpdater] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [keysData, setKeysData] = useState(null);
  const [keysModalOpen, setKeysModalOpen] = useState(false);
  const [viewPublicKey, setViewPublicKey] = useState(false);
  const handleGenerateKeys = () => {
    const wallet = ethers.Wallet.createRandom();
    var randomPhrase = wallet.mnemonic.phrase;
    var EdDSA = elliptic.eddsa;
    var ec = new EdDSA("ed25519");
    const hash = crypto.createHash("sha256");
    hash.update(randomPhrase);
    const secretKey = hash.digest().toString("hex");
    // 846122b479a5198a835a6b00712290bab77da52da41ece8fa9b960ed0e7ec5d0

    var key = ec.keyFromSecret(secretKey); // hex string, array or Buffer
    const publicKey = Buffer.from(key.getPublic()).toString("hex");

    setKeysData({ publicKey: publicKey, privateKey: secretKey });
    customAxios
      .put("/users/profile/update", { publicKey: publicKey })
      .then((res) => {
        reload();
        console.log("submit", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setKeysModalOpen(true);
  };

  const {
    userDetails: currentUserDetails,
    loading: currentUserLoading,
    update,
    reload,
  } = useContext(UserContext);
  const [opacity, setOpacity] = useState(0);
  const { userId } = useParams();
  const settingsMenu = (
    <StyledDropdownMenu>
      <Menu.Item
        style={{ borderRadius: "8px", color: "red" }}
        onClick={() => setIsModalVisible(true)}
      >
        <i class="fas fa-sign-out-alt" style={{ marginRight: "10px" }} />
        Delete Account
      </Menu.Item>
    </StyledDropdownMenu>
  );
  let totalTasks = 0;
  let totalEarned = 0;
  let taskPoints = 0;
  if (userDetails?.totalTasks && userDetails?.totalTasks.length !== 0) {
    totalTasks = userDetails?.totalTasks[0].count;
  }
  if (userDetails?.totalEarned && userDetails?.totalEarned.length !== 0) {
    totalEarned = userDetails?.totalEarned[0].sum;
  }
  if (userDetails?.taskPoints && userDetails?.taskPoints.length !== 0) {
    taskPoints = userDetails?.taskPoints[0].sum;
  }
  let pallets = [
    {
      icon: <Task style={{ width: "16px" }} />,
      label: "Bounties",
      value: totalTasks,
    },
    {
      icon: "⭐️",
      label: "Task Points",
      value: userDetails?.taskPoint ? userDetails?.taskPoint : 0,
    },
    { icon: <Earn />, label: "Earned", value: totalEarned },
  ];

  useEffect(() => {
    if (!userId || userId === currentUserDetails?.id) {
      setUserDetails(currentUserDetails);
      setEditable(true);
    } else {
      setLoading(true);
      getProfileDetails(userId)
        .then((data) => {
          setUserDetails(data);
        })
        .finally(() => setLoading(false));
      setEditable(false);
    }
  }, [update, userId, JSON.stringify(currentUserDetails)]);

  const handleScroll = () => {
    if (window.scrollY <= 350) setOpacity(window.scrollY / 306);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const palletElement = pallets.map(({ icon, label, value }, index) => {
    return (
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          borderRight: index !== pallets.length - 1 ? "1px solid #EDEBFE" : "",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        {icon}
        <div style={{ display: "inline-block", marginLeft: "8px" }}>
          {value ? value : "0"}
          <div style={{ color: "#64748B", fontSize: "12px" }}>{label}</div>
        </div>
      </div>
    );
  });

  let wlAdrs = userDetails?.walletAddress;
  return (
    <div
      style={{ marginBottom: !isMobile && "16px", width: isMobile && "100vw" }}
    >
      {isModalVisible ? (
        <DeleteModal
          buttonLabel={"Delete Account"}
          checkSubmitButtonDisabled={(data) => {
            return data?.["userName"]?.toLowerCase() ===
              userDetails?.userName?.toLowerCase()
              ? false
              : true;
          }}
          title="Delete Account"
          note="All your activity on the platform will be lost forever"
          isModalVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          inputList={[
            {
              key: "userName",
              label: (
                <>
                  Username{" "}
                  <span style={{ marginLeft: "4px", fontWeight: 500 }}>
                    ({userDetails?.userName})
                  </span>
                </>
              ),
              otherProps: {
                placeholder: "Type in your username to delete...",
              },
            },
          ]}
          onSubmit={(data) => {
            customAxios
              .delete(`/users/delete`)
              .then((res) => {
                if (res.data.success) {
                  localStorage.clear();
                  toast.success("Account deleted successfully");
                  navigate("/auth/login");
                }
              })
              .catch((err) => toast.error("Some error occured"));
          }}
        />
      ) : null}

      {editProfile && (
        <EditProfileModal
          isModalVisible={editProfile}
          handleClose={() => {
            setEditProfile(false);
          }}
        />
      )}
      {userDetails && (
        <>
          <header
            style={{
              opacity: 1 - opacity,
              marginBottom: "12px",
              height: isMobile ? "auto" : "306px",
              paddingBottom: isMobile && "1px",
              background: "#FFFFFF",
              borderRadius: !isMobile && "0px 0px 12px 12px",
              position: "relative",
            }}
          >
            <img
              src={
                userDetails.backgroundImage
                  ? userDetails.backgroundImage
                  : cover
              }
              style={{ height: "180px", width: "100%", objectFit: "cover" }}
              alt="cover"
            />
            {userDetails.profilePicture ? (
              <img
                src={userDetails.profilePicture}
                style={{
                  width: "120px",
                  height: "120px",
                  filter:
                    "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow( 0px 4px 6px rgba(0, 0, 0, 0.05))",
                  position: "absolute",
                  background: "white",
                  left: "32px",
                  top: "142px",
                  borderRadius: "50%",
                  border: "2px solid white",
                  objectFit: "cover",
                }}
                alt="profile"
              />
            ) : (
              <UserCircleIcon
                style={{
                  width: "120px",
                  height: "120px",
                  filter:
                    "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow( 0px 4px 6px rgba(0, 0, 0, 0.05))",
                  position: "absolute",
                  left: "32px",
                  top: "142px",
                  borderRadius: "50%",
                  border: "2px solid white",
                  background: "#F4F2F2",
                  color: "#64748B",
                }}
              />
            )}
            {editable ? (
              <div
                style={{
                  position: "absolute",
                  top: "16px",
                  right: "150px",
                  backgroundColor: "rgba(0, 0, 0, 0.16)",
                  boxShadow:
                    "0px 4px 8px rgba(0, 0, 0, 0.08), inset 3.6px -3.6px 3.6px rgba(0, 45, 121, 0.1), inset -3.6px 3.6px 3.6px rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(3.6px)",
                  borderRadius: "50%",
                  cursor: "pointer",
                  zIndex: "100",
                }}
              >
                <Dropdown overlay={settingsMenu} trigger={["click"]}>
                  <StyledDotIconContainer>
                    <img
                      src={HorizontalDot}
                      alt="dot icon"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </StyledDotIconContainer>
                </Dropdown>
              </div>
            ) : null}
            {editable && (
              <Button
                onClick={() => {
                  setEditProfile(true);
                }}
                style={{
                  height: "32px",
                  position: "absolute",
                  top: "12px",
                  right: "12px",
                  borderRadius: "8px",
                  borderColor: "#E2E8F0",
                  display: "flex",
                  color: "#64748B",
                  alignItems: "center",
                  gap: "6px",
                  zIndex: "20",
                }}
              >
                <PencilIcon fill="#6B7280" width="14px" /> Edit Profile
              </Button>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: isMobile ? "" : "center",
                margin: "16px",
                gap: "16px",
              }}
            >
              <div style={{ marginLeft: "152px" }}>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "32px",
                  }}
                >
                  {userDetails.profileName}
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    color: "cornflowerblue",
                    fontSize: "14px",
                    marginTop: "4px",
                    cursor: "pointer",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                    lineHeight: "13.27px",
                  }}
                >
                  <span className="text-ellipsis whitespace-nowrap max-w-xs overflow-hidden">
                    {userDetails.userName}
                  </span>
                  <Button
                    type="text"
                    style={{ color: "cornflowerblue" }}
                    size="small"
                    icon={<DocumentDuplicateIcon width={16} />}
                    onClick={() =>
                      copyTextToClipboard(userDetails.userName).then((res) =>
                        toast.success("Username copied successfully")
                      )
                    }
                  ></Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    marginTop: "10px",
                    height: "24px",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {userDetails.twitter && (
                    <Twitter
                      onClick={() => {
                        window.open(userDetails.twitter);
                      }}
                    />
                  )}
                  {userDetails.github && (
                    <Github
                      onClick={() => {
                        window.open(userDetails.github);
                      }}
                    />
                  )}
                  {userDetails.website && (
                    <Website
                      onClick={() => {
                        window.open(userDetails.website);
                      }}
                    />
                  )}

                  {wlAdrs && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#64748B",
                        background: "#F4F2F2",
                        height: "100%",
                        padding: "2px 8px",
                        fontSize: "12px",
                        borderRadius: "8px",
                        gap: "4px",
                      }}
                    >
                      {" "}
                      <WalletIcon width="16px" height="20px" />
                      {wlAdrs.substring(0, 5)}....{wlAdrs.slice(-4)}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  height: "72px",
                  background: "#F6F5FF",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "24px",
                }}
              >
                {palletElement}
              </div>
            </div>
          </header>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile && "column",
              flexWrap: isMobile && "wrap",
              margin: isMobile && "16px",
              rowGap: isMobile && "16px",
            }}
          >
            <aside
              style={{
                position: !isMobile && "sticky",
                top: 0,
                width: isMobile ? "auto" : "312px",
                display: "inline-flex",
                flexDirection: "column",
                gap: isMobile ? "16px" : "12px",
                margin: "0",
              }}
            >
              <Card>
                <Title title="About" />
                <p
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {userDetails.description}
                </p>
                {userDetails.skills && userDetails.skills.length > 0 && (
                  <>
                    <Title title="Skills" />
                    {userDetails.skills?.map((skill) => (
                      <div
                        key={skill}
                        style={{
                          padding: "2px 8px",
                          marginRight: "4px",
                          marginBottom: "4px",
                          fontSize: "12px",
                          background: "#F4F2F2",
                          borderRadius: "8px",
                          height: "20px",
                          display: "inline-flex",
                          alignItems: "center",
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "167%",
                        }}
                      >
                        {skill}
                      </div>
                    ))}
                  </>
                )}
              </Card>
              {editable ? (
                userDetails?.publicKeyNative ? (
                  <div
                    style={{
                      background: "white",
                      padding: "16px 20px",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3 style={{ marginBottom: "0px", fontWeight: "600" }}>
                        🔑 Your Public Key
                      </h3>
                      <div style={{ display: "flex", columnGap: "12px" }}>
                        <Button
                          type="text"
                          style={{ width: "20px", margin: "0", padding: "0" }}
                          onClick={() => {
                            copyTextToClipboard(
                              userDetails?.publicKeyNative
                            ).then((res) => {
                              toast.success("Public key copied");
                            });
                          }}
                          title="Copy public key"
                        >
                          <DocumentDuplicateIcon color="#9CA3AF" width={20} />
                        </Button>
                        <Button
                          type="text"
                          style={{ width: "20px", margin: "0", padding: "0" }}
                          title={
                            viewPublicKey
                              ? "Hide public key"
                              : "View public key"
                          }
                          onClick={() => {
                            setViewPublicKey((prev) => !prev);
                          }}
                        >
                          {viewPublicKey ? (
                            <EyeSlashIcon
                              style={{ color: "#9CA3AF", width: "20px" }}
                            />
                          ) : (
                            <EyeIcon
                              style={{ color: "#9CA3AF", width: "20px" }}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                    <h4
                      style={{ marginBottom: "0px", overflowWrap: "anywhere" }}
                    >
                      {viewPublicKey
                        ? userDetails?.publicKeyNative
                        : getTruncatedAddress(userDetails?.publicKeyNative)}
                    </h4>
                  </div>
                ) : (
                  <div
                    style={{
                      background: "white",
                      padding: "16px 20px",
                      borderRadius: "12px",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "8px",
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: "0px",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      🔑 Key Generator
                    </h3>
                    <h4
                      style={{
                        marginBottom: "0px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Generate your keys here to get verified on applications
                      like Github
                    </h4>
                    <Button
                      style={{
                        marginTop: "8px",
                        borderRadius: "8px",
                        fontSize: "12px",
                      }}
                      type="primary"
                      block={true}
                      onClick={() => {
                        handleGenerateKeys();
                      }}
                    >
                      Generate keys
                    </Button>
                  </div>
                )
              ) : null}
              {userDetails.daoJoined.length > 0 && (
                <DaoJoined
                  daoJoined={userDetails.daoJoined}
                  setUpdater={setUpdater}
                  editable={editable}
                />
              )}
              {userDetails?.isLensConnected && userDetails?.lensProfileDetails ? <LensProfile profile={userDetails?.lensProfileDetails} /> : null}
              {editable && (
                <ConnectedAccounts
                  noOfConnectedAccounts={userDetails?.noOfConnectedAccounts}
                  isPetraConnected={userDetails?.isAptosConnected}
                  isTwitterConnected={userDetails?.isTwitterConnected}
                  isDiscordConnected={userDetails.isDiscordConnected}
                  isMetamaskConnected={userDetails.isMetamaskConnected}
                  isGoogleConnected={userDetails.isGoogleConnected}
                  isPhantomConnected={userDetails.isPhantomConnected}
                  isGithubConnected={userDetails?.isSignatureNativeVerified}
                  publicKey={userDetails?.publicKeyNative}
                  isNearConnected={userDetails?.nearWalletKey}
                  isLensConnected={userDetails?.isLensConnected}
                  reload={() => {
                    reload();
                    setUpdater((prev) => !prev);
                  }}
                />
              )}

            </aside>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: !isMobile && "12px",
              }}
            >
              <DaoSection updater={updater} />
              <section
                style={{
                  marginLeft: "12px",
                  width: !isMobile && "636px",
                }}
              >
                {/* <Rewards
                  selectedTab={selectedRewardsTab}
                  setSelectedTab={setSelectedRewardsTab}
                  data={rewardsData}
                  loading={rewardsLoading}
                /> */}
              </section>
            </div>
          </div>
          <div
            style={{
              position: "fixed",
              top: 0,
              background: "white",
              width: !isMobile && "960px",
              height: "72px",
              boxShadow:
                "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
              borderRadius: "0px 0px 12px 12px",
              display: isMobile ? "none" : "flex",
              alignItems: "center",
              paddingLeft: "32px",
              opacity,
            }}
          >
            {" "}
            {userDetails?.profilePicture ? (
              <img
                src={userDetails.profilePicture}
                style={{
                  width: "52px",
                  height: "53px",
                  filter:
                    "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow( 0px 4px 6px rgba(0, 0, 0, 0.05))",
                  background: "white",
                  borderRadius: "50%",
                  border: "2px solid white",
                  objectFit: "cover",
                }}
                alt="profile"
              />
            ) : (
              <UserCircleIcon
                style={{
                  height: "53px",
                  filter:
                    "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow( 0px 4px 6px rgba(0, 0, 0, 0.05))",
                  background: "white",
                  borderRadius: "50%",
                  border: "2px solid white",
                  objectFit: "cover",
                  color: "#64748B",
                }}
              />
            )}
            <div
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "15.92px",
                lineHeight: "21.23px",
                paddingLeft: "12px",
              }}
            >
              {userDetails.profileName}{" "}
              <div
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,

                  fontSize: "9.29px",
                  alignItems: "center",

                  lineHeight: "13.27px",
                }}
              >
                {userDetails.userName}
              </div>
            </div>
            <div
              style={{
                marginLeft: "auto",
                height: "72px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {pallets.map(({ icon, label, value }, index) => {
                return (
                  <div
                    style={{
                      alignItems: "flex-start",
                      display: "flex",
                      borderRight:
                        index !== pallets.length - 1 ? "1px solid #EDEBFE" : "",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  >
                    {icon}
                    <div style={{ display: "inline-block", marginLeft: "8px" }}>
                      {value ? value : "0"}
                      <div style={{ color: "#64748B", fontSize: "12px" }}>
                        {label}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      {(loading || currentUserLoading) && !userDetails && (
        <div style={{ margin: isMobile && "5px" }}>
          <div style={{ width: isMobile ? "100%" : "960px", height: "306px" }}>
            <Skeleton active paragraph={{ rows: 8 }} />
          </div>
          <aside
            style={{
              width: isMobile ? "30%" : "312px",
              display: "inline-block",
            }}
          >
            <Skeleton active paragraph={{ rows: 11 }} />
          </aside>
          <section
            style={{
              display: "inline-block",
              marginLeft: "12px",
              width: isMobile ? "65%" : "636px",
            }}
          >
            <Skeleton active paragraph={{ rows: 11 }} />
          </section>
        </div>
      )}
      {keysData && keysModalOpen ? (
        <Modal
          width={"600px"}
          isMobile={isMobile}
          title={<h3 style={{ paddingLeft: "12px" }}>Keys Generator</h3>}
          style={{ maxHeight: "fit-content" }}
          visible={keysModalOpen}
          onCancel={() => setKeysModalOpen(false)}
          destroyOnClose
          footer={
            <Button
              type="primary"
              onClick={() => setKeysModalOpen(false)}
              style={{ borderRadius: "8px" }}
            >
              Done
            </Button>
          }
        >
          <Row style={{ padding: "20px 24px", borderTop: "1px solid #E2E8F0" }}>
            <Col xs={24}>
              <InputField
                inputProps={{ readOnly: true }}
                value={keysData.publicKey}
                label="Your Public Key"
              ></InputField>
            </Col>
            <Col xs={24}>
              <InputField
                inputProps={{
                  readOnly: true,
                  suffix: (
                    <Button
                      type="text"
                      style={{ color: "#9CA3AF", width: "16px" }}
                      onClick={() => {
                        copyTextToClipboard(keysData.privateKey).then((res) => {
                          toast.warn(
                            "Private key copied. Remember to clear the clipboard after pasting."
                          );
                        });
                      }}
                    >
                      <IconWrapper>
                        <DocumentDuplicateIcon style={{ fontSize: "16px" }} />
                      </IconWrapper>
                    </Button>
                  ),
                }}
                label="Your Private Key"
                value={keysData.privateKey}
              ></InputField>
            </Col>
            <Col xs={24}>
              <div
                style={{
                  padding: "12px 16px",
                  background: "#FFFBEB",
                  borderRadius: "8px",
                  marginBottom: "12px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "8px",
                  border: "1px solid #F59E0B",
                }}
              >
                <IconWrapper>
                  <ExclamationCircleFilled
                    style={{ fontSize: "18px", color: "#F59E0B" }}
                  />
                </IconWrapper>
                <h5
                  style={{
                    color: "#F59E0B",
                    lineHeight: "16px",
                    marginBottom: "0px",
                  }}
                >
                  Your private key will only be visible once. Please write down
                  your private key in a safe place. You will not be able to view
                  it again.
                </h5>
              </div>
            </Col>
          </Row>
        </Modal>
      ) : null}
      {!(loading || currentUserLoading) && !userDetails ? (
        <EmptyComponent text="You don't have access to view this page" />
      ) : null}
    </div>
  );
};

export default Profile;

import { Button } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import { DaoContext } from "../../../layouts/app/DaoContext";
import { Card, InputField } from "./Common";

const JoinDiscord = ({ state, setState }) => {
  const { daoDetails, daoId } = useContext(DaoContext);
  const navigate = useDaoLensNavigate();
  return (
    <div
      style={{
        padding: "20px",
        font: "normal 500 12px/16px Inter",
        color: "#64748B",
      }}
    >
      {daoDetails?.discordGuildId ? (
        <Card>
          <InputField
            label="Discord invite"
            placeholder="Discord invite link"
            description="Make sure the invite link is matching your connected discord"
            value={state.link}
            onChange={(e) => {
              setState((prev) => ({ ...prev, link: e.target.value }));
            }}
          />
        </Card>
      ) : (
        <Button
          className="rounded"
          type="primary"
          style={{
            borderRadius: "12px",
            padding: "6px 16px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() =>
            window.open(
              `https://discord.com/api/oauth2/authorize?client_id=${
                process.env.REACT_APP_ENDPOINT === "development"
                  ? "1046753505026785401"
                  : "1014817083139301417"
              }&redirect_uri=${
                encodeURIComponent(window.location.origin) +
                "/app/redirect/discordIntgeration"
              }&response_type=code&state=${btoa(
                JSON.stringify({ daoId, from: `/app/dao/${daoId}/bounty/?c=t` })
              )}&from=${btoa("onboarding")}&scope=identify%20guilds`,
              "_self"
            )
          }
        >
          Connect Discord
        </Button>
      )}
    </div>
  );
};

export default JoinDiscord;
export const discordValidation = (state, res) => {
  if (!state.link) {
    toast.error("Please provide discord invite link");
    throw new Error();
  } else {
    res.link = state.link;
  }
};

export const discordInitialState = (bountyDetails) => {
  return { link: bountyDetails?.link };
};

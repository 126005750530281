import { Menu, MenuProps } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { Modal as AntModal } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import GPT from "./GPT";
import { rephrase } from "./MenuItem";
import { languages } from "./language";
import React from "react";

interface GPTMenuPropps {
  onSubmit: (e: any) => void;
  position: {
    left: number;
    top: number;
  };
  buttonMenuPosition: {
    left: number;
    top: number;
  };

  isMenuVisible: boolean;
  range?: number;
  openInModal?: boolean;
  menuItems: ItemType[];
  requestText?: string;
  buttonMenu?: boolean;
  onDropdownClose: () => void;
}

const StyledMenu = styled(Menu)`
  .ant-menu-item:focus,
  .ant-menu-item-selected:focus,
  .ant-menu-submenu:focus,
  .ant-menu-submenu-title:focus {
    outline: none;
    box-shadow: none;
  }
`;
const GPTMenu = ({
  buttonMenu,
  onSubmit,
  position,
  isMenuVisible,
  openInModal,
  menuItems = [],
  requestText,
  onDropdownClose,
  buttonMenuPosition,
}: GPTMenuPropps) => {
  const menuRef: any = useRef(null);
  const gptRef: any = useRef(null);
  const [prompt, setPrompt] = useState("");
  const [selectedPromptType, setSelectedPromptType] = useState<
    string | undefined
  >(undefined);
  const [data, setData] = useState<any>(undefined);

  useOnClickOutside(menuRef, () => {
    onDropdownClose();
  });
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const menuClickHandler = async (e: any) => {
    setSelectedKeys(e.keyPath);
    if (openInModal) {
      switch (e.keyPath[1]) {
        case "TRANSLATE":
          setPrompt(
            `Translate "${requestText}" to ${
              languages.find((language) => language.code === e.key)?.language
            }`
          );
          setSelectedPromptType("TRANSLATE");
          setData({ target: e.key });
          break;
        case "CHANGE_TONE":
          setPrompt(`Change tone of "${requestText}" to friendly`);
          setSelectedPromptType(e.key);
          break;
        case "REPHRASE_CONTENT": {
          setPrompt(
            `Rephrase "${requestText}" as ${
              rephrase.find((item) => item.value === e.key)?.label
            }`
          );
          setSelectedPromptType(e.key);
          break;
        }
      }
      switch (e.key) {
        case "HELP": {
          setSelectedPromptType("");
          setPrompt("Help me write about");
          break;
        }
        case "BRAINSTROM": {
          setSelectedPromptType("");

          setPrompt("Brainstorm ideas on");
          break;
        }
        case "SUMMARIZE": {
          setPrompt(`Summarise "${requestText}"`);
          setSelectedPromptType("SUMMARIZE");
          break;
        }
        case "CHECKLIST": {
          setPrompt(
            `Create checklist on ${requestText ? `"${requestText}"` : ""}`
          );
          setSelectedPromptType("CHECKLIST");
          break;
        }
        case "POLL": {
          setPrompt(`Create poll on ${requestText ? `"${requestText}"` : ""}`);
          setSelectedPromptType("POLL");
          break;
        }

        case "MAKE_LONGER": {
          setPrompt(`Make longer "${requestText}"`);
          setSelectedPromptType("MAKE_LONGER");
          break;
        }

        case "MAKE_SHORTER": {
          setPrompt(`Make shorter "${requestText}"`);
          setSelectedPromptType("MAKE_SHORTER");
          break;
        }
        case "GRAMMER_CHECK": {
          setPrompt(`Check grammar of "${requestText}"`);
          setSelectedPromptType("GRAMMER_CHECK");
          break;
        }
        case "MAKE_COMMENT": {
          setPrompt(`Make comment of "${requestText}"`);
          setSelectedPromptType("MAKE_COMMENT");
          break;
        }
        case "REPHRASE_CONTENT": {
          setPrompt(`Rephrase "${requestText}"`);
          setSelectedPromptType("REPHRASE_CONTENT");
          break;
        }
      }
      setModalVisible(true);
      onDropdownClose();
    } else {
      onSubmit(e);
    }
  };
  useOnClickOutside(gptRef, () => {
    setModalVisible(false);
  });
  const menuFocusRef: any = useRef(null);
  useEffect(() => {
    if (menuFocusRef.current?.focus && isMenuVisible) {
      setTimeout(() => {
        menuFocusRef.current.focus();
      }, 100);
    } else {
      setSelectedKeys([]);
    }
  }, [isMenuVisible]);

  return (
    <>
      <div ref={menuRef}>
        <StyledMenu
          ref={menuFocusRef}
          selectedKeys={selectedKeys}
          role="menu"
          onClick={(e) => {
            setTimeout(() => {
              menuClickHandler(e);
            }, 10);
          }}
          style={{
            display: isMenuVisible ? "block" : "none",
            left: buttonMenu ? buttonMenuPosition.left : position.left,
            top: buttonMenu ? buttonMenuPosition.top : position.top,
            position: "absolute",
            zIndex: 100000000000,
            border: "1px solid #E2E8F0",
            borderRadius: "12px",
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            color: "#6B7280",
          }}
          mode="vertical"
          items={menuItems}
        />
      </div>
      <div
        ref={gptRef}
        style={{
          position: "absolute",
          width: "100%",
          padding: "6px",
          top: position.top,
        }}
      >
        {isModalVisible && (
          <GPT
            onSubmit={(res) => {
              onSubmit(res);
              setModalVisible(false);
            }}
            openInModal={openInModal}
            prompt={prompt}
            promptType={selectedPromptType}
            requestText={requestText}
            data={data}
          />
        )}
      </div>
    </>
  );
};
export default GPTMenu;

import { Skeleton } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import MobilePageTopNavbar from "../../components/Common/MobilePageTopNavbar";
import CreateSpaceModal from "../../components/Spaces/CreateSpaceModal";
import SpaceCard from "../../components/Spaces/SpaceCard";
import useCanEdit from "../../hooks/useCanEdit";
import useWindowSize from "../../hooks/useWindowSize";
import SpaceEmptyImage from "../../assets/images/spaces/empty-state.png";
import SpacesIcon from "../../images/spaces-icon.svg";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { fetchSpaces } from "../../lib/utils/spaces";

const DaoSpaces = () => {
  const { isMobile } = useWindowSize();
  const { daoId, daoName } = useDaoContext();

  const canEdit = useCanEdit({ daoId });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { data, isLoading } = useQuery(
    ["fetch-spaces", daoId],
    () => fetchSpaces(daoId || ""),
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to fetch spaces. Try again later.");
      },
    }
  );

  const isJoinedSpacesEmpty = !data?.joinedSpaces?.length;
  const isOtherSpacesEmpty = !data?.otherSpaces?.length;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "0.75rem",
        width: isMobile ? "100vw" : "auto",
        paddingLeft: isMobile ? "15px" : "0",
      }}
    >
      {isMobile ? (
        <div>
          <MobilePageTopNavbar
            rightElement={
              canEdit &&
              !(isJoinedSpacesEmpty && isOtherSpacesEmpty && !isLoading) ? (
                <button
                  onClick={() => {
                    setIsCreateModalOpen(true);
                  }}
                  className="rounded-lg lg:rounded-xl cursor-pointer border-none bg-blue-500 text-white py-1 px-4 hover:bg-blue-600 active:bg-blue-700"
                >
                  Create Space
                </button>
              ) : (
                <></>
              )
            }
          />
        </div>
      ) : (
        <div
          style={{
            paddingLeft: "20px",
            height: "54px",
            display: "flex",
            background: "white",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1.4rem",
            border: "1px solid #f4f2f2",
            boxShadow: "0px 4px 16px rgba(132, 148, 160, 0.12)",
            borderRadius: "16px",
            position: "relative",
          }}
        >
          <img
            src={SpacesIcon}
            alt="Members"
            style={{ objectFit: "contain", width: "20px" }}
          />
          <h2
            style={{ marginBottom: "0", fontSize: "16px", marginLeft: "4px" }}
          >
            Spaces
          </h2>
          {canEdit &&
          !(isJoinedSpacesEmpty && isOtherSpacesEmpty && !isLoading) ? (
            <button
              onClick={() => {
                setIsCreateModalOpen(true);
              }}
              className="rounded-lg lg:rounded-xl cursor-pointer border-none bg-blue-500 text-white py-1 px-4 hover:bg-blue-600 active:bg-blue-700 ml-auto mr-5"
            >
              Create Space
            </button>
          ) : (
            <></>
          )}
        </div>
      )}

      {isLoading && <Skeleton active paragraph={{ rows: 4 }} />}

      {!isLoading && (!isJoinedSpacesEmpty || !isOtherSpacesEmpty) && (
        <div className="flex flex-col gap-6 mx-1 py-4 lg:bg-white lg:p-5  lg:mb-5 lg:rounded-2xl">
          {!isJoinedSpacesEmpty && (
            <div className="flex flex-col gap-4">
              <h2 className="m-0 text-base font-semibold">Joined Spaces</h2>
              <div className="grid lg:grid-cols-2 gap-5">
                {data?.joinedSpaces?.map((daoDetails) => (
                  <SpaceCard
                    {...daoDetails?.daoDetails?.[0]}
                    isJoined
                    parentDaoName={daoName}
                  />
                ))}
              </div>
            </div>
          )}
          {!isOtherSpacesEmpty && (
            <div className="flex flex-col gap-4">
              <h2 className="m-0 text-base font-semibold">
                All Community Spaces
              </h2>
              <div className="grid lg:grid-cols-2 gap-5">
                {data?.otherSpaces?.map((daoDetails) => (
                  <SpaceCard
                    {...daoDetails?.daoDetails?.[0]}
                    parentDaoName={daoName}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {isJoinedSpacesEmpty && isOtherSpacesEmpty && !isLoading && (
        <div className="bg-white px-3 py-11 rounded-2xl flex flex-col gap-5 items-center">
          {/* TODO: update placeholder graphic */}
          <img
            src={SpaceEmptyImage}
            className="h-32 w-32 lg:h-36 lg:w-36"
            alt="Spaces"
          />
          <div className="flex flex-col gap-2">
            <h1 className="m-0 text-center text-2xl font-semibold text-slate-900">
              Welcome to Spaces
            </h1>
            <p className="m-0 text-center text-gray-500 lg:text-gray-400 lg:text-base max-w-md">
              Spaces are like your private rooms in a big mansion. Use spaces to
              their full potential by creating specific teams to handle tasks
              and projects.
            </p>
          </div>
          {canEdit && (
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="rounded-lg lg:rounded-xl cursor-pointer border-none bg-blue-500 text-white py-2 px-4 w-full lg:w-auto hover:bg-blue-600 active:bg-blue-700"
            >
              Create your first Space
            </button>
          )}
        </div>
      )}
      <CreateSpaceModal
        setIsOpen={setIsCreateModalOpen}
        isOpen={isCreateModalOpen}
      />
    </div>
  );
};

export default DaoSpaces;

import { CalendarIcon } from "@heroicons/react/24/outline";
import { Button, Card, DatePicker, Divider, Space, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import IconWrapper from "./IconWrapper";
import { PopupCard } from "../styles/PopupCard";
import { LabelIcon } from "../styles/LabelIcon";
import { ArrowLeftOutlined, CloseCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

const validateInputValue = (val) => val !== "" && val !== "0";

const CustomDatePicker = styled(DatePicker)`
  position: absolute;
  padding: 0px;
  border: none;
  & .ant-picker-input {
    display: none;
  }
`;
const Grid = styled.div`
  height: 212px;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    height: 82px;
    background: #dddddd;
    border-radius: 50px;
  }
`;

const GridItem = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
`;

const getTimeValues = (interval) => {
  const locale = "en";
  const hours = [];

  moment.locale(locale);

  for (let hour = 0; hour < 24; hour++) {
    hours.push(moment({ hour }).format("h:mm A"));
    hours.push(
      moment({
        hour,
        minute: interval,
      }).format("h:mm A")
    );
  }
  return hours;
};

const DueDate = ({
  isSubtask,
  onChange = () => {},
  defaultValue,
  disabled = false,
  cardProps,
  buttonStyle,
  label = undefined,
  isCloseButtonHidden = false,
  isTaskModal,
}) => {
  const [dateUI, setDateUI] = React.useState(false);
  const [timeUI, setTimeUI] = React.useState(false);
  const [date, setDate] = React.useState("");
  const [time, setTime] = React.useState(undefined);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const timeUIRef = useRef(null);
  useOnClickOutside(timeUIRef, () => {
    onCancel();
  });

  const onChangeDateHandler = (date) => {
    setDate(date);
    setTimeUI(true);
    setDateUI(false);
  };
  const onSubmit = () => {
    onChange(
      date,
      time
        ? moment(time, "h:mm A").format("HH:mmZ")
        : moment().add(1, "minutes").format("HH:mmZ")
    );
  };
  const onCancel = () => {
    if (defaultValue) {
      setDate(moment(defaultValue));
      setTime(moment(defaultValue).format("h:mm a"));
    } else {
      setDate(undefined);
      setTime(undefined);
    }
    setTimeUI(false);
    setDateUI(false);
  };

  useEffect(() => {
    if (defaultValue) {
      setDate(moment(defaultValue));
      setTime(moment(defaultValue).format("h:mm A"));
    } else {
      setDate(undefined);
      setTime(undefined);
    }
  }, [JSON.stringify(defaultValue)]);
  return (
    <PopupCard cardPadding="0px" width="280px" {...cardProps}>
      <div
        onMouseEnter={(e) => {
          setIsMouseInside(true);
        }}
        onMouseLeave={(e) => {
          setIsMouseInside(false);
        }}
      >
        <CustomDatePicker
          open={dateUI}
          showToday={false}
          onOpenChange={(e) => {
            if (!isMouseInside) {
              setDateUI(false);
            }
          }}
          value={date}
          defaultPickerValue={defaultValue ? moment(defaultValue) : null}
          onChange={onChangeDateHandler}
          disabledDate={function disabledDate(current) {
            return current.valueOf() < Date.now() - 86400000;
          }}
          renderExtraFooter={() => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px 0px",
                gap: "12px",
                justifyContent: "end",
              }}
            >
              <Button
                type="text"
                onClick={() => {
                  setDateUI(false);
                  setTimeUI(true);
                }}
                disabled={date ? false : true}
              >
                + Add Time
              </Button>
              <Button
                type="primary"
                disabled={date ? false : true}
                style={{ borderRadius: "0.6rem" }}
                onClick={() => {
                  onSubmit();
                  setDateUI(false);
                }}
              >
                Done
              </Button>
              {date && (
                <Button
                  style={{ borderRadius: "0.6rem" }}
                  onClick={() => {
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
          )}
        />
        {timeUI && (
          <Card
            className="card"
            style={{ height: "320px" }}
            ref={timeUIRef}
            bottom="-38px"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                gap: "12px",
                justifyContent: "start",
              }}
            >
              <Button
                className="borderless-button"
                onClick={() => {
                  setTimeUI(false);
                  setDateUI(true);
                }}
              >
                <ArrowLeftOutlined />
              </Button>
              <Typography.Text>
                {moment(date).format("Do MMMM YYYY")}
              </Typography.Text>
            </div>
            <Grid>
              {getTimeValues(15).map((value) => {
                let disabled =
                  date.diff(new Date(), "hours") === 0 &&
                  moment(value, "h:mm A")?.diff(new Date(), "minutes") < 0;
                if (disabled) {
                  return null;
                }
                return (
                  <Button
                    disabled={disabled}
                    key={value}
                    style={{
                      marginLeft: "0px",
                      borderRadius: "8px",
                      background: time === value ? "#1890ff" : "#fff",
                      color: disabled
                        ? "rgba(0, 0, 0, 0.25)"
                        : time === value
                        ? "#fff"
                        : "black",
                    }}
                    onClick={() => {
                      // console.log(
                      //   date.diff(new Date(), "hours") === 0,
                      //   moment(value, "h:mm A").diff(new Date(), "minutes") < 0,
                      //   value
                      // );

                      setTime(value);
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
            </Grid>
            <div
              style={{
                padding: "8px 12px",
                lineHeight: "38px",
                textAlign: "right",
                borderTop: "1px solid #eee",
              }}
            >
              <Button
                type="primary"
                style={{ borderRadius: "0.6rem" }}
                onClick={() => {
                  onSubmit();
                  setTimeUI(false);
                }}
              >
                Done
              </Button>
            </div>
          </Card>
        )}
      </div>
      <LabelIcon
        background="none"
        height={isSubtask && "24px"}
        onClick={() => {
          if (!disabled) setDateUI(true);
        }}
        style={{
          borderRadius: isTaskModal ? "30px" : "8px",
          height: "auto",
          padding:
            date && validateInputValue(date)
              ? "6px 12px"
              : isTaskModal
              ? "7px 12px"
              : "2px",
          background:
            date && validateInputValue(date)
              ? "#F1F5F9"
              : isTaskModal
              ? "rgb(244, 242, 242)"
              : "white",
        }}
      >
        <IconWrapper width="18px">
          <CalendarIcon className="text-gray-500 stroke-2" />
        </IconWrapper>
        {date && validateInputValue(date) ? (
          <>
            <span>
              {moment(date).format("DD MMM")}
              {moment(date).format("YYYY") === moment().year().toString()
                ? null
                : moment(date).format(" YYYY")}{" "}
              {time && !time.includes("00:00") && time}
            </span>
            {!isCloseButtonHidden && !disabled ? (
              <Button
                className="borderless-button"
                shape="circle"
                size="small"
                icon={<CloseCircleFilled />}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(undefined, undefined);
                }}
              />
            ) : null}
          </>
        ) : (
          <>{label}</>
        )}
      </LabelIcon>
    </PopupCard>
  );
};

export default DueDate;

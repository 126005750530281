import { Button, Col, Row } from "antd";
import React from "react";
import { Modal } from "../../../styles/ModalStyles";
import bountyWelcome from "./../../../assets/images/onboarding/bountyFeed/bountyWelcome.png";
import boutnyEndGif from "./../../../assets/gifs/onboarding/office-michael.gif";
import useWindowSize from "../../../hooks/useWindowSize";
function OnboardingModal({ onClick, step, modalProps, showModal, onClose }) {
  const { isMobile } = useWindowSize();
  const getActiveModalDetails = (step) => {
    switch (step) {
      case "proposal-start":
        return {
          title: "Get Started with Proposals",
          description:
            "Register your community decisions on chain for a decentralised governance. A proposal management built on top of Snapshot allows your community to take your discussion on chain and get community consensus.",
          media: (
            <img
              alt="bountyWelcome"
              src={bountyWelcome}
              style={{ width: "64px", objectFit: "cover", height: "64px" }}
            ></img>
          ),
          buttonLabel: "Proceed",
        };
      case "course-start":
        return {
          title: "Get Started with Courses",
          description:
            "Courses help you create a knowledge hub for your community to learn and get validated for their completion.",
          media: (
            <img
              alt="bountyWelcome"
              src={bountyWelcome}
              style={{ width: "64px", objectFit: "cover", height: "64px" }}
            ></img>
          ),
          buttonLabel: "Proceed",
        };
      case "bounty-start":
        return {
          title: "Get Started with Bounties",
          description:
            "Coordinate with folks within or outside the community by discovering, participating or managing your community bounties. Experience a powerful bounty board closely linked with discussions to understand bounty context in all depths.",
          media: (
            <img
              alt="bountyWelcome"
              src={bountyWelcome}
              style={{ width: "64px", objectFit: "cover", height: "64px" }}
            ></img>
          ),
          buttonLabel: "Proceed",
        };
      case "post-start":
        return {
          title: "Get Started with Discussion Posts",
          description:
            "Share and collaborate on your ideas with the community. With the discussion forum which matches your community spirit, express your ideas, get community polls and translate ideas to proposals in a click.",
          media: (
            <img
              alt="bountyWelcome"
              src={bountyWelcome}
              style={{ width: "64px", objectFit: "cover", height: "64px" }}
            ></img>
          ),
          buttonLabel: "Proceed",
        };
      case "project-start":
        return {
          title: "Get Started with Projects",
          description:
            "Organise your community's efforts by tracking different ongoing projects. Create and assign tasks in those projects and measure the performance of team.",
          media: (
            <img
              alt="bountyWelcome"
              src={bountyWelcome}
              style={{ width: "64px", objectFit: "cover", height: "64px" }}
            ></img>
          ),
          buttonLabel: "Proceed",
        };
      case "course-end":
        return {
          title: "Yipee! Your course has been created!",
          description:
            "You can create course for your community to empower members to learn and validate their growth in the platform.",
          media: (
            <img
              alt="bountyWelcome"
              src={boutnyEndGif}
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            ></img>
          ),
          buttonLabel: "Proceed ➝",
        };
      case "proposal-end":
        return {
          title: "Congratulations!",
          description:
            "You can create proposal for your community and bring community to participate in a decentralised ecosystem.",
          media: (
            <img
              alt="bountyWelcome"
              src={boutnyEndGif}
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            ></img>
          ),
          buttonLabel: "Proceed ➝",
        };
      case "bounty-end":
        return {
          title: " Congratulations!",
          description:
            " You can create bounties for your community and bring contributors to participate with them.",
          media: (
            <img
              alt="bountyWelcome"
              src={boutnyEndGif}
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            ></img>
          ),
          buttonLabel: "Yayiee! Done",
        };
      case "post-end":
        return {
          title: "Yayyie! Post has been Created",
          description:
            " You can create different posts for your community and have actionable discussions within your community.",
          media: (
            <img
              alt="bountyWelcome"
              src={boutnyEndGif}
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            ></img>
          ),
          buttonLabel: "Yayiee! Done",
        };
      case "project-end":
        return {
          title: "Congratulations!",
          description:
            "Track the progress of your project by updating Task status and assigning relevant members to new tasks.",
          media: (
            <img
              alt="bountyWelcome"
              src={boutnyEndGif}
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            ></img>
          ),
          buttonLabel: "Proceed",
        };
      default:
        return null;
    }
  };
  const getModalBody = (step) => {
    const { title, media, description, buttonLabel } =
      getActiveModalDetails(step);
    return (
      <Col
        xs={24}
        style={{
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: "12px",
        }}
      >
        {media}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0 2rem",
            rowGap: "8px",
          }}
        >
          <h2
            style={{
              color: "white",
              marginBottom: "0px",
              textAlign: isMobile ? "center" : "initial",
            }}
          >
            {title}
          </h2>
          <h3
            style={{
              color: "rgba(151, 166, 186, 1)",
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            {description}
          </h3>
        </div>
        <Button
          type="primary"
          style={{ borderRadius: "12px" }}
          onClick={onClick}
        >
          {buttonLabel}
        </Button>
      </Col>
    );
  };

  return (
    <Modal
      footer={null}
      visible={showModal}
      isMobile={isMobile}
      onCancel={onClose}
      centered={true}
      title=""
      closable={false}
      bodyStyle={{
        backgroundColor: "rgba(26, 32, 46, 1)",
        borderRadius: "1rem",
        minHeight: "auto",
      }}
    >
      <Row style={{ with: "100%", padding: "20px" }}>{getModalBody(step)}</Row>
    </Modal>
  );
}

export default OnboardingModal;

import { Input, MenuProps } from "antd";
import IconWrapper from "../IconWrapper";
import {
  Bars3BottomLeftIcon,
  ChatBubbleLeftEllipsisIcon,
  ClipboardDocumentCheckIcon,
  GlobeAltIcon,
  MinusIcon,
  PencilSquareIcon,
  PuzzlePieceIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { AppstoreOutlined, GlobalOutlined } from "@ant-design/icons";
import { languages } from "./language";
type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    style: {
      margin: "0px",
    },
  } as MenuItem;
}
let BRAINSTROM = getItem(
  "Brainstorm Ideas",
  "BRAINSTROM",
  <IconWrapper width={16} style={{}}>
    <SparklesIcon />
  </IconWrapper>
);
let HELP = getItem(
  "Ask Robin to write...",
  "HELP",
  <IconWrapper width={16} style={{}}>
    <PencilSquareIcon />
  </IconWrapper>
);
export let DIVIDER = {
  type: "divider",
  style: {
    margin: 0,
  },
};
let SUMMARISE_CHANNEL = getItem(
  "Summarise",
  "SUMMARISE_CHANNEL",
  <AppstoreOutlined />,
  [
    getItem("Last 3 discussions", 3),
    getItem("Last 5 discussions", 5),
    getItem("Last 10 discussions", 10),
  ]
);

let CHANGE_TONE = getItem(
  "Change Tone",
  "CHANGE_TONE",
  <IconWrapper width={16} style={{}}>
    <SparklesIcon />
  </IconWrapper>,
  [
    getItem("Friendly", "FRIENDLY_TONE"),
    getItem("Casual", "CASUAL_TONE"),
    getItem("Formal", "FORMAL_TONE"),
  ]
);

let MAKE_LONGER = getItem(
  "Make longer",
  "MAKE_LONGER",
  <IconWrapper width={16} style={{}}>
    <Bars3BottomLeftIcon />
  </IconWrapper>
);

let MAKE_SHORTER = getItem(
  "Make Shorter",
  "MAKE_SHORTER",
  <IconWrapper width={16} style={{}}>
    <MinusIcon />
  </IconWrapper>
);
let GRAMMER_CHECK = getItem(
  "Check Grammar",
  "GRAMMER_CHECK",
  <IconWrapper width={16} style={{}}>
    <ClipboardDocumentCheckIcon />
  </IconWrapper>
);

let POLL = getItem(
  "Create Poll",
  "POLL",
  <IconWrapper width={16} style={{}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
      />
    </svg>
  </IconWrapper>
);
let CHECKLIST = getItem(
  "Create Checklist",
  "CHECKLIST",
  <IconWrapper width={16} style={{}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
      />
    </svg>
  </IconWrapper>
);
let MAKE_COMMENT = getItem(
  "Make Comment",
  "MAKE_COMMENT",
  <IconWrapper width={16} style={{}}>
    <ChatBubbleLeftEllipsisIcon />
  </IconWrapper>
);
export const rephrase = [
  { label: "Proposal", value: "REPHRASE_AS_PROPOSAL" },
  { label: "Project Brief", value: "REPHRASE_AS_PROJECT" },
  { label: "General Discussion", value: "REPHRASE_AS_DISCUSSION" },
];
export let TRANSLATE = getItem(
  "Translate",
  "TRANSLATE",
  <IconWrapper width={16} style={{}}>
    <GlobeAltIcon />
  </IconWrapper>,
  languages.map((language) => getItem(language.language, language.code))
);

let SUMMARISE = getItem("Summarise", "SUMMARIZE", <AppstoreOutlined />);

let REPHRASE = getItem(
  "Rephrase as",
  "REPHRASE_CONTENT",
  <IconWrapper width={16} style={{}}>
    <SparklesIcon />
  </IconWrapper>,
  rephrase.map(({ label, value }) => getItem(label, value))
);

export const InputSlashMenu = [BRAINSTROM, DIVIDER, HELP];

export const CommonMenu = [DIVIDER, CHECKLIST, DIVIDER, POLL];
export const InputSelectionMenu = [
  SUMMARISE,
  DIVIDER,
  TRANSLATE,
  DIVIDER,
  GRAMMER_CHECK,
  DIVIDER,
  REPHRASE,
  DIVIDER,
  CHANGE_TONE,
  DIVIDER,
  MAKE_SHORTER,
  DIVIDER,
  MAKE_LONGER,
];

import { Button, Typography, Collapse, Divider } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Footer, Modal } from "../styles/ModalStyles";
import {
  ChatBubbleBottomCenterTextIcon,
  ShareIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";
import IconWrapper from "./IconWrapper";
import CommentSection from "../components/CommentSection";
import customAxios from "../api";
import { toast } from "react-toastify";
const { Title, Text } = Typography;
const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const StyledLabel = styled.div`
  padding: 4px 10px;
  border-radius: 10px;
  border: 1px solid lightgrey;
  background: white;
`;

const parser = new DOMParser();

const ViewBountyModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  bountyId,
}) => {
  const [bountyDetails, setBountyDetails] = useState(null);
  useEffect(() => {
    customAxios
      .get(`/bounty/fetch/details?bountyId=${bountyId}`)
      .then((res) => {
        setBountyDetails(res.data.bountyDetail[0]);
      })
      .catch((Err) => toast.error("Some error occured"));
  }, [bountyId]);
  return (
    <Modal
      destroyOnClose
      // title="#Governance"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Footer>
          <div>
            <Button
              className="circle-border-button"
              icon={
                <IconWrapper width="16px">
                  <ChatBubbleBottomCenterTextIcon />
                </IconWrapper>
              }
            >
              + Add Comment
            </Button>
            <Button className="circle-border-button">
              <IconWrapper width="16px">
                <ShareIcon />
              </IconWrapper>
            </Button>
          </div>
          <div>
            <Button
              className="rounded-corner"
              type="primary"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </Footer>,
      ]}
    >
      <>
        <Title level={5} style={{ padding: "20px 0px 0px 15px" }}>
          {bountyDetails?.bountyTitle}
        </Title>
        {bountyDetails?.bountyType && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "4px 10px",
            }}
          >
            <StyledLabel style={{ marginRight: "6px", background: "#F4F2F2" }}>
              <Text style={{ color: "black" }}>
                {bountyDetails?.bountyType}
              </Text>
            </StyledLabel>
            <Text style={{ color: "#2A3142", marginRight: "6px" }}>for</Text>
            <StyledLabel style={{ background: "#F4F2F2", marginRight: "6px" }}>
              <Text style={{ color: "black" }}>
                {bountyDetails?.bountyAssignmentType}
              </Text>
            </StyledLabel>
            {/* <Text style={{ color: "#2A3142", marginRight: "6px" }}>
                with reward of
              </Text>
              <StyledLabel
                style={{ background: "#E9F9EC", marginRight: "6px", border: "none" }}
              >
                <Text style={{ color: "green" }}>0.06</Text>
              </StyledLabel> */}
          </div>
        )}
        {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
              flexDirection: "column",
            }}
          >
            <Title level={5}>This task is reserved for Tier 1 NFT holders.</Title>
            <Text style={{ color: "black" }}>
              You can buy the NFT in order to access to the task.
            </Text>
            <Button
              className="rounded-corner"
              type="primary"
              style={{
                borderRadius: "10px",
                marginTop: "8px",
                fontWeight: "bold",
                height: "38px",
                fontSize: "16px",
              }}
            >
              Buy Tier 1 NFT
              <IconWrapper width="16px">
                <ArrowRightIcon />
              </IconWrapper>
            </Button>
          </div> */}
        <div>
          <Collapse defaultActiveKey={["1"]} ghost expandIconPosition="end">
            <Panel header="Task Details" key="1" style={{ fontWeight: "bold" }}>
              <p style={{ fontWeight: "normal" }}>
                {bountyDetails?.bountyDescription
                  ? bountyDetails?.bountyDescription
                  : "No description found"}
              </p>
            </Panel>
            <Divider />
            <Panel
              header={`Task Submissions (${bountyDetails?.noOfSubmissions})`}
              key="2"
              style={{ fontWeight: "bold", paddingLeft: "4px" }}
              showArrow={false}
            >
              {/* <p style={{ fontWeight: 'normal' }}>{text}</p> */}
            </Panel>
            <Divider />
            <Panel
              header={`Comments (${bountyDetails?.noOfComments})`}
              key="3"
              style={{ fontWeight: "bold", paddingLeft: "4px" }}
              showArrow={bountyDetails?.noOfComments > 0}
            >
              {bountyDetails?.noOfComments > 0 && (
                <div style={{ fontWeight: "normal" }}>
                  <CommentSection />
                </div>
              )}
            </Panel>
          </Collapse>
        </div>
      </>
    </Modal>
  );
};

ViewBountyModal.propTypes = {
  bountyId: PropTypes.string.isRequired,
};

export default ViewBountyModal;

// bountyDetails details response data

// bountyAssignmentId: null
// bountyAssignmentType: "Any Member"
// bountyAttachments: null
// bountyCreatedAt: "2022-10-05T09:58:38.425Z"
// bountyDescription: "<p>How description would look like</p>"
// bountyGatingCondition: null
// bountyId: "27"
// bountyLastSubmissionDate: "2022-11-08T00:00:00.000Z"
// bountyPriority: "lowPriority"
// bountyReviewers: null
// bountyStatus: "todo"
// bountySubmissionNote: "Sample note is here"
// bountyTitle: "Smaple task"
// bountyType: "Bounty Submission"
// canSubmit: false
// noOfComments: 0
// noOfSubTask: 13
// noOfSubmissions: 7
// profilePicture: null
// userId: "2"
// userName: "aman"

import { Input, Select, Col, Row, Skeleton } from "antd";
import { tokenOptions } from "../../constants";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/common/chevrondown.svg";
import { useEffect, useState } from "react";
import { PuzzlePieceIcon } from "@heroicons/react/24/solid";
import { ethers } from "ethers";
import useWindowSize from "../../hooks/useWindowSize";
import customAxios from "../../api";

const NFT_REQUIREMENT_OPTIONS = [
  { value: "AMOUNT", label: "Amount" },
  { value: "ATTRIBUTE", label: "Attribute" },
];

const PARENT_GATING_KEYS = {
  nftGatingType: "nftGatingType",
  nftAttribute: "nftAttribute",
  amount: "amount",
};

const { Option } = Select;
const NFTComponent = ({
  ele,
  idx,
  handleConditionDetailChange,
  isNoBackground = false,
}) => {
  const { isMobile } = useWindowSize();
  const [attributes, setAttributes] = useState(null);
  const [isNftDetailsFetched, setIsNftDetailsFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  console.log(ele);
  useEffect(() => {
    const fetchNFTDetails = async (address) => {
      setIsLoading(true);
      try {
        const response = await customAxios.get(
          `/bounty/gating/nft/attribute?chain=${ele.contractType}&contractAddress=${ele.contractAddress}`
        );

        const fetchedAttributes = response?.data?.traits;

        if (fetchedAttributes) {
          setAttributes(fetchedAttributes);
          const attributeKeys = Object.keys(fetchedAttributes);

          // Handling the case where user enters a new address
          if (
            ele[PARENT_GATING_KEYS.nftGatingType] === "Attribute" &&
            !attributeKeys.includes(
              ele[PARENT_GATING_KEYS.nftAttribute]?.[0]?.key
            )
          ) {
            handleConditionDetailChange(
              PARENT_GATING_KEYS.nftGatingType,
              "AMOUNT",
              idx
            );
            handleConditionDetailChange(
              PARENT_GATING_KEYS.nftAttribute,
              [],
              idx
            );
          }
        }

        setIsNftDetailsFetched(true);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    const address = ele.contractAddress;
    console.log(ethers.utils.isAddress(address));
    if (
      !ethers.utils.isAddress(address) &&
      ele.contractType !== "SOLANA" &&
      ele.contractType !== "SOLANA_DEVNET"
    )
      return;
    fetchNFTDetails(address);
  }, [ele.contractAddress, ele.contractType]);

  return (
    <>
      <Row
        gutter={["8", "8"]}
        style={{
          padding: !isNoBackground && "2.4rem 12px 0px 12px",
          borderTopLeftRadius: !isNoBackground && "12px",
          borderTopRightRadius: !isNoBackground && "12px",
          background: !isNoBackground && "#eeeeee",
          rowGap: "8px",
          marginBottom: isNoBackground && "8px",
        }}
      >
        <Col xs={24} sm={8}>
          <Select
            suffixIcon={<ChevronDownIcon />}
            style={{ width: "100%" }}
            value={ele.contractType}
            defaultValue="POLYGON"
            onChange={(e) => {
              handleConditionDetailChange("contractType", e, idx);
            }}
          >
            {tokenOptions.map((ele) => (
              <Option value={ele.value}>{ele.label}</Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} sm={16}>
          <Input
            value={ele.contractAddress}
            placeholder={
              ele.contractType === "SOLANA"
                ? "Enter mint address"
                : "Enter nft address"
            }
            style={{ width: "100%", borderRadius: "8px" }}
            onChange={(e) =>
              handleConditionDetailChange(
                "contractAddress",
                e.currentTarget.value,
                idx
              )
            }
          ></Input>
        </Col>
      </Row>
      <Row
        gutter={["8"]}
        style={{
          padding: !isNoBackground && "12px 12px 12px 12px",
          borderBottomLeftRadius: !isNoBackground && "12px",
          borderBottomRightRadius: !isNoBackground && "12px",
          background: !isNoBackground && "#eeeeee",
          rowGap: "8px",
        }}
      >
        {isLoading && (
          <Skeleton
            active
            round
            paragraph={{ rows: isMobile ? 2 : 1, width: "100%" }}
            style={{ padding: "0 4px" }}
          />
        )}
        {!isLoading && (
          <>
            <Col xs={24} sm={8}>
              <span
                style={{
                  background: "#FFFFFF",
                  display: "flex",
                  columnGap: "4px",
                  color: "#0F172A",
                  borderRadius: "8px",
                  alignItems: "center",
                  padding: "5px 12px",
                  border: "1px solid #E2E8F0",
                  fontSize: "14px",
                }}
              >
                <PuzzlePieceIcon width={16} height={16} color={"#4B5563"} />
                Requirement
              </span>
            </Col>
            <Col xs={24} sm={16}>
              <Select
                suffixIcon={<ChevronDownIcon />}
                style={{ width: "100%" }}
                value={ele[PARENT_GATING_KEYS.nftGatingType]}
                defaultValue={NFT_REQUIREMENT_OPTIONS[0].value}
                onChange={(e) => {
                  handleConditionDetailChange(
                    PARENT_GATING_KEYS.nftGatingType,
                    e,
                    idx
                  );
                }}
                disabled={!isNftDetailsFetched}
              >
                {NFT_REQUIREMENT_OPTIONS.filter(
                  // Hide attribute option if not attributes present
                  (opt) => {
                    if (opt.value === "ATTRIBUTE" && !attributes) return false;
                    if (
                      opt.value === "ATTRIBUTE" &&
                      ele.contractType === "GNOSIS_CHAIN"
                    )
                      return false;
                    else return true;
                  }
                ).map((ele) => (
                  <Option value={ele.value}>{ele.label}</Option>
                ))}
              </Select>
            </Col>
            <Col style={{ width: "100%" }}>
              {ele[PARENT_GATING_KEYS.nftGatingType] === "ATTRIBUTE" ? (
                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <Select
                      suffixIcon={<ChevronDownIcon />}
                      style={{ width: "100%" }}
                      value={ele[PARENT_GATING_KEYS.nftAttribute]?.[0]?.key}
                      onChange={(e) => {
                        handleConditionDetailChange(
                          PARENT_GATING_KEYS.nftAttribute,
                          [{ key: e }],
                          idx
                        );
                      }}
                      placeholder="Attribute"
                      disabled={!isNftDetailsFetched}
                    >
                      {attributes &&
                        Object.keys(attributes).map((ele) => (
                          <Option value={ele}>{ele}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col xs={24}>
                    <Select
                      suffixIcon={<ChevronDownIcon />}
                      style={{ width: "100%" }}
                      value={ele[PARENT_GATING_KEYS.nftAttribute]?.[0]?.value}
                      onChange={(e) => {
                        handleConditionDetailChange(
                          PARENT_GATING_KEYS.nftAttribute,
                          [
                            {
                              key: ele[PARENT_GATING_KEYS.nftAttribute]?.[0]
                                ?.key,
                              value: e,
                            },
                          ],
                          idx
                        );
                      }}
                      placeholder="Attribute Value"
                      disabled={!ele[PARENT_GATING_KEYS.nftAttribute]?.[0]?.key}
                    >
                      {attributes?.[
                        ele[PARENT_GATING_KEYS.nftAttribute]?.[0]?.key
                      ]?.map((ele) => (
                        <Option value={ele}>{ele}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              ) : (
                <Input
                  type="number"
                  value={ele?.[PARENT_GATING_KEYS.amount]}
                  onChange={(e) => {
                    if (e.target.value >= 0) {
                      handleConditionDetailChange(
                        PARENT_GATING_KEYS.amount,
                        e.target.value,
                        idx
                      );
                      handleConditionDetailChange(
                        PARENT_GATING_KEYS.nftGatingType,
                        "AMOUNT",
                        idx
                      );
                    }
                  }}
                  disabled={!isNftDetailsFetched}
                  style={{
                    border: "1px solid #E2E8F0",
                    background: "#FFFFFF",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                  placeholder="Amount of NFTs owned"
                />
              )}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default NFTComponent;

import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Space, Tag, Row, Card, Tooltip, Select } from "antd";
import QuillEditor from "../QuillEditor";
import { Modal, Footer } from "../../styles/ModalStyles";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../api";

import Upload from "../Common/form/Upload";
import { CloseCircleFilled } from "@ant-design/icons";
import styles from "./course.module.css";
import GoBackButton from "../InternalComponents/GoBackButton";
import { cancelText, confirmText } from "../CreateBountyModal";
import { checkFileSize, checkImageType, postMixpanel } from "../../utils";
import LinkEntities from "../Common/LinkEntities";
import IconWrapper from "../IconWrapper";
import { ReactComponent as StackIcon } from "./../../images/rectangle-stack-icon.svg";
import XPField from "../Common/form/XPField";
import Gating from "../Gating/Gating";
import { imageUrl } from "./CourseCard";
import useWindowSize from "../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import { useDaoContext } from "../../layouts/app/DaoContext";
import useGating from "../../hooks/useGating";
import GatingModal from "../Gating/GatingModal";
import { confirmOperation } from "../../utils/common";

export const isValidHttpUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};
export const parseQuizDetails = (quiz) => {
  let parsedQuiz = quiz.map((singleQuiz) => {
    let options = singleQuiz.options.map((singleOption) => singleOption.text);
    let correctAns = singleQuiz.options.filter(
      (singleOption) => singleOption.isCorrect
    );
    correctAns = correctAns.map((answers) => answers.text);
    return {
      type: singleQuiz.type,
      questionName: singleQuiz.question,
      options,
      correctAns,
    };
  });
  return parsedQuiz;
};
export const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
  }
`;
const StyledButton = styled(Button)`
  .borderless-button {
    display: none;
  }
  &:hover {
    .borderless-button {
      display: block;
    }
  }
`;

const CreateCourseModal = ({
  isModalVisible,
  handleCancel,
  showCourseVerificationAdminModal,
  setShowCourseVerificationAdminModal,
  onSubmit,
  course,
  mode,
}) => {
  const [skillsList, setSkillsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { daoDetails, daoId } = useDaoContext();

  const [isUpdated, setIsUpdated] = useState(false);
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;

  const defaultState = { taskPoint: 100 };
  const [linkedItems, setLinkedItems] = useState([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [state, setState] = useState(() => defaultState);
  const [thumbnail, setThumbnail] = useState(undefined);
  const getSkillList = () => {
    customAxios
      .get("bounty/skills")
      .then((res) => {
        setSkillsList(res.data.skills);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };
  useEffect(() => {
    getSkillList();
    if (course) {
      setState({
        name: course?.name,
        description: course?.description,
        taskPoint: parseInt(course?.taskPoint),
        skills: course?.skills ? course.skills : [],
      });
      gatingState.setGatingState(
        course?.gatingConditionId,
        course.gatingCondition
      );
    }
    setThumbnail(imageUrl(course?.thumbnailAttachment));
  }, [isModalVisible]);
  const verifyFields = () => {
    if (!state.name) {
      toast.error("Please enter the title of your course");
      return false;
    }
    if (!state.description) {
      toast.error("Please enter the description of your course");
      return false;
    }
    if (!thumbnail) {
      toast.error("Please provide course cover");
      return false;
    }
    if (!state.skills || state.skills.length == 0) {
      toast.error("Please provide atleast one skill");
      return false;
    }
    return true;
  };
  const createCourse = () => {
    let isVerified = verifyFields();
    if (!isVerified) {
      return;
    }
    const formData = new FormData();
    formData.append("name", state?.name);

    formData.append("link", "dscdsc");
    formData.append("provider", "dscdsc");

    formData.append("description", state?.description);
    if (thumbnail && isUpdated) {
      let thumbnailFile = new File(
        [thumbnail.slice(0, thumbnail.size, thumbnail.type)],
        thumbnail.name.split(".").join(`_thumbnail.`),
        { type: thumbnail.type }
      );
      formData.append("files", thumbnailFile);
    }
    if (state?.skills?.length > 0) {
      formData.append("skills", JSON.stringify(state.skills));
    }
    if (state.taskPoint) formData.append("taskPoint", state.taskPoint);

    formData.append("daoId", daoId);
    if (mode === "edit") {
      formData.append("coursesId", course.id);
    }
    gatingState.createFormData(formData);

    if (linkedItems.length) {
      formData.append(
        "linkedEntities",
        JSON.stringify(
          linkedItems.map((ele) => ({
            linkedType: ele.type,
            linkedId: ele.id,
          }))
        )
      );
    }
    setLoading(true);
    customAxios
      .post(`/courses/${mode === "edit" ? "edit" : "create"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          if (mode === "edit") {
            postMixpanel("track", "edit_course", {
              dao_id: daoId,
              course_title: state.name,
            });
          } else {
            postMixpanel("track", "create_course", {
              dao_id: daoId,
              course_title: state.name,
            });
          }
          toast.success(
            mode === "edit"
              ? "Course updated successfully"
              : "Course created successfully"
          );
          handleCancel();
          onSubmit();
          setState(defaultState);
          // dispatch(fetchBounty({ daoId: daoId }));
        } else console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const gatingState = useGating();

  return (
    <>
      <StyledModalWrapper>
        <Modal
          centered
          isMobile={isMobile}
          width="700px"
          height="auto"
          destroyOnClose
          className="create-quiz"
          title={
            showCourseVerificationAdminModal ? (
              <GoBackButton
                buttonText={"Back"}
                showIcon
                clickHandler={() => {
                  setShowCourseVerificationAdminModal(false);
                }}
              />
            ) : (
              <></>
            )
          }
          visible={isModalVisible}
          onCancel={(e) => {
            e.stopPropagation();
            if (
              (state.name && state.name !== "") ||
              (state.description && state.description !== "") ||
              (state.skills && state.skills.length > 0)
            ) {
              confirmOperation({
                cancelText: "Discard Changes",
                confirmText: "Continue Editing",
                message:
                  " You will lose the progress if you close this pop-up?",
                title: "Confirm",
                onCancel: () => handleCancel(),
              });

              return;
            }
            handleCancel();
          }}
          footer={[
            <Footer
              key="modal-footer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  marginLeft: "auto",
                  marginRight: "8px",
                }}
              >
                {mode !== "edit" && (
                  <Tooltip title="Link the discussions, bounties or any entity that led to this course.">
                    <Button
                      className="borderless-button"
                      shape="circle"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        setLinkModalVisible(true);
                      }}
                    >
                      <IconWrapper>
                        <StackIcon />{" "}
                      </IconWrapper>
                      <span style={{ color: "#64748B", fontSize: "12px" }}>
                        {linkedItems.length ? `(${linkedItems.length})` : null}
                      </span>
                    </Button>
                  </Tooltip>
                )}

                <LinkEntities
                  isModalVisible={linkModalVisible}
                  handleClose={() => {
                    setLinkModalVisible(false);
                  }}
                  linkedItems={linkedItems}
                  setLinkedItems={setLinkedItems}
                ></LinkEntities>
                <GatingModal finalGatingState={gatingState} />

                <XPField
                  value={state.taskPoint}
                  onChange={(e) => {
                    setState((prev) => ({ ...prev, taskPoint: e }));
                  }}
                />
              </div>

              <Button
                style={{ cursor: loading ? "wait" : "default" }}
                disabled={loading}
                className="rounded-corner"
                type="primary"
                onClick={() => {
                  createCourse();
                }}
              >
                {mode === "edit" ? "Update" : "Create"}
              </Button>
            </Footer>,
          ]}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Input
              style={{
                fontSize: "21px",
                fontWeight: "600",
                margin: "0 0 0px 8px",
              }}
              placeholder="Add the title of your course"
              size="large"
              className="ip-address"
              value={state?.name}
              autoFocus={true}
              onChange={(e) => {
                setState((prev) => ({ ...prev, name: e.target.value }));
              }}
              bordered={false}
            />
            <div
              style={{
                padding: "20px",
                color: " #64748B",
                display: "flex",
                gap: "20px",
                flexDirection: isMobile ? "column-reverse" : "row",
              }}
            >
              <Upload
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
                data={thumbnail}
                onChange={(newFile) => {
                  checkImageType(newFile.type);
                  checkFileSize(newFile.size);
                  setThumbnail(newFile);
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  width: "202px",
                  font: "normal 600 12px/16px Inter",
                  width: "100%",
                }}
              >
                Description
                <Input.TextArea
                  value={state.description}
                  onChange={(e) => {
                    setState((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }));
                  }}
                  placeholder="Add a description to know learners know more about your course"
                  style={{
                    borderRadius: "16px",
                    height: "100%",
                    resize: "none",
                    padding: "12px 16px",
                  }}
                />
                Skill tags (upto 2)
                <StyledSelect
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                    borderRadius: "12px",
                    font: "normal 500 14px/20px Inter",
                  }}
                  value={state.skills}
                  placeholder="Skills"
                  onChange={(e) => {
                    if (e.length > 2) return;
                    setState((prev) => ({ ...prev, skills: e }));
                  }}
                >
                  {skillsList.map((ele) => (
                    <Select.Option key={ele.name} value={ele.name}>
                      {ele.name}
                    </Select.Option>
                  ))}
                </StyledSelect>
              </div>
            </div>
          </div>
        </Modal>
      </StyledModalWrapper>
    </>
  );
};

export default CreateCourseModal;

import React, { useContext, useEffect } from "react";
import "../ComponentStyles/ViewBountySubmissionModal.scss";
import { Button, Modal, Typography } from "antd";
import GoBackButton from "../InternalComponents/GoBackButton";
import SmallUserDetails from "../InternalComponents/SmallUserDetails";
import SecondaryButtonOutlined from "../InternalComponents/SecondaryButtonOutlined";
import PrimaryButtonFilled from "../InternalComponents/PrimaryButtonFilled";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { useState } from "react";
import pdf from "../../assets/samplepdf.pdf";
import { toast } from "react-toastify";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import pdfIcon from "../../assets/iconSvg/pdf.svg";
import downloadIcon from "../../assets/iconSvg/downloadIcon.svg";
import PaymentModal from "../BountyComponents/PaymentModal";
import PaymentStatus from "./PaymentStatus";
import customAxios from "../../api";
import moment from "moment";
import { ApproveCheck, RejectCross, CommentIcon } from "../Icones";

import DaoJoined from "../Profile/DaoJoined";
import { DaoContext } from "../../layouts/app/DaoContext";
import { UserContext } from "../../layouts/app/UserContext";
import {
  ArrowTopRightOnSquareIcon,
  ArrowUturnDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  BOUNTY_SUBMISSION_STATUS_COLORS,
  DEFAULT_SORTING_TYPE_LIST,
} from "../../constants";
import { postMixpanel } from "../../utils";
import CommentSection from "../CommentsComponents/CommentSection";
import ProfileCardPopup from "../Common/ProfileCard";
import { StyledProfileDetails } from "../BountyFeed";
import { useNavigate } from "react-router";
const { Link } = Typography;
export const ActionButtons = ({
  type,
  buttonText,
  clickHandler,
  style,
  url,
}) => {
  let commonStyles = {
    borderRadius: "12px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "7px 17px",
    fontSize: "14px",
  };
  switch (type) {
    case "primary":
      return null;
    case "link":
      return (
        <div
          style={{
            border: "1px solid #D1D5DB",
            ...commonStyles,
            ...style,
          }}
          onClick={clickHandler}
        >
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            style={{ color: "#6B7280" }}
          >
            {buttonText}
          </a>
        </div>
      );
    case "undo":
      return (
        <div
          style={{
            border: "1px solid #D1D5DB",
            ...commonStyles,
            ...style,
          }}
          onClick={clickHandler}
        >
          <ArrowUturnDownIcon
            width={16}
            strokeWidth={2}
            style={{ transform: "rotateZ(90deg)" }}
            color="#6B7280"
          />
          <span
            style={{
              marginLeft: "10px",
              display: "inline-block",
              color: "#6B7280",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {buttonText}
          </span>
        </div>
      );
    case "edit":
      return (
        <div
          style={{
            border: "1px solid #D1D5DB",
            ...commonStyles,
            ...style,
          }}
          onClick={clickHandler}
        >
          <PencilIcon width={16} color="#6B7280" />
          <span
            style={{
              marginLeft: "10px",
              display: "inline-block",
              color: "#6B7280",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {buttonText}
          </span>
        </div>
      );
    case "delete":
      return (
        <div
          style={{
            border: "1px solid #D1D5DB",
            ...commonStyles,
            ...style,
          }}
          onClick={clickHandler}
        >
          <TrashIcon width={16} color="#6B7280" />
          <span
            style={{
              marginLeft: "10px",
              display: "inline-block",
              color: "#6B7280",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {buttonText}
          </span>
        </div>
      );
    case "secondary":
      return (
        <div
          style={{
            border: "1px solid #D1D5DB",
            ...commonStyles,
            ...style,
          }}
          onClick={clickHandler}
        >
          <span style={{ color: "#6B7280" }}>{buttonText}</span>
        </div>
      );
    case "success":
      return (
        <div
          style={{
            backgroundColor: "#ECFDF5",
            ...commonStyles,
            ...style,
          }}
          onClick={clickHandler}
        >
          <ApproveCheck />{" "}
          <span style={{ marginLeft: "10px", display: "inline-block" }}>
            {buttonText}
          </span>
        </div>
      );
    case "failure":
      return (
        <div
          style={{
            backgroundColor: "#FFF1F2",
            ...commonStyles,
            ...style,
          }}
          onClick={clickHandler}
        >
          <RejectCross />{" "}
          <span style={{ marginLeft: "10px", display: "inline-block" }}>
            {buttonText}
          </span>
        </div>
      );

    default:
      break;
  }
};

function ViewBountySubmissionModal({
  closeModalHandler,
  isModalOpen,
  bountyDetails,
  bountyAmount,
  bountyId,
  fetchSubmissions,
  symbol,
  network,
  isCompleted,
  isPaid,
  userSubmission,
  contractAddress,
  decimals,
  chainId,
  reviewers,
  daoId,
  setSubmitMountyModalVisible,
  setBountySubmissionDataPrefill,
}) {
  const [showAttachmentLink, setShowAttachmentLink] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("Latest");
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [changesRequested, setChangesRequested] = useState(false);
  const [refreshedTransactions, setRefreshedTransactions] = useState(false);
  const navigate = useNavigate();
  const { isAdmin } = useContext(DaoContext);
  const { userDetails } = useContext(UserContext);
  useEffect(() => {
    fetchComments();
  }, []);
  useEffect(() => {
    console.log("submissions", bountyDetails);
    if (
      bountyDetails?.transactionStatus?.length > 0 &&
      !refreshedTransactions
    ) {
      bountyDetails?.transactionStatus.forEach((submission) => {
        return customAxios
          .get(
            `bountypayments/refresh/?safeTxHash=${
              submission.safeTxHash || ""
            }&chainId=${submission.chainId || ""}`
          )
          .then((res) => console.log("refresh res", res))
          .catch((err) => console.log("err", err));
      });
      setRefreshedTransactions(true);
      fetchSubmissions();
    }
  }, [bountyDetails, refreshedTransactions]);
  const handleApproveBounty = (submissionId) => {
    setLoading(true);
    customAxios
      .put(`/bounty/approve`, {
        bountySubmissionId: submissionId,
      })
      .then((res) => {
        if (res.data) {
          toast.success("Bounty approved successfully");
          fetchSubmissions();
          if (bountyAmount) {
            setPaymentModal(true);
          } else closeModalHandler();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const updateBountySubmissionStatus = (action) => {
    customAxios
      .patch("/bountysubmission/update", {
        bountySubmissionId: bountyDetails.bountySubmissionId,
        action,
      })
      .then((res) => {
        console.log("res", res);
        fetchSubmissions();
      })
      .catch((err) => console.log("err", err));
  };
  const fetchComments = () => {
    customAxios
      .post("/bounty-submissions-comments/fetch", {
        bountyId: bountyId,
        bountySubmissionId: bountyDetails.bountySubmissionId,
        parentId: "0",
        sortBy: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortBy,
        sortOrder: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortOrder,
      })
      .then((res) => {
        console.log("sub comms", res.data);
        setComments(res.data.bountyComments);
      })
      .catch((err) => console.log(err));
  };
  // const fetchReplies = (callback = null) => {
  //   customAxios
  //     .post("/bounty-submissions-comments/fetch/child", {
  //       postId: commentDetails.postId,
  //       commentId: commentDetails.id,
  //     })
  //     .then((res) => {
  //       // console.log("all replies", res.data);
  //       setAllReplies(res.data.childComments);
  //       // if (callback) callback();
  //     })
  //     .catch((err) => console.log(err));
  // };
  const postComment = async (
    commentText,
    mentions,
    parentId,
    level2ParentId = "0"
  ) => {
    var postBody = {
      daoId: daoId,
      bountyId: bountyId,
      bountySubmissionId: bountyDetails.bountySubmissionId,
      parentId,
      description: commentText,
      level2ParentId,
    };

    if (mentions?.length > 0) postBody.userTagged = mentions;
    await customAxios.post("/bounty-submissions-comments/create", postBody);
    postMixpanel("track", "comment_on_post", {
      bounty_id: bountyId,
      parent_comment_id: parentId,
      dao_id: daoId,
    });
    fetchComments();
  };
  const titleCase = (string) => {
    let s = string?.split(" ");
    s = s?.map((element) => {
      element = element[0].toUpperCase() + element.slice(1, element.length);
      return element;
    });
    return s?.join(" ");
  };

  return (
    <>
      <PaymentModal
        chainId={chainId}
        contractAddress={contractAddress}
        visible={paymentModal}
        setVisibility={setPaymentModal}
        submissionDetails={bountyDetails}
        bountyAmount={bountyAmount}
        bountyId={bountyId}
        fetchSubmissions={fetchSubmissions}
        symbol={symbol}
        backendNetwork={network}
        decimals={decimals}
      />

      <Modal
        open={isModalOpen}
        visible={isModalOpen}
        width="640px"
        centered
        bodyStyle={{ maxHeight: "640px", overflowY: "auto", paddingBottom: 0 }}
        title={
          <div className="feedcard__top--profile-details">
            <ProfileCardPopup userId={bountyDetails.userId}>
              <StyledProfileDetails
                onClick={() => navigate(`/app/profile/${bountyDetails.userId}`)}
              >
                <div className="feedcard__top--profile-details--user-details">
                  <img
                    src={
                      bountyDetails?.bountySubmissionUserProfilePicture ||
                      dummyUser
                    }
                    alt={bountyDetails?.bountySubmissionUserName}
                  />
                  <span className="feedcard__top--profile-details--user-details--name">
                    {bountyDetails?.bountySubmissionUserName}
                  </span>
                </div>
              </StyledProfileDetails>
            </ProfileCardPopup>
            <span className="feedcard__top--profile-details--time">
              {" "}
              &#x2022;
            </span>
            <span className="feedcard__top--profile-details--time">
              {moment(bountyDetails?.bountyCreatedAt).fromNow(false)}{" "}
            </span>
          </div>
        }
        onOk={closeModalHandler}
        onCancel={closeModalHandler}
        footer={[
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {isAdmin || reviewers.find((x) => x == userDetails.id) ? (
                <>
                  <div>
                    {comments?.length > 0 && !showComments && (
                      <ActionButtons
                        type="secondary"
                        buttonText={
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <CommentIcon /> {comments?.length}
                          </span>
                        }
                        clickHandler={() => setShowComments(!showComments)}
                      />
                    )}
                  </div>
                  {bountyDetails?.transactionStatus?.length == 0 ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {(bountyDetails?.status == "PAYMENT_PENDING" || //! approved - payment is pending
                        bountyDetails?.status == "REJECTED" || //! Rejected or misclick
                        bountyDetails?.status == "REQUESTED_CHANGES") && ( //! Requested changes or misclick
                        <ActionButtons
                          buttonText="Undo"
                          type="undo"
                          style={{ marginLeft: "8px" }}
                          clickHandler={() => {
                            updateBountySubmissionStatus("UNDO");
                          }}
                        />
                      )}
                      {bountyDetails?.status == "IN_REVIEW" && (
                        <ActionButtons
                          buttonText="Request changes"
                          type="secondary"
                          style={{ marginLeft: "8px" }}
                          clickHandler={() => {
                            updateBountySubmissionStatus("REQUESTED_CHANGES");
                          }}
                        />
                      )}
                      {bountyDetails?.status == "IN_REVIEW" && (
                        <ActionButtons
                          buttonText="Reject"
                          type="failure"
                          style={{ marginLeft: "8px" }}
                          clickHandler={() => {
                            updateBountySubmissionStatus("REJECTED");
                          }}
                        />
                      )}
                      {bountyDetails?.status == "IN_REVIEW" ? (
                        bountyAmount ? (
                          <ActionButtons
                            buttonText="Approve & Initiate Payment"
                            type="success"
                            clickHandler={() => {
                              updateBountySubmissionStatus("APPROVED");
                              handleApproveBounty(
                                bountyDetails?.bountySubmissionId
                              );
                            }}
                            style={{ marginLeft: "8px" }}
                          />
                        ) : (
                          <ActionButtons
                            buttonText="Approve"
                            type="success"
                            clickHandler={() => {
                              updateBountySubmissionStatus("APPROVED");
                              handleApproveBounty(
                                bountyDetails?.bountySubmissionId
                              );
                            }}
                            style={{ marginLeft: "8px" }}
                          />
                        )
                      ) : bountyDetails.isWinner && !isPaid ? (
                        <ActionButtons
                          buttonText="Initiate Payment"
                          type="success"
                          clickHandler={() => {
                            updateBountySubmissionStatus("APPROVED");
                            setPaymentModal(true);
                          }}
                          style={{ marginLeft: "8px" }}
                        />
                      ) : null}
                      {bountyDetails?.status !== "IN_REVIEW" && (
                        <div
                          style={{
                            border: "1px solid #E5E7EB",
                            borderRadius: "100px",
                            padding: "6px 12px",
                            display: "flex",
                            alignItems: "center",
                            color: "#374151",
                            fontSize: "14px",
                            fontWeight: "500",
                            marginLeft: "12px",
                          }}
                        >
                          <span
                            style={{
                              display: "inline-block",
                              height: "8px",
                              width: "8px",
                              borderRadius: "20px",
                              backgroundColor:
                                BOUNTY_SUBMISSION_STATUS_COLORS[
                                  bountyDetails?.status
                                ] || "#D9D9D9",
                              marginRight: "10px",
                            }}
                          ></span>
                          <span>
                            {titleCase(
                              bountyDetails?.status
                                ?.toLowerCase()
                                .split("_")
                                .join(" ")
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div>
                        {bountyDetails?.transactionStatus.length > 0 &&
                          bountyDetails.transactionStatus[0].walletType ==
                            "GNOSIS" && (
                            <ActionButtons
                              buttonText={
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                  }}
                                >
                                  View on Gnosis
                                  <ArrowTopRightOnSquareIcon
                                    color="#6B7280"
                                    strokeWidth={2}
                                    width={16}
                                    style={{ marginLeft: "8px" }}
                                  />
                                </span>
                              }
                              type="link"
                              url={`https://app.safe.global/transactions/queue?safe=${bountyDetails?.transactionStatus[0]?.from}`}
                            />
                          )}
                      </div>
                    </>
                  )}
                </> // admin or reviewer
              ) : (
                <>
                  <div>
                    {comments?.length > 0 && !showComments && (
                      <ActionButtons
                        type="secondary"
                        buttonText={
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <CommentIcon /> {comments?.length}
                          </span>
                        }
                        clickHandler={() => setShowComments(!showComments)}
                      />
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {bountyDetails?.status == "REQUESTED_CHANGES" && (
                      <ActionButtons
                        buttonText="Edit"
                        type="edit"
                        style={{ marginLeft: "8px" }}
                        clickHandler={() => {
                          setBountySubmissionDataPrefill({
                            title: bountyDetails.bountySubmissionTitle,
                            description:
                              bountyDetails.bountySubmissionDescription,
                            attachments:
                              bountyDetails.bountySubmissionAttachments,
                            id: bountyDetails.bountySubmissionId,
                          });
                          setSubmitMountyModalVisible(true);
                        }}
                      />
                    )}
                    {/* <ActionButtons
                      buttonText="Delete"
                      type="delete"
                      style={{ marginLeft: "8px" }}
                    /> */}
                  </div>
                </> // user
              )}
            </div>
            {(bountyDetails?.status == "REQUESTED_CHANGES" || showComments) && (
              <div
                style={{
                  marginTop: "24px",
                }}
              >
                <CommentSection
                  entity="bounty-submissions-"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  sortingTypeList={DEFAULT_SORTING_TYPE_LIST}
                  allComments={comments}
                  postComment={postComment}
                  fetchComments={fetchComments}
                  authorUserName={bountyDetails.userName}
                />
              </div>
            )}
          </div>,
        ]}
        className="view-bounty-submission-modal"
      >
        <h3 className="view-bounty-submission-modal__title">
          {bountyDetails?.bountySubmissionTitle}
        </h3>
        <p className="view-bounty-submission-modal__description">
          {bountyDetails.bountySubmissionDescription}
        </p>
        {bountyDetails.bountySubmissionAttachments?.length > 0 && (
          <p>Attachments :</p>
        )}
        <div className="view-bounty-submission-modal__attachment--container">
          {bountyDetails.bountySubmissionAttachments?.map(
            (singleAttachment, index) => {
              return (
                <>
                  {showAttachmentLink ? (
                    <Link href={singleAttachment} target="_blank">
                      <img
                        src={singleAttachment}
                        alt="attachment"
                        style={{
                          width: "130px",
                          height: "130px",
                          borderRadius: "12px",
                          marginRight: "10px",
                        }}
                      />
                    </Link>
                  ) : (
                    <Document
                      file={singleAttachment}
                      onLoadSuccess={() => {}}
                      loading={<span>Loading file</span>}
                      onLoadError={() => {
                        console.log("failed");
                        setShowAttachmentLink(true);
                      }}
                      className="view-bounty-submission-modal__attachment"
                    >
                      <Page pageNumber={1} />
                      <div
                        className="view-bounty-submission-modal__attachment--title"
                        onClick={() => {
                          window.open(singleAttachment);
                        }}
                      >
                        <img src={pdfIcon} alt="" />
                        <span>File {index + 1}</span>
                        <img src={downloadIcon} alt="" />
                      </div>
                    </Document>
                  )}
                </>
              );
            }
          )}
        </div>
        {bountyDetails?.transactionStatus?.length > 0 &&
          bountyDetails?.transactionStatus.map((singleTx) => {
            return (
              <PaymentStatus
                status={singleTx}
                isAdmin={isAdmin}
                userDetails={userDetails}
              />
            );
          })}
      </Modal>
    </>
  );
}

export default ViewBountySubmissionModal;

import { FunnelIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  Checkbox,
  Input,
  Radio,
  Skeleton,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import IconWrapper from "./IconWrapper";
import { PopupCard } from "../styles/PopupCard";
import styled from "styled-components";
import dummyUser from "./../assets/images/login/defaultUser.jpeg";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import { object } from "prop-types";

const FilterButton = styled(Button)`
  color: ${(props) => props.color || "default"};
  border: 1px solid #e2e8f0;
  border-radius: 20px;
  margin-right: 8px;
  font-weight: 500;
`;

const CardBody = styled.div`
  display: flex;
  height: 376px;
  & .left-section {
    width: 120px;
    border-right: 1px solid #eee;
    gap: 0;
  }
  & .filter-item {
    width: 100%;
    height: 47px;
    cursor: pointer;
    padding: 14px 16px;
    &:hover {
      background: #f4f2f2;
    }
  }
  & .selected {
    background: #f4f2f2;
  }
  & .applied {
    color: #40a9ff;
  }
  & .right-section {
    overflow-y: scroll;
    width: 240px;
    padding: 12px;
  }
`;

const CardFooter = styled(Space)`
  width: 100%;
  height: 61px;
  text-align: right;
  border-top: 1px solid #eee;
  justify-content: flex-end;
`;

const AmountData = [
  { value: "0 - 100", displayValue: "$0 - $100" },
  { value: "101 - 200", displayValue: "$101 - $200" },
  { value: "201 - 500", displayValue: "$201 - $500" },
  { value: "501 - 1000", displayValue: "$501 - $1000" },
  { value: "1001 - 2000", displayValue: "$1001 - $2000" },
  { value: "2000 - more", displayValue: "$2000 and more" },
];

const DueDateData = [
  { value: "7", displayValue: "Within a week" },
  { value: "15", displayValue: "Within 15 days" },
  { value: "30", displayValue: "Within 30 days" },
  { value: "45", displayValue: "Within 45 days" },
  { value: "60", displayValue: "Within 60 days" },
];

const changeDataShape = (data, id, name) =>
  Array.isArray(data)
    ? data.map((item) => ({ value: item[id], displayValue: item[name] }))
    : [];

const BountyFilters = ({
  filterOptions,
  initialSubFilterState,
  filterData,
  selectedSubFilter,
  setSelectedSubFilter,
  setApplyFilter,
  loadingFilters,
  numberOfFilters,
  setNumberOfFilters,
}) => {
  const [showCard, setShowCard] = React.useState(false);

  const [tempFilterState, setTempFilterState] = useState(initialSubFilterState);
  const [searchQuery, setSearchQuery] = useState("");

  const [selctedFilter, setSelctedFilter] = React.useState("skills");
  const [filterOptionsConfig, setFilterOptionsConfig] = useState({});
  useEffect(() => {
    setTempFilterState(structuredClone(selectedSubFilter));
    setSearchQuery("");
  }, [showCard]);
  useEffect(() => {
    setSearchQuery("");
  }, [selctedFilter]);
  const ref = React.useRef();
  useEffect(() => {
    let numFilters = 0;
    Object.keys(selectedSubFilter).forEach((key) => {
      Object.keys(selectedSubFilter[key]).forEach((subKey) =>
        selectedSubFilter[key][subKey] ? numFilters++ : null
      );
    });
    setNumberOfFilters(numFilters);
  }, [selectedSubFilter]);
  useOnClickOutside(ref, () => {
    setShowCard(false);
  });
  useMemo(() => {
    setFilterOptionsConfig({
      skills: {
        data: changeDataShape(filterData.skills, "name", "name"),
        searchbar: true,
        icon: false,
        inputType: "checkbox",
      },
      difficultyLevel: {
        data: filterData.difficultyLevel,
        searchbar: true,
        icon: false,
        inputType: "checkbox",
      },
      amount: {
        data: AmountData,
        searchbar: false,
        icon: false,
        inputType: "radio",
      },
      dueDate: {
        data: DueDateData,
        searchbar: false,
        icon: false,
        inputType: "radio",
      },
      creator: {
        data: filterData.reviewers?.map((item) => ({
          value: item.userId,
          displayValue: item.userName,
          icon: item.profilePicture,
        })),
        searchbar: true,
        icon: true,
        inputType: "checkbox",
      },
      reviewer: {
        data: filterData.reviewers?.map((item) => ({
          value: item.userId,
          displayValue: item.userName,
          icon: item.profilePicture,
        })),
        searchbar: true,
        icon: true,
        inputType: "checkbox",
      },
      assignees: {
        data: filterData.reviewers?.map((item) => ({
          value: item.userId,
          displayValue: item.userName,
          icon: item.profilePicture,
        })),
        searchbar: true,
        icon: true,
        inputType: "checkbox",
      },
    });
  }, [filterData]);

  const subfilterSelectionHanlder = ({ target: { checked } }, value, type) => {
    const currentSubFilterState = tempFilterState[selctedFilter];
    const newSubFilterState =
      type === "radio"
        ? { [value]: checked }
        : { ...currentSubFilterState, [value]: checked };
    setTempFilterState((prev) => ({
      ...prev,
      [selctedFilter]: newSubFilterState,
    }));
  };
  return (
    <PopupCard cardPadding="0px" width="360px" bottom="unset" margin="2px 0px">
      <FilterButton
        onClick={() => setShowCard(true)}
        color={numberOfFilters > 0 ? "#40A9FF" : "default"}
      >
        <IconWrapper>
          <FunnelIcon />
        </IconWrapper>
        Filters {numberOfFilters > 0 ? `(${numberOfFilters})` : null}
      </FilterButton>
      {showCard && (
        <Card
          ref={ref}
          className="card"
          title="Filters By"
          extra={
            <Button
              type="text"
              onClick={() => {
                // setSelectedSubFilter(initialSubFilterState);
                setTempFilterState(initialSubFilterState);
              }}
            >
              Clear all
            </Button>
          }
        >
          <CardBody>
            <div className="left-section">
              {filterOptions.map((filterOption) => (
                <Typography
                  key={filterOption.value}
                  className={`filter-item ${selctedFilter === filterOption.value ? "selected" : ""
                    } ${Object.keys(tempFilterState[filterOption.value]).reduce(
                      (a, b) =>
                        tempFilterState[filterOption.value][b] ? 1 : 0 + a,
                      0
                    ) > 0
                      ? "applied"
                      : ""
                    }`}
                  onClick={() => setSelctedFilter(filterOption.value)}
                >
                  {filterOption.displayValue}
                </Typography>
              ))}
            </div>
            <Space direction="vertical" className="right-section">
              {filterOptionsConfig[selctedFilter].searchbar && (
                <Input
                  style={{ borderRadius: "12px" }}
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  prefix={
                    <IconWrapper>
                      <MagnifyingGlassIcon />
                    </IconWrapper>
                  }
                />
              )}
              {loadingFilters && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // height: "500px",
                  }}
                >
                  <Skeleton active />
                </div>
              )}
              {selctedFilter === "amount" && (
                <Typography style={{ marginBottom: "4px", color: "#64748B" }}>
                  Amount pegged to USD
                </Typography>
              )}

              {filterOptionsConfig[selctedFilter].inputType === "checkbox"
                ? filterOptionsConfig[selctedFilter].searchbar
                  ? filterOptionsConfig[selctedFilter].data
                    .filter((ele) =>
                      ele.displayValue
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                    )
                    .map((item) => (
                      <Checkbox
                        key={item.value}
                        onChange={(e) =>
                          subfilterSelectionHanlder(
                            e,
                            item.value,
                            filterOptionsConfig[selctedFilter].inputType
                          )
                        }
                        checked={tempFilterState[selctedFilter][item.value]}
                      >
                        {filterOptionsConfig[selctedFilter].icon && (
                          <img
                            src={item.icon ? item.icon : dummyUser}
                            alt="icon"
                            style={{
                              marginRight: "8px",
                              width: "16px",
                              height: "16px",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                        <span>{item.displayValue}</span>
                      </Checkbox>
                    ))
                  : filterOptionsConfig[selctedFilter].data.map((item) => (
                    <Checkbox
                      key={item.value}
                      onChange={(e) =>
                        subfilterSelectionHanlder(
                          e,
                          item.value,
                          filterOptionsConfig[selctedFilter].inputType
                        )
                      }
                      checked={tempFilterState[selctedFilter][item.value]}
                    >
                      {filterOptionsConfig[selctedFilter].icon && (
                        <img
                          src={item.icon ? item.icon : dummyUser}
                          alt="icon"
                          style={{
                            marginRight: "8px",
                            width: "16px",
                            height: "16px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      <span>{item.displayValue}</span>
                    </Checkbox>
                  ))
                : filterOptionsConfig[selctedFilter].data.map((item) => (
                  <Radio
                    key={item.value}
                    onChange={(e) =>
                      subfilterSelectionHanlder(
                        e,
                        item.value,
                        filterOptionsConfig[selctedFilter].inputType
                      )
                    }
                    checked={tempFilterState[selctedFilter][item.value]}
                  >
                    {filterOptionsConfig[selctedFilter].icon && (
                      <img
                        src={item.icon ? item.icon : dummyUser}
                        alt="icon"
                        style={{
                          marginRight: "8px",
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <span>{item.displayValue}</span>
                  </Radio>
                ))}
            </Space>
          </CardBody>
          <CardFooter>
            <Button
              style={{ borderRadius: "12px" }}
              onClick={() => setShowCard(false)}
            >
              Close
            </Button>
            <Button
              style={{ borderRadius: "12px", marginRight: "16px" }}
              type="primary"
              onClick={() => {
                setShowCard(false);
                setApplyFilter(true);
                setSelectedSubFilter(structuredClone(tempFilterState));
              }}
            >
              Show Result
            </Button>
          </CardFooter>
        </Card>
      )}
    </PopupCard>
  );
};

export default BountyFilters;

import { Dropdown, Menu, Skeleton, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import customAxios from "../../../api";
import { DaoContext } from "../../../layouts/app/DaoContext";
import GoBackButton from "../../InternalComponents/GoBackButton";
import { StyledMenu } from "../CourseCard";
import styled from "styled-components";
import DotIcon from "../../../images/dot.svg";
import DeleteEntityModal from "../../Common/DeleteEntityModal";
import CreateDocumentModal from "./CreateDocumentModal";
import DocumentCard from "./DocumentCard";
import EmptyComponent from "../EmptyComponent";
import no_course from "../../../assets/iconSvg/no_course.svg";
import IconWrapper from "../../IconWrapper";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import useWindowSize from "../../../hooks/useWindowSize";
import { PinIcon } from "../../Icones";
import { useMutation } from "react-query";
import { pinDocumentToOverview } from "../../../utils/Documents";
import { toast } from "react-toastify";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
const StyledDotIconContainer = styled.div`
  & img:hover {
    cursor: pointer;
  }
`;
const CollectionDetailsPage = () => {
  const { documentId } = useParams();
  const [loading, setLoading] = useState(false);
  const { isMobile } = useWindowSize();
  const navigate = useDaoLensNavigate();
  const [mode, setMode] = useState("VIEW");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [update, setUpdate] = useState(false);

  const { daoDetails, isAdmin } = useContext(DaoContext);
  const [collection, setCollection] = useState();
  const [isExpanded, setIsExpanded] = useState(false);

  const { mutate: pinCollection } = useMutation(
    ({ isPin, id }) => pinDocumentToOverview({ collectionId: id, pin: isPin }),
    {
      onSuccess: () => {
        toast.success("Collection pinned");
        setUpdate((prev) => !prev);
      },
      onError: (error) => {
        console.error(error);
        toast.error("Something went wrong while pinning collection");
      },
    }
  );

  useEffect(() => {
    setLoading(true);
    customAxios
      .get(`documents/collection/fetch/?collectionId=${documentId}`)
      .then((res) => {
        if (res.data?.id) setCollection(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [documentId, update]);

  console.log(collection);
  const menu = (
    <StyledMenu onClick={(e) => {}}>
      <Menu.Item
        key={0}
        onClick={() => {
          pinCollection({ isPin: !collection?.pinOverview, id: collection.id });
        }}
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconWrapper>
          <PinIcon />
        </IconWrapper>{" "}
        {collection?.pinOverview
          ? "Unpin / remove from overview"
          : "Pin / show on overview"}
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          setMode("EDIT");
        }}
      >
        <IconWrapper>
          <PencilIcon fill="#6B7280" width="12px" />
        </IconWrapper>{" "}
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          setShowDeleteModal(true);
        }}
      >
        <IconWrapper>
          <TrashIcon fill="#6B7280" width="12px" />
        </IconWrapper>{" "}
        Delete
      </Menu.Item>
    </StyledMenu>
  );

  return (
    <div style={{ paddingLeft: isMobile ? "16px" : "0px" }}>
      <div style={{ height: 20 }} />

      {
        <GoBackButton
          showIcon
          buttonText={"Go Back"}
          clickHandler={() => {
            navigate(-1);
          }}
          style={{ marginBottom: "12px" }}
        />
      }
      {loading && <Skeleton active />}
      <CreateDocumentModal
        onSubmit={() => {
          setUpdate((prev) => !prev);
        }}
        isModalVisible={mode !== "VIEW"}
        handleCancel={() => {
          setMode("VIEW");
        }}
        edit={mode === "EDIT"}
        collection={collection}
      />
      <DeleteEntityModal
        note="This collection will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          customAxios
            .delete(
              `documents/collection/delete?collectionId=${collection?.id}`
            )
            .then(() => {
              navigate(`/app/dao/${daoDetails?.daoId}/library`);
            });
        }}
      />
      {!loading && collection && (
        <div
          style={{
            background: "white",
            borderRadius: "16px",
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            gap: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <div
              style={{
                font: "normal 500 28px/32px Inter",
                width: "56px",
                height: "56px",
                background: collection.background,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              }}
            >
              {collection.icon}
            </div>
            <span
              className="font-semibold"
              style={{
                display: "inline-flex",
                fontSize: "12px",
                alignItems: "center",
                padding: "5px 10px",
                border: "1px solid #E2E8F0",
                borderRadius: "30px",
                gap: "4px",
                marginLeft: "auto",
              }}
            >
              📕 {collection.totalDocuments ? collection.totalDocuments : 0}
            </span>
            {collection?.pinOverview && <PinIcon />}
            {isAdmin && (
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <StyledDotIconContainer>
                  <img src={DotIcon} alt="dot icon" />
                </StyledDotIconContainer>
              </Dropdown>
            )}
          </div>
          <div
            style={{
              font: "normal 500 16px/24px Inter",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {collection.title}
          </div>
          <Typography.Paragraph
            style={{
              color: "#64748B",
              margin: "0",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              cursor: "pointer",
            }}
            onClick={() => {
              setIsExpanded((prev) => !prev);
            }}
            ellipsis={{
              rows: isExpanded ? "" : 2,
            }}
          >
            {collection.description}
          </Typography.Paragraph>

          {isAdmin && (
            <div
              style={{
                background: "#EBF5FF",
                border: "1px dashed #76A9FA",
                borderRadius: "12px",
                font: "normal 500 14px/20px Inter",
                color: "#3B82F6",
                height: "44px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setMode("CREATE");
              }}
            >
              + Add new document
            </div>
          )}
          {!loading && collection?.documents.length === 0 && (
            <EmptyComponent
              imageSource={no_course}
              text="No document added yet"
              subtext="Start by adding your first document or link to this collection."
            />
          )}

          {collection?.documents.map((document) => (
            <DocumentCard
              document={document}
              reload={() => {
                setUpdate((prev) => !prev);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};
export default CollectionDetailsPage;

import React from "react";
import ReactionEmojiImage from "../../assets/images/Comments/reaction-comment.svg";
import { useLayer } from "react-laag";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";

type Props = {
  onEmojiClick: (emojiData: EmojiClickData) => void;
};

const EmojiReactionButton = ({ onEmojiClick }: Props) => {
  const [isOpen, setOpen] = React.useState(false);

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    auto: true,
    triggerOffset: 3,
    arrowOffset: 4,
    possiblePlacements: [
      "bottom-start",
      "bottom-end",
      "bottom-center",
      "top-start",
      "top-center",
      "top-end",
    ],
    onOutsideClick: () => setOpen(false),
  });

  return (
    <>
      <button
        {...triggerProps}
        onClick={() => {
          setOpen(!isOpen);
        }}
        style={{
          background: "none",
          border: "none",
          padding: 0,
          cursor: "pointer",
        }}
      >
        <img
          src={ReactionEmojiImage}
          alt="reaction emoji"
          style={{ width: "18.33px", height: "17.5px" }}
        />
      </button>
      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            style={{
              ...layerProps.style,
              boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
              zIndex: 10,
            }}
          >
            <EmojiPicker
              onEmojiClick={(data) => {
                onEmojiClick(data);
                setOpen(false);
              }}
              autoFocusSearch={false}
            />
          </div>
        )}
    </>
  );
};

export default EmojiReactionButton;

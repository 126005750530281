import Card, { Title } from "../Common/Card";
import React from "react";
import { CheckBadgeIcon, DocumentDuplicateIcon } from "@heroicons/react/24/solid";
import lensIcon from "./../../assets/images/common/lens.svg";
import Pohumanity from "./../../assets/images/lens/pohumanity.svg";
import Ens from "./../../assets/images/lens/ens.svg";
import Sybil from "./../../assets/images/lens/sybil.svg";
import WorldCoin from "./../../assets/images/lens/worldcoin.svg";
import { Tooltip } from "antd";
import IconWrapper from "../IconWrapper";
import { copyTextToClipboard } from "../../utils";
import { toast } from "react-toastify";


interface LensProfile {
    handle: string;
    id: string;
    onChainIdentity?: {
        ens?: { name: string },
        proofOfHumanity?: boolean,
        sybilDotOrg: {
            verified: boolean
        },
        worldcoin: {
            isHuman: boolean
        }

    }
}
const LensProfile = ({ profile }: { profile: LensProfile }) => {
    let onChainProfiles = [{
        icon: Pohumanity,
        toolTipContent: "Proof of Humanity Verified",
        isConnected: profile.onChainIdentity?.proofOfHumanity
    },
    {
        icon: Ens,
        toolTipContent: <div className="flex gap-1">
            Ens:
            <div className="text-slate-500 flex">
                {profile.onChainIdentity?.ens?.name}
            </div>
            <IconWrapper>
                <DocumentDuplicateIcon className=" text-slate-400 cursor-pointer" onClick={() => {
                    copyTextToClipboard(profile.onChainIdentity?.ens?.name).then(() => {
                        toast.success("Ens copied successfully");
                    });

                }} />
            </IconWrapper>
        </div>,
        isConnected: profile.onChainIdentity?.ens?.name ? true : false

    },
    {
        icon: Sybil,
        toolTipContent: "Sybil Verified",
        isConnected: profile.onChainIdentity?.sybilDotOrg.verified

    }, {
        icon: WorldCoin,
        toolTipContent: "Worldcoin Verified",
        isConnected: profile.onChainIdentity?.worldcoin.isHuman
    }]
    return <Card>
        <Title
            title={
                <>
                    Lens Account Details
                    <CheckBadgeIcon className="w-4 ml-1 text-blue-500" />
                </>
            }
            style={{ marginBottom: "12px" }}
        />
        <div className="flex justify-between">
            <Tooltip title={<div className="flex gap-1">
                Profile:
                <div className="text-slate-500 flex">
                    {profile.handle}
                </div>
                <IconWrapper>
                    <DocumentDuplicateIcon className=" text-slate-400 cursor-pointer" onClick={() => {
                        copyTextToClipboard(profile.handle).then(() => {
                            toast.success("Lens handle copied successfully");
                        });

                    }} />
                </IconWrapper>
            </div>} color="white"
                overlayInnerStyle={{ color: "black", borderRadius: "10px", border: "1px solid #E5E7EB", font: "normal 500 12px/16px Inter", paddingBottom: "0px" }}
            >
                <div className="text-slate-500 flex gap-1 border border-solid border-gray-300 rounded-full px-2 py-1" style={{ boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.05)" }}>
                    <img
                        src={lensIcon}
                        alt="Wallet"
                        style={{
                            width: "18px",
                        }}
                    />
                    <div className="text-slate-500 flex">
                        <div className="max-w-[70px] overflow-hidden text-ellipsis font-medium">
                            {profile.handle}
                        </div>
                        #{profile.id}

                    </div>


                </div>
            </Tooltip>

            <div className="flex items-center">
                {onChainProfiles.map((item, index) => {
                    if (!item.isConnected) {
                        return null
                    }
                    return <Tooltip key={index} title={item.toolTipContent} color="white"
                        overlayInnerStyle={{ color: "black", borderRadius: "10px", border: "1px solid #E5E7EB", font: "normal 500 12px/16px Inter", paddingBottom: "0px" }}
                    >
                        <img src={item.icon} key={1} className="-ml-2" />
                    </Tooltip>
                })}
            </div>


        </div>

    </Card>;
};

export default LensProfile;
import moment from "moment";
import React from "react";
import "../ComponentStyles/FeedCard.scss";
import { Typography } from "antd";
import ProfileCardPopup from "./ProfileCard";
import { StyledProfileDetails } from "../BountyFeed";
import { useNavigate } from "react-router";
const { Paragraph } = Typography;
function FeedCard({
  userImage,
  userName,
  userId,
  cardTime,
  cardTitle,
  cardDescription,
  footer,
  onCardClick,
  additionalClassName = "",
}) {
  const navigate = useNavigate();
  return (
    <div
      className={`feedcard__wrapper ${additionalClassName}`}
      onClick={onCardClick}
    >
      <div className="feedcard__top">
        <div className="feedcard__top--profile-details">
          <ProfileCardPopup userId={userId}>
            <StyledProfileDetails
              onClick={() => navigate(`/app/profile/${userId}`)}
            >
              <div className="feedcard__top--profile-details--user-details">
                <img src={userImage} alt={userName} />
                <span className="feedcard__top--profile-details--user-details--name">
                  {userName}
                </span>
              </div>
            </StyledProfileDetails>
          </ProfileCardPopup>
          <span className="feedcard__top--profile-details--time">
            {" "}
            &#x2022;
          </span>
          <span className="feedcard__top--profile-details--time">
            {moment(cardTime).fromNow(false)}
          </span>
        </div>
        <h3 className="feedcard__top--title">{cardTitle}</h3>
        <p className="feedcard__top--description">
          <Paragraph
            ellipsis={{
              rows: 2,
            }}
          >
            {cardDescription}
          </Paragraph>
        </p>
      </div>
      <div className="feedcard__bottom">{footer}</div>
    </div>
  );
}

export default FeedCard;

import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Skeleton } from "antd";

import { StyledMenu } from "../CourseCard";
import { tabs } from "../CreateCourseContentModal";
import { getContentType, HoverCard } from "../CourseContent/CourseContentCard";
import { ReactComponent as GreyVerticalDot } from "../../../assets/images/daoOverview/tripledotVertical.svg";
import { DaoContext } from "../../../layouts/app/DaoContext";
import { useContext, useState } from "react";
import DeleteEntityModal from "../../Common/DeleteEntityModal";
import customAxios from "../../../api";
import CreateDocumentModal from "./CreateDocumentModal";
import IconWrapper from "../../IconWrapper";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import { PinIcon } from "../../Icones";
import { useMutation } from "react-query";
import { pinDocumentToOverview } from "../../../utils/Documents";
import { toast } from "react-toastify";
const DocumentCard = ({
  document,
  reload,
  isWhiteSpaceWrapNotAllowed = false,
  isOnOverviewPage = false,
}) => {
  const { mutate: pinDoc } = useMutation(
    (isPin) => pinDocumentToOverview({ documentId: document.id, pin: isPin }),
    {
      onSuccess: () => {
        toast.success("Document pinned");
        reload();
      },
      onError: (error) => {
        console.error(error);
        toast.error("Something went wrong while pinning document");
      },
    }
  );

  const { isAdmin } = useContext(DaoContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const menu = (
    <StyledMenu>
      {document?.collectionId === null && (
        <Menu.Item
          key={0}
          onClick={() => {
            pinDoc(!document?.pinOverview);
          }}
          style={{ display: "flex", alignItems: "center" }}
        >
          <IconWrapper>
            <PinIcon />
          </IconWrapper>{" "}
          {document?.pinOverview
            ? "Unpin / remove from overview"
            : "Pin / show on overview"}
        </Menu.Item>
      )}
      <Menu.Item
        key={1}
        onClick={(e) => {
          setEditModalVisible(true);
        }}
      >
        <IconWrapper>
          <PencilIcon fill="#6B7280" width="12px" />
        </IconWrapper>{" "}
        Edit
      </Menu.Item>
      <Menu.Item
        key={2}
        onClick={(e) => {
          setShowDeleteModal(true);
        }}
      >
        <IconWrapper>
          <TrashIcon fill="#6B7280" width="12px" />
        </IconWrapper>{" "}
        Delete
      </Menu.Item>
    </StyledMenu>
  );
  return (
    <HoverCard
      style={{
        border: "1px solid #E2E8F0",
        borderRadius: "12px",
        padding: isOnOverviewPage ? "12px" : "16px",
        display: "flex",
        alignItems: "center",
        font: "normal 500 14px/20px Inter",
        background: "white",
        zIndex: 1,
        position: "relative",
        cursor: "pointer",
      }}
    >
      <CreateDocumentModal
        onSubmit={() => {
          reload();
        }}
        isModalVisible={editModalVisible}
        handleCancel={() => setEditModalVisible(false)}
        edit
        document={document}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "16px",
        }}
        onClick={() => {
          window.open(document.link);
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: isOnOverviewPage ? "20px" : "",
            height: isOnOverviewPage ? "20px" : "",
          }}
        >
          {
            tabs.find(({ value }) => value === getContentType(document))
              .coloricon
          }
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            flex: "1",
            overflow: "hidden",
            whiteSpace: isWhiteSpaceWrapNotAllowed ? "" : "nowrap",
          }}
        >
          {document?.title}
          {!isOnOverviewPage && (
            <div
              style={{
                font: "normal 500 12px/16px Inter",
                color: "#64748B",
              }}
            >
              {document.description}
            </div>
          )}
        </div>
      </div>
      {!isOnOverviewPage && (
        <>
          {document?.pinOverview && (
            <div style={{ marginRight: "4px" }}>
              <PinIcon style={{ color: "#6366F1" }} />
            </div>
          )}
          {isAdmin && (
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              style={{
                position: "absolute",
                background: "white",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <GreyVerticalDot
                style={{
                  cursor: "pointer",
                }}
              />
            </Dropdown>
          )}
        </>
      )}
      <DeleteEntityModal
        note="This document content will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          customAxios
            .delete(`documents/delete?documentId=${document.id}`)
            .then(() => {
              reload();
            });
        }}
      />
    </HoverCard>
  );
};
export default DocumentCard;

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import "./InternalComponentStyles/PrimaryButtonFilled.scss";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 16,
      color: "#ffffff",
      marginLeft: "12px",
    }}
    spin
  />
);

function PrimaryButtonFilled({
  fillColor,
  buttonText,
  clickHandler,
  style,
  loader,
  otherProps,
  disabled,
}) {
  return (
    <div
      className="primary-button-filled"
      style={{
        backgroundColor: fillColor,
        ...style,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      onClick={clickHandler}
    >
      <span className="primary-button-filled__text">{buttonText}</span>
      {loader && <Spin indicator={antIcon} />}
    </div>
  );
}

export default PrimaryButtonFilled;

import React, { Dispatch, SetStateAction, useContext, useEffect } from "react";
import "../ComponentStyles/CommentReplyInput.scss";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import { useState } from "react";
import QuillEditor from "../QuillEditor";
import { Button } from "antd";
import { UserContext } from "../../layouts/app/UserContext";
import { trimNewLines } from "../../utils/comments";
import { toast } from "react-toastify";

type Props = {
  postComment: (
    commentText: string,
    mentionedUsers: any[],
    parentId: string,
    level2ParentId?: string
  ) => any;
  isNestedReply: any;
  parentLoader?: any;
  setShowParentLoader?: Dispatch<SetStateAction<boolean>>;
  parentId: string;
  onSubmit: any;
  initialCommentText?: string;
  initialUserTagged?: string[];
  onCancel?: () => void;
  level2ParentId?: string;
  isFocused?: boolean;
  isSmallProfilePicture?: boolean;
  initialGptText?: string;
  onPostCommentSet?: any;
  checkIsEnabled?: () => boolean;
  minimumCharLimit?: number;
};

function CommentReplyInput({
  postComment,
  isSmallProfilePicture = false,
  isFocused = false,
  isNestedReply,
  parentLoader,
  setShowParentLoader,
  minimumCharLimit = 0,
  parentId,
  onSubmit,
  initialCommentText,
  initialUserTagged,
  onCancel,
  level2ParentId = undefined,
  initialGptText,
  onPostCommentSet,
  checkIsEnabled,
}: Props) {
  const userSession = useContext(UserContext);
  const { userDetails } = userSession as any;
  const [focus, setFocus] = useState(isFocused);
  const [commentText, setCommentText] = useState(
    initialGptText ||
      initialCommentText ||
      (isNestedReply
        ? `<span class="mention" data-denotation-char="@" data-id="${
            isNestedReply.userId
          }" data-value="${
            isNestedReply.userName
          }">﻿<span contenteditable="false"><span class="ql-mention-denotation-char">@</span>Sugandh3rd</span>﻿</span> ${"   "}`
        : "")
  );
  const [mentionedUsers, setMentionedUsers] = useState(initialUserTagged || []);
  const [loading, setLoading] = useState(false);

  // ! Effects
  useEffect(() => {
    if (initialGptText) {
      setFocus(true);
      setCommentText(initialGptText);
      onPostCommentSet();
    }
  }, [initialGptText]);

  const commentTextHandler = (ev: any) => {
    setCommentText(ev);
  };

  const onSubmitComment = () => {
    setLoading(true);
    const trimedComment = trimNewLines(commentText);

    if (trimedComment.length < minimumCharLimit) {
      toast.error(
        `Comment is too short. Please ensure comment is at least ${minimumCharLimit} characters.`
      );
      setLoading(false);
      return;
    }

    postComment(
      trimedComment,
      mentionedUsers,
      parentId,
      level2ParentId
    ).finally(() => {
      setCommentText("");
      if (!isFocused) setFocus(false);
      setLoading(false);
      onSubmit();
    });
    setShowParentLoader?.(true);
  };
  return (
    <div
      style={{
        display: "flex",
        margin: "8px 0px",
        alignItems: "center",
        gap: "8px",
        width: "100%",
      }}
    >
      <img
        style={{
          width: isSmallProfilePicture ? "24px" : "40px",
          height: isSmallProfilePicture ? "24px" : "40px",
          zIndex: "2",
          borderRadius: "50%",
          objectFit: "cover",
          marginBottom: "auto",
        }}
        src={
          userDetails?.profilePicture ? userDetails.profilePicture : dummyUser
        }
        alt={userDetails?.userName}
      />
      {!focus && (
        <input
          onFocus={(e) => {
            if (checkIsEnabled && !checkIsEnabled?.()) {
              e.currentTarget.blur();
              return;
            }

            setFocus(true);
          }}
          style={{
            width: "100%",
            border: "none",
            borderBottom: "1px solid #E5E7EB",
            fontSize: "14px",
            padding: "10px 12px",
          }}
          placeholder="Write your comment here"
        />
      )}
      {focus && (
        <div
          style={{
            flexGrow: "1",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <QuillEditor
            value={commentText}
            onBlur={() => {
              if (!focus || commentText || isFocused) return;
              setFocus(false);
            }}
            overflowVisible
            onChange={commentTextHandler}
            placeholder="Write your comment here"
            setMentionedUsers={setMentionedUsers}
            isBordered={true}
            autoFocus
            initialUserTagged={initialUserTagged}
          />
          <div style={{ display: "flex", gap: 12 }}>
            <Button
              type="primary"
              style={{ borderRadius: "8px" }}
              disabled={loading || !commentText || parentLoader}
              loading={loading || parentLoader}
              onClick={onSubmitComment}
            >
              {!!initialCommentText ? "Update" : "Comment"}
            </Button>
            <Button
              style={{ borderRadius: "8px" }}
              disabled={loading}
              onClick={() => {
                if (onCancel) {
                  onCancel();
                } else {
                  setCommentText("");
                  setFocus(false);
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CommentReplyInput;

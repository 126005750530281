export const POLLING_REFRESH_INTERVAL = 15 * 1000;
export const tokenOptions = [
  { label: "Polygon", value: "POLYGON" },
  { label: "Polygon Mumbai", value: "POLYGON_MUMBAI" },
  { label: "Goerli", value: "GOERLI" },
  { label: "Ethereum", value: "ETHEREUM" },
  { label: "Arbitrum", value: "ARBITRUM" },
  { label: "Solana", value: "SOLANA" },
  { label: "Gnosis Chain", value: "GNOSIS_CHAIN" },
  { label: "Solana Devnet", value: "SOLANA_DEVNET" },
];
export const DEFAULT_SORTING_TYPE_LIST = {
  Oldest: {
    sortBy: "creationDate",
    sortOrder: "ascending",
  },
  Latest: {
    sortBy: "creationDate",
    sortOrder: "descending",
  },
  Top: {
    sortBy: "likes",
    sortOrder: "descending",
  },
};
export const PROJECT_STATUSES = {
  todo: "TODO",
  inProgress: "INPROGRESS",
  inReview: "INREVIEW",
  completed: "COMPLETED",
};

export const PROJECT_TASK_STATUSES = Object.values(PROJECT_STATUSES);

export const PROJECT_TABS = {
  TODO: { label: "To-do", color: "#CFD8E3", enum: "TODO" },
  INPROGRESS: { label: "In-progress", color: "#6875F5", enum: "INPROGRESS" },
  INREVIEW: { label: "In-review", color: "#AC94FA", enum: "INREVIEW" },
  COMPLETED: { label: "Completed", color: "#31C48D", enum: "COMPLETED" },
};

export const ROUTES = {
  appOnboarding: "/app/onboarding",
  appDiscovery: "/app/discovery",
  bountyDetails: `/app/dao/:daoId/bounty/:bountyId`,
  authLogin: "/auth/login",
  appHome: "/app/home",
  appProfile: "/app/profile",
};

export const COMMUNITY_TYPES = [
  {
    id: "DAO",
    name: "🌍 DAOs",
    detail:
      "Any community which has active members driving towards the community goals.",
  },
  {
    id: "NFT",
    name: "🌁 NFT Community",
    detail: "Communities where you become a part by acquiring one of the NFTs.",
  },
  {
    id: "CONTENT_CREATOR",
    name: "✨ Content Creator",
    detail:
      "Communities which have a vision of creating social bonds through content.",
  },
  {
    id: "GAMING",
    name: "🎮 Gaming Community",
    detail:
      "Communities which are play-to-earn oriented and host events for their gamers",
  },
  {
    id: "SOCIAL",
    name: "👥 Social",
    detail:
      "Communities which have a vision of creating social bonds between members.",
  },
  {
    id: "PROTOCOL",
    name: "🔧 Protocol",
    details: "",
  },
  {
    id: "OTHERS",
    name: "🔍 Others",
    detail:
      "Any community which has active members driving towards the community goals.",
  },
];

export const FILTER_VALUES = COMMUNITY_TYPES.map((filter) => filter.name);

export const MOBILE_SUPPORTED_PATHS = [
  ROUTES.appDiscovery,
  ROUTES.authLogin,
  ROUTES.appOnboarding,
  ROUTES.appHome,
  ROUTES.appProfile,
];

export const MD_SCREEN_BREAKPOINT = 1200;

export const TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY =
  "twitter-redirect-bounty";

export const JOIN_DAO_BUTTON_ID_MD = "join-dao-button-md";

export const UNAUTH_JOIN_COMMUNITY_DETAILS = "unauthJoinCommunityDetails";

export const OPEN_OLD_ANNOUNCEMENTS_MODAL = "open_old_announcements_modal";

export const BOUNTY_SUBMISSION_STATUS_COLORS = {
  IN_REVIEW: "#A78BFA",
  PAYMENT_PENDING: "#FBBF24",
  PAYMENT_SUCCESSFUL: "#10B981",
  PAYMENT_REJECTED: "#F43F5E",
  REJECTED: "#F43F5E",
  REQUESTED_CHANGES: "#22D3EE",
};

export const CONTRACT_ID = "guest-book.mainnet";

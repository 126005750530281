import { toast } from "react-toastify";
import { getUsernameFromUrl } from "../../../utils";
import { Card, InputField } from "./Common";
export let tabs = [
  {
    label: "Follow",
    value: "FOLLOW",
    key: "twitterHandle",
    getCompleteActionLabel: (data) => {
      return `Follow @${data.twitterHandle.split(".com/")[1]} on twitter`;
    },
    validation: (state) => {
      if (!state.twitterHandle) {
        toast.error("Please provide twitter handel");
        throw new Error();
      }
      return {
        type: "FOLLOW",
        twitterHandle: "https://twitter.com/" + state.twitterHandle,
      };
    },
  },
  {
    label: "Like",
    value: "LIKE",
    key: "tweetUrl",
    getCompleteActionLabel: (data) => {
      return `Like this tweet`;
    },
    validation: (state) => {
      if (!state.tweetUrl) {
        toast.error("Please provide tweet url");
        throw new Error();
      }
      return { type: "LIKE", tweetUrl: state.tweetUrl };
    },
  },
  {
    label: "Reply",
    value: "REPLY",
    key: "tweetUrl",
    getCompleteActionLabel: (data) => {
      return `Reply to this tweet`;
    },
    validation: (state) => {
      if (!state.tweetUrl) {
        toast.error("Please provide tweet url");
        throw new Error();
      }
      return { type: "REPLY", tweetUrl: state.tweetUrl, reply: state.reply };
    },
  },
  {
    label: "Retweet",
    value: "RETWEET",
    key: "tweetUrl",
    getCompleteActionLabel: (data) => {
      return `Retweet this tweet`;
    },
    validation: (state, res) => {
      if (!state.tweetUrl) {
        toast.error("Please provide tweet url");
        throw new Error();
      }
      return { type: "RETWEET", tweetUrl: state.tweetUrl };
    },
  },
  {
    label: "Tweet",
    value: "TWEET",
    key: "tweetMsge",
    getCompleteActionLabel: (data) => {
      return `Tweet this message`;
    },
    validation: (state, res) => {
      if (!state.tweetMsge) {
        toast.error("Please provide tweet message");
        throw new Error();
      }
      return { type: "TWEET", tweetMsge: state.tweetMsge };
    },
  },
  {
    label: "Join space",
    value: "JOIN_SPACE",
    key: "spaceUrl",
    getCompleteActionLabel: (data) => {
      return `Join this twitter space`;
    },
    validation: (state, res) => {
      if (!state.spaceUrl) {
        toast.error("Please provide twitter space url");
        throw new Error();
      }
      return { type: "JOIN_SPACE", spaceUrl: state.spaceUrl };
    },
  },
];
const TwitterTask = ({ state, setState }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "20px",
      }}
    >
      <div
        style={{
          font: "normal 600 12px/16px Inter",
          color: "#64748B",
        }}
      >
        Actions
        <div style={{ marginTop: "8px" }}>
          <div
            style={{
              display: "flex",
              gap: "8px",
              font: "normal 500 12px/16px Inter",
              flexWrap: "wrap",
            }}
          >
            {tabs.map(({ label, value }) => (
              <div
                onClick={() => {
                  setState((prev) => {
                    return { ...prev, [value]: !prev[value] };
                  });
                  // setUpdate((prev) => !prev);
                }}
                style={{
                  padding: "8px 16px",
                  border: `1px solid ${state[value] ? "#76A9FA" : "#E5E7EB"}`,
                  borderRadius: "40px",
                  cursor: "pointer",
                  color: state[value] ? "#3F83F8" : "",
                  background: state[value] ? "#E1EFFE" : "",
                }}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
      </div>
      {state.FOLLOW && (
        <Card>
          <InputField
            label="Twitter handle to follow"
            placeholder="daolens"
            addonBefore="@"
            value={state.twitterHandle}
            onChange={(e) => {
              setState((prev) => ({ ...prev, twitterHandle: e.target.value }));
            }}
          />
        </Card>
      )}
      {(state.LIKE || state.REPLY || state.RETWEET) && (
        <Card>
          <InputField
            label="Tweet URL"
            placeholder="https://twitter.com/VitalikButerin/status/1609310102338543616"
            description="Post to like/reply to/retweet"
            value={state.tweetUrl}
            onChange={(e) => {
              setState((prev) => ({ ...prev, tweetUrl: e.target.value }));
            }}
          />
          {state.REPLY && (
            <InputField
              label="Default reply"
              type="textarea"
              placeholder="Gm frens! Checkout @daolens, they are building amazing DAO tools"
              description="Reply that we will prefill for the users"
              value={state.reply}
              onChange={(e) => {
                setState((prev) => ({ ...prev, reply: e.target.value }));
              }}
            />
          )}
        </Card>
      )}
      {state.TWEET && (
        <Card>
          <InputField
            label="Tweet that you want users to tweet"
            type="textarea"
            placeholder="Gm frens! folks at @daolens are the best"
            value={state.tweetMsge}
            onChange={(e) => {
              setState((prev) => ({ ...prev, tweetMsge: e.target.value }));
            }}
          />
        </Card>
      )}
      {state.JOIN_SPACE && (
        <Card>
          <InputField
            label="Twitter space link"
            placeholder="https://twitter.com/VitalikButerin/status/1609310102338543616"
            description="Space that you want users to join"
            value={state.spaceUrl}
            onChange={(e) => {
              setState((prev) => ({ ...prev, spaceUrl: e.target.value }));
            }}
          />
        </Card>
      )}
    </div>
  );
};

export default TwitterTask;

export const twitterValidation = (state, res) => {
  let twitterTasks = [];
  let atLeastOneOptionSelected = false;
  for (const { value, validation } of tabs) {
    if (state[value]) {
      atLeastOneOptionSelected = true;
      twitterTasks.push(validation(state));
    }
  }
  if (!atLeastOneOptionSelected) {
    toast.error("Please select atleast one action");
    throw new Error();
  }
  res.twitterTasks = JSON.stringify(twitterTasks);
};
export const twitterInitialState = (bountyDetails) => {
  let ans = {};
  bountyDetails.twitterTasks.forEach((task) => {
    ans[task.type] = true;
    if (task.twitterHandle) {
      ans.twitterHandle = getUsernameFromUrl(task.twitterHandle);
    }
    if (task.tweetUrl) {
      ans.tweetUrl = task.tweetUrl;
    }
    if (task.reply) {
      ans.reply = task.reply;
    }
    if (task.tweetMsge) {
      ans.tweetMsge = task.tweetMsge;
    }
    if (task.spaceUrl) {
      ans.spaceUrl = task.spaceUrl;
    }
  });

  return ans;
};

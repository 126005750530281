import React from "react";
import "../ComponentStyles/BountySubmissionsAccordionStyles.scss";
import { Collapse } from "antd";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import RedFlagIcon from "../../images/red-flag.svg";
import BlueFlagIcon from "../../images/blue-flag.svg";
import yellowlagIcon from "../../images/yellow-flag.svg";
import GreyFlagIcon from "../../images/grey-flag.svg";
import subtaskIcon from "../../assets/iconSvg/subtaskIcon.svg";
import calendarIcon from "../../assets/iconSvg/calendarIcon.svg";
import starIcon from "../../assets/iconSvg/starIcon.svg";
import moment from "moment/moment";

const { Panel } = Collapse;
const flags = {
  URGENT_PRIORITY: RedFlagIcon,
  HIGH_PRIORITY: BlueFlagIcon,
  MEDIUM_PRIORITY: yellowlagIcon,
  LOW_PRIORITY: GreyFlagIcon,
};

function SinglePanelHeader({ singleSubtask, onClick }) {
  let description =
    singleSubtask.bountyTitle.length > 30
      ? singleSubtask.bountyTitle.split(" ").slice(0, 4).join(" ") + " ..."
      : singleSubtask.bountyTitle;
  return (
    <div className="bounty-subtask__wrapper" onClick={onClick}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <img src={subtaskIcon} alt="" className="bounty-subtask__icon" /> */}
        <p className="bounty-subtask__description">{description}</p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {singleSubtask.bountyLastSubmissionDate && (
          <GreyBackgroundComponent>
            <img src={calendarIcon} alt="" />
            <span>
              {moment(singleSubtask.bountyLastSubmissionDate).format("DD MMM")}
            </span>
            <span>
              {moment(singleSubtask.bountyLastSubmissionDate).format("hh:mm A")}
            </span>
          </GreyBackgroundComponent>
        )}
        {singleSubtask.bountyPriority && (
          <GreyBackgroundComponent>
            <img src={flags[singleSubtask.bountyPriority]} alt="" />
          </GreyBackgroundComponent>
        )}
        <div className="bounty-subtask__icon bounty-subtask__icon--container">
          <img
            src={singleSubtask.profilePicture ? singleSubtask.profilePicture : dummyUser}
            alt=""
            className="bounty-subtask__icon bounty-subtask__icon--rounded"
          />
          <span className="bounty-subtask__icon--tooltip">
            {singleSubtask.userName}
          </span>
        </div>
      </div>
    </div>
  );
}
function BountySubtasksAccordion({
  subTasks,
  openViewBountySubtaskModal,
  navigate,
  daoId,
  bountyId,
}) {
  return (
    <div className="bounty-submission-container">
      {subTasks.map((eachSubtask, index) => {
        return (
          <SinglePanelHeader
            key={Math.random()}
            singleSubtask={eachSubtask}
            onClick={() => {
              // openViewBountySubtaskModal(index);
              navigate(
                `/app/dao/${daoId}/bounty/${bountyId}/subtask/${eachSubtask.bountyId}`
              );
            }}
          />
        );
      })}
    </div>
  );
}
function GreyBackgroundComponent({ children }) {
  return <div className="bounty-subtask__grey-background">{children}</div>;
}
export default BountySubtasksAccordion;

import { Button, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import customAxios from "../api";
import { Modal } from "../styles/ModalStyles";
import { checkFileSize, postMixpanel } from "../utils";
import addImage from "./../images/addImage.svg";
import mixpanel from "mixpanel-browser";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
const ModalBody = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  & .steps {
    height: 15px;
    font-weight: 600;
    font-size: 12px;
    color: #64748b;
  }

  & .title {
    width: 371px;
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    margin-top: 4px;
  }

  & .image {
    align-self: center;
    width: 100px;
    border-radius: 4rem;
    height: 100px;
    object-fit: cover;
    cursor: pointer;
    margin-top: 28px;
    &:hover {
      border: 1px solid #3b82f6;
    }
  }

  & .input {
    align-self: center;
    width: 100%;
    height: 42px;
    border-radius: 12px;
    margin-top: 16px;
  }

  & .continue-button {
    height: 36px;
    border-radius: 12px;
    margin-top: 28px;

    &:disabled {
      color: #fff;
      background: #b1cdfb;
    }
  }
`;

const CreateDAOScratch = ({ setCurrentStep, setUpdater }) => {
  const [daoName, setDaoName] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const inputImageRef = React.useRef(null);
  const [loading, setLoading] = useState(false);
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const navigate = useDaoLensNavigate();
  const handleCreateDao = async () => {
    const formData = new FormData();
    formData.append("name", daoName);
    formData.append("files", selectedFile);
    setLoading(true);
    const res = await customAxios.post("/dao/create", formData);
    if (res.data.success) {
      postMixpanel("track", "created_community_from_scratch", {
        dao_id: res.data.daoId,
        dao_name: daoName,
      });

      setCurrentStep(0);
      setUpdater((prev) => !prev);
      navigate(`/app/dao/${res.data.daoId}/overview`);
      setLoading(false);
      setSelectedFile({});
    }
  };

  return (
    <Modal
      centered
      destroyOnClose
      title={null}
      visible
      maskStyle={{
        background: "rgba(0, 0, 0, 0.5",
        backdropFilter: "blur(20px)",
      }}
      onCancel={() => setCurrentStep(0)}
      footer={null}
      width="460px"
      height="345px"
    >
      <ModalBody>
        <Typography className="title">
          What’s the name of your organisation?
        </Typography>

        <img
          className="image"
          src={`${selectedFile ? preview : addImage}`}
          alt="add_people"
          onClick={() => inputImageRef.current.click()}
        />

        <input
          ref={inputImageRef}
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onClick={() => {
            inputImageRef.current.value = null;
          }}
          onChange={(e) => {
            const newFile = e.target.files[0];
            checkFileSize(newFile.size);
            setSelectedFile(newFile);
          }}
        />
        <Input
          placeholder="Enter the name..."
          className="input"
          value={daoName}
          maxLength="15"
          onChange={(e) => setDaoName(e.target.value)}
        />
        <Button
          type="primary"
          className="continue-button"
          onClick={handleCreateDao}
          disabled={daoName.trim() === "" || loading}
        >
          Continue
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default CreateDAOScratch;

import { CheckCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import { poll } from "ethers/lib/utils";
import React from "react";
import { useContext } from "react";
import { DaoContext } from "../../../layouts/app/DaoContext";
import { UserContext } from "../../../layouts/app/UserContext";
import { getDefaultDaoImage } from "../../../utils";
import IconWrapper from "../../IconWrapper";
import { getOrdinal } from "../../Proposal/api";

function VotingResultsCard({
  votingResults,
  pollOptions,
  pollType,

  Icon,
}) {
  const { userDetails } = useContext(UserContext);
  const { daoDetails } = useContext(DaoContext);
  console.log("usr", userDetails, daoDetails);
  const getBackgroundPercentage = (optionIndex) => {
    return votingResults?.[optionIndex] ? votingResults[optionIndex] : 0;
  };
  const getIsVoted = (pollOption, userVotingResults, pollType) => {
    if (pollType === "approval") {
      return userVotingResults.includes(pollOption.id);
    }

    if (pollType === "single-choice" || pollType === "basic") {
      return userVotingResults === pollOption.id;
    } else if (pollType === "quadratic" || pollType === "weighted") {
      return userVotingResults[pollOption.id] ? true : false;
    } else {
      return true;
    }
  };
  const getVotedText = (pollType, optionIndex, pollOptionId) => {
    console.log("cotingRe", Number(votingResults[optionIndex]).toFixed(0));
    switch (pollType) {
      case "weighted":
        return !+votingResults[optionIndex]
          ? ""
          : votingResults[optionIndex] + "%";

      case "ranked-choice":
        return getOrdinal(+Number(votingResults[optionIndex]).toFixed(0));
      default:
        return !+votingResults[optionIndex]
          ? ""
          : votingResults[optionIndex] + "%";
    }
  };
  return (
    <div
      style={{
        display: "flex",
        gap: "8px",
        width: "100%",
        flexDirection: "column",
      }}
    >
      {pollOptions.map((pollOption, optionIndex) => {
        return (
          <div
            style={{
              height: "36px",
              width: "100%",
              border: "1px solid #E5E7EB",
              borderRadius: "16px",
              justifyContent: "center",
              alignItems: "center",
              // position: "relative",
              background:
                pollType === "ranked-choice"
                  ? "white"
                  : `linear-gradient(to right, #F3F4F6 ${getBackgroundPercentage(
                      optionIndex
                    )}%, white ${getBackgroundPercentage(optionIndex)}%)`,
              color: "#64748B",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 12px",
                zIndex: 2,
              }}
            >
              <h5
                style={{
                  marginBottom: "0px",
                  color: "#64748B",
                  fontSize: "12px",
                }}
              >
                {pollOption.name}{" "}
              </h5>
              {votingResults?.[optionIndex] ? (
                <div
                  style={{
                    display: "flex",
                    alignitems: "center",
                    gap: "4px",
                    width: "70px",
                    textAlign: "left",
                  }}
                >
                  {Icon}
                  <Tooltip
                    // title={"Average % of votes received"}
                    showArrow={false}
                    overlayInnerStyle={{
                      background: "white",
                      borderRadius: "12px",
                      // color: "#64748B",
                      color: "black",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    <h5
                      style={{
                        marginBottom: "0px",
                        color: "#3B82F6",
                      }}
                    >
                      {getVotedText(pollType, optionIndex, pollOption.id)}
                    </h5>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default VotingResultsCard;

import { Button, Select, Spin, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../../api";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import { Modal } from "../../../styles/ModalStyles";
import { ArrowRight } from "../../Icones";
import IconWrapper from "../../IconWrapper";
import { antIcon } from "../../ImportDiscord";
import addPeopleImg from "./../../../assets/images/sidebar/add_people.png";
import onboardingIntegrations from "./../../../assets/images/onboarding/integrations.png"
import settingsIntegrations from "./../../../assets/images/settings/integration.png"


const ModalBody = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  & .steps {
    height: 15px;
    font-weight: 600;
    font-size: 12px;
    color: #64748b;
  }

  & .title {
    width: 371px;
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    margin-top: 4px;
  }

  & .image {
    align-self: center;
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-top: 28px;
    border-radius: 4rem;
  }

  & .select {
    .ant-select-selector {
      border-radius: 12px !important;
    }
    margin-top: 16px;
  }

  & .text-button {
    align-self: start;
    color: #64748b;
    padding: 0;
    margin-top: 12px;
  }

  & .continue-button {
    height: 36px;
    border-radius: 12px;
    margin-top: 24px;

    &:disabled {
      color: #fff;
      background: #b1cdfb;
    }
  }
`;

function DiscordIntegrationModal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { daoId, from, ...rest } = JSON.parse(atob(searchParams.get("state")));

  const code = searchParams.get("code");
  const [daoName, setDaoName] = useState("");
  const [selectedState, setSelectedState] = useState({});
  const [guilds, setGuilds] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useDaoLensNavigate();
  const [isBotInstalled, setIsBotInstalled] = useState(false);
  const interval = useRef();
  const addingBot = useRef(false);

  useEffect(() => {
    interval.current = setInterval(() => {
      axios
        .get("https://onboarding-bot-discord-5dzspnb6za-uc.a.run.app/guilds")
        .then(async (res) => {
          if (
            res.data.find((server) => {
              return server.name === daoName;
            })
          ) {
            setIsBotInstalled(true);
            if (addingBot.current) {
              addingBot.current = false;
              await handleSubmitDao({ selectedState, daoName });
            } else {
              setLoading(false);
            }
          } else {
            setIsBotInstalled(false);
          }
          if (!addingBot.current) {
            setLoading(false);
          }
        });
    }, [5000]);
    return () => {
      clearInterval(interval.current);
    };
  }, [daoName]);
  useEffect(() => {
    if (daoName !== "") {
      setSelectedState(guilds.filter((item) => item.name === daoName)[0]);
    }
    addingBot.current = false;
    setLoading(true);
  }, [daoName]);

  useEffect(() => {
    if (code) {
      (async () => {
        const { data } = await customAxios.get(
          `/settings/import/discord?code=${code}`
        );
        setGuilds(data.guilds);
        if (data.guilds.length) {
          setDaoName(data?.guilds[0]?.name);
        }
      })();
    }
  }, [code]);
  const handleSubmitDao = async ({ selectedState, daoName }) => {
    setLoading(true);
    const res = await customAxios
      .post("settings/integration/discord", {
        discordGuildId: selectedState.id,
        daoId: daoId,
      })
      .catch((err) => {
        setLoading(false);

        toast.error("Please install DaoLens bot in the selected server");
      });
    if (res.data.isDiscordGuildIDExist) {
      toast.error(
        <div>
          Discord server already connected to{" "}
          <span
            style={{
              color: "blue",
              fontWeight: "500",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate(`/app/dao/${res.data.isDiscordGuildIDExist.id}/overview`)
            }
          >
            {res.data.isDiscordGuildIDExist.name}
          </span>
        </div>
      );
    } else {
      setLoading(false);
      navigation();
    }
  };
  const navigation = () => {
    if (from === "onboarding") {
      navigate(`/app/onboarding?fromDiscord=true&daoId=${daoId}&type=connect`);
    } else if (from) {
      navigate(from, { state: rest });
    } else {
      navigate(`/app/dao/${daoId}/settings/integration`);
    }
  };
  return (
    <Modal
      centered
      destroyOnClose
      title={null}
      visible
      onCancel={() => {
        navigation();
      }}
      footer={null}
      width="460px"
      maskStyle={{
        background: from === "onboarding" ? `url(${onboardingIntegrations})` : `url(${settingsIntegrations})`,
        backdropFilter: "blur(30px)",
        backgroundSize: "100%",
        filter: "blur(9px)"
      }}
      height="345px"
    >
      <ModalBody>
        <Typography className="title">
          What’s the name of your organisation?
        </Typography>
        <img
          className="image"
          src={`${daoName !== "" && selectedState.icon
            ? `https://cdn.discordapp.com/icons/${selectedState.id}/${selectedState.icon}.png`
            : addPeopleImg
            }`}
          alt="add_people"
        />
        <Select
          className="select"
          value={daoName}
          onChange={(value) => setDaoName(value)}
        >
          {guilds.map((guild) => (
            <Select.Option value={guild.name}>{guild.name}</Select.Option>
          ))}
        </Select>
        {loading ? (
          <Spin indicator={antIcon} size="large" />
        ) : isBotInstalled ? (
          <Button
            type="primary"
            className="continue-button"
            onClick={() => handleSubmitDao({ selectedState, daoName })}
            disabled={daoName.trim() === "" || loading}
          >
            Continue
          </Button>
        ) : (
          <Button
            type="primary"
            className="continue-button"
            onClick={() => {
              window.open(
                `https://discord.com/oauth2/authorize?client_id=967219648955301899&guild_id=${
                  guilds?.find((ele) => ele?.name === daoName)?.id
                }&permissions=8&scope=bot%20applications.commands`
              );
              addingBot.current = true;
              setLoading(true);
            }}
            disabled={daoName.trim() === ""}
          >
            + Add Bot and Continue
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
}

export default DiscordIntegrationModal;

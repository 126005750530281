import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import React, { useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import { AnnouncementType } from "../../../../utils/Announcements";
import { useNavigate, useParams } from "react-router";
import { OPEN_OLD_ANNOUNCEMENTS_MODAL } from "../../../../constants";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../../../hooks/useDaoLensNavigate";

type Props = {
  announcement: AnnouncementType;
  canEdit: boolean;
  isOld: boolean;
};

const StyledParagraph = styled.p`
  p {
    margin: 0 !important;
  }
`;

const StyledButton = styled.button`
  &:hover {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  p.rich-text {
    margin: 0 !important;
  }
`;

const getTimeToExpiry = (expiryDuration: string) => {
  const hoursToExpire = moment(expiryDuration).diff(moment(), "hours");

  if (hoursToExpire > 24)
    return (
      `Expires in ${(hoursToExpire / 24).toFixed()} day` +
      (hoursToExpire >= 48 ? "s" : "")
    );
  return `Expires in ${hoursToExpire} hour` + (hoursToExpire > 1 ? "s" : "");
};

const AnnouncementCard = ({ announcement, canEdit, isOld }: Props) => {
  const contentContainerRef = useRef<HTMLDivElement>(null);
  const isNew = useMemo(
    () => moment().diff(moment(announcement.createdAt), "day") <= 1,
    [announcement.createdAt]
  );

  const navigate = useDaoLensNavigate();
  const { daoDetails, daoId, daoName } = useDaoContext();

  useEffect(() => {
    if (!contentContainerRef?.current) return;
    const container = contentContainerRef.current;
    console.log("height", container.scrollHeight, container.clientHeight);
  }, []);
  return (
    <StyledButton
      style={{
        display: "flex",
        flexDirection: "column",
        background: "white",
        borderRadius: "12px",
        height: "100%",
        border: "1px solid #e5e7eb",
        padding: "0",
        width: "100%",
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => {
        if (isOld) window[OPEN_OLD_ANNOUNCEMENTS_MODAL] = true;
        navigate(
          `/app/dao/${daoName}/${daoId}/overview/announcements/${announcement.id}`
        );
      }}
    >
      {isNew && (
        <span
          style={{
            position: "absolute",
            top: 8,
            left: 8,
            background: "#EDE9FE",
            color: "#4C1D95",
            padding: "2px 6px",
            fontSize: "10px",
            borderRadius: "6px",
          }}
        >
          New
        </span>
      )}
      <img
        src={announcement.icon || ""}
        alt={announcement.title}
        style={{
          objectFit: "cover",
          height: "90px",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          width: "100%",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          gap: "16px",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
          ref={contentContainerRef}
          className="annoucement-card-overview-page"
        >
          <h3
            style={{
              textAlign: "left",
              margin: 0,
              color: "#111827",
              fontWeight: "600",
              fontSize: "14px",
            }}
            className="annoucement-card-overview-page"
          >
            {announcement.title}
          </h3>
          <StyledParagraph
            style={{
              margin: 0,
              color: "#6B7280",
              fontSize: "14px",
              textAlign: "left",
              lineHeight: "16px",
              overflow: "hidden",
            }}
            className="rich-text annoucement-card-overview-page"
          >
            {ReactHtmlParser(announcement.description)}
          </StyledParagraph>
        </div>
        <p
          style={{
            fontSize: "12px",
            margin: "0",
            marginTop: "auto",
            textAlign: "right",
            color: "#8B5CF6",
            width: "100%",
          }}
        >
          {canEdit
            ? getTimeToExpiry(announcement?.expiry as string)
            : moment(announcement.createdAt).format("D MMM")}
        </p>
      </div>
    </StyledButton>
  );
};

export default AnnouncementCard;

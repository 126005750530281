import { SearchOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import MobilePageTopNavbar from "../../components/Common/MobilePageTopNavbar";
import EventsFeed from "../../components/EventComponents/EventsFeed";
import CreateEventsModal from "../../components/Events/CreateEventsModal";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import useWindowSize from "../../hooks/useWindowSize";
import { useDaoContext } from "../../layouts/app/DaoContext";
const StyledInput = styled(Input)`
  .ant-input {
    font-weight: 500;
  }
`;

function DaoEvents() {
  const { isMobile } = useWindowSize();
  const { isSpace } = useDaoContext();
  useTrackPageView();
  return (
    <>
      {(isMobile || isSpace) && <div className="mt-5">

      <MobilePageTopNavbar />
      </div>
      }
      {(!isSpace || isMobile) && <div style={{ height: 20 }} />}
      <div
        style={{
          paddingLeft: isMobile ? "15px" : "0",
        }}
      >
        <EventsFeed />
      </div>
    </>
  );
}

export default DaoEvents;

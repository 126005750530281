import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import PostDetails from "./PostDetails";
import customAxios from "../api";
import { useInterval } from "../hooks/useInterval";
import { POLLING_REFRESH_INTERVAL } from "../constants";
import { toast } from "react-toastify";
import { Skeleton } from "antd";
import useWindowSize from "../hooks/useWindowSize";
import { useDaoContext } from "../layouts/app/DaoContext";
import { poll } from "ethers/lib/utils";
import { getPercentage } from "../utils";

const PostDetailsPage = () => {
  const { isMobile } = useWindowSize();
  const [postDetails, setPostDetails] = useState(null);
  const [isPollingPaused, setIsPollingPaused] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(undefined);
  const [votingResults, setVotingResults] = useState([]);
  const [votersList, setVotersList] = useState([]);
  const [userVotingResults, setUserVotingResults] = useState(null);
  const { postId, channelId } = useParams();
  const { daoId } = useDaoContext();
  const fetchPostDetailsService = (id, cb = () => {}) => {
    customAxios
      .get(
        `post/fetch/details?postId=${id}&&daoId=${daoId}&&channelId=${channelId}`
      )
      .then((res) => {
        let data = [];

        data = res.data.postDetails.map((post) => {
          let obj = post.vote.find(
            (votedPost) => votedPost.postId === post.postId
          );
          if (obj !== undefined) {
            return {
              ...post,
              postEngagementStatus: obj.type?.toLowerCase(),
            };
          }
          if (post.polls && !post?.choicesSelected) {
            if (post.polls && !selectedChoice) {
              if (
                post.pollType === "approval" ||
                post.pollType === "ranked-choice"
              ) {
                setSelectedChoice([]);
              } else if (
                post.pollType === "quadratic" ||
                post.pollType === "weighted"
              ) {
                setSelectedChoice({});
              }
            }
          } else {
            setUserVotingResults(post?.choicesSelected);
            setSelectedChoice(post?.choicesSelected);
          }
          console.log("ss", selectedChoice, post.choicesSelected);
          return post;
        });

        console.log("lo", data);
        setPostDetails(data);
        if (data[0].polls) fetchPollResults(data[0]);
      })
      .catch((err) => {
        if (
          err?.response?.data?.error?.stack.includes(
            "Error: UnAuthorized Access"
          )
        )
          toast.error(
            "You don't have access to the workspace. Contact workspace owner to get started."
          );
        else console.log("error");
      })
      .finally(() => {
        cb();
      });
  };
  useEffect(() => {
    if (!daoId) return;
    setLoading(true);
    fetchPostDetailsService(postId, () => {
      setLoading(false);
    });
  }, [updater, postId, daoId]);
  // useInterval(() => fetchPostDetailsService(postId), POLLING_REFRESH_INTERVAL);
  //   const voter=(results)=>{
  //     const postDetails
  // if()
  //   }
  console.log("user", userVotingResults);
  const calculateAverageWeightOfOption = (
    votingResulstArr,
    pollType,
    pollOptionId
  ) => {
    let result = {};
    switch (pollType) {
      case "ranked-choice":
        result = Number(
          votingResulstArr?.reduce((total, curr) => {
            return (
              curr?.choicesSelected?.findIndex((ele) => ele === pollOptionId) +
              1 +
              total
            );
          }, 0)
        ).toFixed(2);
        return result;
      case "weighted":
        result = Number(
          votingResulstArr?.reduce((total, curr) => {
            return curr?.choicesSelected?.[pollOptionId]
              ? curr?.choicesSelected[pollOptionId] + total
              : total;
          }, 0)
        ).toFixed(2);
        return result;

      case "quadratic":
        result = Number(
          votingResulstArr?.reduce((total, curr) => {
            return curr?.choicesSelected?.[pollOptionId]
              ? curr?.choicesSelected[pollOptionId] + total
              : total;
          }, 0)
        ).toFixed(2);
        return result;
      default:
        result = Number(
          votingResulstArr?.reduce((total, curr) => {
            return curr?.choicesSelected?.includes(pollOptionId)
              ? 1 + total
              : total;
          }, 0)
        ).toFixed(2);
        return result;
    }
  };
  const calculateVotedPercentage = (resultsArr, pollType, pollOptions) => {
    let averageWeightForOption;
    let total;

    averageWeightForOption = pollOptions.map((ele) => {
      return calculateAverageWeightOfOption(resultsArr, pollType, ele.id);
    });
    if (pollType === "ranked-choice") {
      return averageWeightForOption;
    }
    total = averageWeightForOption?.reduce(
      (total, curr) => Number(curr) + total,
      0
    );
    return averageWeightForOption.map((ele) =>
      ele ? getPercentage(ele, total) : 0
    );
  };
  const fetchPollResults = (post) => {
    customAxios
      .get(`/postpoll/fetch?postId=${post.postId}`)
      .then((res) => {
        console.log("dsd", res.data.res);
        setVotersList([...res.data.res]);
        const resultsArr = calculateVotedPercentage(
          res.data.res,
          post?.pollType,
          post.polls
        );
        if (post.choicesSelected)
          setUserVotingResults(
            calculateVotedPercentage(
              [{ choicesSelected: post.choicesSelected }],
              post?.pollType,
              post.polls
            )
          );
        setVotingResults(resultsArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useInterval(() => {
    if (!isPollingPaused) {
      fetchPostDetailsService(postId);
    }
  }, POLLING_REFRESH_INTERVAL);

  return (
    <div style={{ paddingLeft: isMobile ? "15px" : "0" }}>
      <div style={{ height: 20 }} />
      {loading && <Skeleton active />}
      {!loading && postDetails && (
        <PostDetails
          setUpdater={setUpdater}
          postDetails={postDetails}
          setPostDetails={setPostDetails}
          name={postDetails?.postName}
          description={postDetails?.postDescription}
          postId={postId}
          daoId={daoId}
          votersList={votersList}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
          votingResults={votingResults}
          fetchPostDetailsService={() => fetchPostDetailsService(postId)}
          isPollingPaused={isPollingPaused}
          userVotingResults={userVotingResults}
          setIsPollingPaused={setIsPollingPaused}
        />
      )}
    </div>
  );
};

export default PostDetailsPage;

import cover from "../../../assets/images/profile/cover.png";
import task from "../../../assets/images/profile/task.png";
import taskpoint from "../../../assets/images/profile/taskpoint.png";
import earn from "../../../assets/images/profile/earn.png";
import NoDataIcon from "../../../assets/images/profile/Ellipse 70.svg";
import { PencilIcon, WalletIcon } from "@heroicons/react/24/solid";
import ReactHtmlParser from "react-html-parser";
import { Button, Skeleton, Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import EditProfileModal from "./EditProfileModal";
import DiscordIcon from "../../../assets/images/profile/Subtract.svg";
import snapshotIcon from "../../../assets/images/profile/Rectangle 237.svg";
import customAxios from "../../../api";
import "../../ComponentStyles/BountyFeed.scss";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useParams, useSearchParams } from "react-router-dom";
import { UserContext } from "../../../layouts/app/UserContext";
import { toast } from "react-toastify";
import { DaoContext, useDaoContext } from "../../../layouts/app/DaoContext";
import useWindowSize from "../../../hooks/useWindowSize";
import EditSpaceProfile from "../../Spaces/EditSpaceProfile";
import { getDefaultDaoImage } from "../../../utils";
export const iconStyling = {
  borderRadius: "2px",
  color: "white",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const DaoProfile = () => {
  const [editProfile, setEditProfile] = useState(false);
  // const [daoData, setDaoData] = useState([]);
  const { isMobile } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const { userId } = useParams();
  const { daoDetails, daoId, isSpace } = useDaoContext();

  const { reload } = useContext(UserContext);

  return (
    <div>
      {editProfile && (
        <EditProfileModal
          isModalVisible={editProfile}
          handleClose={() => {
            setEditProfile(false);
          }}
          onSubmit={() => {
            {
              reload();
            }
          }}
        />
      )}
      <Col
        xs={24}
        style={{
          padding: "0px",
          marginTop: "20px",
          overflowY: "auto",
          height: "100vh",
          position: "relative",
          borderRadius: "16px",
        }}
      >
        {isMobile ? null : (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #F4F2F2",
              borderRadius: "16px",
              padding: "12px 20px",
              background: "white",
              marginBottom: "12px",
              minHeight: "58px",
              zIndex: 1,
              position: "sticky",
              top: 0,
              width: "100%",
            }}
          >
            <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>Profile</h4>
          </Row>
        )}
        {isSpace && <EditSpaceProfile />}
        {!isSpace && (
          <>
            <Col
              xs={24}
              style={{
                border: "1px solid #F4F2F2",
                borderRadius: "16px",
                background: "white",
                padding: "0px",
              }}
            >
              <>
                {daoDetails && (
                  <>
                    <header
                      style={{
                        background: "#FFFFFF",
                        borderRadius: "16px 16px 12px 12px",
                        position: "relative",
                        minHeight: "250px",
                      }}
                    >
                      <img
                        src={
                          daoDetails?.backgroundImage
                            ? daoDetails?.backgroundImage
                            : cover
                        }
                        style={{
                          height: "180px",
                          width: "100%",
                          borderRadius: "16px 16px 0 0",
                          objectFit: "cover",
                        }}
                        alt="cover image"
                      />
                      {daoDetails?.profilePicture ? (
                        <img
                          src={daoDetails?.profilePicture}
                          alt="profile"
                          style={{
                            width: "120px",
                            height: "120px",
                            filter:
                              "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow( 0px 4px 6px rgba(0, 0, 0, 0.05))",
                            position: "absolute",
                            left: "32px",
                            top: "142px",
                            borderRadius: "50%",
                            border: "2px solid white",
                            objectFit: "cover",
                            background: "white",
                          }}
                        />
                      ) : (
                        getDefaultDaoImage(
                          daoDetails?.daoName,
                          {
                            alignSelf: "center",
                            width: "120px",
                            height: "120px",
                            filter:
                              "drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.1)) drop-shadow( 0px 4px 6px rgba(0, 0, 0, 0.05))",
                            position: "absolute",
                            left: "32px",
                            top: "142px",

                            borderRadius: "50%",
                            border: "2px solid white",
                          },
                          { fontSize: "28px" }
                        )
                      )}

                      {!userId && (
                        <Button
                          onClick={() => {
                            reload();
                            setEditProfile(true);
                          }}
                          style={{
                            height: "32px",
                            position: "absolute",
                            top: "12px",
                            right: "12px",
                            borderRadius: "8px",
                            borderColor: "#E2E8F0",
                            display: "flex",
                            color: "#64748B",
                            alignItems: "center",
                            gap: "6px",
                          }}
                        >
                          <PencilIcon width="14px" /> Edit Profile
                        </Button>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          marginLeft: "168px",
                        }}
                      >
                        <div style={{ marginTop: "10px" }}>
                          <h1 style={{ fontWeight: "bold", margin: 0 }}>
                            {daoDetails?.daoName}
                          </h1>
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "4px",
                              height: "24px",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            {daoDetails?.twitterHandle ? (
                              <a
                                href={daoDetails?.twitterHandle}
                                target="_blank"
                              >
                                <i
                                  class="fa-brands fa-twitter fa-regular"
                                  style={{
                                    ...iconStyling,
                                    background: "#629DFF",
                                  }}
                                ></i>
                              </a>
                            ) : null}

                            {daoDetails?.discordUrl && (
                              <a href={daoDetails?.discordUrl} target="_blank">
                                <img
                                  src={DiscordIcon}
                                  alt="discord icon"
                                  style={{ ...iconStyling }}
                                />
                              </a>
                            )}
                            {daoDetails?.github ? (
                              <a href={daoDetails?.github} target="_blank">
                                {" "}
                                <i
                                  class="fa-brands fa-github fa-regular"
                                  style={{
                                    ...iconStyling,
                                    background: "black",
                                  }}
                                ></i>
                              </a>
                            ) : null}

                            {daoDetails?.website ? (
                              <a href={daoDetails?.website} target="_blank">
                                {" "}
                                <i
                                  class=" fa-globe fa-solid"
                                  style={{ ...iconStyling, background: "red" }}
                                ></i>
                              </a>
                            ) : null}
                            {daoDetails?.discordGuildId ? (
                              <div
                                style={{
                                  color: "#64748B",
                                  font: "normal 500 12px/16px Inter",
                                  display: "flex",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  if (loading) {
                                    return;
                                  }
                                  setLoading(true);
                                  customAxios
                                    .post("/settings/integration/discord", {
                                      daoId,
                                      discordGuildId:
                                        daoDetails?.discordGuildId,
                                    })
                                    .catch(() => {
                                      console.log("error");
                                    })
                                    .finally(() => {
                                      setLoading(false);
                                    });
                                }}
                              >
                                <svg
                                  style={{
                                    marginRight: "4px",
                                    animation: loading
                                      ? "rotation 2s infinite linear"
                                      : "",
                                  }}
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.66675 2.66654V5.99987H3.05475M13.2921 7.3332C13.1446 6.16178 12.6127 5.07238 11.7797 4.23572C10.9466 3.39907 9.85951 2.86245 8.68873 2.70996C7.51795 2.55748 6.32967 2.79775 5.3101 3.39311C4.29054 3.98848 3.49731 4.90529 3.05475 5.99987M3.05475 5.99987H6.00008M13.3334 13.3332V9.99987H12.9461M12.9461 9.99987C12.5029 11.0938 11.7094 12.0099 10.69 12.6047C9.67051 13.1996 8.48257 13.4395 7.31215 13.2871C6.14172 13.1347 5.05488 12.5985 4.22177 11.7624C3.38866 10.9263 2.85632 9.8375 2.70808 8.66654M12.9461 9.99987H10.0001"
                                    stroke="#64748B"
                                    stroke-width="1.33333"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>

                                {loading ? "Syncing" : "Sync roles"}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </header>
                    <aside
                      style={{
                        width: "312px",
                        display: "inline-flex",
                        flexDirection: "column",
                        gap: "12px",
                        margin: "0",
                      }}
                    ></aside>
                  </>
                )}
              </>
            </Col>
            <Row
              style={{
                display: "flex",
                flexDirection: "column",
                //   justifyContent: "space-between",
                alignItems: "flex-start",
                border: "1px solid #F4F2F2",
                borderRadius: "16px",
                padding: "16px 20px",
                background: "white",
                marginBottom: "12px",
                marginTop: "12px",
              }}
            >
              <h4
                style={{
                  fontWeight: "600",
                  marginBottom: "0px",
                  fontSize: "16px",
                }}
              >
                About
              </h4>
              {daoDetails?.description && (
                <span
                  style={{
                    marginTop: "10px",
                    color: "#0F172A",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {ReactHtmlParser(daoDetails?.description)}
                </span>
              )}
            </Row>
          </>
        )}
      </Col>
      {!daoDetails && (
        <div style={{ width: "960px" }}>
          <Skeleton active />
        </div>
      )}
    </div>
  );
};

export default DaoProfile;

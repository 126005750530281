import React, { useEffect, useState } from "react";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import OldAnnouncementsModal from "./OldAnnouncementsModal";
import styled from "styled-components";
import { AnnouncementType } from "../../../../utils/Announcements";
import { postMixpanel } from "../../../../utils";
import { useParams } from "react-router";
import { OPEN_OLD_ANNOUNCEMENTS_MODAL } from "../../../../constants";
import { useDaoContext } from "../../../../layouts/app/DaoContext";

const StyledButton = styled.button`
  &:hover {
    text-decoration: underline !important;
  }
`;

type Props = {
  isLoading: boolean;
  data: AnnouncementType[];
  isMinimal?: boolean;
};
const OldAnnouncementButton = ({ data, isLoading, isMinimal }: Props) => {
  const { daoDetails, daoId } = useDaoContext();

  const [isOldAnnouncementsModalOpen, setIsOldAnnouncementsModalOpen] =
    useState(false);
  useEffect(() => {
    if (window[OPEN_OLD_ANNOUNCEMENTS_MODAL])
      setIsOldAnnouncementsModalOpen(true);
    window[OPEN_OLD_ANNOUNCEMENTS_MODAL] = false;
  }, []);

  return (
    <>
      <OldAnnouncementsModal
        data={data}
        isLoading={isLoading}
        isOpen={isOldAnnouncementsModalOpen}
        setIsOpen={setIsOldAnnouncementsModalOpen}
      />
      <StyledButton
        style={{
          background: "none",
          display: "flex",
          alignItems: "center",
          color: "#6B7280",
          border: "none",
          fontSize: "14px",
          gap: "10px",
          cursor: "pointer",
        }}
        onClick={() => {
          postMixpanel("track", "clicked_view_announcement_history_button", {
            dao_id: daoId,
          });
          setIsOldAnnouncementsModalOpen(true);
        }}
      >
        <ArchiveBoxIcon width={isMinimal ? 24 : 16} strokeWidth={2} />
        {!isMinimal && <>View history</>}
      </StyledButton>
    </>
  );
};

export default OldAnnouncementButton;

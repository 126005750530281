import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
  ArrowsUpDownIcon,
} from "@heroicons/react/24/solid";
import { Button, Card, Radio } from "antd";
import React from "react";
import styled from "styled-components";
import { PopupCard } from "../styles/PopupCard";
import IconWrapper from "./IconWrapper";
import { useOnClickOutside } from "../hooks/useOnClickOutside";

export const SortButton = styled(Button)`
  border: 1px solid #e2e8f0;
  border-radius: 20px;
  font-weight: 500;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
`;

const SORTING_OPTIONS = [
  { value: "creationDate", displayValue: "Creation date" },
  { value: "amount", displayValue: "Bounty amount" },
  { value: "priority", displayValue: "Priority" },
  { value: "storyPoint", displayValue: "Story point" },
  { value: "dueDate", displayValue: "Due date" },
];

const SortFilter = ({
  sortingOptions = SORTING_OPTIONS,
  selectedSortFilter,
  setSelectedSortFilter,
  sortOrder,
  setSortOrder,
  setApplyFilter,
}) => {
  const [showCard, setShowCard] = React.useState(false);
  const ref = React.useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setShowCard(false));

  const onChangeRadioHandler = (optionObj) => {
    setSelectedSortFilter(optionObj);
    // setShowCard(false);
    setApplyFilter(true);
  };

  const orderToggleHandler = () => {
    setSortOrder(!sortOrder);
    // setShowCard(false);
    setApplyFilter(true);
  };

  return (
    <PopupCard cardPadding="0px" width="275px" bottom="unset" margin="2px 0px">
      <SortButton onClick={() => setShowCard(true)}>
        <IconWrapper>
          {selectedSortFilter?.displayValue ? (
            sortOrder ? (
              <ArrowSmallDownIcon />
            ) : (
              <ArrowSmallUpIcon />
            )
          ) : (
            <ArrowsUpDownIcon />
          )}
        </IconWrapper>
        {selectedSortFilter?.displayValue
          ? `${selectedSortFilter.displayValue}`
          : `Sort`}
      </SortButton>
      {showCard && (
        <Card
          ref={ref}
          className="card"
          title="Sort By"
          extra={
            sortOrder ? (
              <Button size="small" type="text" onClick={orderToggleHandler}>
                <IconWrapper>
                  <ArrowSmallDownIcon />
                </IconWrapper>
                Desending
              </Button>
            ) : (
              <Button size="small" type="text" onClick={orderToggleHandler}>
                <IconWrapper>
                  <ArrowSmallUpIcon />
                </IconWrapper>
                Ascending
              </Button>
            )
          }
        >
          <RadioGroup direction="vertical">
            {sortingOptions.map((option) => (
              <Radio
                key={option.value}
                checked={
                  selectedSortFilter?.displayValue === option.displayValue
                }
                onChange={() => onChangeRadioHandler(option)}
              >
                {option.displayValue}
              </Radio>
            ))}
          </RadioGroup>
        </Card>
      )}
    </PopupCard>
  );
};

export default SortFilter;

import type { AccountState, WalletSelector } from "@near-wallet-selector/core";
import { setupWalletSelector } from "@near-wallet-selector/core";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import type { WalletSelectorModal } from "@near-wallet-selector/modal-ui";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupNearWallet } from "@near-wallet-selector/near-wallet";
import { setupSender } from "@near-wallet-selector/sender";
import type { ReactNode } from "react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { distinctUntilChanged, map } from "rxjs";

import { setupNeth } from "@near-wallet-selector/neth";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { Skeleton } from "antd";

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}

interface WalletSelectorContextValue {
  selector: WalletSelector;
  modal: WalletSelectorModal;
  accounts: Array<AccountState>;
  accountId: string | null;
  walletId?: string | null;
  signOut: () => void;
}

export const NearWalletSelectorContext =
  React.createContext<WalletSelectorContextValue | null>(null);

export const NearWalletSelectorContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);
  const state = selector?.store.getState();

  const signOut = async () => {
    const state = selector?.store.getState();
    if (state?.selectedWalletId) {
        const wallet = await selector?.wallet(state.selectedWalletId);
        if (wallet) {
            await wallet.signOut();
        }
        setAccounts([])
    }

}


  const init = useCallback(async () => {
    const _selector = await setupWalletSelector({
      network: "mainnet",
      debug: true,
      modules: [
        // setupLedger(),
        setupNearWallet(),
        setupSender(),
        setupMyNearWallet(),
        // setupMathWallet(),
        // setupNightly(),
        // setupMeteorWallet(),
        // setupNearSnap(),
        // setupNarwallets(),
        // setupWelldoneWallet(),
        // setupHereWallet(),
        // setupCoin98Wallet(),
        // setupNearFi(),
        setupNeth({
          gas: "300000000000000",
          bundle: false,
        }),
        // setupOptoWallet(),
        // setupFinerWallet(),
        // setupXDEFI(),
        // setupWalletConnect({
        //   projectId: "c4f79cc...",
        //   metadata: {
        //     name: "NEAR Wallet Selector",
        //     description: "Example dApp used by NEAR Wallet Selector",
        //     url: "https://github.com/near/wallet-selector",
        //     icons: ["https://avatars.githubusercontent.com/u/37784886"],
        //   },
        // }),
        // setupNightlyConnect({
        //   url: "wss://relay.nightly.app/app",
        //   appMetadata: {
        //     additionalInfo: "",
        //     application: "NEAR Wallet Selector",
        //     description: "Example dApp used by NEAR Wallet Selector",
        //     icon: "https://near.org/wp-content/uploads/2020/09/cropped-favicon-192x192.png",
        //   },
        // }),
      ],
    });
    const _modal = setupModal(_selector, {
      contractId: "a1caf1224f5062204e2ea897460e7ccecdf17b55503cea7ad978374993dfcfa9",
    });
    window.selector = _selector;
    window.modal = _modal;

    setSelector(_selector);
    setModal(_modal);
    let _state = _selector?.store.getState();
    if (_state.selectedWalletId) {
      const wallet = await selector?.wallet(_state.selectedWalletId)
      if (wallet?.type === "injected"){ await wallet.signOut();setAccounts([])}
    }
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
      alert("Failed to initialise wallet selector");
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state) => state.accounts),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        setAccounts(nextAccounts);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [selector, modal]);

  if (!selector || !modal) {
    return <Skeleton active />;
  }

  const accountId =
    accounts.find((account) => account.active)?.accountId || null;


  return (
    <NearWalletSelectorContext.Provider
      value={{
        selector,
        modal,
        accounts,
        accountId,
        walletId: state?.selectedWalletId,
        signOut
      }}
    >
      {children}
    </NearWalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(NearWalletSelectorContext);

  if (!context) {
    throw new Error(
      "useWalletSelector must be used within a WalletSelectorContextProvider"
    );
  }

  return context;
}

import { useEffect, useState } from "react";
import customAxios from "../api";

const useDisourse = (daoId: string) => {
  const [status, setStatus] = useState("LOADING");
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (!daoId) return;
    customAxios.post("discourse/status", { daoId }).then((res) => {
      setStatus(res.data.status);
    });
  }, [daoId, update]);

  return {
    status,
    refetchStatus: () => {
      setUpdate((prev) => !prev);
    },
  };
};
export default useDisourse;

import { HoverCard } from "../../../Search/SearchModal";
import GatingConditionPopover from "../../GatingConditionPopover";

const Weighted = ({
  choices,
  selectedChoice,
  setSelectedChoice,
  canVote,
  gatingConditions,
}) => {
  const totalVote = selectedChoice
    ? Object.values(selectedChoice).reduce((prev, curr) => prev + curr, 0)
    : 0;
  console.log("total", totalVote, selectedChoice);
  return (
    <>
      {choices.map((choice) => {
        return (
          <div
            style={{
              border: "1px solid",
              borderRadius: "16px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "12px",
              font: "normal 500 12px/167% Inter",
              color: "#64748B",
              height: "36px",

              borderColor: "#F4F2F2",
              position: "relative",
              paddingLeft: "12px",
            }}
          >
            {choice.name}
            {!canVote ? (
              <GatingConditionPopover
                gatingConditions={gatingConditions}
              ></GatingConditionPopover>
            ) : (
              <div
                style={{ display: "flex", gap: "12px", alignItems: "center" }}
              >
                <HoverCard
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    color: "#64748B",

                    display: "inline-flex",
                    alignItems: "center",
                    border: "1px solid #E2E8F0",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setSelectedChoice((prev) => {
                      if (!(choice.id in prev)) {
                        return prev;
                      }
                      if (prev[choice.id] > 1) {
                        return { ...prev, [choice.id]: prev[choice.id] - 1 };
                      }
                      delete prev[choice.id];
                      return { ...prev };
                    });
                  }}
                >
                  -
                </HoverCard>
                <span style={{ minWidth: "15px" }}>
                  {selectedChoice && choice.id in selectedChoice
                    ? selectedChoice[choice.id]
                    : 0}
                </span>
                <HoverCard
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    display: "inline-flex",
                    color: "#64748B",

                    alignItems: "center",
                    border: "1px solid #E2E8F0",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setSelectedChoice((prev) => ({
                      ...prev,
                      [choice.id]: choice.id in prev ? prev[choice.id] + 1 : 1,
                    }));
                  }}
                >
                  +
                </HoverCard>

                <div
                  style={{
                    width: "50px",
                    marginLeft: "auto",
                  }}
                >
                  {
                    +(
                      ((selectedChoice && choice?.id in selectedChoice
                        ? selectedChoice[choice.id]
                        : 0) *
                        100) /
                      (totalVote ? totalVote : 1)
                    ).toFixed(2)
                  }
                  %
                </div>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Weighted;

import React from "react";
import "./InternalComponentStyles/SquareCapsule.scss";
function SquareCapsule({ textContent, icon, clickHandler }) {
  return (
    <div
      className={
        clickHandler
          ? "square-capsule__wrapper square-capsule__clickable"
          : "square-capsule__wrapper"
      }
      onClick={clickHandler}
    >
      {icon ? <img src={icon} className="square-capsule__icon" alt="" /> : null}
      <span className="square-capsule__text">{textContent}</span>
    </div>
  );
}

export default SquareCapsule;

import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import styled from "styled-components";

export const Card = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "16px",
        background: "rgba(241, 245, 249, 0.5)",
        border: "1px solid #F1F5F9",
        borderRadius: "12px",
      }}
    >
      {children}
    </div>
  );
};
const StyledInput = styled(Input)`
  border-radius: 8px;
  .ant-input {
    border-radius: 8px;
  }
  .ant-input-group-addon {
    border-radius: 8px 0px 0px 8px;
  }
`;
export const InputField = ({
  label,
  value,
  onChange,
  placeholder,
  description,
  addonBefore,
  type,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        font: "normal 600 12px/16px Inter",
        color: "#64748B",
      }}
    >
      {label}
      {type === "textarea" ? (
        <TextArea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          addonBefore={addonBefore}
          autoSize
          style={{
            font: "normal 400 14px/20px Inter",
            color: "#1F2937",
            borderRadius: "8px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        />
      ) : (
        <StyledInput
          value={value}
          onChange={onChange}
          type={type}
          placeholder={placeholder}
          addonBefore={addonBefore}
          style={{
            font: "normal 400 14px/20px Inter",
            color: "#1F2937",
            height: "40px",
          }}
        />
      )}
      <div style={{ color: "#97A6BA", fontWeight: 500 }}>{description}</div>
    </div>
  );
};

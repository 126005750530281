import { Col, Row, Switch } from "antd";
import { ReactComponent as PostImage } from "../../../assets/images/search/post.svg";
import { ReactComponent as BountyIcon } from "../../../assets/images/search/bounty.svg";
import { ReactComponent as ProposalIcon } from "../../../assets/images/search/proposal.svg";
import { ReactComponent as ProjectIcon } from "../../../assets/images/search/project.svg";
import { ReactComponent as CourseIcon } from "../../../assets/images/search/course.svg";
import customAxios from "../../../api";
import { useContext, useEffect, useState } from "react";
import { DaoContext, useDaoContext } from "../../../layouts/app/DaoContext";
import { toast } from "react-toastify";
import IconWrapper from "../../IconWrapper";
import { CalendarIcon, RectangleGroupIcon } from "@heroicons/react/24/outline";
import { DocumentIcon } from "@heroicons/react/24/outline";
import useWindowSize from "../../../hooks/useWindowSize";

const Visibility = () => {
  const { daoId, isSpace } = useDaoContext();

  const {
    daoDetails: {
      discussionDisable,
      bountyDisable,
      proposalsDisable,
      projectsDisable,
      coursesDisable,
      eventsDisable,
      documentsDisable,
      spacesDisable,
    },
    reload,
  } = useContext(DaoContext);
  const [localLoading, setLocalLoading] = useState(false);
  const [entities, setEntities] = useState([]);
  const { isMobile } = useWindowSize();
  useEffect(() => {
    setEntities(
      [
        {
          label: "Discussions",
          icon: <PostImage width="18px" />,
          name: "discussion",
          value: discussionDisable,
        },
        {
          label: "Bounties",
          icon: <BountyIcon width="18px" />,
          name: "bounty",
          value: bountyDisable,
        },
        {
          label: "Proposals",
          icon: <ProposalIcon width="18px" />,
          name: "proposals",
          value: proposalsDisable,
        },
        {
          label: "Projects",
          icon: <ProjectIcon width="18px" />,
          name: "projects",
          value: projectsDisable,
        },
        {
          label: "Courses",
          icon: <CourseIcon width="18px" />,
          name: "courses",
          value: coursesDisable,
        },
        {
          label: "Library",
          icon: (
            <IconWrapper style={{ color: "blue" }} width="18px">
              <DocumentIcon />
            </IconWrapper>
          ),
          name: "documents",
          value: documentsDisable,
        },
        {
          label: "Events",
          icon: (
            <IconWrapper style={{ color: "orange" }} width="18px">
              <CalendarIcon />
            </IconWrapper>
          ),
          name: "events",
          value: eventsDisable,
        },
        {
          label: "Spaces",
          icon: <RectangleGroupIcon className="text-purple-500 w-[18px]" />,
          name: "spaces",
          value: spacesDisable,
        },
      ].filter((item) => !isSpace || item.name !== "spaces")
    );
  }, [
    discussionDisable,
    bountyDisable,
    proposalsDisable,
    projectsDisable,
    coursesDisable,
    eventsDisable,
    documentsDisable,
    spacesDisable,
  ]);
  const updateVisiblity = (name, flag) => {
    setLocalLoading(true);
    customAxios
      .post("/settings/visibility/update", { daoId, [name]: flag })
      .then(() => {
        setEntities((prev) => {
          return prev.map((entity) =>
            entity.name === name ? { ...entity, value: flag } : entity
          );
        });
        reload();
        toast.success("Visibility updated Successfully");
      })
      .finally(() => {
        setLocalLoading(false);
      });
  };
  return (
    <Col xs={24} style={{ padding: "0px", marginTop: "20px" }}>
      {isMobile ? null : (
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #F4F2F2",
            borderRadius: "16px",
            padding: "12px 20px",
            background: "white",
            marginBottom: "12px",
            minHeight: "58px",
          }}
        >
          <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>Visibility</h4>
        </Row>
      )}
      <Row
        style={{
          background: "white",
          borderRadius: "12px",
          flexDirection: "column",
        }}
      >
        <div style={{ margin: "20px", font: "normal 600 16px/24px Inter" }}>
          Manage visibility of sections
          <div
            style={{
              marginTop: "8px",
              font: "normal 500 12px/16px Inter",
              color: "#64748B",
            }}
          >
            Members will be able to see the sections for which the toggles are
            turned on
          </div>
        </div>
        {entities.map((item, index) => (
          <div
            style={{
              height: "56px",
              padding: "0px 20px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              font: "normal 500 14px/20px Inter",
              borderBottom:
                index === entities.length - 1 ? "" : "1px solid #E2E8F0",
            }}
          >
            {item.icon}
            {item.label}
            <Switch
              disabled={localLoading}
              checked={!item.value}
              onChange={(flag) => {
                updateVisiblity(item.name, !flag);
              }}
              style={{ marginLeft: "auto" }}
            />
          </div>
        ))}
      </Row>
    </Col>
  );
};

export default Visibility;

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";

type HorizontalScrollItemProps = {
  children: React.ReactNode;
  width: string;
  onClick?: () => void;
  marginBottom?: string;
};

export const HorizontalScrollItem = ({
  width = "33.3%",
  children,
  onClick,
  marginBottom = "0",
}: HorizontalScrollItemProps) => (
  <div
    style={{
      width,
      flex: "none",
      scrollSnapAlign: "start",
      marginBottom,
    }}
    onClick={onClick}
  >
    {children}
  </div>
);

type Props = {
  children?: React.ReactNode[];
  updater?: any;
  gap?: string;
  areNavButtonsHidden?: boolean;
  onStartingScrollPosition?: (isAtStartingScrollPosition: boolean) => void;
};

const HorizontalScrollContainer = ({
  children,
  updater,
  gap = "24px",
  areNavButtonsHidden = false,
  onStartingScrollPosition,
}: Props) => {
  const { isMobile } = useWindowSize();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isScrollLeftButtonShown, setIsScrollLeftButtonShown] = useState(false);
  const [isScrollRightButtonShown, setIsScrollRightButtonShown] =
    useState(false);
    let x = containerRef.current;


  useEffect(() => {
    const containerElement = containerRef.current;
    containerElement?.scrollTo({ left: 0 });
    const onContainerScroll = (ev: Event) => {
      if (!containerElement) return;
      const target = (ev?.target as HTMLDivElement) || containerElement;
      if (target?.scrollLeft === 0) setIsScrollLeftButtonShown(false);
      else setIsScrollLeftButtonShown(true);
      onStartingScrollPosition?.(target?.scrollLeft === 0);

      const maxScrollLeft =
        containerElement!.scrollWidth - containerElement!.clientWidth - 1;
      if (target?.scrollLeft >= maxScrollLeft)
        setIsScrollRightButtonShown(false);
      else setIsScrollRightButtonShown(true);
    };
    onContainerScroll(undefined as any);
    containerElement?.addEventListener("scroll", onContainerScroll);
    return () => {
      containerElement?.removeEventListener("scroll", onContainerScroll);
    };
  }, [updater,x?.scrollWidth,x?.clientWidth]);


  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          gap,
          scrollSnapType: "x mandatory",
          overflowX: "auto",
          scrollPaddingLeft: "calc(50%-25rem)",
          maxWidth: isMobile ? "calc(100vw - 48px)" : "1000px",
          scrollBehavior: "smooth",
        }}
        ref={containerRef}
      >
        {children}
      </div>
      {!areNavButtonsHidden && isScrollLeftButtonShown && (
        <button
          onClick={() => {
            containerRef.current!.scrollLeft -= 400;
          }}
          style={{
            position: "absolute",
            left: -10,
            top: "50%",
            transform: "translate(-25%, -50%)",
            borderRadius: "100%",
            border: "none",
            background: "white",
            boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
            width: "32px",
            height: "32px",
            cursor: "pointer",
          }}
        >
          <ArrowLeftIcon
            width={"12px"}
            color="#6B7280"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          />
        </button>
      )}
      {!areNavButtonsHidden && isScrollRightButtonShown && (
        <button
          onClick={() => {
            containerRef.current!.scrollLeft += 400;
          }}
          style={{
            position: "absolute",
            right: -10,
            top: "50%",
            transform: "translate(25%, -50%)",
            borderRadius: "100%",
            border: "none",
            background: "white",
            boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.1)",
            width: "32px",
            height: "32px",
            cursor: "pointer",
          }}
        >
          <ArrowRightIcon
            width={"12px"}
            color="#6B7280"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              position: "absolute",
            }}
          />
        </button>
      )}
    </div>
  );
};

export default HorizontalScrollContainer;

import React, { useContext, useEffect, useState } from "react";
import { Typography, Avatar, Col, Skeleton, Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import ShareButtonModal from "./ShareButtonModal";
import PostCommentsSection from "./PostComponents/PostCommentsSection";
import PostWriteComment from "./PostComponents/PostWriteComment";
import PostSecondaryButtonOutlined from "./InternalComponents/PostSecondaryButtonOutlined";
import CommentIcon from "../assets/iconSvg/commentIcon.svg";
import no_post from "../assets/iconSvg/no_posts.svg";
import ThumbUpOutlineActive from "../images/ThumbUpOutlineActive.svg";
import ThumbDownOutlineActive from "../images/ThumbDownOutlineActive.svg";
import AnnotationOutline from "../images/AnnotationOutline.svg";
import ShareOutline from "../images/ShareOutline.svg";
import ThumbUpOutline from "../images/ThumbUpOutline.svg";
import ThumbDownOutline from "../images/ThumbDownOutline.svg";
import { useParams } from "react-router";
import customAxios from "../api";
import { toast } from "react-toastify";
import { postMixpanel } from "../utils";
import { useTrackPageView } from "../hooks/useTrackPageView";
import { togglePinPost } from "../utils/pin";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { PinIcon } from "./Icones";
import { StyledCard } from "./BountyFeed";
import { fetchPosts, POST_PAGINATION_LIMIT } from "../lib/utils/posts";
import { useInfiniteQuery } from "react-query";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInterval } from "../hooks/useInterval";
import { POLLING_REFRESH_INTERVAL } from "../constants";
import { UserContext } from "../layouts/app/UserContext";
import { useDaoContext } from "../layouts/app/DaoContext";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
import { checkIsDiscourseModalShown } from "../lib/utils/discourse";
import ProfileCardPopup from "./Common/ProfileCard";
const newMoment = moment.updateLocale("en", {
  weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
});

export const CATEGORIES_LIST_COLORS = [
  "#F05252",
  "#0694A2",
  "#FACA15",
  "#0E9F6E",
  "#F05252",
  "#0694A2",
  "#FACA15",
  "#0E9F6E",
  "#F05252",
  "#0694A2",
  "#FACA15",
  "#0E9F6E",
  "#F05252",
  "#0694A2",
  "#FACA15",
  "#0E9F6E",
  "#F05252",
  "#0694A2",
  "#FACA15",
  "#0E9F6E",
  "#F05252",
  "#0694A2",
  "#FACA15",
  "#0E9F6E",
];
export const getPostTime = (time) => {
  const timeEllapsed = new Date().getTime() - new Date(time).getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const momentTime = moment(time);
  if (timeEllapsed < oneDay) {
    return momentTime.fromNow();
  }
  if (timeEllapsed < oneDay * 2) {
    return momentTime.calendar();
  }
  if (timeEllapsed < oneDay * 7) {
    return (
      newMoment.weekdaysShort(momentTime) + " at " + momentTime.format("h:mm a")
    );
  }
  if (timeEllapsed < oneDay * 30) {
    return momentTime.format("DD MMM [at] h:mm a");
  }
  return momentTime.format("DD MMM YYYY [at] h:mm a");
};
const StyledProfileDetails = styled.div`
  display: flex;
  align-items: center;
  color: #64748b;
  &:hover {
    color: #3b82f6 !important;
  }
`;
const { Title, Paragraph, Text } = Typography;

const StyledParagraph = styled(Paragraph)`
  p,
  h1,
  h3,
  h2,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0;
  }

  .meta {
    display: none;
  }
  img {
    max-width: 100%;
    object-fit: contain;
  }
  .emoji {
    width: 20px;
    height: 20px;
  }
  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  h4 {
    font-size: 12px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h5 {
    font-size: 10px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: #0f172a;
  }
  .mention {
    background: none;
    color: #3b82f6;
    margin: 0;
    & span {
      margin: 0;
    }
  }
`;

export const StyledButtonContainer = styled.div`
  padding: 8px 16px;
  border-radius: 50px;
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  & :hover {
    cursor: pointer;
  }
`;

const StyledIconsContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 50px;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img:hover {
    cursor: pointer;
  }
`;

export const StyledParentContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const Feed = ({
  updater,
  canEngage,
  canEdit,
  postDetailsData,
  sortBy,
  sortingTypeList,
}) => {
  const [postData, setPostData] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [isCreateCommentEnabled, setIsCreateCommentEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sharePostData, setSharePostData] = useState({
    postName: "",
    rawPostDescription: "",
    parsedPostDescription: "",
    createdAt: "",
    postId: "",
  });
  const [allCategoriesList, setAllCategoriesList] = useState([]);
  const [categoriesListOutside, setCategoriesListOutside] = useState([]);
  const [categoriesListDropdown, setCategoriesListDropdown] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filterDrowpdownOpen, setFilterDrowpdownOpen] = useState(false);

  const { channelId } = useParams();
  const { daoDetails, daoId, daoName, setIsConnectDiscourseModalOpen } =
    useDaoContext();

  const {
    isFetchingNextPage,
    hasNextPage,
    isLoading,
    isError,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(["fetch-posts", daoId, channelId, updater, sortBy], {
    queryFn: ({ pageParam = 0, queryKey }) => {
      return fetchPosts({
        offset: pageParam,
        daoId: queryKey[1],
        channelId: queryKey[2],
        sortBy: sortingTypeList[sortBy]?.sortBy,
        sortOrder: sortingTypeList[sortBy]?.sortOrder,
      });
    },
    onSettled: (data, error) => {},
    enabled: !!daoId,
    getNextPageParam: (lastPage) => {
      if (lastPage.postData?.length < POST_PAGINATION_LIMIT) return undefined;
      return lastPage.offset;
    },
    onSuccess: (data) => {
      setPostData(data?.pages?.map((page) => page?.postData)?.flat());
    },
    onError: (error) => {
      console.error(error);
      toast.error("Something went wrong while fetching posts");
    },
  });

  const [loaderRef] = useInfiniteScroll({
    loading: isLoading || isFetchingNextPage,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: isError,
    rootMargin: "0px 0px 400px 0px",
  });

  useTrackPageView(channelId);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const fetchCategories = () => {
    customAxios
      .post("/post/fetch/category", { channelId })
      .then((res) => {
        setAllCategoriesList(
          res.data.res.map((singlecategory) => singlecategory)
        );
      })
      .catch((err) => console.log("categories err", err));
  };

  useInterval(refetch, POLLING_REFRESH_INTERVAL);
  useEffect(() => {
    if (channelId) fetchCategories();
  }, [daoId, channelId, updater]);
  useEffect(() => {
    let categoriesOutside = allCategoriesList.slice(0, 4);
    let categoriesDropdown =
      allCategoriesList.length > 4
        ? allCategoriesList.slice(4, allCategoriesList.length)
        : [];
    setCategoriesListOutside(categoriesOutside);
    setCategoriesListDropdown(categoriesDropdown);
    setSelectedCategory("All");
  }, [allCategoriesList]);
  const setSelectedCategoryFromDropdown = (category, index) => {
    let newListOutside = categoriesListOutside;
    let lastItemFromOldList = newListOutside.pop();
    newListOutside.unshift(category);
    let newListDropDown = categoriesListDropdown;
    newListDropDown.splice(index, 1);
    newListDropDown.unshift(lastItemFromOldList);
    setCategoriesListOutside(newListOutside);
    setCategoriesListDropdown(newListDropDown);
    setSelectedCategory(category);
    setFilterDrowpdownOpen(false);
  };

  const fetchCommentsService = (id) => {
    customAxios
      .post("/postcomments/fetch", {
        postId: id,
        // parentId: "0",
        parentId: id,
      })
      .then((res) => {
        setCommentsData(res.data.comments.reverse());
      })
      .catch((err) => console.log(err));
  };

  const addCommentsService = (id, desc) => {
    customAxios
      .post("/postcomments/create", {
        postId: id,
        channelId: channelId,
        parentId: id,
        description: desc,
      })
      .then((res) => {
        fetchCommentsService(id);
        // setCommentsData(res.data.comments.reverse());
      })
      .catch((err) => console.log(err));
  };
  const handlePostLikeDislikeApi = async (data) => {
    customAxios
      .post(`post/vote`, data)
      .then((res) => {
        postMixpanel(
          "track",
          data.type === "dislike" ? "disliked_post" : "liked_post",
          {
            channel_id: data.postId,
            source_page: window.location.pathname,
            dao_id: daoId,
          }
        );

        if (!res?.data?.success)
          toast.error("Some error occured. Please refresh the page");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured. Please refresh the page");
      });
  };
  const handlePostLike = (postId) => {
    let finalObj = {};
    setPostData((prevState) => {
      const currData = structuredClone(prevState);

      const newData = currData.map((post) => {
        const prev = structuredClone(post);
        if (post.postId === postId) {
          if (prev.postEngagementStatus === "like") {
            finalObj = {
              postEngagementStatus: "",
              postLikes: prev.postLikes - 1,
            };
            handlePostLikeDislikeApi({
              type: "like",
              postId: postId,
              increase: false,
            });
          } else if (prev.postEngagementStatus === "dislike") {
            finalObj = {
              postEngagementStatus: "like",
              postLikes: prev.postLikes + 1,
              postDislikes: prev.postDislikes - 1,
            };
            handlePostLikeDislikeApi({
              type: "dislike",
              postId: postId,
              increase: false,
            });
            handlePostLikeDislikeApi({
              type: "like",
              postId: postId,
              increase: true,
            });
          } else {
            finalObj = {
              postEngagementStatus: "like",
              postLikes: prev.postLikes + 1,
            };
            handlePostLikeDislikeApi({
              type: "like",
              postId: postId,
              increase: true,
            });
          }

          return { ...prev, ...finalObj };
        } else return prev;
      });
      return newData;
    });
  };
  const handlePostDislike = (postId) => {
    let finalObj = {};

    setPostData((prevState) => {
      const currData = structuredClone(prevState);

      const newData = currData.map((post) => {
        const prev = structuredClone(post);
        if (post.postId === postId) {
          if (prev.postEngagementStatus === "dislike") {
            finalObj = {
              postEngagementStatus: "",
              postDislikes: prev.postDislikes - 1,
            };
            handlePostLikeDislikeApi({
              type: "dislike",
              postId: postId,
              increase: false,
            });
          } else if (prev.postEngagementStatus === "like") {
            finalObj = {
              postEngagementStatus: "dislike",
              postLikes: prev.postLikes - 1,
              postDislikes: prev.postDislikes + 1,
            };
            handlePostLikeDislikeApi({
              type: "dislike",
              postId: postId,
              increase: true,
            });
            handlePostLikeDislikeApi({
              type: "like",
              postId: postId,
              increase: false,
            });
          } else {
            finalObj = {
              postEngagementStatus: "dislike",
              postDislikes: prev.postDislikes + 1,
            };
            handlePostLikeDislikeApi({
              type: "dislike",
              postId: postId,
              increase: true,
            });
          }
          return { ...prev, ...finalObj };
        } else return prev;
      });

      return newData;
    });
  };

  return (
    <div>
      {isLoading && <Skeleton active />}

      {!isLoading && !isFetchingNextPage && !(postData.length > 0) ? (
        <div
          style={{
            marginBottom: "16px",
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
            alignItems: "center",
            background: "#FFFFFF",
            /* Shadow / Low */

            boxShadow: "0px 4px 24px rgba(180, 195, 205, 0.1)",
            borderRadius: "12px",
            padding: "2.5rem",
          }}
        >
          <img
            src={no_post}
            alt="emptyDao"
            style={{ objectFit: "contain", width: "200px" }}
          />
          <Col>
            <h1
              level={4}
              style={{
                fontWeight: "600",
                fontSize: "20px",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              No posts available yet
            </h1>
            <h1
              level={5}
              style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px",
                marginTop: "0px",
                textAlign: "center",
              }}
            >
              Be sure to check here again in a while
            </h1>
          </Col>
        </div>
      ) : null}
      <ShareButtonModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        postTitle={sharePostData?.postName}
        rawPostDescription={sharePostData?.rawPostDescription}
        parsedPostDescription={sharePostData?.parsedPostDescription}
        createdAt={sharePostData?.createdAt}
        postId={sharePostData?.postId}
        channelId={channelId}
        daoId={daoId}
        entity="post"
      />
      {!isLoading &&
        postData?.length > 0 &&
        allCategoriesList?.length > 0 &&
        categoriesListOutside?.length > 0 && (
          <>
            {allCategoriesList.length > 0 ? (
              <div
                style={{
                  height: "32px",
                  backgroundColor:
                    selectedCategory === "All" ? "#E1EFFE" : "#ffffff",
                  borderRadius: "16px",
                  padding: "8px 12px",
                  display: "inline-flex",
                  alignItems: "center",
                  marginRight: "10px",
                  cursor: "pointer",
                  border:
                    selectedCategory === "All" ? "1px solid #3F83F8" : "none",
                }}
                onClick={() => {
                  selectedCategory === "All"
                    ? setSelectedCategory(null)
                    : setSelectedCategory("All");
                }}
              >
                <span style={{ fontSize: "12px", fontWeight: "500" }}>All</span>
              </div>
            ) : null}
            {categoriesListOutside?.map((singleCategory, index) => {
              return (
                <div
                  style={{
                    height: "32px",
                    backgroundColor:
                      selectedCategory === singleCategory.name
                        ? "#E1EFFE"
                        : "#ffffff",
                    borderRadius: "16px",
                    padding: "8px 12px",
                    display: "inline-flex",
                    alignItems: "center",
                    marginRight: "10px",
                    marginBottom: "8PX",
                    cursor: "pointer",
                    border:
                      selectedCategory === singleCategory.name
                        ? "1px solid #3F83F8"
                        : "none",
                  }}
                  onClick={() => {
                    selectedCategory === singleCategory.name
                      ? setSelectedCategory(null)
                      : setSelectedCategory(singleCategory.name);
                  }}
                >
                  <span
                    style={{
                      backgroundColor: singleCategory.colour,
                      display: "inline-block",
                      width: "8px",
                      height: "8px",
                      borderRadius: "20px",
                      marginRight: "8px",
                    }}
                  ></span>
                  <span style={{ fontSize: "12px", fontWeight: "500" }}>
                    {singleCategory.name}
                  </span>
                </div>
              );
            })}
            {categoriesListDropdown?.length > 0 && (
              <div
                style={{
                  height: "32px",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  padding: "8px 12px",
                  display: "inline-flex",
                  alignItems: "center",
                  marginRight: "10px",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => {
                  setFilterDrowpdownOpen(true);
                }}
              >
                <span style={{ fontSize: "12px", fontWeight: "500" }}>
                  More
                </span>
                <i
                  class="fas fa-chevron-down"
                  style={{ marginLeft: "4px", width: "10px" }}
                ></i>
                {filterDrowpdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#ffffff",
                      borderRadius: "12px",
                      padding: "12px 16px",
                      top: "110%",
                      left: "0",
                      cursor: "auto",
                      minWidth: "150px",
                      boxShadow:
                        "0px 10px 15px -4px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {categoriesListDropdown?.map((singleItem, index) => {
                      return (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            setSelectedCategoryFromDropdown(
                              singleItem.name,
                              index
                            );
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: singleItem.colour,
                              display: "inline-block",
                              width: "8px",
                              height: "8px",
                              borderRadius: "20px",
                              marginRight: "8px",
                            }}
                          ></span>
                          <span
                            style={{
                              display: "inline-block",
                              margin: "5px 0",
                              cursor: "pointer",
                            }}
                          >
                            {singleItem.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
            <div style={{ marginBottom: "20px" }}></div>
          </>
        )}

      {!isLoading &&
        postData?.length > 0 &&
        postData?.map((post, index) => {
          if (
            selectedCategory &&
            selectedCategory !== "All" &&
            post?.category?.length > 0 &&
            !post.category.find(
              (singleCategory) => singleCategory.name == selectedCategory
            )
          )
            return null;

          return (
            <DiscussionCard
              addCommentsService={addCommentsService}
              canEdit={canEdit}
              canEngage={canEngage}
              channelId={channelId}
              commentsData={commentsData}
              daoId={daoId}
              daoName={daoName}
              handlePostDislike={handlePostDislike}
              handlePostLike={handlePostLike}
              index={index}
              isCreateCommentEnabled={isCreateCommentEnabled}
              post={post}
              setIsCreateCommentEnabled={setIsCreateCommentEnabled}
              setSharePostData={setSharePostData}
              showModal={showModal}
            />
          );
        })}
      {(isFetchingNextPage || hasNextPage) && (
        <div ref={loaderRef}>
          <Skeleton
            paragraph={{
              rows: 2,
              width: "100%",
            }}
            title={false}
            size="large"
            active
          />
        </div>
      )}
    </div>
  );
};

export const DiscussionCard = ({
  post,
  daoId,
  channelId,
  index,
  daoName,
  canEdit = false,
  isCommentSectionVisible = false,
  activePostId = null,
  setSharePostData = null,
  showModal = null,
  canEngage = false,
  handlePostLike = null,
  handlePostDislike = null,
  isCreateCommentEnabled = false,
  addCommentsService = null,
  setIsCreateCommentEnabled = null,
  commentsData = null,
  isMetadataHidden = false,
  height = "",
}) => {
  const navigate = useDaoLensNavigate();
  const { showLoginModal, userDetails } = useContext(UserContext);
  const { daoDetails, setIsConnectDiscourseModalOpen } = useDaoContext();

  const isDiscourseModalShown = checkIsDiscourseModalShown(
    daoDetails,
    userDetails
  );

  return (
    <StyledCard
      style={{
        width: "100%",
        textAlign: "left",
        marginBottom: "12px",
        borderRadius: "16px",
        border: "1px solid #f0f0f0",
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        height,
      }}
      padding={"16px 0px 0px 0px"}
      height={height}
      onClick={() => {
        navigate(
          `/app/dao/${daoName}/${daoId}/channel/${channelId}/post/${post.postId}`
        );
      }}
      key={post.postId}
    >
      <div
        style={{
          color: "#64748B",
          marginBottom: "6px",
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",

            alignItems: "center",
          }}
        >
          <ProfileCardPopup userId={post.userId}>
            <StyledProfileDetails
              onClick={() => navigate(`/app/profile/${post?.userId}`)}
            >
              <div>
                {post?.userProfilePicture &&
                  post.userProfilePicture !== null && (
                    <Avatar
                      size="small"
                      src={post.userProfilePicture}
                      style={{ marginRight: "10px" }}
                    />
                  )}
              </div>
              {post?.userName && (
                <>
                  <Text
                    style={{
                      color: "inherit",
                      fontWeight: 600,
                      fontSize: 12,
                    }}
                  >
                    {post.userName}
                  </Text>
                  <span style={{ margin: "0 4px" }}>&#8226;</span>
                </>
              )}
            </StyledProfileDetails>
          </ProfileCardPopup>
          <Text
            style={{
              color: "#64748B",
              fontWeight: 600,
              fontSize: "12px",
            }}
          >
            {getPostTime(post?.createdAt)}
          </Text>
        </div>
        {post.pinned && (
          <div style={{ margin: "0 10px 0 auto" }}>
            <PinIcon style={{ color: "#6366F1" }} />
          </div>
        )}
        {canEdit && (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: "8px" }}>
                <Menu.Item
                  onClick={(e) => {
                    e.domEvent.stopPropagation();
                    togglePinPost(daoId, post.postId, !post.pinned);
                  }}
                >
                  <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
                  {post.pinned ? "Unpin" : "Pin"}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <EllipsisHorizontalIcon
              width={"20px"}
              style={{ cursor: "pointer" }}
            />
          </Dropdown>
        )}
      </div>

      <Title
        style={{ padding: "0px 20px" }}
        level={4}
        ellipsis={{
          rows: 2,
        }}
      >
        {post?.postName}
      </Title>
      {post?.postTags && post?.postTags.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexFlow: "wrap",
            gap: "8px",
            margin: "12px",
          }}
        >
          {post.postTags.map((tag) => (
            <div
              style={{
                padding: "2px 16px",
                background: "#F2F5FF",
                borderRadius: "8px",
                color: "#3B82F6",

                fontSize: "12px",
                display: "inline-block",
              }}
            >
              {tag}
            </div>
          ))}
        </div>
      )}

      {post?.category?.length > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexFlow: "wrap",
            padding: "0px 20px",
            gap: "8px",
            margin: "12px 0px",
          }}
        >
          {typeof post?.category !== "string" &&
            post?.category?.map((ele, index) => (
              <div
                style={{
                  height: "32px",
                  backgroundColor: "#ffffff",
                  borderRadius: "16px",
                  padding: "4px 8px",
                  display: "inline-flex",
                  alignItems: "center",
                  background: "#F3F4F6",
                }}
              >
                <span
                  style={{
                    backgroundColor: ele.colour,
                    display: "inline-block",
                    width: "8px",
                    height: "8px",
                    borderRadius: "20px",
                    marginRight: "8px",
                  }}
                ></span>
                <span style={{ fontSize: "12px", fontWeight: "500" }}>
                  {ele.name}
                </span>
              </div>
            ))}

          {post?.postTags &&
            post?.postTags.length > 0 &&
            post.postTags.map((tag) => (
              <div
                style={{
                  padding: "2px 16px",
                  background: "#F2F5FF",
                  borderRadius: "8px",
                  color: "#3B82F6",

                  fontSize: "12px",
                }}
              >
                {tag}
              </div>
            ))}
        </div>
      ) : null}

      <StyledParagraph
        style={{
          // maxHeight: "150px",
          // borderBottom: "1px solid #E5E7EB",
          overflowY: "hidden",
          maxHeight: "150px",
          textOverflow: "ellipsis",
          padding: "0px 20px",
          // filter: "drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.07))",
          // boxShadow: "#f5f5f5 0px -8px 10px -6px inset",
          marginBottom: "0PX",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "20px",
            position: "absolute",
            bottom: "0px",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
          }}
        ></div>
        {ReactHtmlParser(post?.postDescription)}
      </StyledParagraph>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom:
            isCommentSectionVisible && activePostId === post.postId && "24px",
          marginTop: "auto",
          padding: "16px 20px",
          // filter: "drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.07))",
        }}
      >
        <div style={{ display: "flex" }}>
          <StyledButtonContainer
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/app/dao/${daoName}/${daoId}/channel/${channelId}/post/${post.postId}`,
                {
                  state: {
                    isCommentSectionClicked: true,
                  },
                }
              );
            }}
            style={{ marginRight: "10px" }}
          >
            <img src={AnnotationOutline} alt="open comment section" />
            <span style={{ marginLeft: "6px" }}>{post?.noOfPostComments}</span>
          </StyledButtonContainer>
          <StyledButtonContainer
            onClick={(e) => {
              e.stopPropagation();
              setSharePostData({
                postName: post?.postName,
                rawPostDescription: post?.postDescription,
                parsedPostDescription: ReactHtmlParser(post?.postDescription),
                createdAt: post?.createdAt,
                postId: post?.postId,
              });
              showModal();
            }}
          >
            <img src={ShareOutline} alt="open comment section" />
          </StyledButtonContainer>
        </div>
        <StyledIconsContainer style={{ marginRight: "10px" }}>
          <span
            style={{
              marginRight: "4px",
              color:
                post.postEngagementStatus === "like"
                  ? "#22C55E"
                  : "rgba(0,0,0,0.85)",
            }}
          >
            {post?.postLikes}
          </span>
          <img
            src={
              post.postEngagementStatus === "like"
                ? ThumbUpOutlineActive
                : ThumbUpOutline
            }
            alt="thumb up icon"
            onClick={(e) => {
              e.stopPropagation();
              if (!userDetails) showLoginModal();
              if (isDiscourseModalShown) {
                setIsConnectDiscourseModalOpen(true);
                return;
              }
              if (canEngage) handlePostLike(post.postId);
            }}
          />
          <span
            style={{
              marginRight: "4px",
              marginLeft: "4px",
              color:
                post.postEngagementStatus === "dislike"
                  ? "red"
                  : "rgba(0,0,0,0.85)",
            }}
          >
            {post?.postDislikes}
          </span>
          <img
            src={
              post.postEngagementStatus === "dislike"
                ? ThumbDownOutlineActive
                : ThumbDownOutline
            }
            alt="thumb down icon"
            onClick={(e) => {
              e.stopPropagation();
              if (!userDetails) showLoginModal();
              if (isDiscourseModalShown) {
                setIsConnectDiscourseModalOpen(true);
                return;
              }
              if (canEngage) handlePostDislike(post.postId);
            }}
          />
        </StyledIconsContainer>
      </div>

      {isCommentSectionVisible && activePostId === post.postId && (
        <>
          <Paragraph style={{ lineHeight: 2 }}>
            {isCreateCommentEnabled && canEngage && (
              <PostWriteComment
                addComment={(desc) => addCommentsService(post.postId, desc)}
                setAccordionState={(status) =>
                  setIsCreateCommentEnabled(status)
                }
              />
            )}
            <PostCommentsSection
              setAccordionState={isCommentSectionVisible}
              comments={commentsData}
            />
          </Paragraph>
          <PostSecondaryButtonOutlined
            buttonText="+ Add Comment"
            style={{ borderRadius: "100px" }}
            icon={CommentIcon}
            clickHandler={() => setIsCreateCommentEnabled(true)}
          />
        </>
      )}
    </StyledCard>
  );
};

Feed.propTypes = {
  postData: PropTypes.array,
  postDetailsData: PropTypes.object,
};

export default Feed;

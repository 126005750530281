import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import "../ComponentStyles/BountyDetails.scss";
import {
  Button,
  Collapse,
  Modal,
  Tooltip,
  Typography,
  Menu,
  Dropdown,
  Progress,
  Tabs,
  Col,
  Row,
} from "antd";
import CommentIcon from "../../assets/iconSvg/commentIcon.svg";
import { menu as BountyTypeMenu } from "./CreateBounty/data";
import GoBackButton from "../InternalComponents/GoBackButton";
import SmallUserDetails from "../InternalComponents/SmallUserDetails";
import TextButton from "../InternalComponents/TextButton";
import DotIcon from "../../images/dot.svg";
import dummyAvatar from "../../assets/images/login/defaultUser.jpeg";
import userIconGreen from "../../assets/iconSvg/userIconGreen.svg";
import globe from "../../assets/iconSvg/globe.svg";
import diamondIconGreen from "../../assets/iconSvg/diamondIconGreen.svg";
import SquareCapsule from "../InternalComponents/SquareCapsule";
import TokenSquareCapsule from "../InternalComponents/TokenSquareCapsule";
import BountySubmissionsAccordion from "./BountySubmissionsAccordion";
import { useState } from "react";
import ViewBountySubmissionModal from "./ViewBountySubmissionModal";
import { useNavigate, useParams } from "react-router-dom";
import BountySubmit from "../BountySubmit";
import PrimaryButtonFilled from "../InternalComponents/PrimaryButtonFilled";
import moment from "moment/moment";
import { useEffect } from "react";
import customAxios from "../../api";
import BountySubtasksAccordion from "./BountySubtasksAccordion";
import ReactHtmlParser from "react-html-parser";
import ViewBountySubtaskModal from "./ViewBountySubtaskModal";
import { Document, Page } from "react-pdf";
import pdfIcon from "../../assets/iconSvg/pdf.svg";
import downloadIcon from "../../assets/iconSvg/downloadIcon.svg";
import CommentSection from "../CommentsComponents/CommentSection";
import EditBountyModal from "../EditBountyModal";
import BountyShare from "./Share";
import { useInterval } from "../../hooks/useInterval";
import defaultUser from "./../../assets/images/login/defaultUser.jpeg";
import {
  BOUNTY_SUBMISSION_STATUS_COLORS,
  DEFAULT_SORTING_TYPE_LIST,
  MD_SCREEN_BREAKPOINT,
  POLLING_REFRESH_INTERVAL,
} from "../../constants";
import BountySubmitGuidelines from "../BountySubmit/BountySubmitGuidelines";
import styles from "./BountyDetails.module.css";
import { flagValues } from "../Flag";
import { cancelText, confirmText } from "../CreateBountyModal";
import {
  copyTextToClipboard,
  getCurrentDateFromNow,
  getUrlForIPFSHash,
  postMixpanel,
} from "../../utils";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import SlidingContextSidebar from "../SlidingContextSidebar";
import { ReactComponent as StackIcon } from "./../../images/rectangle-stack-icon.svg";
import IconWrapper from "../IconWrapper";
import TwitterTaskDetail from "./BountyDetailComponents/TwitterTaskDetail";
import {
  CheckCircleFilled,
  CopyOutlined,
  ExclamationCircleFilled,
  TwitterOutlined,
  WalletFilled,
} from "@ant-design/icons";
import {
  ArrowsPointingOutIcon,
  EllipsisVerticalIcon,
  LinkIcon,
  LockClosedIcon,
  WindowIcon,
} from "@heroicons/react/24/outline";
import {
  TrashIcon,
  PencilIcon,
  WalletIcon,
  CreditCardIcon,
} from "@heroicons/react/24/solid";
import { ReactComponent as NFT } from "./../../assets/images/gating/nft.svg";
import PostCard from "../Common/PostCard";
import BountyFeedCardContent from "./BountyFeedCardContent";
import CreateBountyModal from "./CreateBounty/CreateBountyModal";
import { StyledLabel } from "../BountyFeed";
import VerifyQuiz from "../Common/VerifyQuiz";
import QuizResult from "../Common/QuizResult";
import { UserContext } from "../../layouts/app/UserContext";
import { DiscordIcon } from "../Icones";
import DeleteEntityModal from "../Common/DeleteEntityModal";
import { DaoContext } from "../../layouts/app/DaoContext";
import useWindowSize from "../../hooks/useWindowSize";
import { togglePinBounty } from "../../utils/pin";
import Share from "./Share";
import Attachment from "../Attachment";
import { PinIcon } from "../Icones";
import FeedCard from "../Common/FeedCard";
import BountySubmitWalletCheck from "./BountySubmitWalletCheck";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../Common/ProfileCard";
import axios from "axios";
import Paragraph from "antd/lib/skeleton/Paragraph";
const { Text } = Typography;
const { Panel } = Collapse;
const { Link } = Typography;

export const StyledDiv = styled.div`
  .ql-size-large {
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  .ql-size-small {
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  .ql-size-huge {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  p,
  h1,
  h3,
  h2,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0;
    span {
      ql-size-large {
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 8px;
        color: #0f172a;
      }
    }
  }

  .meta {
    display: none;
  }
  img {
    max-width: 100%;
    object-fit: contain;
    margin: 8px 0px;
  }
  .emoji {
    width: 20px;
    height: 20px;
  }
  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  h4 {
    font-size: 12px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h5 {
    font-size: 10px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: #0f172a;
  }
  .mention {
    background: none;
    color: #3b82f6;
    margin: 0;
    & span {
      margin: 0;
    }

    font-size: 14px;
  }
`;

export const StyledTaskContainer = styled.div`
  display: flex;
  opacity: ${({ active = true }) => (active ? "1" : "0.5")};
  background: #f9fafb;
  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  z-index: 2;
  row-gap: 4px;
`;
export const PrimaryBountyTaskButton = styled(Button)`
  border-radius: 8px;
  background: #3b82f6;
  font-size: 12px;
`;
export const SecondaryBountyTaskButton = styled(Button)`
  border-radius: 8px;
  background: #eff6ff;
  color: #3b82f6;
  border: none;
  font-size: 12px;
`;

const StyledMenu = styled(Menu)`
  border-radius: 8px;
`;

const StyledDotIconContainer = styled.div`
  & img:hover {
    cursor: pointer;
  }
`;

function BountyDetails({
  daoId,
  bountyId,
  bountyDetails,
  isPollingPaused,
  setIsPollingPaused,
  fetchBountyDetails,
}) {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const navigation = useDaoLensNavigate();
  const [isViewSubmissionModalOpen, setIsViewSubmissionModalOpen] =
    useState(false);
  const [isViewSubtaskModalOpen, setIsViewSubtaskModalOpen] = useState(false);
  const [submitBountyModalVisible, setSubmitMountyModalVisible] =
    useState(false);
  const [
    submitBountyGuidelinesModalVisible,
    setSubmitBountyGuidelinesModalVisible,
  ] = useState(false);

  const [contextSidebarOpen, setContextSidebarOpen] = useState(true);
  const [sortBy, setSortBy] = useState("Latest");
  const [showSubmitWalletCheckModal, setShowSubmitWalletCheckModal] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [accordionState, setAccordionState] = useState(["1"]);
  const [comments, setComments] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [showAttachmentLink, setShowAttachmentLink] = useState(false);
  const [linkedItems, setLinkedItems] = useState([]);
  const [currentBountySubmissionId, setcurrentBountySubmissionId] = useState(0);
  const [currentBountySubtaskId, setcurrentBountySubtaskId] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [canCreateBounty, setCanCreateBounty] = useState(false);
  const { isAdmin, isCurrentDaoJoined, setShowJoinCommunityModal } =
    useContext(DaoContext);
  const [canEdit, setCanEdit] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [quizResultModalOpen, setQuizResultModalOpen] = useState(false);
  const [marksPercent, setMarksPercent] = useState(null);
  const [selectedTab, setSelectedTab] = useState("2");
  const [bountyLoading, setBountyLoading] = useState(false);
  const [nftData, setNftData] = useState({});
  const [isEditBountyModalVisible, setIsEditBountyModalVisible] =
    useState(false);
  const [bountySubmissionDataPrefill, setBountySubmissionDataPrefill] =
    useState(null);
  const [tweetUrls, setTweetUrls] = useState({});
  // ! Local handlers
  useTrackPageView(bountyId);
  const openViewBountySubmissionModal = (submissionId = 0) => {
    setIsViewSubmissionModalOpen(true);
    const currIndex = submissions.findIndex(
      (ele) => ele.bountySubmissionId === submissionId
    );
    setcurrentBountySubmissionId(currIndex);
  };
  const openViewBountySubtaskModal = (subtaskIndex = 0) => {
    setIsViewSubtaskModalOpen(true);
    setcurrentBountySubmissionId(subtaskIndex);
  };
  const closeViewBountySubmissionModal = () => {
    setIsViewSubmissionModalOpen(false);
  };
  const closeViewBountySubtaskModal = () => {
    setIsViewSubtaskModalOpen(false);
  };
  const reloadBountyDetails = () => {
    setBountyLoading(true);
    fetchBountyDetails(bountyId, () => {
      setBountyLoading(false);
    });
  };
  useEffect(() => {
    customAxios
      .get(`post/permission?daoId=${daoId}`)
      .then((res) => {
        setCanEdit(res.data.postPermission.includes("MANAGE"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]);

  const handleTwitterShare = () => {
    const url = window.location.href;

    const anchor = document.createElement("a");
    anchor.href = `http://twitter.com/share?text=Gm folks⚡️%0AJust completed this amazing quiz on Dao Manager. You can check it out here: ${url}`;
    anchor.target = "_blank";

    anchor.click();
  };

  const fetchComments = () => {
    customAxios
      .post("/bountycomments/fetch", {
        bountyId: bountyId,
        parentId: "0",
        sortBy: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortBy,
        sortOrder: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortOrder,
      })
      .then((res) => {
        let transformAllComments = res.data.bountyComments.map(
          (singleComment) => {
            let obj = singleComment.vote.find(
              (ele) => ele.commentId === singleComment.id
            );
            if (obj !== undefined)
              return {
                ...singleComment,
                engagementStatus: obj.type.toLowerCase(),
              };
            else return { ...singleComment, engagementStatus: "" };
          }
        );
        setComments(transformAllComments.slice());
      })
      .catch((err) => console.log(err));
  };
  const handleBountyTasksVerification = (data) => {
    if (!userDetails) {
      showLoginModal();
      return;
    }
    if (!isCurrentDaoJoined) {
      setShowJoinCommunityModal(true);
      return;
    }

    customAxios
      .post(`/bountysubmission/submit`, {
        ...data,
        daoId: daoId,
        type: bountyDetails.bountyType,
        bountyId: bountyId,
      })
      .then((res) => {
        if (res.data.tweetUrl) {
          setTweetUrls((prev) => ({
            ...prev,
            [data.twitterSubtaskId]: res.data.tweetUrl,
          }));
        }
        if (res.data.result) {
          if (!res.data.tweetUrl) {
            reloadBountyDetails();
            toast.success("Task verified successfully");
          }
        } else {
          toast.error("Sorry! Couldn't verify the task");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Sorry! Couldn't verify the task");
        setLoading(false);
      });
  };
  const handleClaimReward = () => {
    customAxios
      .post("/bounty/claim/reward", {
        daoId: daoId,
        bountyId: bountyId,
      })
      .then((res) => {
        reloadBountyDetails();
        if (bountyDetails.taskPoint) {
          const percent =
            (Number(res.data.currentTaskPoints) -
              Number(res.data.currentLevelXpPoints)) /
            (Number(res.data.nextLevelTaskPoints) -
              Number(res.data.currentLevelXpPoints));

          toast(
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
                paddingRight: "50px",
                width: "100%",
              }}
            >
              <span style={{ fontSize: "16px" }}>
                ⭐️ Gained {res.data.bountyTaskPoint}XP{" "}
              </span>
              <Progress
                percent={(percent * 100).toFixed(2)}
                showInfo={false}
                status={"active"}
                strokeColor={"linear-gradient(90deg, #F6D365 0%, #FDA085 100%)"}
                style={{ marginBottom: "4px" }}
              ></Progress>
              <div
                style={{
                  display: "flex",
                  columnGap: "4px",
                  color: "grey",
                  fontSize: "12px",
                }}
              >
                <span>{(percent * 100).toFixed(2)}% completed</span>
                <span>&#x2022; 🔥 lvl {res.data.currentLevel}</span>
              </div>
            </div>,

            {
              position: isMobile ? "top-center" : "bottom-right",

              bodyStyle: {
                marginLeft: "0px !important",
              },
              style: {
                background: "white",
                flexDirection: "column",
                borderRadius: "12px",
                padding: "16px",
                minHeight: "112px",
                boxShadow:
                  "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
              },
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchSubmissions = () => {
    customAxios
      .get(`/bountysubmission/fetch?bountyId=${bountyId}`)
      .then((res) => {
        console.log("bountysubmission", res.data);
        setSubmissions(res.data.bountySubmission);
        setIsCompleted(res.data.isCompleted);
        setIsPaid(res.data.isPaid);
      })
      .catch((err) => console.log(err));
  };
  const fetchSubtasks = () => {
    customAxios
      .get(`/bounty/subtaskList?bountyId=${bountyId}`)
      .then((res) => {
        // console.log("subtasks", res.data);
        setSubTasks(res.data.subTaskList);
      })
      .catch((err) => console.log(err));
  };
  const postComment = async (
    commentText,
    mentions,
    parentId,
    level2ParentId = "0"
  ) => {
    var postBody = {
      daoId: daoId,
      bountyId: bountyId,
      parentId,
      description: commentText,
      level2ParentId,
    };

    if (mentions?.length > 0) postBody.userTagged = mentions;
    await customAxios.post("/bountycomments/create", postBody);
    postMixpanel("track", "comment_on_post", {
      bounty_id: bountyId,
      parent_comment_id: parentId,
      dao_id: daoId,
    });
    fetchComments();
  };
  const navigate = useDaoLensNavigate();

  const { userDetails, showLoginModal } = useContext(UserContext);
  useInterval(() => {
    if (!isPollingPaused) {
      fetchComments();
      if (
        bountyDetails?.noOfSubmissions &&
        bountyDetails?.noOfSubmissions !== "0"
      )
        fetchSubmissions();
      fetchSubtasks();
    }
  }, POLLING_REFRESH_INTERVAL);
  useEffect(() => {
    fetchComments();
    if (
      (bountyDetails?.noOfSubmissions &&
        bountyDetails?.noOfSubmissions !== "0") ||
      (bountyDetails?.noOfUserSubmissions &&
        bountyDetails?.noOfUserSubmissions !== "0")
    )
      fetchSubmissions();
    fetchSubtasks();
  }, [daoId, bountyId]);

  // ! Title case a string
  const titleCase = (string) => {
    let s = string?.split(" ");
    s = s?.map((element) => {
      element = element[0].toUpperCase() + element.slice(1, element.length);
      return element;
    });
    return s?.join(" ");
  };
  const handleSubmitQuiz = (state) => {
    const finalArr = bountyDetails?.quiz?.map((ele, questionIndex) => {
      return {
        questionName: ele.questionName,
        markedAns: state[questionIndex],
      };
    });
    customAxios
      .post(`/bountysubmission/submit`, {
        type: "QUIZ",
        quizAns: finalArr,
        daoId: daoId,
        bountyId: bountyId,
      })
      .then((res) => {
        setQuizModalOpen(false);
        if (res.data.result) {
          reloadBountyDetails();
          setMarksPercent(100);
        } else {
          setMarksPercent(10);
        }
        setQuizResultModalOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteBountyHandler = () => {
    customAxios
      .delete(`bounty/delete?bountyId=${bountyId}`)
      .then((res) => {
        toast.success("Bounty deleted successfully");
        setShowDeleteModal(false);
        navigation(-1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const menu = (
    <StyledMenu>
      <Menu.Item
        onClick={() => {
          setIsEditBountyModalVisible(true);
          setIsPollingPaused(true);
        }}
      >
        <PencilIcon fill="#6B7280" style={{ width: "12px" }} /> Edit
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          togglePinBounty(daoId, bountyId, !bountyDetails.pinned);
        }}
      >
        <PinIcon /> {bountyDetails.pinned ? "Unpin" : "Pin"}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <TrashIcon fill="#6B7280" style={{ width: "12px" }} /> Delete
      </Menu.Item>
    </StyledMenu>
  );

  useEffect(() => {
    customAxios
      .post(`bounty/cancreate`, { daoId: daoId })
      .then((res) => {
        setCanCreateBounty(res.data.canView ? true : false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]);
  const flag = flagValues.find(
    ({ value }) => value === bountyDetails?.bountyPriority
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    if (bountyDetails.nftRewardMetadata) {
      axios
        .get(
          getUrlForIPFSHash(bountyDetails.nftRewardMetadata.split("ipfs://")[1])
        )
        .then((res) => {
          if (res.data.image)
            setNftData({
              nftName: res.data.name,
              nftDescription: res.data.description,
              image: getUrlForIPFSHash(res.data.image.split("ipfs://")[1]),
            });
        });
    }
  }, [bountyDetails]);
  useEffect(() => {
    if (showSubmitWalletCheckModal)
      switch (bountyDetails?.network?.toLowerCase()) {
        case "aptos":
          if (userDetails.isAptosConnected) {
            setShowSubmitWalletCheckModal(false);
            setSubmitMountyModalVisible(true);
          }
          break;
        case "solana":
          if (userDetails.isPhantomConnected) {
            setShowSubmitWalletCheckModal(false);
            setSubmitMountyModalVisible(true);
          }
          break;
        case "solana_devnet":
          if (userDetails.isPhantomConnected) {
            setShowSubmitWalletCheckModal(false);
            setSubmitMountyModalVisible(true);
          }
          break;
        default:
          if (userDetails.isMetamaskConnected) {
            setShowSubmitWalletCheckModal(false);
            if (!bountyDetails?.userSubmission.length)
              setSubmitMountyModalVisible(true);
            else handleClaimReward();
          }
      }
  }, [showSubmitWalletCheckModal, userDetails]);
  console.log("bountyDetails", bountyDetails);
  return (
    <div
      className="bounty-details__wrapper"
      style={{ marginLeft: isMobile ? "16px" : "0" }}
    >
      <DeleteEntityModal
        note="This bounty will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          deleteBountyHandler();
        }}
      />
      {isEditBountyModalVisible ? (
        <CreateBountyModal
          mode="edit"
          bountyDetails={bountyDetails}
          nftData={nftData}
          onSubmit={() => {
            fetchBountyDetails(bountyId);
          }}
          isModalVisible={isEditBountyModalVisible}
          handleCancel={() => setIsEditBountyModalVisible(false)}
        />
      ) : null}

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: "14px" }}>Bounty</span>
        {canCreateBounty || isAdmin ? (
          <Dropdown overlay={menu} trigger={["click"]}>
            <StyledDotIconContainer>
              <EllipsisVerticalIcon
                width={"20px"}
                style={{ cursor: "pointer" }}
              />
            </StyledDotIconContainer>
          </Dropdown>
        ) : null}
      </div> */}

      <BountyFeedCardContent
        data={bountyDetails}
        style={{ marginTop: "5px", marginBottom: "12px" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        <div
          style={{
            paddingRight: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <h4
            className="bounty-details__meta--header"
            style={{ marginBottom: "0px" }}
          >
            {bountyDetails?.bountyTitle}
          </h4>
          {canCreateBounty || isAdmin ? (
            <Dropdown overlay={menu} trigger={["click"]}>
              <StyledDotIconContainer>
                <EllipsisVerticalIcon
                  width={"20px"}
                  style={{ cursor: "pointer" }}
                />
              </StyledDotIconContainer>
            </Dropdown>
          ) : null}
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            columnGap: "8px",
            flexFlow: "wrap",
            rowGap: "8px",
          }}
        >
          {bountyDetails?.bountyLastSubmissionDate ? (
            <StyledLabel
              style={
                bountyDetails.isExpired
                  ? {
                      backgroundColor: "#EF44441A",
                      border: "none",
                    }
                  : {}
              }
            >
              <Text
                style={{
                  color: bountyDetails.isExpired ? "#EF4444" : "#0F172A",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "16px",
                }}
              >
                🗓{" "}
                {getCurrentDateFromNow(bountyDetails.bountyLastSubmissionDate)}
              </Text>
            </StyledLabel>
          ) : null}

          {bountyDetails?.taskPoint ? (
            <StyledLabel>
              <Text
                style={{
                  color: "#0F172A",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "16px",
                }}
              >
                {`⭐️${bountyDetails?.taskPoint}XP`}
              </Text>
            </StyledLabel>
          ) : null}

          <StyledLabel>
            <Text
              style={{
                color: "#0F172A",
                fontWeight: 500,
                fontSize: 12,
                lineHeight: "16px",
              }}
            >
              📜 <span>{bountyDetails?.totalSubmissions}</span> Submissions
            </Text>
          </StyledLabel>
          {bountyDetails.nftRewardMetadata ? (
            <StyledLabel
              style={{ alignItems: "center", display: "flex", gap: "4px" }}
            >
              <Text
                style={{
                  color: "#0F172A",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "16px",
                }}
              >{`🖼️ NFT`}</Text>
              <IconWrapper>
                <i
                  class="fas fa-external-link"
                  style={{
                    fontSize: "10px",
                    color: "#374151",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    axios
                      .get(
                        getUrlForIPFSHash(
                          bountyDetails.nftRewardMetadata.split("ipfs://")[1]
                        )
                      )
                      .then((res) => {
                        if (res.data.image)
                          window.open(
                            getUrlForIPFSHash(
                              res.data.image.split("ipfs://")[1]
                            )
                          );
                      });
                    e.stopPropagation();
                  }}
                />
              </IconWrapper>
            </StyledLabel>
          ) : null}
          {bountyDetails?.bountyAmount &&
          bountyDetails?.bountyType === "BOUNTY_SUBMISSION" ? (
            <StyledLabel>
              <Text
                style={{
                  color: "#0F172A",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "16px",
                }}
              >
                <img
                  src={diamondIconGreen}
                  alt="diamond-icon"
                  className="diamond-image"
                />{" "}
                {bountyDetails?.bountyAmount} {bountyDetails?.symbol}
              </Text>
            </StyledLabel>
          ) : null}

          {!bountyDetails?.bountyType === "CLAIM" &&
          bountyDetails?.userSubmission.length &&
          bountyDetails?.userSubmission?.[0].isWinner ? (
            <>
              <StyledLabel style={{ marginRight: "8px" }}>
                <Text
                  style={{
                    color: "#0F172A",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "16px",
                  }}
                >
                  ✅ {"  "}Submitted on{" "}
                  {moment(bountyDetails?.bountySubmittedDate).format(
                    "DD MMMM YYYY"
                  )}
                </Text>
              </StyledLabel>
            </>
          ) : null}
          {bountyDetails?.bountyType === "CLAIM" &&
          bountyDetails?.userSubmission.length &&
          bountyDetails?.userSubmission?.[0]?.completedSubtasks ? (
            <>
              <StyledLabel style={{ marginRight: "8px" }}>
                <Text
                  style={{
                    color: "#0F172A",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "16px",
                  }}
                >
                  ✅ Claimed{" "}
                  {bountyDetails?.userSubmission[0]?.completedSubtasks} times
                </Text>
              </StyledLabel>
            </>
          ) : null}
          {bountyDetails?.gatekeeping && (
            <StyledLabel>
              <Text
                style={{
                  color: "#2A3142",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "16px",
                }}
              >
                {bountyDetails?.gatekeeping}
              </Text>
            </StyledLabel>
          )}
          {[
            ...(bountyDetails?.bountySkills ? bountyDetails?.bountySkills : []),
          ].map((tag) => (
            <StyledLabel>
              <Text
                style={{
                  color: "#2A3142",
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: "16px",
                  bacgkround: "white",
                }}
              >
                {tag}
              </Text>
            </StyledLabel>
          ))}
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          borderTop: "1px solid #E2E8F0",
          marginTop: "16PX",
          transform: "rotate(-0.09deg)",
        }}
      />

      <Collapse
        // defaultActiveKey={["1"]}
        ghost
        // expandIconPosition="right"
        // expandIcon={() => <></>}
        activeKey={accordionState}
        className="bounty-details-accordion"
      >
        <Panel
          header={
            <TextButton
              clickHandler={() =>
                accordionState[0] === "1"
                  ? setAccordionState([""])
                  : setAccordionState(["1"])
              }
              isActive={accordionState[0] === "1"}
              buttonText="Bounty Details"
            />
          }
          key="1"
          showArrow={false}
        >
          <StyledDiv>
            {bountyDetails?.bountyDescription ? (
              ReactHtmlParser(bountyDetails?.bountyDescription)
            ) : (
              <p>No description found</p>
            )}
          </StyledDiv>
          {bountyDetails?.bountyAttachments?.length > 0 && <p>Attachments :</p>}
          <Attachment files={bountyDetails?.bountyAttachments} />
        </Panel>
      </Collapse>
      {bountyDetails?.bountyType === "TWITTER" ? (
        <TwitterTaskDetail
          bountyLoading={bountyLoading}
          userSubmission={bountyDetails?.userSubmission}
          canEdit={!bountyDetails?.canSubmit}
          twitterTasks={bountyDetails?.twitterTasks}
          handleBountyTasksVerification={handleBountyTasksVerification}
          loading={loading}
          tweetUrls={tweetUrls}
          userTwitterDetails={bountyDetails?.userTwitterDetails}
          setIsPollingPaused={setIsPollingPaused}
          reloadBountyDetails={reloadBountyDetails}
        />
      ) : null}
      {bountyDetails?.bountyType === "QUIZ" && quizModalOpen ? (
        <VerifyQuiz
          isModalOpen={quizModalOpen}
          questions={bountyDetails.quiz}
          handleModalClose={() => {
            setQuizModalOpen(false);
          }}
          handleSubmitQuiz={handleSubmitQuiz}
        />
      ) : null}
      {bountyDetails?.bountyType === "QUIZ" && quizResultModalOpen ? (
        <QuizResult
          isModalOpen={quizResultModalOpen}
          marksPercent={marksPercent}
          handleModalClose={() => {
            setQuizResultModalOpen(false);
          }}
          handleRetakeQuiz={() => {
            setQuizResultModalOpen(false);
            setQuizModalOpen(true);
          }}
          handleTwitterShare={handleTwitterShare}
        />
      ) : null}
      {bountyDetails?.bountyType === "DISCORD" ? (
        <StyledTaskContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {BountyTypeMenu[bountyDetails?.bountyType].icon}
            {BountyTypeMenu[bountyDetails?.bountyType].label}
          </div>
          {!bountyDetails.canSubmit ? null : !bountyDetails?.userSubmission
              ?.length || !bountyDetails?.userSubmission?.[0]?.isWinner ? (
            <div style={{ display: "flex", columnGap: "8px" }}>
              <SecondaryBountyTaskButton
                type="primary"
                onClick={() => {
                  handleBountyTasksVerification();
                }}
              >
                Verify
              </SecondaryBountyTaskButton>
              <PrimaryBountyTaskButton
                type="primary"
                onClick={() => {
                  window.open(bountyDetails?.link, "_blank");
                }}
              >
                Join
              </PrimaryBountyTaskButton>
            </div>
          ) : null}
        </StyledTaskContainer>
      ) : null}
      {bountyDetails?.bountyType === "TELEGRAM" ? (
        <StyledTaskContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {BountyTypeMenu[bountyDetails?.bountyType].icon}
            {BountyTypeMenu[bountyDetails?.bountyType].label}
          </div>
          {!bountyDetails.canSubmit ? null : !bountyDetails?.userSubmission
              ?.length || !bountyDetails?.userSubmission?.[0]?.isWinner ? (
            <div style={{ display: "flex", columnGap: "8px" }}>
              <SecondaryBountyTaskButton
                type="primary"
                onClick={() => {
                  handleBountyTasksVerification();
                }}
              >
                Verify
              </SecondaryBountyTaskButton>{" "}
              <PrimaryBountyTaskButton
                type="primary"
                onClick={() => {
                  window.open(bountyDetails?.link, "_blank");
                }}
              >
                Join
              </PrimaryBountyTaskButton>
            </div>
          ) : null}
        </StyledTaskContainer>
      ) : null}
      {bountyDetails?.bountyType === "SNAPSHOT" ? (
        <StyledTaskContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {BountyTypeMenu[bountyDetails?.bountyType].icon}
            {BountyTypeMenu[bountyDetails?.bountyType].label}
          </div>
          {!bountyDetails.canSubmit ? null : !bountyDetails?.userSubmission
              ?.length || !bountyDetails?.userSubmission?.[0]?.isWinner ? (
            <div style={{ display: "flex", columnGap: "8px" }}>
              <SecondaryBountyTaskButton
                type="primary"
                onClick={() => {
                  handleBountyTasksVerification();
                }}
              >
                Verify
              </SecondaryBountyTaskButton>
              <PrimaryBountyTaskButton
                type="primary"
                onClick={() => {
                  window.open(bountyDetails?.link, "_blank");
                }}
              >
                Vote
              </PrimaryBountyTaskButton>
            </div>
          ) : null}
        </StyledTaskContainer>
      ) : null}
      {bountyDetails?.bountyType === "QUIZ" ? (
        <StyledTaskContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {BountyTypeMenu[bountyDetails?.bountyType].icon}
            Take this quiz and test your knowledge
          </div>
          {bountyDetails.canSubmit &&
          !(
            bountyDetails?.userSubmission[0]?.isWinner ||
            bountyDetails?.userSubmission[0]?.status === "APPROVED" ||
            bountyDetails?.userSubmission[0]?.status === "PAYMENT_SUCCESSFUL" ||
            bountyDetails?.userSubmission[0]?.status === "PAYMENT_PENDING"
          ) ? (
            <div style={{ display: "flex", columnGap: "8px" }}>
              <PrimaryBountyTaskButton
                type="primary"
                onClick={() => setQuizModalOpen(true)}
              >
                Take Quiz
              </PrimaryBountyTaskButton>
            </div>
          ) : null}
        </StyledTaskContainer>
      ) : null}
      {bountyDetails?.bountyType === "INVITE" ? (
        <StyledTaskContainer>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <span>{BountyTypeMenu[bountyDetails?.bountyType].icon}</span>
            Invited{" "}
            {bountyDetails?.userSubmission?.length
              ? bountyDetails?.userSubmission?.[0]?.completedSubtasks
              : 0}
            /{bountyDetails.link} friends
          </div>
          {!bountyDetails.canSubmit ? null : (
            <div style={{ display: "flex", columnGap: "8px" }}>
              <PrimaryBountyTaskButton
                type="primary"
                icon={<CopyOutlined />}
                onClick={() => {
                  copyTextToClipboard(bountyDetails.inviteLink).then((res) => {
                    toast.success("Invite link copied successfully");
                  });
                }}
              >
                Copy invite
              </PrimaryBountyTaskButton>
            </div>
          )}
        </StyledTaskContainer>
      ) : null}
      {bountyDetails?.bountyType === "INVITE" ? (
        <h5
          style={{
            fontSize: "12px",
            color: "#64748B",
            marginTop: "4px",
            marginBottom: "16px",
            marginLeft: "8PX",
          }}
        >
          Invited friends needs to complete atleast 1 bounty for invite to count
        </h5>
      ) : null}

      {bountyDetails.bountyType === "DISCORD" &&
      !userDetails?.isDiscordConnected ? (
        <Button
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "12px",
          }}
          type="primary"
          onClick={() => {
            if (userDetails) navigate("/app/profile");
            if (!userDetails) {
              showLoginModal();
            } else if (!isCurrentDaoJoined) {
              setShowJoinCommunityModal(true);
              return;
            }
          }}
          icon={
            <IconWrapper>
              <DiscordIcon />
            </IconWrapper>
          }
        >
          Connect discord
        </Button>
      ) : null}
      {bountyDetails.bountyType === "SNAPSHOT" &&
      !userDetails?.isMetamaskConnected ? (
        <Button
          style={{
            width: "100%",
            borderRadius: "8px",
            marginTop: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
          }}
          type="primary"
          onClick={() => navigate("/app/profile")}
        >
          <WalletIcon className="w-4 " />
          Connect Wallet
        </Button>
      ) : null}
      {!bountyDetails?.isExpired &&
      bountyDetails?.taskPoint &&
      bountyDetails?.userSubmission?.length ? (
        bountyDetails?.userSubmission?.length &&
        bountyDetails?.userSubmission?.[0]?.rewardClaimed ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // gap: "8px",
              border: "1px solid #E5E7EB",
              borderRadius: "8px",
              marginTop: "24px",
            }}
          >
            <div
              style={{
                opacity:
                  !bountyDetails?.userSubmission.length &&
                  !bountyDetails?.userSubmission[0]?.isWinner
                    ? "0.7"
                    : "1",
                cursor:
                  !bountyDetails?.userSubmission.length &&
                  !bountyDetails?.userSubmission[0]?.isWinner
                    ? "inherit"
                    : "pointer",
                width: "100%",
                background: "#ECFDF5",
                display: "flex",
                alignItems: "center",
                columnGap: "4px",
                padding: "10px 10px",
                color: "#10B981",
                borderRadius: "8px 8px 0px",
              }}
            >
              {/* <IconWrapper>
                <CheckCircleFilled
                  style={{ color: "#0E9F6E", fontSize: "16px" }}
                />
              </IconWrapper>{" "} */}
              <h5
                style={{
                  marginBottom: "0px",
                  fontWeight: "400",
                  color: "#059669",
                }}
              >
                {" "}
                ✅ Reward claimed
              </h5>
            </div>
            {bountyDetails.nftRewardMetadata ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 10px",
                  // borderRadius: "8px",
                  borderBottom: "1px solid #E5E7EB",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "4px" }}
                >
                  {/* <NFT className="text-blue-500" /> */}
                  🖼️
                  <h4 style={{ marginBottom: "0px", fontWeight: "400" }}>
                    NFT Reward
                  </h4>
                  <i
                    class="fas fa-external-link"
                    style={{
                      fontSize: "10px",
                      color: "#374151",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open("https://portfolio.metamask.io/?tab=nfts")
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const finalUrl =
                      "https://polygonscan.com/address/" +
                      userDetails?.walletAddress +
                      "#tokentxnsErc721";

                    window.open(finalUrl);
                  }}
                >
                  {/* <i class="fas fa-receipt" style={{ color: "#374151" }}></i> */}
                  <h5 style={{ marginBottom: "0px", color: "#374151" }}>
                    View Transaction
                  </h5>
                </div>
              </div>
            ) : null}
            {bountyDetails?.taskPoint ? (
              <div
                style={{ padding: "10px 10px" }}
              >{`⭐️${bountyDetails.taskPoint}XP`}</div>
            ) : null}
          </div>
        ) : bountyDetails?.bountyType === "SUBMISSION" &&
          !bountyDetails?.userSubmission?.length ? null : (
          <Button
            disabled={
              bountyDetails?.userSubmission.length === 0
                ? true
                : bountyLoading
                ? true
                : !(
                    bountyDetails?.userSubmission[0]?.isWinner ||
                    bountyDetails?.userSubmission[0]?.status === "APPROVED" ||
                    bountyDetails?.userSubmission[0]?.status ===
                      "PAYMENT_SUCCESSFUL" ||
                    bountyDetails?.userSubmission[0]?.status ===
                      "PAYMENT_PENDING"
                  )
                ? true
                : false
            }
            onClick={() => {
              if (userDetails.isMetamaskConnected) {
                handleClaimReward();
              } else {
                toast.info("Connect your metamask wallet to claim the NFT");
                setShowSubmitWalletCheckModal(true);
              }
            }}
            style={{
              opacity:
                !bountyDetails?.userSubmission.length ||
                bountyLoading ||
                !(
                  bountyDetails?.userSubmission[0]?.isWinner ||
                  bountyDetails?.userSubmission[0]?.status === "APPROVED" ||
                  bountyDetails?.userSubmission[0]?.status ===
                    "PAYMENT_SUCCESSFUL" ||
                  bountyDetails?.userSubmission[0]?.status === "PAYMENT_PENDING"
                )
                  ? "0.7"
                  : "1",
              cursor:
                !bountyDetails?.userSubmission.length ||
                bountyLoading ||
                !(
                  bountyDetails?.userSubmission[0]?.isWinner ||
                  bountyDetails?.userSubmission[0]?.status === "APPROVED" ||
                  bountyDetails?.userSubmission[0]?.status ===
                    "PAYMENT_SUCCESSFUL" ||
                  bountyDetails?.userSubmission[0]?.status === "PAYMENT_PENDING"
                )
                  ? "inherit"
                  : "pointer",
              width: "100%",
              background: "#3B82F6",
              marginTop: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              color: "white",
              borderRadius: "12px",
            }}
          >
            {!bountyDetails?.userSubmission.length ||
            bountyLoading ||
            !(
              bountyDetails?.userSubmission[0]?.isWinner ||
              bountyDetails?.userSubmission[0]?.status === "APPROVED" ||
              bountyDetails?.userSubmission[0]?.status ===
                "PAYMENT_SUCCESSFUL" ||
              bountyDetails?.userSubmission[0]?.status === "PAYMENT_PENDING"
            ) ? (
              <IconWrapper>
                <LockClosedIcon />
              </IconWrapper>
            ) : null}{" "}
            Claim Reward
          </Button>
        )
      ) : null}

      {/* {canEdit && bountyDetails?.taskPoint ? (
        <h6
          style={{
            fontSize: "12px",
            color: "#64748B",
            marginTop: "8px",
            textAlign: "center",
            fontWeight: "400",
          }}
        >
          You can't submit bounties as admin
        </h6>
      ) : null} */}
      {userDetails && !isCurrentDaoJoined ? (
        <Row style={{ width: "100%" }}>
          <div
            style={{
              padding: "12px 16px",
              // background: "#FFFBEB",
              borderRadius: "12px",
              marginBottom: "12px",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "100%",
              columnGap: "8px",
              // border: "1px solid #F59E0B",
            }}
          >
            <IconWrapper>
              <ExclamationCircleFilled
                style={{ fontSize: "18px", color: "#F59E0B" }}
              />
            </IconWrapper>
            <h5
              style={{
                color: "#F59E0B",
                lineHeight: "16px",
                marginBottom: "0px",
              }}
            >
              Please join the community before submitting a bounty.
            </h5>
          </div>
        </Row>
      ) : null}
      {bountyDetails?.canSubmit &&
      !bountyDetails?.isExpired &&
      !bountyDetails?.userSubmission?.length &&
      bountyDetails?.bountyType === "BOUNTY_SUBMISSION" &&
      !bountyDetails?.bountySubmittedDate ? (
        <Button
          onClick={() => {
            if (userDetails) {
              if (!isCurrentDaoJoined) setShowJoinCommunityModal(true);
              else {
                // check if the user has the required wallet connected
                switch (bountyDetails?.network?.toLowerCase()) {
                  case "aptos":
                    return userDetails.isAptosConnected
                      ? setSubmitMountyModalVisible(true)
                      : setShowSubmitWalletCheckModal(true);
                  case "solana":
                    return userDetails.isPhantomConnected
                      ? setSubmitMountyModalVisible(true)
                      : setShowSubmitWalletCheckModal(true);
                  case "solana_devnet":
                    return userDetails.isPhantomConnected
                      ? setSubmitMountyModalVisible(true)
                      : setShowSubmitWalletCheckModal(true);

                  default:
                    return userDetails.isMetamaskConnected
                      ? setSubmitMountyModalVisible(true)
                      : setShowSubmitWalletCheckModal(true);
                }
              }
            } else showLoginModal();
          }}
          disabled={!isCurrentDaoJoined}
          style={{
            width: "100%",
            background: "#3B82F6",
            marginTop: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          Submit
        </Button>
      ) : null}

      {isMobile && (
        <div
          style={{
            display: "flex",
            columnGap: "8px",
            marginTop: "16px",
            justifyContent: "flex-end",
          }}
        >
          <Share entity="bounty" />

          <SlidingContextSidebar
            entityId={bountyId}
            entityType="BOUNTY"
            canEdit={canCreateBounty}
          />
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "16px",
        }}
      >
        <Tabs
          onChange={(key) => setSelectedTab(key)}
          activeKey={selectedTab}
          style={{ width: "100%" }}
          tabBarExtraContent={{
            right: !isMobile && (
              <div
                style={{
                  display: "flex",
                  columnGap: "8px",
                  justifyContent: "flex-end",
                }}
              >
                <Share entity="bounty" />
                <SlidingContextSidebar
                  entityId={bountyId}
                  entityType="BOUNTY"
                  canEdit={canCreateBounty}
                />
              </div>
            ),
          }}
        >
          {(bountyDetails.bountyType !== "INVITE" &&
            bountyDetails.bountyType !== "CLAIM") ||
          bountyDetails?.userSubmission?.length ? (
            <Tabs.TabPane
              tab={
                submissions?.length > 0 || !!!bountyDetails.totalSubmissions
                  ? `Submissions (${bountyDetails.totalSubmissions})`
                  : "Submissions"
              }
              key="1"
            >
              {" "}
              {bountyDetails.bountyType === "BOUNTY_SUBMISSION" ? (
                submissions.length > 0 ||
                bountyDetails?.userSubmission.length ? (
                  <>
                    {submissions?.length && (
                      <>
                        {submissions.map((singleSubmission) => {
                          if (
                            !isAdmin &&
                            singleSubmission.userId !== userDetails.id
                          )
                            return null;
                          return (
                            <FeedCard
                              userId={singleSubmission.userId}
                              onCardClick={() => {
                                openViewBountySubmissionModal(
                                  singleSubmission.bountySubmissionId
                                );
                              }}
                              key={singleSubmission.bountySubmissionId}
                              userImage={
                                singleSubmission.bountySubmissionUserProfilePicture
                              }
                              userName={
                                singleSubmission.bountySubmissionUserName
                              }
                              cardTime={singleSubmission.bountyCreatedAt}
                              cardTitle={singleSubmission.bountySubmissionTitle}
                              cardDescription={
                                singleSubmission.bountySubmissionDescription
                              }
                              isWinner={true}
                              footer={
                                <div
                                  style={{
                                    color: "#6B7280",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <div
                                    style={{
                                      border: "1px solid #E5E7EB",
                                      padding: "8px 12px",
                                      borderRadius: "5rem",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "inline-block",
                                        height: "8px",
                                        width: "8px",
                                        backgroundColor:
                                          BOUNTY_SUBMISSION_STATUS_COLORS[
                                            singleSubmission?.status
                                          ] || "#D9D9D9",
                                        borderRadius: "20px",
                                        marginRight: "5px",
                                      }}
                                    ></span>{" "}
                                    {titleCase(
                                      singleSubmission?.status
                                        ?.toLowerCase()
                                        .split("_")
                                        .join(" ")
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      border: "1px solid #E5E7EB",
                                      padding: "8px 12px",
                                      borderRadius: "5rem",
                                      marginRight: "8px",
                                    }}
                                  >
                                    <img src={CommentIcon} alt="" />{" "}
                                    {singleSubmission.commentCount}
                                  </div>
                                </div>
                              }
                            />
                          );
                        })}
                        {!isAdmin && (
                          <FeedCard
                            userId="1"
                            additionalClassName="blurred"
                            userImage={dummyAvatar}
                            userName={"Daolens user"}
                            cardTime={new Date()}
                            cardTitle={"Submission"}
                            cardDescription={
                              "This is a description for submission"
                            }
                            footer={
                              <div
                                style={{
                                  color: "#6B7280",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div
                                  style={{
                                    border: "1px solid #E5E7EB",
                                    padding: "8px 12px",
                                    borderRadius: "5rem",
                                    marginRight: "8px",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-block",
                                      height: "8px",
                                      width: "8px",
                                      backgroundColor: "#D9D9D9",
                                      borderRadius: "20px",
                                      marginRight: "5px",
                                    }}
                                  ></span>{" "}
                                  Never Started
                                </div>
                                <div
                                  style={{
                                    border: "1px solid #E5E7EB",
                                    padding: "8px 12px",
                                    borderRadius: "5rem",
                                    marginRight: "8px",
                                  }}
                                >
                                  <img src={CommentIcon} alt="" /> 0
                                </div>
                              </div>
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <p>No submissions yet</p>
                )
              ) : (
                <div
                  style={{
                    display: "flex",
                    rowGap: "8px",
                    flexDirection: "column",
                  }}
                >
                  {bountyDetails?.userSubmission?.length
                    ? bountyDetails?.userSubmission?.map((ele) => {
                        return (
                          <ProfileCardPopup
                            userId={ele?.userId}
                            key={ele?.userId}
                          >
                            <div
                              style={{
                                columnGap: "8px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 12px",
                                border: "1px solid #f3f3f3",
                                borderRadius: "12px",
                              }}
                              onClick={() =>
                                navigate(`/app/profile/${ele.userId}`)
                              }
                            >
                              <img
                                src={
                                  ele.bountySubmissionUserProfilePicture
                                    ? ele.bountySubmissionUserProfilePicture
                                    : defaultUser
                                }
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                              <span>{ele.bountySubmissionUserName}</span>{" "}
                            </div>
                          </ProfileCardPopup>
                        );
                      })
                    : null}
                  {submissions.length
                    ? submissions.map((ele) => {
                        return (
                          <ProfileCardPopup
                            userId={ele?.userId}
                            key={ele?.userId}
                          >
                            <div
                              onClick={() =>
                                navigate(`/app/profile/${ele.userId}`)
                              }
                              style={{
                                columnGap: "8px",
                                display: "flex",
                                alignItems: "center",
                                padding: "8px 12px",
                                border: "1px solid #f3f3f3",
                                borderRadius: "12px",
                              }}
                            >
                              <img
                                src={
                                  ele.bountySubmissionUserProfilePicture
                                    ? ele.bountySubmissionUserProfilePicture
                                    : defaultUser
                                }
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  objectFit: "cover",
                                  borderRadius: "50%",
                                }}
                              />
                              <span>{ele.bountySubmissionUserName}</span>{" "}
                            </div>
                          </ProfileCardPopup>
                        );
                      })
                    : null}
                </div>
              )}
            </Tabs.TabPane>
          ) : null}
          <Tabs.TabPane tab={"Discussions"} key="2">
            <CommentSection
              entity="bounty"
              sortBy={sortBy}
              setSortBy={setSortBy}
              sortingTypeList={DEFAULT_SORTING_TYPE_LIST}
              allComments={comments}
              postComment={postComment}
              fetchComments={fetchComments}
              authorUserName={bountyDetails.userName}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>

      {isViewSubmissionModalOpen && (
        <ViewBountySubmissionModal
          daoId={daoId}
          isCompleted={isCompleted}
          isPaid={isPaid}
          isModalOpen={isViewSubmissionModalOpen}
          userSubmission={bountyDetails?.userSubmission?.length}
          openModalHandler={openViewBountySubmissionModal}
          closeModalHandler={closeViewBountySubmissionModal}
          bountyDetails={
            submissions?.length
              ? submissions[currentBountySubmissionId]
              : bountyDetails?.userSubmission[0]
          }
          setSubmitMountyModalVisible={setSubmitMountyModalVisible}
          setBountySubmissionDataPrefill={setBountySubmissionDataPrefill}
          bountyAmount={bountyDetails?.bountyAmount}
          bountyId={bountyDetails?.bountyId}
          fetchSubmissions={fetchSubmissions}
          symbol={bountyDetails?.symbol}
          chainId={bountyDetails?.chainId}
          contractAddress={bountyDetails?.contractAddress}
          network={bountyDetails?.network}
          decimals={bountyDetails?.decimals}
          reviewers={bountyDetails?.bountyReviewers}
        />
      )}
      {isViewSubtaskModalOpen && (
        <ViewBountySubtaskModal
          isModalOpen={isViewSubtaskModalOpen}
          openModalHandler={openViewBountySubtaskModal}
          closeModalHandler={closeViewBountySubtaskModal}
          bountyDetails={subTasks[currentBountySubtaskId]}
          canShowNext={subTasks[currentBountySubtaskId + 1]}
          canShowPrevious={subTasks[currentBountySubtaskId - 1]}
          setcurrentBountySubmissionId={setcurrentBountySubtaskId}
          currentBountySubmissionId={currentBountySubtaskId}
        />
      )}

      {submitBountyModalVisible ? (
        <BountySubmit
          bountyId={bountyId}
          visible={submitBountyModalVisible}
          handleCancel={() => setSubmitMountyModalVisible(false)}
          handleSuccess={fetchSubmissions}
          autoValidate={bountyDetails.autoValidation}
          bountySubmissionDataPrefill={bountySubmissionDataPrefill}
        />
      ) : null}
      {submitBountyGuidelinesModalVisible ? (
        <BountySubmitGuidelines
          visible={submitBountyGuidelinesModalVisible}
          handleCancel={() => {
            setSubmitBountyGuidelinesModalVisible(false);
          }}
          handleSuccess={() => {
            setSubmitBountyGuidelinesModalVisible(false);
            setSubmitMountyModalVisible(true);
          }}
          bountyId={bountyId}
          note={bountyDetails?.bountySubmissionNote}
        />
      ) : null}
      <BountySubmitWalletCheck
        isModalOpen={showSubmitWalletCheckModal}
        closeModalHandler={() => {
          setShowSubmitWalletCheckModal(false);
        }}
        requiredNetwork={bountyDetails?.network?.toLowerCase()}
      />
    </div>
  );
}

export default BountyDetails;

import { ChainId } from "@usedapp/core";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import WalletConnectProvider from "@walletconnect/web3-provider/dist/umd/index.min.js";

export const config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]:
      "https://mainnet.infura.io/v3/00fc42173c364876831197c78843655d",
  },
};

export const walletConnect = new WalletConnectProvider({
  infuraId: "d8df2cb7844e4a54ab0a782f608749dd",
});

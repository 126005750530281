import { Skeleton } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import { Modal } from "../../../../styles/ModalStyles";
import { AnnouncementType } from "../../../../utils/Announcements";
import { StyledModalWrapper } from "../../../CreateBountyModal";
import AnnouncementCard from "./AnnouncementCard";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  data: AnnouncementType[];
};

const OldAnnouncementsModal = ({
  isOpen,
  setIsOpen,
  data,
  isLoading,
}: Props) => {
  const { isMobile } = useWindowSize();
  return (
    <StyledModalWrapper>
      <Modal
        visible={isOpen}
        destroyOnClose
        // @ts-expect-error
        isMobile={isMobile}
        centered={true}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.5",
          backdropFilter: "blur(20px)",
        }}
        width="700px"
        onCancel={() => setIsOpen(false)}
        footer={null}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "20px",
          }}
        >
          <h3 style={{ margin: "0" }}>📣 Old announcements</h3>
          <p style={{ margin: "0" }}>These are not visible to the members</p>
          {isLoading && <Skeleton active paragraph={{ rows: 4 }} />}
          {!isLoading && data?.length === 0 && (
            <p style={{ margin: 0, fontWeight: 500, fontSize: "16px" }}>
              No annoucements found.
            </p>
          )}
          {!isLoading && data?.length !== 0 && (
            <div
              style={{
                display: "grid",
                gap: "16px",
                gridTemplateColumns: "1fr 1fr 1fr",
              }}
            >
              {data?.map((announcement: any) => (
                <AnnouncementCard
                  key={announcement.id}
                  announcement={announcement}
                  canEdit={false}
                  isOld={true}
                />
              ))}
            </div>
          )}
        </div>
      </Modal>
    </StyledModalWrapper>
  );
};

export default OldAnnouncementsModal;

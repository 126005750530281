import { Button } from "antd";
import { useNavigate } from "react-router";
import moment from "moment";
import { ReactComponent as BountyIcon } from "../../../assets/images/search/bounty.svg";
import SearchCard from "../SearchCard";
import { CloseCircleOutlined } from "@ant-design/icons";
import IconWrapper from "../../IconWrapper";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
const Project = ({
  data: projectDetails,
  handleClose,
  onClick,
  handleDeleteLinkedItem,
}) => {
  const navigate = useNavigate();
  const { dao, id, user_id, user_name } = projectDetails;
  const bountyTitle = projectDetails?.title.substring(0, 56);
  const isOpen =
    new Date(projectDetails?.submissionDate).getTime() > new Date().getTime();

  return (
    <SearchCard
      isLinkedItem={!!handleDeleteLinkedItem}
      onClick={() => {
        if (onClick) {
          if (handleDeleteLinkedItem) {
            return;
          } else onClick({ data: projectDetails });
        } else {
          navigate(`/app/dao/${dao.name}/${dao?.id}/bounty/${id}`);
          handleClose();
        }
      }}
    >
      <DocumentTextIcon className="w-6 text-red-500" />
      {handleDeleteLinkedItem ? (
        <Button
          className="borderless-button"
          type="text"
          shape="circle"
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
          icon={
            <IconWrapper width={"initial"}>
              <CloseCircleOutlined />
            </IconWrapper>
          }
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteLinkedItem(projectDetails.id);
          }}
        ></Button>
      ) : null}
      <div
        style={{
          fontWeight: 500,
          lineHeight: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "#64748B",
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            marginBottom: "4px",
          }}
        >
          <div
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            Project
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            {dao?.name && (
              <>
                <div
                  style={{ display: "inline-block" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    navigate(`/app/dao/${dao.name}/${dao?.id}`);
                  }}
                >
                  {dao?.name}
                </div>
                <span style={{ margin: "0 8px" }}>&#8226;</span>
              </>
            )}
            {user_id && (
              <>
                <div
                  style={{ display: "inline-block" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    navigate(`/app/profile/${user_id}`);
                  }}
                >
                  {user_name}
                </div>
                <span style={{ margin: "0 8px" }}>&#8226;</span>
              </>
            )}
            {moment(projectDetails?.createdAt).format("DD MMM")}
          </div>
        </div>

        {bountyTitle}
        {projectDetails?.title.length > 56 ? "..." : ""}
      </div>
    </SearchCard>
  );
};

export default Project;

import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useGating from "../../hooks/useGating";
import { useDaoContext } from "../../layouts/app/DaoContext";
import {
  CreateNewSpaceDataType,
  updateSpace,
  validateCreateSpacesStep1,
} from "../../lib/utils/spaces";
import GatingModal from "../Gating/GatingModal";
import CreateModalDetails from "./CreateModalDetails";

const EditSpaceProfile = () => {
  const { daoDetails, reload } = useDaoContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [profilePicture, setProfilePicture] = useState<string | null>(null);
  const gatingState = useGating();

  const [emojiProfilePicture, setEmojiProfilePicture] = useState<{
    emoji: string;
    background: string;
  } | null>(null);

  const [isUploadCompUpdated, setIsUploadCompUpdated] = useState(false);

  const { isLoading, mutate: update } = useMutation({
    mutationFn: (
      data: Omit<CreateNewSpaceDataType, "visibilityData" | "parentDaoId"> & {
        daoId: string;
      }
    ) => updateSpace(data, gatingState),
    onError: (error) => {
      console.error(error);
      toast.error("Failed to update space. Try again later.");
    },
    onSuccess: () => {
      toast.success("Space details updated");
      setIsUploadCompUpdated(false);
      reload();
    },
  });

  useEffect(() => {
    if (!daoDetails) return;
    setName(daoDetails?.daoName);
    setDescription(daoDetails?.description);
    setProfilePicture(daoDetails?.profilePicture);
    setEmojiProfilePicture(
      daoDetails?.iconEmoji
        ? {
            background: daoDetails?.iconBackground,
            emoji: daoDetails?.iconEmoji,
          }
        : null
    );
    gatingState.setGatingState(
      daoDetails?.subDaoGatingConditionId,
      daoDetails.subDaoGatingCondition
    );
  }, [daoDetails]);

  const handleSubmit = () => {
    const isValid = validateCreateSpacesStep1({
      description,
      name,
      emojiProfilePicture: profilePicture
        ? undefined
        : emojiProfilePicture || undefined,
      profilePicture: profilePicture || undefined,
    });

    if (!isValid) return;

    update({
      daoId: daoDetails?.daoId,
      details: {
        description,
        name,
        emojiProfilePicture: profilePicture
          ? undefined
          : emojiProfilePicture || undefined,
        profilePicture: profilePicture || undefined,
      },
    });
  };

  return (
    <div className="flex flex-col bg-white rounded-xl">
      <CreateModalDetails
        description={description}
        emojiProfilePicture={emojiProfilePicture}
        name={name}
        profilePicture={profilePicture}
        setDescription={setDescription}
        setEmojiProfilePicture={setEmojiProfilePicture}
        setName={setName}
        setProfilePicture={setProfilePicture}
        isUploadCompUpdated={isUploadCompUpdated}
        setIsUploadCompUpdated={setIsUploadCompUpdated}
      />
      <div className="flex items-center px-6 pb-5 border-0 border-t border-solid pt-5 border-gray-100 justify-between">
        <GatingModal finalGatingState={gatingState} />
        <Button
          style={{
            fontSize: "14px",
            padding: "6px 16px",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          type="primary"
          color="#3B82F6"
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default EditSpaceProfile;

import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Popover, Row, Tag } from "antd";
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import PresetDetails from "../../Gating/PresetDetails";
import IconWrapper from "../../IconWrapper";


function GatingConditionPopover({ children, gatingConditions }) {
  console.log("gating", gatingConditions);
  const { isMobile } = useWindowSize();
  const content = () => {
    return (
      <PresetDetails gatingCondition={gatingConditions} />
    );
  };
  return (
    <Popover
      content={content}
      title=""
      trigger={!isMobile ? "hover" : "click"}
      placement="left"
      arrowPointAtCenter={true}
      overlayInnerStyle={{
        minWidth: "300px",
        backgrund: "white",
        maxWidth: "300px",
        borderRadius: "12px",
        padding: "0px 0px"
      }}

    >
      <span>
        <IconWrapper>
          <LockClosedIcon style={{ color: "#64748B" }} />
        </IconWrapper>
      </span>
    </Popover>
  );
}

export default GatingConditionPopover;

import { useState } from "react";
import styled from "styled-components";
import { HoverCard } from "../Search/SearchModal";
import Approval from "./VoteType/Approval";
import Ranked from "./VoteType/Ranked";
import SingleChoiceVoting from "./VoteType/SingleChoice";
import Weighted from "./VoteType/Weighted";

export const ChoiceCard = ({ style, isSelected, ...rest }) => {
  return (
    <HoverCard
      style={{
        border: "1px solid",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: "12px",
        font: "normal 500 12px/167% Inter",
        color: "#1A202E",
        height: "36px",
        position: "relative",
        background: isSelected ? "#EBF5FF" : "",
        borderColor: isSelected ? "#3F83F8" : "#F4F2F2",
        ...style,
      }}
      {...rest}
    />
  );
};
const Vote = ({ votingType, choices, selectedChoice, setSelectedChoice }) => {
  switch (votingType) {
    case "basic": {
      return (
        <SingleChoiceVoting
          choices={choices}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      );
    }
    case "single-choice": {
      return (
        <SingleChoiceVoting
          choices={choices}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      );
    }
    case "approval": {
      return (
        <Approval
          choices={choices}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      );
    }
    case "ranked-choice": {
      return (
        <Ranked
          choices={choices}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      );
    }
    case "quadratic": {
      return (
        <Weighted
          choices={choices}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      );
    }
    case "weighted": {
      return (
        <Weighted
          choices={choices}
          selectedChoice={selectedChoice}
          setSelectedChoice={setSelectedChoice}
        />
      );
    }
  }
  return null;
};

export default Vote;

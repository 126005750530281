import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Select } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { DaoContext } from "../../layouts/app/DaoContext";
import IconWrapper from "../IconWrapper";
import CommentBody from "./CommentBody";
import CommentReplyInput from "./CommentReplyInput";
const { Option } = Select;
export const StyledSelect = styled(Select)`
  .ant-select-selector {
    font-size: 12px !important;
    background: #f9fafb !important;
    margin: 0px !important;
    color: #6b7280 !important;
    height: auto !important;
    padding-left: 6px !important;
    .ant-select-arrow {
      color: #6b7280 !important;
    }
    .ant-select-selection-item {
      font-weight: 500 !important;
    }

    .ant-select-item-option-active {
      color: blue !important;
    }
  }
  .ant-select-item {
    border-bottom: 1px solid black;
    color: blue !important;
    font-size: 12px !important;
  }
`;
function CommentSection({
  allComments,
  postComment,
  canEngage = true,
  entity,
  fetchComments,
  sortBy,
  setSortBy,
  sortingTypeList,
  initialText = "",
  authorUserName = "",
  onPostCommentSet = () => {},
  checkIsEnabled = undefined,
  hideBorderTop,
  minimumCharLimit = 1,
}) {
  const { isCurrentDaoJoined, isAdmin } = useContext(DaoContext);

  const [highlightedCommentId, sethighlightedCommentId] = useState("");
  return (
    <>
      {(isCurrentDaoJoined && canEngage) || isAdmin ? (
        <div
          style={{
            marginBottom: "16px",
            // borderTop: hideBorderTop ? "none" : "1px solid #F3F4F6",
            paddingTop:
              hideBorderTop || allComments?.length > 1 ? "0px" : "16px",
          }}
        >
          {allComments?.length > 1 ? (
            <div
              style={{
                background: "#F9FAFB",
                padding: "0px 20px",
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                marginBottom: "16px",
              }}
            >
              <span
                style={{
                  color: "#6B7280",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Sort by:
              </span>
              <StyledSelect
                dropdownStyle={{ borderRadius: "10px", padding: "0px" }}
                style={{ minWidth: "75px" }}
                suffixIcon={
                  <IconWrapper style={{ color: "#6b7280" }}>
                    <ChevronDownIcon width={"12px"} strokeWidth={"3px"} />
                  </IconWrapper>
                }
                listItemHeight={"32px"}
                bordered={false}
                value={sortBy}
                onChange={(value) => {
                  setSortBy(value);
                }}
              >
                {Object.keys(sortingTypeList).map((ele) => {
                  return <Option value={ele}>{ele}</Option>;
                })}
              </StyledSelect>
            </div>
          ) : null}
          <CommentReplyInput
            postComment={postComment}
            parentId="0"
            initialGptText={initialText}
            onPostCommentSet={onPostCommentSet}
            checkIsEnabled={checkIsEnabled}
            minimumCharLimit={minimumCharLimit}
          />
        </div>
      ) : null}

      {allComments.map((singleComment, index) => {
        return (
          <CommentBody
            authorUserName={authorUserName}
            postComment={postComment}
            canEngage={canEngage}
            defaultReplyLevel={1}
            key={singleComment.id}
            commentDetails={singleComment}
            entity={entity}
            isFirst={index === 0}
            fetchParentReplies={fetchComments}
            highlightedCommentId={highlightedCommentId}
            setHighlightedCommentId={sethighlightedCommentId}
            checkIsEnabled={checkIsEnabled}
            minimumCharLimit={minimumCharLimit}
          />
        );
      })}
    </>
  );
}

export default CommentSection;

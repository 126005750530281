import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Input,
  Space,
  Select,
  Modal as AntModal,
  Radio,
  Tag,
  Col,
  Row,
  Tooltip,
} from "antd";
import IconWrapper from "../../IconWrapper";
import { XMarkIcon } from "@heroicons/react/24/outline";
import QuillEditor from "../../QuillEditor";
import { Modal, Footer } from "../../../styles/ModalStyles";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../../api";
import { ReactComponent as StackIcon } from "./../../../images/rectangle-stack-icon.svg";
import { fetchBounty } from "../../../features/bountypage/bountyPageSlice";
import { useParams } from "react-router";

import DueDate from "../../DueDate";
import moment from "moment";
import withCondition from "../../../images/git-fork.png";
import anyMember from "../../../images/globe.png";
import GenericTagSelect from "./../../Common/GenericTagSelect";
import AddBounty from "../AddBounty";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { MD_SCREEN_BREAKPOINT, tokenOptions } from "../../../constants";
import { ReactComponent as ChevronDownIcon } from "../../../assets/images/common/chevrondown.svg";

import styles from "../../Course/course.module.css";
import { getTrunckedString } from "../../Proposal/ProposalCard";
import { checkFileSize, postMixpanel } from "../../../utils";
import { menu } from "./data";
import XPField from "../../Common/form/XPField";
import LinkEntities from "../../Common/LinkEntities";
import { defaultConditionDetails } from "../../Gating/GatingModal";
import useWindowSize from "../../../hooks/useWindowSize";
import { PaperClipIcon } from "../../Icones";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import GatingModal from "../../Gating/GatingModal";
import useGating from "../../../hooks/useGating";
import { confirmOperation } from "../../../utils/common";
import AddNftReward from "../AddNftReward";
const { Option } = Select;
export const cancelText = "Continue editing";
export const confirmText = "Discard changes";

const StyledDiv = styled.div`
  padding: 12px 20px;

  display: flex;
  flex-wrap: wrap;

  .ant-select-selector {
    background-color: #f3f4f6 !important;
    border: none !important;
    border-radius: 8px !important;
    min-width: 175px !important;
  }
  .btn-addBounty {
    border: 1px dashed #94a3b8;
    background: transparent;
    padding: 5px 12px;
    border-radius: 8px;
    color: #64748b;
    font-size: 12px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    align-self: center;
    span {
      white-space: nowrap;
    }
    &:hover {
      border: 1px dashed #1890ff;
      cursor: pointer;
    }
  }
  .with-reward-of {
    margin: 0 5px;
    color: #0f172a;
    white-space: nowrap;
    opacity: 0.6;
  }
  .for {
    margin: 0 5px;
    color: #0f172a;
    opacity: 0.6;
  }
  .direct-assignment-ip {
    width: 8rem;
  }
  .diamond-image {
    width: 1rem;
    aspect-ratio: 1;
  }
  .direct-assignment-ip {
    background-color: #f4f2f2 !important;
    border: none !important;
    border-radius: 8px !important;
  }
  .bountyOption .gatedValue {
    margin-left: 6px;
    margin-top: 5px;
  }

  .selected-token-amt {
    padding: 5px 12px 3px;
    height: 32px;
    background: rgba(30, 192, 57, 0.1);
    border-radius: 8px;
    border: none;
    color: #22c55e;
    font-weight: 600;
    font-size: 12px;
  }
`;

const StyledAttachment = styled.div`
  padding: 0px 20px;
  .attachment-title {
    // font-weight: 600;
    // font-size: 12px;
    margin: 5px 4px;
  }
`;

export const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;

export const BountyFilesAttachment = ({ files, setFiles, setDeletedFiles }) => {
  const [localFiles, setLocalFiles] = useState(files);
  useEffect(() => {
    setLocalFiles(files);
  }, [files.length]);
  const handleFiles = (id) => {
    setFiles((prev) => prev.filter((file) => file.lastModified !== id));
    if (localFiles[id].urlString) {
      setDeletedFiles((prev) => [...prev, localFiles[id].urlString]);
    }
  };
  return (
    <StyledAttachment>
      <h4 className="attachment-title">Attachments:</h4>

      <div style={{ maxHeight: "70px", overflowY: "auto" }}>
        {localFiles.map((file, idx) => (
          <Tag
            style={{
              border: "1px solid #E2E8F0",
              background: "transparent",
              padding: "0.25rem 1rem",
              borderRadius: "1rem",
              marginBottom: "8px",
              display: "inline-flex",
              gap: "4px",
              alignItems: "center",
              color: "#808080",
            }}
            onClose={() => handleFiles(idx)}
            key={idx}
          >
            <IconWrapper width="12px" style={{ marginRight: "5px" }}>
              <DocumentIcon color="#808080" />
            </IconWrapper>

            {getTrunckedString(file.name, 15)}
            <XMarkIcon
              style={{ width: "12px", cursor: "pointer" }}
              onClick={() => {
                handleFiles(file.lastModified);
              }}
            />
          </Tag>
        ))}
      </div>
    </StyledAttachment>
  );
};

const defaultState = {
  assignmentType: "ANY_MEMBER",
  type: Object.keys(menu)[0],
  taskPoint: 100,
  quiz: [
    {
      question: "",
      type: "MULTIPLE_CHOICE",
      options: [
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
        { text: "", isCorrect: false },
      ],
    },
  ],
};
const CreateBountyModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  bountyDetails,
  mode,
  nftData,
}) => {
  const [skillsList, setSkillsList] = useState([]);
  const [selectedTokenId, setSelectedTokenId] = useState(
    bountyDetails?.id ? bountyDetails?.id : ""
  );
  const [selectedTokenAmt, setSelectedTokenAmt] = useState(
    bountyDetails?.bountyAmount ? bountyDetails?.bountyAmount : ""
  );
  const [nftDetails, setNftDetails] = useState(nftData ? nftData : {});
  const [loading, setLoading] = useState(false);
  const [linkedItems, setLinkedItems] = useState([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const { daoDetails, daoId } = useDaoContext();

  const getSkillList = () => {
    customAxios.get("bounty/skills").then((res) => {
      setSkillsList(
        res.data.skills.map((skill) => ({
          ...skill,
          checked: bountyDetails?.bountySkills?.includes(skill.name),
        }))
      );
    });
  };

  const [state, setState] = useState(() => defaultState);

  const [description, setDescription] = useState(
    bountyDetails?.bountyDescription
  );
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const gatingState = useGating();
  console.log("nftData", nftData, nftDetails);

  useEffect(() => {
    getSkillList();
    if (bountyDetails) {
      setState((prev) => ({
        ...prev,
        type: bountyDetails.bountyType,
        title: bountyDetails.bountyTitle,
        taskPoint: bountyDetails.taskPoint,
        ...menu[bountyDetails.bountyType]?.initialState(bountyDetails),
        submissionDate: bountyDetails?.bountyLastSubmissionDate,
      }));
      setDescription(bountyDetails.bountyDescription);
      gatingState.setGatingState(
        bountyDetails.bountyGatingConditionId,
        bountyDetails.bountyGatingCondition
      );
      if (bountyDetails?.bountyAttachments) {
        setFiles(
          bountyDetails?.bountyAttachments.map((attach, idx) => ({
            name: attach.split("/").pop(),
            lastModified: idx,
            urlString: attach,
          }))
        );
      }
    }
  }, [daoId, isModalVisible]);
  const createBounty = (state) => {
    let res = {};

    menu[state.type]?.validation(state, res);
    const formData = new FormData();
    Object.entries(res)?.forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (mode === "edit") {
      formData.append("bountyId", bountyDetails.bountyId);
    }
    formData.append("reviewers", JSON.stringify(["123"]));
    formData.append("status", "TODO");
    formData.append("type", state.type);
    formData.append("daoId", daoId);
    formData.append("assignmentType", "WITH_CONDITION");
    if (linkedItems.length) {
      formData.append(
        "linkedEntities",
        JSON.stringify(
          linkedItems.map((ele) => ({
            linkedType: ele.type,
            linkedId: ele.id,
          }))
        )
      );
    }
    if (state.type === "BOUNTY_SUBMISSION") {
      let selectedSkills = skillsList
        .filter((ele) => ele.checked)
        .map((ele) => ele.name);
      if (selectedSkills.length > 0) {
        formData.append("skills", JSON.stringify(selectedSkills));
      }

      files
        .filter((file) => !file.urlString)
        .map((file) => {
          var blob = file.slice(0, file.size, file.type);
          let newFileName = file.name.split(".").join(`_main.`);
          let newFile = new File([blob], newFileName, { type: file.type });
          return formData.append("files", newFile);
        });
      if (deletedFiles.length > 0)
        formData.append("deleteAttachments", JSON.stringify(deletedFiles));
      if (state.submissionDate)
        formData.append("submissionDate", state.submissionDate);
    }

    if (state.title) formData.append("title", state?.title);
    else {
      toast.error("Please enter the title for bounty");
      return;
    }
    if (description) formData.append("description", description);
    else {
      toast.error("Please provide bounty description");
      return;
    }
    if (state.taskPoint) formData.append("taskPoint", state.taskPoint);
    gatingState.createFormData(formData);

    if (selectedTokenAmt && state.type === "BOUNTY_SUBMISSION")
      formData.append("amount", selectedTokenAmt);
    if (selectedTokenId && state.type === "BOUNTY_SUBMISSION")
      formData.append("bountyTokenId", selectedTokenId);
    if (nftDetails.nftName) formData.append("nftName", nftDetails.nftName);
    if (nftDetails.nftDescription)
      formData.append("nftDescription", nftDetails.nftDescription);

    if (nftDetails.image) {
      let thumbnailFile = new File(
        [
          nftDetails.image.slice(
            0,
            nftDetails.image.size,
            nftDetails.image.type
          ),
        ],
        nftDetails.image.name.split(".").join(`_nft.`),
        { type: nftDetails.image.type }
      );
      formData.append("files", thumbnailFile);
    }

    if (mentionedUsers)
      formData.append("userTagged", JSON.stringify(mentionedUsers));
    setLoading(true);
    customAxios
      .post(`/bounty/${mode === "edit" ? "edit" : "create"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          toast.success(
            mode === "edit"
              ? "Bounty updated successfully"
              : "Bounty created successfully"
          );
          postMixpanel("track", "created_bounty", {
            dao_id: daoId,
            bounty_title: state.title,
          });
          handleCancel();
          onSubmit();
          setState(defaultState);
        } else {
          if (window.location.host.includes("localhost")) console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const clearRewards = () => {
    setSelectedTokenAmt("");
    setSelectedTokenId("");
    setNftDetails({});
  };
  const fileUploadRef = useRef(null);
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  return (
    <StyledModalWrapper>
      <Modal
        width={"840px"}
        isMobile={isMobile}
        centered={true}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.5",
          backdropFilter: "blur(20px)",
        }}
        bodyStyle={{ maxHeight: "640px" }}
        destroyOnClose
        visible={isModalVisible}
        onCancel={() =>
          confirmOperation({
            cancelText: "Discard Changes",
            confirmText: "Continue Editing",
            message: " You will lose the progress if you close this pop-up?",
            title: "Confirm",
            onCancel: () => handleCancel(),
          })
        }
        footer={[
          <Footer key="modal-footer" justifyContent="space-between">
            <Row
              justify="space-between"
              style={{ width: "100%", alignItems: "center" }}
            >
              <Space className="footer-items">
                <input
                  hidden
                  ref={fileUploadRef}
                  type="file"
                  style={{ width: "0", height: "0" }}
                  onClick={() => {
                    fileUploadRef.current.value = null;
                  }}
                  onChange={(e) => {
                    const newFile = e.target.files[0];
                    checkFileSize(newFile.size);
                    setFiles((prev) => [...prev, newFile]);
                  }}
                />

                {state.type === "BOUNTY_SUBMISSION" && (
                  <Button
                    className="borderless-button"
                    onClick={() => {
                      fileUploadRef.current.click();
                    }}
                  >
                    <IconWrapper>
                      <PaperClipIcon color="#808080" />
                    </IconWrapper>
                  </Button>
                )}
                <DueDate
                  defaultValue={state?.submissionDate}
                  onChange={(date, time) => {
                    if (date) {
                      const dateTime = moment(
                        date.format("YYYY-MM-DD") + ` ${time ? time : "23:59"}`
                      ).format("YYYY-MM-DD HH:mmZ");
                      setState((prev) => ({
                        ...prev,
                        submissionDate: dateTime,
                      }));
                    } else
                      setState((prev) => ({ ...prev, submissionDate: null }));
                  }}
                />
                <XPField
                  value={state.taskPoint}
                  onChange={(e) => {
                    setState((prev) => ({ ...prev, taskPoint: e }));
                  }}
                />

                {mode !== "edit" ? (
                  <Tooltip title="Link the discussions, proposals or any entity that led to this bounty">
                    <LinkEntities
                      isModalVisible={linkModalVisible}
                      handleClose={() => {
                        setLinkModalVisible(false);
                      }}
                      linkedItems={linkedItems}
                      setLinkedItems={setLinkedItems}
                    ></LinkEntities>
                    {/* <div
                      style={{
                        color: "rgb(107, 114, 128)",
                        font: "normal 500 14px/20px Inter",
                        background: "#F1F5F9",
                        borderRadius: "8px",
                        padding: "6px 12px",
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        position: "relative",
                      }}
                    ></div> */}

                    <Button
                      className="borderless-button"
                      style={{
                        display: "flex",
                        background:
                          linkedItems?.length > 0 ? "#F1F5F9" : "transparent",
                        color: "rgb(107, 114, 128)",
                        font: "normal 500 14px/20px Inter",
                        padding:
                          linkedItems?.length > 0 ? "6px 12px" : "6px 0px",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        setLinkModalVisible(true);
                      }}
                    >
                      <IconWrapper>
                        <StackIcon />{" "}
                      </IconWrapper>{" "}
                      <span style={{ color: "#64748B", fontSize: "12px" }}>
                        {linkedItems.length ? `(${linkedItems.length})` : null}
                      </span>
                    </Button>
                  </Tooltip>
                ) : null}
              </Space>
              <Button
                className="rounded-corner submit-bounty-button"
                type="primary"
                disabled={loading}
                onClick={() => createBounty(state)}
              >
                {mode === "edit" ? "Update" : "Create"}
              </Button>
            </Row>
          </Footer>,
        ]}
      >
        <Input.TextArea
          autoFocus={true}
          style={{
            paddingLeft: "20px",
            fontSize: "20px",
            paddingRight: "35px",
            fontWeight: "600",
            margin: "10px 0",
            marginTop: "24px",
          }}
          autoSize={{ minRows: "1", maxRows: "3" }}
          placeholder="Add the title of your bounty"
          // size="large"
          maxLength={70}
          className="ip-address bounty-title"
          value={state?.title}
          onChange={(e) => {
            setState((prev) => ({ ...prev, title: e.target.value }));
          }}
          bordered={false}
        />

        <StyledDiv style={{ alignItems: "center", gap: "8PX" }}>
          <Select
            style={{ minWidth: "175px" }}
            onSelect={(value) => {
              if (value !== "BOUNTY_SUBMISSION") {
                clearRewards();
              }
              setState((prev) => ({ ...prev, type: value }));
            }}
            value={state.type}
            suffixIcon={<ChevronDownIcon />}
          >
            {Object.entries(menu).map(([key, menuItem]) => (
              <Option value={key}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {menuItem.icon}
                  {menuItem.label}
                </div>
              </Option>
            ))}
          </Select>
          <span style={{ fontWeight: "400" }}>for</span>
          <GatingModal finalGatingState={gatingState} />

          {state.type === "BOUNTY_SUBMISSION" ? (
            <div style={{ fontWeight: "400" }}>with reward of </div>
          ) : null}
          {state.type === "BOUNTY_SUBMISSION" ? (
            <AddBounty
              selectedTokenId={selectedTokenId}
              selectedTokenAmt={selectedTokenAmt}
              setSelectedTokenId={setSelectedTokenId}
              setSelectedTokenAmt={setSelectedTokenAmt}
            />
          ) : null}
          {state.type === "BOUNTY_SUBMISSION" ? (
            <AddNftReward
              edit={mode === "edit"}
              nftDetails={nftDetails}
              setNftDetails={setNftDetails}
            />
          ) : null}
        </StyledDiv>

        {state.type === "BOUNTY_SUBMISSION" && (
          <Row
            xs={24}
            style={{
              rowGap: "4px",
              marginBottom: "10px",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <GenericTagSelect
              key={2}
              selectedTagColor="#64748B"
              skillsList={skillsList}
              disableAddNewButton={true}
              setSkillsList={setSkillsList}
            />
          </Row>
        )}
        <QuillEditor
          value={description}
          onChange={(e) => {
            setDescription(e);
          }}
          placeholder="Add details of your bounty here"
          setMentionedUsers={setMentionedUsers}
          height="200px"
          style={{
            padding: "0px 0px 4px 0px",
            resize: "none",
            overflowY: "auto",
            margin: "10px 20px",
          }}
          isBordered={true}
          // bordered
        />
        {menu[state.type]?.component(state, setState)}
        {files.length > 0 && (
          <BountyFilesAttachment
            files={files}
            setFiles={setFiles}
            setDeletedFiles={setDeletedFiles}
          />
        )}
      </Modal>
    </StyledModalWrapper>
  );
};

export default CreateBountyModal;

import React from "react";
import DotIcon from "../../../images/dot.svg";
import "../../ComponentStyles/BountyDetails.scss";
import { Collapse, Dropdown, Menu, Modal, Skeleton, Typography } from "antd";
import dummyAvatar from "../../../assets/images/login/defaultUser.jpeg";
import pdfIcon from "../../../assets/iconSvg/pdf.svg";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { useEffect } from "react";
import downloadIcon from "../../../assets/iconSvg/downloadIcon.svg";
import ReactHtmlParser from "react-html-parser";
import styles from "../../PostDetails.module.css";
import { Document, Page } from "react-pdf";
import customAxios from "../../../api";
import GoBackButton from "../../InternalComponents/GoBackButton";
import SmallUserDetails from "../../InternalComponents/SmallUserDetails";
import TextButton from "../../InternalComponents/TextButton";
import { PROJECT_STATUSES } from "../../../constants";
import SubtasksAccordion from "./SubtasksAccordion";
import StatusPill from "./StatusPill";
import { TaskPillWrapper } from "./TaskCard";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import { StyledDotIconContainer } from "../../PostDetails";
import { toast } from "react-toastify";
import EditTaskModal from "../EditTaskModal";
import { cancelText, confirmText } from "../../CreateBountyModal";
import DeleteEntityModal from "../../Common/DeleteEntityModal";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../../Common/ProfileCard";
import { getCurrentDateFromNow } from "../../../utils";
const { Panel } = Collapse;
const { Link } = Typography;

function TaskDetailsPage() {
  const navigation = useDaoLensNavigate();
  // ! Local states
  const [accordionState, setAccordionState] = useState([]);
  const [task, setTask] = useState(null);
  const [showAttachmentLink, setShowAttachmentLink] = useState(false);
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [loading, setLoading] = useState(true);

  const { projectId, taskId } = useParams();
  const { daoDetails, daoId, daoName } = useDaoContext();

  const fetchTask = async () => {
    setLoading(true);
    try {
      const response = await customAxios(
        `/tasks/fetch/details?taskId=${taskId}`
      );
      setTask(response.data.res?.[0]);
    } catch (error) {
      toast.error("Failed to fetch task.");
      console.error(error);
    }
    setLoading(false);
  };

  const updateStatus = async (status) => {
    const prevStatus = task.status;
    try {
      setTask((prev) => ({ ...prev, status }));
      const formData = new FormData();
      formData.append("status", status);
      formData.append("taskId", task.id);
      formData.append("projectId", projectId);
      await customAxios.post("/tasks/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      toast.success("Status updated");
    } catch (error) {
      setTask((prev) => ({ ...prev, status: prevStatus }));
      console.error(error);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchTask();
  }, [updater]);

  const completedSubTasks = task?.subTask?.filter(
    (subTask) => subTask?.status === PROJECT_STATUSES.completed
  )?.length;

  const dateData = {
    label: moment(task?.submissionDate)?.isSame(new Date(), "day")
      ? "Today"
      : moment(task?.submissionDate)?.isSame(moment().add(1, "day"), "day")
      ? "Tomorrow"
      : moment(task?.submissionDate)?.format("D MMM"),
    color:
      moment(task?.submissionDate)?.isSame(new Date(), "day") ||
      moment(task?.submissionDate)?.isBefore(new Date())
        ? "#F05252"
        : moment(task?.submissionDate)?.isSame(moment().add(1, "day"), "day")
        ? "#E3A008"
        : "#64748B",
  };

  const deleteTaskHandler = () => {
    customAxios
      .delete(`/tasks/delete?taskId=${taskId}`)
      .then((res) => {
        toast.success("Task deleted successfully");
        setShowDeleteModal(false);
        navigation(-1);
      })
      .catch((err) => {
        console.log("error");
        console.error(err);
      });
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const menu = (
    <Menu style={{ borderRadius: "8px" }}>
      <Menu.Item onClick={() => setIsEditTaskModalOpen(true)}>Edit</Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            background: "#fff",
            borderRadius: "16px",
            fontSize: "14px",
            gap: "10px",
            padding: "16px 20px 20px",
          }}
        >
          <Skeleton active />
        </div>
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        marginTop: "20px",
      }}
    >
      <DeleteEntityModal
        note="This task will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          deleteTaskHandler();
        }}
      />
      <EditTaskModal
        isModalVisible={isEditTaskModalOpen}
        defaultData={task}
        handleCancel={() => setIsEditTaskModalOpen(false)}
        onSubmit={() => setUpdater((prev) => !prev)}
      />

      <div
        style={{
          display: "flex",
          background: "#fff",
          borderRadius: "16px",
          fontSize: "14px",
          gap: "10px",
          padding: "16px 20px 20px",
        }}
      >
        <p
          style={{
            fontWeight: "600",
            padding: 0,
            margin: 0,
            cursor: "pointer",
          }}
          className="truncate hover-blue"
          onClick={() => {
            window.history.back();
          }}
        >
          {task.projectTitle}
        </p>
        <ChevronRightIcon width={16} color="#000000" strokeWidth={2} />
        <p style={{ fontWeight: "600", padding: 0, margin: 0 }}>Task details</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "5px 5px 0 5px",
        }}
      >
        <GoBackButton
          showIcon
          buttonText={"Go Back"}
          clickHandler={() => {
            navigate(-1);
          }}
          style={{ marginBottom: "12px" }}
        />
        {task.canUpdateStatus && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <StyledDotIconContainer>
              <img
                src={DotIcon}
                alt="dot icon"
                onClick={(e) => {
                  e.preventDefault();
                }}
              />
            </StyledDotIconContainer>
          </Dropdown>
        )}
      </div>
      <div className="bounty-details__wrapper">
        <div className="bounty-details__meta">
          <div className="bounty-details__meta--user-details">
            <div>
              <SmallUserDetails
                avatar={task?.creatorProfilePicture || dummyAvatar}
                name={task.creatorUserName}
                userId={task.creatorUserId}
                url=""
                showSeparator
                postSeparator={
                  <span className="bounty-details__meta--user-details-time">
                    {moment(task.taskCreatedAt).format("hh:mm A")}
                  </span>
                }
              />
            </div>
          </div>
          <h3 className="bounty-details__meta--header">{task.title}</h3>
          <div style={{ display: "flex", gap: "8px" }}>
            <StatusPill
              status={task?.status}
              updateStatus={updateStatus}
              disabled={!task.canUpdateStatus}
            />
            {task?.subTask?.length > 0 && (
              <TaskPillWrapper>
                <CheckCircleIcon width={16} color="#64748B" />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#64748B",
                  }}
                >
                  {completedSubTasks}/{task?.subTask?.length}
                </span>
              </TaskPillWrapper>
            )}
            {task?.submissionDate && (
              <TaskPillWrapper>
                <ClockIcon width={16} color={dateData.color} />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: dateData.color,
                  }}
                >
                  {getCurrentDateFromNow(task?.submissionDate)}
                </span>
              </TaskPillWrapper>
            )}
            {task?.assignee?.length > 0 && (
              <TaskPillWrapper>
                <span
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#64748B",
                  }}
                >
                  Assigned to
                </span>
                {task?.assignee?.map((eachAssignee) => (
                  <ProfileCardPopup userId={eachAssignee?.id}>
                    <img
                      onClick={() =>
                        navigation(`/app/profile/${eachAssignee?.id}`)
                      }
                      src={
                        eachAssignee?.profilePicture
                          ? eachAssignee.profilePicture
                          : dummyAvatar
                      }
                      alt={eachAssignee?.userName}
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "999px",
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                    />
                  </ProfileCardPopup>
                ))}
              </TaskPillWrapper>
            )}
          </div>
        </div>
        <Collapse
          ghost
          activeKey={accordionState}
          className="bounty-details-accordion"
        >
          <Panel
            header={
              <TextButton
                clickHandler={() =>
                  accordionState[0] === "1"
                    ? setAccordionState([""])
                    : setAccordionState(["1"])
                }
                isActive={accordionState[0] === "1"}
                buttonText="Task Details"
              />
            }
            key="1"
            showArrow={false}
          >
            <div>{ReactHtmlParser(task.description)}</div>
            {task.attachments?.length > 0 && <p>Attachments :</p>}
            <div className="view-bounty-submission-modal__attachment--container">
              {task.attachments?.map((singleAttachment, index) => {
                return (
                  <>
                    {showAttachmentLink ? (
                      <Link href={singleAttachment} target="_blank">
                        <img
                          src={singleAttachment}
                          alt="attachment"
                          style={{
                            width: "130px",
                            height: "130px",
                            marginBottom: "12px",

                            borderRadius: "12px",
                            marginRight: "10px",
                          }}
                        />
                      </Link>
                    ) : (
                      <Document
                        file={singleAttachment}
                        onLoadSuccess={() => {}}
                        loading={<span>Loading file</span>}
                        onLoadError={() => {
                          setShowAttachmentLink(true);
                        }}
                        className="view-bounty-submission-modal__attachment"
                      >
                        <Page pageNumber={1} />
                        <div
                          className="view-bounty-submission-modal__attachment--title"
                          onClick={() => {
                            window.open(singleAttachment);
                          }}
                        >
                          <img src={pdfIcon} alt="" />
                          <span>File {index + 1}</span>
                          <img src={downloadIcon} alt="" />
                        </div>
                      </Document>
                    )}
                  </>
                );
              })}
            </div>
          </Panel>
          <hr />
          {task.subTask?.length > 0 && (
            <>
              <Panel
                header={
                  <TextButton
                    clickHandler={() =>
                      accordionState[0] === "2"
                        ? setAccordionState([""])
                        : setAccordionState(["2"])
                    }
                    isActive={accordionState[0] === "2"}
                    buttonText={`Subtasks (${task.subTask?.length})`}
                  />
                }
                key="2"
                showArrow={false}
              >
                {task.subTask?.length > 0 ? (
                  <SubtasksAccordion
                    navigate={navigation}
                    daoName={daoName}
                    subTasks={task.subTask}
                    projectId={projectId}
                    taskId={taskId}
                    daoId={daoId}
                  />
                ) : (
                  <p>No subtasks added</p>
                )}
              </Panel>
            </>
          )}
        </Collapse>
      </div>
    </div>
  );
}

export default TaskDetailsPage;

import React, { Dispatch, SetStateAction, useState } from "react";
import About from "./About";
import Announcement from "./Announcement";
import Documents from "./Documents";

type Props = {
  canEdit: boolean;
  setShowAboutEditor: Dispatch<SetStateAction<boolean>>;
};

const Profile = ({ canEdit, setShowAboutEditor }: Props) => {
  const [isOnlyAboutSection, setIsOnlyAboutSection] = useState(true);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginBottom: "20px",
      }}
    >
      <About
        canEdit={canEdit}
        setShowAboutEditor={setShowAboutEditor}
        isOnlyAboutSection={isOnlyAboutSection}
      />
      <Announcement
        canEdit={canEdit}
        setIsOnlyAboutSection={setIsOnlyAboutSection}
      />
      <Documents
        canEdit={canEdit}
        setIsOnlyAboutSection={setIsOnlyAboutSection}
      />
    </div>
  );
};

export default Profile;

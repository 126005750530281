import React from "react";
import Lottie from "lottie-react";
function GenericAnimation({
  animationData,
  height = 100,
  width = 100,
  style,
  otherProps,
  isStopped,
}) {
  return (
    <Lottie
      isStopped={false}
      style={{ ...style }}
      animationData={animationData}
      height={height}
      width={width}
      autoplay={true}
      {...otherProps}
    />
  );
}

export default GenericAnimation;

import { Button, Input } from "antd";
import { useState } from "react";
import styled from "styled-components";
import { Footer } from "../../styles/ModalStyles";
import { Modal as AntModal } from "antd";
import { getTrunckedString } from "./ProposalCard";
import { ReactComponent as CasteVote } from "../../assets/images/proposals/CasteVote.svg";
import { ReactComponent as Redirect } from "../../assets/images/proposals/Redirect.svg";
import IconWrapper from "../IconWrapper";
import { LinkIcon } from "@heroicons/react/24/solid";
import { ExclamationCircleOutlined, LinkOutlined } from "@ant-design/icons";

export const Modal = styled(AntModal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 1rem;
    padding: 20px;
  }
  & .ant-modal-header {
    padding: 0px;
    border-bottom: 0;
  }
  & .ant-modal-title {
    font: normal 600 12px/15px Inter;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  & .ant-modal-content {
    top: 100px;
  }
  & .ant-modal-body {
    display: flex;

    flex-direction: column;
    padding: 0;
  }
  & .ant-modal-footer {
    border-top: 0px !important;
    padding: 16px 0px 0px !important;
  }
`;

export const UnstyledTextArea = styled(Input.TextArea)``;

const VoteSubmitModal = ({
  voting,
  isModalVisible,
  onSubmit,
  handleCancel,
  votingPower,
  blockNo,
  choice,
}) => {
  const [reason, setReason] = useState("");
  console.log(votingPower);
  return (
    <Modal
      width="560px"
      height="225px"
      destroyOnClose
      title={
        <>
          <CasteVote />
          Cast your vote
        </>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Footer key="modal-footer">
          <Button
            onClick={() => {
              handleCancel();
            }}
            style={{
              borderRadius: "16px",
              width: "252px",
              height: "36px",
            }}
          >
            Cancel
          </Button>

          <Button
            className="rounded-corner"
            type="primary"
            disabled={voting || votingPower === 0}
            onClick={() => {
              onSubmit(reason);
            }}
            style={{
              borderRadius: "16px",
              width: "252px",
              height: "36px",
              cursor: voting ? "wait" : "default",
            }}
          >
            Confirm
          </Button>
        </Footer>,
      ]}
    >
      <div
        style={{
          margin: "20px 0px",
          font: "normal 500 12px/12px Inter",
          color: "#64748B",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "20px",
          }}
        >
          Choice
          <div style={{ font: "normal 600 12px/16px Inter" }}>
            {getTrunckedString(choice, 40)}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "20px",
          }}
        >
          Snapshot
          <div style={{ font: "normal 600 12px/16px Inter" }}>
            {blockNo}
            <Redirect
              onClick={() => {
                window.open(`https://etherscan.io/block/${blockNo}`);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "20px",
          }}
        >
          Your voting power
          <div style={{ font: "normal 600 12px/16px Inter" }}>
            {votingPower} VOTE
          </div>
        </div>
      </div>

      {votingPower !== 0 ? (
        <UnstyledTextArea
          value={reason}
          style={{
            border: "1px solid #F4F2F2",
            resize: "none",
            borderRadius: "16px",
            height: "82px",
            padding: "12px 16px",
          }}
          onChange={(e) => {
            if (e.target.value.length <= 140) setReason(e.target.value);
          }}
          placeholder="Share your reason(Optional)"
        />
      ) : (
        <div
          style={{
            padding: "8px",
            border: "1px solid #f3f3f3",
            borderRadius: "8px",
          }}
        >
          <IconWrapper>
            <ExclamationCircleOutlined />
          </IconWrapper>{" "}
          Oops, it seems you don't have any voting power at block{" "}
          <strong>{blockNo}</strong>.{" "}
          <span
            type="text"
            onClick={() => {
              window.open(
                "https://github.com/snapshot-labs/snapshot/discussions/767",
                "_blank"
              );
            }}
            style={{
              color: "blue",
              textDecoration: "underline",
              display: "inline-flex",
              cursor: "pointer",
            }}
          >
            Learn More{" "}
          </span>
        </div>
      )}
    </Modal>
  );
};

export default VoteSubmitModal;

import {
  CheckCircleFilled,
  ShareAltOutlined,
  UpSquareOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  CalendarIcon,
  MapPinIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowRightCircleIcon,
  BellIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/solid";
import { Button, Col, Row } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import customAxios from "../../../api";
import useWindowSize from "../../../hooks/useWindowSize";
import { UserContext } from "../../../layouts/app/UserContext";
import { postMixpanel } from "../../../utils";
import { StyledCard } from "../../BountyFeed";
import LoadingSpinner from "../../Common/LoadingSpinner";
import IconWrapper from "../../IconWrapper";
import ShareButtonModal from "../../ShareButtonModal";
import defaultUser from "./../../../assets/images/login/defaultUser.jpeg";
import ReactHtmlParser from "react-html-parser";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../../Common/ProfileCard";
export const getLocationSubstring = (data) => {
  const words = data.split(",");
  const length = words.length;
  const finalSubstring =
    length >= 4
      ? `${words[length - 4]}, ${words[length - 3]}`
      : `${words[length - 3]}, ${words[length - 2]}`;
  return finalSubstring;
};

function EventFeedCard({
  data,
  reloadEventList,
  isCompact = false,
  isShareButtonHidden = false,
}) {
  const { showLoginModal, userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useWindowSize();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { daoDetails, daoId } = useDaoContext();

  const handleAddEventToCalendar = (data) => {
    if (!userDetails) {
      showLoginModal();
      return;
    }

    postMixpanel("track", "clicked_on_add_to_calendar", {
      dao_id: daoId,
      events_id: data.id,
      event_name: data.name,
      event_type: data.type,
    });
    const url = `http://www.google.com/calendar/render?action=TEMPLATE&text=${
      data.title
    }&dates=${data.eventAt}/${data.eventEndAt}&details=${
      data.description + (data?.link ? ` Link: ${data.link}` : "")
    }&trp=false`;

    window.open(url);
  };
  const handleMarkInterested = (eventId) => {
    if (!userDetails) {
      showLoginModal();
      return;
    }
    setLoading(true);
    customAxios
      .post(`/affair/mark/interested`, { eventsId: eventId })
      .then((res) => {
        if (res.data.success) {
          postMixpanel("track", "clicked_on_interested", {
            dao_id: daoId,
            events_id: data.id,
            event_name: data.name,
            event_type: data.type,
          });
          reloadEventList();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const navigate = useDaoLensNavigate();
  console.log("title", data);
  return (
    <>
      <ShareButtonModal
        entity="event"
        isModalOpen={isShareModalOpen}
        handleCancel={() => setIsShareModalOpen(false)}
        link={`${window.location.origin}/app/dao/${data.daoName}/${data.daoId}/events/${data.id}`}
      />
      <StyledCard
        style={{
          width: "100%",
          textAlign: "left",
          // marginBottom: "16px",
          borderRadius: "16px",
          cursor: "pointer",
          marginBottom: "12px",
        }}
        onClick={() => {
          postMixpanel("track", "clicked_on_event_feeed_card", {
            dao_id: daoId,
            events_id: data.id,
            event_name: data.name,
            event_type: data.type,
          });
          navigate(`/app/dao/${data.daoName}/${data.daoId}/events/${data.id}`);
        }}
      >
        <Row
          style={{
            alignItems: isMobile ? "flex-start" : "center",
            justifyContent: "space-between",
            rowGap: "12px",
            flexDirection: isMobile ? "column-reverse" : "inherit",
          }}
        >
          <Col md={16}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <img
                  src={
                    data.hostDetails[0]?.profilePicture
                      ? data.hostDetails[0].profilePicture
                      : defaultUser
                  }
                  style={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />

                <ProfileCardPopup userId={data.hostDetails[0].userId}>
                  <h5
                    style={{ marginBottom: "0px", color: "#64748B" }}
                    onClick={() =>
                      navigate(`/app/profile/${data.hostDetails[0].userId}`)
                    }
                  >
                    {data.hostDetails.length > 1
                      ? `Hosted by ${
                          data.hostDetails[0].profileName.split(" ")[0]
                        } +${data.hostDetails.length - 1} others`
                      : `Hosted by ${
                          data.hostDetails[0].profileName.split(" ")[0]
                        }`}
                  </h5>
                </ProfileCardPopup>
              </div>
              <h3 style={{ marginBottom: "0px" }}>{data.title}</h3>
              {data?.description?.length > 200 ? (
                <>
                  {showFullDescription ? (
                    <p>{ReactHtmlParser(data.description)}</p>
                  ) : (
                    <p
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                      }}
                    >
                      {ReactHtmlParser(`${data.description.slice(0, 199)}...`)}{" "}
                      <span
                        style={{
                          cursor: "pointer",
                          color: "#999999",
                          border: "1px solid #cccccc",
                          padding: "5px 10px",
                          borderRadius: "10rem",
                          alignSelf: "center",
                        }}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          setShowFullDescription(true);
                        }}
                      >
                        Read more
                      </span>
                    </p>
                  )}
                </>
              ) : (
                <p>{ReactHtmlParser(data.description)}</p>
              )}

              <div
                style={{
                  display: "flex",
                  columnGap: "4px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                  }}
                >
                  <h5
                    style={{
                      marginBottom: "0px",
                      color: "#64748B",
                      alignItems: "center",
                      display: "flex",
                      marginTop: "5px",
                    }}
                  >
                    {moment().isBetween(
                      data.eventAt,
                      data.eventEndAt
                    ) ? null : (
                      <IconWrapper
                        style={{ color: "#64748B", marginRight: "5px" }}
                      >
                        {" "}
                        <CalendarIcon />{" "}
                      </IconWrapper>
                    )}
                    {moment().isBetween(data.eventAt, data.eventEndAt) ? (
                      <div
                        style={{
                          display: "inline-flex",
                          columnGap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            background: "#0E9F6E",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <span style={{ color: "#0E9F6E", marginRight: "5px" }}>
                          Live Now{" "}
                        </span>
                      </div>
                    ) : moment(moment(data.eventAt)).diff(
                        moment.now(),
                        "minutes"
                      ) <= 15 ? (
                      moment(data.eventAt).fromNow()
                    ) : (
                      moment(data.eventAt).format("hh:mm A, DD MMM")
                    )}{" "}
                    |{" "}
                    {data.type === "ONLINE" ? (
                      <div
                        style={{
                          columnGap: "2px",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <IconWrapper style={{ margin: "0 3px 0 5px" }}>
                          <VideoCameraOutlined />
                        </IconWrapper>
                        Online
                      </div>
                    ) : (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(data.link, "_blank");
                        }}
                        style={{
                          color: "#3B82F6",
                          columnGap: "2px",
                          cursor: "pointer",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <IconWrapper>
                          <MapPinIcon />
                        </IconWrapper>
                        {data.location
                          ? getLocationSubstring(data.location)
                          : "Offline"}
                      </div>
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </Col>
          <Col md={8} xs={24}>
            <div
              style={{
                height: isMobile ? "150px" : "108px",
                width: isCompact ? "100%" : isMobile ? "300px" : "auto",
                // border: "1px solid #f3f3f3",
                background: "#f9fafb",
                borderRadius: "12px",
                padding: "12px 8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={data.icon}
                alt={data.title}
                style={{
                  width: isCompact ? "100%" : isMobile ? "300px" : "192px",
                  height: isCompact ? "100%" : isMobile ? "150px" : "108px",
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
              />
            </div>
          </Col>
        </Row>
        <Col xs={24}>
          <div
            style={{
              borderTop: "1px solid #64748B",
              marginTop: "12px",
              opacity: "0.1",
              paddingBottom: "12px",
            }}
          ></div>{" "}
        </Col>
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            rowGap: "8px",
          }}
        >
          <Col md={8} xs={24}>
            {data.totalInterested.length ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                }}
              >
                <img
                  src={
                    data.totalInterested[0]?.profilePicture
                      ? data.totalInterested[0]?.profilePicture
                      : defaultUser
                  }
                  style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
                <h5 style={{ color: "#64748B", marginBottom: "0px" }}>
                  {data.totalInterested.length} interested
                </h5>
              </div>
            ) : null}
          </Col>
          <Col
            md={16}
            xs={24}
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "8px",
              justifyContent: "flex-end",
            }}
          >
            {!isShareButtonHidden && (
              <Button
                icon={
                  <IconWrapper style={{ color: "#6B7280" }}>
                    <ShareAltOutlined />
                  </IconWrapper>
                }
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                  postMixpanel(
                    "track",
                    "clicked_on_share_event_from_event_feed",
                    {
                      dao_id: daoId,
                      events_id: data.id,
                      event_name: data.name,
                      event_type: data.type,
                    }
                  );
                  setIsShareModalOpen(true);
                }}
              ></Button>
            )}
            {data.isInterested ? (
              <div
                style={{
                  display: "flex",
                  columnGap: "4px",
                  alignItems: "center",
                }}
              >
                <IconWrapper style={{ color: "#059669" }}>
                  <CheckCircleFilled />
                </IconWrapper>{" "}
                <span style={{ color: "#6B7280", fontSize: "12px" }}>
                  Going
                </span>
              </div>
            ) : null}
            {loading ? (
              <LoadingSpinner />
            ) : data.isInterested ? (
              moment().isBetween(data?.eventAt, data?.eventEndAt) ||
              moment(moment(data?.eventAt)).diff(moment.now(), "minute") <=
                15 ? (
                data?.type === "ONLINE" ? (
                  <Button
                    type="primary"
                    style={{ borderRadius: "12px" }}
                    icon={
                      <IconWrapper>
                        <VideoCameraIcon />
                      </IconWrapper>
                    }
                    onClick={(e) => {
                      postMixpanel("track", "clicked_on_join_now", {
                        dao_id: daoId,
                        events_id: data.id,
                        event_name: data.name,
                        event_type: data.type,
                      });
                      window.open(
                        data.link.includes("https://")
                          ? data.link
                          : "https://" + data.link,
                        "_blank"
                      );
                    }}
                  >
                    Join Now
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    icon={
                      <IconWrapper>
                        <ArrowRightCircleIcon
                          color="#ffffff"
                          style={{ transform: "translateY(1px)" }}
                        />
                      </IconWrapper>
                    }
                    style={{
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      postMixpanel("track", "clicked_on_start_navigation", {
                        dao_id: daoId,
                        events_id: data.id,
                        event_name: data.name,
                        event_type: data.type,
                      });
                      window.open(data.link, "_blank");
                    }}
                  >
                    Start navigation
                  </Button>
                )
              ) : (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddEventToCalendar(data);
                  }}
                  icon={
                    <IconWrapper>
                      <PlusIcon />
                    </IconWrapper>
                  }
                  style={{ borderRadius: "12px", color: "#6B7280" }}
                  type="default"
                >
                  Add to calendar
                </Button>
              )
            ) : (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleMarkInterested(data.id);
                }}
                icon={
                  <IconWrapper>
                    <BellIcon />
                  </IconWrapper>
                }
                style={{
                  borderRadius: "12px",
                  color: "#ffffff",
                  backgroundColor: "#1890ff",
                }}
                type="default"
              >
                Interested
              </Button>
            )}
          </Col>
        </Row>
      </StyledCard>
    </>
  );
}

export default EventFeedCard;

import { FolderPlusIcon } from "@heroicons/react/24/outline";
import { Input } from "antd";
import { useRef, useState } from "react";

import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import { ReactComponent as DocumentIcon } from "../../../assets/images/course/document.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/images/course/remove.svg";
import { checkFileSize } from "../../../utils";

let supportedFiles = ["pdf", "docx"];
const UploadContent = ({ state, setState }) => {
  return (
    <>
      {state.file || state.link?.includes("https://storage.googleapis.com") ? (
        <div
          style={{
            border: "1px solid #E2E8F0",
            borderRadius: "12px",
            padding: "16px",
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <DocumentIcon style={{ height: "100%" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
              width: "100%",
            }}
          >
            <Input
              value={state.title}
              onChange={(e) => {
                setState((prev) => ({ ...prev, title: e.target.value }));
              }}
              maxLength={50}
              bordered={false}
              style={{
                padding: "0px",
                height: "24px",
                font: "normal 500 16px/24px Inter",
              }}
              placeholder="Add Title"
            />
            <Input
              value={state.description}
              onChange={(e) => {
                setState((prev) => ({ ...prev, description: e.target.value }));
              }}
              bordered={false}
              style={{
                padding: "0px",
                height: "16px",
                font: "normal 500 12px/16px Inter",
                color: "#64748B",
              }}
              placeholder="Add description"
              maxLength={75}
            />
          </div>
          <RemoveIcon
            style={{ cursor: "pointer", marginLeft: "auto" }}
            onClick={() => {
              setState((prev) => ({
                ...prev,
                file: undefined,
                link: undefined,
              }));
            }}
          />
        </div>
      ) : (
        <FileUploader
          handleChange={(file) => {
            checkFileSize(file.size);
            if (supportedFiles.includes(file.name.split(".").at(-1))) {
              setState((prev) => ({ ...prev, file }));
              setState((prev) => ({ ...prev, title: file.name }));
            } else {
              toast.error("Only pdf and docx files are supported");
            }
          }}
          name="file"
        >
          <div
            style={{
              cursor: "pointer",
              height: "200px",
              background: "rgba(226, 232, 240, 0.2)",
              font: "normal 600 14px/20px Inter",
              color: "#1A202E",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "12px",
              border: "1px dashed #CFD8E3",
              borderRadius: "16px",
            }}
          >
            <FolderPlusIcon
              width={28}
              color="#64748B"
              strokeWidth="2px"
              style={{ marginBottom: "4px" }}
            />
            Drag & Drop to Upload Documents
            <div
              style={{
                font: "normal 500 12px/16px Inter",
                color: "#97A6BA",
              }}
            >
              Supported files: .pdf, .docx
            </div>
            <div style={{ textDecoration: "underline" }}>Add from computer</div>
          </div>
        </FileUploader>
      )}
    </>
  );
};

export default UploadContent;

import React, { useRef } from "react";
import styled from "styled-components";
import { Button, Calendar, Input, Typography } from "antd";
import { useState } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { v4 as uuid } from "uuid";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import IconWrapper from "../../IconWrapper";

const AddSubTaskButton = styled(Button)`
  margin-bottom: 10px;
  font-size: 14px;
  height: 32px;
  color: grey;
  & .plus {
    font-size: 14px;
  }
`;

const SubTaskUI = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0px 8px 10px 8px;
  padding: 6px 10px;
  box-sizing: border-box;
  height: 40px;
  border: 1px solid #f4f2f2;
  box-shadow: 0px 4px 16px rgba(132, 148, 160, 0.12);
  border-radius: 8px;

  & .borderless-button {
    border: none;
    padding: 0;
    background: none;
    line-height: initial;
  }
`;

function CreateSubtaskInput({
  addSubtaskHandler,
  allSubtasks,
  setUpdatedFieldsNames = () => {},
  isEdit = false,
}) {
  const [reviewer, setReviewer] = useState(null);
  const [showSubtaskInput, setShowSubtaskInput] = useState(false);
  const [localTitle, setLocalTitle] = useState("");
  const [localPriority, setLocalPriority] = useState(null);
  const [localTaskPoints, setLocalTaskPoints] = useState(null);
  const inputRef = useRef();
  useOnClickOutside(inputRef, () => {
    setReviewer(null);
    setLocalTaskPoints(null);
    setLocalPriority(null);
    setLocalTitle("");
    setShowSubtaskInput(false);
  });
  const createSubtaskOnSubmitHandler = (e) => {
    e.preventDefault();
    if (localTitle === "") {
      return;
    }
    setReviewer(null);
    setLocalTaskPoints(null);
    setLocalPriority(null);
    setLocalTitle("");

    setShowSubtaskInput(true);
    const newSubTask = {
      tmpId: uuid(),
      title: localTitle,
      priority: localPriority,
      taskPoint: localTaskPoints,
      reviewers: [reviewer],
    };
    if (isEdit) newSubTask.isNew = true;
    console.log({ newSubTask });
    addSubtaskHandler(newSubTask);
    setUpdatedFieldsNames();
  };

  return (
    <>
      {showSubtaskInput && (
        <>
          {!allSubtasks.length ? (
            <h4 style={{ padding: "0px 16px" }}>Sub Tasks:</h4>
          ) : null}
          <SubTaskUI ref={inputRef}>
            {/* <img src={StatusIcon} alt="icon" /> */}
            <form
              onSubmit={createSubtaskOnSubmitHandler}
              style={{ width: "100%" }}
            >
              <Input
                autoFocus={true}
                placeholder="New Subtask"
                value={localTitle}
                onChange={(e) => {
                  setLocalTitle(e.target.value);
                }}
                bordered={false}
              />
            </form>
            {/* <Flag
              defaultValue={localPriority}
              isSubtask
              handleChange={(value) => {
                setLocalPriority(value);
              }}
            /> */}
            {/* <TaskPoints
            isSubtask
            points={localTaskPoints}
            setPoints={setLocalTaskPoints}
          /> */}
            {/* <Assignee selectedUser={bountyReviewer} setReviewer={setReviewer} /> */}
            {/* <ReviewerSelector reviewer={reviewer} setReviewer={setReviewer} /> */}
            <Button
              type="primary"
              size="small"
              style={{ borderRadius: "8px" }}
              disabled={localTitle.trim() === ""}
              onClick={createSubtaskOnSubmitHandler}
            >
              Create
            </Button>
          </SubTaskUI>
        </>
      )}
      <AddSubTaskButton
        size="small"
        type="text"
        style={{
          textAlign: "left",
          paddingLeft: "20px",
          marginTop: "10px",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          color: "#64748B",
        }}
        onClick={() => setShowSubtaskInput(true)}
      >
        + ADD SUBTASK
      </AddSubTaskButton>
    </>
  );
}

export default CreateSubtaskInput;

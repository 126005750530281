import { useEffect, useState } from "react";

const usePlaceholder = (placeholders: string[]) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let pollingInterval = setInterval(() => {
      setIndex((prev) => (prev + 1) % placeholders?.length);
    }, 3000);
    return () => clearInterval(pollingInterval);
  }, []);
  if (!placeholders || placeholders.length === 0) {
    return "Ask robin anything...";
  }

  return placeholders[index];
};

export default usePlaceholder;

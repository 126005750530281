import { useEffect, useState } from "react";

import { Select, Skeleton } from "antd";
import withCondition from "../../images/git-fork.png";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/common/chevrondown.svg";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon, SwatchIcon } from "@heroicons/react/24/solid";
import DeleteEntityModal from "../Common/DeleteEntityModal";
import customAxios from "../../api";
import { useDaoContext } from "../../layouts/app/DaoContext";
import React from "react";
import CreatePreset from "./CreatePreset";
import PresetDetails from "./PresetDetails";
import CustomGating from "./CustomGating";
import { UserGatingHook } from "../../hooks/useGating";
const { Option } = Select;
export interface Preset {
  id: string;
  name: string;
  icon: string;
  backgroundColor: string;
  gatingCondition?: GatingCondition;
  entity?: "CHANNEL" | "PERMISSION";
  type?: string;
  isDefault?: boolean;
}

export interface GatingCondition {
  operator: "OR" | "AND";
  conditions: any[];
}
interface GatingProps {
  state: UserGatingHook;
}

export const defaultConditionDetails = {
  operator: "AND",
  conditions: [
    {
      type: "TOKEN_GATING",
      contractType: "POLYGON",
      contractAddress: "",
      amount: "",
    },
  ],
};
const Gating = ({
  state: {
    presetId,
    setPresetId,
    conditionDetails,
    setConditionDetails,
    gatingType,
    setGatingType,
    isLoading,
    presets,
    setUpdate,
    preset,
  },
}: GatingProps) => {
  const [mode, setMode] = useState<"CREATE" | "EDIT" | "VIEW">("VIEW");

  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editConfirmationModal, setEditConfirmationModal] = useState(false);

  return (
    <>
      {loading || isLoading ? (
        <Skeleton active />
      ) : (
        <div
          className="space-y-4 w-full"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex gap-2">
            <div
              onClick={() => {
                setGatingType("PRESET");
              }}
              className={`border border-solid ${
                gatingType === "PRESET"
                  ? "border-indigo-500"
                  : " border-gray-200"
              } rounded-xl px-4 py-2 font-medium text-sm flex items-center grow cursor-pointer`}
            >
              <SwatchIcon className="text-teal-500 h-4 w-4 mr-1" />
              Gating Presets
            </div>
            <div
              onClick={() => {
                setGatingType("CUSTOM");
              }}
              className={`border border-solid ${
                gatingType === "CUSTOM"
                  ? "border-indigo-500"
                  : " border-gray-200"
              } rounded-xl px-4 py-2 font-medium text-sm flex items-center grow cursor-pointer`}
            >
              <img
                src={withCondition}
                height="16px"
                width="16px"
                style={{ marginRight: "4px" }}
              />
              Custom Gating
            </div>
          </div>
          {gatingType === "PRESET" && (
            <>
              <Select
                className="border border-solid border-gray-200 rounded-xl w-full"
                style={{
                  color: "#1F2937",
                  height: "40px",
                }}
                dropdownStyle={{ borderRadius: "12px", padding: 0 }}
                size="large"
                bordered={false}
                value={presetId}
                onChange={async (key) => {
                  if (key === "-1") {
                    setMode("CREATE");
                  } else {
                    setPresetId(key);
                  }
                }}
                placeholder="Choose Preset"
                suffixIcon={<ChevronDownIcon style={{ cursor: "pointer" }} />}
              >
                {presets.map((preset) => (
                  <Option
                    key={preset.id}
                    value={preset.id}
                    label={preset.name}
                    className=" border-0 border-b border-solid border-gray-100"
                  >
                    <div className="flex items-center text-sm font-medium h-9 ">
                      <div>
                        <div
                          className="rounded-full  w-6 h-6 flex text-xs justify-center items-center mr-2"
                          style={{ background: preset.backgroundColor }}
                        >
                          {preset.icon}
                        </div>
                      </div>

                      <div className="whitespace-nowrap overflow-hidden text-ellipsis w-full">
                        {preset.name}
                      </div>
                    </div>
                  </Option>
                ))}
                <Option key={-1} value={"-1"}>
                  <div className="flex items-center text-sm font-medium h-9 text-blue-500">
                    <PlusCircleIcon className=" h-4 w-4 mr-2.5" /> Add New
                    Gating Presets
                  </div>
                </Option>
              </Select>
              {presetId && presetId !== "-1" && (
                <div className=" font-semibold text-gray-800 space-y-2">
                  <div className="flex justify-between">
                    Preset Details:
                    {!preset?.isDefault && (
                      <div className=" text-gray-500">
                        <PencilSquareIcon
                          onClick={() => {
                            setEditConfirmationModal(true);
                          }}
                          className=" h-4 w-4 mr-2.5 stroke-2 cursor-pointer"
                        />
                        <TrashIcon
                          onClick={() => {
                            setShowDeleteModal(true);
                          }}
                          className=" h-4 w-4 stroke-2 cursor-pointer"
                        />
                      </div>
                    )}
                  </div>
                  <PresetDetails gatingCondition={preset?.gatingCondition} />
                </div>
              )}
              <DeleteEntityModal
                note="You are using this preset in a lot of places, deleting this would remove it from all the places"
                title="Are you sure you want to delete this?"
                isModalVisible={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onSubmit={() => {
                  setLoading(true);
                  setPresetId(undefined);
                  customAxios
                    .delete("dao/gating/template/delete", {
                      params: {
                        templateId: presetId,
                      },
                    })
                    .then(() => {
                      setUpdate((prev) => !prev);
                    })
                    .finally(() => {
                      setLoading(false);
                      setShowDeleteModal(false);
                    });
                }}
              />{" "}
              <DeleteEntityModal
                note="Editing this preset would ungroup it and form individual conditions in all the places it’s being used"
                title="Are you sure you want to edit this?"
                cta="Edit"
                isModalVisible={editConfirmationModal}
                onClose={() => setEditConfirmationModal(false)}
                onSubmit={() => {
                  setMode("EDIT");
                  setEditConfirmationModal(false);
                }}
              />
              <CreatePreset
                mode={mode}
                onCancel={() => {
                  setMode("VIEW");
                }}
                preset={structuredClone(preset)}
                onSubmit={(presetId) => {
                  setPresetId(presetId);
                  setUpdate((prev) => !prev);
                  setMode("VIEW");
                }}
              />
            </>
          )}

          {gatingType === "CUSTOM" && (
            <CustomGating
              conditionDetails={conditionDetails}
              setConditionDetails={setConditionDetails}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Gating;

import { Avatar, Button, Dropdown, Menu, Modal, Skeleton } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import customAxios from "../../api";
import { DaoContext, useDaoContext } from "../../layouts/app/DaoContext";
import ErrorComponent from "../Common/ErrorComponent";
import GoBackButton from "../InternalComponents/GoBackButton";
import { Text } from "../Profile/DaoSection";
import { getOrdinal, voteProposal } from "./api";
import { getTrunckedString } from "./ProposalCard";
import Vote from "./Vote";
import dummyAvatar from ".././../assets/images/login/defaultUser.jpeg";
import VoteSubmitModal from "./VoteSubmitModal";
import {
  getTruncatedAddress,
  getUrlForIPFSHash,
  postMixpanel,
} from "../../utils";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import SlidingContextSidebar from "../SlidingContextSidebar";
import { PinIcon } from "../Icones";
import { togglePinProposal } from "../../utils/pin";
import ReactMarkdown from "react-markdown";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { UserContext } from "../../layouts/app/UserContext";
import ConnectWalletModal from "../Common/ConnectWalletModal";
import SmallUserDetails from "../InternalComponents/SmallUserDetails";
import styled from "styled-components";
import { useWalletConnect } from "../../layouts/app/WalletConnectContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../Common/ProfileCard";
const StyledDivForMarkdown = styled.div`
  .markdown-custom {
    margin-bottom: 40px;
    ul,
    li {
      margin: 0;
      // padding: 0;
    }
  }
`;
export const validateChoices = (votingType, selectedChoice, choices) => {
  switch (votingType) {
    case "basic": {
      return selectedChoice ? true : false;
    }
    case "single-choice": {
      return selectedChoice ? true : false;
    }
    case "approval": {
      return selectedChoice.length > 0;
    }
    case "ranked-choice": {
      return choices.length === selectedChoice.length;
    }
    case "quadratic": {
      return Object.keys(selectedChoice).length > 0;
    }
    case "weighted": {
      return Object.keys(selectedChoice).length > 0;
    }
    default:
      return selectedChoice ? true : false;
  }
};
export const getChoiceString = (votingType, selectedChoice, choices) => {
  switch (votingType) {
    case "basic": {
      return choices[selectedChoice - 1];
    }
    case "single-choice": {
      return choices[selectedChoice - 1];
    }
    case "approval": {
      return selectedChoice.map((idx) => choices[idx - 1]).join(", ");
    }
    case "ranked-choice": {
      return selectedChoice
        .map((idx, index) => `(${getOrdinal(index + 1)}) ${choices[idx - 1]}`)
        .join(", ");
    }
    case "quadratic": {
      const totalVote = selectedChoice
        ? Object.values(selectedChoice).reduce((prev, curr) => prev + curr, 0)
        : 0;

      return Object.entries(selectedChoice)
        .map(([key, value]) => {
          return `${+((value * 100) / totalVote).toFixed(2)}% for ${
            choices[parseInt(key) - 1]
          }`;
        })
        .join(", ");
    }
    case "weighted": {
      const totalVote = selectedChoice
        ? Object.values(selectedChoice).reduce((prev, curr) => prev + curr, 0)
        : 0;
      return Object.entries(selectedChoice)
        .map(([key, value]) => {
          return `${+((value * 100) / totalVote).toFixed(2)}% for ${
            choices[parseInt(key) - 1]
          }`;
        })
        .join(", ");
    }
    default:
      return choices[selectedChoice - 1];
  }
};

const ProposalDetailsPage = () => {
  const navigate = useDaoLensNavigate();
  const { isCurrentDaoJoined, isAdmin } = useContext(DaoContext);
  const { userDetails } = useContext(UserContext);

  const [proposal, setProposal] = useState(undefined);
  const [selectedChoice, setSelectedChoice] = useState(undefined);
  const [updater, setUpdater] = useState(false);
  const [isWalletConnectModalOpen, setWalletConnectModalOpen] = useState(false);
  const { proposalId } = useParams();
  const { daoDetails, daoId } = useDaoContext();

  const [submitModal, setSubmitModal] = useState(false);
  const [isChoiceValid, setIsChoiceValid] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [totalVoteCount, setTotalVoteCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const { isWalletConnected } = useWalletConnect();

  useTrackPageView(proposalId);

  useEffect(() => {
    if (!daoId) return;
    setLoading(true);
    setVoting(true);
    customAxios
      .get(
        `/proposals/fetch?proposalId=${proposalId}&daoId=${daoId}&&status=ALL`
      )
      .then(({ data: { res } }) => {
        if (res.length > 0) {
          setProposal(res[0]);
          if (res[0].status === "closed") setShowResult(true);
          setTotalVoteCount(
            res[0]?.scores.reduce((total, item) => total + item, 0)
          );
          let votingType = res[0].votingType;
          if (res[0].userVote && res[0].userVote.length > 0) {
            setSelectedChoice(res[0].userVote[0].choice);
          } else if (
            votingType === "approval" ||
            votingType === "ranked-choice"
          ) {
            setSelectedChoice([]);
          } else if (votingType === "quadratic" || votingType === "weighted") {
            setSelectedChoice({});
          }
        }
      })
      .finally(() => {
        setVoting(false);
        setLoading(false);
      });
  }, [daoId, proposalId, updater]);

  useEffect(() => {
    const choiceValidity = validateChoices(
      proposal?.votingType,
      selectedChoice,
      proposal?.choices
    );

    setIsChoiceValid(choiceValidity);
  }, [JSON.stringify(selectedChoice)]);

  useEffect(() => {
    (async () => {
      const {
        data: { canCreate },
      } = await customAxios.get(`/proposals/cancreate?daoId=${daoId}`);
      setCanCreate(canCreate);
    })();
  }, [daoId, proposalId, updater]);

  const [voting, setVoting] = useState(false);
  const vote = async (reason) => {
    setVoting(true);
    const { voterAddress } = await voteProposal(
      {
        data: {
          reason,
          proposal: proposal.snapProposalId,
          type: proposal.votingType,
          choice: selectedChoice,
          space: proposal.space,
        },
        proposal,
      },
      isWalletConnected
    ).catch((err) => {
      console.log(err, "lp");
      setSubmitModal(false);
      setVoting(false);
      throw new Error();
    });
    customAxios
      .post("/proposals/vote", {
        choices: selectedChoice,
        proposalId: proposal.id,
        voterAddress,
        votedAt: new Date().toISOString(),
        space: proposal.space,
        snapProposalId: proposal.snapProposalId,
      })
      .then((res) => {
        if (res.data.success) {
          postMixpanel("track", "voted_on_proposal", {
            dao_id: daoId,
            proposal_title: proposal.title,
          });
          toast.success("Your vote is in!");
          setSubmitModal(false);
          setUpdater((prev) => !prev);
        } else toast.error("Some error occured");
      })
      .catch((err) => toast.error("Some error occured"))
      .finally(() => {
        setVoting(false);
      });
  };
  return (
    <>
      {loading && <Skeleton active style={{ marginTop: "20px" }} />}
      <ConnectWalletModal
        isModalOpen={isWalletConnectModalOpen}
        setIsModalOpen={setWalletConnectModalOpen}
        onSuccess={() => {
          toast.success("Wallet connected succesfully");
          setUpdater((prev) => !prev);

          setSubmitModal(true);
        }}
      />
      {!loading && !proposal && <ErrorComponent />}
      {submitModal && (
        <VoteSubmitModal
          voting={voting}
          onSubmit={vote}
          isModalVisible={submitModal}
          handleCancel={() => setSubmitModal(false)}
          votingPower={proposal?.votingPower}
          blockNo={proposal?.blockNo}
          choice={getChoiceString(
            proposal?.votingType,
            selectedChoice,
            proposal?.choices
          )}
        />
      )}
      {!loading && proposal && (
        <div
          style={{
            background: "white",
            marginTop: "20px",
            padding: "20px",
            borderRadius: "16px",
          }}
        >
          <GoBackButton
            showIcon
            buttonText={"Go Back"}
            clickHandler={() => {
              navigate(-1);
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <ProfileCardPopup
              userId={proposal?.userDetails ? proposal?.userDetails.id : null}
            >
              <div
                onClick={() =>
                  navigate(`/app/profile/${proposal?.userDetails.id}`)
                }
              >
                <Avatar
                  size={16}
                  src={
                    proposal?.userDetails
                      ? proposal?.userDetails?.profilePicture
                      : dummyAvatar
                  }
                  style={{ marginRight: "4px" }}
                />
                <Text style={{ font: "normal 500 12px/20px Inter" }}>
                  {getTruncatedAddress(proposal?.creatorAddress)}
                </Text>
              </div>
            </ProfileCardPopup>
            <div
              style={{
                font: "normal 500 14px/20px Inter",
                color: "#64748B",
                display: "flex",
                alignItems: "center",
                columnGap: "12px",
              }}
            >
              {proposal?.status === "active" && (
                <>{moment(proposal?.endDate).fromNow(true)} Left</>
              )}
              {proposal?.status === "pending" && (
                <>{moment(proposal?.startDate).fromNow(true)} Left</>
              )}

              <div
                style={{
                  display: "inline-block",
                  background:
                    proposal?.status === "active" ? "#22C55E" : "#6875F5",
                  color: "white",
                  padding: "4px 12px",
                  borderRadius: "18px",
                }}
              >
                {proposal?.status === "active"
                  ? "Active"
                  : proposal?.status === "pending"
                  ? "Pending"
                  : "Closed"}
              </div>
              {proposal.pinned && <PinIcon style={{ color: "#6366F1" }} />}
              {(canCreate || isAdmin) && (
                <Dropdown
                  overlay={
                    <Menu style={{ borderRadius: "8px" }}>
                      <Menu.Item
                        onClick={(e) => {
                          e.domEvent.stopPropagation();
                          togglePinProposal(
                            daoId,
                            proposal.id,
                            !proposal.pinned
                          );
                        }}
                      >
                        <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
                        {proposal.pinned ? "Unpin" : "Pin"}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <EllipsisHorizontalIcon
                    width={"20px"}
                    style={{ cursor: "pointer" }}
                  />
                </Dropdown>
              )}
            </div>
          </div>

          <div
            style={{
              font: "normal 600 16px/24px Inter",
              color: "#1A202E",
              marginTop: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            }}
          >
            {getTrunckedString(proposal?.title, 150)}

            {proposal && (
              <div
                style={{
                  padding: "16px",
                  border: "1px solid #E2E8F0",
                  borderRadius: "12px",
                  boxShadow: "0px 4px 10px rgba(180, 195, 205, 0.15)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    font: "normal 600 12px/16px Inter",
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_881_16775)">
                      <path
                        d="M11.2766 4.39046V11.0571M7.94328 6.89046V11.0571M4.60994 9.39046V11.0571M2.94328 14.3905H12.9433C13.3853 14.3905 13.8092 14.2149 14.1218 13.9023C14.4343 13.5897 14.6099 13.1658 14.6099 12.7238V2.7238C14.6099 2.28177 14.4343 1.85784 14.1218 1.54528C13.8092 1.23272 13.3853 1.05713 12.9433 1.05713H2.94328C2.50125 1.05713 2.07733 1.23272 1.76477 1.54528C1.45221 1.85784 1.27661 2.28177 1.27661 2.7238V12.7238C1.27661 13.1658 1.45221 13.5897 1.76477 13.9023C2.07733 14.2149 2.50125 14.3905 2.94328 14.3905Z"
                        stroke="#64748B"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_881_16775">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {showResult || !isCurrentDaoJoined ? (
                    ` On Chain Voting Results (${totalVoteCount} ${
                      totalVoteCount > 1 ? "Votes" : "Vote"
                    })`
                  ) : (
                    <>
                      {" "}
                      On Chain Voting on ⚡️ Snapshot
                      <span style={{ margin: "0 6px" }}>&#8226;</span>
                      Cast your Vote Below
                    </>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {showResult || !isCurrentDaoJoined ? (
                    proposal.choices.map((choice, id) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #F4F2F2",
                            borderRadius: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            font: "normal 500 12px/20px Inter",
                            color: "#64748B",
                            height: "36px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              background: "#F4F2F2",
                              borderRadius: "16px",
                              width: `${
                                ((proposal.scores[id]
                                  ? proposal.scores[id]
                                  : 0) *
                                  100) /
                                (totalVoteCount === 0 ? 1 : totalVoteCount)
                              }%`,
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              color: "#1A202E",
                              position: "absolute",
                              zIndex: 1,
                            }}
                          ></div>
                          <div style={{ paddingLeft: "12px", zIndex: 2 }}>
                            {choice}
                          </div>
                          <div style={{ paddingRight: "12px", zIndex: 2 }}>
                            {`${+(
                              ((proposal.scores[id] ? proposal.scores[id] : 0) *
                                100) /
                              (totalVoteCount === 0 ? 1 : totalVoteCount)
                            ).toFixed(2)}%`}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <Vote
                        choices={proposal.choices}
                        votingType={proposal.votingType}
                        selectedChoice={selectedChoice}
                        setSelectedChoice={setSelectedChoice}
                      />
                      <Button
                        type="primary"
                        disabled={!isChoiceValid}
                        style={{
                          borderRadius: "16px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingRight: "12px",
                          font: "normal 500 12px/167% Inter",
                          height: "36px",
                          position: "relative",
                        }}
                        onClick={() => {
                          if (userDetails.isMetamaskConnected) {
                            if (isChoiceValid) {
                              setSubmitModal(true);
                            }
                          } else {
                            toast.info(
                              "Please connect your wallet before voting on a proposal",
                              { autoClose: "2000", pauseOnFocusLoss: false }
                            );
                            setWalletConnectModalOpen(true);
                          }
                        }}
                      >
                        Vote
                      </Button>
                    </>
                  )}
                </div>
                <div
                  onClick={() => {
                    setShowResult((prev) => !prev);
                  }}
                  style={{
                    marginTop: "12px",
                    font: "normal 500 12px/16px Inter",
                    color: "#64748B",
                    display: "inline-block",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                >
                  {isCurrentDaoJoined &&
                    proposal?.status === "active" &&
                    (showResult ? (
                      <>
                        <GoBackButton
                          showIcon
                          style={{ display: "inline-block", height: "5px" }}
                        />
                        Back to Voting
                      </>
                    ) : (
                      "Show current results"
                    ))}
                </div>
              </div>
            )}
            <StyledDivForMarkdown
              style={{
                font: "normal 400 14px/24px Inter",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            >
              <ReactMarkdown
                className="markdown-custom"
                transformImageUri={(src, alt, title) => {
                  if (src.includes("ipfs://")) {
                    return getUrlForIPFSHash(src.split("ipfs://")[1]);
                  }
                  return src;
                }}
                children={`${proposal.description}`}
              ></ReactMarkdown>
            </StyledDivForMarkdown>
            <div style={{ display: "flex" }}>
              <SlidingContextSidebar
                canEdit={isAdmin}
                entityId={proposalId}
                entityType="PROPOSALS"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProposalDetailsPage;

import { PlusIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import { postMixpanel } from "../../../../utils";
import { AnnouncementType } from "../../../../utils/Announcements";
import HorizontalScrollContainer, {
  HorizontalScrollItem,
} from "../../../DIscoverPage/HorizontalScrollContainer";
import AnnouncementCard from "./AnnouncementCard";
import CreateAnnouncementModal from "./CreateAnnouncementModal";

type Props = {
  canEdit: boolean;
  announcements: AnnouncementType[];
  setIsCreateButtonShown: Dispatch<SetStateAction<boolean>>;
  createAnnouncmentButtonRef: React.RefObject<HTMLButtonElement>;
};

const StyledButton = styled.button`
  &:hover {
    background: #f3f4f6 !important;
  }
`;

const AnnouncementList = ({
  canEdit,
  announcements,
  setIsCreateButtonShown,
  createAnnouncmentButtonRef,
}: Props) => {
  const { daoId } = useDaoContext();

  const { isMobile } = useWindowSize();
  const [isCreateAnnouncementModalOpen, setIsCreateAnnouncementModalOpen] =
    useState(false);

  const isEmpty = announcements.length === 0;
  return (
    <div style={{ paddingBottom: isEmpty ? "20px" : "" }}>
      <HorizontalScrollContainer
        // gap="16px"
        // areNavButtonsHidden={!isMobile && announcements.length <= 2}
        // onStartingScrollPosition={(isAtStartingScrollPosition) =>
        //   setIsCreateButtonShown(!isAtStartingScrollPosition)
        // }
      >
        {canEdit && (
          <HorizontalScrollItem width={isEmpty || isMobile ? "100%" : "48%"}>
            <StyledButton
              style={{
                background: "white",
                display: "flex",
                margin: 0,
                flexDirection: isMobile && !isEmpty ? "row" : "column",
                gap: isEmpty ? "4px" : isMobile ? "14px" : "18px",
                border: "1px dashed #D1D5DB",
                color: "#6B7280",
                fontSize: "14px",
                borderRadius: "12px",
                justifyContent: "center",
                alignItems: "center",
                padding: isMobile ? "16px" : "16px",
                paddingTop: isEmpty ? "24px" : "",
                paddingBottom: isEmpty ? "24px" : "",
                cursor: "pointer",
                width: "100%",
                height: isEmpty ? "" : "calc(100% - 20px)",
                minHeight: isEmpty || isMobile ? "" : "200px",
              }}
              onClick={() => {
                postMixpanel("track", "clicked_add_new_announcement_button", {
                  dao_id: daoId,
                });
                setIsCreateAnnouncementModalOpen(true);
              }}
              ref={createAnnouncmentButtonRef}
            >
              <div>
                <PlusIcon
                  width={isEmpty ? 24 : 36}
                  strokeWidth={2}
                  style={{
                    height: "24px",
                  }}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <span style={{ fontSize: isEmpty ? "14px" : "16px" }}>
                  Add announcement
                </span>
                {isEmpty && (
                  <span style={{ fontSize: "12px" }}>
                    Let everyone know what's new in the community
                  </span>
                )}
              </div>
            </StyledButton>
          </HorizontalScrollItem>
        )}

        {announcements.map((announcement) => (
          <HorizontalScrollItem
            width={isMobile ? "100%" : "48%"}
            marginBottom="20px"
          >
            <AnnouncementCard
              announcement={announcement}
              canEdit={canEdit}
              isOld={false}
            />
          </HorizontalScrollItem>
        ))}
      </HorizontalScrollContainer>
      <CreateAnnouncementModal
        isOpen={isCreateAnnouncementModalOpen}
        setIsOpen={setIsCreateAnnouncementModalOpen}
      />
    </div>
  );
};

export default AnnouncementList;

import minimatch from "minimatch";
import { NavigateOptions, To, useNavigate } from "react-router";
import { useDaoContext } from "../layouts/app/DaoContext";
import { convertDaoToSpaceUrl } from "../lib/utils/spaces";

const useDaoLensNavigate = () => {
  const navigate = useNavigate();
  const { daoDetails, isSpace, parentDaoDetails } = useDaoContext();

  const navigateWrapper = (
    to: To,
    options: NavigateOptions | undefined = undefined
  ) => {
    const destination = to.toString();
    if (!isSpace || !minimatch(destination, "/app/dao/**")) {
      return navigate(to, options);
    }
    const newDestination = convertDaoToSpaceUrl(
      destination,
      daoDetails?.parentDaoId,
      parentDaoDetails?.daoName
    );

    return navigate(newDestination, options);
  };

  return navigateWrapper;
};

export default useDaoLensNavigate;

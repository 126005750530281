import Web3 from "web3";
import { ExternalProvider } from "@ethersproject/providers";
import abi from "../../assets/abi/erc20_abi.json";
import { BigNumberish } from "ethers";
import { toast } from "react-toastify";

declare global {
  interface Window {
    ethereum?: any;
  }
}
export const ethereumPayment = async (
  contractAddress: string,
  bountyAmount: number,
  recipientAddress: string,
  chainId: number,
  backendNetwork: string
) => {
  const web3 = new Web3(window.ethereum);
  const accounts = await web3.eth.requestAccounts();
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.toHex(chainId) }],
    });
  } catch (error: any) {
    toast.error(`Please add ${backendNetwork} network on metamask`);
    // eslint-disable-next-line
    throw new Error(error);
  }
  const currentChainId = await web3.eth.getChainId();

  if (chainId !== currentChainId) {
    toast.error(`Please choose correct ${backendNetwork} network on metamask`);
    throw new Error(
      `Please choose correct ${backendNetwork} network on metamask`
    );
  }

  const currentGasPrice = await web3.eth.getGasPrice();
  if (!contractAddress) {
    console.log("Enter");
    await web3.eth.sendTransaction({
      from: accounts[0],
      to: recipientAddress,
      value: bountyAmount,
    });
  } else {
    const tokenContract = new web3.eth.Contract(abi as any, contractAddress);

    await tokenContract.methods
      .transfer(recipientAddress, `${bountyAmount}`)
      .send({
        from: accounts[0],
        gasPrice: web3.utils.toHex(currentGasPrice),
      });
  }

  return accounts[0];
};

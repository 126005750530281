import { Button, Col, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import InviteModal from "../../Common/InviteModal";
import Gating from "../../Gating/Gating";
import PresetDetails from "../../Gating/PresetDetails";
import withCondition from "../../../images/git-fork.png";


export const getDefaultCondition = (type) => {
  switch (type) {
    case "NFT_GATING":
      return {
        type: type,
        contractAddress: "",
        nftGatingType: "AMOUNT",
        contractType: "POLYGON",
      };
    case "TOKEN_GATING":
      return {
        type: type,
        contractType: "POLYGON",
        contractAddress: "",
        amount: "",
      };
    case "SPECIFIC_ADDRESS":
      return {
        type: type,
        specificAddress: [""],
      };
    case "DISCORD_ROLES":
      return {
        type: type,
        discordRole: [],
      };
    case "MEMBER_PROFILE":
      return {
        type: type,
        user: [],
      };
    default:
      return {
        type: type,
      };
  }
};
function CollapsiblePermissionItem({
  ele,
  permissionIdx,
  editable,
  permissionData
}) {

  const [open, setOpen] = useState(false);
  const [inviteAdminModalOpen, setInviteAdminModalOpen] = useState(false);
  const { gatingState } = ele;
  // const gatingState = useGating()

  return (
    <Row
      style={{
        padding: "20px",
        borderBottom: "1px solid #F1F5F9",
      }}
    >
      <div
        className="cursor-pointer"
        onClick={() => setOpen((prev) => !prev)}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
        }}
      >
        <h2
          style={{
            fontSize: "16px",
            fontWeight: "500",
            color: "#0F172A",
            marginBottom: "2px",
          }}
        >
          {ele.label}
        </h2>
        {!permissionIdx ? (
          <Button
            type="text"
            style={{
              position: "absolute",
              right: "-0.8rem",
              top: "-0.3rem",
              color: "#3F83F8",
              fontSize: "12px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setInviteAdminModalOpen(true);
            }}
          >
            Invite admins via email {"->"}
          </Button>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#64748B",
          }}
        >
          <div style={{ fontWeight: "400", color: "#64748B" }}>
            {ele.description}
          </div>
          <i
            class={`fas fa-chevron-${open ? "up" : "down"}`}
            style={{ cursor: "pointer", fontSize: "16px" }}
          ></i>
        </div>
      </div>
      {open && editable ? (
        <div className="mt-4 w-full">
          <Gating state={gatingState} />
        </div>
      ) : null}
      {open && !editable && <div className="w-full flex flex-col gap-4 mt-4">
        <div className="text-gray-800 font-medium text-sm bg-gray-50 rounded-xl p-4 w-full flex items-center">
          {gatingState.gatingType === "PRESET" ? (
            <>
              <div
                className="mr-2.5 text-base"

              >
                {gatingState.preset?.icon}
              </div>
              {gatingState.preset?.name}
            </>
          ) :
            <>           <img
              src={withCondition}
              height="16px"
              width="16px"
              style={{ marginRight: "4px" }}
            />Custom Gating</>}



        </div>
        <div className=" font-semibold text-gray-800 space-y-2">
          <div className="flex justify-between">
            Preset Details:
          </div>
          <PresetDetails gatingCondition={gatingState.gatingType === "CUSTOM" ? gatingState.conditionDetails : gatingState.preset.gatingCondition} />
        </div>

      </div>}


      {inviteAdminModalOpen ? (
        <InviteModal
          modalOpen={inviteAdminModalOpen}
          onClose={() => setInviteAdminModalOpen(false)}
        />
      ) : null}
    </Row>
  );
}

export default CollapsiblePermissionItem;

import { Button } from "antd";
import { getAddress } from "ethers/lib/utils";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import customAxios from "../../../api";
import { useWalletConnect } from "../../../layouts/app/WalletConnectContext";
import metaMaskIcon from "./../../../assets/iconSvg/metamask.png";
import walletConnectIcon from "./../../../assets/iconSvg/wallet-connect.svg";
import Web3 from "web3";
import { useEthers } from "@usedapp/core";
import { walletConnect } from "../../../utils/connectors";
import { UserContext } from "../../../layouts/app/UserContext";
import { Modal } from "../../../styles/ModalStyles";
import styled from "styled-components";
const WalletConnectButton = styled(Button)`
  &:hover {
    background: #f4f2f2 !important;
  }
`;

function ConnectWalletModal({
  isModalOpen,
  setIsModalOpen,
  onSuccess = () => {},
  disableWalletConnect = false,
}) {
  const [loading, setLoading] = useState(false);
  const { reload } = useContext(UserContext);
  const { onClickActivate, isWalletConnected, setWalletConnected } =
    useWalletConnect();
  const WalletConnect = () => {
    const handleConnectWallet = () => {
      onClickActivate();
    };
    useEffect(() => {
      if (isWalletConnected) {
        const authWalletConnect = async () => {
          try {
            setWalletConnected(true);
            customAxios
              .post("auth/connect/metamask", {
                publicAddress: account,
              })
              .then((response) => {
                handleSignMessage({
                  publicAddress: account,
                  ...response.data,
                  isWalletConnect: true,
                });
              })
              .catch((err) => {
                console.log(err);
                toast.error("Some error occured");
              });
          } catch (error) {
            console.error(error);
          }
        };
        authWalletConnect();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);
    return (
      <WalletConnectButton
        style={{
          color: "#014737",
          padding: "22px 8px",
          gap: "8px",
          display: "inline-flex",
          alignItems: "center",
          borderRadius: "8px",
          marginBottom: "12px",
        }}
        onClick={handleConnectWallet}
      >
        <img src={walletConnectIcon} alt="icon" height="16" />
        <p
          style={{
            marginBottom: "0px",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Wallet Connect
        </p>
      </WalletConnectButton>
    );
  };

  const connectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          // Return the address of the wallet
          const publicAddress = getAddress(res[0]);
          customAxios
            .post("auth/connect/metamask", {
              publicAddress: publicAddress,
            })
            .then((response) => {
              handleSignMessage({
                publicAddress: publicAddress,
                ...response.data,
              });
            })
            .catch((err) => {
              console.log("error");
            });
        })
        .catch((err) => toast.error(err.message));
    } else {
      toast.error("Install metamask extension!!");
    }
  };
  const { account } = useEthers();

  const handleSignMessage = ({
    publicAddress,
    message,
    token,
    isWalletConnect = false,
  }) => {
    let web3;
    console.log("fir", isWalletConnected);
    if (!isWalletConnected) {
      web3 = new Web3(Web3.givenPovider || window.ethereum);
    } else {
      web3 = new Web3(walletConnect);
    }

    web3.eth.personal
      .sign(
        web3.utils.fromUtf8(`I am signing my one-time nonce: ${message}`),
        publicAddress
      )
      .then((res) => {
        customAxios
          .post("auth/connect/metamask/login", {
            token: "Bearer " + token,
            signature: res,
            isWalletConnect: isWalletConnect,
          })
          .then((response) => {
            localStorage.setItem("access_token", response.data.access_token);
            setLoading(false);
            setIsModalOpen(false);
            onSuccess();
            reload();
          })
          .catch((err) => {
            toast.error(
              "Metamask Account is already Connected With Another Account"
            );
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      title={
        <h2
          style={{
            paddingLeft: "13px",
            fontSize: "16px",
            fontWeight: "600",
            marginBottom: "0px",
          }}
        >
          Connect a wallet
        </h2>
      }
      visible={isModalOpen}
      footer={null}
      destroyOnClose={true}
      onCancel={() => setIsModalOpen(false)}
      width="420px"
    >
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          rowGap: "12px",
        }}
      >
        <Button
          style={{
            borderRadius: "8px",
            minHeight: "3rem",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
          type="default"
          block={true}
          icon={
            <img
              src={metaMaskIcon}
              alt="metasmaskicon"
              style={{ width: "20px", marginRight: "0.5rem" }}
            />
          }
          onClick={() => connectMetamask()}
        >
          MetaMask
        </Button>
        {!disableWalletConnect ? <WalletConnect /> : null}
      </div>
    </Modal>
  );
}

export default ConnectWalletModal;

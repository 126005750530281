import { StyledCard } from "../BountyFeed";
import { getPostTime } from "../Feed";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { useDaoContext } from "../../layouts/app/DaoContext";

export const RelatedPosts = ({ post }) => {
  const navigate = useDaoLensNavigate();
  const { daoId, daoName } = useDaoContext();
  const handleClick = () => {
    navigate(
      `/app/dao/${daoName}/${daoId}/channel/${post.channel_id}/post/${post.id}`
    );
  };
  return (
    <StyledCard
      style={{
        width: "100%",
        textAlign: "left",
        borderRadius: "16px",
        cursor: "pointer",
        marginBottom: "12px",
      }}
      onClick={handleClick}
    >
      <div
        style={{
          color: "#64748B",
          display: "flex",
          fontWeight: "600",
          fontSize: "12px",
          gap: "0.5rem",
        }}
      >
        <p>{post.user_name}</p>
        <p>{getPostTime(post.created_at)}</p>
      </div>
      <h3 style={{ fontWeight: "600", fontSize: "16px" }}>{post.name}</h3>
    </StyledCard>
  );
};

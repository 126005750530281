import React from "react";
import "./InternalComponentStyles/TokenSquareCapsule.scss";
function TokenSquareCapsule({ clickHandler, icon, textContent }) {
  return (
    <div
      className={
        clickHandler
          ? "token-square-capsule__wrapper token-square-capsule__clickable"
          : "token-square-capsule__wrapper"
      }
      onClick={clickHandler}
    >
      <img src={icon} className="token-square-capsule__icon" alt="" />
      <span className="token-square-capsule__text">{textContent}</span>
    </div>
  );
}

export default TokenSquareCapsule;

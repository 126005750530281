import { TwitterOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import React from "react";
import { Modal } from "../../../styles/ModalStyles";

function QuizResult({
  marksPercent,
  isModalOpen,
  handleModalClose,
  handleRetakeQuiz,
  handleTwitterShare,
}) {
  return (
    <Modal
      width="520px"
      bodyStyle={{
        padding: "16px",
        minHeight: "320px",
      }}
      open={isModalOpen}
      visible={isModalOpen}
      centered
      destroyOnClose
      onOk={handleModalClose}
      onCancel={handleModalClose}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontSize: "75px", height: "100%" }}>
          {marksPercent > 80 ? "🥳" : "😕"}
        </h1>
        {marksPercent > 80 ? (
          <h2>Congratulations! You have completed the quiz</h2>
        ) : (
          <h2>
            Sorry, You didn't pass the test
            <br />
            Please try again
          </h2>
        )}
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h4
            style={{
              background: "#F4F2F2",
              borderRadius: "8px",
              display: "flex",
              width: "fit-content",
              padding: "4px 8px",
              color: "#64748B",
            }}
          >
            Your result: {marksPercent < 80 ? "FAIL" : "PASS"}
          </h4>
        </div>
        {marksPercent < 80 ? (
          <Row
            style={{
              justifyContent: "flex-end",
              columnGap: "8px",
              marginTop: "32px",
            }}
          >
            <Button
              style={{ borderRadius: "12px", height: "40px" }}
              onClick={() => handleModalClose()}
            >
              Cancel
            </Button>{" "}
            <Button
              type="primary"
              style={{
                background: "#3B82F6",
                borderRadius: "12px",
                height: "40px",
              }}
              onClick={handleRetakeQuiz}
            >
              Retake Quiz
            </Button>
          </Row>
        ) : (
          <Row
            style={{
              justifyContent: "flex-end",
              columnGap: "8px",
              marginTop: "32px",
            }}
          >
            <Button
              icon={<TwitterOutlined />}
              onClick={() => {
                handleTwitterShare();
              }}
              type="primary"
              style={{
                height: "40px",
                display: "flex",
                borderRadius: "12px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                background: "#3B82F6",
              }}
            >
              Share on Twitter
            </Button>
          </Row>
        )}
      </div>
    </Modal>
  );
}

export default QuizResult;

import React, { useState, createElement } from "react";
import { Avatar, Comment, Tooltip, Typography, Select } from "antd";
import {
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const { Text } = Typography;
const { Option } = Select;

const StyledComment = styled(Comment)`
  ul {
    display: flex;
    justify-content: flex-end;
  }
  & .ant-comment-actions > li > span {
    margin-right: 0px;
  }
  & .ant-comment-inner {
    padding: 0px 0px;
  }
`;

const StyledSelect = styled(Select)`
  & .ant-select-selector {
    border-radius: 50px !important;
    background: #f7f7f7 !important;
  }
  & span {
    color: #64748b !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
`;

const CommentArea = ({ children }) => {
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState(null);

  const like = () => {
    setLikes(1);
    setDislikes(0);
    setAction("liked");
  };

  const dislike = () => {
    setLikes(0);
    setDislikes(1);
    setAction("disliked");
  };

  const actions = [
    <span key="comment-basic-reply-to">Reply to</span>,
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {createElement(action === "liked" ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{likes}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(
          action === "disliked" ? DislikeFilled : DislikeOutlined
        )}
        <span className="comment-action">{dislikes}</span>
      </span>
    </Tooltip>,
  ];

  return (
    <StyledComment
      actions={actions}
      // author={<a>Han Solo</a>}
      avatar={
        <Avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
      }
      content={
        <p>
          We supply a series of design principles, practical patterns and high
          quality design resources (Sketch and Axure).
        </p>
      }
    >
      {children}
    </StyledComment>
  );
};

const CommentSection = () => {
  const [value, setValue] = useState("1");
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text style={{ color: "black", fontWeight: 600, fontSize: 14 }}>
          Comments (12)
        </Text>
        <StyledSelect
          placeholder="Select Filter"
          value={value}
          style={
            {
              // width: 120,
            }
          }
          onChange={(value) => setValue(value)}
        >
          <Option value="1">Top Rated</Option>
          <Option value="2">Most liked</Option>
          <Option value="3">Most Disliked</Option>
        </StyledSelect>
      </div>

      <div style={{ height: 10 }} />
      <CommentArea>
        <CommentArea>
          <CommentArea />
          <CommentArea />
        </CommentArea>
      </CommentArea>
    </div>
  );
};

export default CommentSection;

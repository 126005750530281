import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import customAxios from "../../api";
import MobilePageTopNavbar from "../../components/Common/MobilePageTopNavbar";
import Feed from "../../components/Feed";
import GPT from "../../components/GPT/GPT";
import { ChannelMenu } from "../../components/GPT/MenuItem";
import OnboardingModal from "../../components/Onboarding/OnboardingModal";
import {
  DEFAULT_SORTING_TYPE_LIST,
  MD_SCREEN_BREAKPOINT,
} from "../../constants";
import Post from "../../features/post/Post";
import useWindowSize from "../../hooks/useWindowSize";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { UserContext } from "../../layouts/app/UserContext";
import { postMixpanel, updateWalkmeStatus } from "../../utils";

const steps = [
  {
    target: ".filter-menu",
    disableBeacon: true,
    disableScrolling: true,
    title: "Discover Discussions",
    content:
      "View the ideas people have been sharing in different channels. You can always search ideas or sort them based on your preference.",
  },
  {
    target: ".create-post-button",
    title: "Create Posts",

    content: "Start to compose your thoughts by clicking on create button.",
    disableScrolling: true,
  },
  {
    target: ".post-description",
    title: "Add Details of the post",
    disableScrolling: true,
    disableBeacon: true,

    content:
      "Articulate your ideas in details by giving it a simple title and adding far depth in details of the same.  ",
  },

  {
    target: ".footer-items",
    title: "Enrich your post",
    disableScrolling: true,

    content:
      "Get community feedback on your post by adding polls or add tasks to be tracked using the post by adding checklist. Use these features to create a far more enriched discussion post.",
  },
  {
    target: ".submit-post-button",
    title: "Create and Engage with Post",
    disableScrolling: true,

    content:
      "Click on 'Post' to share your posts with the member having relevant permission to view the post.",
  },
];
function DaoChannel() {
  const { walkMeStatus, reload } = useContext(UserContext);
  const { isMobile, windowSize } = useWindowSize();
  const [sortBy, setSortBy] = useState("Latest");
  const [updater, setUpdater] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canEngage, setCanEngage] = useState(false);
  const [showEndingModal, setShowEndingModal] = useState(false);
  const [startTour, setStartTour] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [channelName, setChannelName] = useState("");
  const { daoId, isSpace } = useDaoContext();

  useEffect(() => {
    if (!daoId) return;
    customAxios
      .get(`post/permission?daoId=${daoId}`)
      .then((res) => {
        setCanEngage(res.data.postPermission.includes("ENGAGE"));
        setCanEdit(res.data.postPermission.includes("MANAGE"));
        if (
          res.data.postPermission.includes("MANAGE") &&
          walkMeStatus &&
          walkMeStatus?.postCreate === false
        )
          setShowOnboardingModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [updater, daoId, walkMeStatus]);
  return (
    <div
      style={{
        paddingLeft: isMobile ? "15px" : "0",
      }}
    >
      {(windowSize.width < MD_SCREEN_BREAKPOINT || isSpace) && (
        <div className="lg:mt-5">
          <MobilePageTopNavbar channelName={channelName} />
        </div>
      )}
      {showOnboardingModal ? (
        <OnboardingModal
          step={"post-start"}
          showModal={showOnboardingModal}
          onClick={() => {
            updateWalkmeStatus({ postCreate: true }, reload);
            postMixpanel("track", "started_walke_me_tour_for_create_channel");

            setShowOnboardingModal(false);
            setStartTour(true);
          }}
          onClose={() => {
            setShowOnboardingModal(false);
          }}
        />
      ) : null}
      {showEndingModal ? (
        <OnboardingModal
          step={"post-end"}
          showModal={showEndingModal}
          onClick={() => {
            setShowEndingModal(false);
          }}
          onClose={() => {
            setShowEndingModal(false);
          }}
        />
      ) : null}

      {/* {startTour ? (
        <WalkmeTour
          run={startTour}
          steps={steps}
          otherProps={{
            disableScrollParentFix: true,
            disableScrolling: true,
            continuous: true,
            disableBeacon: true,
            callback: (step) => {
              console.log(step);
              if (
                step.step.target === ".create-post-button" &&
                step.action === "update"
              ) {
                setTimeout(() => {
                  setCreateModalVisible(true);
                }, 1000);
              }
              if (step.status === "finished") {
                setShowEndingModal(true);
                setCreateModalVisible(false);
              }
            },
          }}
        />
      ) : null} */}
      {(!isSpace || isMobile) && <div style={{ height: 20 }} />}
      <Post
        setUpdater={setUpdater}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortingTypeList={DEFAULT_SORTING_TYPE_LIST}
        canEdit={canEdit}
        isModalVisible={createModalVisible}
        setIsModalVisible={setCreateModalVisible}
        setChannelName={setChannelName}
      />

      <Feed
        updater={updater}
        canEdit={canEdit}
        sortBy={sortBy}
        sortingTypeList={DEFAULT_SORTING_TYPE_LIST}
        canManage={canEngage}
        canEngage={canEngage}
      />
    </div>
  );
}

export default DaoChannel;

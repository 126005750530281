import {
  ArrowLeftOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Input,
  Select,
  Skeleton,
  Space,
  Typography,
  Dropdown,
  Menu,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "../../styles/ModalStyles";
import customAxios from "../../api/index";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import GenosisIcon from "../../images/genosis.svg";
import metamaskIcon from "../../assets/iconSvg/metamask.png";
import addPaymentIcon from "../../assets/iconSvg/addPaymentEmptyState.svg";
import { ethers } from "ethers";
import { ReactComponent as SolanaIcon } from "../../assets/images/login/solana-sol-logo.svg";
import { startSolanaPayment } from "./SolanaPayment";
import { useWindow } from "@usedapp/core/dist/esm/src/providers";
import useWindowSize from "../../hooks/useWindowSize";
import { ethereumPayment } from "../Payment/EthereumPayment";
import { useDaoContext } from "../../layouts/app/DaoContext";
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
const items = [
  {
    key: "1",
    label: "Item 1",
  },
  {
    key: "2",
    label: "Item 2",
  },
  {
    key: "3",
    label: "Item 3",
  },
];

export const ModalBody = styled.div`
  width: 100%;
  padding: 16px 20px 20px 20px;

  & .heading {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
  }

  & .add-new-payment-btn {
    width: 100%;
    color: #3f83f8;
    margin: 0px;
    padding: 0px 12px;
    font-weight: 600;
    font-size: 12px;
  }

  & .initiate-payment-btn {
    width: 100%;
    border-radius: 8px;
    margin-top: 12px;
  }

  & .grid-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
  }

  & .label {
    font-weight: 600;
    font-size: 12px;
    color: #64748b;
    margin-bottom: 4px;
  }

  & .select {
    width: 100%;
    .ant-select-selector {
      border-radius: 8px !important;
    }
  }

  & .input {
    border-radius: 8px;
  }

  & .payment-option {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 16px 1fr 20px;
    grid-column-gap: 12px;
    padding: 14px 16px 12px 16px;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    margin-bottom: 12px;
    position: relative;

    &:hover {
      // border: 1px solid #9061f9;
      cursor: pointer;
      .delete-btn {
        display: block;
      }
    }
  }

  & .selected-option {
    border: 1px solid #9061f9;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  }

  & .delete-btn {
    display: none;
    position: absolute;
    border: none;
    right: 0.4rem;
    top: 0.2rem;
    align-self: center;
  }
`;
export const truncateWalletAddress = (addr) => {
  if (addr) {
    addr =
      addr.slice(0, 5) + "..." + addr.slice(addr.length - 5, addr.length - 1);
  }
  return addr;
};

const PaymentModal = ({
  visible,
  setVisibility,
  submissionDetails,
  bountyAmount,
  bountyId,
  fetchSubmissions,
  symbol,
  backendNetwork,
  decimals,
  contractAddress,
  chainId,
}) => {
  const [selectedPaymentMedium, setSelectedPaymentMedium] = useState("Wallet");
  const [addPaymentStep, setAddPaymentStep] = useState(false);
  const [paying, setPaying] = useState(false);
  const [savedPaymentMethodList, setSavedPaymentMethodList] = useState([]);
  const [paymentMethodMetaData, setPaymentMethodMetaData] = useState([]);
  const [selectedWalletType, setSelectedWalletType] = useState("");
  const [selectedNetworkType, setSelectedNetworkType] = useState("");
  const [gnosisAddress, setGnosisAddress] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(-1);
  const [selectedWallet, setSelectedWallet] = useState({});
  const { daoDetails, daoId } = useDaoContext();
  useEffect(() => {
    getSavePaymentMethod();
  }, [daoId]);

  useEffect(() => {
    if (!visible) {
      resetStatesHandler();
    }
  }, [visible]);
  // ! Local handlers
  const resetStatesHandler = () => {
    setAddPaymentStep(false);
    setSelectedNetworkType("");
    setGnosisAddress("");
    // setPaymentMethodMetaData([]);
    // setSelectedPaymentMethod(-1);
    // getSavePaymentMethod();
  };
  const getSavePaymentMethod = () => {
    customAxios
      .get(`bountypayments/fetch/savepaymentmethod?daoId=${daoId}`)
      .then(({ data }) => {
        setSavedPaymentMethodList(data.savedPaymentMethods);
        setSelectedPaymentMethod(
          data.savedPaymentMethods[data.savedPaymentMethods.length - 1]?.id
        );
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };

  const getPaymentMethodMetaData = () => {
    setAddPaymentStep(true);
    customAxios
      .get(`bountypayments/paymentmethodmetadata`)
      .then(({ data }) => {
        setPaymentMethodMetaData(data.paymentMethodMetadata);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };
  const connectWalletHandler = () => {
    customAxios
      .post(`bountypayments/savepaymentmethod`, {
        walletType: selectedWalletType,
        network: selectedNetworkType,
        walletAddress: gnosisAddress,
        daoId,
      })
      .then((res) => {
        getSavePaymentMethod();
        setAddPaymentStep(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };
  const deletePaymentMethodHanlder = (id) => {
    customAxios
      .delete(`/bountypayments/delete/paymentmethod`, {
        data: { daoId, methodId: id },
      })
      .then((res) => {
        getSavePaymentMethod();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const confirmSubmission = async (fromAddress, to) => {
    await customAxios.post("bountypayments/statement/create", {
      bountyId: bountyId,
      bountySubmissionId: submissionDetails.bountySubmissionId,
      from: fromAddress,
      to,
      recevierId: submissionDetails.userId,
      walletType: selectedWallet.walletType,
      network: backendNetwork,
      amount: bountyAmount,
      status: "PAYMENT_SUCCESSFUL",
    });
    setVisibility(false);
    fetchSubmissions();
  };
  const startPetraPayment = async () => {
    const sample =
      "0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC";
    if (window.aptos) {
      setPaying(true);
      const transaction = {
        arguments: [
          submissionDetails.aptosWalletAddress,
          bountyAmount * Math.pow(10, decimals),
        ],

        function: "0x1::coin::transfer",
        type: "entry_function_payload",
        type_arguments: ["0x1::aptos_coin::AptosCoin"],
        // type_arguments: [sample],
      };

      try {
        const pendingTransaction = await window.aptos.signAndSubmitTransaction(
          transaction
        );

        window.aptos.connect().then((res) => {
          // Return the address of the wallet
          const publicAddress = res.address;
          confirmSubmission(
            publicAddress,
            submissionDetails.aptosWalletAddress
          );
        });

        // In most cases a dApp will want to wait for the transaction, in these cases you can use the typescript sdk
        // const client = new AptosClient("https://testnet.aptoslabs.com");
        // const txn = await client.waitForTransactionWithResult(
        //   pendingTransaction.hash
        // );
      } catch (error) {
        console.log(error);
        toast.error("Some error occured");
        // see "Errors"
      } finally {
        setPaying(false);
      }
    } else {
      toast.info("Please install Petra wallet");
    }
  };
  const startMetamaskPayment = async () => {
    setPaying(true);

    try {
      const from = await ethereumPayment(
        contractAddress,
        bountyAmount * Math.pow(10, decimals),
        submissionDetails.walletAddress,
        parseInt(chainId),
        backendNetwork
      );

      await confirmSubmission(from, submissionDetails.walletAddress);
    } finally {
      setPaying(false);
    }
  };
  // let selectedWallet = savedPaymentMethodList.find(
  //   (method) => method.id === selectedPaymentMethod
  // );
  useEffect(() => {
    let selectedWalletLocal = savedPaymentMethodList.find(
      (method) => method.id === selectedPaymentMethod
    );
    setSelectedWallet(selectedWalletLocal);
  });

  const startGnosisPayment = () => {
    setPaying(true);

    customAxios
      .post("/bountypayments/initiate", {
        walletType: "GNOSIS",
        walletAddress: selectedWallet.address,
        userId: submissionDetails.userId,
        network: backendNetwork,
        bountyId: bountyId,
        bountySubmissionId: submissionDetails.bountySubmissionId,
      })
      .then((res) => {
        toast.success("Payment processed");
        setVisibility(false);
        fetchSubmissions();
      })
      .catch((err) => console.log("gnosis payment err", err))
      .finally(() => {
        setPaying(false);
      });
  };
  const { isMobile } = useWindowSize();
  return (
    <Modal
      centered
      destroyOnClose
      title={null}
      height="auto"
      isMobile={false}
      visible={visible}
      closable={true}
      onCancel={() => setVisibility(false)}
      footer={null}
      width={464}
    >
      <ModalBody>
        {!addPaymentStep ? (
          <>
            <Space className="heading">
              <Typography>Payment</Typography>
            </Space>

            {bountyAmount ? (
              paying ? (
                <Skeleton active />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#F9FAFB",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "12px",
                      width: "100%",
                    }}
                  >
                    <div>
                      {bountyAmount} {symbol} ({backendNetwork})
                    </div>
                    <div>{/* <PencilIcon color="#6B7280" width={20} /> */}</div>
                  </div>
                  <ArrowDownIcon
                    color="#6B7280"
                    width={24}
                    style={{ margin: "10px 0" }}
                  />
                  <div
                    style={{
                      backgroundColor: "#F9FAFB",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "12px",
                      width: "100%",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={
                          submissionDetails.bountySubmissionUserProfilePicture
                        }
                        style={{
                          width: "24px",
                          marginRight: "12px",
                          borderRadius: "150px",
                        }}
                        alt={submissionDetails.bountySubmissionUserName}
                      />
                      <span
                        style={{
                          color: "#374151",
                          fontSize: "14px",
                          fontWeight: "500",
                          display: "inline-flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#1F2937",
                            fontWeight: "500",
                          }}
                        >
                          {submissionDetails.bountySubmissionUserName}
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#6B7280",
                            fontWeight: "500",
                          }}
                        >
                          {submissionDetails.walletAddress}
                        </span>
                      </span>
                    </div>
                    <div>
                      <CopyOutlined
                        color="#6B7280"
                        strokeWidth={1.5}
                        width={16}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(
                              submissionDetails.walletAddress ||
                                submissionDetails.aptosWalletAddress
                            )
                            .then(
                              () => {
                                toast.success(
                                  "Wallet address copied successfully"
                                );
                              },
                              () => {
                                toast.error("Wallet address not copied");
                              }
                            );
                        }}
                      />
                    </div>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      maxWidth: "470px",
                      margin: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <input
                      type="number"
                      name=""
                      value={bountyAmount}
                      disabled
                      id=""
                      style={{
                        color: "#374151",
                        fontSize: "14px",
                        fontWeight: "500",
                        borderRadius: "12px",
                        border: "1px solid #D1D5DB",
                        padding: "9px 17px",
                        width: "122px",
                      }}
                    />
                    <span
                      style={{
                        color: "#374151",
                        fontSize: "14px",
                        fontWeight: "500",
                        borderRadius: "12px",
                        border: "1px solid #D1D5DB",
                        padding: "9px 17px",
                        width: "100px",
                        display: "inline-block",
                      }}
                    >
                      {symbol}
                    </span>
                    <ArrowRightIcon
                      color="#6B7280"
                      width={24}
                      strokeWidth={2}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "12px",
                        padding: "9px 17px",
                        width: "130px",
                      }}
                    >
                      <img
                        src={
                          submissionDetails.bountySubmissionUserProfilePicture
                        }
                        style={{ width: "24px", marginRight: "12px" }}
                        alt={submissionDetails.bountySubmissionUserName}
                      />
                      <span
                        style={{
                          color: "#374151",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        {submissionDetails.bountySubmissionUserName}
                      </span>
                    </div>
                  </div> */}
                  <div
                    style={{
                      margin: "24px 0 24px 0",
                      paddingTop: "24px",
                      borderTop: "1px dashed #E5E7EB",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        border: `1px solid`,
                        borderColor:
                          selectedPaymentMedium === "Wallet"
                            ? "#3B82F6"
                            : "#E5E7EB",
                        color:
                          selectedPaymentMedium === "Wallet"
                            ? "#3B82F6"
                            : "#6B7280",
                        backgroundColor:
                          selectedPaymentMedium === "Wallet"
                            ? "#EFF6FF"
                            : "#ffffff",
                        padding: "8px 12px",
                        borderRadius: "40px",
                        display: "inline-block",
                        marginRight: "8px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedPaymentMedium("Wallet");
                      }}
                    >
                      Wallet
                    </span>
                    <span
                      style={{
                        display: "inline-block",
                        border: `1px solid`,
                        borderColor:
                          selectedPaymentMedium === "Off platform"
                            ? "#3B82F6"
                            : "#E5E7EB",
                        color:
                          selectedPaymentMedium === "Off platform"
                            ? "#3B82F6"
                            : "#6B7280",
                        backgroundColor:
                          selectedPaymentMedium === "Off platform"
                            ? "#EFF6FF"
                            : "#ffffff",
                        padding: "8px 12px",
                        borderRadius: "40px",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedPaymentMedium("Off platform");
                      }}
                    >
                      Off platform
                    </span>
                  </div>
                  {selectedPaymentMedium === "Wallet" ? (
                    <>
                      {" "}
                      {savedPaymentMethodList.length > 0 ? (
                        <>
                          <Dropdown
                            overlay={
                              <Menu>
                                {savedPaymentMethodList.map((method) => {
                                  return (
                                    <Menu.Item
                                      onClick={() => {
                                        setSelectedPaymentMethod(method.id);
                                      }}
                                      style={{
                                        padding: "10px",
                                        borderRadius: "12px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {method.walletType.toLowerCase() ===
                                      "metamask" ? (
                                        <img
                                          src={metamaskIcon}
                                          alt="icon"
                                          height="16"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ) : method.walletType.toLowerCase() ===
                                        "phantom" ? (
                                        <SolanaIcon
                                          style={{
                                            height: "12px",
                                            marginRight: "5px",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={GenosisIcon}
                                          alt="icon"
                                          height="16"
                                          style={{ marginRight: "5px" }}
                                        />
                                      )}
                                      <span>
                                        {method.walletType} (
                                        {truncateWalletAddress(method.address)})
                                      </span>
                                    </Menu.Item>
                                  );
                                })}
                                <Menu.Item
                                  onClick={getPaymentMethodMetaData}
                                  style={{
                                    padding: "10px",
                                    borderRadius: "12px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <PlusCircleIcon
                                      color="#3B82F6"
                                      width={18}
                                      style={{ marginRight: "5px" }}
                                    />{" "}
                                    Add wallet
                                  </span>
                                </Menu.Item>
                              </Menu>
                            }
                            trigger={["click"]}
                          >
                            <div
                              style={{
                                border: "1px solid #D1D5DB",
                                width: "100%",
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "9px 17px",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                {selectedWallet?.walletType.toLowerCase() ===
                                "metamask" ? (
                                  <img
                                    src={metamaskIcon}
                                    alt="icon"
                                    height="16"
                                    style={{ marginRight: "5px" }}
                                  />
                                ) : selectedWallet?.walletType.toLowerCase() ===
                                  "phantom" ? (
                                  <SolanaIcon
                                    style={{
                                      height: "12px",
                                      marginRight: "5px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={GenosisIcon}
                                    alt="icon"
                                    height="16"
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                <span
                                  style={{
                                    color: "#1F2937",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {selectedWallet?.walletType} (
                                  {truncateWalletAddress(
                                    selectedWallet?.address
                                  )}
                                  )
                                </span>
                              </span>

                              <ChevronDownIcon
                                width={16}
                                color="#374151"
                                strokeWidth={2}
                              />
                            </div>
                          </Dropdown>
                          <span
                            style={{
                              display: "inline-block",
                              width: "100%",
                              backgroundColor: "#3B82F6",
                              borderRadius: "12px",
                              padding: "9px 0",
                              textAlign: "center",
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#ffffff",
                              marginTop: "24px",
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              if (selectedWallet.network !== backendNetwork) {
                                toast.error(
                                  `Please choose correct ${backendNetwork
                                    .split("_")
                                    .join(" ")
                                    .toLowerCase()} network wallet`
                                );
                                return;
                              }

                              if (!bountyAmount) {
                                setVisibility(false);
                                return;
                              }
                              switch (selectedWallet.walletType.toLowerCase()) {
                                case "metamask":
                                  startMetamaskPayment();

                                  break;
                                case "gnosis":
                                  startGnosisPayment();
                                  break;
                                case "petra":
                                  startPetraPayment();
                                  break;
                                case "phantom":
                                  {
                                    try {
                                      setPaying(true);
                                      await startSolanaPayment(
                                        selectedWallet,
                                        bountyAmount * Math.pow(10, decimals),
                                        submissionDetails?.publicKey,
                                        contractAddress
                                      );
                                      await confirmSubmission(
                                        selectedWallet.address,
                                        submissionDetails?.publicKey
                                      );
                                    } finally {
                                      setPaying(false);
                                    }
                                  }

                                  break;
                              }
                            }}
                          >
                            {bountyAmount ? <>Confirm payment</> : <>Ok</>}
                          </span>
                        </>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#EFF6FF",
                            borderRadius: "12px",
                            width: "100%",
                            color: "#3B82F6",
                            fontSize: "14px",
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "9px 0",
                            cursor: "pointer",
                          }}
                          onClick={getPaymentMethodMetaData}
                        >
                          <span
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <PlusCircleIcon
                              color="#3B82F6"
                              width={24}
                              style={{ marginRight: "5px" }}
                            />{" "}
                            Add wallet
                          </span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "18px 0",
                        border: "1px solid #D1D5DB",
                        borderRadius: "12px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#6B7280",
                          display: "inline-block",
                          textAlign: "center",
                        }}
                      >
                        Coming soon
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#6B7280",
                          display: "inline-block",
                          margin: "10px 0",
                          textAlign: "center",
                        }}
                      >
                        This functionality would enable you to document and
                        authenticate the payment that you conducted on a
                        different platform.
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#3B82F6",
                          display: "inline-block",
                          cursor: "pointer",
                          textAlign: "center",
                        }}
                      >
                        Contact us to get faster
                      </span>
                    </div>
                  )}

                  {/* {savedPaymentMethodList.map((method, index) => {
                    return (
                      <div
                        className={`payment-option ${
                          method.id === selectedPaymentMethod
                            ? "selected-option"
                            : ""
                        }`}
                        key={method.id}
                        onClick={() => {
                          setSelectedPaymentMethod(method.id);
                        }}
                      >
                        {method.walletType.toLowerCase() === "metamask" ? (
                          <img
                            src={metamaskIcon}
                            alt="icon"
                            height="16"
                            style={{ marginTop: "2px" }}
                          />
                        ) : method.walletType.toLowerCase() === "phantom" ? (
                          <SolanaIcon
                            style={{ height: "12px", marginTop: "2px" }}
                          />
                        ) : (
                          <img
                            src={GenosisIcon}
                            alt="icon"
                            height="16"
                            style={{ marginTop: "2px" }}
                          />
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            fontWeight: "600",
                          }}
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {method.walletType}
                          </Typography>
                          <Typography
                            style={{ fontSize: "12px", color: "#64748B" }}
                          >
                            {truncateWalletAddress(method.address)}
                          </Typography>
                        </div>
                        <Button
                          icon={<DeleteOutlined />}
                          shape="circle"
                          className="delete-btn"
                          onClick={() => deletePaymentMethodHanlder(method.id)}
                        />
                      </div>
                    );
                  })} */}
                </div>
              )
            ) : (
              <span>
                No amount is added on the bounty, hence payment is not possible.
              </span>
            )}

            {/* {bountyAmount && savedPaymentMethodList.length > 0 ? (
              <Button
                className="add-new-payment-btn"
                type="text"
                onClick={getPaymentMethodMetaData}
              >
                + Add new payment method
              </Button>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={addPaymentIcon}
                  alt=""
                  style={{
                    marginBottom: "20px",
                  }}
                />
                <Button
                  className="add-new-payment-btn"
                  type="primary"
                  onClick={getPaymentMethodMetaData}
                  style={{ color: "#ffffff", borderRadius: "8px" }}
                >
                  Add payment method
                </Button>
              </div>
            )}
            {savedPaymentMethodList?.length > 0 && (
              <Button
                className="initiate-payment-btn"
                type="primary"
                onClick={async () => {
                  if (selectedWallet.network !== backendNetwork) {
                    toast.error(
                      `Please choose correct ${backendNetwork
                        .split("_")
                        .join(" ")
                        .toLowerCase()} network wallet`
                    );
                    return;
                  }

                  if (!bountyAmount) {
                    setVisibility(false);
                    return;
                  }
                  switch (selectedWallet.walletType.toLowerCase()) {
                    case "metamask":
                      startMetamaskPayment();

                      break;
                    case "gnosis":
                      startGnosisPayment();
                      break;
                    case "petra":
                      startPetraPayment();
                      break;
                    case "phantom":
                      {
                        try {
                          setPaying(true);
                          await startSolanaPayment(
                            selectedWallet,
                            bountyAmount * Math.pow(10, decimals),
                            submissionDetails?.publicKey,
                            contractAddress
                          );
                          await confirmSubmission(
                            selectedWallet.address,
                            submissionDetails?.publicKey
                          );
                        } finally {
                          setPaying(false);
                        }
                      }

                      break;
                  }
                }}
              >
                {bountyAmount ? <>Initiate payment</> : <>Ok</>}
              </Button>
            )} */}
          </>
        ) : (
          <>
            <Space className="heading" style={{ marginBottom: "10px" }}>
              <Button
                type="text"
                style={{ padding: "0" }}
                onClick={resetStatesHandler}
              >
                <ArrowLeftOutlined />
              </Button>
              <Typography>Add payment method</Typography>
            </Space>
            <div className="grid-wrapper">
              <Typography className="label">Wallet type</Typography>
              <Typography className="label">Network</Typography>
              <Select
                style={{ textTransform: "capitalize" }}
                className="select"
                placeholder="Choose type"
                onSelect={(value) => setSelectedWalletType(value)}
              >
                {paymentMethodMetaData.map((method) => (
                  <Select.Option
                    value={method.walletType}
                    key={method.walletType}
                    style={{ textTransform: "capitalize" }}
                  >
                    {method.walletType.toLowerCase()}
                  </Select.Option>
                ))}
              </Select>
              <Select
                className="select"
                placeholder="Choose type"
                value={selectedNetworkType}
                onSelect={(value) => setSelectedNetworkType(value)}
                disabled={selectedWalletType === ""}
              >
                {selectedWalletType &&
                  paymentMethodMetaData
                    .filter(
                      (method) => method.walletType === selectedWalletType
                    )[0]
                    .network?.map((networkType) => (
                      <Select.Option value={networkType}>
                        {networkType}
                      </Select.Option>
                    ))}
              </Select>
            </div>
            <div style={{ marginTop: "12px" }}>
              <Typography className="label">Wallet address</Typography>
              <Input
                className="input"
                placeholder="Enter your wallet address"
                disabled={selectedNetworkType === ""}
                onChange={(e) => {
                  let addr = e.target.value;
                  if (addr.includes(":")) {
                    addr = addr.split(":")[1];
                  }
                  setGnosisAddress(addr);
                }}
              />
            </div>
            <Button
              className="initiate-payment-btn"
              type="primary"
              onClick={connectWalletHandler}
              disabled={gnosisAddress.trim() === ""}
              style={{ height: "40px", marginTop: "16px" }}
            >
              Add wallet
            </Button>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PaymentModal;

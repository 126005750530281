import React, { useRef, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Mention from "quill-mention";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/dist/quill.mention.css";
import styled from "styled-components";
import { useEffect } from "react";
import customAxios from "../../../api";
import { useParams } from "react-router";
import ImageResize from "quill-image-resize-module-react";
import ImageCompress from "quill-image-compress";
import GPTMenu from "../../GPT/GPTMenu";
import gpt from "../../../assets/images/gpt/robin.gif";

import {
  CommonMenu,
  InputSelectionMenu,
  InputSlashMenu,
} from "../../GPT/MenuItem";
import { GPTButton } from "../GPT";
import IconWrapper from "../../IconWrapper";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import markdown, { getCodeString } from "@wcj/markdown-to-html";
import MagicUrl from "quill-magic-url";
import { useDaoContext } from "../../../layouts/app/DaoContext";

const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const text = e.clipboardData.getData("text/plain");
    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(text);
    const index = text.length + range.index;
    const length = 0;
    this.quill.updateContents(delta, "silent");
    this.quill.setSelection(index, length, "silent");
    this.quill.scrollIntoView();
  }
}
export var formats = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "image",
  "mention",
  "video",
];
const StyledQuill = styled(ReactQuill)`
  padding: 4px;
  overflow: auto;
  & .ql-container {
    border: none !important;
  }
  .ql-tooltip {
    z-index: 20;
  }
  p {
    margin: 0;
    white-space: pre-wrap;
  }
  .ql-stroke,
  .ql-snow {
    stroke: #64748b;
    color: #64748b;
  }
  & .ql-toolbar {
    & .ql-formats {
      margin-right: 8px;
    }
    background: white;
    width: 100%;
    border: ${(props) => (props.isBordered ? "" : "none")};
    position: ${(props) => (props.isBordered ? "" : "absolute")};
    top: 0px;
    display: ${(props) => (props.disableToolbar ? "none" : "block")};
    z-index: 1;
    // background: white;
    padding: ${(props) => (props.isBordered ? "" : "0px 4px 4px 4px")};
    padding-left: 8px;
    border-radius: ${(props) =>
      props.isBordered ? "12px 12px 0px 0px" : "8px"};
  }
  & .ql-editor {
    overflow: auto;
    margin-bottom: ${(props) => (props.isBordered ? "" : "1.5rem")};
    font-size: 14px;
    height: ${(props) =>
      props.isDynamicHeight
        ? "auto !important"
        : props.height
        ? props.height
        : "100%"};
    min-height: ${(props) =>
      props.height
        ? `${props.height} !important`
        : props.disableToolbar
        ? "50px"
        : "96px"};
    word-break: break-word;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "500px")};
    padding: 8px 15px;

    padding-top: ${(props) =>
      props.disableToolbar || props.isBordered ? "" : "28px"};
    ${(props) => {
      if (props.bordered)
        return `         
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        &:hover{
           border-color: #40a9ff;
        }
        &:focus {
             border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
        }
        `;
      else return "";
    }}
  }
  & .ql-mention-list-container {
    list-style: none;
    padding: 10px;
    border-radius: 12px;
    z-index: 20;
  }
  & .ql-mention-list {
    list-style-type: none;
    margin: 0;
    max-height: 240px;
  }
  & .ql-mention-list-item {
    margin: 0;
    border-radius: 12px;
    padding: 0px 8px;
    background: white;
    & .selected {
      background: #d3e1eb;
      &:hover {
        background: #d3e1eb;
      }
    }
    &:hover {
      background: #f3f3f3;
    }
  }
  & .ql-blank {
    &::before {
      font-family: Inter;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #bfbfbf;
      font-style: normal;
    }
  }
  .ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "Enter Url:" !important;
  }
`;
var values = [];
var addUser = (userId) => {};
const modules = {
  toolbar: "#toolbar",
  // toolbar: [
  //   ["bold", "italic", "underline"],
  //   ["link"],
  //   [{ header: [1, 2, false] }],
  //   [({ list: "ordered" }, { list: "bullet" })],
  //   ["image"],
  // ],
  magicUrl: true,

  mention: {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@", "#"],
    onSelect: function (item, insertItem) {
      addUser(item.id);
      insertItem(
        { denotationChar: item.denotationChar, id: item.id, value: item.value },
        true
      );
    },
    source: function (searchTerm, renderList) {
      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = [];
        for (let i = 0; i < values.length; i++)
          if (
            ~values[i].value?.toLowerCase().indexOf(searchTerm?.toLowerCase())
          )
            matches.push(values[i]);
        renderList(matches, searchTerm);
      }
    },
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
  imageCompress: {
    quality: 0.7, // default
    maxWidth: 1000, // default
    maxHeight: 1000, // default
    imageType: "image/jpeg", // default
    debug: true, // default
  },
};
Quill.register("module/mention", Mention);
Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);
Quill.register("modules/clipboard", PlainClipboard, true);
Quill.register("modules/magicUrl", MagicUrl);

const QuillEditor = (props) => {
  const { daoId } = useDaoContext();
  const [mentionedUsers, setMentionedUsers] = useState([]);
  addUser = (userId) => {
    if (!(userId in mentionedUsers)) {
      setMentionedUsers([...mentionedUsers, userId]);
      props.setMentionedUsers([...mentionedUsers, userId]);
    }
  };
  useEffect(() => {
    customAxios
      .get(`users/daousers?daoId=${daoId ? daoId : props.daoId}`)
      .then((res) => {
        values = res.data.daoUser.map((singleUser) => {
          return { id: singleUser.id, value: singleUser.userName };
        });
      })
      .catch((err) => console.log("daousers_err", err));
  }, []);
  const quillRef = useRef(null);
  const quill = quillRef.current?.getEditor();
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [buttonMenu, setButtonMenu] = useState(false);
  const [range, setRange] = useState(undefined);
  const handleSelectionChange = (range) => {
    if (showDropdown) {
      setRange((prev) => prev);
    } else {
      setRange(range);
    }
  };
  console.log(range)
  return (
    <div
      className="quillBound text-editor"
      style={{ paddingTop: "20px", position: "relative" }}
    >
      <div style={{ position: "relative" }}>
        <button
          tabIndex={-1}
          onClick={(e) => {
            setButtonMenu(true);
            setShowDropdown(true);
            const { top, left } = quill.getBounds(range?.index + range?.length);
            setDropdownPosition({ top: top + 30, left });
          }}
          style={{
            background: "none",
            position: "absolute",
            border: "1px solid #E5E7EB",
            borderLeft: "1px solid #E5E7EB",
            borderRight: "none",
            borderRadius: "8px 0 0 8px",
            left: "15px",
            padding: "0px 8px",
            height: "42px",
            cursor: "pointer",
          }}
        >
          <img
            src={gpt}
            width="24px"
            height={24}
            style={{ marginRight: "4px" }}
          />
          Ask Robin
          <IconWrapper style={{ marginLeft: "4px" }} width={12}>
            {showDropdown ? (
              <ChevronUpIcon stroke="2px" />
            ) : (
              <ChevronDownIcon stroke="2px" />
            )}
          </IconWrapper>
        </button>
        <div
          id="toolbar"
          style={{
            margin: "0 15px 0 137px",
            border: "1px solid #E5E7EB",
            borderLeft: "none",
            borderRadius: "0 8px 8px 0",
            display: "flex",
          }}
        >
          <select class="ql-size">
            <option value="small"></option>
            <option selected></option>
            <option value="large"></option>
            <option value="huge"></option>
          </select>
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <button class="ql-underline"></button>
          <button class="ql-strike"></button>
          <select class="ql-color" />
          <select class="ql-background" />
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <button class="ql-link"></button>
          <button class="ql-code"></button>
          <button class="ql-video"></button>
          <button class="ql-image"></button>
        </div>
      </div>

      <StyledQuill
        disableToolbar={props.disableToolbar}
        style={{
          // ...props.style,
          border: props.bordered ? "1px solid #F1F5F9" : "",
          borderRadius: "12px",
          ...props.style,
        }}
        bounds=".quillBound"
        // onMouseDown={(e) => {
        //   e.preventDefault();
        // }}
        onKeyDown={(e) => {
          const { top, left } = quill.getBounds(range?.index);
          setDropdownPosition({ top: top + 30, left });
          if (e.key === "/") {
            setButtonMenu(false);
            setShowDropdown(true);
          } else {
            setShowDropdown(false);
          }
        }}
        ref={quillRef}
        theme="snow"
        height={props?.height}
        formats={formats}
        isBordered={props.isBordered}
        className={props.className}
        placeholder="✨ Press “/” for Ai prompts"
        modules={modules}
        onChangeSelection={handleSelectionChange}
        value={props.value}
        onChange={(e) => {
          if (props.setUpdatedFieldsNames)
            props.setUpdatedFieldsNames((prev) =>
              prev.includes("description") ? prev : [...prev, "description"]
            );
          props.onChange(e === "<p><br></p>" ? "" : e);
        }}
        {...props.otherProps}
      />
      <GPTMenu
        buttonMenu={buttonMenu}
        buttonMenuPosition={{ top: 64, left: 15 }}
        position={{
          left: dropdownPosition.left,
          top: dropdownPosition.top + 52,
        }}
        onSubmit={(result) => {
          if (result?.type) {
            if (result.type === "CHECKLIST") {
              props?.populateChecklist(result.list);
            } else if (result.type === "POLL") {
              props.populatePoll(result.list, result.question);
            }
          } else {
            let rangeIndex = range? range.index:0;
            if (quill.getText(rangeIndex, 1) === "/") {
              quill.deleteText(rangeIndex, 1);
            }
            if (range?.length > 0) {
              quill.deleteText(rangeIndex, range.length);
            }
            const currText = quill.getText();
            props.onChange(
              currText.slice(0, rangeIndex) +
                markdown(result) +
                currText.slice(rangeIndex)
            );
            setRange(undefined);
          }
        }}
        isMenuVisible={showDropdown}
        onDropdownClose={() => {
          setShowDropdown(false);
        }}
        openInModal
        menuItems={
          range?.length > 0
            ? [...InputSelectionMenu, ...CommonMenu, ...InputSlashMenu]
            : [...InputSlashMenu, ...CommonMenu]
        }
        requestText={ range? quill?.getText(range?.index, range?.length):""}
      />
    </div>
  );
};

export default QuillEditor;

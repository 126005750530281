import { Menu } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
import useWindowSize from "../hooks/useWindowSize";
import { SideBarItemType } from "../lib/types/sidebar";

type Props = {
  menuItems: SideBarItemType[];
  selectedMenuItem: string;
  setSelectedMenuItem: Dispatch<SetStateAction<string>>;
  setMobileSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

const SidemenuModules = ({
  menuItems,
  selectedMenuItem,
  setSelectedMenuItem,
  setMobileSidebarOpen,
}: Props) => {
  const { isMobile } = useWindowSize();
  const navigate = useDaoLensNavigate();

  return (
    <Menu.SubMenu
    className="sidebar"
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
          }}
          className="pl-6"
        >
          <span className="m-0 text-gray-500 font-semibold">Modules</span>
        </div>
      }
    >
      {menuItems.map((item) => {
        if (!item.submenu) {
          return (
            <Menu.Item
              key={item.title}
              style={{
                margin: 0,

                borderBottom:
                  item.title === "Upcoming Events" ||
                  item.title === "Your Project"
                    ? "1px solid #F4F2F2"
                    : "none",
                color: selectedMenuItem === item.title ? "#1890ff" : "black",
                borderRight:
                  selectedMenuItem === item.title
                    ? "1px solid #1890ff"
                    : "none",
                backgroundColor:
                  selectedMenuItem === item.title ? "#f7f7f7" : "initial",
              }}
              onClick={() => {
                setSelectedMenuItem(item.title);
                if (isMobile) setMobileSidebarOpen(false);
                navigate(`${item.route}`);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <img
                    src={item.icon}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  />
                  {item.title}
                </div>
                {item?.isNew && (
                  <span className="text-blue-500 font-semibold bg-blue-50 rounded-lg px-2 py-1 self-center h-5 text-xs leading-0">
                    NEW
                  </span>
                )}
                {item?.notifications && (
                  <div
                    style={{
                      background: "#3B82F6",
                      borderRadius: "50px",
                      color: "white",
                      width: "40px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "4%",
                    }}
                  >
                    {item.notifications}
                  </div>
                )}
              </div>
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu.SubMenu>
  );
};

export default SidemenuModules;

import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import customAxios from "../../api";
import PostCommentReplyEditor from "./PostCommentReplyEditor";
import "./InternalComponentStyles/UserComment.scss";
import { useParams } from "react-router";

// ! +1 like
const like = (commentId) => {
  customAxios
    .post("/postcomments/vote", {
      type: "like",
      commentId: commentId,
      increase: true,
    })
    .then((res) => {
      console.log("like res", res.data);
    })
    .catch((err) => console.log(err));
};

// ! -1 like
const unlike = (commentId) => {
  customAxios
    .post("/postcomments/vote", {
      type: "like",
      commentId: commentId,
      increase: false,
    })
    .then((res) => {
      console.log("like res", res.data);
    })
    .catch((err) => console.log(err));
};

// ! +1 dislike
const dislike = (commentId) => {
  customAxios
    .post("/postcomments/vote", {
      type: "dislike",
      commentId: commentId,
      increase: true,
    })
    .then((res) => {
      console.log("like res", res.data);
    })
    .catch((err) => console.log(err));
};

// ! -1 dislike
const undislike = (commentId) => {
  customAxios
    .post("/postcomments/vote", {
      type: "dislike",
      commentId: commentId,
      increase: false,
    })
    .then((res) => {
      console.log("like res", res.data);
    })
    .catch((err) => console.log(err));
};

function LikeAction({
  likesCount,
  commentId,
  isLiked,
  isDisliked,
  likeStatus,
  likeStatusHandler,
}) {
  const [isSelected, setIsSelected] = useState(isLiked);
  const [localCount, setLocalCount] = useState(likesCount);
  const toggleHandler = () => {
    if (isDisliked) {
      undislike(commentId);
      likeStatusHandler(1);
    }
    setIsSelected((prevState) => {
      if (prevState) {
        setLocalCount(localCount - 1);
        unlike(commentId);
      } else {
        setLocalCount(localCount + 1);
        like(commentId);
      }
      return !prevState;
    });
  };

  useEffect(() => {
    if (likeStatus === 2 && isSelected === true) {
      setIsSelected((prevState) => {
        if (prevState) {
          setLocalCount(localCount - 1);
          unlike(commentId);
        } else {
          setLocalCount(localCount + 1);
          like(commentId);
        }
        return !prevState;
      });
    }
  }, [likeStatus]);

  return (
    <div
      className="user-comment__actions--right--small-icon"
      onClick={(e) => {
        e.stopPropagation();
        toggleHandler();
      }}
    >
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1667 7.5013H14.1367C14.4207 7.50131 14.7001 7.57392 14.9482 7.71224C15.1963 7.85055 15.4049 8.04999 15.5543 8.2916C15.7036 8.53322 15.7888 8.809 15.8016 9.09277C15.8144 9.37654 15.7545 9.65887 15.6275 9.91297L12.7108 15.7463C12.5724 16.0234 12.3595 16.2564 12.096 16.4192C11.8325 16.582 11.5289 16.6681 11.2192 16.668H7.87167C7.73583 16.668 7.6 16.6513 7.4675 16.618L4.33333 15.8346M10.1667 7.5013V3.33464C10.1667 2.89261 9.99107 2.46868 9.67851 2.15612C9.36595 1.84356 8.94203 1.66797 8.5 1.66797H8.42083C8.00417 1.66797 7.66667 2.00547 7.66667 2.42214C7.66667 3.01714 7.49083 3.5988 7.16 4.0938L4.33333 8.33464V15.8346M10.1667 7.5013H8.5M4.33333 15.8346H2.66667C2.22464 15.8346 1.80072 15.659 1.48816 15.3465C1.17559 15.0339 1 14.61 1 14.168V9.16797C1 8.72594 1.17559 8.30202 1.48816 7.98946C1.80072 7.6769 2.22464 7.5013 2.66667 7.5013H4.75"
          stroke={isSelected ? "#22C55E" : "#64748B"}
          strokeWidth="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style={{ color: isSelected ? "#22C55E" : "#64748B" }}>
        {localCount}
      </span>
    </div>
  );
}

function DislikeAction({
  dislikesCount,
  commentId,
  isLiked,
  isDisliked,
  likeStatus,
  likeStatusHandler,
}) {
  const [isSelected, setIsSelected] = useState(isDisliked);
  const [localCount, setLocalCount] = useState(dislikesCount);

  const toggleHandler = () => {
    if (isLiked) {
      unlike(commentId);
      likeStatusHandler(2);
    }
    setIsSelected((prevState) => {
      if (prevState) {
        setLocalCount(localCount - 1);
        undislike(commentId);
      } else {
        setLocalCount(localCount + 1);
        dislike(commentId);
      }
      return !prevState;
    });
  };
  useEffect(() => {
    if (likeStatus === 1 && isSelected === true) {
      setIsSelected((prevState) => {
        if (prevState) {
          setLocalCount(localCount - 1);
          unlike(commentId);
        } else {
          setLocalCount(localCount + 1);
          like(commentId);
        }
        return !prevState;
      });
    }
  }, [likeStatus]);

  return (
    <div
      className="user-comment__actions--right--small-icon"
      onClick={(e) => {
        e.stopPropagation();
        toggleHandler();
      }}
    >
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.83192 10.8346H2.86192C2.57787 10.8346 2.29853 10.762 2.05042 10.6237C1.80232 10.4854 1.59368 10.2859 1.44432 10.0443C1.29496 9.80272 1.20983 9.52693 1.19701 9.24317C1.18419 8.9594 1.24412 8.67707 1.37109 8.42297L4.28776 2.58964C4.42614 2.31271 4.63891 2.07979 4.90223 1.917C5.16555 1.75421 5.46901 1.66798 5.77859 1.66797H9.12692C9.26318 1.66805 9.39892 1.68484 9.53109 1.71797L12.6644 2.5013M6.83109 10.8346V15.0013C6.83109 15.4433 7.00668 15.8673 7.31924 16.1798C7.63181 16.4924 8.05573 16.668 8.49776 16.668H8.57776C8.99442 16.668 9.33192 16.3305 9.33192 15.9146C9.33192 15.3188 9.50776 14.7371 9.83859 14.2413L12.6653 10.0013V2.5013M6.83192 10.8346H8.49859M12.6653 2.5013H14.3319C14.774 2.5013 15.1979 2.6769 15.5104 2.98946C15.823 3.30202 15.9986 3.72594 15.9986 4.16797V9.16797C15.9986 9.61 15.823 10.0339 15.5104 10.3465C15.1979 10.659 14.774 10.8346 14.3319 10.8346H12.2486"
          stroke={isSelected ? "#D31027" : "#64748B"}
          strokeWidth="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style={{ color: isSelected ? "#D31027" : "#64748B" }}>
        {localCount}
      </span>
    </div>
  );
}

function RepliesAction({ repliesCount, clickHandler }) {
  const [isSelected, setIsSelected] = useState(false);
  const [localCount, setLocalCount] = useState(repliesCount);
  const toggleHandler = () => {
    setIsSelected((prevState) => {
      if (prevState) {
        setLocalCount(localCount - 1);
      } else setLocalCount(localCount + 1);
      return !prevState;
    });
    clickHandler();
  };
  return (
    <div
      className="user-comment__actions--right--small-icon"
      onClick={(e) => {
        e.stopPropagation();
        toggleHandler();
      }}
    >
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.33333 4.83529H12.6667M4.33333 8.16862H7.66667M8.5 14.8353L5.16667 11.502H2.66667C2.22464 11.502 1.80072 11.3264 1.48816 11.0138C1.17559 10.7012 1 10.2773 1 9.83529V3.16862C1 2.72659 1.17559 2.30267 1.48816 1.99011C1.80072 1.67755 2.22464 1.50195 2.66667 1.50195H14.3333C14.7754 1.50195 15.1993 1.67755 15.5118 1.99011C15.8244 2.30267 16 2.72659 16 3.16862V9.83529C16 10.2773 15.8244 10.7012 15.5118 11.0138C15.1993 11.3264 14.7754 11.502 14.3333 11.502H11.8333L8.5 14.8353Z"
          stroke={isSelected ? "#3B82F6" : "#64748B"}
          strokeWidth="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style={{ color: isSelected ? "#3B82F6" : "#64748B" }}>
        {repliesCount}
      </span>
    </div>
  );
}

function PostUserComment({ commentText, icon, canReply, commentDetails }) {
  const { channelId } = useParams();
  // ! Local states
  const [showReplyBar, setShowReplyBar] = useState(false);
  const [replyLineHeights, setreplyLineHeights] = useState([]);
  const [allReplies, setAllReplies] = useState([]);
  const [likeStatus, setLikeStatus] = useState(0);

  const commentRef = useRef(null);
  const replyEditorRef = useRef(null);
  const currentReplyRef = useRef(null);

  const replyHandler = (state) => {
    setShowReplyBar(state);
  };

  const handleReplyLineHeights = (heights) => {
    setreplyLineHeights(heights);
  };

  const addReply = (reply, callback) => {
    // handleReplyLineHeights([0]);
    customAxios
      .post("/postcomments/create", {
        postId: commentDetails.postId,
        parentId: commentDetails.id,
        description: reply,
        channelId: channelId,
      })
      .then((res) => {
        console.log("replies push", res.data);
        fetchReplies();
        // setComments([...comments, { description: commentText }]);
      })
      .catch((err) => console.log(err));
    setAllReplies((prevReplies) => {
      prevReplies.push(reply);
      return prevReplies;
    });
  };

  const fetchReplies = (callback = null) => {
    customAxios
      .post("/postcomments/fetch/child", {
        postId: commentDetails.postId,
        commentId: commentDetails.id,
      })
      .then((res) => {
        // console.log("all replies", res.data);
        setAllReplies(res.data.childComments);
        // if (callback) callback();
      })
      .catch((err) => console.log(err));
  };

  const likeStatusHandler = (status) => {
    setLikeStatus(status);
  };

  useEffect(() => {
    if (canReply) {
      fetchReplies();
    }
  }, []);

  useEffect(() => {
    // handleReplyLineHeights([replyEditorRef.current.y - commentRef.current.y]);
  }, [allReplies]);

  console.log("allReplies", allReplies);

  return (
    <div
      className={
        canReply ? "user-comment" : "user-comment user-comment__is-reply"
      }
    >
      <div className="user-comment__details">
        <img
          src={icon}
          alt=""
          className="user-comment__details--icon"
          ref={commentRef}
        />
        <div className="user-comment__details--text">
          <p>{commentText}</p>
        </div>
        {showReplyBar &&
          canReply &&
          replyLineHeights.map((currentHeight, index) => {
            return (
              <span
                key={index}
                className="user-comment__details--vertical-line"
                style={{ height: currentHeight / 2 + 8 }}
              ></span>
            );
          })}
        {!canReply && (
          <span className="user-comment__details--curve-line"></span>
        )}
      </div>
      <div className="user-comment__actions">
        <div className="user-comment__actions--left">
          {canReply && !showReplyBar && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                replyHandler(true);
              }}
            >
              Reply to
            </span>
          )}
        </div>
        <div className="user-comment__actions--right">
          {canReply && (
            <RepliesAction
              repliesCount={allReplies.length}
              clickHandler={() => replyHandler(!showReplyBar)}
            />
          )}
          <DislikeAction
            likeStatus={likeStatus}
            likeStatusHandler={likeStatusHandler}
            dislikesCount={commentDetails?.dislikes}
            commentId={commentDetails?.id}
            isLiked={
              commentDetails?.vote?.[0]?.type === "LIKE" ||
              commentDetails?.vote?.[1]?.type === "LIKE"
            }
            isDisliked={
              commentDetails?.vote?.[0]?.type === "DISLIKE" ||
              commentDetails?.vote?.[1]?.type === "DISLIKE"
            }
          />
          <LikeAction
            likeStatus={likeStatus}
            likeStatusHandler={likeStatusHandler}
            likesCount={commentDetails?.likes}
            commentId={commentDetails?.id}
            isLiked={
              commentDetails?.vote?.[0]?.type === "LIKE" ||
              commentDetails?.vote?.[1]?.type === "LIKE"
            }
            isDisliked={
              commentDetails?.vote?.[0]?.type === "DISLIKE" ||
              commentDetails?.vote?.[1]?.type === "DISLIKE"
            }
          />
        </div>
      </div>
      {showReplyBar &&
        canReply &&
        allReplies.map((singleReply, index) => {
          return (
            <PostUserComment
              key={index}
              handleReplyLineHeights={handleReplyLineHeights}
              canReply={false}
              commentText={singleReply.description}
              icon={icon}
              commentDetails={singleReply}
            />
          );
        })}
      {showReplyBar && canReply && (
        <PostCommentReplyEditor
          replyEditorRef={replyEditorRef}
          commentRef={commentRef}
          handleReplyLineHeights={handleReplyLineHeights}
          replyHandler={replyHandler}
          addReply={addReply}
          repliesLength={allReplies.length}
        />
      )}
    </div>
  );
}

export default PostUserComment;

import React, { useEffect, useState } from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import MobilePageTopNavbar from "../../../Common/MobilePageTopNavbar";
import GoBackButton from "../../../InternalComponents/GoBackButton";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "react-query";
import {
  deleteAnnouncement,
  fetchAnnouncementById,
  voteOnAnnouncement,
} from "../../../../utils/Announcements";
import { Dropdown, Menu, Skeleton } from "antd";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import customAxios from "../../../../api";
import LikeDislike from "../../../Common/LikeDislike";
import defaultUser from "../../../../assets/images/login/defaultUser.jpeg";
import CreateAnnouncementModal from "./CreateAnnouncementModal";
import { confirmOperation } from "../../../../utils/common";
import { toast } from "react-toastify";
import Share from "../../../BountyComponents/Share";
import { postMixpanel } from "../../../../utils";

import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../../../Common/ProfileCard";
import SlidingContextSidebar from "../../../SlidingContextSidebar";

const DetailsPage = () => {
  const { isMobile } = useWindowSize();
  const navigate = useDaoLensNavigate();
  const nativeNavigate = useNavigate();
  const { announcementId } = useParams();
  const { daoId, isSpace } = useDaoContext();

  const { data, isLoading } = useQuery(
    ["announcement-details", announcementId],
    {
      queryFn: () => fetchAnnouncementById(announcementId || ""),
      onError: (error) => {
        console.error(error);
        toast.error("Something went wrong");
      },
    }
  );

  const { mutate: deleteCurrentAnnouncement } = useMutation(
    () => deleteAnnouncement(data?.id || ""),
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to delete announcement");
      },
      onSuccess: () => navigate(`/app/dao/${daoId}/overview`),
    }
  );

  const [canEdit, setCanEdit] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`settings/canview`, { daoId: daoId })
      .then((res) => {
        setCanEdit(res.data.canView ? true : false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]); // ! Local handlers

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "0.75rem",
        width: isMobile ? "100vw" : "auto",
        paddingLeft: isMobile ? "15px" : "0",
      }}
    >
      {isMobile || isSpace ? (
        <div className="lg:mt-5">
          <MobilePageTopNavbar />
        </div>
      ) : null}

      <GoBackButton
        showIcon
        buttonText={"Go Back"}
        clickHandler={() => {
          nativeNavigate(-1);
        }}
        style={{ marginTop: isMobile || isSpace ? undefined : "20px" }}
      />

      <div
        style={{
          background: "white",
          borderRadius: "16px",
          padding: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoading && <Skeleton paragraph={{ rows: 5 }} />}
        {data && (
          <>
            <img
              src={data?.icon}
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "12px",
                height: "200px",
              }}
              alt="Cover"
            />
            <div
              style={{ display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <ProfileCardPopup userId={data?.userId}>
                    <div
                      onClick={() => navigate(`/app/profile/${data.userId}`)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <img
                        src={data.profilePicture || defaultUser}
                        alt={data.userName}
                        style={{
                          width: "20px",
                          height: "20px",
                          objectFit: "cover",
                          borderRadius: "100%",
                        }}
                      />
                      <span style={{ fontSize: "12px", color: "#6B7280" }}>
                        {data.userName}
                      </span>
                    </div>
                  </ProfileCardPopup>
                  <div
                    style={{
                      width: "4px",
                      height: 4,
                      background: "#9CA3AF",
                      borderRadius: "100%",
                    }}
                  />
                  <p
                    style={{ margin: "0", fontSize: "12px", color: "#6B7280" }}
                  >
                    {" "}
                    {moment(data.createdAt).fromNow()}
                  </p>
                </div>
                {canEdit && (
                  <Dropdown
                    overlay={
                      <Menu style={{ borderRadius: "8px" }}>
                        <Menu.Item
                          onClick={(e) => {
                            e.domEvent.stopPropagation();
                            setIsEditModalOpen(true);
                          }}
                        >
                          <PencilIcon
                            fill="#6B7280"
                            width={12}
                            style={{ marginRight: "4px" }}
                          />
                          <span>Edit</span>
                        </Menu.Item>
                        <Menu.Item
                          onClick={(e) => {
                            e.domEvent.stopPropagation();
                            postMixpanel(
                              "track",
                              "clicked_delete_announcement",
                              {
                                dao_id: daoId,
                                announcement_id: announcementId,
                              }
                            );
                            confirmOperation({
                              onOk: () => deleteCurrentAnnouncement(),
                              cancelText: "Cancel",
                              confirmText: "Delete",
                              message:
                                "Are you sure you want to delete this annoucement?",
                              title: "Delete announcement",
                            });
                          }}
                        >
                          <TrashIcon
                            fill="#6B7280"
                            width={12}
                            style={{ marginRight: "4px" }}
                          />
                          Delete
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <EllipsisHorizontalIcon
                      width={"20px"}
                      style={{ cursor: "pointer" }}
                    />
                  </Dropdown>
                )}
              </div>

              <h1
                style={{
                  margin: 0,
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#000000",
                }}
              >
                {data?.title}
              </h1>
            </div>
            <p
              style={{
                margin: 0,
                color: "#1F2937",
                fontSize: "14px",
                textAlign: "left",
                lineHeight: "16px",
              }}
              className="rich-text announcement"
            >
              {ReactHtmlParser(data.description)}
            </p>
            <div style={{ alignSelf: "end", display: "flex", gap: "8px" }}>
              <SlidingContextSidebar
                entityId={announcementId}
                entityType="ANNOUNCEMENT"
                canEdit={canEdit}
              />
              <Share entity="announcement" style={{}} />
              <LikeDislike
                onDislike={() =>
                  voteOnAnnouncement({
                    type: "dislike",
                    announcementId: data.id,
                    increase: true,
                  })
                }
                onLike={() =>
                  voteOnAnnouncement({
                    type: "like",
                    announcementId: data.id,
                    increase: true,
                  })
                }
                onUndoLike={() =>
                  voteOnAnnouncement({
                    type: "like",
                    announcementId: data.id,
                    increase: false,
                  })
                }
                onUndoDislike={() =>
                  voteOnAnnouncement({
                    type: "dislike",
                    announcementId: data.id,
                    increase: false,
                  })
                }
                dislikeCount={data.totalDisLikedVote}
                likeCount={data.totalLikedVote}
                currentUserVote={data?.vote?.type || undefined}
              />
            </div>
          </>
        )}
      </div>
      {data && (
        <CreateAnnouncementModal
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          data={data}
          isEdit
        />
      )}
    </div>
  );
};

export default DetailsPage;

import { Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import QuillEditor from "../QuillEditor";
import { FolderIcon } from "@heroicons/react/24/solid";
import { checkFileSize, checkImageType } from "../../utils";
import { PhotoIcon } from "@heroicons/react/24/outline";
import useWindowSize from "../../hooks/useWindowSize";

const StyledInput = styled(Input)`
  ::placeholder {
    color: #97a6ba;
    font: normal 500 14px/20px Inter;
  }
`;

const AboutCommunity = ({
  communityName,
  setCommunityName,
  aboutCommunity,
  setAboutCommunity,
  isImportedFromDiscord,
  profile,
  setProfile,
}) => {
  const { isMobile } = useWindowSize();
  return (
    <>
      {!isImportedFromDiscord && (
        <div style={{ display: "flex", gap: profile ? "12px" : "24px" }}>
          <div style={{ width: isMobile ? "100%" : "80px" }}>
            <FileUploader
              handleChange={(newFile) => {
                checkImageType(newFile.type);
                checkFileSize(newFile.size);
                setProfile(newFile);
              }}
              name="file"
            >
              {profile ? (
                <img
                  src={URL.createObjectURL(profile)}
                  style={{
                    borderRadius: "50%",
                    width: "100%",
                    aspectRatio: 1,
                    objectFit: "cover",
                  }}
                />
              ) : (
                <div
                  style={{
                    cursor: "pointer",
                    height: "100%",
                    aspectRatio: 1,
                    background: "rgba(226, 232, 240, 0.2)",
                    font: "normal 600 14px/20px Inter",
                    color: "#1A202E",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    border: "1px dashed #CFD8E3",
                    borderRadius: "50%",
                  }}
                >
                  <PhotoIcon width={28} color="#64748B" strokeWidth="2px" />
                </div>
              )}
            </FileUploader>
          </div>
          <div
            style={{
              font: "normal 500 14px/20px Inter",
              color: "#1F2937",
              width: "100%",
            }}
          >
            Name your community *
            <StyledInput
              style={{
                borderRadius: "12px",
                padding: "12px 12px 12px 16px",
                marginTop: "8px",
              }}
              maxLength="30"
              value={communityName}
              onChange={(e) => setCommunityName(e.currentTarget.value)}
              placeholder="Eg. Cool community"
            />
          </div>
        </div>
      )}
      <div>
        <div
          style={{
            font: "normal 500 14px/20px Inter",
            color: "#1F2937",
            marginBottom: "8px",
          }}
        >
          What’s the goal of your community? *
        </div>
        <QuillEditor
          style={{
            padding: "0px 4px 4px 0px",
            resize: "none",
            overflowY: "auto",
          }}
          isBordered={true}
          value={aboutCommunity}
          maxHeight="200px"
          onChange={(e) => setAboutCommunity(e)}
          placeholder="Add description to let the members know more about your community"
        />
      </div>
    </>
  );
};

export default AboutCommunity;

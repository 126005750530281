import { Skeleton } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  CommunityFilterType,
  EntityValueType,
} from "../../../types/DiscoverPage";
import { fetchEngagements } from "../../../utils/DiscoverPage";
import PostCard from "../../Common/PostCard";
import CourseCard from "../../Course/CourseCard";
import EventFeedCard from "../../EventComponents/EventFeedCard";
import { DiscussionCard } from "../../Feed";
import ProposalCard from "../../Proposal/ProposalCard";

type Props = {
  activeEntity: EntityValueType;
  searchQuery: string;
  communityType: CommunityFilterType;
};

const OtherEntities = ({ activeEntity, communityType, searchQuery }: Props) => {
  const { isMobile } = useWindowSize();
  const { data: entities, isLoading } = useQuery(
    ["highest-engaging-entities", activeEntity, searchQuery, communityType],
    {
      queryFn: () => fetchEngagements(activeEntity, searchQuery, communityType),
      onError: (error) => {
        toast.error("Something went wrong");
        console.error(error);
      },
    }
  );
  if (isLoading)
    return <Skeleton active paragraph={{ rows: 5, width: "100%" }} />;
  if (entities?.length === 0)
    return <h3 style={{ color: "#6b7280" }}>No results found.</h3>;
  return (
    <>
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: isMobile ? "" : "repeat(2, minmax(0, 1fr))",
        }}
      >
        {entities?.map((entity, index) => {
          return (
            <>
              {activeEntity === "BOUNTY" ? (
                <PostCard
                  bountyPost={entity}
                  daoId={entity?.daoId}
                  selectedTab="Available"
                  showDao={false}
                  daoName={entity?.daoName}
                  canCreateBounty={false}
                  isAdmin={false}
                  isMarginBottomRemoved
                />
              ) : activeEntity === "PROPOSALS" ? (
                <ProposalCard proposal={entity} height="100%" />
              ) : activeEntity === "POST" ? (
                <div
                  className="discover-page-discussion-card-container"
                  style={{ height: "100%" }}
                >
                  <DiscussionCard
                    channelId={entity.channelId}
                    daoId={entity.daoId}
                    daoName={entity?.daoName}
                    index={index}
                    post={entity}
                    isMetadataHidden
                    height="100%"
                  />
                </div>
              ) : activeEntity === "COURSES" ? (
                <div
                  className="discover-page-discussion-card-container"
                  style={{
                    height: "100%",
                    border: "1px solid #e5e7eb",
                    borderRadius: "12px",
                  }}
                >
                  <CourseCard
                    daoName={entity?.daoName}
                    daoId={entity?.daoId}
                    course={entity}
                    canManageCourse={false}
                    fetchCourseDetailsService={null}
                    redirect={true}
                  />
                </div>
              ) : activeEntity === "EVENTS" ? (
                <EventFeedCard
                  data={entity}
                  reloadEventList={() => window.location.reload()}
                  isCompact
                  isShareButtonHidden
                />
              ) : null}
            </>
          );
        })}
      </div>
    </>
  );
};

export default OtherEntities;

import { Modal, Spin } from "antd";
import "../ComponentStyles/PaymentStatus.scss";
import React from "react";
import OpenLinkIcon from "../InternalComponents/OpenLinkIcon";
import greenCheck from "../../assets/iconSvg/greenCheck.svg";
import pendingPayment from "../../assets/iconSvg/pendingPayment.svg";
import failedPayment from "../../assets/iconSvg/failedPayment.svg";
import defaultWalletIcon from "./../../assets/images/common/wallet.png";
import GnosisIcon from "../../images/genosis.svg";
import metamaskIcon from "../../assets/iconSvg/metamask.png";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

function PaymentStatus({ status, isAdmin, userDetails }) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 16,
        marginLeft: "6px",
        color: "#633112",
      }}
      spin
    />
  );
  const iconMap = {
    PAYMENT_SUCCESSFUL: greenCheck,
    PAYMENT_PENDING: pendingPayment,
    PAYMENT_FAILED: failedPayment,
    error: failedPayment,
  };
  const colorMap = {
    PAYMENT_SUCCESSFUL: "#057A55",
    PAYMENT_PENDING: "#633112",
    PAYMENT_FAILED: "#633112",
    error: "#633112",
  };
  const bgColorMap = {
    PAYMENT_SUCCESSFUL: "#F3FAF7",
    PAYMENT_PENDING: "#FDFDEA",
    PAYMENT_FAILED: "#FFF8F1",
    error: "#FFF8F1",
  };

  const titleMap = {
    PAYMENT_SUCCESSFUL: "Payment successful",
    PAYMENT_PENDING: "Payment pending ",
    PAYMENT_FAILED: "Payment failed ",
    error: "Something went wrong",
  };
  const statusMap = { confirmed: "👍 Approved", pending: "-" };
  const walletIcon = {
    GNOSIS: GnosisIcon,
    METAMASK: metamaskIcon,
  };
  const parseLongString = (str) => {
    return str.slice(0, 20) + "...";
  };
  const truncateWalletAddress = (addr) => {
    if (addr) {
      addr =
        addr.slice(0, 5) + "..." + addr.slice(addr.length - 5, addr.length - 1);
    }
    return addr;
  };
  console.log("status", status);

  return (
    <div className="payment-status-modal">
      <div
        className={`payment-status-modal__top ${status?.status}`}
        style={{ backgroundColor: bgColorMap[status?.status] || "#F3FAF7" }}
      >
        <div className="payment-status-modal__top--status">
          <img src={iconMap[status?.status]} alt="" />
          <span>
            {status?.status == "PAYMENT_PENDING" ? (
              <>
                {status?.transactionData?.confirmationsRequired ? (
                  <>
                    Threshold reached (
                    {status?.transactionData?.result?.reduce((a, b) => {
                      if (b.status == "confirmed") return a + 1;
                      return a + 0;
                    }, 0)}{" "}
                    of {status?.transactionData?.result?.length})
                  </>
                ) : (
                  <>
                    Processing payment <Spin indicator={antIcon} size="large" />
                  </>
                )}
              </>
            ) : (
              <>{titleMap["PAYMENT_SUCCESSFUL"]}</>
            )}
          </span>
        </div>
        <div className="payment-status-modal__top--link">
          {/* <a
            href={status?.url || "https://daolens.com"}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: colorMap[status?.type] || "#057A55" }}
          >
            {status?.urlTitle || "Etherscan"}
          </a>
          <OpenLinkIcon color={"#057A55"} /> */}
        </div>
      </div>
      {status?.transactionData?.result?.length > 0 && (
        <div className="payment-status-modal__middle">
          {status?.transactionData?.result?.map((singleResult) => {
            return (
              <div>
                <span>
                  {singleResult.userName ||
                    truncateWalletAddress(singleResult.address)}{" "}
                  {isAdmin &&
                    userDetails?.walletAddress.toLowerCase() ===
                      singleResult.address.toLowerCase() && <>(You)</>}
                </span>
                <span>{statusMap[singleResult.status]}</span>
              </div>
            );
          })}
        </div>
      )}
      <div className="payment-status-modal__bottom">
        <p className="payment-status-modal__bottom--text">
          <span>Amount :</span>
          <span>{status?.amount || 0.0001}</span>
        </p>
        <p className="payment-status-modal__bottom--text">
          <span>From :</span>
          <span>
            <img
              src={
                walletIcon[status?.walletType]
                  ? walletIcon[status?.walletType]
                  : defaultWalletIcon
              }
              alt=""
            />{" "}
            {truncateWalletAddress(status?.from)}
          </span>
        </p>
        <p className="payment-status-modal__bottom--text">
          <span>To :</span>
          <span>{truncateWalletAddress(status?.to)}</span>
        </p>
        <p className="payment-status-modal__bottom--text">
          <span>Date :</span>
          <span>
            {moment(status?.transactionData?.submissionDate).format(
              "MMM D, HH:MM A"
            )}
          </span>
        </p>
      </div>
    </div>
  );
}

export default PaymentStatus;

import customAxios from "../api";
export type ReactionType = {
  reactionId: string;
  reactionType: string;
  userReacted: boolean;
  userNames: string[];
  count: number;
};

export const deleteComment = async (entity: string, commentId: string) => {
  await customAxios.delete(`${entity}comments/delete`, {
    data: { commentId },
  });
};

export type UpdateCommentOptions = {
  content: string;
  userTagged: any[];
  commentId: string;
  entity: string;
};

export const updateComment = async ({
  commentId,
  content,
  userTagged,
  entity,
}: UpdateCommentOptions) => {
  await customAxios.post(`${entity}comments/edit`, {
    id: commentId,
    description: content,
    userTagged,
  });
};

export const addReaction = async (
  reaction: string,
  commentId: string,
  entity: string
) => {
  await customAxios.post(`${entity}comments/reaction/create`, {
    commentId,
    reaction,
  });
};

export type DeleteReactionOptions = {
  reaction: string;
  commentId: string;
  entity: string;
};

export const deleteReaction = async ({
  commentId,
  entity,
  reaction,
}: DeleteReactionOptions) => {
  await customAxios.post(`${entity}comments/reaction/delete`, {
    commentId,
    reaction,
  });
};

export const getReplyMention = (commentDetails: any) =>
  commentDetails
    ? `${commentDetails.userName}: ${commentDetails.description}`
    : "Comment deleted";

export const trimNewLines = (richText: string) => {
  const lines = richText.split("<p><br></p>");

  const linesWithoutLeadingNewLines: string[] = [];

  let foundContent = false;

  lines.forEach((line) => {
    if (line === "" && !foundContent) return;
    if (line || foundContent) {
      foundContent = true;
      linesWithoutLeadingNewLines.push(line);
    }
  });

  const linesWithoutTrailingAndLeadingNewLines: string[] = [];
  foundContent = false;
  linesWithoutLeadingNewLines.reverse().forEach((line) => {
    if (line === "" && !foundContent) return;
    if (line || foundContent) {
      foundContent = true;
      linesWithoutTrailingAndLeadingNewLines.push(line);
    }
  });

  const trimedRichText = linesWithoutTrailingAndLeadingNewLines
    .reverse()
    .join("<p><br></p>");

  return trimedRichText;
};

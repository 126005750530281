import React, { useContext, useEffect } from "react";
import DiscordIcon from "./../../assets/images/profile/Subtract.svg";
import { getDefaultDaoImage } from "./../../utils";
import daoDefaultBg from "./../../assets/images/daoSideBar/daoDefaultBg.png";
import blockSocLogo from "./../../assets/images/logos/blockSoclogo.png";
import { DaoContext } from "../../layouts/app/DaoContext";
import { Skeleton } from "antd";
import { getTrunckedString } from "../Proposal/ProposalCard";
import { iconStyling } from "../Settings/DaoProfile/DaoProfile";
import { JOIN_DAO_BUTTON_ID_MD } from "../../constants";

const Logo = () => {
  const { loading, daoDetails } = useContext(DaoContext);
  return (
    <div style={{ marginTop: "-60px" }}>
      {loading ? (
        getDefaultDaoImage(
          "",
          {
            alignSelf: "center",
            width: "60px",
            height: "60px",
            cursor: "pointer",

            borderRadius: "50%",
          },
          { fontSize: "20px" }
        )
      ) : (daoDetails as any)?.profilePicture ? (
        <img
          src={
            (daoDetails as any)?.profilePicture
              ? (daoDetails as any)?.profilePicture
              : blockSocLogo
          }
          alt="dao_logo"
          style={{
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            objectFit: "cover",
            border: "1px solid #ffffff",
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
            background: "white",
          }}
          height="60px"
          width="70px"
        />
      ) : (
        getDefaultDaoImage(
          (daoDetails as any)?.daoName,
          {
            alignSelf: "center",
            width: "60px",
            height: "60px",
            cursor: "pointer",

            borderRadius: "50%",
          },
          { fontSize: "20px" }
        )
      )}
    </div>
  );
};

const DaoIntroCard = () => {
  const { daoDetails, loading, isCurrentDaoJoined } = useContext(DaoContext);
  useEffect(() => {
    console.log({ joinedInComponent: isCurrentDaoJoined });
  }, [isCurrentDaoJoined]);

  const handleJoinClick = () => {
    const buttonElement: HTMLButtonElement | null = document.querySelector(
      `#${JOIN_DAO_BUTTON_ID_MD}`
    );

    buttonElement?.click();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "white",
        position: "relative",
        borderRadius: "12px",
      }}
    >
      <div style={{ height: "122px", width: "100%" }}>
        <img
          src={
            (daoDetails as any)?.backgroundImage
              ? (daoDetails as any).backgroundImage
              : daoDefaultBg
          }
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
            borderRadius: "12px 12px 0 0",
          }}
          alt="background"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 12px 16px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Logo />
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <h2 style={{ margin: 0, fontSize: "16px" }}>
              {loading ? <Skeleton active paragraph={{ rows: 1 }} /> : null}
              {!loading
                ? getTrunckedString((daoDetails as any)?.daoName, 15)
                : null}
            </h2>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <a
                href={(daoDetails as any)?.twitterHandle}
                target="_blank"
                rel="noreferrer"
              >
                <i
                  className="fa-brands fa-twitter fa-regular"
                  style={{ ...iconStyling, background: "#629DFF" }}
                ></i>
              </a>

              {(daoDetails as any)?.discordUrl && (
                <a
                  href={(daoDetails as any)?.discordUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={DiscordIcon}
                    alt="discord icon"
                    style={{ ...iconStyling }}
                  />
                </a>
              )}

              {(daoDetails as any)?.github && (
                <a
                  href={(daoDetails as any)?.github}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i
                    className="fa-brands fa-github fa-regular"
                    style={{ ...iconStyling, background: "black" }}
                  ></i>
                </a>
              )}

              <a
                href={(daoDetails as any)?.website}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i
                  className=" fa-globe fa-solid"
                  style={{ ...iconStyling, background: "red" }}
                ></i>
              </a>
            </div>
          </div>
        </div>
        {/* {!loading && !isCurrentDaoJoined && (
          <button
            onClick={handleJoinClick}
            style={{
              cursor: "pointer",
              fontSize: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "42px",
              marginLeft: "4px",
              backgroundColor: "#3F83F8",
              color: "#FFFFFF",
              border: "none",
              padding: "8px 12px",
            }}
          >
            Join
          </button>
        )} */}
      </div>
    </div>
  );
};

export default DaoIntroCard;

import customAxios from "../../api";

export type FetchPostOptions = {
  daoId: string;
  channelId: string;
  offset: number;
  sortBy: string;
  sortOrder: string;
};

export const POST_PAGINATION_LIMIT = 4;

export const fetchPosts = async ({
  daoId,
  channelId,
  sortBy,
  sortOrder,
  offset = 0,
}: FetchPostOptions) => {
  const result = await customAxios.post("/post/fetch", {
    daoId,
    channelId,
    offset,
    sortBy,
    sortOrder,
    limit: POST_PAGINATION_LIMIT,
  });

  const posts = result?.data?.postData.map((post) => {
    let obj = post.vote.find((votedPost) => votedPost.postId === post.postId);
    if (obj !== undefined) {
      return {
        ...post,
        postEngagementStatus: obj.type.toLowerCase(),
      };
    }
    return post;
  });

  const data: { postData: any[]; offset: number } = {
    offset: result?.data?.offset,
    postData: posts,
  };

  return data;
};

import { Col, Radio, Row, Button, Select } from "antd";
import React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/common/chevrondown.svg";
import { Bin } from "../../assets/iconSvg/Bin";
import { getDefaultCondition } from "../Settings/Permissions/CollapsiblePermissionItem";
// import { dropdown } from "./GatingModal";
import { ReactComponent as NFT } from "../../assets/images/gating/nft.svg";
import { ReactComponent as DiscordIcon } from "../../assets/images/bountyPage/discord.svg";
import TokenComponent from "./Token";
import NFTComponent from "./NFT";
import DiscordComponent from "./Discord";
import Bounty from "./Bounty";
import LevelComponent from "./Level";
import WalletAddress from "./WalletAddress";
import MemberComponent from "./Member";
import {
  CircleStackIcon,
  CurrencyDollarIcon,
  StarIcon,
  UsersIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
const { Option } = Select;

const StyledRowWrapper = styled.div`
  .ant-radio-button-wrapper:first-of-type {
    border-radius: 12px 0px 0px 12px;
  }

  .ant-radio-button-wrapper:last-of-type {
    border-radius: 0px 12px 12px 0px;
  }
`;
interface CustomGatingProps {
  conditionDetails: any;
  setConditionDetails: (data: any) => void;
}
const StyledGatingCondition = styled.div`
  position: relative;
  margin: 14px 0px;
  .gating-type {
    width: 10rem !important;
    position: absolute;
    top: -1rem;
    margin-left: 0.5rem;
  }
  .ant-select-selector {
    border-radius: 8px !important;
  }
  .ip-address {
    border-radius: 12px;
  }
`;
const StyledBin = styled.div`
  .bin {
    position: absolute;
    right: 0.6rem;
    top: 0.6rem;
    z-index: 10;
    opacity: 0.5;
  }
  .bin + .bin {
    position: absolute;
    inset: 38% 0% 0% 94%;
    z-index: 10;
    opacity: 0.5;
  }
`;
let dropdown = {
  TOKEN_GATING: {
    label: "Token Gating",
    value: "TOKEN_GATING",
    icon: <CircleStackIcon className="text-green-500 h-4 w-4 stroke-2" />,
    component: (ele, idx, handleConditionDetailChange) => {
      return (
        <TokenComponent
          ele={ele}
          idx={idx}
          handleConditionDetailChange={handleConditionDetailChange}
        />
      );
    },
  },
  NFT_GATING: {
    label: "NFT Gating",
    value: "NFT_GATING",
    icon: (
      <NFT
        style={{ width: "18px" }}
        className=" text-orange-500 h-4 w-4 stroke-2"
      />
    ),
    component: (ele, idx, handleConditionDetailChange) => {
      return (
        <NFTComponent
          ele={ele}
          idx={idx}
          handleConditionDetailChange={handleConditionDetailChange}
        />
      );
    },
  },
  SPECIFIC_ADDRESS: {
    label: "Wallet Address",
    value: "SPECIFIC_ADDRESS",
    icon: <WalletIcon className="text-indigo-500 h-4 w-4 stroke-2" />,
    component: (ele, idx, handleConditionDetailChange) => {
      return (
        <WalletAddress
          ele={ele}
          idx={idx}
          handleConditionDetailChange={handleConditionDetailChange}
        />
      );
    },
  },
  MEMBER_PROFILE: {
    label: "Members",
    value: "MEMBER_PROFILE",
    icon: <UsersIcon className="text-blue-500 h-4 w-4 stroke-2" />,
    component: (ele, idx, handleConditionDetailChange) => {
      return (
        <MemberComponent
          ele={ele}
          idx={idx}
          handleConditionDetailChange={handleConditionDetailChange}
        />
      );
    },
  },
  DISCORD_ROLES: {
    label: "Discord Role",
    value: "DISCORD_ROLES",
    icon: <DiscordIcon style={{ width: "18px" }} />,
    component: (ele, idx, handleConditionDetailChange) => {
      return (
        <DiscordComponent
          ele={ele}
          idx={idx}
          handleConditionDetailChange={handleConditionDetailChange}
        />
      );
    },
  },
  BOUNTY: {
    label: "Bounty",
    value: "BOUNTY",
    icon: <CurrencyDollarIcon className="text-teal-500 h-4 w-4 stroke-2" />,
    component: (ele, idx, handleConditionDetailChange) => {
      return (
        <Bounty
          ele={ele}
          idx={idx}
          handleConditionDetailChange={handleConditionDetailChange}
        />
      );
    },
  },

  LEVEL: {
    label: "Level",
    value: "LEVEL",
    icon: <StarIcon className="text-yellow-500 h-4 w-4 stroke-2" />,
    component: (ele, idx, handleConditionDetailChange) => {
      return (
        <LevelComponent
          ele={ele}
          idx={idx}
          handleConditionDetailChange={handleConditionDetailChange}
        />
      );
    },
  },
};
const CustomGating = ({
  conditionDetails,
  setConditionDetails,
}: CustomGatingProps) => {
  const handleConditionDetailChange = (key, value, idx) => {
    const currList = conditionDetails?.conditions?.slice();
    currList[idx][key] = value;
    setConditionDetails((prev) => ({
      ...prev,
      conditions: currList.slice(),
    }));
  };
  return (
    <Row className="px-1">
      {conditionDetails?.conditions?.map((ele, idx) => {
        return (
          <Col
            xs={24}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {idx ? (
              <StyledRowWrapper>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                  className="radio-btn"
                >
                  <Radio.Group
                    options={[
                      { label: "And", value: "AND" },
                      { value: "OR", label: "Or" },
                    ]}
                    buttonStyle="solid"
                    onChange={(e) => {
                      const currCondition = { ...conditionDetails };
                      currCondition["operator"] = e.target.value;
                      setConditionDetails({ ...currCondition });
                    }}
                    value={conditionDetails.operator}
                    optionType="button"
                    style={{ borderRadius: "0px 12px 12px 0px" }}
                    className="radio-btn"
                  />
                </Row>
              </StyledRowWrapper>
            ) : null}

            <StyledGatingCondition>
              <Row style={{ marginTop: "24px" }}>
                {conditionDetails.conditions.length > 1 ? (
                  <StyledBin
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const currCondition = { ...conditionDetails };
                      currCondition.conditions.splice(idx, 1);
                      setConditionDetails({ ...currCondition });
                    }}
                  >
                    <Bin className="bin" />
                  </StyledBin>
                ) : null}
                <Select
                  className="gating-type"
                  suffixIcon={<ChevronDownIcon />}
                  style={{
                    width: "100%",
                    zIndex: 1,
                    transform: "translateX(4px)",
                  }}
                  value={ele.type}
                  onChange={(e) => {
                    const currList = conditionDetails?.conditions?.slice();
                    currList[idx] = getDefaultCondition(e);

                    setConditionDetails((prev) => ({
                      ...prev,
                      conditions: currList.slice(),
                    }));
                  }}
                >
                  {Object.entries(dropdown).map(([key, { label, icon }]) => (
                    <Option value={key}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {icon} {label}
                      </div>
                    </Option>
                  ))}
                </Select>
              </Row>
              {dropdown[ele.type].component(
                ele,
                idx,
                handleConditionDetailChange
              )}
            </StyledGatingCondition>
          </Col>
        );
      })}

      {conditionDetails?.conditions?.length < 4 ? (
        <Row style={{ margin: "0px auto", marginBottom: "10px" }}>
          <Button
            type="primary"
            style={{
              background: "white",
              border: "none",
              color: "#1890ff",
              margin: "0 auto",
              textShadow: "none",
              boxShadow: "none",
            }}
            icon={
              <i className="fas fa-plus" style={{ marginRight: "0.5rem" }} />
            }
            onClick={() => {
              const currCondition = conditionDetails.conditions.slice();
              currCondition.splice(
                currCondition.length,
                0,
                getDefaultCondition("TOKEN_GATING")
              );
              setConditionDetails((prev) => ({
                ...prev,
                conditions: currCondition,
              }));
            }}
          >
            Add another condition
          </Button>
        </Row>
      ) : null}
    </Row>
  );
};

export default CustomGating;

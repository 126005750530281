import { Col, Input, Radio, Row, Select, Button, Space, Checkbox } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as Close } from "./../../../assets/iconSvg/crossIcon.svg";
import styled from "styled-components";
import { Modal } from "../../../styles/ModalStyles";
import { toast } from "react-toastify";
import customAxios from "../../../api";
import { UserContext } from "../../../layouts/app/UserContext";
import { postMixpanel, updateWalkmeStatus } from "../../../utils";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import useWindowSize from "../../../hooks/useWindowSize";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import useGating from "../../../hooks/useGating";
import Gating from "../../Gating/Gating";
import { confirmOperation } from "../../../utils/common";

const steps = [
  {
    target: ".channel-title",
    disableBeacon: true,
    disableScrolling: true,
    title: "Add Title",
    content:
      "Channelise your discussions in different streams to facilitate a more organised discussion within your community.",
  },
  {
    target: ".condition-button",
    title: "Add Conditions",

    content:
      "Grant permissions to your community members to access the channels. Permissions can be provided in differnt gating mechanism.",
    disableScrolling: true,
  },
  {
    target: ".create-button",
    title: "Create Channel",
    disableScrolling: true,
    disableBeacon: true,

    content:
      "Click on the Create button to launch the channel for your community.",
  },
];

const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px 0px 0px 20px !important;
    border-bottom: 0px;
  }
  .ant-modal-content {
    border-radius: 8px;
    background: #ffffff;

    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
  .ant-modal-footer {
    padding: 0px;
  }
  .ant-select-selector {
    border-radius: 8px !important;
  }
`;

function CreateChannelModal({
  showChannelModal,
  setShowChannelModal,
  setUpdater,
  channelDetails = null,
}) {
  const [state, setState] = useState({});
  const { walkMeStatus, reload } = useContext(UserContext);

  const navigate = useDaoLensNavigate();
  const [startTour, setStartTour] = useState(false);
  const [loading, setLoading] = useState(false);

  const gatingState = useGating("CHANNEL");

  const { isMobile } = useWindowSize();
  useEffect(() => {
    if (channelDetails) {
      setState({ channelName: channelDetails?.daoChannelName });
      gatingState.setGatingState(
        channelDetails?.gatingConditionId,
        channelDetails?.gatingCondition
      );
    }
  }, []);
  const { daoId } = useDaoContext();

  const addChannel = () => {
    if (!state.channelName) {
      toast.error("Please enter a channel name");
      return;
    }
    let finalObj = {
      channelType:
        gatingState.gatingType === "PRESET" && gatingState?.preset?.isDefault
          ? "PUBLIC"
          : "PRIVATE",
      daoId: daoId,
      channelName: state.channelName?.trim(),
    };
    if (channelDetails) {
      finalObj.channelId = channelDetails.daoChannelId;
    }
    gatingState.createResponseObject(finalObj);

    setLoading(true);
    customAxios
      .post(
        finalObj.channelId ? "/daochannel/edit" : "daochannel/create",
        finalObj
      )
      .then((res) => {
        if (res.data.success) {
          setUpdater((prev) => !prev);
          if (walkMeStatus && !walkMeStatus?.channelCreate)
            updateWalkmeStatus({ channelCreate: true }, reload);
          setLoading(false);
          setShowChannelModal(false);
          if (!finalObj.channelId) {
            postMixpanel("track", "created_channel", {
              dao_id: daoId,
              channel_name: state.channelName,
              channel_type: state.channelType.toLowercase(),
              channel_id: res?.data?.channelId,
            });
            navigate(`/app/dao/${daoId}/channel/${res.data.channelId}`);
            toast.success("Channel created successfully.");
          } else {
            postMixpanel("track", "edited_channel", {
              dao_id: daoId,
              channel_name: state.channelName,
              channel_type: state.channelType.toLowercase(),
              channel_id: finalObj.channelId,
            });
            toast.success("Channel updated successfully.");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <StyledModalWrapper>
      {/* {startTour ? (
        <WalkmeTour
          run={startTour}
          steps={steps}
          otherProps={{
            disableScrollParentFix: true,
            disableScrolling: true,
            continuous: true,
            disableBeacon: true,
            // callback: (step) => {
            //   if (
            //     step.step.target === ".create-bounty-button" &&
            //     step.action === "update"
            //   ) {
            //     setTimeout(() => {
            //       setCreateModalVisible(true);
            //     }, 1000);
            //   }
            //   if (step.status === "finished") {
            //     setShowEndingModal(true);
            //     setCreateModalVisible(false);
            //   }
            // },
          }}
        />
      ) : null} */}

      <Modal
        headerPadding={"20px 0px 0px 20px !important"}
        visible={showChannelModal}
        destroyOnClose
        closeIcon={<Close />}
        centered={true}
        onCancel={() =>
          confirmOperation({
            cancelText: "Discard Changes",
            confirmText: "Continue Editing",
            message: " You will lose the progress if you close this pop-up?",
            title: "Confirm",
            onCancel: () => setShowChannelModal(false),
          })
        }
        title={
          <h3
            style={{
              marginBottom: "0px",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            {channelDetails ? "Edit " : "Create "}Channel
          </h3>
        }
        width={isMobile ? "auto" : "600px"}
        bodyStyle={{
          padding: "0px",
          minHeight: "auto",
          overflowY: "scroll",
          maxHeight: "420px",
        }}
        footer={
          <Row
            xs={24}
            style={{
              padding: "10px",
              justifyContent: "flex-end",
              // columnGap: "8px",
            }}
          >
            <Row
              style={{
                justifyContent: "flex-end",
                // columnGap: "8px",
              }}
            >
              <Button
                type="secondary"
                style={{
                  borderRadius: "12px",
                  height: "36px",
                  color: "#64748B",
                  padding: "7px 16px",
                }}
                onClick={() => setShowChannelModal(false)}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                className="create-button"
                disabled={loading || !state.channelName?.trim()}
                style={{
                  borderRadius: "12px",
                  background: "#3B82F6",
                  padding: "7px 16px",
                  height: "36px",
                }}
                onClick={() => {
                  addChannel();
                }}
              >
                {channelDetails ? "Update " : "Create "}
              </Button>
            </Row>
          </Row>
        }
      >
        <div className="px-4 mt-2">
          <Input
            autoFocus={true}
            maxLength="22"
            className="channel-title"
            value={state?.channelName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                channelName: e.target.value,
              }))
            }
            placeholder="Channel name"
            style={{
              borderRadius: "12px",
              minHeight: "32px",
              marginBottom: "16px",
            }}
          />
          <div className="mb-4">
            <Gating state={gatingState} />
          </div>
        </div>
      </Modal>
    </StyledModalWrapper>
  );
}

export default CreateChannelModal;

import { FlagIcon } from "@heroicons/react/24/outline";
import { Button, Card } from "antd";
import React, { useEffect } from "react";
import IconWrapper from "./IconWrapper";
import { PopupCard } from "../styles/PopupCard";
import { Feild } from "../styles/Feild";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
import RedFlagIcon from "../images/red-flag.svg";
import BlueFlagIcon from "../images/blue-flag.svg";
import yellowlagIcon from "../images/yellow-flag.svg";
import GreyFlagIcon from "../images/grey-flag.svg";
import { LabelIcon } from "../styles/LabelIcon";
import { CloseCircleFilled } from "@ant-design/icons";
import useWindowSize from "../hooks/useWindowSize";
import Tooltip from "./Common/Tooltip";

export const flagValues = [
  { value: "URGENT_PRIORITY", icon: RedFlagIcon, title: "Urgent Priority" },
  { value: "HIGH_PRIORITY", icon: BlueFlagIcon, title: "High Priority" },
  { value: "MEDIUM_PRIORITY", icon: yellowlagIcon, title: "Medium Priority" },
  { value: "LOW_PRIORITY", icon: GreyFlagIcon, title: "Low Priority" },
];

const Flag = ({
  isSubtask,
  handleChange,
  defaultValue,
  style,
  disabled = false,
}) => {
  const { isMobile } = useWindowSize();
  const [showCard, setShowCard] = React.useState(false);
  const [selectedFlag, setSelectedFlag] = React.useState(
    defaultValue
      ? flagValues.find((ele) => ele.value === defaultValue).icon
      : ""
  );
  const ref = React.useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setShowCard(false));
  return (
    <Tooltip
      isTooltipClosedOnContentClick
      content={
        <div ref={ref} bodyStyle={{ padding: "10px 8px" }}>
          {flagValues.map((flagValue) => (
            <Feild
              key={flagValue.value}
              onClick={() => {
                setSelectedFlag(flagValue.icon);
                handleChange(flagValue.value);
                setShowCard(false);
              }}
            >
              <img src={flagValue.icon} alt="icon" />{" "}
              <span>{flagValue.title}</span>
            </Feild>
          ))}
        </div>
      }
    >
      <>
        {!showCard && selectedFlag !== "" ? (
          <LabelIcon
            height={isSubtask || isMobile ? "24px" : "32px"}
            onClick={() => {
              if (!disabled) setShowCard(true);
            }}
          >
            <img
              src={selectedFlag}
              alt="icon"
              style={{ width: isMobile ? "12px" : "auto" }}
            />
            {!disabled ? (
              <Button
                className="borderless-button"
                shape="circle"
                size="small"
                icon={<CloseCircleFilled />}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedFlag("");
                  handleChange("");
                }}
              />
            ) : null}
          </LabelIcon>
        ) : (
          <Button
            style={{ 
              background: showCard ? "#eee" : "#fff", 
              ...style,  
              borderRadius: "8px", 
            }}
            className="borderless-button"
            shape="circle"
            onClick={() => setShowCard(true)}
          >
            <IconWrapper width="18px">
              <FlagIcon />
            </IconWrapper>
          </Button>
        )}
      </>
    </Tooltip>
  );
};

export default Flag;

import { Button, Input, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const AddSubTaskButton = styled(Button)`
  font-size: 14px;
  color: grey;
  & .plus {
    font-size: 14px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: #eee;
  margin: 10px 0px;
`;
const HiddenDivider = styled.div`
  height: 1px;

  margin: 10px 0px;
`;

const StyledNoteSubmission = styled.div`
  .add-btn {
    /* padding: 0; */
    padding-left: 20px !important;
  }
`;

const StyledSubtask = styled.div`
  .add-subtask {
    padding-left: 20px !important;
  }
`;

const StyledNotesSubmission = styled.div`
  .ant-btn {
    padding-left: 20px !important;
  }
  .heading {
    margin-left: 20px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #0f172a;
  }
  .divider {
    margin-left: 20px;
    margin-bottom: 8px;
    width: 148px;
    height: 0px;
    left: 421px;
    top: 614px;
    border: 1px dashed #dddddd;
  }
  .input-textarea {
    padding: 0 20px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    &::placeholder {
      color: #64748b;
    }
  }
`;

const AddNoteSubmission = ({
  state,
  setState,
  hideDivider,
  defaultNote,
  setNote,
}) => {
  const [textAreaVisible, setTextAreaVisible] = useState(false);
  return (
    <StyledNoteSubmission>
      {textAreaVisible ? (
        <StyledNotesSubmission>
          <Typography.Text strong className="heading">
            Note For Submission
          </Typography.Text>
          <div className="divider" />
          <Input.TextArea
            className="input-textarea"
            value={defaultNote || state?.submissionNote}
            onChange={(e) => {
              if (setNote) {
                setNote(e.target.value);
              } else
                setState((prev) => ({
                  ...prev,
                  submissionNote: e.target.value,
                }));
            }}
            placeholder="Type your notes here..."
            autoSize={{ minRows: 2 }}
            bordered={false}
          />
        </StyledNotesSubmission>
      ) : (
        <StyledSubtask>
          <AddSubTaskButton
            size="small"
            type="text"
            style={{ textAlign: "left" }}
            onClick={() => setTextAreaVisible(true)}
            className="add-subtask"
          >
            <span className="plus">+</span>&nbsp;ADD NOTE FOR SUBMISSIONS
          </AddSubTaskButton>
        </StyledSubtask>
      )}
      {!hideDivider ? <Divider /> : <HiddenDivider />}
    </StyledNoteSubmission>
  );
};

export default AddNoteSubmission;

import { Button, Col, Row, Skeleton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import customAxios from "../../../api";
import useGating from "../../../hooks/useGating";
import useWindowSize from "../../../hooks/useWindowSize";
import { DaoContext } from "../../../layouts/app/DaoContext";
import { postMixpanel } from "../../../utils";
import CollapsiblePermissionItem from "./CollapsiblePermissionItem";

import { PencilIcon } from "@heroicons/react/24/solid";
import styled from "styled-components";
export const HideScrollDiv = styled.div`
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    ::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
    }

`;
function Permissions() {
  const [permissionData, setPermissionData] = useState(null);
  const [updater, setUpdater] = useState(false);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const { daoDetails, daoId } = useContext(DaoContext);
  const { isMobile } = useWindowSize();
  const [editable, setEditable] = useState(false);
  const permissionTypeList = {
    manageDaoGatingCondition: {
      label: "Who can become an admin",
      description:
        "Any member with this permission would be able to make changes to DAO profile details.",
      key: "manageDaoGatingCondition",
      submitKey: "MANAGE_DAO_DETAILS",
      gatingState: useGating("PERMISSION"),
      presetId: "manageDaoGatingConditionId",
    },
    channelCreationGatingCondition: {
      label: "Manage Channels",
      description:
        "Any member with this permission would be able to create/edit/delete channels in the DAO.",
      key: "channelCreationGatingCondition",
      submitKey: "MANAGE_CHANNELS",
      gatingState: useGating("PERMISSION"),
      presetId: "channelCreationGatingConditionId",
    },
    manageBountiesGatingCondition: {
      label: "Manage Bounties",
      description:
        "Any member with this permission would be able to create, delete and update bounties.",
      key: "manageBountiesGatingCondition",
      submitKey: "MANAGE_BOUNTIES",
      gatingState: useGating("PERMISSION"),
      presetId: "manageBountiesGatingConditionId",
    },
    engagePostGatingCondition: {
      label: "Engage with Posts",
      description:
        "Any member with this permission would be able to comment/vote/react to posts.",
      key: "engagePostGatingCondition",
      submitKey: "ENGAGE_POSTS",
      gatingState: useGating("PERMISSION"),
      presetId: "engagePostGatingConditionId",

    },
    managePostGatingCondition: {
      label: "Manage Posts",
      description:
        "Any member with this permission would be able to create,edit and delete new posts.",
      key: "managePostGatingCondition",
      submitKey: "MANAGE_POSTS",
      gatingState: useGating("PERMISSION"),
      presetId: "managePostGatingConditionId",

    },
    viewUserCondition: {
      label: "View Member Profile",
      description:
        "Any member with this permission would be able to create,edit and delete member profile.",
      key: "viewUserCondition",
      submitKey: "VIEW_USER",
      gatingState: useGating("PERMISSION"),
      presetId: "viewUserConditionId",
    },
  };

  useEffect(() => {
    if (!daoId) {
      return;
    }
    setLoading(true);

    customAxios
      .get(`settings/permissions/fetch?daoId=${daoId}`)
      .then((res) => {
        const data = res.data.permissions[0];
        Object.entries(data).map(([key, val]) => {
          if (val && key in permissionTypeList) {
            const permissiontype = permissionTypeList[key];
            const presetId = data[permissiontype.presetId];
            permissionTypeList[key].gatingState.setGatingState(presetId, val?.gatingConditions[0]);
          }
        });
        setPermissionData(data)
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        toast.error(
          "You don't have access to the workspace. Contact workspace owner to get started."
        );
      });
  }, [updater, daoId]);
  const handleSubmit = () => {
    let permissions = Object.values(permissionTypeList).map(permissionType => {
      let res = {};
      const { gatingType, presetId, conditionDetails, preset } = permissionType.gatingState;
      if (gatingType === "PRESET") {
        if (preset.type === "ADMIN_ONLY") {
          return null;
        }
        res.gatingConditionId = presetId;
        res.gatingConditions =
          [preset?.gatingCondition];
      } else {
        res.gatingConditions =
          [conditionDetails];
        res.gatingConditionId =
          null;
      }
      return { ...res, operator: "OR", type: permissionType.submitKey }

    }).filter(permission => permission);

    customAxios
      .post("/settings/permissions/update", {
        daoId: daoId,
        permissions,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("Changes saved successfully");
          postMixpanel("track", "edited_permissions", {
            dao_id: daoId,
            dao_name: daoDetails.name,
          });
          setEditable(false);
          setUpdater((prev) => !prev);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Col
      xs={24}
      style={{
        padding: "0px",
        marginTop: isMobile ? "0px" : "20px",
        position: "relative",
        borderRadius: "16px",
        overflow: "hidden"
      }}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #F4F2F2",
          borderRadius: "16px",
          padding: "12px 20px",
          background: "white",
          marginBottom: "12px",
          zIndex: 1,
          position: "absolute",
          top: 1,
          width: "100%",
        }}
      >
        <h4 style={{ fontWeight: "600", marginBottom: "0px" }}>
          Permissions
        </h4>

        {!editable ? (
          <Button
            icon={
              <PencilIcon className="w-3 mr-1" />
            }
            onClick={() => setEditable(true)}
            type="text"
            style={{
              color: "#3B82F6",
              backgroundColor: "#3B82F61F",
              display: "inline-flex",
              alignItems: "center",
              padding: "7px 12px",
              borderRadius: "12px",
              cursor: "pointer",
              fontWeight: "600",
            }}
          >
            Edit
          </Button>
        ) : (
          <Col xs={24} md={10} style={{ display: "flex" }}>
            <Button
              style={{
                borderRadius: "12px",

                // padding: "6px 16px",
                display: "flex",
                marginRight: "8px",
                alignItems: "center",
                width: isMobile ? "50%" : "fit-content",
              }}
              onClick={() => {
                setUpdater((prev) => !prev);
                setEditable(false);
              }}
            >
              Discard
            </Button>
            <Button
              onClick={() => {
                handleSubmit(state);
              }}
              type="primary"
              style={{
                borderRadius: "12px",
                background: "#3B82F6",
                padding: "8px 16px",
                display: "flex",
                alignItems: "center",
                width: isMobile ? "50%" : "fit-content",
              }}
            >
              Save changes
            </Button>
          </Col>
        )}
      </Row>
      <HideScrollDiv style={{ height: "100vh", overflowY: "auto" }} className={`${isMobile ? "" : "pt-16"} mt-1`}>

        {loading ? (
          <Row
            style={{
              padding: "20px",
              borderBottom: "1px solid #F1F5F9",
              cursor: "pointer",
            }}
          >
            {" "}
            <Skeleton style={{ minHeight: "40vh" }} active />{" "}
          </Row>
        ) : null}
        {!loading && permissionData ? (
          <Col
            xs={24}
            style={{
              border: "1px solid #F4F2F2",
              borderRadius: "16px",
              background: "white",
              padding: "0px",
              marginTop: isMobile ? "66px" : "0px",
            }}
          >
            {Object.values(permissionTypeList).map((ele, idx) =>
              idx ? (
                <CollapsiblePermissionItem
                  ele={ele}
                  permissionIdx={idx}
                  editable={editable}
                  permissionData={permissionData}
                />
              ) : null
            )}
          </Col>
        ) : null}
        {!loading && permissionData ? (
          <Col
            xs={24}
            style={{
              border: "1px solid #F4F2F2",
              borderRadius: "16px",
              background: "white",
              padding: "0px",
              marginTop: "12px",
              marginBottom: isMobile ? "60px" : "20px",
            }}
          >
            <CollapsiblePermissionItem
              ele={permissionTypeList.manageDaoGatingCondition}
              editable={editable}
              permissionData={permissionData}
            />
          </Col>
        ) : null}


      </HideScrollDiv>


    </Col>
  );
}

export default Permissions;

import { TwitterCircleFilled, TwitterOutlined } from "@ant-design/icons";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { Button, Input, Tooltip } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { COMMUNITY_TYPES } from "../../constants";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { getWebsiteVal } from "../../utils";

const StyledButton = styled.button`
  border: 1px solid #e2e8f0;
  &:hover {
    background: #f9fafb !important;
  }
  ${(props) =>
    props.selected &&
    `
    border:1px solid #3F83F8;
    background: #EFF6FF !important;
    &:hover {
    background:  #EFF6FF !important;
  }
  
    `}
`;
const StyledSocial = styled.div`
  border: 1px solid ${(props) => (props.added ? "#93C5FD" : "#e2e8f0")};
  cursor: pointer;
  width: 48px;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${(props) => (props.added ? "#EFF6FF" : "")};
  &:hover {
    background: ${(props) => (props.isSelected ? "" : "#F3F4F6")};
  }
`;
const CommunityTypeCard = ({ onClick, name, detail, isSelected }) => (
  <StyledButton
    onClick={onClick}
    selected={isSelected}
    style={{
      display: "flex",
      height: "32px",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
      background: "#ffffff",
      borderRadius: "17px",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center",
      padding: "9px 15px",
      font: "normal 500 14px/16px Inter",
      color: "#1F2937",
    }}
  >
    {name}
  </StyledButton>
);

const Details = ({
  communityType,
  setCommunityType,
  twitter,
  setTwitter,
  website,
  setWebsite,
}) => {
  const [selectedSocial, setSelectedSocial] = useState(undefined);
  const [localTwitter, setLocalTwitter] = useState(twitter);
  const [localWebsite, setLocalWebsite] = useState(website);
  const ref = React.useRef();
  useOnClickOutside(ref, () => {
    if (selectedSocial) {
      setSelectedSocial(undefined);
      setLocalWebsite(website);
    }
  });
  return (
    <>
      <div>
        <div
          style={{
            font: "normal 500 14px/20px Inter",
            color: "#1F2937",
            marginBottom: "16px",
          }}
        >
          Choose type of community*
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            rowGap: "12px",
            columnGap: "8px",
          }}
        >
          {COMMUNITY_TYPES.map(({ detail, id, name }) => (
            <CommunityTypeCard
              detail={detail}
              name={name}
              key={id}
              onClick={() => {
                let newCommunityType = [...communityType];
                if (communityType.includes(id))
                  newCommunityType = communityType.filter((ele) => ele !== id);
                else newCommunityType.push(id);
                setCommunityType(newCommunityType.slice());
              }}
              isSelected={communityType?.includes(id)}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          font: "normal 500 14px/20px Inter",
          color: "#1F2937",
        }}
      >
        Add Links
        <div
          style={{
            display: "flex",
            marginTop: "16px",
            gap: "12px",
          }}
        >
          <Tooltip
            title={
              selectedSocial != "website" &&
              !!website && (
                <div
                  style={{
                    color: "#6B7280",
                    font: "normal 400 14px/20px Inter",
                  }}
                >
                  {website}
                </div>
              )
            }
            placement="top"
            color="white"
            overlayInnerStyle={{ borderRadius: "8px" }}
            overlayStyle={{
              display: selectedSocial != "website" && !!website ? "" : "none",
            }}
          >
            <StyledSocial
              added={!!website}
              isSelected={selectedSocial === "website"}
              onClick={() => {
                setSelectedSocial((prev) => (prev ? undefined : "website"));
              }}
            >
              <GlobeAltIcon
                width={24}
                color={!!website ? "#3B82F6" : "#64748B"}
                strokeWidth="2px"
              />
              {selectedSocial === "website" && (
                <div
                  ref={ref}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    background: "white",
                    font: "normal 500 12px/16px Inter",
                    position: "absolute",
                    color: "black",
                    bottom: "120%",
                    left: 0,
                    padding: "12px",
                    borderRadius: "12px",
                    boxShadow:
                      "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Input
                    size="large"
                    placeholder="daolens.com"
                    allowClear
                    autoFocus={true}
                    prefix={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "4px",
                        }}
                      >
                        <span style={{ color: "grey" }}>https:// </span>
                      </div>
                    }
                    value={getWebsiteVal(localWebsite)}
                    onChange={(e) => {
                      setLocalWebsite(getWebsiteVal(e.target.value));
                    }}
                    style={{
                      borderRadius: "8px",
                      width: "240px",
                      marginBottom: "12px",
                      color: "#6B7280",
                      font: "normal 400 14px/20px Inter",
                    }}
                    onPressEnter={() => {
                      setWebsite(localWebsite);
                      setSelectedSocial(undefined);
                    }}
                  />
                  <Button
                    type="primary"
                    style={{ borderRadius: "12px", width: "100%" }}
                    onClick={() => {
                      setWebsite(localWebsite);
                      setSelectedSocial(undefined);
                    }}
                  >
                    {website ? "Save" : " Add Website"}
                  </Button>
                </div>
              )}
            </StyledSocial>
          </Tooltip>
          <Tooltip
            title={
              selectedSocial != "twitter" &&
              !!twitter && (
                <div
                  style={{
                    color: "#6B7280",
                    font: "normal 400 14px/20px Inter",
                  }}
                >
                  {twitter}
                </div>
              )
            }
            placement="top"
            color="white"
            overlayInnerStyle={{ borderRadius: "8px" }}
            overlayStyle={{
              display: selectedSocial != "twitter" && !!twitter ? "" : "none",
            }}
          >
            <StyledSocial
              added={!!twitter}
              isSelected={selectedSocial === "twitter"}
              onClick={() => {
                setSelectedSocial((prev) => (prev ? undefined : "twitter"));
              }}
            >
              <TwitterOutlined
                style={{
                  color: !!twitter ? "#3B82F6" : "#64748B",
                  fontSize: "20px",
                }}
              />
              {selectedSocial === "twitter" && (
                <div
                  ref={ref}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    background: "white",
                    font: "normal 500 12px/16px Inter",
                    position: "absolute",
                    color: "black",
                    bottom: "120%",
                    left: 0,
                    padding: "12px",
                    borderRadius: "12px",
                    boxShadow:
                      "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  <Input
                    onPressEnter={() => {
                      setTwitter(localTwitter);
                      setSelectedSocial(undefined);
                    }}
                    size="large"
                    autoFocus={true}
                    placeholder="daolens"
                    allowClear
                    prefix={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "4px",
                        }}
                      >
                        <span style={{ color: "grey" }}>
                          https://twitter.com/
                        </span>
                      </div>
                    }
                    value={localTwitter}
                    onChange={(e) => {
                      if (e.target.value.split("/").length) {
                        setLocalTwitter(
                          e.target.value.substring(
                            e.target.value.lastIndexOf("/") + 1,
                            e.target.value.length
                          )
                        );
                      } else setLocalTwitter(e.target.value);
                    }}
                    style={{
                      borderRadius: "8px",
                      width: "240px",
                      marginBottom: "12px",
                      color: "#6B7280",
                      font: "normal 400 14px/20px Inter",
                    }}
                  />
                  <Button
                    type="primary"
                    style={{ borderRadius: "12px", width: "100%" }}
                    onClick={() => {
                      setTwitter(localTwitter);
                      setSelectedSocial(undefined);
                    }}
                  >
                    {twitter ? "Save" : " Add Twitter handle"}
                  </Button>
                </div>
              )}
            </StyledSocial>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default Details;

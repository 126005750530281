import React from "react";
import "../ComponentStyles/BountySubmissionsAccordionStyles.scss";
import { Collapse } from "antd";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
const { Panel } = Collapse;
function SinglePanelHeader({ singleSubmission, onClick, style }) {
  let description = singleSubmission.bountySubmissionDescription
    ? singleSubmission.bountySubmissionDescription.length > 40
      ? singleSubmission.bountySubmissionDescription.slice(0, 40) + "..."
      : singleSubmission.bountySubmissionDescription
    : "";
  return (
    <div onClick={onClick} style={{ width: "100%", ...style, margin: "6px 0" }}>
      <div className="bounty-submission-header__wrapper">
        <img
          src={singleSubmission.bountySubmissionUserProfilePicture || dummyUser}
          alt=""
          className="bounty-submission-header__icon"
        />
        <div className="bounty-submission-header__details">
          <p className="bounty-submission-header__details--name">
            {singleSubmission.bountySubmissionUserName}
          </p>
          <p className="bounty-submission-header__details--description">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
function BountySubmissionsAccordion({
  submissions,
  openViewBountySubmissionModal,
}) {
  return (
    // <Collapse
    //   defaultActiveKey={["1"]}
    //   ghost
    //   expandIconPosition="right"
    //   className="bounty-submission-accordion"
    // >
    //   {submissions.map((eachSubmission, index) => (
    //     <Panel
    //       header={<SinglePanelHeader singleSubmission={eachSubmission} />}
    //       key={index + 1}
    //       showArrow={true}
    //     >
    //       <p>some text</p>
    //     </Panel>
    //   ))}
    // </Collapse>
    <>
      {submissions.filter((ele) => ele.isWinner).length ? (
        <div
          className="bounty-submission-container"
          style={{
            marginBottom: "12px",
            border: "1px solid #ddd",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            padding: "0px",
          }}
        >
          <div
            style={{
              background: " #F6F5FF",
              color: "#4A1D96",
              width: "100%",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              padding: "8px 16px",
              fontWeight: "500",
            }}
          >
            Here’s the winner 🥳
          </div>
          {submissions
            .filter((ele) => ele.isWinner)
            .map((eachSubmission, index) => {
              return (
                <SinglePanelHeader
                  style={{ padding: "16px" }}
                  key={Math.random()}
                  singleSubmission={eachSubmission}
                  onClick={() =>
                    openViewBountySubmissionModal(
                      eachSubmission.bountySubmissionId
                    )
                  }
                />
              );
            })}
        </div>
      ) : null}
      {submissions.filter((ele) => !ele.isWinner).length ? (
        <div className="bounty-submission-container">
          {submissions
            .filter((ele) => !ele.isWinner)
            .map((eachSubmission, index) => {
              return (
                <SinglePanelHeader
                  key={Math.random()}
                  singleSubmission={eachSubmission}
                  onClick={() =>
                    openViewBountySubmissionModal(
                      eachSubmission.bountySubmissionId
                    )
                  }
                />
              );
            })}
        </div>
      ) : null}
    </>
  );
}

export default BountySubmissionsAccordion;

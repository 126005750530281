import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import "../ComponentStyles/BountyCommentsSection.scss";
import PostUserComment from "../InternalComponents/PostUserComment ";
import dummyAvatar from "../../assets/images/login/defaultUser.jpeg";
import { getProfileDetails } from "../Profile/Profile";

const PostCommentsSection = ({ setAccordionState, comments }) => {

  const [userProfilePicture, setUserProfilePicture] = useState(dummyAvatar);
  useEffect(() => {
    getProfileDetails().then((data) => {
      if (data.profilePicture) setUserProfilePicture(data.profilePicture);
    });
  }, [])
  return (
    <div className="bounty-comments-section__wrapper">
      {comments.map((singleComment, index) => {
        return (
          <PostUserComment
            key={index}
            canReply
            icon={userProfilePicture}
            commentText={singleComment.description}
            commentDetails={singleComment}
          />
        );
      })}
    </div>
  );
};

PostCommentsSection.propTypes = {
  setAccordionState: PropTypes.func,
  comments: PropTypes.array.isRequired
};

export default PostCommentsSection;

import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/solid";
import { Crisp } from "crisp-sdk-web";
import React, { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";
import { Modal } from "../styles/ModalStyles";
import { copyTextToClipboard } from "../utils";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  error: string;
  crispChatMessage: string;
};

const ErrorModal = ({
  error,
  isOpen,
  setIsOpen,
  crispChatMessage = "Hey, I am facing this issue:",
}: Props) => {
  return (
    <Modal
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      // @ts-expect-error
      height={"auto"}
      footer={null}
    >
      <div className="flex flex-col p-5 gap-4">
        <h3 className="text-black text-base font-semibold m-0">
          Something went wrong
        </h3>
        {error && (
          <div className="relative">
            <p className="bg-gray-100 rounded-2xl m-0 p-3 font-mono text-xs error-message max-h-32 overflow-y-auto">
              {error}
            </p>
            <button
              className="absolute bottom-0 right-0 mb-2 mr-3 bg-gray-100 p-0 m-0 border-none cursor-pointer text-gray-500 hover:text-gray-800"
              onClick={() => {
                toast.success("Copied!");
                copyTextToClipboard(error);
              }}
            >
              <DocumentDuplicateIcon className="w-3 stroke-2" />
            </button>
          </div>
        )}
        <p className="text-xs font-normal m-0">
          We're sorry, but an error has occurred. Please try again later. If
          this issue persists, please contact our support team for further
          assistance.
        </p>
        <button
          className="flex items-center text-blue-700 bg-blue-50 border-none gap-2 self-end px-3 py-2 rounded-xl cursor-pointer hover:bg-blue-100 active:bg-blue-200"
          onClick={() => {
            Crisp.chat.show();
            Crisp.chat.open();
            Crisp.message.sendText(crispChatMessage + "\n" + error);
          }}
        >
          <ChatBubbleLeftEllipsisIcon className="w-4 text-blue-500" /> Contact
          us
        </button>
      </div>
    </Modal>
  );
};

export default ErrorModal;

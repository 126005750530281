import { useWallet } from "@solana/wallet-adapter-react";
import base58 from "bs58";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import customAxios from "../api";

const useSolana = (onSuccess, onSign, onError,connectType) => {
  const { publicKey, connected, wallet, disconnect, signMessage } = useWallet();
  const { pathname } = useLocation();
  console.log(pathname)
  useEffect(() => {
    if (connected) {
      customAxios
        .post(
          `auth/${connectType==="connect" ? "connect/" : ""}phantom`,
          {
            publicAddress: publicKey,
          }
        )
        .then(async (res) => {
          try {
            let data = await signMessage(
              new TextEncoder().encode(
                `I am signing my one-time nonce: ${res.data.message}`
              )
            );
            onSign();
            let response = await customAxios.post(
              `auth/${connectType==="connect" ? "connect/" : ""
              }phantom/login`,
              {
                token: "Bearer " + res.data.token,
                signature: base58.encode(data),
              }
            );
            onSuccess(response.data.access_token, response.data.newUser);
          } catch (error) {
            console.log(connected, error);
            onError();
          } finally {
            disconnect();
          }
        });
    }
  }, [connected]);
  useEffect(() => {
    localStorage.removeItem("walletName");
    disconnect();
  }, []);
  return { wallet, disconnect };
};

export default useSolana;

import { Input, Select, Col, Row } from "antd";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/common/chevrondown.svg";
import { useEffect, useState } from "react";
import customAxios from "../../api";
import { useDaoContext } from "../../layouts/app/DaoContext";

const { Option } = Select;
export let levleBracket = [
  { label: "is greater than and equal to", value: "GREATER_THAN_EQUAL_TO" },
  { label: "is greater than", value: "GREATER_THAN" },
  { label: "is less than and equal to", value: "LESS_THAN" },
  { label: "is less than", value: "LESS_THAN_EQUAL_TO" },
  { label: "is equal to", value: "EQUAL_TO" },
];
const LevelComponent = ({ ele, idx, handleConditionDetailChange }) => {
  const [bountyList, setBountyList] = useState([]);
  const { daoDetails, daoId } = useDaoContext();
  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`bounty/fetch`, { daoId })
      .then(({ data }) => {
        setBountyList(data.res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]);

  return (
    <Row
      gutter={["8"]}
      style={{
        padding: "2rem 12px 15px 12px",
        borderRadius: "12px",
        background: "#eeeeee",
        rowGap: "8px",
      }}
    >
      <Col xs={24} sm={6}>
        <div
          style={{
            border: "1px solid #E2E8F0",
            background: "#FFFFFF",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            padding: "0px 12px",
            gap: "4px",
            height: "100%",
            textAlign: "left",
          }}
        >
          If Level
        </div>
      </Col>
      <Col xs={24} sm={12}>
        <Select
          placeholder="Select condition"
          suffixIcon={<ChevronDownIcon />}
          value={ele?.levelConditionType}
          style={{ width: "100%" }}
          onChange={(e) => {
            handleConditionDetailChange("levelConditionType", e, idx);
          }}
        >
          {levleBracket.map(({ label, value }) => {
            return <Option value={value}>{label}</Option>;
          })}
        </Select>
      </Col>
      <Col xs={24} sm={6}>
        <Input
          type="number"
          value={ele?.level}
          onChange={(e) => {
            if (e.target.value >= 0)
              handleConditionDetailChange("level", e.target.value, idx);
          }}
          style={{
            border: "1px solid #E2E8F0",
            background: "#FFFFFF",
            borderRadius: "8px",
            width: "100%",
          }}
          placeholder="Add level here"
        />
      </Col>
    </Row>
  );
};

export default LevelComponent;

import snapShotIcon from "../../../images/snapshot.png";
import React, { useContext, useEffect, useState } from "react";
import customAxios from "../../../api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import { Button, Modal } from "antd";
import { DaoContext } from "../../../layouts/app/DaoContext";
import "../../ComponentStyles/DiscourseModalStyles.scss";
import SecondaryButtonOutlined from "../../InternalComponents/PostSecondaryButtonOutlined";
import PrimaryButtonFilled from "../../InternalComponents/PrimaryButtonFilled";
import paper_airplane from "./../../../assets/images/settings/paper-airplane.gif";
import { PlayCircleFilled } from "@ant-design/icons";
import discourseIcon from "../../../images/discourse_icon.png";
import { getDefaultDaoImage } from "../../../utils";
import useDisourse from "../../../hooks/useDiscourse";

const DiscourseIntegrationModal = ({
  isOpen,
  setIsOpen,
  reload,
  daoDetails,
  status,
}) => {
  let daoId = daoDetails?.daoId;
  const [userName, setUserName] = useState("");
  const [url, setUrl] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    if (loading) return;
    if (!userName) return toast.error("Enter your username");
    if (!url) return toast.error("Enter your community's URL");
    if (!apiKey) return toast.error("Enter your API key");
    setLoading(true);
    try {
      await customAxios.post(
        "discourse/import",
        { communityUrl: url, daoId, apiKey, apiUserName: userName },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      reload();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={isOpen}
      footer={
        status === "IMPORTING" ? null : (
          <div>
            <SecondaryButtonOutlined
              buttonText={"Cancel"}
              clickHandler={() => setIsOpen(false)}
            />

            <PrimaryButtonFilled
              buttonText={"Import"}
              fillColor="#3F83F8"
              style={{ marginLeft: "10px" }}
              clickHandler={onSubmit}
              loader={loading}
              disabled={loading}
            />
          </div>
        )
      }
      onCancel={() => setIsOpen(false)}
      className="discourse-modal"
      title={status === "IMPORTING" ? null : "Import from Discourse"}
    >
      {/* {status === "IMPORTED" ? <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyItems: "center",
          //   gap: "20px",

        }}
      >
        <div className="discourse-modal__single-input">
          <p>
            Discourse username
          </p>
          <div className="text-sm leading-5 font-normal py-2 px-3 text-gray-800 bg-gray-200 border border-solid">
            pie.discourse.com
          </div>
        </div>
        <div className="discourse-modal__single-input">
          <p>
            Discourse URL

          </p>
          <input
            value={url}
            onChange={(ev) => {
              setUrl(ev.target.value);
            }}
            type="text"
            name="community-name"
            placeholder="Enter your community’s URL"
            id=""
          />
        </div>
        <div className="discourse-modal__single-input">
          <p>
            Discourse API key

          </p>
          <input
            value={apiKey}
            onChange={(ev) => {
              setApiKey(ev.target.value);
            }}
            type="text"
            name="community-name"
            placeholder="Enter the API key form discourse"
            id=""
          />
        </div>
      </div> :} */}
      {status === "IMPORTING" ? (
        <div>
          <img
            src={paper_airplane}
            className="w-full h-36 object-cover px-10"
          />
          <div className="flex justify-between">
            <img
              src={discourseIcon}
              alt="snapshot"
              style={{ width: "80px", aspectRatio: 1 }}
            />
            {daoDetails?.profilePicture ? (
              <img
                src={daoDetails?.profilePicture}
                alt="dao_logo"
                style={{
                  borderRadius: "50%",
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  border: "1px solid #ffffff",
                  boxShadow:
                    "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
                  background: "white",
                }}
                height="60px"
                width="70px"
              />
            ) : (
              getDefaultDaoImage(
                daoDetails?.daoName,
                {
                  alignSelf: "center",
                  width: "80px",
                  height: "80px",
                  cursor: "pointer",

                  borderRadius: "50%",
                },
                { fontSize: "20px" }
              )
            )}
          </div>
          <div className="flex flex-col items-center font-semibold leading-6 text-base mt-6">
            Importing discussions
            <div className="text-center mt-2 text-gray-500 text-sm font-normal">
              This will usually takes a few minutes. You can safely close this
              window while we import your discussions in the background.
            </div>
          </div>

          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            style={{
              border: "1px solid #D1D5DB",
              boxShadow: " 0px 1px 2px rgba(0, 0, 0, 0.05)",
              borderRadius: "12px",
              height: "38px",
              fontFamily: "Inter",
            }}
            className="w-full mt-6 text-sm font-medium"
            type="text"
          >
            {" "}
            Continue in background
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            justifyItems: "center",
            //   gap: "20px",
          }}
        >
          <div className="discourse-modal__single-input">
            <p>
              Discourse username
              <span>*</span>
            </p>
            <input
              value={userName}
              onChange={(ev) => {
                setUserName(ev.target.value);
              }}
              type="text"
              name="community-name"
              placeholder="Enter your username"
              id=""
            />
          </div>
          <div className="discourse-modal__single-input">
            <p>
              Discourse URL
              <span>*</span>
            </p>
            <input
              value={url}
              onChange={(ev) => {
                setUrl(ev.target.value);
              }}
              type="text"
              name="community-name"
              placeholder="Enter your community’s URL"
              id=""
            />
          </div>
          <div className="discourse-modal__single-input">
            <p>
              Discourse API key
              <span>*</span>
            </p>
            <input
              value={apiKey}
              onChange={(ev) => {
                setApiKey(ev.target.value);
              }}
              type="text"
              name="community-name"
              placeholder="Enter the API key form discourse"
              id=""
            />
          </div>
          <a
            href="https://www.loom.com/share/1b039bc987c445cbac245ebee9f04581"
            target="_blank"
            className="text-blue-500 cursor-pointer text-xs font-medium leading-4"
          >
            <PlayCircleFilled /> Know where to find api key
          </a>
        </div>
      )}
    </Modal>
  );
};

export default DiscourseIntegrationModal;

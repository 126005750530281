const Card = ({ style = {}, children }) => {

    return <div
        style={{
            borderRadius: "12px",
            background: "white",
            padding: "20px",
            width: "100%", ...style
        }}>
        {children}</div>
}
export default Card;

export const Title = ({ title, style }) => {
    return <div
        style={{
            height: "24px",
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "24px",
            marginBottom: "4px",
            display: "flex",
            alignItems: "center",
            ...style
        }}>{title}</div>
}
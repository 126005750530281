import React from "react";
import { useState } from "react";
import "./InternalComponentStyles/NotificationPanel.scss";
import {
  BountyNotificationCard,
  PostNotificationCard,
  ProposalNotificationCard,
} from "../InternalComponents/SingleNotificationCards";
import indiGgLogo from "../../assets/iconSvg/indiGgLogo.svg";
import notificationEmptyState from "../../assets/iconSvg/no_notification.svg";
import userIcon from "../../assets/images/login/defaultUser.jpeg";
import { useEffect } from "react";
import customAxios from "../../api";
import { Navigate, useNavigate, useParams } from "react-router";
import useWindowSize from "../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import discourseIcon from "../../images/discourse_icon.png";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
function NotificationPanel({ closePanel }) {
  const navigate = useDaoLensNavigate();
  const [activeTab, setActiveTab] = useState("all");
  const [allNotifications, setAllNotifications] = useState([]);
  const { windowSize } = useWindowSize();
  useEffect(() => {
    customAxios
      .get("/notifications/fetch")
      .then((res) => {
        setAllNotifications(res.data.data);
        // setUnreadNotificationCount(res.data.unReadCount[0].count);
      })
      .catch((err) => console.log("polling err", err));
  }, []);
  const readSingleNotification = (id) => {
    customAxios
      .patch("/notifications/markread", { relationId: id })
      .then((res) => {
        setAllNotifications(res.data.data);
        // setUnreadNotificationCount(res.data.unReadCount[0].count);
      })
      .catch((err) => console.log("read single err", err));
  };
  const readAllNotification = () => {
    customAxios
      .patch("/notifications/markread", {})
      .then((res) => {
        setAllNotifications(res.data.data);
        // setUnreadNotificationCount(res.data.unReadCount[0].count);
      })
      .catch((err) => console.log("read all err", err));
  };

  return (
    <div
      className="notification-panel__wrapper"
      onClick={(e) => {
        e.stopPropagation();
        if (e.target.className === "notification-panel__wrapper") closePanel();
      }}
    >
      <div
        className="notification-panel"
        style={
          windowSize.width < MD_SCREEN_BREAKPOINT
            ? {
                left: 0,
                width: "90%",
                position: "relative",
                overflow: "visible",
              }
            : {
                position: "relative",
                overflow: "visible",
              }
        }
      >
        <span
          style={{
            position: "absolute",
            top: "50%",
            zIndex: "200",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            right: "-14px",
            backgroundColor: "#ffffff",
            borderRadius: "40px",
            height: "24px",
            width: "24px",
            cursor: "pointer",
          }}
          onClick={() => closePanel()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#6B7280"
            className="w-6 h-6"
            style={{ transform: "scale(1.25)" }}
            height="24px"
            width="24px"
          >
            <path
              fillRule="evenodd"
              d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <div className="notification-panel__banner">
          <span>Notifications</span>
          <span onClick={readAllNotification} style={{ cursor: "pointer" }}>
            Mark all as read
          </span>
        </div>
        <div className="notification-panel__list">
          {" "}
          {allNotifications?.length > 0 ? (
            allNotifications.map((singleNotification) => {
              if (
                !singleNotification.daoDetails ||
                !singleNotification.userId
              ) {
                readSingleNotification(
                  singleNotification?.userNotificationRelationId
                );
                return null;
              }
              return (
                <PostNotificationCard
                  icon={indiGgLogo}
                  from={singleNotification?.daoDetails?.name}
                  type={singleNotification?.sourceObjectType}
                  bucket={singleNotification?.bucketCode}
                  status={singleNotification?.content}
                  notificationDetails={singleNotification}
                  time={singleNotification?.createdAt}
                  userIcon={
                    singleNotification?.assignerDetails?.profilePicture ||
                    (singleNotification?.sourceObjectType === "DISCOURSE"
                      ? discourseIcon
                      : userIcon)
                  }
                  isUnread
                  readNotificationOnClick={() => {
                    readSingleNotification(
                      singleNotification?.userNotificationRelationId
                    );
                    closePanel();
                    if (singleNotification?.sourceObjectType === "BOUNTY") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/bounty/${singleNotification?.sourceObjectDetails.id}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/bounty/${singleNotification?.sourceObjectDetails.id}`
                        );
                    }
                    if (singleNotification?.sourceObjectType === "PROPOSALS") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/proposal/${singleNotification?.sourceObjectDetails.id}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/proposal/${singleNotification?.sourceObjectDetails.id}`
                        );
                    }
                    if (singleNotification?.sourceObjectType === "POST") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/channel/${singleNotification?.channelDetails.id}/post/${singleNotification?.sourceObjectDetails.id}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/channel/${singleNotification?.channelDetails.id}/post/${singleNotification?.sourceObjectDetails.id}`
                        );
                    }
                    if (singleNotification?.sourceObjectType === "EVENTS") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/events/${singleNotification?.sourceObjectId}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/events/${singleNotification?.sourceObjectId}`
                        );
                    }
                    if (singleNotification?.sourceObjectType === "TASKS") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/project/${singleNotification?.sourceObjectDetails?.id}/task/${singleNotification?.sourceObjectId}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/project/${singleNotification?.sourceObjectDetails?.id}/task/${singleNotification?.sourceObjectId}`
                        );
                    }
                    if (singleNotification?.sourceObjectType === "PROJECTS") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/project/${singleNotification?.sourceObjectId}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/project/${singleNotification?.sourceObjectId}`
                        );
                    }
                    if (
                      singleNotification?.sourceObjectType === "ANNOUNCEMENT"
                    ) {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/overview`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/overview`
                        );
                    }

                    if (singleNotification?.sourceObjectType === "COURSES") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/course/${singleNotification?.sourceObjectId}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/course/${singleNotification?.sourceObjectId}`
                        );
                    }
                    if (singleNotification?.sourceObjectType === "COLLECTION") {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/library/${singleNotification?.sourceObjectId}`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/library/${singleNotification?.sourceObjectId}`
                        );
                    }
                    if (
                      singleNotification?.content ===
                      "DISCOURSE_MIGRATION_FAILED"
                    ) {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/settings/integration`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/settings/integration`
                        );
                    }
                    if (
                      singleNotification?.content ===
                      "DISCOURSE_MIGRATION_COMPLETE"
                    ) {
                      if (singleNotification?.parentDaoDetails)
                        navigate(
                          `/app/dao/${singleNotification?.parentDaoDetails?.name}/${singleNotification?.parentDaoDetails?.id}/spaces/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/overview`
                        );
                      else
                        navigate(
                          `/app/dao/${singleNotification?.daoDetails.name}/${singleNotification?.daoDetails.id}/overview`
                        );
                    }
                  }}
                />
              );
            })
          ) : (
            <>
              <img src={notificationEmptyState} />
              <span
                style={{
                  marginTop: "20px",
                  fontSize: "20px",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                You are all set! No new notification 🥳
              </span>
            </>
          )}
        </div>

        {/* <PostNotificationCard
          icon={indiGgLogo}
          from="Indi.gg"
          type="Post"
          status="Mentioned"
          notificationText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis,
        cum consequuntur. Iusto, voluptate. Vel perspiciatis, ab dolorum optio
        neque enim."
          time={1667955300908}
          userIcon={userIcon}
        />
        <ProposalNotificationCard
          icon={indiGgLogo}
          from="Indi.gg"
          type="Proposal"
          status="Assigned"
          notificationText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis,
        cum consequuntur. Iusto, voluptate. Vel perspiciatis, ab dolorum optio
        neque enim."
          time={1667955300908}
          userIcon={userIcon}
          isUnread
        />
        <PostNotificationCard
          icon={indiGgLogo}
          from="Indi.gg"
          type="Post"
          status="Comment"
          notificationText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis,
        cum consequuntur. Iusto, voluptate. Vel perspiciatis, ab dolorum optio
        neque enim."
          time={1667955300908}
          userIcon={userIcon}
        />
        <ProposalNotificationCard
          icon={indiGgLogo}
          from="Indi.gg"
          type="Proposal"
          status="New proposal"
          notificationText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis,
        cum consequuntur. Iusto, voluptate. Vel perspiciatis, ab dolorum optio
        neque enim."
          time={1667955300908}
          userIcon={userIcon}
          isUnread
        />
        <BountyNotificationCard
          icon={indiGgLogo}
          from="Indi.gg"
          type="Bounty"
          status="Changed deadline"
          notificationText="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perferendis,
        cum consequuntur. Iusto, voluptate. Vel perspiciatis, ab dolorum optio
        neque enim."
          time={1667955300908}
          userIcon={userIcon}
        /> */}
      </div>
    </div>
  );
}

export default NotificationPanel;

import { Tooltip } from "antd";
import { EmojiClickData } from "emoji-picker-react";
import React, { useContext, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import styled from "styled-components";
import { UserContext } from "../../layouts/app/UserContext";
import {
  addReaction,
  deleteReaction,
  DeleteReactionOptions,
  ReactionType,
} from "../../utils/comments";
import { CommentActionButton } from "./CommentBody";
import EmojiReactionButton from "./EmojiReactionButton";

type ReactionButtonProps = {
  reaction: string;
  userNames: string[];
  react: () => any;
  deleteReaction: () => any;
};

const USERNAME_TOOLTIP_LIMIT_COUNT = 6;

const LIKE_REACTION_TYPE = "👍";

const ReactionPillButton = styled.button`
  &:hover {
    border: ${(props: any) =>
      !props.hasCurrentUserReacted ? "1px solid #9ca3af !important" : ""};
  }
`;

const ReactionButton = ({
  reaction,
  userNames,
  react,
  deleteReaction,
}: ReactionButtonProps) => {
  const { userDetails } = useContext(UserContext);

  const hasCurrentUserReacted = userNames?.includes(
    (userDetails as any)?.userName
  );

  const tooltipString =
    userNames?.length < USERNAME_TOOLTIP_LIMIT_COUNT
      ? userNames?.join(", ")
      : userNames?.slice(0, USERNAME_TOOLTIP_LIMIT_COUNT).join(", ") +
        ` and ${userNames?.length - USERNAME_TOOLTIP_LIMIT_COUNT} others`;

  return (
    <Tooltip
      title={tooltipString}
      overlayInnerStyle={{ borderRadius: "12px" }}
      mouseEnterDelay={0.5}
    >
      <ReactionPillButton
        style={{
          background: hasCurrentUserReacted ? "#EFF6FF" : "none",
          border: hasCurrentUserReacted
            ? "1px solid #3B82F6"
            : "1px solid #E5E7EB",
          borderRadius: "40px",
          padding: "0px 8px",
          fontSize: "12px",
          cursor: "pointer",
        }}
        // @ts-expect-error
        hasCurrentUserReacted={hasCurrentUserReacted}
        onClick={() => (hasCurrentUserReacted ? deleteReaction() : react())}
      >
        {reaction} {userNames?.length}
      </ReactionPillButton>
    </Tooltip>
  );
};

type Props = {
  reactions?: ReactionType[];
  commentId: string;
  entity: string;
  checkIsEnabled?: () => boolean;
};

const Reactions = ({
  reactions = [],
  commentId,
  entity,
  checkIsEnabled,
}: Props) => {
  const { userDetails, showLoginModal } = useContext(UserContext);
  const [reactionMap, setReactionMap] = useState(reactions);

  const currentUserName = (userDetails as any)?.userName;

  const toggleReaction = (reaction: string) => {
    const reactionObj = reactionMap.find(
      (item) => item.reactionType === reaction
    );
    if (!reactionObj) {
      setReactionMap((prev) => [
        ...prev,
        {
          reactionId: "",
          count: 1,
          reactionType: reaction,
          userNames: [currentUserName],
          userReacted: true,
        },
      ]);
      return;
    }
    if (reactionObj.userNames?.includes(currentUserName)) {
      setReactionMap((prev) =>
        prev.map((item) =>
          item.reactionType === reactionObj.reactionType
            ? {
                ...item,
                userNames: item.userNames.filter(
                  (user) => user !== currentUserName
                ),
              }
            : item
        )
      );
    } else {
      setReactionMap((prev) =>
        prev.map((item) =>
          item.reactionType === reactionObj.reactionType
            ? {
                ...item,
                userNames: [...item.userNames, currentUserName],
              }
            : item
        )
      );
    }
  };
  const { mutate: react } = useMutation({
    mutationFn: ({ reaction }: { reaction: string }) =>
      addReaction(reaction, commentId, entity),
    onMutate: ({ reaction }) => {
      toggleReaction(reaction);
    },
    onError: (error, { reaction }) => {
      console.error(error);
      toast.error("Failed to react to this comment");
      toggleReaction(reaction);
    },
  });

  const { mutate: removeReaction } = useMutation({
    mutationFn: (data: DeleteReactionOptions) => deleteReaction(data),
    onError: (error, { reaction }) => {
      console.error(error);
      toast.error("Failed to remove reaction");
      toggleReaction(reaction);
    },
    onMutate: ({ reaction }) => {
      toggleReaction(reaction);
    },
  });

  const onEmojiClick = (emojiData: EmojiClickData) => {
    if (!userDetails) {
      showLoginModal();
      return;
    }
    if (checkIsEnabled && !checkIsEnabled?.()) {
      return;
    }
    if (
      reactionMap
        .find((item) => item.reactionType === emojiData.emoji)
        ?.userNames?.includes(currentUserName)
    ) {
      return;
    }
    react({ reaction: emojiData.emoji });
  };

  return (
    <>
      {!reactionMap?.find(
        (reaction) =>
          reaction.reactionType === LIKE_REACTION_TYPE &&
          reaction.userNames.includes(currentUserName)
      ) && (
        <>
          <CommentActionButton
            onClick={() => {
              if (!userDetails) {
                showLoginModal();
                return;
              }
              if (checkIsEnabled && !checkIsEnabled?.()) {
                return;
              }
              react({ reaction: LIKE_REACTION_TYPE });
            }}
          >
            Like
          </CommentActionButton>
          <div
            style={{
              width: 4,
              height: 4,
              background: "#D1D5DB",
              borderRadius: "100%",
            }}
          />
        </>
      )}
      <EmojiReactionButton onEmojiClick={onEmojiClick} />
      {reactionMap
        .filter((item) => item.userNames?.length !== 0)
        .map((item) => (
          <ReactionButton
            key={item.reactionType}
            reaction={item.reactionType}
            userNames={item.userNames}
            react={() => {
              if (!userDetails) {
                showLoginModal();
                return;
              }
              if (checkIsEnabled && !checkIsEnabled?.()) {
                return;
              }
              react({ reaction: item.reactionType });
            }}
            deleteReaction={() => {
              if (!userDetails) {
                showLoginModal();
                return;
              }
              if (checkIsEnabled && !checkIsEnabled?.()) {
                return;
              }
              removeReaction({
                commentId,
                entity,
                reaction: item.reactionType,
              });
            }}
          />
        ))}
    </>
  );
};

export default Reactions;

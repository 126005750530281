export const ENTITY_KEYS = [
  "discussion",
  "courses",
  "projects",
  "bounty",
  "proposals",
  "events",
  "documents",
] as const;

export type EntityKeyType = typeof ENTITY_KEYS[number];

export type EntityType = {
  name: string;
  description: string;
  icon: string;
  background: string;
  key: EntityKeyType;
  switchDisabled?: boolean;
};

export const ENTITIES: EntityType[] = [
  {
    name: "Discussion Channels",
    description:
      "For topic specific communication amongst your community members",
    icon: "💬",
    background: "#EFF6FF",
    key: "discussion",
    switchDisabled: true,
  },
  {
    name: "Courses",
    background: "#F5F3FF",
    icon: "📚",
    key: "courses",
    description: "Create lessons and quizzes for your community",
  },
  {
    name: "Documents",
    background: "#F5F3FF",

    icon: "📄",
    key: "documents",

    description: "A resource repository including relevant documents",
  },

  {
    name: "Projects",
    description: "A one stop shop for all your project management needs",
    icon: "👨‍💻",
    background: "#FFFBEB",
    key: "projects",
  },
  {
    name: "Bounties",
    description: "Create a bounty board for contributors, evaluate and pay ",
    icon: "💰",
    background: "#F0FDFA",
    key: "bounty",
  },
  {
    name: "Proposal",
    description:
      "Create, discuss and vote on proposals with Snapshot integration",
    icon: "👩🏻‍⚖️",
    background: "#EEF2FF",
    key: "proposals",
  },
  {
    name: "Events",
    description: "Hub for tracking organising all your events",
    icon: "🎪",
    background: "#FFF1F2",
    key: "events",
  },
];

export const COMMENT_NOT_ALLOWED_STATUS =
  "cannot comment more than 3 times consecutively";

import { Input } from "antd";
import Label from "./Label"

const InputField = ({ label, children, placeholder, value, onChange, style, inputProps }) => {
    return <div style={{ borderBottom: "1px solid #F4F2F2", margin: "10px 10px 10px 10px ", ...style }}>
        {label && <Label>{label}</Label>}
        {children ? children : <Input
            style={{ height: "100%" }}
            placeholder={placeholder}
            className="ip-address"
            value={value}
            onChange={onChange}
            bordered={false}
            {...inputProps}
        />}</div>
}

export default InputField;
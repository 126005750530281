import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { ConnectionProvider, useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, LedgerWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, SolletExtensionWalletAdapter, SolletWalletAdapter, TorusWalletAdapter, UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton,
    WalletConnectButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import customAxios from '../../api';
import bs58 from 'bs58';
import { ReactComponent as SolanaIcon } from "../../assets/images/login/solana-sol-logo.svg";


require('@solana/wallet-adapter-react-ui/styles.css');

export const SolanaWalletProvider = (props) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/solana-labs/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            // new UnsafeBurnerWalletAdapter(),

            new PhantomWalletAdapter(),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network })
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    {/* <WalletDisconnectButton /> */}
                    {props.children}
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};


export const SolanaButton = ({ onSuccess, setActiveStep }) => {
    const { publicKey, connect, connected, connecting, wallet, disconnect, signMessage } = useWallet();

    useEffect(() => {
        if (connected) {
            customAxios
                .post("auth/phantom", {
                    publicAddress: publicKey,
                }).then(async (res) => {
                    let data = await signMessage(new TextEncoder().encode(`I am signing my one-time nonce: ${res.data.message}`));
                    setActiveStep(-1);
                    let response = await customAxios
                        .post("auth/phantom/login", {
                            token: "Bearer " + res.data.token,
                            signature: bs58.encode(data),
                        }).catch(() => {
                            setActiveStep(0)
                        });
                    onSuccess(response.data.access_token, response.data.newUser);
                })
        }
    }, [connected])
    useEffect(() => {
        localStorage.removeItem("walletName")
        disconnect()
    }, [])


    return <WalletMultiButton
        style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            background: "white",
            color: "black",
            borderRadius: "12px",
            border: "1px solid #d9d9d9",
            fontWeight: "500",
            fontFamily: "Inter",
            fontSize: "14px",
            lineHeight: "10px",
            height: "40px",
        }}
    >
        {!wallet && <SolanaIcon style={{ width: "20px", marginRight: "8px" }} />} Solana Wallet
    </WalletMultiButton>

}
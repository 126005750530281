import { Input } from "antd";
import React, { Dispatch, SetStateAction } from "react";

type Props = {
  email: string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  username: string;
  setUserName: Dispatch<SetStateAction<string>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  reEnterPassword: string;
  setReEnterPassword: Dispatch<SetStateAction<string>>;
  errors?: Record<string, string>;
};

const CreateAccount = ({
  email,
  name,
  password,
  reEnterPassword,
  setName,
  setPassword,
  setReEnterPassword,
  setUserName,
  username,
  errors,
}: Props) => {
  return (
    <div className="flex flex-col p-6">
      <h3 className="m-0 font-semibold text-xl">Create a Discourse account</h3>
      <p className="m-0 mt-3 text-zinc-500">
        You need to create an account because this community feed syncs 2 ways
        with Discourse
      </p>
      <div className="flex flex-col gap-5 mt-9">
        <div className="flex flex-col gap-2">
          <label className="m-0 text-gray-500">Your name</label>
          <Input
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
            style={{ borderRadius: "12px", padding: "10px 16px" }}
            placeholder="Eg. Vitalik Buterin"
            status={errors?.name ? "error" : ""}
          />
          {errors?.name && (
            <p className="m-0 text-sm text-gray-500">{errors?.name}</p>
          )}
        </div>
        <div className="flex gap-6 w-full">
          <div className="flex flex-col gap-2 flex-1">
            <label className="m-0 text-gray-500">Username</label>
            <Input
              value={username}
              onChange={(e) => setUserName(e.currentTarget.value)}
              style={{ borderRadius: "12px", padding: "10px 16px" }}
              placeholder="Eg. VitalikButerin321"
              status={errors?.username ? "error" : ""}
            />
            {errors?.username && (
              <p className="m-0 text-sm text-gray-500">{errors?.username}</p>
            )}
          </div>
          <div className="flex flex-col gap-2 flex-1">
            <label className="m-0 text-gray-500">Your email </label>
            <Input
              value={email}
              disabled
              style={{ borderRadius: "12px", padding: "10px 16px" }}
              placeholder="Fill in your email address here"
              type="email"
              status={errors?.email ? "error" : ""}
            />
            {errors?.email && (
              <p className="m-0 text-sm text-gray-500">{errors?.email}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label className="m-0 text-gray-500">Password</label>
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            style={{ borderRadius: "12px", padding: "10px 16px" }}
            placeholder="Enter password here"
            status={errors?.password ? "error" : ""}
          />
          {errors?.password && (
            <p className="m-0 text-sm text-gray-500">{errors?.password}</p>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label className="m-0 text-gray-500">Confirm Password</label>
          <Input.Password
            value={reEnterPassword}
            onChange={(e) => setReEnterPassword(e.currentTarget.value)}
            style={{ borderRadius: "12px", padding: "10px 16px" }}
            placeholder="Re-enter password here"
            status={errors?.reEnterPassword ? "error" : ""}
          />
          {errors?.reEnterPassword && (
            <p className="m-0 text-sm text-gray-500">
              {errors?.reEnterPassword}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;

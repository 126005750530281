import React from "react";
import { Navigate, useNavigate } from "react-router";
import styled from "styled-components";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../Common/ProfileCard";
import "./InternalComponentStyles/SmallUserDetails.scss";
const StyledProfileDetails = styled.div`
  display: flex;
  align-items: center;
  color: #64748b;
  &:hover {
    color: #3b82f6 !important;
  }
`;
function SmallUserDetails({
  avatar,
  name,
  url,
  showSeparator,
  postSeparator,
  size,
  userId,
}) {
  const navigate = useDaoLensNavigate();
  return (
    <div className="small-user-details__wrapper">
      <ProfileCardPopup userId={userId}>
        <StyledProfileDetails
          onClick={() => navigate(`/app/profile/${userId}`)}
        >
          <div>
            <img
              src={avatar}
              alt=""
              className="small-user-details__icon"
              style={{ width: size, height: size, objectFit: "cover" }}
            />
          </div>
          <p className="small-user-details__link m-0">{name}</p>
        </StyledProfileDetails>
      </ProfileCardPopup>
      {showSeparator && <span className="small-user-details__separator"></span>}
      {postSeparator}
    </div>
  );
}

export default SmallUserDetails;

import React from "react";
import { useState } from "react";
import CreateBountySubtaskModal from "./CreateBountySubtaskModal";
import { Button, Dropdown, Menu, Space, Typography } from "antd";
import { MoreOutlined, DeleteOutlined, DownOutlined } from "@ant-design/icons";
import MenuItem from "antd/lib/menu/MenuItem";
import styled from "styled-components";

function SubtaskCardView({ order, subtask, updateSubtasks, deleteSubtasks, setUpdatedFieldsNames = () => { } }) {
  const StyledSubtaskCard = styled.div`
    &:hover {
      cursor: pointer;

      box-shadow: 0px 10px 15px -4px rgba(0, 0, 0, 0.06),
        0px 4px 6px -4px rgba(0, 0, 0, 0.03);
      cursor: pointer;
      border-radius: 8px;
      -webkit-transition: 0.2s;
      transition: 0.2s;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 14px;
    padding: 0px 8px;
  `;
  const [showSubtaskModal, setShowSubtaskModal] = useState(false);
  // console.log("subtask here", subtask);

  const menuItems = [
    {
      key: "1",
      label: "Edit Details",
      onClick: (e) => {
        // e.stopPropogation();
        setShowSubtaskModal(true);
      },
    },
    {
      key: "2",
      label: "Delete",
      onClick: (e) => {
        // e.stopPropogation();

        deleteSubtasks(subtask);
      },
    },
  ];
  const menu = (
    <Menu>
      {menuItems.map((ele) => {
        return <MenuItem onClick={() => ele.onClick()}>{ele.label}</MenuItem>;
      })}
    </Menu>
  );

  return (
    <>
      <StyledSubtaskCard
        onClick={() => {
          setShowSubtaskModal(true);
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <img src={StatusIcon} alt="icon" style={{ marginRight: "10px" }} /> */}
            <Typography.Text style={{ wordBreak: "break-word" }}>
              {subtask.title}
            </Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <Flag
              isSubtask
              defaultValue={subtask.priority}
              handleChange={(value) => {}}
            /> */}
            {/* <TaskPoints
              style={{ margin: "0 10px" }}
              isSubtask
              points={subtask.taskPoint}
              setPoints={(points) => {
                updateSubtasks({ title: subtask.title, taskPoint: points });
              }}
            /> */}
            {/* <ReviewerSelector
              reviewer={subtask?.reviewers?.[0]}
              setReviewer={(user) =>
                updateSubtasks({ title: subtask.title, reviewers: [user] })
              }
            /> */}

            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                shape="circle"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                icon={<MoreOutlined />}
                style={{
                  border: "none",
                  padding: "0",
                  background: "none",
                }}
              />
            </Dropdown>
          </div>
        </div>
      </StyledSubtaskCard>
      {showSubtaskModal && (
        <CreateBountySubtaskModal
          order={order}
          onSubmit={() => { }}
          isModalVisible={showSubtaskModal}
          handleCancel={() => setShowSubtaskModal(false)}
          title={subtask.title}
          priority={subtask.priority}
          updateSubtasks={updateSubtasks}
          defaultData={subtask}
          setUpdatedFieldsNames={setUpdatedFieldsNames}
        />
      )}
    </>
  );
}

export default SubtaskCardView;

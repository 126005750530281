import React, { useState, useEffect } from "react";
import BountyFeed from "../../components/BountyFeed";
const Bountypage = () => {
  return (
    <div>
      {" "}
      <div style={{ height: 20 }} />
      <BountyFeed />
    </div>
  );
};

export default Bountypage;

import { Button, Dropdown, Input, Select, Space, Tooltip } from "antd";
import React, { useState } from "react";
import {
  ChevronDownIcon,
  ClockIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import styled from "styled-components";
import IconWrapper from "./IconWrapper";
import PropTypes from "prop-types";
import { HoverCard } from "./Search/SearchModal";
import InputField from "./Common/form/Input";
import { StyledMenu } from "./Course/CourseCard";
import { CheckIcon, LockClosedIcon } from "@heroicons/react/24/solid";

import { postProposalType } from "./PostComponents/CreatePostModal/utils";
import GatingModal from "./Gating/GatingModal";

function getItem({ label, key, icon, children, type, activeDuration }) {
  const isSelected = activeDuration === key;
  return {
    key,
    icon: isSelected ? (
      <CheckIcon width={12} style={{ color: "#3B82F6" }} strokeWidth={2} />
    ) : (
      icon
    ),

    children,
    label,
    type,
    style: {
      borderRadius: "8px",
      margin: "4px 8px",
      display: "flex",
      alignItems: "center",
      background: isSelected ? "#EFF6FF" : "",
    },
  };
}

const Wrapper = styled(Space)`
  display: flex;
  margin: 16px;
  padding: 16px 20px;
  border: 1px solid #dddddd;
  border-radius: 1rem;
  min-height: 82px;
  > div > span {
    padding: 12px;
    height: 44px;
    border: 1px solid #dddddd;
    border-radius: 8px;
  }
  > div > span > span > button {
    border: none;
    padding: 0;
    background: none;
  }
  > div > button {
    padding: 0;
    font-weight: 600;
    font-size: 14px;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #94a3b8;
  & .question-input {
    font-size: 16px;
    padding: 0;
    &::placeholder {
      font-weight: 600;
      color: #94a3b8;
    }
  }
  > button {
    border-radius: 0.6rem;
    color: #64748b;
    font-size: 12px;
  }
`;

const CreatePoll = ({
  onRemovePoll,
  options,
  setOption,
  questionInput,
  setQuestionInput,
  pollDetails,
  setPollDetails,
  DURATION_OBJECTS,
  gatingState,
}) => {
  const handleExpiryDurationClick = (e) => {
    setPollDetails((prev) => ({ ...prev, expireAfter: e.key }));
  };

  const menuItems = DURATION_OBJECTS.map((obj) =>
    getItem({
      label: obj.label,
      activeDuration: pollDetails?.expireAfter,
      key: obj.label,
    })
  );
  return (
    <Wrapper
      direction="vertical"
      style={{
        overflowY: "auto",
        minHeight: options.length ? 135 + 50 * options.length : "182px",
        columnGap: "16px",
        rowGap: "16px",
      }}
    >
      <Header>
        <Input
          className="question-input"
          placeholder="Type your question here"
          value={questionInput}
          autoFocus={true}
          onChange={(e) => setQuestionInput(e.target.value)}
          bordered={false}
        />
        <div style={{ alignItems: "center", display: "flex" }}>
          <StyledSelect
            // disabled={}
            style={{
              minWidth: "200px",
              maxWidth: "200px",
            }}
            placeholder="Voting Type"
            value={pollDetails?.pollType}
            // optionLabelProp={"description"}
            onChange={(choice) => {
              setPollDetails((prev) => ({ ...prev, pollType: choice }));
              if (choice === "basic") {
                setOption(["Yes", "No", "Abstain"]);
              }
            }}
          >
            {postProposalType.map(({ label, value, description }) => (
              <Select.Option value={value}>
                <Tooltip
                  title={description}
                  overlayInnerStyle={{
                    borderRadius: "12px",
                    background: "black",
                    color: "white",
                  }}
                  showArrow={false}
                >
                  <span>{label}</span>
                  <br />

                  <span style={{ fontSize: "10px" }}>{description}</span>
                </Tooltip>
              </Select.Option>
            ))}
          </StyledSelect>
        </div>
      </Header>
      {pollDetails?.pollType ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {options?.map((choice, idx) => (
            <div style={{ display: "flex", gap: "8px" }}>
              <InputField
                style={{
                  border: "1px solid #E2E8F0",
                  borderRadius: "8px",
                  margin: 0,
                  height: "40px",
                  width: "100%",
                }}
                inputProps={{
                  disabled: pollDetails?.pollType === "basic",
                  autoFocus: questionInput.length ? true : false,
                  maxLength: 60,
                  onKeyDown: (e) => {
                    if (e.key === "Enter" && idx === options.length - 1) {
                      setOption((prev) => {
                        return [...prev, ""];
                      });
                    }
                  },
                }}
                value={choice}
                onChange={(e) => {
                  setOption((prev) => {
                    prev.splice(idx, 1, e.target.value);
                    return [...prev];
                  });
                }}
                placeholder={`Option ${idx + 1}`}
              />
              {pollDetails?.pollType !== "basic" ? (
                <HoverCard
                  style={{
                    border: "1px solid #E2E8F0",
                    borderRadius: "8px",
                    height: "40px",
                    width: "40px",
                    flexGrow: 0,
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    font: "Inter",
                    fontWeight: "400",
                    fontSize: "24px",
                    color: "#64748B",
                  }}
                  onClick={() => {
                    setOption((prev) => {
                      prev.splice(idx + 1, 0, "");
                      return [...prev];
                    });
                  }}
                >
                  +
                </HoverCard>
              ) : null}
              {pollDetails?.pollType !== "basic" && options.length > 2 ? (
                <HoverCard
                  style={{
                    border: "1px solid #E2E8F0",
                    borderRadius: "8px",
                    height: "40px",
                    width: "40px",
                    flexGrow: 0,
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    font: "Inter",
                    fontWeight: "400",
                    fontSize: "24px",
                    color: "#64748B",
                  }}
                  onClick={() => {
                    setOption((prev) => {
                      prev.splice(idx, 1);
                      return [...prev];
                    });
                  }}
                >
                  -
                </HoverCard>
              ) : null}
            </div>
          ))}
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          flexFlow: "wrap",
        }}
      >
        <HoverCard
          style={{
            // border: "1px solid #E2E8F0",
            borderRadius: "8px",
            height: "32px",
            background: "#F3F4F6",
            width: "32px",
            flexGrow: 0,
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            font: "Inter",
            fontWeight: "400",
            fontSize: "24px",
            color: "#64748B",
          }}
          onClick={() => {
            onRemovePoll();
          }}
        >
          <IconWrapper>
            <TrashIcon
              style={{ fontSize: "16px" }}
              width="16px"
              strokeWidth="2px"
            />{" "}
          </IconWrapper>
        </HoverCard>

        <Dropdown
          overlay={
            <StyledMenu
              style={{
                borderRadius: "8px",
              }}
              mode="vertical"
              items={menuItems}
              onClick={handleExpiryDurationClick}
            />
          }
          trigger={["click"]}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              background: "#F3F4F6",
              padding: "8px 16px",
              borderRadius: "8px",
              cursor: "pointer",
              color: "#6B7280",
              fontWeight: 500,
            }}
          >
            <ClockIcon width={16} strokeWidth="2px" />
            <p style={{ margin: 0 }} className="text-xs">
              Expire in:{" "}
              <span style={{}}>
                {
                  DURATION_OBJECTS.find(
                    (obj) => obj.label === pollDetails?.expireAfter
                  )?.label
                }
              </span>
            </p>
            <ChevronDownIcon width={12} strokeWidth={2} />
          </div>
        </Dropdown>
        <GatingModal finalGatingState={gatingState} />
      </div>
    </Wrapper>
  );
};

CreatePoll.proptype = {
  onRemovePoll: PropTypes.func.isRequired,
};

export default CreatePoll;

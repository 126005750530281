const TermsOfService = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Terms of Service</h1>
      <p>
        <strong>DaoLens</strong> provides its products and services to you
        subject to these <strong>Terms of Service</strong>.
      </p>
      <p>
        When we use the terms &quot;Company&quot;, &quot;we&quot;,
        &quot;our&quot;, or &quot;us&quot; in this document, we are referring to{" "}
        <strong>DaoLens</strong>. When we use the term &quot;Services&quot; in
        this document, we mean all products and services created, maintained,
        and operated by the Company. When we use the term &quot;Website&quot; in
        this document, we mean the Company’s website located at{" "}
        <a href="https://daolens.com">https://daolens.com</a>. When we use the
        term &quot;Materials&quot; in this document, we mean the applications,
        code, data, images, information, links, sounds, text, videos and other
        content or materials made available by the Services and the Website.
        When we use the terms &quot;you&quot; and &quot;your&quot; in this
        document, we mean you as a user of the Services.
      </p>
      <p>
        These Terms of Service govern your access to and use of (a) the
        Services, (b) the Website, and (c) the Materials.
      </p>
      <p>
        THESE TERMS OF SERVICE FORM A LEGALLY BINDING AGREEMENT, SO PLEASE READ
        THEM CAREFULLY. THESE TERMS OF SERVICE CONTAIN PROVISIONS THAT AFFECT
        YOUR LEGAL RIGHTS, INCLUDING LIMITATIONS OF LIABILITY PROVISIONS IN
        SECTION 13 AND ARBITRATION AND CLASS ACTION WAIVER PROVISIONS IN SECTION
        20.
      </p>
      <p>
        If you breach or violate these Terms of Service, we may terminate your
        access to the Services.
      </p>
      <h2 id="1-acceptance-and-acknowledgment">
        1) Acceptance and Acknowledgment
      </h2>
      <p>
        If you use the Services, you accept and agree to these Terms of Service.
        If you use the Services on behalf of a business or other legal entity,
        you must have the authority to bind that entity to these Terms of
        Service, in which case the terms &quot;you&quot; or &quot;your&quot; are
        referring to that entity. If you do not have the authority necessary to
        bind such entity to these Terms of Service, you shall not accept these
        Terms of Service and shall not use the Services on behalf of such
        entity. If you do not accept or agree with any of these Terms of
        Service, you shall not access or use the Services.
      </p>
      <h2 id="2-eligibility">2) Eligibility</h2>
      <p>
        In order to use the Services, you must be at least 16 years of age, you
        must have permission from your parent or legal guardian if you are
        younger than the age of majority in your jurisdiction, and you must not
        be barred from receiving the Services under applicable law. You must
        only use the Services in compliance with these Terms of Service and all
        applicable federal, state, and local laws and regulations. If you use
        the Services, you represent and warrant to us that these things are
        true.
      </p>
      <h2 id="3-our-property">3) Our Property</h2>
      <p>
        All rights, title, and interest in and to the Services and the Website,
        including the names, look, and feel of the Services and the Website, are
        the exclusive property of the Company or our licensors. You shall not
        acquire any ownership interest in or to the Services by accessing,
        downloading, or using the Services. You shall not alter, edit, hide,
        modify, obscure, or remove any legal and proprietary notices contained
        within the Services.
      </p>
      <h2 id="4-your-property">4) Your Property</h2>
      <p>
        All rights you have in any Materials that you create, distribute, post,
        publish, send, submit, transmit, upload or otherwise provide to the
        Services shall remain yours, unless otherwise specified in these Terms
        of Service.
      </p>
      <h2 id="5-licenses-granted-by-us">5) Licenses Granted by Us</h2>
      <p>
        We hereby grant you a limited, non-assignable, non-exclusive,
        non-sublicensable, non-transferable, personal, revocable, and worldwide
        license to access and use the Services as provided by the Company for
        the sole purpose of enabling you to enjoy the benefit of the Services,
        subject to these Terms of Service and the payment of all applicable
        fees. Unless you have our express written permission, you shall not (i)
        alter, decompile, deobfuscate, modify, reverse assemble, or reverse
        engineer the Services, or (ii) assign, copy, distribute, lease,
        redistribute, reproduce, rent, resell, sell, sublicense, transfer or
        otherwise exploit or make the Services available to any third party.
        Upon any termination of these Terms of Service, the licenses and rights
        granted to you in this section shall be immediately revoked and
        terminated, and you shall have no further right to access and use the
        Services.
      </p>
      <h2 id="6-licenses-granted-by-you">6) Licenses Granted by You</h2>
      <ol>
        <li>
          By creating, distributing, posting, publishing, submitting,
          transmitting, uploading or otherwise providing or using any Materials
          with the Services, you grant the Company an assignable, non-exclusive,
          perpetual, royalty-free, transferable, unrestricted, and worldwide
          license and right to adapt, communicate, copy, display, distribute,
          host, modify (in whole or in part), perform, process, publish,
          reproduce, store, sublicense, translate, transmit, use, and create
          derivative works of such Materials using any means, mediums, or
          methods now known or later developed for the sole purpose of operating
          and providing the Services. In addition, by making any Materials
          available to users of the Services, you grant those users a
          non-exclusive license to access and use those Materials in connection
          with the Services.
        </li>
        <li>
          If you give or make any comments, feedback, ideas, recommendations, or
          suggestions (&quot;Feedback&quot;) to the Company, you hereby assign
          all rights, title, and interest in and to such Feedback to the Company
          without charge. You acknowledge and agree that we shall be free to
          commercialize, exploit, implement, share, and use any Feedback you
          provide to us in any way and for any purpose. We shall not be liable
          to you or any third party for any disclosure, exploitation,
          implementation, or use of any Feedback. You shall not give the Company
          any Feedback that is subject to any license.
        </li>
      </ol>
      <h2 id="7-accounts-and-registration">7) Accounts and Registration</h2>
      <ol>
        <li>
          When you register for an account, you shall provide accurate,
          complete, and current information and you agree to keep that
          information accurate and current.
        </li>
        <li>
          You are responsible for all activity that occurs under your account.
        </li>
        <li>
          You are responsible for maintaining the security of your account and
          password. The Company shall not be liable for any damage or loss from
          your failure to comply with this provision.
        </li>
      </ol>
      <h2 id="8-fees">8) Fees</h2>
      <ol>
        <li>
          Your access to and use of the Services is contingent upon your payment
          of all applicable fees as described within the Services. You shall pay
          all such fees in a timely manner and with a valid payment method in
          United States Dollars. We accept major credit cards, certain debit
          cards, and/or such other payment methods we may make available to you
          from time to time through the Services (&quot;Payment Method&quot;),
          as forms of payment.
        </li>
        <li>
          When we make portions of the Services available to you without charge
          (whether as part of a beta release, free trial, proof-of-concept
          implementation, or otherwise), we may terminate your access to those
          portions of the Services at any time for any or no reason.
        </li>
        <li>
          All fees are exclusive of all taxes, levies, and duties imposed by
          taxing authorities. Where required by law, we will collect those taxes
          and remit them to the appropriate taxing authorities. Otherwise, you
          are solely responsible for the payment of all taxes, levies, and
          duties.
        </li>
        <li>
          If you fail to pay any applicable fees when due, or if we attempt to
          charge your Payment Method and we do not receive payment, we will
          freeze your account and the Services will be inaccessible until you
          make payment. If your account has been frozen for a while, we will
          queue it up for cancellation. You shall pay all amounts due on your
          account immediately upon demand and reimburse the Company for any and
          all chargebacks, claims, fees, fines, penalties, reversals and other
          liability incurred by the Company (including costs and related
          expenses).
        </li>
        <li>
          We reserve the right, but have no obligation, to refund any payments
          you make to the Company, in full or in part, at our sole discretion.
        </li>
        <li>
          We reserve the right to cancel or reject any payments from you or
          prevent you from initiating future payments for any reason or no
          reason.
        </li>
      </ol>
      <h2 id="9-materials-and-content">9) Materials and Content</h2>
      <ol>
        <li>
          All Materials are the sole responsibility of the entity or person that
          makes the Materials available. Any reliance on or use of any Materials
          downloaded or obtained from or through the Services by you is solely
          at your own risk. Some Materials may be disrespectful, harmful,
          offensive or otherwise inappropriate or objectionable and you may be
          exposed to such Materials while using the Services.
        </li>
        <li>
          We do not endorse, guarantee, represent, support, or warrant the
          accuracy, completeness, reliability, truthfulness, or usefulness of
          any Materials, nor the ideas and opinions expressed through any
          Materials. Under no circumstances shall we be liable or responsible
          for any errors or omissions in any Materials, or any damage or loss of
          any kind incurred as a result of the use of any Materials. We reserve
          the right, but have no obligation, to delete or remove any Materials
          that violate these Terms of Service or any applicable laws and
          regulations.
        </li>
        <li>
          You are solely responsible for your use of the Services and any
          Materials that you provide to the Services, including compliance with
          all applicable laws and regulations. For Materials that you provide to
          the Services, you represent and warrant to the Company that:
          <ol>
            <li>
              You own or have the right to provide such Materials, and are able
              and willing to grant the licenses set forth in these Terms of
              Service;
            </li>
            <li>
              We will not need to obtain licenses from or pay royalties to any
              third party in connection with such Materials;
            </li>
            <li>
              Such Materials complies with these Terms of Service and all
              applicable laws; and
            </li>
            <li>
              Such Materials do not infringe upon any third party’s rights,
              including intellectual property rights, privacy rights, and
              publicity rights.
            </li>
          </ol>
        </li>
      </ol>
      <h2 id="10-restrictions-on-use">10) Restrictions on Use</h2>
      <p>You agree that you shall not use the Services to:</p>
      <ol>
        <li>Collect personal information from others without their consent;</li>
        <li>
          Damage, disrupt, impair, overburden or otherwise negatively affect the
          operation of the Services;
        </li>
        <li>Distribute anything that qualifies as malware or spyware;</li>
        <li>Exploit or harm minors;</li>
        <li>Extort or steal from others;</li>
        <li>Harass or threaten others;</li>
        <li>Impersonate others;</li>
        <li>
          Perpetrate, plan, or threaten any activity that qualifies as violence;
        </li>
        <li>
          Send Materials that contain incitements to violence or threats of any
          kind;
        </li>
        <li>Send Materials that are defamatory, discriminatory, or hateful;</li>
        <li>Send Materials that are sexually explicit (e.g. pornography);</li>
        <li>Send spam to others;</li>
        <li>
          Share the private personal information of others for the purposes of
          harassment;
        </li>
        <li>Violate any applicable law, ordinance, or regulation; or</li>
        <li>Violate the rights of others.</li>
      </ol>
      <p>Furthermore, you agree that you shall not:</p>
      <ol>
        <li>Access or use another user’s account or account credentials;</li>
        <li>
          Access or use areas or features of the Services that you are not
          authorized or meant to access;
        </li>
        <li>Access or use the Services by automated means or methods;</li>
        <li>
          Circumvent authentication and security measures of the Services;
        </li>
        <li>
          Compromise the security of the Services or the users of the Services;
          or
        </li>
        <li>Probe, scan, or test the vulnerability of the Services.</li>
      </ol>
      <h2 id="11-subscriptions">11) Subscriptions</h2>
      <ol>
        <li>
          When you purchase a subscription in relation to the Services, you
          authorize the Company to charge your Payment Method immediately and on
          a recurring (e.g. monthly or yearly) basis for the applicable
          subscription charge, any and all taxes, any and all transaction fees,
          and any other charges related to your subscription.
        </li>
        <li>
          Subscriptions shall automatically renew at the end of your applicable
          subscription period, unless you cancel your subscription before the
          end of the current subscription period. If you cancel a subscription
          before the end of your current subscription period, your cancellation
          shall take effect at the end of the current subscription period, and
          you will not be charged again. You will continue to have access to
          features of the Services included with your subscription until the end
          of the current subscription period, unless your access to the Services
          is terminated earlier in accordance with these Terms of Service.
        </li>
        <li>
          We may change the price of any subscriptions from time to time. When
          we do so, we will give at least 30 days notice and will notify you via
          the email address on record. We may also post a notice about price
          changes on our Website or the affected Services themselves. Price
          changes for subscriptions shall take effect at the start of the next
          applicable subscription period following the date of the price change.
        </li>
      </ol>
      <h2 id="12-disclaimers">12) Disclaimers</h2>
      <ol>
        <li>
          You use the Services at your own risk. We provide the Services on an
          &quot;as available&quot;, &quot;as is&quot;, and &quot;with all
          faults&quot; basis and without any promises, representations, or
          warranties whatsoever. We reserve the right at any time to change and
          discontinue (temporarily or permanently) any part of the Services at
          any time with or without notice to you. Additionally, we reserve the
          right to add, change, or remove features and functionality from the
          Services with or without notice to you.
        </li>
        <li>
          To the fullest extent permitted by law, we expressly disclaim any and
          all warranties of any kind, whether express, implied, or statutory,
          including, but not limited to, warranties of accuracy, availability,
          fitness for a particular purpose, functionality, merchantability,
          non-infringement, performance, quality, reliability, title, usefulness
          and any other warranty that might arise under any law. Without
          limiting the foregoing, we make no promises that the Services will be:
          (i) available or permitted in your jurisdiction; (ii) compliant with
          all applicable laws in your jurisdiction; (iii) error-free and
          uninterrupted; and (iv) meet your business or professional needs.
        </li>
        <li>
          To the extent any of the foregoing disclaimers or limitations of
          liability do not apply to you, all applicable implied and statutory
          warranties shall be limited to the fullest extent permitted by law for
          the minimum period required by law and no warranties shall apply after
          such period.
        </li>
      </ol>
      <h2 id="13-limitation-of-liability">13) Limitation of Liability</h2>
      <p>
        To the fullest extent permitted by law, we shall not be liable, in law
        or in equity, to you or to any third party for any direct, indirect,
        consequential, exemplary, incidental, punitive, or special damages,
        including, but not limited to, damages for loss of business, data,
        goodwill, income, opportunity, profit, revenue, sales, use and other
        financial and intangible losses (even if the Company has been advised of
        the possibility of such damages) resulting from (i) the use or inability
        to use the Services; (ii) unauthorized access to or alteration of your
        data or transmissions; (iii) the cost of procurement of substitute goods
        and services resulting from any goods or services purchased or obtained
        or messages received or transactions entered into through or from the
        Services; or (iv) any other matter relating to these Terms of Service or
        the Services, whether as a breach of contract, tort (including
        negligence whether active or passive), or any other theory of liability.
        Our total liability to you for any and all claims, including for any
        implied warranties, shall be limited to and not exceed the amounts paid
        by you to the Company or one hundred dollars (USD $100.00), whichever is
        greater. In all cases, we shall not be liable or responsible to you or
        any third party for any damages or losses that are not reasonably
        foreseeable.
      </p>
      <h2 id="14-indemnification">14) Indemnification</h2>
      <p>
        You agree to indemnify us, our affiliates and subsidiaries, and all of
        our agents, contractors, directors, employees, officers, and
        representatives from and against any and all actions, claims, costs,
        damages, debts, expenses, fees (including, but not limited to,
        attorneys’ fees), fines, judgments, liabilities, losses, obligations,
        and penalties arising out of or in relation to any action, claim,
        counterclaim, proceeding, or suit arising out of or in relation to: (i)
        any Materials you provide to the Services; (ii) your access to,
        activities on, and usage of the Services; (iii) your access to,
        activities on, and usage of third party services; (iv) your breach of
        these Terms of Service, or any agreement or contract you have with a
        third party; (v) your violation of any third party rights, including,
        but not limited to, any intellectual property, privacy, or publicity
        rights; and (vi) your violation of any law, ordinance, or regulation.
      </p>
      <h2 id="15-privacy">15) Privacy</h2>
      <ol>
        <li>
          Our Privacy Policy (located at{" "}
          <a href="https://typicalbot.com/policies/privacy">
            https://typicalbot.com/policies/privacy
          </a>
          ) describes the information we collect from you, to whom we share that
          information with and for what purpose, and what we do with that
          information. When you use the Services, you accept and agree that we
          may process and use your data and information as set forth in the
          Privacy Policy.
        </li>
        <li>
          The humans at the Company may access your data and information for the
          following reasons:
          <ol>
            <li>
              <strong>To help you with your support requests.</strong> We will
              ask you for your consent before accessing the data associated with
              your account.
            </li>
            <li>
              <strong>To safeguard the Services.</strong> We will look at logs
              and metadata as part of our work to ensure the security of the
              Services and your data.
            </li>
            <li>
              <strong>To comply with the law.</strong> We will only preserve or
              share your data if we are compelled to do so by a government
              authority in the United States of America with a legally binding
              order. When we receive such an order, we’ll notify you, unless the
              order prohibits us from doing so. Whenever possible, we will
              refuse to preserve or share any data with any government
              authority.
            </li>
          </ol>
        </li>
      </ol>
      <h2 id="16-third-parties">16) Third Parties</h2>
      <ol>
        <li>
          We use third party vendors to provide the necessary technology
          required to run the Services. Our use of any third party vendor does
          not indicate that we endorse that third party vendor. We shall not be
          liable or responsible for the actions or inactions of any third party
          vendor.
        </li>
        <li>
          The Services may link to third party content and third party websites,
          some of which may use Materials under license from the Company. We do
          not endorse or support these third party websites.
        </li>
        <li>
          You acknowledge that these Terms of Service are between the Company
          and you only.
        </li>
      </ol>
      <h2 id="17-term-and-termination">17) Term and Termination</h2>
      <ol>
        <li>
          These Terms of Service begin on the date of electronic acceptance or
          on the date you first access or use the Services, whichever comes
          first.
        </li>
        <li>
          If you breach these Terms of Service, we may terminate your access to
          the Services immediately, with or without advance notice. If we
          disable, suspend, or terminate your access to the Services, or if we
          deactivate or delete your account, you shall not attempt to access the
          Services or create a new account without our express written consent
          and permission to do so.
        </li>
        <li>
          In the event of any expiration or termination of these Terms of
          Service, the following sections shall survive: Section 3 (Our
          Property), Section 4 (Your Property), Section 9 (Materials and
          Content), Section 12 (Disclaimers), Section 13 (Limitation of
          Liability), Section 14 (Indemnification), Section 15 (Privacy),
          Section 16 (Third Parties), Section 18 (Assignment and Beneficiaries),
          Section 20 (Dispute Resolution), Section 21 (Rights and Remedies), and
          Section 22 (Miscellaneous Provisions).
        </li>
      </ol>
      <h2 id="18-assignment-and-beneficiaries">
        18) Assignment and Beneficiaries
      </h2>
      <p>
        You shall not assign or transfer these Terms of Service, but these Terms
        of Service may be assigned or transferred by the Company. We may also
        permit third parties to exercise our rights or perform our obligations
        under these Terms of Service. These Terms of Service do not create any
        third party beneficiary rights.
      </p>
      <h2 id="19-changes-to-the-terms">19) Changes to the Terms</h2>
      <p>
        We may change these Terms of Service in the future. Whenever we make a
        significant change to these Terms of Service, we will let you know by
        email and post a notice on the Services and the Website. When you use
        the Services, you are agreeing to the latest Terms of Service currently
        in effect.
      </p>
      <h2 id="20-dispute-resolution">20) Dispute Resolution</h2>
      <ol>
        <li>
          <strong>Arbitration or Mediation:</strong> You agree that any and all
          future, past, and present claims or disputes you may have against the
          Company arising out of, in connection with, or relating to these Terms
          of Service, the Services, or your relationship with the Company (a
          &quot;Claim&quot;), shall be resolved by arbitration or mediation. For
          the avoidance of doubt and clarification, this means that any Claim
          you have will not be resolved by a judge or jury in a court of law.
          For the purposes of these Terms of Service, a Claim shall have the
          broadest possible meaning and include claims based in common law,
          contract, fraud, misrepresentation, regulation, statute, tort or any
          other legal theory.
        </li>
        <li>
          <strong>Class or Representative Actions:</strong> You agree that any
          Claim you have against the Company shall only be resolved on an
          individual basis and not on behalf of, or as part of, any purported
          class, consolidated, or representative action or proceeding,
          regardless of how the Claim is resolved.
        </li>
        <li>
          <strong>Equitable Relief:</strong> You hereby irrevocably waive your
          right to seek equitable relief of any kind, including, but not limited
          to, injunctive relief against the Company and their affiliates,
          agents, contractors, directors, employees, officers, and
          representatives.
        </li>
        <li>
          <strong>Trial Waivers:</strong> You hereby irrevocably waive your
          right to a trial. If, for whatever reason, a trial should occur, you
          hereby irrevocably waive your right to a trial by jury.
        </li>
        <li>
          <strong>Notice:</strong> You shall provide the Company with written
          notice of any Claim you have against the Company (a &quot;Claim
          Notice&quot;) prior to initiating any arbitration, mediation, or
          litigation proceeding. A Claim Notice shall describe the basis and
          nature of the Claim, describe the specific relief requested, and
          include the contact information of the party giving notice. A Claim
          Notice shall be sent to the Company by email to{" "}
          <a href="mailto:claims@typicalbot.com">
            <em>claims@typicalbot.com</em>
          </a>{" "}
          and by certified mail to our registered agent at{" "}
          <em>2800 E. Enterprise Ave, Ste 333, Appleton, WI 54913</em>. You
          hereby agree to give the Company a reasonable opportunity to resolve
          the Claim stated in any Claim Notice. If the Company and you do not
          resolve the Claim within ninety (90) days after the Claim Notice is
          received, only then may the Company or you commence an arbitration or
          mediation proceeding.
        </li>
        <li>
          <strong>Rules of Arbitration:</strong> Arbitration shall be conducted
          by one (1) neutral arbitrator in accordance with these Terms of
          Service and the rules of the American Arbitration Association
          (&quot;AAA&quot;). If there is a conflict or inconsistency between
          these Terms of Service and the rules of the AAA, these Terms of
          Service shall govern. The arbitrator shall follow the provisions of
          these Terms of Service as a court would. The arbitrator may award any
          damages and relief on an individual basis that a court would award to
          an individual under these Terms of Service and applicable law. Unless
          the Company and you agree otherwise, any and all arbitration hearings
          shall take place in the State of Minnesota. Any Claim for $5,000.00 or
          less shall be conducted solely on the basis of documents submitted to
          the arbitrator. Any Claim for more than $5,000.00, but less than
          $20,000.00, shall be conducted on the basis of documents submitted to
          the arbitrator or through a telephonic hearing. Any Claim for more
          than $20,000.00 shall be conducted solely by an in-person hearing.
          Regardless of the manner in which the arbitration is conducted, the
          arbitrator must issue a reasoned written decision sufficient to
          explain the essential findings and conclusions on which any award is
          based. Payment of all administration, arbitrator, and filing fees
          shall be governed by the rules of the AAA, except as otherwise
          provided in these Terms of Service.
        </li>
      </ol>
      <p>
        All of the foregoing provisions in this section shall survive any
        termination of these Terms of Service.
      </p>
      <h2 id="21-rights-and-remedies">21) Rights and Remedies</h2>
      <ol>
        <li>
          The rights granted and obligations made under these Terms of Service
          are of a unique and irreplaceable nature, the loss of which may result
          in immediate and irreparable harm to the Company for which remedies at
          law are inadequate. Therefore, we shall be entitled to seek equitable
          relief, including injunctive relief, without the obligation to post
          any bond or surety, in the event of any breach or anticipatory breach
          by you.
        </li>
        <li>
          We reserve all rights not expressly granted to you herein. The rights
          and remedies granted to the Company hereunder are cumulative and not
          exclusive. We reserve the right to object to claims, subpoenas or
          other demands.
        </li>
      </ol>
      <h2 id="22-miscellaneous-provisions">22) Miscellaneous Provisions</h2>
      <ol>
        <li>
          <strong>Electronic Form:</strong> You hereby waive any and all
          defenses you may have based on the electronic form of these Terms of
          Service and the lack of signing by the parties hereto to execute these
          Terms of Service.
        </li>
        <li>
          <strong>Headings:</strong> Headings are for convenience only and shall
          not be used to construe the meaning of any provision in these Terms of
          Service.
        </li>
        <li>
          <strong>Interpretation:</strong> These Terms of Service shall not be
          construed against the Company by virtue of having drafted them.
        </li>
        <li>
          <strong>Notices:</strong> We will provide notices to you by email to
          the email address we have on record for you. You shall provide notices
          to the Company by email to{" "}
          <a href="mailto:legal@typicalbot.com">
            <em>legal@typicalbot.com</em>
          </a>
          . Emailed notices shall be deemed given and received one business day
          after the email is sent. You hereby agree and consent to receive all
          communications from the Company electronically, including agreements,
          disclosures, notices or other information. We may provide any such
          communications by email or by posting them on the Website or the
          Services.
        </li>
        <li>
          <strong>Documentation:</strong> Upon our request, you shall furnish to
          us any documentation, substantiation, or releases necessary to verify
          your compliance with these Terms of Service.
        </li>
        <li>
          <strong>Force Majeure:</strong> We shall not be liable or responsible
          to you for failure to perform any of our obligations under these Terms
          of Service if such failure is as a result of: (i) an act of God,
          earthquake, fire, flood, hurricane, storm or other natural disaster,
          or any failure or interruption of electricity, internet, or telephone
          service; (ii) an act of confiscation, embargo, foreign enemies,
          government sanction, hostilities (regardless of whether war is
          declared), insurrection, invasion, nationalization, protest,
          rebellion, revolution, riot, terrorist activities, or war; or (iii) a
          labor dispute, lockout, or strike.
        </li>
        <li>
          <strong>Governing Law:</strong> The laws of the United States of
          America and the State of Wisconsin shall govern these Terms of Service
          and any claim or dispute of any sort that might arise between us and
          you, without regard to any choice of law, conflicts of law, or similar
          principles and without regard to your location.
        </li>
        <li>
          <strong>Jurisdiction and Venue:</strong> All litigation or other legal
          proceedings resulting from these Terms of Service or the Services
          shall be brought and settled in a federal or state court in the State
          of Wisconsin, and you hereby irrevocably agree and consent to the
          jurisdiction of and venue in those courts and waive any objection as
          to inconvenient forum and personal jurisdiction.
        </li>
        <li>
          <strong>Severability:</strong> If any provision of these Terms of
          Service is found or held to be illegal, invalid, unenforceable, or
          void, the arbitrator or court shall endeavor to give effect to the
          parties’ intentions as reflected in the provision, and all other
          provisions of these Terms of Service shall remain in full force and
          effect.
        </li>
        <li>
          <strong>Survival:</strong> The terms and provisions of these Terms of
          Service that by their nature should survive termination shall survive
          any termination of these Terms of Service.
        </li>
        <li>
          <strong>Waivers:</strong> No failure or delay by the Company in
          exercising any right shall waive any further exercise of that right.
          In addition, no waiver of any breach or default of these Terms of
          Service shall waive any preceding or subsequent breach or default.
        </li>
        <li>
          If you have any questions about these Terms of Service, you may
          contact the Company via email to{" "}
          <a href="mailto:legal@typicalbot.com">
            <em>legal@typicalbot.com</em>
          </a>
          .
        </li>
      </ol>
      <h2 id="23-entire-agreement">23) Entire Agreement</h2>
      <p>
        These Terms of Service constitute the entire agreement and understanding
        between the Company and you concerning your use of the Services and any
        other subject matter hereof. These Terms of Service supersede all prior
        agreements, communications, proposals, representations, and
        understandings with respect to the Services and all other subject matter
        hereof, whether oral or written. You may also be subject to additional
        terms and conditions that may apply when you use the services of a third
        party.
      </p>
    </div>
  );
};

export default TermsOfService;

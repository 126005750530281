import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import customAxios from "../../../api";
import DeleteModal from "../../Common/DeleteModal";
import { Crisp } from "crisp-sdk-web";
import useWindowSize from "../../../hooks/useWindowSize";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";

function DaoManage() {
  const { daoDetails, daoId } = useDaoContext();
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [daoName, setDaoName] = useState(null);
  const { isMobile } = useWindowSize();
  const navigate = useDaoLensNavigate();
  const fetchDaoDetails = (daoId) => {
    customAxios
      .get(`/dao/fetch?daoId=${daoId}`)
      .then((res) => {
        setDaoName(res.data.daoDetails[0].daoName.toLowerCase());
      })
      .catch((err) => {
        console.log("error");
      });
  };
  useEffect(() => fetchDaoDetails(daoId), [daoId]);
  return (
    <Col xs={24} style={{ padding: "0px", marginTop: "20px" }}>
      {isModalVisible ? (
        <DeleteModal
          buttonLabel={"Delete Community"}
          checkSubmitButtonDisabled={(data) => {
            return data?.["communityName"].toLowerCase() === daoName
              ? false
              : true;
          }}
          title="Delete Community"
          note="All the community data on the platform will be lost forever"
          isModalVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          inputList={[
            {
              key: "communityName",
              label: (
                <>
                  Enter the community name to delete{" "}
                  <span style={{ marginLeft: "4px", fontWeight: 500 }}>
                    ({daoName})
                  </span>
                </>
              ),
              otherProps: {
                placeholder: "Type in the community name to delete",
              },
            },
          ]}
          onSubmit={(data) => {
            customAxios
              .delete(`/dao/delete?daoId=${daoId}`)
              .then((res) => {
                if (res.data.success) {
                  navigate("/app/discovery  ");
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                  toast.success("Community deleted succesfully");
                }
              })
              .catch((err) => toast.error("Some error occured"));
          }}
        />
      ) : null}
      {isMobile ? null : (
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #F4F2F2",
            borderRadius: "16px",
            padding: "12px 20px",
            background: "white",
            marginBottom: "12px",
            minHeight: "58px",
          }}
        >
          <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>Manage</h4>
        </Row>
      )}

      <Row
        xs={24}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "white",
          borderRadius: "12px",
          padding: "20px",
        }}
      >
        <Row
          style={{
            flexDirection: "column",
            rowGap: "8px",
          }}
        >
          <h3 style={{ marginBottom: "0px" }}>Delete account</h3>
          <h4 style={{ color: "rgba(100, 116, 139, 1)", marginBottom: "0px" }}>
            We are sorry to see you go 😕
            <br />
            If you have any issues managing your community with our tool, we are
            more than happy to resolve them. Connect with us{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "rgba(63, 131, 248, 1)",
                cursor: "pointer",
              }}
              onClick={() => {
                Crisp.chat.open();
              }}
            >
              here
            </span>
          </h4>
          <h5 style={{ marginBottom: "0px" }}>If you still wish to proceed</h5>
          <Button
            style={{
              background: "rgba(240, 82, 82, 1)",
              borderRadius: "12px",
              width: "fit-content",
              color: "white",
            }}
            onClick={() => setIsModalVisible(true)}
          >
            Delete Community
          </Button>
        </Row>
      </Row>
    </Col>
  );
}

export default DaoManage;

import React, { useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthLayout from "./layouts/auth";
import AppLayout from "./layouts/app";
import useWindowSize from "./hooks/useWindowSize";
import {
  checkAccessTokenExpiry,
  checkIsLoggedInUserValid,
  postMixpanel,
} from "./utils";
import { WalletConnectProvider } from "./layouts/app/WalletConnectContext";
import mixpanel from "mixpanel-browser";
import { Crisp } from "crisp-sdk-web";
import UserContextProvider from "./layouts/app/UserContext";
import { useTrackPageView } from "./hooks/useTrackPageView";
import { QueryClient, QueryClientProvider } from "react-query";
import { SolanaWalletProvider } from "./layouts/app/SolanaConnectContext";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SecurityPolicy from "./pages/SecurityPolicy";
import TermsOfService from "./pages/TermsOfService";
import { hotjar } from "react-hotjar";
import { UNAUTH_JOIN_COMMUNITY_DETAILS } from "./constants";
import useDaoLensNavigate from "./hooks/useDaoLensNavigate";
import { NearWalletSelectorContextProvider } from "./layouts/app/NearWalletSelectorContext";
import NotFound from "./components/404";
import RootLayout from "./layouts/app/Lens";
const queryClient = new QueryClient();

export const PrivateRoute = ({ children }) => {
  const location = useLocation();
  if (!checkAccessTokenExpiry()) {
    return <UserContextProvider>{children}</UserContextProvider>;
  } else {
    localStorage.removeItem("access_token");
    localStorage.removeItem(UNAUTH_JOIN_COMMUNITY_DETAILS);
    localStorage.removeItem("new_user");
    mixpanel?.reset();
    return (
      <Navigate
        to="/auth/login"
        state={{ from: location.pathname + location.search }}
      />
    );
  }
};
export const PublicRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useDaoLensNavigate();
  useEffect(() => {
    if (checkIsLoggedInUserValid()) {
      navigate("/app");
    } else {
      localStorage.removeItem("access_token");
      localStorage.removeItem("new_user");
      mixpanel?.reset();
    }
  }, [location.pathname]);
  return children;
};
const App = () => {
  const { isMobile } = useWindowSize();
  useTrackPageView();

  useEffect(() => {
    Crisp.configure("ee0dc320-d996-4b1e-845a-a7306c1ff7b8", { safeMode: true });
    Crisp.chat.onChatInitiated(() => {
      postMixpanel("track", "chatbot_inititated");
    });
  }, []);

  useEffect(() => {
    if (isMobile) {
      Crisp.chat.hide();

      Crisp.chat.onChatClose(() => {
        Crisp.chat.hide();
      });
    } else {
      Crisp.chat.show();
      Crisp.chat.onChatClose(() => null);
    }
  }, [isMobile]);

  mixpanel.init("23c7b8602a12d021de186d5206a89122", {
    ignore_dnt: true,

    opt_out_tracking_by_default: window.location.host.includes("localhost"),
  });
  const isGeneralContribution = window.location.host === "manager.daolens.com";
  const isDaolensContribution = window.location.host === "uniflux.daolens.com";
  let id;
  if (isGeneralContribution) {
    id = 3286115;
  } else if (isDaolensContribution) {
    id = 3286154;
  }
  hotjar.initialize(id, 6);

  return (
    <QueryClientProvider client={queryClient}>
      <RootLayout>
        <NearWalletSelectorContextProvider>
          <WalletConnectProvider>
            <SolanaWalletProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/security" element={<SecurityPolicy />} />
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route path="/404" element={<NotFound />} />
                  <Route
                    path="/auth/*"
                    element={
                      <PublicRoute>
                        <AuthLayout />
                      </PublicRoute>
                    }
                  />
                  <Route
                    path="/app/*"
                    element={
                      <PrivateRoute>
                        <AppLayout />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <PrivateRoute>
                        <Navigate to="/app" />
                      </PrivateRoute>
                    }
                  />
                </Routes>
                <ToastContainer
                  position={isMobile ? "top-center" : "bottom-center"}
                  autoClose={3000}
                  hideProgressBar={true}
                  newestOnTop={true}
                  closeOnClick
                  limit={2}
                  rtl={false}
                />
              </BrowserRouter>
            </SolanaWalletProvider>
          </WalletConnectProvider>
        </NearWalletSelectorContextProvider>
      </RootLayout>
    </QueryClientProvider>
  );
};

export default App;

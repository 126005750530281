import { Button, Modal, Row } from "antd";
import React from "react";
import { Navigate, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { COMMUNITY_TYPES, MD_SCREEN_BREAKPOINT } from "../../constants";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import useWindowSize from "../../hooks/useWindowSize";
import { getDefaultDaoImage } from "../../utils";
import daoDefaultBg from "./../../assets/images/daoSideBar/daoDefaultBg.png";
import blockSocLogo from "./../../assets/images/logos/blockSoclogo.png";
const StyledModal = styled(Modal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-body {
    padding: 0 !important;
  }
  & .ant-modal-close-x {
    color: #f3f3f3;
  }
`;
const StyledCapsule = styled.div`
  color: #64748b;
  width: fit-content;
  padding: 4px 8px;
  font-size: 14px;
  background: #f7f7f7;
  border-radius: 6.62787px;
`;
function CommunityExistsModal({ setCurrentStep, daoDetails, setDaoDetails }) {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const navigate = useDaoLensNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const onDiscard = () => {
    setCurrentStep(-1);
    setDaoDetails(null);
    const previousRoute = atob(searchParams.get("state"));
    if (previousRoute !== "undefined") {
      navigate(previousRoute);
    } else navigate("/app/discovery");
  };
  return (
    <StyledModal
      centered
      destroyOnClose
      title={null}
      visible
      onCancel={() => onDiscard()}
      footer={null}
      width="460px"
      maskStyle={{
        background: "rgba(0, 0, 0, 0.5",
        backdropFilter: "blur(15px)",
      }}
      height="345px"
    >
      <div style={{ position: "relative" }}>
        <img
          src={
            daoDetails?.backgroundImage
              ? daoDetails.backgroundImage
              : daoDefaultBg
          }
          alt="logo"
          style={{
            borderTopRightRadius: "12px",
            borderTopLeftRadius: "12px",
            objectFit: "cover",
            width: "100%",
            height: "120px",
          }}
        />
        <div style={{ position: "absolute", top: "72%", left: "41%" }}>
          {daoDetails?.profilePicture ? (
            <img
              src={
                daoDetails?.profilePicture
                  ? daoDetails?.profilePicture
                  : blockSocLogo
              }
              alt="dao_logo"
              style={{
                borderRadius: "100%",
                width: "88px",
                height: "88px",
                objectFit: "cover",
                border: "2px solid #ffffff",
                boxShadow:
                  "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
              }}
            />
          ) : (
            getDefaultDaoImage(
              daoDetails?.daoName,
              {
                alignSelf: "center",
                width: "88px",
                height: "88px",
                cursor: "pointer",

                borderRadius: "50%",
              },
              { fontSize: "28px" }
            )
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "64px",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#1A202E",
            lineHeight: "28px",
          }}
        >
          {daoDetails?.name}
        </div>
        <Row
          style={{
            columnGap: "8px",
            margin: "12px 0px 8px 0px",
          }}
        >
          <StyledCapsule>👥 : {daoDetails.contributors}</StyledCapsule>
          <StyledCapsule>💰 : {daoDetails.bounty}</StyledCapsule>
          {daoDetails.communityType ? (
            <StyledCapsule>
              {
                COMMUNITY_TYPES.find(
                  (type) => daoDetails.communityType === type.id
                )?.name
              }
            </StyledCapsule>
          ) : null}
        </Row>
        <h5
          style={{
            background: "#EDEBFE",
            color: "#5521B5",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            fontWeight: "400",
            height: "32px",
            alignItems: "center",
            margin: "8px 0px",
          }}
        >
          The community you are trying to create already exists!
        </h5>

        <Button
          type="primary"
          style={{
            marginBottom: "24px",
            width: isMobile ? "90%" : "410px",
            height: "40px",
            borderRadius: "12px",
            fontWeight: "500",
          }}
          onClick={() => {
            setCurrentStep(-1);
            navigate(`/app/dao/${daoDetails.id}/overview`);
          }}
        >
          View Community
        </Button>
      </div>
    </StyledModal>
  );
}

export default CommunityExistsModal;

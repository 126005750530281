import { VerifiedOutlined } from "@ant-design/icons";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { Button, Modal, Row } from "antd";
import React, { useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import styled from "styled-components";
import scheduledGif from "./../../assets/gifs/seeYousoon.gif";
import IconWrapper from "../IconWrapper";
import { useContext } from "react";
import { UserContext } from "../../layouts/app/UserContext";
const StyledModal = styled(Modal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
    border-bottom: none;
  }
  & .ant-modal-body {
  }
  & .ant-modal-close-x {
  }
  h3,
  h4 {
    margin-bottom: 0px;
  }
`;
const StyledInlineWidget = styled(InlineWidget)`
  ._cUP1np9gMvFQrcPftuf {
    .OGcBAyJGBej5Gnyi9hGA {
      .xahN8AEzyAvQtVj17TPv {
        margin: 0px;
      }
    }
  }
`;
function ClaimCommunity({ modalOpen, onClose }) {
  const [scheduled, setScheduled] = useState(false);
  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setScheduled(true);
    },
  });
  const { userDetails } = useContext(UserContext);
  return (
    <StyledModal
      destroyOnClose
      width={"700px"}
      visible={modalOpen}
      centered={true}
      onCancel={onClose}
      footer={null}
      title={
        <h2 style={{ fontSize: "20px", marginBottom: "0px" }}>
          Schedule a call to claim the account{" "}
          <IconWrapper style={{ marginRight: "4px" }}>
            <CheckBadgeIcon style={{ color: "#3F83F8", width: "20px" }} />
          </IconWrapper>
        </h2>
      }
      bodyStyle={{ padding: "12px 20px" }}
    >
      {!scheduled ? (
        <>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              padding: "18px 20px",
              background: "#F6F5FF",

              borderRadius: "12px",
            }}
          >
            <h3 style={{ color: "#3F83F8" }}>
              What happens on verification call?
            </h3>
            <h4>
              ✅ Verification process to prove you are the admin of this
              community.
            </h4>
            <h4>
              🚀 Product walkthrough as what you would be able to do once you
              hold Admin access.
            </h4>
            <h4>
              ✨ Assistance in structuring your workspace which suits best for
              your community.
            </h4>
          </div> */}
          <StyledInlineWidget
            url="https://calendly.com/meetwithnayan/dao-manager-private-access"
            prefill={{
              name: userDetails?.profileName,
              email: userDetails?.emailId,
              guests: ["nayan@daolens.com"],
            }}
            styles={{ height: "500px", width: "100%" }}
            pageSettings={{
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
            }}
          />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
          }}
        >
          <img
            src={scheduledGif}
            alt="gif"
            style={{ height: "400px", borderRadius: "16px" }}
          ></img>
          <h4
            style={{
              padding: "8px",
              background: "#DEF7EC",
              width: "100%",
              borderRadius: "12px",
              color: "#014737",
            }}
          >
            ✅ Your call has been scheduled
          </h4>
          <Row style={{ justifyContent: "flex-end" }}>
            <Button
              type="primary"
              onClick={onClose}
              style={{ borderRadius: "8px", marginTop: "4px" }}
            >
              See you soon
            </Button>
          </Row>
        </div>
      )}
    </StyledModal>
  );
}

export default ClaimCommunity;

import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as DragIcon } from "./../../../images/dragIcon.svg";
import CreateCourseContentModal, { tabs } from "../CreateCourseContentModal";
import greenCheck from "../../../assets/iconSvg/greenCheckmark.svg";
import styled from "styled-components";
import { ReactComponent as GreyVerticalDot } from "../../../assets/images/daoOverview/tripledotVertical.svg";
import { imageUrl, StyledMenu } from "../CourseCard";
import { Dropdown, Menu } from "antd";
import DeleteEntityModal from "../../Common/DeleteEntityModal";
import customAxios from "../../../api";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import CourseCompletionVerifyModal from "../CourseCompletionVerifyModal";
import IconWrapper from "../../IconWrapper";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";

export const getContentType = (content) => {
  if (content.link) {
    if (content.link.includes("https://storage.googleapis.com/")) {
      return "UPLOAD";
    }
    return "ADD_LINK";
  }
  return "QUIZ";
};
export const HoverCard = styled.div`
  :hover {
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
`;
const CourseContentCard = ({
  content,
  index,
  canManageCourse,
  fetchCourseDetailsService,
  edit,
  isEnrolled,
  course,
}) => {
  const { courseId } = useParams();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: content?.id });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditCourseContentModalVisible, setIsEditCourseContentModalVisible] =
    useState(false);
  const [isCompletionModalOpen, setIsCompletionModalOpen] = useState(false);

  const menu = (
    <StyledMenu>
      <Menu.Item
        onClick={(e) => {
          setIsEditCourseContentModalVisible(true);
        }}
      >
        <IconWrapper>
          <PencilIcon fill="#6B7280" width="12px" />
        </IconWrapper>{" "}
        Edit
        <CreateCourseContentModal
          onSubmit={() => {
            fetchCourseDetailsService();
          }}
          isModalVisible={isEditCourseContentModalVisible}
          handleCancel={() => setIsEditCourseContentModalVisible(false)}
          edit
          content={content}
        />
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          setShowDeleteModal(true);
        }}
      >
        <IconWrapper>
          <TrashIcon fill="#6B7280" width="12px" />
        </IconWrapper>{" "}
        Delete
      </Menu.Item>
    </StyledMenu>
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: "1px solid #E2E8F0",
    borderRadius: "12px",
    padding: "16px",
    display: "flex",
    gap: "16px",
    alignItems: "center",
    font: "normal 500 14px/20px Inter",
    background: "white",
    zIndex: 1,
    position: "relative",
    cursor: "pointer",
  };
  const deleteCourseContentHandler = () => {
    customAxios
      .delete(`courses/delete/details?courseDetailsId=${content.id}`)
      .then((res) => {
        toast.success("Course Content deleted successfully");
        fetchCourseDetailsService();
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  return (
    <HoverCard
      ref={setNodeRef}
      style={style}
      onClick={async () => {
        if (edit) {
          return;
        }
        if (!canManageCourse && !isEnrolled) {
          toast.error("Please enroll course to access the content");
          return;
        }

        if (content.link) {
          if (!content.isCompleted && !canManageCourse) {
            await customAxios.post("/courses/detail/mark", {
              courseDetailsId: content.id,
              courseId,
            });
            fetchCourseDetailsService();
          }
          window.open(content.link);
        } else {
          if (!isCompletionModalOpen) setIsCompletionModalOpen(true);
        }
      }}
    >
      {content?.quiz && (
        <CourseCompletionVerifyModal
          isModalOpen={isCompletionModalOpen}
          handleModalClose={() => setIsCompletionModalOpen(false)}
          questions={content?.quiz}
          content={content}
          courseDetails={course}
          fetchCourseDetailsService={fetchCourseDetailsService}
        />
      )}
      {edit ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          {...attributes}
          {...listeners}
        >
          <DragIcon />
        </div>
      ) : content.isCompleted ? (
        <img src={greenCheck} alt="" style={{ minWidth: "24px" }} />
      ) : (
        <div
          style={{
            minWidth: "24px",
            aspectRatio: 1,
            borderRadius: "50%",
            background: "#F1F5F9",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            font: "normal 500 12px/16px Inter",
            color: "#64748B",
          }}
        >
          {index + 1}
        </div>
      )}

      <div style={{ display: "flex", alignItems: "center" }}>
        {tabs.find(({ value }) => value === getContentType(content)).coloricon}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          flex: "1",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {content.title}
        <div
          style={{
            font: "normal 500 12px/16px Inter",
            color: "#64748B",
          }}
        >
          {content.description}
        </div>
      </div>
      {edit && (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          style={{
            position: "absolute",
            background: "white",
          }}
        >
          <GreyVerticalDot
            style={{
              cursor: "pointer",
            }}
          />
        </Dropdown>
      )}
      <DeleteEntityModal
        note="This course content will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          deleteCourseContentHandler();
        }}
      />
    </HoverCard>
  );
};

export default CourseContentCard;

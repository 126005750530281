import { Button, Col, Row, Tag, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { Modal } from "../../styles/ModalStyles";
import styled from "styled-components";
import IconWrapper from "../IconWrapper";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { checkFileSize, postMixpanel } from "../../utils";
import { useDaoContext } from "../../layouts/app/DaoContext";

const StyledAttachment = styled.div`
  padding: 0px 0px;
  .attachment-title {
    // font-weight: 600;
    // font-size: 12px;
    margin: 5px 4px;
  }
`;
const BountyFilesAttachment = ({ files, onDelete }) => {
  return (
    <StyledAttachment>
      <h4 className="attachment-title">Attachments:</h4>
      {files.map((file, idx) => (
        <Tag
          style={{
            border: "1px solid #E2E8F0",
            background: "transparent",
            padding: "0.25rem 1rem",
            borderRadius: "1rem",
            marginBottom: "8px",
          }}
          closable
          onClose={() => onDelete(file.lastModified)}
          key={idx}
        >
          <IconWrapper width="12px" style={{ marginRight: "5px" }}>
            <DocumentIcon />
          </IconWrapper>{" "}
          {file.name}
        </Tag>
      ))}
    </StyledAttachment>
  );
};
function BountySubmit({
  visible,
  handleCancel,
  bountyId,
  handleSuccess,
  subtaskId,
  autoValidate,
  bountySubmissionDataPrefill,
}) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const fileUploadRef = useRef(null);
  const [submitting, setSubmitting] = useState(false);
    const { daoDetails, daoId } = useDaoContext();
  
  console.log(subtaskId);
  const submitBounty = (state) => {
    if (bountySubmissionDataPrefill) {
      customAxios
        .patch("/bountysubmission/update", {
          bountySubmissionId: bountySubmissionDataPrefill.id,
          action: "REQUESTED_CHANGES",
          title: state.title,
          description: state.description,
        })
        .then((res) => {
          console.log("res", res);
          handleSuccess();
          handleCancel();
        })
        .catch((err) => console.log("err", err));

      return;
    }
    if (!autoValidate && (!state?.title || state.title.trim() === "")) {
      return toast.error("Please enter the title for your submission");
    }
    if (!state?.description || !state?.description?.trim() === "") {
      return toast.error("Please enter the description for your submission");
    }
    setSubmitting(true);
    customAxios
      .post("/bountysubmission/submit", {
        ...state,
        attachments: state.attachments
          ? state.attachments.map((ele) => ele.publicUrl)
          : [],
        // title: "title sample",
        bountyId: subtaskId || bountyId,
        daoId: daoId,
        type: "BOUNTY_SUBMISSION",
      })
      .then((res) => {
        if (res.data.sucess) {
          postMixpanel("track", "created_bounty_submission", {
            dao_id: daoId,
            bounty_submission_name: state.title,
            bounty_id: bountyId,
          });
          toast.success(
            subtaskId
              ? "Checklist Item submitted successfully"
              : "Bounty submitted successfully"
          );
        }
        handleCancel();
        if (handleSuccess) handleSuccess();
      })
      .catch((err) => toast.error("Some erorr occured"))
      .finally(() => {
        setSubmitting(false);
      });
  };
  useEffect(() => {
    if (bountySubmissionDataPrefill) {
      setState((prev) => ({
        ...prev,
        title: bountySubmissionDataPrefill.title,
        description: bountySubmissionDataPrefill.description,
        attachments: bountySubmissionDataPrefill?.attachments?.map(
          (singleAttachment) => {
            return {
              publicUrl: singleAttachment,
            };
          }
        ),
      }));
    }
  }, [bountySubmissionDataPrefill]);

  const { Title } = Typography;
  return (
    <Modal
      visible={visible}
      destroyOnClose
      width="614px"
      onCancel={handleCancel}
      footer={
        <Row xs={24} style={{ justifyContent: "space-between" }}>
          {autoValidate ? (
            <div></div>
          ) : (
            <Button
              type="secondary"
              icon={<i class="fa-solid fa-paperclip"></i>}
              style={{ borderRadius: "20px", border: "1px solid #E2E8F0" }}
              onClick={() => {
                fileUploadRef.current.click();
              }}
            >
              Add Attachment
            </Button>
          )}
          <input
            hidden
            ref={fileUploadRef}
            type="file"
            accept={"image/*"}
            onClick={() => {
              fileUploadRef.current.value = null;
            }}
            onChange={(e) => {
              const newFile = e.target.files[0];
              checkFileSize(newFile.size);
              if (newFile) {
                let formData = new FormData();
                formData.append("files", e.target.files[0]);
                setLoading(true);
                customAxios
                  .post("/bountysubmission/uploadattach", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                  })
                  .then((res) => {
                    setLoading(false);

                    setState((prev) => {
                      const imageObject = {};

                      for (const key in newFile) {
                        const value = newFile[key];
                        const notFunction = typeof value !== "function";
                        notFunction && (imageObject[key] = value);
                      }
                      return {
                        ...prev,
                        attachments: state?.attachments?.length
                          ? [
                              ...state?.attachments,
                              {
                                ...imageObject,
                                publicUrl: res.data.publicUrl[0],
                              },
                            ]
                          : [
                              {
                                ...imageObject,
                                publicUrl: res.data.publicUrl[0],
                              },
                            ],
                      };
                    });
                  })
                  .catch((Err) => {
                    setLoading(false);

                    console.log("error");
                  });
              }
            }}
          />
          <Button
            type="primary"
            style={{ borderRadius: "12px", background: "#3B82F6" }}
            disabled={loading || submitting}
            onClick={() => {
              submitBounty(state);
            }}
          >
            Submit
          </Button>
        </Row>
      }
    >
      <Col xs={24} style={{ rowGap: "1rem", padding: "16px" }}>
        {!autoValidate && (
          <TextArea
            autoFocus={true}
            style={{
              fontSize: "21px",
              fontWeight: "600",
              paddingRight: "35px",
              marginBottom: "10px",
              paddingLeft: "0px",
            }}
            autoSize={{ minRows: "1", maxRows: "3" }}
            placeholder="Add your submission title"
            // size="large"
            value={state?.title}
            onChange={(e) => {
              setState((prev) => ({ ...prev, title: e.target.value }));
            }}
            bordered={false}
          />
        )}
        {/* <TextArea
            value={state?.title}
            style={{ borderRadius: "8px", marginBottom: "10px" }}
            placeholder="Enter your title..."
            rows={1}
            onChange={(e) => {
              setState((prev) => ({ ...prev, title: e.target.value }));
            }}
          ></TextArea> */}

        <TextArea
          value={state?.description}
          style={{
            borderRadius: "8px",
            resize: "none",
            marginBottom: "10px",
            paddingLeft: "0px",
          }}
          placeholder="Type your submission details here..."
          rows={18}
          bordered={false}
          onChange={(e) => {
            setState((prev) => ({ ...prev, description: e.target.value }));
          }}
        ></TextArea>
        {state?.attachments?.length > 0 && (
          <BountyFilesAttachment
            files={state.attachments}
            onDelete={(id) => {
              setState((prev) => {
                const currFiles = state.attachments.slice();
                return {
                  ...prev,
                  attachments: currFiles.filter(
                    (ele) => ele.lastModified === id
                  ),
                };
              });
            }}
          />
        )}
      </Col>
    </Modal>
  );
}

export default BountySubmit;

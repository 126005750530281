import { Button } from "antd";
import React, { useRef } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import useWindowSize from "../../../hooks/useWindowSize";
const StyledDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};

  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: inset 73.7333px -73.7333px 73.7333px rgba(165, 165, 165, 0.1),
    inset -73.7333px 73.7333px 73.7333px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  z-index: 300;
  .sliding-sidebar {
    position: absolute;
    right: 0;
    width: 520px;
    background: #f4f2f2;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    &__content {
      height: 100%;
      overflow-y: scroll;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      padding: 20px 10px;
    }
    &__banner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 16px;
      border-bottom: 1px solid #e2e8f0;
    }
    > img {
      width: 300px;
      margin-top: 100px;
    }
    &__tabs {
      border-bottom: 1px solid #e2e8f0;
      margin-top: 20px;
      padding-bottom: 10px;
      &--single-tab {
        margin: 0 15px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        &-active {
          position: relative;
          color: #3b82f6;
          &::after {
            content: "";
            position: absolute;
            bottom: -13px;
            left: 0;
            height: 2px;
            width: 200%;
            transform: translateX(-25%);
            background-color: #3b82f6;
          }
        }
      }
    }
  }
`;
function SlidingSidebar({
  children,
  banner,
  closeOnEscape = true,
  handleClose,
  open,
}) {
  const sidebarRef = useRef(null);
  const { isMobile } = useWindowSize();
  useOnClickOutside(sidebarRef, () => {
    if (closeOnEscape) {
      handleClose();
    }
  });
  return (
    <StyledDiv
      className="sliding-sidebar__wrapper"
      onClick={(e) => {
        e.stopPropagation();
        if (closeOnEscape) {
          handleClose();
        }
      }}
      open={open}
      ref={sidebarRef}
    >
      <div
        className="sliding-sidebar"
        style={isMobile ? { right: 0, width: "90%" } : {}}
      >
        {/* {isMobile ? (
          <Button style={{ position: "absolute" }}>Close</Button>
        ) : null} */}
        <div className="sliding-sidebar__banner">{banner}</div>
        <div className="sliding-sidebar__content">{children}</div>
      </div>
    </StyledDiv>
  );
}

export default SlidingSidebar;

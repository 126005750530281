import { Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import petra from "../../assets/images/common/petra.jpg";
import solana from "../../assets/images/login/solana-sol-logo.svg";
import metamask from "../../assets/iconSvg/metamask.png";
import customAxios from "../../api";
import { getAddress } from "ethers/lib/utils";
import { toast } from "react-toastify";
import Web3 from "web3";
import { walletConnect } from "../../utils/connectors";
import { UserContext } from "../../layouts/app/UserContext";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import walletConnectIcon from "./../../assets/iconSvg/wallet-connect.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { useWalletConnect } from "../../layouts/app/WalletConnectContext";
import { useEthers } from "@usedapp/core";
import useSolana from "../../hooks/useSolana";
function BountySubmitWalletCheck({
  isModalOpen,
  closeModalHandler,
  requiredNetwork,
}) {
  const { isMobile } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const { reload } = useContext(UserContext);
  const { account } = useEthers();
  const { onClickActivate, isWalletConnected, setWalletConnected } =
    useWalletConnect();
  const handleConnectWallet = () => {
    onClickActivate();
  };
  useSolana(
    (access_token) => {
      localStorage.setItem("access_token", access_token);
      reload();
    },
    () => {
    },
    () => {
      toast.error("Solana Account is already connected with another account");
    },
    "connect"
  );
  useEffect(() => {
    if (isWalletConnected) {
      const authWalletConnect = async () => {
        try {
          setWalletConnected(true);
          customAxios
            .post("auth/connect/metamask", {
              publicAddress: account,
            })
            .then((response) => {
              handleSignMessage({
                publicAddress: account,
                ...response.data,
                isWalletConnect: true,
              });
            })
            .catch((err) => {
              console.log(err);
              toast.error("Some error occured");
            });
        } catch (error) {
          console.error(error);
        }
      };
      authWalletConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  const connectPetra = () => {
    if (window.aptos) {
      window.aptos
        .connect()
        .then((res) => {
          // Return the address of the wallet
          console.log("tt", res);
          const publicAddress = res.address;
          customAxios
            .post("auth/connect/aptos", {
              publicAddress: publicAddress,
            })
            .then((response) => {
              handleSignMessageForPetra({
                publicAddress: publicAddress,
                ...response.data,
                publicKey: res.publicKey,
              });
            })
            .catch((err) => {
              console.log("error", err);
            });
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } else {
      toast.error("Install Petra wallet extension!!");
    }
  };
  const handleSignMessageForPetra = ({ message, token }) => {
    window.aptos
      .signMessage({
        message: "I am signing my one-time nonce:",
        nonce: message,
      })
      .then((res) => {
        window.aptos.account().then(({ publicKey }) => {
          customAxios
            .post("auth/connect/aptos/login", {
              token: "Bearer " + token,
              signature: res.signature,
              publicKey: publicKey,
            })
            .then((response) => {
              localStorage.setItem(
                "access_token",
                response?.data?.access_token
              );
              reload();
            })
            .catch((err) => {
              toast.error(
                "Petra wallet already connected with another account"
              );
            });
        });
      });
  };
  const handleSignMessage = ({
    publicAddress,
    message,
    token,
    isWalletConnect = false,
  }) => {
    let web3;
    if (!isWalletConnect) {
      web3 = new Web3(Web3.givenPovider || window.ethereum);
    } else {
      web3 = new Web3(walletConnect);
    }

    web3.eth.personal
      .sign(
        web3.utils.fromUtf8(`I am signing my one-time nonce: ${message}`),
        publicAddress
      )
      .then((res) => {
        customAxios
          .post("auth/connect/metamask/login", {
            token: "Bearer " + token,
            signature: res,
            isWalletConnect: isWalletConnect,
          })
          .then((response) => {
            localStorage.setItem("access_token", response.data.access_token);
            setLoading(false);
            reload();
          })

          .catch((err) => {
            toast.error(
              "Wallet address is already connected with another account on Dao Manager"
            );
            setLoading(false);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const connectMetamask = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => {
          // Return the address of the wallet
          customAxios
            .post("auth/connect/metamask", {
              publicAddress: getAddress(res[0]),
            })
            .then((response) => {
              handleSignMessage({
                publicAddress: getAddress(res[0]),
                ...response.data,
                isWalletConnect: false,
              });
            })
            .catch((err) => {
              console.log("error");
              setLoading(false);
            });
        })
        .catch((err) => toast.error(err.message));
    } else {
      toast.error("Install metamask extension!!");
    }
  };
  const getNetworkDetails = () => {
    switch (requiredNetwork) {
      case "aptos":
        return (
          <>
            <img
              style={{ marginRight: "10px", borderRadius: "50px" }}
              width={24}
              src={petra}
              alt="petra"
            />
            <span
              style={{ fontSize: "14px", color: "#1F2937", fontWeight: "500" }}
            >
              Petra
            </span>
          </>
        );
      case "solana":
        return (
          <>
            <img
              style={{ marginRight: "10px", borderRadius: "50px" }}
              width={24}
              src={solana}
              alt="solana"
            />
            <span
              style={{ fontSize: "14px", color: "#1F2937", fontWeight: "500" }}
            >
              Solana
            </span>
          </>
        );
      case "solana_devnet":
        return (
          <>
            <img
              style={{ marginRight: "10px", borderRadius: "50px" }}
              width={24}
              src={solana}
              alt="solana"
            />
            <span
              style={{ fontSize: "14px", color: "#1F2937", fontWeight: "500" }}
            >
              Solana
            </span>
          </>
        );
      default:
        return isMobile ? (
          <>
            {" "}
            <img
              src={walletConnectIcon}
              alt="icon"
              style={{ marginRight: "10px", borderRadius: "50px" }}
              width={24}
            />
            <span
              style={{ fontSize: "14px", color: "#1F2937", fontWeight: "500" }}
            >
              Wallet Connect
            </span>
          </>
        ) : (
          <>
            <img
              style={{ marginRight: "10px", borderRadius: "50px" }}
              width={24}
              src={metamask}
              alt="metamask"
            />
            <span
              style={{ fontSize: "14px", color: "#1F2937", fontWeight: "500" }}
            >
              Metamask
            </span>
          </>
        );
    }
  };
  return (
    <Modal
      open={isModalOpen}
      return
      visible={isModalOpen}
      width="400px"
      centered
      bodyStyle={{ maxHeight: "640px", overflowY: "auto", paddingBottom: 0 }}
      title={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <span style={{ fontSize: "16px", fontWeight: "600" }}>
            Connect wallet to submit
          </span>
          <span
            style={{ color: "#6B7280", fontSize: "12px", fontWeight: "400" }}
          >
            Be eligible for this bounty reward
          </span>
        </div>
      }
      footer={<></>}
      onOk={closeModalHandler}
      onCancel={closeModalHandler}
      className="view-bounty-submission-modal"
    >
      <div
        style={{
          border: "1px solid #E5E7EB",
          borderRadius: "12px",
          padding: "12px",
          marginBottom: "24px",
          marginTop: "10px",
        }}
      >
        {getNetworkDetails()}
      </div>
      {requiredNetwork?.includes("solana") ? (
        <WalletMultiButton
          style={{
            backgroundColor: "#3B82F6",
            borderRadius: "12px",
            padding: "12px",
            cursor: "pointer",
            textAlign: "center",
            color: "#ffffff",
            fontSize: "14px",
            fontWeight: "500",
            width: "100%",
          }}
        >
          <span
            style={{
              width: "100%",
              display: "inline-block",
              textAlign: "center",
            }}
          >
            Connect wallet
          </span>
        </WalletMultiButton>
      ) : (
        <div
          style={{
            backgroundColor: "#3B82F6",
            borderRadius: "12px",
            padding: "12px",
            cursor: "pointer",
            textAlign: "center",
            color: "#ffffff",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={() => {
            switch (requiredNetwork) {
              case "aptos":
                connectPetra();
                return;
              default:
                if (isMobile) {
                  console.log("hello");
                  handleConnectWallet();
                } else connectMetamask();
                return;
            }
          }}
        >
          Connect wallet
        </div>
      )}
    </Modal>
  );
}

export default BountySubmitWalletCheck;

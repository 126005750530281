import {
  ChevronRightIcon,
  HomeIcon,
  RectangleGroupIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router";
import { useDaoContext } from "../../layouts/app/DaoContext";

export const SpaceBreadcrumb = () => {
  const { daoDetails, parentDaoDetails } = useDaoContext();
  const navigate = useNavigate();
  return (
    <div className="flex items-center gap-0">
      <button
        className="bg-white border-none cursor-pointer"
        onClick={() =>
          parentDaoDetails?.daoName &&
          parentDaoDetails?.daoId &&
          navigate(
            `/app/dao/${parentDaoDetails?.daoName}/${parentDaoDetails?.daoId}`
          )
        }
      >
        {parentDaoDetails?.profilePicture ? (
          <img
            src={parentDaoDetails?.profilePicture}
            alt={daoDetails?.daoName}
            className="w-5 h-5 rounded-full object-cover"
          />
        ) : (
          <HomeIcon className="w-5 text-gray-500" />
        )}
      </button>
      <ChevronRightIcon className="w-3 text-gray-300 stroke-[4]" />
      <button
        className="bg-white border-none cursor-pointer"
        onClick={() =>
          parentDaoDetails?.daoName &&
          parentDaoDetails?.daoId &&
          daoDetails?.daoId &&
          daoDetails?.daoName &&
          navigate(
            `/app/dao/${parentDaoDetails?.daoName}/${parentDaoDetails?.daoId}/spaces/${daoDetails?.daoName}/${daoDetails?.daoId}`
          )
        }
      >
        {daoDetails?.iconEmoji ? (
          <div className="text-lg w-5 h-5 rounded-full flex items-center justify-center">
            {daoDetails?.iconEmoji}
          </div>
        ) : daoDetails?.profilePicture ? (
          <img
            src={daoDetails?.profilePicture}
            alt={daoDetails?.daoName}
            className="w-5 h-5 rounded-full object-cover"
          />
        ) : (
          <RectangleGroupIcon className="w-5 text-gray-500" />
        )}
      </button>
      <ChevronRightIcon className="w-3 text-gray-300 stroke-[4]" />
    </div>
  );
};

type Props = {
  icon: React.ReactNode;
  entity: string;
};

const BreadcrumbDesktopContainer = ({ entity, icon }: Props) => {
  return (
    <div className="flex items-center gap-3">
      <SpaceBreadcrumb />
      <div className="flex gap-1 items-center">
        {icon} {entity}
      </div>
    </div>
  );
};

export default BreadcrumbDesktopContainer;

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
} from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize";
import { DaoContext } from "../../layouts/app/DaoContext";
import { UserContext } from "../../layouts/app/UserContext";
import { DaoType } from "../../lib/types/common";
import {
  checkCanJoinSpace,
  getGatingList,
  joinSpace,
} from "../../lib/utils/spaces";
import { Modal } from "../../styles/ModalStyles";
import PresetDetails from "../Gating/PresetDetails";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  daoDetails: DaoType;
};

const JoinSpaceModal = ({
  isOpen,
  setIsOpen,
  daoDetails: spaceDetails,
}: Props) => {
  const navigate = useNavigate();
  const { userDetails, showLoginModal } = useContext(UserContext);
  const {
    profilePicture,
    iconBackground,
    iconEmoji,
    daoName,
    subDaoGatingCondition,
    contributors,
  } = spaceDetails;
  const {
    daoDetails: daoDetailsFromContext,
    parentDaoDetails: parentDaoDetailsFromContext,
    isCurrentDaoJoined,
  } = useContext(DaoContext);

  const parentDetails = parentDaoDetailsFromContext || daoDetailsFromContext;

  const { isLoading, data: canJoin } = useQuery(
    ["can-join-space", spaceDetails.daoId],
    () => checkCanJoinSpace(spaceDetails.daoId),
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to check eligibility to join.");
      },
    }
  );

  const { isLoading: isJoining, mutate: handleJoinSpace } = useMutation({
    mutationFn: () => joinSpace(spaceDetails?.daoId),
    onError: (error) => {
      console.error(error);
      toast.error("Failed to join space. Try again later.");
    },
    onSuccess: () => {
      navigate(
        `/app/dao/${parentDetails?.daoName}/${spaceDetails?.parentDaoId}/spaces/${spaceDetails?.daoName}/${spaceDetails?.daoId}`
      );
    },
  });

  const { isMobile } = useWindowSize();

  useEffect(() => {
    console.log({ isOpen });
  }, [isOpen]);

  return (
    <Modal
      visible={isOpen}
      // @ts-expect-error
      isMobile={isMobile}
      destroyOnClose
      width="450px"
      onCancel={() => setIsOpen(false)}
      centered={true}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(20px)",
      }}
      footer={null}
      height={"auto"}
    >
      <div className="flex flex-col p-3 pt-5 items-center">
        {!iconEmoji && profilePicture && (
          <img
            src={profilePicture}
            className="w-20 h-20 rounded-full flex items-center justify-center object-cover"
            alt="Profile"
          />
        )}
        {iconEmoji && (
          <div
            style={{ background: iconBackground }}
            className="text-3xl w-20 h-20 rounded-full flex items-center justify-center"
          >
            {iconEmoji}
          </div>
        )}
        <div className="flex items-center gap-4 w-full mt-4">
          <div className="flex-1 border border-solid border-gray-200 h-0" />
          <span className="uppercase text-gray-400 font-semibold text-xs text-center">
            {daoDetailsFromContext.daoName}
          </span>
          <div className="flex-1 border border-solid border-gray-200 h-0" />
        </div>
        <p className="m-0 font-semibold text-base mt-2 text-center">
          {daoName}
        </p>
        <div className="flex gap-1 mt-4">
          <div className="bg-gray-100 rounded-md px-2 py-1 font-semibold text-gray-500 text-xs">
            👥: {contributors}
          </div>
          {subDaoGatingCondition && (
            <div className="bg-gray-100 rounded-md px-2 py-1 font-semibold text-gray-500 text-xs">
              🔒 {subDaoGatingCondition?.conditions?.length} condition
              {subDaoGatingCondition?.conditions?.length !== 1 && "s"}
            </div>
          )}
        </div>
        {spaceDetails.subDaoGatingCondition && (
          <div className="mt-4 w-full">
            <PresetDetails
              gatingCondition={spaceDetails.subDaoGatingCondition}
            />
          </div>
        )}
        {!isCurrentDaoJoined && !!canJoin && (
          <div className="text-xs text-blue-500 py-2 px-4 bg-blue-50 flex items-center w-full mt-3 rounded-lg gap-3">
            <InformationCircleIcon className="w-4 flex-shrink-0" />
            <p className="m-0">
              By joining this space, you’ll be automatically joining the main
              {parentDetails?.daoName} workspace as well.
            </p>
          </div>
        )}
        {(!userDetails || (!isLoading && canJoin)) && (
          <button
            onClick={() => (userDetails ? handleJoinSpace() : showLoginModal())}
            disabled={isJoining}
            className={classNames(
              "border-none bg-blue-500 text-white text-xs inline-flex w-full items-center gap-1 justify-center p-2 rounded-lg mt-4",
              isJoining
                ? "cursor-not-allowed opacity-50"
                : "hover:bg-blue-600 active:bg-blue-700 cursor-pointer"
            )}
          >
            Join Space <ArrowRightIcon className="w-3 stroke-[3]" />
          </button>
        )}
        {userDetails && !isLoading && !canJoin && (
          <p className="text-yellow-900 text-xs py-2 px-3 bg-yellow-50 rounded-lg mt-4 w-full text-center">
            Sorry, you don’t satisfy one or more conditions to join this space
          </p>
        )}
      </div>
    </Modal>
  );
};

export default JoinSpaceModal;

import { Button, Select, Spin, Typography } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import styled from "styled-components";
import customAxios from "../api";
import { Modal } from "../styles/ModalStyles";

import { useSearchParams } from "react-router-dom";
import { getDefaultDaoImage } from "../utils";
import { MD_SCREEN_BREAKPOINT } from "../constants";
import useWindowSize from "../hooks/useWindowSize";
import { LoadingOutlined } from "@ant-design/icons";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 40,
      marginTop: "30px",
    }}
    spin
  />
);
export const ModalBody = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  & .steps {
    height: 15px;
    font-weight: 600;
    font-size: 12px;
    color: #64748b;
  }

  & .title {
    width: 371px;
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    margin-top: 4px;
  }

  & .image {
    align-self: center;
    width: 100px;
    height: 100px;
    cursor: pointer;
    margin-top: 28px;
    border-radius: 4rem;
  }

  & .select {
    .ant-select-selector {
      border-radius: 12px !important;
    }
    margin-top: 16px;
  }

  & .text-button {
    align-self: start;
    color: #64748b;
    padding: 0;
    margin-top: 12px;
  }

  & .continue-button {
    height: 36px;
    border-radius: 12px;
    margin-top: 24px;

    &:disabled {
      color: #fff;
      background: #b1cdfb;
    }
  }
`;

const ImportDiscord = ({
  setCurrentStep,
  setUpdater = () => {},
  discordToken,
  handleSubmit,
}) => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;

  const [daoName, setDaoName] = useState("");
  const [selectedState, setSelectedState] = useState({});
  const [guilds, setGuilds] = useState([]);
  const [isBotInstalled, setIsBotInstalled] = useState(false);
  const [loading, setLoading] = useState(true);
  const interval = useRef();
  const addingBot = useRef(false);

  useEffect(() => {
    interval.current = setInterval(() => {
      axios
        .get("https://onboarding-bot-discord-5dzspnb6za-uc.a.run.app/guilds")
        .then(async (res) => {
          if (
            res.data.find((server) => {
              return server.name === daoName;
            })
          ) {
            setIsBotInstalled(true);
            if (addingBot.current) {
              addingBot.current = false;
              await handleSubmit({
                selectedState,
                daoName,
                discordGuildId: guilds?.find((ele) => ele?.name === daoName)
                  ?.id,
              });
            } else {
              setLoading(false);
            }
          } else {
            setIsBotInstalled(false);
          }
          if (!addingBot.current) {
            setLoading(false);
          }
        });
    }, [5000]);
    return () => {
      clearInterval(interval.current);
    };
  }, [daoName]);
  useEffect(() => {
    if (daoName !== "") {
      setSelectedState(guilds.filter((item) => item.name === daoName)[0]);
    }
    addingBot.current = false;
    setLoading(true);
  }, [daoName]);

  useEffect(() => {
    if (discordToken) {
      (async () => {
        const { data } = await customAxios.get(
          `/dao/import/discord?code=${discordToken}`
        );
        setGuilds(data.guilds);
        if (data.guilds.length) {
          setDaoName(data?.guilds[0]?.name);
        }
      })();
    }
  }, [discordToken]);
  const [searchParams, setSearchParams] = useSearchParams();
  const previousRoute = atob(searchParams.get("state"));

  const navigate = useDaoLensNavigate();
  const onDiscard = () => {
    if (previousRoute === "/app/onboarding") {
      navigate(-3);
    }
    navigate(previousRoute);
    setCurrentStep(0);
  };
  return (
    <Modal
      centered
      destroyOnClose
      title={null}
      visible
      onCancel={() => onDiscard()}
      footer={null}
      width={isMobile ? "100%" : "460px"}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(20px)",
      }}
      height="345px"
    >
      <ModalBody>
        <h1 style={{ fontSize: "20px", lineHeight: "24px" }}>
          What’s the name of your organisation?
        </h1>

        {daoName !== "" && selectedState.icon ? (
          <img
            className="image"
            src={`${`https://cdn.discordapp.com/icons/${selectedState.id}/${selectedState.icon}.png`}`}
            alt="add_people"
          />
        ) : (
          getDefaultDaoImage(
            daoName,
            {
              alignSelf: "center",
              width: "100px",
              height: "100px",
              cursor: "pointer",
              marginTop: "28px",
              borderRadius: "4rem",
            },
            { fontSize: "32px" }
          )
        )}
        <Select
          className="select"
          value={daoName}
          onChange={(value) => setDaoName(value)}
        >
          {guilds.map((guild) => (
            <Select.Option value={guild.name}>{guild.name}</Select.Option>
          ))}
        </Select>

        {loading ? (
          <Spin indicator={antIcon} size="large" />
        ) : isBotInstalled ? (
          <Button
            type="primary"
            className="continue-button"
            onClick={() =>
              handleSubmit({
                selectedState,
                daoName,
                discordGuildId: guilds?.find((ele) => ele?.name === daoName)
                  ?.id,
              })
            }
            disabled={daoName.trim() === ""}
          >
            Continue
          </Button>
        ) : (
          <Button
            type="primary"
            className="continue-button"
            onClick={() => {
              window.open(
                `https://discord.com/oauth2/authorize?client_id=967219648955301899&guild_id=${
                  guilds?.find((ele) => ele?.name === daoName)?.id
                }&permissions=8&scope=bot%20applications.commands`
              );
              addingBot.current = true;
              setLoading(true);
            }}
            disabled={daoName.trim() === ""}
          >
            + Add Bot and Continue
          </Button>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ImportDiscord;

import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Skeleton } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import useWindowSize from "../../../hooks/useWindowSize";
import { CommunityFilterType } from "../../../types/DiscoverPage";
import {
  fetchCommunities,
  handleCreateNewCommunity,
} from "../../../utils/DiscoverPage";
import CommunityCard from "../CommunityCard";

type Props = {
  searchQuery: string;
  communityType: CommunityFilterType;
};

const DEFAULT_COMMUNITY_COUNT_TO_LOAD = 8;
const LOAD_MORE_COUNT_INCREAMENT = 6;

const Communities = ({ communityType, searchQuery }: Props) => {
  const { isMobile } = useWindowSize();
  const [communityCountLimit, setCommunityCountLimit] = useState(
    DEFAULT_COMMUNITY_COUNT_TO_LOAD
  );
  const location = useLocation();

  const { data: response } = useQuery(
    ["communities", searchQuery, communityType, communityCountLimit],
    {
      queryFn: () =>
        fetchCommunities(
          !searchQuery ? communityCountLimit : undefined,
          communityType,
          searchQuery
        ),
      onError: (error) => {
        toast.error("Something went wrong while fetching communties.");
        console.error(error);
      },
      keepPreviousData: true,
    }
  );
  const navigate = useDaoLensNavigate();

  const data = response?.communities;
  const totalCount = response?.totalCount;
  if (!data) return <Skeleton active paragraph={{ rows: 5, width: "100%" }} />;
  if (data.length === 0)
    return <p>No community found for the selected filters.</p>;

  return (
    <>
      <div
        style={{
          display: "grid",
          gap: "16px",
          gridTemplateColumns: isMobile
            ? undefined
            : "repeat(3, minmax(0, 1fr))",
        }}
      >
        <button
          style={{
            background: "#EFF6FF",
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            gap: isMobile ? "14px" : "18px",
            border: "2px dashed #93C5FD",
            color: "#3B82F6",
            borderRadius: "12px",
            justifyContent: "center",
            alignItems: "center",
            padding: isMobile ? "16px" : "",
            cursor: "pointer",
            minHeight: isMobile ? "" : "200px",
          }}
          onClick={() => {
            navigate("/app/onboarding", {
              state: {
                from: location.pathname + location.search,
                communityType,
              },
            });
          }}
        >
          <PlusIcon width={20} strokeWidth={2} />
          <span style={{ fontSize: "16px" }}>Add your community</span>
        </button>
        {data?.map((community) => (
          <CommunityCard {...community} key={community.id} width="auto" />
        ))}
      </div>
      {!searchQuery && totalCount > communityCountLimit && (
        <button
          onClick={() =>
            setCommunityCountLimit((prev) => prev + LOAD_MORE_COUNT_INCREAMENT)
          }
          style={{
            display: "flex",
            background: "white",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            fontSize: "14px",
            cursor: "pointer",
            border: "none",
            color: "#6B7280",
          }}
        >
          Load more
          <ChevronDownIcon width={10} strokeWidth={2} />
        </button>
      )}
    </>
  );
};

export default Communities;

export const languages = [
  // {
  //   id: "1",
  //   language: "Albanian",
  //   code: "sq",
  // },
  // {
  //   id: "2",
  //   language: "Amharic",
  //   code: "am",
  // },
  // {
  //   id: "3",
  //   language: "Arabic",
  //   code: "ar",
  // },
  // {
  //   id: "4",
  //   language: "Armenian",
  //   code: "hy",
  // },
  // {
  //   id: "5",
  //   language: "Azerbaijani",
  //   code: "az",
  // },
  // {
  //   id: "6",
  //   language: "Basque",
  //   code: "eu",
  // },
  // {
  //   id: "7",
  //   language: "Belarusian",
  //   code: "be",
  // },
  // {
  //   id: "8",
  //   language: "Bengali",
  //   code: "bn",
  // },
  // {
  //   id: "9",
  //   language: "Bosnian",
  //   code: "bs",
  // },
  // {
  //   id: "10",
  //   language: "Bulgarian",
  //   code: "bg",
  // },
  // {
  //   id: "11",
  //   language: "Catalan",
  //   code: "ca",
  // },
  // {
  //   id: "12",
  //   language: "Cebuano",
  //   code: "ceb",
  // },
  // {
  //   id: "13",
  //   language: "Chinese (Simplified)",
  //   code: "zh-CN",
  // },
  // {
  //   id: "14",
  //   language: "Chinese (Traditional)",
  //   code: "zh-TW",
  // },
  // {
  //   id: "15",
  //   language: "Corsican",
  //   code: "co",
  // },
  // {
  //   id: "16",
  //   language: "Croatian",
  //   code: "hr",
  // },
  // {
  //   id: "17",
  //   language: "Czech",
  //   code: "cs",
  // },
  // {
  //   id: "18",
  //   language: "Danish",
  //   code: "da",
  // },
  // {
  //   id: "19",
  //   language: "Dutch",
  //   code: "nl",
  // },
  // {
  //   id: "20",
  //   language: "English",
  //   code: "en",
  // },
  // {
  //   id: "21",
  //   language: "Esperanto",
  //   code: "eo",
  // },
  // {
  //   id: "22",
  //   language: "Estonian",
  //   code: "et",
  // },
  // {
  //   id: "23",
  //   language: "Finnish",
  //   code: "fi",
  // },
  {
    id: "24",
    language: "French",
    code: "fr",
  },
  // {
  //   id: "25",
  //   language: "Frisian",
  //   code: "fy",
  // },
  // {
  //   id: "26",
  //   language: "Galician",
  //   code: "gl",
  // },
  // {
  //   id: "27",
  //   language: "Georgian",
  //   code: "ka",
  // },
  {
    id: "28",
    language: "German",
    code: "de",
  },
  // {
  //   id: "29",
  //   language: "Greek",
  //   code: "el",
  // },
  // {
  //   id: "30",
  //   language: "Gujarati",
  //   code: "gu",
  // },
  // {
  //   id: "31",
  //   language: "Haitian Creole",
  //   code: "ht",
  // },
  // {
  //   id: "32",
  //   language: "Hausa",
  //   code: "ha",
  // },
  // {
  //   id: "33",
  //   language: "Hawaiian",
  //   code: "haw",
  // },
  // {
  //   id: "34",
  //   language: "Hebrew",
  //   code: "iw",
  // },
  {
    id: "35",
    language: "Hindi",
    code: "hi",
  },
  // {
  //   id: "36",
  //   language: "Hmong",
  //   code: "hmn",
  // },
  // {
  //   id: "37",
  //   language: "Hungarian",
  //   code: "hu",
  // },
  // {
  //   id: "38",
  //   language: "Icelandic",
  //   code: "is",
  // },
  // {
  //   id: "39",
  //   language: "Igbo",
  //   code: "ig",
  // },
  // {
  //   id: "40",
  //   language: "Indonesian",
  //   code: "id",
  // },
  // {
  //   id: "41",
  //   language: "Irish",
  //   code: "ga",
  // },
  // {
  //   id: "42",
  //   language: "Italian",
  //   code: "it",
  // },
  {
    id: "43",
    language: "Japanese",
    code: "ja",
  },
  // {
  //   id: "44",
  //   language: "Javanese",
  //   code: "jw",
  // },
  // {
  //   id: "45",
  //   language: "Kannada",
  //   code: "kn",
  // },
  // {
  //   id: "46",
  //   language: "Kazakh",
  //   code: "kk",
  // },
  // {
  //   id: "47",
  //   language: "Khmer",
  //   code: "km",
  // },
  // {
  //   id: "48",
  //   language: "Korean",
  //   code: "ko",
  // },
  // {
  //   id: "49",
  //   language: "Kurdish",
  //   code: "ku",
  // },
  // {
  //   id: "50",
  //   language: "Kyrgyz",
  //   code: "ky",
  // },
  // {
  //   id: "51",
  //   language: "Lao",
  //   code: "lo",
  // },
  // {
  //   id: "52",
  //   language: "Latin",
  //   code: "la",
  // },
  // {
  //   id: "53",
  //   language: "Latvian",
  //   code: "lv",
  // },
  // {
  //   id: "54",
  //   language: "Lithuanian",
  //   code: "lt",
  // },
  // {
  //   id: "55",
  //   language: "Luxembourgish",
  //   code: "lb",
  // },
  // {
  //   id: "56",
  //   language: "Macedonian",
  //   code: "mk",
  // },
  // {
  //   id: "57",
  //   language: "Malagasy",
  //   code: "mg",
  // },
  // {
  //   id: "58",
  //   language: "Malay",
  //   code: "ms",
  // },
  // {
  //   id: "59",
  //   language: "Malayalam",
  //   code: "ml",
  // },
  // {
  //   id: "60",
  //   language: "Maltese",
  //   code: "mt",
  // },
  // {
  //   id: "61",
  //   language: "Maori",
  //   code: "mi",
  // },
  // {
  //   id: "62",
  //   language: "Marathi",
  //   code: "mr",
  // },
  // {
  //   id: "63",
  //   language: "Mongolian",
  //   code: "mn",
  // },
  // {
  //   id: "64",
  //   language: "Myanmar (Burmese)",
  //   code: "my",
  // },
  // {
  //   id: "65",
  //   language: "Nepali",
  //   code: "ne",
  // },
  // {
  //   id: "66",
  //   language: "Norwegian",
  //   code: "no",
  // },
  // {
  //   id: "67",
  //   language: "Nyanja (Chichewa)",
  //   code: "ny",
  // },
  // {
  //   id: "68",
  //   language: "Pashto",
  //   code: "ps",
  // },
  // {
  //   id: "69",
  //   language: "Persian",
  //   code: "fa",
  // },
  // {
  //   id: "70",
  //   language: "Polish",
  //   code: "pl",
  // },
  // {
  //   id: "71",
  //   language: "Portuguese (Portugal, Brazil)",
  //   code: "pt",
  // },
  // {
  //   id: "72",
  //   language: "Punjabi",
  //   code: "pa",
  // },
  // {
  //   id: "73",
  //   language: "Romanian",
  //   code: "ro",
  // },
  // {
  //   id: "74",
  //   language: "Russian",
  //   code: "ru",
  // },
  // {
  //   id: "75",
  //   language: "Samoan",
  //   code: "sm",
  // },
  // {
  //   id: "76",
  //   language: "Scots Gaelic",
  //   code: "gd",
  // },
  // {
  //   id: "77",
  //   language: "Serbian",
  //   code: "sr",
  // },
  // {
  //   id: "78",
  //   language: "Sesotho",
  //   code: "st",
  // },
  // {
  //   id: "79",
  //   language: "Shona",
  //   code: "sn",
  // },
  // {
  //   id: "80",
  //   language: "Sindhi",
  //   code: "sd",
  // },
  // {
  //   id: "81",
  //   language: "Sinhala (Sinhalese)",
  //   code: "si",
  // },
  // {
  //   id: "82",
  //   language: "Slovak",
  //   code: "sk",
  // },
  // {
  //   id: "83",
  //   language: "Slovenian",
  //   code: "sl",
  // },
  // {
  //   id: "84",
  //   language: "Somali",
  //   code: "so",
  // },
  // {
  //   id: "85",
  //   language: "Spanish",
  //   code: "es",
  // },
  // {
  //   id: "86",
  //   language: "Sundanese",
  //   code: "su",
  // },
  // {
  //   id: "87",
  //   language: "Swahili",
  //   code: "sw",
  // },
  // {
  //   id: "88",
  //   language: "Swedish",
  //   code: "sv",
  // },
  // {
  //   id: "89",
  //   language: "Tagalog (Filipino)",
  //   code: "tl",
  // },
  // {
  //   id: "90",
  //   language: "Tajik",
  //   code: "tg",
  // },
  // {
  //   id: "91",
  //   language: "Tamil",
  //   code: "ta",
  // },
  // {
  //   id: "92",
  //   language: "Telugu",
  //   code: "te",
  // },
  // {
  //   id: "93",
  //   language: "Thai",
  //   code: "th",
  // },
  // {
  //   id: "94",
  //   language: "Turkish",
  //   code: "tr",
  // },
  // {
  //   id: "95",
  //   language: "Ukrainian",
  //   code: "uk",
  // },
  // {
  //   id: "96",
  //   language: "Urdu",
  //   code: "ur",
  // },
  // {
  //   id: "97",
  //   language: "Uzbek",
  //   code: "uz",
  // },
  // {
  //   id: "98",
  //   language: "Vietnamese",
  //   code: "vi",
  // },
  // {
  //   id: "99",
  //   language: "Welsh",
  //   code: "cy",
  // },
  // {
  //   id: "100",
  //   language: "Xhosa",
  //   code: "xh",
  // },
  // {
  //   id: "101",
  //   language: "Yiddish",
  //   code: "yi",
  // },
  // {
  //   id: "102",
  //   language: "Yoruba",
  //   code: "yo",
  // },
  // {
  //   id: "103",
  //   language: "Zulu",
  //   code: "zu",
  // },
  // {
  //   id: "104",
  //   language: "Afrikaans (South Africa)",
  //   code: "af-ZA",
  // },
  // {
  //   id: "105",
  //   language: "Amharic (Ethiopia)",
  //   code: "am-ET",
  // },
  // {
  //   id: "106",
  //   language: "Armenian (Armenia)",
  //   code: "hy-AM",
  // },
  // {
  //   id: "107",
  //   language: "Azerbaijani (Azerbaijan)",
  //   code: "az-AZ",
  // },
  // {
  //   id: "108",
  //   language: "Indonesian (Indonesia)",
  //   code: "id-ID",
  // },
  // {
  //   id: "109",
  //   language: "Malay (Malaysia)",
  //   code: "ms-MY",
  // },
  // {
  //   id: "110",
  //   language: "Bengali (Bangladesh)",
  //   code: "bn-BD",
  // },
  // {
  //   id: "111",
  //   language: "Bengali (India)",
  //   code: "bn-IN",
  // },
  // {
  //   id: "112",
  //   language: "Catalan (Spain)",
  //   code: "ca-ES",
  // },
  // {
  //   id: "113",
  //   language: "Czech (Czech Republic)",
  //   code: "cs-CZ",
  // },
  // {
  //   id: "114",
  //   language: "Danish (Denmark)",
  //   code: "da-DK",
  // },
  // {
  //   id: "115",
  //   language: "German (Germany)",
  //   code: "de-DE",
  // },
  // {
  //   id: "116",
  //   language: "English (Australia)",
  //   code: "en-AU",
  // },
  // {
  //   id: "117",
  //   language: "English (Canada)",
  //   code: "en-CA",
  // },
  // {
  //   id: "118",
  //   language: "English (Ghana)",
  //   code: "en-GH",
  // },
  // {
  //   id: "119",
  //   language: "English (United Kingdom)",
  //   code: "en-GB",
  // },
  // {
  //   id: "120",
  //   language: "English (India)",
  //   code: "en-IN",
  // },
  // {
  //   id: "121",
  //   language: "English (Ireland)",
  //   code: "en-IE",
  // },
  // {
  //   id: "122",
  //   language: "English (Kenya)",
  //   code: "en-KE",
  // },
  // {
  //   id: "123",
  //   language: "English (New Zealand)",
  //   code: "en-NZ",
  // },
  // {
  //   id: "124",
  //   language: "English (Nigeria)",
  //   code: "en-NG",
  // },
  // {
  //   id: "125",
  //   language: "English (Philippines)",
  //   code: "en-PH",
  // },
  // {
  //   id: "126",
  //   language: "English (Singapore)",
  //   code: "en-SG",
  // },
  // {
  //   id: "127",
  //   language: "English (South Africa)",
  //   code: "en-ZA",
  // },
  // {
  //   id: "128",
  //   language: "English (Tanzania)",
  //   code: "en-TZ",
  // },
  // {
  //   id: "129",
  //   language: "English (United States)",
  //   code: "en-US",
  // },
  // {
  //   id: "130",
  //   language: "Spanish (Argentina)",
  //   code: "es-AR",
  // },
  // {
  //   id: "131",
  //   language: "Spanish (Bolivia)",
  //   code: "es-BO",
  // },
  // {
  //   id: "132",
  //   language: "Spanish (Chile)",
  //   code: "es-CL",
  // },
  // {
  //   id: "133",
  //   language: "Spanish (Colombia)",
  //   code: "es-CO",
  // },
  // {
  //   id: "134",
  //   language: "Spanish (Costa Rica)",
  //   code: "es-CR",
  // },
  // {
  //   id: "135",
  //   language: "Spanish (Ecuador)",
  //   code: "es-EC",
  // },
  // {
  //   id: "136",
  //   language: "Spanish (El Salvador)",
  //   code: "es-SV",
  // },
  // {
  //   id: "137",
  //   language: "Spanish (Spain)",
  //   code: "es-ES",
  // },
  // {
  //   id: "138",
  //   language: "Spanish (United States)",
  //   code: "es-US",
  // },
  // {
  //   id: "139",
  //   language: "Spanish (Guatemala)",
  //   code: "es-GT",
  // },
  // {
  //   id: "140",
  //   language: "Spanish (Honduras)",
  //   code: "es-HN",
  // },
  // {
  //   id: "141",
  //   language: "Spanish (Mexico)",
  //   code: "es-MX",
  // },
  // {
  //   id: "142",
  //   language: "Spanish (Nicaragua)",
  //   code: "es-NI",
  // },
  // {
  //   id: "143",
  //   language: "Spanish (Panama)",
  //   code: "es-PA",
  // },
  // {
  //   id: "144",
  //   language: "Spanish (Paraguay)",
  //   code: "es-PY",
  // },
  // {
  //   id: "145",
  //   language: "Spanish (Peru)",
  //   code: "es-PE",
  // },
  // {
  //   id: "146",
  //   language: "Spanish (Puerto Rico)",
  //   code: "es-PR",
  // },
  // {
  //   id: "147",
  //   language: "Spanish (Dominican Republic)",
  //   code: "es-DO",
  // },
  // {
  //   id: "148",
  //   language: "Spanish (Uruguay)",
  //   code: "es-UY",
  // },
  // {
  //   id: "149",
  //   language: "Spanish (Venezuela)",
  //   code: "es-VE",
  // },
  // {
  //   id: "150",
  //   language: "Basque (Spain)",
  //   code: "eu-ES",
  // },
  // {
  //   id: "151",
  //   language: "Filipino (Philippines)",
  //   code: "fil-PH",
  // },
  // {
  //   id: "152",
  //   language: "French (Canada)",
  //   code: "fr-CA",
  // },
  // {
  //   id: "153",
  //   language: "French (France)",
  //   code: "fr-FR",
  // },
  // {
  //   id: "154",
  //   language: "Galician (Spain)",
  //   code: "gl-ES",
  // },
  // {
  //   id: "155",
  //   language: "Georgian (Georgia)",
  //   code: "ka-GE",
  // },
  // {
  //   id: "156",
  //   language: "Gujarati (India)",
  //   code: "gu-IN",
  // },
  // {
  //   id: "157",
  //   language: "Croatian (Croatia)",
  //   code: "hr-HR",
  // },
  // {
  //   id: "158",
  //   language: "Zulu (South Africa)",
  //   code: "zu-ZA",
  // },
  // {
  //   id: "159",
  //   language: "Icelandic (Iceland)",
  //   code: "is-IS",
  // },
  // {
  //   id: "160",
  //   language: "Italian (Italy)",
  //   code: "it-IT",
  // },
  // {
  //   id: "161",
  //   language: "Javanese (Indonesia)",
  //   code: "jv-ID",
  // },
  // {
  //   id: "162",
  //   language: "Kannada (India)",
  //   code: "kn-IN",
  // },
  // {
  //   id: "163",
  //   language: "Khmer (Cambodia)",
  //   code: "km-KH",
  // },
  // {
  //   id: "164",
  //   language: "Lao (Laos)",
  //   code: "lo-LA",
  // },
  // {
  //   id: "165",
  //   language: "Latvian (Latvia)",
  //   code: "lv-LV",
  // },
  // {
  //   id: "166",
  //   language: "Lithuanian (Lithuania)",
  //   code: "lt-LT",
  // },
  // {
  //   id: "167",
  //   language: "Hungarian (Hungary)",
  //   code: "hu-HU",
  // },
  // {
  //   id: "168",
  //   language: "Malayalam (India)",
  //   code: "ml-IN",
  // },
  // {
  //   id: "169",
  //   language: "Marathi (India)",
  //   code: "mr-IN",
  // },
  // {
  //   id: "170",
  //   language: "Dutch (Netherlands)",
  //   code: "nl-NL",
  // },
  // {
  //   id: "171",
  //   language: "Nepali (Nepal)",
  //   code: "ne-NP",
  // },
  // {
  //   id: "172",
  //   language: "Norwegian Bokmål (Norway)",
  //   code: "nb-NO",
  // },
  // {
  //   id: "173",
  //   language: "Polish (Poland)",
  //   code: "pl-PL",
  // },
  // {
  //   id: "174",
  //   language: "Portuguese (Brazil)",
  //   code: "pt-BR",
  // },
  // {
  //   id: "175",
  //   language: "Portuguese (Portugal)",
  //   code: "pt-PT",
  // },
  // {
  //   id: "176",
  //   language: "Romanian (Romania)",
  //   code: "ro-RO",
  // },
  // {
  //   id: "177",
  //   language: "Sinhala (Sri Lanka)",
  //   code: "si-LK",
  // },
  // {
  //   id: "178",
  //   language: "Slovak (Slovakia)",
  //   code: "sk-SK",
  // },
  // {
  //   id: "179",
  //   language: "Slovenian (Slovenia)",
  //   code: "sl-SI",
  // },
  // {
  //   id: "180",
  //   language: "Sundanese (Indonesia)",
  //   code: "su-ID",
  // },
  // {
  //   id: "181",
  //   language: "Swahili (Tanzania)",
  //   code: "sw-TZ",
  // },
  // {
  //   id: "182",
  //   language: "Swahili (Kenya)",
  //   code: "sw-KE",
  // },
  // {
  //   id: "183",
  //   language: "Finnish (Finland)",
  //   code: "fi-FI",
  // },
  // {
  //   id: "184",
  //   language: "Swedish (Sweden)",
  //   code: "sv-SE",
  // },
  // {
  //   id: "185",
  //   language: "Tamil (India)",
  //   code: "ta-IN",
  // },
  // {
  //   id: "186",
  //   language: "Tamil (Singapore)",
  //   code: "ta-SG",
  // },
  // {
  //   id: "187",
  //   language: "Tamil (Sri Lanka)",
  //   code: "ta-LK",
  // },
  // {
  //   id: "188",
  //   language: "Tamil (Malaysia)",
  //   code: "ta-MY",
  // },
  // {
  //   id: "189",
  //   language: "Telugu (India)",
  //   code: "te-IN",
  // },
  // {
  //   id: "190",
  //   language: "Vietnamese (Vietnam)",
  //   code: "vi-VN",
  // },
  // {
  //   id: "191",
  //   language: "Turkish (Turkey)",
  //   code: "tr-TR",
  // },
  // {
  //   id: "192",
  //   language: "Urdu (Pakistan)",
  //   code: "ur-PK",
  // },
  // {
  //   id: "193",
  //   language: "Urdu (India)",
  //   code: "ur-IN",
  // },
  // {
  //   id: "194",
  //   language: "Greek (Greece)",
  //   code: "el-GR",
  // },
  // {
  //   id: "195",
  //   language: "Bulgarian (Bulgaria)",
  //   code: "bg-BG",
  // },
  // {
  //   id: "196",
  //   language: "Russian (Russia)",
  //   code: "ru-RU",
  // },
  // {
  //   id: "197",
  //   language: "Serbian (Serbia)",
  //   code: "sr-RS",
  // },
  // {
  //   id: "198",
  //   language: "Ukrainian (Ukraine)",
  //   code: "uk-UA",
  // },
  // {
  //   id: "199",
  //   language: "Hebrew (Israel)",
  //   code: "he-IL",
  // },
  // {
  //   id: "200",
  //   language: "Arabic (Israel)",
  //   code: "ar-IL",
  // },
  // {
  //   id: "201",
  //   language: "Arabic (Jordan)",
  //   code: "ar-JO",
  // },
  // {
  //   id: "202",
  //   language: "Arabic (United Arab Emirates)",
  //   code: "ar-AE",
  // },
  // {
  //   id: "203",
  //   language: "Arabic (Bahrain)",
  //   code: "ar-BH",
  // },
  // {
  //   id: "204",
  //   language: "Arabic (Algeria)",
  //   code: "ar-DZ",
  // },
  // {
  //   id: "205",
  //   language: "Arabic (Saudi Arabia)",
  //   code: "ar-SA",
  // },
  // {
  //   id: "206",
  //   language: "Arabic (Iraq)",
  //   code: "ar-IQ",
  // },
  // {
  //   id: "207",
  //   language: "Arabic (Kuwait)",
  //   code: "ar-KW",
  // },
  // {
  //   id: "208",
  //   language: "Arabic (Morocco)",
  //   code: "ar-MA",
  // },
  // {
  //   id: "209",
  //   language: "Arabic (Tunisia)",
  //   code: "ar-TN",
  // },
  // {
  //   id: "210",
  //   language: "Arabic (Oman)",
  //   code: "ar-OM",
  // },
  // {
  //   id: "211",
  //   language: "Arabic (State of Palestine)",
  //   code: "ar-PS",
  // },
  // {
  //   id: "212",
  //   language: "Arabic (Qatar)",
  //   code: "ar-QA",
  // },
  // {
  //   id: "213",
  //   language: "Arabic (Lebanon)",
  //   code: "ar-LB",
  // },
  // {
  //   id: "214",
  //   language: "Arabic (Egypt)",
  //   code: "ar-EG",
  // },
  // {
  //   id: "215",
  //   language: "Persian (Iran)",
  //   code: "fa-IR",
  // },
  // {
  //   id: "216",
  //   language: "Hindi (India)",
  //   code: "hi-IN",
  // },
  // {
  //   id: "217",
  //   language: "Thai (Thailand)",
  //   code: "th-TH",
  // },
  {
    id: "218",
    language: "Korean (South Korea)",
    code: "ko-KR",
  },
  // {
  //   id: "219",
  //   language: "Chinese, Mandarin (Traditional, Taiwan)",
  //   code: "zh-TW",
  // },
  // {
  //   id: "220",
  //   language: "Chinese, Cantonese (Traditional, Hong Kong)",
  //   code: "yue-Hant-HK",
  // },
  // {
  //   id: "221",
  //   language: "Japanese (Japan)",
  //   code: "ja-JP",
  // },
  // {
  //   id: "222",
  //   language: "Chinese, Mandarin (Simplified, Hong Kong)",
  //   code: "zh-HK",
  // },
  {
    id: "223",
    language: "Chinese, Mandarin (Simplified, China)",
    code: "zh",
  },
];

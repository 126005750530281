import errorImage from "../../assets/images/error/Mask group.png"

const ErrorComponent = () => {
    return <div style={{
        width: "100%",
        height: "500px",
        background: "white",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        font: "normal 600 24px/30px Inter",
        color: "#0F172A",
        gap: "8px"
    }}><img src={errorImage} style={{ width: "150px", height: "150px" }} />
        Woaah!
        <div style={{ color: "#64748B", width: "322px", font: "normal 500 16px/20px Inter", textAlign: "center" }}>
            The page you were looking for has been removed by the community, or it probably went for a tech-vacation.</div>
    </div>
}

export default ErrorComponent;
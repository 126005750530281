import {
  InfoCircleFilled,
  SearchOutlined,
  SecurityScanTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Row, Skeleton, Tabs } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import styled from "styled-components";
import customAxios from "../../../api";
import { DaoContext, useDaoContext } from "../../../layouts/app/DaoContext";
import { UserContext } from "../../../layouts/app/UserContext";
import CommentSection from "../../CommentSection";
import EmptyComponent from "../../Common/EmptyComponent";
import CreateEventsModal from "../../Events/CreateEventsModal";
import IconWrapper from "../../IconWrapper";
import EventFeedCard from "../EventFeedCard";
import no_events from "./../../../assets/images/events/eventsFeedEmpty.svg";
import { ReactComponent as SyncIcon } from "./../../../assets/iconSvg/sync.svg";
import { toast } from "react-toastify";
import useWindowSize from "../../../hooks/useWindowSize";
import { useLocalStorage } from "@solana/wallet-adapter-react";
import { Modal } from "../../../styles/ModalStyles";
import DiscordSyncModal from "../../DiscordSyncModal";
import { getCurrentPath } from "../../../utils";
const StyledInput = styled(Input)`
  .ant-input {
    font-weight: 500;
  }
`;

function EventsFeed() {
  const { isMobile } = useWindowSize();
  const { isAdmin } = useContext(DaoContext);
  const location = useLocation();
  const { daoDetails, daoId, daoName } = useDaoContext();
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("UPCOMING");
  const [eventListLoading, setEventListLoading] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [query, setQuery] = useState("");
  const [syncLoading, setSyncLoading] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);

  const isDiscordConnected = !!daoDetails.discordGuildId;
  const reloadEventList = () => {
    fetchEventsFeed();
  };
  useEffect(() => {
    if (location?.state?.openCreateModal) {
      setCreateModalOpen(true);
    }
    if (location?.state?.syncWithDiscord) {
      customAxios
        .get(`/affair/sync/discord/affair?daoId=${daoId}`)
        .then((res) => {
          toast.success("Sync successful");
        })
        .catch((err) => {
          toast.error("Couldn't sync with discord");
        });
    }
  }, [location.state]);
  const fetchEventsFeed = () => {
    customAxios
      .get(`/affair/fetch/feed?daoId=${daoId}&type=${selectedTab}`)
      .then((res) => {
        setEventListLoading(false);

        setEventsList(res.data.res);
      })
      .catch((err) => {
        setEventListLoading(false);

        console.log("err");
      });
  };

  useEffect(() => {
    if (!daoId) return;
    setEventListLoading(true);
    fetchEventsFeed();
  }, [selectedTab, daoId]);
  return (
    <div>
      {syncModalOpen ? (
        <DiscordSyncModal
          daoId={daoId}
          discordConnectionUrl={`https://discord.com/api/oauth2/authorize?client_id=${
            process.env.REACT_APP_ENDPOINT === "development"
              ? "1046753505026785401"
              : "1014817083139301417"
          }&redirect_uri=${
            encodeURIComponent(window.location.origin) +
            "/app/redirect/discordIntgeration"
          }&response_type=code&state=${btoa(
            JSON.stringify({
              daoId,
              from: getCurrentPath(),
              syncWithDiscord: true,
            })
          )}&from=${btoa("onboarding")}&scope=identify%20guilds`}
          onSuccess={() => {
            fetchEventsFeed();

            toast.success("Sync successful ");
            setSyncModalOpen(false);
          }}
          syncModalOpen={syncModalOpen}
          onClose={() => setSyncModalOpen(false)}
        />
      ) : null}
      {createModalOpen ? (
        <CreateEventsModal
          isOpen={createModalOpen}
          setIsOpen={setCreateModalOpen}
          onSuccess={reloadEventList}
        />
      ) : null}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
          // marginTop: isMobile ? "1rem" : "0px",
          flexFlow: isMobile ? "wrap" : "initial",
          gap: "8px",
        }}
      >
        <StyledInput
          placeholder="Search Events"
          className="search-bounties"
          value={query}
          allowClear
          onChange={(e) => setQuery(e.target.value)}
          prefix={
            <SearchOutlined
              style={{ color: "#94A3B8" }}
              className="site-form-item-icon"
            />
          }
          style={{
            padding: "8px 20px",
            borderRadius: "12px",
            borderColor: "#E2E8F0",
          }}
        />
        {isAdmin ? (
          <Button
            type="text"
            style={{
              color: "#3B82F6",
              borderRadius: "12px",
              fontWeight: "600",
              background: syncLoading ? "#f3f3f3" : "#DBEAFE",
            }}
            icon={
              <IconWrapper>
                <SyncIcon />{" "}
              </IconWrapper>
            }
            onClick={() => setSyncModalOpen(true)}
          >
            Sync Discord
          </Button>
        ) : null}
        {isAdmin ? (
          <Button
            type="primary"
            className="create-bounty-button"
            style={{
              padding: "16px 16px",
              borderRadius: "12px",
              background: "#3B82F6",
              fontWeight: 600,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            Create New
          </Button>
        ) : null}
      </div>
      <Tabs
        onChange={(key) => setSelectedTab(key)}
        activeKey={selectedTab}
        style={{ width: "100%" }}
      >
        <Tabs.TabPane tab={"Upcoming"} key="UPCOMING"></Tabs.TabPane>
        <Tabs.TabPane tab={"Past"} key="PAST"></Tabs.TabPane>
      </Tabs>
      {!eventListLoading ? (
        eventsList.length ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {eventsList
              ?.filter((ele) =>
                ele?.title?.toLowerCase().includes(query.toLowerCase())
              )
              .map((event, idx) => (
                <>
                  {!idx ||
                  moment(event.eventAt).diff(
                    moment(eventsList[idx - 1].eventAt),
                    "days"
                  ) !== 0 ? (
                    <div>
                      {idx ? (
                        <div
                          style={{
                            borderTop: "1px solid #64748B",
                            marginTop: "12px",
                            opacity: "0.2",
                          }}
                        ></div>
                      ) : null}
                      <div style={{ margin: "10px 0px 20px 0px" }}>
                        <span style={{ fontWeight: "600", fontSize: "16px" }}>
                          {moment(event.eventAt).format("D MMM ")}
                        </span>
                        <span style={{ fontSize: "14px", color: "#64748B" }}>
                          {moment(event.eventAt).format("dddd")}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <Row style={{ rowGap: "12px" }}>
                    {/* <Col md={2} lg={3}>
                      <h5
                        style={{
                          fontWeight: "500",
                          marginBottom: "0px",
                          paddingTop: "16px",
                        }}
                      >
                        {moment(event.eventAt).format("hh:mm A")}
                      </h5>
                    </Col> */}
                    <Col xs={24}>
                      {" "}
                      <EventFeedCard
                        data={{ ...event, daoId, daoName }}
                        reloadEventList={reloadEventList}
                      />
                    </Col>
                  </Row>
                </>
              ))}
          </div>
        ) : (
          <div
            style={{
              background: "white",
              borderRadius: "16px",
            }}
          >
            <EmptyComponent
              text="No events available yet"
              subtext="Be sure to check here again in a while"
              imageSource={no_events}
            />
          </div>
        )
      ) : (
        <Skeleton active />
      )}
    </div>
  );
}

export default EventsFeed;

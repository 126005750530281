import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useNavigate } from "react-router";
import { DaoType } from "../../lib/types/common";
import JoinSpaceModal from "./JoinSpaceModal";

type Props = DaoType & {
  isJoined?: boolean;
  parentDaoName?: string;
};

const SpaceCard = ({ isJoined, parentDaoName, ...props }: Props) => {
  const {
    iconEmoji,
    iconBackground,
    profilePicture,
    daoName,
    subDaoGatingCondition,
    description,
    contributors,
  } = props;
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <button
        onClick={() =>
          isJoined
            ? navigate(
                `/app/dao/${parentDaoName}/${props?.parentDaoId}/spaces/${daoName}/${props?.daoId}`
              )
            : setIsJoinModalOpen(true)
        }
        className="flex flex-col px-4 py-3 justify-between rounded-2xl border border-solid border-gray-200 bg-white gap-2 cursor-pointer hover:shadow overflow-hidden"
      >
        <div className="w-full flex flex-col items-start">
          {" "}
          {!iconEmoji && profilePicture && (
            <img
              src={profilePicture}
              className="w-16 h-16 rounded-full flex items-center justify-center object-cover"
              alt="Profile"
            />
          )}
          {iconEmoji && (
            <div
              style={{ background: iconBackground }}
              className="text-3xl w-16 h-16 rounded-full flex items-center justify-center"
            >
              {iconEmoji}
            </div>
          )}
          <h4 className="text-base m-0 max-w-full text-ellipsis whitespace-nowrap overflow-hidden">
            {daoName}
          </h4>
          <p className="rich-text annoucement-card-overview-page text-sm text-left overflow-hidden m-0 space-details mb-4">
            {ReactHtmlParser(description)}
          </p>
        </div>
        <div className="flex gap-1">
          <div className="bg-gray-100 rounded-md px-2 py-1 font-semibold text-gray-500 text-xs">
            👥: {contributors}
          </div>
          {subDaoGatingCondition && (
            <div className="bg-gray-100 rounded-md px-2 py-1 font-semibold text-gray-500 text-xs">
              🔒 {subDaoGatingCondition?.conditions?.length} condition
              {subDaoGatingCondition?.conditions?.length !== 1 && "s"}
            </div>
          )}
        </div>
      </button>
      <JoinSpaceModal
        isOpen={isJoinModalOpen}
        setIsOpen={setIsJoinModalOpen}
        daoDetails={props}
      />
    </>
  );
};

export default SpaceCard;

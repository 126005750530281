import React, { useContext } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { DaoContext } from "../../layouts/app/DaoContext";
import { syncDiscordRoles } from "../../utils/common";

type Props = {
  postRoleSyncCallback: () => any;
};

const SyncDiscordRoles = ({ postRoleSyncCallback }: Props) => {
  const { daoDetails, daoId } = useContext(DaoContext);
  const { mutate: synRoles, isLoading } = useMutation(["sync-roles"], {
    mutationFn: () =>
      syncDiscordRoles(daoId as any, (daoDetails as any).discordGuildId, () =>
        postRoleSyncCallback?.()
      ),
    onError: (error) => {
      toast.error("Something went wrong while syncing roles.");
      console.error(error);
    },
  });
  return (
    <button
      onClick={() => synRoles()}
      style={{
        fontSize: "12px",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "4px",
        background: "none",
        border: "none",
        cursor: "pointer",
      }}
    >
      <svg
        style={{
          marginRight: "4px",
          animation: isLoading
            ? "anti-clock-wise-rotation 2s infinite linear"
            : "",
        }}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66675 2.66654V5.99987H3.05475M13.2921 7.3332C13.1446 6.16178 12.6127 5.07238 11.7797 4.23572C10.9466 3.39907 9.85951 2.86245 8.68873 2.70996C7.51795 2.55748 6.32967 2.79775 5.3101 3.39311C4.29054 3.98848 3.49731 4.90529 3.05475 5.99987M3.05475 5.99987H6.00008M13.3334 13.3332V9.99987H12.9461M12.9461 9.99987C12.5029 11.0938 11.7094 12.0099 10.69 12.6047C9.67051 13.1996 8.48257 13.4395 7.31215 13.2871C6.14172 13.1347 5.05488 12.5985 4.22177 11.7624C3.38866 10.9263 2.85632 9.8375 2.70808 8.66654M12.9461 9.99987H10.0001"
          stroke="#64748B"
          stroke-width="1.33333"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      {isLoading ? "Syncing" : "Sync roles"}
    </button>
  );
};

export default SyncDiscordRoles;

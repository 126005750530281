import customAxios from "../../api";

export type CreateAccountDataType = {
  name: string;
  email: string;
  username: string;
  password: string;
  reEnterPassword: string;
};

export const validateCreateAccountData = ({
  name,
  password,
  reEnterPassword,
  username,
}: CreateAccountDataType) => {
  const errors: Record<string, string> = {};

  if (!name) {
    errors.name = "Please enter name";
  }

  if (!username) {
    errors.username = "Please enter username";
  }

  if (!password) {
    errors.password = "Please enter password";
  }

  if (!reEnterPassword) {
    errors.reEnterPassword = "Please re-enter password";
  }

  if (password !== reEnterPassword) {
    errors.reEnterPassword = "Your passwords don’t match";
  }
  return errors;
};

export const createDiscourseAccount = async (
  data: CreateAccountDataType & { daoId: string }
) => {
  const res = await customAxios.post("/discourse/createuser", {
    daoId: data.daoId,
    name: data.name,
    email: data.email,
    username: data.username,
    password: data.password,
  });

  return res.data as {
    success: boolean;
    errors: Record<string, string[]>;
    status: string;
  };
};

export const checkIsDiscourseModalShown = (daoDetails, userDetails) => {
  if (
    daoDetails?.discourseState !== "IMPORTED" ||
    !daoDetails?.isDiscourseSyncEnabled
  )
    return false;
  const doesDiscourseUserExist = userDetails?.daoJoined?.find(
    (dao) => dao.dao_id === daoDetails?.daoId
  )?.doesDiscourseUserExist;
  console.log({ doesDiscourseUserExist });
  return !doesDiscourseUserExist;
};

import styled from "styled-components";

export const PopupCard = styled.div`
  display: inline-block;
  position: relative;
  text-align: left;
  & .ant-card-body {
    padding: ${(props) => props.cardPadding || "10px 0px"};
  }
  & .card {
    width: ${(props) => props.width || "184px"};
    position: absolute;

    bottom: ${(props) => props.bottom || "38px"};
    top: ${(props) => props.top || "inherit"};
    margin: ${(props) => props.margin || "0px"};

    transition: 300ms all;
    z-index: 11000;
    border-radius: 1rem;
    box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
  }
`;

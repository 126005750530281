import React, { Dispatch, SetStateAction, useState } from "react";
import { StyledModalWrapper } from "../../../CreateBountyModal";
import { Modal } from "../../../../styles/ModalStyles";
import useWindowSize from "../../../../hooks/useWindowSize";
import styled from "styled-components";
import { Button, Dropdown, Input, Menu, MenuProps, Tooltip } from "antd";
import Upload from "../../../Common/form/Upload";
import {
  AnnouncementType,
  createAnnouncement,
  ExpiryDurationEnum,
  updateAnnouncement,
} from "../../../../utils/Announcements";
import { checkFileSize, checkImageType, postMixpanel } from "../../../../utils";
import QuillEditor from "../../../QuillEditor";
import { CheckIcon } from "@heroicons/react/24/solid";
import { RectangleStackIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import LinkEntities from "../../../Common/LinkEntities";

type MenuItem = Required<MenuProps>["items"][number];

type GetItemType = {
  label: React.ReactNode;
  key?: React.Key | null;
  icon?: React.ReactNode;
  children?: MenuItem[];
  type?: "group";
  activeDuration?: ExpiryDurationEnum;
};

function getItem({
  label,
  key,
  icon,
  children,
  type,
  activeDuration,
}: GetItemType): MenuItem {
  const isSelected = activeDuration === key;
  return {
    key,
    icon: null,
    children,
    label,
    type,
    style: {
      borderRadius: "8px",
      margin: "4px 8px",
      display: "flex",
      alignItems: "center",
      background: isSelected ? "#EFF6FF" : "",
      fontWeight: isSelected ? 500 : 400,
    },
  } as MenuItem;
}

const StyledModalTitleInput = styled(Input)`
  font-size: 20px;
  background-color: white;
  border-radius: 16px;
  padding: 12px 20px;
  &::placeholder {
    font-weight: 600;
    color: #94a3b8;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-dropdown-menu-submenu-title {
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
`;

const DURATION_OBJECTS: { value: ExpiryDurationEnum; label: string }[] = [
  { label: "1 day", value: ExpiryDurationEnum.ONE_DAY },
  { label: "7 days", value: ExpiryDurationEnum.SEVEN_DAYS },
  { label: "30 days", value: ExpiryDurationEnum.THIRTY_DAYS },
  { label: "90 days", value: ExpiryDurationEnum.NINTY_DAYS },
];

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEdit?: boolean;
  data?: AnnouncementType;
};

const CreateAnnouncementModal = ({
  isOpen,
  setIsOpen,
  isEdit = false,
  data,
}: Props) => {
  const { daoId } = useDaoContext();

  const { mutate: createNewAnnouncement, isLoading: isCreateLoading } =
    useMutation({
      mutationFn: (data: AnnouncementType) =>
        createAnnouncement(data, daoId || ""),
      onError: (error) => {
        console.error(error);
        toast.error("Something went wrong while creating announcement.");
      },
      onSuccess: () => {
        window.location.reload();
      },
    });

  const { mutate: editAnnouncement, isLoading: isEditLoading } = useMutation({
    mutationFn: (newData: Partial<AnnouncementType>) =>
      updateAnnouncement(data?.id || "", newData),

    onError: (error) => {
      console.error(error);
      toast.error("Something went wrong while updating announcement.");
    },
    onSuccess: () => {
      window.location.reload();
    },
  });

  const isLoading = isCreateLoading || isEditLoading;

  const { isMobile } = useWindowSize();
  const [title, setTitle] = useState(isEdit ? data?.title : "");
  const [description, setDescription] = useState(
    isEdit ? data?.description : ""
  );
  const [expiry, setExpiry] = useState<ExpiryDurationEnum>(
    ExpiryDurationEnum.SEVEN_DAYS
  );
  const [isUploadCompUpdated, setIsUploadCompUpdated] = useState(false);
  const [coverImage, setCoverImage] = useState(data?.icon ? data?.icon : null);
  const [linkedItems, setLinkedItems] = useState([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const menuItems = DURATION_OBJECTS.map((obj) =>
    getItem({
      label: (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {obj.label}
          {expiry === obj.value && (
            <CheckIcon
              width={12}
              style={{ color: "#3B82F6" }}
              strokeWidth={2}
            />
          )}
        </div>
      ),
      activeDuration: expiry,
      key: obj.value,
    })
  );

  const handleExpiryDurationClick: MenuProps["onClick"] = (e) => {
    if (e.key in ExpiryDurationEnum) {
      setExpiry(parseInt(e.key) as ExpiryDurationEnum);
      return;
    }
  };

  const handleSubmit = () => {
    if (!title) {
      toast.error("Please add title");
      return;
    }

    if (!description) {
      toast.error("Please add description");
      return;
    }
    postMixpanel(
      "track",
      isEdit ? "clicked_edit_announcement" : "clicked_publish_announcement",
      {
        dao_id: daoId,
      }
    );
    return isEdit
      ? editAnnouncement({ description, title, icon: coverImage })
      : createNewAnnouncement({
          description: description || "",
          expiry,
          title: title || "",
          icon: coverImage,
          linkedItems,
        });
  };

  return (
    <StyledModalWrapper>
      <Modal
        visible={isOpen}
        destroyOnClose
        // @ts-expect-error
        isMobile={isMobile}
        centered={true}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.5",
          backdropFilter: "blur(20px)",
        }}
        width="700px"
        bodyStyle={{ height: "80vh" }}
        onCancel={() => setIsOpen(false)}
        footer={
          <div className="flex justify-between items-center gap-3">
            {!isEdit && (
              <Dropdown
                overlay={
                  <StyledMenu
                    style={{
                      borderRadius: "8px",
                    }}
                    mode="vertical"
                    items={menuItems}
                    onClick={handleExpiryDurationClick}
                  />
                }
                trigger={["click"]}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    background: "#F3F4F6",
                    padding: "12px 16px",
                    borderRadius: "12px",
                    cursor: "pointer",
                    color: "#6B7280",
                    fontWeight: 500,
                  }}
                  className="whitespace-nowrap text-xs md:text-sm"
                >
                  <ClockIcon width={16} />
                  <p style={{ margin: 0 }}>
                    <span className="hidden md:inline-block mr-1">
                      Expire in:
                    </span>
                    <span style={{ color: "#000000" }}>
                      {
                        DURATION_OBJECTS.find((obj) => obj.value === expiry)
                          ?.label
                      }
                    </span>
                  </p>
                  <ChevronDownIcon width={12} strokeWidth={2} />
                </div>
              </Dropdown>
            )}
            {!isEdit && (
              <>
                <LinkEntities
                  isModalVisible={linkModalVisible}
                  handleClose={() => {
                    setLinkModalVisible(false);
                  }}
                  linkedItems={linkedItems}
                  setLinkedItems={setLinkedItems}
                ></LinkEntities>
                <Tooltip title="Link the discussions, bounties or any entity that led to this project.">
                  <Button
                    className="borderless-button"
                    onClick={() => {
                      setLinkModalVisible(true);
                    }}
                    shape="circle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "100px",
                      width: "auto",
                      padding: "0 12px",
                    }}
                  >
                    <RectangleStackIcon className="w-5" />
                    <span style={{ color: "#64748B", fontSize: "12px" }}>
                      {linkedItems.length ? `(${linkedItems.length})` : null}
                    </span>
                  </Button>
                </Tooltip>
              </>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
              }}
            >
              <Button
                onClick={handleSubmit}
                type="primary"
                style={{
                  borderRadius: "12px",
                  fontSize: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontWeight: 500,
                  height: "38px",
                  width: "83px",
                }}
                disabled={isLoading}
              >
                {isEdit ? "Update" : "Publish"}
              </Button>
            </div>
          </div>
        }
      >
        <StyledModalTitleInput
          style={{
            fontSize: "21px",
            fontWeight: "600",
          }}
          placeholder="Add title of the announcement"
          size="large"
          bordered={false}
          autoFocus={true}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 35px 24px 20px",
            width: "100%",
            gap: "24px",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Upload
              setIsUpdated={setIsUploadCompUpdated}
              label={""}
              suggestedSize="1920 * 1080"
              isUpdated={isUploadCompUpdated}
              data={coverImage}
              onChange={(newFile: any) => {
                checkImageType(newFile.type);
                checkFileSize(newFile.size);
                setCoverImage(newFile);
              }}
              width={"100%"}
              title={"Add Cover image"}
              height={!coverImage ? "84px" : "200px"}
              isInfoHidden
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <label
              style={{ fontSize: "12px", fontWeight: "600", color: "#64748B" }}
            >
              Description
            </label>
            <QuillEditor
              height={coverImage ? "180px" : "310px"}
              value={description}
              style={{ marginLeft: "-4px" }}
              isBordered
              onChange={(e: string) => setDescription(e)}
              placeholder="Tell it out to you community..."
              isDynamicHeight
            />
          </div>
        </div>
      </Modal>
    </StyledModalWrapper>
  );
};

export default CreateAnnouncementModal;

import {
  CheckCircleFilled,
  ClockCircleOutlined,
  ShareAltOutlined,
  UpSquareOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  ArrowUpLeftIcon,
  BellIcon,
  CalendarIcon,
  EllipsisVerticalIcon,
  MapPinIcon,
  PlusIcon,
  VideoCameraIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/solid";
import { Button, Col, Dropdown, Menu, Row, Skeleton, Tabs } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import customAxios from "../../../api";
import { useTrackPageView } from "../../../hooks/useTrackPageView";
import useWindowSize from "../../../hooks/useWindowSize";
import { DaoContext, useDaoContext } from "../../../layouts/app/DaoContext";
import { postMixpanel } from "../../../utils";
import { togglePinEvents } from "../../../utils/pin";
import Share from "../../BountyComponents/Share";
import CommentSection from "../../CommentsComponents/CommentSection";
import DeleteEntityModal from "../../Common/DeleteEntityModal";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { StyledMenu } from "../../Course/CourseCard";
import CreateEventsModal from "../../Events/CreateEventsModal";
import { PinIcon } from "../../Icones";
import IconWrapper from "../../IconWrapper";
import GoBackButton from "../../InternalComponents/GoBackButton";
import { StyledDotIconContainer } from "../../PostDetails";
import SlidingContextSidebar from "../../SlidingContextSidebar";
import ReactHtmlParser from "react-html-parser";
import defaultUser from "./../../../assets/images/login/defaultUser.jpeg";
import { getLocationSubstring } from "../EventFeedCard";
import { UserContext } from "../../../layouts/app/UserContext";
import { DEFAULT_SORTING_TYPE_LIST } from "../../../constants";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import ProfileCardPopup from "../../Common/ProfileCard";

function EventDetailsPage() {
  const { eventId } = useParams();
  const { daoDetails, daoId } = useDaoContext();

  const navigate = useDaoLensNavigate();
  const { isAdmin, isCurrentDaoJoined, setShowJoinCommunityModal } =
    useContext(DaoContext);
  const { showLoginModal, userDetails } = useContext(UserContext);

  const { isMobile } = useWindowSize();
  const [sortBy, setSortBy] = useState("Latest");

  const [loading, setLoading] = useState(false);
  const [interestedUserList, setInteresedUserList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("2");
  const [eventDetails, setEventDetails] = useState(null);
  const [eventLoading, setEventLoading] = useState(false);
  const [comments, setComments] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [updater, setUpdater] = useState(false);
  useTrackPageView();
  const menu = (
    <StyledMenu>
      <Menu.Item onClick={() => setIsEditModalOpen(true)}>
        <PencilIcon
          fill="#6B7280"
          style={{ width: "14px", transform: "translateY(2px)" }}
        />{" "}
        Edit
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => {
          togglePinEvents(daoId, eventId, !eventDetails?.pinned);
        }}
      >
        {eventDetails?.pinned ? "Unpin" : "Pin"}
      </Menu.Item> */}
      <Menu.Item
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <TrashIcon
          fill="#6B7280"
          style={{ width: "14px", transform: "translateY(2px)" }}
        />{" "}
        Delete
      </Menu.Item>
    </StyledMenu>
  );
  const handleAddEventToCalendar = (data) => {
    if (!userDetails) {
      showLoginModal();
      return;
    }
    if (!isCurrentDaoJoined) {
      setShowJoinCommunityModal(true);
      return;
    }
    postMixpanel("track", "clicked_on_add_to_calendar", {
      dao_id: daoId,
      events_id: data.id,
      event_name: data.name,
      event_type: data.type,
    });
    const url = `http://www.google.com/calendar/render?action=TEMPLATE&text=${
      data.title
    }&dates=${data.eventAt}/${data.eventEndAt}&details=${
      data.description + (data?.link ? ` Link: ${data.link}` : "")
    }&trp=false`;

    window.open(url);
  };
  const handleMarkInterested = (eventId) => {
    if (!userDetails) {
      showLoginModal();
      return;
    }
    if (!isCurrentDaoJoined) {
      setShowJoinCommunityModal(true);
      return;
    }
    setLoading(true);
    customAxios
      .post(`/affair/mark/interested`, { eventsId: eventId })
      .then((res) => {
        if (res.data.success) {
          postMixpanel("track", "clicked_on_interested", {
            dao_id: daoId,
            events_id: eventDetails.id,
            event_name: eventDetails.name,
            event_type: eventDetails.type,
          });
        }
        fetchEventDetails(eventId);
        fetchInterestedUsers(eventId);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const fetchInterestedUsers = (eventId) => {
    customAxios
      .get(`/affair/fetch/details/interested?eventsId=${eventId}`)
      .then((res) => {
        setInteresedUserList(res.data.res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeleteEvent = (eventId) => {
    if (!userDetails) {
      showLoginModal();
      return;
    }
    if (!isCurrentDaoJoined) {
      setShowJoinCommunityModal(true);
      return;
    }
    customAxios
      .delete(`/affair/delete?eventsId=${eventId}`)
      .then((res) => {
        toast.success("Event deleted successfully");
        setShowDeleteModal(false);
        navigate(-1);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const fetchComments = () => {
    customAxios
      .post("/affair/comments/fetch", {
        eventsId: eventId,
        parentId: "0",
        sortBy: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortBy,
        sortOrder: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortOrder,
      })
      .then((res) => {
        let transformAllComments = res.data.eventComments.map(
          (singleComment) => {
            let obj = singleComment.vote.find(
              (ele) => ele.commentId === singleComment.id
            );
            if (obj !== undefined)
              return {
                ...singleComment,
                engagementStatus: obj.type.toLowerCase(),
              };
            else return { ...singleComment, engagementStatus: "" };
          }
        );
        setComments(transformAllComments.slice());
      })
      .catch((err) => console.log(err));
  };
  const postComment = async (
    commentText,
    mentions,
    parentId,
    level2ParentId = "0"
  ) => {
    var postBody = {
      daoId: daoId,
      eventsId: eventId,
      parentId,
      description: commentText,
      userTagged: mentions,
      level2ParentId,
    };

    if (mentions?.length > 0) postBody.userTagged = mentions;
    await customAxios.post("/affair/comments/create", postBody);
    postMixpanel("track", "comment_on_event", {
      event_id: eventId,
      parent_comment_id: parentId,
      dao_id: daoId,
    });
    fetchComments();
  };
  const fetchEventDetails = (eventId) => {
    setEventLoading(true);
    customAxios
      .get(`/affair/fetch/details?eventsId=${eventId}`)
      .then((res) => {
        setEventLoading(false);

        setEventDetails(res.data.res[0]);
      })
      .catch((err) => {
        setEventLoading(false);

        console.log(err);
      });
  };
  useEffect(() => {
    fetchEventDetails(eventId);
    fetchInterestedUsers(eventId);
  }, [eventId, updater]);
  console.log(eventDetails, "lk");
  return (
    <div style={{ paddingLeft: isMobile ? "15px" : "0px" }}>
      <DeleteEntityModal
        note="This event will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          handleDeleteEvent(eventDetails.id);
        }}
      />
      {eventDetails && (
        <CreateEventsModal
          isOpen={isEditModalOpen}
          setIsOpen={setIsEditModalOpen}
          onSuccess={() => setUpdater((prev) => !prev)}
          isEdit
          eventDetail={eventDetails}
        />
      )}
      <div style={{ height: 20 }} />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <GoBackButton
          showIcon
          buttonText={"Go Back"}
          clickHandler={() => {
            navigate(-1);
          }}
        />
        {/* {eventDetails?.pinned && <PinIcon />} */}
        {isAdmin ? (
          <Dropdown overlay={menu} trigger={["click"]}>
            <StyledDotIconContainer>
              <EllipsisVerticalIcon
                width={"20px"}
                style={{ cursor: "pointer" }}
              />
            </StyledDotIconContainer>
          </Dropdown>
        ) : null}
      </div>
      {!eventLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "white",
            rowGap: "20px",
            marginTop: "12px",
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          <div
            style={{
              width: isMobile ? "300px" : "560px",
              // border: "1px solid #f3f3f3",
              background: "#f9fafb",
              borderRadius: "12px",
              padding: "12px 8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={eventDetails?.icon}
              style={{
                width: isMobile ? "100%" : "360px",
                objectFit: "cover",
                borderRadius: "12px",
              }}
            />
          </div>
          <Row style={{ alignItems: "center", columnGap: "12px" }}>
            <Col
              md={2}
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "4px",
                padding: "10px 0px 6px 0px",
                width: "64px",
                borderRadius: "12px",
                justifyContent: "center",
                alignItems: "center",
                background: "#F6F5FF",
              }}
            >
              <h4 style={{ marginBottom: "-4px", fontWeight: "600" }}>
                {" "}
                {moment(eventDetails?.eventAt).format("MMM").toUpperCase()}
              </h4>
              <h2
                style={{
                  color: "#7E3AF2",
                  marginBottom: "0px",
                  fontWeight: "600",
                }}
              >
                {" "}
                {moment(eventDetails?.eventAt).format("D")}
              </h2>
            </Col>
            <Col md={20}>
              <h2 style={{ marginBottom: "5px" }}>{eventDetails?.title}</h2>

              <div
                style={{
                  display: "flex",
                  columnGap: "4px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                  }}
                >
                  <h5
                    style={{
                      marginBottom: "0px",
                      color: "#64748B",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {moment().isBetween(
                      eventDetails?.eventAt,
                      eventDetails?.eventEndAt
                    ) ? null : (
                      <IconWrapper
                        style={{ color: "#64748B", marginRight: "7px" }}
                      >
                        {" "}
                        <ClockCircleOutlined />
                      </IconWrapper>
                    )}
                    {moment().isBetween(
                      eventDetails?.eventAt,
                      eventDetails?.eventEndAt
                    ) ? (
                      <div
                        style={{
                          display: "inline-flex",
                          columnGap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            background: "#0E9F6E",
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <span style={{ color: "#0E9F6E" }}>Live Now</span>
                      </div>
                    ) : moment(moment(eventDetails?.eventAt)).diff(
                        moment.now(),
                        "minutes"
                      ) <= 15 ? (
                      moment(eventDetails?.eventAt).fromNow()
                    ) : (
                      moment(eventDetails?.eventAt).format("DD MMM, hh:mm A")
                    )}{" "}
                    <div style={{ width: "8px" }}></div>|{" "}
                    {eventDetails?.type === "ONLINE" ? (
                      <div
                        style={{
                          columnGap: "2px",
                          display: "inline-flex",
                          alignItems: "center",
                          marginLeft: "8px",
                        }}
                      >
                        <IconWrapper>
                          <VideoCameraOutlined />
                        </IconWrapper>
                        Online
                      </div>
                    ) : (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(eventDetails.link, "_blank");
                        }}
                        style={{
                          color: "#3B82F6",
                          columnGap: "2px",
                          cursor: "pointer",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                      >
                        <IconWrapper>
                          <MapPinIcon />
                        </IconWrapper>
                        {eventDetails?.location
                          ? getLocationSubstring(eventDetails?.location)
                          : "Offline"}
                      </div>
                    )}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
          <Row
            style={{
              background: "#F3F4F6",
              alignItems: "center",
              padding: "8px",
              borderRadius: "12px",
            }}
          >
            <h5
              style={{
                marginRight: "12px",
                marginBottom: "0px",
                color: "#64748B",
              }}
            >
              {" "}
              Hosted by {""}
            </h5>
            <div style={{ display: "flex", columnGap: "8px", rowGap: "8px" }}>
              {eventDetails?.hostDetails?.map((host, idx) => (
                <ProfileCardPopup userId={host?.userId} key={host.userId}>
                  <div
                    onClick={() => navigate(`/app/profile/${host.userId}`)}
                    style={{
                      display: "flex",
                      columnGap: "4px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    <img
                      src={
                        host.profilePicture ? host.profilePicture : defaultUser
                      }
                      style={{
                        width: "16px",
                        height: "16px",
                        objectFit: "contain",
                        borderRadius: "50%",
                      }}
                    />
                    <h5 style={{ marginBottom: "0px", color: "#64748B" }}>
                      {host.profileName}
                      {eventDetails?.hostDetails?.length !== idx + 1
                        ? "   |"
                        : null}
                    </h5>
                  </div>
                </ProfileCardPopup>
              ))}
            </div>
          </Row>
          <Row>
            <Col xs={24}>
              <h5 style={{ color: "#64748B" }}>Event Details</h5>
            </Col>
            <Col xs={24}>
              <h4>{ReactHtmlParser(eventDetails?.description)}</h4>
            </Col>
          </Row>
          {eventDetails?.isInterested &&
          (moment().isBetween(
            eventDetails?.eventAt,
            eventDetails?.eventEndAt
          ) ||
            moment(eventDetails?.eventAt).diff(moment.now(), "minute") <=
              15) ? (
            <Row
              style={{
                width: "100%",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {eventDetails?.type === "ONLINE" ? (
                <Button
                  type="primary"
                  style={{ borderRadius: "12px", height: "44px" }}
                  icon={
                    <IconWrapper>
                      <VideoCameraIcon />
                    </IconWrapper>
                  }
                  onClick={() => {
                    postMixpanel("track", "clicked_on_join_now", {
                      dao_id: daoId,
                      events_id: eventDetails.id,
                      event_name: eventDetails.name,
                      event_type: eventDetails.type,
                    });
                    window.open(
                      eventDetails.link.includes("https://")
                        ? eventDetails.link
                        : "https://" + eventDetails.link,
                      "_blank"
                    );
                  }}
                >
                  Join Now
                </Button>
              ) : (
                <Button
                  type="primary"
                  icon={
                    <IconWrapper>
                      <ArrowRightCircleIcon
                        color="#ffffff"
                        style={{ transform: "translateY(1px)" }}
                      />
                    </IconWrapper>
                  }
                  style={{
                    borderRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    postMixpanel("track", "clicked_on_start_navigation", {
                      dao_id: daoId,
                      events_id: eventDetails.id,
                      event_name: eventDetails.name,
                      event_type: eventDetails.type,
                    });
                    window.open(eventDetails.link, "_blank");
                  }}
                >
                  Start navigation
                </Button>
              )}
            </Row>
          ) : (
            <Row
              style={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Col>
                {eventDetails?.totalInterested?.length ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "4px",
                    }}
                  >
                    <img
                      src={
                        eventDetails?.totalInterested[0]?.profilePicture
                          ? eventDetails?.totalInterested[0]?.profilePicture
                          : defaultUser
                      }
                      style={{
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                    <h5 style={{ color: "#64748B", marginBottom: "0px" }}>
                      {eventDetails?.totalInterested?.length} interested
                    </h5>
                  </div>
                ) : null}
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "8px",
                }}
              >
                {eventDetails?.isInterested ? (
                  <div
                    style={{
                      display: "flex",
                      columnGap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <IconWrapper style={{ color: "#059669" }}>
                      <CheckCircleFilled />
                    </IconWrapper>{" "}
                    <span style={{ color: "#6B7280", fontSize: "12px" }}>
                      Going
                    </span>
                  </div>
                ) : null}
                {loading ? (
                  <LoadingSpinner />
                ) : eventDetails?.isInterested ? (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddEventToCalendar(eventDetails);
                    }}
                    icon={
                      <IconWrapper>
                        <PlusIcon />
                      </IconWrapper>
                    }
                    style={{ borderRadius: "12px", color: "#6B7280" }}
                    type="default"
                  >
                    Add to calendar
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMarkInterested(eventDetails?.id);
                    }}
                    icon={
                      <IconWrapper>
                        <BellIcon />
                      </IconWrapper>
                    }
                    style={{ borderRadius: "12px", color: "#6B7280" }}
                    type="default"
                  >
                    Interested
                  </Button>
                )}
              </Col>
            </Row>
          )}
          <Row>
            <Tabs
              onChange={(key) => setSelectedTab(key)}
              activeKey={selectedTab}
              style={{ width: "100%" }}
              tabBarExtraContent={{
                right: !isMobile && (
                  <div
                    style={{
                      display: "flex",
                      columnGap: "8px",
                      justifyContent: "flex-end",
                      marginBottom: "10px",
                    }}
                  >
                    <Share entity="event" />
                    {/* <SlidingContextSidebar
                    entityId={bountyId}
                    entityType="EVENT"
                    canEdit={canCreateBounty}
                  /> */}
                  </div>
                ),
              }}
            >
              {interestedUserList.length ? (
                <Tabs.TabPane tab={"Interested"} key="1">
                  <div
                    style={{
                      display: "flex",
                      rowGap: "8px",
                      flexDirection: "column",
                    }}
                  >
                    {interestedUserList.map((ele) => {
                      return (
                        <ProfileCardPopup userId={ele.userId}>
                          <div
                            onClick={() =>
                              navigate(`/app/profile/${ele.userId}`)
                            }
                            style={{
                              columnGap: "8px",
                              display: "flex",
                              alignItems: "center",
                              padding: "8px 12px",
                              border: "1px solid #f3f3f3",
                              borderRadius: "12px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={
                                ele.profilePicture
                                  ? ele.profilePicture
                                  : defaultUser
                              }
                              style={{
                                width: "16px",
                                height: "16px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                            <span>{ele.userName}</span>{" "}
                          </div>
                        </ProfileCardPopup>
                      );
                    })}
                  </div>
                </Tabs.TabPane>
              ) : null}
              <Tabs.TabPane tab={"Discussions"} key="2">
                <CommentSection
                  entity="events/"
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  sortingTypeList={DEFAULT_SORTING_TYPE_LIST}
                  allComments={comments}
                  postComment={postComment}
                  fetchComments={fetchComments}
                  hideBorderTop
                />
              </Tabs.TabPane>
            </Tabs>
          </Row>
        </div>
      ) : (
        <Skeleton style={{ marginTop: "24px" }} active />
      )}
    </div>
  );
}

export default EventDetailsPage;

import { Button, Input, Menu, MenuProps, Select, Skeleton } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import robin from "../../assets/images/gpt/robin.gif";
import robinactive from "../../assets/images/gpt/robin_active.gif";

import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import useGPT from "./useGPT";
import { DaoContext } from "../../layouts/app/DaoContext";
import { ReactComponent as BroomIcon } from "../../assets/images/gpt/broom_.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/gpt/copy.svg";

import ReactMarkdown from "react-markdown";
import markdown, { getCodeString } from "@wcj/markdown-to-html";

import {
  ChatBubbleBottomCenterTextIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  SparklesIcon,
} from "@heroicons/react/24/solid";
import { ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/outline";
import IconWrapper from "../IconWrapper";
import { toast } from "react-toastify";
import { parser, parsePoll } from "./utils";
import Loading from "./Loading";
import useAnimatedPlaceholder from "./useAnimatedPlaceholder";
import ComparePost from "./ComparePost";
import SuggestionPill from "./SuggestionPill";
import usePostGPT from "../../hooks/usePostsGPT";
const { Option } = Select;

const GPTWrapper = styled.div`
  --borderWidth: 0px;
  padding: 2px;
  position: relative;
  z-index: 1;
  :after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(
      74.24deg,
      rgba(244, 124, 194, ${(props) => (props["aria-busy"] ? "0.85" : "0.4")})
        0%,
      rgba(132, 166, 255, ${(props) => (props["aria-busy"] ? "0.85" : "0.4")})
        50.52%,
      rgba(255, 210, 141, ${(props) => (props["aria-busy"] ? "0.85" : "0.4")})
        100%
    );
    border-radius: 12px;
    z-index: -1;
    animation: animatedgradient
      ${(props) => (props["aria-busy"] ? "3s" : "10s")} ease alternate infinite;
    background-size: 300% 300%;
  }
  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const StyledInput = styled(Input)`
  color: #1f2937;
  padding: 0;
  width: 100%;
`;

const StyledDiv = styled.div`
  padding-right: 15px;
  .edit {
    display: none;
  }
  :hover {
    .edit {
      display: block;
    }
  }
`;
interface GPTProps {
  data?: any;
  prompt?: string;
  requestText?: string;
  promptType?: string;
  openInModal?: boolean;
  onSubmit: (e: any) => void;
}
export const GPTButton = styled.button`
  border: none;
  padding: 8px 12px;
  gap: 8px;
  color: white;
  line-height: normal;
  font: normal 500 12px/16px Inter;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  background: linear-gradient(
    96.14deg,
    #ff8db6 1.27%,
    #b579c2 38.55%,
    #897bea 99.33%
  );
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  :disabled {
    opacity: 0.3;
  }
  :hover {
    box-shadow: ${(props) =>
      props.disabled ? "" : "0px 10px 15px -3px rgba(203, 54, 255, 0.25)"};
  }
`;
const RoundedButton = styled.button`
  border: none;
  padding: 4px 6px;
  gap: 8px;
  color: white;
  line-height: normal;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background: linear-gradient(
    96.14deg,
    #ff8db6 1.27%,
    #b579c2 38.55%,
    #897bea 99.33%
  );
  display: inline-flex;
  aspect-ratio: 1;
  align-items: center;

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  :disabled {
    opacity: 0.3;
  }
  :hover {
    box-shadow: ${(props) =>
      props.disabled ? "" : "0px 10px 15px -3px rgba(203, 54, 255, 0.25)"};
  }
`;
const ClearButton = styled.button`
  border: none;
  outline: none;
  background: #f3f4f6;
  color: #6b7280;

  line-height: normal;
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  display: inline-flex;
  aspect-ratio: 1;
  align-items: center;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
`;
export const Secondary = styled(Button)`
  border: 1px solid #d1d5db;
  font: normal 500 12px/16px Inter;
  color: #6b7280;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
`;

const GPT = (props: GPTProps) => {
  const [focus, setFocus] = useState(false);
  const [event, setEvent] = useState<any>(undefined);
  const [update, setUpdate] = useState(false);

  const { daoDetails }: any = useContext(DaoContext);

  const {
    firstPostId,
    secondPostId,
    setFirstPostId,
    setSecondPostId,
    loading: postsLoading,
    posts,
  } = usePostGPT(daoDetails?.daoId, props.data?.channelId);
  const placeholder = useAnimatedPlaceholder(props.data?.placeholders);
  const {
    askGPT,
    result,
    loading,
    askGPTFree,
    summarize,
    clearResult,
    translate,
    stopLoading,
    summarizeChannel,
    critiqueProsAndCons,
    error,
    comparePosts,
  } = useGPT();
  useEffect(() => {
    if (props.data?.channelId) {
      setPrompt("");
      clearResult();
    }
  }, [props.data?.channelId]);

  const inputRef: any = useRef(null);
  const gptWrapperRef: any = useRef(null);

  const [prompt, setPrompt] = useState(props.prompt ? props.prompt : "");
  useOnClickOutside(gptWrapperRef, () => {
    setFocus(false);
  });
  useEffect(() => {
    if (prompt.length > 0) {
      setFocus(true);
    }
    if (props.promptType) {
      if (props.promptType === "TRANSLATE") {
        translate(props.requestText, props.data.target);
      } else if (props.promptType === "ACTION" && props.prompt) {
        askGPT(getRealPrompt(props.prompt), daoDetails?.daoName);
      } else if (props.promptType === "CHECKLIST" && props.requestText ) {
        askGPT(getRealPrompt(props.requestText), daoDetails?.daoName);
      } else if (props.promptType === "POLL" && props.requestText) {
        askGPT(getRealPrompt(props.requestText), daoDetails?.daoName);
      } else if (props.requestText)
        askGPTFree(props.promptType, props.requestText, daoDetails?.daoName);
    }
  }, []);
  const hitRequest = async () => {
    if (event.keyPath[1] === "SUMMARISE_CHANNEL") {
      setPrompt(`Key takeaways from recent post`);
      summarizeChannel(
        parseInt(event.key),
        props.data?.channelId,
        daoDetails.daoId
      );
    }
    switch (event.keyPath[0]) {
      case "HELP": {
        setPrompt("Help me write about");
        break;
      }
      case "BRAINSTROM": {
        setPrompt("Brainstorm ideas on");
        break;
      }
      case "POST": {
        setPrompt("Summarise this discussion");
        summarize("POST", props.data.postId, daoDetails.daoId);
        break;
      }
      case "CRITIQUE": {
        setPrompt(" Critique Pros and Cons of this Post");
        critiqueProsAndCons(props.data.postId, daoDetails.daoId);
        break;
      }
      case "DISCUSSION": {
        setPrompt("Summarise this discussion");
        summarize("DISCUSSION", props.data.postId, daoDetails.daoId);
        break;
      }
    }
  };
  useEffect(() => {
    if (event) {
      hitRequest();
    }
  }, [event?.keyPath[1], event?.keyPath[0], update]);
  const getRealPrompt = (prompt: string) => {
    if (props.promptType === "CHECKLIST") {
      return `${prompt}
             Make a checklist out of the above text for tasks to perform on the above text
             Stick to this markdown format: 
             (Title of the checklist should be in ### Heading level 3	) 
             (Summary of the checklist in  paragraph) 
             (List of checklist in an unordered list)`;
    } else if (props.promptType === "POLL") {
      return `${prompt} 
              Make a poll out of the above text, with choices for users to choose from. 
              Stick to this markdown format: 
              (Title of the poll should be in ### Heading level 3	) 
              (Summary of the poll in  paragraph) 
              (The question to be asked with the poll in #### Heading level 4) 
              (List of choices to choose from in an unordered list) `;
    } else if (props.data?.channelId) {
      return `In the context of "${props.data.channelName}" channel  ${prompt}`;
    }

    return prompt;
  };
  const res = parser(markdown(result));
  console.log(res)

  return (
    <GPTWrapper aria-busy={loading}>
      <form
        ref={gptWrapperRef}
        onClick={() => {
          setFocus(true);
        }}
        style={{
          background: "white",
          height: "100%",
          borderRadius: "10px",
          zIndex: 1,
          boxShadow:
            "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (props.data?.channelId && prompt === "Compare posts") {
            if (!firstPostId) {
              toast.error("Please select first post");
              return;
            }
            if (!secondPostId) {
              toast.error("Please select second post");
              return;
            }
            comparePosts(firstPostId, secondPostId, daoDetails.daoName);
          } else if (event) {
            hitRequest();
          } else {
            askGPT(
              getRealPrompt(prompt),
              daoDetails?.daoName,
              props?.data?.postId ? props.data?.postDescription : ""
            );
          }
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "8px 16px",
            alignItems: "center",
            gap: "8px",
            color: "#1F2937",
            font: "normal 500 14px/20px Inter",
            whiteSpace: "nowrap",
          }}
        >
          <img src={loading ? robinactive : robin} width="24px" height={24} />
          {props.data?.channelId && (
            <span
              className="text-gray-500 bg-gray-50 py-1 px-2 rounded-lg"
              style={{
                font: "normal 500 12px/16px Inter",
              }}
            >
              In # {props.data.channelName}
            </span>
          )}
          <StyledDiv
            style={{
              width: "100%",
            }}
          >
            <StyledInput
              disabled={loading}
              autoFocus={true}
              ref={inputRef}
              bordered={false}
              value={prompt}
              onChange={(e) => {
                setEvent(undefined);

                setPrompt(e.target.value);
              }}
              placeholder={focus ? "Ask robin anything..." : placeholder}
            />
            {result && (
              <IconWrapper
                width={12}
                style={{ marginRight: "48px", color: "#6b7280" }}
              >
                <PencilIcon fill="#6B7280" className="edit" />
              </IconWrapper>
            )}
          </StyledDiv>

          {!result && !loading && (
            <>
              {prompt.length > 0 && (
                <ClearButton
                  type="button"
                  onClick={() => {
                    setPrompt("");
                  }}
                >
                  <IconWrapper style={{}} width={12}>
                    <XMarkIcon />
                  </IconWrapper>
                </ClearButton>
              )}
              <RoundedButton disabled={prompt.length === 0 || loading}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.63434 7.7653C2.32192 7.45288 2.32192 6.94634 2.63434 6.63392L7.43434 1.83392C7.74676 1.5215 8.25329 1.5215 8.56571 1.83392L13.3657 6.63392C13.6781 6.94634 13.6781 7.45288 13.3657 7.7653C13.0533 8.07771 12.5468 8.07771 12.2343 7.7653L8.80002 4.33098L8.80003 13.5996C8.80003 14.0414 8.44185 14.3996 8.00003 14.3996C7.5582 14.3996 7.20003 14.0414 7.20003 13.5996L7.20002 4.33098L3.76571 7.7653C3.45329 8.07771 2.94676 8.07771 2.63434 7.7653Z"
                    fill="white"
                  />
                </svg>
              </RoundedButton>
            </>
          )}
        </div>
        {(props.data?.channelId || props?.data?.postId) && (
          <div
            style={{
              height: focus && prompt.length === 0 && !result ? "50px" : "0px",
              overflow: "hidden",
              transition: "height 0.3s ease-in-out",
            }}
          >
            <div
              style={{
                display: "flex",
                overflowY: "hidden",
                overflowX: "auto",
                whiteSpace: "nowrap",
                gap: "8px",
                margin: "0px 16px",
                padding: "8px 0px 20px",
              }}
            >
              {props.data?.channelId && (
                <>
                  {props.data?.postCount === 0 ? (
                    <>
                      <SuggestionPill
                        onClick={() => {
                          setPrompt("Draft a welcome message");
                          askGPT(
                            getRealPrompt("draft a welcome message"),
                            daoDetails?.daoName
                          );
                        }}
                      >
                        Draft a welcome message
                      </SuggestionPill>
                      <SuggestionPill
                        onClick={() => {
                          setPrompt("Tell me 5 ideas for engaging post");
                          askGPT(
                            getRealPrompt("tell me 5 ideas for engaging post"),
                            daoDetails?.daoName
                          );
                        }}
                      >
                        Tell me 5 ideas for engaging post
                      </SuggestionPill>
                    </>
                  ) : (
                    <>
                      <SuggestionPill
                        onClick={() => {
                          setPrompt("Compare posts");
                        }}
                      >
                        Compare posts
                      </SuggestionPill>
                      <SuggestionPill
                        onClick={() => {
                          setPrompt("Tell me 5 ideas for engaging post");
                          askGPT(
                            getRealPrompt("tell me 5 ideas for engaging post"),
                            daoDetails?.daoName
                          );
                        }}
                      >
                        Tell me 5 ideas for engaging post
                      </SuggestionPill>
                      <SuggestionPill
                        onClick={() => {
                          setEvent({
                            key: 5,
                            keyPath: [5, "SUMMARISE_CHANNEL"],
                          });
                          setUpdate((prev) => !prev);
                        }}
                      >
                        Key takeaways from recent post
                      </SuggestionPill>
                      <SuggestionPill
                        onClick={() => {
                          setPrompt("Write a post to impress the admin");
                          askGPT(
                            getRealPrompt(
                              "write a post to impress the admin of this community"
                            ),
                            daoDetails?.daoName
                          );
                        }}
                      >
                        Write a post to impress the admin of this community
                      </SuggestionPill>
                      <SuggestionPill
                        onClick={() => {
                          setPrompt("Brainstorm ideas on...");
                        }}
                      >
                        Brainstorm ideas on...
                      </SuggestionPill>
                    </>
                  )}
                </>
              )}
              {props?.data?.postId && (
                <>
                  <SuggestionPill
                    onClick={() => {
                      setEvent({
                        key: "DISCUSSION",
                        keyPath: ["DISCUSSION", "SUMMARISE"],
                      });
                      setUpdate((prev) => !prev);
                    }}
                  >
                    Summarise this discussion
                  </SuggestionPill>
                  <SuggestionPill
                    onClick={() => {
                      setEvent({
                        key: "CRITIQUE",
                        keyPath: ["CRITIQUE", "POST"],
                      });
                      setUpdate((prev) => !prev);
                    }}
                  >
                    Critique Pros and Cons of this Post
                  </SuggestionPill>
                  <SuggestionPill
                    onClick={() => {
                      setPrompt("Make a comment for this post");
                      askGPTFree(
                        "MAKE_COMMENT",
                        props.data?.postDescription,
                        daoDetails?.daoName
                      );
                    }}
                  >
                    Make a comment for this post
                  </SuggestionPill>
                  <SuggestionPill
                    onClick={() => {
                      setPrompt("Turn this post into Twitter thread ");
                      askGPT(
                        `Turn the post "${props.data?.postDescription}" into a Twitter thread`,
                        daoDetails?.daoName
                      );
                    }}
                  >
                    Turn this post into Twitter thread
                  </SuggestionPill>
                </>
              )}
            </div>
          </div>
        )}

        {loading ||
        result ||
        error ||
        (props.data?.channelId && prompt === "Compare posts") ? (
          <div
            style={{
              background: "white",
              display: "flex",
              flexDirection: "column",
              margin: "0px 16px",
              padding: "8px 0px",
              borderTop: "1px solid #E2E8F0",
            }}
          >
            {loading && <Loading stopLoading={stopLoading} />}
            {!loading &&
            !error &&
            props.data?.channelId &&
            prompt === "Compare posts" ? (
              <ComparePost
                firstPostId={firstPostId}
                secondPostId={secondPostId}
                setFirstPostId={setFirstPostId}
                setSecondPostId={setSecondPostId}
                posts={posts}
                loading={postsLoading}
                result={result}
              />
            ) : null}
            {!loading && result ? (
              <>
                <div
                  className="overflow-y-auto mb-3"
                  style={{
                    font: "normal 500 14px/20px",
                    color: "#1A202E",
                    maxHeight: props.openInModal ? "" : "450px",
                  }}
                >
                  <ReactMarkdown>{result.trim()}</ReactMarkdown>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                  }}
                >
                  <Secondary
                    type="text"
                    onClick={() => {
                      navigator.clipboard.writeText(result).then(
                        () => {
                          toast.success("Text copied successfully");
                        },
                        () => {
                          toast.success("Unable to copy text");
                        }
                      );
                    }}
                  >
                    <CopyIcon width={16} />
                  </Secondary>
                  <Secondary
                    type="text"
                    onClick={() => {
                      setPrompt("");
                      clearResult();
                    }}
                  >
                    <BroomIcon width={12} /> Clear
                  </Secondary>
                  <Secondary type="text" htmlType="submit">
                    <SparklesIcon width="16px" /> Regenerate
                  </Secondary>
                  {props?.data?.channelId && props?.data?.canCreatePost && (
                    <GPTButton
                      onClick={async () => {
                        const title = await askGPT(
                          `Provide a suitable title on the post ${result} in less than 15 words `,
                          daoDetails?.daoName
                        );

                        props.data?.onCreatePost({
                          title,
                          description: result,
                        });
                      }}
                      type="button"
                      disabled={loading}
                      style={{
                        cursor: loading ? "not-allowed" : "pointer",
                        gap: "4px",
                      }}
                    >
                      + Create Post
                    </GPTButton>
                  )}
                  {props.openInModal && (
                    <GPTButton
                      onClick={() => {
                        if (
                          ["CHECKLIST", "POLL"].includes(
                            props.promptType ? props.promptType : ""
                          )
                        ) {
                          props.onSubmit({
                            type: props.promptType,
                            list: res?.list,
                            question: res?.h4,
                          });
                        } else {
                          props.onSubmit(result);
                        }
                      }}
                      type="button"
                      disabled={
                        loading ||
                        ["CHECKLIST", "POLL"].includes(
                          props.promptType ? props.promptType : ""
                        )
                          ? res.list.length === 0
                          : false
                      }
                      style={{
                        cursor: loading ? "not-allowed" : "pointer",
                        gap: "4px",
                      }}
                    >
                      {props?.requestText &&
                      props.promptType &&
                      !["CHECKLIST", "POLL"].includes(props.promptType) ? (
                        <>
                          <IconWrapper
                            style={{ marginRight: "4px" }}
                            width={12}
                          >
                            <ArrowPathIcon />
                          </IconWrapper>
                          Replace
                        </>
                      ) : (
                        "+ Insert"
                      )}
                    </GPTButton>
                  )}
                  {props?.data?.postId && props?.data?.canComment && (
                    <GPTButton
                      onClick={async () => {
                        props.data?.onPostComment(result);
                      }}
                      type="button"
                      disabled={loading}
                      style={{
                        cursor: loading ? "not-allowed" : "pointer",
                        gap: "4px",
                      }}
                    >
                      <IconWrapper style={{ marginRight: "4px" }} width={12}>
                        <ChatBubbleBottomCenterTextIcon />
                      </IconWrapper>{" "}
                      Post Comment
                    </GPTButton>
                  )}
                </div>
              </>
            ) : null}
            {!loading && error && (
              <div
                style={{
                  color: "#F43F5E",
                  font: "normal 500 14px/20px Inter",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Sorry, the server seems to be busy at the moment{" "}
                <div style={{ display: "flex", gap: "8px" }}>
                  <Secondary
                    type="text"
                    onClick={() => {
                      setPrompt("");
                      clearResult();
                    }}
                  >
                    <BroomIcon width={12} /> Clear
                  </Secondary>
                  <GPTButton
                    disabled={loading}
                    style={{ cursor: loading ? "not-allowed" : "pointer" }}
                  >
                    <IconWrapper style={{ marginRight: "4px" }} width={12}>
                      <ArrowPathIcon />
                    </IconWrapper>{" "}
                    Try Again
                  </GPTButton>
                </div>
              </div>
            )}
          </div>
        ) : null}

        {!loading && !error && result && (
          <div
            style={{
              height: "32px",
              display: "flex",
              alignItems: "center",
              background: "#F9FAFB",
              borderRadius: "0px 0px 12px 12px",
              padding: "0px 12px",
              gap: "6px",
              color: "#6B7280",
              font: "normal 500 12px/16px Inter",
            }}
          >
            <ExclamationTriangleIcon width={12} /> Ai responses can be inacurate
            and misleading
          </div>
        )}
      </form>
    </GPTWrapper>
  );
};

export default GPT;

import React, { useEffect, useState } from "react";
import { Button, Input, Tooltip } from "antd";
import { Modal, Footer } from "../../styles/ModalStyles";
import { toast } from "react-toastify";
import customAxios from "../../api";
import { useParams } from "react-router";

import InputField from "../Common/form/Input";
import { StyledModalWrapper } from "../Proposal/CreateProposalModal";
import { ReactComponent as EnterEmoji } from "../../assets/images/project/enter_emoji.svg";
import { ReactComponent as StackIcon } from "./../../images/rectangle-stack-icon.svg";
import EmojiPicker from "emoji-picker-react";
import IconWrapper from "../IconWrapper";
import { BountyFilesAttachment } from "../CreateBountyModal";
import Attachments from "../Common/Attachments";
import QuillEditor from "../QuillEditor";
import { postMixpanel } from "../../utils";
import { PaperClipIcon } from "../Icones";
import LinkEntities from "../Common/LinkEntities";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { confirmOperation } from "../../utils/common";

export const BACKGROUND_COLORS = [
  "#C3DDFD",
  "#FBD5D5",
  "#FCD9BD",
  "#FDF6B2",
  "#BCF0DA",
  "#AFECEF",
  "#CDDBFE",
  "#DCD7FE",
  "#FAD1E8",
];

const CreateProjectModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  isEdit = false,
  defaultData = null,
}) => {
  const [form, setForm] = useState({});
  const [background, setBackground] = useState(
    BACKGROUND_COLORS[Math.floor(Math.random() * BACKGROUND_COLORS.length)]
  );
  const [showEmojiModal, setEmojiModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [attachmentsToBeDeleted, setAttachmentsToBeDeleted] = useState([]);
  const defaultAttachments = defaultData?.attachments?.filter(
    (attachment) => !attachmentsToBeDeleted.includes(attachment)
  );

  const [linkedItems, setLinkedItems] = useState([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const { daoDetails, daoId } = useDaoContext();

  const inputRef = React.useRef(null);

  useEffect(() => {
    if (defaultData) {
      setForm({
        title: defaultData.title,
        description: defaultData.description,
        icon: defaultData.icon,
      });
      if (defaultData?.background) {
        setBackground(defaultData.background);
      }
    }
  }, [defaultData, isModalVisible]);
  const createProject = () => {
    const formData = new FormData();
    if (!form.title) {
      return toast.error("Please provide title of the project");
    }
    if (!form.description) {
      return toast.error("Please provide description of the project");
    }
    formData.append("title", form.title);
    formData.append("description", form.description);
    formData.append("daoId", daoId);
    formData.append("background", background);
    if (linkedItems.length) {
      formData.append(
        "linkedEntities",
        JSON.stringify(
          linkedItems.map((ele) => ({ linkedType: ele.type, linkedId: ele.id }))
        )
      );
    }
    files.map((file) => {
      var blob = file.slice(0, file.size, file.type);
      let newFile = new File([blob], file.name, { type: file.type });
      return formData.append("files", newFile);
    });
    if (form.icon) formData.append("icon", form.icon);
    setLoading(true);
    customAxios
      .post("/projects/create", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          postMixpanel("track", "created_project", {
            dao_id: daoId,
            project_name: form.title,
          });
          toast.success("Project created successfully");
          handleCancel();
          onSubmit();
          setForm({});
        } else console.log("error");
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateProject = () => {
    const formData = new FormData();
    if (!form.title) {
      return toast.error("Please provide title of the project");
    }
    if (!form.description) {
      return toast.error("Please provide description of the project");
    }
    if (form.icon) formData.append("icon", form.icon);
    formData.append("title", form.title);
    formData.append("description", form.description);
    formData.append("projectId", defaultData.id);
    if (attachmentsToBeDeleted.length > 0)
      formData.append(
        "deletedAttachments",
        JSON.stringify(attachmentsToBeDeleted)
      );
    files.map((file) => {
      var blob = file.slice(0, file.size, file.type);
      let newFile = new File([blob], file.name, { type: file.type });
      return formData.append("files", newFile);
    });
    setFiles([]);
    setLoading(true);
    customAxios
      .post("/projects/edit", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("Project updated successfully");
          handleCancel();
          onSubmit();
        } else console.log("error");
      })
      .catch((err) => {
        console.log("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <StyledModalWrapper>
        <Modal
          width="640px"
          // height="600px"
          destroyOnClose
          visible={isModalVisible}
          onCancel={() =>
            isEdit
              ? handleCancel()
              : confirmOperation({
                  cancelText: "Discard Changes",
                  confirmText: "Continue Editing",
                  message:
                    " You will lose the progress if you close this pop-up?",
                  title: "Confirm",
                  onCancel: () => handleCancel(),
                })
          }
          footer={[
            <Footer key="modal-footer" justifyContent="space-between">
              <div style={{ marginLeft: "14px", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    columnGap: "8px",
                    flexDirection: "row ",
                  }}
                >
                  <Button
                    className="borderless-button"
                    onClick={() => inputRef.current.click()}
                  >
                    <IconWrapper width="18px">
                      <PaperClipIcon />
                    </IconWrapper>
                  </Button>
                  <LinkEntities
                    isModalVisible={linkModalVisible}
                    handleClose={() => {
                      setLinkModalVisible(false);
                    }}
                    linkedItems={linkedItems}
                    setLinkedItems={setLinkedItems}
                  ></LinkEntities>
                  <Tooltip title="Link the discussions, bounties or any entity that led to this project.">
                    <Button
                      className="borderless-button"
                      shape="circle"
                      onClick={() => {
                        setLinkModalVisible(true);
                      }}
                    >
                      <IconWrapper>
                        <StackIcon />{" "}
                        <span style={{ color: "#64748B", fontSize: "12px" }}>
                          {linkedItems.length
                            ? `(${linkedItems.length})`
                            : null}
                        </span>
                      </IconWrapper>
                    </Button>
                  </Tooltip>
                  <input
                    hidden
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    style={{ width: "0", height: "0" }}
                    onClick={() => {
                      inputRef.current.value = null;
                    }}
                    onChange={(e) => {
                      const newFile = e.target.files[0];

                      // console.log("kk", newFile);
                      setFiles((prev) => [...prev, newFile]);
                    }}
                  />
                </div>
              </div>
              <Button
                className="rounded-corner"
                type="primary"
                style={{ marginLeft: "auto" }}
                disabled={loading}
                onClick={() => {
                  !isEdit ? createProject() : updateProject();
                }}
              >
                {isEdit ? "Save" : "Create"}
              </Button>
            </Footer>,
          ]}
        >
          <div
            style={{
              height: "400px",
              overflowY: "auto",
              marginLeft: "8px",
              marginRight: "20px",
              cursor: "pointer",
            }}
            className="setup-project"
            onClick={() => {
              setEmojiModal(false);
            }}
          >
            <div
              style={{
                margin: "20px 12px 8px 12px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setEmojiModal(true);
              }}
            >
              {form.icon ? (
                <div
                  style={{
                    font: "normal 500 30px/40px Inter",
                    width: "60px",
                    height: "60px",
                    background,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1.5px solid #FFFFFF",
                    borderRadius: "12px",
                    boxShadow: " 0px 0px 3px rgba(25, 38, 72, 0.2)",
                  }}
                >
                  {form.icon}
                </div>
              ) : (
                <div
                  style={{
                    padding: "4px 8px",
                    background: "#F1F5F9",
                    borderRadius: "8px",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "4px",
                    font: "normal 500 12px/16px Inter",
                    color: "#64748B",
                  }}
                >
                  <EnterEmoji width="16px"></EnterEmoji>
                  Add icon
                </div>
              )}
            </div>

            {showEmojiModal && (
              <div
                style={{ position: "fixed", zIndex: 10 }}
                onClick={(e) => e.stopPropagation()}
              >
                <EmojiPicker
                  onEmojiClick={(emojiData) => {
                    setForm((prev) => ({ ...prev, icon: emojiData.emoji }));
                    if (!isEdit)
                      setBackground(
                        BACKGROUND_COLORS[
                          Math.floor(Math.random() * BACKGROUND_COLORS.length)
                        ]
                      );
                    setEmojiModal(false);
                  }}
                  autoFocusSearch={false}
                />
              </div>
            )}

            <Input
              style={{
                fontSize: "21px",
                fontWeight: "600",
                marginBottom: "8px",
                marginTop: isEdit && "20px",
              }}
              placeholder="Add project name"
              size="large"
              value={form?.title}
              onChange={(e) => {
                setForm((prev) => ({ ...prev, title: e.target.value }));
              }}
              bordered={false}
            />

            <QuillEditor
              value={form.description}
              isBordered={true}
              height="200px"
              style={{
                margin: "0px 4px",
                resize: "none",
                overflowY: "auto",
              }}
              className="bounty-description"
              onChange={(e) => {
                setForm((prev) => ({
                  ...prev,
                  description: e,
                }));
              }}
              placeholder="Add details of your project below"
            />
            {isEdit && (
              <div style={{ padding: "4px 20px" }}>
                <Attachments
                  attachments={defaultAttachments}
                  onDelete={(attachment) => {
                    setAttachmentsToBeDeleted((prev) => [...prev, attachment]);
                  }}
                />
              </div>
            )}
          </div>
          {files.length > 0 && (
            <BountyFilesAttachment files={files} setFiles={setFiles} />
          )}
        </Modal>
      </StyledModalWrapper>
    </>
  );
};

export default CreateProjectModal;

import { Row, Input } from "antd";
const WalletAddress = ({ ele, idx, handleConditionDetailChange }) => {
  return (
    <Row
      gutter={["8"]}
      style={{
        columnGap: "1rem",
        flexFlow: "nowrap",
        padding: "2rem 12px 15px 12px",
        borderRadius: "12px",
        background: "#eeeeee",
        position: "relative",
      }}
    >
      <Input
        placeholder="Enter wallet address"
        value={ele?.specificAddress[0]}
        className="ip-address"
        autoFocus={true}
        onChange={(e) => {
          handleConditionDetailChange("specificAddress", [e.target.value], idx);
        }}
      ></Input>
    </Row>
  );
};

export default WalletAddress;

import Announcement from "./Pallet/Announcement";
import Bounty from "./Pallet/Bounty";
import Post from "./Pallet/Post";
import Project from "./Pallet/Project";
import Proposal from "./Pallet/Proposal";
import UserProfile from "./Pallet/UserProfile";

const SearchItem = ({
  data,
  handleClose,
  searchTerm,
  onClick,
  handleDeleteLinkedItem,
}) => {
  switch (data.type) {
    case "BOUNTY":
      return (
        <Bounty
          data={data}
          handleClose={handleClose}
          searchTerm={searchTerm}
          handleDeleteLinkedItem={handleDeleteLinkedItem}
          onClick={onClick}
        />
      );
    case "USER_PROFILE":
      return (
        <UserProfile
          data={data}
          handleClose={handleClose}
          handleDeleteLinkedItem={handleDeleteLinkedItem}
          searchTerm={searchTerm}
        />
      );
    case "POST":
      return (
        <Post
          data={data}
          onClick={onClick}
          handleClose={handleClose}
          handleDeleteLinkedItem={handleDeleteLinkedItem}
          searchTerm={searchTerm}
        />
      );
    case "PROPOSALS":
      return (
        <Proposal
          data={data}
          onClick={onClick}
          handleClose={handleClose}
          handleDeleteLinkedItem={handleDeleteLinkedItem}
          searchTerm={searchTerm}
        />
      );
    case "PROJECTS":
      return (
        <Project
          data={data}
          handleClose={handleClose}
          searchTerm={searchTerm}
          handleDeleteLinkedItem={handleDeleteLinkedItem}
          onClick={onClick}
        />
      );
    case "ANNOUNCEMENT":
      return (
        <Announcement
          data={data}
          handleClose={handleClose}
          searchTerm={searchTerm}
          handleDeleteLinkedItem={handleDeleteLinkedItem}
          onClick={onClick}
        />
      );
  }
  return <div>Hi</div>;
};

export default SearchItem;

import { Button, Col, Row, Skeleton, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import customAxios from "../../../api";
import addPaymentIcon from "../../../assets/iconSvg/addPaymentEmptyState.svg";
import GenosisIcon from "../../../images/genosis.svg";
import metamaskIcon from "../../../assets/iconSvg/metamask.png";
import AddPaymentModal from "./AddPaymentModal";
import { truncateWalletAddress } from "../../BountyComponents/PaymentModal";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as SolanaIcon } from "../../../assets/images/login/solana-sol-logo.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import { useDaoContext } from "../../../layouts/app/DaoContext";

const DaoPayment = () => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isMobile } = useWindowSize();

  const [savedPaymentMethodList, setSavedPaymentMethodList] = useState([]);
  const deletePaymentMethodHanlder = (id) => {
    setLoading(true);
    customAxios
      .delete(`/bountypayments/delete/paymentmethod`, {
        data: { daoId, methodId: id },
      })
      .then((res) => {
        setUpdate((prev) => !prev);
        toast.success("Payment method deleted successfully");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { daoDetails, daoId } = useDaoContext();

  const getSavePaymentMethod = () => {
    setLoading(true);
    customAxios
      .get(`bountypayments/fetch/savepaymentmethod?daoId=${daoId}`)
      .then(({ data }) => {
        setSavedPaymentMethodList(data.savedPaymentMethods);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!daoId) return;
    getSavePaymentMethod();
  }, [update, daoId]);
  return (
    <Col
      xs={24}
      style={{ padding: "0px", marginTop: isMobile ? "0PX" : "20px" }}
    >
      <AddPaymentModal
        visible={showPaymentModal}
        setVisibility={setShowPaymentModal}
        onSubmit={() => {
          setUpdate((prev) => !prev);
          setShowPaymentModal(false);
        }}
      />

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #F4F2F2",
          borderRadius: "16px",
          padding: "12px 20px",
          background: "white",
          marginBottom: "12px",
          minHeight: "58px",
        }}
      >
        {isMobile ? null : (
          <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>Payments</h4>
        )}
        {savedPaymentMethodList.length > 0 && (
          <Button
            className="add-new-payment-btn"
            type="primary"
            style={{
              color: "#ffffff",
              borderRadius: "8px",
              width: isMobile ? "100%" : "fit-content",
            }}
            onClick={() => {
              setShowPaymentModal(true);
            }}
          >
            + Add Method
          </Button>
        )}
      </Row>
      <div
        style={{
          background: "white",
          borderRadius: "16px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        {loading && <Skeleton active />}

        {!loading &&
          savedPaymentMethodList.map((method, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  border: "1px solid #E2E8F0",
                  borderRadius: "12px",
                  padding: "12px",
                  gap: "12px",
                }}
              >
                {method.walletType.toLowerCase() === "metamask" ? (
                  <img
                    src={metamaskIcon}
                    alt="icon"
                    height="16"
                    style={{ marginTop: "2px" }}
                  />
                ) : method.walletType.toLowerCase() === "phantom" ? (
                  <SolanaIcon style={{ height: "12px", marginTop: "2px" }} />
                ) : (
                  <img
                    src={GenosisIcon}
                    alt="icon"
                    height="16"
                    style={{ marginTop: "2px" }}
                  />
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    fontWeight: "600",
                  }}
                >
                  <Typography style={{ fontSize: "14px" }}>
                    {method.walletType}
                  </Typography>
                  <Typography style={{ fontSize: "12px", color: "#64748B" }}>
                    {truncateWalletAddress(method.address)}
                  </Typography>
                </div>
                <Button
                  icon={<DeleteOutlined />}
                  shape="circle"
                  className="delete-btn"
                  onClick={() => deletePaymentMethodHanlder(method.id)}
                  style={{ marginLeft: "auto" }}
                />
              </div>
            );
          })}
        {!loading && savedPaymentMethodList.length === 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              font: "normal 600 16px/24px Inter",
              padding: "64px 0px",
            }}
          >
            <img
              src={addPaymentIcon}
              alt=""
              style={{
                marginBottom: "20px",
                width: "100px",
              }}
            />
            Add payment methods
            <div
              style={{
                font: "normal 500 12px/18px Inter",
                color: "#64748B",
                textAlign: "center",
                margin: "8px 100px 16px",
              }}
            >
              In order to start processing payments for bounties you would need
              to setup Metamask or Gnosis wallet
            </div>
            <Button
              className="add-new-payment-btn"
              type="primary"
              style={{ color: "#ffffff", borderRadius: "8px" }}
              onClick={() => {
                setShowPaymentModal(true);
              }}
            >
              Add payment method
            </Button>
          </div>
        )}
      </div>
    </Col>
  );
};

export default DaoPayment;

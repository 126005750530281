import styled from "styled-components";
import { Modal as AntModal } from "antd";

export const Modal = styled(AntModal)`
  top: ${(props) => (props.isMobile ? "auto !important" : "")};
  bottom: ${(props) => (props.isMobile ? "0 !important" : "")};
  position: ${(props) => (props.isMobile ? "absolute" : "")};
  left: ${(props) => (props.isMobile ? "0" : "")};
  right: ${(props) => (props.isMobile ? "0" : "")};
  margin: ${(props) => (props.isMobile ? "0" : "")};
  // padding: ${(props) => (props.isMobile ? "0 !important" : "")};
  & .ant-modal-footer {
    padding: ${(props) => props.footerPadding || "10px 16px"};
  }
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 1rem;
  }
  & .ant-modal-header {
    padding: ${(props) => props.headerPadding || "16px 11px 0px 11px;"};
    border-bottom: 0;
  }
  & .ant-modal-title {
    width: fit-content;
    padding: 2px 0px;
    background: #f4f2f2;
    border-radius: 0.6rem;
    font-size: small;
    /* margin-left: 8px; */
    background: #fff;
  }
  .ant-modal-body {
    // max-height: 600px;
  }
  & .ant-modal-content {
    width: ${(props) =>
      props.isMobile ? "100vw" : props.width ? props.width : "600px"};
  }
  & .ant-modal-body {
    display: flex;
    flex-direction: column;
    min-height: ${(props) => props.height || "400px"};
    padding: 0;
    overflow-y: auto;
    max-height: 620px;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: center;
  & .borderless-button {
    border: none;
    padding: 0;
    background: none;
    line-height: initial;
  }
  & .circle-border-button {
    border-radius: 1rem;
  }
  & .rounded-corner {
    border-radius: 0.6rem;
  }
`;

import { CloseCircleFilled } from "@ant-design/icons";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button, Card, Col, Input, Space } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { Feild } from "../../../styles/Feild";
import { LabelIcon } from "../../../styles/LabelIcon";
import { PopupCard } from "../../../styles/PopupCard";
import { checkFileSize } from "../../../utils";
import Tooltip from "../../Common/Tooltip";
import DueDate from "../../DueDate";
import Flag from "../../Flag";
import { PaperClipIcon } from "../../Icones";
import IconWrapper from "../../IconWrapper";
import { ReactComponent as CrossIcon } from "./../../../assets/iconSvg/crossIcon.svg";
import emptyUser from "./../../../assets/iconSvg/emptyUser.svg";
import dummyUser from "./../../../assets/images/login/defaultUser.jpeg";
const SearchBar = styled(Input)`
  border-radius: 0.6rem;
  margin: 0px 2% 4%;
  width: 96%;
`;
const StyledCard = styled(Card)`
  width: 178px;
  position: absolute;
  transition: 300ms all;
  border-radius: 1rem;
  z-index: 1;
  box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
  padding: 0px;
  & .search-input {
    border-radius: 0.6rem;
  }
  & .borderless-button {
    border: none;
    padding: 0;
    background: none;
    line-height: initial;
  }
`;

const Circle = () => (
  <div
    style={{
      width: "16px",
      height: "16px",
      marginRight: "4px",
      /* Colors/bg/white */
      borderRadius: "50%",
      background: "#FFFFFF",
      /* Colors/text/disabled-cool gray-400 */

      border: "1px solid #97A6BA",
    }}
  ></div>
);
function EditableChecklistItem({
  ele,
  idx,
  checklist,
  setChecklist,
  focusIndex,
  setFocusIndex,
  uploadFiles,
  addNewChecklistItem,
  BountyFilesAttachment,
  assigneeList,
}) {
  console.log(ele);
  const [showCard, setShowCard] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterAssigneeList, setFilterAssigneeList] = useState([]);
  const cardRef = useRef();
  const editableChecklistItemRef = useRef();
  useOnClickOutside(cardRef, () => setShowCard(false));

  useEffect(() => {
    if (!searchInput) setFilterAssigneeList(assigneeList.slice());
    else
      setFilterAssigneeList(
        assigneeList
          .slice()
          .filter((ele) =>
            ele.userName?.toLowerCase().includes(searchInput.toLowerCase())
          )
      );
  }, [searchInput, assigneeList]);
  const inputRef = useRef();
  return (
    <div
      ref={editableChecklistItemRef}
      className="border border-solid relative grow"
      style={{
        padding: "4px 12px",
        borderRadius: "8px",
        borderColor: "#E2E8F0",
      }}
    >
      <div
        style={{
          justifyContent: "space-between",
          columnGap: "8px",
          display: "flex",
        }}
      >
        <Input
          placeholder="Add checklist item"
          value={ele.title}
          multiple={true}
          onFocus={() => setFocusIndex(idx)}
          onChange={(e) => {
            const currList = checklist.slice();
            currList[idx]["title"] = e.target.value;
            setChecklist(currList.slice());
          }}
          autoFocus={focusIndex === idx}
          prefix={<Circle />}
          onKeyDown={(e) => {
            if (e.key === "Enter") addNewChecklistItem(idx);
          }}
          bordered={false}
          style={{ padding: "0px" }}
        ></Input>
        <input
          ref={inputRef}
          style={{ display: "none" }}
          hidden
          type="file"
          name="Attachment"
          accept="image/*"
          multiple
          onChange={(e) => {
            if (e.target.files.length) {
              const newFile = e.target.files[0];
              checkFileSize(newFile.size);
              const insertPublicUrl = (file) => {
                setChecklist((prev) => {
                  const currList = prev.slice();
                  const currAttachments = currList[idx]?.["attachments"]?.length
                    ? currList[idx]?.["attachments"].slice()
                    : [];
                  currAttachments.push(file);
                  currList[idx].attachments = currAttachments.slice();
                  return currList.slice();
                });
              };
              uploadFiles(newFile, insertPublicUrl);
            }
          }}
          onClick={() => {
            inputRef.current.value = null;
          }}
        />
        <Space>
          <Button
            className="borderless-button"
            shape="circle"
            style={{
              padding: "0px",
              border: "none",
              background: "none",
              transform: "translateY(2px)",
            }}
            onClick={() => inputRef.current.click()}
          >
            <IconWrapper width="18px">
              <PaperClipIcon />
            </IconWrapper>
          </Button>

          <Flag
            style={{
              border: "none",
              boxShadow: "none",
              transform: "translateY(2px)",
              margin: "0 -7px 0 -8px",
            }}
            defaultValue={
              checklist?.length > 0 ? checklist[idx]["priority"] || null : null
            }
            handleChange={(value) => {
              const currList = checklist.slice();
              currList[idx]["priority"] = value;
              setChecklist(currList.slice());
            }}
          />
          <DueDate
            buttonStyle={{
              border: "none",
              boxShadow: "none",
            }}
            cardProps={{ top: "38px" }}
            defaultValue={checklist[idx]?.dueDate}
            onChange={(date, time) => {
              const currList = checklist.slice();

              if (date) {
                const dateTime = moment(
                  date.format("YYYY-MM-DD") + ` ${time ? time : "00:00 AM"}`
                ).format("YYYY-MM-DD HH:mmZ");
                currList[idx]["dueDate"] = dateTime;
              } else currList[idx]["dueDate"] = null;
              setChecklist(currList.slice());
            }}
          />
          <Tooltip
            content={
              <>
                <Input
                  className="search-input"
                  placeholder="Search members..."
                  value={searchInput}
                  prefix={
                    <IconWrapper>
                      <MagnifyingGlassIcon />
                    </IconWrapper>
                  }
                  style={{ margin: "4px 0px" }}
                  autoFocus={true}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Space
                  direction="vertical"
                  style={{
                    minHeight: "250px",
                    width: "100%",
                    maxHeight: "250px",
                    overflowY: "auto",
                  }}
                >
                  {!filterAssigneeList.length ? (
                    <h4 style={{ textAlign: "center" }}>No users found</h4>
                  ) : null}
                  {filterAssigneeList.map((user, index) => (
                    <Feild
                      key={user}
                      onClick={() => {
                        setChecklist((prev) => {
                          const currList = prev.slice();
                          currList[idx]["assigneeId"] = user;
                          return currList.slice();
                        });
                        setShowCard(false);
                      }}
                    >
                      <img
                        style={{
                          width: "22px",
                          borderRadius: "50%",
                          height: "22px",
                          objectFit: "cover",
                        }}
                        src={
                          user?.profilePicture
                            ? user?.profilePicture
                            : dummyUser
                        }
                        alt="icon"
                      />{" "}
                      <span>
                        {user?.userName?.slice(0, 16)}
                        {user?.userName?.length > 16 ? "..." : null}
                      </span>
                    </Feild>
                  ))}
                </Space>
              </>
            }
          >
            <LabelIcon
              padding="0px"
              onClick={() => {
                setShowCard(true);
              }}
              style={{ background: "#fff", borderRadius: "0px" }}
              height="24px"
            >
              {ele?.assigneeId ? (
                <>
                  <Tooltip title={ele?.assigneeId?.userName}>
                    <img
                      src={
                        ele.assigneeId.profilePicture
                          ? ele.assigneeId.profilePicture
                          : dummyUser
                      }
                      alt="icon"
                      style={{
                        width: "20px",
                        borderRadius: "50%",
                        height: "20px",
                        objectFit: "cover",
                      }} // style={{ borderRadius: "10rem" }}
                    />
                  </Tooltip>
                  <Button
                    className="borderless-button"
                    shape="circle"
                    size="small"
                    style={{
                      padding: "0px",
                      border: "none",
                      background: "none",
                    }}
                    icon={<CloseCircleFilled />}
                    onClick={() => {
                      setChecklist((prev) => {
                        const currList = prev.slice();
                        currList[idx]["assigneeId"] = null;
                        return currList.slice();
                      });
                      setShowCard(false);
                    }}
                  />
                </>
              ) : (
                <img
                  src={emptyUser}
                  alt="icon"
                  height={"20px"}
                  style={{ borderRadius: "10rem" }}
                />
              )}
            </LabelIcon>
          </Tooltip>
        </Space>
      </div>

      {ele?.attachments?.length ? (
        <BountyFilesAttachment
          showHeader={false}
          files={ele.attachments}
          onDelete={(id) => {
            setChecklist((prev) => {
              const currList = prev.slice();

              currList[idx]["attachments"] = currList[idx][
                "attachments"
              ].filter((ele) => ele.publicUrl !== id);
              return currList.slice();
            });
          }}
        />
      ) : null}
    </div>
  );
}

export default EditableChecklistItem;

import { Skeleton } from "antd";
import { AxiosError } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import customAxios from "../api";
import { TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY } from "../constants";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
import useWindowSize from "../hooks/useWindowSize";
import { UserContext } from "../layouts/app/UserContext";
import TwitterAlreadyConnectedModal from "../components/Profile/TwitterAlreadyConnectedModal";

const TwitterBountyRedirection = () => {
  const { isMobile } = useWindowSize();
  const [searchParams] = useSearchParams();
  const { reload } = useContext(UserContext);
  const [
    isTwitterAccountAlreadyConnectedModalOpen,
    setIsTwitterAccountAlreadyConnectedModalOpen,
  ] = useState(false);
  const [existingTwitterUserDetails, setExistingTwitterUserDetails] =
    useState(undefined);

  const { mutate: postConnectTwitter } = useMutation({
    mutationFn: ({
      oauthToken,
      oauthVerifier,
    }: {
      oauthToken: string;
      oauthVerifier: string;
    }) =>
      customAxios.post("/auth/connect/twitter", { oauthToken, oauthVerifier }),
    onSuccess: ({ data }) => {
      if (data.access_token) {
        localStorage.setItem("access_token", data.access_token);
        handleRedirection();
      } else if (data.userAlreadyExist) {
        setExistingTwitterUserDetails(data.userAlreadyExist);
        setIsTwitterAccountAlreadyConnectedModalOpen(true);
      }
    },
    onError: (error: Error | AxiosError) => {
      if (
        (error as AxiosError<any, any>)?.response?.data?.error?.stack?.includes(
          "duplicate key"
        )
      )
        toast.error(
          "Twitter account already connected with a different profile."
        );
      else toast.error("Something went wrong while connecting to twitter");
      console.error(error);
      handleRedirection();
    },
  });

  useEffect(() => {
    if (searchParams.get("oauth_token") && searchParams.get("oauth_verifier")) {
      postConnectTwitter({
        oauthToken: searchParams.get("oauth_token") as string,
        oauthVerifier: searchParams.get("oauth_verifier") as string,
      });
    }
  }, [searchParams, postConnectTwitter]);

  const navigate = useDaoLensNavigate();

  const handleRedirection = () => {
    try {
      const redirectStr = localStorage.getItem(
        TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY
      );
      if (!redirectStr) {
        throw new Error(
          `${TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY} local storage is empty`
        );
      }
      const redirectData: { bountyId: string; daoId: string } =
        JSON.parse(redirectStr);
      if (!redirectData) {
        throw new Error(
          `${TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY} is not valid json or null`
        );
      }

      if (!redirectData?.bountyId || !redirectData?.daoId)
        throw new Error(
          `${TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY} is missing bountyId or daoId`
        );

      const { daoId, bountyId } = redirectData;
      reload();
      navigate(`/app/dao/${daoId}/bounty/${bountyId}`);
    } catch (error) {
      console.error(error);
      navigate("/");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: isMobile ? "20px" : "20px 20px 20px 80px",
      }}
    >
      <TwitterAlreadyConnectedModal
        isOpen={isTwitterAccountAlreadyConnectedModalOpen}
        setIsOpen={setIsTwitterAccountAlreadyConnectedModalOpen}
        userAlreadyExist={existingTwitterUserDetails as any}
        onOk={() => handleRedirection()}
      />
      <Skeleton active paragraph={{ rows: 4 }} />
    </div>
  );
};

export default TwitterBountyRedirection;

import { toast } from "react-toastify";
import customAxios from "../api";

export enum ExpiryDurationEnum {
  ONE_DAY = 1,
  SEVEN_DAYS = 7,
  THIRTY_DAYS = 30,
  NINTY_DAYS = 90,
  NEVER = "NEVER",
}

export type AnnouncementType = {
  title: string;
  description: string;
  createdAt?: string;
  icon?: string | null;
  id?: string;
  expiry: ExpiryDurationEnum | string;
  linkedItems: any[];
};

export const fetchAnnouncementForCommunity = async (daoId: string) => {
  if (!daoId) return [];
  const response = await customAxios.get("/announcement/fetch", {
    params: { daoId, newAnnouncements: true },
  });

  return response.data.announcements;
};

export const deleteAnnouncement = async (announcementId: string) =>
  customAxios.delete("/announcement/delete", { params: { announcementId } });

export const updateAnnouncement = async (
  announcementId: string,
  data: Partial<AnnouncementType>
) => {
  console.log({ announcementId });
  const formData = new FormData();
  formData.append("announcementId", announcementId);
  if (data.title === "") {
    toast.error("Please add title");
    return;
  }
  formData.append("title", data.title!);

  if (data.description === "") {
    toast.error("Please add description");
    return;
  }
  formData.append("description", data.description!);

  if (data.icon) {
    formData.append("files", data.icon);
  }

  await customAxios.patch("/announcement/update", formData);
};

export const createAnnouncement = async (
  data: AnnouncementType,
  daoId: string
) => {
  const formData = new FormData();
  formData.append("daoId", daoId);
  if (data.title === "") {
    toast.error("Please add title");
    return;
  }
  formData.append("title", data.title);

  if (data.description === "") {
    toast.error("Please add description");
    return;
  }
  formData.append("description", data.description);

  formData.append("expiry", data.expiry.toString());

  if (data.linkedItems.length) {
    formData.append(
      "linkedEntities",
      JSON.stringify(
        data.linkedItems.map((ele) => ({
          linkedType: ele.type,
          linkedId: ele.id,
        }))
      )
    );
  }

  if (data.icon) {
    formData.append("files", data.icon);
  }
  await customAxios.post("/announcement/create", formData);
};

export const fetchOldAnnouncementForCommunity = async (daoId: string) => {
  if (!daoId) return [];
  const response = await customAxios.get("/announcement/fetch", {
    params: { daoId, newAnnouncements: false },
  });

  return response.data.announcements;
};

type AnnouncementDetailsType = {
  createdAt: string;
  daoId: string;
  description: string;
  expiry: string;
  icon: string;
  id: string;
  title: string;
  totalLikedVote: number;
  updatedAt: string;
  vote: { type: "LIKE" | "DISLIKE" };
  totalDisLikedVote: number;
  profilePicture: string;
  userName: string;
  userId: string;
};

export const fetchAnnouncementById = async (announcementId: string) => {
  const response = await customAxios.get("/announcement/fetch/details", {
    params: { announcementId },
  });

  return response.data?.res as AnnouncementDetailsType;
};

type VoteOptions = {
  increase: boolean;
  announcementId: string;
  type: "like" | "dislike";
};

export const voteOnAnnouncement = async ({
  announcementId: announcementsId,
  increase,
  type,
}: VoteOptions) =>
  customAxios.post("/announcement/vote", { type, announcementsId, increase });

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Menu, Row, Dropdown, Modal, Skeleton } from "antd";
import styled from "styled-components";
import { toast } from "react-toastify";
import InviteIcon from "./../assets/images/profile/Vector.svg";
import daoDefaultBg from "./../assets/images/daoSideBar/daoDefaultBg.png";
import blockSocLogo from "./../assets/images/logos/blockSoclogo.png";
import DaoBounty from "../images/bounty-icon.svg";
import OverviewIcon from "../images/overview-icon.svg";
import MembersIcon from "../images/members-icon.svg";
import ProposalsIcon from "../images/proposal-icon.svg";
import DocumentIcon from "./../assets/images/documents/document.svg";
import SpacesIcon from "../images/spaces-icon.svg";
import ProjectsIcon from "../images/project-icon.svg";
import CourseIcon from "../images/course-icon.svg";
import EventsIcon from "../assets/images/events/events.svg";
import HorizontalDot from "../assets/iconSvg/horizontalthreedot.svg";
import GreyHorizontalDot from "../images/grey-horizontal-dot.svg";
import claimBg from "./../assets/images/daoSideBar/claimBg.png";
import customAxios from "../api";
import CreateChannelModal from "./SideMenu/CreateChannnelModal";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableChannelItem } from "./SideMenu/SortableChannelItem";
import { ReactComponent as AddIcon } from "../assets/images/daoSideBar/add.svg";
import { getTrunckedString } from "./Proposal/ProposalCard";
import { DaoContext } from "../layouts/app/DaoContext";
import banResponseImage from "./../assets/images/daoOverview/banResponse.png";
import { CopyOutlined } from "@ant-design/icons";
import { getDefaultDaoImage, postMixpanel } from "../utils";
import {
  COMMUNITY_TYPES,
  JOIN_DAO_BUTTON_ID_MD,
  MD_SCREEN_BREAKPOINT,
} from "../constants";
import ClaimCommunity from "./ClaimCommunity";
import IconWrapper from "./IconWrapper";
import {
  CheckBadgeIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/solid";
import { UserContext } from "../layouts/app/UserContext";
import useWindowSize from "../hooks/useWindowSize";
import { confirmOperation } from "../utils/common";
import { SideBarItemType } from "../lib/types/sidebar";
import SidemenuModules from "./SidemenuModules";
import InviteModal from "./Spaces/InviteModal";
import SidebarHeader from "./Spaces/SidebarHeader";
import JoinSpaceModal from "./Spaces/JoinSpaceModal";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
import SecondaryButtonOutlined from "./InternalComponents/PostSecondaryButtonOutlined";
import useDisourse from "../hooks/useDiscourse";
const { SubMenu } = Menu;

const StyledCapsule = styled.div`
  color: #64748b;
  width: fit-content;
  padding: 4px 8px;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 6.62787px;
`;

const StyledTitleDiv = styled.div`
  position: absolute;
  bottom: 3%;
  left: 30%;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
`;

const StyledMenu = styled(Menu)`
  & .ant-menu-item-selected {
    background-color: #f7f7f7 !important;
  }

  & .ant-menu-item:hover {
    background-color: #f7f7f7 !important;
  }
  & .ant-menu-item {
    background-color: #fff !important;
  }

  & .ant-menu-sub.ant-menu-inline {
    background-color: #fff !important;
  }
`;

const StyledDotIconContainer = styled.div`
  & img:hover {
    cursor: pointer;
  }
`;

const StyledDropdownMenu = styled(Menu)`
  border-radius: 8px;
`;

const StyledModal = styled(Modal)`
  top: ${(props) => (props.isMobile ? "auto !important" : "")};
  bottom: ${(props) => (props.isMobile ? "0 !important" : "")};
  position: ${(props) => (props.isMobile ? "absolute" : "")};
  left: ${(props) => (props.isMobile ? "0" : "")};
  right: ${(props) => (props.isMobile ? "0" : "")};
  // // padding: ${(props) => (props.isMobile ? "0 !important" : "")};

  & .ant-modal-content {
    width: ${(props) =>
      props.isMobile ? "100vw" : props.width ? props.width : "600px"};
  }
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  & .ant-modal-body {
    padding: 0 !important;
  }
  & .ant-modal-close-x {
    color: #f3f3f3;
  }
`;

const joinInviteButtonStyles = {
  position: "absolute",
  top: "10%",
  cursor: "pointer",
  left: "68%",
  fontSize: "12px",
  width: "76px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "42px",
  marginLeft: "4px",
};

const Sidemenu = () => {
  const [canCreateChannel, setCanCreateChannel] = useState(false);
  const [showAddButton, setShowAddButton] = useState(false);
  const [showChannelModal, setShowChannelModal] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Overview");
  const [showJoinDaoModal, setShowJoinDaoModal] = useState(false);
  const [channel, setChannel] = useState(null);
  const [claimCommunityModalOpen, setClaimCommunityModalOpen] = useState(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { windowSize, isMobile } = useWindowSize();
  const [itemsNew, setItemNew] = useState<SideBarItemType[]>([]);
  const {
    daoDetails,
    isCurrentDaoJoined,
    canView,
    showJoinCommunityModal,
    setShowJoinCommunityModal,
    loading,
    reload: daoReload,
    isAdmin,
    isSpace,
    parentDaoDetails,
    isInviteModalOpen,
    setIsInviteModalOpen,
    daoId,
  } = useContext(DaoContext);
  const { status } = useDisourse(daoId);
  const parentDaoId = daoDetails?.parentDaoId;

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [canJoinDao, setCanJoinDao] = useState(true);

  const claimAccessRef = useRef(null);
  const [inviteButtonMargin, setInviteButtonMargin] = useState("16px");

  useEffect(() => {
    if (claimAccessRef?.current) {
      let rect = (claimAccessRef.current as any).getBoundingClientRect();
      setInviteButtonMargin(`calc(100vh - 123px - ${rect.y + rect.height}px)`);
    }
  });

  useEffect(() => {}, []);
  useEffect(() => {
    let items: SideBarItemType[] = [
      {
        title: "Overview",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/overview`,
        icon: OverviewIcon,
        isModule: false,
      },
      {
        title:
          "Members" +
          (daoDetails?.contributors ? ` (${daoDetails?.contributors})` : ""),
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/members`,
        icon: MembersIcon,
        isModule: false,
      },
    ];
    if (daoDetails?.bountyDisable !== undefined && !daoDetails?.bountyDisable) {
      items.push({
        title: "Bounties",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/bounty`,
        icon: DaoBounty,
        isModule: true,
      });
    }
    if (
      daoDetails?.projectsDisable !== undefined &&
      !daoDetails?.projectsDisable
    ) {
      items.push({
        title: "Projects",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/project`,
        icon: ProjectsIcon,
        isModule: true,
      });
    }
    if (
      daoDetails?.proposalsDisable !== undefined &&
      !daoDetails?.proposalsDisable
    ) {
      items.push({
        title: "Proposals",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/proposal`,
        icon: ProposalsIcon,
        isModule: true,
      });
    }
    if (
      daoDetails?.coursesDisable !== undefined &&
      !daoDetails?.coursesDisable
    ) {
      items.push({
        title: "Courses",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/course`,
        subRoutes: [`/app/dao/${daoDetails.daoName}/${daoId}/course/`],
        isModule: true,
        icon: CourseIcon,
      });
    }
    if (
      daoDetails?.documentsDisable !== undefined &&
      !daoDetails?.documentsDisable
    ) {
      items.push({
        title: "Documents",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/library`,
        subRoutes: [`/app/dao/${daoDetails.daoName}/${daoId}/library/`],
        isModule: true,
        icon: DocumentIcon,
      });
    }

    if (daoDetails?.eventsDisable !== undefined && !daoDetails?.eventsDisable) {
      items.push({
        title: "Events",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/events`,
        icon: EventsIcon,
        isModule: true,
      });
    }

    if (!isSpace && !daoDetails?.spacesDisable) {
      items.push({
        title: "Spaces",
        route: `/app/dao/${daoDetails?.daoName}/${daoId}/spaces`,
        icon: SpacesIcon,
        isModule: true,
        isNew: true,
      });
    }
    setItemNew(items);
  }, [
    daoDetails?.bountyDisable,
    daoDetails?.coursesDisable,
    daoDetails?.proposalsDisable,
    daoDetails?.projectsDisable,
    daoDetails?.documentsDisable,
    daoDetails?.eventsDisable,
    daoId,
    daoDetails.daoName,
    isSpace,
    parentDaoId,
  ]);

  useEffect(() => {
    if (!showChannelModal) setChannel(null);
  }, [showChannelModal]);

  const leaveDaoHandler = () => {
    customAxios
      .post(`dao/leave`, { daoId: daoId })
      .then((res) => {
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const { reload, userDetails, showLoginModal, setMobileSidebarOpen } =
    useContext(UserContext);

  const inviteDaoHandler = ({ popup }) => {
    let url = window.location.href.split(daoId);
    navigator.clipboard
      .writeText(`${url[0]}${daoId}/overview?invite=true`)
      .then(
        () => {
          postMixpanel(
            "track",
            popup
              ? "copied_community_invite_link_through_popup"
              : "copied_community_invite_link",
            {
              dao_id: daoId,
              dao_name: daoDetails?.name,
            }
          );
          if (popup) setInviteModalOpen(false);
          toast.success("Invite link copied successfully");
        },
        () => {
          toast.error("Link not copied");
        }
      )
      .catch((err) => {
        console.error("error", err);
      });
  };

  const settingsMenu = (
    <StyledDropdownMenu>
      {isAdmin && (
        <Menu.Item
          onClick={() => {
            setMobileSidebarOpen(false);
            navigate(
              `/app/dao/${daoDetails?.daoName}/${daoId}/settings/profile`,
              { state: { from: window.location?.pathname } }
            );
          }}
          style={{ borderRadius: "8px" }}
        >
          <i className="fas fa-cog" style={{ marginRight: "10px" }} />
          Settings
        </Menu.Item>
      )}
      {isCurrentDaoJoined === true &&
        !daoDetails?.userRoles?.includes("ADMIN") && (
          <Menu.Item
            style={{ borderRadius: "8px", color: "red" }}
            onClick={() =>
              confirmOperation({
                onOk: leaveDaoHandler,
                cancelText: "Cancel",
                confirmText: "Leave",
                message: "Are you sure you want to leave this community?",
                title: "Leave community",
              })
            }
          >
            <i
              className="fas fa-sign-out-alt"
              style={{ marginRight: "10px" }}
            />
            Leave
          </Menu.Item>
        )}
    </StyledDropdownMenu>
  );
  useEffect(() => {
    if (daoId) {
      customAxios
        .post(`daochannel/cancreate`, { daoId: daoId })
        .then((res) => {
          setCanCreateChannel(res.data.canView ? true : false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [daoId]);
  const handleDeleteChannel = (id) => {
    customAxios
      .delete(`daochannel/delete`, {
        data: {
          channelId: id,
        },
      })
      .then((res) => {
        toast.success("Channel deleted successfully");
        navigate(`/app/dao/${daoDetails?.daoName}/${daoId}/overview`);
        fetchChannelList();
      })
      .catch((err) => {
        console.error("error");
      });
  };
  const handleEditChannel = (channelDetails) => {
    setChannel(channelDetails);
    setShowChannelModal(true);
  };

  useEffect(() => {
    const activeTitle = itemsNew?.find(
      (item) =>
        location.pathname.startsWith(item.route) ||
        item?.subRoutes?.find((route) => location.pathname.startsWith(route))
    )?.title;
    activeTitle && setSelectedMenuItem(activeTitle);
  }, [location.pathname, itemsNew, daoDetails?.daoName]);

  const [channelList, setChannelList] = useState<any[]>([]);
  const [updater, setUpdater] = useState(false);
  let navigate = useDaoLensNavigate();

  const fetchChannelList = useCallback(() => {
    customAxios.post("/daochannel/fetch", { daoId: daoId }).then((res) => {
      setChannelList(res.data.channelList);
    });
  }, [daoId]);

  useEffect(() => {
    if (daoId) {
      fetchChannelList();
    }
  }, [daoId, updater, fetchChannelList]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const items = channelList.map((ele) => ele.daoChannelId);

      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      const orderedItems = arrayMove(items, oldIndex, newIndex);

      setChannelList((prev) => {
        const orderedChannels = orderedItems.map((orderId) =>
          prev.find((ele) => ele.daoChannelId === orderId)
        );
        return orderedChannels;
      });
      customAxios
        .post("/daochannel/reorderchannel", {
          channelId: active.id,
          order: newIndex + 1,
          type: newIndex > oldIndex ? "DOWN" : "UP",
          daoId: daoId,
        })
        .then((res) => {
          if (res.data.success) {
          }
        })
        .catch((err) => {
          console.error("error");
          fetchChannelList();
        });
    }
  };
  return (
    <div
      style={{
        width: isMobile ? 280 : 300,
        maxWidth: isMobile ? 280 : 300,
        position: "sticky",
        top: "0px",
        display: "grid",
        alignSelf: "flex-start",
      }}
    >
      {isSpace && !isMobile && (
        <SidebarHeader
          dropdownContent={settingsMenu}
          isCurrentDaoJoined={isCurrentDaoJoined}
          loading={loading}
          onInvite={() => setIsInviteModalOpen(true)}
        />
      )}
      <div
        style={{
          border: "1px solid #F4F2F2",
          borderRadius: "20px",
          width: isMobile ? 280 : 300,
          maxWidth: isMobile ? 280 : 300,
          background: "white",
          objectFit: "cover",
          position: "sticky",
          top: "0",

          marginTop:
            windowSize.width < MD_SCREEN_BREAKPOINT
              ? "0"
              : isSpace
              ? "8px"
              : "20px",
        }}
      >
        <StyledModal
          visible={inviteModalOpen}
          onCancel={() => setInviteModalOpen(false)}
          width={480}
          footer={null}
          isMobile={isMobile}
          centered
        >
          <div style={{ position: "relative" }}>
            <img
              src={
                daoDetails?.backgroundImage
                  ? daoDetails.backgroundImage
                  : daoDefaultBg
              }
              alt="logo"
              style={{
                borderTopRightRadius: "12px",
                borderTopLeftRadius: "12px",
                objectFit: "cover",
                width: "100%",
                height: "120px",
              }}
            />
            <div style={{ position: "absolute", top: "72%", left: "41%" }}>
              {daoDetails?.profilePicture ? (
                <img
                  src={daoDetails?.profilePicture}
                  alt="dao_logo"
                  style={{
                    borderRadius: "100%",
                    width: "88px",
                    height: "88px",
                    objectFit: "cover",
                    border: "2px solid #ffffff",
                    boxShadow:
                      "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
                  }}
                />
              ) : (
                getDefaultDaoImage(
                  daoDetails?.daoName,
                  {
                    alignSelf: "center",
                    width: "88px",
                    height: "88px",
                    cursor: "pointer",

                    borderRadius: "50%",
                  },
                  { fontSize: "28px" }
                )
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "64px",
              padding: "0px 16px",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                fontWeight: "600",
                color: "#1A202E",
                lineHeight: "28px",
              }}
            >
              Invite members to {daoDetails?.daoName}
            </div>

            <div
              style={{
                color: "#64748B",
                fontSize: "14px",
                lineHeight: "20px",
                fontWeight: "400",
                marginTop: "4px",
                padding: "4px 0px",
                textAlign: "center",
              }}
            >
              Bring in your community to experience what you created and
              collaborate together for community success ✨
            </div>
            <Button
              icon={<CopyOutlined />}
              type="primary"
              style={{
                marginTop: "16px",
                marginBottom: "24px",
                width: "95%",
                height: "40px",
                borderRadius: "12px",
                fontWeight: "500",
              }}
              onClick={() => inviteDaoHandler({ popup: true })}
            >
              Invite Members
            </Button>
          </div>
        </StyledModal>
        {canView === false && (
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,
              background: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(25px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "485px",
                background: "white",
                padding: "12px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <img
                src={banResponseImage}
                style={{ width: "100%" }}
                alt="banned"
              />
              <div
                style={{ width: "341px", font: "normal 600 20px/28px Inter" }}
              >
                🚫 Woah! Looks like you have been banned from this community
              </div>
              <Button
                onClick={() => {
                  navigate("/app/profile");
                }}
                type="primary"
                style={{
                  borderRadius: "12px",
                  width: "100%",
                  height: "40px",
                  font: "normal 600 14px/20px Inter",
                }}
              >
                {" "}
                Okay, life goes on
              </Button>
            </div>
          </div>
        )}
        {!isSpace && showJoinDaoModal && (
          <StyledModal
            visible={showJoinDaoModal}
            onCancel={() => setShowJoinDaoModal(false)}
            width={458}
            footer={null}
            centered
          >
            <div style={{ position: "relative" }}>
              <img
                src={
                  daoDetails?.backgroundImage
                    ? daoDetails.backgroundImage
                    : daoDefaultBg
                }
                alt="logo"
                style={{
                  borderTopRightRadius: "12px",
                  borderTopLeftRadius: "12px",
                  objectFit: "cover",
                  width: "100%",
                  height: "120px",
                }}
              />
              <div style={{ position: "absolute", top: "72%", left: "41%" }}>
                {daoDetails?.profilePicture ? (
                  <img
                    src={daoDetails?.profilePicture}
                    alt="dao_logo"
                    style={{
                      borderRadius: "100%",
                      width: "88px",
                      height: "88px",
                      objectFit: "cover",
                      border: "2px solid #ffffff",
                      boxShadow:
                        "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                ) : (
                  getDefaultDaoImage(
                    daoDetails?.daoName,
                    {
                      alignSelf: "center",
                      width: "88px",
                      height: "88px",
                      cursor: "pointer",

                      borderRadius: "50%",
                    },
                    { fontSize: "28px" }
                  )
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "64px",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#1A202E",
                  lineHeight: "28px",
                }}
              >
                {getTrunckedString(daoDetails?.daoName, 15)}
              </div>
              <Row
                style={{
                  columnGap: "8px",
                  margin: "12px 0px 8px 0px",
                }}
              >
                <StyledCapsule>👥 : {daoDetails.contributors}</StyledCapsule>
                <StyledCapsule>💰 : {daoDetails.bounty}</StyledCapsule>
                {daoDetails.communityType ? (
                  <StyledCapsule>
                    {
                      COMMUNITY_TYPES.find(
                        (type) => daoDetails.communityType === type.id
                      )?.name
                    }
                  </StyledCapsule>
                ) : null}
              </Row>
              <div
                style={{
                  color: "#64748B",
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  marginTop: "4px",
                }}
              >
                Join the community space to engage on the platform
              </div>
              <Button
                type="primary"
                style={{
                  marginTop: "24px",
                  marginBottom: "24px",
                  width: "80%",
                  height: "40px",
                  borderRadius: "12px",
                  fontWeight: "500",
                }}
                onClick={() => {
                  if (userDetails) {
                    joinDaoHandler();
                  } else {
                    showLoginModal();
                  }
                }}
              >
                Join 🤝
              </Button>
            </div>
          </StyledModal>
        )}

        {isSpace && (
          <JoinSpaceModal
            daoDetails={daoDetails as any}
            isOpen={showJoinDaoModal}
            setIsOpen={setShowJoinDaoModal}
          />
        )}

        {windowSize.width < MD_SCREEN_BREAKPOINT ? (
          <div
            style={{
              position: "relative",
              marginBottom: "2rem",
              background: "white",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
            }}
            className="flex items-center justify-between px-4 pt-2"
          >
            <button
              onClick={() => setShowJoinDaoModal(true)}
              id={JOIN_DAO_BUTTON_ID_MD}
              style={{ display: "none" }}
            />
            <div className="flex gap-2 items-center">
              {daoDetails?.iconEmoji ? (
                <div
                  style={{ background: daoDetails?.iconBackground }}
                  className="text-3xl w-12 h-12 rounded-full flex items-center justify-center"
                >
                  {daoDetails?.iconEmoji}
                </div>
              ) : (
                <>
                  {daoDetails.profilePicture && (
                    <img
                      src={daoDetails.profilePicture}
                      className="w-12 h-12 rounded-full flex items-center justify-center object-cover"
                      alt="Profile"
                    />
                  )}
                </>
              )}
              <div className="flex flex-col gap-1">
                {isSpace && parentDaoDetails?.daoName && (
                  <span className="uppercase font-semibold text-xs text-gray-400">
                    {getTrunckedString(parentDaoDetails?.daoName, 15)}
                  </span>
                )}
                <span
                  style={{ fontSize: "16px", fontWeight: "600" }}
                  className="text-gray-800"
                >
                  {getTrunckedString(daoDetails?.daoName, 15)}
                </span>
              </div>
            </div>

            {!loading && isCurrentDaoJoined && (
              <div
                style={{
                  cursor: "pointer",
                  color: "white",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "42px",
                }}
              >
                <Dropdown overlay={settingsMenu} trigger={["click"]}>
                  <div>
                    <IconWrapper width="24px">
                      <EllipsisHorizontalIcon
                        width="24px"
                        style={{ color: "grey" }}
                      />
                    </IconWrapper>
                  </div>
                </Dropdown>
              </div>
            )}
          </div>
        ) : !isSpace ? (
          <div
            style={{
              position: "relative",
              marginBottom: "2rem",
              background: "white",
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
            }}
          >
            <img
              src={
                daoDetails?.backgroundImage
                  ? daoDetails.backgroundImage
                  : daoDefaultBg
              }
              alt="logo"
              style={{
                borderTopRightRadius: "20px",
                borderTopLeftRadius: "20px",
                objectFit: "cover",
              }}
              height="84px"
              width="100%"
            />

            <div
              style={{
                background:
                  "linear-gradient(360deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
                height: "84px",
                position: "absolute",
                width: "100%",
                top: 0,
              }}
            ></div>
            <div style={{ position: "absolute", top: "55%", left: "7%" }}>
              {loading ? (
                getDefaultDaoImage(
                  "",
                  {
                    alignSelf: "center",
                    width: "60px",
                    height: "60px",
                    cursor: "pointer",

                    borderRadius: "50%",
                  },
                  { fontSize: "20px" }
                )
              ) : daoDetails?.profilePicture ? (
                <img
                  src={
                    daoDetails?.profilePicture
                      ? daoDetails?.profilePicture
                      : blockSocLogo
                  }
                  alt="dao_logo"
                  style={{
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    objectFit: "cover",
                    border: "1px solid #ffffff",
                    boxShadow:
                      "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
                    background: "white",
                  }}
                  height="60px"
                  width="70px"
                />
              ) : (
                getDefaultDaoImage(
                  daoDetails?.daoName,
                  {
                    alignSelf: "center",
                    width: "60px",
                    height: "60px",
                    cursor: "pointer",

                    borderRadius: "50%",
                  },
                  { fontSize: "20px" }
                )
              )}
            </div>

            <StyledTitleDiv
              style={{ textShadow: "0px 0px 1px rgba(0, 0, 0, 0.24)" }}
            >
              {loading ? <Skeleton active paragraph={{ rows: 1 }} /> : null}
              {!loading ? getTrunckedString(daoDetails?.daoName, 15) : null}
            </StyledTitleDiv>

            {!loading && isCurrentDaoJoined && (
              <>
                <div
                  style={{
                    position: "absolute",
                    top: "10%",
                    cursor: "pointer",
                    color: "white",
                    right: selectedMenuItem !== "Overview" ? "33%" : "5%",
                    width: "32px",
                    height: "32px",
                    backgroundColor: "rgba(0, 0, 0, 0.16)",
                    boxShadow:
                      "0px 4px 8px rgba(0, 0, 0, 0.08), inset 3.6px -3.6px 3.6px rgba(0, 45, 121, 0.1), inset -3.6px 3.6px 3.6px rgba(255, 255, 255, 0.1)",
                    backdropFilter: "blur(3.6px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "42px",
                  }}
                >
                  <Dropdown overlay={settingsMenu} trigger={["click"]}>
                    <StyledDotIconContainer>
                      <img
                        src={HorizontalDot}
                        alt="dot icon"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      />
                    </StyledDotIconContainer>
                  </Dropdown>
                </div>
                {selectedMenuItem !== "Overview" && (
                  <div
                    id="invite-button"
                    style={{
                      ...(joinInviteButtonStyles as any),
                      background:
                        "linear-gradient(98.56deg, #8FBAFF 0%, #8FBAFF 0.01%, #3E87FF 94.89%)",
                      boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.1)",
                      backdropFilter: "blur(3.6px)",
                      color: "#FFFFFF",
                      fontWeight: "600",
                      padding: "8px 12px 8px 12px",
                      borderRadius: "12px",
                      border: "0.5px solid #DBEAFE",
                    }}
                    onClick={() => setIsInviteModalOpen(true)}
                  >
                    <img
                      src={InviteIcon}
                      alt="invite"
                      style={{ marginRight: "8px" }}
                    />
                    Invite
                  </div>
                )}
              </>
            )}
          </div>
        ) : null}
        {loading && <Skeleton active />}
        {!loading && (
          <StyledMenu
            mode="inline"
            selectedKeys={[selectedMenuItem]}
            selectable={true}
            // @ts-expect-error
            defaultOpenKeys={[undefined, "discussions"]}
            style={{
              maxHeight: isMobile ? "56vh" : "70vh",
              overflowY: "auto",
              borderRadius: isSpace ? "16px" : "0 0 12px 12px",
              overflowX: "hidden",
            }}
          >
            {itemsNew
              .filter((item) => !item.isModule)
              .map((item) => {
                if (!item.submenu) {
                  return (
                    <Menu.Item
                      key={item.title}
                      style={{
                        margin: 0,

                        borderBottom:
                          item.title === "Upcoming Events" ||
                          item.title === "Your Project"
                            ? "1px solid #F4F2F2"
                            : "none",
                        color:
                          selectedMenuItem === item.title ? "#1890ff" : "black",
                        borderRight:
                          selectedMenuItem === item.title
                            ? "1px solid #1890ff"
                            : "none",
                        backgroundColor:
                          selectedMenuItem === item.title
                            ? "#f7f7f7"
                            : "initial",
                      }}
                      onClick={() => {
                        setSelectedMenuItem(item.title);
                        if (windowSize.width < MD_SCREEN_BREAKPOINT)
                          setMobileSidebarOpen(false);
                        navigate(`${item.route}`);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <img
                            src={item.icon}
                            alt="icon"
                            style={{ marginRight: "10px" }}
                          />
                          {item.title}
                        </div>
                        {item?.notifications && (
                          <div
                            style={{
                              background: "#3B82F6",
                              borderRadius: "50px",
                              color: "white",
                              width: "40px",
                              height: "20px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "4%",
                            }}
                          >
                            {item.notifications}
                          </div>
                        )}
                      </div>
                    </Menu.Item>
                  );
                }
                return null;
              })}
            <div className="h-px w-full border border-solid bg-zinc-100 border-zinc-100 mt-1" />
            <SidemenuModules
              menuItems={itemsNew.filter((item) => item.isModule)}
              selectedMenuItem={selectedMenuItem}
              setMobileSidebarOpen={setMobileSidebarOpen}
              setSelectedMenuItem={setSelectedMenuItem}
            />
            <div className="h-px w-full border border-solid bg-zinc-100 border-zinc-100 mt-1" />

            {daoDetails?.discussionDisable !== undefined &&
              !daoDetails.discussionDisable && (
                <SubMenu
                  className="sidebar"
                  key={"discussions"}
                  onTitleMouseEnter={() => setShowAddButton(true)}
                  onTitleMouseLeave={() => setShowAddButton(false)}
                  style={{ borderRadius: "12px" }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        position: "relative",
                      }}
                    >
                      <div>
                        <span className="m-0 text-gray-500 font-semibold">
                          All Discussions
                        </span>
                        {(canCreateChannel || isAdmin) &&
                        channelList.length > 0 ? (
                          <AddIcon
                            style={{
                              width: "24px",
                              height: "24px",
                              position: "absolute",
                              right: "0.2rem",
                              top: "0.5rem",
                            }}
                            onClick={(e) => {
                              postMixpanel(
                                "track",
                                "clicked_add_channel_button"
                              );
                              setShowChannelModal(true);
                              e.stopPropagation();
                            }}
                          />
                        ) : null}
                      </div>{" "}
                    </div>
                  }
                >
                  {status === "IMPORTING" ? (
                    <div className="flex items-center pl-[24px] pr-[34px] pt-2 pb-2 text-[#6B7280] text-xs">
                      <svg
                        style={{
                          marginRight: "4px",
                          animation:
                            "anti-clock-wise-rotation 2s infinite linear",
                        }}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2.66675 2.66654V5.99987H3.05475M13.2921 7.3332C13.1446 6.16178 12.6127 5.07238 11.7797 4.23572C10.9466 3.39907 9.85951 2.86245 8.68873 2.70996C7.51795 2.55748 6.32967 2.79775 5.3101 3.39311C4.29054 3.98848 3.49731 4.90529 3.05475 5.99987M3.05475 5.99987H6.00008M13.3334 13.3332V9.99987H12.9461M12.9461 9.99987C12.5029 11.0938 11.7094 12.0099 10.69 12.6047C9.67051 13.1996 8.48257 13.4395 7.31215 13.2871C6.14172 13.1347 5.05488 12.5985 4.22177 11.7624C3.38866 10.9263 2.85632 9.8375 2.70808 8.66654M12.9461 9.99987H10.0001"
                          stroke="#64748B"
                          stroke-width="1.33333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Importing from discourse
                    </div>
                  ) : null}
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={channelList.map((ele) => ele.daoChannelId)}
                      strategy={verticalListSortingStrategy}
                    >
                      {channelList.map((item, id) => (
                        <SortableChannelItem
                          handleDeleteChannel={handleDeleteChannel}
                          handleEditChannel={handleEditChannel}
                          selectedMenuItem={selectedMenuItem}
                          key={item.daoChannelId}
                          canCreateChannel={canCreateChannel || isAdmin}
                          setSelectedMenuItem={setSelectedMenuItem}
                          id={item.daoChannelId}
                          item={item}
                          daoName={daoDetails?.daoName}
                          onClick={() => {
                            if (windowSize.width < MD_SCREEN_BREAKPOINT)
                              setMobileSidebarOpen(false);
                          }}
                          itemsNew={itemsNew}
                        />
                      ))}
                      {(canCreateChannel || isAdmin) &&
                      channelList.length === 0 ? (
                        <Menu.Item style={{ paddingLeft: "28px" }}>
                          <div
                            style={{ color: "#40A9FF", paddingLeft: "0px" }}
                            onClick={(e) => {
                              postMixpanel(
                                "track",
                                "clicked_add_channel_button"
                              );
                              setShowChannelModal(true);
                              e.stopPropagation();
                            }}
                          >
                            {" "}
                            <i
                              className="fa-solid fa-plus"
                              style={{
                                color: "#40A9FF",
                                fontSize: "14px",
                                marginRight: "0.2rem",
                                paddingLeft: "0px",
                              }}
                            ></i>
                            Add Channel
                          </div>
                        </Menu.Item>
                      ) : null}
                    </SortableContext>
                  </DndContext>
                </SubMenu>
              )}
          </StyledMenu>
        )}

        {showChannelModal ? (
          <CreateChannelModal
            channelDetails={channel}
            setUpdater={setUpdater}
            showChannelModal={showChannelModal}
            setShowChannelModal={setShowChannelModal}
          />
        ) : null}
      </div>
      {claimCommunityModalOpen ? (
        <ClaimCommunity
          modalOpen={claimCommunityModalOpen}
          onClose={() => setClaimCommunityModalOpen(false)}
        />
      ) : null}
      {daoDetails?.isClaimed === false && !isSpace ? (
        <div
          style={{
            marginTop: "16px",
            padding: "8px 16px",
            display: "flex",
            backgroundImage: `url(${claimBg})`,
            backgroundBlendMode: "multiply",
            border: "1px solid rgba(237, 235, 254, 1)",
            backgroundSize: "cover",
            backgroundColor: "rgb(235 233 255)",
            borderRadius: "12px",
            cursor: "pointer",
            alignSelf: "flex-start",
          }}
          onClick={() => {
            setClaimCommunityModalOpen(true);
          }}
          ref={claimAccessRef}
        >
          <h4
            style={{
              marginBottom: "0px",
              fontWeight: "400",
              verticalAlign: "center",
            }}
          >
            <IconWrapper style={{ marginRight: "4px" }}>
              <CheckBadgeIcon style={{ color: "#3F83F8", width: "16px" }} />
            </IconWrapper>
            <span style={{ fontWeight: "600" }}>Claim Access</span> to{" "}
            {daoDetails?.daoName} community{" "}
          </h4>
        </div>
      ) : null}
      {selectedMenuItem !== "Overview" && isMobile && isCurrentDaoJoined && (
        <div
          id="invite-button"
          style={{
            background:
              "linear-gradient(98.56deg, #8FBAFF 0%, #8FBAFF 0.01%, #3E87FF 94.89%)",
            boxShadow: "0px 2px 14px rgba(0, 0, 0, 0.1)",
            backdropFilter: "blur(3.6px)",
            color: "#FFFFFF",
            padding: "8px 12px 8px 12px",
            borderRadius: "12px",
            border: "0.5px solid #DBEAFE",
            display: "flex",
            justifyContent: "center",
            alignSelf: "flex-end",
            marginTop: inviteButtonMargin,
            fontWeight: "600",
          }}
          onClick={() => setIsInviteModalOpen(true)}
        >
          <img src={InviteIcon} alt="invite" style={{ marginRight: "8px" }} />
          Invite members
        </div>
      )}
      {isInviteModalOpen ? (
        <InviteModal
          isOpen={isInviteModalOpen}
          setIsOpen={setIsInviteModalOpen}
        />
      ) : null}
    </div>
  );
};

export default Sidemenu;

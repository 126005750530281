export const postProposalType = [
  {
    value: "single-choice",
    label: "Single Choice",
    description: "Users can choose only one option ",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#single-choice-voting",
  },
  {
    value: "approval",
    label: "Multi-choice",
    description: "Users can choose multiple options",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#approval-voting",
  },
  //   {
  //     value: "quadratic",
  //     label: "Quadratic",
  //     description: "Users can approve a certain number of options ",
  //     learnMoreUrl:
  //       "https://docs.snapshot.org/user-guides/proposals/voting-types#quadratic-voting",
  //   },
  //   {
  //     value: "ranked-choice",
  //     label: "Ranked Choice",
  //     description:
  //       "Users can rank the different choices in their order of preference",
  //     learnMoreUrl:
  //       "https://docs.snapshot.org/user-guides/proposals/voting-types#single-choice-voting",
  //   },
  {
    value: "weighted",
    label: "Weighted",
    description: "Users can spread their votes over multiple options",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#weighted-voting",
  },
  // {
  //   value: "basic",
  //   label: "Basic",
  //   description:
  //     "Users can abstain from voting while still participating in quorum ",
  //   learnMoreUrl:
  //     "https://docs.snapshot.org/user-guides/proposals/voting-types#single-choice-voting",
  // },
];

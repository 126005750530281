import { InfoCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import customAxios from "../../api";
import useWindowSize from "../../hooks/useWindowSize";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { Modal } from "../../styles/ModalStyles";
import IconWrapper from "../IconWrapper";

function DiscordSyncModal({
  syncModalOpen,
  onClose,
  onSuccess,
  discordConnectionUrl,
}) {
  const [syncLoading, setSyncLoading] = useState(false);
  const { daoDetails, daoId } = useDaoContext();
  const isDiscordConnected = !!daoDetails.discordGuildId;
  const { isMobile } = useWindowSize();
  const syncWithDiscord = () => {
    setSyncLoading(true);
    customAxios
      .get(`/affair/sync/discord/affair?daoId=${daoId}`)
      .then((res) => {
        setSyncLoading(false);
        onSuccess();
      })
      .catch((err) => {
        toast.error("Couldn't sync with discord");
        onClose();
      });
  };
  return (
    <Modal
      visible={syncModalOpen}
      width={isMobile ? "auto" : "450px"}
      height={"auto"}
      isMobile={isMobile}
      centered={true}
      onCancel={onClose}
      title={
        <h4
          style={{
            marginLeft: "4px",
            fontWeight: "600",
            fontSize: "14px",
            marginBottom: "0px",
          }}
        >
          Sync events with Discord
        </h4>
      }
      footer={null}
    >
      <div
        style={{
          padding: "20px 16px",
          paddingTop: "8px",
          display: "flex",
          gap: "12px",
          flexDirection: "column",
        }}
      >
        <h5 style={{ fontWeight: "400", color: "#8D9198" }}>
          By syncing events with Discord, all the events created here will be
          visible on Discord and all the events created on Discord will be
          visible here!
          <br />
          <br />
          Auto-sync happens in every 6 hours, you can manually sync as well.
        </h5>
        <div
          style={{
            background: "#EFF6FF",
            padding: "8px 16px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            borderRadius: "8PX",
          }}
        >
          <IconWrapper>
            <InfoCircleFilled style={{ color: "#3B82F6" }} />
          </IconWrapper>
          <h5 style={{ color: "#3B82F6", marginBottom: "0px" }}>
            You can change this later while creating an event.
          </h5>
        </div>
        <Button
          type="primary"
          disabled={syncLoading}
          block={true}
          onClick={() => {
            if (isDiscordConnected) syncWithDiscord();
            else window.open(discordConnectionUrl, "_self");
          }}
          style={{
            borderRadius: "8px",
            background: "#3B82F6",
            fontSize: "12px",
          }}
        >
          Sync Discord
        </Button>
      </div>
    </Modal>
  );
}

export default DiscordSyncModal;

import React, { useState, useEffect, useContext } from "react";
// import { Helmet } from "react-helmet";
import { Modal, Button, Typography, Input } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import styled from "styled-components";
import { toast } from "react-toastify";

import customAxios from "../api";
import ShareIcon from "../images/share.svg";
import TwitterIcon from "../images/twitter.svg";
import TelegramIcon from "../images/telegram.svg";
import GradientBackground from "../images/gradient_share.png";
import { ReactComponent as CopyIcon } from "../assets/images/bountyPage/copy.svg";
import { DaoContext } from "../layouts/app/DaoContext";
import { postMixpanel } from "../utils";
import { useParams } from "react-router";
const { Paragraph } = Typography;

const StyledImage = styled.img`
  &:hover {
    cursor: pointer;
  }
  margin-right: 14px;
`;

const StyledModal = styled(Modal)`
  & .ant-modal-content,
  .ant-modal-header {
    border-radius: 1rem;
  }
`;

// const parser = new DOMParser();

const ShareButtonModal = ({ handleCancel, isModalOpen, entity, link = "" }) => {
  const { daoDetails } = useContext(DaoContext);
  const {
    daoId,
    bountyId,
    postId,
    courseId,
    channelId,
    proposalId,
    eventId,
    projectId,
    document_id,
    spaceId,
  } = useParams();
  let url = window.location.href;

  const twitterIconClickHandler = () => {
    postMixpanel("track", "clicked_on_twitter_share_in share_modal", {
      share_url: link ? link : url,
      dao_id: daoId,
      bounty_id: bountyId,
      post_id: postId,
      course_id: courseId,
      document_id: document_id,
      channel_id: channelId,
      proposal_id: proposalId,
      project_id: projectId,
      event_id: eventId,
      space_id: spaceId,
    });
    const anchor = document.createElement("a");
    anchor.href = `http://twitter.com/share?text=Gm folks⚡️%0ACheck out this interesting ${entity} from ${daoDetails?.daoName.trim()} on Dao Manager:&url=${
      link ? link : url
    }`;
    anchor.target = "_blank";
    anchor.click();
  };

  const telegramIconClickHandler = () => {
    postMixpanel("track", "clicked_on_telegram_icon_in share_modal", {
      share_url: link ? link : url,
      dao_id: daoId,
      bounty_id: bountyId,
      post_id: postId,
      course_id: courseId,
      document_id: document_id,
      channel_id: channelId,
      proposal_id: proposalId,
      project_id: projectId,
      event_id: eventId,
    });
    const anchor = document.createElement("a");
    anchor.href = `https://telegram.me/share/url?text=Gm folks⚡️%0ACheck out this interesting ${entity} from ${daoDetails?.daoName.trim()} on Dao Manager:&url=${
      link ? link : url
    }`;
    anchor.target = "_blank";
    anchor.click();
  };

  const shareIconClickHandler = () => {
    postMixpanel("track", "clicked_on_copy_link_in share_modal", {
      share_url: link ? link : url,
      dao_id: daoId,
      bounty_id: bountyId,
      post_id: postId,
      course_id: courseId,
      document_id: document_id,
      channel_id: channelId,
      proposal_id: proposalId,
      project_id: projectId,
      event_id: eventId,
    });
    navigator.clipboard.writeText(link ? link : url).then(
      () => {
        toast.success("Link copied successfully");
      },
      () => {
        toast.success("Link not copied");
      }
    );
  };

  return (
    <>
      <StyledModal
        width={458}
        visible={isModalOpen}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: "20px",
            color: "#0F172A",
            lineHeight: "24px",
            font: "normal 600 16px/24px Inter",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // alignItems: "center",
          }}
        >
          <span
            style={{ transform: "translateY(-7px)" }}
          >{`Share ${entity}`}</span>
          <div
            style={{
              font: "normal 500 14px/20px Inter",
              background: "#F4F2F2",
              color: "#64748B",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "44px",
              borderRadius: "8px",
              padding: "12px",
            }}
          >
            <Input
              value={link ? link : url}
              disabled
              style={{
                border: "none",
                cursor: "text",
                background: "none",
                color: "#64748B",
              }}
            />
            <CopyIcon
              onClick={shareIconClickHandler}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div style={{ alignSelf: "center" }}>
            <StyledImage
              src={TwitterIcon}
              alt="share icon"
              onClick={twitterIconClickHandler}
            />
            <StyledImage
              src={TelegramIcon}
              alt="share icon"
              onClick={telegramIconClickHandler}
            />
          </div>
        </div>
      </StyledModal>
    </>
  );
};

ShareButtonModal.propTypes = {
  handleCancel: PropTypes.func,
  isModalOpen: PropTypes.bool,
  postTitle: PropTypes.string,
  rawPostDescription: PropTypes.string,
  parsedPostDescription: PropTypes.object,
  createdAt: PropTypes.string,
  postId: PropTypes.string,
  channelId: PropTypes.string,
  daoId: PropTypes.string,
  entity: PropTypes.string,
};

export default ShareButtonModal;

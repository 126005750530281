import {
  CopyOutlined,
  LoadingOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { UserContext } from "../../../../layouts/app/UserContext";
import {
  connectTwitter,
  copyTextToClipboard,
  shareOnTwitter,
} from "../../../../utils";
import {
  PrimaryBountyTaskButton,
  SecondaryBountyTaskButton,
} from "../../BountyDetails";
import { tabs } from "../../CreateBounty/TwitterTask";
import { useNavigate, useParams } from "react-router-dom";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../../../hooks/useDaoLensNavigate";
import TweetModal from "./TweetModal";
export const StyledTaskContainer = styled.div`
  display: flex;
  opacity: 0.5;
  border: 1px solid #e5e7eb;
  ${({ active = true }) =>
    active
      ? `
  
  opacity:1;
 
box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
  `
      : ``};

  padding: 10px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  z-index: 2;
  row-gap: 4px;
`;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
function TwitterTaskDetail({
  twitterTasks,
  handleBountyTasksVerification,
  userSubmission,
  loading,
  canEdit,
  bountyLoading,
  tweetUrls,
  userTwitterDetails,
  setIsPollingPaused,
  reloadBountyDetails,
}) {
  const { userDetails, showLoginModal } = useContext(UserContext);
  const { bountyId } = useParams();
  const { daoId } = useDaoContext();
  const [isTweetModalOpen, setIsTweetModalOpen] = useState(false);

  const handleOnTweetAndVerify = (idx, tweetMsge) => {
    handleBountyTasksVerification({
      twitterSubtaskId: idx,
      type: "TWITTER",
      tweetMsge,
    });
  };

  return (
    <div style={{ display: "flex", rowGap: "24px", flexDirection: "column" }}>
      {twitterTasks.map((ele, idx) => {
        const actionObject = tabs.find((tab) => tab.value === ele.type);
        const active =
          !userSubmission?.length && !idx
            ? true
            : userSubmission[0]?.completedSubtasks - 1 === idx - 1
            ? true
            : false;
        const verified =
          userSubmission?.length &&
          idx <= userSubmission[0]?.completedSubtasks - 1
            ? true
            : false;
        console.log(verified, "veri", active);
        return (
          <div style={{ position: "relative" }}>
            <StyledTaskContainer active={active}>
              <div
                style={{
                  display: "flex",
                  columnGap: "12px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    columnGap: "12px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "16px",
                      width: "16px",
                      border: `3px solid ${verified ? "#31C48D" : "#3F83F8"}`,
                      borderRadius: "50%",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12)",
                    }}
                  ></div>
                  {actionObject.getCompleteActionLabel(ele)}
                </div>

                {!userDetails?.isTwitterConnected && (
                  <Button
                    style={{
                      width: "160px",
                      margin: "8px 0px",
                      borderRadius: "8px",
                    }}
                    type="primary"
                    onClick={() => {
                      if (!userDetails) showLoginModal();
                      else {
                        connectTwitter({
                          callbackUrl: `${window.location.origin}/app/twitter-bounty-redirect`,
                          bountyId,
                          daoId,
                        });
                      }
                    }}
                    icon={<TwitterOutlined />}
                  >
                    Connect twitter
                  </Button>
                )}
              </div>
              {bountyLoading ? null : canEdit ? null : userSubmission?.length &&
                userSubmission?.[0]
                  ?.isWinner ? null : !userDetails?.isTwitterConnected ? null : active ? (
                loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Spin indicator={antIcon} size="small" />
                  </div>
                ) : (
                  <div style={{ display: "flex", columnGap: "8px" }}>
                    {ele.reply ? (
                      <Tooltip title="Copy the reply suggested by the creator of this bounty">
                        <Button
                          type="text"
                          onClick={() => {
                            if (!userDetails) {
                              showLoginModal();
                              return;
                            }
                            copyTextToClipboard(ele.reply).then(() => {
                              toast.success("Text copied successfully");
                            });
                          }}
                          style={{ borderRadius: "8px", fontSize: "12px" }}
                          icon={<CopyOutlined />}
                        >
                          Reply
                        </Button>
                      </Tooltip>
                    ) : null}
                    {ele.type !== "TWEET" && (
                      <SecondaryBountyTaskButton
                        type="primary"
                        onClick={() => {
                          handleBountyTasksVerification({
                            twitterSubtaskId: idx,
                            type: "TWITTER",
                          });
                        }}
                      >
                        Verify
                      </SecondaryBountyTaskButton>
                    )}

                    {ele.type === "TWEET" && (
                      <TweetModal
                        isOpen={isTweetModalOpen}
                        setIsOpen={(val) => {
                          setIsTweetModalOpen(val);
                          setIsPollingPaused(val);
                        }}
                        defaultTweetMsg={ele.tweetMsge}
                        onTweetAndVerify={(tweetMsge) =>
                          handleOnTweetAndVerify(idx, tweetMsge)
                        }
                        userTwitterDetails={userTwitterDetails}
                        tweetUrl={tweetUrls[idx]}
                        reloadBountyDetails={reloadBountyDetails}
                      />
                    )}
                    <PrimaryBountyTaskButton
                      type="primary"
                      onClick={() => {
                        if (!userDetails) {
                          showLoginModal();
                          return;
                        } else {
                          if (ele?.type === "TWEET") {
                            setIsTweetModalOpen(true);
                            setIsPollingPaused(true);
                          } else window.open(ele[actionObject.key], "_blank");
                        }
                      }}
                    >
                      {ele?.type === "TWEET"
                        ? "Tweet and Verify"
                        : actionObject.label}
                    </PrimaryBountyTaskButton>
                  </div>
                )
              ) : null}
            </StyledTaskContainer>
            {idx < twitterTasks.length - 1 ? (
              <div
                style={{
                  position: "absolute",
                  transform: "rotate(90deg)",
                  width: "24px",
                  border: "1px solid #D1D5DB",
                  bottom: "-13px",
                  left: "13px",
                }}
              ></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default TwitterTaskDetail;

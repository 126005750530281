import React from "react";
import "./InternalComponentStyles/TextButton.scss";
import leftArrowGrey from "../../assets/iconSvg/leftArrowGrey.svg";
import DownVector from "../../assets/iconSvg/Vector2.svg";
import RightVector from "../../assets/iconSvg/RightVector.svg";
function TextButton({ buttonText, clickHandler, isActive, style }) {
  return (
    <div onClick={clickHandler} className="text-button__wrapper" style={style}>
      <span className="text-button__text">{buttonText}</span>
      <img
        src={isActive ? DownVector : RightVector}
        className="text-button__icon"
        alt=""
      />
    </div>
  );
}

export default TextButton;

import React from "react";
import customAxios from "../../../api";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import SearchModal, { SEARCH_PAGINATION_LIMIT } from "../../Search/SearchModal";

function LinkEntities({
  isModalVisible,
  handleClose,
  linkedItems,
  setLinkedItems,
  isForLinking = true,
  isForUpdateLinking = false,
  onClick = undefined,
  newLinkedItems = undefined,
  handleDeleteLinkedItem = undefined,
  handleSubmit = undefined,
}) {
  const handleDeleteLinkedItemDefault = (deletedItemId) => {
    setLinkedItems((prev) => {
      const curr = prev.slice();
      const filterCurr = curr.filter((ele) => ele.id !== deletedItemId);
      return filterCurr;
    });
  };
  const { daoDetails, daoId } = useDaoContext();

  return (
    <SearchModal
      isModalVisible={isModalVisible}
      linkedItems={linkedItems}
      isForUpdateLinking={isForUpdateLinking}
      newLinkedItems={newLinkedItems}
      handleDeleteLinkedItem={
        handleDeleteLinkedItem
          ? handleDeleteLinkedItem
          : handleDeleteLinkedItemDefault
      }
      closeModal={handleClose}
      handleSubmit={handleSubmit}
      getSearchResults={async (searchTerm, type, offset) => {
        const { data } = await customAxios.get(
          `/linkentites/search?searchContent=${searchTerm}&type=${type}&daoId=${daoId}&limit=${SEARCH_PAGINATION_LIMIT}&offset=${offset}`
        );
        return { data: data.data, searchTerm, nextOffset: data.offset };
      }}
      onClick={({ data }) => {
        if (onClick) {
          onClick(data);
        } else {
          setLinkedItems((prev) => {
            const curr = prev.slice();
            curr.push(data);

            return curr;
          });
        }
        handleDeleteLinkedItem = {};
      }}
      isForLinking={isForLinking}
    />
  );
}

export default LinkEntities;

import { Col } from "antd";
import emptyDaoListImg from "../../assets/images/bountyPage/Frame 34355.png";
const EmptyComponent = ({ text, subtext, imageSource = false }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "12px",
        padding: "2.5rem",
      }}
    >
      <img
        src={imageSource || emptyDaoListImg}
        alt="emptyDao"
        style={{ objectFit: "contain", width: "200px" }}
      />
      <div
        style={{
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          marginBottom: "4px",
          marginTop: "8px",
        }}
      >
        {text}
      </div>
      <div
        style={{
          color: "#64748B",
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "16px",
          marginTop: "0px",
        }}
      >
        {subtext}
      </div>
    </div>
  );
};

export default EmptyComponent;

import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import { getDefaultDaoImage } from "../../../../utils";
import ConenctArrowsImage from "../../../../assets/images/common/connect-arrows.svg";
import DicourseIcon from "../../../../images/discourse_icon.png";
import GoogleIcon from "../../../../assets/iconSvg/google_img.png";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { useMutation } from "react-query";
import { connectGoogle } from "../../../../lib/utils/google";
import { toast } from "react-toastify";
import classNames from "classnames";
import SwitchAccountModal from "./SwitchAccountModal";
import { UserContext } from "../../../../layouts/app/UserContext";

type Props = {
  setEmail: Dispatch<SetStateAction<string>>;
  handleNext: () => void;
  handleFlowComplete: () => void;
};

const ConnectGoogle = ({ handleNext, setEmail, handleFlowComplete }: Props) => {
  const { daoDetails, daoId } = useDaoContext();
  const { reload } = useContext(UserContext);
  const [existingAccountDetails, setExistingAccountDetails] = useState<{
    emailId: string;
    profilePicture: string;
    profileName: string;
  } | null>(null);
  const [isSwitchProfileModalOpen, setIsSwitchProfileModalOpen] =
    useState(false);

  const { mutate: handleGoogle, isLoading } = useMutation({
    mutationFn: (
      data: Omit<TokenResponse, "error" | "error_description" | "error_uri">
    ) => connectGoogle({ ...data, daoId }),
    onSuccess: (data) => {
      if (data?.access_token) {
        localStorage.setItem("access_token", data.access_token);
        setEmail(data?.userDetails?.emailId);
        if (data?.userDataOnDiscourse) handleFlowComplete();
        else {
          reload();
          handleNext();
        }
      } else {
        setExistingAccountDetails({
          emailId: data?.userDetails?.emailId,
          profileName: data?.userDetails?.profileName,
          profilePicture: data?.userDetails?.profilePicture,
        });
        setIsSwitchProfileModalOpen(true);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Something went wrong while handling");
    },
  });

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleGoogle(tokenResponse);
    },
    flow: "implicit",
  });

  return (
    <div className="flex flex-col gap-6 items-center p-5">
      <div className="flex gap-5 items-center">
        {daoDetails?.profilePicture ? (
          <img
            src={daoDetails?.profilePicture}
            alt="dao_logo"
            className="w-14 h-14 rounded-full object-cover"
          />
        ) : (
          getDefaultDaoImage(
            daoDetails?.daoName,
            {
              alignSelf: "center",
              width: "56px",
              height: "56px",
              borderRadius: "50%",
            },
            { fontSize: "24px" }
          )
        )}

        <img src={ConenctArrowsImage} className="" alt="Connect arrows" />
        <img src={DicourseIcon} alt="Dicourse" className="w-14 h-14" />
      </div>
      <div className="flex flex-col gap-3 items-center">
        <h3 className="text-black text-xl text-center  font-semibold m-0">
          Community has enabled live sync with Discourse
        </h3>
        <p className="text-zinc-500 text-center m-0">
          When you engage here, your activity will also be visible on Discourse,
          and vice versa. To join the conversation, just log in or sign up for
          an account.
        </p>
      </div>
      <button
        className={classNames(
          "w-full bg-white border border-solid border-gray-300 flex items-center justify-center gap-2 py-3 rounded-xl",
          isLoading
            ? "cursor-not-allowed opacity-70"
            : "hover:bg-gray-50 active:bg-gray-100 cursor-pointer"
        )}
        onClick={() => googleLogin()}
        disabled={isLoading}
      >
        <img src={GoogleIcon} className="h-4" alt="google" />
        Connect with Google
      </button>
      <SwitchAccountModal
        isOpen={isSwitchProfileModalOpen}
        setIsOpen={setIsSwitchProfileModalOpen}
        emailId={existingAccountDetails?.emailId || ""}
        profileName={existingAccountDetails?.profileName || ""}
        profilePicture={existingAccountDetails?.profilePicture || ""}
      />
    </div>
  );
};

export default ConnectGoogle;

import { Menu, Row, Tabs } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import useWindowSize from "../../../hooks/useWindowSize";
const StyledMenu = styled(Menu)`
  & .ant-menu-item-selected {
    background-color: #f7f7f7 !important;
  }

  & .ant-menu-item:hover {
    background-color: #f7f7f7 !important;
  }
`;
function Sidebar() {
  const navigate = useDaoLensNavigate();
  const location = useLocation();
  const { isMobile } = useWindowSize();
  const [selectedTab, setSelectedTab] = useState(
    location.pathname.split("/").slice(-1)[0]
  );
  const [pagesVisited, setPagesVisited] = useState(0);
  const itemsNew = [
    { title: "Profile", route: "profile" },
    { title: "Permissions", route: "permissions" },
    { title: "Integrations", route: "integration" },
    { title: "Payments", route: "payments" },
    { title: "Visibility", route: "visibility" },
    { title: "Webhooks", route: "webhooks" },
    { title: "Notifications", route: "notifications", label: "New" },
    { title: "Manage", route: "manage" },
  ];

  useEffect(() => {
    setPagesVisited((prev) => prev - 1);
  }, [location.pathname]);
  return (
    <div
      style={{
        width: isMobile ? "100%" : 300,
        border: "1px solid #F4F2F2",
        borderRadius: "20px",
        marginTop: isMobile ? "0px" : "20px",
        background: isMobile ? "inherit" : "white",
      }}
    >
      <Row
        style={{
          padding: isMobile ? "0px" : "12px 24px",
          borderBottom: "1px solid #f3f3f3",
        }}
      >
        <h4
          style={{
            marginBottom: "0px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            console.log(location.state.from)
            if (location.state.from)
              navigate(location.state.from)
            else {
              navigate("/app/discovery")
            };
          }}
        >
          <i
            className="fas fa-chevron-left"
            style={{
              display: "inline-block",
              marginRight: "5px",
            }}
          ></i>{" "}
          {isMobile ? "Settings" : "Go Back"}
        </h4>
      </Row>

      {isMobile ? (
        <Tabs
          onChange={(key) => {
            navigate(`${key}`, { state: { from: location?.state?.from } });
            setSelectedTab(key);
          }}
          activeKey={selectedTab}
          style={{ width: "100%", padding: "0px 8px" }}
        >
          {itemsNew.map((ele) => {
            return (
              <Tabs.TabPane tab={ele.title} key={ele.route}></Tabs.TabPane>
            );
          })}
        </Tabs>
      ) : (
        <StyledMenu
          mode="inline"
          selectedKeys={[location.pathname.split("/").slice(-1)[0]]}
          selectable={true}
          // defaultSelectedKeys={defaultSelectedKeys}
          // onOpenChange={onOpenChange}
          style={{
            width: 300,
            paddingTop: 0,
            borderRadius: "0 0 12px 12px",
          }}
        // items={items}
        >
          {itemsNew.map((item) => {
            return (
              <Menu.Item
                key={item?.route}
                style={{
                  margin: 0,
                  borderBottom:
                    item.title === "Upcoming Events" ||
                      item.title === "Your Project"
                      ? "1px solid #F4F2F2"
                      : "none",
                }}
                onClick={() => {
                  navigate(`${item.route}`, { state: { from: location?.state?.from } });
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    {/* <img
                    src={item?.icon}
                    alt="icon"
                    style={{ marginRight: "10px" }}
                  /> */}
                    {item?.title}
                  </div>
                  {item?.label && (
                    <div
                      style={{
                        background: "#3B82F6",
                        borderRadius: "50px",
                        color: "white",
                        width: "40px",
                        height: "20px",
                        display: "flex",
                        padding: "4px 8px",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "4%",
                        fontSize: "12px",
                      }}
                    >
                      {item.label}
                    </div>
                  )}
                </div>
              </Menu.Item>
            );
          })}
        </StyledMenu>
      )}
    </div>
  );
}

export default Sidebar;

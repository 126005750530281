import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import customAxios from "../api";
import { GatingCondition, Preset } from "../components/Gating/Gating";
import { useDaoContext } from "../layouts/app/DaoContext";
export const fetchTemplates = async (daoId: string) => {
  if (!daoId) {
    return [];
  }
  const response = await customAxios.get(`/dao/gating/template?daoId=${daoId}`);
  return response?.data.res as Preset[];
};
const defaultConditionDetails: GatingCondition = {
  operator: "AND",
  conditions: [
    {
      type: "TOKEN_GATING",
      contractType: "POLYGON",
      contractAddress: "",
      amount: "",
    },
  ],
};
export interface UserGatingHook {
  presetId?: string;
  setPresetId: (string) => void;
  conditionDetails?: GatingCondition;
  setConditionDetails: (data: GatingCondition) => void;
  gatingType: "PRESET" | "CUSTOM";
  setGatingType: (type: "PRESET" | "CUSTOM") => void;
  preset?: Preset;
  isLoading: boolean;
  presets: Preset[];
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  entity?: string;
  setGatingState: (
    presetId?: string,
    conditionDetails?: GatingCondition,
    gatingType?: "PRESET" | "CUSTOM"
  ) => void;
  clearGating: () => void;
  isDefaultPreset: boolean;
  createFormData: (
    formData: FormData,
    gatingKey?: string,
    presetIdKey?: string
  ) => void;
  createResponseObject: (res: any) => void;
}

const getDefaultPreset = (presets: Preset[], entity?: string) => {
  return presets?.find(
    (preset) =>
      preset?.type ===
      (entity === "CHANNEL"
        ? "PUBLIC"
        : entity === "PERMISSION"
        ? "ADMIN_ONLY"
        : "ANY_MEMBER")
  );
};

const adminPreset: Preset = {
  backgroundColor: "#EFF6FF",
  icon: "🦍",
  id: "ADMIN_ONLY",
  isDefault: true,
  name: "Admins Only",
  type: "ADMIN_ONLY",
};
const useGating = (entity?: string): UserGatingHook => {
  const [presetId, setPresetId] = useState<string | undefined>("");
  const [conditionDetails, setConditionDetails] = useState<
    GatingCondition | undefined
  >(defaultConditionDetails);
  const { isSpace, daoDetails } = useDaoContext();
  let daoId =
    isSpace && window.location.pathname.includes("settings/profile")
      ? daoDetails.parentDaoId
      : daoDetails.daoId;
  const [gatingType, setGatingType] = useState<"PRESET" | "CUSTOM">("PRESET");
  const [update, setUpdate] = useState(true);
  const [presets, setPresets] = useState<Preset[]>([]);
  const clearGating = () => {
    setPresetId(getDefaultPreset(presets, entity)?.id);
    setGatingType("PRESET");
    setConditionDetails(defaultConditionDetails);
  };
  const setGatingState = (
    presetId?: string,
    conditionDetails?: GatingCondition,
    gatingType?: "PRESET" | "CUSTOM"
  ) => {
    if (gatingType) {
      setGatingType(gatingType);
    }
    if (gatingType === "CUSTOM" || !presetId) {
      setGatingType("CUSTOM");
      setConditionDetails(conditionDetails);
    } else {
      setPresetId(presetId);
      setGatingType("PRESET");
    }
  };

  const { data, isLoading } = useQuery(
    ["templates", daoId, update],
    () => fetchTemplates(daoId || ""),
    {
      onError: (error) => {
        console.error(error);
      },
      onSettled(data, error) {
        if (!data) {
          return;
        }
        let presetsRes: any[] = [];
        if (entity === "PERMISSION") {
          presetsRes.push(adminPreset);
        }
        presetsRes.push(
          ...data?.filter((preset) =>
            entity === "CHANNEL" ? true : preset.type !== "PUBLIC"
          )
        );

        setPresets(presetsRes);

        if (!presetId) setPresetId(getDefaultPreset(presetsRes, entity)?.id);
      },
    }
  );
  let preset = presets.find((preset) => preset.id === presetId);
  const isDefaultPreset =
    gatingType === "PRESET" &&
    presetId === getDefaultPreset(presets, entity)?.id;
  const createFormData = (
    formData: FormData,
    gatingKey?: string,
    presetIdKey?: string
  ) => {
    if (gatingType === "PRESET") {
      formData.append(
        presetIdKey ? presetIdKey : "gatingConditionId",
        presetId ? presetId : ""
      );
      formData.append(
        gatingKey ? gatingKey : "gatingCondition",
        JSON.stringify(preset?.gatingCondition)
      );
    } else {
      formData.append(
        gatingKey ? gatingKey : "gatingCondition",
        JSON.stringify(conditionDetails)
      );
    }
  };
  const createResponseObject = (response: any, gatingKey?: string) => {
    if (gatingType === "PRESET") {
      response.gatingConditionId = presetId;
      response[gatingKey ? gatingKey : "gatingCondition"] =
        preset?.gatingCondition;
    } else {
      response[gatingKey ? gatingKey : "gatingCondition"] = conditionDetails;
    }
  };
  return {
    presetId,
    setPresetId,
    conditionDetails,
    setConditionDetails,
    gatingType,
    setGatingType,
    preset,
    isLoading,
    presets,
    setUpdate,
    entity,
    setGatingState,
    clearGating,
    isDefaultPreset,
    createFormData,
    createResponseObject,
  };
};

export default useGating;

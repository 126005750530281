import {
  DocumentDuplicateIcon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { toast } from "react-toastify";
import useWindowSize from "../../hooks/useWindowSize";
import { DaoContext } from "../../layouts/app/DaoContext";
import { Modal } from "../../styles/ModalStyles";
import { copyTextToClipboard, validateEmail } from "../../utils";
import { useMutation, useQuery } from "react-query";
import { getAdminInviteLink, sendEmailInvite } from "../../utils/common";
import { Select } from "antd";
import classNames from "classnames";
import { getGatingList } from "../../lib/utils/spaces";
import PresetDetails from "../Gating/PresetDetails";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const InviteModal = ({ isOpen, setIsOpen }: Props) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [activeInviteType, setActiveInviteType] = useState<"admin" | "member">(
    "member"
  );
  const { isMobile } = useWindowSize();
  const { daoDetails, daoId, isAdmin } = useContext(DaoContext);
  const {
    isLoading,
    data: adminInviteUrl,
    isError,
  } = useQuery(["admin-link", daoId], () => getAdminInviteLink(daoId), {
    onError: (error) => {
      console.error(error);
    },
  });

  const { isLoading: isLoadingEmailInvite, mutate: sendInvite } = useMutation({
    mutationFn: (data: { emails: string[]; daoId: string; daoName: string }) =>
      sendEmailInvite(
        data.emails,
        data.daoId,
        data.daoName,
        activeInviteType.toUpperCase() as any
      ),
    onError: (error) => {
      console.error(error);
      toast.error("Failed to send invite");
    },
    onSuccess: () => {
      toast.success("Invite sent");
      setEmails([]);
      setIsOpen(false);
    },
  });
  let url = window.location.href.split(daoId as string);

  const memberInviteUrl = `${url[0]}${daoId}/overview?invite=true`;

  return (
    <Modal
      visible={isOpen}
      // @ts-expect-error
      isMobile={isMobile}
      destroyOnClose
      onCancel={() => setIsOpen(false)}
      centered={true}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(20px)",
      }}
      height={"auto"}
      footer={null}
    >
      <div className="flex flex-col p-5">
        <h2 className="font-semibold text-base">Invite</h2>
        <div className="flex flex-col gap-2">
          <label className="text-xs inline-flex items-center gap-1">
            <GlobeAltIcon className="w-3 text-blue-500 stroke-2" /> Member
            invite
          </label>
          <div className="m-0 bg-gray-100 rounded-lg p-3 text-slate-500 flex gap-2 justify-between">
            <span className="break-all leading-tight">{memberInviteUrl}</span>
            <button
              className="bg-gray-100 border-none cursor-pointer flex-shrink-0"
              onClick={() => {
                toast.success("Link copied");
                copyTextToClipboard(memberInviteUrl);
              }}
            >
              <DocumentDuplicateIcon className="w-4" />
            </button>
          </div>
        </div>
        {isAdmin && !isError && (isLoading || adminInviteUrl) && (
          <>
            <div className="flex flex-col gap-2 mt-4">
              <label className="text-xs inline-flex items-center gap-1">
                <img
                  src="/images/onboarding/crown.png"
                  className="w-3"
                  alt="crown"
                />{" "}
                Admin invite
              </label>
              <div className="m-0 bg-gray-100 rounded-lg p-3 text-slate-500 flex gap-2 justify-between">
                <span className="break-all leading-tight">
                  {isLoading ? "Fetching..." : adminInviteUrl}
                </span>
                <button
                  className="bg-gray-100 border-none cursor-pointer flex-shrink-0"
                  onClick={() => {
                    toast.success("Link copied");
                    copyTextToClipboard(adminInviteUrl);
                  }}
                >
                  <DocumentDuplicateIcon className="w-4" />
                </button>
              </div>
            </div>
            <p className="text-xs text-red-700 m-0 mt-3">
              Be mindful while sharing, as anyone with the link can become admin
            </p>
          </>
        )}

        <div className="flex items-center w-full gap-1 mt-2">
          <div className="border border-dashed h-0 flex-1 text-slate-300" />
          <span className="text-slate-500 text-xs">OR</span>
          <div className="border border-dashed h-0 flex-1 text-slate-300" />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-xs inline-flex items-center gap-1">
            Invite members
          </label>
          <div className="flex border-slate-200 border border-solid rounded-lg gap-1 items-center">
            <Select
              className="email-multi-select"
              style={{ width: "100%" }}
              value={emails}
              onChange={(value) => {
                if (value.length) {
                  if (validateEmail(value[value.length - 1])) setEmails(value);
                  else toast.warn("Please enter a valid email address");
                } else setEmails(value);
              }}
              options={[]}
              open={false}
              placeholder="Add mail address"
              mode="tags"
            />
            {isAdmin && (
              <Select
                className="invite-type-select"
                style={{ fontSize: "12px" }}
                value={activeInviteType}
                onChange={(e) => setActiveInviteType(e as any)}
                options={[
                  { value: "admin", label: "Admin" },
                  { value: "member", label: "Member" },
                ]}
              />
            )}
          </div>
        </div>
        {daoDetails.subDaoGatingCondition && (
          <div className="mt-4">
            <PresetDetails gatingCondition={daoDetails.subDaoGatingCondition} />
          </div>
        )}
        <button
          className={classNames(
            "text-white bg-blue-500 self-end border-none rounded-xl py-2 px-4 mt-4",
            emails.length === 0 || isLoadingEmailInvite
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer hover:bg-blue-600 active:bg-blue-700"
          )}
          disabled={emails.length === 0 || isLoadingEmailInvite}
          onClick={() =>
            sendInvite({
              emails,
              daoId: daoDetails.daoId,
              daoName: daoDetails.daoName,
            })
          }
        >
          Send invite
        </button>
      </div>
    </Modal>
  );
};

export default InviteModal;

import React from "react";
import { useHover, useLayer } from "react-laag";
import { useQuery } from "react-query";
import { fetchUserProfile } from "../../lib/utils/common";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { UserProfileType } from "../../lib/types/common";
import { Skeleton } from "antd";
import { useDaoContext } from "../../layouts/app/DaoContext";
import cover from "../../assets/images/profile/cover.png";
import { ReactComponent as Twitter } from "../../assets/images/profile/twitter.svg";
import { ReactComponent as Github } from "../../assets/images/profile/github.svg";
import { ReactComponent as Website } from "../../assets/images/profile/website.svg";

import {
  ArrowTopRightOnSquareIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { WalletIcon } from "@heroicons/react/24/solid";
import { copyTextToClipboard, getTruncatedAddress } from "../../utils";
import { toast } from "react-toastify";

type ProfileProps = {
  profileDetails: UserProfileType;
};

const Profile = ({ profileDetails }: ProfileProps) => {
  const { daoId } = useDaoContext();
  const discordRoles = profileDetails?.daoJoined?.find(
    (dao) => dao.dao_id === daoId
  )?.user_roles;

  return (
    <div className="flex flex-col text-xs p-5 pt-3 relative">
      <img
        src={
          profileDetails.backgroundImage
            ? profileDetails.backgroundImage
            : cover
        }
        className="w-full object-cover h-24 absolute top-0 left-0 rounded-t-xl -z-10"
        alt="cover"
      />
      <a
        href={`/app/profile/${profileDetails?.id}`}
        target="_blank"
        rel="noreferrer"
        className="flex gap-1 items-center self-end text-gray-600 hover:text-gray-600 hover:bg-opacity-100 bg-white bg-opacity-70 border border-solid border-white rounded-full px-3 py-1 -mr-2"
      >
        Full profile
        <ArrowTopRightOnSquareIcon className="w-[14px] stroke-2" />
      </a>
      {profileDetails.profilePicture ? (
        <img
          src={profileDetails.profilePicture}
          className="w-16 h-16 rounded-full object-cover shadow-xl"
          alt="profile"
        />
      ) : (
        <UserCircleIcon className="w-16 h-16 rounded-full object-cover shadow-xl" />
      )}
      <p className="m-0 text-gray-500 mt-3">{profileDetails?.userName}</p>
      <p className="m-0 text-black text-lg font-semibold">
        {profileDetails?.profileName}
      </p>
      <div className="flex gap-2 mt-2 items-center">
        {profileDetails.twitter && (
          <a
            href={profileDetails?.twitter}
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <Twitter />
          </a>
        )}
        {profileDetails.github && (
          <a
            href={profileDetails?.github}
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <Github />
          </a>
        )}
        {profileDetails.website && (
          <a
            href={profileDetails?.website}
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <Website />
          </a>
        )}

        {profileDetails?.walletAddress && (
          <button
            className="flex items-center leading-tight text-slate-500 bg-gray-100 h-full py-1 px-2 rounded-lg gap-1 border-none cursor-pointer"
            onClick={() => {
              copyTextToClipboard(profileDetails?.walletAddress);
              toast.success("Copied!");
            }}
          >
            <WalletIcon width={16} />
            {getTruncatedAddress(profileDetails?.walletAddress)}
          </button>
        )}
      </div>
      <p className="m-0 leading-5 font-normal text-gray-500 mt-3">
        {profileDetails?.description}
      </p>
      {discordRoles && discordRoles?.length !== 0 && (
        <>
          <div className="w-full h-0 border-[0.5px] border-solid border-gray-200 my-3" />
          <p className="m-0 font-semibold text-gray-500 mb-2 mt-1">
            DISCORD ROLES
          </p>
          <div className="flex flex-wrap gap-1">
            {discordRoles?.map((role) => (
              <p
                key={role}
                className="rounded-lg bg-gray-100 px-3 py-1 m-0 text-black font-semibold"
              >
                {role}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

type Props = {
  userId: string;
  children?: React.ReactNode;
};

const ProfileCardPopup = ({ userId, children }: Props) => {
  const [isOver, hoverProps] = useHover({ delayLeave: 500, delayEnter: 500 });
  const navigate = useDaoLensNavigate();

  const { data, isLoading } = useQuery(
    ["profile", userId],
    () => fetchUserProfile(userId),
    {
      onError: (error) => {
        console.error(error);
        navigate(`/app/profile/${userId}`);
      },
      enabled: isOver,
    }
  );

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen: isOver,
    placement: "bottom-start",
    auto: true,
    triggerOffset: 0,
    arrowOffset: 4,
  });
  if (!userId) {
    return children;
  }
  return (
    <>
      <div
        {...triggerProps}
        {...hoverProps}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="cursor-pointer"
      >
        {children}
      </div>
      {isOver &&
        renderLayer(
          <div
            {...layerProps}
            {...hoverProps}
            style={{ ...layerProps.style }}
            className="bg-white shadow-xl w-[360px] rounded-xl z-[10000] max-h-screen"
            onClick={(e) => e.stopPropagation()}
          >
            {isLoading && (
              <div className="p-5">
                <Skeleton active />
              </div>
            )}
            {data && <Profile profileDetails={data} />}
          </div>
        )}
    </>
  );
};

export default ProfileCardPopup;

import { Button, Col, Modal, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useRef, useState } from "react";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { TagsTwoTone } from "@ant-design/icons";
function BountySubmitGuidelines({
  visible,
  handleCancel,
  bountyId,
  handleSuccess,
  note,
}) {
  return (
    <Modal
      visible={visible}
      title="Submission guidelines"
      destroyOnClose
      className="bounty-submission-modal"
      onCancel={handleCancel}
      footer={
        <Row xs={24} style={{ justifyContent: "space-between" }}>
          <div></div>
          <Button
            type="primary"
            style={{ borderRadius: "12px" }}
            onClick={() => {
              handleSuccess();
            }}
          >
            I understand. Proceed
          </Button>
        </Row>
      }
    >
      <p style={{ padding: "0 16px" }}>{note}</p>
    </Modal>
  );
}

export default BountySubmitGuidelines;

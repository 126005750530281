import { toast } from "react-toastify";
import { postMixpanel } from ".";
import customAxios from "../api";

export const togglePinBounty = async (daoId, bountyId, pin) => {
  try {
    await customAxios.post("/bounty/pinned", {
      daoId,
      bountyId,
      type: pin ? "PIN" : "UNPIN",
    });
    postMixpanel("track", `bounty_card_${pin ? "pin" : "unpin"}`);
    toast.success(`Successfully ${pin ? "pinned" : "unpinned"}.`);
    window.location.reload();
  } catch (error) {
    console.error(error);
    toast.error(`Something went wrong while ${pin ? "pinning" : "unpinning"}.`);
  }
};
export const togglePinEvents = async (daoId, eventsId, pin) => {
  try {
    await customAxios.post("/affair/pinned", {
      daoId,
      eventsId,
      type: pin ? "PIN" : "UNPIN",
    });
    postMixpanel("track", `events_card_${pin ? "pin" : "unpin"}`);
    toast.success(`Successfully ${pin ? "pinned" : "unpinned"}.`);
    // window.location.reload();
  } catch (error) {
    toast.error(`Something went wrong while ${pin ? "pinning" : "unpinning"}.`);
  }
};
export const togglePinPost = async (daoId, postId, pin) => {
  try {
    await customAxios.post("/post/pinned", {
      daoId,
      postId,
      type: pin ? "PIN" : "UNPIN",
    });

    postMixpanel("track", `post_card_${pin ? "pin" : "unpin"}`);
    toast.success(`Successfully ${pin ? "pinned" : "unpinned"}.`);
    window.location.reload();
  } catch (error) {
    console.error(error);
    toast.error(`Something went wrong while ${pin ? "pinning" : "unpinning"}.`);
  }
};

export const togglePinProject = async (daoId, projectId, pin) => {
  try {
    await customAxios.post("/projects/pinned", {
      daoId,
      projectId,
      type: pin ? "PIN" : "UNPIN",
    });

    postMixpanel("track", `project_card_${pin ? "pin" : "unpin"}`);
    toast.success(`Successfully ${pin ? "pinned" : "unpinned"}.`);
    window.location.reload();
  } catch (error) {
    console.error(error);
    toast.error(`Something went wrong while ${pin ? "pinning" : "unpinning"}.`);
  }
};

export const togglePinProposal = async (daoId, proposalsId, pin) => {
  try {
    await customAxios.post("/proposals/pinned", {
      daoId,
      proposalsId,
      type: pin ? "PIN" : "UNPIN",
    });

    postMixpanel("track", `proposal_card_${pin ? "pin" : "unpin"}`);
    toast.success(`Successfully ${pin ? "pinned" : "unpinned"}.`);
    window.location.reload();
  } catch (error) {
    console.error(error);
    toast.error(`Something went wrong while ${pin ? "pinning" : "unpinning"}.`);
  }
};

export const togglePinCourses = async (daoId, courseId, pin) => {
  try {
    await customAxios.post("/courses/pinned", {
      daoId,
      courseId,
      type: pin ? "PIN" : "UNPIN",
    });

    postMixpanel("track", `courses_card_${pin ? "pin" : "unpin"}`);
    toast.success(`Successfully ${pin ? "pinned" : "unpinned"}.`);
    window.location.reload();
  } catch (error) {
    console.error(error);
    toast.error(`Something went wrong while ${pin ? "pinning" : "unpinning"}.`);
  }
};

import React, { useRef } from "react";
import "../ComponentStyles/BountyDetails.scss";
import { Button, Collapse, Modal, Typography } from "antd";
import GoBackButton from "../InternalComponents/GoBackButton";
import SmallUserDetails from "../InternalComponents/SmallUserDetails";
import TextButton from "../InternalComponents/TextButton";
import dummyAvatar from "../../assets/images/login/defaultUser.jpeg";
import dotsMenuIcon from "../../assets/iconSvg/dotsMenuIcon.svg";
import userIconGreen from "../../assets/iconSvg/userIconGreen.svg";
import globe from "../../assets/iconSvg/globe.svg";
import diamondIconGreen from "../../assets/iconSvg/diamondIconGreen.svg";
import SquareCapsule from "../InternalComponents/SquareCapsule";
import TokenSquareCapsule from "../InternalComponents/TokenSquareCapsule";
import BountySubmissionsAccordion from "./BountySubmissionsAccordion";
import { useState } from "react";
import ViewBountySubmissionModal from "./ViewBountySubmissionModal";
import BountyWriteComment from "./BountyWriteComment";
import { useNavigate } from "react-router-dom";
import BountySubmit from "../BountySubmit";
import SecondaryButtonOutlined from "../InternalComponents/SecondaryButtonOutlined";
import commentIcon from "../../assets/iconSvg/commentIcon.svg";
import PrimaryButtonFilled from "../InternalComponents/PrimaryButtonFilled";
import moment from "moment/moment";
import { useEffect } from "react";
import customAxios from "../../api";
import BountySubtasksAccordion from "./BountySubtasksAccordion";
import ReactHtmlParser from "react-html-parser";
import ViewBountySubtaskModal from "./ViewBountySubtaskModal";
import { Document, Page } from "react-pdf";
import pdfIcon from "../../assets/iconSvg/pdf.svg";
import downloadIcon from "../../assets/iconSvg/downloadIcon.svg";
import CommentSection from "../CommentsComponents/CommentSection";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
const { Panel } = Collapse;
const { Link } = Typography;
const bountyTypeList = [
  { label: "Bounty", value: "BOUNTY_SUBMISSION" },
  { label: "Task", value: "DIRECT_ASSIGNMENT" },
];

function SubtaskDetails({
  daoId,
  bountyId,
  bountyDetails,
  subtaskId,
  canSubmit,
  // setRerender,
}) {
  const myRef = useRef(null);
  // const submissions = [
  //   { icon: dummyAvatar, name: "Lorem Ipsum 1", description: text },
  //   { icon: dummyAvatar, name: "Lorem Ipsum 2", description: text },
  //   { icon: dummyAvatar, name: "Lorem Ipsum 3", description: text },
  //   { icon: dummyAvatar, name: "Lorem Ipsum 4", description: text },
  // ];
  const navigation = useDaoLensNavigate();
  // ! Local states
  const [isViewSubmissionModalOpen, setIsViewSubmissionModalOpen] =
    useState(false);
  const [isViewSubtaskModalOpen, setIsViewSubtaskModalOpen] = useState(false);
  const [submitBountyModalVisible, setSubmitMountyModalVisible] =
    useState(false);
  const [accordionState, setAccordionState] = useState([]);
  const [comments, setComments] = useState([]);
  const [submissions, setSubmissions] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [showAttachmentLink, setShowAttachmentLink] = useState(false);
  const [currentBountySubmissionId, setcurrentBountySubmissionId] = useState(0);
  const [currentBountySubtaskId, setcurrentBountySubtaskId] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  // ! Local handlers
  const openViewBountySubmissionModal = (submissionIndex = 0) => {
    submissions.forEach((sub, index) => {
      if (sub.bountySubmissionId == submissionIndex) submissionIndex = index;
    });
    setIsViewSubmissionModalOpen(true);
    setcurrentBountySubmissionId(submissionIndex);
  };
  const openViewBountySubtaskModal = (subtaskIndex = 0) => {
    setIsViewSubtaskModalOpen(true);
    setcurrentBountySubmissionId(subtaskIndex);
  };
  const closeViewBountySubmissionModal = () => {
    setIsViewSubmissionModalOpen(false);
  };
  const closeViewBountySubtaskModal = () => {
    setIsViewSubtaskModalOpen(false);
  };
  // const addCommentHandler = (commentText) => {
  //   customAxios
  //     .post("/bountycomments/create", {
  //       daoId: daoId,
  //       bountyId: subtaskId,
  //       parentId: "0",
  //       description: commentText,
  //     })
  //     .then((res) => {
  //       console.log("comments push", res.data);
  //       fetchComments();
  //       // setComments([...comments, { description: commentText }]);
  //     })
  //     .catch((err) => console.log(err));
  // };
  const fetchComments = () => {
    customAxios
      .post("/bountycomments/fetch", {
        bountyId: subtaskId,
        parentId: "0",
      })
      .then((res) => {
        // console.log("comments", res.data);
        setComments([...res.data.bountyComments.reverse()]);
      })
      .catch((err) => console.log(err));
  };
  const fetchSubmissions = () => {
    customAxios
      .get(`/bountysubmission/fetch?bountyId=${subtaskId}`)
      .then((res) => {
        // console.log("subs", res.data);
        setSubmissions(res.data.bountySubmission);
        setIsCompleted(res.data.isCompleted);
        setIsPaid(res.data.isPaid);
      })
      .catch((err) => console.log(err));
  };
  //   const fetchSubtasks = () => {
  //     customAxios
  //       .get(`/bounty/subtaskList?bountyId=${bountyId}`)
  //       .then((res) => {
  //         console.log("subtasks", res.data);
  //         setSubTasks(res.data.subTaskList);
  //       })
  //       .catch((err) => console.log(err));
  //   };
  const postComment = (commentText, mentions) => {
    var postBody = {
      daoId: daoId,
      bountyId: bountyId,
      parentId: "0",
      description: commentText,
    };
    if (mentions.length > 0) postBody.userTagged = mentions;
    customAxios
      .post("/bountycomments/create", postBody)
      .then((res) => {
        fetchComments();
        // setComments([...comments, { description: commentText }]);
      })
      .catch((err) => console.log(err));
  };
  const postReply = () => { };
  // ! Effects
  useEffect(() => {
    // ! Fetch comments
    fetchComments();
    fetchSubmissions();
    // fetchSubtasks();
  }, []);
  const titleCase = (string) => {
    let s = string.split(" ");
    s = s.map((element) => {
      element = element[0].toUpperCase() + element.slice(1, element.length);
      return element;
    });
    return s.join(" ");
  };
  return (
    <div className="bounty-details__wrapper">
      <GoBackButton
        showIcon
        buttonText={"Go Back"}
        clickHandler={() => {
          navigation(`/app/dao/${daoId}/bounty/${bountyId}`);
        }}
      />
      <div className="bounty-details__meta">
        <div className="bounty-details__meta--user-details">
          <div>
            <SmallUserDetails
              avatar={bountyDetails.profilePicture || dummyAvatar}
              name={bountyDetails.userName}
              userId={bountyDetails.userId}
              url=""
              showSeparator
              postSeparator={
                <span className="bounty-details__meta--user-details-time">
                  {moment(bountyDetails.bountyCreatedAt).format("hh:mm A")}
                </span>
              }
            />
          </div>

          {/* <img
            src={dotsMenuIcon}
            className="bounty-details__meta--user-details-menu"
            alt=""
          /> */}
        </div>
        <h3 className="bounty-details__meta--header">
          {bountyDetails.bountyTitle}
        </h3>
        {bountyDetails.bountyLastSubmissionDate && (
          <p className="bounty-details__meta--sub-header">
            {new Date(bountyDetails.bountyLastSubmissionDate).getTime() < new Date().getTime() ? "Expired on" : "Expires in"}:{" "}
            {moment(bountyDetails.bountyLastSubmissionDate).format(
              "DD MMMM, hh:mm A"
            )}{" "}
            <span>
              ({moment(bountyDetails.bountyLastSubmissionDate).fromNow()})
            </span>
          </p>
        )}
        <div className="bounty-details__meta--rewards">
          <SquareCapsule
            textContent={titleCase(
              bountyTypeList.find(
                (ele) => ele.value === bountyDetails.bountyType
              ).label
            )}
            icon={userIconGreen}
          />
          <span>for</span>
          <SquareCapsule
            textContent={titleCase(
              bountyDetails.bountyAssignmentType
                .split("_")
                .join(" ")
                .toLowerCase()
            )}
            icon={globe}
          />
          {bountyDetails.bountyAmount && (
            <>
              <span>with reward of</span>
              <TokenSquareCapsule
                icon={diamondIconGreen}
                textContent={`${bountyDetails.bountyAmount} ETH`}
              />
            </>
          )}
        </div>
      </div>
      <Collapse
        // defaultActiveKey={["1"]}
        ghost
        // expandIconPosition="right"
        // expandIcon={() => <></>}
        activeKey={accordionState}
        className="bounty-details-accordion"
      >
        <Panel
          header={
            <TextButton
              clickHandler={() =>
                accordionState[0] === "1"
                  ? setAccordionState([""])
                  : setAccordionState(["1"])
              }
              isActive={accordionState[0] === "1"}
              buttonText="Checklist Item Details"
            />
          }
          key="1"
          showArrow={false}
        >
          <div>{ReactHtmlParser(bountyDetails.bountyDescription)}</div>
          {bountyDetails.bountyAttachments?.length > 0 && <p>Attachments :</p>}
          <div className="view-bounty-submission-modal__attachment--container">
            {bountyDetails.bountyAttachments?.map((singleAttachment, index) => {
              return (
                <>
                  {showAttachmentLink ? (
                    <Link href={singleAttachment} target="_blank">
                      <img
                        src={singleAttachment}
                        alt="attachment"
                        style={{
                          width: "130px",
                          height: "130px",
                          marginBottom: "12px",
                          objectFit: "contain",
                          borderRadius: "12px",
                          marginRight: "10px",
                        }}
                      />
                    </Link>
                  ) : (
                    <Document
                      file={singleAttachment}
                      onLoadSuccess={() => { }}
                      loading={<span>Loading file</span>}
                      onLoadError={() => {
                        setShowAttachmentLink(true);
                      }}
                      className="view-bounty-submission-modal__attachment"
                    >
                      <Page pageNumber={1} />
                      <div
                        className="view-bounty-submission-modal__attachment--title"
                        onClick={() => {
                          window.open(singleAttachment);
                        }}
                      >
                        <img src={pdfIcon} alt="" />
                        <span>File {index + 1}</span>
                        <img src={downloadIcon} alt="" />
                      </div>
                    </Document>
                  )}
                </>
              );
            })}
          </div>
        </Panel>
        <hr />
        {bountyDetails.noOfSubmissions && bountyDetails.noOfSubmissions != "0" && (
          <>
            <Panel
              header={
                <TextButton
                  clickHandler={() =>
                    accordionState[0] === "3"
                      ? setAccordionState([""])
                      : setAccordionState(["3"])
                  }
                  isActive={accordionState[0] === "3"}
                  buttonText={`Task Submissions (${submissions.length})`}
                />
              }
              key="3"
              showArrow={false}
            >
              {submissions.length > 0 ? (
                <BountySubmissionsAccordion
                  submissions={submissions}
                  openViewBountySubmissionModal={openViewBountySubmissionModal}
                />
              ) : (
                <p>No submissions yet</p>
              )}
            </Panel>
            <hr />
          </>
        )}
        <Panel
          header={
            <TextButton
              clickHandler={() =>
                accordionState[0] === "5"
                  ? setAccordionState([""])
                  : setAccordionState(["5"])
              }
              isActive={accordionState[0] === "5"}
              buttonText={`Comments (${comments.length})`}
              style={{ marginTop: "0px" }}
            />
          }
          key="5"
          showArrow={false}
        >
          <CommentSection
            allComments={comments}
            postComment={postComment}
            postReply={postReply}
            authorUserName={bountyDetails.userName}
          />
        </Panel>
      </Collapse>
      <div className="bounty-comments-section__actions">
        <div></div>
        {canSubmit && (
          <PrimaryButtonFilled
            buttonText="Submit"
            fillColor="#3B82F6"
            style={{ padding: "8px 12px" }}
            clickHandler={() => setSubmitMountyModalVisible(true)}
          />
        )}
      </div>
      {submitBountyModalVisible ? (
        <BountySubmit
          bountyId={bountyId}
          visible={submitBountyModalVisible}
          handleCancel={() => setSubmitMountyModalVisible(false)}
          handleSuccess={() => {
            fetchSubmissions();
          }}
          subtaskId={subtaskId}
        />
      ) : null}
      {isViewSubmissionModalOpen && submissions.length > 0 && (
        <ViewBountySubmissionModal
          isCompleted={isCompleted}
          isPaid={isPaid}
          isModalOpen={isViewSubmissionModalOpen}
          openModalHandler={openViewBountySubmissionModal}
          closeModalHandler={closeViewBountySubmissionModal}
          bountyDetails={submissions[currentBountySubmissionId]}
          canShowNext={submissions[currentBountySubmissionId + 1]}
          canShowPrevious={submissions[currentBountySubmissionId - 1]}
          setcurrentBountySubmissionId={setcurrentBountySubmissionId}
          currentBountySubmissionId={currentBountySubmissionId}
          bountyAmount={bountyDetails.bountyAmount}
          bountyId={bountyDetails.bountyId}
          fetchSubmissions={fetchSubmissions}
          symbol={bountyDetails.symbol}
          network={bountyDetails.network}
        />
      )}
    </div>
  );
}

export default SubtaskDetails;

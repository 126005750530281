import React, { useEffect, useState } from "react";
import { BACKGROUND_COLORS } from "../Project.js/CreateProjectModal";
import { ReactComponent as EnterEmoji } from "../../assets/images/project/enter_emoji.svg";
import EmojiPicker from "emoji-picker-react";
import { Button, Input } from "antd";
import {
  defaultConditionDetails,
  StyledConditionedModalTitle,
} from "./GatingModal";
import CustomGating from "./CustomGating";
import { toast } from "react-toastify";
import customAxios from "../../api";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { Modal, Footer } from "../../styles/ModalStyles";
import useWindowSize from "../../hooks/useWindowSize";
import { Preset } from "./Gating";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

interface CreatePresetForm {
  icon?: string;
  name?: string;
}
interface CreatePresetProps {
  preset?: Preset;
  onSubmit: (presetId: string) => void;
  onCancel: () => void;
  mode: "CREATE" | "EDIT" | "VIEW";
}

const CreatePreset = ({
  preset,
  onSubmit,
  onCancel,
  mode,
}: CreatePresetProps) => {
  const [state, setState] = useState<CreatePresetForm>({});
  const [background, setBackground] = useState(
    BACKGROUND_COLORS[Math.floor(Math.random() * BACKGROUND_COLORS.length)]
  );
  const { isMobile } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [showEmojiModal, setEmojiModal] = useState(false);
  const [conditionDetails, setConditionDetails] = useState(
    defaultConditionDetails
  );
  const { daoId } = useDaoContext();
  useEffect(() => {
    if (mode === "EDIT" && preset) {
      setState({ icon: preset.icon, name: preset.name });
      setBackground(preset.backgroundColor);
      setConditionDetails(preset.gatingCondition);
    } else {
      setState({});
      setConditionDetails(defaultConditionDetails);
    }
  }, [mode]);
  const upsertTemplate = () => {
    if (!state.name) {
      toast.error("Please provide preset name");
      return;
    }
    if (!state.icon) {
      toast.error("Please provide preset icon");
      return;
    }
    setLoading(true);
    customAxios
      .post(`/dao/gating/template/${mode === "EDIT" ? "update" : "create"}`, {
        daoId,
        gatingCondition: conditionDetails,
        name: state.name,
        icon: state.icon,
        backgroundColor: background,
        templateId: mode === "EDIT" ? preset?.id : "undefined",
      })
      .then((res) => {
        toast.success("Preset saved successfully");
        onSubmit(res.data?.id);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        isMobile={isMobile}
        destroyOnClose
        closable={false}
        headerPadding="14px 20px"
        title={
          <div className="flex items-center text-sm font-medium ">
            <ArrowLeftIcon
              className="h-4 w-4 stroke-2  mr-2 cursor-pointer"
              style={{ strokeWidth: "2.5px" }}
              onClick={() => {
                onCancel();
              }}
            />
            {mode === "EDIT"
              ? "Updating Gating Preset"
              : "Adding New Gating Preset"}
          </div>
        }
        centered={true}
        width="650px"
        bodyStyle={{ minHeight: "auto" }}
        visible={mode !== "VIEW"}
        onCancel={() => {
          setConditionDetails(() => defaultConditionDetails);
          onCancel();
        }}
        footer={[
          <Footer key="modal-footer" justifyContent="flex-end">
            <Button
              className="rounded-corner"
              onClick={() => {
                setConditionDetails(() => defaultConditionDetails);
                onCancel();
              }}
            >
              Cancel
            </Button>

            <Button
              className="rounded-corner"
              type="primary"
              onClick={() => {
                upsertTemplate();
              }}
            >
              Save
            </Button>
          </Footer>,
        ]}
      >
        <div className="flex gap-6 px-5 pt-2">
          {state.icon ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setEmojiModal(true);
              }}
              style={{
                fontSize: 48,
                width: "80px",
                height: "80px",
                background,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1.5px solid #FFFFFF",
                borderRadius: "12px",
                boxShadow: " 0px 0px 3px rgba(25, 38, 72, 0.2)",
                cursor: "pointer",
                marginBottom: "8px",
              }}
            >
              {state.icon}
            </div>
          ) : (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setEmojiModal(true);
              }}
              style={{
                padding: "16px",
                background: "rgba(226, 232, 240, 0.2)",
                display: "inline-flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "4px",
                font: "normal 500 12px/16px Inter",
                color: "#64748B",
                border: "1px dashed #CFD8E3",
                borderRadius: "16px",
                cursor: "pointer",
                marginBottom: "8px",
              }}
            >
              <EnterEmoji
                width="28px"
                height="28px"
                // fill="#97A6BA"
                color="pink"
              ></EnterEmoji>
              Add icon
            </div>
          )}
          {showEmojiModal && (
            <div
              style={{ position: "fixed", zIndex: 10 }}
              onClick={(e) => e.stopPropagation()}
            >
              <EmojiPicker
                onEmojiClick={(emojiData) => {
                  setState((prev) => ({
                    ...prev,
                    icon: emojiData.emoji,
                  }));
                  if (mode === "CREATE")
                    setBackground(
                      BACKGROUND_COLORS[
                        Math.floor(Math.random() * BACKGROUND_COLORS.length)
                      ]
                    );
                  setEmojiModal(false);
                }}
                autoFocusSearch={false}
              />
            </div>
          )}
          <div className="grow text-sm font-medium text-grey-800 py-2">
            Preset Name
            <Input
              value={state.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              placeholder="Eg. For contributors above level 50"
              maxLength={75}
              style={{
                borderRadius: "12px",
                marginBottom: "8px",
                padding: "6px 16px",
                marginTop: "8px",
              }}
            />
          </div>
        </div>
        <div className="px-5 pt-2">
          <CustomGating
            conditionDetails={conditionDetails}
            setConditionDetails={setConditionDetails}
          />
        </div>
      </Modal>
    </>
  );
};

export default CreatePreset;

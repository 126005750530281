import styled from "styled-components";

const Container = styled.div`
  h2,
  h1 {
    margin: 0;
  }

  h2 {
    margin-top: 10px;
  }
`;

const SecurityPolicy = () => {
  return (
    <Container style={{ padding: "20px" }}>
      <h1>DaoLens Security</h1>
      We take security very seriously at DaoLens. We welcome any peer review of
      our 100% open source code to ensure nobody's data is ever compromised or
      hacked.
      <h2>Where should I report security issues?</h2>
      In order to give the community time to respond and upgrade we strongly
      urge you report all security issues privately. Please use{" "}
      <a
        href="https://forms.clickup.com/37310575/f/13jm3f-4504/RBYP5VM5H9HR30TRA6"
        target="_blank"
        rel="noreferrer"
      >
        this form
      </a>{" "}
      to provide details and repro steps and we will respond ASAP. If you are
      unable to use Hacker One, email us directly at{" "}
      <a href="mailto:hello@daolens.com">hello@daolens.com</a> with details and
      repro steps. Security issues always take precedence over bug fixes and
      feature work. We can and do mark releases as "urgent" if they contain
      serious security fixes.
      <br />
      <b>Please note:</b> Due to a significant number of low quality security
      reports sent via email, we are unlikely to act on security reports sent to
      us via email unless they come from a trusted source, and include details
      on the vulnerability and step by step instructions to reproduce it.
      Theoretical reports without a proof of concept are not accepted. We
      strongly recommend you follow the Hacker One submission protocols.
      <h2>Password Storage</h2>
      DaoLens uses Discord and Metamask for login and signup. We follow the best
      practices as listed in the official Discord and Metamask developer docs{" "}
      <a
        href="https://discord.com/developers/docs/topics/oauth2"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>{" "}
      and{" "}
      <a
        href="https://docs.metamask.io/guide/"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .<h2>Database</h2>
      We use Mongo DB for storing the data, and follow the best practices
      recommended by them. More on security of MongoDB{" "}
      <a
        href="https://www.mongodb.com/docs/manual/security/"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .<h2>DDOS</h2>
      We use HAProxy for DDOS protection.
    </Container>
  );
};

export default SecurityPolicy;

import { Fragment, useContext, useEffect, useState } from "react";
import { Modal, Footer } from "../../styles/ModalStyles";
import styled from "styled-components";
import { Button, Skeleton } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import Gating from "./Gating";
import useGating from "../../hooks/useGating";
import { CloseCircleFilled } from "@ant-design/icons";

export const defaultConditionDetails = {
  operator: "AND",
  conditions: [
    {
      type: "TOKEN_GATING",
      contractType: "POLYGON",
      contractAddress: "",
      amount: "",
    },
  ],
};

export const StyledConditionedModalTitle = styled.div`
  display: flex;
  margin-left: 10px;
  gap: 8px;

  .conditioned-title {
    width: 20px;
    height: 20px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
  }
`;

const GatingModal = ({ finalGatingState }) => {
  const [isGatingModalVisible, setGatingModalVisible] = useState(false);

  const gatingState = useGating(finalGatingState?.entity);
  const { setGatingState } = gatingState;
  const {
    preset,
    conditionDetails,
    gatingType,
    isLoading,
    clearGating,
    isDefaultPreset,
  } = finalGatingState;

  useEffect(() => {
    setGatingState(
      finalGatingState.presetId,
      finalGatingState.conditionDetails,
      finalGatingState.gatingType
    );
  }, [isGatingModalVisible]);

  const { isMobile } = useWindowSize();

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isLoading ? (
        <div className="w-36">
          <Skeleton.Button active block />
        </div>
      ) : (
        <>
          <div
            className="rounded-lg h-8 px-3 cursor-pointer bg-gray-100"
            onClick={() => {
              setGatingModalVisible(true);
            }}
          >
            <div className="flex items-center text-xs font-medium h-full whitespace-nowrap relative">
              {!isDefaultPreset && (
                <CloseCircleFilled
                  onClick={(e) => {
                    e.stopPropagation();
                    clearGating();
                  }}
                  className="w-4 opacity-0 hover:opacity-100"
                  style={{
                    position: "absolute",
                    top: -5,
                    right: -20,
                  }}
                />
              )}
              {gatingType === "PRESET" ? (
                <>
                  <div>
                    <div
                      className="rounded-full  w-6 h-6 flex justify-center items-center mr-2.5"
                      style={{ background: preset?.backgroundColor }}
                    >
                      {preset?.icon}
                    </div>
                  </div>

                  <div className="whitespace-nowrap overflow-hidden text-ellipsis w-full">
                    {preset?.name}
                  </div>
                </>
              ) : (
                `${conditionDetails?.conditions?.length} condition${
                  conditionDetails?.conditions?.length > 1 ? "s" : ""
                } added`
              )}
            </div>
          </div>{" "}
          <Modal
            isMobile={isMobile}
            destroyOnClose
            title={
              <StyledConditionedModalTitle>
                <p>With Condition</p>
              </StyledConditionedModalTitle>
            }
            centered={true}
            width="650px"
            bodyStyle={{ minHeight: "auto" }}
            visible={isGatingModalVisible}
            onCancel={() => {
              setGatingModalVisible(false);
            }}
            footer={[
              <Footer key="modal-footer" justifyContent="flex-end">
                <Button
                  className="rounded-corner"
                  onClick={() => {
                    setGatingModalVisible(false);
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="rounded-corner"
                  type="primary"
                  onClick={() => {
                    finalGatingState.setGatingState(
                      gatingState.presetId,
                      gatingState.conditionDetails,
                      gatingState.gatingType
                    );
                    setGatingModalVisible(false);
                  }}
                >
                  Save
                </Button>
              </Footer>,
            ]}
          >
            <div className="px-4 mb-4">
              <Gating state={gatingState} />
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default GatingModal;

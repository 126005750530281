import { Button } from "antd";
import React, { useContext } from "react";
import { DaoContext } from "../../layouts/app/DaoContext";

const ConnectDiscord = () => {
  const { daoDetails, daoId } = useContext(DaoContext);
  
  const isDiscordConnected = !!(daoDetails as any)?.discordGuildId;
  if (isDiscordConnected) return null;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "white",
        padding: "12px 16px",
        borderRadius: "12px",
        gap: "12px",
        width: "100%",
      }}
    >
      <span style={{ fontSize: "14px" }}>
        Connect discord to add conditions
      </span>
      <Button
        onClick={() =>
          window.open(
            `https://discord.com/api/oauth2/authorize?client_id=${
              process.env.REACT_APP_ENDPOINT === "development"
                ? "1046753505026785401"
                : "1014817083139301417"
            }&redirect_uri=${
              encodeURIComponent(window.location.origin) +
              "/app/redirect/discordIntgeration"
            }&response_type=code&state=${btoa(
              JSON.stringify({ daoId, from: window.location.pathname })
            )}&scope=identify%20guilds`,
            "_self"
          )
        }
        type="primary"
        style={{
          borderRadius: "12px",
          background: "#3B82F6",
          padding: "6px 16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        Connect
      </Button>
    </div>
  );
};

export default ConnectDiscord;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, Tag } from "antd";
import {
  ChartBarSquareIcon,
  ArrowLongRightIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import { CloseOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Footer, Modal } from "../../../styles/ModalStyles";
import IconWrapper from "../../IconWrapper";
import CreatePoll from "../../CreatePoll";
import customAxios from "../../../api";
import GenericTagSelect from "../../Common/GenericTagSelect";
import { ReactComponent as ChecklistIcon } from "./../../../assets/iconSvg/checklist.svg";
import { ReactComponent as CrossIcon } from "./../../../assets/iconSvg/crossIcon.svg";
import styles from "../../Course/course.module.css";
import EditableChecklistItem from "./EditableChecklistItem";
import {
  BountyFilesAttachment,
  cancelText,
  confirmText,
} from "../../CreateBountyModal";
import { getTrunckedString } from "../../Proposal/ProposalCard";
import { checkFileSize, postMixpanel } from "../../../utils";
import QuillEditor from "../../QuillEditor";
import { PaperClipIcon } from "../../Icones";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import {
  MINIMUM_POST_CHAR_LIMIT,
  MINIMUM_TITLE_CHAR_LIMIT,
} from "../../../lib/constants/posts";
import { confirmOperation } from "../../../utils/common";

const StyledTitle = styled.div`
  color: #64748b;
  padding: 2px 12px;
  height: 24px;
  background: #f4f2f2;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  margin-left: 9px;
  ::before {
    content: "In ";
  }
`;

const StyledInput = styled(Input)`
  padding: 0px 20px;
  margin: 8px 0px;
  font-size: 20px;
  font-weight: 600;
  &::placeholder {
    font-weight: 600;
    color: #94a3b8;
  }
`;
const StyledAttachment = styled.div`
  padding: 0px 10px;
  margin: 8px 0px;
  .attachment-title {
    font-weight: 600;
    font-size: 12px;
    margin: 5px 8px;
  }
`;

const PostFilesAttachment = ({ files, onDelete, showHeader = true }) => {
  return (
    <StyledAttachment>
      {showHeader ? <p className="attachment-title">ATTACHMENTS</p> : null}
      {files.map((file, idx) => (
        <Tag
          onClick={(e) => {
            e.stopPropagation();
            window.open(file.publicUrl);
          }}
          style={{
            cursor: "pointer",
            border: "1px solid #E2E8F0",
            background: "transparent",
            padding: "0.25rem 0.8rem",
            borderRadius: "52px",
            marginBottom: "8px",
          }}
          closeIcon={<i class="fas fa-times" style={{ fontSize: "12px" }}></i>}
          closable
          onClose={(e) => {
            e.stopPropagation();
            onDelete(file.publicUrl);
          }}
          key={idx}
        >
          {file.name}
          {file.size && (
            <span
              style={{
                margin: "0px 8px 0px 4px",
                fontSize: "12px",
                color: "#64748B",
              }}
            >
              {Number(file.size / 1024 / 1024).toFixed(1) > 0
                ? `${Number(file.size / 1024 / 1024).toFixed(1)} MB`
                : `${Number(file.size / 1024).toFixed(1)} KB`}
            </span>
          )}
        </Tag>
      ))}
    </StyledAttachment>
  );
};

const EditPostModal = ({
  isModalVisible,
  setIsModalVisible,
  setUpdater,
  channelDetails,
  postDetails,
  canEdit,
  edit,
}) => {
  const [title, setTitle] = useState("");

  const [description, setDescription] = useState("");
  const [tagsList, setTagsList] = useState([]);
  const [modifiedTagsList, setModifiedTagsList] = useState([]);
  const { channelId } = useParams();
  const { daoDetails, daoId } = useDaoContext();

  const [pollVisibility, setPollVisibility] = React.useState(false);
  const [files, setFiles] = useState([]);
  const inputRef = React.useRef(null);
  const [checklist, setChecklist] = useState([{ title: "" }]);
  const [options, setOption] = React.useState({ values: ["", "", ""] });
  const [questionInput, setQuestionInput] = React.useState("");
  const [focusIndex, setFocusIndex] = useState(() => 0);
  const [showChecklistUI, setShowChecklistUI] = useState(false);
  const [updatedFieldsNames, setUpdatedFieldsNames] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState([]);

  useEffect(() => {
    if (postDetails?.length > 0) {
      setDescription(postDetails[0]?.postDescription);
      if (postDetails[0]?.polls) {
        setOption({ values: postDetails[0]?.polls.map((poll) => poll.name) });
        setPollVisibility(true);
      }
      if (postDetails[0]?.checklist && postDetails[0]?.checklist.length > 0) {
        const editableChecklist = postDetails[0]?.checklist.map((ele) =>
          !ele.assigneeId
            ? ele
            : {
              ...ele,
              assigneeId: {
                id: ele.assigneeId,
                profilePicture: ele.assigneeProfilePicture,
                userName: ele.assigneeUserName,
              },
            }
        );
        setChecklist(editableChecklist);
        setShowChecklistUI(true);
      }
      if (postDetails[0]?.postPollQuestion) {
        setQuestionInput(postDetails[0]?.postPollQuestion);
      }
      if (
        postDetails[0]?.postPollAttachments &&
        postDetails[0]?.postPollAttachments.length > 0
      ) {
        setAttachments(postDetails[0]?.postPollAttachments);
      }
      setTitle(postDetails[0]?.postName);
    }
  }, [postDetails]);

  const [assigneeList, setAssigneeList] = useState([]);
  const getAssigneeList = (daoId) => {
    customAxios
      .get(`/users/daousers?daoId=${daoId}`)
      .then((res) => {
        setAssigneeList(res.data.daoUser);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const getTagsList = () => {
    customAxios
      .get(`tags?daoId=${daoId}`)
      .then((res) => {
        setTagsList(
          res.data.tagsList.map((ele, idx) => ({ name: ele, id: idx }))
        );
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };
  useEffect(() => {
    if (!daoId) return;
    getTagsList();
    getAssigneeList(daoId);
  }, [daoId]);

  useEffect(() => {
    if (tagsList.length > 0) {
      if (postDetails[0]?.postTags) {
        setModifiedTagsList(
          tagsList.map((tag) => {
            // if (tag.name === postDetails[0]?.postTags[0]) {
            //   return {
            //     ...tag,
            //     checked: true,
            //   };
            // }
            if (postDetails[0]?.postTags.includes(tag.name)) {
              return {
                ...tag,
                checked: true,
              };
            }
            return tag;
          })
        );
        let difference = postDetails[0]?.postTags?.filter(
          (x) => !tagsList.map((tag) => tag.name).includes(x)
        );
        if (difference?.length > 0) {
          difference = difference.map((tag) => ({
            name: tag,
            skipDropdown: true,
            checked: true,
          }));
          setModifiedTagsList((prev) => [...prev, ...difference]);
        }
      } else {
        setModifiedTagsList(tagsList);
      }
    } else {
      if (postDetails[0]?.postTags?.length > 0) {
        setModifiedTagsList(
          postDetails[0]?.postTags?.map((tag) => ({
            name: tag,
            skipDropdown: true,
            checked: true,
          }))
        );
      } else {
        setModifiedTagsList(tagsList);
      }
    }
  }, [tagsList, postDetails]);

  const uploadFiles = (file, insertPublicUrl) => {
    const formData = new FormData();
    formData.append("files", file);

    customAxios
      .post("/post/uploadattach", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        file["publicUrl"] = res?.data?.publicUrl[0];
        insertPublicUrl(file);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };

  const addNewChecklistItem = () => {
    const currList = [...checklist];
    currList.push({ title: "" });
    setChecklist(currList.slice());
    setFocusIndex(currList.length - 1);
  };
  const removeChecklist = () => {
    setShowChecklistUI(false);
    setChecklist([{}]);
  };
  const handleCreatePost = async () => {
    const formData = new FormData();
    let flag = 1;
    if (!title) {
      toast.error("Please enter the title for the post");
      flag = 0;
    } else if (showChecklistUI && checklist.length) {
      for (let i = 0; i < checklist.length; i++) {
        const ele = checklist[i];
        if (!ele.title) {
          toast.error("Please enter the title for checklist item");
          setFocusIndex(i);
          flag = 0;
          break;
        }
        // else if (!ele.dueDate) {
        //   toast.error("Please enter the due date for checklist item");
        //   setFocusIndex(i);
        //   flag = 0;
        //   break;
        // }
      }
    } else if (title.length <= MINIMUM_TITLE_CHAR_LIMIT) {
      toast.error(
        `Title is too short. Please make sure it is at least ${MINIMUM_TITLE_CHAR_LIMIT} characters.`
      );
      flag = 0;
    } else if (description.length <= MINIMUM_POST_CHAR_LIMIT) {
      toast.error(
        `Post is too short. Please make sure it is at least ${MINIMUM_POST_CHAR_LIMIT} characters.`
      );
      flag = 0;
    }

    if (pollVisibility && !questionInput) {
      return toast.error("Poll question cannot be empty");
    }
    if (flag) {
      formData.append("postId", postDetails[0]?.postId);
      formData.append("daoId", daoId);
      formData.append("channelId", channelId);
      if (updatedFieldsNames.includes("title")) {
        formData.append("postName", title);
      }
      if (updatedFieldsNames.includes("description")) {
        formData.append("description", description);
      }
      if (updatedFieldsNames.includes("question")) {
        formData.append("pollsQuestion", pollVisibility ? questionInput : null);
      }
      if (options.values[0] !== "" && updatedFieldsNames.includes("polls")) {
        formData.append(
          "polls",
          pollVisibility ? JSON.stringify(options.values) : null
        );
      }
      if (deletedAttachments.length > 0) {
        formData.append(
          "deletedAttachments",
          JSON.stringify(deletedAttachments)
        );
      }
      if (
        modifiedTagsList.filter((ele) => ele.checked && ele.isNew).length > 0
      ) {
        formData.append(
          "newTags",
          JSON.stringify(
            modifiedTagsList
              .filter((ele) => ele.checked && ele.isNew)
              .map((ele) => ele.name)
          )
        );
      }
      if (
        modifiedTagsList.filter((ele) => ele.checked && !ele.isNew).length > 0
      ) {
        formData.append(
          "tags",
          JSON.stringify(
            modifiedTagsList
              .filter((ele) => ele.checked && !ele.isNew)
              .map((ele) => ele.name)
          )
        );
      }
      if (
        postDetails[0]?.postTags &&
        postDetails[0]?.postTags?.length > 0 &&
        modifiedTagsList.filter(
          (ele) =>
            !ele.checked &&
            !ele.isNew &&
            ele.name === postDetails[0]?.postTags[0]
        ).length > 0
      ) {
        formData.append(
          "deletedTags",
          JSON.stringify(
            modifiedTagsList
              .filter(
                (ele) =>
                  !ele.checked &&
                  !ele.isNew &&
                  ele.name === postDetails[0]?.postTags[0]
              )
              .map((ele) => ele.name)
          )
        );
      }
      // let data = {
      //   postId: postDetails[0]?.postId,
      //   channelName: "Hello",
      //   ...(updatedFieldsNames.includes('title') && { postName: title }),
      //   ...(updatedFieldsNames.includes('description') && { description: description }),
      //   daoId: daoId,
      //   channelId: channelId,
      //   ...(options.values[0] !== "" && updatedFieldsNames.includes('polls') && { polls: options.values }),
      //   // polls: options.values,
      //   postPollQuestion: `${questionInput}`,
      //   ...(tagsList.filter((ele) => ele.checked && !ele.isNew).length > 0 && {
      //     tags: tagsList
      //       .filter((ele) => ele.checked && !ele.isNew)
      //       .map((ele) => ele.name),
      //   }),
      //   // tags: tagsList
      //   //   .filter((ele) => ele.checked && !ele.isNew)
      //   //   .map((ele) => ele.name),
      //   ...(tagsList.filter((ele) => ele.checked && ele.isNew).length > 0 && {
      //     newTags: tagsList
      //       .filter((ele) => ele.checked && ele.isNew)
      //       .map((ele) => ele.name),
      //   }),
      //   // newTags: tagsList
      //   //   .filter((ele) => ele.checked && ele.isNew)
      //   //   .map((ele) => ele.name),
      // };
      // need to enable below fields for edit post after testing
      // if (showChecklistUI && checklist.length) {
      //   data["checklist"] = checklist.map((ele, idx) => ({
      //     ...ele,
      //     order: idx,
      //     attachments: ele?.attachments?.length
      //       ? ele.attachments?.map((ele) => ele.publicUrl)
      //       : [],
      //     assigneeId: ele.assigneeId ? ele.assigneeId.id : null,
      //   }));
      // }
      // if (tagsList.filter((ele) => ele.checked && ele.isNew).length) {
      //   data["newTags"] = tagsList
      //     .filter((ele) => ele.checked && ele.isNew)
      //     .map((ele) => ele.name);
      // }
      // if (files.length) {
      //   data["attachments"] = files.map((ele) => ele.publicUrl);
      // }
      if (updatedFieldsNames.includes("checklist")) {
        if (showChecklistUI && checklist.length) {
          // const list = checklist.map((ele, idx) => ({
          //   ...ele,
          //   order: idx,
          //   attachments: ele?.attachments?.length
          //     ? ele.attachments?.map((ele) => ele.publicUrl)
          //     : [],
          //   assigneeId: ele.assigneeId ? ele.assigneeId.id : null,
          // }));
          const list = checklist.map((ele, idx) => ({
            ...ele,
            order: idx,
            attachments: ele?.attachments ? ele?.attachments : [],
            assigneeId: ele?.assigneeId ? ele?.assigneeId.id : null,
          }));
          formData.append("checklist", JSON.stringify(list));
        }
      }
      if (updatedFieldsNames.includes("files") && files?.length) {
        files.map((file) => {
          var blob = file.slice(0, file.size, file.type);
          let newFileName = file.name.split(".").join(`_main.`);
          let newFile = new File([blob], newFileName, { type: file.type });
          return formData.append("files", newFile);
        });
      }

      handleSubmitPost(formData);
    }
  };
  const [submitting, setSubmitting] = useState(false);
  const handleSubmitPost = async (formData) => {
    setSubmitting(true);
    customAxios
      .put("post/update", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.data.success) {
          postMixpanel("track", "created_post", {
            dao_id: daoId,
            post_title: title,
            post_id: postDetails[0]?.postId,
          });
          setUpdater((prev) => !prev);
          setIsModalVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setPollVisibility(false);
  };

  const tagDeleteHandler = (name) => {
    customAxios
      .delete(`tags/delete?daoId=${daoId}&tagName=${name}`)
      .then((res) => {
        toast.success("tag deleted successfully");
        getTagsList();
      })
      .catch((err) => {
        console.log("error");
      });
  };

  return (
    <Modal
      destroyOnClose
      width={"696px"}
      bodyStyle={{ minHeight: showChecklistUI ? "500px" : "400px" }}
      title={<StyledTitle>#{channelDetails?.daoChannelName}</StyledTitle>}
      visible={isModalVisible}
      onCancel={() =>
        confirmOperation({
          cancelText: "Discard Changes",
          confirmText: "Continue Editing",
          message: " You will lose the progress if you close this pop-up?",
          title: "Confirm",
          onCancel: () => handleCancel(),
        })
      }
      footer={[
        <Footer key="modal-footer">
          <div style={{ marginLeft: "14px", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                columnGap: "8px",
                flexDirection: "row ",
              }}
            >
              <Button
                className="borderless-button"
                onClick={() => inputRef.current.click()}
              >
                <IconWrapper width="18px">
                  <PaperClipIcon />
                </IconWrapper>
              </Button>
              <input
                ref={inputRef}
                onClick={() => {
                  inputRef.current.value = null;
                }}
                style={{ display: "none" }}
                hidden
                type="file"
                name="Attachment"
                multiple
                onChange={(e) => {
                  if (e.target.files?.length) {
                    const newFile = e.target.files[0];
                    checkFileSize(newFile.size);
                    setUpdatedFieldsNames((prev) => [...prev, "files"]);
                    setFiles((prev) => [...prev, newFile]);
                  }
                }}
              />

              <Button
                style={{ marginLeft: "0px" }}
                className="borderless-button"
                onClick={() => {
                  // setChecklist([{}]);
                  setShowChecklistUI((prev) => !prev);
                }}
              >
                <IconWrapper width={"18px"}>
                  <ChecklistIcon width={"18px"} />
                </IconWrapper>
              </Button>

              <Button
                className="borderless-button"
                onClick={() => setPollVisibility(true)}
              >
                <IconWrapper width="20px">
                  <ChartBarSquareIcon />
                </IconWrapper>
              </Button>
            </div>
          </div>
          <Button
            type="primary"
            disabled={submitting}
            className="rounded-corner"
            onClick={handleCreatePost}
          >
            Post
            <IconWrapper>
              <ArrowLongRightIcon />
            </IconWrapper>
          </Button>
        </Footer>,
      ]}
    >
      <StyledInput
        placeholder="Add the title of your post here"
        size="large"
        bordered={false}
        value={title}
        onChange={(e) => {
          setUpdatedFieldsNames((prev) => [...prev, "title"]);
          setTitle(e.target.value);
        }}
      />
      <GenericTagSelect
        key={1}
        showRemoveTagIcon={true}
        onTagDelete={(name) => tagDeleteHandler(name)}
        skillsList={modifiedTagsList}
        setSkillsList={setModifiedTagsList}
        addButtonText={"Add tag"}
        addNewButtonText={"Add new tag"}
        searchText="Search tags..."
        AddIcon={() => <TagIcon height="16px" width="16px" />}
        canEdit={canEdit}
      />
      {showChecklistUI ? (
        <Col
          xs={24}
          // onBlur={() => setFocusIndex(-1)}
          style={{
            background: "#FFFFFF",
            /* Colors/divider/default */
            margin: "16px 20px",

            padding: "12px 16px",
            border: "1px solid #F1F5F9",
            borderRadius: "12px",
            poition: "relative",
          }}
        >
          <h4 style={{ fontWeight: "500" }}>Checklist</h4>
          <CrossIcon
            color="#64748B"
            onClick={() => removeChecklist()}
            style={{
              position: "absolute",
              top: "15.5px",
              right: "15.5px",
              cursor: "pointer",
            }}
          />
          {checklist.map((ele, idx) => (
            <EditableChecklistItem
              ele={ele}
              BountyFilesAttachment={PostFilesAttachment}
              idx={idx}
              checklist={checklist}
              setChecklist={setChecklist}
              focusIndex={focusIndex}
              setFocusIndex={setFocusIndex}
              uploadFiles={uploadFiles}
              addNewChecklistItem={addNewChecklistItem}
              assigneeList={assigneeList}
              setUpdatedFieldsNames={() =>
                setUpdatedFieldsNames((prev) => [...prev, "checklist"])
              }
            />
          ))}

          <Col>
            <Button
              onClick={() => addNewChecklistItem()}
              type="text"
              style={{
                margin: "12px 0px",
                display: "flex",
                padding: "4px 1px",
                alignItems: "center",
                color: "#64748B",
              }}
              icon={
                <svg
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              }
            >
              Add checklist item{" "}
            </Button>
          </Col>
        </Col>
      ) : null}
      {/* {pollVisibility && (
        <CreatePoll
          onRemovePoll={setPollVisibility}
          setOption={(values) => {
            setUpdatedFieldsNames((prev) => [...prev, "polls"]);
            setOption(values);
          }}
          options={options}
          questionInput={questionInput}
          setQuestionInput={(value) => {
            setUpdatedFieldsNames((prev) => [...prev, "question"]);
            setQuestionInput(value);
          }}
        />
      )} */}
      <QuillEditor
        height="300px"
        value={description}
        style={{ margin: "10px 14px" }}
        isBordered={true}
        onChange={(e) => setDescription(e)}
        paddingLeft="0px"
        setUpdatedFieldsNames={setUpdatedFieldsNames}
      />
      <div style={{ padding: "0 10px", margin: "8px 0" }}>
        {attachments?.length > 0 &&
          attachments.map((attach) => (
            <div
              style={{
                border: "1px solid rgb(226, 232, 240)",
                padding: "0.25rem 0.8rem",
                background: "transparent",
                borderRadius: "52px",
                fontSize: "12px",
                marginRight: "10px",
                marginBottom: "8px",
                display: "inline-block",
              }}
            >
              {getTrunckedString(attach.split("/").pop(), 20)}
              <CloseOutlined
                style={{
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeletedAttachments((prev) => [...prev, attach]);
                  setAttachments((prev) =>
                    prev.filter((value) => !(value === attach))
                  );
                }}
              />
            </div>
          ))}
      </div>
      {files?.length > 0 && (
        <BountyFilesAttachment files={files} setFiles={setFiles} />
      )}
    </Modal>
  );
};

EditPostModal.propTypes = {
  postDetails: PropTypes.array,
};

export default EditPostModal;

import React, { useContext, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Dropdown, Menu } from "antd";
import { useLocation, useNavigate } from "react-router";
import HashIcon from "./../../images/hash-alt.svg";
import HashLockIcon from "./../../images/hash-alt-lock.svg";
import styled from "styled-components";
import { ReactComponent as DragIcon } from "./../../images/dragIcon.svg";

import GreyHorizontalDot from "./../../images/grey-horizontal-dot.svg";
import { getTrunckedString } from "../Proposal/ProposalCard";
import { confirmOperation } from "../../utils/common";
import { DaoContext } from "../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import useWindowSize from "../../hooks/useWindowSize";
const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    background: #d3e1eb;
    color: #1890ff !important;
  }
`;
const StyledDiv = styled.div`
  .extra-menu {
    display: none;
    align-items: center;
  }
  &:hover {
    .extra-menu {
      display: flex;
    }
  }
`;

const StyledDotIconContainer = styled.div`
  position: absolute;
  right: 0.2rem;
  & img:hover {
    cursor: pointer;
  }
`;

export const StyledDropdownMenu = styled(Menu)`
  border-radius: 8px;
`;

export function SortableChannelItem({
  item,
  id,
  setSelectedMenuItem,
  selectedMenuItem,
  canCreateChannel,
  handleDeleteChannel,
  handleEditChannel,
  onClick,
  itemsNew,
  daoName,
}) {
  const { daoId } = useContext(DaoContext);
  const { isMobile } = useWindowSize();

  const menu = (
    <StyledDropdownMenu>
      <Menu.Item onClick={() => handleEditChannel(item)} key="edit">
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          confirmOperation({
            onOk: () => handleDeleteChannel(id),
            cancelText: "Cancel",
            confirmText: "Delete",
            message: "Are you sure you want to delete this channel?",
            title: "Delete Channel",
          })
        }
        key="delete"
      >
        Delete
      </Menu.Item>
    </StyledDropdownMenu>
  );
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.daoChannelId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const navigate = useDaoLensNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.split("channel/")[1]?.split("/")[0]) {
      setSelectedMenuItem(
        location.pathname.split("channel/")[1]?.split("/")[0]
      );
    } else {
      let foundPage = itemsNew.find(
        (singlePage) => singlePage.route == location.pathname
      );
      if (foundPage) setSelectedMenuItem(foundPage.title);
    }
  }, [location.pathname]);
  return (
    <StyledDiv
      ref={setNodeRef}
      style={{ ...style, padding: "0px 4px" }}
      key={id}
    >
      <StyledMenuItem
        key={item.daoChannelId}
        onClick={() => {
          if (onClick) onClick();
          setSelectedMenuItem(item.daoChannelId);

          navigate(`/app/dao/${daoName}/${daoId}/channel/${item.daoChannelId}`);
        }}
        style={{
          //   borderRadius: "12px",

          color: selectedMenuItem === id ? "#1890ff" : "black",
          borderRight: selectedMenuItem === id ? "3px solid #1890ff" : "none",
          backgroundColor: selectedMenuItem === id ? "#f7f7f7" : "initial",
        }}
      >
        {canCreateChannel ? (
          <div className="extra-menu">
            <Button
              shape="circle"
              title="Drag this to reorder channels"
              style={{
                background: "none",
                position: "absolute",
                top: "0.4rem",
                left: "-0.2rem",
                border: "none",
                color: "#64748B",
                boxShadow: "none",
                cursor: "grab",
                marginRight: "4px",
              }}
              icon={<DragIcon />}
              {...attributes}
              {...listeners}
            ></Button>
          </div>
        ) : null}
        <div className="flex justify-between rounded-xl w-full ml-4 max-w-[195px] md:max-w-[217px]">
          <div style={{ borderRadius: "12px", display: "flex" }}>
            {item.type === "PUBLIC" ? (
              <img src={HashIcon} alt="icon" style={{ marginRight: "8px" }} />
            ) : (
              <img
                src={HashLockIcon}
                alt="icon"
                style={{ marginRight: "8px" }}
              />
            )}
            {getTrunckedString(item.daoChannelName, isMobile ? 10 : 18)}
            {item.postCount ? (
              <span style={{ marginLeft: "0.2rem" }}>({item.postCount})</span>
            ) : null}
          </div>
          {canCreateChannel ? (
            <div
              className={isMobile ? "" : "extra-menu"}
              onClick={(e) => e.stopPropagation()}
            >
              <Dropdown overlay={menu} trigger={["click"]}>
                <StyledDotIconContainer>
                  <img src={GreyHorizontalDot} alt="dot icon" />
                </StyledDotIconContainer>
              </Dropdown>
            </div>
          ) : null}
        </div>
      </StyledMenuItem>
    </StyledDiv>
  );
}

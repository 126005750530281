import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import BountyDetails from "../../components/BountyComponents/BountyDetails";
import customAxios from "../../api";
import { useInterval } from "../../hooks/useInterval";
import { fetchBountyDetails } from "./bountyPageSlice";
import { POLLING_REFRESH_INTERVAL } from "../../constants";
import { Skeleton } from "antd";
import ErrorComponent from "../../components/Common/ErrorComponent";
import { getCurrentDateFromNow } from "../../utils";
import GoBackButton from "../../components/InternalComponents/GoBackButton";
import { useDaoContext } from "../../layouts/app/DaoContext";

const BountyDetailsPage = () => {
  const navigation = useNavigate();
  const [bountyDetails, setBountyDetails] = useState(null);
  const [isPollingPaused, setIsPollingPaused] = useState(false);
  const { bountyId} = useParams();
  const { daoDetails, daoId } = useDaoContext();
  
  const [loading, setLoading] = useState(false);
  const fetchBountyDetails = (bountyId, onLoad) => {
    setLoading(true);
    customAxios
      .get(`/bounty/fetch/details?bountyId=${bountyId}`)
      .then((res) => {
        let isExpired;
        // console.log(res.data.bountyDetail);
        if (res.data.bountyDetail[0]?.bountyLastSubmissionDate) {
          isExpired = getCurrentDateFromNow(
            res.data.bountyDetail[0]?.bountyLastSubmissionDate
          )
            .toLowerCase()
            .includes("expired");
        }
        setBountyDetails({ ...res.data.bountyDetail[0], isExpired: isExpired });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        if (onLoad) onLoad();
      });
  };
  useEffect(() => {
    fetchBountyDetails(bountyId);
  }, []);
  useInterval(() => {
    if (!isPollingPaused) {
      fetchBountyDetails(bountyId);
    }
  }, POLLING_REFRESH_INTERVAL);
  return (
    <div>
      <div style={{ height: 20 }} />
      <GoBackButton
        showIcon
        buttonText={"Go Back"}
        style={{ marginBottom: "10px", marginLeft: "5px" }}
        clickHandler={() => {
          navigation(`/app/dao/${daoId}/bounty/`);
        }}
      />
      {loading && !bountyDetails && <Skeleton active />}
      {bountyDetails && (
        <BountyDetails
          bountyLoading={loading}
          bountyDetails={bountyDetails}
          bountyId={bountyId}
          daoId={daoId}
          isPollingPaused={isPollingPaused}
          setIsPollingPaused={setIsPollingPaused}
          fetchBountyDetails={fetchBountyDetails}
        />
      )}
      {!loading && !bountyDetails && <ErrorComponent />}
    </div>
  );
};

export default BountyDetailsPage;

import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Tag } from "antd";
import NewPostLabel from "../../components/NewPostLabel";
import { useParams } from "react-router";
import customAxios from "../../api";
import { toast } from "react-toastify";
import CreatePostModal from "../../components/PostComponents/CreatePostModal";
import useExitPrompt from "../../hooks/useExitPrompt";
import { postMixpanel } from "../../utils";
import GPT from "../../components/GPT/GPT";
import { ChannelMenu } from "../../components/GPT/MenuItem";
import { DaoContext } from "../../layouts/app/DaoContext";
import { UserContext } from "../../layouts/app/UserContext";
import { checkIsDiscourseModalShown } from "../../lib/utils/discourse";
let noPostSuggestions = [
  "Draft a welcome message",
  "Tell me 5 ideas for engaging post",
  "What would elon musk think of this channel",
];

let postSuggestions = [
  "Tell me 5 ideas for engaging post",
  "Key takeaways from recent posts",
  "Write a post to impress the admin of this community",
  "Brainstorm ideas on...",
  "What would elon musk think of this channel",
];
const Post = ({
  setUpdater,
  canEdit,
  isModalVisible,
  setIsModalVisible,
  setChannelName,

  setSortBy,
  sortBy,
  sortingTypeList,
}) => {
  const { channelId } = useParams();

  const [channelDetails, setChannelDetails] = useState(null);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (isModalVisible) setShowExitPrompt(true);
    else setShowExitPrompt(false);
  }, [isModalVisible]);
  useEffect(() => {
    customAxios
      .get(`daochannel/fetch/details?channelId=${channelId}`)
      .then((res) => {
        setChannelDetails(res.data.channel[0]);
      })
      .catch((err) => {
        console.log("error");
      });
  }, [channelId]);
  useEffect(() => {
    if (setChannelName) setChannelName(channelDetails?.daoChannelName);
  }, [channelDetails]);
  const { isAdmin, setIsConnectDiscourseModalOpen, daoDetails } =
    useContext(DaoContext);
  const { userDetails } = useContext(UserContext);

  const isDiscourseModalShown = checkIsDiscourseModalShown(
    daoDetails,
    userDetails
  );

  return (
    <>
      <NewPostLabel
        canEdit={canEdit}
        content={channelDetails?.daoChannelName}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortingTypeList={sortingTypeList}
        onNewPostHandler={() => {
          if (isDiscourseModalShown) {
            setIsConnectDiscourseModalOpen(true);
            return;
          }
          postMixpanel("track", "clicked_create_new_post_button");
          setIsModalVisible(true);
        }}
      />
      {isModalVisible ? (
        <CreatePostModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setUpdater={setUpdater}
          channelDetails={channelDetails}
          canEdit={canEdit}
          description={description}
          title={title}
        />
      ) : null}
      <div style={{ height: 20 }} />
      <GPT
        data={{
          channelId,
          canCreatePost: isAdmin || canEdit,
          channelName: channelDetails?.daoChannelName,
          placeholders:
            parseInt(channelDetails?.postCount) === 0
              ? noPostSuggestions
              : postSuggestions,
          suggestions: [...noPostSuggestions, ...postSuggestions],
          postCount: parseInt(channelDetails?.postCount),
          onCreatePost: (result) => {
            setDescription(result.description);
            setTitle(result.title);

            postMixpanel("track", "clicked_create_new_post_gpt_button");
            setIsModalVisible(true);
          },
        }}
      />
      <div style={{ height: 20 }} />
    </>
  );
};

export default Post;

import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { postMixpanel } from "../utils";

export function useTrackPageView(refreshKey) {
  const {
    daoId,
    bountyId,
    postId,
    courseId,
    channelId,
    proposalId,
    eventId,
    projectId,
    document_id,
    spaceId,
  } = useParams();
  useEffect(() => {
    postMixpanel("track", "page_view", {
      dao_id: daoId,
      space_id: spaceId,
      bounty_id: bountyId,
      post_id: postId,
      course_id: courseId,
      document_id: document_id,
      channel_id: channelId,
      proposal_id: proposalId,
      project_id: projectId,
      event_id: eventId,
    });
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
  }, [refreshKey]);
}

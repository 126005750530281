import { useState } from "react";
import { toast } from "react-toastify";

let tabs = [
  { label: "Once", value: "ONCE" },
  { label: "Daily", value: "DAILY" },
  { label: "Weekly", value: "WEEKLY" },
  { label: "Monthly", value: "MONTHLY" },
];
const Claim = ({ state, setState }) => {
  return (
    <div
      style={{
        font: "normal 600 12px/16px Inter",
        color: "#64748B",
        padding: "20px",
      }}
    >
      Recurrence
      <div style={{ marginTop: "8px" }}>
        <div
          style={{
            display: "flex",
            gap: "8px",
            font: "normal 500 12px/16px Inter",
            flexWrap: "wrap",
          }}
        >
          {tabs.map(({ label, value }) => (
            <div
              onClick={() => {
                setState((prev) => {
                  return { ...prev, recurrence: value };
                });
              }}
              style={{
                padding: "8px 16px",
                border: `1px solid ${
                  state.recurrence === value ? "#76A9FA" : "#E5E7EB"
                }`,
                borderRadius: "40px",
                cursor: "pointer",
                color: state.recurrence === value ? "#3F83F8" : "",
                background: state.recurrence === value ? "#E1EFFE" : "",
              }}
            >
              {label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Claim;

export const claimValidation = (state, res) => {
  if (!state.recurrence) {
    toast.error("Please provide claim recurrence");
    throw new Error();
  } else {
    res.recurrence = state.recurrence;
  }
};

export const claimInitialState = (bountyDetails) => {
  return { recurrence: bountyDetails?.recurrence };
};

import { Button, Col, Dropdown } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  matchPath,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import styled from "styled-components";
import DaoCreateModal from "./DaoCreateModal";
import CreateDAOScratch from "./StyledCreateDAOScratch";
import { useSearchParams } from "react-router-dom";
import { Tooltip } from "antd";
import defaultUser from "./../assets/images/login/defaultUser.jpeg";
import axios from "axios";
import customAxios from "../api";
import sidebarAddImg from "./../assets/images/sidebar/Group 8.png";
import { ReactComponent as LoginIcon } from "./../assets/images/login/login.svg";
import daolensHome from "./../assets/iconSvg/daolensLogoBlue.svg";
import ImportDiscord from "./ImportDiscord";
import search from "./../assets/images/sidebar/search.png";
import SearchModal from "./Search/SearchModal";
import NotificationPanel from "./InternalComponents/NotificationPanel";
import { UserContext } from "../layouts/app/UserContext";
import { getProfileDetails } from "./Profile/Profile";
import CommunityExistsModal from "./CommunityExistsModal";
import mixpanel from "mixpanel-browser";
import { Crisp } from "crisp-sdk-web";
import { getDefaultDaoImage, postMixpanel } from "../utils";
import useWindowSize from "../hooks/useWindowSize";
import {
  MD_SCREEN_BREAKPOINT,
  ROUTES,
  UNAUTH_JOIN_COMMUNITY_DETAILS,
} from "../constants";
import Sidemenu from "./Sidemenu";
import DaoContextProvider from "../layouts/app/DaoContext";
import IconWrapper from "./IconWrapper";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
import { processDaoList } from "../lib/utils/spaces";
import classNames from "classnames";
import useNear from "../hooks/useNear";

const StyledSidePannel = styled.div`
  display: flex;
  flex-direction: column;
  ${"" /* width: 5rem; */}
  gap: 1rem;
  ${"" /* background: #ffffff; */}
  ${"" /* z-index: 301; */}
  ${"" /* box-shadow: 0px 4px 24px rgba(180, 195, 205, 0.1); */}
  height: 100vh;
  position: fixed;
  .sidepannel-btn {
    background: transparent;
    border: none;
  }
  .profile-img {
    &:hover {
      border: 1px solid #3b82f6;
    }
  }
  .sidepannel_image {
    width: 3rem;
    cursor: pointer;
    /* Light/BG/White */
  }
`;

const StyledIcons = styled.div`
  cursor: pointer;
  padding: 0.2rem;
`;
const StyledMenuItem = styled.div`
  color: #64748b;
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;
  border-radius: 12px;
  &:hover {
    background: #f4f2f2;
    cursor: pointer;
  }
`;
const Icons = ({ pannelData, selectedDaoId }) => {
  const navigate = useDaoLensNavigate();
  const { windowSize } = useWindowSize();
  const { setMobileSidebarOpen } = useContext(UserContext);

  const isSpace = pannelData?.spaces?.length !== 0;
  const isDaoSelected = selectedDaoId === pannelData.daoId;
  const res = matchPath(
    "/app/dao/:daoName/:daoId/spaces/:spaceName/:spaceId/*",
    window.location.pathname
  );
  const spaceId = res?.params?.spaceId || "";

  return (
    <StyledIcons
      className={classNames(
        "sidepanel-btn flex flex-col",
        isSpace && "transition-[max-height] duration-500",
        isDaoSelected && isSpace
          ? "bg-opacity-100 bg-purple-50 rounded-xl border border-solid border-purple-200"
          : "bg-opacity-0"
      )}
      style={
        isDaoSelected && pannelData?.spaces?.length === 0
          ? {
            border: "2px solid #3B82F6",
            filter: "drop-shadow(0px 4px 16px rgba(124, 139, 150, 0.16))",
            borderRadius: "14px",
          }
          : {}
      }
      onClick={() => {
        if (windowSize.width < MD_SCREEN_BREAKPOINT)
          setMobileSidebarOpen(false);
        navigate(`/app/dao/${pannelData.daoName}/${pannelData.daoId}/overview`);
      }}
    >
      <Tooltip placement="right" title={pannelData.daoName}>
        <div
          className={classNames(
            isSpace &&
            isDaoSelected &&
            spaceId &&
            "border border-solid border-transparent",
            isSpace &&
            isDaoSelected &&
            !spaceId &&
            "border border-solid rounded-xl border-blue-500"
          )}
        >
          {pannelData.profilePicture ? (
            <img
              src={pannelData?.profilePicture}
              className="sidepannel_image"
              alt="daoLogo"
              style={{
                objectFit: "cover",
                width: "40px",
                height: "40px",
                border: "1px solid #FFFFFF",
                boxShadow: "0px 4px 16px rgba(57, 57, 57, 0.16)",
                borderRadius: "12px",
              }}
            />
          ) : (
            getDefaultDaoImage(
              pannelData?.daoName,
              {
                alignSelf: "center",
                width: "40px",
                height: "40px",
                cursor: "pointer",

                borderRadius: "12px",
              },
              { fontSize: "14px" }
            )
          )}
        </div>
      </Tooltip>
      {isSpace && (
        <div
          className={classNames(
            "flex flex-col gap-2 transition-all duration-500 ease-in-out",
            isDaoSelected ? "max-h-screen pb-1 mt-2" : "max-h-0 overflow-hidden"
          )}
        >
          <div className="w-full border border-solid border-gray-300 h-0" />
          {pannelData?.spaces?.map((space) => (
            <div
              key={space?.daoId}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/app/dao/${pannelData.daoName}/${pannelData.daoId}/spaces/${space?.daoName}/${space?.daoId}`
                );
                if (windowSize.width < MD_SCREEN_BREAKPOINT)
                  setMobileSidebarOpen(false);
              }}
              className={classNames(
                isSpace &&
                isDaoSelected &&
                spaceId !== space?.daoId &&
                "border border-solid border-transparent rounded-full",
                isSpace &&
                isDaoSelected &&
                spaceId === space?.daoId &&
                "border border-solid rounded-full border-blue-500"
              )}
            >
              <Tooltip placement="right" title={space.daoName}>
                {space?.iconEmoji ? (
                  <div
                    style={{ background: space?.iconBackground }}
                    className="text-2xl w-10 h-10 rounded-full flex items-center justify-center"
                  >
                    {space?.iconEmoji}
                  </div>
                ) : space.profilePicture ? (
                  <img
                    src={space?.profilePicture}
                    alt="daoLogo"
                    className="object-cover w-10 h-10 border border-solid border-white rounded-full"
                    style={{
                      boxShadow: "0px 4px 16px rgba(57, 57, 57, 0.16)",
                    }}
                  />
                ) : (
                  getDefaultDaoImage(
                    space?.daoName,
                    {
                      alignSelf: "center",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",

                      borderRadius: "1000px",
                    },
                    { fontSize: "14px" }
                  )
                )}
              </Tooltip>
            </div>
          ))}
        </div>
      )}
    </StyledIcons>
  );
};

const SidePannel = ({ currentStep, setCurrentStep }) => {
  const {
    walkMeStatus,
    sidebarRef,
    sidebarContainerRef,
    setMobileSidebarOpen,
    mobileSidebarOpen,
    reload,
  } = useContext(UserContext);
  const { windowSize, isMobile } = useWindowSize();
  const navigate = useDaoLensNavigate();
  const location = useLocation();
  const [updater, setUpdater] = useState(false);
  const [daoList, setDaoList] = useState([]);
  const [daoDetails, setDaoDetails] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchModal, setSearchModal] = useState(false);
  const [showNotificationPanel, setShowNotificationPanel] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [discordToken, setDiscordToken] = useState("");
  const [canShowSideMenu, setCanShowSideMenu] = useState(false);
  const { update, userDetails, showLoginModal, loading } =
    useContext(UserContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [userProfilePicture, setUserProfilePicture] = useState(defaultUser);
  const handleNewDao = () => {
    setCurrentStep(2);
  };
  const onDiscard = (previousRoute) => {
    navigate(previousRoute);
    setCurrentStep(0);
  };
  useEffect(() => {
    const fn = async () => {
      try {
        const res = await customAxios.get("/dao/daojoinedbyuser");
        setDaoList(processDaoList(res.data.daoList));
      } catch (error) {
        throw new Error(error.message ?? error);
      }
    };
    fn();
    getProfileDetails().then((data) => {
      if (data?.profilePicture) setUserProfilePicture(data.profilePicture);
    });
  }, [updater, update]);
  useEffect(() => {
    if (location.pathname === "/app/home" && searchParams.get("error")) {
      const previousRoute = atob(searchParams.get("state"));
      onDiscard(previousRoute);
    } else if (location.pathname === "/app/home" && searchParams.get("code")) {
      setDiscordToken(searchParams.get("code"));
      setCurrentStep(3);
    }
  }, [location]);
  useEffect(() => {
    if (location.pathname != "/app/discovery") setCanShowSideMenu(true);
  }, [mobileSidebarOpen]);

  useEffect(() => {
    let pollingInterval = setInterval(() => {
      customAxios
        .get("/notifications/polling")
        .then((res) => {
          setUnreadNotificationCount(res.data.unReadCount[0].count);
        })
        .catch((err) => console.log("polling err", err));
    }, 5000);
    return () => clearInterval(pollingInterval);
  }, []);

  const handleCreateDao = () => {
    navigate("/app/onboarding");
  };
  const handleSubmitDao = async ({
    selectedState,
    daoName,
    discordGuildId,
  }) => {
    setLoadingButton(true);
    const formData = new FormData();
    formData.append("name", daoName);
    formData.append("discordGuildId", discordGuildId);
    if (selectedState.icon) {
      const { data } = await axios.get(
        `https://cdn.discordapp.com/icons/${selectedState.id}/${selectedState.icon}.png`,
        { responseType: "blob" }
      );
      formData.append("files", data);
    }
    const previousRoute = atob(searchParams.get("state"));

    customAxios
      .post("/dao/create", formData)
      .then((res) => {
        setLoadingButton(false);
        if (!res.data.isDiscordGuildIDExist) {
          setCurrentStep(0);
          setUpdater((prev) => !prev);
          if (previousRoute !== "undefined") {
            if (previousRoute === "/app/onboarding") {
              postMixpanel(
                "track",
                "created_community_from_discord_through_onboarding_flow",
                {
                  dao_id: res.data.daoId,
                  dao_name: daoName,
                }
              );

              navigate(
                `${previousRoute}?fromDiscord=true&daoId=${res.data.daoId}&daoName=${daoName}&type=import`
              );
            } else {
              postMixpanel("track", "created_community_from_discord", {
                dao_id: res.data.daoId,
                dao_name: daoName,
              });
              navigate(previousRoute);
            }
          } else {
            postMixpanel("track", "created_community_from_discord", {
              dao_id: res.data.daoId,
              dao_name: daoName,
            });
            navigate(`/app/dao/${res.data.daoName}/${res.data.daoId}/overview`);
          }
        } else {
          setCurrentStep(4);
          setDaoDetails(res.data.isDiscordGuildIDExist);
        }
      })
      .catch((err) => {
        setLoadingButton(false);

        console.log(err);
      });
  };
  const res = matchPath(
    {
      path: "/app/dao/:daoName/:daoId/*",
    },
    window.location.pathname
  );
  const { signOut } = useNear();

  return (
    <div>
      <StyledSidePannel
        style={
          windowSize.width < MD_SCREEN_BREAKPOINT
            ? { zIndex: 1 }
            : {
              backgroundColor: "#ffffff",
              zIndex: 301,
              boxShadow: "0px 4px 24px rgba(180, 195, 205, 0.1)",
            }
        }
        ref={sidebarContainerRef}
      >
        <SearchModal
          isModalVisible={searchModal}
          closeModal={() => {
            setSearchModal(false);
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          {windowSize.width < MD_SCREEN_BREAKPOINT ? (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginRight: "10px",
                transform: "translate(-100%,-15px)",
                backgroundColor: "#ffffff",
                height: "100%",
                paddingTop: "20px",
                paddingRight: "10px",
                position: "relative",
                overflow: "visible",
                boxShadow:
                  "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)",
              }}
              ref={sidebarRef}
            >
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  zIndex: "200",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  right: "-14px",
                  backgroundColor: "#ffffff",
                  borderRadius: "40px",
                  height: "24px",
                  width: "24px",
                  cursor: "pointer",
                }}
                onClick={() => setMobileSidebarOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="#6B7280"
                  className="w-6 h-6"
                  style={{ transform: "scale(1.25)" }}
                  width="24px"
                  height="24px"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              <div
                className="sidebar-navigation"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "auto",
                  rowGap: "1rem",
                  maxHeight: "71%",
                  alignItems: "center",
                }}
              >
                {isMobile ? (
                  <Tooltip placement="right" title={"Support"}>
                    <Button
                      type="secondary"
                      size="large"
                      style={{
                        borderRadius: "12px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                      icon={
                        <i
                          class="fa-solid fa-comment"
                          style={{ fontSize: "18px", color: "#64748B" }}
                        ></i>
                      }
                      onClick={() => {
                        setMobileSidebarOpen(false);
                        Crisp.chat.show();
                        Crisp.chat.open();
                      }}
                    ></Button>
                  </Tooltip>
                ) : null}

                {window.location.host !== "uniflux.daolens.com" ? (
                  <Tooltip placement="right" title={"Add Community"}>
                    <button
                      className="sidepannel-btn add-community"
                      onClick={() => {
                        handleCreateDao();
                      }}
                    >
                      <img src={sidebarAddImg} className="sidepannel_image" />
                    </button>
                  </Tooltip>
                ) : null}

                {daoList &&
                  daoList?.map((pannelData, idx) => {
                    return (
                      <Icons
                        pannelData={pannelData}
                        selectedDaoId={res?.params?.daoId}
                      />
                    );
                  })}
              </div>

              {canShowSideMenu && location.pathname !== ROUTES.appDiscovery && (
                <DaoContextProvider>
                  <Col span={4} style={{ maxWidth: "fit-content" }}>
                    <Sidemenu />
                  </Col>
                </DaoContextProvider>
              )}
            </div>
          ) : (
            <div
              className="sidebar-navigation"
              style={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                rowGap: "1rem",
                maxHeight: "71%",
                alignItems: "center",
              }}
            >
              {window.location.host !== "uniflux.daolens.com" ? (
                <Tooltip placement="right" title={"Discover"}>
                  <Button
                    type="secondary"
                    size="large"
                    style={{
                      borderRadius: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      minHeight: "40px",
                    }}
                    icon={
                      <img
                        src={daolensHome}
                        style={{ width: "18px", cursor: "pointer" }}
                      />
                    }
                    onClick={() => {
                      if (windowSize.width < MD_SCREEN_BREAKPOINT)
                        setMobileSidebarOpen(false);
                      navigate("/app/discovery");
                    }}
                  ></Button>
                </Tooltip>
              ) : null}
              {window.location.host !== "uniflux.daolens.com" ? (
                <Tooltip placement="right" title={"Add Community"}>
                  <button
                    className="sidepannel-btn add-community"
                    onClick={() => {
                      handleCreateDao();
                    }}
                  >
                    <img src={sidebarAddImg} className="sidepannel_image" />
                  </button>
                </Tooltip>
              ) : null}
              {daoList &&
                daoList?.map((pannelData, idx) => {
                  return (
                    <Icons
                      pannelData={pannelData}
                      selectedDaoId={res?.params?.daoId}
                    />
                  );
                })}
            </div>
          )}

          {!isMobile ? (
            <div
              className="sidebarActions"
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
                height: "27%",
                marginBottom: !isMobile && userDetails && "24px",
              }}
            >
              {!loading && userDetails ? (
                <Tooltip placement="right" title={"Search"}>
                  <Button
                    type="secondary"
                    className="global-search"
                    size="large"
                    style={{
                      borderRadius: "12px",
                      justifyContent: "center",
                      marginTop: !isMobile && "auto",
                      alignItems: "center",
                      display: "flex",
                    }}
                    icon={
                      <img
                        src={search}
                        style={{ width: "18px", cursor: "pointer" }}
                      />
                    }
                    onClick={() => {
                      setSearchModal(true);
                    }}
                  ></Button>
                </Tooltip>
              ) : null}
              {!loading && userDetails ? (
                <Tooltip placement="right" title={"Notifications"}>
                  <Button
                    type="secondary"
                    size="large"
                    className="notification"
                    style={{ borderRadius: "12px" }}
                    icon={
                      <i
                        class="fa-solid fa-bell "
                        style={{ position: "relative", color: "#64748B" }}
                      >
                        {unreadNotificationCount !== 0 && (
                          <span
                            style={{
                              position: "absolute",
                              display: "inline-flex",

                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                              backgroundColor: "#3B82F6",
                              height: "20px",
                              width: "20px",
                              borderRadius: "1rem",
                              fontSize: "10px",
                              fontWeight: "600",
                              top: "-15px",
                              right: "-18px",
                            }}
                          >
                            {unreadNotificationCount}
                          </span>
                        )}
                      </i>
                    }
                    onClick={() => {
                      //  ! Open notification sidebar
                      setShowNotificationPanel(!showNotificationPanel);
                    }}
                  ></Button>
                </Tooltip>
              ) : null}
              {!loading && !userDetails ? (
                <Tooltip placement="right" title={"Login"}>
                  <Button
                    type="secondary"
                    size="large"
                    style={{
                      borderRadius: "12px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      marginTop: !isMobile && "auto",
                      padding: "0 7px",
                    }}
                    onClick={() => {
                      showLoginModal(false);
                      setMobileSidebarOpen(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#64748B"
                      className="w-6 h-6"
                      style={{ height: "24px", width: "24px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                      />
                    </svg>
                  </Button>
                </Tooltip>
              ) : null}
              {!loading && userDetails ? (
                <Tooltip
                  placement="right"
                  color="white"
                  className="user-profile"
                  title={
                    <>
                      <StyledMenuItem
                        onClick={() => {
                          navigate("/app/profile");
                        }}
                        style={{ padding: "10px 24px 4px 10px" }}
                      >
                        <svg
                          width="12"
                          height="13"
                          viewBox="0 0 12 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5.99984 5.19981C6.63636 5.19981 7.24681 4.94695 7.6969 4.49687C8.14699 4.04678 8.39984 3.43633 8.39984 2.79981C8.39984 2.16329 8.14699 1.55284 7.6969 1.10275C7.24681 0.652667 6.63636 0.399811 5.99984 0.399811C5.36332 0.399811 4.75287 0.652667 4.30279 1.10275C3.8527 1.55284 3.59984 2.16329 3.59984 2.79981C3.59984 3.43633 3.8527 4.04678 4.30279 4.49687C4.75287 4.94695 5.36332 5.19981 5.99984 5.19981V5.19981ZM0.399841 12.3998C0.399841 11.6644 0.54469 10.9362 0.826116 10.2568C1.10754 9.57736 1.52004 8.96002 2.04004 8.44001C2.56005 7.92001 3.17739 7.50751 3.85681 7.22609C4.53624 6.94466 5.26444 6.79981 5.99984 6.79981C6.73524 6.79981 7.46345 6.94466 8.14287 7.22609C8.82229 7.50751 9.43963 7.92001 9.95964 8.44001C10.4796 8.96002 10.8921 9.57736 11.1736 10.2568C11.455 10.9362 11.5998 11.6644 11.5998 12.3998H0.399841Z"
                            fill="#64748B"
                          />
                        </svg>
                        My Profile
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={async () => {
                          await signOut();
                          try {
                            postMixpanel("track", "logged_out");
                            localStorage.removeItem("access_token");
                            localStorage.removeItem(
                              UNAUTH_JOIN_COMMUNITY_DETAILS
                            );
                            mixpanel.reset();
                            Crisp?.session?.reset();
                            reload();

                          } catch (err) {
                            console.log("err", err);
                          } finally {
                            navigate("/app/discovery");


                          }

                          // navigate("/");
                        }}
                        style={{ padding: "4px 24px 10px 10px" }}
                      >
                        <svg
                          width="14"
                          height="12"
                          viewBox="0 0 14 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.3333 8.66681L13 6.00014M13 6.00014L10.3333 3.33348M13 6.00014H3.66667M7.66667 8.66681V9.33348C7.66667 9.86391 7.45595 10.3726 7.08088 10.7477C6.70581 11.1228 6.1971 11.3335 5.66667 11.3335H3C2.46957 11.3335 1.96086 11.1228 1.58579 10.7477C1.21071 10.3726 1 9.86391 1 9.33348V2.66681C1 2.13638 1.21071 1.62767 1.58579 1.2526C1.96086 0.877523 2.46957 0.666809 3 0.666809H5.66667C6.1971 0.666809 6.70581 0.877523 7.08088 1.2526C7.45595 1.62767 7.66667 2.13638 7.66667 2.66681V3.33348"
                            stroke="#F05252"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Logout
                      </StyledMenuItem>
                    </>
                  }
                  overlayInnerStyle={{ padding: "0px", borderRadius: "12px" }}
                >
                  <Button
                    style={{ height: "auto" }}
                    icon={
                      <img
                        src={userProfilePicture}
                        className="profile-img"
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          aspectRatio: "1",
                          borderRadius: "50%",
                        }}
                      />
                    }
                    type="text"
                  ></Button>
                </Tooltip>
              ) : null}
            </div>
          ) : null}
        </div>
      </StyledSidePannel>
      <div>
        {currentStep === 1 && (
          <DaoCreateModal
            setCurrentStep={setCurrentStep}
            handleNewDao={handleNewDao}
          />
        )}
        {currentStep === 2 && (
          <CreateDAOScratch
            setCurrentStep={setCurrentStep}
            isDaoCreated={updater}
            setUpdater={setUpdater}
          />
        )}
        {currentStep === 3 && (
          <ImportDiscord
            setCurrentStep={setCurrentStep}
            discordToken={discordToken}
            handleSubmit={handleSubmitDao}
            setUpdater={setUpdater}
          />
        )}
        {currentStep === 4 && (
          <CommunityExistsModal
            daoDetails={daoDetails}
            setDaoDetails={setDaoDetails}
            setCurrentStep={setCurrentStep}
          />
        )}
      </div>
      {showNotificationPanel && (
        <NotificationPanel closePanel={setShowNotificationPanel} />
      )}
    </div>
    // </ClickAwayListener>
  );
};

export default SidePannel;

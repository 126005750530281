import { UserCircleIcon } from "@heroicons/react/24/outline";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { UserContext } from "../../../../layouts/app/UserContext";
import { Modal } from "../../../../styles/ModalStyles";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  emailId: string;
  profilePicture: string;
  profileName: string;
};

const SwitchAccountModal = ({
  isOpen,
  setIsOpen,
  emailId,
  profileName,
  profilePicture,
}: Props) => {
  const { logout } = useContext(UserContext);
  return (
    <Modal
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      // @ts-expect-error
      height={"auto"}
      footer={null}
    >
      <div className="flex flex-col p-5">
        <p className="text-base text-black font-semibold m-0">
          An account with this Google ID already exists
        </p>
        <p className="text-slate-500 m-0 mt-2">
          Please switch your account to access the discussions
        </p>
        <div className="flex border border-solid border-gray-200 rounded-xl p-3 gap-3 items-center mt-4">
          {profilePicture ? (
            <img
              src={profilePicture}
              className="w-12 h-12 rounded-full object-cover"
              alt="profile"
            />
          ) : (
            <UserCircleIcon className="w-12 h-12" />
          )}
          <div className="flex flex-col">
            <p className="m-0 font-semibold">{profileName}</p>
            <p className="m-0 text-sm text-gray-500">{emailId}</p>
          </div>
          <button
            onClick={() => logout("/auth/login")}
            className="text-sm rounded-xl cursor-pointer border-none bg-blue-500 text-white py-2 px-4 hover:bg-blue-600 active:bg-blue-700 ml-auto"
          >
            Switch Account
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SwitchAccountModal;

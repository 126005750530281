import moment from "moment/moment";
import "./InternalComponentStyles/SingleNotificationCards.scss";
import React from "react";
import atIcon from "../../assets/iconSvg/@.svg";
import assignedIcon from "../../assets/iconSvg/assignedIcon.svg";
import notificationCommentIcon from "../../assets/iconSvg/notificationCommentIcon.svg";
import newProposalIcon from "../../assets/iconSvg/newProposalIcon.svg";
import changedDeadlineIcon from "../../assets/iconSvg/changedDeadlineIcon.svg";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import HtmlParser from "react-html-parser";
import { getTruncatedAddress, getTruncatedString } from "../../utils";
const STATUS_DATA = {
  mentioned: {
    icon: atIcon,
    primaryColor: "#9061F9",
    secondaryColor: "#F6F5FF",
  },
  assigned: {
    icon: assignedIcon,
    primaryColor: "#057A55",
    secondaryColor: "#DEF7EC",
  },
  reviewer: {
    icon: assignedIcon,
    primaryColor: "#057A55",
    secondaryColor: "#DEF7EC",
  },
  tagged: {
    icon: assignedIcon,
    primaryColor: "#057A55",
    secondaryColor: "#DEF7EC",
  },
  commented: {
    icon: notificationCommentIcon,
    primaryColor: "#6875F5",
    secondaryColor: "#E5EDFF",
  },
  replied: {
    icon: notificationCommentIcon,
    primaryColor: "#6875F5",
    secondaryColor: "#E5EDFF",
  },
  proposals: {
    icon: newProposalIcon,
    primaryColor: "#E74694",
    secondaryColor: "#FDF2F8",
  },
  posts: {
    icon: newProposalIcon,
    primaryColor: "#E74694",
    secondaryColor: "#FDF2F8",
  },
  "changed deadline": {
    icon: changedDeadlineIcon,
    primaryColor: "#F05252",
    secondaryColor: "#FDF2F2",
  },
  discourse_migration_failed: {
    icon: assignedIcon,
    primaryColor: "#057A55",
    secondaryColor: "#DEF7EC",
  },
  discourse_migration_complete: {
    icon: assignedIcon,
    primaryColor: "#057A55",
    secondaryColor: "#DEF7EC",
  },
};
const TEMPLATE_VARIABLES = [
  "<user>",
  "<bounty_title>",
  "<comment>",
  "<post_title>",
  "<proposal_title>",
  "<event_title>",
  "<announcement_title>",

  "<task_title>",
  "<course_title>",
  "<collection_title>",
  "<project_title>",
  "<template_text>",
];
const TEMPLATE = {
  bounty: {
    bounty_assignment: { reviewer: "<user> assigned you <bounty_title>" },
    bounty_details: {
      tagged: "<user> tagged you in <bounty_title>",
      created: "<user> created a bounty <bounty_title>",
    },
    bounty_comment: {
      commented: "<user> commented <comment> on your bounty",
      tagged: "<user> tagged you on <comment> comment",
      replied: "<user> replied on <comment> comment",
    },
  },
  post: {
    post_details: {
      tagged: "<user> tagged you in <post_title>",
      created: "<user> created a post <post_title>",
    },
    post_comment: {
      commented: "<user> commented <comment> on your post",
      tagged: "<user> tagged you on <comment> comment",
      replied: "<user> replied on <comment> comment",
    },
  },
  proposals: {
    proposals_created: {
      proposals: "<user> posted new proposal <proposal_title>",
    },
  },
  events: {
    events_comment: {
      commented: "<user> commented <comment> on your event",
      tagged: "<user> tagged you on <comment> comment",
      replied: "<user> replied on <comment> comment",
    },
    event_details: {
      tagged: "<user> tagged you in <event_title>",
      created: "<user> created a event <event_title>",
    },
  },
  discourse: {
    discourse_migration: {
      discourse_migration_failed: "Discourse migration has failed",
      discourse_migration_complete: "Discourse migration is successful",
    },
  },
  tasks: {
    task_details: {
      created: "<user> created a new task <task_title> ",
      updated:
        "<user> updated status of task <task_title> from <template_text> ",
    },
  },
  collection: {
    collection_details: {
      created: "<user> create a new collection <collection_title>",
    },
  },
  courses: {
    courses_details: {
      created: "<user> create a new course <course_title>",
    },
  },
  announcement: {
    announcement_details: {
      created: "<user> create a new announcement <announcement_title>",
    },
  },
  projects: {
    projects_details: {
      created: "<user> create a new project <project_title>",
    },
  },
};
function PostNotificationCard({
  icon,
  from,
  type,
  bucket,
  status,
  notificationDetails,
  time,
  userIcon,
  isUnread,
  readNotificationOnClick,
}) {
  // ! Local states
  const [templateVariables, setTemplateVariables] = useState({
    "<user>": "",
    "<bounty_title>": "",
    "<comment>": "",
    "<post_title>": "",
    "<proposal_title>": "",
    "<event_title>": "",
    "<announcement_title>": "",

    "<task_title>": "",
    "<course_title>": "",
    "<collection_title>": "",
    "<project_title>": "",
    "<template_text>": "",
  });
  // ! Effects
  useEffect(() => {
    setTemplateVariables({
      "<user>": notificationDetails?.assignerDetails?.userName || "User",
      "<bounty_title>":
        notificationDetails?.sourceObjectDetails?.title || "your bounty",
      "<comment>":
        notificationDetails?.sourceObjectDetails?.description || "some comment",
      "<post_title>":
        notificationDetails?.sourceObjectDetails?.name || "your post",
      "<proposal_title>":
        notificationDetails?.sourceObjectDetails?.title || "your proposal",
      "<event_title>":
        notificationDetails?.sourceObjectDetails?.title || "your event",
      "<announcement_title>":
        notificationDetails?.sourceObjectDetails?.title || "your announcement",
      "<task_title>":
        notificationDetails?.sourceObjectDetails?.title || "your task",
      "<course_title>":
        notificationDetails?.sourceObjectDetails?.title || "your course",
      "<collection_title>":
        notificationDetails?.sourceObjectDetails?.title || "your collection",
      "<project_title>":
        notificationDetails?.sourceObjectDetails?.title || "your project",
      "<template_text>": notificationDetails?.template || "status updated",
    });
  }, [from, notificationDetails]);

  // ! Local handlers
  const parseNotificationText = (text) => {
    TEMPLATE_VARIABLES.forEach((variable) => {
      if (text.includes(variable)) {
        if (variable === "<user>")
          text = text.replace(variable, `${templateVariables[variable]}`);
        else
          text = text.replace(
            variable,
            `"${getTruncatedString(templateVariables[variable], 80)}"`
          );
      }
    });
    // if (text?.length > 50) return text.slice(0, 50) + " ...";
    return HtmlParser(text);
  };
  const titleCase = (string) => {
    if (string?.toLowerCase().includes("discourse")) return "Discourse";
    let s = string?.trim()?.toLowerCase()?.split(" ");
    s = s?.map((element) => {
      element =
        element[0]?.toUpperCase() + element?.slice(1, element.length) || "";
      return element;
    });
    return s?.join(" ") || "";
  };
  const getTemplate = (type, bucket, content) => {
    console.log("template details", type, bucket, content);
    return (
      TEMPLATE[type.toLowerCase()][bucket.toLowerCase()][
        content.toLowerCase()
      ] || "You have a new notification!"
    );
  };

  return (
    <div
      className="single-notification-card post-notification-card"
      onClick={readNotificationOnClick}
    >
      <div className="single-notification-card__banner">
        <div className="single-notification-card__banner--left">
          {isUnread && <span className="unread-notification"></span>}
          {/* <img src={icon} alt="" /> */}
          <span>{titleCase(from)}</span>
          <span className="single-notification-card__banner--left-separator"></span>
          <span>{titleCase(type)}</span>
        </div>
        {console.log("status", status)}
        {status.toLowerCase() === "created" ||
        status.toLowerCase() === "updated" ? null : (
          <div
            className="single-notification-card__banner--right"
            style={{
              backgroundColor:
                STATUS_DATA[status.toLowerCase()]?.secondaryColor,
            }}
          >
            <img src={STATUS_DATA[status.toLowerCase()]?.icon} alt="" />
            <span
              style={{ color: STATUS_DATA[status.toLowerCase()]?.primaryColor }}
            >
              {titleCase(status)}
            </span>
          </div>
        )}
      </div>
      <p className="single-notification-card__notification--text">
        <img src={userIcon} alt="" />{" "}
        {/* {parseNotificationText(notificationText)} */}
        <span>{parseNotificationText(getTemplate(type, bucket, status))}</span>
      </p>
      <span className="single-notification-card__notification--time">
        {moment(time).fromNow()}
        {/* <span className="single-notification-card__banner--left-separator"></span>
        {moment(time).format("HH:MM A")} */}
      </span>
    </div>
  );
}

export { PostNotificationCard };

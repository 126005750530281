import classNames from "classnames";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useDaoContext } from "../../../../layouts/app/DaoContext";
import { UserContext } from "../../../../layouts/app/UserContext";
import {
  CreateAccountDataType,
  createDiscourseAccount,
  validateCreateAccountData,
} from "../../../../lib/utils/discourse";
import { Modal } from "../../../../styles/ModalStyles";
import styles from "../../../../components/Course/course.module.css";
import ConnectGoogle from "./ConnectGoogle";
import CreateAccount from "./CreateAccount";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

enum Steps {
  loading,
  intro,
  create,
}

const DiscourseAccountCreationModal = ({ isOpen, setIsOpen }: Props) => {
  const { isMobile } = useWindowSize();
  const { userDetails, reload } = useContext(UserContext);
  const { daoId } = useDaoContext();
  const [step, setStep] = useState<Steps>(Steps.loading);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const showSuccessModal = () =>
    Modal.info({
      title: (
        <span style={{ color: "#000000", fontWeight: "600", fontSize: "16px" }}>
          You’re almost done!
        </span>
      ),
      icon: null,
      content: (
        <span style={{ color: "#64748B", fontWeight: "500", fontSize: "14px" }}>
          We sent an activation mail to {email}. Please follow the instructions
          in the mail to activate your account. If it doesn’t arrive, check your
          spam folder.
        </span>
      ),
      okText: "Ok",
      closable: true,
      onOk: () => {
        setIsOpen(false);
        setEmail("");
        setPassword("");
        setUsername("");
        setReEnterPassword("");
      },
      bodyStyle: {
        paddingTop: "16px",
        paddingBottom: "12px",
        paddingLeft: "24px",
        paddingRight: "24px",
        borderRadius: "16px",
      },
      okButtonProps: {
        style: {
          background: "#3F83F8",
          fontWeight: "500",
          borderRadius: "12px",
        },
      },
      modalRender: (children) => (
        <div className={styles.antModalContainer}>{children}</div>
      ),
    });

  const { mutate: createDiscourseAccountMutateFn, isLoading } = useMutation({
    mutationFn: (data: CreateAccountDataType & { daoId: string }) =>
      createDiscourseAccount(data),
    onSuccess: (data) => {
      if (data.success) {
        reload();
        showSuccessModal();
        return;
      }

      if (data?.errors) {
        const errors = {};
        Object.entries(data?.errors).forEach(([key, value]) => {
          errors[key] = value.join(" ");
        });
        setFormErrors(errors);
      }

      if (data?.status === "Account Exist on discourse") {
        toast.error("Account already exists");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Something went wrong while creating your Discourse account."
      );
    },
  });

  useEffect(() => {
    if ((userDetails as any)?.isGoogleConnected) {
      setEmail((userDetails as any)?.emailId);
      setStep(Steps.create);
    } else setStep(Steps.intro);
  }, [userDetails]);

  const handleCreateAcount = () => {
    setFormErrors({});
    const data: CreateAccountDataType = {
      email,
      name,
      password,
      reEnterPassword,
      username,
    };

    const errors = validateCreateAccountData(data);
    if (Object.keys(errors).length !== 0) {
      setFormErrors(errors);
      return;
    }

    createDiscourseAccountMutateFn({ ...data, daoId });
  };

  return (
    <Modal
      visible={isOpen}
      // @ts-expect-error
      isMobile={isMobile}
      destroyOnClose
      width="550px"
      onCancel={() => setIsOpen(false)}
      centered={true}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(20px)",
      }}
      footer={
        step === Steps.create ? (
          <div className="flex justify-end items-center">
            <button
              onClick={handleCreateAcount}
              className={classNames(
                "text-sm font-semibold rounded-xl border-none bg-blue-500 text-white py-2 px-4  ml-auto",
                isLoading
                  ? "cursor-not-allowed opacity-70"
                  : "hover:bg-blue-600 active:bg-blue-700 cursor-pointer"
              )}
            >
              Create Account
            </button>
          </div>
        ) : null
      }
      height={"auto"}
    >
      {step === Steps.intro && (
        <ConnectGoogle
          handleFlowComplete={() => {
            reload();
            setIsOpen(false);
          }}
          handleNext={() => setStep(Steps.create)}
          setEmail={setEmail}
        />
      )}
      {step === Steps.create && (
        <CreateAccount
          email={email}
          name={name}
          password={password}
          reEnterPassword={reEnterPassword}
          setName={setName}
          setPassword={setPassword}
          setReEnterPassword={setReEnterPassword}
          setUserName={setUsername}
          username={username}
          errors={formErrors}
        />
      )}
    </Modal>
  );
};

export default DiscourseAccountCreationModal;

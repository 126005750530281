import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import customAxios from "../../api";
import { useDaoContext } from "../../layouts/app/DaoContext";
import React from "react";
import { getContractDetails, getGatingList } from "../../lib/utils/spaces";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { useNavigate } from "react-router";

export const fetchBounties = async (daoId: string) => {
  const response = await customAxios.post("bounty/fetch", {
    daoId,
  });

  return response?.data;
};
interface PresetDetailsProps {
  gatingCondition: any;
}
const PresetDetails = ({ gatingCondition }: PresetDetailsProps) => {
  const { daoDetails, isSpace } = useDaoContext();
  const daolensNavigate = useDaoLensNavigate();
  const navigate = useNavigate();
  const [update, setUpdate] = useState(false);

  let daoId =
    isSpace && window.location.pathname.includes("settings/profile")
      ? daoDetails.parentDaoId
      : daoDetails.daoId;

  const { data, isLoading } = useQuery(
    ["bounties", daoId],
    () => fetchBounties(daoId || ""),
    {
      onError: (error) => {
        console.error(error);
      },
      onSettled(data, error) {},
    }
  );
  let conditionText =
    gatingCondition?.operator === "OR"
      ? "satisfy one of the conditions"
      : "satisfy all the conditions";
  return (
    <div className="text-gray-800 font-normal text-sm space-y-3 bg-gray-50 rounded-xl p-4 w-full">
      {gatingCondition?.conditions.length > 1 && (
        <div className="font-medium mb-1">
          Members need to <span className="font-semibold">{conditionText}</span>{" "}
          below:
        </div>
      )}
      {isLoading
        ? null
        : getGatingList(
            gatingCondition,
            data?.res,
            isSpace && window.location.pathname.includes("settings/profile")
              ? navigate
              : daolensNavigate,
            data?.daoDetails.daoDetails[0]
          )?.map((item, index) => item)}
    </div>
  );
};

export default PresetDetails;

import React, { useEffect } from "react";
import "../ComponentStyles/BountyWriteComment.scss";
import dummyAvatar from "../../assets/images/login/defaultUser.jpeg";
import crossIcon from "../../assets/iconSvg/crossIcon.svg";
import { Input } from "antd";
import PrimaryButtonFilled from "../InternalComponents/PrimaryButtonFilled";
import { useState } from "react";
import { getProfileDetails } from "../Profile/Profile";

const { TextArea } = Input;

function PostWriteComment({ setAccordionState, addComment }) {
  const [comment, setComment] = useState("");
  const [userProfilePicture, setUserProfilePicture] = useState(dummyAvatar);
  useEffect(() => {
    getProfileDetails().then((data) => {
      if (data.profilePicture) setUserProfilePicture(data.profilePicture);
    });
  }, [])

  return (
    <div className="bounty-write-comment__wrapper" style={{ marginBottom: '1em' }}>
      <div className="bounty-write-comment__user-details">
        <img
          src={userProfilePicture}
          alt=""
          className="bounty-write-comment__user-details--icon"
        />
        <div className="bounty-write-comment__user-details--content">
          <span className="bounty-write-comment__user-details--content-time">
            12:30 PM
          </span>
          <span className="bounty-write-comment__user-details--content-name">
            New comment by Aman Saxena
          </span>
        </div>
        <img
          src={crossIcon}
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            setAccordionState(false);
          }}
          className="bounty-write-comment__user-details--cross"
        />
      </div>
      <TextArea
        className="bounty-write-comment__text-area"
        rows={4}
        placeholder="Write your comment here ..."
        value={comment}
        onChange={(ev) => {
          ev.stopPropagation();
          setComment(ev.target.value);
        }}
      />
      <PrimaryButtonFilled
        buttonText="Add comment"
        fillColor="#3B82F6"
        style={{
          padding: "8px 12px",
          alignSelf: "flex-end",
          marginTop: "12px",
        }}
        clickHandler={(e) => {
          e.stopPropagation();
          addComment(comment);
          setAccordionState(false)
        }}
      />
    </div>
  );
}

export default PostWriteComment;

import { useWalletLogin, useWalletLogout, useActiveProfile } from '@lens-protocol/react-web';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import customAxios from '../api';
import unauthenticatedAxios from '../api/unauthenticatedaxios';
const useLens = (onSuccess, onSign, onError) => {
    const { execute: login, isPending: isLoginPendin, } = useWalletLogin();
    // const { execute: logout } = useWalletLogout();
    // const { data: wallet, loading } = useActiveProfile();
    const { isConnected } = useAccount();
    const { disconnectAsync } = useDisconnect();
    const { pathname } = useLocation();

    // console.log(wallet)

    const { connectAsync } = useConnect({
        connector: new InjectedConnector(),
    });


    const onLoginClick = async () => {
        try {
            if (isConnected) {
                await disconnectAsync();
            }
            // console.log(wallet)

            const { connector } = await connectAsync();

            if (connector instanceof InjectedConnector) {
                const signer = await connector.getSigner();
                const address = await signer.getAddress();
                // const res = await login(signer);


                const res = await customAxios
                    .post(`auth/${pathname.includes("app/profile") ? "connect/" : ""}lens`, {
                        publicAddress: address,
                    });
                let signature = await signer.signMessage(`I am signing my one-time nonce: ${res.data.message}`);
                onSign();
                let response = await customAxios.post(
                    `auth/${pathname.includes("app/profile") ? "connect/" : ""
                    }lens/login`,
                    {
                        token: "Bearer " + res.data.token,
                        signature: signature,
                    }
                );
                onSuccess(response.data.access_token, response.data.newUser);
            }

        } catch (error) {
            console.log()
            if (error?.response?.data?.error?.stack?.toLowerCase()?.includes("lens profile doesn't exist")) {
                toast.error("Lens profile doesn't exist");
            } else if (error?.response?.data?.error?.stack?.toLowerCase()?.includes(
                "duplicate key value violates unique constraint"

            )) {
                toast.error(
                    "Lens profile is already connected with another account"
                );

            } else {
                toast.error(
                    "Something went wrong"

                );

            }
            onError();

        } finally {
            disconnectAsync();
        }


    }
    return {
        // loading,
        // wallet,
        onLoginClick
    }
}


export default useLens;
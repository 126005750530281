import React from "react";
const SuggestionPill = ({ classname, ...rest }: any) => {
  return (
    <div
      className={`py-2 px-3 border border-solid border-gray-100 rounded-full text-gray-600 text-xs font-medium leading-4  cursor-pointer ${classname}`}
      {...rest}
    />
  );
};
export default SuggestionPill;

import React, { useContext, useEffect, useState } from "react";
import { Input, Button, Col, Skeleton } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import PropTypes from "prop-types";
import BountySubmit from "../BountySubmit";
import customAxios from "../../api";
import { useParams } from "react-router";

import { useNavigate } from "react-router-dom";
import no_course from "../../assets/iconSvg/no_course.svg";
import CreateCourseModal from "./CreateCourseModal";

import "./../ComponentStyles/BountyFeed.scss";
import useExitPrompt from "../../hooks/useExitPrompt";
import OnboardingModal from "../Onboarding/OnboardingModal";
import { postMixpanel, updateWalkmeStatus } from "../../utils";
import { UserContext } from "../../layouts/app/UserContext";

import { DaoContext } from "../../layouts/app/DaoContext";

import CourseCard from "./CourseCard";
import MobilePageTopNavbar from "../Common/MobilePageTopNavbar";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";

import DocumentFeed from "./Document/DocumentFeed";
import { Stylediv } from "./Document/CreateDocumentModal";
import { HoverCard } from "./CourseContent/CourseContentCard";

export const StyledInput = styled(Input)`
  .ant-input {
    font-weight: 500;
  }
`;

const initialSubFilterState = {
  skills: {},
  creator: {},
  difficultyLevel: {},
};

const filterOptions = [
  { value: "skills", displayValue: "Skills" },
  { value: "creator", displayValue: "Creator" },
  { value: "difficultyLevel", displayValue: "Level" },
];

const onlyTruthy = (object) => {
  for (var key in object) {
    if (!object[key]) {
      delete object[key];
    }
  }
  return object;
};
const CoursePage = () => {
  const { walkMeStatus, reload } = useContext(UserContext);
  const { windowSize } = useWindowSize();
  const [courseList, setCourseList] = useState([]);
  const [updater, setUpdater] = useState(false);
  const [isCourseDataFetched, setIsCourseDataFetched] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState("All Courses");
  const [selectedSubFilter, setSelectedSubFilter] = React.useState(
    initialSubFilterState
  );
  // const [filterData, setFilterData] = useState({});
  // const [tags, setTags] = useState([]);

  const [sortOrder, setSortOrder] = React.useState("descending");

  const {
    isAdmin,
    daoId,
    daoName,
    daoDetails: { coursesDisable, documentsDisable },
  } = useContext(DaoContext);

  // const [numberOfFilters, setNumberOfFilters] = useState(0);
  // const [applyFilter, setApplyFilter] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [canManageCourse, setCanManageCourse] = useState(false);
  const [submitBountyModalVisible, setSubmitMountyModalVisible] =
    useState(false);
  const { isMobile } = useWindowSize();
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showEndingModal, setShowEndingModal] = useState(false);
  const [
    showCourseVerificationAdminModal,
    setShowCourseVerificationAdminModal,
  ] = useState(false);

  useEffect(() => {
    customAxios
      .post(`settings/canview`, { daoId: daoId })
      .then((res) => {
        setCanManageCourse(res.data.canView ? true : false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [walkMeStatus]);
  // useEffect(() => {
  //   (async () => {
  //     const {
  //       data: { discordRoles },
  //     } = await customAxios.get(`/daoroles/fetch?daoId=${daoId}`);
  //     setTags(["All guild", ...discordRoles]);
  //     const {
  //       data: { skills },
  //     } = await customAxios.get(`/bounty/skills?daoId=${daoId}`);
  //     const {
  //       data: { creators },
  //     } = await customAxios.get(`/courses/creator/fetch?daoId=${daoId}`);
  //     const {
  //       data: { difficultyLevel },
  //     } = await customAxios.get(`/courses/level/fetch`);

  //     setFilterData({
  //       skills,
  //       reviewers: creators.map((creator) => ({
  //         ...creator,
  //         userId: creator.id,
  //       })),
  //       difficultyLevel: difficultyLevel.map((level) => ({
  //         value: level,
  //         displayValue: level,
  //       })),
  //     });
  //   })();
  // }, [daoId, updater, selectedTab, selectTag]);

  useEffect(() => {
    setIsCourseDataFetched(false);
    const parsedFilterData = filterOptions.reduce((pv, cv) => {
      const data = onlyTruthy(selectedSubFilter[cv.value]);
      const keys = Object.keys(data);
      return keys.length === 0 ? { ...pv } : { ...pv, [cv.value]: keys };
    }, {});
    (async () => {
      try {
        const { data } = await customAxios.post(
          `courses/fetch${selectedTab === "My Courses" ? "/joined" : ""}`,
          {
            daoId: daoId,
            ...parsedFilterData,
            guildTag: undefined,
            sortBy: "creationDate",
            sortOrder,
            type: selectedTab === "Catalogue" ? "CATALOGUE" : undefined,
          }
        );
        setSelectedTab((prev) => {
          if (prev === selectedTab) {
            setCourseList(
              data.coursesList.filter((ele) =>
                ele.name.toLowerCase().includes(query.toLowerCase())
              )
            );
            setIsCourseDataFetched(true);
          }
          return prev;
        });
      } catch {
        setIsCourseDataFetched(true);
      }
    })();
  }, [
    JSON.stringify(selectedSubFilter),
    selectedTab,
    query,
    sortOrder,
    updater,
  ]);
  const createNewButtonHandler = () => {
    postMixpanel("track", "clicked_create_new_course_button");
    setCreateModalVisible(true);
  };

  const [type, setType] = useState(documentsDisable ? "Courses" : "Documents");

  return (
    <div
      style={{
        marginTop: isMobile ? "0" : 20,
        paddingLeft: isMobile ? "16px" : "0px",
      }}
    >
      {windowSize.width < MD_SCREEN_BREAKPOINT && <MobilePageTopNavbar />}
      <div
        className="bounties-state-filter__container discover-courses"
        style={{
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {documentsDisable ? null : (
          <span
            onClick={() => {
              setType("Documents");
            }}
            className={
              type === "Documents"
                ? "bounties-state-filter__single-filter selected"
                : "bounties-state-filter__single-filter"
            }
          >
            Documents
          </span>
        )}
        {coursesDisable ? null : (
          <span
            onClick={() => {
              setType("Courses");
            }}
            className={
              type === "Courses"
                ? "bounties-state-filter__single-filter selected"
                : "bounties-state-filter__single-filter"
            }
          >
            Courses
          </span>
        )}
      </div>
      {type === "Documents" && <DocumentFeed />}
      {type === "Courses" && (
        <>
          {/* {showOnboardingModal ? (
            <OnboardingModal
              step={"course-start"}
              showModal={showOnboardingModal}
              onClick={() => {
                updateWalkmeStatus({ courseCreate: true }, reload);
                postMixpanel("track", "started_walk_me_tour_for_create_course");

                setShowOnboardingModal(false);
                setStartTour(true);
              }}
              onClose={() => {
                setShowOnboardingModal(false);
              }}
            />
          ) : null} */}
          {/* {showEndingModal ? (
            <OnboardingModal
              step={"course-end"}
              showModal={showEndingModal}
              onClick={() => {
                postMixpanel(
                  "track",
                  "finished_walk_me_tour_for_create_course"
                );

                setShowEndingModal(false);
              }}
              onClose={() => {
                setShowEndingModal(false);
              }}
            />
          ) : null} */}

          {createModalVisible ? (
            <CreateCourseModal
              onSubmit={() => {
                setUpdater((prev) => !prev);
                setSelectedTab("All Courses");
                setQuery("");
              }}
              isModalVisible={createModalVisible}
              handleCancel={() => setCreateModalVisible(false)}
              // TODO: disable when API is integrated
              isOnboardingEnabled={true}
              showCourseVerificationAdminModal={
                showCourseVerificationAdminModal
              }
              setShowCourseVerificationAdminModal={
                setShowCourseVerificationAdminModal
              }
            />
          ) : null}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "12px",
            }}
          >
            <StyledInput
              placeholder="Search Courses"
              className="search-courses"
              allowClear
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              prefix={
                <SearchOutlined
                  style={{ color: "#94A3B8" }}
                  className="site-form-item-icon"
                />
              }
              style={{
                padding: "8px 18px",
                borderRadius: "12px",
                borderColor: "#f0f0f0",
                fontWeight: "600",
              }}
            />
            {isAdmin ? (
              <Button
                className="create-new-course"
                type="primary"
                style={{
                  padding: "18px 21px",
                  borderRadius: "12px",
                  background: "#3B82F6",
                  fontWeight: 600,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "12px",
                }}
                onClick={createNewButtonHandler}
              >
                Create New
              </Button>
            ) : null}
          </div>

          <div
            style={{
              display: "flex",
              gap: "8px",
              font: "normal 500 14px/20px Inter",
              flexWrap: "wrap",
              color: "#64748B",
              marginBottom: "12px",
            }}
          >
            {["All Courses", "My Courses", "Catalogue"].map((value) => (
              <Stylediv
                key={value}
                onClick={() => {
                  setSelectedTab(value);
                }}
                style={{
                  padding: "4px 12px",
                  border: `1px solid ${
                    value === selectedTab ? "#76A9FA" : "#E5E7EB"
                  }`,
                  borderRadius: "40px",
                  cursor: "pointer",
                  color: value === selectedTab ? "#3F83F8" : "",
                  background: value === selectedTab ? "#E1EFFE" : "",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                {value}
              </Stylediv>
            ))}
          </div>

          {isCourseDataFetched === true && !(courseList.length > 0) ? (
            <div
              style={{
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                rowGap: "1rem",
                alignItems: "center",
                background: "#FFFFFF",
                /* Shadow / Low */

                boxShadow: "0px 4px 24px rgba(180, 195, 205, 0.1)",
                borderRadius: "12px",
                padding: "2.5rem",
              }}
            >
              <img
                src={no_course}
                alt="emptyDao"
                style={{ objectFit: "contain", width: "200px" }}
              />
              <Col>
                <h1
                  level={4}
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    marginBottom: "4px",
                    textAlign: "center",
                  }}
                >
                  No Courses {query ? "matched your search" : "available yet"}
                </h1>
                <h1
                  level={5}
                  style={{
                    color: "#64748B",
                    fontWeight: "500",
                    fontSize: "14px",
                    marginTop: "0px",
                    textAlign: "center",
                  }}
                >
                  {query ? (
                    <span>
                      Please{" "}
                      {query ? (
                        "clear the search "
                      ) : (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#5E54ED",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            setSelectedSubFilter(initialSubFilterState);
                          }}
                        >
                          Remove all filters{" "}
                        </span>
                      )}
                      and try again
                    </span>
                  ) : (
                    " Be sure to check here again in a while"
                  )}{" "}
                </h1>
              </Col>
            </div>
          ) : null}

          {isCourseDataFetched === false && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                // height: "500px",
              }}
            >
              <Skeleton active />
            </div>
          )}

          {isCourseDataFetched &&
            courseList?.length > 0 &&
            courseList.map((course) => {
              return (
                <HoverCard
                  style={{
                    borderRadius: "16px",
                    marginBottom: "13px",
                  }}
                >
                  <CourseCard
                    daoId={daoId}
                    daoName={daoName}
                    course={course}
                    redirect={true}
                    canManageCourse={canManageCourse}
                  />
                </HoverCard>
              );
            })}
        </>
      )}
    </div>
  );
};

CoursePage.propTypes = {
  bountyList: PropTypes.array,
  bountyDetails: PropTypes.array,
  fetchBountyDetails: PropTypes.func,
};

export default CoursePage;

import { useState } from "react";
import customAxios from "../../api";
export enum GPT_REQUEST_TYPES {
  TALK = "talk",
  TALK_FREE = "talkFreely",
}
enum PROMPT_TYPES {
  SUMMARIZE = "SUMMARIZE",
  GRAMMER_CHECK = "GRAMMER_CHECK",
  REPHRASE_CONTENT = "REPHRASE_CONTENT",
  FRIENDLY_TONE = "FRIENDLY_TONE",
  CASUAL_TONE = "CASUAL_TONE",
  FORMAL_TONE = "FORMAL_TONE",
  MAKE_LONGER = "MAKE_LONGER",
  MAKE_SHORTER = "MAKE_SHORTER",
  OPTIMIZE_FOR_SE = "OPTIMIZE_FOR_SE",
  MAKE_COMMENT = "MAKE_COMMENT",
}
const useGPT = () => {
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const stopLoading = () => {
    setLoading(false);
  };
  const askGPT = async (
    actionPrompt: string,
    daoName: string,
    requestText: string = ""
  ) => {
    const res = await fetchRequest("gpt/ask", {
      requestType: GPT_REQUEST_TYPES.TALK,
      daoName,
      actionPrompt,
      requestText,
    });
    return res;
  };
  const critiqueProsAndCons = async (postId: string, daoId: string) => {
    const res = await fetchRequest(`gpt/prosandcons`, {
      daoId,
      postId,
    });
    return res;
  };
  const askGPTFree = async (
    promptType: string,
    requestText: string,
    daoName: string
  ) => {
    const res = await fetchRequest("gpt/ask", {
      requestType: GPT_REQUEST_TYPES.TALK_FREE,
      daoName,
      actionPrompt: "  ",
      promptType,
      requestText,
    });
    return res;
  };
  const translate = async (text?: string, target?: string) => {
    const res = await fetchRequest("gcp/translate", {
      text,
      target,
      source: "en",
    });
    return res;
  };
  const summarize = async (entity: string, postId: string, daoId: string) => {
    const res = await fetchRequest(
      `gpt/${entity === "POST" ? "summarizePost" : "summarizeThread"}`,
      {
        daoId,
        postId,
      }
    );
    return res;
  };
  const summarizeChannel = async (
    count: number,
    channelId: string,
    daoId: string
  ) => {
    const res = await fetchRequest(`gpt/summarizeChannel`, {
      daoId,
      channelId,
      count,
    });
    return res;
  };
  const comparePosts = async (
    firstPostId: string,
    secondPostId: string,
    daoName: string
  ) => {
    const res = await fetchRequest(`gpt/compare`, {
      firstPostId,
      secondPostId,
      daoName,
    });
    return res;
  };
  const fetchRequest = async (path: string, params: any) => {
    setLoading(true);
    setError(false);
    let localLoading = true;
    try {
      const res = await customAxios.post(path, params);
      setLoading((prev) => {
        if (prev) {
          setResult(res?.data);
        } else {
          localLoading = false;
        }
        return prev;
      });
      return res?.data;
    } catch (error) {
      setLoading((prev) => {
        if (prev) {
          setError(true);
        }
        return prev;
      });
    } finally {
      if (localLoading) setLoading(false);
    }
  };
  const clearResult = () => {
    setResult("");
    setError(false);
  };
  return {
    result,
    loading,
    stopLoading,
    askGPT,
    askGPTFree,
    summarize,
    clearResult,
    translate,
    summarizeChannel,
    error,
    comparePosts,
    critiqueProsAndCons,
  };
};

export default useGPT;

import minimatch from "minimatch";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import customAxios from "../../api";

export const DaoContext = React.createContext({
  daoDetails: {},
  reload: () => {},
  isAdmin: false,
  loading: false,
  librarySection: "",
  setLibrarySection: () => {},
  isCurrentDaoJoined: false,
  setShowJoinCommunityModal: () => {},
  showJoinCommunityModal: false,
  canView: false,
  isSpace: false,
  parentDaoDetails: {},
  daoId: "",
  setIsInviteModalOpen: (isOpen) => {},
  isInviteModalOpen: false,
  daoName: "",
  isConnectDiscourseModalOpen: false,
  setIsConnectDiscourseModalOpen: () => {},
});

export const useDaoContext = () => useContext(DaoContext);

const DaoContextProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [daoDetails, setDaoDetails] = useState({});
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [parentDaoDetails, setParentDaoDetails] = useState({});
  const [update, setUpdate] = useState(false);
  const [isCurrentDaoJoined, setIsCurrentDaoJoined] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canView, setCanView] = useState(null);
  const [librarySection, setLibrarySection] = useState("Documents");
  const [showJoinCommunityModal, setShowJoinCommunityModal] = useState(false);
  const [isSpace, setIsSpace] = useState(false);
  const [daoName, setDaoName] = useState("");
  const [isConnectDiscourseModalOpen, setIsConnectDiscourseModalOpen] =
    useState(false);

  const [isAdmin, setIsAdmin] = useState(false);
  const reload = () => {
    setUpdate((prev) => !prev);
  };
  const [daoId, setdaoId] = useState(false);
  useEffect(() => {
    const isSpacePath = minimatch(location.pathname, "/app/dao/*/*/spaces/**");
    if (isSpacePath) {
      setIsSpace(true);
      const pathname = location.pathname.split("/");
      const spaceIdFromUrl = pathname[7];
      const spaceNameFromUrl = pathname[6];

      if (isNaN(spaceIdFromUrl)) {
        navigate("/404", { replace: true });
        return;
      }

      setdaoId(spaceIdFromUrl);
      setDaoName(spaceNameFromUrl);
      return;
    }
    setIsSpace(false);
    setParentDaoDetails({});

    let daoIdFromUrl, daoNameFromUrl;

    const pathname = !daoId
      ? window.location.pathname.split("/")
      : location.pathname.split("/");

    if (pathname[1] === "app" && pathname[2] === "dao") {
      if (isNaN(pathname[4])) {
        daoIdFromUrl = pathname[3];
      } else {
        daoIdFromUrl = pathname[4];
        daoNameFromUrl = pathname[3];
      }

      if (isNaN(daoIdFromUrl)) {
        navigate("/404", { replace: true });
        return;
      }

      daoIdFromUrl && setdaoId(daoIdFromUrl);
      daoNameFromUrl && setDaoName(daoNameFromUrl);
    }
  }, [daoId, location, navigate]);

  useEffect(() => {
    setLibrarySection("Documents");
    if (daoId) {
      // setLoading(true);
      customAxios
        .get(`dao/view/permission?daoId=${daoId}`)
        .then(({ data: { canView } }) => {
          setCanView(canView);

          if (canView) {
            customAxios
              .post(`settings/canview`, { daoId: daoId })
              .then((res) => {
                setIsAdmin(!!res.data.canView);
              })
              .finally(() => {
                setLoading(false);
              });
            customAxios
              .get(`/dao/fetch?daoId=${daoId}`)
              .then((res) => {
                setDaoDetails(res.data.daoDetails[0]);
                if (isSpace) {
                  return customAxios.get(
                    `/dao/fetch?daoId=${res.data.daoDetails?.[0]?.parentDaoId}`
                  );
                }
              })
              .then((res) => {
                if (!res) return;
                setParentDaoDetails(res.data.daoDetails[0]);
              })
              .catch((err) => {
                err?.response?.status === 404 &&
                  navigate("/404", { replace: true });
                console.error(err);
              })
              .finally(() => {
                setLoading(false);
              });
            customAxios
              .get("dao/daojoinedbyuser")
              .then((res) => {
                if (res.data.daoList.find((dao) => dao.daoId === daoId)) {
                  setIsCurrentDaoJoined(true);
                } else {
                  setIsCurrentDaoJoined(false);
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        });
    }
  }, [update, daoId]);

  return (
    <DaoContext.Provider
      value={{
        daoDetails,
        reload,
        update,
        isCurrentDaoJoined,
        canView,
        loading,
        isAdmin,
        librarySection,
        showJoinCommunityModal,
        setShowJoinCommunityModal,
        setLibrarySection,
        isSpace,
        parentDaoDetails,
        daoId,
        daoName,
        isInviteModalOpen,
        setIsInviteModalOpen,
        isConnectDiscourseModalOpen,
        setIsConnectDiscourseModalOpen,
      }}
    >
      {children}
    </DaoContext.Provider>
  );
};

export default DaoContextProvider;

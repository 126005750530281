import React, { useEffect, useState } from "react";
import { Button, Col, Input, Tag, Spin, Tooltip } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ArrowLongRightIcon,
  ChartBarIcon,
  ChartBarSquareIcon,
  TagIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Footer, Modal } from "../../../styles/ModalStyles";
import IconWrapper from "../../IconWrapper";
import CreatePoll from "../../CreatePoll";
import customAxios from "../../../api";
import GenericTagSelect from "../../Common/GenericTagSelect";
import { ReactComponent as ChecklistIcon } from "./../../../assets/iconSvg/checklist.svg";
import { ReactComponent as StackIcon } from "./../../../images/rectangle-stack-icon.svg";
import EditableChecklistItem from "./EditableChecklistItem";
import { DocumentIcon } from "@heroicons/react/24/solid";
import styles from "../../Course/course.module.css";
import { BountyFilesAttachment } from "../../CreateBountyModal";
import { checkFileSize, postMixpanel } from "../../../utils";
import LinkEntities from "../../Common/LinkEntities";
import { MD_SCREEN_BREAKPOINT } from "../../../constants";
import useWindowSize from "../../../hooks/useWindowSize";
import QuillEditor from "../../GPT/editor/PostQuillEditor";
import markdown from "@wcj/markdown-to-html";
import { defaultConditionDetails } from "../../Gating/Gating";
import { poll } from "ethers/lib/utils";
import moment from "moment";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import useGating from "../../../hooks/useGating";
import { HoverCard } from "../../Search/SearchModal";
import { getTrunckedString } from "../../Proposal/ProposalCard";
import {
  MINIMUM_POST_CHAR_LIMIT,
  MINIMUM_TITLE_CHAR_LIMIT,
} from "../../../lib/constants/posts";
import { confirmOperation } from "../../../utils/common";
const DURATION_OBJECTS = [
  { label: "6 hours", value: 6, unit: "hours" },
  { label: "12 hours", value: 12, unit: "hours" },

  { label: "1 day", value: 1, unit: "day" },
  { label: "2 days", value: 2, unit: "day" },
  { label: "3 days", value: 3, unit: "day" },
];
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "#88c6ff",
      marginLeft: "12px",
    }}
    spin
  />
);

const StyledTitle = styled.div`
  color: #64748b;
  padding: 2px 12px;
  height: 24px;
  background: #f4f2f2;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  margin-left: 9px;
  ::before {
    content: "In ";
  }
`;

const StyledInput = styled(Input)`
  padding: 0px 20px;
  margin: 8px 0px;
  font-size: 20px;
  &::placeholder {
    font-weight: 600;
    color: #94a3b8;
  }
`;
const StyledAttachment = styled.div`
  padding: 6px 0px;
  .attachment-title {
    font-weight: 600;
    font-size: 12px;
  }
`;

const PostFilesAttachment = ({ files, onDelete, showHeader = true }) => {
  return (
    <StyledAttachment>
      {showHeader ? <p className="attachment-title">ATTACHMENTS</p> : null}
      {files.map((file, idx) => (
        <Tag
          onClick={(e) => {
            e.stopPropagation();
            window.open(file.publicUrl);
          }}
          style={{
            border: "1px solid #E2E8F0",
            background: "transparent",
            padding: "0.25rem 1rem",
            borderRadius: "1rem",
            display: "inline-flex",
            gap: "4px",
            alignItems: "center",

          }}
          closeIcon={<i class="fas fa-times" style={{ fontSize: "12px" }}></i>}
          closable
          onClose={(e) => {
            e.stopPropagation();
            onDelete(file.publicUrl);
          }}
          key={idx}
        >
          <IconWrapper width="12px" style={{ marginRight: "5px" }}>
            <DocumentIcon />
          </IconWrapper>
          {getTrunckedString(file.name, 15)}
          {/* <span
            style={{
              margin: "0px 8px 0px 4px",
              fontSize: "12px",
              color: "#64748B",
            }}
          >
            {Number(file?.size || 1 / 1024 / 1024).toFixed(1) > 0
              ? `${Number(file?.size || 1 / 1024 / 1024).toFixed(1)} MB`
              : `${Number(file?.size || 1 / 1024).toFixed(1)} KB`}
          </span> */}
        </Tag>
      ))}
    </StyledAttachment>
  );
};
function CreatePostModal({
  isModalVisible,
  setIsModalVisible,
  setUpdater = null,
  channelDetails,
  canEdit,
  onSuccess = null,
  description: gptDescription,
  title: gptTitle,
  postDetails
}) {
  const [title, setTitle] = useState("");

  const [description, setDescription] = useState(gptDescription);
  const [tagsList, setTagsList] = useState([]);
  const { channelId } = useParams();
  const { daoDetails, daoId } = useDaoContext();
  const [attachments, setAttachments] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [pollVisibility, setPollVisibility] = React.useState(false);
  const [files, setFiles] = useState([]);
  const inputRef = React.useRef(null);
  const [checklist, setChecklist] = useState([{ title: "" }]);
  const [options, setOption] = React.useState(["Yes", "No", "Abstain"]);
  const [questionInput, setQuestionInput] = React.useState("");
  const [focusIndex, setFocusIndex] = useState(() => 0);
  const [showChecklistUI, setShowChecklistUI] = useState(false);
  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const gatingState = useGating();
  const [pollDetails, setPollDetails] = useState({
    pollType: "single-choice",
    expireAfter: "12 hours",
  });
  const [linkedItems, setLinkedItems] = useState([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);

  const isCloseConfirmationModalShown =
    description ||
    title ||
    tagsList.find((tag) => tag.checked) ||
    files.length !== 0 ||
    checklist?.[0]?.title ||
    questionInput ||
    linkedItems?.length !== 0;
  const populateChecklist = (items) => {
    setShowChecklistUI(true);
    setChecklist(items.map((item) => ({ title: item })));
  };
  const populatePoll = (values, question) => {
    setPollVisibility(true);
    setOption(values);
    setQuestionInput(question);
  };
  const getAssigneeList = (daoId) => {
    customAxios
      .get(`/users/daousers?daoId=${daoId}`)
      .then((res) => {
        setAssigneeList(res.data.daoUser);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const getTagsList = (daoId) => {
    customAxios
      .get(`tags?daoId=${daoId}`)
      .then((res) => {
        setTagsList(
          res.data.tagsList.map((ele, idx) => ({ name: ele, id: idx, checked: postDetails[0]?.postTags.includes(ele) }))
        );
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };

  const fetchSavedDrafts = (daoId) => {
    customAxios
      .get(`post/fetchdraft?daoId=${daoId}`)
      .then((res) => {
        let DRAFT = res.data.postDraft;
        if (DRAFT.postName) setTitle(DRAFT.postName);
        if (DRAFT.description) setDescription(DRAFT.description);
        if (DRAFT.attachments) {
          // setFiles(DRAFT.attachments);
          createFilesFromUrls(DRAFT.attachments);
        }
        if (DRAFT.newTags)
          setTagsList(
            DRAFT.newTags.map((ele, idx) => ({
              name: ele,
              id: idx,
              checked: true,
            }))
          );
        if (DRAFT.checklist?.length > 0) {
          setShowChecklistUI(true);
          setChecklist([]);
          DRAFT.checklist.forEach(async (checklist) => {
            await createChecklistFilesFromUrls(checklist);
          });
        }
        if (DRAFT.pollsQuestion) {
          setPollVisibility(true);
          setQuestionInput(DRAFT.pollsQuestion);
          setOption({ values: DRAFT.polls });
        }
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
      });
  };
  const createChecklistFilesFromUrls = async (checklistParam) => {
    if (checklistParam.attachments?.length > 0) {
      const currFiles = files.slice();
      await checklistParam.attachments.forEach(async (url) => {
        let response = await fetch(url);
        let data = await response.blob();
        let fileName = url?.split("/");
        // console.log("files data from url", response, url, fileName);
        fileName = fileName[fileName.length - 1];
        let metadata = {
          type: data.type,
        };
        let file = new File([data], fileName, metadata);
        file.publicUrl = url;
        currFiles.push(file);
        return;
      });
      checklistParam.attachments = currFiles;
      setChecklist([checklistParam, ...checklist]);
      setFocusIndex(checklist.length - 1);
    }
  };
  const createFilesFromUrls = async (urls) => {
    setIsFileUploading(true);
    if (urls.length > 0) {
      const currFiles = files.slice();
      let localFiles = await urls.forEach(async (url) => {
        let response = await fetch(url);
        let data = await response.blob();
        let fileName = url?.split("/");
        console.log("files data from url", response, url, fileName);
        fileName = fileName[fileName.length - 1];
        let metadata = {
          type: data.type,
        };
        let file = new File([data], fileName, metadata);
        file.publicUrl = url;
        // currFiles.push(file);
        setFiles([...currFiles.slice(), file]);
        return;
      });
      setIsFileUploading(false);
    }
  };
  useEffect(() => {
    if (postDetails?.length > 0) {
      setTitle(postDetails[0]?.postName);
      setDescription(postDetails[0]?.postDescription);
      if (postDetails[0]?.checklist && postDetails[0]?.checklist.length > 0) {
        const editableChecklist = postDetails[0]?.checklist.map((ele) =>
          !ele.assigneeId
            ? ele
            : {
              ...ele,
              assigneeId: {
                id: ele.assigneeId,
                profilePicture: ele.assigneeProfilePicture,
                userName: ele.assigneeUserName,
              },
            }
        );
        setChecklist(editableChecklist);
        setShowChecklistUI(true);
      }
      if (postDetails[0]?.postPollQuestion) {
        setQuestionInput(postDetails[0]?.postPollQuestion);
      }
      if (
        postDetails[0]?.postPollAttachments &&
        postDetails[0]?.postPollAttachments.length > 0
      ) {
        setAttachments(postDetails[0]?.postPollAttachments);
      }
    }
  }, [postDetails]);
  useEffect(() => {
    if (!daoId) return;
    getTagsList(daoId);
    getAssigneeList(daoId);
    // fetchSavedDrafts(daoId);
    if (gptDescription) {
      setDescription(markdown(gptDescription));
    }
    if (gptTitle) {
      setTitle(gptTitle);
    }
  }, []);
  const uploadFiles = (file, insertPublicUrl) => {
    const formData = new FormData();
    formData.append("files", file);
    customAxios
      .post("/post/uploadattach", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        file["publicUrl"] = res?.data?.publicUrl[0];
        insertPublicUrl(file);
        setIsFileUploading(false);
      })
      .catch((err) => {
        console.log(err);
        console.log("error");
        setIsFileUploading(false);
      });
  };

  const addNewChecklistItem = (index) => {

    setChecklist(prev => {
      prev.splice(index + 1, 0, { title: "" })

      return [...prev];
    });
    setFocusIndex(index + 1);
  };
  const removeChecklist = () => {
    setShowChecklistUI(false);
    setChecklist([{}]);
  };
  const savePostAsDraft = (state) => {
    setLoading(true);
    customAxios
      .post("post/savedraft", { daoId, postDraft: state })
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          postMixpanel("track", "saved_post_in_draft", {
            dao_id: daoId,
            post_title: state?.postName || "NO TITLE ADDED",
          });
          if (setUpdater) setUpdater((prev) => !prev);
          if (onSuccess) onSuccess();
          setIsModalVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleCreatePost = async (isDraft) => {
    let flag = 1;
    if (!title) {
      return toast.error("Please enter the title for the post");
    } else if (showChecklistUI && checklist.length) {
      for (let i = 0; i < checklist.length; i++) {
        const ele = checklist[i];
        if (!ele.title) {
          setFocusIndex(i);
          flag = 0;
          return toast.error("Please enter the title for checklist item");
        }
      }
    } else if (title.length <= MINIMUM_TITLE_CHAR_LIMIT) {
      toast.error(
        `Title is too short. Please make sure it is at least ${MINIMUM_TITLE_CHAR_LIMIT} characters.`
      );
      flag = 0;
    } else if (description.length <= MINIMUM_POST_CHAR_LIMIT) {
      toast.error(
        `Post is too short. Please make sure it is at least ${MINIMUM_POST_CHAR_LIMIT} characters.`
      );
      flag = 0;
    }
    if (!description) {
      return toast.error("Please enter post description");

    }

    if (flag) {
      const formData = new FormData();
      if (postDetails) {
        formData.append("postId", postDetails[0]?.postId);
      }

      formData.append("daoId", daoId);
      formData.append("channelId", channelId);
      formData.append("description", description);
      formData.append("postName", title);
      let tags = tagsList
        .filter((ele) => ele.checked && !ele.isNew)
        .map((ele) => ele.name);
      formData.append("tags", JSON.stringify(tags))
      let newTags = tagsList
        .filter((ele) => ele.checked && ele.isNew)
        .map((ele) => ele.name)
        ;
      if (newTags.length > 0)
        formData.append("newTags", JSON.stringify(newTags))
      if (deletedAttachments.length > 0)
        formData.append("deletedAttachments", JSON.stringify(deletedAttachments));
      if (pollVisibility) {
        if (!questionInput) {
          return toast.error("Poll question cannot be empty");
        } else {
          formData.append("pollsQuestion", questionInput);
        }
        if (options.filter((option) => !option).length)
          return toast.error("Poll options cannot be empty");
        if (options.length !== new Set(options).size)
          return toast.error("Poll options must be unique");
        else {
          formData.append(
            "polls", JSON.stringify(options))
        }
        gatingState.createFormData(formData, "pollGatingCondition");

        formData.append(
          "pollType", pollDetails.pollType);
        const selectedDurationObj = DURATION_OBJECTS.find(
          (obj) => obj.label === pollDetails.expireAfter
        );
        formData.append(
          "pollExpirationDate", moment()
            .add(selectedDurationObj.value, selectedDurationObj.unit)
            .format("YYYY-MM-DDTHH:mm:ss.sssZ"));
      }
      if (mentionedUsers.length > 0) formData.append("userTagged", JSON.stringify(mentionedUsers));
      if (showChecklistUI && checklist.length) {
        formData.append(
          "checklist", JSON.stringify(checklist.map((ele, idx) => ({
            ...ele,
            order: idx,
            attachments: ele?.attachments?.length
              ? ele.attachments?.map((ele) => ele.publicUrl)
              : [],
            assigneeId: ele.assigneeId ? ele.assigneeId.id : null,
          }))));
      }
      if (files.length) {
        formData.append(
          "attachments", JSON.stringify(files.map((ele) => ele.publicUrl)));
      }
      if (linkedItems.length) {
        formData.append(
          "linkedEntities", JSON.stringify(linkedItems.map((ele) => ({
            linkedType: ele.type,
            linkedId: ele.id,
          }))));

      }
      return isDraft ? savePostAsDraft(formData) : handleSubmitPost(formData);
    }
  };
  const handleSubmitPost = async (state) => {
    setLoading(true);
    let promise = postDetails ? customAxios.put("post/update", state) : customAxios.post("post/create", state)
    promise
      .then((res) => {
        setLoading(false);

        if (res.data.success) {
          postMixpanel("track", postDetails ? "updated_post" : "created_post", {
            dao_id: daoId,
            post_title: state.postName,
            post_id: postDetails ? postDetails[0]?.postId : undefined,
          });
          if (setUpdater) setUpdater((prev) => !prev);
          if (onSuccess) onSuccess();
          setIsModalVisible(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        console.log("error");
      });
  };

  const confirm = () => {
    Modal.confirm({
      title: (
        <span style={{ color: "#000000", fontWeight: "600", fontSize: "16px" }}>
          Confirm
        </span>
      ),
      icon: null,
      content: (
        <span style={{ color: "#64748B", fontWeight: "500", fontSize: "14px" }}>
          You will lose the progress if you close this pop-up
        </span>
      ),
      okText: "Continue editing",
      cancelText: "Discard changes",
      closable: true,
      onOk: () => {
        setIsModalVisible(true);
      },
      onCancel: () => setIsModalVisible(false),
      bodyStyle: {
        paddingTop: "16px",
        paddingBottom: "12px",
        paddingLeft: "24px",
        paddingRight: "24px",
        borderRadius: "16px",
      },
      okButtonProps: {
        style: {
          background: "#3F83F8",
          fontWeight: "500",
          borderRadius: "12px",
        },
      },
      modalRender: (children) => (
        <div className={styles.antModalContainer}>{children}</div>
      ),
      cancelButtonProps: {
        style: {
          color: "#64748B",
          fontWeight: "500",
          borderRadius: "12px",
          border: "1px solid #64748B",
          marginTop: "5px",
        },
      },
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setPollVisibility(false);
  };

  const tagDeleteHandler = (name) => {
    customAxios
      .delete(`tags/delete?daoId=${daoId}&tagName=${name}`)
      .then((res) => {
        toast.success("tag deleted successfully");
        getTagsList(daoId);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;

  return (
    <Modal
      isMobile={isMobile}
      destroyOnClose
      width={"696px"}
      title={<StyledTitle>#{channelDetails?.daoChannelName}</StyledTitle>}
      visible={isModalVisible}
      centered={true}
      onCancel={() =>
        isCloseConfirmationModalShown
          ? confirmOperation({
            cancelText: "Discard Changes",
            confirmText: "Continue Editing",
            message: " You will lose the progress if you close this pop-up?",
            title: "Confirm",
            onCancel: () => handleCancel(),
          })
          : handleCancel()
      }
      footer={[
        <Footer key="modal-footer ">
          <div style={{ alignItems: "center" }}>
            <div
              className="footer-items"
              style={{
                display: "flex",
                columnGap: "12px",
                flexDirection: "row ",
              }}
            >
              <Button
                style={{ marginLeft: "0px" }}
                className="borderless-button"
                onClick={() => inputRef.current.click()}
              >
                <IconWrapper width="20px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M12.6425 5.83339L7.15412 11.3217C6.99494 11.4755 6.86797 11.6594 6.78062 11.8627C6.69327 12.0661 6.64729 12.2848 6.64537 12.5061C6.64345 12.7274 6.68562 12.9468 6.76942 13.1516C6.85322 13.3565 6.97698 13.5426 7.13346 13.699C7.28995 13.8555 7.47604 13.9793 7.68087 14.0631C7.88569 14.1469 8.10516 14.1891 8.32646 14.1871C8.54776 14.1852 8.76646 14.1392 8.9698 14.0519C9.17314 13.9645 9.35704 13.8376 9.51079 13.6784L14.8558 8.19006C15.463 7.56138 15.799 6.71937 15.7914 5.84538C15.7838 4.9714 15.4332 4.13535 14.8152 3.51733C14.1972 2.8993 13.3611 2.54874 12.4871 2.54114C11.6131 2.53355 10.7711 2.86953 10.1425 3.47672L4.79662 8.96422C3.85886 9.90198 3.33203 11.1739 3.33203 12.5001C3.33203 13.8263 3.85886 15.0981 4.79662 16.0359C5.73438 16.9737 7.00626 17.5005 8.33246 17.5005C9.65865 17.5005 10.9305 16.9737 11.8683 16.0359L17.0825 10.8334"
                      stroke="#6B7280"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </IconWrapper>
              </Button>
              <input
                ref={inputRef}
                onClick={() => {
                  inputRef.current.value = null;
                }}
                style={{ display: "none" }}
                hidden
                type="file"
                name="Attachment"
                multiple
                onChange={(e) => {
                  if (e.target.files.length) {
                    const newFile = e.target.files[0];
                    checkFileSize(newFile.size);
                    setIsFileUploading(true);
                    const insertPublicUrl = (file) => {
                      const currFiles = files.slice();
                      currFiles.push(file);
                      setFiles(currFiles.slice());
                    };
                    uploadFiles(newFile, insertPublicUrl);
                  }
                }}
              />

              <Button
                style={{ marginLeft: "0px" }}
                className="borderless-button"
                disabled={showChecklistUI === true}
                onClick={() => {
                  setChecklist([{}]);
                  setShowChecklistUI((prev) => !prev);
                }}
              >
                <IconWrapper width={"18px"}>
                  <ChecklistIcon />
                </IconWrapper>
              </Button>

              {!postDetails && <Button
                style={{ marginLeft: "0px", color: "" }}
                className="borderless-button"
                title="Poll"
                onClick={() => {
                  setPollVisibility(true);
                }}
                disabled={pollVisibility === true}
              >
                <IconWrapper width="20px" style={{ color: "red" }}>
                  <ChartBarSquareIcon
                    style={{
                      width: "20px",
                      color: pollVisibility === true ? "#94A3B8" : "#6A7280",
                    }}
                    strokeWidth="2px"
                    width="20px"
                  />
                </IconWrapper>
              </Button>}

              {!postDetails && <Tooltip title="Link the bounties, proposals or any entity that led to this discussion">
                <Button
                  className="borderless-button"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "0PX",
                  }}
                  onClick={() => {
                    setLinkModalVisible(true);
                  }}
                >
                  <IconWrapper>
                    <StackIcon />{" "}
                  </IconWrapper>{" "}
                  <span style={{ color: "#64748B", fontSize: "12px" }}>
                    {linkedItems.length ? `(${linkedItems.length})` : null}
                  </span>
                </Button>
              </Tooltip>}
            </div>
          </div>
          <Button
            type="primary"
            className="rounded-corner submit-post-button"
            disabled={loading}
            onClick={() => handleCreatePost(false)}
          >
            Post
            <IconWrapper>
              <ArrowLongRightIcon />
            </IconWrapper>
          </Button>
        </Footer>,
      ]}
    >
      <StyledInput
        style={{
          fontSize: "21px",
          fontWeight: "600",
        }}
        placeholder="Add the title of your post here"
        size="large"
        bordered={false}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <LinkEntities
        isModalVisible={linkModalVisible}
        handleClose={() => {
          setLinkModalVisible(false);
        }}
        linkedItems={linkedItems}
        setLinkedItems={setLinkedItems}
      ></LinkEntities>
      <GenericTagSelect
        key={1}
        showRemoveTagIcon={true}
        onTagDelete={(name) => tagDeleteHandler(name)}
        skillsList={tagsList}
        setSkillsList={setTagsList}
        addButtonText={"Add tag"}
        addNewButtonText={"Add new tag"}
        searchText="Search tags..."
        AddIcon={() => <TagIcon height="16px" width="16px" />}
        canEdit={canEdit}
      />
      <QuillEditor
        height="300px"
        style={{ margin: "0px 14px" }}
        isBordered
        populateChecklist={populateChecklist}
        populatePoll={populatePoll}
        value={description}
        className="post-description"
        onChange={(e) => setDescription(e)}
        placeholder="Add details of your post here"
        setMentionedUsers={setMentionedUsers}
      />

      {showChecklistUI ? (
        <Col
          xs={24}
          // onBlur={() => setFocusIndex(-1)}
          style={{
            background: "#FFFFFF",
            /* Colors/divider/default */
            margin: "16px 20px",

            padding: "16px",
            border: "1px solid #dddddd",
            borderRadius: "12px",
            poition: "relative",
          }}
        ><div className="flex justify-between items-center text-base font-medium mb-4">
            Checklist
            <HoverCard
              style={{
                // border: "1px solid #E2E8F0",
                borderRadius: "8px",
                height: "32px",
                background: "#F3F4F6",
                width: "32px",
                flexGrow: 0,
                flexShrink: 0,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                font: "Inter",
                fontWeight: "400",
                fontSize: "24px",
                color: "#64748B",
              }}
              onClick={() => {
                removeChecklist();
              }}
            >
              <IconWrapper>
                <TrashIcon
                  style={{ fontSize: "16px" }}
                  width="16px"
                  strokeWidth="2px"
                />{" "}
              </IconWrapper>
            </HoverCard>

          </div>


          <div className="space-y-2 ">
            {checklist.map((ele, idx) => (
              <div key={idx} className="flex gap-2">
                <EditableChecklistItem
                  ele={ele}
                  BountyFilesAttachment={PostFilesAttachment}
                  idx={idx}
                  checklist={checklist}
                  setChecklist={setChecklist}
                  focusIndex={focusIndex}
                  setFocusIndex={setFocusIndex}
                  uploadFiles={uploadFiles}
                  addNewChecklistItem={addNewChecklistItem}
                  assigneeList={assigneeList}
                />
                <HoverCard
                  style={{
                    border: "1px solid #E2E8F0",
                    borderRadius: "8px",
                    height: "40px",
                    width: "40px",
                    flexGrow: 0,
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    font: "Inter",
                    fontWeight: "400",
                    fontSize: "24px",
                    color: "#64748B",
                  }}
                  onClick={() => {
                    addNewChecklistItem(idx)
                  }}
                >
                  +
                </HoverCard>
                {checklist.length > 1 && <HoverCard
                  style={{
                    border: "1px solid #E2E8F0",
                    borderRadius: "8px",
                    height: "40px",
                    width: "40px",
                    flexGrow: 0,
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    font: "Inter",
                    fontWeight: "400",
                    fontSize: "24px",
                    color: "#64748B",
                  }}
                  onClick={() => {
                    const currList = checklist.slice();
                    currList.splice(idx, 1);
                    setChecklist(currList.slice());
                  }}
                >
                  -
                </HoverCard>}</div>
            ))}

          </div>
        </Col>
      ) : null}
      {pollVisibility && (
        <CreatePoll
          gatingState={gatingState}
          pollDetails={pollDetails}
          setPollDetails={setPollDetails}
          onRemovePoll={() => {
            setPollVisibility(false);
            setQuestionInput("");
          }}
          setOption={setOption}
          options={options}
          questionInput={questionInput}
          setQuestionInput={setQuestionInput}
          DURATION_OBJECTS={DURATION_OBJECTS}
        />
      )}
      <div style={{ padding: "0 10px", margin: "8px 0" }}>
        {attachments?.length > 0 &&
          attachments.map((attach) => (
            <div
              style={{
                border: "1px solid rgb(226, 232, 240)",
                padding: "0.25rem 0.8rem",
                background: "transparent",
                borderRadius: "52px",
                fontSize: "12px",
                marginRight: "10px",
                marginBottom: "8px",
                display: "inline-block",
              }}
            >
              {getTrunckedString(attach.split("/").pop(), 20)}
              <CloseOutlined
                style={{
                  marginLeft: "3px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDeletedAttachments((prev) => [...prev, attach]);
                  setAttachments((prev) =>
                    prev.filter((value) => !(value === attach))
                  );
                }}
              />
            </div>
          ))}
      </div>

      {isFileUploading === true && (
        <div style={{ display: "flex" }}>
          <Spin
            indicator={antIcon}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginLeft: "10px",
              marginBottom: "10px",
              marginRight: "10px",
            }}
          />
          Uploading...
        </div>
      )}
      {files?.length > 0 && (
        <BountyFilesAttachment files={files} setFiles={setFiles} />
      )}
    </Modal>
  );
}

export default CreatePostModal;

import { Checkbox } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { Card, InputField } from "./Common";

const Submission = ({ state, setState }) => {
  return (
    <div
      style={{
        padding: "20px",
        font: "normal 500 12px/16px Inter",
        color: "#64748B",
      }}
    >
      <Checkbox
        checked={state.autoValidation}
        onChange={(e) => {
          setState((prev) => ({ ...prev, autoValidation: e.target.checked }));
        }}
        style={{ marginRight: "4px", marginBottom: "16px" }}
      />{" "}
      Autovalidate (only text)
      {state.autoValidation && (
        <Card>
          <InputField
            label="Correct answer"
            placeholder="Leave empty for accepting any value"
            value={state.answer}
            onChange={(e) => {
              setState((prev) => ({ ...prev, answer: e.target.value }));
            }}
          />
        </Card>
      )}
    </div>
  );
};
export default Submission;

export const submissionValidation = (state, res) => {
  if (state.autoValidation) {
    res.autoValidation = state.autoValidation;
    if (!state.answer) {
      toast.error("Please provide the correct answer");
      throw new Error();
    } else {
      res.link = state.answer;
    }
  }
};
export const submissionInitialState = (bountyDetails) => {
  return {
    autoValidation: bountyDetails.autoValidation,
    answer: bountyDetails.link,
  };
};

import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { Modal } from "../../../styles/ModalStyles";
import deleteSadGif from "./../../../assets/images/settings/pikachu-sad.gif";
function DeleteModal(props) {
  const { isMobile } = useWindowSize();
  const {
    isModalVisible,
    buttonLabel,
    onClose,
    media = (
      <img
        src={deleteSadGif}
        alt="delete"
        style={{ width: "100%", objectFit: "cover", borderRadius: "12px" }}
      />
    ),
    title = "",
    note = "",
    inputList = [],
    onSubmit,
    checkSubmitButtonDisabled,
  } = props;
  const [modalData, setModalData] = useState(null);
  return (
    <Modal
      width={"450px"}
      isMobile={isMobile}
      visible={isModalVisible}
      onCancel={onClose}
      title={<h3 style={{ paddingLeft: "4PX" }}>{title}</h3>}
      footer={
        <Row style={{ justifyContent: "flex-end" }}>
          <Button onClick={() => onClose()} style={{ borderRadius: "12PX" }}>
            Cancel
          </Button>
          <Button
            onClick={() => onSubmit(modalData)}
            disabled={checkSubmitButtonDisabled(modalData)}
            style={{
              background: "rgba(240, 82, 82, 1)",
              opacity: checkSubmitButtonDisabled(modalData) ? "50%" : "100%",
              borderRadius: "12px",
              width: "fit-content",
              color: "white",
            }}
          >
            {buttonLabel}
          </Button>
        </Row>
      }
    >
      <Row style={{ width: "100%", rowGap: "20px", marginBottom: "20px" }}>
        <Col xs={24} style={{ padding: "0px 20px" }}>
          {media}
        </Col>
        <Col xs={24}>
          <h4
            style={{
              padding: "0px 20px",
              width: "100%",
              background: "rgba(253, 232, 232, 1)",
              color: "rgba(119, 29, 29, 1)",
              marginBottom: "0px",
              fontWeight: "400",
            }}
          >
            {note}
          </h4>
        </Col>
        {inputList.map((ele) => (
          <Col
            xs={24}
            style={{
              padding: "0px 20px",
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
            }}
          >
            <h4
              style={{
                marginBottom: "0px",
                color: "rgba(100, 116, 139, 1)",
                fontWeight: "400",
              }}
            >
              {ele.label}
            </h4>
            <Input
              style={{ borderRadius: "8px" }}
              value={modalData?.[ele.key]}
              onChange={(e) => {
                setModalData((prev) => {
                  return { ...prev, [ele.key]: e.target.value };
                });
              }}
              {...ele.otherProps}
            />
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default DeleteModal;

import { useEffect, useState } from "react";
import customAxios from "../api";

const usePostGPT = (daoId?: string, channelId?: string) => {
  const [posts, setPosts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [firstPostId, setFirstPostId] = useState<string | undefined>(undefined);
  const [secondPostId, setSecondPostId] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    setFirstPostId(undefined);
    setSecondPostId(undefined);

    setLoading(true);
    customAxios
      .post("post/fetch", {
        daoId,
        channelId,
        offset: 0,
        limit: 10000,
      })
      .then((res) => {
        setPosts(res.data.postData);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [daoId, channelId]);
  return {
    firstPostId,
    secondPostId,
    setFirstPostId,
    setSecondPostId,
    posts,
    loading,
  };
};

export default usePostGPT;

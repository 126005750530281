import React, { Dispatch, SetStateAction } from "react";
import { Modal } from "../../styles/ModalStyles";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ProfileCardPopup from "../Common/ProfileCard";

type Props = {
  userAlreadyExist: {
    id: string;
    userName: string;
    profileName: string;
    twitterUserDetails: {
      username: string;
      name: string;
      profilePicture: string;
    };
  };
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onOk?: () => void;
};

const TwitterAlreadyConnectedModal = ({
  isOpen,
  setIsOpen,
  userAlreadyExist,
  onOk,
}: Props) => {
  if (!userAlreadyExist) return null;

  const { twitterUserDetails } = userAlreadyExist;

  return (
    <Modal
      footer={null}
      // @ts-expect-error
      height="auto"
      width="350px"
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      <div className="flex flex-col p-5">
        <div className="flex gap-2 items-center">
          {twitterUserDetails.profilePicture && (
            <img
              src={twitterUserDetails.profilePicture}
              alt={twitterUserDetails.name}
              className="w-12 h-12 object-cover rounded-full"
            />
          )}
          <div className="flex flex-col gap-0 items-center">
            <p className="m-0 font-semibold text-base">
              {twitterUserDetails.name}
            </p>
            <p className="m-0 text-gray-500">@{twitterUserDetails.username}</p>
          </div>
        </div>
        <div className="flex text-blue-500 bg-blue-50 rounded-lg py-2 px-3 mt-3 items-start gap-2">
          <InformationCircleIcon
            className="w-4 flex-shrink-0"
            strokeWidth={2}
          />
          <p className="m-0 text-xs">
            Your twitter account is already linked to an account{" "}
            <div className="inline-block underline">
              <ProfileCardPopup userId={userAlreadyExist.id}>
                ‘{userAlreadyExist.userName}’
              </ProfileCardPopup>
            </div>{" "}
            on the platform. Try using a different twitter account.
          </p>
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-600 active:bg-blue-700 cursor-pointer border-none py-2 text-white rounded-lg text-xs mt-3"
          onClick={() => (onOk ? onOk() : setIsOpen(false))}
        >
          Okay, go back
        </button>
      </div>
    </Modal>
  );
};

export default TwitterAlreadyConnectedModal;

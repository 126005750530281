import customAxios from "../api";
import {
  BountyCardType,
  BountyDetailsFromApiType,
  CommunityFilterType,
  CommunityType,
  CourseCardType,
  CourseDetailsFromApiType,
  EntityValueType,
  PostCardType,
  PostDetailsFromApiType,
  ProposalCardType,
  ProposalDetailsFromApiType,
} from "../types/DiscoverPage";

type CommunityResApiType = {
  backgroundImage: string;
  bounty: number;
  communityType: string;
  contributors: number;
  communityTypes: string[];
  createdAt: string;
  description: string;
  discordUrl: string;
  id: string;
  isClaimed: boolean;
  name: string;
  profilePicture: string;
  twitterHandle: string;
};

export const fetchCommunities = async (
  limit: number | undefined,
  communityType: CommunityFilterType,
  searchQuery: string
) => {
  const res = await customAxios.get(`/dao/discover`, {
    params: {
      limit,
      communityType: communityType ? communityType : undefined,
      searchContent: searchQuery,
    },
  });
  const communities: CommunityResApiType[] = res.data.res;

  const processedCommunities: CommunityType[] = communities.map(
    (community) => ({
      bountyCount: community.bounty,
      communityType: community.communityType,
      coverImage: community.backgroundImage,
      id: community.id,
      memberCount: community.contributors,
      profileImage: community.profilePicture,
      title: community.name,
      discord: community.discordUrl,
      twitter: community.twitterHandle,
      communityTypes: community.communityTypes,
    })
  );

  return { communities: processedCommunities, totalCount: res.data.count };
};

export const fetchEngagements = async (
  entity: EntityValueType,
  searchQuery: string,
  communityType: CommunityFilterType
) => {
  const params: Record<string, string> = {
    type: entity,
    searchContent: searchQuery,
  };
  if (communityType) params["communityType"] = communityType;
  const response = await customAxios.get(`/discover/engagements`, {
    params,
  });

  let data:
    | BountyCardType[]
    | ProposalCardType[]
    | PostCardType[]
    | CourseCardType[] = [];

  if (entity === "BOUNTY") {
    data = (response?.data?.res as BountyDetailsFromApiType[])?.map(
      (bounty) =>
        ({
          bountyAmount: bounty.amount,
          bountyId: bounty.id,
          bountyCreatedAt: bounty.createdAt,
          bountyLastSubmissionDate: bounty.submissionDate,
          bountyTitle: bounty.title,
          bountyType: bounty.type,
          daoId: bounty.daoId,
          daoName: bounty.daoName,
          noOfSubmissions: bounty.noOfSubmissions,
          pinned: bounty.pinned,
          profilePicture: bounty?.profilePicture,
          symbol: bounty?.symbol,
          taskPoint: bounty.taskPoint,
          userId: bounty.userId,
          userName: bounty?.userName,
        } as BountyCardType)
    );
  }

  if (entity === "PROPOSALS") {
    data = (response?.data?.res as ProposalDetailsFromApiType[])?.map(
      (proposal) =>
        ({
          daoId: proposal.daoId,
          description: proposal.description,
          endDate: proposal.endDate,
          id: proposal.id,
          pinned: proposal.pinned,
          daoName: proposal.daoName,
          creatorAddress: proposal?.creatorAddress,
          startDate: proposal.startDate,
          status: proposal.status,
          title: proposal.title,
          userId: proposal.userId,
          userName: proposal.userName,
          profilePicture: proposal.profilePicture,
        } as ProposalCardType)
    );
  }

  if (entity === "POST") {
    data = (response?.data?.res as PostDetailsFromApiType[]).map(
      (post) =>
        ({
          category: post.category,
          createdAt: post.createdAt,
          postDescription: post.description,
          postId: post.id,
          postName: post.name,
          postTags: post.postTags,
          userId: post.userId,
          daoName: post.daoName,
          userName: post.userName,
          userProfilePicture: post.profilePicture,
          channelId: post.channelId,
          daoId: post.daoId,
        } as PostCardType)
    );
  }

  if (entity === "COURSES") {
    data = (response?.data?.res as CourseDetailsFromApiType[]).map(
      (course) =>
        ({
          daoId: course.daoId,
          description: course.description,
          difficultyLevel: course.difficultyLevel,
          guildTags: course.guildTags,
          id: course.id,
          daoName: course.daoName,
          course: course.daoName,
          name: course.name,
          pinned: course.pinned,
          provider: course.provider,
          providerLogoAttachment: course.providerLogoAttachment,
          thumbnailAttachment: course.thumbnailAttachment,
        } as CourseCardType)
    );
  }

  if (entity === "EVENTS") {
    data = response?.data?.res;
  }

  return data as any[];
};

export const fetchEvents = async () => {
  const response = await customAxios.get("/discover/events");

  return response.data.res;
};

export const handleCreateNewCommunity = () => {
  const createCommunityButtonElement = document.querySelector(
    ".sidepannel-btn.add-community"
  ) as HTMLButtonElement;
  if (!createCommunityButtonElement) {
    console.error("Create community button not found");
    return;
  }

  createCommunityButtonElement.click();
};

import { SearchOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { Button, Input, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import customAxios from "../../api";
import useExitPrompt from "../../hooks/useExitPrompt";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import useWindowSize from "../../hooks/useWindowSize";
import { DaoContext } from "../../layouts/app/DaoContext";
import { UserContext } from "../../layouts/app/UserContext";
import { postMixpanel, updateWalkmeStatus } from "../../utils";
import EmptyComponent from "../Common/EmptyComponent";
import MobilePageTopNavbar from "../Common/MobilePageTopNavbar";
import OnboardingModal from "../Onboarding/OnboardingModal";
import SnapshotIntegrationModal from "../Settings/Integrations/SnapshotIntegrationModal";
import CreateProposalModal from "./CreateProposalModal";
import ProposalCard from "./ProposalCard";
import no_proposal from "../../assets/iconSvg/no_proposal.svg";
import loading_proposal from "../../assets/iconSvg/loading_proposal_image.svg";
import ConnectWalletModal from "../Common/ConnectWalletModal";
import { toast } from "react-toastify";
import { Stylediv } from "../Course/Document/CreateDocumentModal";
import "../ComponentStyles/proposal_page.css";
import { useInfiniteQuery } from "react-query";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  fetchProposals,
  PROPOSAL_PAGINATION_LIMIT,
} from "../../utils/Proposals";
const steps = [
  {
    target: ".create-proposals",
    disableBeacon: true,
    disableScrolling: true,
    title: "Create Proposals",
    content: "Click on button start creating a new proposal.",
  },
  {
    target: ".create-proposals-2",
    disableBeacon: true,
    disableScrolling: true,
    title: "Create Proposals",
    content:
      "Add the title of your proposal and add details around the same in description.",
  },
  {
    target: ".configure-proposal",
    disableBeacon: true,
    disableScrolling: true,
    title: "Configure Proposal Settings",
    // TODO: update copy
    content: "Add a poll.",
  },
  {
    target: ".publish-proposal",
    disableBeacon: true,
    disableScrolling: true,
    title: "Click on Publish button to publish the proposal.",
  },
];

const StyledInput = styled(Input)`
  .ant-input {
    font-weight: 600;
  }
`;
const proposalStatus = {
  ALL: "All",
  ACTIVE: "Active",
  PENDING: "Pending",
  CLOSED: "Closed",
};
const Proposal = () => {
  const { walkMeStatus, reload } = useContext(UserContext);
  const [isWalletConnectModalOpen, setWalletConnectModalOpen] = useState(false);
  const { userDetails } = useContext(UserContext);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  // const [proposals, setProposals] = useState([]);
  const {
    daoDetails,
    loading: daoDetailsLoading,
    isAdmin,
    reload: daoReload,
    daoId,
    daoName,
    isSpace,
  } = useContext(DaoContext);
  const { isMobile } = useWindowSize();
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showEndingModal, setShowEndingModal] = useState(false);
  const [startTour, setStartTour] = useState(false);
  const [createProposalModalStep, setCreateProposalModalStep] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(true);

  const {
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data,
    isLoading,
    isError,
  } = useInfiniteQuery(
    ["fetch-proposals", daoId, selectedStatus, updater],
    ({ pageParam = 0, queryKey }) =>
      fetchProposals({
        offset: pageParam,
        daoId: queryKey[1],
        status: queryKey[2],
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.res.length < PROPOSAL_PAGINATION_LIMIT) return undefined;
        return lastPage.offset;
      },
    }
  );

  const [loaderRef] = useInfiniteScroll({
    loading: isLoading || isFetchingNextPage,
    hasNextPage,
    onLoadMore: fetchNextPage,
    disabled: isError,
    rootMargin: "0px 0px 400px 0px",
  });

  const proposals = data?.pages?.map((page) => page?.res)?.flat();

  useEffect(() => {
    if (createModalVisible) setShowExitPrompt(true);
    else setShowExitPrompt(false);
  }, [createModalVisible]);
  useEffect(() => {
    setLoading(true);

    (async () => {
      if (!daoId) return;
      const {
        data: { canCreate },
      } = await customAxios.get(`/proposals/cancreate?daoId=${daoId}`);
      if (
        canCreate &&
        walkMeStatus?.proposalCreate === false &&
        walkMeStatus &&
        daoDetails?.snapshotEnsName
      )
        setShowOnboardingModal(true);
      setCanCreate(canCreate);
      setLoading(false);
    })();
  }, [updater, walkMeStatus, daoDetails, selectedStatus, daoId]);
  useTrackPageView();
  const handleWalletConnectSuccess = () => {
    toast.success("Wallet connected successfully");
    setCreateModalVisible(true);
  };
  const proposalResult = proposals?.filter(
    (proposal) =>
      proposal.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      proposal.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ padding: isMobile ? "15px" : "0" }}>
      {" "}
      {isMobile || isSpace ? (
        <div className="lg:mt-5">
          <MobilePageTopNavbar />
        </div>
      ) : null}
      <ConnectWalletModal
        isModalOpen={isWalletConnectModalOpen}
        onSuccess={handleWalletConnectSuccess}
        setIsModalOpen={setWalletConnectModalOpen}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "12px",
          marginTop: "20px",
        }}
      >
        {showOnboardingModal ? (
          <OnboardingModal
            step={"proposal-start"}
            showModal={showOnboardingModal}
            onClick={() => {
              updateWalkmeStatus({ proposalCreate: true }, reload);
              postMixpanel(
                "track",
                "started_walke_me_tour_for_create_proposal"
              );

              setShowOnboardingModal(false);
              setStartTour(true);
            }}
            onClose={() => {
              setShowOnboardingModal(false);
            }}
          />
        ) : null}
        {showEndingModal ? (
          <OnboardingModal
            step={"proposal-end"}
            showModal={showEndingModal}
            onClick={() => {
              postMixpanel(
                "track",
                "finished_walke_me_tour_for_create_proposal"
              );

              setShowEndingModal(false);
            }}
            onClose={() => {
              setShowEndingModal(false);
            }}
          />
        ) : null}

        {/* Before quiz creation */}
        {/* {startTour ? (
          <WalkmeTour
            run={startTour}
            steps={steps}
            otherProps={{
              disableScrollParentFix: true,
              disableScrolling: true,
              continuous: true,
              disableBeacon: true,
              callback: (step) => {
                if (
                  step.step.target === ".create-proposals" &&
                  step.action === "update"
                ) {
                  setTimeout(() => {
                    setCreateModalVisible(true);
                  }, 1000);
                }
                if (
                  step.step.target === ".create-proposals-2" &&
                  step.lifecycle === "complete"
                ) {
                  setCreateProposalModalStep(2);
                  // setShowCourseVerificationAdminModal(true);
                }
                if (step.status === "finished") {
                  setShowEndingModal(true);
                  setCreateProposalModalStep(1);
                  setCreateModalVisible(false);
                }
              },
            }}
          />
        ) : null} */}

        {createModalVisible ? (
          <CreateProposalModal
            space={daoDetails?.snapshotEnsName}
            onSubmit={() => {
              setUpdater((prev) => !prev);
              setSearchTerm("");
            }}
            isModalVisible={createModalVisible}
            handleCancel={() => {
              setCreateModalVisible(false);
              setCreateProposalModalStep(1);
            }}
            step={createProposalModalStep}
            setStep={setCreateProposalModalStep}
          />
        ) : null}
        <StyledInput
          placeholder="Search Proposals"
          className="search-courses"
          value={searchTerm}
          allowClear
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          prefix={
            <SearchOutlined
              style={{ color: "#94A3B8" }}
              className="site-form-item-icon"
            />
          }
          style={{
            padding: "8px 18px",
            borderRadius: "12px",
            borderColor: "#f0f0f0",
            fontWeight: "600",
          }}
        />
        {(canCreate || isAdmin) && daoDetails?.snapshotEnsName ? (
          <Button
            className="create-proposals"
            type="primary"
            style={{
              padding: "18px 21px",
              borderRadius: "12px",
              background: "#3B82F6",
              fontWeight: 600,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "12px",
            }}
            onClick={() => {
              if (userDetails.isMetamaskConnected) {
                postMixpanel("track", "clicked_create_new_proposal_button");

                setCreateModalVisible(true);
              } else {
                toast.info(
                  "Please connect your wallet before creating a proposal",
                  { autoClose: "2000", pauseOnFocusLoss: false }
                );
                setWalletConnectModalOpen(true);
              }
            }}
          >
            Create New
          </Button>
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          gap: "8px",
          font: "normal 500 14px/20px Inter",
          flexWrap: "wrap",
          color: "#64748B",
          marginBottom: "12px",
        }}
      >
        {Object.entries(proposalStatus).map(([section, label]) => (
          <Stylediv
            key={section}
            onClick={() => {
              setSelectedStatus(section);
            }}
            style={{
              padding: "4px 12px",
              border: `1px solid ${
                selectedStatus === section ? "#76A9FA" : "#E5E7EB"
              }`,
              borderRadius: "40px",
              cursor: "pointer",
              color: selectedStatus === section ? "#3F83F8" : "",
              background: selectedStatus === section ? "#E1EFFE" : "",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {label}
          </Stylediv>
        ))}
      </div>
      <div
        style={{
          background: "transparent",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {(loading || daoDetailsLoading || isLoading) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={loading_proposal} alt="" />
            <h4 style={{ margin: "20px 0" }}>
              Hold on, we’re fetching all your proposals from Snapshot
            </h4>
            <Skeleton
              paragraph={{ rows: 3, width: "100%" }}
              title={false}
              size="large"
              active
            />
          </div>
        )}
        {!(loading || daoDetailsLoading || isLoading) &&
          !daoDetails?.snapshotEnsName &&
          isAdmin && (
            <div
              style={{
                borderRadius: "16px",
                padding: "64px 95px",
              }}
            >
              <SnapshotIntegrationModal
                daoId={daoDetails.daoId}
                reload={daoReload}
              />
            </div>
          )}

        {!(loading || daoDetailsLoading || isLoading) &&
          !(searchTerm && isFetchingNextPage) &&
          proposalResult?.length === 0 &&
          (daoDetails?.snapshotEnsName || !isAdmin) && (
            <div style={{ background: "white", borderRadius: "16px" }}>
              <EmptyComponent
                imageSource={no_proposal}
                text={
                  searchTerm
                    ? "No Proposals matched your search"
                    : "No Proposals available yet"
                }
                subtext={
                  searchTerm
                    ? "Please clear the search and try again"
                    : "Be sure to check here again in a while"
                }
              />
            </div>
          )}
        {!(loading || daoDetailsLoading || isLoading) &&
          proposalResult.map((proposal) => (
            <ProposalCard
              proposal={{ ...proposal, daoId: daoId, daoName: daoName }}
              canEdit={canCreate || isAdmin}
            />
          ))}
        {(isFetchingNextPage || hasNextPage) && (
          <div ref={loaderRef}>
            <Skeleton
              paragraph={{
                rows: 2,
                width: "100%",
              }}
              title={false}
              size="large"
              active
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Proposal;

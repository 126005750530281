const PrivacyPolicy = () => {
  return (
    <div style={{ padding: "20px" }}>
      <h1>Privacy policy</h1>
      DaoLens ("We", "Us" and "Our" ) built 'DaoLens' (the "bot") as a Discord
      Bot. This service is provided by ourself and is intended for use as is.
      This page is used to inform users ("you") regarding my policies with the
      collection, use, and disclosure of personal information if anyone decided
      to use this service. If you choose to use the bot, then you agree to the
      collection and use of information in relation to this policy. The
      collected information is required for the service to work. It is not
      shared with 3rd parties.
      <br />
      Information collection and use When installing the the bot you grant it
      access to the following scopes: read_shipping, write_shipping,
      read_products
      <br />
      DaoLens bot does not store user's Shopify login credentialss. Once DaoLens
      bot is uninstalled, all store related data (products, customers, orders)
      is deleted. No other information is stored. Sharing of data with 3rd party
      services The bot is hosted(database/routing/server) on{" "}
      <a href="https://aws.amazon.com" target="_blank" rel="noreferrer">
        AWS
      </a>{" "}
      and Heroku.
      <br />
      Security We value your trust in providing your personal information, thus
      We are striving to use commercially acceptable means of protecting it. But
      remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and We cannot guarantee
      its absolute security.
      <br />
      Cookies We do not use any cookies to track our users. Changes to this
      privacy policy We may update this privacy policy from time to time. Thus,
      you are advised to review this page periodically for any changes. We will
      notify you of any changes by sending you an email to give time for you to
      raise any concerns.
      <br />
      Contact us If you have any questions or suggestions about my Privacy
      Policy, do not hesitate to contact us at{" "}
      <a href="mailto:hello@daolens.com">hello@daolens.com</a>
    </div>
  );
};

export default PrivacyPolicy;

import styled from "styled-components";
import useDisourse from "../../hooks/useDiscourse";
import { getDefaultDaoImage } from "../../utils";
import { CommunityWallet, Discord, Discourse, Snapshot } from "./Integration";
const DashedCircle = styled.div`
  border: 4px dashed rgba(255, 255, 255, 0.08);
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const BubbleWrapper = styled.div`
  box-shadow: inset 5.33333px -5.33333px 5.33333px rgba(165, 165, 165, 0.1),
    inset -5.33333px 5.33333px 5.33333px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5.33333px);
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.1);
  padding: 8px;
  display: inline-block;
  animation: rotation-anti 15s infinite linear;
  visibility: ${(props) => (props.show ? "" : "hidden")};

  @keyframes rotation-anti {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-359deg);
    }
  }
`;
const RotateWrapper = styled.div`
  animation: rotation 15s infinite linear;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;
const IntegrationView = ({ daoDetails }) => {
  const { status } = useDisourse(daoDetails.daoId);

  const isSnapshotIntegrated = !!daoDetails?.snapshotEnsName;
  const isMetamaskIntegrated = !!daoDetails?.walletAddress;
  const isDiscordConnected = !!daoDetails?.discordGuildId;
  const isDiscourseImported = status === "IMPORTED";
  const atleastOneConnected =
    isSnapshotIntegrated ||
    isMetamaskIntegrated ||
    isDiscordConnected ||
    isDiscourseImported;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <DashedCircle
        style={{
          width: "592px",
          position: "absolute",
          animation: !atleastOneConnected
            ? "rotation-anti 20s infinite linear"
            : "",
        }}
      >
        <DashedCircle style={{ width: "476px" }}>
          <DashedCircle style={{ width: "364px" }}></DashedCircle>
        </DashedCircle>
      </DashedCircle>
      <RotateWrapper
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "592px",
          aspectRatio: 1,
        }}
      >
        <BubbleWrapper
          style={{ top: "75px", position: "absolute" }}
          show={isDiscordConnected}
        >
          <Discord view />
        </BubbleWrapper>
        <BubbleWrapper
          style={{ left: "15px", position: "absolute" }}
          show={isMetamaskIntegrated}
        >
          <CommunityWallet view />
        </BubbleWrapper>
        <BubbleWrapper
          style={{ right: "15px", position: "absolute" }}
          show={isSnapshotIntegrated}
        >
          <Snapshot view />
        </BubbleWrapper>
        <BubbleWrapper
          style={{ bottom: "75px", position: "absolute" }}
          show={isDiscourseImported}
        >
          <Discourse view />
        </BubbleWrapper>
      </RotateWrapper>
      {daoDetails?.profilePicture ? (
        <img
          src={daoDetails?.profilePicture}
          alt="dao_logo"
          style={{
            borderRadius: "50%",
            width: "150px",
            height: "150px",
            objectFit: "cover",
            border: "6px solid #ffffff",
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
          }}
        />
      ) : (
        getDefaultDaoImage(
          daoDetails?.daoName,
          {
            alignSelf: "center",
            width: "150px",
            height: "150px",
            cursor: "pointer",
            border: "6px solid #ffffff",

            borderRadius: "50%",
          },
          { fontSize: "28px" }
        )
      )}
    </div>
  );
};

export default IntegrationView;

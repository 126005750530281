import { Modal } from "../../../styles/ModalStyles";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Input, Select, Space, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import customAxios from "../../../api";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { ModalBody } from "../../BountyComponents/PaymentModal";
import useWindowSize from "../../../hooks/useWindowSize";
import { useWalletConnect } from "../../../layouts/app/WalletConnectContext";
import Web3 from "web3";
import { walletConnect } from "../../../utils/connectors";
import { useEthers } from "@usedapp/core";
import { UserContext } from "../../../layouts/app/UserContext";
import { useDaoContext } from "../../../layouts/app/DaoContext";

// import "../ComponentStyles/antdStyles.scss";

const AddPaymentModal = ({ visible, setVisibility, onSubmit }) => {
  const [selectedWalletType, setSelectedWalletType] = useState("");
  const { isMobile } = useWindowSize();
  const [addPaymentStep, setAddPaymentStep] = useState(false);
  const [paymentMethodMetaData, setPaymentMethodMetaData] = useState([]);
  const [selectedNetworkType, setSelectedNetworkType] = useState("");
  const [gnosisHashToSign, setGnosisHashToSign] = useState("");
  const { onClickActivate, isWalletConnected, setWalletConnected } =
    useWalletConnect();
  const { account } = useEthers();
  const { userDetails } = useContext(UserContext);
    const { daoDetails, daoId } = useDaoContext();
  
  useEffect(() => {
    getPaymentMethodMetaData();
    getHashToSign();
  }, []);
  console.log("paymentMethodMetaData", selectedWalletType);
  const [gnosisAddress, setGnosisAddress] = useState("");
  const getPaymentMethodMetaData = () => {
    setAddPaymentStep(true);
    customAxios
      .get(`bountypayments/paymentmethodmetadata`)
      .then(({ data }) => {
        setPaymentMethodMetaData(data.paymentMethodMetadata);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured");
      });
  };
  const connectWalletHandler = () => {
    if (selectedWalletType === "GNOSIS") {
      return handleSignMessage(gnosisHashToSign);
    }
    return customAxios
      .post(`bountypayments/savepaymentmethod`, {
        walletType: selectedWalletType,
        network: selectedNetworkType,
        walletAddress: gnosisAddress,
        daoId,
      })
      .then((res) => {
        onSubmit();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured");
      });
  };
  const getHashToSign = () => {
    customAxios
      .get("bountypayments/getHash")
      .then((res) => {
        console.log("res", res.data.hashToSign);
        setGnosisHashToSign(res.data.hashToSign);
      })
      .catch((err) => console.log("err", err));
  };
  console.log("account", userDetails);
  const handleSignMessage = (hash) => {
    let web3;
    if (!isWalletConnected) {
      web3 = new Web3(Web3.givenPovider || window.ethereum);
    } else {
      web3 = new Web3(walletConnect);
    }

    web3.eth.personal
      .sign(web3.utils.fromUtf8(hash), userDetails.walletAddress) // assuming that te wallet is connected already
      .then((res) => {
        console.log("metamask res", res);
        customAxios
          .post("bountypayments/delegateforgnosis", {
            daoId,
            walletAddress: userDetails.walletAddress,
            safe: gnosisAddress,
            signature: res,
            chain: selectedNetworkType.toUpperCase(),
          })
          .then((response) => {
            console.log("response", response);
            if (response.data.success) {
              toast.success("Connected Gnosis wallet");
              setVisibility(false);
              customAxios
                .post(`bountypayments/savepaymentmethod`, {
                  walletType: selectedWalletType,
                  network: selectedNetworkType,
                  walletAddress: gnosisAddress,
                  daoId,
                })
                .then((res) => {
                  onSubmit();
                })
                .catch((err) => {
                  console.log(err);
                  toast.error("Some error occured");
                });
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
        // customAxios
        //   .post("auth/connect/metamask/login", {
        //     token: "Bearer " + token,
        //     signature: res,
        //   })
        //   .then((response) => {
        //     localStorage.setItem("access_token", response.data.access_token);
        //     setLoading(false);
        //     setIsModalOpen(false);
        //     onSuccess();
        //     reload();
        //   })

        //   .catch((err) => {
        //     toast.error(
        //       "Metamask Account is already Connected With Another Account"
        //     );
        //     setLoading(false);
        //   });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      centered
      destroyOnClose
      title={null}
      visible={visible}
      onCancel={() => setVisibility(false)}
      footer={null}
      isMobile={isMobile}
      width={"492px"}
      height="170px"
    >
      <ModalBody>
        <>
          <Space className="heading" style={{ marginBottom: "10px" }}>
            <Typography>Add payment method</Typography>
          </Space>
          <div className="grid-wrapper">
            <Typography className="label">Wallet type</Typography>
            <Typography className="label">Network</Typography>
            <Select
              style={{ textTransform: "capitalize" }}
              className="select"
              placeholder="Choose type"
              onSelect={(value) => setSelectedWalletType(value)}
            >
              {paymentMethodMetaData.map((method) => (
                <Select.Option
                  value={method.walletType}
                  key={method.walletType}
                  style={{ textTransform: "capitalize" }}
                >
                  {method.walletType.toLowerCase()}
                </Select.Option>
              ))}
            </Select>
            <Select
              className="select"
              placeholder="Choose type"
              onSelect={(value) => setSelectedNetworkType(value)}
              disabled={selectedWalletType === ""}
            >
              {selectedWalletType &&
                paymentMethodMetaData
                  .filter(
                    (method) => method.walletType === selectedWalletType
                  )[0]
                  .network.map((networkType) => (
                    <Select.Option value={networkType}>
                      {networkType}
                    </Select.Option>
                  ))}
            </Select>
          </div>
          <div style={{ marginTop: "12px" }}>
            <Typography className="label">Wallet address</Typography>
            <Input
              className="input"
              placeholder="Enter your wallet address"
              disabled={selectedNetworkType === ""}
              onChange={(e) => setGnosisAddress(e.target.value)}
            />
          </div>
          <Button
            className="initiate-payment-btn"
            type="primary"
            onClick={connectWalletHandler}
            disabled={gnosisAddress.trim() === ""}
            style={{ height: "40px", marginTop: "16px" }}
          >
            {selectedWalletType == "GNOSIS"
              ? "Approve and add wallet"
              : "Add wallet"}
          </Button>
        </>
      </ModalBody>
    </Modal>
  );
};

export default AddPaymentModal;

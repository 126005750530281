import React from "react";
import { DiscordIcon, ArrowRight } from "./Icones";
import styled from "styled-components";
import { Modal } from "../styles/ModalStyles";
import { Button, Typography } from "antd";
import IconWrapper from "./IconWrapper";
import DaoCreationPNG from "../images/DaoCreation.png";
import { Navigate, useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import community from "../assets/iconSvg/community.svg";
import { postMixpanel } from "../utils";
import { CloseOutlined } from "@ant-design/icons";
import { MD_SCREEN_BREAKPOINT } from "../constants";
import useWindowSize from "../hooks/useWindowSize";
import addCommunityImage from "./../assets/images/daoCreation/addCommunity.svg";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
const ModalBody = styled.div`
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  & .title {
    // width: 106px;
    height: 32px;
    font-weight: 500;
    font-size: 20px;
  }

  & .image {
    align-self: center;
    width: 300px;
    height: 244px;
    margin-top: 0px;
  }

  & .discord-button {
    align-self: center;
    width: 100%;
    margin-top: 0px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }

  & .text-button {
    color: #64748b;
    align-self: center;
    margin-top: 8px;
  }
`;

const DaoCreateModal = ({ setCurrentStep, handleNewDao, closable = true }) => {
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const handleClose = () => {
    setCurrentStep(0);
  };
  const navigate = useDaoLensNavigate();
  return (
    <Modal
      centered={!isMobile}
      destroyOnClose
      title={null}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.5",
        backdropFilter: "blur(20px)",
      }}
      visible
      onCancel={
        closable
          ? handleClose
          : () => {
              navigate(-1);
            }
      }
      footer={null}
      width={isMobile ? "100%" : "440px"}
      style={{
        top: isMobile && "calc(100vh - 400px)",
      }}
      height="312px"
    >
      <ModalBody>
        <Typography className="title" style={{ textAlign: "center" }}>
          Add Community
        </Typography>

        <img src={addCommunityImage} alt="add-btn" className="image" />
        <Button
          type="primary"
          className="discord-button"
          onClick={() => {
            postMixpanel(
              "track",
              "clicked_import_from_discord_button_in_add_community_modal"
            );
            window.open(
              `https://discord.com/api/oauth2/authorize?client_id=${
                process.env.REACT_APP_ENDPOINT === "development"
                  ? "1046753505026785401"
                  : "1014817083139301417"
              }&redirect_uri=${
                encodeURIComponent(window.location.origin) + "/app/home"
              }&response_type=code&state=${btoa(
                window.location.pathname
              )}&scope=identify%20guilds`,
              "_self"
            );
          }}
        >
          <IconWrapper width="21px">
            <DiscordIcon />
          </IconWrapper>
          Import from Discord
        </Button>
        <Button
          type="text"
          className="text-button"
          onClick={() => {
            handleNewDao(
              postMixpanel(
                "track",
                "clicked_create_from_Scratch_button_in_add_community_modal"
              )
            );
          }}
        >
          Create from scratch
          <IconWrapper>
            <ArrowRight />
          </IconWrapper>
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default DaoCreateModal;

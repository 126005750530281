import { useEffect, useState } from "react";
import customAxios from "../api";

type Options = {
  daoId: string | undefined;
};

const useCanEdit = ({ daoId }: Options) => {
  const [canEdit, setCanEdit] = useState(false);
  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`settings/canview`, { daoId })
      .then((res) => {
        setCanEdit(res.data.canView ? true : false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [daoId]);

  return canEdit;
};

export default useCanEdit;

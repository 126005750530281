import axios from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { toast } from "react-toastify";
import customAxios from "../api";
import mixpanel from "mixpanel-browser";
import pattern from "./../assets/images/logos/pattern.png";
import elliptic from "elliptic";
import crypto from "crypto";
import { ethers } from "ethers";
import { TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY } from "../constants";
import minimatch from "minimatch";
const IPFS_DOWNLOAD_ENDPOINT = "https://api.thegraph.com/ipfs/api/v0/cat";
export const checkAccessTokenExpiry = (token) => {
  const currToken = localStorage.getItem("access_token");
  const newUser = localStorage.getItem("new_user");
  if (currToken) {
    const timeDiff = moment().diff(
      moment.unix(jwtDecode(currToken).iat),
      "seconds"
    );
    if (timeDiff > 172800) {
      return true;
    } else if (newUser === "true") {
      return true;
    } else return false;
  } else return false;
};

export const checkIsLoggedInUserValid = () => {
  const currToken = localStorage.getItem("access_token");
  const newUser = localStorage.getItem("new_user");
  if (currToken) {
    const timeDiff = moment().diff(
      moment.unix(jwtDecode(currToken).iat),
      "seconds"
    );
    if (timeDiff > 172800) {
      return false;
    } else if (newUser === "true") {
      return false;
    } else return true;
  } else return false;
};
export const shareOnTwitter = (text) => {
  const anchor = document.createElement("a");
  anchor.href = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    text
  )}
`;
  anchor.target = "_blank";
  anchor.click();
};

export const updateWalkmeStatus = (data, reload) => {
  customAxios.post("/onboarding/walkme/update", data).then((res) => {
    reload();
  });
};
export const validateEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};
export const checkImageType = (fileType) => {
  console.log(fileType);
  if (fileType.split("/")[0] !== "image") {
    toast.error("Please upload an image");
    throw new Error("Please upload an image");
  }
};
export const checkFileSize = (filesize) => {
  if (filesize > 9000000) {
    toast.error("File size is too large");
    throw new Error("File size is too large");
  }
};
export const getTruncatedString = (string, maxLength) => {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + "...";
  } else return string;
};
export const getUsernameFromUrl = (val) => {
  let urlValue;
  if (val) {
    if (!!val?.split("/")[3]) {
      urlValue = val?.split("/")[3];
    } else {
      urlValue = val;
    }
  } else {
    urlValue = "";
  }
  return urlValue;
};

export const getWebsiteVal = (websiteval) => {
  if (websiteval) {
    if (websiteval.split("//")[1]) {
      return websiteval.split("//")[1];
    } else return websiteval;
  }
  return "";
};

export const isValidTwitterUrl = (twitterUrl) => {
  return (
    twitterUrl.startsWith("https://twitter.com/") ||
    twitterUrl?.startsWith("https://www.twitter.com/")
  );
};

export const isValidGithubUrl = (twitterUrl) => {
  return (
    twitterUrl.startsWith("https://github.com/") ||
    twitterUrl?.startsWith("https://www.github.com/")
  );
};
export const getMetadataFromIpfsHash = async (ipfsHash) => {
  const { data } = await axios.get(`https://ipfs.io/${ipfsHash}`);
  const { Data } = await axios.get(getUrlForIPFSHash(ipfsHash.split("/")[1]));
  console.log("first", Data);
  return { ...data, image: `https://ipfs.io/${data.image}` };
};
export const getUrlForIPFSHash = (hash) => {
  if (hash === "") {
    return "";
  }
  const getLastIndex = hash.lastIndexOf("/");
  const finalUrl =
    "https://" +
    hash.substring(0, getLastIndex) +
    "snapshot.mypinata.cloud/ipfs/" +
    hash.substring(getLastIndex);
  return finalUrl;
};

export const getTruncatedAddress = (address) => {
  return `${address?.substring(0, 5)}...${address?.slice(-4)}`;
};
export const postMixpanel = (type, eventName, data) => {
  const sourcePage = getCurrentPath();
  const constantData = {
    source_page: sourcePage,
  };

  const isSpace = minimatch(sourcePage, "/app/dao/*/*/spaces/**");

  if (isSpace) {
    const paths = sourcePage?.split("/");

    const spaceId = paths[7];
    if (spaceId) constantData.space_id = spaceId;

    const daoId = paths[4];
    if (daoId) constantData.dao_id = daoId;
  }

  switch (type) {
    case "track":
      mixpanel?.track(eventName, { ...data, ...constantData });
      return;
    case "register_once":
      mixpanel?.register_once(eventName, { ...data, ...constantData });
      return;
    default:
      return null;
  }
};
export const getPercentage = (num, total) => {
  console.log("jj", num, total);
  return +Number((num * 100) / total).toFixed(2);
};
export const getCurrentPath = () => {
  return window.location.href.split(window.location.host)[1];
};

export function getRandomItemFromArray(items) {
  return items[Math.floor(Math.random() * items.length)];
}

export const connectTwitter = ({
  callbackUrl = `${window.location.origin}/app/profile`,
  bountyId = null,
  daoId = null,
}) => {
  if (bountyId && daoId) {
    localStorage.setItem(
      TWITTER_BOUNTY_DETAILS_LOCAL_STORAGE_KEY,
      JSON.stringify({ bountyId, daoId })
    );
  }

  customAxios
    .post("/auth/connect/twitter/request/token", {
      callbackUrl,
    })
    .then((res) => {
      console.log({ link: res.data.authLink.url });
      if (res.data.authLink.url) window.open(res.data.authLink.url, "_self");
    });
};

export const getDefaultDaoImage = (daoName, imageStyle, textStyle) => {
  return (
    <div
      style={{
        width: "24px",
        height: "24px",
        borderRadius: "12px",
        objectFit: "contain",
        backgroundImage: `url(${pattern})`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "contain",
        ...imageStyle,
      }}
    >
      <h5
        style={{
          fontSize: "12px",
          color: "white",
          fontWeight: "500",
          marginBottom: "0px",
          ...textStyle,
        }}
      >
        {daoName?.charAt(0)?.toUpperCase()}
      </h5>
    </div>
  );
};
export const copyTextToClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};
export const getCurrentDateFromNow = (date) => {
  if (
    moment(date).diff(moment.now()) < 0 &&
    moment(date).diff(moment.now(), "day") > -1
  )
    return `Expired ${moment(date).fromNow()}`;
  else if (
    moment(date).diff(moment.now()) < 0 &&
    moment(date).diff(moment.now(), "day") < -1
  )
    return `Expired on ${moment(date).format("D MMM YYYY, hh:mm A ")}`;
  else {
    return `Due on ${moment(date).format("D MMM YYYY, hh:mm A ")}`;
  }
};
export const generateSignature = (secretKey, msg) => {
  var EdDSA = elliptic.eddsa;
  var ec = new EdDSA("ed25519");
  // ################ for generating public and private key pair ###################
  var key = ec.keyFromSecret(secretKey);
  var msgHash = crypto.createHash("sha256").update(msg).digest();
  var signature = key.sign(msgHash).toHex();
  var toBePasted = "---BEGIN SIGNATURE---" + signature + "---END SIGNATURE---";
  // const secretKey = hash.digest().toString("hex");
  // 846122b479a5198a835a6b00712290bab77da52da41ece8fa9b960ed0e7ec5d0

  // const publicKey = Buffer.from(key.getPublic()).toString("hex");
  // 385e662efab35be8a8b900c2884bdf09b9f7a585dc1852f3cef95d2940a556c8
  // ################ for generating public and private key pair ###################

  // ################ for signing the message ###################

  // // Verify signature
  // var signature = '70bed1...';
  console.log("msg", msg, secretKey);
  console.log(key.verify(msgHash, signature));
  var key2 = ec.keyFromPublic(
    "0x047cfd6e0156f3e582342face302c7f09c873a985b82cc9d20231eb87d0a828fa50d3bcc3380ef6be2023e85766943c3aca01e65d1ca814fd35b7cc00970b4f5c3",
    "hex"
  );
  // // Verify signature
  // var signature = '70bed1...';
  console.log(key2.verify(msgHash, signature));

  return {
    privateKey: secretKey,
    signature: signature,
    toBePasted: toBePasted,
  };
  // console.log("yoooo",msgHash.data == Buffer.from(msgHash.toString(), 'utf8'))
  // ---BEGIN SIGNATURE---95F8B20FB9346018B9E5EBF58669EE4BC7DC28204E68AE4D74D6F1F70581EB1CB0423B5DF7A4C509B6E43C623F8E6CB2C354E4169B98C4C88BA9110F522B6404---END SIGNATURE---

  // ################ for signing the message ###################

  // ################ for verifying the message ###################
  // Import public key
  // var pub = '0a1af638...';
};

import React, { useEffect, useRef, useState } from "react";
import { Button, Input, Space, Tag, Row, Card, Tooltip, Select } from "antd";
import { Modal, Footer } from "../../styles/ModalStyles";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../api";
import { useParams } from "react-router";
import styles from "./course.module.css";
import { ReactComponent as LinkIcon } from "../../assets/images/course/link.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/course/upload.svg";
import { ReactComponent as QuizIcon } from "../../assets/images/course/quiz.svg";

import UploadContent from "./CourseContent/UploadContent";
import AddLink from "./CourseContent/AddLink";
import { confirm, isValidHttpUrl } from "./CreateCourseModal";
import QuizContent from "./CourseContent/QuizContent";
import {
  quizInitialState,
  quizValidation,
} from "../BountyComponents/CreateBounty/Quiz";
import { ReactComponent as ColorLinkIcon } from "../../assets/images/course/colorlink.svg";
import { ReactComponent as DocumentIcon } from "../../assets/images/course/document.svg";
import { ReactComponent as ColorQuizIcon } from "../../assets/images/course/colorquiz.svg";
import { ReactComponent as BlueLinkIcon } from "../../assets/images/course/bluelink.svg";
import { ReactComponent as BlueUploadIcon } from "../../assets/images/course/blueupload.svg";
import { ReactComponent as BlueQuizIcon } from "../../assets/images/course/bluequiz.svg";
import { getContentType } from "./CourseContent/CourseContentCard";
import useWindowSize from "../../hooks/useWindowSize";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import { postMixpanel } from "../../utils";
import { useDaoContext } from "../../layouts/app/DaoContext";
import { confirmOperation } from "../../utils/common";

const Stylediv = styled.div`
  &:hover {
    background: rgba(226, 232, 240, 0.2);
  }
`;
export let tabs = [
  {
    label: "Upload",
    value: "UPLOAD",
    icon: (isSelected) => (isSelected ? <BlueUploadIcon /> : <UploadIcon />),
    coloricon: <DocumentIcon />,
    component: (state, setState) => (
      <UploadContent state={state} setState={setState} />
    ),
    initialState: (content) => ({
      link: content.link,
    }),
    validation: (state, res) => {
      if (state.file) {
        res.files = state.file;
        return;
      }
      if (state.link?.includes("https://storage.googleapis.com")) {
        return;
      }
      toast.error("Please provide attachment");
      throw new Error();
    },
  },
  {
    label: "Add link",
    value: "ADD_LINK",
    icon: (isSelected) => (isSelected ? <BlueLinkIcon /> : <LinkIcon />),
    coloricon: <ColorLinkIcon />,

    component: (state, setState) => (
      <AddLink state={state} setState={setState} />
    ),
    validation: (state, res) => {
      if (!state.link) {
        toast.error("Please provide course link");
        throw new Error();
      } else {
        res.link = state.link;
      }
    },
    initialState: (content) => ({
      link: content.link,
    }),
  },
  {
    label: "Quiz",
    value: "QUIZ",
    icon: (isSelected) => (isSelected ? <BlueQuizIcon /> : <QuizIcon />),
    coloricon: <ColorQuizIcon />,
    component: (state, setState) => (
      <QuizContent state={state} setState={setState} />
    ),
    validation: quizValidation,
    initialState: quizInitialState,
  },
];

export const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;

const CreateCourseContentModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  edit,
  content,
}) => {
  const [loading, setLoading] = useState(false);
  const { courseId } = useParams();
  const { daoDetails, daoId } = useDaoContext();

  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const defaultState = {
    tab: "UPLOAD",
    quiz: [
      {
        question: "",
        type: "MULTIPLE_CHOICE",
        options: [
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
          { text: "", isCorrect: false },
        ],
      },
    ],
  };
  const [state, setState] = useState(() => defaultState);

  useEffect(() => {
    if (edit) {
      setState((prev) => ({
        ...prev,
        title: content.title,
        tab: getContentType(content),
        description: content.description,
        ...tabs
          .find(({ value }) => value === getContentType(content))
          ?.initialState(content),
      }));
    }
  }, [isModalVisible]);
  const createCourseContent = () => {
    let res = {};

    tabs.find(({ value }) => value === state.tab)?.validation(state, res);

    const formData = new FormData();
    if (!state.title) {
      toast.error("Please provide content title");
      throw new Error();
    } else {
      formData.append("title", state.title);
    }
    if (state.description) {
      formData.append("description", state.description);
    }
    Object.entries(res)?.forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("daoId", daoId);
    formData.append("courseId", courseId);
    if (edit) {
      formData.append("courseDetailId", content.id);
    }

    setLoading(true);
    customAxios
      .post(`/courses/details/${edit ? "edit" : "add"}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        toast.success(
          `Course content ${edit ? "updated" : "added"} successfully`
        );
        if (edit) {
          postMixpanel("track", "edit_course_content", {
            dao_id: daoId,
            course_content_title: state.title,
          });
        } else {
          postMixpanel("track", "create_course_content", {
            dao_id: daoId,
            course_content_title: state.title,
          });
        }
        handleCancel();
        onSubmit();
        setState(defaultState);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <StyledModalWrapper>
        <Modal
          centered
          isMobile={isMobile}
          width="640px"
          height="auto"
          destroyOnClose
          className="create-quiz"
          title={<></>}
          visible={isModalVisible}
          onCancel={() => {
            if (state.title || state.description || state.link || state.file)
              confirmOperation({
                cancelText: "Discard Changes",
                confirmText: "Continue Editing",
                message:
                  " You will lose the progress if you close this pop-up?",
                title: "Confirm",
                onCancel: () => handleCancel(),
              });
            else {
              handleCancel();
            }
          }}
          footer={
            state?.tab === "ADD_LINK" &&
            !(
              state.link &&
              !state.link?.includes("https://storage.googleapis.com")
            )
              ? null
              : [
                  <Footer key="modal-footer" justifyContent="flex-end">
                    <div style={{ display: "flex", gap: "4px" }}>
                      <Button
                        disabled={loading}
                        className="rounded-corner"
                        type="primary"
                        onClick={() => {
                          createCourseContent();
                        }}
                        style={{ cursor: loading ? "wait" : "pointer" }}
                      >
                        {edit ? "Update Content" : " Add content"}
                      </Button>
                    </div>
                  </Footer>,
                ]
          }
        >
          <div
            style={{
              padding: "0px 20px",
              font: "normal 600 20px/28px Inter",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginBottom: "20px",
            }}
          >
            {edit ? "Edit Course Content" : "Add Course Content"}
            <div
              style={{
                display: "flex",
                gap: "8px",
                font: "normal 500 14px/20px Inter",
                flexWrap: "wrap",
                color: "#64748B",
              }}
            >
              {tabs.map(({ label, value, icon }) => (
                <Stylediv
                  key={value}
                  onClick={() => {
                    setState((prev) => {
                      return { ...prev, tab: value };
                    });
                  }}
                  style={{
                    padding: "8px 16px",
                    border: `1px solid ${
                      state.tab === value ? "#76A9FA" : "#E5E7EB"
                    }`,
                    borderRadius: "40px",
                    cursor: "pointer",
                    color: state.tab === value ? "#3F83F8" : "",
                    background: state.tab === value ? "#E1EFFE" : "",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  {icon(state.tab === value)}
                  {label}
                </Stylediv>
              ))}
            </div>
            {tabs
              .find(({ value }) => value === state.tab)
              ?.component(state, setState)}
          </div>
        </Modal>
      </StyledModalWrapper>
    </>
  );
};

export default CreateCourseContentModal;

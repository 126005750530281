import React, { useEffect, useState } from "react";
import { Button, Input, Select as AntSelect, Tooltip } from "antd";
import { Modal, Footer } from "../../styles/ModalStyles";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useParams } from "react-router";
import moment from "moment";
import InputField from "../Common/form/Input";
import customAxios from "../../api";
import { HoverCard } from "../Search/SearchModal";
import { createProposal } from "./api";
import { getTrunckedString } from "./ProposalCard";
import DueDate from "../DueDate";
import { postMixpanel } from "../../utils";
import { ReactComponent as StackIcon } from "./../../images/rectangle-stack-icon.svg";
import LinkEntities from "../Common/LinkEntities";
import IconWrapper from "../IconWrapper";
import { useWindow } from "@usedapp/core/dist/esm/src/providers";
import useWindowSize from "../../hooks/useWindowSize";
import { useWalletConnect } from "../../layouts/app/WalletConnectContext";
import { useDaoContext } from "../../layouts/app/DaoContext";
import {
  confirmOperation,
  roundUpTimeToGivenMinuteMultiple,
} from "../../utils/common";
import styles from "../../components/Course/course.module.css";
import ErrorModal from "../ErrorModal";
const proposalType = [
  {
    value: "single-choice",
    label: "Single Choice",
    description: "Users can choose only one option ",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#single-choice-voting",
  },
  {
    value: "approval",
    label: "Approval",
    description: "Approve a certain number of options",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#approval-voting",
  },
  {
    value: "quadratic",
    label: "Quadratic",
    description: "Users can approve a certain number of options ",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#quadratic-voting",
  },
  {
    value: "ranked-choice",
    label: "Ranked Choice",
    description:
      "Users can rank the different choices in their order of preference",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#single-choice-voting",
  },
  {
    value: "weighted",
    label: "Weighted",
    description: "Users can spread their votes over multiple options",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#weighted-voting",
  },
  {
    value: "basic",
    label: "Basic",
    description:
      "Users can abstain from voting while still participating in quorum ",
    learnMoreUrl:
      "https://docs.snapshot.org/user-guides/proposals/voting-types#single-choice-voting",
  },
];

export const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;

const Select = styled(AntSelect)`
  .ant-select-selector {
    border-radius: 12px !important;
    background: #f4f2f2 !important;
    border: 0 !important;
    height: 36px !important;
    display: flex !important;
    align-items: center !important;
    font-family: "Inter" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
`;
export const UnstyledTextArea = styled(Input.TextArea)`
  &:focus {
    box-shadow: none;
  }
`;
const CreateProposalModal = ({
  isModalVisible,
  handleCancel,
  onSubmit,
  space,
  step,
  setStep,
}) => {
  const { daoDetails, daoId } = useDaoContext();

  const [choices, setChoices] = useState([""]);
  const [submitting, setSubmitting] = useState(undefined);
  const [proposalSettings, setProposalSettings] = useState(undefined);
  const [linkedItems, setLinkedItems] = useState([]);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [form, setForm] = useState({});
  const { isMobile } = useWindowSize();
  const { isWalletConnected } = useWalletConnect();
  const [error, setError] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  useEffect(() => {
    customAxios.get(`/proposals/settings?space=${space}`).then(
      ({
        data: {
          res: { space },
        },
      }) => {
        setProposalSettings(space);
        let startDate = new Date().getTime() + space?.voting?.delay * 1000;
        let endDate = startDate + space?.voting?.period * 1000;
        setForm((prev) => ({
          ...prev,
          choice: space?.voting?.type ? space.voting?.type : "single-choice",
          startDate: space?.voting?.delay
            ? new Date(startDate)?.toISOString().split("T").join(" ")
            : roundUpTimeToGivenMinuteMultiple(moment(), 15),
          endDate: space?.voting?.period
            ? new Date(endDate)?.toISOString().split("T").join(" ")
            : undefined,
        }));
      }
    );
  }, [space]);

  const goToStepTwo = () => {
    if (!form.name) {
      toast.error("Please enter title");
      return;
    }
    if (!form.details) {
      toast.error("Please enter description");
      return;
    }
    setStep(2);
  };
  const submitProposal = async () => {
    setSubmitting(true);
    setError("");
    try {
      if (!form.choice) return toast.error("Please provide voting type");
      if (!form.startDate) return toast.error("Please provide start date");
      if (!form.endDate) return toast.error("Please provide end date");

      if (new Date(form.startDate).getTime() < new Date().getTime()) {
        return toast.error("Start date should be greater than current time");
      }
      if (
        new Date(form.startDate).getTime() >= new Date(form.endDate).getTime()
      ) {
        return toast.error("End date should be greater than start date ");
      }
      let chs =
        form.choice === "basic" ? ["For", "Against", "Abstain"] : choices;
      if (chs?.length === 0) return toast.error("Please provide choices");
      const emptychoices = chs.filter((choice) => choice.trim().length === 0);

      if (emptychoices?.length > 0)
        return toast.error("Choices cannot be empty");

      if (!window.ethereum) {
        toast.error("Please install metamask extension");
        return;
      }

      const [account] = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      if (!account) {
        throw Error("Wallet not connected");
      }
      const { receipt, creatorAddress } = await createProposal(
        {
          data: {
            body: form.details,
            choices:
              form.choice === "basic" ? ["For", "Against", "Abstain"] : choices,
            end: Math.round(new Date(form.endDate).getTime() / 1000),
            metadata: "{}",
            network: proposalSettings?.network,
            plugins: "{}",
            space,
            start: Math.round(new Date(form.startDate).getTime() / 1000),
            timestamp: Math.round(new Date().getTime() / 1000),
            title: form.name,
            type: form.choice,
          },
        },
        isWalletConnected
      );
      const res = await customAxios.post("/proposals/create", {
        title: form.name,
        startDate: form.startDate,
        description: form.details,
        endDate: form.endDate,
        votingType: form.choice,
        choices: chs,
        daoId,
        creatorAddress,
        space,
        snapProposalId: receipt.id,
        linkedEntites: linkedItems.map((ele) => ({
          linkedType: ele.type,
          linkedId: ele.id,
        })),
      });
      if (res.data.success) {
        postMixpanel("track", "created_proposal", {
          dao_id: daoId,
          proposal_title: form.name,
        });
        toast.success("Proposal created successfully");
        handleCancel();
        onSubmit();
        setForm({});
      } else toast.error("Some error occured");
    } catch (error) {
      console.error(error);

      const errorMessage = error
        ? error.error_description
          ? error.error_description
          : error.toString()
        : "Please choose correct wallet and network";

      if (errorMessage.length <= 25) toast.error(errorMessage);
      else if (errorMessage?.includes("user rejected signing")) {
        toast.error("User rejected signing");
      } else {
        setError(errorMessage);
        setIsErrorModalOpen(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {}, []);
  return (
    <StyledModalWrapper>
      <Modal
        width="640px"
        height="500px"
        isMobile={isMobile}
        destroyOnClose
        title={<></>}
        className="create-proposals-2 configure-proposal"
        visible={isModalVisible}
        onCancel={() => {
          if (
            (form.name && form.name !== "") ||
            (form.details && form.details !== "") ||
            (form.endDate && form.endDate !== "") ||
            (form.startDate && form.startDate !== "") ||
            (choices?.length === 1 && choices[0].length > 0) ||
            choices.length > 1
          ) {
            confirmOperation({
              cancelText: "Discard Changes",
              confirmText: "Continue Editing",
              message: " You will lose the progress if you close this pop-up?",
              title: "Confirm",
              onCancel: () => handleCancel(),
            });
            return;
          }
          handleCancel();
        }}
        footer={[
          <Footer key="modal-footer" justifyContent="space-between">
            {step === 1 ? (
              <>
                <LinkEntities
                  isModalVisible={linkModalVisible}
                  handleClose={() => {
                    setLinkModalVisible(false);
                  }}
                  linkedItems={linkedItems}
                  setLinkedItems={setLinkedItems}
                ></LinkEntities>
                <Tooltip title="Link the discussions, bounties or any entity that led to this proposal">
                  <Button
                    className="borderless-button"
                    shape="circle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      setLinkModalVisible(true);
                    }}
                  >
                    <IconWrapper>
                      <StackIcon />{" "}
                    </IconWrapper>{" "}
                    <span style={{ color: "#64748B", fontSize: "12px" }}>
                      {linkedItems.length ? `(${linkedItems.length})` : null}
                    </span>
                  </Button>
                </Tooltip>
                <Button
                  className="rounded-corner"
                  type="primary"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    goToStepTwo();
                  }}
                >
                  Next
                </Button>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                    flexWrap: "wrap",
                  }}
                >
                  <Tooltip
                    title={
                      proposalSettings?.voting?.delay ? (
                        <div>
                          The Space enforces a delay until voting can start
                        </div>
                      ) : null
                    }
                  >
                    <DueDate
                      label="Start Date"
                      disabled={proposalSettings?.voting?.delay}
                      defaultValue={form.startDate}
                      onChange={(date, time) => {
                        if (date) {
                          const dateTime = moment(
                            date.format("YYYY-MM-DD") +
                              ` ${time ? time : "00:00 AM"}`
                          ).format("YYYY-MM-DD HH:mmZ");
                          setForm((prev) => ({
                            ...prev,
                            startDate: dateTime,
                          }));
                        } else
                          setForm((prev) => ({ ...prev, startDate: null }));
                      }}
                    />
                  </Tooltip>
                  Till
                  <Tooltip
                    title={
                      proposalSettings?.voting?.period ? (
                        <div>
                          The Space enforces the duration of the voting period
                        </div>
                      ) : null
                    }
                  >
                    <div>
                      <DueDate
                        label="End Date"
                        disabled={proposalSettings?.voting?.period}
                        defaultValue={form.endDate}
                        onChange={(date, time) => {
                          if (date) {
                            const dateTime = moment(
                              date.format("YYYY-MM-DD") +
                                ` ${time ? time : "00:00 AM"}`
                            ).format("YYYY-MM-DD HH:mmZ");
                            setForm((prev) => ({
                              ...prev,
                              endDate: dateTime,
                            }));
                          } else setForm((prev) => ({ ...prev, endDate: "" }));
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>

                <Button
                  className="rounded-corner publish-proposal"
                  type="primary"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    submitProposal();
                  }}
                  disabled={submitting}
                >
                  Publish
                </Button>
              </>
            )}
          </Footer>,
        ]}
      >
        <div
          style={{
            height: "500px",
            overflowY: "auto",
            marginLeft: "8px",
            marginRight: "20px",
          }}
        >
          {step === 1 ? (
            <>
              <Input
                style={{
                  fontSize: "21px",
                  fontWeight: "600",
                  marginBottom: "18px",
                }}
                placeholder="Add the title of your proposal here"
                size="large"
                value={form?.name}
                onChange={(e) => {
                  setForm((prev) => ({ ...prev, name: e.target.value }));
                }}
                bordered={false}
              />
              <InputField
                label={`DESCRIPTION (${
                  form.details ? form.details?.length : 0
                }/14,400)`}
                style={{ margin: "0px", border: "0px" }}
              >
                <UnstyledTextArea
                  value={form.details}
                  style={{ border: "0px", resize: "none", height: "400px" }}
                  onChange={(e) => {
                    if (e.target.value.length < 14400) {
                      setForm((prev) => ({ ...prev, details: e.target.value }));
                    }
                  }}
                  placeholder="Please return to add details here"
                />
              </InputField>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  font: "Inter",
                  fontWeight: "600",
                  fontSize: "20px",
                  gap: "8px",
                  marginLeft: "10px",
                  marginBottom: "20px",
                  lineHeight: "28px",
                }}
                onClick={() => {
                  setStep(1);
                }}
              >
                <svg
                  style={{ cursor: "pointer" }}
                  width="26"
                  height="28"
                  viewBox="0 0 26 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.5192 7.64551C16.7629 7.89867 16.8998 8.24198 16.8998 8.59996C16.8998 8.95793 16.7629 9.30124 16.5192 9.55441L12.2383 14L16.5192 18.4455C16.756 18.7001 16.887 19.0411 16.884 19.3951C16.8811 19.7491 16.7444 20.0877 16.5033 20.338C16.2623 20.5883 15.9362 20.7302 15.5954 20.7333C15.2545 20.7364 14.9261 20.6003 14.681 20.3544L9.48096 14.9544C9.23725 14.7012 9.10034 14.3579 9.10034 14C9.10034 13.642 9.23725 13.2987 9.48096 13.0455L14.681 7.64551C14.9248 7.39242 15.2554 7.25024 15.6001 7.25024C15.9448 7.25024 16.2754 7.39242 16.5192 7.64551V7.64551Z"
                    fill="#64748B"
                  />
                </svg>
                Voting
              </div>
              <Select
                disabled={proposalSettings?.voting?.type}
                style={{
                  marginLeft: "10px",
                  marginBottom: "20px",
                  width: "140px",
                }}
                placeholder="Voting Type"
                value={form.choice}
                onChange={(choice) => {
                  setForm((prev) => ({ ...prev, choice }));
                }}
              >
                {proposalType.map(({ label, value }) => (
                  <Select.Option value={value}>
                    <span>{label}</span>
                  </Select.Option>
                ))}
              </Select>
              {form.choice && form.choice !== "basic" && (
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  {choices.map((choice, idx) => (
                    <div style={{ display: "flex", gap: "8px" }}>
                      <InputField
                        style={{
                          border: "1px solid #E2E8F0",
                          borderRadius: "8px",
                          margin: 0,
                          height: "40px",
                          width: "100%",
                        }}
                        inputProps={{
                          autoFocus: true,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              setChoices((prev) => {
                                return [...prev, ""];
                              });
                            }
                          },
                        }}
                        value={choice}
                        onChange={(e) => {
                          setChoices((prev) => {
                            prev.splice(idx, 1, e.target.value);
                            return [...prev];
                          });
                        }}
                        placeholder={`Option ${idx + 1}`}
                      />
                      <HoverCard
                        style={{
                          border: "1px solid #E2E8F0",
                          borderRadius: "8px",
                          height: "40px",
                          width: "40px",
                          flexGrow: 0,
                          flexShrink: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          font: "Inter",
                          fontWeight: "400",
                          fontSize: "24px",
                          color: "#64748B",
                        }}
                        onClick={() => {
                          setChoices((prev) => {
                            prev.splice(idx + 1, 0, "");
                            return [...prev];
                          });
                        }}
                      >
                        +
                      </HoverCard>
                      {choices.length > 1 && (
                        <HoverCard
                          style={{
                            border: "1px solid #E2E8F0",
                            borderRadius: "8px",
                            height: "40px",
                            width: "40px",
                            flexGrow: 0,
                            flexShrink: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            font: "Inter",
                            fontWeight: "400",
                            fontSize: "24px",
                            color: "#64748B",
                          }}
                          onClick={() => {
                            setChoices((prev) => {
                              prev.splice(idx, 1);
                              return [...prev];
                            });
                          }}
                        >
                          -
                        </HoverCard>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
      <ErrorModal
        error={error}
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
        crispChatMessage="Hey, I am facing this error while creating a proposal:"
      />
    </StyledModalWrapper>
  );
};

export default CreateProposalModal;

import { Dropdown } from "antd";
import React, { useContext } from "react";
import { DaoContext } from "../../layouts/app/DaoContext";
import { getTrunckedString } from "../Proposal/ProposalCard";
import GreyHorizontalDot from "../../images/grey-horizontal-dot.svg";
import { UserPlusIcon } from "@heroicons/react/24/solid";

type Props = {
  onInvite: () => void;
  dropdownContent?: any;
  loading: boolean;
  isCurrentDaoJoined: boolean;
  isInviteButtonHidden?: boolean;
};

const SidebarHeader = ({
  dropdownContent,
  onInvite,
  isCurrentDaoJoined,
  isInviteButtonHidden = false,
  loading,
}: Props) => {
  const { daoDetails, parentDaoDetails } = useContext(DaoContext);
  return (
    <div className="rounded-2xl bg-white p-4 flex flex-col gap-4 mt-5">
      <div className="flex justify-between flex-nowrap">
        <div className="flex gap-2 items-center">
          {daoDetails?.iconEmoji ? (
            <div
              style={{ background: daoDetails?.iconBackground }}
              className="text-3xl w-12 h-12 rounded-full flex items-center justify-center mr-2"
            >
              {daoDetails?.iconEmoji}
            </div>
          ) : (
            <>
              {daoDetails.profilePicture && (
                <img
                  src={daoDetails.profilePicture}
                  className="w-12 h-12 rounded-full flex items-center justify-center object-cover mr-2"
                  alt="Profile"
                />
              )}
            </>
          )}
          <div className="flex flex-col gap-1">
            {parentDaoDetails?.daoName && (
              <span className="uppercase font-semibold text-xs text-gray-400">
                {getTrunckedString(parentDaoDetails?.daoName, 15)}
              </span>
            )}
            <span
              style={{ fontSize: "16px", fontWeight: "600" }}
              className="text-gray-800"
            >
              {getTrunckedString(daoDetails?.daoName, 15)}
            </span>
          </div>
        </div>
        {!loading && isCurrentDaoJoined && dropdownContent && (
          <Dropdown overlay={dropdownContent} trigger={["click"]}>
            <img
              src={GreyHorizontalDot}
              alt="dot icon"
              className="cursor-pointer w-4 flex-shrink-0"
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </Dropdown>
        )}
      </div>
      {!loading && isCurrentDaoJoined && !isInviteButtonHidden && (
        <button
          onClick={onInvite}
          className="border-none text-blue-500 bg-blue-50 rounded-xl flex items-center justify-center gap-2 py-2 hover:bg-blue-100 active:bg-blue-200 cursor-pointer"
        >
          <UserPlusIcon className="w-4" /> Invite Members
        </button>
      )}
    </div>
  );
};

export default SidebarHeader;

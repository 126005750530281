import React from "react";
import { Navigate, Route, Routes } from "react-router";
import LoginModal from "../../features/login/Components/LoginModal";

function AuthLayout() {
  return (
    <Routes>
      <Route path="login" element={<LoginModal />} />
      <Route path="*" element={<Navigate to="login" />} />
    </Routes>
  );
}

export default AuthLayout;

import React, { useEffect, useRef, useState } from "react";
import customAxios from "../../api";
import { getProfileDetails } from "../../components/Profile/Profile";
import { useLocation, useNavigate } from "react-router";
import mixpanel from "mixpanel-browser";
import { Crisp } from "crisp-sdk-web";
import { UNAUTH_JOIN_COMMUNITY_DETAILS } from "../../constants";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";

export const UserContext = React.createContext({
  update: true,
  reload: () => {},
  showLoginModal: () => {},
  setMobileSidebarOpen: (open) => {},
  userDetails: undefined,
  loading: false,
  logout: (redirectTo = "") => { },
});

const UserContextProvider = ({ children }) => {
  const [update, setUpdate] = useState(true);
  const [userDetails, setUserDetails] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [walkMeStatus, setWalkMeStatus] = useState(null);
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const sidebarContainerRef = useRef(null);
  const location = useLocation();
  const navigate = useDaoLensNavigate();

  useEffect(() => {
    const logout = () => {
      setUserDetails(undefined);
      console.log("logout1");
      if (!localStorage.getItem("access_token")) return;
      localStorage.removeItem("access_token");
      localStorage.removeItem(UNAUTH_JOIN_COMMUNITY_DETAILS);
      mixpanel.reset();
      Crisp?.session?.reset();
      navigate("/app/discovery");
      window.location.reload();
    };
    setLoading(true);

    getProfileDetails()
      .then((data) => {
        if (data) {
          setUserDetails(data);
        } else logout();
      })
      .catch((error) => {
        console.error(error);
        logout();
      })
      .finally(() => setLoading(false));
  }, [update]);
  function clickAwayHandler(ev) {
    if (sidebarContainerRef.current !== null) {
      let sidebarClientRect =
        sidebarContainerRef?.current?.getBoundingClientRect();
      if (
        ev.x > sidebarClientRect.width &&
        ev.y < sidebarClientRect.height - 72
      )
        // ! clicked anywhere outside the sidebar components
        setMobileSidebarOpen(false);
    }
  }
  useEffect(() => {
    if (sidebarRef?.current && sidebarContainerRef?.current) {
      if (mobileSidebarOpen) {
        // ! is open
        window.addEventListener("click", clickAwayHandler);
        sidebarRef.current.style.transform = "translate(-0%,-15px)";
        sidebarContainerRef.current.style.zIndex = 15;
      } else {
        // ! is closed
        window.removeEventListener("click", clickAwayHandler);
        sidebarRef.current.style.transform = "translate(-100%,-15px)";
        sidebarContainerRef.current.style.zIndex = 1;
      }
    }
  }, [mobileSidebarOpen]);

  const reload = () => {
    setUpdate((prev) => !prev);
  };
  const showLoginModal = () => {
    navigate("/auth/login", {
      state: {
        from: location.pathname + location.search,
      },
    });
  };

  const logout = (redirectTo = "/app/discovery") => {
    setUserDetails(undefined);
    console.log("logout2");

    if (!localStorage.getItem("access_token")) return;
    localStorage.removeItem("access_token");
    localStorage.removeItem(UNAUTH_JOIN_COMMUNITY_DETAILS);
    mixpanel.reset();
    Crisp?.session?.reset();
    navigate(redirectTo);
    window.location.reload();
  };

  return (
    <UserContext.Provider
      value={{
        update,
        reload,
        userDetails,
        loading,
        walkMeStatus,
        mobileSidebarOpen,
        setMobileSidebarOpen,
        sidebarRef,
        sidebarContainerRef,
        showLoginModal,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

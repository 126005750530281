import { Avatar, Select, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import customAxios from "../../api";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/common/chevrondown.svg";
import { StyledCard, StyledProfileDetails } from "../BountyFeed";
import { getPostTime } from "../Feed";
import { GPTButton } from "./GPT";

const { Option } = Select;
const { Title, Paragraph, Text } = Typography;

const StyledSelect = styled(Select)``;
interface ComparePostProps {
  firstPostId?: string;
  setFirstPostId: (val: string) => void;
  secondPostId?: string;
  setSecondPostId: (val: string) => void;
  loading?: boolean;
  posts: any[];
  result: string;
}
const ComparePost = ({
  firstPostId,
  setFirstPostId,
  secondPostId,
  setSecondPostId,
  loading,
  posts,
  result,
}: ComparePostProps) => {
  return (
    <>
      <div className="border border-gray-200 border-solid  rounded-xl relative flex justify-center flex-col items-center mb-3 overflow-x-hidden">
        {loading && (
          <Skeleton.Button active style={{ width: "1000px", height: "80px" }} />
        )}
        {!loading && (
          <>
            <StyledSelect
              style={{
                font: "normal 500 12px/16px Inter",
                color: "#1F2937",
              }}
              dropdownStyle={{ borderRadius: "12px" }}
              bordered={false}
              size="large"
              className="w-full"
              optionLabelProp="label"
              showSearch
              value={firstPostId}
              onChange={(key: any) => {
                setFirstPostId(key);
              }}
              placeholder="Choose Post 1"
              suffixIcon={<ChevronDownIcon style={{ cursor: "pointer" }} />}
              filterOption={(input, option) => {
                return option?.label
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {posts
                .filter((post) => post.postId !== secondPostId)
                .map((post) => (
                  <Option
                    key={post.postId}
                    value={post.postId}
                    label={post.postName}
                  >
                    <div
                      style={{
                        color: "#64748B",
                        marginBottom: "6px",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px",
                          alignItems: "center",
                        }}
                      >
                        <StyledProfileDetails
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {post?.userProfilePicture &&
                            post.userProfilePicture !== null && (
                              <img
                                src={post.userProfilePicture}
                                style={{
                                  marginRight: "10px",
                                  width: "16px",
                                  aspectRatio: "1",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          {post?.userName && (
                            <>
                              <Text
                                style={{
                                  color: "inherit",
                                  fontWeight: 600,
                                  fontSize: 12,
                                }}
                              >
                                {post.userName}
                              </Text>
                              <span style={{ margin: "0 4px" }}>&#8226;</span>
                            </>
                          )}
                        </StyledProfileDetails>
                        <Text
                          style={{
                            color: "#64748B",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          {getPostTime(post?.createdAt)}
                        </Text>
                      </div>
                    </div>

                    <Typography.Title
                      level={5}
                      ellipsis={{
                        rows: 2,
                      }}
                    >
                      {post?.postName}
                    </Typography.Title>
                  </Option>
                ))}
            </StyledSelect>
            <div className="h-px w-full bg-gray-200" />
            <div className="absolute bg-gray-100 text-gray-500 px-2 py-0.5 rounded-lg text-xs font-medium leading-4">
              VS
            </div>
            <StyledSelect
              style={{
                font: "normal 500 12px/16px Inter",
                color: "#1F2937",
              }}
              bordered={false}
              size="large"
              className="w-full"
              dropdownClassName="text-gray-200"
              optionLabelProp="label"
              showSearch
              value={secondPostId}
              onChange={(key: any) => {
                setSecondPostId(key);
              }}
              placeholder="Choose Post 2"
              suffixIcon={<ChevronDownIcon style={{ cursor: "pointer" }} />}
            >
              {posts
                .filter((post) => post.postId !== firstPostId)
                .map((post) => (
                  <Option
                    key={post.postId}
                    value={post.postId}
                    label={post.postName}
                  >
                    <div
                      style={{
                        color: "#64748B",
                        marginBottom: "6px",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px",
                          alignItems: "center",
                        }}
                      >
                        <StyledProfileDetails
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {post?.userProfilePicture &&
                            post.userProfilePicture !== null && (
                              <img
                                src={post.userProfilePicture}
                                style={{
                                  marginRight: "10px",
                                  width: "16px",
                                  aspectRatio: "1",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          {post?.userName && (
                            <>
                              <Text
                                style={{
                                  color: "inherit",
                                  fontWeight: 600,
                                  fontSize: 12,
                                }}
                              >
                                {post.userName}
                              </Text>
                              <span style={{ margin: "0 4px" }}>&#8226;</span>
                            </>
                          )}
                        </StyledProfileDetails>
                        <Text
                          style={{
                            color: "#64748B",
                            fontWeight: 600,
                            fontSize: "12px",
                          }}
                        >
                          {getPostTime(post?.createdAt)}
                        </Text>
                      </div>
                    </div>

                    <Typography.Title
                      level={5}
                      ellipsis={{
                        rows: 2,
                      }}
                    >
                      {post?.postName}
                    </Typography.Title>
                  </Option>
                ))}
            </StyledSelect>
          </>
        )}
      </div>{" "}
      <div
        style={{
          display: "flex",
          gap: "8px",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <GPTButton className="mb-2" disabled={!firstPostId || !secondPostId}>
          Compare Posts
        </GPTButton>
      </div>
    </>
  );
};

export default ComparePost;

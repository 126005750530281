import { CloseCircleFilled } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { Feild } from "../../styles/Feild";
import { PopupCard } from "../../styles/PopupCard";
import MintNftModal from "../Common/MintNfModal";
import { ReactComponent as NFT } from "./../../assets/images/gating/nft.svg";
const AddBountyBtnWrapper = styled.div`
  & .btn-addBounty {
    border: 1px dashed #94a3b8;
    background: transparent;
    padding: 5px 12px;
    border-radius: 8px;
    color: #64748b;
    font-size: 12px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    align-self: center;
    span {
      white-space: nowrap;
    }
    &:hover {
      border: 1px dashed #1890ff;
      cursor: pointer;
      color: #1890ff;
    }
  }

  & .diamond-image {
    width: 1rem;
    aspect-ratio: 1;
  }
`;

function AddNftReward({ nftDetails, setNftDetails, edit }) {
  const [shownNftModal, setShownNftModal] = React.useState(false);
  return (
    <PopupCard
      style={{ display: "flex", gap: "8px" }}
      cardPadding="0px"
      bottom="unset"
      margin="2px 50px"
      width={"280px"}
    >
      {shownNftModal ? (
        <MintNftModal
          isVisible={shownNftModal}
          setState={setNftDetails}
          state={nftDetails}
          handleClose={() => setShownNftModal(false)}
        />
      ) : null}
      {nftDetails.image ? (
        <Tooltip
          title={edit && "NFT details cannot be edited."}
          color="blue"
          overlayStyle={{ borderRadius: "12px" }}
        >
          <Feild
            style={{
              display: "inline-flex",
              background: "#FFF7ED",
              color: "#7C2D12",
              position: "relative",
            }}
            key={"nft"}
            onClick={() => {
              if (!edit) setShownNftModal(true);
            }}
          >
            {!edit && (
              <Button
                style={{
                  border: "none",
                  background: "none",
                  right: "-8px",
                  top: "-8px",
                  position: "absolute",
                }}
                shape="circle"
                size="small"
                icon={<CloseCircleFilled />}
                onClick={(e) => {
                  setNftDetails({});
                  e.stopPropagation();
                }}
              />
            )}
            <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
              <NFT
                style={{ width: "18px" }}
                className=" text-[#F97316] h-4 w-4 stroke-2"
              />
              <span style={{ fontSize: "12px" }}>NFT</span>
            </div>
          </Feild>
        </Tooltip>
      ) : (
        <AddBountyBtnWrapper>
          <button
            className="btn-addBounty"
            onClick={() => setShownNftModal(true)}
          >
            <span style={{ fontSize: "14px" }}> + NFT</span>
          </button>
        </AddBountyBtnWrapper>
      )}
    </PopupCard>
  );
}

export default AddNftReward;

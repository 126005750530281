import { useState } from "react";
import { StyledButtonContainer } from "../Feed";
import ShareButtonModal from "../ShareButtonModal";
import ShareOutline from "../../images/ShareOutline.svg";

const Share = ({ entity, style }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ShareButtonModal
        handleCancel={() => {
          setIsModalOpen(false);
        }}
        isModalOpen={isModalOpen}
        entity={entity}
      />
      <StyledButtonContainer
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          setIsModalOpen(true);
        }}
      >
        <img
          src={ShareOutline}
          alt="open comment section"
          style={{ width: "20px", height: "20px" }}
        />
      </StyledButtonContainer>
    </>
  );
};

export default Share;

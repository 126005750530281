export async function insertTextAtCaretPosition(
  text: string,
  inputRef: any,
  onSubmit: any
) {
  const selectionStart = inputRef.current.input.selectionStart;
  const selectionEnd = inputRef.current.input.selectionEnd;
  let value = inputRef.current.input.value;
  if (value.charAt(selectionStart - 1) === "/") {
    value =
      value.slice(0, selectionStart - 1) +
      "" +
      value.slice(selectionStart - 1 + 1);
  }
  const newValue =
    value.slice(0, selectionStart) + text + value.slice(selectionEnd);

  const newSelectionStart = selectionStart + text.length;
  inputRef.current.value = newValue;
  await onSubmit(newValue);
  inputRef.current.setSelectionRange(newSelectionStart, newSelectionStart);
}
export const suggestions = [
  "Suggest New Post Ideas",
  "Combine and merge last 5 discussions in a different tone",
  "Combine and merge last 10 discussions in a different tone",
];

export const parser = (html: any) => {
  const tmpElement = document.createElement("div");
  tmpElement.innerHTML = html.trim();

  const list: string[] = [];
  const h3 = tmpElement?.querySelector("h3")?.innerHTML;
  const paragraph = tmpElement?.querySelector("p")?.innerHTML;
  const h4 = tmpElement?.querySelector("h4")?.innerHTML;

  const unorderedList = tmpElement.querySelector("ul");
  const items = unorderedList?.querySelectorAll("li");

  tmpElement
    .querySelector("ol")
    ?.querySelectorAll("li")
    .forEach((item) => list.push(item.innerText));
  items?.forEach((item) => list.push(item.innerText));
  return { list, h4, h3, paragraph };
};

export function parsePoll(htmlString: any) {
  let regex = /<li>(.*?)<\/li>/gs;
  const liMatches = htmlString.matchAll(regex);
  const choices = [];

  for (const match of liMatches) {
    choices.push(match[1]);
  }

  regex = /<q>(.*?)<\/q>/gs;
  const question = htmlString.match(regex);

  regex = /<title>(.*?)<\/title>/gs;
  const title = htmlString.match(regex);

  regex = /<summary>(.*?)<\/summary>/gs;
  const summary = htmlString.match(regex);

  return {
    choices,
    question: question ? question[0] : "",
    title: title ? title[0] : "",
    summary: summary ? summary[0] : "",
  };
}

import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { Button } from "antd";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import { DaoContext } from "../../../layouts/app/DaoContext";
import ReactHtmlParser from "react-html-parser";
import { iconStyling } from "../../Settings/DaoProfile/DaoProfile";
import DiscordIcon from "../../../assets/images/profile/Subtract.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import { StyledParagraph } from "../../PostDetails";
import { PencilIcon } from "@heroicons/react/24/solid";

type Props = {
  canEdit: boolean;
  setShowAboutEditor: Dispatch<SetStateAction<boolean>>;
  isOnlyAboutSection?: boolean;
};

const About = ({
  canEdit,
  setShowAboutEditor,
  isOnlyAboutSection = false,
}: Props) => {
  const { isMobile } = useWindowSize();
  const { daoDetails } = useContext(DaoContext);
  const about = (daoDetails as any)?.description;
  const isViewMoreButtonRequired = about?.length > 500 && !isOnlyAboutSection;
  const [isViewMore, setIsViewMore] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "white",
        padding: "24px 20px",
        borderRadius: "16px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>About</h3>
        {canEdit && (
          <Button
            icon={<PencilIcon fill="#6B7280"  width={12} />}
            style={{ borderRadius: "8px" }}
            onClick={() => setShowAboutEditor(true)}
          />
        )}
      </div>
      <StyledParagraph
        style={{
          marginBottom: "0px",
          width: "100%",
          wordBreak: "break-word",
          overflow: "hidden",
          height: !isViewMoreButtonRequired || isViewMore ? "auto" : "120px",
        }}
        className="about-view-section"
      >
        {about?.length ? ReactHtmlParser(about) : "No description added"}
      </StyledParagraph>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: !(isViewMore || !isViewMoreButtonRequired)
            ? "10px"
            : undefined,
          marginTop: !(isViewMore || !isViewMoreButtonRequired)
            ? "-35px"
            : undefined,
          background:
            "linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 46.43%, rgba(255, 255, 255, 0.4) 114.29%)",
        }}
      >
        {isViewMoreButtonRequired && (
          <Button
            onClick={() => setIsViewMore((prev) => !prev)}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
            }}
            shape="round"
          >
            {!isViewMore ? "View more" : "View less"}
            {!isViewMore ? (
              <ChevronDownIcon width={10} strokeWidth={2} />
            ) : (
              <ChevronUpIcon width={10} strokeWidth={2} />
            )}
          </Button>
        )}
      </div>
      <div
        style={{
          display: isMobile ? "none" : "flex",
          alignItems: "center",
          gap: "12px",
          marginTop: !isViewMore ? "10px" : "",
        }}
      >
        {(daoDetails as any)?.twitterHandle && (
          <a
            href={(daoDetails as any)?.twitterHandle}
            target="_blank"
            rel="noreferrer"
          >
            <i
              className="fa-brands fa-twitter fa-regular"
              style={{ ...iconStyling, background: "#629DFF" }}
            ></i>
          </a>
        )}

        {(daoDetails as any)?.discordUrl && (
          <a
            href={(daoDetails as any)?.discordUrl}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={DiscordIcon}
              alt="discord icon"
              style={{ ...iconStyling }}
            />
          </a>
        )}

        {(daoDetails as any)?.github && (
          <a
            href={(daoDetails as any)?.github}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <i
              className="fa-brands fa-github fa-regular"
              style={{ ...iconStyling, background: "black" }}
            ></i>
          </a>
        )}

        {(daoDetails as any)?.website && (
          <a
            href={(daoDetails as any)?.website}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <i
              className=" fa-globe fa-solid"
              style={{ ...iconStyling, background: "red" }}
            ></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default About;

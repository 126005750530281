import styled from "styled-components";

export const Feild = styled.div`
  height: 36px;
  line-height: 34px;
  border-radius: 0.4rem;
  padding: 0px 10px;
  &: hover {
    cursor: pointer;
    background: #eee;
  }
`;

import customAxios from "../api";

type FetchProposalsOptions = {
  daoId: string;
  offset?: number;
  status: string;
};

type Proposal = {
  id: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  attachments: null;
  startDate: string;
  endDate: string;
  votingType: string;
  choices: string[];
  daoId: string;
  snapProposalId: string;
  creatorAddress: string;
  userId: string;
  space: string;
  status: string;
  scores: number[];
  pinned: false;
  userName: string;
  profilePicture: string;
  userVote: any[];
  totalVotes: number;
  blockNo: string;
  votingPower: number;
};

export const PROPOSAL_PAGINATION_LIMIT = 2;
export const fetchProposals = async ({
  daoId,
  status,
  offset = 0,
}: FetchProposalsOptions) => {
  const result = await customAxios("/proposals/fetch", {
    params: { daoId, status, offset, limit: PROPOSAL_PAGINATION_LIMIT },
  });

  const data: { res: Proposal[]; offset: number } = result?.data;
  return data;
};

import {
  MagnifyingGlassIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { Button, Card, Input, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PopupCard } from "../../styles/PopupCard";
import { Feild } from "../../styles/Feild";
import IconWrapper from "../IconWrapper";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import emptyUser from "../../assets/iconSvg/emptyUser.svg";
import { LabelIcon } from "../../styles/LabelIcon";
import { CloseCircleFilled } from "@ant-design/icons";
import customAxios from "../../api";
import { useParams } from "react-router";
import { useDaoContext } from "../../layouts/app/DaoContext";

const SearchBar = styled(Input)`
  border-radius: 12px;
  margin: 0px 12px;
  width: 95%;
`;

// const users = ["User One", "User Two", "User Three", "User Four", "User Five"];

const ReviewerSelector = ({
  reviewer,
  setReviewer,
  setUpdatedFieldsNames = () => {},
  pillBg = "#fff",
}) => {
  const [showCard, setShowCard] = React.useState(false);
  const ref = React.useRef();
  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [query, setQuery] = useState("");
  useEffect(() => {
    setFilterUsers(
      users.filter((ele) =>
        ele.userName?.toLowerCase().includes(query.toLowerCase())
      )
    );
  }, [query, users]);
  // Call hook passing in the ref and a function to call on outside click

  useOnClickOutside(ref, () => setShowCard(false));
  const { daoDetails, daoId } = useDaoContext();
  
  const fetchUsers = async () => {
    try {
      const res = await customAxios.get(`/bounty/reviewers?daoId=${daoId}`);
      const usersFromDb = await res?.data?.reviewers;
      setUsers([...usersFromDb]);
    } catch (error) {
      // throw new Error(error.message ?? error);
      console.log(error);
    }
  };
  useEffect(() => {
    if (!daoId) return;
    fetchUsers();
  }, [daoId]);

  return (
    <PopupCard width="240px">
      {showCard ? (
        <>
          <Card className="card" ref={ref} bodyStyle={{ padding: "10px 12px" }}>
            <Input
              placeholder="Search Reviewers"
              value={query}
              autoFocus={true}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              style={{
                minHeight: "32px",
                borderRadius: "12px",
                margin: "4px 0px",
              }}
              prefix={
                <IconWrapper>
                  <MagnifyingGlassIcon />
                </IconWrapper>
              }
            />{" "}
            <Space
              direction="vertical"
              style={{
                gap: "4px",
                rowGap: "4px",
                columnGap: "4px",
                maxHeight: "250px",
                overflowY: "auto",
                minHeight: "250px",
                width: "100%",
              }}
            >
              {!filterUsers.length ? (
                <h4 style={{ textAlign: "center" }}>No users found</h4>
              ) : null}
              {filterUsers.map((user) => (
                <Feild
                  key={user}
                  onClick={() => {
                    setUpdatedFieldsNames();
                    setReviewer(user);
                    setShowCard(false);
                  }}
                >
                  <img
                    style={{
                      width: "22px",
                      borderRadius: "50%",
                      height: "22px",
                      objectFit: "cover",
                    }}
                    src={
                      user?.profilePicture ? user?.profilePicture : dummyUser
                    }
                    alt="icon"
                  />{" "}
                  <span>
                    {user?.userName?.slice(0, 16)}
                    {user?.userName?.length > 16 ? "..." : null}
                  </span>
                </Feild>
              ))}
            </Space>
          </Card>
          {reviewer ? (
            <LabelIcon
              onClick={() => {
                setShowCard(true);
              }}
              height={"24px"}
              padding="0px"
            >
              <>
                <Tooltip title={reviewer?.userName} placement="left">
                  <img
                    src={
                      reviewer.profilePicture
                        ? reviewer.profilePicture
                        : dummyUser
                    }
                    alt="icon"
                    height={"20px"}
                    style={{
                      width: "22px",
                      borderRadius: "50%",
                      height: "22px",
                      objectFit: "cover",
                    }}
                  />
                </Tooltip>
                <Button
                  className="borderless-button"
                  shape="circle"
                  size="small"
                  icon={<CloseCircleFilled />}
                  onClick={() => {
                    setUpdatedFieldsNames();
                    setReviewer(null);
                    setShowCard(false);
                  }}
                />
              </>
            </LabelIcon>
          ) : (
            <Button
              className="borderless-button"
              shape="circle"
              onClick={() => {
                setShowCard(true);
              }}
              style={{
                margin: "auto 0",
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
                height: "100%",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  color: "#64748B",
                  margin: "auto 0",
                }}
              >
                Reviewers*
              </span>
            </Button>
          )}
        </>
      ) : reviewer ? (
        <LabelIcon
          style={{ background: pillBg, borderRadius: "0px" }}
          onClick={() => {
            setShowCard(true);
          }}
          height={"24px"}
          padding="0px"
        >
          <>
            <Tooltip title={reviewer?.userName} placement="left">
              <img
                src={
                  reviewer.profilePicture ? reviewer.profilePicture : dummyUser
                }
                alt="icon"
                height={"20px"}
                style={{
                  width: "22px",
                  borderRadius: "50%",
                  height: "22px",
                  objectFit: "cover",
                }}
              />
            </Tooltip>

            <Button
              className="borderless-button"
              shape="circle"
              size="small"
              icon={<CloseCircleFilled />}
              onClick={() => {
                setUpdatedFieldsNames();
                setReviewer(null);
                //   setShowCard(false);
              }}
            />
          </>
        </LabelIcon>
      ) : (
        <Button
          onClick={() => {
            setShowCard(true);
          }}
          className="borderless-button"
          shape="circle"
          style={{
            margin: "auto 0",
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            height: "100%",
          }}
        >
          <span
            style={{
              fontWeight: 500,
              color: "#64748B",
              margin: "auto 0",
            }}
          >
            Reviewers*
          </span>
        </Button>
      )}
    </PopupCard>
  );
};

export default ReviewerSelector;

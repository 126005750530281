import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Select } from "antd";
import React from "react";
import {
  PROJECT_STATUSES,
  PROJECT_TABS,
  PROJECT_TASK_STATUSES,
} from "../../../constants";
import {
  StyledBountyTitle,
} from "../../BountyComponents/CreateBountySubtaskModal";

const StatusPill = ({ status, updateStatus, disabled = true }) => {
  const statuses = PROJECT_TASK_STATUSES;

  const handleStatusChangeOnClick = (e) => {
    e.stopPropagation();
    const indexOfStatus = statuses.findIndex((ele) => ele === status);
    updateStatus(
      statuses[indexOfStatus === statuses.length - 1 ? 0 : indexOfStatus + 1]
    );
  };


  return (
    <StyledBountyTitle status={status}>
      <Select
        defaultValue="TODO"
        style={{ width: "130px" }}
        onChange={(e) => updateStatus(e)}
        value={status}
        onClick={(e) => e.stopPropagation()}
        disabled={disabled}
      >
        {statuses.map((currStatus) => (
          <Select.Option value={currStatus} key={currStatus}>
            {PROJECT_TABS?.[currStatus]?.label}
          </Select.Option>
        ))}
      </Select>

      <button
        onClick={handleStatusChangeOnClick}
        className="arrow-right"
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          opacity: disabled && "50%",
        }}
        disabled={disabled}
      >
        {status === PROJECT_STATUSES.completed ? (
          <ArrowLeftIcon height="1rem" width="1rem" />
        ) : (
          <ArrowRightIcon height="1rem" width="1rem" />
        )}
      </button>
    </StyledBountyTitle>
  );
};

export default StatusPill;

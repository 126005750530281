import { InfoCircleFilled } from "@ant-design/icons";
import { Button, Input, Row } from "antd";
import React, { useState } from "react";
import { toast } from "react-toastify";
import useWindowSize from "../../../hooks/useWindowSize";
import { Modal } from "../../../styles/ModalStyles";
import { checkFileSize, checkImageType } from "../../../utils";
import IconWrapper from "../../IconWrapper";
import Upload from "../form/Upload";
import { ReactComponent as PolygonIcon } from "./../../../assets/images/polygonIcon.svg";
function MintNftModal({ state, setState, isVisible, handleClose }) {
  const { isMobile } = useWindowSize();
  const [isUpdated, setIsUpdated] = useState(false);
  return (
    <Modal
      onCancel={handleClose}
      visible={isVisible}
      height="auto"
      isMobile={isMobile}
      title={<h2 style={{ paddingLeft: "8px" }}>NFT Reward</h2>}
      footer={
        <Row
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "4px",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <IconWrapper>
              <PolygonIcon />
            </IconWrapper>
            <h5 style={{ marginBottom: "0px", color: "#6B7280" }}>
              {" "}
              Minted on Polygon (Gas fee is on us)
            </h5>
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <Button
              style={{ borderRadius: "12px" }}
              type="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{ borderRadius: "12px" }}
              type="primary"
              onClick={() => {
                if (!state.nftName)
                  return toast.error("Please enter the name of your NFT");
                if (!state.nftDescription)
                  return toast.error(
                    "Please enter the description of your NFT"
                  );
                if (!state.image)
                  return toast.error("Please add the image for your NFT");

                handleClose();
              }}
            >
              Done
            </Button>
          </div>
        </Row>
      }
    >
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div
          style={{
            color: " #64748B",
            display: "flex",
            gap: "20px",
            flexDirection: isMobile ? "column-reverse" : "row",
          }}
        >
          <Upload
            label="NFT Image"
            isUpdated={true}
            setIsUpdated={setIsUpdated}
            data={state.image}
            onChange={(newFile) => {
              checkImageType(newFile.type);
              checkFileSize(newFile.size);
              setState((prev) => ({ ...prev, image: newFile }));
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              // width: "202px",
              font: "normal 600 12px/16px Inter",
              width: "100%",
            }}
          >
            Title
            <Input
              value={state.nftName}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  nftName: e.target.value,
                }));
              }}
              placeholder="Name of the NFT"
              style={{
                borderRadius: "16px",

                resize: "none",
                padding: "12px 16px",
              }}
            />
            Description
            <Input.TextArea
              value={state.nftDescription}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  nftDescription: e.target.value,
                }));
              }}
              placeholder="Add memorable details for the task winner"
              style={{
                borderRadius: "16px",
                height: "100%",
                resize: "none",
                padding: "12px 16px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            padding: "12px 16px",
            background: "#EFF6FF",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            columnGap: "12px",
            border: "1px solid #3B82F6",
          }}
        >
          <IconWrapper>
            <InfoCircleFilled style={{ fontSize: "16px", color: "#3B82F6" }} />
          </IconWrapper>
          <h5
            style={{
              color: "#3B82F6",
              lineHeight: "16px",
              marginBottom: "0px",
            }}
          >
            Please double-check before adding your NFT as it cannot be edited
            once added
          </h5>
        </div>
      </div>
    </Modal>
  );
}

export default MintNftModal;

import React from "react";
import SearchCard from "../SearchCard";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import { Button } from "antd";
import IconWrapper from "../../IconWrapper";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { MegaphoneIcon } from "@heroicons/react/24/outline";

type AnnouncementType = {
  id: string;
  type: "ANNOUNCEMENT";
  title: string;
  description: string;
  created_at: string;
  user_id: string;
  user_name: string;
  user_profile_picture: string;
  name: string;
  dao_name: string;
  dao_id: string;
};

type Props = {
  handleClose: () => void;
  onClick: (data: any) => void;
  handleDeleteLinkedItem?: (id: string) => void;
  data: AnnouncementType;
};

const Announcement = ({
  data,
  handleClose,
  handleDeleteLinkedItem,
  onClick,
}: Props) => {
  const navigate = useDaoLensNavigate();
  const { dao_name, dao_id, id, user_id, user_name } = data;
  const bountyTitle = data?.title.substring(0, 56);

  return (
    <SearchCard
      isLinkedItem={!!handleDeleteLinkedItem}
      onClick={() => {
        if (onClick) {
          if (handleDeleteLinkedItem) {
            return;
          } else onClick({ data });
        } else {
          navigate(
            `/app/dao/${dao_name}/${dao_id}/overview/announcements/${id}`
          );
          handleClose();
        }
      }}
    >
      <MegaphoneIcon className="w-6 text-purple-500" />
      {handleDeleteLinkedItem ? (
        <Button
          className="borderless-button"
          type="text"
          shape="circle"
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
          icon={
            <IconWrapper width={"initial"}>
              <CloseCircleOutlined />
            </IconWrapper>
          }
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteLinkedItem(data.id);
          }}
        ></Button>
      ) : null}
      <div
        style={{
          fontWeight: 500,
          lineHeight: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "#64748B",
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            marginBottom: "4px",
          }}
        >
          <div
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            Announcement
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            {dao_name && (
              <>
                <div
                  style={{ display: "inline-block" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    navigate(`/app/dao/${dao_name}/${dao_id}`);
                  }}
                >
                  {dao_name}
                </div>
                <span style={{ margin: "0 8px" }}>&#8226;</span>
              </>
            )}
            {user_id && (
              <>
                <div
                  style={{ display: "inline-block" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClose();
                    navigate(`/app/profile/${user_id}`);
                  }}
                >
                  {user_name}
                </div>
                <span style={{ margin: "0 8px" }}>&#8226;</span>
              </>
            )}
            {moment(data?.created_at).format("DD MMM")}
          </div>
        </div>

        {bountyTitle}
        {data?.title.length > 56 ? "..." : ""}
      </div>
    </SearchCard>
  );
};

export default Announcement;

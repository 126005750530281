import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import customAxios from "../api";
import { useWalletSelector } from "../layouts/app/NearWalletSelectorContext";
import * as nearAPI from "near-api-js";
import { KeyPair } from "near-api-js";
import axios from "axios";
import { toast } from "react-toastify";

const useNear = (onSuccess, onSign, onError, isNearConnected) => {
  const { pathname } = useLocation();
  const { accountId, selector, modal, signOut ,walletId} = useWalletSelector();


  useEffect(() => {
    if (accountId && onSuccess && !isNearConnected) {
      (async () => {
        const isSignedin = await selector.isSignedIn();

        if (!isSignedin) {
          return;
        }
        const nonceData = await customAxios.post(
          `auth/${pathname.includes("app/profile") ? "connect/" : ""}near`,
          {
            publicAddress: accountId,
          }
        );

        try {

          let keystore = new nearAPI.keyStores.BrowserLocalStorageKeyStore();
          if(walletId === "sender") {
            let acceesKeys =  window?.near?.authData?.accessKey;
            let keys = KeyPair.fromString(acceesKeys.secretKey);
            await keystore.setKey("mainnet", accountId,keys)
          }
          const keyPair = await keystore.getKey("mainnet", accountId);
          let messgae = new TextEncoder().encode(`I am signing my one-time nonce: ${nonceData.data.message}`);
          const { signature } = keyPair.sign(messgae);
          let pubKey = keyPair.getPublicKey().toString();

         setTimeout(async ()=>{
          let response = await customAxios.post(
            `auth/${pathname.includes("app/profile") ? "connect/" : ""
            }near/login`,
            {
              token: "Bearer " + nonceData.data.token,
              signature: signature.toString(),
              publicKey: pubKey,
            }
          );

          onSuccess(response.data.access_token, response.data.newUser);
         },walletId === "sender"? 2000:0);
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong")
          onError();
          signOut();
        }
      })();
    }
  }, [accountId]);

  return { modal, signOut };
};

export default useNear;

import { Typography } from "antd";
import React, { useState } from "react";
import { ReactComponent as PDFIcon } from "../assets/images/attachment/pdf.svg";
import { ReactComponent as DOCXIcon } from "../assets/images/attachment/docx.svg";
import { ReactComponent as FolderIcon } from "../assets/images/attachment/folder.svg";
import { ReactComponent as VideoIcon } from "../assets/images/attachment/video.svg";

let types = {
  // image: {
  //   extensions: ["jpeg"],
  // },
  pdf: {
    extensions: ["pdf"],
    icon: (isSelected) => <PDFIcon width={isSelected ? "20px" : "40px"} />,
  },
  document: {
    extensions: ["docx", "txt"],
    icon: (isSelected) => <DOCXIcon width={isSelected ? "20px" : "40px"} />,
  },
  video: {
    extensions: ["mp4", "mov"],
    icon: (isSelected) => <VideoIcon width={isSelected ? "20px" : "40px"} />,
  },
  folder: {
    extensions: ["zip", "rar"],
    icon: (isSelected) => <FolderIcon width={isSelected ? "20px" : "40px"} />,
  },
};

const getFileType = (extension) => {
  return Object.entries(types).find(([key, files]) =>
    files.extensions.includes(extension)
  );
};

const Attachment = ({ files }) => {
  const [currentLink, setCurrentLink] = useState();
  if (!files || files.length === 0) {
    return null;
  }
  return (
    <div
      style={{
        marginBottom: "1em",
        marginTop: "1em",
        display: "flex",
        gap: "20px",
        flexWrap: "wrap",
      }}
    >
      {files.map((link) => {
        let type = getFileType(link.split(".").at(-1));

        return (
          <Typography.Link href={link} target="_blank">
            {type ? (
              <div
                onMouseEnter={() => {
                  setCurrentLink(link);
                }}
                onMouseLeave={() => {
                  setCurrentLink(undefined);
                }}
                style={{
                  width: "120px",
                  height: "120px",
                  objectFit: "cover",
                  borderRadius: "12px",
                  background: "#F3F4F6",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: link === currentLink ? "" : "flex-end",
                  alignItems: link === currentLink ? "" : "center",
                  padding: link === currentLink ? "2px 10px" : "",
                }}
              >
                {type[1].icon(link === currentLink)}
                {link === currentLink ? (
                  <Typography.Paragraph
                    style={{
                      font: "normal 600 12px/14px Inter",
                      color: "#6B7280",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                    ellipsis={{
                      rows: 4,
                    }}
                  >
                    {link.split("/").at(-1)}
                  </Typography.Paragraph>
                ) : (
                  <div
                    style={{
                      wordBreak: "break-word",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "120px",
                      background: "#E5E7EB",
                      borderRadius: "0px 0px 9px 9px",
                      padding: "7px 8px",
                      font: "normal 600 12px/14px Inter",
                      color: "#6B7280",
                      marginTop: "28px",
                    }}
                  >
                    {link.split("/").at(-1)}
                  </div>
                )}
              </div>
            ) : (
              <img
                src={link}
                alt="attachment"
                style={{
                  width: "120px",
                  height: "120px",
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
              />
            )}
          </Typography.Link>
        );
      })}
    </div>
  );
};

export default Attachment;

import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Input,
  Space,
  Select,
  Upload,
  Modal as AntModal,
  Radio,
  Tag,
  Col,
  Row,
} from "antd";
import QuillEditor from "../QuillEditor";
import { Modal, Footer } from "../../styles/ModalStyles";
import TaskPoints from "../TaskPoints";
import Flag from "../Flag";
import AddNoteSubmission from "../AddNoteSubmission";
import styled from "styled-components";
import { ArrowRight } from "../../Icones/index";
import DiamondImage from "../../images/diamond.png";
import DueDate from "../DueDate";
import moment from "moment";
import ReviewerSelector from "./ReviewerSelector";
import AddBounty from "./AddBounty";
import IconWrapper from "../IconWrapper";
import { DocumentIcon } from "@heroicons/react/24/solid";
import { checkFileSize } from "../../utils";
import { PaperClipIcon } from "../Icones";
const { Option } = Select;

const StyledDiv = styled.div`
  padding: 0px 51px 16px 20px;

  .bountyType {
    width: 1rem;
    height: 1rem;
  }

  display: flex;
  .ant-select-selector,
  .application-based {
    background-color: #f4f2f2 !important;
    border: none !important;
    border-radius: 8px !important;
  }
  .application-based {
    padding: 0.35rem 1rem;
  }
  .btn-addBounty {
    border: 1px dashed #94a3b8;
    background: transparent;
    padding: 5px 12px;
    border-radius: 8px;
    color: #64748b;
    font-size: 12px;
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: center;
    align-self: center;
    span {
      white-space: nowrap;
    }
  }
  .with-reward-of {
    margin: 0 5px;
    color: #0f172a;
    white-space: nowrap;
    opacity: 0.6;
  }
  .for {
    margin: 0 5px;
    color: #0f172a;
    opacity: 0.6;
  }
  .direct-assignment-ip {
    width: 8rem;
  }
  .diamond-image {
    width: 1rem;
    aspect-ratio: 1;
  }
  .direct-assignment-ip {
    background-color: #f4f2f2 !important;
    border: none !important;
    border-radius: 8px !important;
  }
  .bountyOption .gatedValue {
    margin-left: 6px;
    margin-top: 5px;
  }
  .selected-token-amt {
    padding: 5px 12px 3px;
    height: 32px;
    background: rgba(30, 192, 57, 0.1);
    border-radius: 8px;
    border: none;
    color: #22c55e;
    font-weight: 600;
    font-size: 12px;
  }
`;

export const StyledBountyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;

  .diamond-image {
  }

  .ant-select-selector {
    background: ${(props) =>
      props.status === "TODO"
        ? "#F2F5FF !important"
        : props.status === "INPROGRESS"
        ? "#8DA2FB !important"
        : props.status === "INREVIEW"
        ? "#AC94FA !important"
        : props.status === "COMPLETED"
        ? "#31C48D !important"
        : ""};
    border-radius: 8px 0px 0px 8px !important;
    margin-right: 5px !important;
    border: none !important;
    color: ${(props) => (props.status !== "TODO" ? "#fff" : "#64748B")};
  }
  .ant-select-arrow {
    color: ${(props) => (props.status !== "TODO" ? "#FFF !important" : "")};
  }

  .arrow-right {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: ${(props) => (props.status !== "TODO" ? "#fff" : "#64748B")};
    border: none;
    height: 32px;
    border-radius: 0px 8px 8px 0px;
    background-color: ${(props) =>
      props.status === "TODO"
        ? "#F2F5FF"
        : props.status === "INPROGRESS"
        ? "#8DA2FB"
        : props.status === "INREVIEW"
        ? "#AC94FA"
        : props.status === "COMPLETED"
        ? "#31C48D"
        : ""};
  }
`;

const StyledAttachment = styled.div`
  padding: 0px 20px;
  .attachment-title {
    font-weight: 600;
    font-size: 12px;
    margin: 5px 8px;
  }
`;

const StyledModalWrapper = styled.div`
  .ant-modal-header {
    padding: 20px !important;
  }
`;

const BountyCreation = ({
  bountyType,
  selectedTokenId,
  selectedTokenAmt,
  setSelectedTokenId,
  setSelectedTokenAmt,
}) => {
  return (
    <StyledDiv style={{ alignItems: "baseline" }} className="fw-500">
      <AddBounty
        selectedTokenId={selectedTokenId}
        selectedTokenAmt={selectedTokenAmt}
        setSelectedTokenId={setSelectedTokenId}
        setSelectedTokenAmt={setSelectedTokenAmt}
        isSubtask
      />
    </StyledDiv>
  );
};

const CreateBountyTitle = ({ status, setStatus }) => {
  const statuses = ["TODO", "IN PROGRESS", "IN REVIEW", "COMPLETED"];

  // const handleStatusChange = (e) => {
  //   setStatus(e);
  // };

  const handleStatusChangeOnClick = () => {
    const indexOfStatus = statuses.findIndex((ele) => ele === status);
    setStatus(
      statuses[indexOfStatus === statuses.length - 1 ? 0 : indexOfStatus + 1]
    );
  };

  console.log(status);

  return (
    <StyledBountyTitle status={status}>
      <Select
        defaultValue="TODO"
        style={{ width: "140px" }}
        onChange={(e) => setStatus(e)}
        value={status}
      >
        {statuses.map((currStatus) => (
          <Option value={currStatus}>{currStatus}</Option>
        ))}
      </Select>

      <button onClick={handleStatusChangeOnClick} className="arrow-right">
        <ArrowRight height="1rem" width="1rem" />
      </button>
    </StyledBountyTitle>
  );
};

const BountyFilesAttachment = ({ files, setFiles }) => {
  const handleFiles = (id) => {
    setFiles((prev) => prev.filter((file) => file.lastModified !== id));
  };

  return (
    <StyledAttachment>
      <p className="attachment-title">Attachments:</p>
      {files.map((file, idx) => (
        <Tag
          style={{
            border: "1px solid #E2E8F0",
            background: "transparent",
            padding: "0.25rem 1rem",
            borderRadius: "1rem",
            marginBottom: "1rem",
          }}
          closable
          onClose={() => handleFiles(file.lastModified)}
          key={idx}
        >
          <IconWrapper width="12px" style={{ marginRight: "5px" }}>
            <DocumentIcon />
          </IconWrapper>
          {file.name}
        </Tag>
      ))}
    </StyledAttachment>
  );
};
const CreateBountySubtaskModal = ({
  isModalVisible,
  handleCancel,
  defaultData,
  updateSubtasks,
  setUpdatedFieldsNames = () => {},
  order,
}) => {
  const [status, setStatus] = useState(defaultData?.status || "TODO");
  const [title, setTitle] = useState(defaultData?.title || "");
  const [description, setDescription] = useState(
    defaultData?.description || ""
  );
  const [priority, setPriority] = useState(defaultData?.priority || null);
  const [submissionDate, setSubmissionDate] = useState(
    defaultData?.submissionDate || ""
  );
  const [submissionNote, setSubmissionNote] = useState(
    defaultData?.submissionNote || ""
  );
  const [taskPoint, setTaskPoint] = useState(defaultData?.taskPoint || "0");

  const [files, setFiles] = useState(defaultData?.files || []);

  const fileUploadRef = useRef(null);

  const [selectedTokenId, setSelectedTokenId] = useState(
    defaultData.bountyTokenId || ""
  );
  const [selectedTokenAmt, setSelectedTokenAmt] = useState(
    defaultData.amount || ""
  );
  return (
    <StyledModalWrapper>
      <Modal
        centered
        width="840px"
        destroyOnClose
        // title={<Cre  ateBountyTitle setStatus={setStatus} status={status} />}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Footer key="modal-footer">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Space>
                <input
                  hidden
                  ref={fileUploadRef}
                  type="file"
                  style={{ width: "0", height: "0" }}
                  onClick={() => {
                    fileUploadRef.current.value = null;
                  }}
                  onChange={(e) => {
                    const newFile = e.target.files[0];
                    checkFileSize(newFile.size);
                    setFiles((prev) => [...prev, newFile]);
                    setUpdatedFieldsNames();
                  }}
                />
                <Button
                  className="borderless-button"
                  shape="circle"
                  onClick={() => {
                    fileUploadRef.current.click();
                  }}
                >
                  <IconWrapper width="18px">
                    <PaperClipIcon />
                  </IconWrapper>
                </Button>
                <Flag
                  defaultValue={priority}
                  handleChange={(value) => {
                    setPriority(value);
                  }}
                />
                <DueDate
                  defaultValue={submissionDate}
                  onChange={(date, time) => {
                    if (date) {
                      setUpdatedFieldsNames();
                      const dateTime = moment(
                        date.format("YYYY-MM-DD") + ` ${time ? time : "23:59"}`
                      ).format("YYYY-MM-DD HH:mmZ");
                      setSubmissionDate(dateTime);
                    } else setSubmissionDate(null);
                  }}
                />
                {/* <TaskPoints setPoints={setTaskPoint} points={taskPoint} /> */}
                {/* <Assignee
                isSubtask
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              /> */}
                {/* <ReviewerSelector
                  reviewer={defaultData?.reviewers?.[0]}
                  setReviewer={(user) => {
                    console.log("user here", user);
                    updateSubtasks({ title: title, reviewers: [user] });
                  }}
                /> */}
              </Space>
              <Button
                className="rounded-corner"
                type="primary"
                style={{ marginLeft: "50px" }}
                onClick={() => {
                  updateSubtasks(
                    {
                      description: description,
                      priority: priority,
                      status: status,
                      title: title,
                      submissionDate: submissionDate,
                      submissionNote: submissionNote,
                      taskPoint: taskPoint,
                      files: files,
                      reviewers: [defaultData?.reviewers?.[0]],
                      bountyTokenId: selectedTokenId,
                      amount: selectedTokenAmt,
                    },
                    order
                  );
                  handleCancel();
                }}
              >
                Create
              </Button>
            </Row>
          </Footer>,
        ]}
      >
        <Input
          style={{
            paddingLeft: "20px",
            fontSize: "21px",
            fontWeight: "600",
            margin: "10px 0",
          }}
          placeholder="Add the title of your checklist item here"
          size="large"
          className="ip-address"
          value={title}
          onChange={(e) => {
            setUpdatedFieldsNames();
            setTitle(e.target.value);
          }}
          bordered={false}
        />

        {/* <Tags /> */}
        {/* <StyledQuillEditior> */}
        <QuillEditor
          value={description}
          onChange={(e) => {
            setUpdatedFieldsNames();
            setDescription(e);
          }}
          placeholder="Add details of your checklist item here"
          paddingLeft="0px"
        />
        {/* </StyledQuillEditior> */}
        {files.length > 0 && (
          <BountyFilesAttachment files={files} setFiles={setFiles} />
        )}
        {/* <AddNoteSubmission
          defaultNote={submissionNote}
          setNote={setSubmissionNote}
          state={state}
          setState={setState}
          hideDivider
        /> */}
      </Modal>
    </StyledModalWrapper>
  );
};

export default CreateBountySubtaskModal;

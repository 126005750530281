import { toast } from "react-toastify";
import customAxios from "../api";
import { defaultConditionDetails } from "../components/Gating/GatingModal";
import { UserGatingHook } from "../hooks/useGating";

export const fetchDaoUsers = async (daoId: string) => {
  const response = await customAxios.get(`users/daousers?daoId=${daoId}`);
  return response.data.daoUser;
};

type CreateEventDataType = {
  daoId: string;
  title: string;
  description: string;
  type: "IN_PERSON" | "ONLINE";
  eventAt: string;
  eventEndAt: string;
  gatingCondition: any;
  link: any;
  hostIds: string[];
  image: any;
  taskPoint: string;
  userTagged: string[];
  createDiscord?: boolean;
};

export const createNewEvents = async (
  {
    daoId,
    description,
    eventAt,
    eventEndAt,
    hostIds,
    link,
    title,
    type,
    image,
    taskPoint,
    userTagged,
    createDiscord,
  }: CreateEventDataType,
  gatingState: UserGatingHook
) => {
  const formData = new FormData();
  gatingState.createFormData(formData);
  formData.append("daoId", daoId);
  formData.append("createDiscord", JSON.stringify(createDiscord));

  if (userTagged) formData.append("userTagged", JSON.stringify(userTagged));
  formData.append("taskPoint", taskPoint);

  if (image) {
    formData.append("files", image);
  }

  if (description) {
    formData.append("description", description);
  }

  if (!eventAt) {
    toast.error("Please add event start");
    throw new Error("Form error");
  }
  formData.append("eventAt", eventAt);

  if (!eventEndAt) {
    toast.error("Please add event end");
    throw new Error("Form error");
  }
  formData.append("eventEndAt", eventEndAt);

  if (!link) {
    toast.error("Please add event location");
    throw new Error("Form error");
  }
  formData.append("link", link);

  if (!title) {
    toast.error("Please add event title");
    throw new Error("Form error");
  }
  formData.append("title", title);

  if (!type) {
    toast.error("Please add event type");
    throw new Error("Form error");
  }
  formData.append("type", type);

  if (!hostIds || hostIds.length === 0) {
    toast.error("Please select event hosts");
    throw new Error("Form error");
  }
  formData.append("hostIds", JSON.stringify(hostIds));

  await customAxios.post("/affair/create", formData);
};

type EditEventDataType = {
  eventId: string;
} & CreateEventDataType;

export const editEvent = async (
  {
    daoId,
    description,
    eventAt,
    eventEndAt,
    hostIds,
    link,
    title,
    type,
    image,
    taskPoint,
    eventId,
  }: EditEventDataType,
  gatingState: UserGatingHook
) => {
  const formData = new FormData();
  gatingState.createFormData(formData);
  formData.append("daoId", daoId);
  formData.append("eventsId", eventId);
  formData.append("taskPoint", taskPoint);
  if (image) {
    formData.append("files", image);
  }

  if (description) {
    formData.append("description", description);
  }

  if (!eventAt) {
    toast.error("Please add event start");
    throw new Error("Form error");
  }
  formData.append("eventAt", eventAt);

  if (!eventEndAt) {
    toast.error("Please add event end");
    throw new Error("Form error");
  }
  formData.append("eventEndAt", eventEndAt);

  if (!link) {
    toast.error("Please add event location");
    throw new Error("Form error");
  }
  formData.append("link", link);

  if (!title) {
    toast.error("Please add event title");
    throw new Error("Form error");
  }
  formData.append("title", title);

  if (!type) {
    toast.error("Please add event type");
    throw new Error("Form error");
  }
  formData.append("type", type);

  if (!hostIds || hostIds.length === 0) {
    toast.error("Please select event hosts");
    throw new Error("Form error");
  }
  formData.append("hostIds", JSON.stringify(hostIds));

  await customAxios.post("/affair/edit", formData);
};

export const checkIsAllDayEvent = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const isEndEventAllDay =
    endDate.toISOString() ===
    endDate.startOf("day").add(1, "day").subtract(1, "minute").toISOString();

  const isStartEventAllDay =
    startDate.startOf("day").toISOString() === startDate.toISOString();

  const result = isStartEventAllDay && isEndEventAllDay;
  return result;
};

import { ArrowRightIcon, LinkIcon } from "@heroicons/react/24/outline";
import { Button, Input } from "antd";
import { ReactComponent as RemoveIcon } from "../../../assets/images/course/remove.svg";
import { ReactComponent as ColorLinkIcon } from "../../../assets/images/course/colorlink.svg";
import { isValidHttpUrl } from "../CreateCourseModal";
import { toast } from "react-toastify";
import IconWrapper from "../../IconWrapper";

const AddLink = ({ state, setState }) => {
  return (
    <>
      {state.link && !state.link?.includes("https://storage.googleapis.com") ? (
        <div
          style={{
            border: "1px solid #E2E8F0",
            borderRadius: "12px",
            padding: "16px",
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <ColorLinkIcon style={{ height: "100%" }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "2px",
            }}
          >
            <Input
              value={state.title}
              onChange={(e) => {
                setState((prev) => ({ ...prev, title: e.target.value }));
              }}
              bordered={false}
              style={{
                padding: "0px",
                height: "24px",
                font: "normal 500 16px/24px Inter",
              }}
              placeholder="Add Title"
              maxLength={50}
            />
            <Input
              value={state.description}
              onChange={(e) => {
                setState((prev) => ({ ...prev, description: e.target.value }));
              }}
              bordered={false}
              style={{
                padding: "0px",
                height: "16px",
                font: "normal 500 12px/16px Inter",
                color: "#64748B",
              }}
              placeholder="Add description"
              maxLength={75}
            />
          </div>
          <RemoveIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setState((prev) => ({ ...prev, link: undefined }));
            }}
          />
        </div>
      ) : (
        <div
          style={{
            cursor: "pointer",
            height: "200px",
            background: "rgba(226, 232, 240, 0.2)",
            font: "normal 600 14px/20px Inter",
            color: "#1A202E",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
            border: "1px dashed #CFD8E3",
            borderRadius: "16px",
          }}
        >
          <LinkIcon
            width={28}
            color="#64748B"
            strokeWidth="2px"
            style={{ marginBottom: "4px" }}
          />
          Add Link
          <div
            style={{
              font: "normal 400 12px/16px Inter",
              color: "#97A6BA",
            }}
          >
            Direct your users to google drive, youtube, or article
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();

              setState((prev) => ({
                ...prev,
                link: prev.localLink,
                description: prev.localLink,
              }));
            }}
          >
            <Input
              value={state.localLink}
              placeholder="Paste link here"
              onChange={(e) => {
                setState((prev) => ({ ...prev, localLink: e.target.value }));
              }}
              style={{
                width: "300px",
                borderRadius: "12px",
                marginRight: "8px",
              }}
            />
            <Button
              className="rounded-corner"
              type="primary"
              htmlType="submit"
              disabled={!(state.localLink && isValidHttpUrl(state.localLink))}
              style={{ borderRadius: "12px", padding: "4px 12px" }}
            >
              <IconWrapper>
                <ArrowRightIcon />
              </IconWrapper>
            </Button>
          </form>
        </div>
      )}
    </>
  );
};

export default AddLink;

import { getDefaultDaoImage } from "../../utils/index.js";
import { Bubble } from "./FirstView.js";
import OverviewIcon from "../../images/overview-icon.svg";

const VisiblityView = ({ entities, daoDetails }) => {
  let overview = {
    label: "Overview",
    viewicon: OverviewIcon,
    value: false,
  };

  return (
    <>
      <div
        style={{
          padding: "12px",
          background: "rgba(217, 217, 217, 0.1)",
          borderRadius: "24px",
          backdropFilter: "blur(7.2px)",
          zIndex: 1,
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: "24px",
            font: "normal 500 14px/20px Inter",
            color: "#1F2937",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <img
            src={daoDetails.backgroundImage}
            style={{
              width: "360px",
              height: "120px",
              borderRadius: "24px 24px 0px 0px",
              objectFit: "cover",
            }}
          />
          <div style={{ position: "absolute", top: "98px", left: "24px" }}>
            {daoDetails?.profilePicture ? (
              <img
                src={daoDetails?.profilePicture}
                alt="dao_logo"
                style={{
                  borderRadius: "50%",
                  width: "76px",
                  height: "76px",
                  objectFit: "cover",
                  border: "2px solid #ffffff",
                  boxShadow:
                    "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              getDefaultDaoImage(
                daoDetails?.daoName,
                {
                  alignSelf: "center",
                  width: "76px",
                  height: "76px",
                  cursor: "pointer",
                  border: "2px solid #ffffff",

                  borderRadius: "50%",
                },
                { fontSize: "28px" }
              )
            )}
          </div>
          <div
            style={{
              margin: "12px 0px 22px 108px",
              font: "normal 600 20px/28px Inter",
            }}
          >
            {daoDetails.daoName}
          </div>
          {[
            overview,
            entities[1],
            entities[3],
            entities[4],
            entities[2],
            entities[5],
            entities[0],
          ].map((entity) =>
            !entity.value ? (
              <div
                style={{
                  padding: "12px 24px",
                  font: "normal 500 16px/24px Inter",
                  color: "#1F2937",
                  borderTop: "1px solid #F3F4F6",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={entity.viewicon}
                  alt="icon"
                  style={{
                    marginRight: "8px",
                  }}
                />
                {entity.label}
              </div>
            ) : null
          )}
        </div>
      </div>

      <Bubble
        style={{
          top: "20%",
          left: "20%",
          width: "108px",
        }}
      />
      <Bubble
        style={{
          top: "22%",
          left: "65%",
          width: "108px",
          zIndex: 2,
        }}
      />
      <Bubble
        style={{
          top: "538px",
          left: "65%",
          width: "108px",
        }}
      />
      <Bubble
        style={{
          left: "20%",
          top: "548px",
        }}
      />
    </>
  );
};

export default VisiblityView;

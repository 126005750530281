import { Input } from "antd";
import React from "react";
import styled from "styled-components";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
const StyledInput = styled(Input)`
  border-radius: 8px;
  margin-top: 8px;
  width: 122px;
  .ant-input {
    border-radius: 8px;
    margin: 0;
  }

  span:nth-child(1) {
    border-radius: 8px 0px 0px 8px;
  }
  span:nth-child(3) {
    border-radius: 0px 8px 8px 0px;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const XPField = ({ value = 0, onChange }) => {
  const [showCard, setShowCard] = React.useState(false);
  const ref = React.useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => {
    if (showCard) setShowCard(false);
  });
  return (
    <div
      className="bg-gray-100"
      style={{
        color: "rgb(107, 114, 128)",
        font: "normal 500 14px/20px Inter",
        borderRadius: "8px",
        padding: "6px 12px",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        whiteSpace: "nowrap",
        position: "relative",
      }}
      onClick={() => {
        setShowCard((prev) => !prev);
      }}
    >
      {showCard && (
        <div
          ref={ref}
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            background: "white",
            font: "normal 500 12px/16px Inter",
            position: "absolute",
            color: "black",
            bottom: "120%",
            padding: "12px",
            borderRadius: "12px",
            boxShadow:
              "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
          }}
        >
          ⭐️ XP Reward
          <StyledInput
            type="number"
            addonAfter={
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  onChange(value + 10);
                }}
              >
                +
              </div>
            }
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value))}
            addonBefore={
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (Number(value) > 10) onChange(value - 10);
                }}
              >
                -
              </div>
            }
          />
        </div>
      )}
      ⭐️ {value ? value : 0} XP
    </div>
  );
};

export default XPField;

import {
  Button,
  Collapse,
  Dropdown,
  Input,
  Menu,
  Skeleton,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import customAxios from "../../api";
import GoBackButton from "../InternalComponents/GoBackButton";
import TextButton from "../InternalComponents/TextButton";
import { ReactComponent as GreyVerticalDot } from "../../assets/images/daoOverview/tripledotVertical.svg";
import { ReactComponent as SearchImage } from "../../assets/images/search/search.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
import styled from "styled-components";
import CreateTaskModal from "./CreateTaskModal";
import TaskFeed from "./Task/TaskFeed";
import { toast } from "react-toastify";
import CreateProjectModal from "./CreateProjectModal";
import Attachments from "../Common/Attachments";
import { DaoContext, useDaoContext } from "../../layouts/app/DaoContext";
import ReactHtmlParser from "react-html-parser";
import { UserContext } from "../../layouts/app/UserContext";
import OnboardingModal from "../Onboarding/OnboardingModal";
import { postMixpanel, updateWalkmeStatus } from "../../utils";
import { useTrackPageView } from "../../hooks/useTrackPageView";
import DeleteEntityModal from "../Common/DeleteEntityModal";
import { togglePinProject } from "../../utils/pin";
import { PinIcon } from "../Icones";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import SlidingContextSidebar from "../SlidingContextSidebar";

const StyledCollapsePannel = styled(Collapse.Panel)`
  .ant-collapse-header {
    padding: 0px !important;
  }
`;
const steps = [
  {
    target: ".create-project-task",
    disableBeacon: true,
    disableScrolling: true,
    title: "Create Tasks in Project",
    content: "Click on button to start creating a new Task.",
  },
  {
    target: ".setup-project-task-details",
    disableBeacon: true,
    disableScrolling: true,
    title: "Setup Task details",
    content:
      "Add task name and description to define tasks. Provide information about the Assignee and the reviewer of the task. Update the due date when the task is expected to be completed.",
  },
  {
    target: ".setup-project-subtasks",
    disableBeacon: true,
    disableScrolling: true,
    title: "Create Sub Tasks",
    content:
      "Breakdown tasks into further steps by creating new Sub Tasks and adding their details.",
  },
];
const ProjectDetailsPage = () => {
  const { projectId } = useParams();
  const { daoDetails, daoId, daoName } = useDaoContext();

  const navigate = useDaoLensNavigate();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(undefined);
  const [createModalVisible, setCreateModalVisible] = useState(undefined);
  const [accordionState, setAccordionState] = useState([""]);
  const [stage, setStage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [updater, setUpdater] = useState(false);
  const [isEditProjectModalOpen, setIsEditProjectModalOpen] = useState(false);
  const [startTour, setStartTour] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const [showEndingModal, setShowEndingModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { walkMeStatus, reload } = useContext(UserContext);
  const navigation = useDaoLensNavigate();
  const { isAdmin } = useContext(DaoContext);
  useTrackPageView(projectId);
  useEffect(() => {
    if (!daoId) return;
    setLoading(true);
    customAxios
      .get(`/projects/fetch?daoId=${daoId}`)
      .then(({ data }) => {
        setProject(data.res.find((project) => project.id === projectId));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [projectId, updater, daoId]);
  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`settings/canview`, { daoId: daoId })
      .then((res) => {
        // setCanManageCourse(res.data.canView ? true : false);
        if (
          res.data.canView &&
          walkMeStatus &&
          walkMeStatus?.projectTaskCreate === false
        )
          setShowOnboardingModal(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [walkMeStatus, daoId]);
  const deleteProjectHandler = () => {
    customAxios
      .delete(`/projects/delete?projectId=${projectId}`)
      .then((res) => {
        toast.success("Project deleted successfully");
        navigation(`/app/dao/${daoId}/project`);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log("error");
        console.error(err);
      });
  };

  const menu = (
    <Menu style={{ borderRadius: "8px" }}>
      <Menu.Item onClick={() => setIsEditProjectModalOpen(true)}>
        <PencilIcon
          fill="#6B7280"
          style={{ width: "12px", marginRight: "3px" }}
        />{" "}
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={() => togglePinProject(daoId, projectId, !project?.pinned)}
      >
        <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
        {project?.pinned ? "Unpin" : "Pin"}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setShowDeleteModal(true);
        }}
      >
        <TrashIcon
          fill="#6B7280"
          style={{ width: "12px", marginRight: "6px" }}
        />
        Delete
      </Menu.Item>
    </Menu>
  );
  const [feedStatusUpdateOnCreate, setFeedStatusUpdateOnCreate] =
    useState("TODO");
  return (
    <>
      <DeleteEntityModal
        note="This project will be deleted permanently. Do you still want to
          proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          deleteProjectHandler();
        }}
      />
      <div
        style={{
          background: "white",
          marginTop: "20px",
          paddingTop: "12px",
          borderRadius: "16px",
          overflow: "clip",
        }}
      >
        {showOnboardingModal ? (
          <OnboardingModal
            step={"project-start"}
            showModal={showOnboardingModal}
            onClick={() => {
              updateWalkmeStatus({ projectTaskCreate: true }, reload);
              postMixpanel("track", "started_walk_me_tour_for_create_project");

              setShowOnboardingModal(false);
              setStartTour(true);
            }}
            onClose={() => {
              setShowOnboardingModal(false);
            }}
          />
        ) : null}
        {showEndingModal ? (
          <OnboardingModal
            step={"project-end"}
            showModal={showEndingModal}
            onClick={() => {
              postMixpanel("track", "finished_walk_me_tour_for_create_project");

              setShowEndingModal(false);
            }}
            onClose={() => {
              setShowEndingModal(false);
            }}
          />
        ) : null}
        {/* {startTour ? (
          <WalkmeTour
            run={startTour}
            steps={steps}
            otherProps={{
              disableScrollParentFix: true,
              disableScrolling: true,
              continuous: true,
              disableBeacon: true,
              callback: (step) => {
                console.log({ step, target: step.step.target });
                if (
                  step.step.target === ".create-project-task"
                  // &&
                  // step.action === "update"
                ) {
                  setTimeout(() => {
                    setCreateModalVisible(true);
                  }, 1000);
                }

                if (step.status === "finished") {
                  setShowEndingModal(true);
                  setCreateModalVisible(false);
                }
              },
            }}
          />
        ) : null} */}
        <CreateProjectModal
          isModalVisible={isEditProjectModalOpen}
          handleCancel={() => setIsEditProjectModalOpen(false)}
          onSubmit={() => setUpdater((prev) => !prev)}
          isEdit
          defaultData={project}
        />
        {loading && (
          <div style={{ padding: "20px" }}>
            <Skeleton active />
          </div>
        )}
        {createModalVisible ? (
          <CreateTaskModal
            onSubmit={() => setUpdater((prev) => !prev)}
            isModalVisible={createModalVisible}
            handleCancel={() => setCreateModalVisible(false)}
            setFeedStatusUpdateOnCreate={setFeedStatusUpdateOnCreate}
          />
        ) : null}
        {!loading && project && (
          <Collapse
            // defaultActiveKey={["1"]}
            ghost
            // expandIconPosition="right"
            // expandIcon={() => <></>}
            activeKey={accordionState}
            className="bounty-details-accordion"
          >
            <StyledCollapsePannel
              header={
                <div
                  style={{
                    display: "flex",
                    font: "normal 600 16px/24px Inter",
                    alignItems: "center",
                    gap: "8px",
                    padding: "0 20px 6px 20px",
                  }}
                >
                  {" "}
                  <GoBackButton
                    showIcon
                    clickHandler={() => {
                      if (stage === 1) {
                        setStage(0);
                      } else navigate(`/app/dao/${daoName}/${daoId}/project`);
                    }}
                  />
                  {stage === 1 ? (
                    <>
                      <Input
                        style={{ fontSize: "14px" }}
                        autoFocus={true}
                        placeholder="Search for keywords"
                        className="ip-address"
                        value={searchTerm}
                        onChange={(e) => {
                          setSearchTerm(e.target.value);
                        }}
                        bordered={false}
                      />
                      {searchTerm && (
                        <XMarkIcon
                          onClick={() => {
                            setSearchTerm("");
                          }}
                          width={18}
                          color="#64748B"
                          cursor="pointer"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <div
                        style={{
                          font: "normal 500 12px/16px Inter",
                          width: "24px",
                          height: "24px",
                          background: project.background,
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1.5px solid #FFFFFF",
                          borderRadius: "4px",
                          boxShadow: " 0px 0px 3px rgba(25, 38, 72, 0.2)",
                        }}
                      >
                        {project.icon ? project.icon : "🗂"}
                      </div>
                      {project.title}
                      <TextButton
                        clickHandler={() =>
                          accordionState[0] === "1"
                            ? setAccordionState([""])
                            : setAccordionState(["1"])
                        }
                        isActive={accordionState[0] === "1"}
                      />
                      <SearchImage
                        onClick={() => {
                          setStage(1);
                        }}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "auto",
                        }}
                      />
                      <SlidingContextSidebar
                        entityId={projectId}
                        entityType="PROJECTS"
                        canEdit={isAdmin}
                        style={{ border: 0, padding: 0, color: "pink" }}
                      />
                      {project?.pinned && (
                        <PinIcon style={{ color: "#6366F1" }} />
                      )}
                      {isAdmin && (
                        <>
                          <Dropdown overlay={menu} trigger={["click"]}>
                            <GreyVerticalDot />
                          </Dropdown>
                          <Button
                            className="rounded-corner create-project-task"
                            type="primary"
                            style={{ borderRadius: "12px" }}
                            disabled={loading}
                            onClick={() => {
                              setCreateModalVisible(true);
                            }}
                          >
                            + Task
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </div>
              }
              key="1"
              showArrow={false}
            >
              <Typography.Paragraph style={{ paddingLeft: "25px" }}>
                {ReactHtmlParser(project.description)}
              </Typography.Paragraph>

              <div style={{ padding: "0 20px" }}>
                <Attachments attachments={project.attachments} />
              </div>
            </StyledCollapsePannel>
          </Collapse>
        )}
        {!loading && (
          <div
            style={{
              height: "4px",
              background: "#E2E8F0",
              marginTop: "4px",
              borderRadius: "16px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: `${
                  (project?.completedTasks * 100) /
                  (project?.totalTasks ? project?.totalTasks : 1)
                }%`,
                height: "100%",
                background: "#3B82F6",
              }}
            ></div>
          </div>
        )}
      </div>
      <TaskFeed
        setCreateModalVisible={setCreateModalVisible}
        rerender={updater}
        searchTerm={searchTerm}
        feedStatusUpdateOnCreate={feedStatusUpdateOnCreate}
      />
    </>
  );
};
export default ProjectDetailsPage;

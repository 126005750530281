import { HoverCard } from "../../Search/SearchModal";
import { ChoiceCard } from "../Vote";

const SingleChoiceVoting = ({ choices, selectedChoice, setSelectedChoice }) => {
  return (
    <>
      {choices.map((choice, index) => {
        let idx = index + 1;
        let isSelected = selectedChoice === idx;
        return (
          <ChoiceCard
            isSelected={isSelected}
            onClick={() => {
              setSelectedChoice(idx);
            }}
          >
            {typeof choice === "string" ? choice : choice.name}
            {isSelected && (
              <svg
                style={{ position: "absolute", right: "16px" }}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.2773 3.08403C13.5071 3.2372 13.5692 3.54764 13.416 3.7774L7.41602 12.7774C7.33293 12.902 7.19834 12.9829 7.04927 12.9976C6.9002 13.0124 6.75237 12.9595 6.64645 12.8536L2.64645 8.85361C2.45118 8.65834 2.45118 8.34176 2.64645 8.1465C2.84171 7.95124 3.15829 7.95124 3.35355 8.1465L6.92229 11.7152L12.584 3.2227C12.7372 2.99294 13.0476 2.93085 13.2773 3.08403Z"
                  fill="#3F83F8"
                />
              </svg>
            )}
          </ChoiceCard>
        );
      })}
    </>
  );
};

export default SingleChoiceVoting;

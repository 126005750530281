
import { Input } from "antd";
import CourseVerificationAdminModal from "../CourseVerificationAdminModal";
import { ReactComponent as QuizIcon } from "../../../assets/images/course/colorquiz.svg";

const QuizContent = ({ state, setState }) => {
  return (
    <>
      <div
        style={{
          border: "1px solid #E2E8F0",
          borderRadius: "12px",
          padding: "16px",
          display: "flex",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <QuizIcon style={{ height: "100%" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "65%",
            gap: "2px",
          }}
        >
          <Input
            value={state.title}
            onChange={(e) => {
              setState((prev) => ({ ...prev, title: e.target.value }));
            }}
            bordered={false}
            style={{
              padding: "0px",
              height: "24px",
              font: "normal 500 16px/24px Inter",
            }}
            placeholder="Add Title of the quiz"
            maxLength={50}
          />
          <Input
            value={state.description}
            onChange={(e) => {
              setState((prev) => ({ ...prev, description: e.target.value }));
            }}
            maxLength={75}
            bordered={false}
            style={{
              padding: "0px",
              height: "16px",
              font: "normal 500 12px/16px Inter",
              color: "#64748B",
            }}
            placeholder="Add description"
          />
        </div>
        <div
          style={{
            font: "normal 500 12px/16px Inter",
            color: "#3F83F8",
            whiteSpace: "nowrap",
          }}
        >
          Passing marks: 75%
        </div>
      </div>
      <CourseVerificationAdminModal
        quizDetails={state.quiz}
        setQuizDetails={(data) => {
          setState((prev) => ({ ...prev, quiz: data }));
        }}
      />
    </>
  );
};

export default QuizContent;


import { Col } from "antd";
import { ReactComponent as EmptyLibrary } from "../../../src/assets/images/course/empty_library.svg";

const EmptyComponent = ({ text, subtext }) => {
    return <div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "12px",
            padding: "2.5rem",
        }}
    >
        <EmptyLibrary />
        <div
            style={{
                fontWeight: "600",
                fontSize: "16px",
                marginBottom: "4px",
                marginTop: "8px"
            }}
        >
            {text}
        </div>
        <div
            style={{
                color: "#64748B",
                fontWeight: "500",
                fontSize: "14px",
                marginTop: "0px",
            }}
        >
            {subtext}
        </div>
    </div>
}

export default EmptyComponent;
import { CloseOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import customAxios from "../../api";
import EntityTypeChip from "../Common/EntityTypeChip";
import LinkEntities from "../Common/LinkEntities";
import SlidingSidebar from "../Common/SlidingSidebar";
import IconWrapper from "../IconWrapper";
import SmallUserDetails from "../InternalComponents/SmallUserDetails";
import { ReactComponent as StackIcon } from "./../../images/rectangle-stack-icon.svg";
import defaultImg from "./../../assets/images/login/defaultUser.jpeg";
import { useDaoContext } from "../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
const StyledButtonContainer = styled.div`
  padding: 8px 20px;
  border-radius: 50px;
  border: 1px solid lightgrey;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  & :hover {
    cursor: pointer;
  }
`;
const StyledTitle = styled.h3`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
function SlidingContextSidebar({ entityId, entityType, canEdit = false, style = {} }) {
  const [linkedItems, setLinkedItems] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [newLinkedItems, setNewLinkedItems] = useState([]);

  const handleDeleteLinkedItem = (id) => {
    customAxios
      .delete(`/linkentites/delete`, { data: { linkId: id } })
      .then((res) => {
        toast.success("Item deleted from linked entities successfully.");
        setUpdater((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRedirectLink = (data) => {
    switch (data.type) {
      case "POST":
        return `/app/dao/${data?.dao?.name}/${data?.dao?.id}/channel/${data?.channelId}/post/${data.id}`;
      case "BOUNTY":
        return `/app/dao/${data?.dao?.name}/${data?.dao?.id}/bounty/${data?.id}`;
      case "PROPOSALS":
        return `/app/dao/${data?.dao?.name}/${data.dao_id}/proposal/${data?.id}`;
      case "ANNOUNCEMENT":
        return `/app/dao/${data?.dao?.name}/${data.dao_id}/overview/announcements/${data?.id}`;
      default:
        return null;
    }
  };

  const fetchLinkedItems = (entityId, entityType) => {
    setLoading(true);
    customAxios
      .get(`/linkentites/fetch?linkId=${entityId}&type=${entityType}`)
      .then((res) => {
        setLinkedItems(
          res.data.res.map((ele) => ({
            ...ele.data,
            type: ele.type,
            userName: ele.userDetails?.userName || "",
            profilePicture: ele.userDetails?.profilePicture || "",
          }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const { daoId, daoName } = useDaoContext();

  const navigate = useDaoLensNavigate();
  useEffect(() => {
    fetchLinkedItems(entityId, entityType);
  }, [entityId, entityType, updater]);

  return (
    <div>
      <StyledButtonContainer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...style
        }}
        onClick={() => {
          setSidebarOpen(true);
        }}
      >
        <IconWrapper width="unset">
          <StackIcon />
        </IconWrapper>
        <span style={{ color: "#64748B", fontSize: "12px" }}>
          {linkedItems?.length > 1 ? `(${linkedItems?.length})` : null}
        </span>
      </StyledButtonContainer>

      {linkModalVisible ? (
        <LinkEntities
          isModalVisible={linkModalVisible}
          isForLinking={false}
          isForUpdateLinking={true}
          newLinkedItems={newLinkedItems}
          handleSubmit={() => {
            customAxios
              .post(`/linkentites/save`, {
                linkedEntities: newLinkedItems.map((ele) => ({
                  linkedId: ele.id,
                  linkedType: ele.type,
                })),
                linkerId: entityId,
                linkerType: entityType,
              })
              .then((res) => {
                toast.success("Items linked successfully");
                setLinkModalVisible(false);
                setNewLinkedItems([]);
                setSidebarOpen(true);
                setUpdater((prev) => !prev);
              })
              .catch((err) => {
                console.log(err);
              });
          }}
          handleClose={() => {
            setLinkModalVisible(false);
          }}
          onClick={(data) => {
            setNewLinkedItems((prev) => {
              const curr = prev.slice();
              curr.push(data);

              return curr;
            });
          }}
          handleDeleteLinkedItem={(deletedItemId) => {
            setNewLinkedItems((prev) => {
              const curr = prev.slice();
              const filterCurr = curr.filter((ele) => ele.id !== deletedItemId);
              return filterCurr;
            });
          }}
          linkedItems={linkedItems}
          setLinkedItems={setLinkedItems}
        ></LinkEntities>
      ) : null}
      {sidebarOpen ? (
        <SlidingSidebar
          handleClose={() => {
            setSidebarOpen(false);
          }}
          open={sidebarOpen}
          banner={
            <div
              style={{
                justifyContent: "space-between",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3 style={{ marginBottom: "0px" }}>Context History</h3>

              {canEdit ? (
                <Button
                  type="primary"
                  style={{ borderRadius: "8px" }}
                  onClick={() => {
                    setLinkModalVisible(true);
                  }}
                >
                  Add More
                </Button>
              ) : null}
            </div>
          }
        >
          {loading && <Skeleton active />}
          {!loading &&
            linkedItems.map((ele, idx) => {
              return (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      background: "#FFFFFF",
                      padding: "16px 20px",

                      border:
                        ele.id === entityId
                          ? "1px solid #A4CAFE"
                          : "1px solid #F4F2F2",
                      borderRadius: "16px",
                      zIndex: "2",
                      rowGap: "4px",
                      cursor: "pointer",
                      position: "relative",

                      boxShadow:
                        ele.id === entityId
                          ? "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
                          : "none",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();

                      switch (ele.type) {
                        case "PROJECTS":
                          navigate(
                            `/app/dao/${daoName}/${daoId}/project/${ele.id}`
                          );
                          break;
                        case "BOUNTY":
                          navigate(
                            `/app/dao/${daoName}/${daoId}/bounty/${ele.id}`
                          );
                          break;
                        case "POST":
                          navigate(
                            `/app/dao/${daoName}/${daoId}/channel/${ele.channelId}/post/${ele.id}`
                          );
                          break;
                        case "PROPOSALS":
                          navigate(
                            `/app/dao/${daoName}/${daoId}/proposal/${ele.id}`
                          );
                          break;
                        case "ANNOUNCEMENT":
                          navigate(`/app/dao/${daoName}/${daoId}/overview/announcements/${ele?.id}`);
                          break;
                        default:
                      }
                    }}
                  >
                    {canEdit && ele.id !== entityId ? (
                      <Button
                        className="borderless-button"
                        type="secondary"
                        shape="circle"
                        size="small"
                        style={{
                          position: "absolute",
                          top: "-0.5rem",
                          right: "-0.5rem",
                        }}
                        icon={
                          <IconWrapper width={"initial"}>
                            <CloseOutlined
                              style={{ color: "#64748B", fontSize: "12px" }}
                            />
                          </IconWrapper>
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteLinkedItem(ele.id);
                        }}
                      ></Button>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(getRedirectLink(ele.type));
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          columnGap: "6px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={
                            ele.profilePicture ? ele.profilePicture : defaultImg
                          }
                          style={{
                            height: "24px",
                            width: "24px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />{" "}
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#64748B",
                            fontWeight: "600",
                          }}
                        >
                          {ele.userName}
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#64748B",
                            fontWeight: "600",
                          }}
                        >
                          &#x2022;{" "}
                          {moment.utc(ele.createdAt).format("DD MMM YYYY")}
                        </span>
                      </div>
                      <EntityTypeChip type={ele.type} />
                    </div>
                    <StyledTitle style={{ marginBottom: "0px" }}>
                      {ele.name ? ele.name : ele.title}
                    </StyledTitle>
                  </div>
                  {idx < linkedItems.length - 1 ? (
                    <div
                      style={{
                        position: "absolute",
                        transform: "rotate(90deg)",
                        width: "24px",
                        border: "1px solid #D1D5DB",
                        bottom: "-13px",
                        left: "30px",
                      }}
                    ></div>
                  ) : null}
                </div>
              );
            })}
        </SlidingSidebar>
      ) : null}
    </div>
  );
}

export default SlidingContextSidebar;

import customAxios from "../../api";
import { UserProfileType } from "../types/common";

export const fetchUserProfile = async (userId: string) => {
  const response = await customAxios.get("/users/profile/fetch", {
    params: {
      userId,
    },
  });

  const userDetails: UserProfileType = response?.data?.userDetails[0];

  return userDetails;
};

const ANTD_TIME_PICKER_COL = "ant-picker-time-panel-column";
const SELECTED_ITEM = "ant-picker-time-panel-cell-selected";

export const placeTimePickerToInitialPosition = (timeout = 100) => {
  const timeCols = document.getElementsByClassName(ANTD_TIME_PICKER_COL);

  const len = timeCols.length;

  for (let i = 0; i < len; i++) {
    const timeCol = timeCols[i] as HTMLUListElement;
    setTimeout(() => {
      const selectedTimeItem = timeCol?.getElementsByClassName(
        SELECTED_ITEM
      )[0] as HTMLLIElement;
      selectedTimeItem?.scrollIntoView?.({ block: "center" });
    }, timeout);
  }
};

import { Button, Col, Input, Row, Skeleton } from "antd";
import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import customAxios from "../../../api";
import { DaoContext, useDaoContext } from "../../../layouts/app/DaoContext";
import { getAddress } from "ethers/lib/utils";
import { Modal as AntModal } from "antd";
import { useWalletConnect } from "../../../layouts/app/WalletConnectContext";
import walletConnectIcon from "../../../assets/iconSvg/wallet-connect.svg";
import styled from "styled-components";
import { useEthers } from "@usedapp/core";
import useWindowSize from "../../../hooks/useWindowSize";
import IconWrapper from "../../IconWrapper";
import {
  CopyFilled,
  CopyOutlined,
  LinkOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import { Modal } from "../../../styles/ModalStyles";
import InputField from "../DaoProfile/InputField";
import { copyTextToClipboard } from "../../../utils";
import { DocumentDuplicateIcon, XCircleIcon } from "@heroicons/react/24/solid";

function DaoWebhooks() {
  const { daoDetails, daoId } = useDaoContext();

  const {
    daoDetails: { webhooks },
    reload,
  } = useContext(DaoContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [webhookData, setWebhookData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const { isMobile } = useWindowSize();
  const { account } = useEthers();
  const fetchWebhooks = () => {
    let resObj = {};
    webhookTypes.forEach((event) => {
      const eventExists = webhooks?.find(
        (ele) => event.key === ele.event && ele.webhookUrl
      );
      if (eventExists) resObj[event.key] = eventExists;
      else resObj[event.key] = null;
    });
    setWebhookData((prev) => ({ ...resObj }));

    setLoading(false);
  };
  const connectWebhook = ({ url, type }) => {
    console.log("first", url, type);
    customAxios
      .post("/dao/edit", {
        daoId: daoId,
        webhook: {
          webhookUrl: url,
          event: type,
        },
      })
      .then((res) => {
        reload();
        setLoading(true);
        setIsModalOpen(false);
        console.log(res);
      });
  };
  console.log("web", webhookData);
  const webhookTypes = [
    {
      label: "User entry",
      key: "USER_JOIN",
      icon: <UserAddOutlined style={{ color: "#06B6D4" }} />,
      description: "When a user joins your community",
    },

    {
      label: "User exit",
      key: "USER_LEAVE",
      icon: <UserDeleteOutlined style={{ color: "#F43F5E" }} />,
      description: "When a user leaves your community",
    },
    {
      label: "Permissions",
      key: "PERMISSION_CHANGE",
      icon: (
        <ShieldCheckIcon
          style={{ color: "#F59E0B", fontSize: "18px", width: "16px" }}
        />
      ),
      description: "When user permissions are changed in your community",
    },
  ];
  useEffect(() => {
    if (!daoId) return;
    fetchWebhooks();
  }, [daoId, webhooks]);

  return (
    <Fragment>
      {isModalOpen ? (
        <Modal
          width={isMobile ? "auto" : "600px"}
          height="auto"
          isMobile={isMobile}
          destroyOnClose
          centered={true}
          title={
            <h3 style={{ paddingLeft: "8px" }}>
              Webhook Registration: {url?.label}
            </h3>
          }
          visible={isModalOpen}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          footer={
            <Button
              type="primary"
              disabled={!url?.url?.trim()?.length}
              onClick={() => connectWebhook({ url: url.url, type: url.type })}
              style={{ borderRadius: "8px", background: "#3B82F6" }}
            >
              Register
            </Button>
          }
        >
          <Row style={{ padding: "12px 20px", width: "100%" }}>
            <InputField
              inputProps={{ autoFocus: true }}
              inputStyle={{ width: "100%" }}
              style={{ width: "100%" }}
              onChange={(e) => {
                setUrl((prev) => ({ ...prev, url: e.target.value }));
              }}
              placeholder={"Paste your URL here"}
              value={url?.url}
              label="Webhook URL"
            />
          </Row>
        </Modal>
      ) : null}
      <Col xs={24} style={{ padding: "0px", marginTop: "20px" }}>
        {isMobile ? null : (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #F4F2F2",
              borderRadius: "16px",
              padding: "12px 20px",
              background: "white",
              marginBottom: "12px",
              minHeight: "58px",
            }}
          >
            <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>Webhooks</h4>
            {/* <span style={{ fontSize: "12px", color: "#64748B" }}>
              {
                "Webhooks are automated messages sent from apps when something happens. Get updates on the whenever the following events occur in your community"
              }
            </span> */}
          </Row>
        )}
        {loading ? (
          <Row
            style={{
              background: "white",
              borderRadius: "12px",
              padding: "20px",
            }}
          >
            <Skeleton active />
          </Row>
        ) : (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
          >
            {webhookTypes.map((ele) => {
              return (
                <Row
                  xs={24}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "white",
                    borderRadius: "12px",
                    padding: "12px 20px",
                    rowGap: "8px",
                  }}
                >
                  <Row style={{ flexDirection: "column", rowGap: "4px" }}>
                    <Row style={{ alignItems: "center", columnGap: "4px" }}>
                      <IconWrapper>{ele.icon}</IconWrapper>
                      <h4 style={{ marginBottom: "0px" }}>{ele.label}</h4>
                    </Row>
                    <span style={{ fontSize: "12px", color: "#64748B" }}>
                      {ele.description}
                    </span>
                  </Row>
                  {webhookData && webhookData[ele?.key] ? (
                    <Row
                      style={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "8px",
                      }}
                    >
                      <Button
                        title="Copy URL"
                        onClick={() => {
                          copyTextToClipboard(
                            webhookData[ele?.key].webhookUrl
                          ).then((res) => {
                            toast.success("URL Copied");
                          });
                        }}
                        icon={
                          <IconWrapper>
                            <DocumentDuplicateIcon color="" width={20} />
                          </IconWrapper>
                        }
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "8px",
                          background: "#EFF6FF",
                          color: "#3B82F6",
                        }}
                        type={"text"}
                      ></Button>{" "}
                      <Button
                        title="Edit"

                        onClick={() => {
                          setIsModalOpen(true);
                          setUrl({
                            type: ele.key,
                            label: ele.label,
                            url: webhookData[ele?.key].webhookUrl,
                          });
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "8px",
                          background: "#EFF6FF",
                          color: "#3B82F6",
                        }}
                        type={"text"}
                      > Edit</Button>
                      <Button
                        // disabled={true}

                        type="error"
                        style={{
                          borderRadius: "12px",
                          padding: "6px 16px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          connectWebhook({ url: "", type: ele.key });
                        }}
                      >
                        Remove
                      </Button>
                    </Row>
                  ) : (
                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                        setUrl({ type: ele.key, label: ele.label });
                      }}
                      type="primary"
                      style={{
                        borderRadius: "12px",
                        background: "#3B82F6",
                        padding: "6px 16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Register
                    </Button>
                  )}{" "}
                </Row>
              );
            })}
          </div>
        )}
      </Col>
    </Fragment>
  );
}

export default DaoWebhooks;

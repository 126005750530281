import { Input } from "antd";

export const labeStyling = {
  color: "#64748B",
  fontSize: "12px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "16px",
};

const InputField = ({
  style,
  placeholder,
  onChange,
  value,
  label,
  type,
  inputStyle,
  prefix,
}) => {
  return (
    <div
      style={{
        ...labeStyling,
        marginBottom: "16px",
        ...style,
      }}
    >
      {label}
      {type === "textarea" ? (
        <Input.TextArea
          style={{
            borderRadius: "8px",
            margin: "4px 0px",
            height: "120px",
            ...inputStyle,
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      ) : (
        <Input
          style={{
            borderRadius: "8px",
            margin: "4px 0px",
            height: "40px",
            ...inputStyle,
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          prefix={prefix}
        />
      )}
    </div>
  );
};

export default InputField;

import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import customAxios from "../../api";
import Integrations from "../../components/Settings/Integrations";
import DaoProfile from "../../components/Settings/DaoProfile/DaoProfile";
import Permissions from "../../components/Settings/Permissions";
import Sidebar from "../../components/Settings/Sidebar";
import DaoOverview from "../DaoOverview";
import DaoManage from "../../components/Settings/DaoManage";
import DaoPayment from "../../components/Settings/DaoPayment";
import Visibility from "../../components/Settings/Visibility/Visibility";
import useWindowSize from "../../hooks/useWindowSize";
import axios from "axios";
import DaoWebhooks from "../../components/Settings/DaoWebhooks";
import DaoNotifications from "../../components/Settings/DaoNotifications";
import { useDaoContext } from "../../layouts/app/DaoContext";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);
function Settings() {
  const { daoDetails, daoId } = useDaoContext();

  const { isMobile } = useWindowSize();

  const navigate = useDaoLensNavigate();

  useEffect(() => {
    if (!daoId) return;
    customAxios
      .post(`settings/canview`, { daoId: daoId })
      .then((res) => {
        if (!res.data.canView) navigate(`/app/dao/${daoId}/overview`);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [daoId]);
  return (
    <div style={{ background: "#F6F5FF", width: "100%", height: "100vh" }}>
      <Row
        justify="center"
        // gutter={[16, 16]}
        style={{
          background: "#F6F5FF",
          columnGap: "12px",
          padding: isMobile ? "16px" : "0px",
        }}
      >
        <Col
          md={4}
          xs={24}
          style={{
            maxWidth: isMobile ? "100%" : "fit-content",
            padding: "0px",
          }}
        >
          <Sidebar />
        </Col>
        <Col md={10} xs={24} style={{ padding: "0px", position: "relative" }}>
          {/* <Spin
            size="large"
            indicator={antIcon}
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          /> */}
          <Routes>
            <Route path="/integration" element={<Integrations />} />
            <Route path="/profile" element={<DaoProfile />} />
            <Route path="/manage" element={<DaoManage />} />
            <Route path="/payments" element={<DaoPayment />} />
            <Route path="/visibility" element={<Visibility />} />
            <Route path="/notifications" element={<DaoNotifications />} />
            <Route path="/webhooks" element={<DaoWebhooks />} />

            <Route path="*" element={<Permissions />} />
          </Routes>
        </Col>
      </Row>
    </div>
  );
}

export default Settings;

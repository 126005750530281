import { ArrowRightIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Skeleton } from "antd";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
import useWindowSize from "../../../hooks/useWindowSize";
import { useDaoContext } from "../../../layouts/app/DaoContext";
import { fetchDocsInOview } from "../../../utils/Documents";
import CreateDocumentModal from "../../Course/Document/CreateDocumentModal";
import DocumentCard from "../../Course/Document/DocumentCard";
import CollectionCard from "./CollectionCard";

const StyledButton = styled.button`
  &:hover {
    background: #f3f4f6 !important;
  }
`;

type Props = {
  canEdit: boolean;
  setIsOnlyAboutSection: Dispatch<SetStateAction<boolean>>;
};

const Documents = ({ canEdit, setIsOnlyAboutSection }: Props) => {
  const { isMobile } = useWindowSize();
  const [isCreateDocumentModal, setIsCreateDocumentModal] = useState(false);
  const navigate = useDaoLensNavigate();
  const { daoDetails, daoId, daoName } = useDaoContext();

  const [updater, setUpdater] = useState(false);
  const { data, isLoading } = useQuery(
    ["documents", daoId, updater],
    () => fetchDocsInOview(daoId || ""),
    {
      onError: (error) => {
        console.error(error);
        toast.error("Something went wrong");
      },
      onSuccess: (data) => {
        if (data?.collection?.length !== 0 || data?.document?.length !== 0)
          setIsOnlyAboutSection(false);
      },
    }
  );

  const isEmpty =
    data?.collection?.length === 0 && data?.document?.length === 0;
  if (!canEdit && !isLoading && isEmpty) return null;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          padding: "24px 20px",
          background: "white",
          borderRadius: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              margin: 0,
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            📄 Community documents
          </h3>
          <CreateDocumentModal
            isModalVisible={isCreateDocumentModal}
            handleCancel={() => setIsCreateDocumentModal(false)}
            onSubmit={() => setUpdater((prev) => !prev)}
            isOverviewPinned
          />
          <button
            style={{
              display: "flex",
              alignItems: "center",
              background: "none",
              border: "none",
              gap: "4px",
              cursor: "pointer",
              color: "#6B7280",
            }}
            onClick={() => navigate(`/app/dao/${daoName}/${daoId}/library`)}
          >
            View all <ArrowRightIcon width={16} strokeWidth={2} />
          </button>
        </div>
        {isLoading && <Skeleton paragraph={{ rows: 4 }} />}
        {!isLoading && (
          <div
            style={{
              display: "grid",
              gap: "16px",
              gridTemplateColumns: isEmpty || isMobile ? "1fr" : "1fr 1fr",
            }}
          >
            {isEmpty && (
              <StyledButton
                style={{
                  background: "white",
                  display: "flex",
                  margin: 0,
                  flexDirection: "column",
                  gap: "4px",
                  border: "1px dashed #D1D5DB",
                  color: "#6B7280",
                  fontSize: "14px",
                  borderRadius: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: isMobile ? "16px" : "16px",
                  paddingTop: "24px",
                  paddingBottom: isEmpty ? "24px" : "",
                  cursor: "pointer",
                  width: "100%",
                  height: isEmpty ? "" : "calc(100% - 20px)",
                  minHeight: isEmpty || isMobile ? "" : "200px",
                }}
                onClick={() => setIsCreateDocumentModal(true)}
              >
                <div>
                  <PlusIcon
                    width={isEmpty ? 24 : 36}
                    strokeWidth={2}
                    style={{
                      height: "24px",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                  }}
                >
                  <span style={{ fontSize: isEmpty ? "14px" : "16px" }}>
                    Add document
                  </span>
                  {isEmpty && (
                    <span style={{ fontSize: "12px" }}>
                      Pin essential documents, like whitepapers and guidelines
                    </span>
                  )}
                </div>
              </StyledButton>
            )}

            {!isEmpty && canEdit && (
              <StyledButton
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#6B7280",
                  background: "none",
                  border: "1px dashed #D1D5DB",
                  borderRadius: "12px",
                  padding: "12px",
                  cursor: "pointer",
                  gap: "8px",
                }}
                onClick={() => setIsCreateDocumentModal(true)}
              >
                <PlusIcon width={16} color="#6B7280" />
                Add document
              </StyledButton>
            )}
            {data?.collection.map((collection) => (
              <CollectionCard key={collection.id} collection={collection} />
            ))}
            {data?.document.map((doc) => (
              <DocumentCard
                document={doc}
                reload={() => setUpdater((prev) => !prev)}
                isWhiteSpaceWrapNotAllowed
                isOnOverviewPage
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Documents;

import moment from "moment/moment";
import "../ComponentStyles/CommentBody.scss";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import dummyUser from "../../assets/images/login/defaultUser.jpeg";
import CommentReplyInput from "./CommentReplyInput";
import replyArrow from "../../assets/iconSvg/replyArrow.svg";
import ReactHtmlParser from "react-html-parser";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Typography } from "antd";
import styled from "styled-components";
import { useMutation } from "react-query";
import { deleteComment, getReplyMention } from "../../utils/comments";
import { UserContext } from "../../layouts/app/UserContext";
import { checkIsInViewport, confirmOperation } from "../../utils/common";
import Reactions from "./Reactions";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import useWindowSize from "../../hooks/useWindowSize";
import EditComment from "./EditComment";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";
import ProfileCardPopup from "../Common/ProfileCard";
const { Paragraph } = Typography;
const StyledParagraph = styled(Paragraph)<{ viewfull?: boolean }>`
  p {
    margin-bottom: 0;
  }

  .meta {
    display: none;
  }
  img {
    max-width: 100%;
    margin: 8px 0px;
    object-fit: contain;
  }
  .emoji {
    width: 20px;
    height: 20px;
  }
  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  h4 {
    font-size: 12px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h5 {
    font-size: 10px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: #0f172a;
  }
  .mention {
    background: none;
    color: #3b82f6;
    margin: 0;
    & span {
      margin: 0;
    }
  }
`;

const ShowRepliesButton = styled.button`
  &:hover {
    text-decoration: underline;
  }
`;

export const CommentActionButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: #6b7280;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const HIGHLIGHT_DURATION = 1 * 1000;

type Props = {
  commentDetails: any;
  canEngage?: boolean;
  key: any;
  isReply?: boolean;
  isNestedReply?: boolean;
  defaultReplyLevel: any;
  replyReferenceForMention: any;
  postComment: any;
  entity: any;
  fetchParentReplies: any;
  authorUserName: boolean;
  isFirst?: boolean;
  level2ParentId?: string;
  highlightedCommentId: string;
  setHighlightedCommentId: Dispatch<SetStateAction<string>>;
  checkIsEnabled?: () => boolean;
  minimumCharLimit?: number;
};

function CommentBody({
  commentDetails,
  authorUserName,
  canEngage = true,
  key,
  isReply,
  isNestedReply,
  defaultReplyLevel,
  replyReferenceForMention,
  postComment,
  entity,
  fetchParentReplies,
  isFirst,
  level2ParentId,
  highlightedCommentId,
  setHighlightedCommentId,
  checkIsEnabled = undefined,
  minimumCharLimit = 0,
}: Props) {
  const navigate = useDaoLensNavigate();
  const { isMobile } = useWindowSize();

  const { userDetails } = useContext(UserContext);
  const [viewfull, setViewFull] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showReplyBar, setShowReplyBar] = useState(false);
  const [isRepliesOpen, setIsRepliesOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const { mutate: deleteCurrentComment } = useMutation({
    mutationFn: ({ entity, id }: { entity: string; id: string }) =>
      deleteComment(entity, id),
    onError: (error) => {
      console.error(error);
      toast.error("Something went wrong while deleting comment");
    },
    onSuccess: () => {
      toast.success("Comment Deleted Successfully");
      fetchParentReplies();
    },
  });

  const replyHandler = (state: any) => {
    setShowReplyBar(state);
  };

  const isCurrentUserComment =
    (userDetails as any)?.userName === commentDetails?.userName;

  const allReplies =
    commentDetails?.level2Children?.sort(
      (commentA, commentB) =>
        new Date(commentA.createdAt).getTime() -
        new Date(commentB.createdAt).getTime()
    ) || [];

  return (
    <>
      <div
        key={key}
        style={{
          position: "relative",
          paddingBottom: allReplies?.length > 0 ? 0 : "16px",
          paddingTop: "16px",
          borderTop:
            !isFirst && defaultReplyLevel === 1 ? "1px solid #F3F4F6" : "",
          background:
            highlightedCommentId === commentDetails.id ? "#FFFBEB" : "white",
          paddingLeft: isReply ? "48px" : 0,
          lineHeight: "2",
        }}
        id={commentDetails.id}
      >
        {isEditing && (
          <EditComment
            commentId={commentDetails?.id}
            content={commentDetails?.description}
            userTagged={commentDetails?.taggedUsers}
            setIsEditing={setIsEditing}
            onSuccess={() => {
              fetchParentReplies();
              setIsEditing(false);
            }}
            isNestedReply={isNestedReply}
            entity={entity}
            minimumCharLimit={minimumCharLimit}
          />
        )}
        {!isEditing && (
          <div
            style={{
              display: "flex",
              gap: "12px",
              paddingBottom: allReplies?.length > 0 ? "16px" : 0,
            }}
          >
            <ProfileCardPopup userId={commentDetails?.userId}>
              <img
                src={
                  commentDetails.profilePicture
                    ? commentDetails.profilePicture
                    : dummyUser
                }
                onClick={() =>
                  navigate(`/app/profile/${commentDetails.userId}`)
                }
                style={{
                  width: defaultReplyLevel !== 1 ? "24px" : "40px",
                  height: defaultReplyLevel !== 1 ? "24px" : "40px",
                  zIndex: 2,
                  borderRadius: "50%",
                }}
                alt={commentDetails?.userName}
              />
            </ProfileCardPopup>
            <div
              style={{
                width: "calc(100% - 52px)",
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                gap: "0px",
              }}
            >
              <div
                style={{ display: "flex", columnGap: "8px", flexWrap: "wrap" }}
              >
                <ProfileCardPopup userId={commentDetails?.userId}>
                  <button
                    onClick={() =>
                      navigate(`/app/profile/${commentDetails.userId}`)
                    }
                    style={{
                      cursor: "pointer",
                      color: "#000000",
                      fontSize: "14px",
                      background: "none",
                      border: "none",
                      fontWeight: "500",
                      padding: "0",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: isMobile ? "125px" : "200px",
                    }}
                  >
                    {commentDetails.userName}
                  </button>
                </ProfileCardPopup>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  {authorUserName === commentDetails.userName && (
                    <span
                      style={{
                        color: "#10B981",
                        background: "#ECFDF5",
                        borderRadius: "8px",
                        padding: "2px 8px",
                      }}
                    >
                      Author
                    </span>
                  )}
                  <span
                    className="time"
                    style={{ color: "#6B7280", fontSize: "12px" }}
                  >
                    {moment(commentDetails.createdAt).fromNow()}
                  </span>
                </div>
              </div>
              {defaultReplyLevel >= 3 && (
                <button
                  style={{
                    background: "#F9FAFB",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    gap: "10px",
                    padding: "4px 10px",
                    font: "normal 500 12px/16px Inter",
                    color: "#64748B",
                    position: "relative",
                    marginBottom: "4px",
                    border: "none",
                    cursor: "pointer",
                    zIndex: 2,
                    margin: "8px 0",
                  }}
                  onClick={() => {
                    setHighlightedCommentId(commentDetails?.parentId);
                    setTimeout(
                      () => setHighlightedCommentId(""),
                      HIGHLIGHT_DURATION
                    );
                    const element = document.getElementById(
                      `${commentDetails?.parentId}`
                    );

                    if (!checkIsInViewport(element)) {
                      element?.scrollIntoView();
                    }
                  }}
                >
                  <img src={replyArrow} alt="" />
                  <StyledParagraph
                    style={{
                      marginBottom: 0,
                      display: "flex",
                      gap: "4px",
                      // maxWidth: "80%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      alignItems: "center",
                    }}
                  >
                    {ReactHtmlParser(replyReferenceForMention)}
                  </StyledParagraph>
                </button>
              )}
              <div
                style={{
                  backgroundColor: isReply ? "transparent" : "#ffffff",
                  wordBreak: "break-word",
                  fontWeight: "400",
                  color: "#1F2937",
                  overflow: "hidden",
                }}
              >
                <StyledParagraph
                  viewfull={viewfull}
                  style={{
                    marginBottom: "4px",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    setViewFull((prev) => !prev);
                  }}
                  ellipsis={{
                    rows: 10,
                    expandable: true,
                    symbol: <span>Read more</span>,
                  }}
                >
                  {ReactHtmlParser(commentDetails.description)}
                </StyledParagraph>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  flexWrap: "wrap",
                }}
              >
                {canEngage ? (
                  <>
                    <RepliesAction
                      repliesCount={allReplies?.length}
                      clickHandler={() => {
                        if (checkIsEnabled && !checkIsEnabled?.()) {
                          return;
                        }
                        fetchParentReplies();

                        replyHandler(!showReplyBar);
                        setIsRepliesOpen(true);
                      }}
                    />
                    <div
                      style={{
                        width: 4,
                        height: 4,
                        background: "#D1D5DB",
                        borderRadius: "100%",
                      }}
                    />
                  </>
                ) : null}
                {isCurrentUserComment ? (
                  <>
                    <CommentActionButton
                      onClick={() => {
                        if (checkIsEnabled && !checkIsEnabled?.()) {
                          return;
                        }
                        setIsEditing(true);
                      }}
                    >
                      Edit
                    </CommentActionButton>
                    <div
                      style={{
                        width: 4,
                        height: 4,
                        background: "#D1D5DB",
                        borderRadius: "100%",
                      }}
                    />
                    <CommentActionButton
                      onClick={() => {
                        if (checkIsEnabled && !checkIsEnabled?.()) {
                          return;
                        }
                        confirmOperation({
                          onOk: () => {
                            deleteCurrentComment({
                              entity,
                              id: commentDetails?.id,
                            });
                          },
                          cancelText: "cancel",
                          confirmText: "Delete",
                          message:
                            "Are you sure you want to delete this comment?",
                          title: "Delete comment",
                        });
                      }}
                    >
                      Delete
                    </CommentActionButton>
                    {canEngage ? (
                      <div
                        style={{
                          width: 4,
                          height: 4,
                          background: "#D1D5DB",
                          borderRadius: "100%",
                        }}
                      />
                    ) : null}
                  </>
                ) : null}
                {canEngage ? (
                  <Reactions
                    commentId={commentDetails?.id}
                    entity={entity}
                    reactions={commentDetails?.reactions}
                    checkIsEnabled={checkIsEnabled}
                  />
                ) : null}
              </div>
              {allReplies?.length !== 0 && (
                <ShowRepliesButton
                  onClick={() => {
                    setIsRepliesOpen((prev) => !prev);
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    padding: 0,
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "4px",
                    color: "#3B82F6",
                    cursor: "pointer",
                    width: "fit-content",
                  }}
                >
                  <ChevronDownIcon
                    width={12}
                    strokeWidth={4}
                    style={{ rotate: isRepliesOpen ? "180deg" : "" }}
                  />
                  {allReplies?.length}{" "}
                  {allReplies?.length === 1 ? "reply" : "replies"}
                </ShowRepliesButton>
              )}
            </div>
          </div>
        )}
        <div style={{ paddingLeft: isReply ? 0 : "48px" }}>
          {showReplyBar && (
            <CommentReplyInput
              isNestedReply={defaultReplyLevel > 1 && commentDetails}
              level2ParentId={level2ParentId || commentDetails?.id}
              postComment={postComment}
              parentId={commentDetails.id}
              parentLoader={showLoader}
              setShowParentLoader={setShowLoader}
              onSubmit={() => {
                fetchParentReplies();
                setShowReplyBar(false);
                setShowLoader(false);
              }}
              isFocused
              isSmallProfilePicture
              onCancel={() => setShowReplyBar(false)}
            />
          )}
        </div>
      </div>
      {isRepliesOpen &&
        allReplies?.length > 0 &&
        allReplies?.map((singleReply, index) => {
          return (
            <CommentBody
              entity={entity}
              replyReferenceForMention={getReplyMention(
                allReplies?.find(
                  (comment) => comment?.id === singleReply?.parentId
                )
              )}
              defaultReplyLevel={
                defaultReplyLevel +
                (singleReply?.parentId !== commentDetails.id ? 2 : 1)
              }
              isNestedReply={commentDetails}
              commentDetails={singleReply}
              key={index}
              level2ParentId={level2ParentId || commentDetails?.id}
              isReply
              postComment={postComment}
              fetchParentReplies={fetchParentReplies}
              authorUserName={authorUserName}
              highlightedCommentId={highlightedCommentId}
              setHighlightedCommentId={setHighlightedCommentId}
            />
          );
        })}
    </>
  );
}

function RepliesAction({ repliesCount, clickHandler }: any) {
  const [, setIsSelected] = useState(false);
  const [localCount, setLocalCount] = useState(repliesCount);
  const { userDetails, showLoginModal } = useContext(UserContext);

  const toggleHandler = () => {
    if (!userDetails) {
      showLoginModal();
      return;
    }
    setIsSelected((prevState) => {
      if (prevState) {
        setLocalCount(localCount - 1);
      } else setLocalCount(localCount + 1);
      return !prevState;
    });
    clickHandler();
  };
  return (
    <CommentActionButton
      onClick={toggleHandler}
      style={{
        background: "none",
        border: "none",
        padding: 0,
        margin: 0,
        fontSize: "14px",
        color: "#6B7280",
      }}
    >
      Reply
    </CommentActionButton>
  );
}
export default CommentBody;

import { PushpinFilled } from "@ant-design/icons";
import pinImage from "../images/pin.svg";
import IconWrapper from "./IconWrapper";

export const DiscordIcon = (props) => {
  return (
    <svg
      viewBox="0 0 21 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.0917 4.67141C15.797 4.08454 14.4128 3.65802 12.9655 3.41516C12.7878 3.7265 12.5801 4.14526 12.437 4.47838C10.8985 4.25422 9.37423 4.25422 7.86408 4.47838C7.72094 4.14526 7.50858 3.7265 7.32925 3.41516C5.88045 3.65802 4.49458 4.0861 3.19994 4.67452C0.588649 8.49768 -0.119229 12.2259 0.234711 15.9012C1.96666 17.1543 3.64512 17.9155 5.29526 18.4136C5.70269 17.8703 6.06606 17.2928 6.37909 16.6842C5.78291 16.4647 5.21189 16.1938 4.67234 15.8794C4.81548 15.7766 4.95549 15.6692 5.09076 15.5587C8.38161 17.05 11.9572 17.05 15.2087 15.5587C15.3456 15.6692 15.4856 15.7766 15.6272 15.8794C15.086 16.1954 14.5134 16.4662 13.9172 16.6857C14.2303 17.2928 14.5921 17.8719 15.0011 18.4152C16.6528 17.917 18.3328 17.1558 20.0648 15.9012C20.4801 11.6406 19.3553 7.94662 17.0917 4.67141ZM6.82743 13.6409C5.83955 13.6409 5.02941 12.7474 5.02941 11.6593C5.02941 10.5712 5.82225 9.67607 6.82743 9.67607C7.83263 9.67607 8.64275 10.5696 8.62545 11.6593C8.62701 12.7474 7.83263 13.6409 6.82743 13.6409ZM13.4721 13.6409C12.4842 13.6409 11.674 12.7474 11.674 11.6593C11.674 10.5712 12.4669 9.67607 13.4721 9.67607C14.4772 9.67607 15.2874 10.5696 15.2701 11.6593C15.2701 12.7474 14.4772 13.6409 13.4721 13.6409Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ArrowRight = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14 5l7 7m0 0l-7 7m7-7H3"
      />
    </svg>
  );
};

export function SymbolsAddCircle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="linear-gradient(90deg, #4facfe 0%, #00f2fe 100%)"
        d="M11 17h2v-4h4v-2h-4V7h-2v4H7v2h4Zm1 5q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z"
      ></path>
    </svg>
  );
}

export const PinIcon = ({ style = {} }) => (
  <PushpinFilled style={{ color: "#6A7280", ...style }} />
);
export const PaperClipIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.6425 5.83339L7.15412 11.3217C6.99494 11.4755 6.86797 11.6594 6.78062 11.8627C6.69327 12.0661 6.64729 12.2848 6.64537 12.5061C6.64345 12.7274 6.68562 12.9468 6.76942 13.1516C6.85322 13.3565 6.97698 13.5426 7.13346 13.699C7.28995 13.8555 7.47604 13.9793 7.68087 14.0631C7.88569 14.1469 8.10516 14.1891 8.32646 14.1871C8.54776 14.1852 8.76646 14.1392 8.9698 14.0519C9.17314 13.9645 9.35704 13.8376 9.51079 13.6784L14.8558 8.19006C15.463 7.56138 15.799 6.71937 15.7914 5.84538C15.7838 4.9714 15.4332 4.13535 14.8152 3.51733C14.1972 2.8993 13.3611 2.54874 12.4871 2.54114C11.6131 2.53355 10.7711 2.86953 10.1425 3.47672L4.79662 8.96422C3.85886 9.90198 3.33203 11.1739 3.33203 12.5001C3.33203 13.8263 3.85886 15.0981 4.79662 16.0359C5.73438 16.9737 7.00626 17.5005 8.33246 17.5005C9.65865 17.5005 10.9305 16.9737 11.8683 16.0359L17.0825 10.8334"
        stroke="#6B7280"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const RejectCross = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L6.58579 8L5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071C5.68342 11.0976 6.31658 11.0976 6.70711 10.7071L8 9.41421L9.29289 10.7071C9.68342 11.0976 10.3166 11.0976 10.7071 10.7071C11.0976 10.3166 11.0976 9.68342 10.7071 9.29289L9.41421 8L10.7071 6.70711C11.0976 6.31658 11.0976 5.68342 10.7071 5.29289C10.3166 4.90237 9.68342 4.90237 9.29289 5.29289L8 6.58579L6.70711 5.29289Z"
        fill="#DC2626"
      />
    </svg>
  );
};
export const ApproveCheck = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z"
        fill="#059669"
      />
    </svg>
  );
};
export const CommentIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.00016C2.5 4.07969 3.24619 3.3335 4.16667 3.3335H12.5C13.4205 3.3335 14.1667 4.07969 14.1667 5.00016V10.0002C14.1667 10.9206 13.4205 11.6668 12.5 11.6668H9.16667L6.32149 14.512L5.83333 15.0002V11.6668H4.16667C3.24619 11.6668 2.5 10.9206 2.5 10.0002V5.00016Z"
        fill="#F9FAFB"
      />
      <path
        d="M14.1667 6.66683H15.8333C16.7538 6.66683 17.5 7.41302 17.5 8.3335V13.3335C17.5 14.254 16.7538 15.0002 15.8333 15.0002H14.1667V18.3335L10.8333 15.0002H7.5C7.03976 15.0002 6.6231 14.8136 6.32149 14.512M6.32149 14.512L9.16667 11.6668H12.5C13.4205 11.6668 14.1667 10.9206 14.1667 10.0002V5.00016C14.1667 4.07969 13.4205 3.3335 12.5 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V10.0002C2.5 10.9206 3.24619 11.6668 4.16667 11.6668H5.83333V15.0002L6.32149 14.512Z"
        stroke="#6B7280"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

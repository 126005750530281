import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input, Select, Switch } from "antd";
import { StyledModalWrapper } from "../CreateBountyModal";
import { Modal } from "../../styles/ModalStyles";
import useWindowSize from "../../hooks/useWindowSize";
import styles from "../Course/course.module.css";
import Upload from "../Common/form/Upload";
import { checkFileSize, checkImageType, getCurrentPath } from "../../utils";
import moment from "moment-timezone";
import { LinkIcon, MapIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "react-query";
import { createNewEvents, editEvent, fetchDaoUsers } from "../../utils/Events";
import { toast } from "react-toastify";
import styled from "styled-components";
import XPField from "../Common/form/XPField";
import defaultUser from "./../../assets/images/login/defaultUser.jpeg";
import { UserContext } from "../../layouts/app/UserContext";
import QuillEditor from "../QuillEditor";
import DateTimePicker from "../Common/DateTime";
import IconWrapper from "../IconWrapper";
import { useDaoContext } from "../../layouts/app/DaoContext";
import useGating from "../../hooks/useGating";
import GatingModal from "../Gating/GatingModal";

import { useLocation } from "react-router";
import DiscordSyncModal from "../DiscordSyncModal";
import TimezoneDropdwon from "../Common/TimezoneDropdwon";
import { confirmOperation } from "../../utils/common";
const EVENT_LOCATION_TYPE = {
  online: "🌐 Online",
  inPerson: "📍 In-person",
};

const StyledSelect = styled(Select)`
  & .ant-select-selector {
    border-radius: 12px !important;
    padding: 4px 8px;
    border: 1px solid rgb(226, 232, 240);
  }
  & span {
    font-size: 14px !important;
    border-radius: 8px !important;
  }
`;

const StyledModalTitleInput = styled(Input)`
  padding: 0px 0px;
  margin: 8px 0px;
  font-size: 20px;
  &::placeholder {
    font-weight: 600;
    color: #94a3b8;
  }
`;

const LocationButton = styled.button`
  &:hover {
    background: #f3f4f6 !important;
    border: 1px solid #3f83f8 !important;
  }
`;

const CreateEventsModal = ({
  isOpen,
  setIsOpen,
  onSuccess,
  isEdit = false,
  eventDetail,
}) => {
  const { daoDetails, daoId } = useDaoContext();
  console.log(eventDetail)

  const [mentionedUsers, setMentionedUsers] = useState([]);
  const location = useLocation();
  const [createDiscordEvent, setCreateDiscordEvent] = useState(
    isEdit ? eventDetail?.createDiscord : true
  );
  const isDiscordConnected = !!daoDetails.discordGuildId;
  const { userDetails } = useContext(UserContext);
  const gatingState = useGating();
  const { data: daoUsers } = useQuery(["dao-users", daoId], {
    queryFn: () => fetchDaoUsers(daoId),
    onError: (error) => {
      console.error(error);
      toast.error("Something went wrong while fetch users.");
    },
  });

  const { mutate: createEvent, isLoading } = useMutation({
    mutationFn: (data) => createNewEvents(data, gatingState),
    onSuccess: () => {
      setIsOpen(false);
      onSuccess();
    },
    onError: (error) => {
      console.error(error);
      if (error.message === "Form error") return;
      toast.error("Something went wrong while creating event.");
    },
  });
  const { mutate: editCurrEvent, isLoading: isEditLoading } = useMutation({
    mutationFn: (data) => editEvent(data, gatingState),
    onSuccess: () => {
      setIsOpen(false);
      onSuccess();
    },
    onError: (error) => {
      console.error(error);
      if (error.message === "Form error") return;
      toast.error("Something went wrong while creating event.");
    },
  });

  const { isMobile } = useWindowSize();
  const [title, setTitle] = useState(eventDetail?.title || "");
  const [isUploadCompUpdated, setIsUploadCompUpdated] = useState(false);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const [coverImage, setCoverImage] = useState(
    eventDetail?.icon ? eventDetail?.icon : null
  );
  const [description, setDescription] = useState(
    eventDetail?.description || ""
  );
  const [startDate, setStartDate] = useState(
    isEdit
      ? moment(eventDetail?.eventAt, "YYYY-MM-DD HH:mmZ")
      : moment().add(15 - (moment().minute() % 15), "minutes")
  );
  const [endDate, setEndDate] = useState(
    isEdit
      ? moment(eventDetail?.eventEndAt, "YYYY-MM-DD HH:mmZ")
      : moment().add(15 - (moment().minute() % 15) + 60, "minutes")
  );
  const [locationType, setLocationType] = useState(
    isEdit
      ? eventDetail?.type !== "ONLINE"
        ? EVENT_LOCATION_TYPE.inPerson
        : EVENT_LOCATION_TYPE.online
      : EVENT_LOCATION_TYPE.online
  );
  const [inPersonLocation, setInPersonLocation] = useState(
    eventDetail?.type !== "ONLINE" ? eventDetail?.link || "" : ""
  );
  const [linkLocation, setLinkLocation] = useState(
    eventDetail?.type === "ONLINE" ? eventDetail?.link || "" : ""
  );
  const [hosts, setHosts] = useState(
    eventDetail
      ? eventDetail?.hostDetails?.map((host) => host.userId)
      : [userDetails.id]
  );
  const [syncModalOpen, setSyncModalOpen] = useState(false);
  const [taskPoint, setTaskPoint] = useState(eventDetail?.taskPoint || 100);

  useEffect(() => {
    if (eventDetail) {
      gatingState.setGatingState(
        eventDetail?.gatingConditionId,
        eventDetail.gatingCondition
      );
    }
    const modalData = localStorage.getItem("eventModalData");
    const data = JSON.parse(modalData);

    if (data) {
      if (moment().diff(data.timestamp, "hours") < 1) {
        if (data.coverImage) setCoverImage(JSON.parse(data.coverImage));

        if (data.description) setDescription(data.description);
        if (data.title) setTitle(data.title);
        if (data.mentionedUsers) setMentionedUsers(data.mentionedUsers);

        if (data.locationType) setLocationType(data.locationType);
        if (data.linkLocation) setLinkLocation(data.linkLocation);
        if (data.hosts) setHosts(data.hosts);
        if (data.inPersonLocation) setInPersonLocation(data.inPersonLocation);
        if (data.startDate) setStartDate(moment(data.startDate));
        if (data.endDate) setEndDate(moment(data.endDate));
        if (data.timezone) setTimezone(data.timezone);
        setTimeout(() => {
          localStorage.removeItem("eventModalData");
        }, 3000);
      }
    } else {
      localStorage.removeItem("eventModalData");
    }

    return () => {
      moment.tz.setDefault(moment.tz.guess());
    };
  }, []);

  const handleCreateOrEdit = () => {
    const data = {
      userTagged: mentionedUsers,
      daoId,
      description,

      eventAt: startDate.format("YYYY-MM-DD HH:mmZ"),
      eventEndAt: endDate.format("YYYY-MM-DD HH:mmZ"),
      hostIds: hosts,
      link:
        locationType === EVENT_LOCATION_TYPE.online
          ? linkLocation
          : inPersonLocation,
      title,
      type:
        locationType === EVENT_LOCATION_TYPE.online ? "ONLINE" : "IN_PERSON",
      image: coverImage,
      taskPoint,
    };

    isEdit
      ? editCurrEvent({ ...data, eventId: eventDetail?.id })
      : createEvent({
        ...data,
        createDiscord: isDiscordConnected ? createDiscordEvent : false,
      });
  };

  return (
    <StyledModalWrapper>
      {syncModalOpen ? (
        <DiscordSyncModal
          syncModalOpen={syncModalOpen}
          onClose={() => setSyncModalOpen(false)}
          discordConnectionUrl={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_ENDPOINT === "development"
            ? "1046753505026785401"
            : "1014817083139301417"
            }&redirect_uri=${encodeURIComponent(window.location.origin) +
            "/app/redirect/discordIntgeration"
            }&response_type=code&state=${btoa(
              JSON.stringify({
                daoId,
                from: getCurrentPath(),

                openCreateModal: true,
              })
            )}&from=${btoa("eventCreation")}&scope=identify%20guilds`}
        />
      ) : null}
      <Modal
        visible={isOpen}
        destroyOnClose
        isMobile={isMobile}
        footerPadding={"0px"}
        centered={true}
        maskStyle={{
          background: "rgba(0, 0, 0, 0.5",
          backdropFilter: "blur(20px)",
        }}
        width="700px"
        onCancel={() =>
          confirmOperation({
            cancelText: "Discard Changes",
            confirmText: "Continue Editing",
            message: " You will lose the progress if you close this pop-up?",
            title: "Confirm",
            onCancel: () => setIsOpen(false),
          })
        }
        footer={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {!isEdit && !isDiscordConnected ? (
              <div
                style={{
                  width: "100%",

                  background: "#EFF6FF",
                  display: "flex",
                  padding: "8px 20px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconWrapper style={{ marginRight: "0.5rem" }}>
                    <i
                      class="fab fa-discord"
                      style={{
                        color: "rgb(59, 130, 246)",
                      }}
                    ></i>
                  </IconWrapper>
                  <h5
                    style={{
                      marginBottom: "0px",
                      textAlign: "left",
                      color: "#374151",
                      fontSize: isMobile ? "12px" : "14px",
                    }}
                  >
                    Sync with your Discord server to show your events there as
                    well{" "}
                  </h5>
                </div>
                <Button
                  type="text"
                  onClick={() => {
                    localStorage.setItem(
                      "eventModalData",
                      JSON.stringify({
                        title,
                        description: description,
                        coverImage: JSON.stringify(coverImage),
                        startDate: startDate,
                        linkLocation,
                        inPersonLocation,
                        endDate: endDate,
                        mentionedUsers,
                        hosts,
                        timestamp: moment.utc(),
                        locationType: locationType,
                        timezone,
                      })
                    );
                    setSyncModalOpen(true);
                  }}
                  style={{
                    color: "rgb(59, 130, 246",
                    fontSize: isMobile ? "12px" : "14px",
                  }}
                  size="small"
                >
                  Sync Discord
                </Button>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "end",
                gap: "16px",
                padding: "10px 16px",
                alignItems: "center",
              }}
            >
              <GatingModal finalGatingState={gatingState} />
              <XPField
                value={taskPoint}
                onChange={(e) => {
                  setTaskPoint(e);
                }}
              />
              <Button
                style={{
                  fontSize: "14px",
                  padding: "6px 16px",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="primary"
                color="#3B82F6"
                disabled={isLoading || isEditLoading}
                onClick={handleCreateOrEdit}
              >
                {isEdit ? "Update" : "Create"}
              </Button>
            </div>
          </div >
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 35px 24px 20px",
            width: "100%",
            gap: "24px",
            marginBottom: isDiscordConnected ? "0px" : "4rem",
          }}
        >
          <div
            style={{
              position: "sticky",
              top: 0,
              background: "#ffffff",
              zIndex: 100,
              paddingTop: "24px",
            }}
          >
            <StyledModalTitleInput
              style={{
                fontSize: "21px",
                fontWeight: "600",
              }}
              placeholder="Add title of the event"
              size="large"
              bordered={false}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <label
              style={{ fontSize: "12px", fontWeight: "600", color: "#64748B" }}
            >
              Event cover
            </label>
            <Upload
              setIsUpdated={setIsUploadCompUpdated}
              label={""}
              suggestedSize="1920 * 1080"
              isUpdated={isUploadCompUpdated}
              data={coverImage}
              onChange={(newFile) => {
                checkImageType(newFile.type);
                checkFileSize(newFile.size);
                const res = {};

                Object.entries(newFile).map((ele) => {
                  res[ele] = newFile[ele];
                });
                setCoverImage(newFile);
              }}
              width="100%"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <label
              style={{ fontSize: "12px", fontWeight: "600", color: "#64748B" }}
            >
              Event details
            </label>
            <QuillEditor
              height="200px"
              value={description}
              style={{
                padding: "0px 0px 4px 0px",
                resize: "none",
                overflowY: "auto",
              }}
              isBordered={true}
              onChange={(e) => setDescription(e)}
              placeholder="A short note to the attendees on why they need to join this event"
              setMentionedUsers={setMentionedUsers}
            />
          </div>
          <div className="flex flex-col gap-3">
            <label
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#64748B",
              }}
            >
              Date & Time
            </label>
            <div className="flex gap-3 flex-wrap items-center">
              <DateTimePicker
                dateTime={startDate}
                startAfterDate={moment().subtract(1, "day")}
                setDateTime={setStartDate}
                onChange={(newStartDate) =>
                  setEndDate(moment(newStartDate).add(60, "minutes"))
                }
                timezone={timezone}
              />
              <span>to</span>
              <DateTimePicker
                dateTime={endDate}
                startAfterDate={moment(startDate).subtract(1, "day")}
                setDateTime={setEndDate}
                timezone={timezone}
              />
            </div>
          </div>
          <TimezoneDropdwon
            setTimezone={(newTz) => {
              setTimezone(newTz);
              setStartDate((prev) => prev.tz(newTz));
              setEndDate((prev) => prev.tz(newTz));
            }}
            timezone={timezone}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "16px",
              gap: "12px",
              background: "#F9FAFB",
              borderRadius: "16px",
            }}
          >
            <label
              style={{ fontSize: "12px", fontWeight: "600", color: "#64748B" }}
            >
              Where is this happening?
            </label>
            <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
              <LocationButton
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  background:
                    locationType === EVENT_LOCATION_TYPE.online
                      ? "#EBF5FF"
                      : "#FFFFFF",
                  color:
                    locationType === EVENT_LOCATION_TYPE.online
                      ? "#3F83F8"
                      : "#666666",
                  border:
                    locationType === EVENT_LOCATION_TYPE.online
                      ? "1px solid #3F83F8"
                      : "1px solid #E2E8F0",
                }}
                onClick={() => setLocationType(EVENT_LOCATION_TYPE.online)}
              >
                {EVENT_LOCATION_TYPE.online}
              </LocationButton>
              <LocationButton
                style={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  background:
                    locationType === EVENT_LOCATION_TYPE.inPerson
                      ? "#EBF5FF"
                      : "#FFFFFF",
                  color:
                    locationType === EVENT_LOCATION_TYPE.inPerson
                      ? "#3F83F8"
                      : "#666666",
                  border:
                    locationType === EVENT_LOCATION_TYPE.inPerson
                      ? "1px solid #3F83F8"
                      : "1px solid #E2E8F0",
                }}
                onClick={() => setLocationType(EVENT_LOCATION_TYPE.inPerson)}
              >
                {EVENT_LOCATION_TYPE.inPerson}
              </LocationButton>
            </div>
            <Input
              prefix={
                EVENT_LOCATION_TYPE.online === locationType ? (
                  <LinkIcon color="#97A6BA" width="16px" strokeWidth={2} />
                ) : (
                  <MapIcon color="#97A6BA" width="16px" strokeWidth={2} />
                )
              }
              style={{
                border: "1px solid #E2E8F0",
                borderRadius: "12px",
                padding: "8px 12px",
              }}
              placeholder={
                EVENT_LOCATION_TYPE.online === locationType
                  ? "Add link for zoom, twitter space, discord space etc..."
                  : "Add google maps location link"
              }
              value={
                EVENT_LOCATION_TYPE.online === locationType
                  ? linkLocation
                  : inPersonLocation
              }
              onChange={(e) =>
                EVENT_LOCATION_TYPE.online === locationType
                  ? setLinkLocation(e.currentTarget.value)
                  : setInPersonLocation(e.currentTarget.value)
              }
            />
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <label
              style={{ fontSize: "12px", fontWeight: "600", color: "#64748B" }}
            >
              Event host
            </label>
            <StyledSelect
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Please choose the hosts for this event"
              className="event-host-selector"
              defaultValue={[]}
              value={hosts}
              onChange={(e) => {
                setHosts(e);
              }}
              dropdownStyle={{ borderRadius: "12px" }}
            >
              {daoUsers?.map((user) => (
                <Select.Option value={user.id}>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      src={
                        user.profilePicture ? user.profilePicture : defaultUser
                      }
                      style={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "100%",
                      }}
                      alt={user.profileName}
                    />
                    {user.profileName}
                  </div>
                </Select.Option>
              ))}
            </StyledSelect>
          </div>
          {!isEdit && isDiscordConnected ? (
            <div
              style={{
                padding: "12px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#F9FAFB",
                borderRadius: "12px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <IconWrapper width="16px">
                  <i
                    class="fab fa-discord"
                    style={{
                      fontSize: "16px",
                      color: "rgb(59, 130, 246)",
                    }}
                  ></i>
                </IconWrapper>
                <h4
                  style={{
                    marginBottom: "0px",
                    textAlign: "left",
                    color: "#6B7280",
                  }}
                >
                  Show this event on discord{" "}
                </h4>
              </div>

              <Switch
                onChange={(flag) => setCreateDiscordEvent(flag)}
                checked={createDiscordEvent}
              />
            </div>
          ) : null}
        </div>

      </Modal >
    </StyledModalWrapper >
  );
};

CreateEventsModal.prototype = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default CreateEventsModal;

import { Button, Card, Checkbox, Input, Space, Tag, Tooltip } from "antd";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { PlusIcon } from "@heroicons/react/24/outline";

import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import IconWrapper from "../../IconWrapper";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  padding-left: 20px;
  align-items: center;
  column-gap: 4px;
  h4 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  & .padding-zero {
    padding: 0;
    font-size: 12px;
    font-weight: 600;
  }
  > span {
    border: none;
    border-radius: 0.5rem;
    display: inline-block !important;
    margin-bottom: 4px;
  }
  .tag {
    button {
      display: none;
      position: absolute;
      top: -10px;
      right: -10px;
    }

    &:hover button {
      display: block;
    }
  }
`;

const AddSearchTagWrapper = styled.div`
  display: inline-block;
  position: relative;
  align-items: center;
  & .ant-card-body {
    padding: 10px;
  }
  & .tag-card {
    width: 218px;
    position: absolute;
    top: 120%;
    transition: 300ms all;
    border-radius: 1rem;
    z-index: 5;
    box-shadow: 0 0px 0px rgb(0 0 0 / 12%), 0 0px 10px rgb(0 0 0 / 10%);
  }
  & .search-input {
    border-radius: 0.6rem;
  }
  .padding-zero {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .padding-zero > span {
    margin: 0;
  }
`;

const GenericTagSelect = ({
  title = "",
  skillsList,
  className,
  setSkillsList,
  AddIcon = () => <i class="fas fa-medal" style={{ fontSize: "16px" }}></i>,
  addButtonText = "Add skills",
  emptyText = "No Data Found",
  disableAddNewButton = false,
  addNewButtonText = "Add new skill",
  selectedTagColor = "purple",
  searchText = "Search skills...",
  setUpdatedFieldsNames = () => {},
  showRemoveTagIcon = false,
  onTagDelete = () => {},
  canEdit,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAddNewTagInput, setShowAddNewTagInput] = useState(false);
  const [addNewTagInput, setAddnewTagInput] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const ref = useRef();

  useEffect(() => {
    if (skillsList?.length) {
      if (searchInput) {
        const tempFilteredList = skillsList?.filter((ele) =>
          ele.name.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredList(tempFilteredList.slice());
      } else setFilteredList(skillsList.slice());
    }
  }, [searchInput, skillsList]);
  useEffect(()=>{
    setShowAddNewTagInput(false);
    setSearchInput("");
  },[showDropdown])
  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(ref, () => setShowDropdown(false));

  const addNewTagHandler = (newTagName) => {
    const curr = skillsList.slice();
    curr.push({ name: newTagName, checked: true, isNew: true });
    setSkillsList(curr.slice());
    setAddnewTagInput("");
  };

  // console.log(tagsFromDB);
  const handleAddTags = async () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <Wrapper>
      <AddSearchTagWrapper>
        {skillsList.filter((ele) => ele.checked).length ? (
          <Button
            type="link"
            style={{
              fontSize: "14px",
              fontWeight: "400",
              marginLeft: "0px",
              display: "inline-block",
              marginRight: "4px",
            }}
            className="padding-zero"
            onClick={handleAddTags}
            icon={<i className="fas fa-plus" style={{ marginRight: "4px" }} />}
            size="small"
          >
            Add
          </Button>
        ) : (
          <Button
            className="padding-zero tags-button"
            type="link"
            size="small"
            style={{ fontSize: "14px", fontWeight: "400" }}
            onClick={handleAddTags}
          >
            <IconWrapper>{<AddIcon></AddIcon>} </IconWrapper> {addButtonText}
          </Button>
        )}
        {showDropdown && (
          <Card className="tag-card" ref={ref}>
            <Input
              autoFocus={true}
              style={{ marginBottom: "12px" }}
              className="search-input"
              placeholder={searchText}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <Space
              direction="vertical"
              style={{
                gap: "4px",
                rowGap: "4px",
                columnGap: "4px",
                maxHeight: "250px",
                overflowY: "scroll",
                width: "100%",
              }}
            >
              {filteredList.length === 0 && emptyText}
              {filteredList?.map(
                (tag, idx) =>
                  !tag?.skipDropdown && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        onChange={(e) => {
                          const curr = skillsList.slice();
                          const currIndex = curr.findIndex(
                            (ele) => tag.name === ele.name
                          );

                          curr[currIndex]["checked"] = e.target.checked;
                          // console.log("op", curr);
                          setSkillsList(curr.slice());
                          setUpdatedFieldsNames();
                        }}
                        checked={tag.checked}
                        //   checked={selectedSkills.find((ele) => ele.name === tag.name)}
                      >
                        {tag.name.length > 16 ? (
                          <Tooltip title={tag.name}>
                            {tag.name.slice(0, 16)}...
                          </Tooltip>
                        ) : (
                          tag.name
                        )}
                      </Checkbox>
                      {showRemoveTagIcon === true && !tag?.isNew && canEdit && (
                        <CloseOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => onTagDelete(tag.name)}
                        />
                      )}
                    </div>
                  )
              )}
              {showAddNewTagInput && (
                <Space>
                  <Checkbox
                    disabled={true}

                    // onChange={(e) => handleNewTags(e, addNewTagInput)}
                  />
                  <Input
                    placeholder="Enter tag name"
                    bordered={false}
                    autoFocus={true}
                    className="padding-zero"
                    maxLength={15}
                    value={addNewTagInput}
                    onChange={(e) => {
                      setAddnewTagInput(e.target.value);
                    }}
                    onPressEnter={() => {
                      if (addNewTagInput.trim().length > 0) {
                        setUpdatedFieldsNames();
                        addNewTagHandler(addNewTagInput.trim());
                      }
                    }}
                  />
                </Space>
              )}
              {!disableAddNewButton ? (
                <Button
                  className="padding-zero"
                  type="link"
                  icon={
                    <IconWrapper>
                      <PlusIcon />
                    </IconWrapper>
                  }
                  onClick={() => {
                      setShowAddNewTagInput(true);
                      if (addNewTagInput.trim().length > 0) {
                        setUpdatedFieldsNames();
                        addNewTagHandler(addNewTagInput.trim());
                      }
                    }
                  }
                >
                  {addNewButtonText}
                </Button>
              ) : null}
            </Space>
          </Card>
        )}
      </AddSearchTagWrapper>
      {skillsList?.filter((ele) => ele.checked).length
        ? title
          ? title
          : null
        : null}
      {skillsList
        ?.filter(
          (ele) => ele.checked || (ele?.skipDropdown && ele?.checked === true)
        )
        ?.map((tag) => {
          const isLongTag = tag.name.length > 20;
          const tagElem = (
            <Tag
              color={selectedTagColor}
              className={"tag"}
              style={{
                marginRight: "8px",
                fontSize: "14px",
                position: "relative",
                background: "#F4F2F2",
                color: selectedTagColor,
              }}
            >
              <Button
                type="text"
                shape="circle"
                size="small"
                icon={<CloseCircleFilled />}
                style={{ position: "absolute", top: "-16px", right: "-8px" }}
                onClick={() => {
                  setUpdatedFieldsNames();
                  const curr = skillsList.slice();
                  const idx = curr.findIndex((ele) => tag.name === ele.name);

                  curr[idx]["checked"] = false;
                  // console.log("op", curr);
                  setSkillsList(curr.slice());
                }}
              />
              {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag.name} key={tag.name}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
    </Wrapper>
  );
};

export default GenericTagSelect;

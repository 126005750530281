import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import {
  Card,
  Typography,
  Avatar,
  Progress,
  Collapse,
  Button,
  Input,
  Col,
  Dropdown,
  Menu,
  Skeleton,
  Space,
  Tooltip,
  Popover,
  Tabs,
} from "antd";
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SignalFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router";
import styled from "styled-components";
import PropTypes, { element } from "prop-types";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "../components/ComponentStyles/BountyDetails.scss";

import DotIcon from "../images/dot.svg";
import ThumbUpOutlineActive from "../images/ThumbUpOutlineActive.svg";
import ThumbDownOutlineActive from "../images/ThumbDownOutlineActive.svg";
import HandRaised from "../images/HandRaised.svg";
import AnnotationOutline from "../images/AnnotationOutline.svg";
import ShareOutline from "../images/ShareOutline.svg";
import ThumbUpOutline from "../images/ThumbUpOutline.svg";
import ThumbDownOutline from "../images/ThumbDownOutline.svg";
import customAxios from "../api";
import { toast } from "react-toastify";
import CommentSection from "./CommentsComponents/CommentSection";
import { CompressOutlined } from "@ant-design/icons";
import DueDate from "./DueDate";
import Flag from "./Flag";
import defaultUserImg from "./../assets/images/login/defaultUser.jpeg";
import {
  ArrowLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  LinkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon, TrashIcon, UsersIcon } from "@heroicons/react/24/solid";
import ChecklistItem from "./BountyComponents/PostComponents/ChecklistIem";
import EditPostModal from "./PostComponents/EditPostModal";
import { useInterval } from "../hooks/useInterval";
import {
  DEFAULT_SORTING_TYPE_LIST,
  POLLING_REFRESH_INTERVAL,
} from "../constants";
import styles from "./PostDetails.module.css";
import { poll } from "ethers/lib/utils";
import { CATEGORIES_LIST_COLORS, getPostTime } from "./Feed";

import mixpanel from "mixpanel-browser";
import {
  getCurrentDateFromNow,
  getDefaultDaoImage,
  postMixpanel,
} from "../utils";
import { useTrackPageView } from "../hooks/useTrackPageView";
import { RelatedPosts } from "./Common/RelatedPosts";

import SlidingContextSidebar from "./SlidingContextSidebar";
import { HandRaisedIcon } from "@heroicons/react/24/solid";
import IconWrapper from "./IconWrapper";
import DeleteEntityModal from "./Common/DeleteEntityModal";
import { UserContext } from "../layouts/app/UserContext";
import useWindowSize from "../hooks/useWindowSize";
import { DaoContext, useDaoContext } from "../layouts/app/DaoContext";
import { togglePinPost } from "../utils/pin";
import { PinIcon } from "./Icones";
import { Helmet } from "react-helmet";
import Share from "./BountyComponents/Share";
import Attachment from "./Attachment";
import GPT from "./GPT/GPT";
import { PostMenu } from "./GPT/MenuItem";
import Vote from "./Proposal/Vote";
import VotingTypeCard from "./Common/VotingTypeCards";
import VotingResultsCard from "./Common/VotingResultsCard";
import markdown from "@wcj/markdown-to-html";
import useDaoLensNavigate from "../hooks/useDaoLensNavigate";
import { checkIsDiscourseModalShown } from "../lib/utils/discourse";
import { MINIMUM_COMMENT_CHAR_LIMIT } from "../lib/constants/posts";
import { Modal } from "./../styles/ModalStyles";
import { Feild } from "../styles/Feild";
import dummyUser from "./../assets/images/login/defaultUser.jpeg";
import { getOrdinal } from "./Proposal/api";
import { postProposalType } from "./PostComponents/CreatePostModal/utils";
import ProfileCardPopup from "./Common/ProfileCard";
import CreatePostModal from "./PostComponents/CreatePostModal";
import { COMMENT_NOT_ALLOWED_STATUS } from "../lib/constants/common.ts";

const { Title, Paragraph, Text } = Typography;

export const StyledParagraph = styled(Paragraph)`
  p,
  h1,
  h3,
  h2,
  h4,
  h5,
  h6 {
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0;
  }
  .meta {
    display: none;
  }
  img {
    max-width: 100%;
    object-fit: contain;
    margin: 8px 0px;
  }
  .emoji {
    width: 20px;
    height: 20px;
  }
  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  h4 {
    font-size: 12px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }

  h5 {
    font-size: 10px;
    font-weight: 600;
    line-height: 180%;
    margin-bottom: 8px;
    color: #0f172a;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: #0f172a;
  }
  .mention {
    background: none;
    color: #3b82f6;
    margin: 0;
    & span {
      margin: 0;
    }

    font-size: 14px;
  }
`;

const PollCard = styled(Card)`
  width: 100%;
  border-radius: 14px;
  .ant-tabs-tab {
    padding: 0px 0px 8px 0px !important;
    align-items: end;
  }
  & .ant-card-body {
    padding: 12px 18px;
  }
`;
const StyledCollapseWrapper = styled(Progress)`
  .ant-progress-inner {
    background: #e5edff;
    /* shadow-inner */

    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 40px;
  }
`;
const StyledProfileDetails = styled.div`
  display: flex;
  align-items: center;
  color: #64748b;
  cursor: pointer;
  &:hover {
    color: #3b82f6 !important;
  }
`;
const StyledButton = styled(Button)`
  border: none;
  &:hover {
    border: 1px solid #1890ff;
  }
`;
const StyledList = styled.ul`
  & .liClass {
    margin-left: 0;
    padding-left: 0;
    ::before {
      content: "•";
      color: #1890ff;
      margin-right: 10px;
    }
  }
`;
const LinkSpan = styled.span`
  &:hover {
    color: #3b82f6;
  }
`;

const WrapperDiv = styled.div`
  padding: 16px 22px;
  margin-bottom: 20px;
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f4f2f2;
  box-shadow: 0px 4px 16px rgba(132, 148, 160, 0.12);
  -webkit-box-shadow: 0px 4px 16px rgba(132, 148, 160, 0.12);
  -moz-box-shadow: 0px 4px 16px rgba(132, 148, 160, 0.12);
  border-radius: 16px;

  & .hash {
    color: #bcc5d3;
    font-size: 16px;
  }
  > button {
    border-radius: 0.6rem;
  }
`;

const StyledButtonContainer = styled.div`
  padding: 4px 20px;
  border-radius: 50px;
  border: 1px solid lightgrey;
  border: 1px solid lightgrey;

  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  & :hover {
    cursor: pointer;
  }
`;

const StyledIconsContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 50px;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img:hover {
    cursor: pointer;
  }
`;

export const StyledDotIconContainer = styled.div`
  & img:hover {
    cursor: pointer;
  }
`;

const StyledMenu = styled(Menu)`
  border-radius: 8px;
`;

const StyledParentContainer = styled.div`
  .ant-progress-inner {
    background: #e5edff;
    /* shadow-inner */

    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 40px;
  }
  // &:hover {
  //   cursor: pointer;
  // }
`;
const { Panel } = Collapse;

const PostDetails = ({
  postId,
  postDetails,
  setUpdater,
  fetchPostDetailsService,
  setPostDetails,
  setIsPollingPaused,
  selectedChoice,
  setSelectedChoice,
  votersList,
  votingResults,
  userVotingResults,
}) => {
  const [voteSuccessModalOpen, setVoteSuccessModalOpen] = useState(false);

  const { userDetails, showLoginModal } = useContext(UserContext);
  const { isMobile } = useWindowSize();
  const navigate = useDaoLensNavigate();
  const location = useLocation();
  const commentSectionRef = useRef(null);

  const [isCommentSectionVisible, setIsCommentSectionVisible] = useState(true);
  const [attachedPostMedia, setAttachedPostMedia] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    postDetails[0].choicesSelected ||
      getCurrentDateFromNow(postDetails[0].pollExpirationDate)
        .toLowerCase()
        .includes("expired")
      ? "results"
      : "vote"
  );
  const [showTasks, setShowTasks] = useState(() => true);
  const [isEditPostModalVisible, setIsEditPostModalVisible] = useState(false);
  const { channelId } = useParams();
  const [sortBy, setSortBy] = useState("Oldest");
  const [channelDetails, setChannelDetails] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [relatedPostsLoading, setRelatedPostsLoading] = useState(false);
  const [gptComment, setGPTComment] = useState("");
  const {
    daoDetails,
    daoId,
    daoName,
    isAdmin,
    isCurrentDaoJoined,
    setShowJoinCommunityModal,
    setIsConnectDiscourseModalOpen,
  } = useDaoContext();

  const [currView, setCurrView] = useState(
    postDetails[0].choicesSelected ? "results" : "vote"
  );
  useEffect(() => {
    if (location.state) {
      if (location.state?.isCommentSectionClicked === true) {
        commentSectionRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const [deleting, setDeleting] = useState(false);
  const deletePostHandler = async () => {
    setDeleting(true);
    try {
      await customAxios.delete(`post/delete?postId=${postId}`);
      toast.success("Post deleted successfully");
      navigate(`/app/dao/${daoId}/channel/${channelDetails.daoChannelId}`);
      setShowDeleteModal(false);
    } catch (error) {
      console.log("error");
    } finally {
      setDeleting(false);
    }
  };
  useTrackPageView(postId);

  const isDiscourseModalShown = checkIsDiscourseModalShown(
    daoDetails,
    userDetails
  );

  const menu = (
    <StyledMenu>
      <Menu.Item
        onClick={() => {
          if (isDiscourseModalShown) {
            setIsConnectDiscourseModalOpen(true);
            return;
          }
          setIsEditPostModalVisible(true);
          setIsPollingPaused(true);
        }}
      >
        <PencilIcon
          fill="#6B7280"
          width="14px"
          style={{ transform: "translateY(2px)", marginRight: "6px" }}
        />
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={() => togglePinPost(daoId, postId, !postDetails?.[0]?.pinned)}
      >
        <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
        {postDetails?.[0]?.pinned ? "Unpin" : "Pin"}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          if (isDiscourseModalShown) {
            setIsConnectDiscourseModalOpen(true);
            return;
          }
          setShowDeleteModal(true);
        }}
      >
        <TrashIcon
          fill="#6B7280"
          width="14px"
          style={{ transform: "translateY(2px)", marginRight: "6px" }}
        />
        Delete
      </Menu.Item>
    </StyledMenu>
  );

  const [canEdit, setCanEdit] = useState(false);
  const [canEngage, setCanEngage] = useState(false);
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useState("");
  useEffect(() => {
    customAxios
      .get(`post/permission?daoId=${daoId}`)
      .then((res) => {
        setCanEngage(res.data.postPermission.includes("ENGAGE"));
        setCanEdit(res.data.postPermission.includes("MANAGE"));
      })
      .catch((err) => {
        console.log(err);
      });
    fetchCommentsService(postId);
  }, [daoId, postId]);
  useEffect(() => {
    fetchCommentsService(postId);
  }, [sortBy]);
  useEffect(() => {
    customAxios
      .get(`daochannel/fetch/details?channelId=${channelId}`)
      .then((res) => {
        setChannelDetails(res.data.channel[0]);
      })
      .catch((err) => {
        console.log("error");
      });
  }, [channelId, postId]);

  useEffect(() => {
    if (postDetails) {
      setRelatedPostsLoading(true);
      customAxios
        .post("post/fetch/related", {
          title: postDetails?.[0].postName,
          description: postDetails?.[0].postDescription,
          postId,
          daoId,
        })
        .then((res) => {
          setRelatedPosts(res.data.res);
        })
        .catch((err) => {
          console.log("error");
        })
        .finally(() => {
          setRelatedPostsLoading(false);
        });
    }
  }, [postId, postDetails]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [canReact, setCanReact] = useState(true);

  const handlePostLikeDislikeApi = async (data) => {
    customAxios
      .post(`post/vote`, data)
      .then((res) => {
        postMixpanel(
          "track",
          data.type === "dislike" ? "disliked_post" : "liked_post",
          {
            post_id: data.postId,
            source_page: window.location.pathname,
            dao_id: daoId,
          }
        );

        if (!res?.data?.success) {
          toast.error("Some error occured. Please refresh the page");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some error occured. Please refresh the page");
      })
      .finally(() => {
        setCanReact(true);
      });
  };

  const onPollOptionClick = (id) => {
    setLoading(true);
    customAxios
      .post(`postpoll/updatepoll`, { postId, choicesSelected: selectedChoice })
      .then((res) => {
        setLoading(false);
        // setVoteSuccessModalOpen(true);
        // setPostData(res.data.postData);
        fetchPostDetailsService();
        setSelectedTab("results");
        setCurrView("results");
      })
      .catch((err) => {
        console.log("error");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (
      postDetails &&
      postDetails.length > 0 &&
      postDetails[0].postPollAttachments
    ) {
      setAttachedPostMedia(postDetails[0]?.postPollAttachments);
    }
  }, [postDetails]);

  const fetchCommentsService = (id) => {
    customAxios
      .post("/postcomments/fetch", {
        postId: id ? id : postId,
        // parentId: "0",
        parentId: "0",
        sortBy: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortBy,
        sortOrder: DEFAULT_SORTING_TYPE_LIST[sortBy]?.sortOrder,
      })
      .then((res) => {
        let transformAllComments = res.data.childComments.map(
          (singleComment) => {
            let obj = singleComment.vote.find(
              (ele) => ele.commentId === singleComment.id
            );
            if (obj !== undefined)
              return {
                ...singleComment,
                engagementStatus: obj.type.toLowerCase(),
              };
            else return { ...singleComment, engagementStatus: "" };
          }
        );
        setComments(transformAllComments);
      })
      .catch((err) => console.log(err));
  };
  useInterval(() => fetchCommentsService(postId), POLLING_REFRESH_INTERVAL);

  const postComment = async (
    commentText,
    mentions,
    parentId,
    level2ParentId = "0"
  ) => {
    const response = await customAxios.post("/postcomments/create", {
      postId: postId,
      parentId,
      daoId: daoId,
      description: commentText,
      channelId: channelId,
      userTagged: mentions,
      level2ParentId,
    });

    if (response.data?.status === COMMENT_NOT_ALLOWED_STATUS) {
      toast.error("Cannot comment more than 3 times consecutively");
      return;
    }
    postMixpanel("track", "comment_on_post", {
      post_id: postId,
      parent_comment_id: parentId,
      dao_id: daoId,
      channel_id: channelId,
    });

    fetchCommentsService(postId);
  };

  const getChecklistCompletionPercentage = (checklist) => {
    return (
      (checklist.filter((ele) => ele.isCompleted).length / checklist.length) *
      100
    );
  };

  const markChecklistComplete = (ele) => {
    if (ele.canMarkChecklist)
      customAxios
        .post("/post/checklist/markcomplete", {
          checklistId: ele.id,
          markComplete: !ele.isCompleted,
        })
        .then((res) => {
          setUpdater((prev) => !prev);
        })
        .catch((err) => {
          console.log("error");
        });
  };
  const getVotedText = (choicesSelected, pollOptions, pollType) => {
    let text;
    let average;
    let total;
    switch (pollType) {
      case "single":
        return pollOptions.find((ele) => ele.id === choicesSelected).name;

      case "weighted":
        total = pollOptions.reduce(
          (total, curr) =>
            choicesSelected[curr.id] ? choicesSelected[curr.id] + total : total,
          0
        );
        text = Object.keys(choicesSelected).map((key) =>
          pollOptions.find((ele) => ele.id === key)?.name?.length > 7
            ? `${Number((choicesSelected[key] * 100) / Number(total)).toFixed(
                2
              )}% for ${pollOptions
                ?.find((ele) => ele.id === key)
                ?.name.slice(0, 7)}... ,`
            : `${Number((choicesSelected[key] * 100) / Number(total)).toFixed(
                2
              )}% for ${pollOptions
                ?.find((ele) => ele.id === key)
                ?.name.slice(0, 7)} `
        );
        return text;
      case "quadratic":
        total = pollOptions.reduce(
          (total, curr) =>
            choicesSelected[curr.id] ? choicesSelected[curr.id] + total : total,
          0
        );
        text = Object.keys(choicesSelected).map((key) =>
          pollOptions.find((ele) => ele.id === key)?.name?.length > 7
            ? `${Number((choicesSelected[key] * 100) / Number(total)).toFixed(
                2
              )}% for ${pollOptions
                ?.find((ele) => ele.id === key)
                ?.name.slice(0, 7)}... ,`
            : `${Number((choicesSelected[key] * 100) / Number(total)).toFixed(
                2
              )}% for ${pollOptions
                ?.find((ele) => ele.id === key)
                ?.name.slice(0, 7)} `
        );
        return text;
      case "approval":
        text = choicesSelected.map((key) =>
          pollOptions.find((ele) => ele.id === key)?.name?.length > 7
            ? `${pollOptions
                ?.find((ele) => ele.id === key)
                ?.name.slice(0, 7)}... ,`
            : `${pollOptions?.find((ele) => ele.id === key)?.name.slice(0, 7)} `
        );
        return text;
      case "ranked-choice":
        text = choicesSelected.map((key, index) =>
          pollOptions.find((ele) => ele.id === key)?.name?.length > 7
            ? `(${getOrdinal(index + 1)}) ${pollOptions
                ?.find((ele) => ele.id === key)
                ?.name.slice(0, 7)}... ,`
            : `(${getOrdinal(index + 1)}) ${pollOptions
                ?.find((ele) => ele.id === key)
                ?.name.slice(0, 7)} `
        );

        return text;
      default:
        return pollOptions.find((ele) => ele.id === choicesSelected).name;
    }
  };
  const isValidVote = (votingType, selectedChoice, choices) => {
    switch (votingType) {
      case "basic": {
        return selectedChoice ? true : false;
      }
      case "single-choice": {
        return selectedChoice ? true : false;
      }
      case "approval": {
        return selectedChoice.length > 0;
      }
      case "ranked-choice": {
        return choices.length === selectedChoice.length;
      }
      case "quadratic": {
        return Object.keys(selectedChoice).length > 0;
      }
      case "weighted": {
        return Object.keys(selectedChoice).length > 0;
      }
      default:
        return selectedChoice ? true : false;
    }
  };
  const handlePostLike = (postDetails) => {
    let finalObj = {};
    setPostDetails((prevState) => {
      const prev = structuredClone(prevState[0]);

      if (prev.postEngagementStatus === "like") {
        finalObj = {
          postEngagementStatus: "",
          postLikes: prev.postLikes - 1,
        };
        handlePostLikeDislikeApi({
          type: "like",
          postId: postId,
          increase: false,
        });
      } else if (prev.postEngagementStatus === "dislike") {
        finalObj = {
          postEngagementStatus: "like",
          postLikes: prev.postLikes + 1,
          postDislikes: prev.postDislikes - 1,
        };
        handlePostLikeDislikeApi({
          type: "dislike",
          postId: postId,
          increase: false,
        });
        handlePostLikeDislikeApi({
          type: "like",
          postId: postId,
          increase: true,
        });
      } else {
        finalObj = {
          postEngagementStatus: "like",
          postLikes: prev.postLikes + 1,
        };
        handlePostLikeDislikeApi({
          type: "like",
          postId: postId,
          increase: true,
        });
      }
      return [{ ...prev, ...finalObj }];
    });
  };
  const handlePostDislike = (postDetails) => {
    let finalObj = {};
    setPostDetails((prevState) => {
      const prev = structuredClone(prevState[0]);
      if (prev.postEngagementStatus === "dislike") {
        finalObj = {
          postEngagementStatus: "",
          postDislikes: prev.postDislikes - 1,
        };
        handlePostLikeDislikeApi({
          type: "dislike",
          postId: postId,
          increase: false,
        });
      } else if (prev.postEngagementStatus === "like") {
        finalObj = {
          postEngagementStatus: "dislike",
          postLikes: prev.postLikes - 1,
          postDislikes: prev.postDislikes + 1,
        };
        handlePostLikeDislikeApi({
          type: "dislike",
          postId: postId,
          increase: true,
        });
        handlePostLikeDislikeApi({
          type: "like",
          postId: postId,
          increase: false,
        });
      } else {
        finalObj = {
          postEngagementStatus: "dislike",
          postDislikes: prev.postDislikes + 1,
        };
        handlePostLikeDislikeApi({
          type: "dislike",
          postId: postId,
          increase: true,
        });
      }
      return [{ ...prev, ...finalObj }];
    });
  };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onPostCommentSet = () => {
    setGPTComment("");
  };
  return (
    <div>
      <Helmet>
        <title>{postDetails?.postName}</title>
      </Helmet>
      <Modal
        title={
          <h4
            style={{
              display: "flex",
              alignItems: "center",
              // marginBottom: "0px",
              gap: "8px",
              color: "#6B7280",
            }}
          >
            <IconWrapper>
              <UsersIcon />
            </IconWrapper>
            All voters ({votersList.length})
          </h4>
        }
        footer={null}
        width={isMobile ? "auto" : "420px"}
        isMobile={isMobile}
        height={"auto"}
        visible={voteSuccessModalOpen}
        onCancel={() => {
          setVoteSuccessModalOpen(false);
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "8px 16px",
            paddingTop: "0px",
            gap: "12px",
            flexDirection: "column",
          }}
        >
          <Input
            placeholder="Search users"
            value={query}
            autoFocus={true}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            style={{
              minHeight: "32px",
              borderRadius: "12px",
            }}
            prefix={
              <IconWrapper>
                <MagnifyingGlassIcon />
              </IconWrapper>
            }
          />{" "}
          <Space
            direction="vertical"
            style={{
              gap: "4px",
              rowGap: "4px",
              columnGap: "4px",
              maxHeight: "250px",
              overflowY: "auto",
              minHeight: "250px",
              width: "100%",
            }}
          >
            {!votersList?.length ? (
              <h4 style={{ textAlign: "center" }}>No users found</h4>
            ) : null}
            {votersList
              ?.filter((ele) => ele.userName.toLowerCase().includes(query))
              ?.map((user) => (
                <div
                  key={user}
                  onClick={() => {}}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "8px",
                    padding: "8px 0px",
                    borderBottom: "1px solid #F4F2F2",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <img
                      style={{
                        width: "22px",
                        borderRadius: "50%",
                        height: "22px",
                        objectFit: "cover",
                      }}
                      src={
                        user?.userProfilePicture
                          ? user?.userProfilePicture
                          : dummyUser
                      }
                      alt="icon"
                    />{" "}
                    <span style={{ fontSize: "12px" }}>
                      {user?.userName?.slice(0, 16)}
                      {user?.userName?.length > 16 ? "..." : null}
                    </span>
                  </div>
                  <div style={{ fontSize: "12px" }}>
                    {getVotedText(
                      user.choicesSelected,
                      postDetails[0].polls,
                      postDetails[0].pollType
                    ).toString().length > 40 ? (
                      <Tooltip
                        showArrow={false}
                        overlayInnerStyle={{
                          background: "white",
                          borderRadius: "12px",
                          color: "#64748B",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                        title={getVotedText(
                          user.choicesSelected,
                          postDetails[0].polls,
                          postDetails[0].pollType
                        )}
                      >
                        {getVotedText(
                          user.choicesSelected,
                          postDetails[0].polls,
                          postDetails[0].pollType
                        )
                          .toString()
                          .slice(0, 40) + "..."}
                      </Tooltip>
                    ) : (
                      getVotedText(
                        user.choicesSelected,
                        postDetails[0].polls,
                        postDetails[0].pollType
                      )
                    )}
                  </div>
                </div>
              ))}
          </Space>
        </div>
      </Modal>
      <DeleteEntityModal
        note="This post will be deleted permanently. Do you still want to proceed?"
        isModalVisible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={() => {
          deletePostHandler();
        }}
      />
      <WrapperDiv>
        <Text
          strong
          ellipsis={{
            rows: 1,
          }}
        >
          <span className="hash">#</span>
          <LinkSpan
            style={{
              marginLeft: "4px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(
                `/app/dao/${daoName}/${daoId}/channel/${channelDetails.daoChannelId}`
              );
            }}
          >
            {channelDetails?.daoChannelName}
          </LinkSpan>
          <span className="hash" style={{ marginLeft: "4px" }}>
            <ChevronRightIcon
              style={{ transform: "translateY(3px)" }}
              width={16}
              color="#000000"
              strokeWidth={2}
            />
          </span>
          <span style={{ marginLeft: "4px" }}>
            {postDetails?.["0"]?.postName}
          </span>
        </Text>
      </WrapperDiv>
      {isEditPostModalVisible ? (
        <CreatePostModal
          isModalVisible={isEditPostModalVisible}
          setIsModalVisible={(status) => {
            setIsEditPostModalVisible(status);
            setIsPollingPaused(false);
          }}
          edit={true}
          postDetails={postDetails}
          setUpdater={setUpdater}
          channelDetails={channelDetails}
          canEdit={canEdit || isAdmin}
        />
      ) : null}
      <GPT
        data={{
          channelId: channelId,
          channelName: channelDetails?.daoChannelName,
          postId: postDetails[0].postId,
          postDescription: postDetails[0].postDescription,
          placeholders: [
            "Summarise this discussion",
            "Critique Pros and Cons of this Post",
            "Make a comment for this post",
            "Turn this post into Twitter thread",
          ],
          onPostComment: (result) => {
            setIsCommentSectionVisible(true);
            const resultInRichText = markdown(result);
            setGPTComment(resultInRichText);
            commentSectionRef.current?.scrollIntoView({ behavior: "smooth" });
          },
          canComment: canEngage,
        }}
      />
      <div style={{ height: "20px" }}></div>
      {postDetails?.length > 0 &&
        [postDetails[0]].map((post, index) => (
          <StyledParentContainer
            style={{
              width: "100%",
              textAlign: "left",
              marginBottom: "12px",
              borderRadius: "16px",
              padding: "16px 20px 16px 20px",
              border: "1px solid #f0f0f0",
              background: "#fff",
            }}
            key={post.postId}
          >
            {/* <div style={{ marginBottom: "10px" }}>
              <GoBackButton
                showIcon
                buttonText={"Go Back"}
                clickHandler={() => {
                  navigate(-1);
                }}
              />
            </div> */}
            <div
              style={{
                color: "#64748B",
                marginBottom: "6px",
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0px",
                  alignItems: "center",
                }}
              >
                {" "}
                <ProfileCardPopup userId={post.userId}>
                  <StyledProfileDetails
                    onClick={() => navigate(`/app/profile/${post.userId}`)}
                  >
                    {post?.userProfilePicture &&
                      post.userProfilePicture !== null && (
                        <Avatar
                          size="small"
                          src={post.userProfilePicture}
                          style={{ marginRight: "10px" }}
                        />
                      )}
                    {post?.userName && (
                      <>
                        <Text
                          style={{
                            color: "inherit",
                            fontWeight: 600,
                            fontSize: 12,
                            maxWidth: isMobile ? "80px" : "200px",
                          }}
                          ellipsis
                        >
                          {post.userName}
                        </Text>
                        <span style={{ margin: "0 4px" }}>&#8226;</span>
                      </>
                    )}
                  </StyledProfileDetails>
                </ProfileCardPopup>
                <Text
                  style={{
                    color: "#64748B",
                    fontWeight: 600,
                    fontSize: "12px",
                  }}
                >
                  {getPostTime(post?.createdAt)}
                </Text>
              </div>
              {post.pinned && (
                <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                  <PinIcon style={{ color: "#6366F1" }} />
                </div>
              )}
              {canEdit || isAdmin ? (
                <Dropdown overlay={menu} trigger={["click"]}>
                  <StyledDotIconContainer>
                    <img
                      src={DotIcon}
                      alt="dot icon"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </StyledDotIconContainer>
                </Dropdown>
              ) : null}
            </div>
            <Title level={4}>{post?.postName}</Title>
            {post?.category?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexFlow: "wrap",
                  gap: "8px",
                  margin: "12px 0px",
                }}
              >
                {post?.category?.map((ele, index) => (
                  <div
                    style={{
                      height: "32px",
                      backgroundColor: "#ffffff",
                      borderRadius: "16px",
                      padding: "4px 8px",
                      display: "inline-flex",
                      alignItems: "center",
                      background: "#F3F4F6",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: ele.colour,
                        display: "inline-block",
                        width: "8px",
                        height: "8px",
                        borderRadius: "20px",
                        marginRight: "8px",
                      }}
                    ></span>
                    <span style={{ fontSize: "12px", fontWeight: "500" }}>
                      {ele.name}
                    </span>
                  </div>
                ))}
              </div>
            ) : null}
            {post?.checklist.length ? (
              // <StyledCollapseWrapper>
              //   <Collapse
              //     ghost
              //     // expandIconPosition="end"
              //     // style={{ padding: "0px" }}
              //   >

              <div
                style={{
                  background: "rgba(240,245,255,0.7)",
                  marginTop: "6px",
                  borderRadius: "12px",
                  padding: "12px 16px",
                  marginBottom: "12px",
                }}
              >
                {" "}
                <div
                  style={{
                    alignItems: "center",

                    marginBottom: "6px",
                    display: "flex",

                    justifyContent: "space-between",
                  }}
                >
                  <h4
                    style={{
                      fontWeight: "500",
                      marginBottom: "0px",
                      color: "#362F78",
                    }}
                  >
                    {post.checklist.filter((ele) => ele.isCompleted).length} of{" "}
                    {post.checklist.length} tasks
                  </h4>
                  <Button
                    type="text"
                    icon={
                      showTasks ? (
                        <i
                          class="fas fa-chevron-up"
                          style={{ marginRight: "4px" }}
                        ></i>
                      ) : (
                        <i
                          class="fas fa-chevron-down"
                          style={{ marginRight: "4px" }}
                        >
                          {" "}
                        </i>
                      )
                    }
                    style={{ color: "#6875F5", fontSize: "12px" }}
                    onClick={() => setShowTasks((prev) => !prev)}
                  >
                    {showTasks ? "Hide Tasks" : "Show tasks"}
                  </Button>
                </div>
                {/* <StyledCollapseWrapper> */}
                <Progress
                  status={"active"}
                  strokeColor={
                    "linear-gradient(0deg, #5850EC 0%, rgba(86, 77, 255, 0.23) 145.83%)"
                  }
                  percent={Number(
                    getChecklistCompletionPercentage(post?.checklist)
                  ).toFixed(0)}
                />
                {/* </StyledCollapseWrapper> */}
                <div
                  style={{
                    background: "#FFFFFF",
                    borderRadius: "12px",
                    margin: "12px",
                  }}
                >
                  {showTasks
                    ? post?.checklist.map((ele, idx) => {
                        return (
                          <ChecklistItem
                            ele={ele}
                            canEdit={canEdit || isAdmin}
                            key={ele.id}
                            idx={idx}
                            markChecklistComplete={markChecklistComplete}
                          />
                        );
                      })
                    : null}
                </div>
              </div>
            ) : null}
            {post?.postTags && post?.postTags.length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexFlow: "wrap",
                  gap: "8px",
                  margin: "12px 0px",
                }}
              >
                {post.postTags.map((tag) => (
                  <div
                    style={{
                      padding: "2px 16px",
                      background: "#F2F5FF",
                      borderRadius: "8px",
                      color: "#3B82F6",

                      fontSize: "12px",
                      display: "inline-block",
                    }}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            )}
            <StyledParagraph style={{ lineHeight: "24px", overflow: "hidden" }}>
              {ReactHtmlParser(post?.postDescription)}
            </StyledParagraph>
            {attachedPostMedia && attachedPostMedia.length > 0 && (
              <Paragraph style={{ lineHeight: 2 }}>
                <Text style={{ fontWeight: 600, fontSize: 14 }}>
                  ATTACHMENTS:
                </Text>
                <Attachment files={[...attachedPostMedia]} />
              </Paragraph>
            )}
            {/* <Vote
              choices={post.polls}
              votingType={post.pollType}
              selectedChoice={selectedChoice}
              setSelectedChoice={setSelectedChoice}
            /> */}
            {post?.polls && post?.polls.length ? (
              <PollCard style={{ marginBottom: "1.5rem" }}>
                <Tabs
                  onChange={(key) => setSelectedTab(key)}
                  activeKey={selectedTab}
                  style={{ width: "100%" }}
                  tabBarExtraContent={{
                    right: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "8px",
                          marginBottom: "8px",
                          alignItems: "end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "end",
                            gap: "4px",
                          }}
                        >
                          <Tooltip
                            overlayInnerStyle={{
                              background: "white",
                              borderRadius: "12px",
                              // color: "#64748B",
                              color: "black",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                            showArrow={false}
                            title={
                              postProposalType.find(
                                (ele) => ele.value === post.pollType
                              )?.description
                            }
                          >
                            <div
                              style={{
                                cursor: "pointer",
                                marginBottom: "-2px",
                              }}
                            >
                              <IconWrapper>
                                <InformationCircleIcon
                                  style={{
                                    fontWeight: "800",
                                    color: "#64748B",
                                  }}
                                  strokeWidth={"2px"}
                                  width="14px"
                                />
                              </IconWrapper>
                            </div>
                          </Tooltip>

                          <Text
                            style={{
                              color: "#64748B",
                              fontWeight: 600,
                              marginBottom: "0px",
                              fontSize: "12px",
                            }}
                          >
                            {`${
                              postProposalType.find(
                                (ele) => ele.value === post.pollType
                              )?.label
                            } Poll`}{" "}
                          </Text>
                        </div>
                        <span style={{ color: "#64748B" }}>&#x2022;</span>
                        {votersList.length ? (
                          <div
                            onClick={() => setVoteSuccessModalOpen(true)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              cursor: "pointer",
                            }}
                          >
                            <IconWrapper>
                              <UsersIcon
                                style={{
                                  // marginRight: "10px",
                                  color: "#64748B",
                                  width: "14px",
                                }}
                              />
                            </IconWrapper>
                            <Text
                              style={{
                                color: "#64748B",
                                fontWeight: 600,
                                marginBottom: "0px",
                                fontSize: "12px",
                              }}
                            >
                              {`${votersList?.length}`}
                            </Text>
                          </div>
                        ) : null}
                      </div>
                    ),
                  }}
                >
                  <Tabs.TabPane
                    tab={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                        }}
                      >
                        Your Vote
                      </div>
                    }
                    key="vote"
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>
                        {post.postPollQuestion}
                      </h4>

                      {currView === "vote" ? (
                        <VotingTypeCard
                          canVote={post.canVote}
                          pollType={post.pollType}
                          gatingConditions={post.pollGatingCondition}
                          choices={post.polls}
                          selectedChoice={selectedChoice}
                          setSelectedChoice={setSelectedChoice}
                          isExpired={
                            !getCurrentDateFromNow(post.pollExpirationDate)
                              .toLowerCase()
                              .includes("expired")
                          }
                        />
                      ) : (
                        <VotingResultsCard
                          pollOptions={post?.polls}
                          votingResults={userVotingResults}
                          pollType={post.pollType}
                          Icon={
                            <img
                              style={{
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                              }}
                              src={
                                userDetails?.profilePicture
                                  ? userDetails?.profilePicture
                                  : defaultUserImg
                              }
                            />
                          }
                          choicesSelected={post?.choicesSelected}
                        />
                      )}
                      {!getCurrentDateFromNow(post.pollExpirationDate)
                        .toLowerCase()
                        .includes("expired") ? (
                        post?.choicesSelected && currView === "results" ? (
                          <StyledButton
                            type="text"
                            block={true}
                            style={{
                              borderRadius: "16px",
                              background: "#EFF6FF",
                              color: "#3B82F6",
                              fontSize: "12px",
                              fontWeight: "600",
                            }}
                            disabled={loading}
                            onClick={() => {
                              setCurrView("vote");
                            }}
                          >
                            Change Vote
                          </StyledButton>
                        ) : (
                          <Button
                            type="primary"
                            block={true}
                            style={{ borderRadius: "16px" }}
                            disabled={
                              loading ||
                              !isValidVote(
                                post.pollType,
                                selectedChoice,
                                post.polls
                              )
                            }
                            onClick={() => {
                              if (!userDetails) {
                                showLoginModal();
                                return;
                              } else if (!isCurrentDaoJoined) {
                                toast.info(
                                  "Please join the community before you vote."
                                );
                                return;
                              }
                              if (
                                isValidVote(
                                  post.pollType,
                                  selectedChoice,
                                  post.polls
                                )
                              ) {
                                onPollOptionClick(post.poll);
                              } else {
                                return toast?.error("Please select an otpion");
                              }
                            }}
                          >
                            Vote
                          </Button>
                        )
                      ) : null}

                      {
                        <div
                          style={{
                            justifyContent:
                              getCurrentDateFromNow(post.pollExpirationDate)
                                .toLowerCase()
                                .includes("expired") || post?.choicesSelected
                                ? currView === "results"
                                  ? "center"
                                  : "space-between"
                                : "center",

                            display: "flex",
                          }}
                        >
                          {getCurrentDateFromNow(post.pollExpirationDate)
                            .toLowerCase()
                            .includes("expired") || post?.choicesSelected ? (
                            currView === "results" ? null : (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "4px",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setCurrView("results");
                                }}
                              >
                                <IconWrapper>
                                  <ArrowLeftIcon
                                    style={{ color: "#6B7280" }}
                                    strokeWidth="2px"
                                  />
                                </IconWrapper>
                                <h5
                                  style={{
                                    color: "#6B7280",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Cancel
                                </h5>
                              </div>
                            )
                          ) : null}
                          <h5
                            style={{
                              color: "#6B7280",
                              marginBottom: "0px",
                              textAlign: "center",
                            }}
                          >
                            {getCurrentDateFromNow(post.pollExpirationDate)
                              .toLowerCase()
                              .includes("expired")
                              ? "Poll Expired"
                              : `Poll expires 
                        ${moment(post?.pollExpirationDate).fromNow()}`}
                          </h5>
                        </div>
                      }
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    key="results"
                    tab={
                      <div
                        style={{
                          display: "flex",
                          gap: "4px",
                        }}
                      >
                        Poll Results
                      </div>
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                      }}
                    >
                      <h4 style={{ fontWeight: "500", marginBottom: "0px" }}>
                        {post.postPollQuestion}
                      </h4>

                      <VotingResultsCard
                        userVotingResults={userVotingResults}
                        pollOptions={post?.polls}
                        votingResults={votingResults}
                        Icon={
                          daoDetails?.profilePicture ? (
                            <img
                              src={daoDetails?.profilePicture}
                              style={{
                                width: "16px",
                                height: "16px",
                                borderRadius: "50%",
                              }}
                            />
                          ) : (
                            getDefaultDaoImage(
                              daoDetails?.daoName,
                              {
                                alignSelf: "center",
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",

                                borderRadius: "50%",
                              },
                              { fontSize: "10px" }
                            )
                          )
                        }
                        pollType={post.pollType}
                        choicesSelected={post?.choicesSelected}
                      />
                      <h5
                        style={{
                          color: "#6B7280",
                          marginBottom: "0px",
                          textAlign: "center",
                        }}
                      >
                        {getCurrentDateFromNow(post.pollExpirationDate)
                          .toLowerCase()
                          .includes("expired")
                          ? "Poll Expired"
                          : `Poll expires
                        ${moment(post?.pollExpirationDate).fromNow()}`}
                      </h5>
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </PollCard>
            ) : null}

            {selectedTab === "results" ? null : null}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: isCommentSectionVisible && "24px",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <StyledButtonContainer
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCommentSectionVisible((prevState) => !prevState);
                  }}
                  style={{ marginRight: isMobile ? "4px" : "10px" }}
                >
                  <img src={AnnotationOutline} alt="open comment section" />
                  <span style={{ marginLeft: "6px" }}>
                    {post?.noOfPostComments}
                  </span>
                </StyledButtonContainer>
                <Share
                  entity="post"
                  style={{ marginRight: "8px", border: "1px solid lightgrey" }}
                />

                <SlidingContextSidebar
                  entityId={postId}
                  entityType="POST"
                  canEdit={canEdit || isAdmin}
                />
              </div>
              <StyledIconsContainer style={{ marginLeft: "4px" }}>
                <span
                  style={{
                    marginRight: "4px",
                    color:
                      post.postEngagementStatus === "like"
                        ? "#22C55E"
                        : "rgba(0,0,0,0.85)",
                  }}
                >
                  {post?.postLikes}
                </span>
                <img
                  src={
                    post.postEngagementStatus === "like"
                      ? ThumbUpOutlineActive
                      : ThumbUpOutline
                  }
                  style={{ cursor: canReact ? "pointer" : "wait" }}
                  alt="thumb up icon"
                  onClick={(e) => {
                    e.stopPropagation();

                    if (isDiscourseModalShown) {
                      setIsConnectDiscourseModalOpen(true);
                      return;
                    }
                    if (canEngage && canReact) {
                      setCanReact(false);
                      handlePostLike(postDetails);
                    }

                    if (!userDetails) {
                      showLoginModal();
                    } else if (!isCurrentDaoJoined) {
                      setShowJoinCommunityModal(true);
                      return;
                    }
                  }}
                />
                <span
                  style={{
                    marginRight: "4px",
                    marginLeft: "4px",
                    color:
                      post.postEngagementStatus === "dislike"
                        ? "red"
                        : "rgba(0,0,0,0.85)",
                  }}
                >
                  {post?.postDislikes}
                </span>
                <img
                  src={
                    post.postEngagementStatus === "dislike"
                      ? ThumbDownOutlineActive
                      : ThumbDownOutline
                  }
                  style={{ cursor: canReact ? "pointer" : "wait" }}
                  alt="thumb down icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isDiscourseModalShown) {
                      setIsConnectDiscourseModalOpen(true);
                      return;
                    }
                    if (canEngage && canReact) {
                      setCanReact(false);
                      handlePostDislike(postDetails);
                    }
                    if (!userDetails) {
                      showLoginModal();
                    } else if (!isCurrentDaoJoined) {
                      setShowJoinCommunityModal(true);
                      return;
                    }
                  }}
                />
              </StyledIconsContainer>
            </div>
            {isCommentSectionVisible && (
              <>
                <Paragraph style={{ lineHeight: 2 }} ref={commentSectionRef}>
                  <CommentSection
                    initialText={gptComment}
                    entity="post"
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortingTypeList={DEFAULT_SORTING_TYPE_LIST}
                    canEngage={canEngage}
                    allComments={comments}
                    setAllComments={setComments}
                    postComment={postComment}
                    fetchComments={fetchCommentsService}
                    authorUserName={post?.userName}
                    onPostCommentSet={onPostCommentSet}
                    checkIsEnabled={() => {
                      if (isDiscourseModalShown) {
                        setIsConnectDiscourseModalOpen(true);
                        return false;
                      }
                      return true;
                    }}
                    minimumCharLimit={MINIMUM_COMMENT_CHAR_LIMIT}
                  />

                  {/* <PostCommentsSection
                    setAccordionState={isCommentSectionVisible}
                    comments={commentsData}
                  /> */}
                </Paragraph>
                {/* <PostSecondaryButtonOutlined
                  buttonText="+ Add Comment"
                  style={{ borderRadius: "100px" }}
                  icon={CommentIcon}
                  clickHandler={() => setIsCreateCommentEnabled(true)}
                /> */}
              </>
            )}
          </StyledParentContainer>
        ))}
      {relatedPosts?.length > 0 && (
        <div>
          <p style={{ fontWeight: "600", fontSize: "1rem" }}>Related Posts</p>
          {relatedPostsLoading ? (
            <Skeleton active />
          ) : (
            <div>
              {relatedPosts?.map((post, idx) => (
                <RelatedPosts key={idx} post={post} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

PostDetails.propTypes = {
  daoId: PropTypes.string,
  postId: PropTypes.string,
  postDetails: PropTypes.array,
  fetchPostDetailsService: PropTypes.func,
  setPostDetails: PropTypes.func,
};

export default PostDetails;

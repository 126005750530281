import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

function LoadingSpinner(props) {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  return <Spin indicator={antIcon} {...props} />;
}

export default LoadingSpinner;

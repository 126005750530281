import { useEffect, useState } from "react";

const useAnimatedPlaceholder = (placeholders: string[]) => {
  const [index, setIndex] = useState(0);

  const [currentPlaceholderText, setCurrentPlaceholderText] = useState("");
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (!placeholders || placeholders.length === 0) return;
    const updatePlaceholder = () => {
      const interval = setInterval(
        () => {
          setCurrentPlaceholderText((prevPlaceholder) => {
            const activePlaceholder = placeholders[index];
            if (
              (isAdding &&
                activePlaceholder.length === prevPlaceholder.length) ||
              (!isAdding && prevPlaceholder.length === 0)
            ) {
              setTimeout(
                () => {
                  setIsAdding((prev) => !prev);
                },
                isAdding ? 3000 : 0
              );
              clearInterval(interval);
              if (!isAdding) {
                setIndex((prev) => (prev + 1) % placeholders.length);
              }
              return prevPlaceholder;
            }

            return activePlaceholder.substring(
              0,
              prevPlaceholder.length + (isAdding ? 1 : -1)
            );
          });
        },
        isAdding ? 40 : 30
      );
      return { interval };
    };
    const { interval } = updatePlaceholder();

    return () => {
      clearInterval(interval);
    };
  }, [placeholders, isAdding, index]);

  if (!placeholders || placeholders.length === 0) {
    return "Ask robin anything...";
  }

  return currentPlaceholderText;
};

export default useAnimatedPlaceholder;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import CourseDetails from "./CourseDetails";
import customAxios from "../../api";
import { Skeleton } from "antd";
import { useDaoContext } from "../../layouts/app/DaoContext";

const CourseDetailsPage = () => {
  const [courseDetails, setCourseDetails] = useState(null);
  const { courseId } = useParams();
  const { daoDetails, daoId } = useDaoContext();
  
  const [loading, setLoading] = useState(false);

  const fetchCourseDetailsService = () => {
    setLoading(true);
    setCourseDetails([]);
    customAxios
      .post(`/courses/fetch/details`, { courseId: courseId })
      .then((res) => {
        setCourseDetails(res.data.courseDetails);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCourseDetailsService();
  }, []);
  return (
    <div>
      <div style={{ height: 20 }} />
      {loading && <Skeleton active />}
      {
        <CourseDetails
          courseDetails={courseDetails}
          fetchCourseDetailsService={fetchCourseDetailsService}
          courseId={courseId}
        />
      }

      {/* <ErrorComponent /> */}
    </div>
  );
};

export default CourseDetailsPage;

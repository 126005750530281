import React from "react";
import "./InternalComponentStyles/GoBackButton.scss";
import leftArrowGrey from "../../assets/iconSvg/leftArrowGrey.svg";
import GoBackIcon from "../../assets/iconSvg/GoBackIcon.svg";
import IconWrapper from "../IconWrapper";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import RightVector from "../../assets/iconSvg/RightVector.svg";

function GoBackButton({ buttonText, clickHandler, showIcon, style }) {
  return (
    <div className="go-back-button__wrapper" onClick={clickHandler} style={{ ...style }}>
      {showIcon && (
        // <IconWrapper style={{ marginRight: "8px" }}>
        //   <ChevronLeftIcon
        //     style={{
        //       color: showIcon ? "#64748b" : "#000000",
        //     }}
        //     width="18px"
        //   />
        // </IconWrapper>
        <img src={GoBackIcon} alt="go back icon" style={{ marginRight: '8px' }} />
      )}
      <span
        className="go-back-button__text"
        style={{
          color: showIcon ? "#64748b" : "#000000",
          cursor: showIcon ? "pointer" : "auto",
        }}
      >
        {buttonText}
      </span>
    </div>
  );
}

export default GoBackButton;

import { Avatar, Button, Typography } from "antd";
import { useNavigate } from "react-router";
import moment from "moment";
import SearchCard from "../SearchCard";
import { ReactComponent as PostImage } from "../../../assets/images/search/post.svg";
import IconWrapper from "../../IconWrapper";
import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import useDaoLensNavigate from "../../../hooks/useDaoLensNavigate";
const { Title, Text } = Typography;
const Post = ({
  data,
  handleClose,
  searchTerm,
  onClick,
  handleDeleteLinkedItem,
}) => {
  const navigate = useDaoLensNavigate();
  const { dao, id, postCreator, parentDao } = data;
  return (
    <SearchCard
      isLinkedItem={!!handleDeleteLinkedItem}
      onClick={() => {
        if (onClick) {
          if (handleDeleteLinkedItem) {
            return;
          } else onClick({ data });
        } else {
          navigate(
            `/app/dao/${dao.name}/${dao?.id}/channel/${data?.channelId}/post/${id}`
          );
          handleClose();
        }
      }}
      setOrder
    >
      {handleDeleteLinkedItem ? (
        <Button
          className="borderless-button"
          type="text"
          shape="circle"
          style={{ position: "absolute", top: "1rem", right: "1rem" }}
          icon={
            <IconWrapper width={"initial"}>
              <CloseCircleOutlined />
            </IconWrapper>
          }
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteLinkedItem(data.id);
          }}
        ></Button>
      ) : null}
      <PostImage />
      <div
        style={{
          fontWeight: 500,
          lineHeight: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            color: "#64748B",
            display: "flex",
            justifyContent: "space-between",
            padding: "0",
            marginBottom: "4px",
          }}
        >
          <div
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "16px",
            }}
          >
            Post
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            <div
              style={{ display: "inline-block" }}
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
                navigate(`/app/dao/${dao.name}/${dao?.id}`);
              }}
            >
              {parentDao ? `${parentDao.name}/` : ""}
              {dao?.name}
            </div>
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            <div
              style={{ display: "inline-block" }}
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
                navigate(`/app/profile/${postCreator?.id}`);
              }}
            >
              {postCreator?.userName}
            </div>
            <span style={{ margin: "0 8px" }}>&#8226;</span>
            {moment(data.createdAt).format("DD MMM")}
          </div>
        </div>
        {data?.postTitle?.substring(0, 70)}
        {data?.postTitle?.length > 70 && "..."}
      </div>
    </SearchCard>
  );
};

export default Post;

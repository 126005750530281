import React, { useState } from "react";
import { useLayer, useHover, Arrow } from "react-laag";
import { PlacementType } from "react-laag/dist/PlacementType";

type TooltipProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  showOnHover?: boolean;
  placement?: PlacementType;
  disabled?: boolean;
  className?: string;
  delayEnter?: number;
  delayLeave?: number;
  isTooltipClosedOnContentClick?: boolean;
};

const Tooltip = ({
  children,
  content,
  showOnHover,
  placement = "bottom-start",
  disabled = false,
  className,
  delayEnter = 100,
  delayLeave = 300,
  isTooltipClosedOnContentClick,
}: TooltipProps) => {
  // state used for click event
  const [isOpen, setIsOpen] = useState(false);
  // state used for hover event
  const [isOver, hoverProps] = useHover({ delayEnter, delayLeave });

  const isTooltipVisible = disabled ? false : showOnHover ? isOver : isOpen;

  const { triggerProps, layerProps, renderLayer } = useLayer({
    isOpen: isTooltipVisible,
    placement: placement,
    auto: true,
    triggerOffset: 15,

    onOutsideClick: () => setIsOpen(false),
  });

  return (
    <>
      <div
        {...triggerProps}
        {...hoverProps}
        onClick={() => setIsOpen(!isOpen)}
        className={className}
      >
        {children}
      </div>
      {isTooltipVisible &&
        renderLayer(
          <div
            className="relative z-[9999] px-3 py-2 text-sm bg-white border rounded-md shadow-lg select-none tooltip text-grey border-grey-500"
            {...layerProps}
            {...hoverProps}
            onClick={(e) => {
              e.stopPropagation();
              isTooltipClosedOnContentClick && setIsOpen(false);
            }}
          >
            {content}
          </div>
        )}
    </>
  );
};

export default Tooltip;

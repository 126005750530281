import { createContext, useState, useContext, useEffect } from "react";

import { useEthers } from "@usedapp/core";

import { walletConnect } from "../../utils/connectors";
import Web3 from "web3";
import { getInjectedProvider } from "@usedapp/core/dist/esm/src/helpers";
import SmallUserDetails from "../../components/InternalComponents/SmallUserDetails";
import { UserContext } from "./UserContext";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router";

const WalletConnectContext = createContext(undefined);

const WalletConnectProvider = (props) => {
  const { activate } = useEthers();
  useEffect(() => {
    const web3 = new Web3(walletConnect);
    const getAccounts = async () => {
      if (web3.currentProvider.wc.accounts.length) {
        setWalletConnected(true);
        await walletConnect.enable();

        await activate(walletConnect);
      }
    };
    if (
      localStorage?.getItem("access_token") &&
      jwtDecode(localStorage.getItem("access_token"))?.isMetamaskConnected
    ) {
      getAccounts();
    }
  }, []);
  const [isWalletConnected, setWalletConnected] = useState(false);

  const onClickActivate = async () => {
    try {
      await walletConnect.enable();
      await activate(walletConnect);
      setWalletConnected(true);
    } catch (error) {
      window.location.reload();
      console.error(error.message);
    }
  };

  return (
    <WalletConnectContext.Provider
      value={{ onClickActivate, isWalletConnected, setWalletConnected }}
    >
      {props.children}
    </WalletConnectContext.Provider>
  );
};

const useWalletConnect = () => useContext(WalletConnectContext);

export { useWalletConnect, WalletConnectProvider };

import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Popover } from "antd";
import IconWrapper from "../../../IconWrapper";
import { ChoiceCard } from "../../../Proposal/Vote";
import GatingConditionPopover from "../../GatingConditionPopover";

const SingleChoice = ({
  choices,
  selectedChoice,
  setSelectedChoice,
  canVote,
  gatingConditions,
}) => {
  return (
    <>
      {choices.map((choice) => {
        let isSelected = selectedChoice === choice.id;
        return (
          <ChoiceCard
            disabled={!canVote}
            isSelected={isSelected}
            onClick={() => {
              if (canVote) setSelectedChoice(choice.id);
            }}
            style={{
              justifyContent: "space-between",
              padding: "0px 12px",
              color: "#64748B",
            }}
          >
            {choice.name}
            {canVote ? (
              <GatingConditionPopover
                gatingConditions={gatingConditions}
              ></GatingConditionPopover>
            ) : null}
            {isSelected && (
              <IconWrapper style={{ color: "#3B82F6" }} width="20px">
                <CheckCircleIcon />
              </IconWrapper>
            )}
          </ChoiceCard>
        );
      })}
    </>
  );
};

export default SingleChoice;

import { Button, Input } from "antd";

export const labeStyling = {
  color: "#64748B",
  fontSize: "12px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "16px",
};

const InputField = ({
  style,
  placeholder,
  onChange,
  value,
  label,
  type,
  textColor,
  textSize,
  textWeight,
  inputProps,
  inputStyle,
  prefix,
}) => {
  return (
    <div
      style={{
        ...labeStyling,
        marginBottom: "16px",
        ...style,
      }}
    >
      {label}
      {type === "textarea" ? (
        <Input.TextArea
          style={{
            borderRadius: "8px",
            margin: "4px 0px",
            color: textColor,
            fontSize: textSize,
            fontWeight: textWeight,
            ...inputStyle,
          }}
          placeholder={placeholder}
          value={value}
          {...inputProps}
          onChange={onChange}
        />
      ) : (
        <Input
          maxLength={100}
          style={{
            borderRadius: "8px",
            margin: "4px 0px",
            color: textColor,
            fontSize: textSize,
            fontWeight: textWeight,
            ...inputStyle,
          }}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...inputProps}
          prefix={prefix}
        />
      )}
    </div>
  );
};

export default InputField;

import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PencilIcon from "../../../assets/images/profile/pencil.svg";

const Upload = ({
  data,
  onChange,
  isUpdated,
  setIsUpdated,
  label = "Course cover",
  suggestedSize = "1000 * 1000",
  width = "202px",
  isInfoHidden = false,
  height = "200px",
  title = "Drag & Drop to Upload",
  emptyStateComponent = null,
}) => {
  const [profileFocus, setProfileFocus] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setProfileFocus(true);
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        width,
        font: "normal 600 12px/16px Inter",
        position: "relative",
      }}
    >
      {label}
      <FileUploader
        handleChange={(file) => {
          setIsUpdated(true);
          onChange(file);
        }}
        name="file"
      >
        {data ? (
          <>
            <img
              src={`${
                isUpdated && typeof data === "object"
                  ? URL.createObjectURL(data)
                  : data
              }`}
              alt="profile"
              style={{
                width,
                height,
                background: "white",
                borderRadius: "16px",
                objectFit: "cover",
              }}
            />
            {profileFocus && (
              <div
                style={{
                  background: "black",
                  opacity: 0.5,
                  position: "absolute",
                  borderRadius: "16px",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onMouseLeave={() => {
                  setProfileFocus(false);
                }}
              >
                <img src={PencilIcon} alt="edit" />
              </div>
            )}
          </>
        ) : (
          <>
            {" "}
            {emptyStateComponent && emptyStateComponent}
            {!emptyStateComponent && (
              <div
                style={{
                  cursor: "pointer",
                  width,
                  height,
                  background: "rgba(226, 232, 240, 0.2)",
                  font: "normal 500 14px/20px Inter",
                  color: "#1A202E",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "12px",
                  border: "1px dashed #CFD8E3",
                  borderRadius: "16px",
                }}
              >
                {title}
                {!isInfoHidden && (
                  <div
                    style={{
                      font: "normal 500 12px/16px Inter",
                      color: "#97A6BA",
                    }}
                  >
                    Suggested size: {suggestedSize}
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      Supported: .png, .jpg, .jpeg
                    </div>
                  </div>
                )}
                <div style={{ textDecoration: "underline" }}>
                  Add from computer
                </div>
              </div>
            )}
          </>
        )}
      </FileUploader>
    </div>
  );
};

export default Upload;

import greyCheck from "../../assets/iconSvg/checkmark.svg";
import greenCheck from "../../assets/iconSvg/greenCheckmark.svg";
import { getTrunckedString } from "../Proposal/ProposalCard";
import { Typography, Menu, Dropdown } from "antd";
import styled from "styled-components";
import { useState } from "react";
import DeleteEntityModal from "../Common/DeleteEntityModal";
import customAxios from "../../api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import DotIcon from "../../images/dot.svg";
import CreateCourseModal from "./CreateCourseModal";
import { MD_SCREEN_BREAKPOINT } from "../../constants";
import useWindowSize from "../../hooks/useWindowSize";
import { togglePinCourses } from "../../utils/pin";
import { PinIcon } from "../Icones";
import ReactHtmlParser from "react-html-parser";
import IconWrapper from "../IconWrapper";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import useDaoLensNavigate from "../../hooks/useDaoLensNavigate";

const StyledDotIconContainer = styled.div`
  & img:hover {
    cursor: pointer;
  }
`;
export const imageUrl = (url) => url?.substring(2, url.length - 2);

const { Title, Paragraph } = Typography;

export const StyledMenu = styled(Menu)`
  border-radius: 8px;
`;
const CourseCard = ({
  course,
  daoName,
  daoId,
  canManageCourse,
  fetchCourseDetailsService,
  redirect,
  descriptionExpanded = false,
}) => {
  const [isEditCourseModalVisible, setIsEditCourseModalVisible] =
    useState(false);
  const { courseId } = useParams();
  console.log(courseId);
  const { windowSize } = useWindowSize();
  const isMobile = windowSize.width < MD_SCREEN_BREAKPOINT;
  const navigate = useDaoLensNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteCourseHandler = () => {
    customAxios
      .delete(`courses/delete?coursesId=${course.id}`)
      .then((res) => {
        toast.success("Course deleted successfully");
        navigate(-1);
        setShowDeleteModal(false);
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const tagsData = {
    taskPoint: course?.taskPoint,
    completedSubTask: course.completedSubTask,
    totalSubTask: course.totalSubTask,
  };
  const menu = (
    <StyledMenu onClick={(e) => {}}>
      <Menu.Item
        onClick={(e) => {
          e.domEvent.stopPropagation();
          setIsEditCourseModalVisible(true);
        }}
      >
        <PencilIcon fill="#6B7280" style={{ width: "12px" }} /> Edit
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          e.domEvent.stopPropagation();
          togglePinCourses(course.daoId, course.id, !course.pinned);
        }}
      >
        <PinIcon style={{ transform: "translateY(-1px)" }} />{" "}
        {course.pinned ? "Unpin" : "Pin"}
      </Menu.Item>
      <Menu.Item
        onClick={(e) => {
          e.domEvent.stopPropagation();
          setShowDeleteModal(true);
        }}
      >
        <TrashIcon fill="#6B7280" style={{ width: "12px" }} /> Delete
      </Menu.Item>
    </StyledMenu>
  );
  return (
    <div
      style={{
        overflowX: "hidden",
        background: "white",
        borderRadius: "12px",
        padding: "20px",
      }}
      onClick={() => {
        if (redirect)
          navigate(`/app/dao/${daoName}/${course.daoId}/course/${course.id}`);
      }}
    >
      <div
        style={{
          display: "flex",
          alignContent: "flex-start",
          gap: "5px 20px",
        }}
      >
        <div style={{ width: "100px" }}>
          <img
            src={imageUrl(course.thumbnailAttachment)}
            style={{
              width: "96px",
              height: "96px",
              border: "1px solid #F1F5F9",
              borderRadius: "16px",
              objectFit: "cover",
            }}
            alt="thumbnail"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "4px",
          }}
        >
          <div style={{ display: "flex" }}>
            <DeleteEntityModal
              note="This course will be deleted permanently. Do you still want to proceed?"
              isModalVisible={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              onSubmit={() => {
                deleteCourseHandler();
              }}
            />
            <CreateCourseModal
              onSubmit={() => {
                fetchCourseDetailsService?.();
              }}
              mode="edit"
              course={course}
              isModalVisible={isEditCourseModalVisible}
              handleCancel={() => setIsEditCourseModalVisible(false)}
            />
            <Title
              level={4}
              style={{
                fontSize: "16px",
                // width: "420px",
                // overflow: "hidden",
                textOverflow: "ellipsis",
                // whiteSpace: "nowrap",
                display: "inline",
                width: "100%",
                margin: "0",
              }}
            >
              {getTrunckedString(course.name, 50)}
            </Title>
            {course.pinned && <PinIcon style={{ color: "#6366F1" }} />}

            {canManageCourse && (
              <Dropdown
                overlay={menu}
                trigger={["click"]}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <StyledDotIconContainer style={{ marginLeft: "8px" }}>
                  <img src={DotIcon} alt="dot icon" />
                </StyledDotIconContainer>
              </Dropdown>
            )}
          </div>
          <Paragraph
            style={{
              color: "#64748B",
              margin: "0",
              width: "100%",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              cursor: "pointer",
              height: "100%",
            }}
            ellipsis={{
              rows: isMobile ? 3 : courseId ? 3 : 2,
              expandable: courseId ? true : false,
              symbol: "more",
            }}
          >
            {ReactHtmlParser(course.description)}
          </Paragraph>

          {!isMobile && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <span
                style={{
                  display: "inline-flex",
                  fontSize: "12px",
                  alignItems: "center",
                  padding: "5px 10px",
                  border: "1px solid #E2E8F0",
                  borderRadius: "30px",
                  gap: "4px",
                  whiteSpace: "nowrap",
                }}
              >
                {course.isEnrolled ? (
                  course.isEnrolled.isCompleted ? (
                    <>
                      {" "}
                      <img src={greenCheck} alt="" /> Completed
                    </>
                  ) : (
                    <>
                      {" "}
                      <img src={greyCheck} alt="" />
                      {course.completedSubTask} of {course.totalSubTask}
                    </>
                  )
                ) : (
                  <>📕 {course.totalSubTask}</>
                )}
              </span>
              {tagsData.taskPoint && (
                <span
                  style={{
                    display: "inline-flex",
                    fontSize: "12px",
                    alignItems: "center",
                    padding: "5px 10px",
                    border: "1px solid #E2E8F0",
                    borderRadius: "30px",
                    whiteSpace: "nowrap",
                  }}
                >
                  ⭐️ {tagsData.taskPoint}
                </span>
              )}
              {course?.skills?.map((skill) => (
                <span
                  style={{
                    display: "inline-flex",
                    fontSize: "12px",
                    alignItems: "center",
                    padding: "5px 10px",
                    backgroundColor: "#F6F5FF",
                    borderRadius: "30px",
                    color: "#4A1D96",
                    fontWeight: "500",
                    whiteSpace: "nowrap",
                  }}
                >
                  # {skill}
                </span>
              ))}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            marginTop: "8px",
          }}
        >
          <span
            style={{
              display: "inline-flex",
              fontSize: "12px",
              alignItems: "center",
              padding: "5px 10px",
              border: "1px solid #E2E8F0",
              borderRadius: "30px",
              gap: "4px",
              whiteSpace: "nowrap",
            }}
          >
            {course.isEnrolled ? (
              course.isEnrolled.isCompleted ? (
                <>
                  {" "}
                  <img src={greenCheck} alt="" /> Completed
                </>
              ) : (
                <>
                  {" "}
                  <img src={greyCheck} alt="" />
                  {course.completedSubTask} of {course.totalSubTask}
                </>
              )
            ) : (
              <>📕 {course.totalSubTask}</>
            )}
          </span>
          {tagsData.taskPoint && (
            <span
              style={{
                display: "inline-flex",
                fontSize: "12px",
                alignItems: "center",
                padding: "5px 10px",
                border: "1px solid #E2E8F0",
                borderRadius: "30px",
                whiteSpace: "nowrap",
              }}
            >
              ⭐️ {tagsData.taskPoint}
            </span>
          )}
          {course?.skills?.map((skill) => (
            <span
              style={{
                display: "inline-flex",
                fontSize: "12px",
                alignItems: "center",
                padding: "5px 10px",
                backgroundColor: "#F6F5FF",
                borderRadius: "30px",
                color: "#4A1D96",
                fontWeight: "500",
                whiteSpace: "nowrap",
              }}
            >
              # {skill}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseCard;

import { Col, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import useDaoLensNavigate from "../../../../hooks/useDaoLensNavigate";
import useWindowSize from "../../../../hooks/useWindowSize";
import ProfileCardPopup from "../../../Common/ProfileCard";
import DueDate from "../../../DueDate";
import Flag from "../../../Flag";
import dummyUser from "./../../../../assets/images/login/defaultUser.jpeg";
const { Paragraph } = Typography;
const Circle = ({ style, onClick = () => {} }) => (
  <div
    onClick={onClick}
    style={{
      width: "20px",
      height: "20px",
      marginRight: "4px",
      /* Colors/bg/white */
      borderRadius: "50%",
      background: "#FFFFFF",
      display: "inline-block",
      /* Colors/text/disabled-cool gray-400 */

      border: "1px solid #97A6BA",
      ...style,
    }}
  ></div>
);
const BountyFilesAttachment = ({ files, showHeader = true }) => {
  const { isMobile } = useWindowSize();
  const StyledAttachment = styled.div`
    // padding: 0px ${isMobile ? "0px" : "10px"};
    // margin: ${isMobile ? "4px" : "8px"} 0px;
    .attachment-title {
      font-weight: 600;
      font-size: 12px;
      margin: 5px 8px;
    }
  `;
  return (
    <StyledAttachment>
      {showHeader ? <p className="attachment-title">ATTACHMENTS</p> : null}
      {files.map((file, idx) => (
        <Tag
          onClick={(e) => {
            e.stopPropagation();
            window.open(file);
          }}
          style={{
            cursor: "pointer",
            border: "1px solid #E2E8F0",
            background: "rgb(241, 245, 249)",
            padding: "0.25rem 0.8rem",
            borderRadius: "8px",
            marginRight: "0px",
            color: "#64748b",
            fontWeight: "500",
          }}
          key={idx}
        >
          File
          <span
            style={{
              margin: "0px 0px 0px 4px",
              fontSize: "12px",
              color: "#64748B",
            }}
          >
            {/* {Number(file.size / 1024 / 1024).toFixed(1) > 0
              ? `${Number(file.size / 1024 / 1024).toFixed(1)} MB`
              : `${Number(file.size / 1024).toFixed(1)} KB`} */}
            1 MB
          </span>
        </Tag>
      ))}
    </StyledAttachment>
  );
};
function ChecklistItem({ ele, idx, markChecklistComplete, canEdit }) {
  const navigate = useDaoLensNavigate();
  const { isMobile } = useWindowSize();
  return (
    <Col
      xs={24}
      style={{
        background: "#FFFFFF",
        minHeight: "50px",
        /* Colors/divider/dark */
        // margin: "12px 16px",
        marginTop: idx === 0 ? "12px" : "0px",

        borderBottom: "1px solid #F1F5FF",

        /* shadow-sm */
        padding: "8px 12px",

        borderRadius: "12px",
        display: "flex",
        flexWrap: isMobile && "wrap",
        alignItems: "center",
      }}
    >
      <div
        style={{
          gap: "8px",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            gap: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {ele.isCompleted ? (
            <i
              class="fas fa-check-circle"
              onClick={() => (canEdit ? markChecklistComplete(ele) : null)}
              style={{
                cursor: ele.canMarkChecklist ? "pointer" : "initial",
                color: " #3F83F8",
                fontSize: "16px",
              }}
            ></i>
          ) : (
            <i
              class="far fa-circle"
              style={{
                color: "#97A6BA",
                cursor: ele.canMarkChecklist ? "pointer" : "initial",
                fontSize: "16px",
              }}
              onClick={() => (canEdit ? markChecklistComplete(ele) : null)}
            ></i>
            // <div
            //   style={{
            //     height: "20px",
            //     width: "20px",
            //     borderRadius: "50%",
            //     border: "1px solid #97A6BA",
            //     cursor: ele.canMarkChecklist ? "pointer" : "initial",
            //   }}
            // ></div>
          )}
          <Paragraph
            // ellipsis={{
            //   rows: 2,
            //   // expandable: true,
            // }}
            style={{
              marginBottom: "0px",
              textDecoration: ele.isCompleted ? "line-through" : "none",
              color: ele.isCompleted ? "#64748B" : "imherit",
            }}
          >
            {ele.title}
          </Paragraph>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            marginLeft: "24px",
            flexWrap: "wrap",
          }}
        >
          {ele?.attachments?.length ? (
            <BountyFilesAttachment files={ele.attachments} showHeader={false} />
          ) : null}
          {ele.priority ? (
            <Flag defaultValue={ele.priority} disabled={true} />
          ) : null}
          {/* <DueDate defaultValue={ele?.dueDate} disabled={true} /> */}
          {ele?.dueDate && (
            <DueDate defaultValue={ele?.dueDate} disabled={true} />
          )}

          {ele?.assigneeId ? (
            <>
              <Tooltip title={ele?.assigneeUserName}>
                <ProfileCardPopup userId={ele.assigneeId}>
                  <img
                    onClick={() => navigate(`/app/profile/${ele.assigneeId}`)}
                    src={
                      ele.assigneeProfilePicture
                        ? ele.assigneeProfilePicture
                        : dummyUser
                    }
                    alt="icon"
                    style={{
                      cursor: "pointer",
                      objectFit: "cover",
                      width: "22px",
                      height: "22px",
                      borderRadius: "50%",
                    }} // style={{ borderRadius: "10rem" }}
                  />
                </ProfileCardPopup>
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
    </Col>
  );
}

export default ChecklistItem;

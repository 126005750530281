import community from "../images/discover-page/community.png";
import gating from "../images/discover-page/gating.png";
import proposals from "../images/discover-page/proposals.png";
import settings from "../images/discover-page/settings.png";
import robinai from "../images/discover-page/robinai.png";

export const MANAGER_TUTORIALS: {
  image: any;
  title: string;
  description: string;
  link: string;
}[] = [
  {
    image: settings,
    title: "Setting option and Features there",
    description:
      "Explore what all options you can play around with in the settings menu.",
    link: "https://www.loom.com/share/93f0c56148664de69a96065df7d544d0",
  },
  {
    image: community,
    title: "Create community",
    description:
      "Learn how to create and setup a community on the DAO manager.",
    link: "https://www.loom.com/share/6a5bf747c34f4ef688a3afbb10192b7f",
  },
  {
    image: gating,
    title: "How to gate?",
    description:
      "How to gate different touch points in your community in the DAO manager.",
    link: "https://www.loom.com/share/5f4591e3914f4932a429a2cf0a1f32a6",
  },
  {
    image: proposals,
    title: "How to create Proposals?",
    description:
      "Learn how DAO manager is integrated with Snapshot to streamline your proposals.",
    link: "https://www.loom.com/share/b378d7bc83cc41c984a723101f7a4e66",
  },
  {
    image: robinai,
    title: "AI Powered Community Assistant",
    description:
      "We designed Robin AI to help you get the most out of your community. Use Robin to get summaries, insights, feedbacks or use it to draft your next proposal.",
    link: "https://www.loom.com/share/bffc65e3a93a4e5ea68679856a762f75",
  },
];

export const FILTERS = [
  { label: "🙌🏻 All", value: "" },
  { label: "️💜 Your", value: "YOURS" },
  { label: "🌍 DAO", value: "DAO" },
  { label: "✨ Content creation", value: "CONTENT_CREATOR" },
  { label: "👥 Social", value: "SOCIAL" },
  { label: "🎮 Gaming", value: "GAMING" },
  { label: "🔧 Protocol", value: "PROTOCOL" },
  { label: "🏞 NFT", value: "NFT" },
  { label: "⚪️ Others", value: "OTHERS" },
] as const;

export const FILTER_VALUES = FILTERS.map((filter) => filter.value);

type EntityType = {
  label: string;
  value: string;
};

export const ENTITIES = [
  { value: "", label: "🏡 Community" },
  { value: "BOUNTY", label: "👨‍💻 Bounty" },
  { value: "POST", label: "💬 Discussions" },
  { value: "COURSES", label: "📚 Course" },
  { value: "EVENTS", label: "🎭 Event" },
  { value: "PROPOSALS", label: "⚡ P️roposal" },
] as const;

export const ENTITY_VALUES = ENTITIES.map((entity) => entity.value);
